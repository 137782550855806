//@ts-check
import { push } from "connected-react-router";

/**
 * 
 * @param {string} caminho 
 * @param {any} state 
 * @returns 
 */
export default function goTo(caminho, state = undefined) {
  return push(caminho, state);
}