import { log } from './LogUtils';
import { isNonEmptyObject, isNonEmptyArray, in_, isStrictObject } from './ComparatorsUtils';
import { getURIFromEntity } from './URIUtils';
import * as actionTypes from '../store/actions/actionTypes';

/**
 * 
 * @param {Object} object 
 */
export const reverseObject = (object = {}) => {
    if (!isStrictObject(object)) {
        return null;
    }

    let reversed_object = {};

    for (let item in object) {
        reversed_object[object[item]] = item;
    }

    return reversed_object;
}

/**
 * Método que atualiza o objeto enviado, com as novas propriedades. 
 * @param {Record<string,any>} oldObject Objeto original
 * @param {Record<string,any>} updatedProperties Propriedades a serem atualizadas
 * 
 * @returns {Record<string,any>}
 */
export const updateObject = (oldObject, updatedProperties) => {
    // log('utils updateObject', oldObject, updatedProperties);
    return {
        ...oldObject,
        ...updatedProperties
    };
}

/**
 * Remove propriedades de um objeto cujo valor for igual aos passados por parâmetro.
 * Aceita `undefined`, `null`, `false`, `''`, `0`, etc.
 * @param {Object} object objeto a ter propriedades removidas
 * @param {Array} propertyList lista de valores a serem removidos
 */
export const removeProperties = (object, propertyList) => {
    log('utils removeProperties', object, propertyList);

    if (!(isNonEmptyObject(object) && isNonEmptyArray(propertyList))) {
        return null;
    }

    for (let property in object) {
        if (in_(object[property], propertyList)) {
            delete object[property];
        }
    }

    return object;
}

// /**
//  * Junta duas listas. Os itens da segunda lista só serão adicionados à primeira se eles passarem no teste executado pela função informada.
//  * @param {*} oldArray lista cujos itens serão todos retornados
//  * @param {*} newArray lista cujos itens poderão ser adicionados junto com os outros
//  * @param {*} shouldConcat função que recebe a primeira lista e itens da segunda, é executada para cada item e, se retornar verdadeiro,
//  * o item será adicionado na lista; se não, não.
//  */
// export const concatUnique = (oldArray, newArray, shouldConcat) => (isNonEmptyArray(newArray) ? newArray : [])
//     .reduce((previousArray, currentItem) => shouldConcat(oldArray, currentItem) ? previousArray.concat(currentItem) : previousArray, oldArray);

/**
 * Mesma coisa que `Object.assign`, só que também copia `getters` e `setters`.
 */
export const assign = (target, ...sources) => {
    log('utils assign', target, sources);
    sources.forEach(source => {
        Object.keys(source).forEach(property => {
            Object.defineProperty(target, property, Object.getOwnPropertyDescriptor(source, property));
        });
    });
    return target;
};

/**
 * Retorna um objeto com todas as propriedades do objeto informado cujas chaves e/ou valores passarem no filtro.
 * @param {Object} object 
 * @param {Function} filter função que recebe a chave e o valor de cada propriedade do objeto
 */
export const filterObject = (object, filter) => {
    if ((!object) || (!filter)) {
        return object;
    }
    return Object
        .keys(object)
        .map(key => ({ key, value: object[key] }))
        .filter(item => filter(item.key, item.value))
        .reduce((prev, curr) => Object.assign({}, prev, { [curr.key]: curr.value }), {});
}

/**
 * Retorna uma cópia do valor informado sem quaisquer vínculos com o valor original
 * @param {Object} object objeto a ser copiado
 */
export const deepClone = object => JSON.parse(JSON.stringify(object));

/**
 * Define o valor inicial para uma variável do *reducer* que, durante a execução do *reducer*, poderá ter seu valor definido para o valor inicial,
 * o valor atual ou um novo valor. No último caso, como o valor será calculado, a variável também é definida para o valor inicial.
 * @param {Object} action ação disparada
 * @param {String} actionName nome da variável na ação que indica o que fazer com a variável do *reducer*
 * @param {Object} state um *reducer*
 * @param {Object} initialState estado inicial de um *reducer*
 * @param {String} variableName nome de variável do *reducer*. Se não informado, será usada a mesma variável da ação.
 */
export const initReducerVariable = (action, actionName, state, initialState, variableName) => {
    // Verifica se foi informado o nome da variável que indica o que fazer com a variável do reducer
    if (!variableName) {
        // Se não foi informado, usa a mesma variável do reducer.
        variableName = actionName;
    }
    return ((!action[actionName]) || (action[actionName] === actionTypes.REDUCER_HOLD) || (action[actionName] === actionTypes.REDUCER_UPDATE))
        ? deepClone(state[variableName])
        : deepClone(initialState[variableName]);
};

/**
 * Testa se dois valores são iguais ou diferentes, assumindo que foram lidos de um campo numérico.
 * Só funciona com o retorno de `InputCustomizado.getMaskValue`, que só pode ser um `Number` ou `null`.
 * Assume que `null` é diferente de zero.
 * Assim, dois valores serão iguais se ambos forem `null`, zero, ou se tiverem o mesmo valor.
 * @param {String} valueA valor lido do campo de seleção
 * @param {String} valueB valor lido do *reducer*
 */
export const areNumericInputValuesEqual = (valueA, valueB) => {
    return ((valueA === 0) && (valueB === 0)) ||
        ((valueA === null) && (valueB === null))
        || (valueA === valueB);
}

/**
 * Testa se dois valores são iguais ou diferentes, assumindo que foram lidos de um campo de seleção e possuem um `href`.
 * Considera todos os *falsy* igualmente.
 * Ou seja, se um for `null` e o outro for `undefined`, eles são considerados iguais.
 * Se um for *truthy*, ou seja, um `String`, basta testar se forem diferentes.
 * @param {String} valueA valor lido do campo de seleção
 * @param {String} valueB valor lido do *reducer*
 */
export const areSelectInputValuesEqual = (valueA, valueB) => {
    // Se valueA for falsy, eles serão iguais se valueB for falsy e diferentes se valueB for truthy.
    return ((!valueA) && (!valueB))
        // Se valueA for truthy, eles serão iguais se eles forem iguais.
        || (valueA && (getURIFromEntity(valueA.value) === getURIFromEntity((valueB || {}).value)));
}

/**
 * Testa se dois valores são iguais ou diferentes, assumindo que foram lidos de um campo de texto.
 * Considera todos os *falsy* igualmente.
 * Ou seja, se um for `null` e o outro for `undefined`, eles são considerados iguais.
 * Se um for *truthy*, ou seja, um `String`, basta testar se forem diferentes.
 * @param {String} valueA 
 * @param {String} valueB 
 */
export const areTextInputValuesEqual = (valueA, valueB) => {
    // Se valueA for falsy, eles serão iguais se valueB for falsy e diferentes se valueB for truthy.
    return ((!valueA) && (!valueB))
        // Se valueA for truthy, eles serão iguais se eles forem iguais.
        || (valueA && (valueA === valueB));
}

/**
 * Faz a projeção de um objeto como se fosse no Spring.
 * 
 * Provavelmente não suporta propriedades que podem ser um valor ou um objeto.
 * 
 * Exemplo:
 * ```
let pagamentoVenda = {
    dataHora: "2019-11-08T11:24:51.217-03:00",
    valorDesconto: 0,
    valorTotal: 1,
    itemPagamentoVendaList: [
        {
            quantidade: 1,
            produto: {
                nome: "Product 0",
                _links: { self: { href: "http://localhost:9080/api/produtos/49627" } }
            },
            valorTotal: 1,
            livre: false,
            _links: { self: { href: "http://localhost:9080/api/itemPagamentoVendas/15454" } }
        }
    ],
    fusoHorario: "-03:00",
    venda: {
        origemVenda: {
            nome: "mobi",
            _links: { self: { href: "http://localhost:9080/api/origemVendas/187" } }
        },
        cliente: null,
        nome: "10:33:07",
        _links: { self: { href: "http://localhost:9080/api/vendas/8140" } }
    },
    _links: { self: { href: "http://localhost:9080/api/pagamentoVendas/7805" } }
};

let pagamentoVendaProjection = {
    venda: {
        cliente: {
            _links: { self: { href: false } }
        },
        origemVenda: {
            _links: { self: { href: false } }
        },
        _links: { self: { href: false } }
    },
    _links: { self: { href: false } }
}

project(pagamentoVenda, pagamentoVendaProjection);
 * ```
 * @param {Object} object 
 * @param {Object} projection 
 */
export const project = (object, projection) => {
    return Object.keys(projection).reduce((a, e) => ({ ...a, [e]: object[e] ? (projection[e] ? project(object[e], projection[e]) : object[e]) : object[e] }), {});
}

/**
 * Atualiza uma variável que fica dentro de um objeto,
 * que fica dentro de um objeto,
 * que fica dentro de um objeto, etc.
 */
export const updateDeepObject = (object, keyArray, value) => {
    // Se já visitou todos os objetos, altera o valor da propriedade
    if (keyArray.length === 1) {
        return Object.assign({}, object, {
            [keyArray[0]]: value
        });
    }
    // Se não, acessa as propriedades de forma recursiva.
    else {
        return Object.assign({}, object, {
            [keyArray[0]]: updateDeepObject((object || {})[keyArray[0]], keyArray.slice(1), value)
        });
    }
};

export const mapObjectKeys = (oldObj, changeArray = [], normalize) => {
    const newObj = {};

    for(const data of changeArray) {
        const {oldKey, newKey} = data;

        if (normalize) {
            newObj[newKey] = normalize[newKey](oldObj[oldKey]);
        }
        else {
            newObj[newKey] = oldObj[oldKey];
        }
    }

    return newObj;
}

export const orderObject = (unorderedObject) => {
    const object = {};
    const keys = Object.keys(unorderedObject).sort();

    for (const key in keys) {
        object[key] = unorderedObject[key];
    }

    return object;
}

/**
 * 
 * @param {Array} array 
 * @param {Object} keys 
 * @param {Object} value_ids 
 */
export const convertArrayToObject = (array = [], keys = {id: 'id', data: 'data'}) => {
    const obj = {};
    const id   = keys.id   || [];
    const data = keys.data || [];

    for (const item of array) {
        const key = item[id];

        obj[key] = item[data];
    }

    return obj;
}