import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { formatI18nString } from "../../../utils/StringUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"

import BarraAcoesForm, { SUBMIT_ROLE_NEW } from "../../cadastros/barraAcao/BarraAcoesForm"
import BuildCellFromPedido from "./BuildCellFromPedido.js"
import HelpParagraph from "../../UI/HelpParagraph/HelpParagraph"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

/**
 * Exibe as últimas vendas feitas por usuário sem vínculo com empresa.
 */
class GridVendaFromCliente extends React.Component {

  /**
   * Ao clicar em voltar na barra de ações,
   * abre a leitura de código.
   */
  handleListar = () => {
    log("GridVendaFromCliente handleListar")
    this.props.setupSaleNoCompany()
  }

  /**
   * Ao clicar em adicionar na barra de ações,
   * inicia uma nova venda.
   */
  handleSubmit = () => {
    log("GridVendaFromCliente handleSubmit")
    // Busca a origem de venda
    let origemVenda = (this.props.origemVendaList || [])[0]
    // Se houver
    if (origemVenda) {
      // Inicia nova venda
      this.props.beginNewOrListSalesNoCompany(origemVenda, true)
    }
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("GridVendaFromCliente render")

    return <>
      {
        <WidthAwareDiv>
          <HelpParagraph children={getStrings().ordersHelp.map(formatI18nString)} />
        </WidthAwareDiv>
      }
      <BarraAcoesForm
        handleListar={this.handleListar}
        handleSubmit={this.handleSubmit}
        submitRole={SUBMIT_ROLE_NEW}
      />

      <div className="pure-g" >
        {(this.props.origemVendaList || []).flatMap(origemVenda => origemVenda.vendaList || []).map(venda =>
          <BuildCellFromPedido
            key={getURIFromEntity(venda)}
            venda={venda}
          />)}
      </div>
    </>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  tabIndex: state.appReducer.getTabIndex(),
  ...state.idiomaReducer,
  origemVendaList: state.controleVendaReducer.origemVendaList,
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  beginNewOrListSalesNoCompany: (origemVenda, forceNew) => dispatch(controleVendaActions.beginNewOrListSalesNoCompany(origemVenda, forceNew)),
  setupSaleNoCompany: () => dispatch(controleVendaActions.setupSaleNoCompany())
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(GridVendaFromCliente)
