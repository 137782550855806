//@ts-check
import en_US from '../../../i18n/en_US';
import pt_BR from '../../../i18n/pt_BR';
import { ClienteHttp, LogUtils } from '../utils';

/**
 * 
 * @type {import('./types').localidadeType}
 */
const localidade = {
  atual: 'pt_BR',
  traducoes: {
    pt_BR,
    en_US,
  },
  cep: {
    pt_BR: {
      obterUrl: (cep) => `https://viacep.com.br/ws/${cep}/json/`,
      tamanho: 8,
    },
    en_US: {
      obterUrl: (cep) => `https://api.zippopotam.us/us/${cep}`,
      tamanho: 5,
    },
  },
}

/**
 * 
 * @param {string} cep 
 * @param {(resposta: any) => void} callback 
 * @param {(error: any) => void} error 
 */
export function obterEnderecoPorCep(cep, callback = (resposta) => { LogUtils.log('resposta', resposta); }, error = (error) => { LogUtils.log('error', error) }) {
  const localidadeCep = localidade.cep[localidade.atual];
  const {obterUrl, tamanho} = localidadeCep;

  if (cep.length === tamanho) {
    ClienteHttp.obterClienteHttp().get(obterUrl(cep))
      .then(callback)
      .catch(error);
  }
}

/**
 * 
 * @param {import('./types').traducoesNomeType} novaLocalidade 
 */
export function mudarLocalidade(novaLocalidade) {
  localidade.atual = novaLocalidade;
}

/**
 * 
 * @returns {import('./types').traducoesType}
 */
export default function() {
  const traducoes = localidade.traducoes[localidade.atual];

  return traducoes;
}
