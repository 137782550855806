import { botaoPrincipalReducerTipo } from "./botaoPrincipalReducer";
import criarAction from "../../factory/criarAction";

const tiposDeAcoes = {
    DEFINIR_BOTAO_PRINCIPAL: 'DEFINIR_BOTAO_PRINCIPAL:BOTAO_PRINCIPAL',
    LIMPAR_BOTAO_PRINCIPAL: 'LIMPAR_BOTAO_PRINCIPAL:BOTAO_PRINCIPAL',
}

export default tiposDeAcoes;

export const limparBotaoPrincipal = () => function(dispatch: any) {
    dispatch(criarAction(undefined, tiposDeAcoes.LIMPAR_BOTAO_PRINCIPAL));
}

export const definirBotaoPrincipal = (carga: botaoPrincipalReducerTipo) => function(dispatch: any) {
    dispatch(criarAction(carga, tiposDeAcoes.DEFINIR_BOTAO_PRINCIPAL));
}
