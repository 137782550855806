//@ts-check
import React from 'react';
import { Botao, Texto } from '../../../nucleo/nucleo';
import { connect } from 'react-redux';

import './BotaoCarrinhoUnidade.css';

/**
 * 
 * @param {import('./types').botaoCarrinhoUnidadePropsType} props 
 * @returns 
 */
function BotaoCarrinhoUnidade(props) {
  const disabled = props.disabled || props.quantidadeDeProdutos < props.quantidadeMinima;

  return <Botao className={'botao-carrinho-unidade row ' + (props.className || '') + (disabled ? ' disabled' : '')} onClick={props.webAppBotaoPrincipalReducer.executarAcao}>
    <Texto>{'Adicionar'}</Texto>
  </Botao>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  const grupoProdutoArray = Object.values(state.webAppGrupoProdutosReducer.grupos);
  const grupoProduto = grupoProdutoArray.find((grupoProduto) => grupoProduto.nome === state.webAppCarrinhoReducer.unidadeAtual.split(' ')[0]);
  const grupoProdutoTamanho = grupoProduto
    ? grupoProduto.grupoProdutoTamanhoList.find(({id}) => id === state.webAppCarrinhoReducer.grupoProdutoTamanhoId)
    : {quantidadeMinimaSabores: 1};
  const quantidadeMinimaSabores = grupoProdutoTamanho
    ? grupoProdutoTamanho.quantidadeMinimaSabores
    : 1;
  const sabores = state.webAppCarrinhoReducer.unidade.sabores;

  const props = {
    webAppBotaoPrincipalReducer: state.webAppBotaoPrincipalReducer,
    quantidadeDeProdutos: [0, ...Object.values(sabores).map(sabor => sabor.quantidade)]
        .reduce((soma, valorTotal) => soma + valorTotal),
    quantidadeMinima: quantidadeMinimaSabores,
  };

  return props;
};

const mapActionToProps = () => ({});

export default connect(mapStateToProps, mapActionToProps)(BotaoCarrinhoUnidade);
