import React from 'react';
import { connect } from 'react-redux';

import * as controleVendaActions from '../../store/actions/controleVenda/controleVendaAction';

import {
    STATE_CONTROLE_VENDA_CLIENT_LAST_SALES,
    STATE_CONTROLE_VENDA_GRID_ORIGENS,
    STATE_CONTROLE_VENDA_GRID_VENDAS,
    STATE_CONTROLE_VENDA_MANUTENCAO_VENDA,
    STATE_CONTROLE_VENDA_NOVA_VENDA,
    STATE_CONTROLE_VENDA_PAGAR_VENDAS,
    STATE_CONTROLE_VENDA_PAGAR_ORIGENS,
} from '../../store/reducers/controleVenda/controleVendaReducer';

import { RamoEmpresa } from '../../store/actions/empresaSelectorAction';

import {
    NIVEL_USUARIO_SEM_EMPRESA_VENDA
} from '../../store/reducers/empresaSelectorReducer';

import * as manutencaoVendaActions from '../../store/actions/controleVenda/manutencao/manutencaoVendaAction';

import {
    MANUTENCAO_CONTENT_ITENS,
    MANUTENCAO_CONTENT_PAGAMENTO,
    MANUTENCAO_CONTENT_PRODUTO
} from '../../store/actions/controleVenda/manutencao/manutencaoVendaAction';

import { push } from 'connected-react-router'

import * as breadCrumbs from '../UI/BreadCrumbs/BreadCrumbs';
import I18n from '../UI/I18n/I18n';

/**
 * Exibe o header ou o bread crumbs baseado nas variáveis armazenadas no *reducer*.
 */
class ControleVendaHeader extends React.Component {

    /**
     * Quando for exibir o *link* para as vendas da origem, e for uma venda nova em uma origem livre, exibe somente o texto
     * ao invés do *link* pois, como a origem era livre, não poderia resultar em uma tela exibindo suas vendas.
     */
    blockVendas = () => this.props.vendaAmount === 0;

    /**
     * Retorna o conteúdo do header baseado nas variáveis armazenadas no *reducer*.
     */
    getHeader = () => {

        switch (this.props.state) {

            case STATE_CONTROLE_VENDA_CLIENT_LAST_SALES:
                return breadCrumbs.home_reader_orders(() => this.props.setupSaleNoCompany());

            case STATE_CONTROLE_VENDA_GRID_ORIGENS:
                return I18n('sourcesInUse');

            case STATE_CONTROLE_VENDA_GRID_VENDAS:
                return breadCrumbs.sourcesInUse_sourceSales(() => this.props.exibeGridOrigemVendaEmUso(), this.props.origemVenda.nome);

            case STATE_CONTROLE_VENDA_MANUTENCAO_VENDA:
            case STATE_CONTROLE_VENDA_NOVA_VENDA:

                switch (this.props.manutencaoContent) {

                    case MANUTENCAO_CONTENT_ITENS:

                        // Se for usuário sem vínculo com empresa
                        if (!this.props.isCargo) {
                            if (this.props.clientSaleAmount) {
                                return breadCrumbs.home_reader_orders_saleProducts_items(
                                    () => this.props.handleBack(() => this.props.goTo('/'), true),
                                    () => this.props.handleBack(() => this.props.setupSaleNoCompany(), true),
                                    () => this.props.handleBack(() => this.props.goTo('/pedidos'), true),
                                    this.props.venda,
                                    () => this.props.exibeProdutos());
                            }
                            return breadCrumbs.home_reader_saleProducts_items(
                                () => this.props.handleBack(() => this.props.goTo('/'), true),
                                () => this.props.handleBack(() => this.props.setupSaleNoCompany(), true),
                                this.props.venda,
                                () => this.props.exibeProdutos());
                        }
                        else if (this.props.ramoEmpresa === RamoEmpresa.PAINEL) {
                            return breadCrumbs.saleProducts_items(this.props.venda, () => this.props.exibeProdutos());
                        }
                        return breadCrumbs.sourcesInUse_sourceSales_saleProducts_items(
                            () => this.props.handleBack(() => this.props.exibeGridOrigemVendaEmUso(), true),
                            (this.props.origemVenda || {}).nome,
                            () => this.props.handleBack(() => this.props.exibeGridVendaFromOrigem(), true),
                            this.blockVendas(),
                            this.props.venda,
                            () => this.props.exibeProdutos()
                        );

                    case MANUTENCAO_CONTENT_PAGAMENTO:

                        // Se for usuário sem vínculo com empresa
                        if (!this.props.isCargo) {
                            if (this.props.clientSaleAmount) {
                                return breadCrumbs.home_reader_orders_saleProducts_payment(
                                    () => this.props.handleBack(() => this.props.goTo('/'), true),
                                    () => this.props.handleBack(() => this.props.setupSaleNoCompany(), true),
                                    () => this.props.handleBack(() => this.props.goTo('/pedidos'), true),
                                    this.props.venda,
                                    () => this.props.exibeProdutos());
                            }
                            return breadCrumbs.home_reader_saleProducts_payment(
                                () => this.props.handleBack(() => this.props.goTo('/'), true),
                                () => this.props.handleBack(() => this.props.setupSaleNoCompany(), true),
                                this.props.venda,
                                () => this.props.exibeProdutos());
                        }
                        else if (this.props.ramoEmpresa === RamoEmpresa.PAINEL) {
                            return breadCrumbs.saleProducts_payment(this.props.venda, () => this.props.exibeProdutos());
                        }
                        return breadCrumbs.sourcesInUse_sourceSales_saleProducts_payment(
                            () => this.props.handleBack(() => this.props.exibeGridOrigemVendaEmUso(), true),
                            (this.props.origemVenda || {}).nome,
                            () => this.props.handleBack(() => this.props.exibeGridVendaFromOrigem(), true),
                            this.blockVendas(),
                            this.props.venda,
                            () => this.props.exibeProdutos()
                        );

                    case MANUTENCAO_CONTENT_PRODUTO:

                        // Se for usuário sem vínculo com empresa
                        if (!this.props.isCargo) {
                            if (this.props.nivelUsuarioSemEmpresa === NIVEL_USUARIO_SEM_EMPRESA_VENDA) {
                                if (this.props.clientSaleAmount) {
                                    return breadCrumbs.home_reader_orders_saleProducts(
                                        () => this.props.handleBack(() => this.props.goTo('/'), true),
                                        () => this.props.handleBack(() => this.props.setupSaleNoCompany(), true),
                                        () => this.props.handleBack(() => this.props.goTo('/pedidos'), true),
                                        this.props.venda);
                                }
                                return breadCrumbs.home_reader_saleProducts(
                                    () => this.props.handleBack(() => this.props.goTo('/'), true),
                                    () => this.props.handleBack(() => this.props.setupSaleNoCompany(), true),
                                    this.props.venda);
                            } else {
                                return breadCrumbs.home_reader_company(() => this.props.setupSaleNoCompany(), this.props.venda);
                            }
                        }
                        else if (this.props.ramoEmpresa === RamoEmpresa.PAINEL) {
                            return breadCrumbs.saleProducts(this.props.venda);
                        }
                        return breadCrumbs.sourcesInUse_sourceSales_saleProducts(
                            () => this.props.handleBack(() => this.props.exibeGridOrigemVendaEmUso()),
                            (this.props.origemVenda || {}).nome,
                            () => this.props.handleBack(() => this.props.exibeGridVendaFromOrigem()),
                            this.blockVendas(),
                            this.props.venda
                        );

                    default:
                        return null;
                }

            case STATE_CONTROLE_VENDA_PAGAR_VENDAS:
                return breadCrumbs.sourcesInUse_sourceSales_salesPayment(
                    () => this.props.exibeGridOrigemVendaEmUso(),
                    (this.props.origemVenda || {}).nome,
                    () => this.props.exibeGridVendaFromOrigem()
                );

            case STATE_CONTROLE_VENDA_PAGAR_ORIGENS:
                return breadCrumbs.sourcesInUse_salesPayment(() => this.props.exibeGridOrigemVendaEmUso());

            default:
                return null;
        }
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {

        const infoUsuarioSemEmpresa = this.props.nivelUsuarioSemEmpresa === NIVEL_USUARIO_SEM_EMPRESA_VENDA
            ? I18n('order', false, [1])
            : ((this.props.origemVenda || {}).empresa || {}).nomeFantasia || I18n('company');

        return <div className='header breadCrumbs'>
            {this.props.isCargo
                ? <h1>{I18n('movement')}</h1>
                : <div className='header-title'>
                    <div>
                        {infoUsuarioSemEmpresa}
                    </div>
                    <div>
                        {this.props.origemVenda?.nome || ''}
                    </div>
                </div>
            }
            <h2>{this.getHeader()}</h2>
        </div>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    state: state.controleVendaReducer.state,
    clientSaleAmount: state.controleVendaReducer.clientSaleAmount,
    isCargo: state.empresaSelectorReducer.cargo ? true : false,
    nivelUsuarioSemEmpresa: state.controleVendaReducer.nivelUsuarioSemEmpresa,
    origemVenda: state.controleVendaReducer.origemVendaList[0],
    vendaAmount: state.controleVendaReducer.vendaAmount,
    venda: state.controleVendaReducer.origemVendaList[0] && state.controleVendaReducer.origemVendaList[0].vendaList
        && state.controleVendaReducer.origemVendaList[0].vendaList[0] ? state.controleVendaReducer.origemVendaList[0].vendaList[0] : null,

    manutencaoContent: state.manutencaoVendaReducer.manutencaoContent,
    ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa
});
/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    exibeGridOrigemVendaEmUso: () => dispatch(controleVendaActions.exibeGridOrigemVendaEmUso()),
    exibeGridVendaFromOrigem: () => dispatch(manutencaoVendaActions.exibeGridVendaFromOrigem()),
    exibeProdutos: () => dispatch(manutencaoVendaActions.exibeProdutos()),
    goTo: path => dispatch(push(path)),
    handleBack: (confirmMethod, ignoreCode) => dispatch(manutencaoVendaActions.handleBack(confirmMethod, ignoreCode)),
    setupSaleNoCompany: () => dispatch(controleVendaActions.setupSaleNoCompany())
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(ControleVendaHeader);
