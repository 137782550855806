import React from "react"
import { connect } from "react-redux"
import { verificaCorEstado } from "../../producao/grid/CardPedidoItemProducao"

import { getNomeProdutoFromItemVenda } from "../../../utils/MiscUtils"
import { formatNumber } from "../../../utils/NumberUtils"

import { dispatchTipo } from "../../../store/actions/acaoTemplate"
import { appDialogShow } from "../../../store/actions/appAction"

import Icones from "../../../assets/icones/Icones"
import { getStrings } from "../../../utils/LocaleUtils"

export type CardPedidoItemTipo = {
  dispatch: dispatchTipo
  itemList: Array<any>
  estadoItensVenda: string
}

class CardPedidoItem extends React.Component<CardPedidoItemTipo> {

  render() {
    const FaRegComment = Icones.fa.FaRegComment;
    const estadoItensVenda = this.props.estadoItensVenda;

    return <>
      {this.props.itemList.map((item, index) =>
        <div className={`linha ${estadoItensVenda !== item.estado ? verificaCorEstado(item.estado) : ''}`} key={index}>
          <div className='coluna-quantidade'>
            {item.livre ? '1' : item.quantidade}
          </div>
          <div className='coluna-descricao'>
            {item.livre ? `${getStrings().allYouCanEatName(getNomeProdutoFromItemVenda(item))}` : getNomeProdutoFromItemVenda(item)}
          </div>
          <div className='coluna-valor'>
            {formatNumber(item.valorTotal, 2)}
          </div>
          <div
            className='coluna-observacao'
            onClick={item.observacao
              ? () => { this.props.dispatch(appDialogShow(item.observacao, getNomeProdutoFromItemVenda(item))); }
              : undefined
            }
          >
            {item.observacao
              ? <FaRegComment />
              : null
            }
          </div>
        </div>)
      }
    </>;
  }
};

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = (state: any) => ({
  ...state.idiomaReducer
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = (dispatch: dispatchTipo) => ({ dispatch });

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(CardPedidoItem);
