import React from "react"
import { connect } from "react-redux"

import {
  STATE_CONTROLE_VENDA_GET_SALE_FROM_CALL
} from "../../../store/reducers/controleVenda/controleVendaReducer"
import * as appActions from "../../../store/actions/appAction"
import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { blurAll } from "../../../utils/ScreenUtils"
import { getCurrentHourMinuteSecondFormatted } from "../../../utils/TimeUtils"

import InputCustomizado from "../../UI/Input/InputCustomizado"
import NomeInputDefault from "../../cadastros/cadastroInputDefault/NomeInputDefault"

/**
 * Classe que monta o conteúdo da dialog para inserir nome e número de pessoas de uma nova venda.
 */
const DialogNovaVenda = (props) => {
  let nomeField;
  let numeroPessoasField;
  const [firstRun, updateFirstRun] = React.useState(true);

  const handleSubmit = event => {
    log('DialogNovaVenda handleSubmit', { event });

    // Impede que o form redirecione para outra URL
    if (event) {
      event.preventDefault();
    }

    blurAll();

    props.appDialogHide();

    let newVendaData = {
      origemVenda: props.origemVenda,
      nome: props.isCargo ? nomeField.getValue() : getCurrentHourMinuteSecondFormatted(),
      numeroPessoas: props.isCargo ? numeroPessoasField.inputComponent.value : 1
    };

    // Se for um usuário com vínculo com empresa
    if (props.isCargo) {

      // Verifica se o dialog foi aberto por causa de um chamado em uma origem de venda
      if (props.fromCallState === STATE_CONTROLE_VENDA_GET_SALE_FROM_CALL) {
        // Se sim, armazena os novos dados da venda e troca o estado para eles serem usados
        props.setupNewVendaFromCall(newVendaData);
      }
      // Se o dialog foi aberto pelo controle de vendas, inicia a nova venda normalmente.
      else {
        props.continueNewVenda(newVendaData);
      }
    }
    // Se for um usuário sem vínculo com empresa
    else {
      // Inicia a nova venda normalmente
      props.exibeManutencaoVendaNoCompany(props.origemVenda, newVendaData, !props.isAuthenticated);
    }
  }

  const componentDidMount = () => {
    log('DialogNovaVenda componentDidMount');
    if (props.dialogShow) {
      // Se for um usuário sem empresa iniciando uma venda, não precisa dar um nome à venda
      if (props.noCompany && props.origemVenda) {

        // // Se usuário já possuir venda nesta origem (comentado para perguntar nada ao iniciar venda por cliente)
        // if (((props.origemVenda.vendaList || []).length > 0)
        //     || (((props.origemVenda.empresa || {}).parametrosEmpresa || {}).nivelUsuarioSemEmpresa !== NIVEL_USUARIO_SEM_EMPRESA_VENDA)
        //     // ou possuir dados da venda salva no local storage,
        //     || canOpenSaleFromStorage()) {
        //     // abre ela sem perguntar pelos dados de uma nova venda.
        handleSubmit();
        // }
      }
      else {
        nomeField.inputField.inputComponent.value = getCurrentHourMinuteSecondFormatted();
        nomeField.inputField.inputComponent.select();
      }
    }
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  const componentDidUpdate = () => {
    log('DialogNovaVenda componentDidUpdate');
    componentDidMount();
  };

  const focusComponent = (evt, component) => {
    let keyCode = evt.key || evt.which;

    if (keyCode === 'Tab') {
      evt.preventDefault();
      component.inputComponent.focus();
    }
  };

  // eslint-disable-next-line
  React.useEffect(() => {
    if (firstRun) {
      updateFirstRun(false);
      componentDidMount();
    } else {
      componentDidUpdate();
    }
  });

  const render = () => {
    log('DialogNovaVenda render');
    // Retorna o conteúdo da dialog
    return <>

      <form onSubmit={handleSubmit} >

        <div className='sub-form'>
          {props.noCompany ? null : <NomeInputDefault
            ref={input => nomeField = getReduxWrappedComponent(input)}
            subPlaceholder={getStrings().saleSubPlaceholder()}
            onFocus={() => {
              nomeField.inputField.inputComponent.select();
            }}
            isRequired={true}
            onKeyDown={(evt) => { focusComponent(evt, numeroPessoasField) }} />}
          <InputCustomizado
            ref={input => { if (input) { numeroPessoasField = getReduxWrappedComponent(input); numeroPessoasField.inputComponent.value = 1; } }}
            id='numeroPessoas'
            inputType='number'
            name='numeroPessoas'
            required={true}
            label={getStrings().peopleAmountRequired}
            min={1} />
        </div>

        {/* type="submit" indica que quando o botão for clicado será chamado o método "onSubmit" do formulário */}
        <div className='dialog-horizontal-button-grid'>
          <button
            className='blue-button font-color'
            type='submit'
            tabIndex={(props.tabIndex + 1) * -1}
          >
            {getStrings().create}
          </button>
        </div>
      </form>
    </>;
  };

  return render();
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  tabIndex: state.appReducer.getTabIndex(),
  dialogShow: state.appReducer.dialogShow,
  fromCallState: state.controleVendaReducer.fromCallState,
  isAuthenticated: !!state.authReducer.token,
  isCargo: !!state.empresaSelectorReducer.cargo
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  appDialogHide: () => dispatch(appActions.appDialogHide()),
  continueNewVenda: newVendaData => dispatch(controleVendaActions.continueNewVenda(newVendaData)),
  exibeManutencaoVendaNoCompany: (origemVenda, newVendaData, isAuthenticated) =>
    dispatch(controleVendaActions.exibeManutencaoVendaNoCompany(origemVenda, newVendaData, isAuthenticated)),
  setupNewVendaFromCall: newVendaData => dispatch(controleVendaActions.setupNewVendaFromCall(newVendaData)),
  resetFromCall: () => dispatch(controleVendaActions.resetFromCall())
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(DialogNovaVenda);
