import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputCustomizado from '../../UI/Input/InputCustomizado';

import { getStrings } from '../../../utils/LocaleUtils';
import { getReduxWrappedComponent } from '../../../utils/reduxUtils/reduxUtils';

/**
 * Classe que realiza a montagem de um dos 3 campos de registros básicos de todos os formulários de cadastro: Ativo, Código e Nome.
 * Classe responsável por montar o campo padrão "Nome".
 */
class NomeInputDefault extends React.Component {
    static propTypes = {
        defaultValue: PropTypes.any,
        handleInputValidado: PropTypes.func,
        isRequired: PropTypes.bool,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        onKeyDown: PropTypes.func,
        placeholder: PropTypes.string,
        ref: PropTypes.func,
        subPlaceholder: PropTypes.string,
        tabIndex: PropTypes.number,
    };

    /**
     * Método que retorna o valor do campo.
     */
    getValue = () => {
        return this.inputField.inputComponent.value;
    }

    /**
     * Método que monta o componente.
     */
    render() {

        // Prepara o placeholder do campo
        var placeholder;

        if ('placeholder' in this.props) {
            placeholder = this.props.placeholder;
        }
        else if ('subPlaceholder' in this.props) {
            placeholder = getStrings().namePlaceholderTemplate(this.props.subPlaceholder);
        }
        else {
            placeholder = getStrings().namePlaceholder;
        }

        // Monta o componente
        return (
            <InputCustomizado ref={input => this.inputField = getReduxWrappedComponent(input)} defaultValue={this.props.defaultValue} placeholder={placeholder} onKeyDown={this.props.onKeyDown}
                maxLength='40' id='nome' type='text' name='nome' required={true} label={this.props.isRequired ? getStrings().nameRequired : getStrings().name} onFocus={this.props.onFocus} onBlur={this.props.onBlur} handleInputValidado={this.props.handleInputValidado}/>
        );
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {
    return {
        ...state.idiomaReducer
    };
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, null, null, { forwardRef: true })(NomeInputDefault);
