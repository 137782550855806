/**
 * 
 * @type {import("./types").tipoConfig}
 */
const config = {
  empresas: {},
  filtroLog: [],
  modoDesenvolvedor: process.env.NODE_ENV === "development",
}

export default config;
