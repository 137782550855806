import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getStrings } from "../../../utils/LocaleUtils";
import { log } from "../../../utils/LogUtils";
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils";
import { cadastroBuildOptionsFromPaises } from "../../../utils/SelectUtils";

import * as cadastroActions from "../../../store/actions/cadastroAction";
import { updateAllFormData } from "../../../store/actions/signUpAction";
import { cadastroEmpresaEUsuarioSaveData } from "../../../store/actions/cadastroEmpresaAction";
import {
  STATE_CADASTRO_EMPRESA_EMPRESA
  , STATE_CADASTRO_EMPRESA_USUARIO
} from "../../../store/reducers/cadastroEmpresaReducer";

import InputCustomizado from "../../UI/Input/InputCustomizado";
import { getURIFromEntity } from "../../../utils/URIUtils";
import { USUARIO_SET_STATE } from "../../../store/actions/actionTypes";

/**
 * Exibe os campos de endereço do cadastro do usuário.
 */
class EnderecoForm extends React.Component {

  /**
   * Trata atualizações em campos de texto.
   * @param {string} key
   * @param {any} value
   */
  handleTextInput = (key, value) => {
    const updateFunction = this.updateFuncionSwitch();

    updateFunction({
      [key]: value
    });
  }

  zipCodeCallBack = (_, state, city, neighborhood, street) => {
    this.focusOnNumeroInput();

    if (state) {
      this.handleTextInput("uf", state);
    }
    if (city) {
      this.handleTextInput("municipio", city);
    }
    if (neighborhood) {
      this.handleTextInput("bairro", neighborhood);
    }
    if (street) {
      this.handleTextInput("endereco", street);
    }

    this.forceUpdate();
  }

  /**
   * Método que carrega os itens do campo Países.
   */
  loadPaises = () => {
    log("EnderecoForm loadPaises");

    // Carrega o campo de País com a função de busca, conforme o campo for sendo preenchido
    this.props.cadastroLoadPaises(
      cadastros => {
        const options = cadastroBuildOptionsFromPaises(true, cadastros);
        const paisOption = options.filter((pais) => pais.labelKey === "BR")[0];

        this.pais.inputComponent.updateOptions(options);
        this.pais.inputComponent.updateValue(paisOption, "iso");

        this.handleTextInput("paisOption", paisOption);
      },
      () => { });
  }

  focusOnNumeroInput = () => {

    if (!this.props.noAutoFocus) {
      const numeroInputElement = document.getElementById("numero");
  
      if (numeroInputElement) {
        numeroInputElement.focus({ preventScroll: false });
      }
    }
  }

  enderecoReducerSwitch = () => {
    const entity = (this.props.entity || "user") + (this.props.companyForm || false);

    switch (entity) {
      case "companyfalse":
        return this.props.enderecoEmpresa || {};
      case "companytrue":
        return this.props.enderecoEmpresa || {};
      case "userfalse":
        return this.props.dadosAdicionais || {};
      case "usertrue":
        return this.props.enderecoUsuarioEmpresa || {};
      case "userformfalse":
        return this.props.usuario.dadosAdicionais || {};
      case "userformtrue":
        return this.props.enderecoUsuarioEmpresa || {};
      case "companyformfalse":
        return this.props.empresaForm.enderecoEmpresa || {};
      case "companyformtrue":
        return this.props.empresaForm.enderecoEmpresa || {};
      default:
        return this.props.externalData?.dadosAdicionais || {};
    }
  }

  updateFuncionSwitch = () => {
    const entity = (this.props.entity || "user") + (this.props.companyForm || false);
    const enderecoReducer = this.enderecoReducerSwitch();

    switch (entity) {
      case "companyfalse":
        return (dados) => this.props.updateCadastroEmpresaFormData({
          ...this.props.empresa,
          enderecoEmpresa: {
            ...enderecoReducer,
            ...dados,
          },
        });
      case "companytrue":
        return (dados) => this.props.updateCadastroEmpresaFormData({
          ...this.props.empresa,
          enderecoEmpresa: {
            ...enderecoReducer,
            ...dados,
          },
        });
      case "userfalse":
        return (dados) => this.props.updateAllSignUpFormData({
          ...this.props.signUpFormData,
          dadosAdicionais: {
            ...enderecoReducer,
            ...dados,
          },
        });
      case "usertrue":
        return (dados) => this.props.updateCadastroEmpresaUsuarioFormData({
          ...(this.props.cadastroEmpresaDadosUsuario || {}),
          dadosAdicionais: {
            ...enderecoReducer,
            ...dados,
          },
        });
      case "userformfalse":
        return (dados) => this.props.updateFormUsuario({
          ...this.props.usuario,
          dadosAdicionais: {
            ...enderecoReducer,
            ...dados,
          },
        });
      case "userformtrue":
        return (dados) => this.props.updateFormUsuario({
          ...this.props.empresa.usuario,
          dadosAdicionais: {
            ...enderecoReducer,
            ...dados,
          },
        });
      case "companyformfalse":
        return (dados) => this.props.updateFormEmpresa({
          ...this.props.empresaForm,
          enderecoEmpresa: {
            ...enderecoReducer,
            ...dados,
          },
        });
      case "companyformtrue":
        return (dados) => this.props.updateFormEmpresa({
          ...this.props.empresaForm,
          enderecoEmpresa: {
            ...enderecoReducer,
            ...dados,
          },
        });
      default:
        return (dados) => this.props.updateExternalData({
          ...(this.props.externalData || {}),
          dadosAdicionais: {
            ...enderecoReducer,
            ...dados,
          },
        });
    }
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  componentDidMount() {
    log("EnderecoForm componentDidMount");
    this.loadPaises();

    if (!this.props.noAutoFocus) {
      const cepElement = document.getElementById("cep");
      cepElement.focus();
    }
  }

  componentDidUpdate() {
    log("EnderecoForm componentDidUpdate");

    const enderecoReducer = this.enderecoReducerSwitch();

    if ((enderecoReducer.pais || "") !== getURIFromEntity(this.pais.inputComponent.getValue()?.value)) {
      this.pais.inputComponent.updateValue(enderecoReducer.paisOption, "iso");
    }

    if ((enderecoReducer.cep || "") !== this.cep.inputComponent.value) {
      this.cep.inputComponent.value = enderecoReducer.cep || "";
    }

    if ((enderecoReducer.uf || "") !== this.uf.inputComponent.value) {
      this.uf.inputComponent.value = enderecoReducer.uf || "";
    }

    if ((enderecoReducer.municipio || "") !== this.municipio.inputComponent.value) {
      this.municipio.inputComponent.value = enderecoReducer.municipio || "";
    }

    if ((enderecoReducer.bairro || "") !== this.bairro.inputComponent.value) {
      this.bairro.inputComponent.value = enderecoReducer.bairro || "";
    }

    if ((enderecoReducer.endereco || "") !== this.endereco.inputComponent.value) {
      this.endereco.inputComponent.value = enderecoReducer.endereco || "";
    }

    if ((enderecoReducer.numero || "") !== this.numero.inputComponent.value) {
      this.numero.inputComponent.value = enderecoReducer.numero || "";
    }

    if ((enderecoReducer.complemento || "") !== this.complemento.inputComponent.value) {
      this.complemento.inputComponent.value = enderecoReducer.complemento || "";
    }
  }

  /**
   * Método executado ANTES de "DESMONTAR" o componente.
   * Marca o componente como NÃO montado.
   */
  componentWillUnmount() {
    this.ismounted = false;
  }

  render() {
    log("EnderecoForm render");

    const entity = this.props.entity || "user";
    let titulo;

    if (this.props.companyForm === true && entity === "user") {
      titulo = `${getStrings().user} - ${getStrings().address}`;
    }
    else if (this.props.companyForm === false && entity === "company") {
      titulo = `${getStrings().company} - ${getStrings().address}`;
    }
    else {
      titulo = getStrings().address;
    }

    return <div className="endereco-form flex flex-column flex-nowrap">

      <h2 style={{marginTop: '0'}}>{titulo}</h2>

      <div className="flex flex-row flex-nowrap">
        <div className="flex flex-row flex-nowrap" style={{ flex: "4 1 0" }}>
          <InputCustomizado
            topClassName="full-width"
            //topClassStyle={{ padding: "0 0.3em" }}
            ref={input => { if (input) { this.pais = getReduxWrappedComponent(input); } }}
            placeholder={getStrings().countryPlaceholderTemplate(getStrings()[`${entity}SubPlaceholder`])}
            id="pais"
            inputType="singleSelect"
            name="pais"
            required={true}
            label={getStrings().countryRequired}
            onChange={(value) => this.handleTextInput("paisOption", value)} />
        </div>
        <div className="flex flex-row flex-nowrap" style={{ flex: "3 1 0" }}>
          <InputCustomizado
            topClassName="full-width"
            topClassStyle={{ paddingLeft: "0.6em" }}
            ref={input => { if (input) { this.cep = getReduxWrappedComponent(input) } }}
            placeholder={getStrings().zipCodePlaceholderTemplate(getStrings()[`${entity}SubPlaceholder`])}
            maxLength="20"
            id="cep"
            type="text"
            name="cep"
            required={true}
            validacaoDados="numeroInteiroNegativo"
            label={getStrings().zipCodeRequired}
            handleInputValidado={value => this.handleTextInput("cep", value)}
            onFocus={() => {
              // Acumula o valor atual do campo
              this.zipCode = this.cep.inputComponent.value;
            }}
            onBlur={() => {
              // Verifica se o valor do campo foi alterado para evitar de sobrescrever os campos toda vez que o campo for focado
              if (this.zipCode === this.cep.inputComponent.value) {
                return;
              }

              this.props.readZipCode(this.cep.inputComponent.value, this.zipCodeCallBack);
            }} />
        </div>
        <div className="flex flex-row flex-nowrap" style={{ flex: "2 1 0" }}>
          <InputCustomizado
            topClassName="full-width"
            topClassStyle={{ paddingLeft: "0.6em" }}
            ref={input => { if (input) { this.uf = getReduxWrappedComponent(input) } }}
            placeholder={getStrings().countryStatePlaceholderTemplate(getStrings()[`${entity}SubPlaceholder`])}
            maxLength="50"
            id="uf"
            type="text"
            name="uf"
            required={true}
            label={getStrings().countryStateRequired}
            handleInputValidado={value => this.handleTextInput("uf", value)} />
        </div>
      </div>

      <InputCustomizado
        //topClassStyle={{ padding: "0 0.3em" }}
        ref={input => { if (input) { this.municipio = getReduxWrappedComponent(input) } }}
        placeholder={getStrings().cityPlaceholderTemplate(getStrings()[`${entity}SubPlaceholder`])}
        maxLength="50"
        id="municipio"
        type="text"
        name="municipio"
        required={true}
        label={getStrings().cityRequired}
        handleInputValidado={value => this.handleTextInput("municipio", value)} />

      <InputCustomizado
        //topClassStyle={{ padding: "0 0.3em" }}
        ref={input => { if (input) { this.bairro = getReduxWrappedComponent(input) } }}
        placeholder={getStrings().neighborhoodPlaceholderTemplate(getStrings()[`${entity}SubPlaceholder`])}
        maxLength="50"
        id="bairro"
        type="text"
        name="bairro"
        required={true}
        label={getStrings().neighborhoodRequired}
        handleInputValidado={value => this.handleTextInput("bairro", value)} />

      <InputCustomizado
        //topClassStyle={{ padding: "0 0.3em" }}
        ref={input => { if (input) { this.endereco = getReduxWrappedComponent(input) } }}
        placeholder={getStrings().addressPlaceholderTemplate(getStrings()[`${entity}SubPlaceholder`])}
        maxLength="50"
        id="endereco"
        type="text"
        name="endereco"
        required={true}
        label={getStrings().addressRequired}
        handleInputValidado={value => this.handleTextInput("endereco", value)} />

      <div className="flex flex-row flex-nowrap">
        <div className="flex flex-row flex-nowrap justify-content-center" style={{ flex: "1 1 0" }}>
          <InputCustomizado
            topClassName="full-width"
            //topClassStyle={{ paddingLeft: "0 0.3em" }}
            ref={input => { if (input) { this.numero = getReduxWrappedComponent(input) } }}
            placeholder={getStrings().addressNumberPlaceholderTemplate(getStrings()[`${entity}SubPlaceholder`])}
            maxLength="20"
            id="numero"
            type="text"
            name="numero"
            required={true}
            label={getStrings().numberRequired}
            handleInputValidado={value => this.handleTextInput("numero", value)} />
        </div>
        <div className="flex flex-row flex-nowrap justify-content-center" style={{ flex: "2 1 0" }}>
          <InputCustomizado
            topClassName="full-width"
            topClassStyle={{ paddingLeft: "0.6em" }}
            ref={input => { if (input) { this.complemento = getReduxWrappedComponent(input) } }}
            placeholder={getStrings().complementPlaceholderTemplate(getStrings()[`${entity}SubPlaceholder`])}
            maxLength="20"
            id="complemento"
            type="text"
            name="complemento"
            label={getStrings().complement}
            handleInputValidado={value => this.handleTextInput("complemento", value)} />
        </div>
      </div>

    </div>;
  }
}

EnderecoForm.propTypes = {
  updateFormData: PropTypes.any,

  /**
   * Se esta tela é montada dentro do cadastro de empresa por usuário não logado.
   */
  companyForm: PropTypes.bool,

  /**
   * Se é endereço de empresa (`company`) ou de usuário (`user`).
   */
  entity: PropTypes.oneOf(["company", "user"]).isRequired,

  /** Função ao alterar valor do campo.
   * 
   * Se for um campo de *select*, será chamado no `onChange`.
   * Se for campo de texto, no `handleInputValidado`.
   */
  handleChange: PropTypes.func,

  updateReducer: PropTypes.func,

  /**
   * Função a ser executada para preencher os campos obtidos através de consulta do CEP.
   * 
   * Receberá nesta ordem: estado (UF), municipio, bairro e rua.
   */
  zipCodeCallBack: PropTypes.func
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {any} state 
 */
function mapStateToProps(state) {
  const usuario = state.usuarioReducer.usuario || {};
  const empresaForm = state.cadastroEmpresaReducer.cadastroEmpresaDados || {};
  const props = {
    ...state.idiomaReducer,
    dadosAdicionais: state.signUpReducer.formData.dadosAdicionais,
    empresa: state.cadastroEmpresaReducer.cadastroEmpresaDados.empresa,
    empresaForm: {
      ...empresaForm,
      enderecoEmpresa: {
        ...(empresaForm?.enderecoEmpresa || {}),
        pais: getURIFromEntity(empresaForm?.enderecoEmpresa?.pais),
        paisOption: cadastroBuildOptionsFromPaises(false, empresaForm?.enderecoEmpresa?.pais),
      },
    },
    enderecoEmpresa: state.cadastroEmpresaReducer.cadastroEmpresaDados?.empresa?.enderecoEmpresa || {},
    enderecoUsuarioEmpresa: state.cadastroEmpresaReducer.cadastroEmpresaDados?.usuario?.dadosAdicionais || {},
    cadastroEmpresaDadosUsuario: state.cadastroEmpresaReducer.cadastroEmpresaDados?.usuario,
    paisReducer: state.paisReducer,
    signUpFormData: state.signUpReducer.formData,
    usuario: {
      ...usuario,
      dadosAdicionais: {
        ...(usuario?.dadosAdicionais || {}),
        pais: getURIFromEntity(usuario?.dadosAdicionais?.pais),
        paisOption: cadastroBuildOptionsFromPaises(false, usuario?.dadosAdicionais?.pais),
      }
    },
  };

  return props;
};

/**
 * Mapeia as ações para utilizar localmente. 
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch) {
  const props = {
    cadastroLoadPaises: (onSuccess, onComplete) =>
      dispatch(cadastroActions.cadastroLoadPaises(onSuccess, onComplete)),
    readZipCode: (zipCodeRaw, callBack) =>
      dispatch(cadastroActions.readZipCode(zipCodeRaw, callBack)),
    updateAllSignUpFormData: (keyArray, value) =>
      dispatch(updateAllFormData(keyArray, value)),
    updateCadastroEmpresaFormData: (dados) =>
      dispatch(cadastroEmpresaEUsuarioSaveData(STATE_CADASTRO_EMPRESA_EMPRESA, dados)),
    updateCadastroEmpresaUsuarioFormData: (dados) =>
      dispatch(cadastroEmpresaEUsuarioSaveData(STATE_CADASTRO_EMPRESA_USUARIO, dados)),
    updateFormUsuario: (dados) =>
      dispatch({
        usuario: {
          ...dados, dadosAdicionais: {
            ...(dados.dadosAdicionais || {}),
            pais: dados.dadosAdicionais?.paisOption?.value,
            paisOption: undefined,
          }
        }, type: USUARIO_SET_STATE
      }),
      updateFormEmpresa: (dados) =>
        dispatch(cadastroEmpresaEUsuarioSaveData("STATE_CADASTRO_EMPRESA_FORM", {
          ...dados,
          enderecoEmpresa: {
            ...(dados?.enderecoEmpresa || {}),
            pais: dados?.enderecoEmpresa?.paisOption?.value,
            paisOption: undefined,
          }
        })),
  };

  return props;
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EnderecoForm);