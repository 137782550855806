import React from "react";
import styled from "styled-components";
import { ConteudoSection, SegmentoCard, TitleSub } from ".";
import { PATH_IMAGENS_SEGMENTOS } from "../utils/resources";

const Segmentos = React.forwardRef((props, ref) => {
  return (
    <ConteudoSection ref={ref}>
      <TitleSub primary> Conheça alguns segmentos em que o Mobi já é realidade</TitleSub>
      <SegmentosGrid>
        <SegmentoCard key={0} cardTitle={"Bar"} src={PATH_IMAGENS_SEGMENTOS + "bar.png"} />
        <SegmentoCard key={1} cardTitle={"Distribuidora"} src={PATH_IMAGENS_SEGMENTOS + "distribuidoras.png"} />
        <SegmentoCard key={2} cardTitle={"Foodtruck"} src={PATH_IMAGENS_SEGMENTOS + "foodtruck.png"} />
        <SegmentoCard key={3} cardTitle={"Hamburgueria"} src={PATH_IMAGENS_SEGMENTOS + "hamburgueria.png"} />
        <SegmentoCard key={4} cardTitle={"Loja"} src={PATH_IMAGENS_SEGMENTOS + "loja.jpeg"} />
        <SegmentoCard key={5} cardTitle={"Minimercado"} src={PATH_IMAGENS_SEGMENTOS + "minimercado.png"} />
        <SegmentoCard key={6} cardTitle={"Padaria"} src={PATH_IMAGENS_SEGMENTOS + "padaria.png"} />
        <SegmentoCard key={7} cardTitle={"Papelaria"} src={PATH_IMAGENS_SEGMENTOS + "papelarias.png"} />
        <SegmentoCard key={8} cardTitle={"Pizzaria"} src={PATH_IMAGENS_SEGMENTOS + "pizzaria.png"} />
        <SegmentoCard key={9} cardTitle={"Quiosque"} src={PATH_IMAGENS_SEGMENTOS + "quiosque_praia.png"} />
        <SegmentoCard key={10} cardTitle={"Restaurante"} src={PATH_IMAGENS_SEGMENTOS + "restaurante.png"} />
        <SegmentoCard key={11} cardTitle={"Sorveteria"} src={PATH_IMAGENS_SEGMENTOS + "sorveteria.png"} />
      </SegmentosGrid>
    </ConteudoSection>
  );
});

const SegmentosGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    width: 80%;
  }
`;

export default Segmentos;
