//@ts-check
import React from 'react';
import { Botao, Entrada } from '../../../nucleo/nucleo';

import DialogTemplate from '../DialogTemplate';
import { LocalidadeUtils, ClienteHttp, EmailUtils } from '../../../nucleo/utils/utils';
import { connect } from 'react-redux';
import { mostrarOverlay, esconderOverlay } from '../../../nucleo/redux/overlay/overlayActions';
import CarregamentoOverlay from '../../overlay/CarregamentoOverlay/CarregamentoOverlay';
import { getNomeEmpresa } from '../../../assets/empresas/configEmpresa';
import AvisoDialog from '../AvisoDialog/AvisoDialog';
import { mostrarDialog } from '../../../nucleo/redux/dialog/dialogActions';

import "./NovaSenhaDialog.css";

/**
 * 
 * @param {import('./types').novaSenhaDialogPropsType} props 
 * @returns 
 */
function NovaSenhaDialog(props) {
  const [email, updateEmail] = React.useState('');

  return <DialogTemplate fecharHabilitado={props.fecharHabilitado} titulo={LocalidadeUtils.obterTraducao().login.passwordRecovery} style={{maxWidth: '25em'}}>
    <div id='nova-senha-dialog'>
      <Entrada type="email" value={email} onChange={
        /**
         * 
         * @param {{target: {value: string}}} evt 
         */
        ({target}) => { updateEmail(target.value) }} placeholder='Email' />
      <Botao disabled={!EmailUtils.emailIsValid(email)} className={'ui-submit'} children={LocalidadeUtils.obterTraducao().login.submit} onClick={() => {

        props.dispatch(mostrarOverlay({componente: 
          /**
           * 
           * @param {any} props 
           * @returns 
           */
          (props) => <CarregamentoOverlay {...props} />}));

        props.fecharDialog();

        ClienteHttp.obterClienteHttp()
          .post(ClienteHttp.getApi('user/resetPassword'), { login: { email: email } }, {params: {nomeEmpresa: getNomeEmpresa()}})
          .then((_) => {
            props.dispatch(esconderOverlay());
            props.dispatch(mostrarDialog({
              conteudo: () => 
                <AvisoDialog mensagem={LocalidadeUtils.obterTraducao().phrases.emailSentTo(email)} />
            }));
          })
          .catch((erro) => {
            setTimeout(() => {
              props.dispatch(esconderOverlay());
              const mensagem = erro?.response?.data === 'userNotFound' ? LocalidadeUtils.obterTraducao().eror.userNotFound : LocalidadeUtils.obterTraducao().eror.sendingEmail;
              props.dispatch(mostrarDialog({
                conteudo: () =>
                  <AvisoDialog mensagem={mensagem} />
              }));
            }, 500);
          })
      }} />
    </div>
  </DialogTemplate>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({

});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(NovaSenhaDialog);
