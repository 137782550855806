import "../vendas/vendas.css";

import React from 'react';
import { connect } from 'react-redux';

import { log } from "../../utils/LogUtils"
import { getPageSize } from "../../utils/StorageUtils/LocalStorageUtils"
import { updateActiveMenuItem } from "../../utils/ScreenUtils"
import { urlDatabase } from "../../utils/SecureConnectionUtils"

import * as cadastroActions from '../../store/actions/cadastroAction';
import { push } from 'connected-react-router'

import {
    STATE_CONTROLE_VENDA_GET_SALE_FROM_CANCEL
} from '../../store/reducers/controleVenda/controleVendaReducer';

import GridPagamento from './GridPagamento';
import I18n from '../UI/I18n/I18n';

/**
 * Exibe a tela de cancelamento de pagamento, com paginação, filtros e lista de pagamentos.
 */
class CancelBox extends React.Component {
    constructor() {
        log('CancelBox constructor');
        super();

        // Recupera e ajusta a URL para acesso aos dados
        this.urlDataBase = urlDatabase + '/pagamentoVendas/findToCancel';
    }

    /**
     * Retorna se o componente está montado no momento em que o método é chamado,
     * já que ele é passado por parâmetro para ser chamado depois.
     */
    getIsMounted = () => {
        log('CancelBox getIsMounted', { mounted: this.ismounted });
        return this.ismounted;
    }

    /**
     * Método executado APÓS montar o componente.
     */
    componentDidMount() {

        log('CancelBox componentDidMount', this.getIsMounted);

        this.ismounted = true;

        updateActiveMenuItem('menuItemCancelamento', 'menuItemVenda');

        // Carrega os últimos pagamentos
        this.props.cadastroLoadFromServer(this.props.page, getPageSize(), this.urlDataBase, this.getIsMounted, 'dataHora,DESC', null, null, 'pagamentoVenda');
    }

    /**
     * Método executado APÓS a montagem/renderização do componente.
     */
    componentDidUpdate() {
        log('CancelBox componentDidUpdate');
        // Se houve o cancelamento de um ou mais pagamentos da mesma venda, abre a manutenção desta venda.
        if (this.props.fromCallState === STATE_CONTROLE_VENDA_GET_SALE_FROM_CANCEL) {
            this.props.goTo('/controleVendas');
        }
    };

    /**
     * Método executado ANTES de "DESMONTAR" o componente.
     */
    componentWillUnmount() {

        log('CancelBox componentWillUnmount');

        this.ismounted = false;
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('CancelBox render');

        return <div id='cadastro-box' >

            {/* Header do box */}
            <div className='header breadCrumbs'>
                <h1>{I18n('saleCancellation')}</h1>
                <h2>{I18n('payments')}</h2>
            </div>

            {/* Conteúdo do box */}
            <div id='conteudo-box' >
                <GridPagamento
                    history={this.props.history}
                />
            </div>

        </div>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    page: state.cadastroReducer.page,
    fromCallState: state.controleVendaReducer.fromCallState
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    cadastroLoadFromServer: (page, pageSize, urlDataBase, isMounted, sortProperty, filterProperties, filter, objetoCadastroLast) =>
        dispatch(cadastroActions.cadastroLoadFromServer(page, pageSize, urlDataBase, isMounted, sortProperty, filterProperties, filter, objetoCadastroLast)),

    goTo: path => dispatch(push(path))
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(CancelBox);
