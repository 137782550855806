import React from 'react';
import { connect } from 'react-redux';

import { getAppUsuario } from "../../../utils/AppUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"
import { getURIFromEntity, getIdFromURI } from "../../../utils/URIUtils"
import { getApi } from "../../../utils/SecureConnectionUtils"
import getEndereco from '../../../utils/EnderecoUtils/getEndereco'

import * as appActions from '../../../store/actions/appAction';
import * as producaoActions from '../../../store/actions/producaoAction';

import BuildButton from '../../UI/Toolbar/BarraAcoesButton';
import { AiOutlinePrinter } from "react-icons/ai";

import {
    ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA,
    toDeliverOrCancelList,
    itemStateList,
    ESTADO_ITEM_VENDA_MOSTRAR_ROTA,
    itemStateListForDeliveryMan,
    ESTADO_ITEM_VENDA_PRODUZIDO,
    itemStateListNoDelivery,
    ESTADO_ITEM_VENDA_ENCERRADO
} from '../../../store/reducers/controleVenda/controleVendaReducer';

import Row from '../../vendas/grid/Row';

import "./DialogItemVenda.css";

const id_mapping = {
    TO_PRINT: 'print-item-button',
    EM_PRODUCAO: 'production-item-button',
    PRODUZIDO: 'ready-item-button',
    AGUARDANDO: 'waiting-item-button',
    MOSTRAR_ROTA: 'display-route-button',
};

const StateButton = (props) => {
    return <>
        <button
            {...props}
            className={`state-button blue-button font-color${props.className ? ` ${props.className}` : ''}`}
        />
    </>;
};

/**
 * Monta a dialog para exibição de observações do item venda e para alteração do estado do mesmo.
 */
class DialogVenda extends React.Component {

    buildButtonState = (buttonState, itemList) => itemList.forEach((item) => {

        let stateList = [];
        const endereco = getEndereco(item)

        if (itemList.filter((item) => item.estadoItensVenda !== ESTADO_ITEM_VENDA_ENCERRADO).length !== 0) {
            if (this.props.isEntregador) {
                stateList = toDeliverOrCancelList;
            }
            // listaSemVendasParaEntrega
            else if (itemList.filter((item) => item.estadoItensVenda !== ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA).length === 0 && endereco) {
                stateList = itemStateListForDeliveryMan;
            }
            // listaNaoPronta
            else if (!endereco || itemList.filter((item) => item.estadoItensVenda !== ESTADO_ITEM_VENDA_PRODUZIDO).length > 0) {
                stateList = itemStateListNoDelivery;
            }
            else {
                stateList = itemStateList;
            }
        }

        for (let state of stateList) {
            if (!buttonState[state]) {
                buttonState[state] = { itemList: [] };
            }
            buttonState[state].itemList.push(item);
        }
    });

    getObservacaoVenda = (venda) => {
        if (venda?.observacao) {
            if (venda?.entregaVenda?.observacao) {
                return venda.observacao + '\n' + venda.entregaVenda.observacao;
            }
            return venda.observacao;
        }
        else {
            return venda?.entregaVenda?.observacao;
        }
    };

    /**
     * Método que monta o componente.
     */
    render() {
        log('DialogVenda render', { tabIndex: this.props.tabIndex });

        let observacao = null;
        let observacaoItens = '';

        if (this.props.venda) {
            if (this.getObservacaoVenda(this.props.venda)) {
                observacao =
                    <>
                        <label>{getStrings().saleObservation}</label>
                        <span className="observacao-item-textarea" role="textbox" >{this.getObservacaoVenda(this.props.venda)}</span>
                    </>
            }

            this.props.venda.itemVendaList.forEach(item => item.observacao ? observacaoItens = observacaoItens.concat((item.nomeProduto || item.produto.nome) + ': ' + item.observacao + '\n\n') : '');

            if (observacaoItens) {
                observacaoItens =
                    <>
                        <label>{getStrings().itemsObservation}</label>
                        <span className="observacao-item-textarea" role="textbox" >{observacaoItens.trim()}</span>
                    </>
            }
        }

        let itemList = [];

        if (this.props.vendasList) {
            itemList.push(...this.props.vendasList);
        }
        else if (this.props.venda) {
            itemList.push(this.props.venda);
        }

        let buttonState = {};
        let buttons = [];

        this.buildButtonState(buttonState, itemList);

        Object.keys(buttonState).forEach((state) => {
            buttons.push(
                <StateButton
                    key={state}
                    type='button'
                    id={id_mapping[state]}
                    onClick={() => {
                        this.props.appDialogHide();
                        this.props.onDialogHide && this.props.onDialogHide();
                        if (state === ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA) {
                            this.props.loadDeliveryUserList(itemList, null, this.props.isEntregador ? getAppUsuario() : null);
                        }
                        else if (state === ESTADO_ITEM_VENDA_MOSTRAR_ROTA) {
                            this.props.mostrarRotaCallback();
                        }
                        else {
                            this.props.updateItemVenda(
                                { estado: state,
                                  vendaList: buttonState[state].itemList.map(item => getIdFromURI(getURIFromEntity(item)))
                                },
                                getApi('vendas/update'));
                        }
                    }}>
                    {getStrings().saleItemStateEnumToString(state)}
                </StateButton>
            );
        });

        // Ajuste para compatibilidade: gera um componente para cada 2.
        buttons = buttons.reduce((previous, current, index, array) => {
            // Pula os pares
            if ((index % 2) === 1) {
                return previous;
            }
            // Se houver um componente para fazer dupla com este,
            // gera um componente com este e o próximo.
            if (array[index + 1]) {
                return previous.concat(<Row key={index}>
                    {current}
                    {array[index + 1]}
                </Row>);
            }
            // Senão, gera um componente apenas com o atual
            // (deve ocorrer sempre no último se forem botões ímpares ou não ocorrer se forem botões pares).
            return previous.concat(<Row key={index}>
                {current}
            </Row>);
        }, []);

        const iconStyle = {width: 'auto', height: 'auto', maxWidth: '1em', maxHeight: '1em', verticalAlign: 'middle', paddingRight: '0.3em', paddingBottom: '0.1em'};

        // Monta o componente
        return <div className={isAncientChromeMobile ? 'ancient-chrome-mobile' : undefined} style={{marginBottom: '7px'}}>
            {observacao}
            {observacaoItens}
            <h2 className='ButtonAlign'>{getStrings().setOrderStateTo}</h2>
            <div className='dialog-horizontal-button-grid flow-buttons'>
                {buttons}
            </div>
            {!this.props.itemVendasList
            ? <div style={{marginTop: '1em'}}>
                <BuildButton
                    style={{marginTop: '0.5em', fontSize: '1em'}}
                    id="botao-imprimir"
                    key='select'
                    isPrimary={true}
                    commandMethod={() => {
                        this.props.appDialogHide();
                        this.props.onDialogHide && this.props.onDialogHide();
                        this.props.setPrinted(getURIFromEntity(this.props.venda.itemVendaList[0]), false, true);
                    }}
                    label={<AiOutlinePrinter style={iconStyle} />}
                    texto={getStrings().print}
                    title={getStrings().print} />
            </div>
            : null
            }
        </div>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    tabIndex: state.appReducer.getTabIndex(),
    agruparProdutosVenda: state.producaoReducer.agruparProdutosVenda,
    ...state.idiomaReducer,
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    appDialogShow: (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles) =>
        dispatch(appActions.appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles)),
    appDialogHide: () => dispatch(appActions.appDialogHide()),
    loadDeliveryUserList: (listItemVenda, listItemProducao, idEntregador) => dispatch(producaoActions.loadDeliveryUserList(listItemVenda, listItemProducao, idEntregador)),
    setPrinted: (itemVenda, printed, printOtherItems) => dispatch(producaoActions.setPrinted(itemVenda, printed, printOtherItems)),
    updateItemVenda: (formData, urlUpdate, urlParams) => dispatch(producaoActions.updateItemVenda(formData, urlUpdate, urlParams)),
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(DialogVenda);
