import React from "react"
import { connect } from "react-redux"
import { SkyLightStateless } from "react-skylight"

import { log } from "../../../utils/LogUtils"

import * as appActions from "../../../store/actions/appAction"

class DialogSystem extends React.Component {

  /**
   * Método que monta o componente.
   */
  render() {
    log("DialogSystem render")
    let content =
      (<div className="dialog-content" style={{ ...this.props.dialogContentStyles }} ref={ref => {
        if (ref) {
          this.notificationElement = ref.parentElement
          ref.parentElement.id = "Dialog"
          ref.parentElement.tabIndex = 0
        }
      }}>
        {this.props.dialogContent}
      </div>)

    // Monta o componente
    return <SkyLightStateless
      ref={ref => this.dialog = ref}
      closeButtonStyle={null}
      closeOnEsc={true}
      dialogStyles={{ ...this.props.dialogStyles }}
      isVisible={this.props.dialogShow}
      onCloseClicked={() => {
        if (this.props.dialogOnCloseClicked) {
          this.props.dialogOnCloseClicked()
        }
        this.props.appDialogHide()
      }}
      onOverlayClicked={() => {
        if (this.props.dialogOnOverlayClicked) {
          this.props.dialogOnOverlayClicked()
        }
        this.props.appDialogHide()
      }}
      title={this.props.dialogTitle}
      titleStyle={{ ...this.props.dialogTitleStyles }} >
      {content}
    </SkyLightStateless >
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  dialogShow: state.appReducer.dialogShow,
  dialogTitle: state.appReducer.dialogTitle,
  dialogContent: state.appReducer.dialogContent,
  dialogStyles: state.appReducer.dialogStyles,
  dialogContentStyles: state.appReducer.dialogContentStyles,
  dialogTitleStyles: state.appReducer.dialogTitleStyles,
  dialogOnCloseClicked: state.appReducer.dialogOnCloseClicked,
  dialogOnOverlayClicked: state.appReducer.dialogOnOverlayClicked
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  appDialogHide: () => dispatch(appActions.appDialogHide())
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(DialogSystem)
