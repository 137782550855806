import React from "react";
import styled from "styled-components";
import { TitleSub, Paragraph, ConteudoSection } from ".";
import { PATH_IMAGENS_FOTOS, PATH_IMAGENS_PRINTS } from "../utils/resources";
import { ImagensContainer, PrintSmartphone, ImgRounded } from "./Containers/";

const AtendimentoSalao = React.forwardRef((props, ref) => {
  return (
    <ConteudoSection ref={ref}>
      <TitleSub primary>Atendimento no Salão</TitleSub>
      <Paragraph>Atendimento no balcão/frente de caixa - Aplicativos para atendimento no salão, garçom, monitores...</Paragraph>
      <ImagensContainer>
        <PrintTest src={PATH_IMAGENS_PRINTS + "atendimento1.png"} alt={"atendimento"} />
        <PrintTest src={PATH_IMAGENS_PRINTS + "atendimento2.png"} alt={"atendimento2"} />

        <ImgCliente src={PATH_IMAGENS_FOTOS + "atendimento3.png"} alt={"Tela instagram"} />
        <ImgGarcom src={PATH_IMAGENS_FOTOS + "atendimento4.png"} alt={"Pessoa usando mobi"} />
      </ImagensContainer>
    </ConteudoSection>
  );
});

const PrintTest = styled(PrintSmartphone)`
  position: absolute;
  &:nth-child(1) {
    bottom: 0;
    z-index: 10;
  }
  &:nth-child(2) {
    top: 0;
    right: 0;
  }
  @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    &:nth-child(1) {
      top: 0;
      right: 150px;
    }
    &:nth-child(2) {
      top: 50px;
      right: 75px;
      z-index: 10;
    }

    @media (max-width: ${(props) => props.theme.sizes.breakPointSmall}) {
      height: 250px;
      &:nth-child(1) {
        top: 0;
        left: 30px;
      }
      &:nth-child(2) {
        top: 50px;
        right: 30px;
        z-index: 10;
      }
    }
  }
`;

const ImgGarcom = styled(ImgRounded)`
  left: 75px;
  bottom: 0;
  z-index: 10;
`;

const ImgCliente = styled(ImgRounded)`
  left: 0;
  t bottom: 0;
  z-index: 12;
  @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    left: 0;
    bottom: 60px;
  }
`;

export default AtendimentoSalao;
