import styled from "styled-components";

export const Title = styled.h1`
  padding: 1rem 2rem;
  font-size: 2em;
  line-height: 44px;
  margin-top: 24px;
  max-width: 90vw;
  color: ${(props) => (props.primary ? props.theme.textColor : props.theme.secondTextColor)};
  text-align: center;
  font-weight: 700;
  @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    font-size: 1.5em;
    line-height: 28px;
    margin-bottom: 0;
  } ;
`;

export const TitleSub = styled(Title)`
  font-size: 1.7em;
  @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    font-size: 1.4em;
  } ;
`;
