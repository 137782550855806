import React from 'react';

import { connect } from 'react-redux';

import { appDialogShow } from '../../../store/actions/appAction';

import { getURIFromEntity } from '../../../utils/URIUtils';

import CardPedidoProducao from './CardPedidoProducao';

class GridPedidosProducao extends React.Component {
    enderecos = [];

    render() {
        return <div className={`pure-g`}>
            {this.props.vendaList.map(
                (venda, index) => {
                    return <CardPedidoProducao
                        key={index}
                        venda={venda}
                        vendaId={getURIFromEntity(venda)}
                        modoSelecao={this.props.modoSelecao}
                        selecionados={this.props.selecionados}
                        mostrarRotaCallback={() => this.props.mostrarRotaCallback(venda)}
                        atualizarSelecao={this.props.atualizarSelecao}
                        isEntregador={this.props.isEntregador}
                    />;
                }
            )
            }
        </div>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * 
 * @param {Object} state 
 */
const mapStateToProps = state => ({
    ...state.idiomaReducer,
    ...state.producaoReducer,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    appDialogShow:  (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles) =>
        dispatch(appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles)),
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(GridPedidosProducao);
