import styled from "styled-components";
import { displayFlexCenter } from "../../css/styles";

export const InputContainer = styled.div`
  ${displayFlexCenter}
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  margin-bottom: 1em;
  @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    width: 100%;
  }
`;
