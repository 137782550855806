import React from "react"
import { connect } from "react-redux"

import * as AuthUtils from "../../../utils/AuthUtils"
import { log } from "../../../utils/LogUtils"
import { formatNumber } from "../../../utils/NumberUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"

import * as collectorAction from "../../../store/actions/collectorAction"

import Icones from "../../../assets/icones/Icones"
import InputCustomizado from "../../UI/Input/InputCustomizado"

import "./BuildCellFromCollectorProduct.css";

/**
 * Função que monta a célula a partir do produto coletado ou filtrado.
 */
class BuildCellFromCollectorProduct extends React.Component {

    /**
     * Retorna o método para ajustar quantidade dependendo em qual tabela está este componente.
     */
    adjustAmount = () => this.props.filtered ? this.props.adjustFilteredAmount : this.props.adjustCollectedAmount

    /**
     * Retorna a quantidade atual do produto ou a quantidade persistida.
     */
    getAmount = () => (this.props.collectorProduct.amount || (this.props.collectorProduct.amount === 0))
        ? this.props.collectorProduct.amount : this.props.collectorProduct.persistedAmount

    /**
     * Não permite alterar a quantidade se produto foi inserido através de leitor de código de barras.
     */
    lockSpinner = () => this.props.collectorProduct.fromBarQRCode

    /**
     * Método executado APÓS a montagem/renderização do componente.
     * Atualiza o campo de quantidade.
     */
    componentDidMount() {
        this.componentDidUpdate()
    }

    /**
     * Método executado APÓS a atualização do componente.
     * Atualiza o campo de quantidade.
     */
    componentDidUpdate() {
        if (this.amount) {
            this.amount.setMaskValue(this.getAmount())
        }
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log("BuildCellFromCollectorProduct render")

        const GoTrashcan = Icones.go.GoTrashcan

        /**
         * Identifica o `input` de quantidade unicamente para tratamento de seleção automática.
         */
        let id = `${this.props.collectorProduct.produtoURI}/amountInputField/${this.props.filtered ? "filtered" : "collected"}`

        /**
         * Se o cargo possui permissão para remover itens de venda.
         */
        let removalAllowed = this.props.filtered || AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_EXCLUSAO])

        return <div className="table-collector table-row" >
            {/* Botão de exclusão (lixeira) */}
            <div className="table-cell del ButtonAlign" >
                <button
                    tabIndex={this.props.tabIndex}
                    className="grid-button table-button"
                    onClick={() => {
                        if (removalAllowed) {
                            this.props.removeProduct(this.props.collectorProduct, this.props.filtered, this.props.clienteURI, this.props.vendaURI)
                        }
                    }}
                    {...(removalAllowed ? {} : {
                        style: {
                            visibility: "hidden"
                        }
                    })}
                >
                    <GoTrashcan />
                </button>
            </div>
            {/* Nome do produto */}
            <div className="table-cell product TextAlign" >
                {this.props.collectorProduct.name}
            </div>
            {/* Preço do produto */}
            <div className="table-cell price NumberAlign" >
                {formatNumber(this.props.collectorProduct.price, 2)}
            </div>
            {/* Spinner de quantidade */}
            <div className={"table-cell ButtonAlign"} >
                {/* Botão de decrementar quantidade */}
                {this.lockSpinner() ? null : <button
                    tabIndex={this.props.tabIndex}
                    className="pure-u-6-24 button grid-button decrementa"
                    onClick={() => this.adjustAmount()(this.props.collectorProduct.produtoURI, -1)}
                >−</button>}
                {/* Exibe um campo de texto para inserir a quantidade ou somente exibe a quantidade. */}
                <div className={`pure-u-${this.lockSpinner() ? 24 : 12}-24 quantidade`} >
                    {/* Verifica se o spinner está travado para somente exibir a quantidade. */}
                    {this.lockSpinner() ? this.getAmount() : <InputCustomizado
                        ref={input => { if (input) this.amount = getReduxWrappedComponent(input) }}
                        id={id}
                        inputType="masked"
                        validacaoDados="numeroInteiro"
                        type="text"
                        name="amountInputField"
                        scale={0}
                        max={1000000000000}
                        padFractionalZeros={false}
                        className={`amountInputField Lighter${(!this.props.filtered) && this.props.collectorProduct.amountChanged ? " quantidadeAlterada" : ""}`}
                        // Seleciona todo o conteúdo do campo ao pressionar o mesmo
                        onFocus={() => document.getElementById(id).select()}
                        // Atualiza a quantidade do produto no estado a cada dígito digitado, a menos que o campo contenha um número inválido.
                        onInput={() => {
                            if (this.amount.getMaskValue() || (this.amount.getMaskValue() === 0)) {
                                this.adjustAmount()(this.props.collectorProduct.produtoURI, null, this.amount.getMaskValue())
                            }
                        }}
                        // Ao sair do campo, se ele possui um valor inválido ou estiver vazio, altera a quantidade do produto para zero.
                        // Isso não pode ser feito antes, senão atrapalha a digitação do campo.
                        onBlur={() => {
                            if ((!this.amount.getMaskValue()) && (this.amount.getMaskValue() !== 0)) {
                                this.adjustAmount()(this.props.collectorProduct.produtoURI, null, 0)
                            }
                        }}
                    />}
                </div>
                {/* Botão de incrementar quantidade */}
                {this.lockSpinner() ? null : <button
                    tabIndex={this.props.tabIndex}
                    className="pure-u-6-24 button grid-button incrementa"
                    onClick={() => this.adjustAmount()(this.props.collectorProduct.produtoURI, +1)}
                >+</button>}
            </div>
            {/* Valor total */}
            <div className="table-cell total NumberAlign" >
                {formatNumber(this.props.collectorProduct.total, 2)}
            </div>
        </div>
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    tabIndex: state.appReducer.getTabIndex()
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    adjustCollectedAmount: (produtoURI, increment, absolute) => dispatch(collectorAction.adjustCollectedAmount(produtoURI, increment, absolute)),
    adjustFilteredAmount: (produtoURI, increment, absolute) => dispatch(collectorAction.adjustFilteredAmount(produtoURI, increment, absolute)),
    removeProduct: (collectorProduct, filtered, clienteURI, vendaURI) => dispatch(collectorAction.removeProduct(collectorProduct, filtered, clienteURI, vendaURI))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(BuildCellFromCollectorProduct)
