import { log } from './LogUtils';
import { isNonEmptyObject, isStrictObject, isString, isNumber } from './ComparatorsUtils';
import { ascendingSort } from './SortUtils';
import { removeProperties } from './ObjectUtils';
import { barCodeFormatList } from '../store/actions/controleVenda/controleVendaAction';
import { getIdFromURI, getURIFromEntity } from './URIUtils';

/**
 * Gera uma nova lista sem os formatos com ordem 0, colocando os formatos em ordem, trocando o nome do formato pelo nome usado para configurar o leitor.
 * @param {Record<string, unknown> | undefined} ordemFormatoCodigoBarras 
 * @returns 
 */
export const barCodeFormatOrder = (ordemFormatoCodigoBarras = undefined) => {
    if (!isNonEmptyObject(ordemFormatoCodigoBarras)) {
        return null;
    }

    return Object.keys(ordemFormatoCodigoBarras).filter(key => ordemFormatoCodigoBarras[key] > 0)
    .sort((a, b) => ascendingSort(ordemFormatoCodigoBarras[a], ordemFormatoCodigoBarras[b]))
    .map(key => (barCodeFormatList.find(format => format.persistenceName === key) || {reader:() => undefined}).reader());
};

/**
 * Se houver, retorna `object._embedded`. Se não, retorna `null`.
 * @param {Object} object objeto possível de possuir `_embedded`
 */
export const getEmbedded = object => {
    log('utils getEmbedded', object);
    
    if (isNonEmptyObject(object)  && '_embedded' in object) {
        return object._embedded;
    }

    return null;
}

/**
 * 
 * @param {Object} object 
 */
export const buildMatrixVariables = object => {
    log('utils buildMatrixVariables', object);

    if(!isStrictObject(object)) {
        return null;
    }

    removeProperties(object, [undefined, null]);

    let matrixVariables = '';

    Object.keys(object).filter(key => (((!object[key].length) && (object[key].length !== 0)) || (object[key].length > 0))).forEach((key, index) => {
        if (index > 0) {
            matrixVariables += ';';
        }
        matrixVariables += key + '=' + object[key];
    });

    return matrixVariables;
}

/**
 * Busca em uma `string` que representa um SVG a primeira instância de valores de largura e altura.
 * @param {String} svg 
 */
export const extractWidthAndHeightFromSVGString = svg => {
    if (!isString(svg)) {
        return {width:"", height:""};
    }

    // Busca a primeira aparição de largura
    let index = svg.indexOf('width');
    // Busca as aspas que antecedem o valor
    let openingQuoteIndex = svg.indexOf('"', index);
    // Busca as aspas que sucedem o valor
    let closingQuoteIndex = svg.indexOf('"', openingQuoteIndex + 1);
    // Busca o valor da largura entre as aspas
    let width = svg.substring(openingQuoteIndex + 1, closingQuoteIndex);
    // Busca a primeira aparição de altura
    index = svg.indexOf('height');
    // Busca as aspas que antecedem o valor
    openingQuoteIndex = svg.indexOf('"', index);
    // Busca as aspas que sucedem o valor
    closingQuoteIndex = svg.indexOf('"', openingQuoteIndex + 1);
    // Busca o valor da altura entre as aspas e retorna os valores
    return { width, height: svg.substring(openingQuoteIndex + 1, closingQuoteIndex) };
}

/**
 * 
 * @param {Object} obj 
 * @param {String} key 
 * @param {String} type 
 * @param {any} optionalArgument 
 */
export const setOptionalArgument = (obj, key, type, optionalArgument) => {
    return obj
        ? typeof obj[key] === type
            ? obj[key]
            : optionalArgument
        : optionalArgument;
};

/**
 * Retorna o caminho completo de imagem de produto
 * 
 * @param {String} empresaId
 * @param {Object} produto
 * 
 * @returns {String} imagePath
 */
export const getProductImagePath = (empresaId, produto) => {
    if (!produto.imagemFile) {
        return null;
    }

    const produtoId = getIdFromURI(getURIFromEntity(produto));
    const imagePath = `images/${empresaId}/${produtoId}?v=${produto.imagemVersion || '0'}`;

    return imagePath;
}

export const pxToCm = px => {
    const pxNumber = isNumber(px) ? px : isString(px) ? parseFloat(px) : NaN;
    return pxNumber * 127 / 4800;
};

export const getNomeProdutoFromItemVenda = itemVenda => itemVenda.nomeProduto || itemVenda.produto?.nome;

export function debugging() {
    const debuggingString = sessionStorage.getItem('debugging');
    const debugging = debuggingString === 'true';

    if (debugging) {
        debugger;
    }
}