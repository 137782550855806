import React from 'react'

import { connect } from 'react-redux';

import { setLayoutIdAction } from '../../../store/actions/placeholderMessageAction';
import { updateFullScreenAction } from '../../../store/actions/appAction';
import { messageLayoutIds } from '../../../store/reducers/placeholderMessageReducer';

class ShowWelcomeMessage extends React.Component {
    componentDidMount() {
        this.props.setLayoutId(((this.props.location || {}).state || {}).layoutId);
        this.props.updateFullScreen(true);
    }

    componentWillUnmount() {
        this.props.updateFullScreen(false);
    }

    render() {
        return <div className={`no-user-select`} unselectable={`on`} style={{...this.props.welcomeMessage.style, ...this.props.fullScreenStyle}}>
            <h1>{this.props.welcomeMessage.text.value}</h1>
        </div>
    }
}

const mapStateToProps = state => ({
    fullScreenStyle: state.appReducer.fullScreenStyle.content,
    welcomeMessage: state.placeholderMessageReducer.data[messageLayoutIds.welcomeMessage],
});

const mapDispatchToProps = dispatch => ({
    setLayoutId:        (layoutId) => dispatch(setLayoutIdAction(layoutId)),
    updateFullScreen: (fullScreen) => dispatch(updateFullScreenAction(fullScreen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowWelcomeMessage);
