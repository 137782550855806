import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as appActions from '../../../store/actions/appAction';

/**
 * Link que, quando clicado, chama o método de esconder o *spinner*. Usado para esconder o spinner quando o usuário trocar de tela.
 */
class LinkHide extends React.Component {

    render() {

        let { appSpinnerHide, onClick, ...otherProps } = this.props;

        return <Link {...otherProps} onClick={() => {
            // Oculta o spinner quando o usuário for para outra tela, caso alguma operação na tela atual esteja demorando demais.
            // É feito o teste se o caminho da tela mudou porque, ao clicar para ir para a mesma tela onde já está, este método é disparado.
            if (window.location.pathname !== this.props.to) {
                this.props.appSpinnerHide();
            }
            this.props.onClick();
        }}>{this.props.children}</Link>;
    }
}

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        appSpinnerHide: () => dispatch(appActions.appSpinnerHide())
    };
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(null, mapDispatchToProps)(LinkHide);
