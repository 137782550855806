import styled from "styled-components";

export const Input = styled.input.attrs((props) => ({
  type: props.type || 'text',
}))`
  position: relative !important;
  width: 100% !important;
  border-radius: 5px !important;
  border: 2px solid #cecdcd !important;
  background: white !important;
  height: 50px !important;
  padding: 0.4em !important;
  font-weight: 500 !important;
  &::placeholder {    
    color: rgb(117,117,117) !important;
    filter: unset !important;
    font-size: 13.3333px !important;
    font-weight: 500 !important;
    opacity: 1 !important;
  }
`;

export const EmailInput = styled(Input).attrs((props) => ({
  type: "email",
}))``;

export const TextInput = styled(Input).attrs((props) => ({
  type: "text",
}))``;
