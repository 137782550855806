import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { getApi } from "../../../utils/SecureConnectionUtils"
import { getURIFromEntity, getIdFromURI } from "../../../utils/URIUtils"

import * as appActions from "../../../store/actions/appAction"
import * as producaoActions from "../../../store/actions/producaoAction"
import { ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA } from "../../../store/reducers/controleVenda/controleVendaReducer"

import InputCustomizado from "../../UI/Input/InputCustomizado"
import { Button } from "../../UI/Button/Button"

const ConfirmButton = customProps => {
  const props = { ...customProps, reference: undefined }

  return <>
    <Button
      {...props}
      ref={customProps.reference}
      className={`blue-button font-color${props.className ? ` ${props.className}` : ""}`}
    />
  </>
}

/**
 * Classe que monta o *dialog* para usuário escolher entregador que fará entrega dos produtos ao cliente.
 */
class DialogEntregador_ extends React.Component {

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  componentDidMount() {
    log("DialogEntregador componentDidMount")
    this.componentDidUpdate()
  }

  /**
   * Método executado APÓS a atualização do componente.
   */
  componentDidUpdate() {
    log("DialogEntregador componentDidUpdate")
    if (this.confirmButton) {
      // Desabilita o botão depois da tela montar. Se colocar disabled no render, nunca mais fica enabled.
      this.confirmButton.disabled = true
    }
    this.radioList
      .filter(radioComponent => radioComponent && radioComponent.inputComponent)
      .forEach(radioComponent => radioComponent.inputComponent.checked = false)
  }

  /**
   * Método que monta o componente.
   */
  render() {
    log("DialogEntregador render")

    // Inicializa a variável para guardar a referência de cada item da lista
    this.radioList = []

    // Monta a lista de origens de venda com check box
    let deliveryUserGrid = this.props.deliveryUserList.map(usuario => {

      let deliveryUserURI = getURIFromEntity(usuario)

      let name = "radioDelivery"

      let id = `${name}${((deliveryUserURI || "").split("/api/usuarios/") || [])[1] || ""}`

      return <InputCustomizado
        key={deliveryUserURI}
        id={id}
        type="radio"
        name={name}
        label={getStrings().userNamePhoneTemplate(usuario.nome, usuario.sobrenome, (usuario.dadosAdicionais || {}).telefone)}
        ref={ref => {
          if (ref) {
            // adiciona no item uma referência para o usuário
            getReduxWrappedComponent(ref).deliveryUser = deliveryUserURI
            // guarda a referência do item
            this.radioList.push(getReduxWrappedComponent(ref))
          }
        }}
        handleInputValidado={() => {
          if (this.confirmButton && this.radioList) {
            this.confirmButton.disabled = this.radioList.filter(radioComponent =>
              radioComponent.inputComponent.checked).length === 0
          }
        }}
        tabIndex={(this.props.tabIndex + 1) * -1}
      />
    })

    // Monta o componente
    return <>
      <div className="dialog-scroll-pane">
        {/* entregadores */}
        <div className="table" >
          {deliveryUserGrid}
        </div>
      </div>
      {/* botão */}
      <div className="dialog-horizontal-button-grid">
        <ConfirmButton reference={ref => this.confirmButton = ref} type="button"
          onClick={() => {
            this.props.appDialogHide()
            // Busca o entregador selecionado
            let deliveryUser = this.radioList
              .filter(radioComponent => radioComponent.inputComponent.checked)
              .map(radioComponent => radioComponent.deliveryUser)[0] || ""
            if (this.props.listItemProducao) {
              this.props.updateItemVenda({
                estado: ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA,
                entregador: deliveryUser,
                itemProducaoList: this.props.listItemProducao.map(item => getIdFromURI(getURIFromEntity(item)))
              }, getApi("itemProducao/update"))
            }
            else {
              if (this.props.showProduced) {
                // Atualiza a venda
                this.props.updateItemVenda({
                  estado: ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA,
                  entregador: deliveryUser,
                  vendaList: this.props.listItemVenda.map(item => getIdFromURI(getURIFromEntity(item)))
                }, getApi("vendas/update"))
              }
              else {
                // Atualiza o item de venda
                this.props.updateItemVenda({
                  estado: ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA,
                  entregador: deliveryUser,
                  itemVendaList: this.props.listItemVenda.map(item => getIdFromURI(getURIFromEntity(item)))
                }, getApi("itemVendas/update"))
              }
            }
          }} >{getStrings().confirm}</ConfirmButton>
      </div>
    </>
  }
}

DialogEntregador_.propTypes = {

  /**
   * Lista de usuários com cargo com permissão de entrega.
   */
  deliveryUserList: PropTypes.array
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  tabIndex: state.appReducer.getTabIndex(),
  ...state.idiomaReducer,
  dialogShow: state.appReducer.dialogShow,
  showProduced: state.producaoReducer.showProduced,
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  appDialogHide: () => dispatch(appActions.appDialogHide()),
  updateItemVenda: (formData, urlUpdate, urlParams) => dispatch(producaoActions.updateItemVenda(formData, urlUpdate, urlParams))
})

const DialogEntregador = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DialogEntregador_)

/**
 * Exporta o último argumento entre parênteses.
 */
export default DialogEntregador
