export type tipoAcao = {
  type: string,
  carga: any
}

const acaoTemplate = (carga: any, tipo: string): tipoAcao => ({
  type: tipo,
  carga
});

export type acaoNomeadaTipo<reducerTipo> = (carga: any, estadoAtual: reducerTipo) => reducerTipo

export type acoesNomeadasTipo<reducerTipo> = {
  [x: string]: acaoNomeadaTipo<reducerTipo>,
}

export type dispatchTipo = (acao: any) => any;

export default acaoTemplate;