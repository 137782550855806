import React from "react"
import { connect } from "react-redux"
import * as AuthUtils from "../../../utils/AuthUtils"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"

import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"

/**
 * Componente que monta a célula a partir da venda.
 */
const BuildCellFromVenda = (props) => {
  const handleClick = (evt) => {
    log("BuildCellFromVenda handleClick")

    evt.preventDefault()
    props.exibeManutencaoVenda(props.venda)
  }

  const mapKeysToActions = {
    "Enter": handleClick,
    " ": (evt) => {
      evt.preventDefault()
      return handleClick(evt)
    },
  }

  const handleKey = (evt) => {

    if (evt.target.className.includes("card")) {
      try {
        mapKeysToActions[evt.key](evt)
      } catch (err) { }
    }
  }

  const render = () => {
    log("BuildCellFromVenda render")

    /**
     * Se o cargo possui permissão para entrar na venda.
     */
    let enterVendaAllowed = AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_EXCLUSAO, AuthUtils.PERMISSAO_VENDA_ENVIO, AuthUtils.PERMISSAO_VENDA_PAGAMENTO])

    return <>
      <div className="pure-u-1 pure-u-sm-1-2 pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-4" >
        <div tabIndex={props.tabIndex} className={`${enterVendaAllowed ? "clickable " : ""} card card-container`} onClick={enterVendaAllowed ? handleClick : () => { }} onKeyDown={handleKey}>
          <div className="card-content">
            <h1 className="ButtonAlign">{props.venda.nome}</h1>
            <h2 className="ButtonAlign">{getStrings().currencyValue(props.venda.valorTotal)}</h2>
            <h3 className="ButtonAlign">{getStrings().peopleAmountTemplate(props.venda.numeroPessoas)}</h3>
          </div>
        </div>
      </div>
    </>
  }

  return render()
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {
  return {
    ...state.idiomaReducer,
    tabIndex: state.appReducer.getTabIndex(),
  }
}

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
  return {
    exibeManutencaoVenda: venda => dispatch(controleVendaActions.exibeManutencaoVenda(venda))
  }
}

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(BuildCellFromVenda)
