import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../../../utils/LocaleUtils"
import { getReduxWrappedComponent } from "../../../../../utils/reduxUtils/reduxUtils"
import InputCustomizado from "../../../../UI/Input/InputCustomizado"

import { RamoEmpresa } from "../../../../../store/actions/empresaSelectorAction"
import { dispatchTipo } from "../../../../../store/actions/acaoTemplate"
import { STATE_CADASTRO_EMPRESA_EMPRESA } from "../../../../../store/reducers/cadastroEmpresaReducer"
import { cadastroEmpresaEUsuarioSaveData } from "../../../../../store/actions/cadastroEmpresaAction"
import { areNumericInputValuesEqual } from "../../../../../utils/ObjectUtils"

export interface SignUpCompanyContactStepType {
  updateReducer: () => void
}

interface SignUpCompanyContactStepTypeWithReducer extends SignUpCompanyContactStepType {
  contatoEmpresa: any
  empresaStep: number
  parametrosEmpresa: any
  reducerEmpresa: Record<string, any>
  updateEmpresa: (dados: any) => void
}

class SignUpCompanyContactStep_ extends React.Component<SignUpCompanyContactStepTypeWithReducer> {
  email: any = undefined
  telefone: any = undefined
  nomeContato: any = undefined
  percentualComissao: any = undefined
  precoLivre: any = undefined

  updateEmpresa = (dados: any, name: string) => {
    this.props.updateEmpresa({
      [name]: {
        // @ts-ignore
        ...this.props[name],
        ...dados,
      }
    });
  } 

  updateReducer = (key: string, dados: any) => {
    this.updateEmpresa({
      [key]: dados,
    }, "contatoEmpresa");
  }

  componentDidUpdate() {
    if ((this.props.contatoEmpresa.email || "") !== this.email.inputComponent.value) {
      this.email.inputComponent.value = this.props.contatoEmpresa.email;
    }

    if ((this.props.contatoEmpresa.telefone || "") !== this.telefone.inputComponent.value) {
      this.telefone.inputComponent.value = this.props.contatoEmpresa.telefone;
    }

    if ((this.props.contatoEmpresa.nomeContato || "") !== this.nomeContato.inputComponent.value) {
      this.nomeContato.inputComponent.value = this.props.contatoEmpresa.nomeContato;
    }

    if (!areNumericInputValuesEqual(this.percentualComissao.getMaskValue(), this.props.parametrosEmpresa?.percentualComissao)) {
      this.percentualComissao.setMaskValue(this.props.parametrosEmpresa?.percentualComissao);
    }

    if (this.props.reducerEmpresa?.ramoEmpresa === RamoEmpresa.RESTAURANTE_A_KILO
      && !areNumericInputValuesEqual(this.precoLivre.getMaskValue(), this.props.parametrosEmpresa?.precoLivre)) {
      this.precoLivre.setMaskValue(this.props.parametrosEmpresa?.precoLivre);
    }
  }

  render() {
    return <>
      { /* Contato Empresa */}
      <div id='campos-contato' className={`${this.props.empresaStep !== 2 ? "hidden" : ""}`}>
        
        <h2>{`${getStrings().company} - ${getStrings().contact}`}</h2>

        <InputCustomizado
          ref={(input: any) => { if (input !== null) { this.email = getReduxWrappedComponent(input); } }}
          placeholder={getStrings().emailPlaceholderTemplate(getStrings().companySubPlaceholder)}
          maxLength='60'
          id='emailContato'
          type='email'
          name='emailContato'
          required={true}
          label={getStrings().emailRequired}
          handleInputValidado={(dados) => this.updateReducer("email", dados)}
        />

        <InputCustomizado
          ref={(input: any) => { if (input !== null) { this.telefone = getReduxWrappedComponent(input); } }}
          placeholder={getStrings().telephonePlaceholderTemplate(getStrings().companySubPlaceholder)}
          maxLength='30'
          id='telefone'
          type='text'
          name='telefone'
          required={true}
          label={getStrings().telephoneRequired}
          handleInputValidado={(dados) => this.updateReducer("telefone", dados)}
          autoComplete='nah'
        />

        <InputCustomizado
          ref={(input: any) => { if (input !== null) { this.nomeContato = getReduxWrappedComponent(input); } }}
          placeholder={getStrings().contactNamePlaceholderTemplate(getStrings().companySubPlaceholder)}
          maxLength='60'
          id='nomeContato'
          type='text'
          name='nomeContato'
          required={true}
          label={getStrings().contactNameRequired}
          handleInputValidado={(dados) => this.updateReducer("nomeContato", dados)}
          autoComplete='nah'
        />
      </div>

      { /* Parâmetros Empresa */}
      <div id='campos-parametros' className={`sub-form ${this.props.empresaStep !== 2 ? "hidden" : ""}`}>

        <h2>{`${getStrings().company} - ${getStrings().parameters}`}</h2>

        <InputCustomizado
          ref={(input: any) => { if (input !== null) { this.percentualComissao = getReduxWrappedComponent(input); } }}
          placeholder={getStrings().commissionPercentPlaceholder()}
          id='percentualComissao'
          inputType='masked'
          validacaoDados='numeroDecimal'
          scale={5}
          max={100}
          type='text'
          name='percentualComissao'
          label={getStrings().commissionPercent}
          handleInputValidado={() => this.updateEmpresa({percentualComissao: this.percentualComissao.getMaskValue()}, "parametrosEmpresa")}
          autoComplete='nah'
        />

        {this.props.reducerEmpresa && this.props.reducerEmpresa.ramoEmpresa === RamoEmpresa.RESTAURANTE_A_KILO ?
          <InputCustomizado
            ref={(input: any) => { if (input)  { this.precoLivre = getReduxWrappedComponent(input); } }}
            placeholder={getStrings().allYouCanEatPlaceholder}
            id='precoLivre'
            inputType='masked'
            validacaoDados='numeroDecimal'
            max={100000000}
            scale={2}
            type='text'
            name='precoLivre'
            label={getStrings().allYouCanEatPrice}
            handleInputValidado={() => {this.updateEmpresa({precoLivre: this.precoLivre.getMaskValue()}, "parametrosEmpresa")}}
            autoComplete='nah'
          /> : null}
      </div>
    </>
  }
}

function mapStateToProps(state: any) {
  const props = {
    contatoEmpresa: state.cadastroEmpresaReducer.cadastroEmpresaDados.empresa.contatoEmpresa || {},
    empresaStep: state.cadastroEmpresaReducer.step,
    parametrosEmpresa: state.cadastroEmpresaReducer.cadastroEmpresaDados.empresa.parametrosEmpresa || {},
    reducerEmpresa: state.cadastroEmpresaReducer.cadastroEmpresaDados.empresa,
  };

  return props;
}

function mapDispatchToProps(dispatch: dispatchTipo) {
  const props = {
    updateEmpresa: (dados: any) =>
      dispatch(cadastroEmpresaEUsuarioSaveData(STATE_CADASTRO_EMPRESA_EMPRESA, dados)),
  };

  return props;
}

export const SignUpCompanyContactStep = connect(mapStateToProps, mapDispatchToProps)(SignUpCompanyContactStep_)