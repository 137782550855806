import { updateObject } from "../../utils/ObjectUtils"

import * as actionTypes from "../actions/actionTypes"

/**
 * Estado Inicial, obrigatório no Reducer.
 */
export const initialState = {

  /**
   * Nome fantasia da empresa.
   */
  name: ""

  /**
   * Origem de venda de onde o código foi lido.
   */
  , origemVenda: null
}

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.COMPANY_INFORMATION:
      // Basta controlar a construção do action no outro arquivo.
      return updateObject(state, {
        ...state
        , ...action
      })
    default: return state
  }
}

export default reducer
