import React from 'react';
import { connect } from 'react-redux';

import { isEmpresaPainel as util_isEmpresaPainel } from "../../utils/CompanyUtils"
import { formatNumber } from "../../utils/NumberUtils"
import { log } from "../../utils/LogUtils"
import { listToFragment } from "../../utils/ScreenUtils"

// Chaves das colunas
import {
    PAGAMENTO_VENDA_ORIGEM,
    PAGAMENTO_VENDA_VALOR_PRODUTOS,
    PAGAMENTO_VENDA_VALOR_COMISSAO,
    PAGAMENTO_VENDA_VALOR_TOTAL,
    PAGAMENTO_VENDA_VENDA
} from './RelatorioBox';

/**
 * Classe que realiza a montagem das linhas da tabela.
 */
class FechamentoTableRow extends React.Component {

    /**
     * Monta cada célula de acordo com a chave da coluna.
     */
    cellData = column => {
        log('FechamentoTableRow cellData', column);
        switch (column) {
            case PAGAMENTO_VENDA_ORIGEM:
                return <td className='Lighter' width='18%' >{this.props.dadosMap[PAGAMENTO_VENDA_ORIGEM]}</td>;
            case PAGAMENTO_VENDA_VENDA:
                return <td className={`Lighter ${this.isEmpresaPainel() ? 'NumberAlign' : 'TextAlign'}`} width='18%' >{this.props.dadosMap[PAGAMENTO_VENDA_VENDA]}</td>;
            case PAGAMENTO_VENDA_VALOR_PRODUTOS:
                return <td className='Lighter NumberAlign' width='20%' >{formatNumber(this.props.dadosMap[PAGAMENTO_VENDA_VALOR_PRODUTOS], 2)}</td>;
            case PAGAMENTO_VENDA_VALOR_COMISSAO:
                return <td className='Lighter NumberAlign' width='24%' >{formatNumber(this.props.dadosMap[PAGAMENTO_VENDA_VALOR_COMISSAO], 2)}</td>;
            case PAGAMENTO_VENDA_VALOR_TOTAL:
                return <td className='Lighter NumberAlign' width='20%' >{formatNumber(this.props.dadosMap[PAGAMENTO_VENDA_VALOR_TOTAL], 2)}</td>;
            default:
                return null;
        }
    }

    isEmpresaPainel = () => util_isEmpresaPainel(this.props.ramoEmpresa);

    /**
     * Método que executa a montagem do componente.
     */
    render() {
        log('FechamentoTableRow render');

        // Se não recebeu as colunas por parâmetro, não pode montar a linha da tabela
        if (!this.props.columnOrder) {
            return null;
        }

        return <tr>
            {/* Dados do relatório */}
            {listToFragment(this.props.columnOrder.map(column => this.cellData(column)))}
        </tr>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    ...state.relatorioReducer,
    ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(FechamentoTableRow);
