import React from "react";
import { connect } from "react-redux";

import { getAppEmpresa } from "../../../utils/AppUtils";
import { equalsCoerced } from "../../../utils/ComparatorsUtils";
import { getStrings } from "../../../utils/LocaleUtils";
import { log } from "../../../utils/LogUtils";
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils";
import * as cadastroActions from "../../../store/actions/cadastroAction";

import {
  cadastroBuildJSONFromOptions
  , cadastroBuildOptionsFromCadastros
  , cadastroBuildOptionsFromEscopoExibicaoItemVenda
  , cadastroBuildOptionsFromCargoFuncao
  , cadastroRecuperaValueFromOptions
  , cadastroRecuperaValueFromOptionsList
} from "../../../utils/SelectUtils";
import { localeSort } from "../../../utils/SortUtils";
import { entityURIListEqualsList } from "../../../utils/URIUtils";
import { createCargoPermissaoTemplate } from "../../../utils/CargoUtils/createCargoPermissaoTemplate";

import AtivoInputDefault from "../cadastroInputDefault/AtivoInputDefault.js";
import CadastroForm from "./CadastroForm";
import InputCustomizado from "../../UI/Input/InputCustomizado";
import NomeInputDefault from "../cadastroInputDefault/NomeInputDefault.js";
import { Fieldset } from "../../UI/Fields/Fieldset";

import "./CargoForm.css";

/**
 * Classe que repassa para o CadastroForm as informações para a montagem do formulário para o cadastro.
 */
const CargoForm = (props) => {
  log("CargoForm render");

  return <>
    <CadastroForm
      {...props}
      formModel={CargoValues}
      registerSaveHelp={getStrings().roleFormHelp}
      registerUpdateHelp={getStrings().roleFormHelp}
    />
  </>;
}

const CustomCheckBox = (props) => {
  return <InputCustomizado
    ref={props.reference}
    id={props.id}
    tabIndex={props.tabIndex}
    type="checkbox"
    name={props.name}
    label={props.label}
    handleInputValidado={props.handleInputValidado}
    topClassName={props.topClassName}
    className={`${props.className ? ` ${props.className}` : ""}`}
  />;
}

/**
 * Classe que realiza a montagem do formulário para o cadastro.
 */
class CargoValues extends React.Component {
  enabled = true;
  ismounted = false;

  /**
   * Retorna se os campos obrigatórios (required) foram todos preenchidos
   */
  checkRequired = newData => {
    let required = (newData.nome !== "") && (newData.limiteExibicaoItensProduzidos !== "") && (newData.escopoExibicaoItemVenda !== null);
    log("CargoValues checkRequired", { required });
    return required;
  };

  /**
   * Recupera os dados do formulário.
   */
  getFormData = () => {
    let formData = {
      empresa: getAppEmpresa(),
      enabled: this.enabled.getValue(),
      nome: this.nome.getValue(),
      usuarioList: this.props.cadastroDados.usuarioList,
      cargoFuncaoList: cadastroRecuperaValueFromOptionsList(this.funcoesCargo.inputComponent.getValue()),
      cargoPermissao: {
        cadastroGeral: this.cadastroGeral.inputComponent.checked,
        cadastroEmpresa: this.cadastroEmpresa.inputComponent.checked,
        cadastroCargo: this.cadastroCargo.inputComponent.checked,
        producaoAlteracao: this.producaoAlteracao.inputComponent.checked,
        producaoControle: this.producaoControle.inputComponent.checked,
        vendaControle: this.vendaControle.inputComponent.checked,
        vendaEntrega: this.vendaEntrega.inputComponent.checked,
        vendaEnvio: this.vendaEnvio.inputComponent.checked,
        vendaExclusao: this.vendaExclusao.inputComponent.checked,
        vendaPagamento: this.vendaPagamento.inputComponent.checked,
        controleFaturamento: false, // this.controleFaturamento.inputComponent.checked,
        controleFinanceiro: false, // this.controleFinanceiro.inputComponent.checked,
        relatorios: this.relatorios.inputComponent.checked,
        integracaoConfiguracao: this.integracaoConfiguracao.inputComponent.checked
      },
      parametrosCargo: {
        limiteExibicaoItensProduzidos: this.limiteExibicaoItensProduzidos.getMaskValue() ? (this.limiteExibicaoItensProduzidos.getMaskValue() * 3600000000000) : null,
        limiteExibicaoItensDataEntrega: this.limiteExibicaoItensDataEntrega.getMaskValue() ? (this.limiteExibicaoItensDataEntrega.getMaskValue() * 3600000000000) : null,
        escopoExibicaoItemVenda: cadastroRecuperaValueFromOptions(this.escopoExibicaoItemVenda.inputComponent.getValue()),
        escopoExibicaoItemVendaOption: this.escopoExibicaoItemVenda.inputComponent.getValue(),
        tipoProdutoList: this.isParametroEscopoTipo() ? cadastroBuildJSONFromOptions(true, this.tipoProdutoList.inputComponent.getValue()) : [],
        tipoProdutoListOption: this.isParametroEscopoTipo() ? this.tipoProdutoList.inputComponent.getValue() : [],
        receberNotificacaoOrigem: this.receberNotificacaoOrigem.inputComponent.checked,
        ativarSomAoNotificar: this.ativarSomAoNotificar.inputComponent.checked,
      },
      ...(this.props.cadastroDados._links ? { _links: this.props.cadastroDados._links } : {})
    };
    log("CargoValues getFormData", { formData });
    return formData;
  };

  /**
   * Método executado ao clicar no botão "Gravar".
   * Recupera os dados do formulário e repassa para o método que irá persistir os dados.
   */
  handleGravar = () => {
    log("CargoValues handleGravar");
    // Repassa para o método que irá persistir os dados
    this.props.onSave(this.getFormData());
  };

  /**
   * Método que carrega os itens do campo Escopo de Exibição de Itens de Venda.
   */
  loadEscoposExibicaoItemVenda = () => {
    log("CargoValues loadEscoposExibicaoItemVenda");

    // Carrega o campo de Escopo de Exibição de Itens de Venda com a função de busca, conforme o campo for sendo preenchido.
    this.props.cadastroLoadEscoposExibicaoItemVenda(
      cadastros => {
        if (!this.ismounted)
          return;

        this.escopoExibicaoItemVenda.inputComponent.updateOptions(localeSort(cadastroBuildOptionsFromEscopoExibicaoItemVenda(true, cadastros), "label"));
      },
      () => {
        if (this.ismounted && (this.props.loading.escoposExibicaoItemVenda !== false)) {

          this.props.cadastroUpdateLoading({ escoposExibicaoItemVenda: false });
        }
      });
  };

  /**
   * Método que carrega as Funções do Cargo.
   */
  loadFuncoesCargo = () => {
    log("CargoValues loadFuncoesCargo")

    // Carrega o campo das Funções do Cargo com a função de busca, conforme o campo for sendo preenchido.
    this.props.cadastroLoadCargoFuncoes(
      cadastros => {
        if (!this.ismounted)
          return;

        this.funcoesCargo.inputComponent.updateOptions(localeSort(cadastroBuildOptionsFromCargoFuncao(true, cadastros), "label"));
      },
      () => {
        if (this.ismounted && (this.props.loading.funcoesCargo !== false)) {

          this.props.cadastroUpdateLoading({ funcoesCargo: false });
        }
      });
  };

  /**
   * Método que carrega os itens do campo Tipo de Produto.
   */
  loadTiposProduto = () => {
    log("CargoValues loadTiposProduto")

    // Carrega o campo de Tipo de Produto com a função de busca, conforme o campo for sendo preenchido.
    this.props.cadastroLoadTiposProduto(null, tipoProduto => {
      if (this.ismounted) {
        // Guarda a lista de cadastros para repopular o select se necessário
        this.tipoProdutoOptionList = cadastroBuildOptionsFromCadastros(true, tipoProduto);

        this.tipoProdutoList.inputComponent.updateOptions(this.tipoProdutoOptionList);
      }
    }, () => {
      if (this.ismounted && (this.props.loading.tipos !== false)) {

        this.props.cadastroUpdateLoading({ tipos: false });
      }
    })
  };

  /**
   * Retorna se um CargoPermissão é igual ao outro.
   */
  isCargoPermissaoEqual = (oldCargoPermissao, newCargoPermissao) => {
    let result = equalsCoerced(oldCargoPermissao, newCargoPermissao, (oldValue, newValue) => (newValue.cadastroGeral === oldValue.cadastroGeral) && (newValue.cadastroEmpresa === oldValue.cadastroEmpresa) && (newValue.cadastroCargo === oldValue.cadastroCargo) && (newValue.producaoAlteracao === oldValue.producaoAlteracao) && (newValue.producaoControle === oldValue.producaoControle) && (newValue.vendaControle === oldValue.vendaControle) && (newValue.vendaEntrega === oldValue.vendaEntrega) && (newValue.vendaEnvio === oldValue.vendaEnvio) && (newValue.vendaExclusao === oldValue.vendaExclusao) && (newValue.vendaPagamento === oldValue.vendaPagamento) && (newValue.relatorios === oldValue.relatorios) && (newValue.integracaoConfiguracao === oldValue.integracaoConfiguracao));
    log("CargoValues isCargoPermissaoEqual", { oldCargoPermissao, newCargoPermissao, result });
    return result;
  };

  /**
   * Retorna se o parâmetro Escopo de Exibição de Itens de Venda está configurado para exibir pelo Tipo de Produto para determinar se exibe o *select* de tipo de produto.
   */
  isParametroEscopoTipo = () => {
    let result = ((((this.props || {}).cadastroDados || {}).parametrosCargo || {}).escopoExibicaoItemVenda || "") === "TIPO_PRODUTO";
    log("CargoValues isParametroEscopoTipo", { result });
    return result;
  };

  /**
   * Retorna se um CargoPermissão é igual ao outro.
   */
  isParametrosCargoEqual = (oldParametrosCargo, newParametrosCargo) => {
    let result = equalsCoerced(oldParametrosCargo, newParametrosCargo, (oldValue, newValue) => (newValue.limiteExibicaoItensProduzidos === oldValue.limiteExibicaoItensProduzidos) && (newValue.escopoExibicaoItemVenda === oldValue.escopoExibicaoItemVenda) && entityURIListEqualsList(oldValue.tipoProdutoList, newValue.tipoProdutoList) && (newValue.receberNotificacaoOrigem === oldValue.receberNotificacaoOrigem));
    log("CargoValues isParametrosCargoEqual", { oldParametrosCargo, newParametrosCargo, result });
    return result;
  };

  /**
   * Retorna se uma lista de usuários é igual à outra.
   */
  isUsuarioListEqual = (oldUsuarioList, newUsuarioList) => {
    let result = equalsCoerced(oldUsuarioList, newUsuarioList, (oldValue, newValue) =>
      // Se o tamanho de cada lista for igual, elas podem ser iguais. Caso contrário, elas são diferentes.
      (oldValue.length === newValue.length)
      // Se o tamanho for igual e todos os elementos forem iguais, as listas são iguais.
      && oldValue.every(oldUsuario => newValue.find(newUsuario => oldUsuario.login.email === newUsuario.login.email)));
    log("CargoValues isUsuarioListEqual", { oldUsuarioList, newUsuarioList, result });
    return result;
  };

  /**
   * Método que define o valor inicial do select.
   */
  setEscopoExibicaoItemVendaVinculado = cadastro => {
    log("CargoValues setEscopoExibicaoItemVendaVinculado", { cadastro })
    if (!cadastro) {
      return;
    }
    const option = cadastro.escopoExibicaoItemVendaOption
      ? cadastro.escopoExibicaoItemVendaOption
      : cadastro.escopoExibicaoItemVenda
        ? cadastroBuildOptionsFromEscopoExibicaoItemVenda(false, cadastro.escopoExibicaoItemVenda)
        : null;

    // Se uma opção havia sido selecionada, coloca essa opção no select. Senão, gera a opção a partir da entidade que estiver no cadastro.
    // A entidade só estará disponível para gerar uma opção quando um cadastro for editado, pois seus dados são lidos da retaguarda.
    // Quando o cadastro é enviado para a retaguarda, a entidade é substituída pelo seu URI, mas a partir daí já havia uma opção selecionada.
    this.escopoExibicaoItemVenda.inputComponent.updateValue(option);
  };

  /**
   * Método que ajusta os Tipos de Produto vinculados a este produto nas opções do Multi Select.
   */
  setTiposVinculados = cadastro => {
    log("CargoValues setTiposVinculados", { cadastro })
    if (!cadastro.tipoProdutoList)
      return;

    let options = cadastroBuildOptionsFromCadastros(true, cadastro.tipoProdutoList);
    log("CargoValues setTiposVinculados", { options });

    // Se uma opção havia sido selecionada, coloca essa opção no select. Senão, gera a opção a partir da entidade que estiver no cadastro.
    // A entidade só estará disponível para gerar uma opção quando um cadastro for editado, pois seus dados são lidos da retaguarda.
    // Quando o cadastro é enviado para a retaguarda, a entidade é substituída pelo seu URI, mas a partir daí já havia uma opção selecionada.
    this.tipoProdutoList.inputComponent.updateValue(
      cadastro.tipoProdutoListOption
        ? cadastro.tipoProdutoListOption
        : cadastro.tipoProdutoList ? options : null);
  };

  setFuncoesCargoVinculados = cadastro => {
    log("CargoValues setFuncoesCargoVinculados", { cadastro });
    if (!cadastro.cargoFuncaoList)
      return;

    this.funcoesCargo.inputComponent.updateValue(cadastroBuildOptionsFromCargoFuncao(true, cadastro.cargoFuncaoList));
  };

  /**
   * Retorna se houve alteração em algum dado do cadastro.
   */
  wasChanged = (newData, oldData) => {
    let result = !equalsCoerced(oldData, newData, (oldValue, newValue) => (newValue.enabled === oldValue.enabled) && (newValue.nome === oldValue.nome) && this.isUsuarioListEqual(oldValue.usuarioList, newValue.usuarioList) && this.isCargoPermissaoEqual(oldValue.cargoPermissao, newValue.cargoPermissao) && this.isParametrosCargoEqual(oldValue.parametrosCargo, newValue.parametrosCargo));
    log("CargoValues wasChanged", { newData, oldData, result });
    return result;
  }

  /**
   * Retorna se houve alteração em algum dado do cadastro, comparando com os dados sendo editados.
   */
  wasChangedFromCurrent = newData => {
    let wasChanged = this.wasChanged(newData, this.props.cadastroDados);
    log("CargoValues wasChangedFromCurrent", { oldData: this.props.cadastroDados, newData, wasChanged });
    return wasChanged;
  };

  /**
   * Retorna se houve alteração em algum dado do cadastro, comparando com os dados presentes ao abrir a criação/edição do cadastro.
   */
  wasChangedFromInitial = newData => {
    let wasChanged = this.wasChanged(newData, this.props.cadastroDadosIniciais);
    log("CargoValues wasChangedFromInitial", { oldData: this.props.cadastroDadosIniciais, newData, wasChanged });
    return wasChanged;
  };

  buildCargoPermissaoTemplate() {
    const adminTemplate = createCargoPermissaoTemplate(getStrings().roleTemplateEnumToString("admin"), "admin", {
      cadastroGeral: true,
      cadastroEmpresa: true,
      cadastroCargo: true,
      producaoControle: true,
      producaoAlteracao: true,
      vendaControle: true,
      vendaEntrega: true,
      vendaEnvio: true,
      vendaExclusao: true,
      vendaPagamento: true,
      relatorios: true,
      integracaoConfiguracao: true,
    });

    const caixaTemplate = createCargoPermissaoTemplate(getStrings().roleTemplateEnumToString("caixa"), "caixa", {
      cadastroGeral: true,
      cadastroEmpresa: false,
      cadastroCargo: false,
      producaoControle: true,
      producaoAlteracao: false,
      vendaControle: true,
      vendaEntrega: true,
      vendaEnvio: true,
      vendaExclusao: true,
      vendaPagamento: true,
      relatorios: true,
      integracaoConfiguracao: false,
    });

    const cozinheiroTemplate = createCargoPermissaoTemplate(getStrings().roleTemplateEnumToString("cozinheiro"), "cozinheiro", {
      cadastroGeral: false,
      cadastroEmpresa: false,
      cadastroCargo: false,
      producaoControle: true,
      producaoAlteracao: true,
      vendaControle: false,
      vendaEntrega: false,
      vendaEnvio: false,
      vendaExclusao: false,
      vendaPagamento: false,
      relatorios: true,
      integracaoConfiguracao: false,
    });

    const entregadorTemplate = createCargoPermissaoTemplate(getStrings().roleTemplateEnumToString("entregador"), "entregador", {
      cadastroGeral: false,
      cadastroEmpresa: false,
      cadastroCargo: false,
      producaoControle: false,
      producaoAlteracao: false,
      vendaControle: false,
      vendaEntrega: true,
      vendaEnvio: false,
      vendaExclusao: false,
      vendaPagamento: false,
      relatorios: false,
      integracaoConfiguracao: false,
    });

    const garcomTemplate = createCargoPermissaoTemplate(getStrings().roleTemplateEnumToString("garcom"), "garcom", {
      cadastroGeral: false,
      cadastroEmpresa: false,
      cadastroCargo: false,
      producaoControle: true,
      producaoAlteracao: false,
      vendaControle: true,
      vendaEntrega: true,
      vendaEnvio: true,
      vendaExclusao: true,
      vendaPagamento: false,
      relatorios: false,
      integracaoConfiguracao: false,
    });

    const options = [
      adminTemplate,
      caixaTemplate,
      cozinheiroTemplate,
      entregadorTemplate,
      garcomTemplate,
    ];

    this.roleTemplate.inputComponent.updateOptions(options, getStrings().permission);
  }

  getCargoTemplateValue(name, defaultValue) {
    const templateInput = this.roleTemplate?.inputComponent || ({ getValue: () => null });
    const value = (templateInput.getValue()?.value?.codigo || {})[name];
    return typeof value === "boolean"
      ? value
      : defaultValue;
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   * Se for uma alteração, ou se estiver retornando de um erro, repassa os dados do cadastro atual para as variáveis da tela.
   */
  componentDidMount() {
    log("CargoValues componentDidMount");
    this.ismounted = true;
    this.loadEscoposExibicaoItemVenda();
    this.loadFuncoesCargo();
    this.loadTiposProduto();
    this.forceUpdate();
  }

  /**
   * Método executado APÓS a atualização do componente.
   * Se for uma alteração, ou se estiver retornando de um erro, repassa os dados do cadastro atual para as variáveis da tela.
   */
  componentDidUpdate() {
    log("CargoValues componentDidUpdate")
    const cadastro = this.props.cadastroDados;

    if (cadastro?.cargoPermissao && cadastro?.parametrosCargo) {
      this.buildCargoPermissaoTemplate();

      // Se for passado false, é false; se for passado true, null, undefined ou não for passado, é true
      this.enabled.inputField.inputComponent.checked = cadastro.enabled !== false;

      this.nome.inputField.inputComponent.value = cadastro.nome || null;
      // Cadastro
      let cargoPermissao = cadastro.cargoPermissao;
      this.cadastroGeral.inputComponent.checked = this.getCargoTemplateValue("cadastroGeral", cargoPermissao.cadastroGeral);
      this.cadastroEmpresa.inputComponent.checked = this.getCargoTemplateValue("cadastroEmpresa", cargoPermissao.cadastroEmpresa);
      this.cadastroCargo.inputComponent.checked = this.getCargoTemplateValue("cadastroCargo", cargoPermissao.cadastroCargo);
      // Controle
      this.producaoControle.inputComponent.checked = this.getCargoTemplateValue("producaoControle", cargoPermissao.producaoControle);
      this.producaoAlteracao.inputComponent.checked = this.getCargoTemplateValue("producaoAlteracao", cargoPermissao.producaoAlteracao);
      this.vendaControle.inputComponent.checked = this.getCargoTemplateValue("vendaControle", cargoPermissao.vendaControle);
      this.vendaEntrega.inputComponent.checked = this.getCargoTemplateValue("vendaEntrega", cargoPermissao.vendaEntrega);
      this.vendaEnvio.inputComponent.checked = this.getCargoTemplateValue("vendaEnvio", cargoPermissao.vendaEnvio);
      this.vendaExclusao.inputComponent.checked = this.getCargoTemplateValue("vendaExclusao", cargoPermissao.vendaExclusao);
      this.vendaPagamento.inputComponent.checked = this.getCargoTemplateValue("vendaPagamento", cargoPermissao.vendaPagamento);
      // Configurações
      this.relatorios.inputComponent.checked = this.getCargoTemplateValue("relatorios", cargoPermissao.relatorios);
      this.integracaoConfiguracao.inputComponent.checked = this.getCargoTemplateValue("integracaoConfiguracao", cargoPermissao.integracaoConfiguracao);

      let parametrosCargo = cadastro.parametrosCargo;
      this.limiteExibicaoItensProduzidos.setMaskValue(
        parametrosCargo.limiteExibicaoItensProduzidos
          ? (parametrosCargo.limiteExibicaoItensProduzidos / 3600000000000)
          : null
      );
      this.limiteExibicaoItensDataEntrega.setMaskValue(
        parametrosCargo.limiteExibicaoItensDataEntrega
          ? (parametrosCargo.limiteExibicaoItensDataEntrega / 3600000000000)
          : null
      );

      this.setEscopoExibicaoItemVendaVinculado(parametrosCargo);

      this.setTiposVinculados(parametrosCargo);

      this.setFuncoesCargoVinculados(cadastro);

      this.receberNotificacaoOrigem.inputComponent.checked = parametrosCargo.receberNotificacaoOrigem;

      this.ativarSomAoNotificar.inputComponent.checked = parametrosCargo.ativarSomAoNotificar;
    }
  }

  render = () => {
    log("CargoValues render");

    return <div className="sub-form">

      <AtivoInputDefault tabIndex={this.props.tabIndex} ref={input => this.enabled = getReduxWrappedComponent(input)} handleInputValidado={this.props.handleChange} />

      <NomeInputDefault ref={input => this.nome = getReduxWrappedComponent(input)} subPlaceholder={getStrings().roleSubPlaceholder} handleInputValidado={this.props.handleChange} isRequired={true} />

      <InputCustomizado id="funcoes" inputType="multiSelect" name="funcoes" label={getStrings().originTypeOpenInOrdersScreen} placeholder={getStrings().originTypeOpenInOrdersScreenPlaceholder} ref={input => this.funcoesCargo = getReduxWrappedComponent(input)} onChange={() => setTimeout(() => this.props.handleChange(), 0)} />

      {/* Parâmetros */}
      <h2 className="parameterHeader" ref={ref => { this.parameterHeader = ref }}>{getStrings().parameters}</h2>

      <InputCustomizado
        ref={input => { if (input && !this.roleTemplate) { this.roleTemplate = getReduxWrappedComponent(input) } }}
        sort
        id="roleTemplate"
        inputType="singleSelect"
        name="roleTemplate"
        placeholder={getStrings().roleTemplate}
        label={getStrings().roleTemplatePlaceholder}
        onChange={() => setTimeout(() => {
            this.forceUpdate();
            return this.props.handleChange();
          }
          , 0
        )}
      />

      <Fieldset legend={getStrings().registers}>
        <CustomCheckBox tabIndex={this.props.tabIndex} reference={input => this.cadastroGeral = getReduxWrappedComponent(input)} id="cadastroGeral" name="cadastroGeral" label={getStrings().generalRegister} handleInputValidado={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(this.props.handleChange(), 0); }} />

        <CustomCheckBox tabIndex={this.props.tabIndex} reference={input => this.cadastroEmpresa = getReduxWrappedComponent(input)} id="cadastroEmpresa" name="cadastroEmpresa" label={getStrings().registerCompanyCreation} handleInputValidado={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(this.props.handleChange(), 0); }} />

        <CustomCheckBox tabIndex={this.props.tabIndex} reference={input => this.cadastroCargo = getReduxWrappedComponent(input)} id="cadastroCargo" name="cadastroCargo" label={getStrings().registerRole} handleInputValidado={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(this.props.handleChange(), 0); }} />
      </Fieldset>

      <Fieldset legend={getStrings().control}>
        <CustomCheckBox tabIndex={this.props.tabIndex} reference={input => this.producaoControle = getReduxWrappedComponent(input)} id="producaoControle" name="producaoControle" label={getStrings().permissionProductionControl} handleInputValidado={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(this.props.handleChange(), 0); }} />

        <CustomCheckBox tabIndex={this.props.tabIndex} reference={input => this.producaoAlteracao = getReduxWrappedComponent(input)} id="producaoAlteracao" name="producaoAlteracao" label={getStrings().permissionProductionUpdate} handleInputValidado={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(this.props.handleChange(), 0); }} />

        <CustomCheckBox tabIndex={this.props.tabIndex} reference={input => this.vendaControle = getReduxWrappedComponent(input)} id="vendaControle" name="vendaControle" label={getStrings().permissionSaleControl} handleInputValidado={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(this.props.handleChange(), 0); }} />

        <CustomCheckBox tabIndex={this.props.tabIndex} reference={input => this.vendaEntrega = getReduxWrappedComponent(input)} id="vendaEntrega" name="vendaEntrega" label={getStrings().permissionSaleDelivery} handleInputValidado={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(this.props.handleChange(), 0); }} />

        <CustomCheckBox tabIndex={this.props.tabIndex} reference={input => this.vendaEnvio = getReduxWrappedComponent(input)} id="vendaEnvio" name="vendaEnvio" label={getStrings().permissionSaleSend} handleInputValidado={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(this.props.handleChange(), 0); }} />

        <CustomCheckBox tabIndex={this.props.tabIndex} reference={input => this.vendaExclusao = getReduxWrappedComponent(input)} id="vendaExclusao" name="vendaExclusao" label={getStrings().permissionSaleDelete} handleInputValidado={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(this.props.handleChange(), 0); }} />

        <CustomCheckBox tabIndex={this.props.tabIndex} reference={input => this.vendaPagamento = getReduxWrappedComponent(input)} id="vendaPagamento" name="vendaPagamento" label={getStrings().permissionSalePayment} handleInputValidado={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(this.props.handleChange(), 0); }} />
      </Fieldset>

      <Fieldset legend={getStrings().settings}>
        <CustomCheckBox tabIndex={this.props.tabIndex} reference={input => this.relatorios = getReduxWrappedComponent(input)} id="relatorios" name="relatorios" label={getStrings().permissionReports} handleInputValidado={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(this.props.handleChange(), 0); }} />

        <CustomCheckBox tabIndex={this.props.tabIndex} reference={input => this.integracaoConfiguracao = getReduxWrappedComponent(input)} id="integracaoConfiguracao" name="integracaoConfiguracao" label={getStrings().permissionIntegration} handleInputValidado={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(this.props.handleChange(), 0); }} />
      </Fieldset>

      <InputCustomizado id="escopoExibicaoItemVenda" inputType="singleSelect" name="escopoExibicaoItemVenda" ref={input => this.escopoExibicaoItemVenda = getReduxWrappedComponent(input)} placeholder={getStrings().saleItemDisplayScopePlaceholder()} label={getStrings().saleItemDisplayScopeRequired()} onChange={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(() => this.props.handleChange(), 0); }} required={true} sort />

      <InputCustomizado id="tipo" inputType="multiSelect" name="tipo" label={getStrings().saleItemDisplayScopeProductType()} placeholder={getStrings().productTypePlaceholder} ref={input => this.tipoProdutoList = getReduxWrappedComponent(input)} onChange={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(() => this.props.handleChange(), 0); }} topClassName={this.isParametroEscopoTipo() ? "" : "hidden"} />

      <InputCustomizado ref={input => this.limiteExibicaoItensProduzidos = getReduxWrappedComponent(input)} placeholder={getStrings().parameterProducedItemsDisplayLimitPlaceholder} id="limiteExibicaoItensProduzidos" inputType="masked" validacaoDados="numeroDecimal" scale={3} max={999.999} type="text" name="limiteExibicaoItensProduzidos" label={getStrings().parameterProducedItemsDisplayLimit} onBlur={this.props.handleChange} />

      <InputCustomizado ref={input => this.limiteExibicaoItensDataEntrega = getReduxWrappedComponent(input)} placeholder={getStrings().parameterProducedItemsDisplayLimitPlaceholder} id="limiteExibicaoItensDataEntrega" inputType="masked" validacaoDados="numeroDecimal" scale={3} max={999.999} type="text" name="limiteExibicaoItensDataEntrega" label={getStrings().parameterDeliveryDateItemsDisplayLimit} onBlur={this.props.handleChange} />

      <CustomCheckBox tabIndex={this.props.tabIndex} reference={input => this.receberNotificacaoOrigem = getReduxWrappedComponent(input)} id="receberNotificacaoOrigem" name="receberNotificacaoOrigem" label={getStrings().permissionReceiveSaleSourceNotification} handleInputValidado={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(this.props.handleChange(), 0); }} />

      <CustomCheckBox tabIndex={this.props.tabIndex} reference={(input) => { if (input) { this.ativarSomAoNotificar = input } }} id="ativarSomAoNotificar" name="ativarSomAoNotificar" label={getStrings().activeSoundOnNotify} handleInputValidado={() => { this.roleTemplate.inputComponent.updateValue(null); setTimeout(this.props.handleChange(), 0); }} />
    </div>;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
function mapStateToProps(state) {
  const props = {
    tabIndex: state.appReducer.getTabIndex()
    , ...state.idiomaReducer
  };

  return props;
};

/**
 * Mapeia as ações para utilizar localmente. 
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch) {
  const props = {
    cadastroLoadEscoposExibicaoItemVenda: (onSuccess, onComplete) => dispatch(cadastroActions.cadastroLoadEscoposExibicaoItemVenda(onSuccess, onComplete))
    , cadastroLoadCargoFuncoes: (onSuccess, onComplete) => dispatch(cadastroActions.cadastroLoadCargoFuncoes(onSuccess, onComplete))
    , cadastroLoadTiposProduto: (urlDataBase, onSuccess, onComplete) => dispatch(cadastroActions.cadastroLoadTiposProduto(urlDataBase, onSuccess, onComplete))
    , cadastroLoadUsuarioList: input => dispatch(cadastroActions.cadastroLoadUsuarioList(input))
    , cadastroUpdateLoading: steps => dispatch(cadastroActions.cadastroUpdateLoading(steps))
  };

  return props;
};

export default connect(mapStateToProps, mapDispatchToProps)(CargoForm);
