import {
  AiOutlineFileDone
  , AiOutlineSelect
  , AiOutlinePrinter
  , AiOutlineSearch
  , AiOutlineSync
  , AiOutlineCalendar,
  AiOutlineArrowDown
} from "react-icons/ai";

import {
  BiCalendar
} from "react-icons/bi";

import {
  CgPlayListRemove
} from "react-icons/cg";

import {
  FaAddressCard
  , FaCogs
  , FaQrcode
  , FaMapMarked
  , FaRegAddressCard
  , FaRegComment
} from 'react-icons/fa';

import {
  FiChevronRight
  , FiChevronsRight
  , FiChevronsLeft
  , FiChevronLeft
} from "react-icons/fi";

import {
  GoLaw
  , GoPencil
  , GoTrashcan
} from 'react-icons/go';

import {
  IoMdUnlock
  , IoMdLock
} from 'react-icons/io';

import {
  RiBuilding2Line
  , RiBuilding4Line
  , RiPrinterLine
} from 'react-icons/ri';

import {
  MdAttachMoney
  , MdCheck
  , MdNotificationsNone
  , MdPrint
  , MdSwitchCamera
  , MdAttachFile
  , MdClose
  , MdQueue
  , MdEventAvailable
  , MdEventSeat
  , MdEventNote
  , MdSend
  , MdFormatListBulleted
  , MdHelp
  , MdInfoOutline
  , MdPlaylistAddCheck
  , MdDirectionsBus
  , MdAdd
  , MdRemove
  , MdArrowDownward
  , MdArrowUpward
  , MdAutorenew
  , MdNavigateBefore
  , MdNavigateNext
  , MdMenu
  , MdMoreVert
  , MdSort
  , MdContentCopy
  , MdSpeakerNotes
  , MdSpeakerNotesOff
} from "react-icons/md";

import {
  GrPowerReset,
} from "react-icons/gr";

const ai = {
  AiOutlineCalendar
  , AiOutlineFileDone
  , AiOutlineSelect
  , AiOutlinePrinter
  , AiOutlineSearch
  , AiOutlineSync
};

const bi = {
  BiCalendar
};

const cg = {
  CgPlayListRemove
}

const fa = {
  FaAddressCard
  , FaCogs
  , FaMapMarked
  , FaRegAddressCard
  , FaRegComment
  , FaQrcode
};

const fi = {
  FiChevronLeft
  , FiChevronRight
  , FiChevronsLeft
  , FiChevronsRight
};

const go = {
  GoLaw
  , GoPencil
  , GoTrashcan
};

const gr = {
  GrPowerReset,
};

const io = {
  IoMdLock
  , IoMdUnlock
};

const md = {
  MdAdd
  , MdArrowDownward
  , MdArrowUpward
  , MdAttachFile
  , MdAttachMoney
  , MdAutorenew
  , MdCheck
  , MdClose
  , MdContentCopy
  , MdEventAvailable
  , MdEventNote
  , MdEventSeat
  , MdFormatListBulleted
  , MdHelp
  , MdInfoOutline
  , MdNavigateBefore
  , MdNavigateNext
  , MdMenu
  , MdMoreVert
  , MdNotificationsNone
  , MdPlaylistAddCheck
  , MdPrint
  , MdQueue
  , MdRemove
  , MdSend
  , MdSort
  , MdSpeakerNotes
  , MdSpeakerNotesOff
  , MdSwitchCamera
  , MdDirectionsBus
};

const ri = {
  RiBuilding2Line
  , RiBuilding4Line
  , RiPrinterLine
};

export default {
  ai
  , bi
  , cg
  , fa
  , fi
  , go
  , gr
  , io
  , md
  , ri
  , ArrowDownIcon: AiOutlineArrowDown
}