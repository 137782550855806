import React from "react"
import { connect } from "react-redux"

import { isEmpresaPainel } from "../../../utils/CompanyUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"

import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"

import BuildCellFromOrigemRaw from "./BuildCellFromOrigemRaw"

import "../vendas.css";

class BuildCellFromOrigem extends React.Component {

  render() {

    let origemVenda = (this.props || {}).origemVenda || {}

    return <BuildCellFromOrigemRaw
      accordionTitle={getStrings().salesTemplate(origemVenda.quantidadeVendas)}
      className={this.props.className}
      codigo={origemVenda.codigo}
      handleClick={evt => {
        log("BuildCellFromOrigem handleClick")

        evt.preventDefault()
        // Se for uma origem livre
        if (this.props.isOrigemLivre) {
          // Se for uma empresa do ramo Painel
          if (isEmpresaPainel(this.props.ramoEmpresa)) {
            // Inicia nova venda
            this.props.setupNewVenda(origemVenda)
          }
          // Se for empresa de outro ramo
          else {
            // Primeiro verifica se outro usuário não está usando esta origem.
            // Se não, reserva ela para si e inicia nova venda.
            this.props.reserveSaleSource(origemVenda)
          }
        } else {
          this.props.exibeGridVendaFromOrigem(origemVenda)
        }
      }}
      isOrigemLivre={this.props.isOrigemLivre}
      nome={origemVenda.nome}
      nomeVendas={origemVenda.nomeVendas}
      quantidadeVendas={origemVenda.quantidadeVendas}
      tabIndex={this.props.tabIndex}
      valorEnviado={origemVenda.valorEnviado}
      valorGeral={origemVenda.valorGeral}
      valorPagamento={origemVenda.valorPagamento}
    />
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  ...state.idiomaReducer,
  ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa,
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  exibeGridVendaFromOrigem: origemVenda => dispatch(controleVendaActions.exibeGridVendaFromOrigem(origemVenda)),
  reserveSaleSource: origemVenda => dispatch(controleVendaActions.reserveSaleSource(origemVenda)),
  setupNewVenda: origemVenda => dispatch(controleVendaActions.setupNewVenda(origemVenda))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(BuildCellFromOrigem)
