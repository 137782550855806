import React from 'react';
import { connect } from 'react-redux';

import { getAppEmpresa } from "../../../utils/AppUtils"
import { equalsCoerced } from "../../../utils/ComparatorsUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import * as SelectUtils from "../../../utils/SelectUtils"
import { localeSort } from "../../../utils/SortUtils"

import * as cadastroActions from '../../../store/actions/cadastroAction';

import CadastroForm from './CadastroForm';
import AtivoInputDefault from '../cadastroInputDefault/AtivoInputDefault.js'
import CodigoInputDefault from '../cadastroInputDefault/CodigoInputDefault.js'
import InputCustomizado from '../../UI/Input/InputCustomizado';
import NomeInputDefault from '../cadastroInputDefault/NomeInputDefault.js'

/**
 * Classe que repassa para o CadastroForm as informações para a montagem do formulário para o cadastro dos Impressoras.
 */
class ImpressoraForm extends React.Component {
    render() {
        log('ImpressoraForm render');
        return <CadastroForm {...this.props} formModel={ImpressoraValues}
            registerSaveHelp={getStrings().printerRegisterHelp} registerUpdateHelp={getStrings().printerRegisterHelp} />;
    }
}

/**
 * Classe que realiza a montagem do formulário para o cadastro dos Impressoras.
 */
class ImpressoraValues extends React.Component {

    constructor() {
        super();
        log('ImpressoraValues constructor');

        this.enabled = true;
        this.ismounted = false;
    }

    /**
     * Retorna se os campos obrigatórios (required) foram todos preenchidos
     */
    checkRequired = newData => {
        log('ImpressoraValues checkRequired', newData);
        return (newData.codigo !== '') && (newData.nome !== '') && (newData.comprimentoLinha !== '') && (newData.tipoImpressora !== null) && (newData.avancoLinha !== '');
    }

    /**
     * Recupera os dados do formulário.
     */
    getFormData = () => {
        log('ImpressoraValues getFormData');

        const formData = {
            empresaSet: [getAppEmpresa()],
            enabled: this.enabled.getValue(),
            codigo: this.codigo.getValue(),
            nome: this.nome.getValue(),
            comprimentoLinha: this.comprimentoLinha.getMaskValue(),
            dispositivo: this.dispositivo,
            tipoImpressora: SelectUtils.cadastroRecuperaValueFromOptions(this.tipoImpressora.inputComponent.getValue()),
            tipoImpressoraOption: this.tipoImpressora.inputComponent.getValue(),
            avancoLinha: this.avancoLinha.getMaskValue(),
            encerramento: this.encerramento.inputComponent.checked,
            balanca: this.balanca.inputComponent.checked,
            tamanhoCodigoQR: this.tamanhoCodigoQR.getMaskValue(),
            exibirSenhaImpressao: this.exibirSenhaImpressao.inputComponent.checked,
            linhasComanda: this.linhasComanda.getMaskValue(),
            ...(this.props.cadastroDados._links ? { _links: this.props.cadastroDados._links } : {}),
        };

        return formData;
    }

    /**
     * Método executado ao clicar no botão "Gravar".
     * Recupera os dados do formulário e repassa para o método que irá persistir os dados.
     */
    handleGravar = () => {
        log('ImpressoraValues handleGravar');

        // Repassa para o método que irá persistir os dados
        this.props.onSave(this.getFormData());
    }

    /**
     * Método que carrega os itens do campo Ramo da Empresa.
     */
    loadTiposImpressora = () => {
        log('EmpresaValues loadTiposImpressora');

        // Carrega o campo de Ramo da Empresa com a função de busca, conforme o campo for sendo preenchido
        this.props.cadastroLoadTiposImpressora(
            cadastros => {
                if (!this.ismounted)
                    return;

                this.tipoImpressora.inputComponent.updateOptions(localeSort(SelectUtils.cadastroBuildOptionsFromTipoImpressora(true, cadastros), 'label'));
            },
            () => {
                if (this.ismounted && (this.props.loading.tiposImpressora !== false)) {

                    this.props.cadastroUpdateLoading({ tiposImpressora: false });
                }
            });
    }

    /**
     * Método que define o valor inicial do select.
     */
    setTipoImpressoraVinculado = cadastro => {
        log('EmpresaValues setTipoImpressoraVinculado', cadastro);
        if (!cadastro) {
            return;
        }
        // Se uma opção havia sido selecionada, coloca essa opção no select. Senão, gera a opção a partir da entidade que estiver no cadastro.
        // A entidade só estará disponível para gerar uma opção quando um cadastro for editado, pois seus dados são lidos da retaguarda.
        // Quando o cadastro é enviado para a retaguarda, a entidade é substituída pelo seu URI, mas a partir daí já havia uma opção selecionada.
        this.tipoImpressora.inputComponent.updateValue(
            cadastro.tipoImpressoraOption
                ? cadastro.tipoImpressoraOption
                : cadastro.tipoImpressora ? SelectUtils.cadastroBuildOptionsFromTipoImpressora(false, cadastro.tipoImpressora) : null);
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro
     */
    wasChanged = (newData, oldData) => {
        let result = !equalsCoerced(oldData, newData, (oldValue, newValue) => (newValue.enabled === oldValue.enabled) && (newValue.codigo === oldValue.codigo) && (newValue.nome === oldValue.nome) && (newValue.comprimentoLinha === oldValue.comprimentoLinha) && (newValue.tipoImpressora === oldValue.tipoImpressora) && (newValue.avancoLinha === oldValue.avancoLinha) && (newValue.encerramento === oldValue.encerramento) && (newValue.balanca === oldValue.balanca) && (newValue.tamanhoCodigoQR === oldValue.tamanhoCodigoQR));
        log('ImpressoraValues wasChanged', { newData, oldData, result });
        return result;
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro, comparando com os dados sendo editados.
     */
    wasChangedFromCurrent = newData => {
        log('ImpressoraValues wasChangedFromCurrent', newData);
        return this.wasChanged(newData, this.props.cadastroDados);
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro, comparando com os dados presentes ao abrir a criação/edição do cadastro.
     */
    wasChangedFromInitial = newData => {
        log('ImpressoraValues wasChangedFromInitial', newData);
        return this.wasChanged(newData, this.props.cadastroDadosIniciais);
    }

    /**
     * Método executado APÓS a montagem/renderização do componente.
     * Define os valores iniciais das opções conforme cadastro carregado.
     */
    componentDidMount() {
        log('ImpressoraValues componentDidMount');
        this.ismounted = true;
        this.loadTiposImpressora();
        this.componentDidUpdate();
    }

    /**
     * Método executado APÓS a atualização do componente.
     * Define os valores iniciais das opções conforme cadastro carregado.
     * 
     * Os dados são populados aqui ao invés de no `render`>`InputCustomizado`>`defaultValue`
     * somente para seguir o padrão das outras telas.
     */
    componentDidUpdate() {
        log('ImpressoraValues componentDidUpdate');

        if (this.props.cadastroDados) {

            // Se for passado false, é false; se for passado true, null, undefined ou não for passado, é true
            this.enabled.inputField.inputComponent.checked = this.props.cadastroDados.enabled !== false;

            this.codigo.inputField.inputComponent.value = this.props.cadastroDados.codigo || null;

            this.nome.inputField.inputComponent.value = this.props.cadastroDados.nome || null;

            this.comprimentoLinha.setMaskValue(this.props.cadastroDados.comprimentoLinha);

            this.dispositivo = this.props.cadastroDados.dispositivo || null;

            this.avancoLinha.setMaskValue(this.props.cadastroDados.avancoLinha);

            this.linhasComanda.setMaskValue(this.props.cadastroDados.linhasComanda);

            this.encerramento.inputComponent.checked = this.props.cadastroDados.encerramento;

            this.balanca.inputComponent.checked = this.props.cadastroDados.balanca;

            this.exibirSenhaImpressao.inputComponent.checked = this.props.cadastroDados.exibirSenhaImpressao;

            this.tamanhoCodigoQR.setMaskValue(this.props.cadastroDados.tamanhoCodigoQR);

            this.setTipoImpressoraVinculado(this.props.cadastroDados);
        }
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('ImpressoraValues render');
        return <div className='sub-form'>

            <AtivoInputDefault tabIndex={this.props.tabIndex} ref={input => this.enabled = getReduxWrappedComponent(input)} handleInputValidado={this.props.handleChange} />

            <CodigoInputDefault ref={input => this.codigo = getReduxWrappedComponent(input)} subPlaceholder={getStrings().printerSubPlaceholder} handleInputValidado={this.props.handleChange} />

            <NomeInputDefault ref={input => this.nome = getReduxWrappedComponent(input)} subPlaceholder={getStrings().printerSubPlaceholder} handleInputValidado={this.props.handleChange} isRequired={true} />

            <InputCustomizado ref={input => this.comprimentoLinha = getReduxWrappedComponent(input)} placeholder={getStrings().lineLengthPlaceholder} id='comprimentoLinha' inputType='masked' validacaoDados='numeroInteiro' type='text' name='comprimentoLinha' required={true} label={getStrings().lineLengthRequired} handleInputValidado={this.props.handleChange} scale={0} padFractionalZeros={false} />

            <InputCustomizado ref={input => this.tamanhoCodigoQR = getReduxWrappedComponent(input)} placeholder={getStrings().qrCodeSizePlaceholder} id='tamanhoCodigoQR' inputType='masked' validacaoDados='numeroInteiro' type='text' name='tamanhoCodigoQR' label={getStrings().qrCodeSize} handleInputValidado={this.props.handleChange} scale={0} padFractionalZeros={false} />

            <InputCustomizado id='tipoImpressora' inputType='singleSelect' name='tipoImpressora' ref={input => this.tipoImpressora = getReduxWrappedComponent(input)} placeholder={getStrings().printerTypePlaceholder} label={getStrings().printerType} onChange={() => setTimeout(() => this.props.handleChange(), 0)} required={true} sort />

            <InputCustomizado ref={input => this.avancoLinha = getReduxWrappedComponent(input)} placeholder={getStrings().lineFeedPlaceholder} id='avancoLinha' inputType='masked' validacaoDados='numeroInteiro' type='text' name='avancoLinha' required={true} label={getStrings().lineFeedRequired} handleInputValidado={this.props.handleChange} scale={0} padFractionalZeros={false} />
            
            <InputCustomizado ref={input => this.linhasComanda = getReduxWrappedComponent(input)} placeholder={getStrings().orderLinesQuantityPlaceholder} id='linhasComanda' inputType='masked' validacaoDados='numeroInteiro' type='text' name='linhasComanda' required={false} label={getStrings().orderLinesQuantity} handleInputValidado={this.props.handleChange} scale={0} padFractionalZeros={false} />

            <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.encerramento = getReduxWrappedComponent(input)} id='encerramento' type='checkbox' name='encerramento' label={getStrings().saleClosure} handleInputValidado={this.props.handleChange} />

            <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.balanca = getReduxWrappedComponent(input)} id='balanca' type='checkbox' name='balanca' label={getStrings().saleUsingScales} handleInputValidado={this.props.handleChange} />
        
            <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.exibirSenhaImpressao = getReduxWrappedComponent(input)} id='exibirSenhaImpressao' type='checkbox' name='exibirSenhaImpressao' label={getStrings().showQueueNumberOnPrint} handleInputValidado={this.props.handleChange} />
        
        </div>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    tabIndex: state.appReducer.getTabIndex(),
    ...state.idiomaReducer,
});

/**
 * Mapeia as ações para utilizar localmente. 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    cadastroLoadTiposImpressora: (onSuccess, onComplete) => dispatch(cadastroActions.cadastroLoadTiposImpressora(onSuccess, onComplete)),
    cadastroUpdateLoading: steps => dispatch(cadastroActions.cadastroUpdateLoading(steps)),
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(ImpressoraForm);
