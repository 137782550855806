import { UPDATE_DELIVERY, REMOVE_DELIVERY, CLEAR_DELIVERY } from '../reducers/deliveryReducer';

/**
 * 
 * @param {Object} payload 
 */
export const updateDeliveryAction = payload => dispatch => {
    return dispatch({
        type: UPDATE_DELIVERY,
        payload,
    });
};

/**
 * 
 * @param {String} payload 
 */
export const removeDeliveryAction = payload => dispatch => {
    return dispatch({
        type: REMOVE_DELIVERY,
        payload,
    });
};

/**
 * 
 */
export const clearDeliveryAction = () => dispatch => {
    return dispatch({
        type: CLEAR_DELIVERY,
    });
};