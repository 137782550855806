import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputCustomizado from '../../UI/Input/InputCustomizado';

import { getStrings } from '../../../utils/LocaleUtils';
import { getReduxWrappedComponent } from '../../../utils/reduxUtils/reduxUtils';

/**
 * Classe que realiza a montagem de um dos 3 campos de registros básicos de todos os formulários de cadastro: Ativo, Código e Nome.
 * Classe responsável por montar o campo padrão "Ativo".
 */
class AtivoInputDefault extends React.Component {
    static propTypes = {
        defaultValue: PropTypes.any,
        handleInputValidado: PropTypes.func,
        onBlur: PropTypes.func,
        ref: PropTypes.func,
        tabIndex: PropTypes.number,
    };

    /**
     * Método que retorna o valor do campo.
     */
    getValue = () => {
        return this.inputField.inputComponent.checked;
    }

    /**
     * Método que monta o componente.
     */
    render() {
        return (
            <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.inputField = getReduxWrappedComponent(input)} defaultChecked={this.props.defaultValue} id='isEnabled' type='checkbox' name='isEnabled' label={getStrings().active} onBlur={this.props.onBlur} handleInputValidado={this.props.handleInputValidado}/>
        );
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    ...state.idiomaReducer,
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, null, null, { forwardRef: true })(AtivoInputDefault);
