import { sourcesInUse_client_codeReader } from "../../components/UI/BreadCrumbs/BreadCrumbs"
import { hideSpinner } from "../../components/UI/Spinner/Spinner"

import { requestErrorHandlerDefault } from "../../utils/ErrorUtils"
import { isNonEmptyArray } from "../../utils/ComparatorsUtils"
import { getAppUsuario, getAppEmpresa } from "../../utils/AppUtils"
import { setIndexReturnArray } from "../../utils/ArrayUtils"
import { getCurrentDateTimeAndZone } from "../../utils/DateUtils"
import { getHeaders } from "../../utils/HeadersUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { roundNumber } from "../../utils/NumberUtils"
import { assign } from "../../utils/ObjectUtils"
import { urlDatabase } from "../../utils/SecureConnectionUtils"
import { getURIFromEntity } from "../../utils/URIUtils"

import {
  APP_NOTIFICATION_TYPE_WARNING_QUESTION
  , COLLECTOR_CODE_READER
  , COLLECTOR_INCREMENT
  , COLLECTOR_LOAD
  , COLLECTOR_MANAGE
  , COLLECTOR_PRODUCT_LOAD
  , COLLECTOR_REMOVE
} from "./actionTypes"
import {
  STATE_COLLECTOR_SALE_CLIENT_LIST
  , STATE_COLLECTOR_SALE_CODE_READER
  , STATE_COLLECTOR_SALE_MANAGE_SALE
} from "../reducers/collectorReducer"
import { axios, appSpinnerHide, appSpinnerShow, appNotificationShow } from "./appAction"
import { buildSpecQuery } from "./cadastroAction"
import { PRODUCT_ENTITY } from "./codeReaderAction"
import * as codeReaderActions from "./codeReaderAction"

/**
 * Id do spinner que é exibido no filtro de clientes.
 */
export const SPINNER_FILTER_CLIENT_ID = "spinnerFilterClientId"

/**
 * Id do spinner que é exibido no filtro de produtos.
 */
export const SPINNER_FILTER_PRODUCT_ID = "spinnerFilterProductId"

/**
 * Ajusta a quantidade de um produto coletado pela quantidade informada. Essa quantidade nunca será menor que zero. Essa quantidade, como a de produtos filtrados, só é persistida quando o usuário enviar os dados. Quando isso acontecer, a quantidade do produto será a informada nos produtos coletados acrescida de quaisquer quantidade informada nos produtos filtrados.
 * @param {*} produtoURI 
 * @param {*} increment quantidade a ser somada à quantidade anterior. Pode ser negativo.
 * @param {*} absolute nova quantidade a substituir quantidade anterior. Só é usado se `increment` não for informado.
 */
export const adjustCollectedAmount = (produtoURI, increment, absolute) => (dispatch, getState) => {

  log('collectorAction adjustCollectedAmount', adjustCollectedAmount);

  let state = () => getState().collectorReducer;

  dispatch({
    type: COLLECTOR_INCREMENT,
    collectedProductList: state().collectedProductList.map(product =>
      // Verifica se é o produto que foi informado
      (product.produtoURI === produtoURI)
        // Se sim, atualiza sua quantidade. Usa a quantidade passada ou calcula em cima do valor anterior. Faz o máximo entre esse valor e zero para garantir valores válidos.
        ? assign({}, product, { amount: Math.max(increment ? (product.amount + increment) : absolute, 0) })
        // Se não, mantém o produto como está.
        : product)
  });
};

/**
 * Ajusta a quantidade de um produto filtrado pela quantidade informada. Essa quantidade nunca será menor que zero.
 * @param {*} produtoURI 
 * @param {*} increment quantidade a ser somada à quantidade anterior. Pode ser negativo.
 * @param {*} absolute nova quantidade a substituir quantidade anterior. Só é usado se `increment` não for informado.
 */
export const adjustFilteredAmount = (produtoURI, increment, absolute) => (dispatch, getState) => {

  log('collectorAction adjustFilteredAmount', produtoURI);

  let currentMoment = getCurrentDateTimeAndZone();
  let dateTime = currentMoment.dateTime;
  let timeZone = currentMoment.timeZone;

  dispatch({
    type: COLLECTOR_INCREMENT,
    // Para atualizar a lista de produtos, itera ela para atualizar o produto que foi informado.
    filteredProductList: getState().collectorReducer.filteredProductList.map(product =>
      // Verifica se é o produto que foi informado
      (product.produtoURI === produtoURI)
        // Se sim, atualiza sua quantidade. Usa a quantidade passada ou calcula em cima do valor anterior. Faz o máximo entre esse valor e zero para garantir valores válidos.
        ? assign({}, product, {
          amount: Math.max(increment ? (product.amount + increment) : absolute, 0),
          dateTime,
          timeZone
        })
        // Se não, mantém o produto como está.
        : product)
  });
};

/**
 * Remove da lista de produtos filtrados todos os produtos que não foram obtidos com o leitor de código de barras ou QR.
 */
export const clearFilteredProductList = () => (dispatch, getState) => {

  log('collectorAction clearFilteredProductList');

  dispatch({
    type: COLLECTOR_PRODUCT_LOAD,
    filterProdutoValue: null,
    lastProductList: [],
    filteredProductList: mergeProductList(getState().collectorReducer.filteredProductList, [])
  });
  hideSpinner(SPINNER_FILTER_PRODUCT_ID);
};

/**
 * Pede confirmação antes de encerrar a venda e enviar ao Hub.
 */
export const confirmToSendToHub = () => (dispatch, getState) => {

  log('collectorAction confirmToSendToHub');

  dispatch(appNotificationShow(
    // Verifica se há produtos pendentes
    (getState().collectorReducer.filteredTotal() || getState().collectorReducer.collectedAmountChanged())
      // Se há produtos pendentes, pergunta se usuário deseja ignorar esses produtos e prosseguir.
      ? getStrings().closeSaleSendHubIgnorePending
      // Se não há produtos pendentes, só pede confirmação para encerrar a venda.
      : getStrings().closeSaleSendHub,
    APP_NOTIFICATION_TYPE_WARNING_QUESTION, getStrings().sendToHub, () => dispatch(sendToHub())));
};

/**
 * Pede confirmação antes de voltar para a tela de coletores caso haja produtos pendentes.
 */
export const confirmToShowCollectorList = () => (dispatch, getState) => {

  log('collectorAction confirmToShowCollectorList');

  // Verifica se há produtos pendentes
  if (getState().collectorReducer.filteredTotal() || getState().collectorReducer.collectedAmountChanged()) {
    // Se há produtos pendentes, pergunta se usuário deseja ignorar esses produtos e prosseguir.
    dispatch(appNotificationShow(getStrings().cancelNewItems,
      APP_NOTIFICATION_TYPE_WARNING_QUESTION, getStrings().sendToHub, () => dispatch(showCollectorList())));
  } else {
    // Se não há produtos pendentes, só pede confirmação para voltar para a tela de coletores.
    dispatch(showCollectorList());
  }
};

/**
 * Exibe o leitor de código de barras/QR para adicionar um produto.
 */
export const getBarQRCodeToAddProduct = () => (dispatch, getState) => {

  log('collectorAction getBarQRCodeToAddProduct');

  let state = () => getState().collectorReducer;

  // Inicia o leitor
  dispatch(codeReaderActions.startReader(PRODUCT_ENTITY,
    // header,
    'control',
    // bread crumbs
    sourcesInUse_client_codeReader(() => dispatch(showCollectorList()),
      state().collector().cliente.nome,
      () => dispatch(manageCollector(state().clienteURISelected))),
    // ao voltar
    () => dispatch(manageCollector(state().clienteURISelected)),
    // cache
    state().produtoCache,
    // ao ler um  produto
    treatCodeData));
  // Exibe o leitor
  dispatch({
    type: COLLECTOR_CODE_READER,
    state: STATE_COLLECTOR_SALE_CODE_READER
  });
};

/**
 * Retorna os clientes que passaram no filtro.
 * @param {*} search busca os clientes que contenham esse texto no seu código, nome ou identificador
 */
export const getCollectorForUseList = search => dispatch => {

  log('collectorAction getCollectorForUseList');

  dispatch(appSpinnerShow('getCollectorForUseList'));

  axios().get(
    urlDatabase + '/clientes',
    getHeaders({ collector: true, search })
  )
    .then(response => dispatch({
      type: COLLECTOR_LOAD,
      collectorList: response.data.content.map(cliente => ({
        clienteURI: getURIFromEntity(cliente),
        cliente,
        venda: getVendaFromCliente(cliente)
      })),
      filterClienteValue: search
    }))
    .catch(error => dispatch(requestErrorHandlerDefault(error)))
    .finally(() => {
      hideSpinner(SPINNER_FILTER_CLIENT_ID);
      dispatch(appSpinnerHide('getCollectorForUseList'));
    });
};

/**
 * Retorna os clientes com vendas.
 */
export const getCollectorInUseList = () => dispatch => {

  log('collectorAction getCollectorInUseList');

  dispatch(appSpinnerShow('getCollectorInUseList'));

  axios().get(
    urlDatabase + '/clientes',
    getHeaders({ collector: true })
  )
    .then(response => dispatch({
      type: COLLECTOR_LOAD,
      collectorList: response.data.content.map(cliente => ({
        clienteURI: getURIFromEntity(cliente),
        cliente,
        venda: getVendaFromCliente(cliente)
      })),
      filterClienteValue: null
    }))
    .catch(error => dispatch(requestErrorHandlerDefault(error)))
    .finally(() => {
      hideSpinner(SPINNER_FILTER_CLIENT_ID);
      dispatch(appSpinnerHide('getCollectorInUseList'))
    });
};

/**
 * Popula a tela com clientes em compras ou filtrados.
 * @param {*} search busca os clientes que contenham esse texto no seu código, nome ou identificador
 */
export const getCollectorList = search => dispatch => {

  log('collectorAction getCollectorList', search);

  dispatch(search ? getCollectorForUseList(search) : getCollectorInUseList());
};

/**
 * Carrega os produtos encontrados pelo filtro para serem coletados.
 * @param {*} filter busca os produtos que contenham esse texto no seu código ou nome
 */
export const getFilteredProductList = filter => (dispatch, getState) => {

  log('collectorAction getFilteredProductList', filter);

  dispatch(appSpinnerShow('getFilteredProductList'));

  let state = () => getState().collectorReducer;

  axios().get(
    urlDatabase + '/produtos',
    getHeaders({ all: true, search: buildSpecQuery(['codigo', 'nome', 'codigoImpresso'], filter), sort: 'nome', projection: 'produtoVendaProjection' })
  )
    .then(response => {
      dispatch({
        type: COLLECTOR_PRODUCT_LOAD,
        filterProdutoValue: filter,
        lastProductList: response.data.content.map(produto => getURIFromEntity(produto)),
        filteredProductList: mergeProductList(state().filteredProductList, response.data.content.map(produto => productFromProduto(produto))),
        // Carrega produtos recebidos no cache
        produtoCache: Object.assign({}, state().produtoCache,
          response.data.content
            .filter(produto => produto.codigoImpresso)
            .reduce((previousObject, currentProduto) =>
              ({ ...previousObject, [currentProduto.codigoImpresso]: currentProduto }), {}))
      })
    })
    .catch(error => dispatch(requestErrorHandlerDefault(error)))
    .finally(() => {
      hideSpinner(SPINNER_FILTER_PRODUCT_ID);
      dispatch(appSpinnerHide('getFilteredProductList'))
    });
};

/**
 * Prepara a lista de produtos para atualizar a venda. Usa a lista de produtos filtrados atualizada pela lista de produtos coletados que tiveram quantidade ajustada.
 */
const getProductListToPersist = state => {
  log('collectorAction getProductListToPersist', state);
  let productIndex;
  return state().collectedProductList
    // Só considera os produtos coletados que tiveram quantidade ajustada.
    .filter(currentCollectedProduct => currentCollectedProduct.amountChanged)
    // Atualiza a lista inicial montada lá embaixo com os produtos coletados que tiveram quantidade ajustada.
    .reduce((previousArray, currentCollectedProduct) =>
      // Busca o produto coletado na lista dos filtrados
      ((productIndex = previousArray.findIndex(itemVenda => itemVenda.produto === currentCollectedProduct.produtoURI)) < 0)
        // Se não encontrou, gera um item de venda para atualizar a quantidade do produto.
        ? previousArray.concat(itemVendaFromProduct(currentCollectedProduct, { quantidade: currentCollectedProduct.amountDifference }))
        // Se encontrou, atualiza a quantidade do item de venda existente.
        : setIndexReturnArray(previousArray, productIndex, Object.assign({}, previousArray[productIndex], { quantidade: currentCollectedProduct.amountDifference + previousArray[productIndex].quantidade })),
      // Monta uma lista inicial de itens de venda a partir da lista de produtos filtrados.
      state().filteredProductList.filter(product => product.amount).map(product => itemVendaFromProduct(product)));
}

/**
 * Retorna a primeira das vendas do cliente ou `null` se houver nenhuma.
 * @param {*} cliente 
 */
const getVendaFromCliente = cliente => ((!cliente.vendaList) || (cliente.vendaList.length < 1)) ? null : cliente.vendaList[0];

/**
 * Gera uma entidade ItemVenda a partir de um produto filtrado.
 * @param {*} product 
 * @param {*} collectedProductList TODO remover?
 */
const itemVendaFromProduct = (product, customData = {}) => {//collectedProductList) => {
  log('collectorAction itemVendaFromProduct', product, customData); //collectedProductList);

  return Object.assign({}, {
    empresa: getAppEmpresa(),
    dataHora: product.dateTime + product.timeZone,
    dataHoraAtualizacao: product.dateTime + product.timeZone,
    fusoHorario: product.timeZone,
    quantidade: product.amount,
    precoUnitario: product.price,
    valorTotal: product.total,
    produto: product.produtoURI,
    usuarioList: [urlDatabase + '/usuarios/' + getAppUsuario()],
  }, customData);
};

/**
 * Seleciona um cliente para gerenciar uma venda sua.
 * @param {*} clienteURISelected 
 * @param {*} vendaURI 
 * @param {*} fromGrid diferencia se vem da tela de coletores ou do leitor de código de barras/QR
 */
export const manageCollector = (clienteURISelected, vendaURI, fromGrid) => (dispatch, getState) => {

  log('collectorAction manageCollector', clienteURISelected);

  let currentMoment = getCurrentDateTimeAndZone();

  // Se um coletor em uso foi aberto, ou seja, se não está retornando para a tela da venda, carrega do servidor os dados da venda.
  if (fromGrid && vendaURI) {
    dispatch(appSpinnerShow('manageCollector'));
    axios().get(
      vendaURI + '/trimmed',
      getHeaders()
    ).then(response => {
      // Atualiza a lista de coletores com os dados atualizados da venda
      dispatch(updateCollectorList(clienteURISelected, response.data));
      // Exibe a tela de gerência da venda
      dispatch({
        type: COLLECTOR_MANAGE,
        state: STATE_COLLECTOR_SALE_MANAGE_SALE,
        clienteURISelected,
      })
    })
      .catch(error => dispatch(requestErrorHandlerDefault(error)))
      .finally(() => dispatch(appSpinnerHide('manageCollector')));
  }
  // Senão, simplesmente altera o estado para exibir a tela.
  else {
    dispatch({
      type: COLLECTOR_MANAGE,
      state: STATE_COLLECTOR_SALE_MANAGE_SALE,
      clienteURISelected,
      // Se um coletor livre foi aberto, atualiza a lista de coletores.
      ...((fromGrid && (!vendaURI)) ? {
        collectorList: getState().collectorReducer.collectorList.map(collector =>
          // Se for o coletor do cliente que foi selecionado
          (collector.clienteURI === clienteURISelected)
            ? {
              // Testa se o coletor tem venda pendente
              ...collector, ...(collector.venda
                // Se sim, mantem o objeto como está.
                ? {}
                // Se não, adiciona a data e hora quando a venda foi iniciada
                : { dateTime: currentMoment.dateTime, timeZone: currentMoment.timeZone })
              // Se não for o coletor do cliente selecionado, mantem o objeto como está
            } : collector)
        // Senão, não atualiza a lista de coletores.
      } : {})
    })
  }
};

/**
 * Junta a lista de produtos filtrados que está sendo exibida na tela com a lista recebida da retaguarda.
 * Antes de juntar as listas, remove da lista da tela os produtos que não tem quantidade
 * e que não vieram de uma leitura de código de barras ou QR.
 * @param {*} frontList lista de produtos filtrados que estão sendo exibidos na tela
 * @param {*} backList lista de produtos filtrados pela retaguarda a serem adicionados à tela
 */
const mergeProductList = (frontList, backList) => {

  log('collectorAction mergeProductList', frontList, backList);

  // Só mantém os produtos da lista da tela se eles vieram de uma leitura de código de barras ou QR ou se possuem quantidade
  frontList = frontList.filter(product => product.fromBarQRCode || product.amount);

  // Começa com a lista de produtos a adicionar
  return (isNonEmptyArray(backList) ? backList : [])
    // Itera os produtos da lista da tela
    .reduce((previousArray, currentItem) =>
      // Testa se o produto a adicionar não está presente na tela
      previousArray.findIndex(previousItem => previousItem.produtoURI === currentItem.produtoURI) < 0 ?
        // Se não está, retorna a lista da tela adicionada do produto; se está, só retorna a lista da tela.
        previousArray.concat(currentItem) : previousArray, frontList);
};

/**
 * Gera um objeto com dados do produto a partir de uma entidade Produto.
 * @param {*} produto 
 * @param {*} customData dados que sobrescrevem os dados padrões
 */
const productFromProduto = (produto, customData = {}) => {

  log('collectorAction productFromProduto', produto, customData);

  let currentMoment = getCurrentDateTimeAndZone();
  let dateTime = currentMoment.dateTime;
  let timeZone = currentMoment.timeZone;
  return assign({}, {
    amount: 0,
    get amountChanged() { return this.amount !== this.persistedAmount },
    get amountDifference() { return this.amount - this.persistedAmount },
    dateTime,
    fromBarQRCode: false,
    itemVendaURI: null,
    name: produto.nome,
    persistedAmount: 0,
    price: produto.preco,
    produtoURI: getURIFromEntity(produto),
    get total() { return roundNumber(this.amount * this.price, 2); },
    timeZone,
  }, customData);
};

/**
 * Remove um produto coletado ou todos os produtos
 * @param {*} product 
 * @param {*} all 
 */
export const removeCollectedProduct = (product, clienteURI, vendaURI, all) => dispatch => {

  log('collectorAction removeCollectedProduct', product, all);

  // Exibe uma notificação perguntando confirmação
  dispatch(appNotificationShow(all ? getStrings().cancelAllSentItems : getStrings().cancelSentItem,
    APP_NOTIFICATION_TYPE_WARNING_QUESTION, getStrings().cancel, () => {
      // Se o usuário confirmou, prossegue com a remoção
      dispatch(appSpinnerShow('removeCollectedProduct'));

      axios().post(
        all ? `${vendaURI}/clear` : product.itemVendaURI,
        all ? null : { deleted: true },
        getHeaders()

      ).then(response => {
        // Atualiza a lista de coletores com os dados atualizados da venda
        dispatch(updateCollectorList(clienteURI, response.data));
      })
        .catch(error => dispatch(requestErrorHandlerDefault(error)))
        .finally(() => dispatch(appSpinnerHide('removeCollectedProduct')));
    }
  ));
};

/**
 * Remove um produto filtrado da tela. Se este produto estiver no retorno do uso do filtro,
 * ao invés de ser removido, ele tem sua quantidade zerada.
 * @param {*} product 
 * @param {*} all remove todos os produtos que atenderem aos critérios
 */
export const removeFilteredProduct = (product, all) => (dispatch, getState) => {

  log('collectorAction removeFilteredProduct', product, all);

  let state = () => getState().collectorReducer;

  dispatch({
    type: COLLECTOR_REMOVE,
    // Nova lista de produtos filtrados, que poderá ter um produto a menos ou não.
    filteredProductList: state().filteredProductList
      // Trata cada produto para decidir se adiciona na nova lista, que começa vazia.
      .reduce((previousList, currentProduct) =>
        // Se o produto sendo tratado é o produto que teve o botão da lixeira clicado ou se deve tratar todos os produtos
        (all || (currentProduct.produtoURI === product.produtoURI)) ?
          // Busca o produto na lista dos últimos produtos trazidos pelo filtro
          ((state().lastProductList.findIndex(lastProdutoURI => lastProdutoURI === currentProduct.produtoURI) < 0) ?
            // Se o produto não foi trazido por último pelo filtro, ele é removido. Retorna a nova lista sem esse item.
            previousList :
            // Se o produto foi trazido por último pelo filtro, retorna a nova lista contendo esse produto, mas com a quantidade zerada.
            previousList.concat(assign({}, currentProduct, { amount: 0 }))) :
          // Se o produto sendo tratado não foi o que teve o botão da lixeira clicado, o adiciona na nova lista.
          previousList.concat(currentProduct), [])
  });
};

/**
 * Remove um ou todos os produtos de uma das tabelas
 * @param {*} product produto a ser removido
 * @param {*} filtered se é da tabela dos filtrados ou dos coletados
 * @param {*} clienteURI para atualizar a lista de coletores ao remover produtos coletados
 * @param {*} all se é para remover todos os produtos
 */
export const removeProduct = (product, filtered, clienteURI, vendaURI, all) => dispatch => {

  log('collectorAction removeProduct', product, filtered, clienteURI, vendaURI, all);

  if (filtered) {
    dispatch(removeFilteredProduct(product, all));
  } else {
    dispatch(removeCollectedProduct(product, clienteURI, vendaURI, all));
  }
};

/**
 * Persiste os produtos na retaguarda, passando eles da lista dos filtrados para a lista dos coletados.
 * @param {*} collectorSelected o mesmo que `collectorList[clienteURISelected]`, por conveniência
 */
export const saveProductList = collectorSelected => (dispatch, getState) => {

  log('collectorAction saveProductList', collectorSelected);

  dispatch(appSpinnerShow('saveProductList'));

  let state = () => getState().collectorReducer;

  // Verifica se é uma venda nova ou uma persistida
  if (collectorSelected.venda) {
    axios().post(
      getURIFromEntity(collectorSelected.venda) + '/addOrIncrementItemVenda',
      getProductListToPersist(state),
      getHeaders()
    ).then(response => {
      dispatch(updateCollectorList(collectorSelected.clienteURI, response.data, true));
      dispatch(removeProduct(null, true, '', '', true));
    })
      .catch(error => dispatch(requestErrorHandlerDefault(error)))
      .finally(() => dispatch(appSpinnerHide('saveProductList')));
  } else {
    // Prepara os dados para persistir uma nova venda
    let currentMoment = getCurrentDateTimeAndZone();
    let dateTime = currentMoment.dateTime;
    let timeZone = currentMoment.timeZone;

    axios().post(
      urlDatabase + '/vendas',
      {
        empresa: getAppEmpresa(),
        nome: dateTime + timeZone,
        dataHoraInicial: collectorSelected.dateTime + collectorSelected.timeZone,
        fusoHorarioInicial: collectorSelected.timeZone,
        dataHoraFinal: collectorSelected.dateTime + collectorSelected.timeZone,
        fusoHorarioFinal: collectorSelected.timeZone,
        quantidadeItens: state().filteredProductList.length,
        valorTotal: state().filteredProductList.reduce((previousSum, currentProduct) => previousSum + currentProduct.total, 0),
        cliente: collectorSelected.clienteURI,
        itemVendaList: state().filteredProductList.filter(product => product.amount).map(product => itemVendaFromProduct(product))
      },
      getHeaders()
    ).then(response => {
      dispatch(updateCollectorList(collectorSelected.clienteURI, response.data, true));
      dispatch(removeProduct(null, true, '', '', true));
    })
      .catch(error => dispatch(requestErrorHandlerDefault(error)))
      .finally(() => dispatch(appSpinnerHide('saveProductList')));
  }
};

/**
 * Realiza um pagamento para enviar os dados para o Hub. Encerra este coletor e volta para a tela de coletores.
 */
export const sendToHub = () => (dispatch, getState) => {

  log('collectorAction sendToHub');

  let currentMoment = getCurrentDateTimeAndZone();
  let dateTime = currentMoment.dateTime;
  let timeZone = currentMoment.timeZone;

  let state = () => getState().collectorReducer;

  dispatch(appSpinnerShow('sendToHub'));

  axios().post(
    getURIFromEntity(state().collector().venda) + '/sendToHub',
    {
      dateTime: dateTime + timeZone,
      timeZone
    },
    getHeaders()
  )
    .then(() => dispatch(showCollectorList()))
    .catch(error => dispatch(requestErrorHandlerDefault(error)))
    .finally(() => dispatch(appSpinnerHide('sendToHub')));
};

/**
 * Mostra a tela de lista de coletores.
 * @param {*} clearClienteFilter se deve limpar o filtro de clientes
 */
export const showCollectorList = clearClienteFilter => dispatch => {

  log('collectorAction showCollectorList');

  dispatch({
    type: COLLECTOR_LOAD,
    collectedProductList: [],
    clienteURISelected: null,
    ...(clearClienteFilter ? { filterClienteValue: null } : {}),
    filterProdutoValue: null,
    lastProductList: [],
    filteredProductList: [],
    state: STATE_COLLECTOR_SALE_CLIENT_LIST,
  });
};

/**
 * Trata o produto selecionado pelo leitor de código de barras ou QR.
 * @param {*} produto 
 */
export const treatCodeData = produto => (dispatch, getState) => {

  log('collectorAction treatCodeData', produto);

  let state = () => getState().collectorReducer;

  // Busca o produto na lista de produtos filtrados
  let productIndex = state().filteredProductList.findIndex(product => product.produtoURI === getURIFromEntity(produto));

  dispatch({
    type: COLLECTOR_CODE_READER,
    produtoCache: Object.assign({}, state().produtoCache, { [produto.codigoImpresso]: produto }),
    // Se esse produto não existia na lista de produtos filtrados, adiciona ele no final da lista.
    filteredProductList: (productIndex < 0) ? state().filteredProductList.concat(productFromProduto(produto, { amount: 1, fromBarQRCode: true })) :
      // Se esse produto já existia na lista de produtos filtrados, gera uma nova lista, incrementando a quantidade desse produto em um.
      state().filteredProductList.map((product, index) => (index === productIndex) ? assign({}, product, { amount: product.amount + 1 }) : product)
  });
  dispatch(manageCollector(state().clienteURISelected));
};

/**
 * Atualiza a lista de coletores com os dados da venda que vieram da retaguarda
 * @param {*} clienteURI identifica qual coletor será atualizado
 * @param {*} venda dados recebidos da retaguarda
 * @param {*} refresh substitui os dados antigos completamente
 */
const updateCollectorList = (clienteURI, venda, refresh) => (dispatch, getState) => {

  log('collectorAction updateCollectorList', clienteURI, venda);

  let state = () => getState().collectorReducer;

  let productIndex;

  dispatch({
    type: COLLECTOR_LOAD,
    collectorList: state().collectorList.map(collector =>
      (collector.clienteURI === clienteURI) ? { ...collector, venda } : collector),
    // Atualiza a lista de produtos coletados
    collectedProductList: ((venda || {}).itemVendaList || []).map(itemVenda => {
      // Cria uma lista nova ou busca produtos já existentes na lista para atualizar ela
      return (refresh || ((productIndex = state().collectedProductList.findIndex(product => product.produtoURI === getURIFromEntity(itemVenda.produto))) < 0))
        // Se não encontrou, cria um produto novo.
        ? productFromProduto(itemVenda.produto, { amount: itemVenda.quantidade, itemVendaURI: getURIFromEntity(itemVenda), persistedAmount: itemVenda.quantidade })
        // Se encontrou, usa o mesmo produto.
        : state().collectedProductList[productIndex]
    })
  });
};
