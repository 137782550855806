import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

/**
 * Classe para impedir que Links sejam focados quando estiverem cobertos por um spinner.
 */
class LinkRedux extends React.Component {

    render() {
        // O React dispara um aviso se é passada uma propriedade inválida para um <a/>.
        // Isso remove o dispatch recebido pelo Redux dos parâmetros.
        let {dispatch, ...props} = this.props;
        return (
            <Link {...props} tabIndex={this.props.tabIndex}/>
        );
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {
    return {
        tabIndex: state.appReducer.getTabIndex()
    };
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(LinkRedux);
