import { applyCommission, applyDiscount, applyPercentage } from "../../../utils/BusinessUtils"
import { isEmpresaPainel } from "../../../utils/CompanyUtils"
import { roundNumber, truncateNumber } from "../../../utils/NumberUtils"
import { updateObject } from "../../../utils/ObjectUtils"
import { isBoolean } from "../../../utils/ComparatorsUtils"
import { initReducerVariable } from "../../../utils/ObjectUtils"
import { ascendingUTCSort } from "../../../utils/SortUtils"
import { entityURIEqualsURI, getURIFromEntity } from "../../../utils/URIUtils"

import * as actionTypes from "../../actions/actionTypes"
import { DISCOUNT_TYPE_PERCENTAGE } from "./pagamentoVendasReducer"

export const STATE_CONTROLE_VENDA_BAR_QR_CODE_TO_NEW_SALE = "STATE_CONTROLE_VENDA_BAR_QR_CODE_TO_NEW_SALE"
export const STATE_CONTROLE_VENDA_CLIENT_LAST_SALES = "STATE_CONTROLE_VENDA_CLIENT_LAST_SALES"
export const STATE_CONTROLE_VENDA_GRID_ORIGENS = "STATE_CONTROLE_VENDA_GRID_ORIGENS"
export const STATE_CONTROLE_VENDA_GRID_VENDAS = "STATE_CONTROLE_VENDA_GRID_VENDAS"
export const STATE_CONTROLE_VENDA_MANUTENCAO_VENDA = "STATE_CONTROLE_VENDA_MANUTENCAO_VENDA"
export const STATE_CONTROLE_VENDA_NEW_SALE_FROM_BAR_QR_CODE = "STATE_CONTROLE_VENDA_NEW_SALE_FROM_BAR_QR_CODE"
/**
 * Quando uma notificação de origem de venda é pressionada e a origem é livre, prepara o *reducer* de vendas para armazenar os dados da nova venda.
 * Depois que a venda for iniciada pelo *dialog*, armazena os dados da nova venda e troca para o próximo estado.
 */
export const STATE_CONTROLE_VENDA_GET_SALE_FROM_CALL = "STATE_CONTROLE_VENDA_GET_SALE_FROM_CALL"
/**
 * Busca e abre uma venda cujo(s) pagamento(s) tenha(m) sido cancelado(s).
 */
export const STATE_CONTROLE_VENDA_GET_SALE_FROM_CANCEL = "STATE_CONTROLE_VENDA_GET_SALE_FROM_CANCEL"
/**
 * Busca e abre uma venda persistida de modo direto.
 */
export const STATE_CONTROLE_VENDA_GET_SALE_FROM_QR_CODE = "STATE_CONTROLE_VENDA_GET_SALE_FROM_QR_CODE"
/**
 * Quando uma notificação de origem de venda é pressionada e a origem não é livre, prepara o *reducer* de vendas para exibir a lista de vendas.
 * Depois que um componente receber as atualizações no *reducer*, abre a tela de vendas que vai tratar os dados.
 */
export const STATE_CONTROLE_VENDA_LIST_SALES_FROM_CALL = "STATE_CONTROLE_VENDA_LIST_SALES_FROM_CALL"
/**
 * Quando uma nova venda é iniciada pelo *dialog* a partir de uma notificação, prepara o *reducer* de vendas para iniciar uma nova venda.
 * Depois que um componente receber as atualizações no *reducer*, abre a tela de vendas que vai tratar os dados.
 * A tela de vendas só vai iniciar uma nova venda com este estado, não com o outro.
 * Assim, se o usuário abrir a *dialog* e o fechar sem iniciar a venda, o controle de vendas funcionará normalmente ao ser acessado manualmente.
 */
export const STATE_CONTROLE_VENDA_NEW_SALE_FROM_CALL = "STATE_CONTROLE_VENDA_NEW_SALE_FROM_CALL"
export const STATE_CONTROLE_VENDA_NOVA_VENDA = "STATE_CONTROLE_VENDA_NOVA_VENDA"
export const STATE_CONTROLE_VENDA_PAGAR_VENDAS = "STATE_CONTROLE_VENDA_PAGAR_VENDAS"
export const STATE_CONTROLE_VENDA_PAGAR_ORIGENS = "STATE_CONTROLE_VENDA_PAGAR_ORIGENS"

export const SELECTED_SALE_SOURCE_STATE_LIST = [
  STATE_CONTROLE_VENDA_GRID_VENDAS,
  STATE_CONTROLE_VENDA_MANUTENCAO_VENDA,
  STATE_CONTROLE_VENDA_NOVA_VENDA,
  STATE_CONTROLE_VENDA_PAGAR_VENDAS]

export const ESTADO_VENDA_ABERTO = "ABERTO"
export const ESTADO_VENDA_AGUARDANDO_ENCERRAMENTO = "AGUARDANDO_ENCERRAMENTO"
export const ESTADO_VENDA_CANCELADO = "CANCELADO"
export const ESTADO_VENDA_ENCERRADO = "ENCERRADO"

export const OPEN_SALE_STATE_LIST = [
  ESTADO_VENDA_ABERTO
  , ESTADO_VENDA_AGUARDANDO_ENCERRAMENTO
]

export const ESTADO_ITEM_VENDA_AGUARDANDO = "AGUARDANDO"
export const ESTADO_ITEM_VENDA_ESPERANDO_ACEITAR = "ESPERANDO_ACEITAR"
export const ESTADO_ITEM_VENDA_CANCELADO = "CANCELADO"
export const ESTADO_ITEM_VENDA_EM_PRODUCAO = "EM_PRODUCAO"
export const ESTADO_ITEM_VENDA_ENTREGUE = "ENTREGUE"
export const ESTADO_ITEM_VENDA_ENVIADO = "ENVIADO"
export const ESTADO_ITEM_NAO_ACEITO = "NAO_ACEITO"
export const ESTADO_ITEM_VENDA_PRODUZIDO = "PRODUZIDO"
export const ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA = "SAIU_PARA_ENTREGA"
export const ESTADO_ITEM_VENDA_ENCERRADO = "ENCERRADO"
export const ESTADO_ITEM_VENDA_MOSTRAR_ROTA = "MOSTRAR_ROTA"

export const itemStateList = [
  ESTADO_ITEM_VENDA_AGUARDANDO
  , ESTADO_ITEM_VENDA_EM_PRODUCAO
  , ESTADO_ITEM_VENDA_PRODUZIDO
  , ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA
  , ESTADO_ITEM_VENDA_ENTREGUE
  , ESTADO_ITEM_VENDA_ENCERRADO
]

export const itemStateListNoDelivery = [
  ESTADO_ITEM_VENDA_AGUARDANDO
  , ESTADO_ITEM_VENDA_EM_PRODUCAO
  , ESTADO_ITEM_VENDA_PRODUZIDO
  , ESTADO_ITEM_VENDA_ENCERRADO
]

export const itemStateListForDeliveryMan = [
  ESTADO_ITEM_VENDA_AGUARDANDO
  , ESTADO_ITEM_VENDA_EM_PRODUCAO
  , ESTADO_ITEM_VENDA_PRODUZIDO
  , ESTADO_ITEM_VENDA_MOSTRAR_ROTA
  , ESTADO_ITEM_VENDA_ENTREGUE
  , ESTADO_ITEM_VENDA_ENCERRADO
]

export const itemAlreadyProducedStateList = [
  ESTADO_ITEM_VENDA_EM_PRODUCAO
  , ESTADO_ITEM_VENDA_PRODUZIDO
  , ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA
  , ESTADO_ITEM_VENDA_ENTREGUE
  , ESTADO_ITEM_VENDA_ENCERRADO
]

export const nowWaitingOrProducingList = [
  ESTADO_ITEM_VENDA_ENVIADO
  , ESTADO_ITEM_VENDA_AGUARDANDO
  , ESTADO_ITEM_VENDA_EM_PRODUCAO
]

export const nowProducedOrDeliveredList = [
  ESTADO_ITEM_VENDA_ENTREGUE
  , ESTADO_ITEM_VENDA_PRODUZIDO
  , ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA
  , ESTADO_ITEM_VENDA_ENCERRADO
]

export const toWaitOrProduceList = [
  ESTADO_ITEM_VENDA_AGUARDANDO
  , ESTADO_ITEM_VENDA_EM_PRODUCAO
  , ESTADO_ITEM_VENDA_PRODUZIDO
]

export const toDeliverOrCancelList = [
  ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA
  , ESTADO_ITEM_VENDA_ENTREGUE
  , ESTADO_ITEM_VENDA_MOSTRAR_ROTA
]

export const TIPO_ORIGEM_VENDA_BALCAO = "Balcão"
export const TIPO_ORIGEM_VENDA_MESA = "Mesa"
export const TIPO_ORIGEM_VENDA_CARTAO = "Cartão"
export const TIPO_ORIGEM_VENDA_DELIVERY = "Delivery"
export const TIPO_ORIGEM_VENDA_LOJA = "Loja"
export const TIPO_ORIGEM_VENDA_IFOOD = "Ifood"
export const TIPO_ORIGEM_VENDA_DELIVERY_MUCH = "Delivery Much"
export const TIPO_ORIGEM_VENDA_AIQFOME = "Aiqfome"
export const TIPO_ORIGEM_VENDA_MOBI = "Mobi"
export const TIPO_ORIGEM_VENDA_WHATSAPP = "WhatsApp"
export const TIPO_ORIGEM_VENDA_TELEFONE = "Telefone"
export const TIPO_ORIGEM_VENDA_PAINEL = "Painel"
export const TIPO_ORIGEM_VENDA_COLETOR = "Coletor"

/**
 * Retorna se uma chave gerada a partir de `saleProductKey` é de um item pertencente à venda informada.
 * @param {string} key chave gerada a partir de `saleProductKey`
 * @param {string | undefined} vendaURI identificador da venda
 */
export const isSaleProductKeyFromSale = (key, vendaURI) => (key || '').startsWith(`${vendaURI}?`);

/**
 * Estado Inicial, obrigatório no Reducer.
 */
export const initialState = {
  state: STATE_CONTROLE_VENDA_GRID_ORIGENS,

  /**
   * Lista de origens de venda que dispararam uma chamada para o garçom através de um usuário sem *login*.
   */
  calledOrigemVendaList: [],

  /**
   * Quantidade de vendas feitas por usuário sem vínculo com empresa que aparecem para acompanhar seus estados.
   */
  clientSaleAmount: 0,

  /**
   * Total da venda. Somente considera o valor da comissão.
   * Considera o que já foi pago com o que ainda falta para pagar.
   */
  commissionTotal: 0,

  /**
   * Se há origens de venda livres.
   */
  containsFree: undefined,

  /**
   * Total da venda. Somente considera o valor do desconto.
   * Considera o que já foi pago com o que ainda falta para pagar.
   */
  discountTotal: 0,

  filtroOrigemVendaValue: '',
  filtroVendaValue: '',

  /**
   * Lista de origens de venda sem vendas abertas.
   */
  freeOrigemVendaList: [],

  /**
   * Estado usado para gerenciar lógica relativa a iniciar vendas através de chamadas em origens de vendas.
   */
  fromCallState: null,

  grupoProdutoList: [],

  /**
   * Se algum produto tem quantidade selecionada para pagamento.
   */
  hasCheckedProduct: null,

  /**
   * Se algum produto de cada venda tem quantidade selecionada para pagamento.
   */
  hasCheckedProductMap: {},

  hasNewSaleItemToSend: false,

  hasNewSalePaymentItemToSend: false,

  /**
   * Se há itens que ainda não foram totalmente pagos.
   */
  hasRemainingAmount: null,

  /**
   * Origem venda que gerou chamado ao garçom através do seu código impresso.
   */
  origemVendaFromCall: null,

  /**
   * Lista de origens de venda que pode conter: origens em uso, origens selecionadas para pagar ou uma única origem selecionada para gerenciar suas vendas.
   */
  origemVendaList: [],

  /**
   * Total das vendas, itens não persistidos. Somente considera o valor da comissão.
   */
  newCommissionTotal: 0,

  /**
   * Total das vendas, itens não persistidos. Somente considera o valor da comissão.
   * Considera toda a quantidade restante, indiferente de estar selecionada.
   */
  newCommissionTotalRemaining: 0,

  /**
   * Total das vendas, itens não persistidos. Somente considera o valor de desconto.
   */
  newDiscountTotal: 0,

  /**
   * Total das vendas, itens não persistidos. Somente considera o valor de desconto.
   * Considera toda a quantidade restante, indiferente de estar selecionada.
   */
  newDiscountTotalRemaining: 0,

  /**
   * Total das vendas, itens não persistidos. Somente considera o valor unitário do produto multiplicado pela quantidade.
   */
  newProductTotal: 0,

  /**
   * Total das vendas, itens não persistidos. Somente considera o valor unitário do produto multiplicado pela quantidade.
   * Considera toda a quantidade restante, indiferente de estar selecionada.
   */
  newProductTotalRemaining: 0,

  /**
   * Mapa de totais por venda, itens não persistidos. Somente considera o valor unitário do produto multiplicado pela quantidade.
   */
  newProductTotalMap: {},

  /**
   * Mapa de totais por venda, itens não persistidos. Somente considera o valor unitário do produto multiplicado pela quantidade.
   * Considera toda a quantidade restante, indiferente de estar selecionada.
   */
  newProductTotalRemainingMap: {},

  /**
   * Mapa de totais por venda, itens não persistidos. Considera o valor unitário do produto multiplicado pela quantidade mais a comissão.
   */
  newProductWithCommissionTotalMap: {},

  /**
   * Mapa de totais por venda, itens não persistidos. Considera o valor unitário do produto multiplicado pela quantidade mais a comissão.
   * Considera toda a quantidade restante, indiferente de estar selecionada.
   */
  newProductWithCommissionTotalRemainingMap: {},

  /**
   * Mapa de totais por venda, itens não persistidos. Considera o total dos pagamentos da venda dividido pelo número de pessoas.
   */
  newProductWithCommissionWithDiscountByPersonTotalMap: {},

  /**
   * Mapa de totais por venda, itens não persistidos. Considera o total dos pagamentos da venda dividido pelo número de pessoas.
   * Considera toda a quantidade restante, indiferente de estar selecionada.
   */
  newProductWithCommissionWithDiscountByPersonTotalRemainingMap: {},

  /**
   * Mapa de totais por venda, itens não persistidos. Considera o total dos pagamentos da venda.
   */
  newProductWithCommissionWithDiscountTotalMap: {},

  /**
   * Mapa de totais por venda, itens não persistidos. Considera o total dos pagamentos da venda.
   * Considera toda a quantidade restante, indiferente de estar selecionada.
   */
  newProductWithCommissionWithDiscountTotalRemainingMap: {},

  /**
   * Lista de itens de venda não enviados, onde cada objeto contém o código do produto (`code`), a sequência do item (`sequence`)
   * e uma entidade do tipo ItemVenda não persistida (`itemVenda`).
   * 
   * A sequência é utilizada pois, normalmente, as quantidades do mesmo produto são somadas em um único item. Isso torna possível
   * discriminar os itens pelo código do produto. Porém, para produtos que são vendidos a kilo, é necessário um item para cada pesagem.
   * Assim, é necessário adicionar mais um dado para discriminar os itens.
   * 
   * Esta lista corresponde aos cartões de produtos que são exibidos na tela com controles para definição de quantidade e observação.
   * Ou seja, os itens de venda desta lista não são válidos enquanto não possuírem quantidade.
   */
  newSaleItemList: [],

  /**
   * Valor total dos novos itens de venda não enviados.
   */
  newSaleItemTotal: 0,

  /**
   * Mapa de itens de pagamento de venda não enviados.
   * Estes itens correspondem aos cartões de itens de pagamento de venda que são exibidos na tela.
   * Também incluem uma entidade do tipo ItemPagamentoVenda não persistida (`itemPagamentoVenda`).
   * Eles são construídos a partir dos dados das entidades de item de venda, porém somente com os dados necessários e outros dados auxiliares.
   * 
   * Os itens com mesmo produto são agrupados.
   * 
   * Os itens são indexados por uma `string` contendo o URI da venda, o código do produto e o número de sequência.
   * O número de sequência sempre é `0` para produtos sem quantidade pesada.
   */
  newSalePaymentItemMap: {},

  /**
   * Total das vendas, itens não persistidos. Tudo.
   */
  newTotal: 0,

  /**
   * Total das vendas, itens não persistidos. Tudo.
   * Considera toda a quantidade restante, indiferente de estar selecionada.
   */
  newTotalRemaining: 0,

  /**
   * Armazena os dados da nova venda a ser iniciada quando esse processo é feito de maneira mais assíncrona (por exemplo, em vendas iniciadas por chamados em origens de venda).
   */
  newVendaData: null,

  /**
 * O que é possível fazer na empresa através do leitor de código.
   */
  nivelUsuarioSemEmpresa: null,

  /**
   * Mapa de quantidades pagas por venda.
   * Calculado a partir dos itens de pagamento de venda enviados.
   * 
   * O mapa é indexado pelo URI da venda e pelo código do produto.
   * 
   * Se for um produto vendido por unidade, a quantidade paga é armazenada diretamente no mapa.
   * 
   * Se for um produto vendido por peso, o mapa conterá outro mapa indexado pela quantidade enviada.
   * Assim, haverá uma quantidade paga para cada combinação de produto pesado e quantidade.
   */
  paidAmountMap: {},

  /**
   * Total da venda. Somente considera o valor unitário do produto multiplicado pela quantidade.
   * Considera o que já foi pago com o que ainda falta para pagar.
   */
  productTotal: 0,

  /**
   * Mapa de quantidades restantes a pagar por venda.
   * Calculado a partir dos itens de venda enviados e dos itens de pagamento de venda enviados.
   * 
   * O mapa é indexado pelo URI da venda e pelo código do produto.
   * 
   * Se for um produto vendido por unidade, a quantidade restante é armazenada diretamente no mapa.
   * 
   * Se for um produto vendido por peso, o mapa conterá outro mapa indexado pela quantidade enviada.
   * Assim, haverá uma quantidade restante para cada combinação de produto pesado e quantidade.
   */
  remainingAmountMap: {},

  /**
   * Sinaliza que o *reducer* deve ser executado de novo para atualizar os valores
   * levando em consideração comissão e desconto.
   */
  runUpdateCommissionDiscount: false,

  /**
   * Total das vendas, itens persistidos. Somente considera o valor da comissão.
   */
  sentCommissionTotal: 0,

  /**
   * Total das vendas, itens persistidos. Somente considera o valor do desconto.
   */
  sentDiscountTotal: 0,

  /**
   * Total das vendas, itens persistidos. Somente considera o valor unitário do produto multiplicado pela quantidade.
   */
  sentProductTotal: 0,

  /**
   * Mapa de totais por venda, itens persistidos. Somente considera o valor unitário do produto multiplicado pela quantidade.
   */
  sentProductTotalMap: {},

  /**
   * Mapa de totais por venda, itens persistidos. Considera o valor unitário do produto multiplicado pela quantidade mais a comissão.
   */
  sentProductWithCommissionTotalMap: {},

  /**
   * Mapa de totais por venda, itens persistidos. Considera o total dos pagamentos da venda dividido pelo número de pessoas.
   */
  sentProductWithCommissionWithDiscountByPersonTotalMap: {},

  /**
   * Mapa de totais por venda, itens persistidos. Considera o total dos pagamentos da venda.
   */
  sentProductWithCommissionWithDiscountTotalMap: {},

  /**
   * Lista de itens de venda enviados, onde cada objeto contém todas as variáveis importantes do item de venda e do produto achatadas,
   * mais algumas variáveis necessárias para controle da tela.
   * 
   * Também usa um número de sequência. Porém, por mais que haja vários itens de venda de produtos vendidos por unidade do mesmo produto,
   * todos eles terão número de sequência igual a zero.
   * Isso porque o número de sequência só é usado aqui para controlar o cancelamento ou não de produtos vendidos por peso que tenham o mesmo peso,
   * já que para produtos vendidos por unidade o controle é feito por somatório da quantidade, iterando os itens em ordem cronológica.
   * 
   * Esta lista corresponde aos cartões de itens de venda que são exibidos na tela com controles para cancelamento e exibição de observação.
   */
  sentSaleItemList: [],

  /**
   * Valor total dos itens de venda persistidos.
   */
  sentSaleItemTotal: 0,

  /**
   * Mapa de itens de pagamento de venda enviados.
   * Estes itens correspondem aos cartões de itens de pagamento de venda que são exibidos na tela.
   * 
   * Eles são construídos a partir dos dados das entidades de item de pagamento de venda, porém somente com os dados necessários e outros dados auxiliares.
   * Os itens com mesmo produto são agrupados.
   * 
   * Os itens são indexados por uma `string` contendo o URI da venda, o código do produto e o número de sequência.
   * O número de sequência sempre é `0` para produtos sem quantidade pesada.
   */
  sentSalePaymentItemMap: {},

  /**
   * Total das vendas, itens persistidos. Tudo.
   */
  sentTotal: 0,

  /**
   * Total da venda. Tudo.
   * Considera o que já foi pago com o que ainda falta para pagar.
   */
  total: 0,

  /**
   * Quantidade de vendas da origem selecionada.
   */
  vendaAmount: 0,

  /**
   * *Link* para última venda que teve itens enviados. Valor deve ser guardado para que seja possível
   * desfazer esse envio mesmo que a venda não esteja mais sendo manipulada.
   */
  vendaURI: '',

  /**
   * Se o controle de produção está sendo exibido no momento.
   */
  visible: false
};

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.GRID_GRUPO_PRODUTO_GRUPO_PRODUTO_LOAD:
    case actionTypes.GRID_GRUPO_PRODUTO_GRUPO_PRODUTO_SORT:
    case actionTypes.MANUTENCAO_VENDA_ADICIONA_REMOVE_ITEM_VENDA:
    case actionTypes.MANUTENCAO_VENDA_UPDATE_NOVOS_ITENS_VENDA:

      let hasNewSaleItemToSend = false;

      let newSaleItemTotal = 0;

      // Itera os novos itens de venda para definir o valor de várias variáveis
      (action.newSaleItemList || state.newSaleItemList || []).forEach(newSaleItem => {
        if (newSaleItem.itemVenda.quantidade) {
          hasNewSaleItemToSend = true;
          newSaleItemTotal += newSaleItem.itemVenda.valorTotal;
        }
      });
      return updateObject(state, {
        ...state,
        ...action,
        hasNewSaleItemToSend,
        newSaleItemTotal
      });

    // As listas de itens de venda enviados e de itens de pagamento de venda enviados dependem das listas de itens de venda e das listas de pagamentos de venda contidas nas listas de vendas contidas na lista de origens de venda.
    // Quando a lista de origens de venda é atualizada, estas listas também devem ser atualizadas.

    // Quando não estiver em manutenção ou pagamento de venda, ambas as listas podem ser limpas, já que não são usadas.
    case actionTypes.CONTROLE_VENDA_LOAD_NOVA_VENDA:
    case actionTypes.CONTROLE_VENDA_LOAD_ORIGEM_VENDA_LIST:
    case actionTypes.CONTROLE_VENDA_LOAD_VENDA_LIST:
    case actionTypes.CONTROLE_VENDA_SHOW_GRID_VENDAS:
    // Quando estiver em manutenção de venda, que inclui o seu pagamento, atualiza as duas listas. // es-lint-disable-next-line // fall through intended
    case actionTypes.CONTROLE_VENDA_UPDATE_VENDA:
    case actionTypes.CONTROLE_VENDA_REMOVE_ITEM_VENDA:
    // Quando estiver em pagamento de várias vendas, atualiza uma das listas e limpa a outra, já que não é usada. // es-lint-disable-next-line // fall through intended
    case actionTypes.CONTROLE_VENDA_LOAD_ORIGEM_VENDA_LIST_PAGAR:
    case actionTypes.CONTROLE_VENDA_UPDATE_DISCOUNT:
    case actionTypes.CONTROLE_VENDA_UPDATE_PERCENTUAL_COMISSAO:
    // Quando estiver em pagamento de (várias) vendas e a quantidade a pagar for alterada, atualiza os totais a pagar. // es-lint-disable-next-line // fall through intended
    case actionTypes.PAGAMENTO_VENDAS_UPDATE_NEW_SALE_PAYMENT_ITEM:

      let accumulatedPaidAmountMap = {};

      let commissionTotal = initReducerVariable(action, 'clearTotal', state, initialState, 'commissionTotal');
      let discountTotal = initReducerVariable(action, 'clearTotal', state, initialState, 'discountTotal');
      let hasNewSalePaymentItemToSend = initReducerVariable(action, 'hasNewSalePaymentItemToSend', state, initialState);
      let hasCheckedProduct = initReducerVariable(action, 'hasCheckedProduct', state, initialState);
      let hasCheckedProductMap = initReducerVariable(action, 'hasCheckedProductMap', state, initialState);
      let hasRemainingAmount = initReducerVariable(action, 'hasRemainingAmount', state, initialState);
      let newCommissionTotal = initReducerVariable(action, 'clearTotal', state, initialState, 'newCommissionTotal');
      let newCommissionTotalRemaining = initReducerVariable(action, 'clearTotal', state, initialState, 'newCommissionTotalRemaining');
      let newDiscountTotal = initReducerVariable(action, 'clearTotal', state, initialState, 'newDiscountTotal');
      let newDiscountTotalRemaining = initReducerVariable(action, 'clearTotal', state, initialState, 'newDiscountTotalRemaining');
      let newProductTotal = initReducerVariable(action, 'clearTotal', state, initialState, 'newProductTotal');
      let newProductTotalRemaining = initReducerVariable(action, 'clearTotal', state, initialState, 'newProductTotalRemaining');
      let newProductTotalMap = initReducerVariable(action, 'clearTotal', state, initialState, 'newProductTotalMap');
      let newProductTotalRemainingMap = initReducerVariable(action, 'clearTotal', state, initialState, 'newProductTotalRemainingMap');
      let newProductWithCommissionTotalMap = initReducerVariable(action, 'clearTotal', state, initialState, 'newProductWithCommissionTotalMap');
      let newProductWithCommissionTotalRemainingMap = initReducerVariable(action, 'clearTotal', state, initialState, 'newProductWithCommissionTotalRemainingMap');
      let newProductWithCommissionWithDiscountByPersonTotalMap = initReducerVariable(action, 'clearTotal', state, initialState, 'newProductWithCommissionWithDiscountByPersonTotalMap');
      let newProductWithCommissionWithDiscountByPersonTotalRemainingMap = initReducerVariable(action, 'clearTotal', state, initialState, 'newProductWithCommissionWithDiscountByPersonTotalRemainingMap');
      let newProductWithCommissionWithDiscountTotalMap = initReducerVariable(action, 'clearTotal', state, initialState, 'newProductWithCommissionWithDiscountTotalMap');
      let newProductWithCommissionWithDiscountTotalRemainingMap = initReducerVariable(action, 'clearTotal', state, initialState, 'newProductWithCommissionWithDiscountTotalRemainingMap');
      let newSalePaymentItemMap = initReducerVariable(action, 'newSalePaymentItemMap', state, initialState);
      let newTotal = initReducerVariable(action, 'clearTotal', state, initialState, 'newTotal');
      let newTotalRemaining = initReducerVariable(action, 'clearTotal', state, initialState, 'newTotalRemaining');
      let paidAmountMap = initReducerVariable(action, 'sentSalePaymentItemMap', state, initialState, 'paidAmountMap');
      let productTotal = initReducerVariable(action, 'clearTotal', state, initialState, 'productTotal');
      let remainingAmountMap = initReducerVariable(action, 'remainingAmountMap', state, initialState);
      let sentCommissionTotal = initReducerVariable(action, 'clearTotal', state, initialState, 'sentCommissionTotal');
      let sentDiscountTotal = initReducerVariable(action, 'clearTotal', state, initialState, 'sentDiscountTotal');
      let sentProductTotal = initReducerVariable(action, 'clearTotal', state, initialState, 'sentProductTotal');
      let sentProductTotalMap = initReducerVariable(action, 'clearTotal', state, initialState, 'sentProductTotalMap');
      let sentProductWithCommissionTotalMap = initReducerVariable(action, 'clearTotal', state, initialState, 'sentProductWithCommissionTotalMap');
      let sentProductWithCommissionWithDiscountByPersonTotalMap = initReducerVariable(action, 'clearTotal', state, initialState, 'sentProductWithCommissionWithDiscountByPersonTotalMap');
      let sentProductWithCommissionWithDiscountTotalMap = initReducerVariable(action, 'clearTotal', state, initialState, 'sentProductWithCommissionWithDiscountTotalMap');
      let sentSaleItemList = initReducerVariable(action, 'sentSaleItemList', state, initialState);
      let sentSaleItemTotal = initReducerVariable(action, 'clearTotal', state, initialState, 'sentSaleItemTotal', 'sentSaleItemTotal');
      let sentSalePaymentItemMap = initReducerVariable(action, 'sentSalePaymentItemMap', state, initialState);
      let sentTotal = initReducerVariable(action, 'clearTotal', state, initialState, 'sentTotal');
      let total = initReducerVariable(action, 'clearTotal', state, initialState, 'total');

      // Verifica se alguma das variáveis deve ter seu valor recalculado.
      // Ou seja, testa se cada variável é diferente de SET e de UPDATE. Neste caso, nenhuma precisa ser recalculada.
      if (['newSalePaymentItemMap', 'remainingAmountMap', 'sentSaleItemList', 'sentSalePaymentItemMap'].every(actionVariable =>
        [actionTypes.REDUCER_SET, actionTypes.REDUCER_UPDATE].every(actionType => action[actionVariable] !== actionTypes[actionType]))) {
        // Se nenhuma tiver, atualiza o reducer com os valores inicializados acima (ou o valor inicial ou o valor anterior).
        const novoEstado = {
          ...state,
          ...action,
          commissionTotal,
          discountTotal,
          hasCheckedProduct,
          hasCheckedProductMap,
          hasRemainingAmount,
          newCommissionTotal,
          newCommissionTotalRemaining,
          newDiscountTotal,
          newDiscountTotalRemaining,
          newProductTotal,
          newProductTotalRemaining,
          newProductTotalMap,
          newProductTotalRemainingMap,
          newProductWithCommissionTotalMap,
          newProductWithCommissionTotalRemainingMap,
          newProductWithCommissionWithDiscountByPersonTotalMap,
          newProductWithCommissionWithDiscountByPersonTotalRemainingMap,
          newProductWithCommissionWithDiscountTotalMap,
          newProductWithCommissionWithDiscountTotalRemainingMap,
          newSalePaymentItemMap,
          newTotal,
          newTotalRemaining,
          paidAmountMap,
          productTotal,
          remainingAmountMap,
          sentCommissionTotal,
          sentDiscountTotal,
          sentProductTotal,
          sentProductTotalMap,
          sentProductWithCommissionTotalMap,
          sentProductWithCommissionWithDiscountByPersonTotalMap,
          sentProductWithCommissionWithDiscountTotalMap,
          sentSaleItemList,
          sentSaleItemTotal,
          sentSalePaymentItemMap,
          sentTotal,
          total
        };

        return novoEstado;
      }

      // Métodos para controlar a execução de outros métodos somente quando receber certos valores de variáveis
      let ifSetVariableThen = (actionVariable, callBack) => {
        if (actionVariable === actionTypes.REDUCER_SET) {
          callBack();
          return true;
        }
        return false;
      };
      let ifSetVariableListThen = (actionVariableList, callBack) => {
        if ((actionVariableList || []).some(actionVariable => action[actionVariable] === actionTypes.REDUCER_SET)) {
          callBack();
          return true;
        }
        return false;
      };
      let ifUpdateVariableThen = (actionVariable, callBack) => {
        if (actionVariable === actionTypes.REDUCER_UPDATE) {
          callBack();
          return true;
        }
        return false;
      };
      let ifSetRemainingAmountMapThen = callBack => ifSetVariableThen(action.remainingAmountMap, callBack);
      let ifSetSentSaleItemListThen = callBack => ifSetVariableThen(action.sentSaleItemList, callBack);
      let ifSetSentSaleItemListOrSetCommissionOrSetDiscountThen = callBack =>
        ifSetVariableThen(action.sentSaleItemList, callBack)
        || ifSetVariableThen((action.commission || {}).action, callBack)
        || ifSetVariableThen((action.discount || {}).action, callBack);
      let ifSetNewSalePaymentItemMapOrSetSentSaleItemListThen = callBack =>
        ifSetVariableThen(action.newSalePaymentItemMap, callBack)
        || ifSetVariableThen(action.sentSaleItemList, callBack);
      let ifSetSentSalePaymentItemMapThen = callBack => ifSetVariableThen(action.sentSalePaymentItemMap, callBack);
      let ifUpdateNewSalePaymentItemMapThen = callBack => ifUpdateVariableThen(action.newSalePaymentItemMap, callBack);

      ifSetSentSalePaymentItemMapThen(() => {
        // Inicializa os totais
        newCommissionTotal = 0;
        newDiscountTotal = 0;
        newProductTotal = 0;
        newTotal = 0;
        sentCommissionTotal = 0;
        sentDiscountTotal = 0;
        sentProductTotal = 0;
        sentTotal = 0;
      });
      ifSetSentSaleItemListOrSetCommissionOrSetDiscountThen(() => {
        // Inicializa os totais
        newCommissionTotalRemaining = 0;
        newDiscountTotalRemaining = 0;
        newProductTotalRemaining = 0;
        newTotalRemaining = 0;
      });
      ifSetSentSaleItemListThen(() => hasCheckedProduct = isEmpresaPainel(action.ramoEmpresa));

      /** Chave que indexa o mapa */
      let key;

      /** Chave que indexa a venda */
      let vendaURI;

      let origemVendaList = action.origemVendaList || state.origemVendaList || [];

      // Itera as origens de venda e as vendas para gerar as listas que montam as tabelas
      (origemVendaList || []).forEach(origemVenda => (origemVenda.vendaList || []).forEach(venda => {

        // Busca a chave que indexa a venda
        vendaURI = getURIFromEntity(venda);

        // Instancia a lista para cada venda
        ifSetSentSalePaymentItemMapThen(() => {
          // Inicializa os totais
          sentProductTotalMap[vendaURI] = 0;
          sentProductWithCommissionTotalMap[vendaURI] = 0;
          sentProductWithCommissionWithDiscountByPersonTotalMap[vendaURI] = 0;
          sentProductWithCommissionWithDiscountTotalMap[vendaURI] = 0;
        });

        ifSetSentSaleItemListThen(() => hasCheckedProductMap[vendaURI] = isEmpresaPainel(action.ramoEmpresa));

        // Itera os pagamentos da venda e os itens de cada
        (venda.pagamentoVendaList || []).forEach(pagamentoVenda => {
          // Soma os totais a partir dos pagamentos
          ifSetSentSalePaymentItemMapThen(() => {

            sentCommissionTotal = roundNumber(
              sentCommissionTotal
              + pagamentoVenda.valorComissao, 2);

            sentDiscountTotal = roundNumber(
              sentDiscountTotal
              + pagamentoVenda.valorDesconto, 2);

            sentProductTotal = roundNumber(
              sentProductTotal
              + pagamentoVenda.valorProdutos, 2);

            sentTotal = roundNumber(
              sentTotal
              + pagamentoVenda.valorTotal, 2);

            sentProductTotalMap[vendaURI] = roundNumber(
              sentProductTotalMap[vendaURI]
              + pagamentoVenda.valorProdutos, 2);

            sentProductWithCommissionTotalMap[vendaURI] = roundNumber(
              sentProductWithCommissionTotalMap[vendaURI]
              + pagamentoVenda.valorProdutos
              + pagamentoVenda.valorComissao, 2);

            sentProductWithCommissionWithDiscountTotalMap[vendaURI] = roundNumber(
              sentProductWithCommissionWithDiscountTotalMap[vendaURI]
              + pagamentoVenda.valorTotal, 2);

            sentProductWithCommissionWithDiscountByPersonTotalMap[vendaURI] = truncateNumber(
              sentProductWithCommissionWithDiscountTotalMap[vendaURI]
              / venda.numeroPessoas, 2);
          });
          // Itera os itens de pagamento
          (pagamentoVenda.itemPagamentoVendaList || []).forEach(itemPagamentoVenda => {
            // Monta a chave que indexa o mapa
            ifSetVariableListThen(['sentSaleItemList', 'remainingAmountMap', 'sentSalePaymentItemMap'], () => key = saleProductKey(venda, itemPagamentoVenda));
            // Monta as quantidades já pagas
            ifSetSentSalePaymentItemMapThen(() => buildAmountMap(key, itemPagamentoVenda, paidAmountMap, +1));
            // Inicia a montagem das quantidades restantes
            ifSetRemainingAmountMapThen(() => buildAmountMap(key, itemPagamentoVenda, remainingAmountMap, -1));
            // Adiciona o item mapeado na lista
            ifSetSentSalePaymentItemMapThen(() => buildSalePaymentItemMap(key, vendaURI, sentSalePaymentItemMap, itemPagamentoVenda, true, undefined, action.ramoEmpresa));
          });
        });

        ifSetSentSaleItemListThen(() => {
          // Se for manutenção de venda, faz uma cópia das quantidades pagas para poder decrementar elas.
          accumulatedPaidAmountMap = JSON.parse(JSON.stringify(paidAmountMap));
          // Inicializa os totais a partir dos itens de venda
          newProductTotalMap[vendaURI] = 0;
          newProductWithCommissionTotalMap[vendaURI] = 0;
          newProductWithCommissionWithDiscountByPersonTotalMap[vendaURI] = 0;
          newProductWithCommissionWithDiscountTotalMap[vendaURI] = 0;
          sentSaleItemTotal = 0;
        });

        ifSetSentSaleItemListOrSetCommissionOrSetDiscountThen(() => {
          // Inicializa os totais a partir dos itens de venda
          newProductTotalRemainingMap[vendaURI] = 0;
          newProductWithCommissionTotalRemainingMap[vendaURI] = 0;
          newProductWithCommissionWithDiscountByPersonTotalRemainingMap[vendaURI] = 0;
          newProductWithCommissionWithDiscountTotalRemainingMap[vendaURI] = 0;
        });

        // Inicializa a variável que indica se há itens que ainda não foram totalmente pagos.
        ifSetRemainingAmountMapThen(() => hasRemainingAmount = false);

        // Ordena os novos itens de venda caso algum tratamento necessite de ordem consistente.
        // Os itens são ordenados de acordo com o seu horário convertido para UTC e exibidos na hora local da aplicação.
        // Esse comportamento foi escolhido porque, para a cozinha, importa quem pediu um item antes para saber qual produzir antes.
        // Essa comparação deve ser feita em UTC porque, se o item A foi solicitado às 13:00 -4 e o item B foi solicitado às 13:30 -3,
        // o item B deve ser produzido primeiro. 13:00 vem antes de 13:30, mas 13:00 -4 corresponde à 17:00 UTC
        // e 13:30 -3 corresponde à 16:30 UTC. Como 17:00 vem depois de 16:30, B deve ser produzido primeiro.
        // Com relação à exibição, é preferível não exibir no fuso horário original para não causar confusão, como no exemplo acima
        // onde 13:30 apareceria antes de 13:00, e para que a hora exibida seja a mesma para quem enviou aquele item.
        (venda.itemVendaList || []).sort(itemVendaAscendingUTCSort).forEach(itemVenda => {
          // Monta a chave que indexa o mapa
          ifSetVariableListThen(['sentSaleItemList', 'remainingAmountMap'], () => key = saleProductKey(venda, itemVenda));
          // Finaliza a montagem das quantidades restantes
          ifSetRemainingAmountMapThen(() => {
            buildAmountMap(key, itemVenda, remainingAmountMap, +1);

            if (remainingAmountMap[key] > 0) {
              hasRemainingAmount = true;
            }
          });
          ifSetSentSaleItemListThen(() => {
            // Adiciona o item mapeado na lista de itens de pagamento de venda enviados
            buildSentSaleItemList(sentSaleItemList, itemVenda, venda, accumulatedPaidAmountMap);
            // Itera os itens de venda persistidos para definir o valor total
            sentSaleItemTotal += itemVenda.valorTotal;
          });
          ifSetNewSalePaymentItemMapOrSetSentSaleItemListThen(() =>
            // Adiciona o item mapeado na lista de itens de pagamento de venda novos
            buildSalePaymentItemMap(key, vendaURI, newSalePaymentItemMap, itemVenda, false, remainingAmountMap, action.ramoEmpresa)
          );
        });

        // Soma os totais a partir dos itens de venda
        ifSetSentSaleItemListOrSetCommissionOrSetDiscountThen(() => {
          Object.values(newSalePaymentItemMap || {}).filter(newSalePaymentItem => vendaURI === newSalePaymentItem.vendaURI).forEach(newSalePaymentItem => {

            let value = roundNumber(remainingAmountMap[newSalePaymentItem.key] * ((newSalePaymentItem.produto.quantidadePesada && (!newSalePaymentItem.livre))
              ? roundNumber(newSalePaymentItem.precoUnitario * newSalePaymentItem.weightedAmount, 2)
              : newSalePaymentItem.precoUnitario), 2);

            newProductTotalRemainingMap[newSalePaymentItem.vendaURI] = roundNumber(
              newProductTotalRemainingMap[newSalePaymentItem.vendaURI]
              + value, 2);

            newProductTotalRemaining = roundNumber(
              newProductTotalRemaining
              + value, 2);

            let valueWithCommission = roundNumber(newSalePaymentItem.produto.quantidadePesada
              // Se for produto com quantidade pesada, aplica a comissão em cada "prato" individualmente,
              // mesmo que seja livre e mesmo que coincidentemente tenha dado o mesmo peso.
              ? (remainingAmountMap[newSalePaymentItem.key] * roundNumber(applyCommission((newSalePaymentItem.livre
                ? 1
                : newSalePaymentItem.weightedAmount) * newSalePaymentItem.precoUnitario, (action.commission || {}).value), 2))
              // Se não, aplica a comissão em toda a quantidade do produto.
              : (applyCommission(remainingAmountMap[newSalePaymentItem.key] * newSalePaymentItem.precoUnitario, (action.commission || {}).value)), 2);

            newProductWithCommissionTotalRemainingMap[newSalePaymentItem.vendaURI] = roundNumber(
              newProductWithCommissionTotalRemainingMap[newSalePaymentItem.vendaURI]
              + valueWithCommission, 2);

            newCommissionTotalRemaining = roundNumber(
              newCommissionTotalRemaining
              + valueWithCommission - value, 2);
          });

          let valueWithCommissionWithDiscount = Math.max((action.discount.type === DISCOUNT_TYPE_PERCENTAGE)
            ? applyDiscount((newProductWithCommissionTotalRemainingMap[vendaURI] || 0), ((action.discount || {}).value || 0))
            : ((newProductWithCommissionTotalRemainingMap[vendaURI] || 0) - ((action.discount || {}).value || 0)), 0);

          newProductWithCommissionWithDiscountTotalRemainingMap[vendaURI] = valueWithCommissionWithDiscount;

          newProductWithCommissionWithDiscountByPersonTotalRemainingMap[vendaURI] = truncateNumber(
            valueWithCommissionWithDiscount
            / venda.numeroPessoas, 2);

          newDiscountTotalRemaining = roundNumber(
            newDiscountTotalRemaining
            + newProductWithCommissionTotalRemainingMap[vendaURI] - valueWithCommissionWithDiscount, 2);
        });
        // Atualiza o estado do check box de cada venda.
        // Define estado marcado se empresa é do ramo Painel, pois deve iniciar marcado, e se há itens para pagar.
        // Poderá acontecer de vendas ficarem com o check box marcado mesmo que não tenham itens para pagar.
        // Porém, como isto só está sendo usado para empresas do ramo Painel, e não é possível pagar várias vendas neste caso, não importa.
        ifSetSentSaleItemListThen(() => hasCheckedProductMap[vendaURI] = isEmpresaPainel(action.ramoEmpresa));
      }));

      ifSetSentSaleItemListOrSetCommissionOrSetDiscountThen(() =>
        newTotalRemaining = newProductTotalRemaining + newCommissionTotalRemaining - newDiscountTotalRemaining
      );

      // Atualiza a quantidade de um novo item de pagamento de venda
      ifUpdateNewSalePaymentItemMapThen(() => {
        (action.salePaymentItemKeyList || []).forEach(salePaymentItemKey => {
          // Busca o novo item de pagamento de venda
          let salePaymentItem = newSalePaymentItemMap[salePaymentItemKey] || { produto: {} };
          // Busca a quantidade restante de pagamento
          let remainingAmount = remainingAmountMap[salePaymentItemKey];
          // Se for para incrementar e se a quantidade selecionada for maior ou igual do que a quantidade ainda não paga, não precisa alterar pois já está tudo pago.
          if (((action.sign > 0) && (salePaymentItem.quantidade >= remainingAmount))
            // Ou se for para decrementar e se a quantidade selecionada for menor ou igual a zero, não precisa alterar pois já está no mínimo possível.
            || ((action.sign < 0) && (salePaymentItem.quantidade <= 0))) {
            return;
          }
          // Define a nova quantidade, ou a mesma quantidade anterior se for só para atualizar o valor total.
          let amount = (!action.sign) ? salePaymentItem.quantidade : (action.sign > 0)
            // Se for para incrementar
            ? action.all
              // Se for para incrementar completamente, atribui o valor da quantidade restante.
              ? remainingAmount
              // Se for para incrementar normalmente, adiciona 1 à quantidade.
              : (salePaymentItem.quantidade + 1)
            // Se for para decrementar
            : (action.all
              // Zera a quantidade se for para decrementar tudo
              ? 0
              // Se não for para decrementar tudo, decrementa a quantidade em 1.
              : (salePaymentItem.quantidade - 1));
          // Atualiza o novo item de pagamento de venda
          salePaymentItem = Object.assign({}, salePaymentItem, {
            checked: !!amount,
            quantidade: amount,
            valorTotal: roundNumber(salePaymentItem.produto.quantidadePesada
              // Se for produto com quantidade pesada, aplica a comissão em cada "prato" individualmente,
              // mesmo que seja livre e mesmo que coincidentemente tenha dado o mesmo peso.
              ? (amount * roundNumber(applyCommission((salePaymentItem.livre
                ? 1
                : salePaymentItem.weightedAmount) * salePaymentItem.precoUnitario, (action.commission || {}).value), 2))
              // Se não, aplica a comissão em toda a quantidade do produto.
              : (applyCommission(amount * salePaymentItem.precoUnitario, (action.commission || {}).value)), 2)
          });
          // Atualiza a lista de novos itens de pagamento de venda com o novo item de pagamento de venda atualizado
          newSalePaymentItemMap[salePaymentItemKey] = salePaymentItem;
        });
        // Verifica se há itens selecionados para pagamento
        hasNewSalePaymentItemToSend = Object.values(newSalePaymentItemMap || {}).some(newSalePaymentItem => newSalePaymentItem.quantidade > 0);
        // Inicializa os totais das vendas que precisam ser recalculados
        (origemVendaList || []).forEach(origemVenda => (origemVenda.vendaList || [])
          .map(venda => getURIFromEntity(venda))
          .filter(vendaURI => (action.salePaymentItemKeyList || []).some(key => isSaleProductKeyFromSale(key, vendaURI)))
          .forEach(vendaURI => {

            newProductTotalMap[vendaURI] = 0;
            newProductWithCommissionTotalMap[vendaURI] = 0;
            newProductWithCommissionWithDiscountTotalMap[vendaURI] = 0;
            newProductWithCommissionWithDiscountByPersonTotalMap[vendaURI] = 0;
          }));
        // Itera os novos itens de pagamento de venda para recalcular os totais de suas vendas que são calculados por item
        Object.values(newSalePaymentItemMap || {})
          .filter(newSalePaymentItem => (action.salePaymentItemKeyList || []).some(key => isSaleProductKeyFromSale(key, newSalePaymentItem.vendaURI)))
          .forEach(newSalePaymentItem => {

            let value = roundNumber((newSalePaymentItem.produto.quantidadePesada && (!newSalePaymentItem.livre))
              ? (newSalePaymentItem.quantidade * roundNumber(newSalePaymentItem.precoUnitario * newSalePaymentItem.weightedAmount, 2))
              : (newSalePaymentItem.precoUnitario * newSalePaymentItem.quantidade), 2);

            let valueWithCommission = roundNumber(newSalePaymentItem.produto.quantidadePesada
              // Se for produto com quantidade pesada, aplica a comissão em cada "prato" individualmente,
              // mesmo que seja livre e mesmo que coincidentemente tenha dado o mesmo peso.
              ? (newSalePaymentItem.quantidade * roundNumber(applyCommission((newSalePaymentItem.livre
                ? 1
                : newSalePaymentItem.weightedAmount) * newSalePaymentItem.precoUnitario, (action.commission || {}).value), 2))
              // Se não, aplica a comissão em toda a quantidade do produto.
              : (applyCommission(newSalePaymentItem.quantidade * newSalePaymentItem.precoUnitario, (action.commission || {}).value)), 2)

            newProductTotalMap[newSalePaymentItem.vendaURI] = roundNumber(
              newProductTotalMap[newSalePaymentItem.vendaURI]
              + value, 2);

            newProductWithCommissionTotalMap[newSalePaymentItem.vendaURI] = roundNumber(
              newProductWithCommissionTotalMap[newSalePaymentItem.vendaURI]
              + valueWithCommission, 2);
          });
        // Itera as vendas que precisam ser recalculadas para calcular seus totais que são calculados por venda
        (origemVendaList || []).forEach(origemVenda => (origemVenda.vendaList || [])
          .map(venda => ({
            vendaURI: getURIFromEntity(venda),
            numeroPessoas: venda.numeroPessoas
          }))
          .filter(venda => (action.salePaymentItemKeyList || []).some(key => isSaleProductKeyFromSale(key, venda.vendaURI)))
          .forEach(venda => {

            newProductWithCommissionWithDiscountTotalMap[venda.vendaURI] = Math.max((action.discount.type === DISCOUNT_TYPE_PERCENTAGE)
              ? applyDiscount((newProductWithCommissionTotalMap[venda.vendaURI] || 0), ((action.discount || {}).value || 0))
              : ((newProductWithCommissionTotalMap[venda.vendaURI] || 0) - ((action.discount || {}).value || 0)), 0);

            newProductWithCommissionWithDiscountByPersonTotalMap[venda.vendaURI] = truncateNumber(
              newProductWithCommissionWithDiscountTotalMap[venda.vendaURI]
              / venda.numeroPessoas, 2);
          })
        );
        // Inicializa os totais
        newProductTotal = 0;
        newCommissionTotal = 0;
        newDiscountTotal = 0;
        newTotal = 0;
        // Itera todos os novos itens de pagamento de venda para recalcular os totais que são calculados por item
        Object.values(newSalePaymentItemMap || {}).forEach(newSalePaymentItem => {

          let valueProduct = roundNumber((newSalePaymentItem.produto.quantidadePesada && (!newSalePaymentItem.livre))
            ? (newSalePaymentItem.quantidade * roundNumber(newSalePaymentItem.precoUnitario * newSalePaymentItem.weightedAmount, 2))
            : (newSalePaymentItem.precoUnitario * newSalePaymentItem.quantidade), 2);

          let valueCommission = roundNumber(newSalePaymentItem.produto.quantidadePesada
            // Se for produto com quantidade pesada, aplica a comissão em cada "prato" individualmente,
            // mesmo que seja livre e mesmo que coincidentemente tenha dado o mesmo peso.
            ? (newSalePaymentItem.quantidade * roundNumber(applyPercentage((newSalePaymentItem.livre
              ? 1
              : newSalePaymentItem.weightedAmount) * newSalePaymentItem.precoUnitario, (action.commission || {}).value), 2))
            // Se não, aplica a comissão em toda a quantidade do produto.
            : (applyPercentage(newSalePaymentItem.quantidade * newSalePaymentItem.precoUnitario, (action.commission || {}).value)), 2)

          newProductTotal = roundNumber(newProductTotal + valueProduct, 2);

          newCommissionTotal = roundNumber(newCommissionTotal + valueCommission, 2);
        });
        // Itera todas as vendas para calcular seus totais que são calculados por venda
        (origemVendaList || []).forEach(origemVenda => (origemVenda.vendaList || []).forEach(venda => {
          // Busca a chave que indexa a venda
          vendaURI = getURIFromEntity(venda);

          newDiscountTotal = roundNumber(
            newDiscountTotal
            + newProductWithCommissionTotalMap[vendaURI]
            - newProductWithCommissionWithDiscountTotalMap[vendaURI], 2);
        }));
        // Calcula o total de tudo não persistido.
        newTotal = roundNumber(newProductTotal + newCommissionTotal - newDiscountTotal, 2);
        // Atualiza as variáveis que indicam o estado dos check boxes das vendas e o geral
        // Inicializa com false.
        // Qualquer produto que estiver parcialmente ou completamente selecionado ocasiona na sua venda e no geral também ficar selecionado.
        hasCheckedProduct = false;
        // Limpa o mapa. Os true e false serão adicionados depois.
        hasCheckedProductMap = {};
        // Itera os novos itens de pagamento para definir o estado do check box das suas vendas
        Object.values(newSalePaymentItemMap || {}).forEach(newSalePaymentItem => {
          // Se ainda não há nem true nem false para esta venda, define false.
          if (!isBoolean(hasCheckedProductMap[newSalePaymentItem.vendaURI])) {
            hasCheckedProductMap[newSalePaymentItem.vendaURI] = false;
          }
          // Se check box do item está selecionado, marca como selecionado o da venda e o geral.
          if (newSalePaymentItem.checked) {
            hasCheckedProduct = true;
            hasCheckedProductMap[newSalePaymentItem.vendaURI] = true;
          }
        });
      });
      // Calcula o total de tudo não persistido.
      ifSetSentSaleItemListOrSetCommissionOrSetDiscountThen(() => {
        productTotal = roundNumber(newProductTotalRemaining + sentProductTotal, 2);
        commissionTotal = roundNumber(newCommissionTotalRemaining + sentCommissionTotal, 2);
        discountTotal = roundNumber(newDiscountTotalRemaining + sentDiscountTotal, 2);
        total = roundNumber(newTotalRemaining + sentTotal, 2);
      });

      return updateObject(state, {
        ...state,
        ...action,
        commissionTotal,
        discountTotal,
        hasCheckedProduct,
        hasCheckedProductMap,
        hasNewSalePaymentItemToSend,
        hasRemainingAmount,
        newCommissionTotal,
        newCommissionTotalRemaining,
        newDiscountTotal,
        newDiscountTotalRemaining,
        newProductTotal,
        newProductTotalRemaining,
        newProductTotalMap,
        newProductTotalRemainingMap,
        newProductWithCommissionTotalMap,
        newProductWithCommissionTotalRemainingMap,
        newProductWithCommissionWithDiscountByPersonTotalMap,
        newProductWithCommissionWithDiscountByPersonTotalRemainingMap,
        newProductWithCommissionWithDiscountTotalMap,
        newProductWithCommissionWithDiscountTotalRemainingMap,
        newSalePaymentItemMap,
        newTotal,
        newTotalRemaining,
        paidAmountMap,
        productTotal,
        remainingAmountMap,
        sentCommissionTotal,
        sentDiscountTotal,
        sentProductTotal,
        sentProductTotalMap,
        sentProductWithCommissionTotalMap,
        sentProductWithCommissionWithDiscountByPersonTotalMap,
        sentProductWithCommissionWithDiscountTotalMap,
        sentSaleItemList,
        sentSaleItemTotal,
        sentSalePaymentItemMap,
        sentTotal,
        total
      });

    case actionTypes.CONTROLE_VENDA_CODE_READER_OPENED:
    case actionTypes.CONTROLE_VENDA_LOAD_FREE:
    case actionTypes.CONTROLE_VENDA_LOAD_UNREAD:
    case actionTypes.CONTROLE_VENDA_PERSIST_VENDA:
    case actionTypes.CONTROLE_VENDA_RESET_STATE:
    case actionTypes.CONTROLE_VENDA_SET_VALUE:
    case actionTypes.CONTROLE_VENDA_SHOW_GRID_ORIGENS:
    case actionTypes.CONTROLE_VENDA_SHOW_MANUTENCAO_VENDA:
    case actionTypes.CONTROLE_VENDA_SHOW_PAGAMENTO_VENDAS:

      // Basta controlar a construção do action no outro arquivo.
      return updateObject(state, {
        ...state,
        ...action
      });

    case actionTypes.CONTROLE_VENDA_REMOVE_FREE_ORIGEM_VENDA:

      return updateObject(state, {
        ...state,
        freeOrigemVendaList: (state.freeOrigemVendaList || []).filter(origemVenda => !entityURIEqualsURI(origemVenda, action.freeOrigemVenda || {}))
      });

    case actionTypes.CONTROLE_VENDA_REMOVE_ORIGEM_VENDA_IN_USE:

      return updateObject(state, {
        ...state,
        origemVendaList: (state.origemVendaList || []).filter(origemVenda => !entityURIEqualsURI(origemVenda, action.origemVendaInUse || {}))
      });

    default: return state;
  }
};

/**
 * Monta ou atualiza um mapa de quantidades (já pagas ou restantes), adicionando ou subtraindo quantidades.
 * @param {Object} key chave que identifica item (de pagamento) de venda por sua venda, seu produto, se é livre e, se for quantidade pesada, sua quantidade
 * @param {Array} item pode ser um item de venda ou um item de pagamento de venda
 * @param {Object} amountMap objeto já instanciado que recebe os dados; pode ser um mapa de quantidade restante ou de quantidade já paga
 * @param {Number} sign define se serão feitas operações de adição (`+1`) ou de subtração (`-1`)
 */
const buildAmountMap = (key, item, amountMap, sign) => {
  // Busca a quantidade restante ou paga da venda informada
  let amountOld = amountMap[key];
  // Define a variação que será aplicada na quantidade.
  let amountDelta = item.produto.quantidadePesada
    // Se for um produto com quantidade pesada, considera cada item como uma unidade.
    ? 1
    // Senão, é a própria quantidade do item.
    : item.quantidade;
  // Se já existir quantidade restante ou paga para esta venda e produto
  if (amountOld) {
    // In(de)crementa a quantidade deste item
    amountOld = amountOld + (sign * amountDelta);
  }
  // Se ainda não houver quantidade restante ou paga para esta venda e produto
  else {
    // Inicializa com a quantidade deste item
    amountOld = sign * amountDelta;
  }
  // Adiciona a quantidade restante ou paga ao mapa para esta venda e produto
  amountMap[key] = amountOld;
};

/**
 * Monta a lista de itens de venda enviados.
 * Lê o mapa de quantidades pagas por venda e produto para determinar se o item pode ser cancelado.
 * Na medida em que o mapa é lido, suas quantidades poderão ser substituídas por valores menores, até zerar.
 * @param {Object} venda de onde os itens de venda persistidos são lidos
 * @param {Array} sentSaleItemList lista já instanciada que recebe os itens mapeados
 * @param {Object} venda para montar a chave para acessar as quantidades pagas por venda e produto
 * @param {Object} accumulatedPaidAmountMap mapa de quantidades pagas indexado por venda e produto
 */
const buildSentSaleItemList = (sentSaleItemList, itemVenda, venda, accumulatedPaidAmountMap) => {
  /** Indica se item de venda pode ser cancelado */
  let removeEnabled = true;
  /** Quantidade paga que não está totalmente vinculada a um item de venda */
  let remainingPaidAmount = 0;
  // Define variáveis que controlam se o item pode ser removido.
  updateItemVendaRemovabilityAndPaidAmount(
    accumulatedPaidAmountMap, saleProductKey(venda, itemVenda), itemVenda, (removeEnabledP, remainingPaidAmountP) => {
      removeEnabled = removeEnabledP;
      remainingPaidAmount = remainingPaidAmountP;
    }
  );
  // Adiciona o item mapeado na lista
  sentSaleItemList.push({
    dataHora: itemVenda.dataHora,
    estado: itemVenda.estado,
    fusoHorario: itemVenda.fusoHorario,
    livre: itemVenda.livre,
    observacao: itemVenda.observacao,
    precoUnitario: itemVenda.precoUnitario,
    produto: {
      codigo: itemVenda.produto.codigo,
      nome: itemVenda.produto.nome,
      quantidadePesada: itemVenda.produto.quantidadePesada,
    },
    nomeProduto: itemVenda.nomeProduto,
    quantidade: itemVenda.quantidade,
    remainingPaidAmount,
    removeEnabled,
    valorTotal: itemVenda.valorTotal,
    _links: itemVenda._links
  });
};

/**
 * Monta as listas de itens de pagamento de venda a enviar e persistidos.
 * 
 * Itens são agrupados por produto.
 * 
 * Itens já pagos não geram itens a enviar.
 * @param {Object} key
 * @param {Object} vendaURI de onde os itens de pagamento de venda persistidos são lidos
 * @param {Object} salePaymentItemMap objeto já instanciado que recebe os itens mapeados
 * @param {Object} itemPagamentoVenda entidade
 * @param {Boolean} sent se é um item novo ou um item persistido
 * @param {Object} remainingAmountMap mapa de quantidades restantes indexado por chave gerada por `saleProductKey`
 * @param {String} ramoEmpresa se Painel, itens começam marcados para pagamento
 */
const buildSalePaymentItemMap = (key, vendaURI, salePaymentItemMap, itemPagamentoVenda, sent, remainingAmountMap, ramoEmpresa) => {
  // Verifica se é um item a enviar e se já foi pago
  // Usa os valores do mapa de quantidades restantes na medida em que ele é construído para só gerar tantos itens quanto ainda há de quantidade restante
  if ((!sent) && (remainingAmountMap[key] < 1)) {
    // Não gera um item a enviar neste caso
    return;
  }
  // Busca um item já existente na lista
  let oldItem = salePaymentItemMap[key];
  // Item que será adicionado na lista, ou no final ou por cima de outro item.
  let newItem = oldItem;
  // Se não encontrou um item já existente
  if (!oldItem) {
    // Gera um novo item
    newItem = {
      key,
      livre: itemPagamentoVenda.livre,
      precoCustoUnitario: itemPagamentoVenda.produto.precoCusto,
      precoUnitario: itemPagamentoVenda.precoUnitario,
      produto: {
        codigo: itemPagamentoVenda.produto.codigo,
        nome: itemPagamentoVenda.produto.nome,
        produtoURI: getURIFromEntity(itemPagamentoVenda.produto),
        quantidadePesada: itemPagamentoVenda.produto.quantidadePesada
      },
      nomeProduto: itemPagamentoVenda.nomeProduto,
      quantidade: sent
        // Se for um item persistido usa a quantidade do item.
        ? (itemPagamentoVenda.produto.quantidadePesada
          // Se for um produto com quantidade pesada, a quantidade indica a quantidade de itens.
          ? 1
          // Se for um produto com quantidade unitária, a quantidade é a própria quantidade do item.
          : itemPagamentoVenda.quantidade)
        // Se for para iniciar com o item selecionado (quando for empresa do ramo Painel)
        : (!sent)
          // Usa a quantidade restante do item
          ? remainingAmountMap[key]
          // Se for um item novo ou se não for para iniciar com o item selecionado, usa zero, já que é só a partir de agora que será possível selecionar uma quantidade.
          : 0,
      // Se for um produto com quantidade pesada, não usa controle de quantidade restante, mas essa quantidade deve ser memorizada em algum lugar.
      ...(itemPagamentoVenda.produto.quantidadePesada ? { weightedAmount: itemPagamentoVenda.quantidade } : {}),
      valorTotal: sent ? itemPagamentoVenda.valorTotal : 0,
      vendaURI
    };
  }
  // Se encontrou um item já existente, testa mais nada, pois foi alterado para sempre iniciar com o item selecionado.
  else {
    // Junta os dois itens
    newItem = Object.assign({}, oldItem, {
      // Se for para iniciar com o item selecionado (quando for empresa do ramo Painel)
      quantidade: (!sent)
        // Usa a quantidade restante do item (agora atualizada)
        ? remainingAmountMap[key]
        // Senão, soma com a quantidade anterior.
        : (oldItem.quantidade + (itemPagamentoVenda.produto.quantidadePesada
          // Se for um produto com quantidade pesada, a quantidade indica a quantidade de itens.
          ? 1
          // Se for um produto com quantidade unitária, a quantidade é a própria quantidade do item.
          : itemPagamentoVenda.quantidade)),
      valorTotal: oldItem.valorTotal + itemPagamentoVenda.valorTotal
    });
  }
  // Adiciona o item mapeado na lista
  salePaymentItemMap[key] = newItem;
};

/**
 * Compara dois itens de venda pela sua data e hora.
 * @param {Object} a primeiro item de venda
 * @param {Object} b segundo item de venda
 * @returns {Number} `-1` se o primeiro tem data e hora mais antiga, `1` se mais recente e `0` se igual
 */
const itemVendaAscendingUTCSort = (a, b) => ascendingUTCSort(a.dataHora, b.dataHora);

/**
 * Monta chave que indexa por venda, código do produto e, se for produto com quantidade pesada, por quantidade e livre.
 * @param {Object} venda 
 * @param {Object} item 
 */
export const saleProductKey = (venda, item) => {
  const key = `${getURIFromEntity(venda)}?c=${item.produto.codigo}${item.nomeProduto ? item.nomeProduto.replace(/\s+/g, '') : ''}${item.produto.quantidadePesada ? `&l=${item.livre ? 1 : 0}` : ''}${item.produto.quantidadePesada ? `&q=${item.quantidade}` : ''}`;
  return key;
};

/**
 * Verifica se item de venda pode ser removido, marca ele como tal e atualiza mapa de quantidades pagas para ser usado no processamento dos próximos itens.
 * @param {*} accumulatedPaidAmountMap mapa de quantidades pagas por venda e produto. No caso de produtos vendidos por peso, este mapa será indexado pela quantidade do item e estará contido no mapa anterior. Poderá ter sua quantidade alterada.
 * @param {*} key ou venda e produto ou quantidade
 * @param {*} itemVenda item de venda sendo atualizado
 * @param {*} callBack função para retornar as variáveis atualizadas
 */
const updateItemVendaRemovabilityAndPaidAmount = (accumulatedPaidAmountMap, key, itemVenda, callBack) => {

  // Cálculo para impedir que itens de venda já pagos sejam cancelados.
  // São impedidos os itens que fazem parte de um subconjunto que:
  // 1. É contíguo;
  // 2. Contém os itens de venda mais velhos;
  // 3. A quantidade paga daquele produto é maior que a soma da quantidade de produto dos itens de venda.
  // Dadas essas regras, é garantido que a quantidade paga daquele produto que excede a quantidade dos itens de venda não canceláveis
  // é menor do que a quantidade do primeiro item de venda cancelável. Por isso, ao cancelar um item de venda, uma de duas coisas pode acontecer:
  // 1. Se a quantidade paga excedente de um produto for nula, o item é excluído;
  // 2. Se não, e se for o primeiro item de venda cancelável, a quantidade do item de venda é alterada para esse valor.

  /** Indica se item de venda pode ser cancelado */
  let removeEnabled = true;
  /** Quantidade paga que não está totalmente vinculada a um item de venda */
  let remainingPaidAmount = 0;
  /** Quantidade do item. Se for produto vendido por peso, é a quantidade *de* itens. */
  let amount = itemVenda.quantidade;
  // Busca a quantidade paga
  let paidAmount = accumulatedPaidAmountMap[key] || 0;
  // Verifica se item não pode ser cancelado por causa do estado
  if (itemAlreadyProducedStateList.some(state => itemVenda.estado === state)
    // ou se ainda há quantidade paga e se essa quantidade cobre a quantidade do item
    || ((paidAmount > 0) && (amount <= paidAmount))
  ) {
    // Se o estado impedir, item não pode ser cancelado.
    removeEnabled = false;
    // Atualiza a quantidade
    accumulatedPaidAmountMap[key] = Math.max(paidAmount - amount, 0);
  }
  // Se item pode ser cancelado e ainda há quantidade paga, mas que não cobre totalmente o item.
  else {
    // Atribui o restante da quantidade paga ao item para que, quando for cancelado,
    // ele tenha sua quantidade reduzida ao invés de ser cancelado.
    remainingPaidAmount = paidAmount;
    // Atualiza a quantidade
    accumulatedPaidAmountMap[key] = 0;
  }
  callBack(removeEnabled, remainingPaidAmount);
};

export default reducer;
