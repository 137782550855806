import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '../../../apps/promo_app_frontend/nucleo/nucleo';

import "./BoxTemplate.css";

import CadastroHeader from '../../cadastros/header/CadastroHeader';

import { connect } from 'react-redux';
import { push } from 'connected-react-router';

const contentType = {
    edit: {
        className: 'spaced-box-content', 
    }
};

/**
 * 
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement} props 
 * @returns 
 */
function Div(props) { return <div {...props} /> }

class BoxTemplate extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node,
        contentClassName: PropTypes.string,
        contentStyle: PropTypes.any,
        dispatch: PropTypes.func,
        form: PropTypes.bool,
        goTo: PropTypes.func,
        headerStyle: PropTypes.any,
        style: PropTypes.any,
        title: PropTypes.string,
        type: PropTypes.string,
    };

    render() {
        const content = contentType[this.props.type] || {
            className: null,
        };

        const Box = this.props.form
            ? Form
            : Div

        return <Box id="cadastro-box" className={this.props.className} style={this.props.style}>
            {this.props.hide_header
                ? null
                : <CadastroHeader title={this.props.title} style={this.props.headerStyle} />
            }
            {this.props.toolbar && !this.props.hide_toolbar
                ? this.props.toolbar({goTo: this.props.goTo})
                : null
            }
            <div id="conteudo-box" style={this.props.contentStyle} className={`${content.className || ''} ${this.props.contentClassName || ''}`}>
                {this.props.children}
            </div>
        </Box>;
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
    dispatch,
    goTo: (caminho) => dispatch(push(caminho)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BoxTemplate);
