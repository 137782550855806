import { atualizarUsuario } from '../usuario/usuarioActions';
import { ClienteHttp, EntidadeUtils, ProdutoUtils } from '../../utils/utils';
import { adicionarGruposAction } from '../produtos/grupoProdutos/grupoProdutosActions';
import { popularProdutos } from '../produtos/produtos/produtosActions';
import moment from 'moment';
import { parametrosEmpresaReducerTipo } from '../parametrosEmpresa/parametrosEmpresaReducer';
import { grupoProdutosReducerTipoSemGrupoCarregado } from '../produtos/grupoProdutos/grupoProdutosReducer';
import { produtosTipo } from '../produtos/produtos/produtosReducer';
import { dispatchTipo } from '../../../../../store/actions/acaoTemplate';

export const carregarProdutos = (parametrosEmpresa: parametrosEmpresaReducerTipo, dispatch: dispatchTipo): Promise<{listaProdutos: Array<any>, grupoProdutoList: Array<any>}> => {
    dispatch(atualizarUsuario());

    if (parametrosEmpresa.idOrigemVendaDaEmpresa !== undefined) {
      const origemVendaId = ClienteHttp.getApi(`origemVenda/${parametrosEmpresa.idOrigemVendaDaEmpresa}`);

      return ClienteHttp.requisicaoServidor(`grupoProdutos/findListWithProdutosVinculados`, 'get', true, {
        params: {
          usoInterno: false,
          origemVenda: origemVendaId,
        }
      })
        .then((resposta: any) => {
          const content = (resposta.data || {}).content || {};
          const listaProdutos: Array<any> = content.produtoList || [];
          const grupoProdutoList: Array<any> = content.grupoProdutoList || [];

          return {listaProdutos, grupoProdutoList};
        });
    }
    
    return new Promise((_: any, reject: () => void) => { reject(); });
}

export const formatarProduto = async (listaProdutos: Array<any>, grupoProdutoList: any, parametrosEmpresa: parametrosEmpresaReducerTipo, dispatch: dispatchTipo) => {
  const gruposComProdutos: grupoProdutosReducerTipoSemGrupoCarregado = {
    grupoId: '',
    grupos: {

    },
  };

  let produtosObject: produtosTipo = {};

  for (const produto of listaProdutos) {
    const grupoProdutos = produto.grupoProdutoList[0];
    const grupoProdutosCodigo: number = EntidadeUtils.getIdFromEntity(grupoProdutos) || -1;
    const grupoProdutoTamanhoListEncapstulated = grupoProdutoList
      .filter((grupoProduto: any) => {
        const uri = EntidadeUtils.getURIFromEntity(grupoProduto);

        return produto.grupoProdutoList
          .map((g: any) => {
            const uri = EntidadeUtils.getURIFromEntity(g);

            return uri;
          }).includes(uri);
      }).map((grupoProduto: any) => {
        return grupoProduto.grupoProdutoTamanhoList
      });
    const [grupoProdutoTamanhoList] = grupoProdutoTamanhoListEncapstulated;
    const precoProdutoCombinado: string | null | undefined = grupoProdutoList
      .find((grupo: any) => {
        const grupoProdutoId = EntidadeUtils.getIdFromEntity(grupo);
        const grupoProdutoId2 = EntidadeUtils.getIdFromEntity(grupoProdutos);
        
        return grupoProdutoId === grupoProdutoId2;
      })?.precoProdutoCombinado;
    
    gruposComProdutos.grupos[`${grupoProdutosCodigo}`] = ({
      codigo: grupoProdutosCodigo,
      nome: grupoProdutos.nome,
      produtos: {
        ...(gruposComProdutos.grupos[`${grupoProdutosCodigo}`]?.produtos || {}),
        [`${EntidadeUtils.getIdFromEntity(produto)}` || '']: {...produto, key: `__key_item_produto_${produto.codigo}__`, id: `${EntidadeUtils.getIdFromEntity(produto)}` || ''}
      },
      grupoProdutoTamanhoList,
      precoProdutoCombinado: !precoProdutoCombinado
        ? 'media'
        : precoProdutoCombinado,
    });

    const id = `${EntidadeUtils.getIdFromEntity(produto)}` || '';

    produtosObject[id] = {
      id,
      codigo: produto.codigo,
      codigoImpresso: produto.codigoImpresso,
      key: `__key_item_produto_${produto.codigo}__`,
      fonteImagem: `${process.env.PUBLIC_URL}/images/${parametrosEmpresa.idDaEmpresa}/${id}?v=${produto.imagemVersion || '0'}`,
      fonteImagemTipo: produto.imagemFile,
      nome: produto.nome,
      descricao: produto.descricao,
      preco: produto.preco,
      promocao: produto.precoPromo
        ? {
          tipo: "por_tempo",
          precoPromo: (moment().isSameOrAfter(moment((produto.dataInicio + '- 00:00:00' || ''), 'DD/MM/YYYY - HH:mm:ss')) && moment().isSameOrBefore(moment((produto.dataFim + ' - 23:59:59' || ''), 'DD/MM/YYYY - HH:mm:ss')))
            ? produto.precoPromo
            : 0,
          dataInicio: produto.dataInicio,
          dataFim: produto.dataFim,
        }
        : undefined,
      quantidadeEmEstoque: ProdutoUtils.obterQuantidadeEmEstoque(),
      grupoProdutosCodigo,
      produtoTamanhoList: produto.produtoTamanhoList,
      mostrar: true,
    };
  }

  dispatch(adicionarGruposAction({grupos: gruposComProdutos, parametrosEmpresa}));
  dispatch(popularProdutos({produtos: produtosObject}));
}

// export const carregarGruposSomente = (parametrosEmpresa: parametrosEmpresaReducerTipo, dispatch: dispatchTipo) => {
//   const empresaId = parametrosEmpresa.idDaEmpresa;
  
//   return ClienteHttp.requisicaoServidor(`grupoProdutos/findAll`, 'get', true, {
//     params: {
//       empresaId
//     },
//   })
//   .then(({data}: any) => {
//     const grupos = data.content.map((grupo: any) => {
//       const codigo: number = EntidadeUtils.getIdFromEntity(grupo) || -1;

//       return {...grupo, codigo};
//     });

//     dispatch(adicionarGruposAction({grupos}));
//   });
// };
