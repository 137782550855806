import React from "react"
import { connect } from "react-redux"
import { dispatchTipo } from "../../../store/actions/acaoTemplate"
import { reduxStateType } from "../../../utils/reduxUtils/reduxStateType"

export interface ButtonType extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  isDialog?: boolean
}

interface ButtonTypeWithMappedReducer extends ButtonType {
  dispatch: dispatchTipo
}

function Button_({tabIndex, isDialog = false, ...props}: ButtonTypeWithMappedReducer) {
  const { dispatch, ...sanitizedProps } = props
  return <button
    {...sanitizedProps}
    tabIndex={isDialog
      ? 1
      : tabIndex
    }
  />
}

function mapStateToProps(state: reduxStateType) {
  const props = {
    tabIndex: state.appReducer.getTabIndex()
  }

  return props
}

function mapDispatchToProps(dispatch: dispatchTipo) {
  const props = {
    dispatch
  }

  return props
}

export const Button = connect(mapStateToProps, mapDispatchToProps)(Button_)