import F3ValidResponse from "./F3ValidResponse";

const F3Logout = (callback) => {
    const FB = window.FB;

    if (FB) {
        F3ValidResponse((response) => {
            const disconnected = response.status === 'unknown';

            if (!disconnected) {
                FB.logout((response) => callback && callback(response));
            } else {
                callback && callback(response);
            }

            return !disconnected;
        });
    }
};

export default F3Logout;