import React from "react";
import styled from "styled-components";
import { TitleSub, Paragraph, ConteudoSection } from ".";
import { PATH_IMAGENS_FOTOS } from "../utils/resources";
import { ImagensContainer, ImgRounded } from "./Containers";

const MuitoAlem = React.forwardRef((props, ref) => {
  return (
    <ConteudoSection ref={ref}>
      <TitleSub primary>Vai muito além</TitleSub>
      <Paragraph>
        Além de funcionar para os Restaurantes e Pizzarias, chegou para atender o público em geral. O nosso aplicativo está sendo lançado
        para todo o estado e traz essa comunicação automatizada com a retaguarda (ERP), podendo seu cliente fazer pedidos por casa, sendo
        ele pessoa física ou jurídica, receber sua mercadoria por delivery ou retirada no local. Você mesmo pode habilitar as funções
        desejadas. Existem inúmeras formas de trabalhar com o nosso app.
      </Paragraph>
      <ImagensContainer width={"300px"} height={"200px"}>
        <ImgRoundLeft src={PATH_IMAGENS_FOTOS + "muito_alem1.png"} alt={"Empresaria notebook"} />
        <ImgRoundRight src={PATH_IMAGENS_FOTOS + "muito_alem2.png"} alt={"Garçom smartphone"} />
      </ImagensContainer>
    </ConteudoSection>
  );
});

const ImgRoundRight = styled(ImgRounded)`
  top: 0;
  right: 15px;
  z-index: 11;
  transform: scaleX(-1);
`;

const ImgRoundLeft = styled(ImgRounded)`
  top: 45px;
  left: 15px;
  z-index: 12;
  /* @media (max-width: ${(props) => props.theme.sizes.breakPointSmall}) {
    left: 74;
  } */
`;

export default MuitoAlem;
