import { nullOrUndefined } from "../../../utils/ComparatorsUtils";

export const AccordionClick = e => {
    // Evita que o onClick do div pai seja disparado
    if (e.stopPropagation)
        e.stopPropagation();

    let target = e.target;

    // Para retornar se o accordion foi aberto ou fechado
    let opened = false;

    if (e.type === 'keydown') {
        target = target.childNodes[0]; 
    }

    while (!nullOrUndefined(target)) {
        if (target.getAttribute('name') === "accordion") {
                target.classList.toggle("active");

                var content = target.nextElementSibling;

                if (content != null && content.classList.contains("accordion-content")) {
                    if (target.classList.contains("active")) {
                        content.style.maxHeight = content.scrollHeight + "px";
                        // Accordion foi aberto
                        opened = true;
                    }
                    else {
                        content.style.maxHeight = "0";
                        // Accordion foi fechado
                        opened = false;
                    }
                }
                target = null;
            }
            else {
                target = target.parentElement;
            }
    }
    // Retorna se o accordion foi aberto ou fechado
    return opened;
}

// document.addEventListener("click", function (element) {

//   if(element.button !== 0)
//     return;

//   let target = element.target;

//   while (target !== undefined && target !== null) {

//     if (target.id === "accordion") {

//       target.classList.toggle("active");

//       var panel = target.nextElementSibling;

//       if (panel.style.maxHeight) {
//         panel.style.maxHeight = null;
//       } else {
//         panel.style.maxHeight = panel.scrollHeight + "px";
//       }

//       target = null;
//     }
//     else {
//       target = target.parentElement;
//     }
//   }
// });