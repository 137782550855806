import { reduxDispatch } from '../reduxUtils/reduxDispatch'
import { mobiAppCoresEstadoInicial, mobiAppCoresType } from '../../apps/promo_app_frontend/nucleo/redux/mobiApp/mobiAppReducer'
import { atualizarMobiApp } from '../../apps/promo_app_frontend/nucleo/redux/mobiApp/mobiAppActions'

export function atualizarEstiloMobiApp(mobiApp: mobiAppCoresType = mobiAppCoresEstadoInicial) {
  const bodyStyles = document.body.style

  bodyStyles.setProperty('--cor-de-fundo', mobiApp.corDeFundo || 'rgb(255,255,255)')
  bodyStyles.setProperty('--cor-de-fundo-texto', mobiApp.corDeFundoTexto || 'rgb(50, 50, 50)')
  bodyStyles.setProperty('--cor-botao-de-acao', mobiApp.corBotaoDeAcao || 'rgb(7,54,89)')
  bodyStyles.setProperty('--cor-botao-de-acao-texto', mobiApp.corBotaoDeAcaoTexto || 'rgb(255, 255, 255)')
  bodyStyles.setProperty('--cor-botao-voltar', mobiApp.corBotaoVoltar || 'rgb(255,255,255)')
  bodyStyles.setProperty('--cor-botao-voltar-texto', mobiApp.corBotaoVoltarTexto || 'rgb(0, 0, 0)')
  bodyStyles.setProperty('--cor-do-dialog', mobiApp.corDoDialog || 'white')
  bodyStyles.setProperty('--cor-do-dialog-texto', mobiApp.corDoDialogTexto || 'rgb(50, 50, 50)')
  bodyStyles.setProperty('--cor-do-card', mobiApp.corDoCard || 'rgb(245,245,245)')
  bodyStyles.setProperty('--cor-do-card-texto', mobiApp.corDoCardTexto || 'rgb(50, 50, 50)')
  bodyStyles.setProperty('--cor-da-entrada', mobiApp.corDaEntrada || 'rgb(255,255,255)')
  bodyStyles.setProperty('--cor-da-entrada-texto', mobiApp.corDaEntradaTexto || 'rgb(50, 50, 50)')
  bodyStyles.setProperty('--cor-da-barra-navegacao', mobiApp.corDaBarraNavegacao || 'rgb(250,250,250)')
  bodyStyles.setProperty('--cor-da-barra-navegacao-texto', mobiApp.corDaBarraNavegacaoTexto || 'rgb(50, 50, 50)')
  bodyStyles.setProperty('--cor-principal', mobiApp.corPrincipal || 'rgb(7, 54, 89)')
  bodyStyles.setProperty('--cor-principal-texto', mobiApp.corPrincipalTexto || 'rgb(255,255,255)')
  bodyStyles.setProperty('--cor-barra-topo', mobiApp.corBarraTopo || 'linear-gradient(to right,rgb(1, 170, 131),rgb(3, 73, 133),rgb(3, 73, 133))')
  bodyStyles.setProperty('--cor-barra-topo-texto', mobiApp.corBarraTopoTexto || 'rgb(255,255,255)')
  bodyStyles.setProperty('--cor-link', mobiApp.corLinksDoCard || 'rgb(119, 119, 119)')

  reduxDispatch(atualizarMobiApp(mobiApp))
}