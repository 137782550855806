import React from 'react';
import { connect } from 'react-redux';

import { getAppEmpresa } from "../../../utils/AppUtils"
import { equalsCoerced } from "../../../utils/ComparatorsUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { cadastroBuildOptionsFromTipoOrigemVenda } from "../../../utils/SelectUtils"
import { naturalSort } from "../../../utils/SortUtils"
import { entityURIEqualsURI } from "../../../utils/URIUtils"

import CadastroForm from './CadastroForm';
import InputCustomizado from '../../UI/Input/InputCustomizado';
import AtivoInputDefault from '../cadastroInputDefault/AtivoInputDefault.js'
import CodigoInputDefault from '../cadastroInputDefault/CodigoInputDefault.js'
import NomeInputDefault from '../cadastroInputDefault/NomeInputDefault.js'

import * as actionTypes from '../../../store/actions/actionTypes';
import * as actions from '../../../store/actions/cadastroAction';
import {
    TIPO_ORIGEM_VENDA_AIQFOME,
    TIPO_ORIGEM_VENDA_CARTAO,
    TIPO_ORIGEM_VENDA_COLETOR,
    TIPO_ORIGEM_VENDA_DELIVERY_MUCH, TIPO_ORIGEM_VENDA_IFOOD,
    TIPO_ORIGEM_VENDA_MESA,
    // TIPO_ORIGEM_VENDA_MOBI,
    TIPO_ORIGEM_VENDA_PAINEL,
    TIPO_ORIGEM_VENDA_TELEFONE,
    TIPO_ORIGEM_VENDA_WHATSAPP
} from '../../../store/reducers/controleVenda/controleVendaReducer';

/**
 * Classe que repassa para o CadastroForm as informações para a montagem do formulário para o cadastro das Origens de Venda.
 */
class OrigemVendaForm extends React.Component {
    render() {
        log('OrigemVendaForm render');
        return <CadastroForm {...this.props} formModel={OrigemVendaValues}
            registerSaveHelp={getStrings().saleSourceSaveHelp} registerUpdateHelp={getStrings().saleSourceUpdateHelp} />;
    }
}

/**
 * Classe que realiza a montagem do formulário para o cadastro das Origens de Venda.
 */
class OrigemVendaValues extends React.Component {
    enabled = true;
    ismounted = false;

    /**
     * Retorna se os campos obrigatórios (required) foram todos preenchidos
     */
    checkRequired = newData => {
        log('OrigemVendaValues checkRequired', newData);
        return (newData.codigo !== '') && (newData.nome !== '') && (newData.tipoOrigemVenda !== null);
    }

    /**
     * Recupera os dados do formulário.
     */
    getFormData = () => {
        log('OrigemVendaValues getFormData');

        const _links = this.props.cadastroDados._links;

        return {
            empresa: getAppEmpresa(),
            enabled: this.enabled.getValue(),
            codigo: this.codigo.getValue(),
            nome: this.nome.getValue(),

            tipoOrigemVenda: this.recuperaTipoOrigemVendaFromOptions(this.tipoOrigemVendaList.inputComponent.getValue()),

            //formatoCodigoImpresso: cadastroRecuperaValueFromOptions(this.formatoCodigoImpresso.inputComponent.getValue()),

            //formatoCodigoImpressoOption: this.formatoCodigoImpresso.inputComponent.getValue(),

            //codigoImpresso: this.isFormatoCodigoImpressoSet() ? this.codigoImpresso.inputComponent.value : null,
            
            _links,
        };
    }

    /**
     * Método executado ao clicar no botão "Configurações".
     * Exibe a tela de gerar origens de venda automaticamente.
     */
    handleConfig = () => {
        log('OrigemVendaValues handleConfig');
        switch (this.props.operation) {
            // Se for um cadastro novo, exibe tela de geração automática de cadastros
            case actionTypes.CADASTRO_NEW:
                this.props.showSetup();
                break;
            // Se for um cadastro existente, exibe notificação para liberar origem de venda.
            case actionTypes.CADASTRO_EDIT:
                this.props.clearUsuarioAtivo();
                break;
            default:
        }
    }

    /**
     * Método executado ao clicar no botão "Gravar".
     * Recupera os dados do formulário e repassa para o método que irá persistir os dados.
     */
    handleGravar = () => {
        log('OrigemVendaValues handleGravar');

        // Repassa para o método que irá persistir os dados
        this.props.onSave(this.getFormData());
    }

    //isFormatoCodigoImpressoSet = () => !!this.props.cadastroDados.formatoCodigoImpresso;

    /**
     * Método que define as opções disponíveis no campo "Tipo"
     * e seleciona o valor atual do cadastro.
     * @param {*} cadastro 
     */
    loadTipos = () => {
        log('OrigemVendaValues loadTipos');

        // Define as opções disponíveis no campo "Tipo"
        this.tipoOrigemVendaList.inputComponent.updateOptions(naturalSort([
            //cadastroBuildOptionsFromTipoOrigemVenda(false, { codigo: TIPO_ORIGEM_VENDA_BALCAO }),
            cadastroBuildOptionsFromTipoOrigemVenda(false, { codigo: TIPO_ORIGEM_VENDA_CARTAO }),
            cadastroBuildOptionsFromTipoOrigemVenda(false, { codigo: TIPO_ORIGEM_VENDA_MESA }),
            cadastroBuildOptionsFromTipoOrigemVenda(false, { codigo: TIPO_ORIGEM_VENDA_IFOOD }),
            cadastroBuildOptionsFromTipoOrigemVenda(false, { codigo: TIPO_ORIGEM_VENDA_DELIVERY_MUCH }),
            cadastroBuildOptionsFromTipoOrigemVenda(false, { codigo: TIPO_ORIGEM_VENDA_AIQFOME }),
            // cadastroBuildOptionsFromTipoOrigemVenda(false, { codigo: TIPO_ORIGEM_VENDA_MOBI }),
            cadastroBuildOptionsFromTipoOrigemVenda(false, { codigo: TIPO_ORIGEM_VENDA_WHATSAPP }),
            cadastroBuildOptionsFromTipoOrigemVenda(false, { codigo: TIPO_ORIGEM_VENDA_TELEFONE }),
            cadastroBuildOptionsFromTipoOrigemVenda(false, { codigo: TIPO_ORIGEM_VENDA_PAINEL }),
            cadastroBuildOptionsFromTipoOrigemVenda(false, { codigo: TIPO_ORIGEM_VENDA_COLETOR }),
        ], 'label'));

        // Seleciona o valor atual do cadastro
        if (this.props.cadastroDados.tipoOrigemVenda) {
            this.tipoOrigemVendaList.inputComponent.updateValue(
                cadastroBuildOptionsFromTipoOrigemVenda(false, { codigo: this.props.cadastroDados.tipoOrigemVenda }));
        } else {
            this.tipoOrigemVendaList.inputComponent.updateValue(null);
        }
    }

    /**
     * Método que retorna o valor que defe ser utiizado para o atributo "tipoOrigemVenda"
     * a partir da opção selecionada no campo "Tipo".
     * @param {*} option
     */
    recuperaTipoOrigemVendaFromOptions = option => {
        log('OrigemVendaValues recuperaTipoOrigemVendaFromOptions', option);

        // Caso nenhuma opção foi selecionada, retorna null
        if (typeof option === undefined || option === null) {
            return null;
        }

        // Recupera o código da opção selecionada
        return option.value.codigo;
    }

    /**
     * Método que define o valor inicial do select.
     */
    // setFormatosCodigoImpressoVinculado = () => {
    //     log('ProdutoValues setFormatosCodigoImpressoVinculado');

    //     // Se uma opção havia sido selecionada, coloca essa opção no select. Senão, gera a opção a partir da entidade que estiver no cadastro.
    //     // A entidade só estará disponível para gerar uma opção quando um cadastro for editado, pois seus dados são lidos da retaguarda.
    //     // Quando o cadastro é enviado para a retaguarda, a entidade é substituída pelo seu URI, mas a partir daí já havia uma opção selecionada.
    //     this.formatoCodigoImpresso.inputComponent.updateValue(
    //         this.props.cadastroDados.formatoCodigoImpressoOption
    //             ? this.props.cadastroDados.formatoCodigoImpressoOption
    //             : this.props.cadastroDados.formatoCodigoImpresso ? cadastroBuildOptionsFromEnum(
    //                 false, this.props.cadastroDados.formatoCodigoImpresso, string => getStrings().printedCodeFormatEnumToString(string)) : null);
    // }

    /**
     * Retorna se houve alteração em algum dado do cadastro
     */
    wasChanged = (newData, oldData) => {
        let result = !equalsCoerced(oldData, newData, (oldValue, newValue) =>
            (newValue.enabled === oldValue.enabled)
            && (newValue.codigo === oldValue.codigo)
            && (newValue.nome === oldValue.nome)
            //&& ((!newValue.formatoCodigoImpresso) || equalsCoerced(oldValue.codigoImpresso, newValue.codigoImpresso, (oldString, newString) => newString === oldString)) 
            //&& (newValue.formatoCodigoImpresso === oldValue.formatoCodigoImpresso) 
            && entityURIEqualsURI(oldValue.tipoOrigemVenda, newValue.tipoOrigemVenda));
        log('OrigemVendaValues wasChanged', { newData, oldData, result });
        return result;
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro, comparando com os dados sendo editados.
     */
    wasChangedFromCurrent = newData => {
        log('OrigemVendaValues wasChangedFromCurrent', newData);
        return this.wasChanged(newData, this.props.cadastroDados);
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro, comparando com os dados presentes ao abrir a criação/edição do cadastro.
     */
    wasChangedFromInitial = newData => {
        log('OrigemVendaValues wasChangedFromInitial', newData);
        return this.wasChanged(newData, this.props.cadastroDadosIniciais);
    }

    /**
     * Método executado APÓS a montagem/renderização do componente.
     * Carrega os tipos disponíveis e também define os valores iniciais das opções conforme cadastro carregado.
     */
    componentDidMount() {
        log('OrigemVendaValues componentDidMount');
        this.ismounted = true;

        // this.props.cadastroLoadFormatosCodigoImpresso(cadastros => {
        //     if (!this.ismounted)
        //         return;

        //     this.formatoCodigoImpresso.inputComponent.updateOptions(
        //         cadastroBuildOptionsFromEnum(true, cadastros, string => getStrings().printedCodeFormatEnumToString(string)));
        // }, () => {
        //     if (this.ismounted && (this.props.loading.formatosCodigoImpresso !== false)) {

        //         this.props.cadastroUpdateLoading({ formatosCodigoImpresso: false });
        //     }
        // });
        
        this.componentDidUpdate();
    }

    /**
     * Método executado APÓS a atualização do componente.
     * Carrega os tipos disponíveis e também define os valores iniciais das opções conforme cadastro carregado.
     * 
     * Não há dados adicionais que são carregados para `OrigemVenda`, mas os campos devem ser populados novamente para,
     * no caso de troca de idioma, os tipos de `OrigemVenda` serem gerados no novo idioma.
     */
    componentDidUpdate() {
        log('OrigemVendaValues componentDidUpdate');

        if (this.props.cadastroDados) {

            // Se for passado false, é false; se for passado true, null, undefined ou não for passado, é true
            this.enabled.inputField.inputComponent.checked = this.props.cadastroDados.enabled !== false;

            this.codigo.inputField.inputComponent.value = this.props.cadastroDados.codigo ? this.props.cadastroDados.codigo : null;

            this.nome.inputField.inputComponent.value = this.props.cadastroDados.nome ? this.props.cadastroDados.nome : null;

            //if (this.isFormatoCodigoImpressoSet()) {
            //    this.codigoImpresso.inputComponent.value = this.props.cadastroDados.codigoImpresso ? this.props.cadastroDados.codigoImpresso : null;
            //}
        }

        this.loadTipos();

        //this.setFormatosCodigoImpressoVinculado();
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('OrigemVendaValues render');

        return <div className='origem-venda-form sub-form'>

            <AtivoInputDefault tabIndex={this.props.tabIndex} ref={input => this.enabled = getReduxWrappedComponent(input)} handleInputValidado={this.props.handleChange} />

            <CodigoInputDefault ref={input => this.codigo = getReduxWrappedComponent(input)} subPlaceholder={getStrings().saleSourceSubPlaceholder()} handleInputValidado={this.props.handleChange} />

            <NomeInputDefault ref={input => this.nome = getReduxWrappedComponent(input)} subPlaceholder={getStrings().saleSourceSubPlaceholder()} handleInputValidado={this.props.handleChange} isRequired={true} />

            <InputCustomizado id='tipo' inputType='singleSelect' name='tipo' ref={input => this.tipoOrigemVendaList = getReduxWrappedComponent(input)} placeholder={getStrings().saleSourceTypePlaceholder()} label={getStrings().typeRequired} onChange={() => setTimeout(() => this.props.handleChange(), 0)} required={true} sort />

            {/* <InputCustomizado id='formatoCodigoImpresso' name='formatoCodigoImpresso' ref={input => this.formatoCodigoImpresso = getReduxWrappedComponent(input)} placeholder={getStrings().printedCodeFormatPlaceholder} label={getStrings().printedCodeFormat} onChange={() => setTimeout(() => this.props.handleChange(), 0)} inputType='singleSelect' /> */}

            {/* {this.isFormatoCodigoImpressoSet() ? <InputCustomizado id='codigoImpresso' name='codigoImpresso' required={true} ref={input => { if (input) { this.codigoImpresso = getReduxWrappedComponent(input) } }} placeholder={getStrings().printedCodePlaceholder} label={getStrings().printedCode} handleInputValidado={this.props.handleChange} type='text' maxLength='17' /> : null} */}

        </div>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    tabIndex: state.appReducer.getTabIndex(),
    ...state.idiomaReducer,
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    //cadastroLoadFormatosCodigoImpresso: (onSuccess, onComplete) => dispatch(actions.cadastroLoadFormatosCodigoImpresso(onSuccess, onComplete)),
    cadastroUpdateLoading: steps => dispatch(actions.cadastroUpdateLoading(steps)),
    clearUsuarioAtivo: () => dispatch(actions.clearUsuarioAtivo()),
    dispatch,
    showSetup: () => dispatch(actions.showSetup())
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OrigemVendaForm);
