import React from "react";
import { connect } from "react-redux";

import { getURIFromEntity } from "../../../utils/URIUtils";

import { dispatchTipo } from "../../../store/actions/acaoTemplate";
import { updateFormData } from "../../../store/actions/signUpAction";

import EnderecoForm from "../../cadastros/form/EnderecoForm";

export interface SignUpUserFormStepAddressInfoType {
  companyForm: boolean
  , handleTextInput: (keyArray: string[], value: any) => void
  , novoUsuarioCargo: boolean
  , updateReducer: (func: any) => void
}

interface signUpUserFormStepAddressInfoTypeWithReducer extends SignUpUserFormStepAddressInfoType {
  formData: any
  , handleEntityInput: (keyArray: string[], value: any) => void
  , step: number
  , updateFromZipCode: (cadastroEmpresaState: string, zipCode: string, state: string, city: string, neighborhood: string, street: string) => void
  , zipCodeCallBack: (zipCode: string, state: string, city: string, neighborhood: string, street: string) => void
}

export class SignUpUserFormStepAddressInfo_ extends React.Component<signUpUserFormStepAddressInfoTypeWithReducer> {
  pais: any = undefined;
  uf: any = undefined;
  municipio: any = undefined;
  bairro: any = undefined;
  endereco: any = undefined;
  numero: any = undefined;
  complemento: any = undefined;
  cep: any = undefined;

  render() {
    return <div className={this.props.step !== 1 ? "hidden" : undefined}>
      <EnderecoForm
        companyForm={this.props.companyForm}
        entity="user"
        handleChange={this.props.updateReducer}
      />
    </div>;
  }
}

function mapStateToProps(state: any) {
  const props = {
    formData: state.signUpReducer.formData 
    , step: state.signUpReducer.step
  };

  return props;
}

function mapDispatchToProps(dispatch: dispatchTipo) {
  const props = {
    handleTextInput: (keyArray: string[], value: any) =>
      dispatch(updateFormData(keyArray, value)),
    handleEntityInput: (keyArray: string[], value: any) => {
      dispatch(updateFormData(["dadosAdicionais", "paisOption"], value));
      dispatch(updateFormData(keyArray, getURIFromEntity(value.value)));
    },
  };

  return props;
}

const SignUpUserFormStepAddressInfo = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SignUpUserFormStepAddressInfo_);

export default SignUpUserFormStepAddressInfo;