import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { goBack } from "../../../utils/NavigatorUtils"
import { pageType } from "../../../utils/entidadeUtils/pageType"

import * as appActions from "../../../store/actions/appAction"
import { reduxStateType } from "../../../utils/reduxUtils/reduxStateType"
import { getRegisterList, getRegisterListType } from "../../../store/actions/cadastroPrintAction"
import { dispatchTipo } from "../../../store/actions/acaoTemplate"
import { cadastroReducerType } from "../../../store/reducers/cadastroReducer"
import { idiomaReducerType } from "../../../store/reducers/idiomaReducer"

import BuildButton from "../../UI/Toolbar/BarraAcoesButton"
import BuildField from "../../UI/Toolbar/BarraAcoesField"
import DialogPageable from "../dialog/DialogPageable"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"
import Icones from "../../../assets/icones/Icones"

export type BarraAcoesTableType = {
  filterProperties: string[],
  handleNovo: () => void,
  onNavigate: (link: string) => void,
  updatePageSize: (pageSize: number) => void,

  /** Ativa o brilho no botão da direita */
  attractSubmit?: boolean,
  printExternalEnabled?: boolean,
  printInternalEnabled?: boolean,
  /** Se o botão da direita está desabilitado */
  submitDisabled?: boolean,
  /** Papel realizado pelo botão da direita (para definir o seu ícone) */
  submitRole?: string,
  unique?: boolean,
};

type BarraAcoesTableReduxStateType = cadastroReducerType & idiomaReducerType;

type BarraAcoesTableReduxDispatchType = {
  appDialogShow: appActions.appDialogShowType,
  appDialogHide: appActions.appDialogHideType,
  getRegisterList: getRegisterListType,
};

type BarraAcoesTableTypeWithReducer = BarraAcoesTableType & BarraAcoesTableReduxStateType & BarraAcoesTableReduxDispatchType;

/**
 * Botão para criar novo cadastro.
 */
export const SUBMIT_NEW_REGISTER = "SUBMIT_NEW_REGISTER";

/**
 * Botão para cancelar pagamento.
 */
export const SUBMIT_CANCEL_PAYMENT = "SUBMIT_CANCEL_PAYMENT";

/**
 * Classe que realiza a montagem da barra de ações para as visualizações de cadastros.
 */
const BarraAcoesTable_ = (props: BarraAcoesTableTypeWithReducer) => {
  /**
   * Método executado ao clicar no botão "<<" (primeira página).
   * Carrega os cadastros correspondentes.
   */
  const handleNavFirst = () => {
    props.onNavigate(props.links.first.href);
  }

  /**
   * Método executado ao clicar no botão "<" (página anterior).
   * Carrega os cadastros correspondentes.
   */
  const handleNavPrev = () => {
    props.onNavigate(props.links.prev.href);
  }

  /**
   * Método executado ao clicar no botão ">" (próxima página).
   * Carrega os cadastros correspondentes.
   */
  const handleNavNext = () => {
    props.onNavigate(props.links.next.href);
  }

  /**
   * Método executado ao clicar no botão ">>" (última página).
   * Carrega os cadastros correspondentes.
   */
  const handleNavLast = () => {
    props.onNavigate(props.links.last.href);
  }

  /**
   * Método executado ao clicar sobre o field que exibe a página atual "2/14 (página 2 de 14)".
   * Exibe uma dialog para definir o número de registros que será exibido por página.
   */
  const showDialog = () => {
    props.appDialogShow(
      <DialogPageable
        updatePageSize={props.updatePageSize}
        appDialogHide={props.appDialogHide}
        numeroRegistros={props.pageSize}
        filterProperties={props.filterProperties}
        printExternalEnabled={props.printExternalEnabled}
        printInternalEnabled={props.printInternalEnabled}
      />,
      getStrings().registers
    );
  }

  const printCode = () => {
    props.appDialogHide();
    props.getRegisterList(props.filterProperties, props.printExternalEnabled, props.printInternalEnabled);
  }

  // Label que exibe a página sendo exibida, ou uma mensagem caso não existam cadastros
  let pageInfo;
  if (props.page.hasOwnProperty("number")) {
    pageInfo = ((props.page as pageType).number + 1) + "/" + ((props.page as pageType).totalPages || 0);
  }
  else {
    pageInfo = "0/0";
  }

  // Define o ícone do botão mais da direita
  let label;

  const FaQrcode = Icones.fa.FaQrcode;
  const MdClose = Icones.md.MdClose;

  switch (props.submitRole) {
    case SUBMIT_CANCEL_PAYMENT:
      label = <MdClose />;
      break;
    case SUBMIT_NEW_REGISTER:
    default:
      label = "\u002B";
      break;
  }

  // Define o texto alternativo do botão mais da direita
  let title;

  switch (props.submitRole) {
    case SUBMIT_CANCEL_PAYMENT:
      title = getStrings().cancelPayment;
      break;
    case SUBMIT_NEW_REGISTER:
    default:
      title = getStrings().newRegister;
      break;
  }

  // Monta o componente
  return <div id="toolbar" className="toolbar">

    <div className="toolbar-content">

      <WidthAwareDiv>
        <div className="bar-container" >

          {/* Botão Voltar */}
          <BuildButton id="back-button" buttonGridClassName="bar-item" key="back" isPrimary={true} commandMethod={() => goBack()} label={"\u003c"} title={getStrings().back} />

          {/* Botões de navegação */}
          <BuildButton id="first-page-button" buttonGridClassName="bar-item" key="first" isPrimary={false} commandMethod={handleNavFirst} isDisabled={!(("first" in props.links) && ("prev" in props.links))} label={"\u003c\u003c"} title={getStrings().firstPage} />
          <BuildButton id="previous-page-button" buttonGridClassName="bar-item" key="prev" isPrimary={false} commandMethod={handleNavPrev} isDisabled={!("prev" in props.links)} label={"\u003c"} title={getStrings().previous} />

          {/* Impressão de códigos na tela */}
          {["origemVenda", "produto"].some(objetoCadastro => objetoCadastro === props.objetoCadastroLast)
            ? <BuildButton id="print-code" buttonGridClassName="bar-item" key="code" label={<FaQrcode />} title={getStrings().printCodes} commandMethod={printCode} />
            : null
          }

          {/* Página atual */}
          <BuildField id="pageInfo" key="pageInfo" commandMethod={showDialog} label={pageInfo} />

          {/* Botões de navegação */}
          <BuildButton id="next-page-button" buttonGridClassName="bar-item" key="next" isPrimary={false} commandMethod={handleNavNext} isDisabled={!("next" in props.links)} label={"\u003e"} title={getStrings().next} />
          <BuildButton id="last-page-button" buttonGridClassName="bar-item" key="last" isPrimary={false} commandMethod={handleNavLast} isDisabled={!(("last" in props.links) && ("next" in props.links))} label={"\u003e\u003e"} title={getStrings().lastPage} />

          {/* Botão Novo */}
          <BuildButton id="new-item-button" buttonGridClassName="bar-item" key="add" isPrimary={true} commandMethod={props.handleNovo} isDisabled={props.submitDisabled || (props.unique && props.cadastroList.length > 0)} label={label} title={title} attractSubmit={props.attractSubmit} />

        </div>
      </WidthAwareDiv>
    </div>
  </div>;
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
function mapStateToProps(state: reduxStateType) {
  const props: BarraAcoesTableReduxStateType = {
    ...state.cadastroReducer,
    ...state.idiomaReducer,
  };

  return props;
}

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch: dispatchTipo) {
  const props: BarraAcoesTableReduxDispatchType = {
    appDialogShow: (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles) =>
      dispatch(appActions.appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles)),
    appDialogHide: () => dispatch(appActions.appDialogHide()),
    getRegisterList: (filterProperties, printExternalEnabled, printInternalEnabled) =>
      dispatch(getRegisterList(filterProperties, printExternalEnabled, printInternalEnabled)),
  };

  return props;
}

/**
 * Exporta o último argumento entre parênteses.
 */
const BarraAcoesTable = connect(mapStateToProps, mapDispatchToProps)(BarraAcoesTable_) as React.ElementType<BarraAcoesTableType>;

export default BarraAcoesTable;
