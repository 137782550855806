import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from "../../utils/LocaleUtils"
import { updateActiveMenuItem } from "../../utils/ScreenUtils"

import MovimentoTableRow from './MovimentoTableRow';

// Chaves das colunas
import RelatorioBox, {
    ITEM_VENDA_ESTADO,
    ITEM_VENDA_HORA,
    ITEM_VENDA_ORIGEM,
    ITEM_VENDA_PRODUTO,
    ITEM_VENDA_VENDA
} from './RelatorioBox';

import * as relatorioActions from '../../store/actions/relatorioAction';

const mapColumnName = {
    [ITEM_VENDA_PRODUTO]: getStrings().item,
    [ITEM_VENDA_VENDA]: getStrings().sale(),
    [ITEM_VENDA_ORIGEM]: getStrings().saleSourceShort(),
    [ITEM_VENDA_ESTADO]: getStrings().state,
    [ITEM_VENDA_HORA]: getStrings().timeHourMinute,
}

/**
 * Monta a tabela de relatório de movimento.
 */
class MovimentoBox extends React.Component {

    /**
     * Lista de chaves de coluna na ordenação inicial.
     */
    columnsInitial = [
        ITEM_VENDA_PRODUTO,
        ITEM_VENDA_VENDA,
        ITEM_VENDA_ORIGEM,
        ITEM_VENDA_ESTADO,
        ITEM_VENDA_HORA,
    ]

    /**
     * Retorna o texto do cabeçalho de cada coluna de acordo com a chave da coluna.
     */
    columnHeader = column => {
        return mapColumnName[column] || ''
    }

    componentDidMount() {

        updateActiveMenuItem('menuRelatorioMovimento', 'menuRelatorios');

        // Método usado no início da implementação do sistema de relatórios genéricos. Investigar necessidade ao substituir sistema de relatórios.
        // this.props.getRelatorio('movimento');

        // Inicializa as opções da geração de relatório
        this.props.sortData({ dadosList: [] }, { report: 'movimento' }, this.columnsInitial, {});
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {

        /**
         * Lista de linhas da tabela.
         */
        let list = null;

        /**
         * Lista de chaves de coluna na ordenação personalidada, se existir, ou inicial, se não
         */
        let columnOrder = this.props.columnOrder || this.columnsInitial;

        // Formata os dados do servidor, se existirem
        if (this.props.dados && ('dadosList' in this.props.dados)) {

            // Formata cada linha da tabela
            list = this.props.dados.dadosList.map(
                dado => <MovimentoTableRow dadosMap={dado.dadosMap} />
            );
        }

        return (
            <RelatorioBox
                header={getStrings().movementReport}
                tableHeader={columnOrder.map(column => ({
                    key: column,
                    text: this.columnHeader(column),
                }))}
                list={list}
                enableFilter={{
                    empresa: true,
                    origemVenda: true,
                    tipoProduto: true,
                    grupoProduto: true,
                    produto: true,
                    usuario: true,
                    estadoVenda: true,
                    estadoItemVenda: true,
                    data: true,
                    dataEntrega:false,
                    hora: true
                }}
                helpText={[getStrings().movementReportHelp()]}
            />
        );
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {
    return {
        ...state.relatorioReducer,
        ...state.idiomaReducer
    };
};

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        getRelatorio: relatorio => dispatch(relatorioActions.getRelatorio(relatorio)),
        sortData: (dados, options, columnOrder, rowSortOptions) => dispatch(relatorioActions.sortData(dados, options, columnOrder, rowSortOptions))
    };
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(MovimentoBox);
