import React from 'react'
import { log } from './LogUtils';
import { newArray } from './ArrayUtils';
import { randomNumberBetween } from './NumberUtils';
import Icones from '../assets/icones/Icones';
import { isNonEmptyArray, isString } from './ComparatorsUtils';

import {
    ICON_ASTERISK,
    ICON_HUB
} from './IconUtils';
import { listToFragment } from './ScreenUtils';

/**
 * Gera uma sequência aleatória de caracteres.
 * @param {Number} length quantidade de caracteres
 */
export const randomString = (length = 8) => {
    log('StringUtils randomString', { length });

    return newArray(length).map(() => {
        let random = randomNumberBetween(0, 64);
        if ((random > -1) && (random < 26)) {
            return String.fromCharCode(random + 65);
        } else if ((random > 25) && (random < 52)) {
            return String.fromCharCode(random - 26 + 97);
        } else if ((random > 51) && (random < 62)) {
            return String.fromCharCode(random - 52 + 48);
        } else if (random === 62) {
            return '+';
        } else if (random === 63) {
            return '/';
        } else {
            return '=';
        }
    }).join('');
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const uncapitalizeFirstLetter = (string) => {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export const prefixIf = (test, prefix, phrase) => test ? `${prefix} ${phrase}` : phrase;

export const containsOnlyNumbers = (string) => /^[0-9]+$/.test(string);

export const stringParseFloat = (string) => parseFloat((string || '0').replace(',', '.'));

export const stringParseInt = (string) => parseInt((string || '0'));

/**
 * Formata um texto internacionalizado, substituindo marcadores por ícones ou texto formatado com JSX.
 * @param {string|any[]} i18nArray texto ou vetor contendo texto ou marcadores
 */
export const formatI18nString = i18nArray => {
    if (!(isNonEmptyArray(i18nArray) || isString(i18nArray))) {
        return null;
    }

    // Se for um vetor, itera e trata os objetos
    return (i18nArray.map) ? listToFragment(i18nArray.map(item => {
        const FaCogs = Icones.fa.FaCogs;
        const FaQrcode = Icones.fa.FaQrcode;
        const FaRegComment = Icones.fa.FaRegComment;

        const GoLaw = Icones.go.GoLaw;
        const GoPencil = Icones.go.GoPencil;
        const GoTrashcan = Icones.go.GoTrashcan;

        const MdAttachFile = Icones.md.MdAttachFile;
        const MdAttachMoney = Icones.md.MdAttachMoney;
        const MdCheck = Icones.md.MdCheck;
        const MdClose = Icones.md.MdClose;
        const MdEventAvailable = Icones.md.MdEventAvailable;
        const MdEventNote = Icones.md.MdEventNote;
        const MdEventSeat = Icones.md.MdEventSeat;
        const MdFormatListBulleted = Icones.md.MdFormatListBulleted;
        const MdHelp = Icones.md.MdHelp;
        const MdSpeakerNotes = Icones.md.MdSpeakerNotes;
        const MdInfoOutline = Icones.md.MdInfoOutline;
        const MdNotificationsNone = Icones.md.MdNotificationsNone;
        const MdPlaylistAddCheck = Icones.md.MdPlaylistAddCheck;
        const MdPrint = Icones.md.MdPrint;
        const MdQueue = Icones.md.MdQueue;
        const MdSend = Icones.md.MdSend;
        const MdSwitchCamera = Icones.md.MdSwitchCamera;
        const TourIcon = Icones.md.MdDirectionsBus;

        // Se objeto tem length negativo, então é um marcador
        if (item.length < 0) {
            // Se marcador é um ícone
            if ('icon' in item) {
                // Verifica qual ícone o marcador representa
                switch (item.icon) {
                    case 'ApxAsterisk': return <span className='icon'>{ICON_ASTERISK}</span>;
                    case 'FaCogs': return <FaCogs />;
                    case 'FaQrcode': return <FaQrcode />;
                    case 'FaRegComment': return <FaRegComment />
                    case 'GoLaw': return <GoLaw />;
                    case 'GoPencil': return <GoPencil />;
                    case 'GoTrashcan': return <GoTrashcan />;
                    case 'ApxHub': return <span className='icon'>{ICON_HUB}</span>;
                    case 'MdAttachFile': return <MdAttachFile />;
                    case 'MdAttachMoney': return <MdAttachMoney />;
                    case 'MdCheck': return <MdCheck />;
                    case 'MdClose': return <MdClose />;
                    case 'MdEventAvailable': return <MdEventAvailable />;
                    case 'MdEventNote': return <MdEventNote />;
                    case 'MdEventSeat': return <MdEventSeat />;
                    case 'MdFormatListBulleted': return <MdFormatListBulleted />;
                    case 'MdInfoOutline': return <MdInfoOutline />;
                    case 'MdNotificationsNone': return <MdNotificationsNone />;
                    case 'MdQueue': return <MdQueue />;
                    case 'MdPlaylistAddCheck': return <MdPlaylistAddCheck />;
                    case 'MdPrint': return <MdPrint />;
                    case 'MdSend': return <MdSend />;
                    case 'MdHelp': return <MdHelp />;
                    case 'MdSpeakerNotes': return <MdSpeakerNotes />;
                    case 'MdSwitchCamera': return <MdSwitchCamera />;
                    case 'TourIcon': return <TourIcon />;
                    default: return null;
                }
            }
            // // Se marcador é de imagem
            // if ('img' in item) {
            //     // Verifica qual imagem o marcador representa
            //     switch (item.img) {
            //         case 'hub_logo_web': return <img
            //             src='https://ampix.mobi:9443/static/hub_logo_web.png'
            //             alt={getStrings().hubLogoAlt}
            //             style={{ display: 'inline-block', verticalAlign: 'middle', height: '1em' }}
            //         />;
            //         default: return null;
            //     }
            // }
            // Se marcador é formatação
            else if ('format' in item) {
                let text = item.text;
                // Itera os formatadores
                item.format.forEach(format => {
                    // Verifica qual formatação aplicar
                    switch (format) {
                        case 'b': text = <b>{text}</b>; break;
                        case 'i': text = <i>{text}</i>; break;
                        case 'u': text = <u>{text}</u>; break;
                        case 'symbol': text = <span style={{ fontFamily: 'Segoe UI Symbol' }}>{text}</span>; break;
                        default: return text;
                    }
                });
                return <>{text}</>;
            } else if ('list' in item) {
                // Formata cada item da lista
                let list = item.list.map((listItem, index) => <li className='Lighter' key={index}>{formatI18nString(listItem)}</li>)
                // let list = item.list.map((listItem, index) => <li className='Lighter' key={index}><p>{formatI18nString(listItem)}</p></li>)
                // Retorna a lista dependendo da ordenação
                if (item.order) {
                    return <ol>{list}</ol>;
                } else {
                    return <ul>{list}</ul>;
                }
            } else {
                return null;
            }
        }
        // Se objeto tem length não negativo, então é uma string
        else {
            return <>{item}</>;
        }
        // Se for só uma string, retorna como está 
    })) : i18nArray;
}