import React from 'react';
import { connect } from 'react-redux';

import { updateActiveMenuItem } from '../../../utils/ScreenUtils';
import { urlDatabase } from '../../../utils/SecureConnectionUtils';

import CadastroBox from './CadastroBox';
import TamanhoForm from '../form/TamanhoForm';
import TamanhoTable from '../table/TamanhoTable';

export type TamanhoBoxTipo = {
    urlDatabase: string,
};

export type tamanhoCadastroTipo = {
    enabled: boolean,
    codigo: string,
    nome: string,
    _links?: {
        first: { href: string },
        prev: { href: string },
        self: { href: string },
        next: { href: string },
        last: { href: string },
    },
};

class TamanhoBox extends React.Component<TamanhoBoxTipo> {
    urlDataBase = urlDatabase + '/tamanhos';

    componentDidMount() {
        updateActiveMenuItem('menuItemTamanho', 'menuItemCadastro');
    }

    render() {
        return <CadastroBox cadastroTable={TamanhoTable} cadastroForm={TamanhoForm} urlDataBase={this.urlDataBase}
            needFormEntities={true}
            needFormUpdate={true}
        />;
    }
}

const mapStateToProps = (state: any) => ({
    ...state.idiomaReducer,
});

const mapDispatchToProps = null // (dispatch: dispatchTipo) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TamanhoBox);
