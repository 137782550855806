import React from "react";
import styled from "styled-components";
import { displayFlexCenter } from "../css/styles";
const Footer = () => {
  return (
    <FooterContainer>
      <span>
        {"Desenvolvido por "}
        <a href="https://www.ampix.com.br/">Ampix Software</a>
      </span>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  ${displayFlexCenter}
  font-size: 12px;
  height: ${(props) => props.theme.sizes.navBarHeight};
  width: 100%;
  /* outline: 1px solid red; */
  span {
    font-weight: 400;
  }
  a {
    text-decoration: none;
    font-weight: 600;
    color: ${(props) => props.theme.primaryColor};
  }
`;
export default Footer;
