import { CSSProperties } from "react";
import { coresRGBTipo } from "../reducers/placeholderMessageReducer";
import { dispatchTipo } from "./acaoTemplate";

const actionTypes = {
    UPDATE_PLACEHOLDER_MESSAGE: 'UPDATE:PLACEHOLDER_MESSAGE',
    UPDATE_TOGGLE_IS_TEXT: 'UPDATE_TOGGLE_IS_TEXT:PLACEHOLDER_MESSAGE',
    SET_LAYOUT_ID: 'SET_LAYOUT_ID:PLACEHOLDER_MESSAGE',
    RESET_LAYOUT_ID: 'RESET_LAYOUT_ID:PLACEHOLDER_MESSAGE',
};

export default actionTypes;

export const updatePlaceholderMessageAction = (payload: {id: string, data: {style: CSSProperties, text?: {value: string}, colorChannels: coresRGBTipo}}) => (dispatch: dispatchTipo) => {
    dispatch({
        type: actionTypes.UPDATE_PLACEHOLDER_MESSAGE,
        payload,
    });
}

export const updateIsTextAction = (payload: boolean) => ({
    type: actionTypes.UPDATE_TOGGLE_IS_TEXT,
    payload,
})

export const setLayoutIdAction = (payload: string) => (dispatch: dispatchTipo) => {
    dispatch(({
        type: actionTypes.SET_LAYOUT_ID,
        payload,
    }));
}
