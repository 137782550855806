import React from 'react';

import "./EmpresaSelectorItem.css";
import { connect } from 'react-redux';

/**
 * Item utilizado no componente EmpresaSelector. 
 */
const EmpresaSelectorItem = (props) =>  {
    const mapKeys = {
        'Enter': props.clickHandler,
        ' ': (evt) => { evt.preventDefault(); return props.clickHandler(evt); },
    };

    const keyHandler = (evt) => {
        try {
            mapKeys[evt.key](evt);
        } catch (err) {}
    };

    return <>
        <div className='pure-u-1-1' >
            <div
                tabIndex={props.tabIndex}
                className='card-container card clickable'
                onClick={props.clickHandler}
                onKeyDown={keyHandler}
            >
                <div className='card-content'>
                    <h1 className='ButtonAlign'>
                        {props.cargo.empresa.nomeFantasia}
                    </h1>
                    <h2 className='ButtonAlign'>
                        {props.cargo.nome}
                    </h2>
                </div>
            </div>
        </div>
    </>;
};

const mapStateToProps = (state) => ({
    tabIndex: state.appReducer.getTabIndex(),
});

export default connect(mapStateToProps, null)(EmpresaSelectorItem);
