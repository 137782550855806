import { updateObject } from "../../utils/ObjectUtils"

import * as actionTypes from "../actions/actionTypes"

/**
 * Estado Inicial. Necessário para o controle de estados. 
 */
const initialState = {
  token: null
  , usuario: null
  , cargoPadrao: null
  , authRedirectPath: "/"
  , authChecked: false
  /** Token de autenticação com o Hub */
  , hubToken: null
  , temp: null
  , inputDoCodigoDeConfirmacao: false
}

/**
 * Reducer para controle de Autenticação e Autorização. 
 * Controla o Estado conforme a Ação executada. 
 * @param {*} state Estado inicial
 * @param {*} action Ação executada
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action)
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
    case actionTypes.AUTH_FAIL: return authFail(state, action)
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action)
    case actionTypes.AUTH_CHECK_STATE_SUCCESS: return authCheckStateSuccess(state, action)
    case actionTypes.AUTH_SET_HUB_TOKEN: return authSetHubToken(state, action)
    case actionTypes.EMAIL_CONFIRMATION_SUCCESS:
      return updateObject(state, {
        persistedEmail: action.email
      })
    case actionTypes.AUTH_UPDATE:
      // Basta controlar a construção do action no outro arquivo.
      return updateObject(state, {
        ...state
        , ...action
      })
    case actionTypes.ATIVAR_INPUT_DO_CODIGO_DE_CONFIRMACAO:
      return ({
        ...state
        , inputDoCodigoDeConfirmacao: true
      })
    case actionTypes.DESATIVAR_INPUT_DO_CODIGO_DE_CONFIRMACAO:
      return ({
        ...state
        , inputDoCodigoDeConfirmacao: false
      })
    default: return state
  }
}
export default reducer

const authStart = state => {
  return updateObject(state, {
    error: null
  })
}

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken
    , usuario: action.usuario
    , cargoPadrao: action.cargoPadrao
    , authRedirectPath: action.authRedirectPath
    , authChecked: true
  })
}

const authFail = state => {
  return updateObject(state, {
    authChecked: true
  })
}

const authLogout = state => {
  return updateObject(state, {
    token: null
    , usuario: null
    , authChecked: true
  })
}

const authCheckStateSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken
    , usuario: action.usuario
    , authChecked: true
  })
}

const authSetHubToken = (state, action) => {
  return updateObject(state, {
    hubToken: action.hubToken
  })
}