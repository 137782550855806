import { OrdenacaoUtils } from "../../../../apps/promo_app_frontend/nucleo/utils/utils"
import { getAppUsuario, updateAppGridGrupoProdutoDisplay } from "../../../../utils/AppUtils"
import * as errorUtils from "../../../../utils/ErrorUtils"
import { getHeaders } from "../../../../utils/HeadersUtils"
import { log } from "../../../../utils/LogUtils"
import { urlDatabase } from "../../../../utils/SecureConnectionUtils"
import {
  getNewSaleItemListMapStorage
  , getSaleSourceNoCompany
  , setNewSaleItemListMapStorage
  , setSaleSourceNoCompany
} from "../../../../utils/StorageUtils/LocalStorageUtils"
import { getURIFromEntity } from "../../../../utils/URIUtils"

import * as actionTypes from "../../actionTypes"
import {
  ESTADO_ITEM_VENDA_AGUARDANDO
  , ESTADO_ITEM_VENDA_ENVIADO
  , ESTADO_ITEM_VENDA_PRODUZIDO
} from "../../../reducers/controleVenda/controleVendaReducer"
import {
  GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_CODE_NUMERICAL
  , GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_NAME_ALPHABETICAL
  , sortProdutoByCodeNumerical
  , sortProdutoByNameAlphabetical
} from "../../../reducers/controleVenda/manutencao/manutencaoVendaReducer"
import { appSpinnerHide, appSpinnerShow, axios } from "../../appAction"

/**
 * Gera um novo item de venda inválido, ou seja, sem quantidade, cujo único propósito é habilitar a exibição dos controles dos produtos.
 * @param {Object} produto
 * @param {Number} sequence se for um produto vendido por peso, poderá precisar de um número de sequência diferente
 * @param {String} observacao seguindo a regra de negócio previamente estabelecida, quando um item fica com quantidade zerada, a observação inserida permanece
 * @param {Boolean} isCargo se o cargo está logado (se não estiver, é um usuário cliente fazendo a venda para ele mesmo (compra))
 * @param {Boolean} obrigarPagarVendaUsuarioSemEmpresa se a empresa aceita vendas feitas pelo usuário sem vínculo com empresa antes do pagamento
 */
export const buildNewSaleItem = (produto, sequence, observacao, isCargo, obrigarPagarVendaUsuarioSemEmpresa) => {
  log("gridGrupoProdutoAction buildNewSaleItem", { produto, sequence, observacao, isCargo })
  return {
    code: produto.codigo,
    itemVenda: {
      estado: produto.quantidadePesada
        ? ESTADO_ITEM_VENDA_PRODUZIDO
        : (isCargo || (!obrigarPagarVendaUsuarioSemEmpresa))
          ? ESTADO_ITEM_VENDA_AGUARDANDO
          : ESTADO_ITEM_VENDA_ENVIADO,
      livre: false,
      precoUnitario: produto.preco,
      produto,
      observacao: observacao || null,
      quantidade: 0,
      usuarioList: [urlDatabase + '/usuarios/' + getAppUsuario()],
      valorTotal: 0
    },
    sequence: sequence || 0
  };
}

export const errorResponse = error => {
  log('gridGrupoProdutoAction errorResponse', error);
  return dispatch => {
    dispatch(errorUtils.requestErrorHandlerDefault(error));
  };
}

/**
 * Busca o método de ordenação de acordo com a estratégia de ordenação selecionada.
 * @param {String} gridGrupoProdutoDisplay estratégia de ordenação selecionada
 */
const getSort = (gridGrupoProdutoDisplay) => (gridGrupoProdutoDisplay === GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_CODE_NUMERICAL)
  ? sortProdutoByCodeNumerical
  : (gridGrupoProdutoDisplay === GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_NAME_ALPHABETICAL)
    ? sortProdutoByNameAlphabetical
    : () => 0

function ordenarPorFiltroDeProdutos(saleItemList = [], filtroDeProdutos = 'nome') {
  log('ordenarPorFiltroDeProdutos', { saleItemList, filtroDeProdutos });

  const produtoList = OrdenacaoUtils.ordenarArrayDeObjetosPorStringItem(saleItemList
    .map((saleItem) => { return {...saleItem.itemVenda.produto, sequence: saleItem.sequence}}), filtroDeProdutos);

  const newSaleItemList = produtoList
    .map((produto) => {
      return saleItemList.find((saleItem) => {
        return saleItem.code === produto.codigo && saleItem.sequence === produto.sequence;
      });
    });

  return newSaleItemList;
}

/**
 * Método executado ao carregar os grupos de produtos ao criar/editar uma venda.
 * Prepara os novos itens de venda temporários.
 * @param {Array} grupoProdutoList
 * @param {Array} produtoList
 * @param {Boolean} firstCall se foi chamado por um `componentDidMount`, por exemplo
 */
export const grupoProdutoLoad = (grupoProdutoList, produtoList, firstCall, callback) => (dispatch, getState) => {
  log('gridGrupoProdutoAction grupoProdutoLoad', { grupoProdutoList, produtoList, firstCall });
  // Busca a origem de venda
  let origemVenda = (getState().controleVendaReducer.origemVendaList || []).find(() => true) || {};
  // Busca os dados da venda que podem estar no local storage
  let newSaleItemList = getNewSaleItemListMapStorage()[getURIFromEntity(origemVenda)] || [];
  let shouldReadFromStorage = firstCall && (!getState().empresaSelectorReducer.cargo) && (newSaleItemList.length > 0);

  const newSaleItemListShouldReadFromStorage = shouldReadFromStorage
    // Usa os dados da venda armazenados no local storage
    ? newSaleItemList.map(newSaleItem => Object.assign({}, newSaleItem, {
      itemVenda: Object.assign({}, newSaleItem.itemVenda, {
        // Atualiza o usuário que pediu o item, já que antes o usuário não estava registrado.
        usuarioList: newSaleItem.itemVenda.usuarioList.map(usuario => usuario.replace('null', getAppUsuario()))
      })
    }))
    // Senão, monta a lista de produtos a partir dos dados recebidos da retaguarda.
    : (produtoList || [])
      .map(produto => buildNewSaleItem(
        produto,
        undefined,
        undefined,
        getState().empresaSelectorReducer.cargo,
        origemVenda.empresa?.parametrosEmpresa?.obrigarPagarVendaUsuarioSemEmpresa));

  const action = {
    type: actionTypes.GRID_GRUPO_PRODUTO_GRUPO_PRODUTO_LOAD,
    grupoProdutoList,
    // Se estiver abrindo a manutenção de venda pela primeira vez, estiver usando usuário não logado
    // e haver dados da venda no local storage.
    newSaleItemList: ordenarPorFiltroDeProdutos(newSaleItemListShouldReadFromStorage || [], origemVenda?.empresa?.parametrosEmpresa?.filtroDeProdutos)
      .sort(getSort(getState().manutencaoVendaReducer.gridGrupoProdutoDisplay))
    // TODO fazer merge dos dados recebidos com os dados do storage
  };
  // Atualiza dados no reducer
  dispatch(action);
  delete action.type;
  callback && callback(action);
  // Limpa os dados do storage, se for o caso.
  if (shouldReadFromStorage && getState().authReducer.token) {
    // Busca a última origem de venda usada por um usuário não registrado
    let saleSourceNoCompany = getSaleSourceNoCompany();
    // Se há, limpa essas informações do storage.
    if (saleSourceNoCompany) {
      setSaleSourceNoCompany(null);
      // Busca os novos itens de venda armazenados no local storage
      let newSaleItemListMap = getNewSaleItemListMapStorage();
      // Remove a origem de venda e os itens
      if (newSaleItemListMap[saleSourceNoCompany.origemVendaURI]) {
        delete newSaleItemListMap[saleSourceNoCompany.origemVendaURI];
        setNewSaleItemListMapStorage(newSaleItemListMap);
      }
    }
  }
  dispatch({
    type: actionTypes.MANUTENCAO_VENDA_LIMPA_MANUTENCAO_VENDA,
    produtoList
  });
};

/**
 * Método que carrega os Grupo de Produtos que possuem Produtos vinculados.
 */
export const loadGrupoProdutoListWithProdutosVinculados = (callback, errorCallback) => (dispatch, getState) => {

  log('gridGrupoProdutoAction loadGrupoProdutoListWithProdutosVinculados');

  dispatch(appSpinnerShow('loadGrupoProdutoListWithProdutosVinculados'));

  let origemVendaURI = null;
  // Se for um usuário sem vínculo com empresa
  if (!getState().empresaSelectorReducer.cargo) {
    // Busca o URI da origem de venda para fazer o papel de empresa
    origemVendaURI = getURIFromEntity((getState().controleVendaReducer.origemVendaList || []).find(() => true) || {})
  }

  axios().get(
    urlDatabase.concat('/grupoProdutos/findListWithProdutosVinculados'),
    getHeaders({
      usoInterno: false,
      ...(origemVendaURI ? { origemVenda: origemVendaURI } : {})
    })
  )
    .then(cadastros => {
      dispatch(grupoProdutoLoad(cadastros.data.content.grupoProdutoList, cadastros.data.content.produtoList, true, callback));
    })
    .catch(error => {
      dispatch(errorResponse(error));
      errorCallback && errorCallback(error);
    })
    .finally(() =>
      dispatch(appSpinnerHide('loadGrupoProdutoListWithProdutosVinculados'))
    );
};

/**
 * Atualiza no `reducer` a exibição de produtos por grupo.
 * @param {*} gridGrupoProdutoDisplay 
 */
export const updateGridGrupoProdutoDisplay = gridGrupoProdutoDisplay => (dispatch, getState) => {
  log('gridGrupoProdutoAction updateGridGrupoProdutoDisplay', { gridGrupoProdutoDisplay });

  updateAppGridGrupoProdutoDisplay(gridGrupoProdutoDisplay);

  // Verifica se deve reordenar a lista de novos itens de venda
  if (getState().manutencaoVendaReducer.gridGrupoProdutoDisplay !== gridGrupoProdutoDisplay) {
    dispatch({
      type: actionTypes.GRID_GRUPO_PRODUTO_GRUPO_PRODUTO_SORT,
      newSaleItemList: ordenarPorFiltroDeProdutos(getState().controleVendaReducer.newSaleItemList || [], getState().controleVendaReducer.origemVendaList.find(() => true)?.empresa?.parametrosEmpresa?.filtroDeProdutos)
        .sort(getSort(gridGrupoProdutoDisplay))
    });
  }

  dispatch({
    type: actionTypes.MANUTENCAO_VENDA_EXIBE,
    gridGrupoProdutoDisplay
  });
};
