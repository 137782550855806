import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { isMobile } from "../../../utils/NavigatorUtils"
import { getReduxWrappedComponent, reduxStateType } from "../../../utils/reduxUtils/reduxUtils"
import { formatI18nString } from "../../../utils/StringUtils"

import { FILTER_TIMER_DELAY, SPINNER_INPUT_ID } from "../../../store/actions/cadastroAction"
import { dispatchTipo } from "../../../store/actions/acaoTemplate"
import { idiomaReducerType } from "../../../store/reducers/idiomaReducer"
import { updateMinVersion, updateMinVersionType } from "../../../store/actions/cadastroEmpresaAction"

import BarraAcoesTable from "../barraAcao/BarraAcoesTable"
import { Button } from "../../UI/Button/Button"
import FiltroInputDefault from "../../UI/filtroInputDefault/FiltroInputDefault"
import HelpParagraph from "../../UI/HelpParagraph/HelpParagraph"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"
import { pageType } from "../../../utils/entidadeUtils/pageType"
import linksType from "../../Paginacao/Tipos/linksType"
import { Moment } from "moment"
import { TableModelModularType } from "../table/TableModel"

export type CadastroListType = {
  atualizaContent: () => void,
  filterProperties: string[],
  links: linksType,
  onNavigate: (link: string) => void,
  onDelete:  (cadastro: Record<string,any>) => void,
  onFilterTimerCompleted: () => void,
  onFilterTimerRestart: () => void,
  onUpdate:  (cadastro: Record<string,any>) => void,
  page?: pageType,
  pageSize: number,
  registerHelp: string,
  tableModel: React.ElementType<TableModelModularType>,
  filtroValue: string|Moment,
  updatePageSize: (pageSize: number) => void,

  cadastroList?: Record<string,any>[],
  canToggleExpand?: boolean,
  objetoCadastro?: string,
  printExternalEnabled?: boolean,
  printInternalEnabled?: boolean,
  unique?: boolean,
}

type CadastroListReduxStateType = idiomaReducerType & {
  objetoCadastroLast: string,

  minVersion?: boolean,
};

type CadastroListReduxDispatchType = {
  updateMinVersion: updateMinVersionType,
};

type CadastroListTypeWithReducer = CadastroListType & CadastroListReduxStateType & CadastroListReduxDispatchType;

/**
 * Classe que monta os componentes e trata os cliques nos botões.
 */
class CadastroList_ extends React.Component<CadastroListTypeWithReducer> {
  state = {
    cadastroListHeight: "auto",
  }

  filtroComponent: any;

  updateCadastroListHeight = () => {
    if (isMobile) {
      this.setState({ cadastroListHeight: window.innerHeight / 2 });
    } else {
      this.setState({ cadastroListHeight: "auto" });
    }
  }

  componentDidMount = () => {
    window.addEventListener("resize", () => {
      this.updateCadastroListHeight();
    })

    this.updateCadastroListHeight();
  }

  /**
   * Método que monta o componente.
   */
  render() {
    log("CadastroList render")

    // Passa para a variável o modelo de tabela recebido por parâmetro
    const TableModel = this.props.tableModel;

    let tableModel = <TableModel cadastroList={this.props.objetoCadastro === this.props.objetoCadastroLast ? this.props.cadastroList : []}
      onUpdate={this.props.onUpdate} onDelete={this.props.onDelete} />;

    if (this.props.objetoCadastro !== "empresa") {
      tableModel = <WidthAwareDiv>{tableModel}</WidthAwareDiv>;
    }

    return <div>
      <WidthAwareDiv>
        <HelpParagraph
          children={[
            this.props.registerHelp,
            formatI18nString(getStrings().tableNavigationBarHelp())]
            .concat((this.props.printExternalEnabled || this.props.printInternalEnabled) ? [getStrings().registerPrintCodeHelp] : [])
            .concat([
              getStrings().filterHelp(false),
              formatI18nString(getStrings().registerTableOptionHelp())])}
        />
      </WidthAwareDiv>

      <BarraAcoesTable
        unique={this.props.unique}
        handleNovo={this.props.atualizaContent}
        onNavigate={this.props.onNavigate}
        updatePageSize={this.props.updatePageSize}
        filterProperties={this.props.filterProperties}
        printExternalEnabled={this.props.printExternalEnabled}
        printInternalEnabled={this.props.printInternalEnabled}
      />

      {this.props.canToggleExpand
        ? <Button className="color-light-gray text-color-black height_2dot5em width_5em padding_2dot5px"
          onClick={() => this.props.updateMinVersion(!this.props.minVersion)}
        >
          {this.props.minVersion ? getStrings().expand : getStrings().minimize}
        </Button>
        : null
      }

      <WidthAwareDiv>
        <div className="sub-form">
          {/* Filtro */}
          <FiltroInputDefault
            // @ts-ignore
            defaultValue={this.props.filtroValue}
            ref={(input) => {
              this.filtroComponent = getReduxWrappedComponent(input);
            }}
            onFilterTimerRestart={this.props.onFilterTimerRestart}
            onFilterTimerCompleted={this.props.onFilterTimerCompleted}
            timerDelay={FILTER_TIMER_DELAY}
            spinnerId={SPINNER_INPUT_ID}
          />
        </div>
      </WidthAwareDiv>

      {/* Tabela de cadastros */}
      <div className="scrollTableByDraggingOutside_cadastroList">
        {tableModel}
      </div>
    </div>;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
* @param {*} state
        */
function mapStateToProps(state: reduxStateType) {
  const props: CadastroListReduxStateType = {
    ...state.idiomaReducer,
    objetoCadastroLast: state.cadastroReducer.objetoCadastroLast,
    minVersion: state.cadastroEmpresaReducer.minVersion,
  };

  return props;
}

function mapDispatchToProps(dispatch: dispatchTipo) {
  const props: CadastroListReduxDispatchType = {
    updateMinVersion: (minVersion) => dispatch(updateMinVersion(minVersion)),
  };

  return props;
}

const CadastroList = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CadastroList_);

/**
* Exporta o último argumento entre parênteses.
*/
export default CadastroList;