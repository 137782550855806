import { css } from 'styled-components';

export const displayFlexCenter = css`
	display: flex;
	align-items: center;
	justify-content: center;
`

export const testeClass = css`
	background-color: red;
	box-shadow: 5px 10px #888888;
`
export const alignLeft = css`
  margin-right: auto;
`;