import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { getReduxWrappedComponent, reduxStateType } from "../../../utils/reduxUtils/reduxUtils"
import { getPageSize } from "../../../utils/StorageUtils/LocalStorageUtils"

import * as appActions from "../../../store/actions/appAction"
import * as cadastroActions from "../../../store/actions/cadastroAction"
import * as cadastroEmpresaActions from "../../../store/actions/cadastroEmpresaAction"
import * as actionTypes from "../../../store/actions/actionTypes"
import { cadastroReducerType, STATE_CADASTRO_FORM } from "../../../store/reducers/cadastroReducer"
import { dispatchTipo } from "../../../store/actions/acaoTemplate"

import CadastroList from "../list/CadastroList"
import { ClienteHttp } from "../../../apps/promo_app_frontend/nucleo/utils/utils"
import { idiomaReducerType } from "../../../store/reducers/idiomaReducer"
import { TableModelModularType } from "./TableModel"

export type CadastroTableType = {
  blankFormData: Record<string,any>,
  filterProperties: string[],
  registerHelp: string,
  tableModel: React.ElementType<TableModelModularType>,
  urlDataBase: string,

  canToggleExpand?: boolean,
  deleteMessageExtra?: string,
  objetoCadastro?: string,
  printExternalEnabled?: boolean,
  printInternalEnabled?: boolean,
  sortProperty?: string,
  unique?: boolean,
};

type CadastroTableReduxStateType = cadastroReducerType & idiomaReducerType;

type CadastroTableReduxDispatchType = {
  cadastroLoadFromServer: cadastroActions.cadastroLoadFromServerType,
  cadastroNavigate: cadastroActions.cadastroNavigateType,
  cadastroDelete: cadastroActions.cadastroDeleteType,
  appNotificationShow: appActions.appNotificationShowType,
  cadastroSwitchUI: cadastroActions.cadastroSwitchUIType,
  cadastroSubmit: cadastroActions.cadastroSubmitType,
  appDialogShow: appActions.appDialogShowType,
  cadastroUpdateFormData: cadastroActions.cadastroUpdateFormDataType,
  cadastroEmpresaUpdateFormData: cadastroEmpresaActions.cadastroEmpresaUpdateFormDataType,
};

type CadastroTableTypeWithReducer = CadastroTableType & CadastroTableReduxStateType & CadastroTableReduxDispatchType;

/**
 * Classe base para a exibição das tabelas dos cadastros,
 * todas as funções que são comuns para todos os cadastros ficam neste arquivo.
 */
class CadastroTable_ extends React.Component<CadastroTableTypeWithReducer> {
  cadastroADeletar: Record<string,any>|null = null;
  ismounted = false;
  blankFormData: Record<string,any>|null = null;
  cadastroListComponent: any;

  /**
   * Retorna se o componente está montado no momento em que o método é chamado,
   * já que ele é passado por parâmetro para ser chamado depois.
   */
  getIsMounted = () => {
    return this.ismounted;
  }

  /**
   * Método executado ao ser alterado o número de cadastros que deve ser exibido em cada página.
   * Recarrega a primeira página dos cadastros,
   * passando por parâmetro o número de cadastros a ser exibido por página.
   * @param {*} pageSize 
   */
  updatePageSize = (pageSize: number) => {
    if (pageSize !== this.props.pageSize && this.cadastroListComponent) {
      this.props.cadastroLoadFromServer(0, pageSize, this.props.urlDataBase, this.getIsMounted, this.props.sortProperty, this.props.filterProperties, this.cadastroListComponent.filtroComponent.getValue(), this.props.objetoCadastro);
    }
  }

  /**
   * Método executado ao clicar no botão "Alterar" da linha da tabela.
   * É aberta a tela de formulário com o cadastro recebido por parâmetro,
   * para que possam ser feitas alterações no mesmo.
   * @param {*} cadastro 
   */
  onUpdate = (cadastro: Record<string,any>) => {
    this.props.cadastroSwitchUI(STATE_CADASTRO_FORM, cadastro, undefined, true, actionTypes.CADASTRO_EDIT);
  }

  /**
   * Método executado ao clicar no botão "Delete" da linha da tabela.
   * É exibida uma notifocação solicitando a confirmação da exclusão do cadastro.
   * @param {*} cadastro 
   */
  onDelete = (cadastro: Record<string,any>) => {

    /**
     * Armazena o cadastro recebido por parâmetro em uma variavel global.
     * Isso é necessário para que o sistema possua as informações do cadastro caso a exclusão seja confirmada.
     */
    this.cadastroADeletar = cadastro;

    // Exibe a notificação solicitando a confirmação da exclusão
    this.props.appNotificationShow(getStrings().removeRegisterQuestion + (this.props.deleteMessageExtra ? (' ' + this.props.deleteMessageExtra) : ''),
      actionTypes.APP_NOTIFICATION_TYPE_INFO_QUESTION, getStrings().remove,
      () => this.props.cadastroDelete(this.cadastroADeletar, this.props.page as any, this.props.pageSize,
        this.props.urlDataBase, this.getIsMounted, this.clearCadastroADeletar,
        this.props.sortProperty, this.props.filterProperties, this.cadastroListComponent.filtroComponent.getValue())
    );
  }

  clearCadastroADeletar = () => {
    this.cadastroADeletar = null;

    return true;
  }

  componentDidMount_ = () => {
    const pageSize = getPageSize(this.props.pageSize);

    let pageNumber = 0;

    if (typeof this.props.page !== 'number') {
      pageNumber = this.props.page?.number || 0;
    }

    this.props.cadastroLoadFromServer(
      pageNumber,
      pageSize,
      this.props.urlDataBase,
      this.getIsMounted,
      this.props.sortProperty,
      this.props.filterProperties,
      (this.cadastroListComponent?.filtroComponent || ({ getValue: () => { } })).getValue(),
      this.props.objetoCadastro);
    
    this.updateBlankFormData({ ...this.blankFormData })

    this.props.cadastroEmpresaUpdateFormData(actionTypes.CADASTRO_EMPRESA_EDIT, { enderecoEmpresa: {}, contatoEmpresa: {} }, null, null, null);
  }

  updateBlankFormData = (blankFormData: Record<string,any>) => {
    this.blankFormData = blankFormData;
    this.props.cadastroUpdateFormData({}, blankFormData);
  }

  /**
   * Método executado APÓS a montagem do componente.
   * Realiza o carregamento dos cadastros na tela.
   */
  componentDidMount() {
    this.ismounted = true;
    this.blankFormData = this.props.blankFormData;

    if (this.props.blankFormData.codigo === '') {
      const getMaxCodEndPoint = this.props.urlDataBase.replace(ClienteHttp.getApi(''), '') + '/maxCodNum';
      ClienteHttp.requisicaoServidor(getMaxCodEndPoint, 'get', true)
        .then((resposta) => {
          const maxCodigo = resposta.data;
          if (maxCodigo) {
            const codigo = parseInt(maxCodigo);
            if (Number.isInteger(codigo)) {
              const proximoCodigo = (codigo + 1).toString().padStart(maxCodigo.length, '0');
              this.updateBlankFormData({ ...this.props.blankFormData, codigo: proximoCodigo });
            }
          }
        })
        .catch((_) => {})
    }

    this.componentDidMount_();
  }

  componentWillUnmount = () => {
    this.ismounted = false;
  };

  /**
   * Método que monta o componente.
   * Repassa para a "CadastroList" os parâmetros 
   * para que efetivamente sejam montados os componentes.
   */
  render() {
    return <CadastroList
      unique={this.props.unique}
      canToggleExpand={this.props.canToggleExpand}
      ref={(input: any) => { if (input && !this.cadastroListComponent) { this.cadastroListComponent = getReduxWrappedComponent(input); } }}
      tableModel={this.props.tableModel}
      onUpdate={this.onUpdate}
      onDelete={this.onDelete}
      onNavigate={(navUri: string) => this.props.cadastroNavigate(navUri, this.props.pageSize)}
      updatePageSize={this.updatePageSize}
      links={this.props.links}
      cadastroList={this.props.cadastroList}
      pageSize={this.props.pageSize}
      atualizaContent={() => this.props.cadastroSwitchUI(STATE_CADASTRO_FORM, this.blankFormData, undefined, undefined, actionTypes.CADASTRO_NEW)}
      onFilterTimerRestart={this.props.cadastroSubmit}
      onFilterTimerCompleted={() => {
        if (this.ismounted) {
          this.props.cadastroLoadFromServer(this.props.page, this.props.pageSize, this.props.urlDataBase, this.getIsMounted,
            // @ts-ignore
            this.props.sortProperty, this.props.filterProperties, this.cadastroListComponent.filtroComponent.getValue(), this.props.objetoCadastro)
        }
      }}
      filtroValue={this.props.filter}
      // @ts-ignore
      objetoCadastro={this.props.objetoCadastro}
      registerHelp={this.props.registerHelp}
      filterProperties={this.props.filterProperties}
      printExternalEnabled={this.props.printExternalEnabled}
      printInternalEnabled={this.props.printInternalEnabled}
    />
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
function mapStateToProps(state: reduxStateType) {
  const props: CadastroTableReduxStateType = {
    ...state.cadastroReducer,
    ...state.idiomaReducer,
  };

  return props;
};

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch: dispatchTipo) {
  const props: CadastroTableReduxDispatchType = {
    cadastroLoadFromServer: (page, pageSize, urlDataBase, isMounted, sortProperty, filterProperties, filter, objetoCadastro) =>
      dispatch(cadastroActions.cadastroLoadFromServer(page, pageSize, urlDataBase, isMounted, sortProperty, filterProperties, filter, objetoCadastro)),
    cadastroNavigate: (navUri, pageSize) =>
      dispatch(cadastroActions.cadastroNavigate(navUri, pageSize)),
    cadastroDelete: (cadastroADeletar, page, pageSize, urlDataBase, isMounted, clearCadastroADeletar, sortProperty, filterProperties, filter) =>
      dispatch(cadastroActions.cadastroDelete(cadastroADeletar, page, pageSize, urlDataBase, isMounted, clearCadastroADeletar, sortProperty, filterProperties, filter)),
    appNotificationShow: (notificationMessage, notificationType, notificationTitle, notificationAction) =>
      dispatch(appActions.appNotificationShow(notificationMessage, notificationType, notificationTitle, notificationAction)),
    cadastroSwitchUI: (state, cadastroDados, keepLoading, formMounted, operation) =>
      dispatch(cadastroActions.cadastroSwitchUI(state, cadastroDados, keepLoading, formMounted, operation)),
    cadastroSubmit: () => dispatch(cadastroActions.cadastroSubmit()),
    appDialogShow: (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles) =>
      dispatch(appActions.appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles)),
    cadastroUpdateFormData: (cadastroDados, cadastroDadosIniciais) => dispatch(cadastroActions.cadastroUpdateFormData(cadastroDados, cadastroDadosIniciais)),
    cadastroEmpresaUpdateFormData: (type, cadastroEmpresaDados, usuario, cargo, contrato) =>
      dispatch(cadastroEmpresaActions.cadastroEmpresaUpdateFormData(type, cadastroEmpresaDados, usuario, cargo, contrato)),
  };

  return props;
};

/**
* Exporta o último argumento entre parênteses.
*/
export const CadastroTable = connect(mapStateToProps, mapDispatchToProps)(CadastroTable_) as React.ElementType<CadastroTableType>;
export default CadastroTable;