import * as actionTypes from "../../../actions/actionTypes"

import { updateObject } from "../../../../utils/ObjectUtils"

/**
 * Itens separados por grupos. Grupos inicialmente fechados.
 */
export const GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_CLOSED = "GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_CLOSED"
/**
 * Itens separados por grupos. Grupos inicialmente abertos.
 */
export const GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_OPEN = "GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_OPEN"
/**
 * Itens misturados e ordenados numericamente por código.
 */
export const GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_CODE_NUMERICAL = "GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_CODE_NUMERICAL"
/**
 * Itens misturados e ordenados alfabeticamente por nome.
 */
export const GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_NAME_ALPHABETICAL = "GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_NAME_ALPHABETICAL"

/**
 * Adicionar novos itens de venda (ainda não persistidos na retaguarda).
 */
export const NEW_SALE_ITEM_AMOUNT_OPERATION_ADD = "NEW_SALE_ITEM_AMOUNT_OPERATION_ADD"
/**
 * Alterar a quantidade de novos itens de venda (ainda não persistidos na retaguarda).
 */
export const NEW_SALE_ITEM_AMOUNT_OPERATION_SET = "NEW_SALE_ITEM_AMOUNT_OPERATION_SET"
/**
 * Tratar a quantidade de novo item de venda como a de um produto vendido a kilo mas pelo valor do livre.
 */
export const NEW_SALE_ITEM_AMOUNT_TYPE_AYCE = "NEW_SALE_ITEM_AMOUNT_TYPE_AYCE"
/**
 * Tratar a quantidade de novo item de venda como a de um produto vendido por unidade.
 */
export const NEW_SALE_ITEM_AMOUNT_TYPE_UNIT = "NEW_SALE_ITEM_AMOUNT_TYPE_UNIT"
/**
 * Tratar a quantidade de novo item de venda como a de um produto vendido a kilo.
 */
export const NEW_SALE_ITEM_AMOUNT_TYPE_WEIGHTED = "NEW_SALE_ITEM_AMOUNT_TYPE_WEIGHTED"

export const sortProdutoByCodeNumerical = (a, b) => {
  let codigoStringA = (((a || {}).itemVenda || {}).produto || {}).codigo || ""
  let codigoStringB = (((b || {}).itemVenda || {}).produto || {}).codigo || ""
  let codigoNumberA = Number(codigoStringA)
  let codigoNumberB = Number(codigoStringB)
  let sequenceA = (a || {}).sequence || 0
  let sequenceB = (b || {}).sequence || 0
  // Ordena pelo código numericamente. Se der empate, o que só deve acontecer se o código não for um número, ordena pelo código alfabeticamente. Se der empate, ordena pela sequência.
  return (codigoNumberA < codigoNumberB) ? -1 : (codigoNumberA > codigoNumberB) ? 1 : (codigoStringA < codigoStringB) ? -1 : (codigoStringA > codigoStringB) ? 1 : (sequenceA < sequenceB) ? -1 : (sequenceA > sequenceB) ? 1 : 0
}

export const sortProdutoByNameAlphabetical = (a, b) => {
  let nomeA = ((((a || {}).itemVenda || {}).produto || {}).nome || "").toLowerCase()
  let nomeB = ((((b || {}).itemVenda || {}).produto || {}).nome || "").toLowerCase()
  let sequenceA = (a || {}).sequence || 0
  let sequenceB = (b || {}).sequence || 0
  // Ordena pelo nome. Se der empate, ordena pela sequência.
  return (nomeA < nomeB) ? -1 : (nomeA > nomeB) ? 1 : (sequenceA < sequenceB) ? -1 : (sequenceA > sequenceB) ? 1 : 0
}

/**
 * Estado Inicial, obrigatório no Reducer.
 */
export const initialState = {

  gridGrupoProdutoDisplay: GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_CLOSED,

  isExibeBotaoPagamento: false,

  manutencaoContent: null,

  /**
   * Armazena os produtos lidos da retaguarda para reinicializar os novos itens de venda.
   */
  produtoList: [],

  /**
   * Se a manutenção de venda está sendo exibido no momento.
   */
  visible: false
}

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.MANUTENCAO_VENDA_EXIBE:
    case actionTypes.MANUTENCAO_VENDA_SET_MANUTENCAO_CONTENT:
    case actionTypes.MANUTENCAO_VENDA_SET_HAS_ITENS_TO_SEND:
    case actionTypes.MANUTENCAO_VENDA_SET_IS_EXIBE_ENCERRAMENTO:
    case actionTypes.MANUTENCAO_VENDA_UPDATE_PRODUTO_REF_LIST:
    case actionTypes.MANUTENCAO_VENDA_LIMPA_MANUTENCAO_VENDA:

      // Basta controlar a construção do action no outro arquivo.
      return updateObject(state, {
        ...state,
        ...action
      })

    default: return state
  }
}

export default reducer
