import React from "react"
import { connect } from "react-redux"

import EnderecoForm from "../../../../cadastros/form/EnderecoForm"

import { dispatchTipo } from "../../../../../store/actions/acaoTemplate"
import { updateFromZipCode } from "../../../../../store/actions/cadastroEmpresaAction"
import { STATE_CADASTRO_EMPRESA_EMPRESA } from "../../../../../store/reducers/cadastroEmpresaReducer"

export interface SignUpCompanyAddressStepType {
  updateReducer: () => void
}

interface SignUpCompanyAddressStepTypeWithReducer extends SignUpCompanyAddressStepType {
  empresaStep: number
  , updateFromZipCode: (zipCode: string, state: string, city: string, neighborhood: string, street: string) => void
} 

class SignUpCompanyAddressStep_ extends React.Component<SignUpCompanyAddressStepTypeWithReducer> {
  pais: any = undefined
  uf: any = undefined
  municipio: any = undefined
  bairro: any = undefined
  endereco: any = undefined
  numero: any = undefined
  complemento: any = undefined
  cep: any = undefined

  render() {
    /* Endereço Empresa */
    return <div id="campos-endereco" className={`${this.props.empresaStep !== 1 ? "hidden" : ""}`}>
        <EnderecoForm
          companyForm={false}
          entity="company"
      />
    </div>
  }
}

function mapStateToProps(state: any) {
  const props = {
    empresaStep: state.cadastroEmpresaReducer.step
  }

  return props
}

function mapDispatchToProps(dispatch: dispatchTipo) {
  const props = {
    updateFromZipCode: (zipCode: string, state: string, city: string, neighborhood: string, street: string) =>
        dispatch(updateFromZipCode(STATE_CADASTRO_EMPRESA_EMPRESA, zipCode, state, city, neighborhood, street))
  }

  return props
}

export const SignUpCompanyAddressStep = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SignUpCompanyAddressStep_)