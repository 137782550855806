import React from 'react';

type propsPadrao = {
  fonte: string,
  alt: string,
  id?: string,
  classe?: string,
  estilo?: React.CSSProperties,
  altura?: string | number,
  largura?: string | number,
}

class Imagem extends React.Component<propsPadrao> {
  render() {
    return <img id={this.props.id} width={this.props.altura} height={this.props.largura} className={this.props.classe} style={this.props.estilo} alt={this.props.alt} src={this.props.fonte} />;
  }
}

export default Imagem;
