import React from 'react';

import { getStrings } from '../../../utils/LocaleUtils';
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { getURIFromEntity } from '../../../utils/URIUtils';

import { connect } from 'react-redux';
import { cadastroLoadTamanhoProdutos } from '../../../store/actions/cadastroAction';

import InputCustomizado from "../../UI/Input/InputCustomizado";

import "./TamanhoForm.css";
import { cadastroBuildOptionsFromEnum } from '../../../utils/SelectUtils';
import { appNotificationShow } from '../../../store/actions/appAction';
import { APP_NOTIFICATION_TYPE_WARNING } from '../../../store/actions/actionTypes';

export const PRECO_PRODUTO_COMBINADO_MAIS_CARO = 'MAIS_CARO';

export const PRECO_PRODUTO_COMBINADO_MEDIA = 'MEDIA';

class GrupoProdutoTamanhoForm extends React.Component {

  constructor(props) {
    super(props);

    const precoProdutoCombinadoOptions = cadastroBuildOptionsFromEnum(true, [PRECO_PRODUTO_COMBINADO_MAIS_CARO, PRECO_PRODUTO_COMBINADO_MEDIA], string => getStrings().combinedProductPriceEnumToString(string));

    this.state = {
      tamanhoList: [],
      precoProdutoCombinadoOptions: precoProdutoCombinadoOptions,
    }
  }

  componentDidMount() {
    this.props.dispatch(cadastroLoadTamanhoProdutos(this.props.urlDataBase,
      (tamanhoProdutos) => {
        this.tamanhoGrupoProdutosOption = tamanhoProdutos;
        this.setState({ tamanhoList: tamanhoProdutos })
      },
      () => {
        if (this.props.loading.tamanho !== false) {
          this.props.cadastroUpdateLoading({ tamanho: false });
        }
      }
    ));
  }

  render() {
    return <>
      <InputCustomizado
        checked={this.props.multiplosTamanhosAtivo}
        tabIndex={this.props.tabIndex}
        id='tamanhoAtivado'
        type='checkbox'
        name='tamanhoAtivado'
        label={getStrings().multipleSizes}
        handleInputValidado={() => {
          if (!this.props.multiplosTamanhosAtivo && !this.state.tamanhoList.length) {
            this.props.dispatch(appNotificationShow(getStrings().noRegisteredSize, APP_NOTIFICATION_TYPE_WARNING));
          }
          else {
            this.props.updateState('multiplosTamanhosAtivo', !this.props.multiplosTamanhosAtivo);
          }
        }}
      />
      {this.props.multiplosTamanhosAtivo && this.state.tamanhoList.length
        ? <fieldset className={'fieldsetStyle'} style={{ paddingTop: '0.5em' }}>
          <table style={{ width: '100%' }}>
            <thead>
              <tr style={{ verticalAlign: 'bottom' }}>
                <th>{getStrings().active}</th>
                <th>{getStrings().size}</th>
                <th>{getStrings().price}</th>
                <th>{getStrings().maxFlavors}</th>
                <th>{getStrings().minFlavors}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.tamanhoList.map((tamanho, index) => {

                const grupoProdutoTamanho = this.props.mapGrupoProdutoTamanho[getURIFromEntity(tamanho)] || {
                  ativo: this.props.possuiTamanhos ? false : true,
                  preco: null,
                  quantidadeMinimaSabores: 1,
                  quantidadeMaximaSabores: 1,
                  tamanho: tamanho,
                };

                return <GrupoProdutoTamanhoRow
                  key={`__grupo_produto_tamanho_row_${index}__`}
                  index={index}
                  grupoProdutoTamanho={grupoProdutoTamanho}
                  updateMapGrupoProdutoTamanho={(key, value) => {
                    const mapGrupoProdutoTamanho = {
                      ...this.props.mapGrupoProdutoTamanho,
                      [key]: value,
                    }
                    this.props.updateState('mapGrupoProdutoTamanho', mapGrupoProdutoTamanho);
                  }}
                />
              })
              }
            </tbody>
          </table>
          <InputCustomizado
            newAPI
            topClassStyle={{ marginTop: '1em' }}
            id='precoProdutoCombinado'
            inputType='singleSelect'
            name='precoProdutoCombinado'
            required={this.props.multiplosTamanhosAtivo}
            value={this.props.precoProdutoCombinado}
            placeholder={getStrings().combinedProductPricePlaceholder}
            label={getStrings().combinedProductPriceLabel}
            options={this.state.precoProdutoCombinadoOptions}
            onChange={value => {
              this.props.updateState('precoProdutoCombinado', value);
            }}
          />
        </fieldset>
        : null
      }
    </>
  }
};

export class GrupoProdutoTamanhoRow extends React.Component {
  componentDidMount() {
    const { tamanho, preco, quantidadeMinimaSabores, quantidadeMaximaSabores } = this.props.grupoProdutoTamanho;
    
    this.tamanho.inputComponent.value = tamanho.nome;
    this.preco.setMaskValue(preco);
    this.quantidadeMinimaSabores.inputComponent.value = quantidadeMinimaSabores;
    this.quantidadeMaximaSabores.inputComponent.value = quantidadeMaximaSabores;
  }

  updateValues = () => {
    const formData = {
      ativo: this.checkAtivo.inputComponent.checked,
      preco: this.preco.getMaskValue(),
      quantidadeMinimaSabores: this.quantidadeMinimaSabores.inputComponent.value,
      quantidadeMaximaSabores: this.quantidadeMaximaSabores.inputComponent.value,
    };

    this.props.updateMapGrupoProdutoTamanho(
      getURIFromEntity(this.props.grupoProdutoTamanho.tamanho),
      {...this.props.grupoProdutoTamanho, ...formData}
    );
  };

  render() {
    return <tr>
      <td style={{ width: '5%', padding: '5px 2.5px' }}>
        <InputCustomizado
          checked={this.props.grupoProdutoTamanho.ativo}
          ref={input => this.checkAtivo = getReduxWrappedComponent(input)}
          type={'checkbox'}
          name={'ativo'}
          handleInputValidado={() => this.updateValues()}
        />
      </td>
      <td style={{ width: '50%', padding: '5px 2.5px' }}>
        <InputCustomizado
          required={this.props.grupoProdutoTamanho.ativo}
          readOnly={true}
          labelStyle={{ height: '3em', textAlign: 'center' }}
          id={`row__${this.props.index}__tamanho`}
          type={'text'}
          name='tamanho'
          placeholder={getStrings().sizePlaceholder}
          tabIndex={-1}
          ref={input => this.tamanho = getReduxWrappedComponent(input)}
        />
      </td>
      <td style={{ width: '14%', padding: '5px 2.5px' }}>
        <InputCustomizado
          required={this.props.grupoProdutoTamanho.ativo}
          style={{ textAlign: 'right' }}
          labelStyle={{ height: '3em', textAlign: 'center' }}
          id={`row__${this.props.index}__preco`}
          max={100000000}
          scale={2}
          inputType='masked'
          type='text'
          validacaoDados='numeroDecimal'
          name='preco'
          ref={input => this.preco = getReduxWrappedComponent(input)}
          placeholder={getStrings().price}
          onBlur={this.props.handleChange}
          handleInputValidado={() => this.updateValues()}
        />
      </td>
      <td style={{ width: '14%', padding: '5px 2.5px' }}>
        <InputCustomizado
          required={this.props.grupoProdutoTamanho.ativo}
          style={{ textAlign: 'right' }}
          labelStyle={{ height: '3em', textAlign: 'center' }}
          id={`row__${this.props.index}__quantidadeMaximaSabores`}
          min={1}
          max={999}
          inputType='number'
          type='text'
          name='quantidadeMaximaSabores'
          ref={input => this.quantidadeMaximaSabores = getReduxWrappedComponent(input)}
          placeholder={getStrings().maxFlavors}
          handleInputValidado={() => this.updateValues()}
        />
      </td>
      <td style={{ width: '14%', padding: '5px 2.5px' }}>
        <InputCustomizado
          required={this.props.grupoProdutoTamanho.ativo}
          style={{ textAlign: 'right' }}
          labelStyle={{ height: '3em', textAlign: 'center' }}
          id={`row__${this.props.index}__quantidadeMinimaSabores`}
          min={1}
          max={999}
          inputType='number'
          type='text'
          name='quantidadeMinimaSabores'
          ref={input => this.quantidadeMinimaSabores = getReduxWrappedComponent(input)}
          placeholder={getStrings().minFlavors}
          handleInputValidado={() => this.updateValues()}
        />
      </td>
    </tr>;
  }
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(GrupoProdutoTamanhoForm);