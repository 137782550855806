import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"

import { updateFbUsersDataAction } from "../../../store/reducers/f3/fbUsersDataReducer"
import { authenticationWithF3 } from "../../../store/actions/authAction"

import { getF3UserPicture } from "../../Auth/F3SDK/F3SDKReact"
import F3LoginComponent from "../../Auth/F3SDK/F3LoginComponent"
import F3ButtonComponent from "../../Auth/F3SDK/F3ButtonComponent"
import F3Logout from "../../Auth/F3SDK/F3Logout"

import "./F3DialogContent.css";


const LoginComponent = (props) => {
    return <F3LoginComponent label={getStrings().f3LoginWithAnotherUser} alwaysLogout getFields={props.getFields} />;
}

class F3DialogContent extends React.Component {
    constructor() {
        super();
        this.state = { connectedIndex: -1 };
    }

    componentDidMount() {
        log('F3DialogContent componentDidMount', this.props.list);
        this.removeUserFromList();
    }

    componentDidUpdate() {
        this.removeUserFromList();
    }

    removeUserFromList = () => {
        const FB = window.FB;

        if (FB) {
            FB.getLoginStatus((response) => {
                if ((response || {}).status !== 'connected' && Object.keys(this.props.list).length > 0) {
                    this.props.updateList({});
                }
            });
        }
    }

    removeList = (FB, response) => {
        if ((response || {status: 'unknown'}).status === 'unknown') {
            this.props.updateList({});
        }
        else {
            FB.logout((response) => {
                this.removeList(FB, response);
            });
        }
    }

    updateUser = (FB, user_id) => {
        FB.login((response) => {
            if ((response || {}).status === 'connected') {
                const user = {[user_id]: {...this.props.list[user_id], token: response.authResponse.accessToken}};
                
                this.props.updateList(user);
                this.props.authenticate(response.authResponse.accessToken);
            }
        });
    }

    loginResponse = (response) => {
        if ((response || {}).status === 'connected') {
            const FB = window.FB;

            if (FB) {
                const userId = response.authResponse.userID;
                const token = response.authResponse.accessToken;

                FB.api(`/${userId}`, {fields: 'name'}, (response) => {
                    const nome = (response || {name: null}).name;

                    getF3UserPicture(userId, response => {
                        const list = {[userId]: {
                            token,
                            nome,
                            imagem_do_perfil: ((response || {}).data || {url: null}).url,
                        }};

                        this.props.updateList(list);
                    });
    
                    this.props.authenticate(token);
                });
            }
        } else {
            this.removeUserFromList();
        }
    }

    render() {
        if (Object.keys(this.props.list).length > 0) {
            return <div className='F3DialogComponent'>{
                (() => {
                    const index = 0;
                    const userId = Object.keys(this.props.list)[index];
                    const usuario = this.props.list[userId];

                    return <div className='f3-dialog-content' key={index}>
                        <button className='f3-button' onClick={() => {
                            const FB = window.FB;

                            if (FB) {
                                FB.getLoginStatus((response) => {
                                    if ((response || {}).status === 'connected') {
                                        if (response.authResponse.accessToken === usuario.token) {
                                            this.props.authenticate(response.authResponse.accessToken);
                                        } else {
                                            FB.logout((response) => {
                                                if ((response || {}).status === 'unknown') {
                                                    this.updateUser(FB, userId);
                                                }
                                            });
                                        }
                                    } else {
                                        this.updateUser(FB, userId);
                                    }
                                });
                            }
                        }} children={
                            <div className='f3-button-innerHTML'>
                                <img className='user-picture' alt='Imagem de perfil' src={usuario.imagem_do_perfil} />
                                <p>{usuario.nome}</p>
                                <p>{usuario.sobrenome}</p>
                            </div>
                        } />
                        {this.state.connectedIndex === index
                            ? <div className='rounded-button' style={{backgroundColor: 'lightgreen'}} />
                            : null
                        }
                    </div>;
                })()}
                <LoginComponent getFields={this.loginResponse} />
                <F3ButtonComponent onClick={() => {
                    F3Logout((response) => {
                        if ((response || {}).status === 'unknown') {
                            this.props.updateList({});
                        }
                    });
                }} children={getStrings().f3Logout} />
            </div>
        }
        else {
            return (<LoginComponent getFields={this.loginResponse} />);
        }
    }
};

const mapStateToProps = state => ({
    updateLocale: state.idiomaReducer,
    list: state.fbUsersDataReducer,
    F3DialogContent: state.F3DialogContent,
});

const mapActionToProps = dispatch => ({
    authenticate: (token) => dispatch(authenticationWithF3(token)),
    updateList: (list) => dispatch(updateFbUsersDataAction(list)),
});

export default connect(mapStateToProps, mapActionToProps)(F3DialogContent);