//@ts-check
import React from 'react';

import DialogTemplate from '../DialogTemplate';
import { connect } from 'react-redux';
import { LocalidadeUtils } from '../../../nucleo/utils/utils';

/**
 * 
 * @param {import('./types').errorDialogPropsType} props 
 * @returns 
 */
function ErroDialog(props) {
  const { mensagem, ...sanitizedProps } = props;

  return <DialogTemplate {...sanitizedProps}
    fecharHabilitado={true}
    titulo={LocalidadeUtils.obterTraducao().dialog.error}
    style={{maxWidth: '20em'}}
  >
    <label className='alinhar-ao-centro' style={{ textAlign: 'center' }}>{props.mensagem}</label>
  </DialogTemplate>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} _ 
 * @returns 
 */
const mapStateToProps = (_) => ({});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ErroDialog);
