import { log } from './LogUtils';
let moment = require('moment');

export const nullOrUndefined = (var_) => {
    return (var_ === undefined || var_ === null);
};

/**
 * 
 * @param {object|Array} var_ 
 */
export const isEmpty = (var_) => {
    return (isArray(var_) && var_.length === 0) || (isStrictObject(var_) && Object.keys(var_).length === 0);
};

/**
 * Retorna se objeto é `object`. Também retorna `false` se for `undefined` ou `null`.
 * @param {object} var_ 
 */
export const isStrictObject = (var_) => {
    return typeof var_ === 'object' && !(isString(var_) || isArray(var_) || var_ === null);
};

/**
 * Retorna se objeto é `object`.
 * @param {object} var_ 
 */
export const isObject = (var_) => {
    return typeof var_ === 'object' && var_ !== null;
}

/**
 * Retorna se objeto é `Array`. Também retorna `false` se for `undefined` ou `null`.
 * @param {Array} var_ 
 */
export const isArray = (var_) => {
    return Array.isArray(var_);
};

/**
 * Retorna se o array passado por parâmetro não é `null` nem `undefined`, se é um vetor e se possui itens.
 * @param {Array} array 
 */
export const isNonEmptyArray = array => {

    if (isArray(array)) {
        return array.length > 0;
    }

    return false;
};

/**
 * Retorna se o objeto passado por parâmetro não é `null` nem `undefined` e se possui propriedades.
 * @param {Object} object 
 */
export const isNonEmptyObject = object => {

    if (isStrictObject(object)) {
        return Object.keys(object).length > 0;
    }

    return false;
};

/**
 * Retorna se objeto é `number`. Também retorna `false` se for `undefined` ou `null`.
 * @param {number} var_ 
 */
export const isNumber = (var_) => {
    return typeof var_ === 'number';
};


/**
 * Retorna se objeto é `boolean`. Também retorna `false` se for `undefined` ou `null`.
 * @param {any} var_ 
 */
export const isBoolean = (var_) => {
    return typeof var_ === 'boolean';
};

/**
 * 
 * @param {any} var_ 
 */
export const isMoment = (var_) => {
    return typeof var_ === 'object' && var_ instanceof moment;
};

/**
 * 
 * @param {any} var_ 
 */
export const isDate = (var_) => {
    return typeof var_ === 'object' && var_ instanceof Date;
}

/**
 * Retorna se objeto é `string`. Também retorna `false` se for `undefined` ou `null`.
 * @param {any} object 
 */
export const isString = object => {
    // log('utils isString', object);
    return (typeof object === 'string') || (object instanceof String);
};

export const isFunction = var_ => {
    return typeof var_ === "function";
};

/**
 * 
 * @param {any} object 
 */
export const isRegex = (object) => {
    return typeof object === 'object' && (object instanceof RegExp);
};

/**
 * 
 * @param {String} keyOrItem 
 * @param {object|Array} objectOrArray 
 * 
 */
export const in_ = (keyOrItem, objectOrArray) => {
    if (isArray(objectOrArray)) {
        return objectOrArray.includes(keyOrItem);
    }

    if (isObject(objectOrArray)) {
        return keyOrItem in objectOrArray;
    }

    return false;
};

export const keysInObject = (keys, object) => {
    if (!(isNonEmptyArray(keys) && isNonEmptyObject(object))) {
        return false;
    }

    for (let key in keys) {
        if (!(key in object)) {
            return false;
        }
    }

    return true;
}

export const comparaListas = (lista1, lista2, comparator = (oldVal, newVal) => oldVal === newVal) => {
    if ((typeof lista1 !== 'object') || (typeof lista2 !== 'object')) {
        return false;
    }

    // Se ambas as listas não existem, elas são iguais
    if ((!lista1) && (!lista2)) {
        return true;
    }

    // Até aqui, pelo menos tem uma lista

    // Já que tem uma lista, se não tiver outra, elas são diferentes
    if ((!lista1) || (!lista2)) {
        return false;
    }

    let arr1, arr2;

    // Até aqui, tem as duas listas
    if (isStrictObject(lista1)) {
        arr1 = Object.keys(lista1);
        arr2 = Object.keys(lista2);
    } else {
        arr1 = lista1;
        arr2 = lista2;
    }

    // Se a quantidade de elementos for diferente, elas são diferentes
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Se elementos forem vazios, eles são iguais
    if (arr1.length === 0) {
        return true;
    }

    for (let item in lista1) {
        if (!comparator(lista2[item], lista1[item])) {
            return false;
        }
    }

    return true;
}

export const arrayOuObjetoNaoEstaVazio = (var_) => {
    return isNonEmptyArray(var_) || isNonEmptyObject(var_);
};

/**
 * Retorna se algo é igual a outro algo. Considera todos os valores `falsy` igualmente.
 * @param {any} oldValue
 * @param {any} newValue
 * @param {function} comparator função que compara se um objeto é igual ao outro, assumindo que ambos existem. Se não informado, usa `===`.
 */
export const equalsCoerced = (oldValue, newValue, comparator = (oldVal, newVal) => oldVal === newVal) => {
    log('utils equalsCoerced', oldValue, newValue, comparator);
    const oldExists = !!oldValue;
    const newExists = !!newValue;

    // Se nenhum existe, são iguais.
    const test_values_are_falsy = (!oldExists) && (!newExists);
    log('utils equalsCoerced (!oldExists) && (!newExists):', test_values_are_falsy);
    if (test_values_are_falsy) {
        return true;
    }

    // A partir daqui, é certo que pelo menos um existe
    // Se ambos existem, retorna se as propriedades de um são iguais às do outro
    const test_a_value_exist = oldExists && newExists;
    log('utils equalsCoerced oldExists && newExists:', test_a_value_exist);

    if (test_a_value_exist) {
        const result = comparator(oldValue, newValue);
        log('utils equalsCoerced all properties are equal', result);
        return result;
    }

    // A partir daqui, é certo que um existe e outro não.
    log('utils equalsCoerced only one exists');
    return false;
    // Sendo assim, um é diferente do outro.
}
