import criarAction from "../factory/criarAction";

const tiposDeAcoes = {
  ATUALIZAR_BOTAO_VOLTAR: 'ATUALIZAR_BOTAO_VOLTAR:NAVEGACAO',
}

export default tiposDeAcoes;

export const atualizarBotaoVoltar = (carga: {disabled: boolean}) => function(dispatch: any) {
  dispatch(criarAction(carga, tiposDeAcoes.ATUALIZAR_BOTAO_VOLTAR));
}
