import React from "react"
import { home_reader, sourcesInUse_codeReader } from "../../../components/UI/BreadCrumbs/BreadCrumbs"
import { hideSpinner } from "../../../components/UI/Spinner/Spinner"

import { ClienteHttp } from "../../../apps/promo_app_frontend/nucleo/utils/utils"
import { getAppUsuario } from "../../../utils/AppUtils"
import { removeDuplicates } from "../../../utils/ArrayUtils"
import { isEmpresaColetor, isEmpresaPainel } from "../../../utils/CompanyUtils"
import { isString, equalsCoerced } from "../../../utils/ComparatorsUtils"
import * as errorUtils from "../../../utils/ErrorUtils"
import { getHeaders } from "../../../utils/HeadersUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { goBack } from "../../../utils/NavigatorUtils"
import { urlDatabase } from "../../../utils/SecureConnectionUtils"
import { getCurrentHourMinuteSecondFormatted } from "../../../utils/TimeUtils"
import { getIdFromEntity, getURIFromEntity } from "../../../utils/URIUtils"
import { getLastScannedSaleSource, getNewSaleItemListMapStorage, getSaleSourceNoCompany, setLastScannedSaleSource } from "../../../utils/StorageUtils/LocalStorageUtils"

import * as actionTypes from "../actionTypes"
import { axios, appDialogShow, appDialogHide, appNotificationShow, appSpinnerShow, appSpinnerHide, confirmMessage } from "../appAction"

import {
  OPEN_SALE_STATE_LIST
  , STATE_CONTROLE_VENDA_GRID_ORIGENS
  , STATE_CONTROLE_VENDA_GRID_VENDAS
  , STATE_CONTROLE_VENDA_MANUTENCAO_VENDA
  , STATE_CONTROLE_VENDA_NEW_SALE_FROM_BAR_QR_CODE
  , STATE_CONTROLE_VENDA_NOVA_VENDA
  , STATE_CONTROLE_VENDA_BAR_QR_CODE_TO_NEW_SALE
  , STATE_CONTROLE_VENDA_GET_SALE_FROM_CALL
  , STATE_CONTROLE_VENDA_NEW_SALE_FROM_CALL
  , STATE_CONTROLE_VENDA_LIST_SALES_FROM_CALL
  , STATE_CONTROLE_VENDA_CLIENT_LAST_SALES
  // , STATE_CONTROLE_VENDA_PAGAR_VENDAS
  // , STATE_CONTROLE_VENDA_PAGAR_ORIGENS
  , saleProductKey
  , STATE_CONTROLE_VENDA_GET_SALE_FROM_QR_CODE
} from "../../reducers/controleVenda/controleVendaReducer"
import { NIVEL_USUARIO_SEM_EMPRESA_VENDA } from "../../reducers/empresaSelectorReducer"
import * as codeReaderActions from "../codeReaderAction"
import * as empresaSelectorActions from "../empresaSelectorAction"
import * as manutencaoVendaActions from "../controleVenda/manutencao/manutencaoVendaAction"
import {
  COMPANY_INFORMATION_ENTITY
  , SALE_SOURCE_ENTITY
} from "../codeReaderAction"
import { SALE_ITEM_UPDATED } from "../subscriberAction"
import {
  MANUTENCAO_CONTENT_ITENS
} from "../controleVenda/manutencao/manutencaoVendaAction"
import { initialState as initialStateManutencao } from "../../reducers/controleVenda/manutencao/manutencaoVendaReducer"

import DialogNovaVenda from "../../../components/vendas/dialog/DialogNovaVenda"
import DialogOrigemVendaChamada from "../../../components/Subscriber/DialogOrigemVendaChamada"
import DialogOrigensLivres from "../../../components/vendas/dialog/DialogOrigensLivres"
import DialogPayOrigensVenda from "../../../components/vendas/dialog/DialogPayOrigensVenda"
import DialogPrintVendas from "../../../components/vendas/dialog/DialogPrintVendas"

/**
 * Id do spinner que é exibido nas telas de venda.
 */
export const SPINNER_VENDAS_ID = "spinnerVendas"

/**
 * Id do spinner que é exibido no filtro de origens de venda.
 */
export const SPINNER_FILTRO_ORIGEM_VENDA_ID = "spinnerFiltroOrigemVenda"

/**
 * Id do spinner que é exibido no filtro de vendas.
 */
export const SPINNER_FILTRO_VENDA_ID = "spinnerFiltroVenda"

const barCodeFormatPrototype = {
  i18n: function () { return `barcode_${this.key}`; },
  reader: function () { return `${this.key}_reader`; }
}

export const barCodeFormatList = [
  Object.assign({}, barCodeFormatPrototype, {
    key: '2of5',
    persistenceName: 'twoOfFive'
  }),
  Object.assign({}, barCodeFormatPrototype, {
    key: 'i2of5',
    persistenceName: 'interleavedTwoOfFive'
  }),
  Object.assign({}, barCodeFormatPrototype, {
    key: 'codabar',
    persistenceName: 'codabar'
  }),
  Object.assign({}, barCodeFormatPrototype, {
    key: 'code_39',
    persistenceName: 'code39'
  }),
  Object.assign({}, barCodeFormatPrototype, {
    key: 'code_39_vin',
    persistenceName: 'code39Vin'
  }),
  Object.assign({}, barCodeFormatPrototype, {
    key: 'code_93',
    persistenceName: 'code93'
  }),
  Object.assign({}, barCodeFormatPrototype, {
    key: 'code_128',
    persistenceName: 'code128'
  }),
  Object.assign({}, barCodeFormatPrototype, {
    key: 'ean',
    persistenceName: 'ean'
  }),
  Object.assign({}, barCodeFormatPrototype, {
    key: 'ean_8',
    persistenceName: 'ean8'
  }),
  Object.assign({}, barCodeFormatPrototype, {
    key: 'upc',
    persistenceName: 'upc'
  }),
  Object.assign({}, barCodeFormatPrototype, {
    key: 'upc_e',
    persistenceName: 'upcE'
  }),
];

export const errorResponse = error => dispatch => {
  log('controleVendaAction errorResponse', error);

  dispatch(errorUtils.requestErrorHandlerDefault(error));
}

/**
 * Método que carrega as origens que possuem vendas não encerradas vinculadas.
 */
export const loadOrigensEmUso = (filterOrigemVenda, filterVenda, options = { cb: () => { } }) => {
  log('controleVendaAction loadOrigensEmUso', filterOrigemVenda, filterVenda);

  return dispatch => {

    let params = {};
    // Se houver, adiciona o valor do filtro de origens de venda à requisição
    if (filterOrigemVenda) {
      params.origemVendaSearch = filterOrigemVenda;
    }
    // Se houver, adiciona o valor do filtro de vendas à requisição
    if (filterVenda) {
      params.vendaSearch = filterVenda;
    }
    dispatch(appSpinnerShow('loadOrigensEmUso'));
    // Recupera os arquivos do servidor
    axios().get(
      urlDatabase.concat('/origemVendas/findOrigemVendaListWithVendaNaoEncerrada'),
      getHeaders(params)
    )
      .then(cadastros => {

        if (cadastros && cadastros.data && cadastros.data.content) {

          const origemVendaList = cadastros.data.content.findOrigemVendaListWithVendaNaoEncerrada
            ? cadastros.data.content.findOrigemVendaListWithVendaNaoEncerrada
            : [];

          dispatch(origemVendaLoad(origemVendaList, filterOrigemVenda, filterVenda, cadastros.data.content.containsFree));
          // Atualiza o valor padrão para o campo de percentual padrão da tela de pagamento de venda(s).
          let percentual = cadastros.data.content.getPercentualComissao;
          dispatch({
            type: actionTypes.PAGAMENTO_VENDAS_UPDATE,
            percentualComissaoEmpresa: percentual
          });
        }
      })
      .catch(error => {
        dispatch(errorResponse(error));
      })
      .finally(() => {
        hideSpinner(SPINNER_FILTRO_ORIGEM_VENDA_ID);
        hideSpinner(SPINNER_FILTRO_VENDA_ID);
        dispatch(appSpinnerHide('loadOrigensEmUso'));

        window.setTimeout(() => {
          options.cb && options.cb();
        }, 500);
      });

    return;
  }
}

/**
 * Método executado ao carregar as origens de venda.
 */
export const origemVendaLoad = (newOrigemVendaList, filterOrigemVenda, filterVenda, containsFree, nivelUsuarioSemEmpresa = NIVEL_USUARIO_SEM_EMPRESA_VENDA, options = { then: () => { } }) => (dispatch, getState) => {
  log('controleVendaAction origemVendaLoad', { newOrigemVendaList, filterOrigemVenda, filterVenda, containsFree, nivelUsuarioSemEmpresa });

  const payload = {
    type: actionTypes.CONTROLE_VENDA_LOAD_ORIGEM_VENDA_LIST,
    origemVendaList: newOrigemVendaList,
    ...((filterOrigemVenda !== undefined) ? { filtroOrigemVendaValue: filterOrigemVenda } : {}), // permitir null para limpar o filtro
    ...((filterVenda !== undefined) ? { filtroVendaValue: filterVenda } : {}), // permitir null para limpar o filtro
    clearTotal: actionTypes.REDUCER_DEFAULT,
    commission: {
      value: getState().pagamentoVendasReducer.percentualComissaoAtual,
      action: actionTypes.REDUCER_HOLD
    },
    containsFree: containsFree,
    discount: {
      type: getState().pagamentoVendasReducer.discountType,
      value: getState().pagamentoVendasReducer.discountValue,
      action: actionTypes.REDUCER_HOLD
    },
    newSalePaymentItemMap: actionTypes.REDUCER_DEFAULT,
    nivelUsuarioSemEmpresa,
    ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
    remainingAmountMap: actionTypes.REDUCER_DEFAULT,
    sentSaleItemList: actionTypes.REDUCER_DEFAULT,
    sentSalePaymentItemMap: actionTypes.REDUCER_DEFAULT
  };

  dispatch(payload);
  options?.then && options.then();
};

/**
 * Método executado ao carregar as vendas vinculadas à origem.
 */
export const vendaFromOrigemLoad = (newVendaFromOrigemList, filtrarVendas = [], options = { cb: () => { } }) => (dispatch, getState) => {
  log('controleVendaAction vendaFromOrigemLoad', newVendaFromOrigemList);

  const filtrarVendasIds = filtrarVendas.map((venda) => getIdFromEntity(venda));
  const origemVendaListFromReducer = getState().controleVendaReducer.origemVendaList;
  const novaOrigemVendaList = origemVendaListFromReducer.map(origemVenda => {
    origemVenda.vendaList = filtrarVendasIds.length
      ? newVendaFromOrigemList
        .filter((venda) => {
          const vendaId = getIdFromEntity(venda);
          const estaNaLista = filtrarVendasIds.includes(vendaId);

          return estaNaLista && (() => {
            const origemVendaCodigoDaVenda = venda.origemVenda.codigo;
            const origemVendaCodigoDoReducer = origemVenda.codigo;
            const mesmaOrigem = origemVendaCodigoDaVenda === origemVendaCodigoDoReducer;

            return mesmaOrigem;
          })();
        })
      : newVendaFromOrigemList;
    return origemVenda;
  });

  dispatch({
    type: actionTypes.CONTROLE_VENDA_LOAD_VENDA_LIST,
    // Atribui a lista de vendas a todas as origens, mas só deve ter uma origem na lista
    origemVendaList: novaOrigemVendaList,
    vendaAmount: newVendaFromOrigemList.length,
    clearTotal: actionTypes.REDUCER_DEFAULT,
    commission: {
      value: getState().pagamentoVendasReducer.percentualComissaoAtual,
      action: actionTypes.REDUCER_HOLD
    },
    discount: {
      type: getState().pagamentoVendasReducer.discountType,
      value: getState().pagamentoVendasReducer.discountValue,
      action: actionTypes.REDUCER_HOLD
    },
    newSalePaymentItemMap: actionTypes.REDUCER_DEFAULT,
    nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
    ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
    remainingAmountMap: actionTypes.REDUCER_DEFAULT,
    sentSaleItemList: actionTypes.REDUCER_DEFAULT,
    sentSalePaymentItemMap: actionTypes.REDUCER_DEFAULT
  });

  window.setTimeout(() => {
    options.cb && options.cb();
  }, 500);
};

/**
 * Método que exibe a Grid de Origens que atulmente estão em uso.
 */
export const exibeGridOrigemVendaEmUso = () => {
  log('controleVendaAction exibeGridOrigemVendaEmUso');

  return dispatch => {

    dispatch({
      type: actionTypes.CONTROLE_VENDA_SHOW_GRID_ORIGENS,
      state: STATE_CONTROLE_VENDA_GRID_ORIGENS,
      fromCallState: null,
      filtroOrigemVendaValue: '',
      filtroVendaValue: '',
      vendaAmount: 0
    });

    dispatch({
      type: actionTypes.MANUTENCAO_VENDA_LIMPA_MANUTENCAO_VENDA,
      vendaURI: null
    });
  }
}

/**
 * Método que exibe as vendas não encerradas que estão vinculadas a OrigemVenda recebida por parâmetro.
 * @param {*} origemVenda
 */
export const exibeGridVendaFromOrigem = origemVenda => (dispatch, getState) => {
  log('controleVendaAction exibeGridVendaFromOrigem', origemVenda);

  dispatch({
    type: actionTypes.CONTROLE_VENDA_SHOW_GRID_VENDAS,
    state: STATE_CONTROLE_VENDA_GRID_VENDAS,
    origemVendaList: [origemVenda],
    clearTotal: actionTypes.REDUCER_DEFAULT,
    commission: {
      value: getState().pagamentoVendasReducer.percentualComissaoAtual,
      action: actionTypes.REDUCER_HOLD
    },
    discount: {
      type: getState().pagamentoVendasReducer.discountType,
      value: getState().pagamentoVendasReducer.discountValue,
      action: actionTypes.REDUCER_HOLD
    },
    newSalePaymentItemMap: actionTypes.REDUCER_DEFAULT,
    nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
    ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
    remainingAmountMap: actionTypes.REDUCER_DEFAULT,
    sentSaleItemList: actionTypes.REDUCER_DEFAULT,
    sentSalePaymentItemMap: actionTypes.REDUCER_DEFAULT
  });
  // Oculta a dialog para seleção da Origem livre
  dispatch(appDialogHide());
};

/**
 * Método que exibe a tela de manutenção/criação de venda.
 * @param {*} venda
 * @param {*} newVenda para controlar se o próximo estado reflete uma venda nova ou existente
 */
export const exibeManutencaoVenda = (venda, newVenda = false) => (dispatch, getState) => {
  log('controleVendaAction exibeManutencaoVenda', venda, newVenda);

  dispatch(manutencaoVendaActions.updateVendaPersistedData(venda, {
    clearTotal: actionTypes.REDUCER_DEFAULT,
    commission: {
      value: getState().pagamentoVendasReducer.percentualComissaoAtual,
      action: actionTypes.REDUCER_HOLD
    },
    discount: {
      type: getState().pagamentoVendasReducer.discountType,
      value: getState().pagamentoVendasReducer.discountValue,
      action: actionTypes.REDUCER_HOLD
    },
    newSalePaymentItemMap: newVenda ? actionTypes.REDUCER_DEFAULT : actionTypes.REDUCER_SET,
    nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
    ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
    remainingAmountMap: newVenda ? actionTypes.REDUCER_DEFAULT : actionTypes.REDUCER_SET,
    sentSaleItemList: newVenda ? actionTypes.REDUCER_DEFAULT : actionTypes.REDUCER_SET,
    sentSalePaymentItemMap: newVenda ? actionTypes.REDUCER_DEFAULT : actionTypes.REDUCER_SET
  }));

  dispatch({
    type: actionTypes.CONTROLE_VENDA_SHOW_MANUTENCAO_VENDA,
    state: newVenda ? STATE_CONTROLE_VENDA_NOVA_VENDA : STATE_CONTROLE_VENDA_MANUTENCAO_VENDA
  });
};

/**
 * Exibe o `dialog` que pergunta sobre os dados da nova venda e a inicia se confirmado.
 * @param {Object} origemVenda
 * @param {Boolean} noCompany decide qual método será usado ao cancelar proceso
 */
export const setupNewVenda = (origemVenda, noCompany) => dispatch => {
  log('controleVendaAction setupNewVenda', { origemVenda, noCompany });

  let onDismiss = () => dispatch(resetFromCall());

  if (noCompany) {
    onDismiss = () => dispatch(cancelSaleNoCompany());
  }

  dispatch(appDialogShow(
    <DialogNovaVenda
      noCompany={noCompany}
      origemVenda={origemVenda}
    />,
    getStrings().newSale,
    { maxHeight: '280px' },
    null,
    onDismiss,
    onDismiss));
};

/**
 * Inicia de fato a nova venda com base nos dados coletados pelo `dialog`.
 * @param {*} newVendaData
 */
export const continueNewVenda = newVendaData => (dispatch, getState) => {
  log('controleVendaAction continueNewVenda', newVendaData);

  // Ajusta a lista de vendas da origem para conter a venda que será gerenciada
  newVendaData.origemVenda.vendaList = [{
    ...newVendaData,
    origemVenda: newVendaData.origemVenda._links.self.href,
    _links: {
      self: {
        href: 'nova_venda'
      }
    }
  }];
  // Atualiza a lista de origens no estado para conter a origem de venda selecionada.
  // Se já possuia uma origem selecionada, mesmo assim ela precisa ter sua lista de vendas atualizada.
  dispatch({
    type: actionTypes.CONTROLE_VENDA_LOAD_NOVA_VENDA,
    origemVendaList: [newVendaData.origemVenda],
    vendaURI: '',
    clearTotal: actionTypes.REDUCER_DEFAULT,
    commission: {
      value: getState().pagamentoVendasReducer.percentualComissaoAtual,
      action: actionTypes.REDUCER_HOLD
    },
    discount: {
      type: getState().pagamentoVendasReducer.discountType,
      value: getState().pagamentoVendasReducer.discountValue,
      action: actionTypes.REDUCER_HOLD
    },
    newSalePaymentItemMap: actionTypes.REDUCER_DEFAULT,
    nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
    ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
    remainingAmountMap: actionTypes.REDUCER_DEFAULT,
    sentSaleItemList: actionTypes.REDUCER_DEFAULT,
    sentSalePaymentItemMap: actionTypes.REDUCER_DEFAULT
  });
  dispatch(manutencaoVendaActions.setManutencaoContent(manutencaoVendaActions.MANUTENCAO_CONTENT_PRODUTO));
  dispatch(exibeManutencaoVenda(newVendaData.origemVenda.vendaList[0], true));
};

/**
 * Armazena os dados da nova venda para ser iniciada logo em seguida.
 * @param {*} newVendaData
 */
export const setupNewVendaFromCall = newVendaData => (dispatch, getState) => {
  log('controleVendaAction setupNewVendaFromCall', newVendaData);

  dispatch({
    type: actionTypes.CONTROLE_VENDA_LOAD_NOVA_VENDA,
    fromCallState: STATE_CONTROLE_VENDA_NEW_SALE_FROM_CALL,
    newVendaData,
    clearTotal: actionTypes.REDUCER_DEFAULT,
    commission: {
      value: getState().pagamentoVendasReducer.percentualComissaoAtual,
      action: actionTypes.REDUCER_HOLD
    },
    discount: {
      type: getState().pagamentoVendasReducer.discountType,
      value: getState().pagamentoVendasReducer.discountValue,
      action: actionTypes.REDUCER_HOLD
    },
    newSalePaymentItemMap: actionTypes.REDUCER_DEFAULT,
    nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
    ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
    remainingAmountMap: actionTypes.REDUCER_DEFAULT,
    sentSaleItemList: actionTypes.REDUCER_DEFAULT,
    sentSalePaymentItemMap: actionTypes.REDUCER_DEFAULT
  });
};

/**
 * @param {boolean} restricted
 * @param {(cadastros) => void} completeCallback
 * 
 * Método que carrega as origens livres (sem vendas abertas).
 * Origens com venda apenas aguardando-encerramento são consideradas livres.
 */
export const loadOrigensLivres = (restricted = false, completeCallback = () => {}) => {
  log('controleVendaAction loadOrigensLivres');
  return dispatch => {
    dispatch(appSpinnerShow('loadOrigensLivres'));
    // Recupera os arquivos do servidor
    axios().get(
      urlDatabase.concat('/origemVendas/findOrigemVendaListLivre'),
      getHeaders({ restricted }),
    )
      .then(cadastros => {
        dispatch({
          type: actionTypes.CONTROLE_VENDA_LOAD_FREE,
          freeOrigemVendaList: cadastros.data.content
        });
        dispatch(showDialogOrigensLivres());
        completeCallback(cadastros)
      })
      .catch(error =>
        dispatch(errorResponse(error))
      )
      .finally(() =>
        dispatch(appSpinnerHide('loadOrigensLivres'))
      );
  }
};

/**
 * 
 * @param {string} tipoOrigem 
 * @param {(resposta: any) => void} completeCallback 
 * @returns 
 */
export const loadAndSetOrigemPedidos = (tipoOrigem, completeCallback = () => {}) => {
  log('controleVendaAction loadAndSetOrigemPedidos');

  return (dispatch) => {
    ClienteHttp.requisicaoServidor('origemVendas/loadOrigemPedidos', 'get', true, { params: { tipoOrigem } })
      .then((resposta) => {
        const origemVenda = resposta.data;

        dispatch(continueNewVenda({ origemVenda, nome: getCurrentHourMinuteSecondFormatted(), numeroPessoas: 1 }));

        completeCallback(resposta);
      });
  };
};

const showDialogOrigensLivres = () => {
  log('controleVendaAction showDialogOrigensLivres');
  return dispatch => {
    // Style da dialog
    const dialogStyles = {
      maxWidth: '750px',
      maxHeight: '550px',
      width: '80%',
      height: '80%',
      top: '8%',
      left: '8%',
      marginTop: '0px',
      marginLeft: '0px',
      margin: 'auto'
    };

    // Style do title da dialog
    const titleStyle = {
      TextAlign: 'center'
    }

    dispatch(appDialogShow(
      <DialogOrigensLivres />,
      getStrings().freeSources(),
      dialogStyles,
      titleStyle
    ));
  }
}

/**
 * Método que carrega as vendas abertas vinculadas a esta OrigemVenda.
 */
export const loadVendaListAbertaFromOrigemVenda = (filtrarVendas = [], options = { cb: () => { } }) => (dispatch, getState) => {
  log('controleVendaAction loadVendaListAbertaFromOrigemVenda');

  const filtrarOrigem = filtrarVendas.length
    ? filtrarVendas.map((vendas) => {
      return getURIFromEntity(vendas.origemVenda)
    })
    : [getURIFromEntity(getState().controleVendaReducer.origemVendaList[0])];

  filtrarOrigem.map((origemVenda) => loadVendaListAbertaFromOrigemVenda_(origemVenda, filtrarVendas, options, dispatch));
}

const loadVendaListAbertaFromOrigemVenda_ = (origemVendaURI, filtrarVendas, options = { cb: () => { } }, dispatch) => {
  dispatch(appSpinnerShow('loadVendaListAbertaFromOrigemVenda'));
  axios().post(
    origemVendaURI + '/findVendaListAberta',
    null,
    getHeaders()
  )
    .then(cadastros => {
      // Se o usuário pode usar esta origem de venda, segue normalmente.
      if (cadastros.data) {
        dispatch(updateVendaList(cadastros.data.content, filtrarVendas, options));
      }
      // Senão, avisa e remove a origem da lista.
      else {
        dispatch(appNotificationShow(getStrings().freeSaleSourceInUse, actionTypes.APP_NOTIFICATION_TYPE_WARNING, getStrings().warning));
        dispatch(exibeGridOrigemVendaEmUso());
        dispatch(removeSaleSourceInUse(origemVendaURI));
      }
    })
    .catch(error =>
      dispatch(errorResponse(error))
    )
    .finally(() =>
      dispatch(appSpinnerHide('loadVendaListAbertaFromOrigemVenda'))
    );
};

/**
 * Método executado após finalizar a busca das vendas no servidor.
 * @param {*} newVendaList
 */
export const updateVendaList = (newVendaList, filtrarVendas = [], options = { cb: () => { } }) => {
  log('controleVendaAction updateVendaList', newVendaList);

  return dispatch => {

    // Caso a lista de vendas vinculadas a esta origem esteja em branco.
    if (newVendaList.length === 0) {

      dispatch(exibeGridOrigemVendaEmUso());
    }
    else {
      // Caso existam vendas vinculadas a origem, atualiza a lista de vendas.
      dispatch(vendaFromOrigemLoad(newVendaList, filtrarVendas, options));
    }
  }
}

/**
 * Encerra todas as vendas abertas passadas por parâmetro, independente dos seus pagamentos.
 * @param {*} formData
 */
export const closeVendas = (vendaList, options = { cb: () => { } }) => {
  log('controleVendaAction closeVendas', vendaList);

  return dispatch => {

    dispatch(appSpinnerShow('closeVendas'));

    axios().post(
      urlDatabase + '/vendas/closeVendas',
      // Gera uma nova lista sem vendas encerradas
      vendaList.filter(venda => isVendaAberta(venda)).map(
        // Substitui a venda na lista pelo seu URI
        venda => venda._links.self.href.replace('{?projection}', '')
      ),
      getHeaders()
    )
      .then(response => {
        // Exibe notificação
        dispatch(appNotificationShow(getStrings().saleClosed(), actionTypes.APP_NOTIFICATION_TYPE_SUCCESS, getStrings().success, null));
        // Se for pensado em utilizar este método para encerrar as vendas individuais no futuro, alterar para receber o seguinte método por parâmetro
        dispatch(exibeGridOrigemVendaEmUso())
      })
      .catch(error =>
        dispatch(errorResponse(error))
      )
      .finally(() => {
        dispatch(appSpinnerHide('closeVendas'));
        options.cb && options.cb();
      });
  };
}

/**
 * Mostra a dialog de seleção de origens de venda para pagamento.
 */
export const showPayOrigemVendaDialog = () => dispatch => {
  log('controleVendaAction showPayOrigemVendaDialog');

  dispatch(appDialogShow(<DialogPayOrigensVenda />,
    getStrings().paySaleSources, { maxHeight: '320px' }
  ));
}

/**
 * Mostra a dialog de seleção de vendas para impressão.
 */
export const showPrintVendaDialog = () => dispatch => {
  log('controleVendaAction showPrintVendaDialog');

  dispatch(appDialogShow(<DialogPrintVendas />,
    getStrings().printSales, { maxHeight: '320px' }
  ));
}

/**
 * Altera o estado e outras variáveis para o valor padrão.
 */
export const resetState = () => {
  log('controleVendaAction resetState');

  return dispatch => {

    dispatch({
      type: actionTypes.CONTROLE_VENDA_RESET_STATE,
      state: STATE_CONTROLE_VENDA_GRID_ORIGENS,
      newVendaData: null,
      vendaAmount: 0
    });
    let state = Object.assign({}, initialStateManutencao);
    delete state.gridGrupoProdutoDisplay;
    dispatch({
      type: actionTypes.MANUTENCAO_VENDA_LIMPA_MANUTENCAO_VENDA,
      ...state
    });
  };
};

/**
 * Processa os dados retornados pelo leitor de código de barras/QR.
 * @param {*} data
 */
export const treatCodeData = data => (dispatch, getState) => {
  log('controleVendaAction treatCodeData', { data });

  // Verifica porque o leitor foi aberto
  switch (getState().controleVendaReducer.state) {
    // Iniciar vendas a partir da tela de origens
    case STATE_CONTROLE_VENDA_BAR_QR_CODE_TO_NEW_SALE:
      // Se for um URI, então na verdade é uma venda a ser aberta.
      if (isString(data)) {
        // Abre a venda
        dispatch(setupPersistedSaleFromURI(null, data));
      }
      // Se é uma origem livre, inicia uma nova venda
      else if (!data.quantidadeVendas) {
        dispatch(setupNewVenda(data));
        // Marca que o dialog para iniciar uma venda está aberto, para desligar a câmera
        // enquanto isso e para religar a câmera caso o usuário cancele o início de nova venda.
        dispatch({
          type: actionTypes.CONTROLE_VENDA_CODE_READER_OPENED,
          state: STATE_CONTROLE_VENDA_NEW_SALE_FROM_BAR_QR_CODE
        });
      }
      // Se não, abre as vendas para adicionar itens na mesma ou em outra.
      else {
        dispatch(exibeGridVendaFromOrigem(data));
      }
      break;
    default:
      dispatch(exibeGridOrigemVendaEmUso());
  }
};

/**
 * Exibe o leitor de código de barras/QR para iniciar uma nova venda.
 */
export const getBarQRCodeToBeginNewSale = () => {
  log('controleVendaAction getBarQRCodeToBeginNewSale');

  return dispatch => {
    dispatch(codeReaderActions.startReader(SALE_SOURCE_ENTITY,
      'control',
      sourcesInUse_codeReader(() => dispatch(exibeGridOrigemVendaEmUso())),
      () => dispatch(exibeGridOrigemVendaEmUso()),
      {}, treatCodeData));
    dispatch({
      type: actionTypes.CONTROLE_VENDA_CODE_READER_OPENED,
      state: STATE_CONTROLE_VENDA_BAR_QR_CODE_TO_NEW_SALE
    });
  };
}

/**
 * Marca todos os itens das vendas para serem enviados para impressão
 * @param {Array} vendaList
 * @param {Boolean} printed se deve marcar como impresso ou não
 * @param {Boolean} printOtherItems se deve marcar somente o item selecionado ou todos os itens da venda que sejam para a mesma impressora
 */
export const setPrinted = (vendaList, printed, printOtherItems = undefined) => (dispatch, getState) => {
  log('controleVendaAction setPrinted');

  dispatch(appSpinnerShow('setPrinted'));

  axios().post(
    urlDatabase + '/vendas/setPrinted',
    {
      vendas: vendaList,
      printed,
      printOtherItems
    },
    getHeaders()
  )
    .then(() => {
      if (manutencaoVendaActions.switchToSaleSourcesAfterSendingSale(getState)) {
        dispatch(exibeGridOrigemVendaEmUso());
      }
      dispatch(appNotificationShow(
        getStrings().salePrintSuccess(vendaList.length === 1),
        actionTypes.APP_NOTIFICATION_TYPE_SUCCESS,
        getStrings().printSales
      ));
    })
    .catch(error =>
      dispatch(errorResponse(error))
    )
    .finally(() =>
      dispatch(appSpinnerHide('setPrinted'))
    );
}

/**
 * Busca origens de venda com chamados que o usuário ainda não visualizou.
 */
export const getUnreadOrigemVendaList = () => (dispatch, getState) => {
  log('controleVendaAction getUnreadOrigemVendaList cargo logado?', getState().empresaSelectorReducer.cargo);

  // Se não houver um cargo logado, não pode executar método.
  if (!getState().empresaSelectorReducer.cargo) {
    return;
  }

  // Não mostra spinner, pois essa consulta ocorre de tempo em tempo* no plano de fundo.
  // *Ao abrir o app e ao receber notificação.

  axios().get(
    urlDatabase + '/origemVendas/unread',
    getHeaders()
  )
    .then(response => {
      log('controleVendaAction getUnreadOrigemVendaList', response);
      // Verifica se recebeu origens de venda
      if (((response || {}).data || {}).content) {
        dispatch({
          type: actionTypes.CONTROLE_VENDA_LOAD_UNREAD,
          calledOrigemVendaList: response.data.content
        });
      }
    })
    .catch(() => { });
};

/**
 * Limpa a lista de origens de venda chamadas.
 */
export const clearUnread = () => dispatch => {
  log('controleVendaAction clearUnread');

  dispatch({
    type: actionTypes.CONTROLE_VENDA_LOAD_UNREAD,
    calledOrigemVendaList: []
  })
};

export const showOrigemVendaChamadaDialog = () => dispatch => {
  log('controleVendaAction showOrigemVendaChamadaDialog');

  dispatch(appDialogShow(<DialogOrigemVendaChamada />,
    getStrings().calls, { maxHeight: '320px' }, null, () => dispatch(resetFromCall())
  ));
};

/**
 * Inicia uma nova venda ou exibe as vendas de uma origem cujo código impresso tenha sido lido.
 * @param {*} origemVenda
 * @param {*} history para ser possível trocar para a tela de controle de vendas para que as ações tenham efeito
 */
export const treatCall = (origemVenda, history) => (dispatch, getState) => {
  log('controleVendaAction treatCall', origemVenda);

  dispatch(appSpinnerShow('treatCall'));

  // Busca os dados atualizados da origem de venda
  axios().get(
    getURIFromEntity(origemVenda),
    getHeaders({ projection: 'origemVendaVendaProjection', statistics: true })
  )
    .then(response => {
      origemVenda = response.data;
      // Se é uma origem livre, inicia uma nova venda
      if (!origemVenda.quantidadeVendas) {
        dispatch({
          type: actionTypes.CONTROLE_VENDA_LOAD_NOVA_VENDA,
          fromCallState: STATE_CONTROLE_VENDA_GET_SALE_FROM_CALL,
          origemVendaFromCall: origemVenda,
          clearTotal: actionTypes.REDUCER_DEFAULT,
          commission: {
            value: getState().pagamentoVendasReducer.percentualComissaoAtual,
            action: actionTypes.REDUCER_HOLD
          },
          discount: {
            type: getState().pagamentoVendasReducer.discountType,
            value: getState().pagamentoVendasReducer.discountValue,
            action: actionTypes.REDUCER_HOLD
          },
          newSalePaymentItemMap: actionTypes.REDUCER_DEFAULT,
          nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
          ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
          remainingAmountMap: actionTypes.REDUCER_DEFAULT,
          sentSaleItemList: actionTypes.REDUCER_DEFAULT,
          sentSalePaymentItemMap: actionTypes.REDUCER_DEFAULT
        });
        dispatch(setupNewVenda(origemVenda));
      }
      // Se não, abre as vendas para adicionar itens na mesma ou em outra.
      else {
        dispatch({
          type: actionTypes.CONTROLE_VENDA_LOAD_VENDA_LIST,
          fromCallState: STATE_CONTROLE_VENDA_LIST_SALES_FROM_CALL,
          origemVendaFromCall: origemVenda,
          clearTotal: actionTypes.REDUCER_DEFAULT,
          commission: {
            value: getState().pagamentoVendasReducer.percentualComissaoAtual,
            action: actionTypes.REDUCER_HOLD
          },
          discount: {
            type: getState().pagamentoVendasReducer.discountType,
            value: getState().pagamentoVendasReducer.discountValue,
            action: actionTypes.REDUCER_HOLD
          },
          newSalePaymentItemMap: actionTypes.REDUCER_DEFAULT,
          nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
          ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
          remainingAmountMap: actionTypes.REDUCER_DEFAULT,
          sentSaleItemList: actionTypes.REDUCER_DEFAULT,
          sentSalePaymentItemMap: actionTypes.REDUCER_DEFAULT
        });
      }
    })
    .catch(error => dispatch(errorResponse(error)))
    .finally(() => dispatch(appSpinnerHide('treatCall')));
};

/**
 * Reinicia a máquina de estados das vendas iniciadas através de chamadas em origens de venda.
 */
export const resetFromCall = () => dispatch => {
  log('controleVendaAction resetFromCall');

  dispatch({
    type: actionTypes.CONTROLE_VENDA_RESET_STATE,
    fromCallState: null,
    origemVendaFromCall: null
  })
};

/**
 * Remove da lista de origens de venda chamadas a que foi selecionada.
 */
export const updateUnread = origemVenda => (dispatch, getState) => {
  log('controleVendaAction updateUnread', origemVenda);

  // Limpa as notificações para que elas não estejam acessíveis enquanto elas são atualizadas silenciosamente
  //dispatch(clearUnread());

  // Marca a notificação como visualizada
  axios().post(
    getURIFromEntity(origemVenda) + '/unread',
    {},
    getHeaders()
  )
    .then(response => {
      // Atualiza a lista de notificações
      dispatch(getUnreadOrigemVendaList());
    })
    .catch(() => { })
    .finally(() => { });
};

/**
 * Exibe a manutenção de venda para empresas do ramo Painel,
 * onde não há a seleção de origem de venda.
 */
export const exibeManutencaoVendaPainel = () => dispatch => {
  log('controleVendaAction exibeManutencaoVendaPainel');

  dispatch(appSpinnerShow('exibeManutencaoVendaPainel'));

  // Busca a origem de venda padrão
  axios().get(
    urlDatabase + '/origemVendas/default',
    getHeaders()
  )
    .then(response => {
      dispatch({
        type: actionTypes.PAGAMENTO_VENDAS_UPDATE,
        percentualComissaoEmpresa: response.data.content.getPercentualComissao
      });
      dispatch(continueNewVenda({
        origemVenda: response.data.content.default,
        nome: '',
        numeroPessoas: 1
      }))
    })
    .catch(error => dispatch(errorResponse(error)))
    .finally(() => dispatch(appSpinnerHide('exibeManutencaoVendaPainel')));
};

/**
 * Exibe a manutenção de venda quando for passado um URI de venda pelo URL.
 */
export const exibeManutencaoVendaSearch = (vendaURI, tryAgain) => (dispatch, getState) => {
  log('controleVendaAction exibeManutencaoVendaSearch', { vendaURI, tryAgain });

  dispatch(appSpinnerShow('exibeManutencaoVendaSearch'));

  // Busca a venda
  axios().get(
    vendaURI + '/trimmed/?projection=vendaReopenProjection',
    getHeaders()
  )
    .then(response => {
      // Se a venda não estiver aberta, gera um erro
      if (!isVendaAberta((response || {}).data)) {
        throw new Error();
      }
      dispatch({
        type: actionTypes.CONTROLE_VENDA_UPDATE_VENDA,
        state: STATE_CONTROLE_VENDA_MANUTENCAO_VENDA,
        // Atualiza a lista de origens de venda para que a única origem nesta lista
        // tenha somente a venda selecionada em sua lista de vendas
        origemVendaList: [Object.assign({}, response.data.origemVenda, {
          vendaList: [response.data]
        })],
        clearTotal: actionTypes.REDUCER_DEFAULT,
        commission: {
          value: getState().pagamentoVendasReducer.percentualComissaoAtual,
          action: actionTypes.REDUCER_HOLD
        },
        discount: {
          type: getState().pagamentoVendasReducer.discountType,
          value: getState().pagamentoVendasReducer.discountValue,
          action: actionTypes.REDUCER_HOLD
        },
        fromCallState: null,
        nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
        newSalePaymentItemMap: actionTypes.REDUCER_SET,
        ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
        remainingAmountMap: actionTypes.REDUCER_SET,
        sentSaleItemList: actionTypes.REDUCER_SET,
        sentSalePaymentItemMap: actionTypes.REDUCER_SET
      });
      // Para recalcular os totais dos novos itens de pagamento de venda
      dispatch({
        type: actionTypes.CONTROLE_VENDA_UPDATE_VENDA,
        newSalePaymentItemMap: actionTypes.REDUCER_UPDATE,
        salePaymentItemKeyList: removeDuplicates(((response.data || {}).itemVendaList || []).map(itemVenda => saleProductKey(response.data, itemVenda))),
        commission: {
          value: getState().pagamentoVendasReducer.percentualComissaoAtual,
          action: actionTypes.REDUCER_HOLD
        },
        discount: {
          type: getState().pagamentoVendasReducer.discountType,
          value: getState().pagamentoVendasReducer.discountValue,
          action: actionTypes.REDUCER_HOLD
        },
      });

      dispatch({
        type: actionTypes.CONTROLE_VENDA_SHOW_MANUTENCAO_VENDA,
        state: STATE_CONTROLE_VENDA_MANUTENCAO_VENDA
      });
    })
    .catch(error => {
      // Se método foi chamado por mensagem sobre nova venda iniciada usando leitura de peso,
      // repete a chamada do método até funcionar,
      // porque é provável que a mensagem foi enviada antes que a entidade pudesse ser consultada.
      if (tryAgain && ((((error || {}).response || {}).data || '') === 'No value present')) {
        dispatch(exibeManutencaoVendaSearch(vendaURI, tryAgain));
        return;
      }
      // Exibe notificação
      dispatch(appNotificationShow(getStrings().saleCantBeManaged, actionTypes.APP_NOTIFICATION_TYPE_WARNING, getStrings().warning, null));
      // Se a venda não for encontrada ou não estiver aberta
      if (isEmpresaPainel(getState().empresaSelectorReducer.ramoEmpresa)) {
        // Se for empresa do ramo Painel, abre uma venda nova como normal.
        dispatch(exibeManutencaoVendaPainel());
      }
      else if (isEmpresaColetor(getState().empresaSelectorReducer.ramoEmpresa)) {
        // Se for empresa do ramo Coletor, faz mais nada.
        return;
      }
      else {
        // Se for empresa de outros ramos, carrega as origens em uso.
        dispatch(exibeGridOrigemVendaEmUso());
      }
    })
    .finally(() => dispatch(appSpinnerHide('exibeManutencaoVendaSearch')));
}

/**
 * Retorna se a venda está aberta.
 * @param {Object} venda
 */
export const isVendaAberta = venda => {
  log('controleVendaAction isVendaAberta', { venda });
  return OPEN_SALE_STATE_LIST.some(openSaleState => (venda || {}).estadoVenda === openSaleState);
}

/**
 * Verifica se a origem de venda livre selecionada está mesmo livre.
 * Quando uma origem livre é selecionada, ela não aparece mais na lista para os outros usuários.
 * Se ela for selecionada por alguém enquanto a lista estiver aberta, uma mensagem fará com que ela seja removida da lista.
 * Caso essas medidas demorem demais e um usuário selecionar uma origem já selecionada por outro,
 * avisa o que ocorreu e remove a origem de venda da lista.
 * @param {Object} origemVenda
 */
export const reserveSaleSource = origemVenda => dispatch => {
  log('controleVendaAction reserveSaleSource', { origemVenda });

  dispatch(appSpinnerShow('reserveSaleSource'));

  axios().post(
    getURIFromEntity(origemVenda) + '/reserve',
    {},
    getHeaders()
  )
    .then(response => {
      // Se o usuário pode usar esta origem de venda, segue normalmente.
      if (response.data) {
        dispatch(setupNewVenda(origemVenda));
      }
      // Senão, avisa e remove a origem da lista.
      else {
        dispatch(removeFreeSaleSource(origemVenda));
        dispatch(appNotificationShow(getStrings().freeSaleSourceInUse, actionTypes.APP_NOTIFICATION_TYPE_WARNING, getStrings().warning));
      }
    })
    .catch(error => dispatch(errorResponse(error)))
    .finally(() => dispatch(appSpinnerHide('reserveSaleSource')));
};

/**
 * Remove uma origem de venda da lista de origens de venda livres.
 * @param {Object} origemVenda
 * @param {Object} usuario id do usuário que gerou a mensagem
 */
export const removeFreeSaleSource = (origemVenda, usuario) => dispatch => {
  log('controleVendaAction removeFreeSaleSource', { origemVenda, usuario });
  // Se recebeu mensagem porque uma origem foi reservada pelo próprio usuário,
  // faz mais nada. Se foi por outro usuário, segue adiante.
  let usuarioLogado = JSON.parse(getAppUsuario());
  if (usuario === usuarioLogado) {
    log('controleVendaAction removeFreeSaleSource returning', { usuarioLogado });
    return;
  }
  log('controleVendaAction removeFreeSaleSource dispatching', { usuarioLogado });
  dispatch({
    type: actionTypes.CONTROLE_VENDA_REMOVE_FREE_ORIGEM_VENDA,
    freeOrigemVenda: getURIFromEntity(origemVenda)
  });
};

/**
 * Marca as origens como não sendo usadas por este usuário.
 */
export const clearUsuarioAtivo = () => dispatch => {
  log('controleVendaAction clearUsuarioAtivo');

  // Não precisa exibir spinner

  axios().post(
    urlDatabase + '/origemVendas/clearUsuarioAtivo',
    null,
    getHeaders()
  )
    // Se der erro, ignora.
    .catch(() => { })
};

/**
 * Remove uma origem de venda da lista de origens de venda em uso.
 * @param {Object} origemVenda
 * @param {Object} usuario id do usuário que gerou a mensagem
 */
export const removeSaleSourceInUse = (origemVenda, usuario) => dispatch => {
  log('controleVendaAction removeSaleSourceInUse', { origemVenda, usuario });
  // Se recebeu mensagem porque uma origem foi reservada pelo próprio usuário,
  // faz mais nada. Se foi por outro usuário, segue adiante.
  let usuarioLogado = JSON.parse(getAppUsuario());
  if (usuario === usuarioLogado) {
    log('controleVendaAction removeSaleSourceInUse returning', { usuarioLogado });
    return;
  }
  log('controleVendaAction removeSaleSourceInUse dispatching', { usuarioLogado });
  dispatch({
    type: actionTypes.CONTROLE_VENDA_REMOVE_ORIGEM_VENDA_IN_USE,
    origemVendaInUse: getURIFromEntity(origemVenda)
  });
};

/**
 * Prepara para iniciar ou continuar uma venda de um usuário sem empresa
 * através da leitura do código QR impresso na origem de venda
 */
export const setupSaleNoCompany = () => dispatch => {
  log('controleVendaAction setupSaleNoCompany');

  dispatch(codeReaderActions.startReader(COMPANY_INFORMATION_ENTITY,
    'codeReaderNewSaleNoCompany',
    home_reader(),
    () => goBack(),
    {}, beginNewOrListSalesNoCompany));

  dispatch({
    type: actionTypes.CONTROLE_VENDA_RESET_STATE,
    state: STATE_CONTROLE_VENDA_BAR_QR_CODE_TO_NEW_SALE,
    newVendaData: null,
    vendaAmount: 0
  });
  let state = Object.assign({}, initialStateManutencao);
  delete state.gridGrupoProdutoDisplay;
  dispatch({
    type: actionTypes.MANUTENCAO_VENDA_LIMPA_MANUTENCAO_VENDA,
    ...state
  });
};

/**
 * Configura a manutenção de venda para funcionar com um usuário sem vínculo com empresa.
 * @param {Object} origemVenda
 * @param {Object} newVendaData
 * @param {Boolean} storage se deve buscar dados da venda do *storage*
 */
export const exibeManutencaoVendaNoCompany = (origemVenda, newVendaData, storage) => (dispatch, getState) => {
  log('controleVendaAction exibeManutencaoVendaNoCompany', { origemVenda, newVendaData, isAuthenticated: storage });
  // Se houver algo de errado
  if (!origemVenda) {
    // Exibe notificação
    dispatch(appNotificationShow(getStrings().saleCantBeManaged, actionTypes.APP_NOTIFICATION_TYPE_WARNING, getStrings().warning, null));
    // Volta para a tela anterior
    goBack();
    return;
  }
  // Validação dos dados
  origemVenda.vendaList = origemVenda.vendaList || [];
  // Armazena se é uma venda nova
  let newSale = false;
  // Se origem de venda não possui venda aberta
  if (origemVenda.vendaList.length < 1) {

    newSale = true;
    // Cria uma venda com dados de uma nova venda
    origemVenda.vendaList.push(newVendaData);
  }
  // Validação dos dados
  origemVenda.vendaList.forEach(venda => venda.itemVendaList = venda.itemVendaList || [])
  // Para recalcular os totais dos novos itens de pagamento de venda
  let venda = (origemVenda.vendaList || []).find(() => true) || {};
  // Para verificar se realmente é uma venda nova
  let vendaURI = getURIFromEntity(venda);
  if ((!vendaURI) || (vendaURI === 'nova_venda')) {
    newSale = true;
  }
  // Busca os dados da venda armazenados no local storage, se houver.
  let newSaleItemList;
  if (storage) {
    newSaleItemList = getNewSaleItemListMapStorage()[getURIFromEntity(origemVenda)];
    if ((newSaleItemList || []).length > 0) {
      dispatch(appNotificationShow(getStrings().orderRestored));
    }
  }
  dispatch({
    type: actionTypes.CONTROLE_VENDA_UPDATE_VENDA,
    state: newSale ? STATE_CONTROLE_VENDA_NOVA_VENDA : STATE_CONTROLE_VENDA_MANUTENCAO_VENDA,
    // Atualiza a lista de origens de venda para que a única origem nesta lista
    // tenha somente a venda selecionada em sua lista de vendas
    origemVendaList: [origemVenda],
    clearTotal: actionTypes.REDUCER_DEFAULT,
    commission: {
      value: getState().pagamentoVendasReducer.percentualComissaoAtual,
      action: actionTypes.REDUCER_HOLD
    },
    discount: {
      type: getState().pagamentoVendasReducer.discountType,
      value: getState().pagamentoVendasReducer.discountValue,
      action: actionTypes.REDUCER_HOLD
    },
    fromCallState: null,
    ...(newSaleItemList ? { newSaleItemList } : {}),
    newSalePaymentItemMap: actionTypes.REDUCER_SET,
    nivelUsuarioSemEmpresa: ((origemVenda.empresa || {}).parametrosEmpresa || {}).nivelUsuarioSemEmpresa,
    ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
    remainingAmountMap: actionTypes.REDUCER_SET,
    sentSaleItemList: actionTypes.REDUCER_SET,
    sentSalePaymentItemMap: actionTypes.REDUCER_SET
  });
  // Para cálculos do livre
  dispatch(empresaSelectorActions.updatePrecoLivre(((origemVenda.empresa || {}).parametrosEmpresa || {}).precoLivre || 0));
  dispatch(empresaSelectorActions.updatePerguntaCpf(((origemVenda.empresa || {}).parametrosEmpresa || {}).perguntaCpf || false));
  dispatch(setValue(true, 'runUpdateCommissionDiscount'));
};

export const updateCommissionDiscount = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.CONTROLE_VENDA_UPDATE_VENDA,
    newSalePaymentItemMap: actionTypes.REDUCER_UPDATE,
    salePaymentItemKeyList: Object.keys(getState().controleVendaReducer.newSalePaymentItemMap || {}),
    commission: {
      value: getState().pagamentoVendasReducer.percentualComissaoAtual,
      action: actionTypes.REDUCER_HOLD
    },
    discount: {
      type: getState().pagamentoVendasReducer.discountType,
      value: getState().pagamentoVendasReducer.discountValue,
      action: actionTypes.REDUCER_HOLD
    },
    runUpdateCommissionDiscount: false
  });
};

/**
 * Cancela o início ou continuação uma venda de um usuário sem empresa.
 */
export const cancelSaleNoCompany = () => dispatch => {
  log('controleVendaAction setupSaleNoCompany');
  goBack();
  dispatch(resetState());
}

/**
 * Prepara para continuar uma venda de um usuário que recém fez cadastro.
 */
export const continueSaleNoCompany = (origemVendaURI, numeroPessoas) => dispatch => {
  log('controleVendaAction continueSaleNoCompany');

  dispatch(appSpinnerShow('continueSaleNoCompany'))

  axios().get(
    origemVendaURI + '/scan',
    getHeaders({ js: true })
  )
    .then(response => {
      const origemVenda = response.data;

      dispatch(exibeManutencaoVendaNoCompany(origemVenda, {
        nome: getCurrentHourMinuteSecondFormatted(),
        numeroPessoas,
        origemVenda,
      }, true));
    })
    .catch(error => dispatch(errorResponse(error)))
    .finally(() => dispatch(appSpinnerHide('continueSaleNoCompany')));
};

/**
 * Inicia ou continua uma venda para um usuário sem vínculo com empresa a partir de *link* de origem de venda.
 * @param {String} origemVendaCode *id* da origem de venda
 * @param {Boolean} order se função foi chamada pelo item *Pedidos* do menu
 */
export const setupOrContinueSaleNoCompanyFromURL = (origemVendaCode, order) => dispatch => {
  log('controleVendaAction setupOrContinueSaleNoCompanyFromURL', { origemVendaCode });

  dispatch(appSpinnerShow('setupOrContinueSaleNoCompanyFromURL'))

  axios().get(
    `${urlDatabase}/origemVendas/${origemVendaCode}/scan`,
    getHeaders({ js: true })
  )
    .then(response => {
      let origemVenda = response.data || {};
      // Se função foi chamada pelo item Pedidos do menu
      if (order) {
        // Exibe o andamento dos pedidos
        dispatch(beginNewOrListSalesNoCompany(origemVenda));
      }
      // Se origem de venda possuir uma venda, continua ela.
      else if ((origemVenda.vendaList || []).length > 0) {
        dispatch(exibeManutencaoVendaNoCompany(origemVenda, {
          nome: getCurrentHourMinuteSecondFormatted(),
          numeroPessoas: (getSaleSourceNoCompany() || {}).numeroPessoas || 1,
          origemVenda,
        }, true))
      }
      // Senão, inicia uma nova venda.
      else {
        dispatch(setupNewVenda(origemVenda, true));
      }
    })
    .catch(error => dispatch(errorResponse(error)))
    .finally(() => dispatch(appSpinnerHide('setupOrContinueSaleNoCompanyFromURL')));
};

/**
 * Usa o resultado de um escaneamento de código QR por um usuário sem vínculo com empresa
 * para iniciar uma nova venda ou para listar os pedidos pendentes.
 * @param {Object} origemVenda origem de venda escaneada
 * @param {Boolean} forceNew força o início de uma nova venda
 */
export const beginNewOrListSalesNoCompany = (origemVenda, forceNew) => dispatch => {
  log('controleVendaAction beginNewOrListSalesNoCompany', { origemVenda, forceNew });
  origemVenda = origemVenda || {};
  let origemVendaURI = getURIFromEntity(origemVenda);
  // Verifica se foi lida uma origem de venda diferente da última lida
  if (!equalsCoerced(getLastScannedSaleSource(), origemVendaURI)) {
    // Se é outra origem, força uma nova venda nesta origem.
    forceNew = true;
  }
  // Armazena a leitura para depois permitir acompanhar os pedidos sem nova leitura
  setLastScannedSaleSource(origemVendaURI);
  // Armazena a quantidade de vendas que foram retornadas
  // para controlar exibição dos bread crumbs
  if (origemVenda.lastClientSales) {
    dispatch(setValue((origemVenda.vendaList || []).length, 'clientSaleAmount'));
  } else {
    dispatch(setValue(0, 'clientSaleAmount'));
  }
  // Se usuário sem vínculo com empresa possui vendas recentes,
  // exibe seus estados.
  if ((!forceNew) && (origemVenda.lastClientSales)) {
    // Exibe a tela
    dispatch({
      type: actionTypes.CONTROLE_VENDA_RESET_STATE,
      state: STATE_CONTROLE_VENDA_CLIENT_LAST_SALES,
    });
    // Armazena as vendas para popular a tela
    dispatch(origemVendaLoad([origemVenda], '', '', false, ((origemVenda.empresa).parametrosEmpresa).nivelUsuarioSemEmpresa));
  }
  // Se não, inicia ou continua venda.
  else {
    dispatch(appDialogShow(
      <DialogNovaVenda
        noCompany
        origemVenda={Object.assign({}, origemVenda, {
          vendaList: forceNew ? [] : origemVenda.vendaList
        })}
      />,
      getStrings().newSale,
      { maxHeight: '280px' },
      null,
      () => dispatch(cancelSaleNoCompany()),
      () => dispatch(cancelSaleNoCompany())));
  }
};

/**
 * Ao receber mensagem que um item de venda teve seu estado alterado
 * e sendo um usuário sem vínculo com empresa,
 * faz uma requisição para receber dados atualizados e verifica o retorno.
 * @param {Object} itemVendaUpdated item de venda que teve seu estado alterado
 */
export const handleNoCompanySaleItemStateUpdate = itemVendaUpdated => (dispatch, getState) => {
  log('controleVendaAction handleNoCompanySaleItemStateUpdate', { itemVendaUpdated });
  // Se não for usuário sem vínculo com empresa, faz mais nada.
  if (getState().empresaSelectorReducer.cargo) {
    return;
  }
  // Se a função foi executada por recebimento de mensagem, avisa que recebeu a mensagem.
  // A função de carregar os itens também avisa que recebeu a mensagem,
  // mas em alguns casos ela não será executada.
  dispatch(confirmMessage('user', SALE_ITEM_UPDATED));

  // Se as últimas vendas feitas por usuário sem vínculo com empresa não estiverem visíveis, não há porque buscar dados que não serão exibidos.
  // if ((!getState().controleVendaReducer.visible) || (getState().controleVendaReducer.state !== STATE_CONTROLE_VENDA_CLIENT_LAST_SALES)) {
  //   // Faz mais nada
  //   return;
  // }
  // Se a tela estiver visível, busca os dados atualizados.
  axios().get(
    `${getURIFromEntity(getState().controleVendaReducer.origemVendaList[0])}/scan`,
    getHeaders({ js: true })
  )
    .then(response => {
      log('controleVendaAction handleNoCompanySaleItemStateUpdate then', { response });
      // Valida se os dados estão atualizados
      // if (itemVendaUpdated) {
      //   Busca o item de venda das vendas que estão sendo exibidas na tela
      //   que corresponde ao item de venda recebido.
      //   let itemVendaUpdatedURI = getURIFromEntityAlt(itemVendaUpdated);
      //   let itemVendaPersisted = (((response || {}).data || {}).vendaList || [])
      //     .flatMap(venda => venda.itemVendaList || [])
      //     .find(item => itemVendaUpdatedURI === getURIFromEntity(item));
      //   // Se item foi excluído, só aceita os dados recebidos se este item não foi retornado.
      //   if ((itemVendaUpdated.deleted && itemVendaPersisted)
      //     || (!itemVendaUpdated.deleted
      //       && (!itemVendaPersisted || itemVendaUpdated.estado !== itemVendaPersisted.estado))) {
      //     // Caso contrário, busca os dados novamente.
      //     dispatch(handleNoCompanySaleItemStateUpdate(itemVendaUpdated));
      //     // Faz mais nada.
      //     return;
      //   }
      // }
      dispatch(beginNewOrListSalesNoCompany((response || {}).data));
    })
    .catch(() => { });
};

/**
 * Define no *reducer* o valor informado na variável informada.
 * @param value novo valor da variável
 * @param position nome da variável
 */
export const setValue = (value, position) => dispatch => {
  log('controleVendaAction setValue', { value, position });

  dispatch({
    type: actionTypes.CONTROLE_VENDA_SET_VALUE,
    [position]: value
  });
};

/**
 * Avisa à retaguarda para avisar ao Hub para iniciar uma nova venda usando uma leitura de peso da balança.
 */
export const orderUsingScales = () => dispatch => {
  log('controleVendaAction orderUsingScales');

  appSpinnerShow('controleVendaAction orderUsingScales');

  axios().post(
    urlDatabase + '/vendas/orderUsingScales',
    {},
    getHeaders()
  )
    .then(() =>
      dispatch(appNotificationShow(getStrings().waitForScales, actionTypes.APP_NOTIFICATION_TYPE_INFO)))
    .catch(error =>
      dispatch(errorResponse(error)))
    .finally(() =>
      dispatch(appSpinnerHide('controleVendaAction orderUsingScales')));
};

/**
 * Prepara a abertura de uma venda previamente persistida através do seu URI.
 * @param {String} subject
 * @param {String} vendaURI
 * @param {String} nomeProduto
 * @param {String} weight
 */
export const setupPersistedSaleFromURI = (subject, vendaURI, nomeProduto, weight) => (dispatch, getState) => {
  log('controleVendaAction setupPersistedSaleFromURI', { subject, vendaURI, nomeProduto, weight });

  // Notifica retaguarda de que recebeu mensagem
  if (subject) {
    dispatch(confirmMessage('role', subject));
  }

  // Se o Controle de Movimento não está aberto, faz mais nada.
  if (!getState().controleVendaReducer.visible) {
    return;
  }

  // Só exibe mensagem sobre venda aberta se recebeu dados do produto,
  // ou seja, se método foi chamado por mensagem,
  // pois só neste caso precisa exibir esta mensagem para esconder uma mensagem anterior.
  if (nomeProduto) {
    dispatch(appNotificationShow(getStrings().openedSaleFromScales(nomeProduto, weight)));
  }

  dispatch(manutencaoVendaActions.setManutencaoContent(MANUTENCAO_CONTENT_ITENS));
  dispatch({
    type: actionTypes.CONTROLE_VENDA_SHOW_MANUTENCAO_VENDA,
    fromCallState: STATE_CONTROLE_VENDA_GET_SALE_FROM_QR_CODE,
    state: STATE_CONTROLE_VENDA_MANUTENCAO_VENDA,
    vendaURI
  });
};
