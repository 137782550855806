import React from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"

import { updateActiveMenuItem } from "../../../../utils/ScreenUtils"

import SignUpCompany from "../SignUpCompany/SignUpCompany"

/**
 * Componente de Cadastro de Empresa com Usuário logado.
 */
class SignUpCompanyAsUser extends React.Component {

  componentDidMount() {

    updateActiveMenuItem("menuItemSignUpCompanyAsUser")
  }

  render() {

    // Como esta tela só serve para cadastrar a primeira empresa de um usuário,
    // depois que o usuário possuir vínculo com uma empresa, esta tela não pode mais ser exibida.
    // Redirecionamento serve principalmente para logo depois que o usuário cadastrar a primeira empresa.
    let authRedirect = null;
    if (this.props.quantidadeCargos) {
      authRedirect = <Redirect to={"/"} />
    }

    return <>
      {authRedirect}
      <SignUpCompany {...this.props} existingUser />
    </>
  }
}

/**
 * Mapeia as propriedades do estado global para utilizar localmente. 
 * @param {*} state 
 */
const mapStateToProps = state => ({

  quantidadeCargos: state.empresaSelectorReducer.quantidadeCargos
})

export default connect(mapStateToProps)(SignUpCompanyAsUser)
