import React from "react"
import { AccordionClick } from "../../UI/Accordion/Accordion"

import { getStrings } from "../../../utils/LocaleUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import CombinacoesProduto from "./CombinacoesProduto"

class CombinacoesGrupo extends React.Component {
  constructor() {
    super()
    this.state = {
      active: true,
      itemTabIndex: -1,
      quantidadeSaboresSelecionados: 0,
    }
  }

  mapKeysToActions = {
    "Enter": this.clickHandler,
    " ": (evt) => {
      evt.preventDefault()
      return this.clickHandler(evt)
    },
  }

  clickHandler = (evt) => {
    const active = AccordionClick(evt)
    this.setState({ active })
  }

  keyHandler = (evt) => {
    const element = evt.target

    if (typeof element.className === "string") {
      const classNameArray = element.className.split(" ")

      if (classNameArray.includes("card")) {
        try {
          this.mapKeysToActions[evt.key](evt)
        } catch (err) { }
      }
    }
  }

  componentDidMount = () => {
    if (!this.accordionContent.previousElementSibling.classList.contains("active")) {
      this.accordionContent.previousElementSibling.classList.toggle("active")
    }
    // this.accordionContent.style.maxHeight = this.accordionContent.scrollHeight + "px"
  }

  componentDidUpdate = (prevProps) => {
    const itemTabIndex = this.state.active
      ? this.props.tabIndex
      : -1

    itemTabIndex === this.state.itemTabIndex
      || this.setState({ itemTabIndex })

    if (prevProps.tamanhoSelecionado.id !== this.props.tamanhoSelecionado.id) {
      this.setState({ quantidadeSaboresSelecionados: 0 })
    }
  }

  updateQuantidadeSaboresSelecionados = (quantidadeSaboresSelecionados) => {
    this.setState({ quantidadeSaboresSelecionados })
  }

  render = () => {
    return <div className="combinacoes-grupo" ref={ref => { if (ref) { this.targetNode = ref } }} style={{ maxHeight: undefined, height: "auto" }}>
      <div
        tabIndex={this.props.tabIndex}
        className={`card grupo-produto card-container${isAncientChromeMobile ? " ancient-chrome-mobile" : ""}`}
        onClick={this.clickHandler}
        onKeyDown={this.keyHandler}
      >
        <div id="accordion" name="accordion" className="card-content adjust build-cell-from-grupo-produto">
          <h1 className="ButtonAlign" style={{ paddingLeft: "8em" }}> {getStrings().flavors} </h1>
          <h1 className="ButtonAlign" style={{ width: "7em", paddingRight: "1.5em" }}> {getStrings().minimumAndMaximumAmount(this.props.tamanhoSelecionado.quantidadeMinimaSabores, this.props.tamanhoSelecionado.quantidadeMaximaSabores)} </h1>
        </div>
        <div className="accordion-content" ref={x => this.accordionContent = x}>
          {this.props.newSaleItemList.map(newSaleItem => {
            return <CombinacoesProduto
              itemVenda={newSaleItem.itemVenda}
              key={getURIFromEntity(newSaleItem.itemVenda.produto) + "?s=" + (newSaleItem.sequence || 0)}
              newSaleItem={newSaleItem}
              produto={newSaleItem.itemVenda.produto}
              tabIndex={this.state.itemTabIndex}
              quantidadeMaximaSabores={this.props.tamanhoSelecionado.quantidadeMaximaSabores}
              quantidadeSaboresSelecionados={this.state.quantidadeSaboresSelecionados}
              updateQuantidadeSaboresSelecionados={this.updateQuantidadeSaboresSelecionados}
              tamanhoSelecionado={this.props.tamanhoSelecionado}
              updateProdutosSelecionados={this.props.updateProdutosSelecionados}
            />
          })}
        </div>
      </div>
    </div>
  }
}

export default CombinacoesGrupo
