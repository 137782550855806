import { getEmbedded } from "./MiscUtils"

export const normalizeCombosFromDB = (response) => {
    const resources = (getEmbedded((response || {}).data) || {}).resources || [];

    return resources.map(_resource => {
        const resource = _resource || {};

        const nome = resource.nome;
        const codigo = resource.codigo;
        const precoVenda = resource.precoVenda;
        const produtos = (resource.produtoComboList || []).map(_produto => {
            const produto = (_produto || {}).produto || {};

            return produto;
        });
    
        return {
            nome,
            codigo,
            precoVenda,
            produtos,
        }
    });
}
