import React from "react"
import { Switch, Route, withRouter, Redirect } from "react-router"
import { connect } from "react-redux"
import apelidos from "./apps/promo_app_frontend/rotas/apelidos"

import WebApp from "./apps/promo_app_frontend/App"

import * as AuthUtils from "./utils/AuthUtils"
import { isEmpresaColetor } from "./utils/CompanyUtils"
import { log } from "./utils/LogUtils"
import { getReduxWrappedComponent } from "./utils/reduxUtils/reduxUtils"
import { forceHttps, isHttp } from "./utils/SecureConnectionUtils"
import * as AppUtils from "./utils/AppUtils"
import { exibirRotaSe } from "./utils/rotaUtils/exibirRotaSe"
import { atualizarEstiloMobiApp } from "./utils/mobiAppUtils/atualizarEstiloMobiApp"
import { getPageNames } from "./utils/siteUtils/siteUtils"

import * as appActions from "./store/actions/appAction"
import * as authActions from "./store/actions/authAction"
import * as cadastroActions from "./store/actions/cadastroAction"
import * as empresaSelectorActions from "./store/actions/empresaSelectorAction"
import * as idiomaActions from "./store/actions/idiomaAction"
import * as gridGrupoProdutoActions from "./store/actions/controleVenda/manutencao/gridGrupoProdutoAction"
import * as producaoActions from "./store/actions/producaoAction"
import * as usuarioActions from "./store/actions/usuarioAction"

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import DevPage from "./components/cadastros/box/DevPage"
import Layout from "./hoc/Layout/Layout"
import Home from "./components/Telas/Home/Home"
import HomeExterna from "./components/Telas/HomeExterna/HomeExterna"
import EmpresaSelector from "./components/EmpresaSelector/EmpresaSelector"
import EmpresaBox from "./components/cadastros/box/EmpresaBox"
import FormaPagamentoBox from "./components/cadastros/box/FormaPagamentoBox"
import CargoBox from "./components/cadastros/box/CargoBox"
import TipoProdutoBox from "./components/cadastros/box/TipoProdutoBox"
import GrupoProdutoBox from "./components/cadastros/box/GrupoProdutoBox"
import ProdutoBox from "./components/cadastros/box/ProdutoBox"
import TamanhoBox from "./components/cadastros/box/TamanhoBox"
import ClienteBox from "./components/cadastros/box/ClienteBox"
import OrigemVendaBox from "./components/cadastros/box/OrigemVendaBox"
import ImpressoraBox from "./components/cadastros/box/ImpressoraBox"
import CancelBox from "./components/cancel/CancelBox"
import ControleVendaBox from "./components/vendas/ControleVendaBox"
import CollectorBox from "./components/vendas/CollectorBox"
import Login from "./components/Telas/Login/Login"
import Logout from "./components/Auth/Logout/Logout"
import SignUp from "./components/Telas/SignUp/SignUp"
import SignUpUser from "./components/Telas/SignUp/SignUpUser/SignUpUser"
import SignUpCompany from "./components/Telas/SignUp/SignUpCompany/SignUpCompany"
import SignUpCompanyAsUser from "./components/Telas/SignUp/SignUpCompanyAsUser/SignUpCompanyAsUser"
import UsuarioForm from "./components/Usuario/UsuarioForm"
import RedefineSenha from "./components/Telas/RedefineSenha/RedefineSenha"
import ProducaoBox from "./components/producao/ProducaoBox"
import MovimentoBox from "./components/Relatorios/MovimentoBox"
import FechamentoBox from "./components/Relatorios/FechamentoBox"
import LucratividadeBox from "./components/Relatorios/LucratividadeBox"
import LucratividadeDataBox from "./components/Relatorios/LucratividadeDataBox"
import LucratividadeHoraBox from "./components/Relatorios/LucratividadeHoraBox"
import Integration from "./components/Telas/Integration/Integration"
import STOMPManager from "./components/Subscriber/STOMPManager"
import PedidosBox from "./components/vendas/PedidosBox"
import BairroBox from "./components/cadastros/box/BairroBox"
// import { LoadF3SDK } from "./components/Auth/F3SDK/F3SDKReact"
import { QuadroHorariosBox } from "./components/cadastros/box/QuadroHorariosBox"
// import PlaceholderMessageEditor from "./components/Telas/Editors/PlaceholderMessageEditor"
import ComboProdutos from "./components/cadastros/form/ComboProdutos"
import PedidosTela from "./components/Telas/PedidosTela/PedidosTela"
import EntregasBox from "./components/Relatorios/EntregasBox"
import NewLandingPage from "./components/Telas/NewLandingPage/LandingPage"

import * as serviceWorker from "./serviceWorker"
import MobiAppBox from "./components/cadastros/box/MobiAppBox"

/**
 * Tema do Material-UI que irá ser aplicado a todos os componentes da aplicação
 * Configurar aqui as cores e estilos principais e realizar overrides de alguns components específicos de acordo com necessidade
 * Estilizações que não necessitam de override (checar na api do componente em https://material-ui.com/api) podem ser feitas direto no componente
 */
const theme =
  createMuiTheme({
    palette: { // Palette de cores que vai ser utilizada em todos os componentes espalhados no sistema
      primary: { // Cor primária
        light: "#6ca7ff"
        , main: "#485dff"
        , dark: "#524d5c"
      },
      secondary: { // Cor secundária
        light: "#f5f4f8"
        , main: "#d8d8d8"
        , dark: "#969696"
      }
    }
    , typography: { // Configuração de textos e tipografias
      useNextVariants: true
      // Use the system font instead of the default Roboto font.
      , fontFamily: [
        "Open Sans"
        , "\"Segoe UI Emoji\""
        , "\"Segoe UI Symbol\""
      ]
    }
    , overrides: { // Overrides de componentes
      MuiSnackbarContent: { // Nome do componente⚛️
        root: { // Nome da regra a sobscrever
          borderRadius: "0px !important"
          , backgroundColor: "#485dff" // primary main
        }
      }
    }
  });

/**
 * Classe responsável por exibir o Menu e exibir na tela o componente definido pelo `Router` do `index.js`.
 * 
 * A intenção é que só seja possível acessar as telas pelas rotas exatas. No momento, ao acessar uma rota aleatória, ocorre o redirecionamento
 * para a última rota válida. Ou seja, ao tentar acessar `/válido1/válido2/inválido`, é redirecionado para `/válido1/válido2`.
 * 
 * Uma tela de rota inválida foi implementada e os `<Router>`s receberam o parâmetro `exact` para que qualquer rota diferente das definidas
 * fossem redirecionadas para a página de 404. Porém, esse redirecionamento não funciona em rotas que possuem `query strings`,
 * como a rota `/selecaoEmpresa?cp=1` para selecionar o cargo padrão 1. Somente `/selecaoEmpresa` funciona. Para que essa rota voltasse a funcionar,
 * os `exact`s foram removidos, o que torna a página de 404 obsoleta.
 * 
 * Deve ser investigado como fazer para que as rotas com `exact` suportem `query strings`. Só assim será possível ter uma página de 404.
 * Quando isso for possível, descomentar os códigos marcados com `404`.
 * 
 * Testes de redirecionamento foram feitos em 10/09/2018.
 * 
 * Há outro problema relacionado a redirecionamento mas que é independente das configurações de rota do React. O VestaCP só carrega a página que carrega o React,
 * que é a `index.html`, se o endereço raíz for acessado. Se for acessado com mais caminhos na URL dá 404. Como ainda não chegou no React, é exibido um `404.html`.
 * Para resolver isso, deve haver uma configuração em `ampix.mobi.apache2.conf` para redirecionar o 404 de volta para o `index.html`. Somente desta maneira o React
 * recebe a rota que ele deve exibir. A configuração é `ErrorDocument 404 /index.html`. Deve ser garantido que esse arquivo não vá ser sobrescrito no futuro.
 */
const App = (props) => {
  let [firstRun, updateFirstRun] = React.useState(true)
  let stompManager = { componentDidUpdate: () => { } }

  const componentDidMount = () => {
    log("App componentDidMount");

    ajustaLandingPageClass();

    const linkElement = document.createElement("link");
    linkElement.id = "manifest-link";
    linkElement.href = "/manifest.json";
    linkElement.rel = "manifest";
    document.getElementsByTagName("head")[0].appendChild(linkElement);

    // Acessa a versão segura da página se já não estiver acessando.
    // TODO Esse redirecionamento deveria ser feito no NGINX ou Apache. Foi feito, funcionou por um tempo, mas parou de funcionar depois. Não foi possível restaurar.
    if (isHttp && forceHttps) {
      window.location = window.location.href.replace("http:", "https:");
    } else {
      props.onTryAutoSignup();

      // Armazena no reducer o idioma selecionado mantido no storage. Se não há no storage, seleciona o padrão.
      props.checkSelectedLanguage();

      // Armazena no reducer se deve mostrar os textos de ajuda, informação que é mantida no storage. Se não há no storage, mostra os textos de ajuda.
      props.checkHelpState();

      // Armazena no reducer se deve abrir o sidedrawer, informação que é mantida no storage. Se não há storage, exibe ou não dependendo da plataforma.
      props.checkSideDrawerState();

      // Armazena no reducer a variável no storage que indica se o usuário possui vínculo com empresa(s).
      props.updateQuantidadeCargos(AppUtils.getAppQuantidadeCargos());

      // Atualiza ou limpa o nome da empresa no Menu.
      props.updateNomeEmpresa(AppUtils.getAppNomeEmpresa());

      // Armazena no reducer a variável no storage que indica o ramo da empresa do cargo selecionado.
      props.updateRamoEmpresa(AppUtils.getAppRamoEmpresa());

      // Armazena no reducer a variável no storage que indica o preço do livre da empresa do cargo selecionado.
      props.updatePrecoLivre(AppUtils.getAppPrecoLivre());

      // Armazena no reducer a variável no storage que indica se deve perguntar pelo CPF ao pagar.
      props.updatePerguntaCpf(AppUtils.getAppPerguntaCpf());

      // Armazena no reducer a variável no storage que indica o nome do usuário selecionado.
      props.updateNomeUsuario(AppUtils.getAppNomeUsuario());

      // Armazena no reducer a variável no storage que indica as permissões que o cargo possui.
      props.updatePermissoesCargo(AppUtils.getAppPermissoesCargo());

      // Armazena no reducer a variável no storage que indica o tamanho padrão da paginação do usuário selecionado.
      let tamanhoPadraoPaginacao = AppUtils.getAppTamanhoPadraoPaginacao();
      props.updateTamanhoPadraoPaginacao(tamanhoPadraoPaginacao);
      props.cadastroUpdatePage(0, tamanhoPadraoPaginacao);
      props.producaoSetPageSize(tamanhoPadraoPaginacao);

      // Armazena no reducer a variável no storage que indica a exibição de produtos por grupo.
      props.updateGridGrupoProdutoDisplay(AppUtils.getAppGridGrupoProdutoDisplay());
    }
  }

  const componentDidUpdate = () => {
    log("App componentDidUpdate");

    ajustaLandingPageClass();

    // Verifica se precisa tentar conectar
    // Comentado em desenvolvimento porque não funciona sem certificado válido
    stompManager.componentDidUpdate({ cargo: AppUtils.getAppCargo() });

    // Verifica se o usuário deve ser redirecionado e o redireciona
    // Implementado aqui porque em Login estava apresentando condição de corrida
    if (props.authRedirectPath.indexOf("selecaoEmpresa") > -1) {
      window.location.replace(props.authRedirectPath);
      props.setRedirectPath("/");
    }

    /**
     * Registra o ServiceWorker.
     */
    serviceWorker.register();
  }

  /**
   * Adiciona ou remove a classe responsálvel pela estilização da
   * landing page dependendo da url e se existe um usuário logado
   */
  const ajustaLandingPageClass = () => {
    const rootElement = document.getElementById("root");

    if (verificaUsuarioLogado() || window.location.pathname !== getPageNames().naoLogado.LANDING) {
      rootElement.classList.remove("landing-page-root");
    }
    else if (!rootElement.classList.contains("landing-page-root")) {
      rootElement.classList.add("landing-page-root");
    }
  }

  /**
   * Método que faz a verificação se há um Usuário logado. 
   */
  const verificaUsuarioLogado = () => {
    log("App verificaUsuarioLogado")
    if (props.isAuthenticated)
      return true;

    return false;
  }

  /**
   * Método que faz a verificação se há um Usuário logado e se o mesmo é Cargo. 
   */
  const verificaUsuarioCargo = () => {
    log("App verificaUsuarioCargo");
    if (!verificaUsuarioLogado())
      return false;

    if (props.isCargo)
      return true;

    return false;
  }

  const RoutesPadrao = (props) => <Switch>
    {props.children}
  </Switch>

  /**
   * Método que retorna as Routes para o Usuário que não estiver logado (desconhecido). 
   */
  const loadUsuarioDesconhecidoRoutes = () => {
    log("App loadUsuarioDesconhecidoRoutes");
    return <RoutesPadrao>
      <Route exact path={getPageNames().naoLogado.LANDING} render={props => <NewLandingPage {...props} />} />
      <Route path={getPageNames().naoLogado.HOME} render={props => <HomeExterna {...props} />} />
      {AuthUtils.PERMISSAO_DEV ? <Route path={getPageNames().especial.DEV} render={props => <DevPage {...props} />} /> : ""}
      <Route path={getPageNames().naoLogado.LOGIN} render={props => <Login {...props} />} />
      <Route path={getPageNames().naoLogado.SIGNUP} render={props => <SignUp {...props} />} />
      <Route path={getPageNames().naoLogado.SIGNUP_USER} render={props => <SignUpUser {...props} />} />
      <Route path={getPageNames().naoLogado.SIGNUP_COMPANY} render={props => <SignUpCompany {...props} />} />
      <Route path={getPageNames().naoLogado.REDEFINIR_SENHA} render={props => <RedefineSenha {...props} />} />
      {/* Controle de Vendas */}
      <Route path={getPageNames().naoLogado.CONTROLE_VENDAS} render={props => <ControleVendaBox {...props} />} />
      {/* <Route exact path="/pageNotFound" render={props => <PageNotFound {...props}/>} /> */}{/* 404 */}
      <Redirect to={getPageNames().naoLogado.HOME} />
      {/* <Redirect to="/pageNotFound" /> */}{/* 404 */}
    </RoutesPadrao>;
  }

  /**
   * Método que retorna as Routes para o Usuário que estiver logado. 
   */
  const loadUsuarioLogadoRoutes = () => {
    log("App loadUsuarioLogadoRoutes");

    let quantidadeCargos = props.quantidadeCargos;
    let selecaoEmpresa;
    // Se usuário possui vínculo com empresa(s), permite selecionar empresas
    if (quantidadeCargos > 0) {
      selecaoEmpresa = <Route path={getPageNames().logado.SELECAO_EMPRESA} render={props => <EmpresaSelector {...props} />} />;
    }
    // Se usuário não possui vínculo com empresa(s), permite criar uma empresa
    else if (quantidadeCargos === 0) {
      selecaoEmpresa = <Route path={getPageNames().logado.CADASTRO_EMPRESA} render={props => <SignUpCompanyAsUser {...props} />} />;
    }
    // Se usuário não está logado, oculta ambas as opções
    else {
      selecaoEmpresa = null;
    }

    return <RoutesPadrao>
      <Route path={getPageNames().logado.HOME} exact render={props => <Home {...props} />} />
      {AuthUtils.PERMISSAO_DEV || JSON.parse(sessionStorage.getItem("a") || "{}").u === "1" ? <Route path={getPageNames().especial.DEV} render={props => <DevPage {...props} />} /> : ''}
      <Route path={getPageNames().naoLogado.LOGIN} render={props => <Login {...props} />} />
      {selecaoEmpresa}
      {/* Controle de Vendas */}
      <Route path={getPageNames().logado.CONTROLE_VENDAS} render={props => <ControleVendaBox {...props} />} />
      <Route path={getPageNames().logado.PEDIDOS} render={props => <PedidosBox {...props} />} />

      <Route path={getPageNames().logado.LEITOR_CODIGO} render={props => <PedidosBox {...props} />} />

      <Route path={getPageNames().logado.USUARIO} render={props => <UsuarioForm {...props} />} />
      {/* <Route path="/alterarSenha" render={props => <AlteraSenha {...props}/>} /> */}
      <Route path={getPageNames().logado.LOGOUT} render={props => <Logout {...props} />} />
      {/* <Route exact path="/pageNotFound" render={props => <PageNotFound {...props}/>} /> */}{/* 404 */}
      <Redirect to={getPageNames().logado.HOME} />
      {/* <Redirect to="/pageNotFound" /> */}{/* 404 */}
    </RoutesPadrao>;
  }

  /**
   * Método que retorna as Routes para o Cargo. 
   */
  const loadCargoRoutes = () => {
    log("App loadCargoRoutes");

    const somenteEntregador = AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_ENTREGA])
      && !AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_PRODUCAO_ALTERACAO, AuthUtils.PERMISSAO_PRODUCAO_CONTROLE]);

    return <RoutesPadrao>
      <Route path={getPageNames().cargoSelecionado.HOME} exact render={props => <Home {...props} />} />

      {AuthUtils.PERMISSAO_DEV ? <Route path={getPageNames().especial.DEV} render={props => <DevPage {...props} />} /> : ""}

      <Route path={getPageNames().cargoSelecionado.SELECAO_EMPRESA} render={props => <EmpresaSelector {...props} />} />

      {// Cadastro de Empresa
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.EMPRESAS} render={props => <EmpresaBox {...props} />} />, [AuthUtils.PERMISSAO_CADASTRO_EMPRESA])}

      {// Cadastro de MobiApp
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.MOBI_APPS} render={props => <MobiAppBox {...props} />} />, [AuthUtils.PERMISSAO_CADASTRO_EMPRESA])}

      {// Cadastro de Cargo
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.CARGOS} render={props => <CargoBox {...props} />} />, [AuthUtils.PERMISSAO_CADASTRO_CARGO])}

      {// Quadro de Horários
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.QUADRO_HORARIOS} render={props => <QuadroHorariosBox {...props} />} />, [AuthUtils.PERMISSAO_CADASTRO_CARGO])}

      {// Mensagem Tela Cheia
        // exibirRotaSe(<Route path="/placeholderMessage" render={props => <PlaceholderMessageEditor {...props} />} />, [AuthUtils.PERMISSAO_CADASTRO_CARGO])}
      }

      {// Cadastro de Tipo de Produto
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.TIPO_PRODUTOS} render={props => <TipoProdutoBox {...props} />} />, [AuthUtils.PERMISSAO_CADASTRO_GERAL])}

      {// Cadastro de Cliente
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.CLIENTES} render={props => <ClienteBox {...props} />} />, [AuthUtils.PERMISSAO_CADASTRO_GERAL],
          { and: isEmpresaColetor(props.ramoEmpresa) })}

      {// Cadastro de Impressora
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.IMPRESSORAS} render={props => <ImpressoraBox {...props} />} />, [AuthUtils.PERMISSAO_CADASTRO_GERAL])}

      {// Cadastro de Forma Pagamento
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.FORMA_PAGAMENTOS} render={props => <FormaPagamentoBox {...props} />} />, [AuthUtils.PERMISSAO_CADASTRO_GERAL])}

      {// Cadastro de Tamanhos
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.TAMANHOS} render={props => <TamanhoBox {...props} />} />, [AuthUtils.PERMISSAO_CADASTRO_GERAL])}

      {// Cadastro de Grupo de Produto
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.GRUPO_PRODUTOS} render={props => <GrupoProdutoBox {...props} />} />, [AuthUtils.PERMISSAO_CADASTRO_GERAL])}

      {// Cadastro de Produto
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.PRODUTOS} render={props => <ProdutoBox {...props} />} />, [AuthUtils.PERMISSAO_CADASTRO_GERAL])}

      {// Cadastro de Combos
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.COMBOS} render={props => <ComboProdutos {...props} />} />, [AuthUtils.PERMISSAO_CADASTRO_GERAL])}

      {// Cadastro de Origem Venda
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.ORIGEM_VENDAS} render={props => <OrigemVendaBox {...props} />} />, [AuthUtils.PERMISSAO_CADASTRO_GERAL])}

      {// Cadastro de Bairros
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.BAIRROS} render={props => <BairroBox {...props} />} />, [AuthUtils.PERMISSAO_CADASTRO_GERAL])}

      {// Pedidos
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.PEDIDOS} render={props => <PedidosTela {...props} />} />, [AuthUtils.PERMISSAO_VENDA_CONTROLE
          , AuthUtils.PERMISSAO_VENDA_ENVIO, AuthUtils.PERMISSAO_VENDA_EXCLUSAO, AuthUtils.PERMISSAO_VENDA_PAGAMENTO],
          { and: !isEmpresaColetor(props.ramoEmpresa) })}

      {// Controle de Vendas
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.CONTROLE_VENDAS} render={props => <ControleVendaBox {...props} />} />, [AuthUtils.PERMISSAO_VENDA_CONTROLE
          , AuthUtils.PERMISSAO_VENDA_ENVIO, AuthUtils.PERMISSAO_VENDA_EXCLUSAO, AuthUtils.PERMISSAO_VENDA_PAGAMENTO],
          { and: !isEmpresaColetor(props.ramoEmpresa) })}

      {// Controle de Vendas (Coletor)
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.CONTROLE_VENDAS} render={props => <CollectorBox {...props} />} />, [AuthUtils.PERMISSAO_VENDA_CONTROLE
          , AuthUtils.PERMISSAO_VENDA_ENVIO, AuthUtils.PERMISSAO_VENDA_EXCLUSAO],
          { and: !isEmpresaColetor(props.ramoEmpresa) })}


      {// Controle de Produção
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.PRODUCAO} render={props => <ProducaoBox {...props} somenteEntregador={somenteEntregador} />} />,
          [AuthUtils.PERMISSAO_PRODUCAO_ALTERACAO, AuthUtils.PERMISSAO_PRODUCAO_CONTROLE, AuthUtils.PERMISSAO_VENDA_ENTREGA])}

      {// Controle de Vendas
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.CANCEL} render={props => <CancelBox {...props} />} />, [AuthUtils.PERMISSAO_VENDA_EXCLUSAO])}

      {// Integração com Hub
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.INTEGRACAO} render={props => <Integration {...props} />} />, [AuthUtils.PERMISSAO_INTEGRACAO_CONFIGURACAO])}

      {// Movimento
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.RELATORIOS_MOVIMENTO} render={props => <MovimentoBox {...props} />} />, [AuthUtils.PERMISSAO_RELATORIOS])}

      {// Fechamento de caixa
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.RELATORIOS_FECHAMENTO} render={props => <FechamentoBox {...props} />} />, [AuthUtils.PERMISSAO_RELATORIOS])}

      {// Lucratividade
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.RELATORIOS_LUCRATIVIDADE} render={props => <LucratividadeBox {...props} />} />, [AuthUtils.PERMISSAO_RELATORIOS])}

      {// Lucratividade por dia
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.RELATORIOS_LUCRATIVIDADE_DATA} render={props => <LucratividadeDataBox {...props} />} />,
          [AuthUtils.PERMISSAO_RELATORIOS])}

      {// Lucratividade por hora
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.RELATORIOS_LUCRATIVIDADE_HORA} render={props => <LucratividadeHoraBox {...props} />} />,
          [AuthUtils.PERMISSAO_RELATORIOS])}

      {// Entregas
        exibirRotaSe(<Route path={getPageNames().cargoSelecionado.RELATORIOS_ENTREGAS} render={props => <EntregasBox {...props} />} />, [AuthUtils.PERMISSAO_RELATORIOS])}

      <Route path={getPageNames().naoLogado.LOGIN} render={props => <Login {...props} />} />
      <Route path={getPageNames().cargoSelecionado.USUARIO} render={props => <UsuarioForm {...props} />} />
      <Route path={getPageNames().cargoSelecionado.LOGOUT} render={props => <Logout {...props} />} />

      <Redirect to={getPageNames().cargoSelecionado.HOME} />
    </RoutesPadrao>;
  }

  React.useEffect(() => {
    if (firstRun) {
      updateFirstRun(false);
      componentDidMount();
    }
    // eslint-disable-next-line
  }, [firstRun])

  React.useEffect(() => {
    componentDidUpdate();
  });

  const render = () => {
    log("App render")
    let routes = null

    if ((new RegExp(apelidos.app + "[(/|?|#).*]*")).test(props.location.pathname)) {
      return <RoutesPadrao>
        {WebApp({ history: props.history })}
      </RoutesPadrao>;
    }
    else {
      atualizarEstiloMobiApp();
    }

    if (props.authChecked) {
      if (verificaUsuarioLogado()) {
        if (verificaUsuarioCargo()) {
          routes = loadCargoRoutes();
        }
        else {
          routes = loadUsuarioLogadoRoutes();
        }
      }
      else {
        routes = loadUsuarioDesconhecidoRoutes();
      }
    }

    return (isHttp && forceHttps)
      ? null
      : <MuiThemeProvider theme={theme}>
        <Layout noLayout={props.authChecked && !verificaUsuarioLogado() && props.location.pathname === getPageNames().naoLogado.LANDING} >
          {routes}
        </Layout>
        <STOMPManager ref={ref => { if (ref) { stompManager = getReduxWrappedComponent(ref) } }} />
      </MuiThemeProvider>;
  }

  return render();
}

function mapStateToProps(state) {
  const props = {
    fbAppDataReducer: state.fbAppDataReducer,
    authChecked: state.authReducer.authChecked,
    authRedirectPath: state.authReducer.authRedirectPath,
    isAuthenticated: state.authReducer.token !== null,
    isCargo: !!state.empresaSelectorReducer.cargo,
    permissoesCargo: state.empresaSelectorReducer.permissoesCargo,
    quantidadeCargos: state.empresaSelectorReducer.quantidadeCargos,
    ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa,
  };

  return props;
}

function mapDispatchToProps(dispatch) {
  const props = {
    appDialogHide: () => dispatch(appActions.appDialogHide()),
    appNotificationHide: () => dispatch(appActions.appNotificationHide()),
    cadastroUpdatePage: (page, pageSize) => dispatch(cadastroActions.cadastroUpdatePage(page, pageSize)),
    checkHelpState: () => dispatch(appActions.checkHelpState()),
    checkSelectedLanguage: () => dispatch(idiomaActions.checkSelectedLanguage()),
    checkSideDrawerState: () => dispatch(appActions.checkSideDrawerState()),
    onTryAutoSignup: () => dispatch(authActions.authCheckState()),
    producaoSetPageSize: pageSize => dispatch(producaoActions.setPageSize(pageSize)),
    setRedirectPath: redirectPath => dispatch(authActions.setRedirectPath(redirectPath)),
    updateGridGrupoProdutoDisplay: gridGrupoProdutoDisplay => dispatch(gridGrupoProdutoActions.updateGridGrupoProdutoDisplay(gridGrupoProdutoDisplay)),
    updateNomeEmpresa: nomeEmpresa => dispatch(empresaSelectorActions.updateNomeEmpresa(nomeEmpresa)),
    updatePerguntaCpf: perguntaCpf => dispatch(empresaSelectorActions.updatePerguntaCpf(perguntaCpf)),
    updatePrecoLivre: precoLivre => dispatch(empresaSelectorActions.updatePrecoLivre(precoLivre)),
    updatePermissoesCargo: permissoesCargo => dispatch(empresaSelectorActions.updatePermissoesCargo(permissoesCargo)),
    updateQuantidadeCargos: quantidadeCargos => dispatch(empresaSelectorActions.updateQuantidadeCargos(quantidadeCargos)),
    updateRamoEmpresa: ramoEmpresa => dispatch(empresaSelectorActions.updateRamoEmpresa(ramoEmpresa)),
    updateNomeUsuario: nomeUsuario => dispatch(usuarioActions.updateNomeUsuario(nomeUsuario)),
    updateTamanhoPadraoPaginacao: tamanhoPadraoPaginacao => dispatch(usuarioActions.updateTamanhoPadraoPaginacao(tamanhoPadraoPaginacao)),
  };

  return props;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
