//@ts-check
import { global_strings, locale } from "../../../utils/LocaleUtils"
import countryName from "./en_US_countryName"

export default {
  country: {
    iso: "US",
    countryName,
  },
  currency: {
    /**
     * 
     * @param {number} value 
     * @returns {string}
     */
    format: (value) => `$ ${value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits: 2})}`,
  },
  dialog: {
    error: "Error",
    installApp: "Install app?",
    login: "Login",
    notAllowedByThisCompany: global_strings[locale.en_us].notAllowedByThisCompany,
    warning: "Warning",
  },
  eror: {
    sendingEmail: "There was an error sending the email",
    userNotFound: "No registration found with the given email",
  },
  menu: {
    companyInformation: "Company information",
    editRegister: "Edit register",
    exit: "Exit",
    login: "Login",
    openingHours: "Opening hours",
    requests: "Requests",
    signUp: "Sign Up",
  },
  /**
   * 
   * @param {number} min 
   * @param {number} max 
   * @returns 
   */
  minimumAndMaximumAmount: (min, max) => `Min: ${min}  Max: ${max}`,
  phrases: {
    developedBy: "Developed by",
    /**
     * 
     * @param {string} email 
     * @returns {string}
     */
    emailSentTo: (email) => `Email sent to ${email}`,
    goBackToProductsScreen: "Go back to products screen",
    incorrectLogin: "Incorrect email or password",
    incorrectToken: "Incorrect code",
    maximumAmountReached: "Maximum amount reached",
    /**
     * 
     * @param {number} quantity 
     * @returns {string}
     */
    minimumFlavorQuantity: (quantity) => `Choose at least ${quantity} flavor${quantity > 1 ? "s" : ""}`,
    productPutInTheRequestssList: "Request submitted",
    receiveInEstablishiment: "Take away in establishiment",
    receiveInThisAddress: "Receive in this address",
    shoppingFailed: "Could not complete the purchase",
  },
  login: {
    /**
     * 
     * @param {string?} email 
     * @returns 
     */
    accountConfirmation: (email = null) => `Enter the code that was sent to ${email || " your inbox"}`,
    forgotMyPassword: "Forget my password",
    login: "Login",
    loginFailed: "Login failed",
    notRegistered: "I'm not register",
    password: "Password",
    passwordRecovery: "Password recovery",
    submit: "Submit",
    userId: "Email",
    /**
     * 
     * @param {string} nomeUsuario 
     * @returns {string}
     */
    welcomeUser: (nomeUsuario) => `Welcome ${nomeUsuario}`,
  },
  noImageAvailableIcon: "/mobi_icons/image-not-available.svg",
  register: {
    error: {
      defaultMessage: "Unable to register",
    },
    invalid: {
      email: "Invalid email",
      securityNumber: "Invalida social security number",
    },
    label: {
      adress: "Address",
      birthDate: "Birth date",
      celphoneNumber: "Cellphone number",
      city: "City",
      complement: "Complement",
      country: "Country",
      deliveryDate: "Delivery date",
      deliveryHour: "Delivery hour",
      email: "Email",
      fullName: "Full name",
      hood: "Hood",
      lastName: "Last name",
      name: "Name",
      newPassword: "New password",
      number: "Number",
      password: "Password",
      personalData: "Personal data",
      repeatPassword: "Repeat the password",
      securityNumber: "Social security number",
      state: "State",
      stateName: "State name",
      street: "Street",
      takeAwayDate: "Take away date",
      takeAwayHour: "Take away hour",
      zipCode: "Zip code",
    },
    passwordsDontMatch: "Passwords don't match",
    placeholder: {
      adress: "Address",
      birthDate: "Birth date",
      celphoneNumber: "Cellphone number",
      city: "City",
      complement: "Complement",
      country: "Country",
      email: "Fill your email",
      fullName: "Fill your full name",
      hood: "Hood",
      lastName: "Fill your last name",
      name: "Fill your name",
      newPassword: "Type a new password",
      number: "Number",
      password: "Type a password",
      personalData: "Personal data",
      repeatPassword: "Repeat the password",
      securityNumber: "Type your social security number",
      state: "State",
      stateName: "State name",
      street: "Street",
      zipCode: "Zip code",
    },
    select: {
      female: "Female",
      gender: "Gender",
      male: "Male",
      notInformed: "Not informed",
    },
    uninformed: {
      adress: "Address not informed",
      birthDate: "Birth date not informed",
      celphoneNumber: "Cellphone number not informed",
      city: "City not informed",
      complement: "Complement not informed",
      country: "Country not informed",
      deliveryDate: "Inform the delivery date",
      deliveryHour: "Inform the delivery hour",
      email: "Email not informed",
      fullName: "Full name not informed",
      hood: "Hood not informed",
      lastName: "Last name not informed",
      name: "Name not informed",
      newPassword: "New password not informed",
      number: "Number not informed",
      password: "Password not informed",
      personalData: "Personal data not informed",
      repeatPassword: "Repeated password not informed",
      securityNumber: "Social security number not informed",
      state: "State not informed",
      stateName: "State name not informed",
      street: "Street not informed",
      takeAwayDate: "Inform the take away date",
      takeAwayHour: "Inform the take away hour",
      zipCode: "Zip Code not informed",
    },
  },
  orders: {
    analisys: "Analisys",
    approved: "Approved",
    cancelled: "Cancelled",
    companyWillContactYou: "Company will contact you ",
    dateOfRequest: "Date of request",
    delivered: "Delivered",
    deliverying: "Deliverying",
    deliveryValue: "Delivery value",
    finished: "Finished",
    inTransit: "In transit",
    item: "item",
    items: "items",
    itemTotal: "Item total",
    notApproved: "Not approved",
    numberOfRequests: "Number of requests",
    numberOfUnitys: "Number of units",
    payedValue: "Payed value",
    product: "Product",
    quantity: "Qty.",
    produced: "Produced",
    order: "Order",
    orderNumber: "Order number",
    orders: "Orders",
    sent: "Sent",
    unityPrice: "Unity price",
    inProduction: "In production",
    orderCanceled: "Order canceled",
    orderPlaced: "Order placed",
    readyToDelivery: "Ready to delivery",
    deliveryCompleted: "Delivery completed",
    orderCompleted: "Order completed",
    totalValue: "Total value",
    waitingPayment: "Waiting payment",
  },
  weeks: {
    DOM: {reduced: "Sun", extended: "Sunday"},
    SEG: {reduced: "Mon", extended: "Monday"},
    TER: {reduced: "Tue", extended: "Tuesday"},
    QUA: {reduced: "Wed", extended: "Wednesday"},
    QUI: {reduced: "Thu", extended: "Thursday"},
    SEX: {reduced: "Fri", extended: "Friday"},
    SAB: {reduced: "Sat", extended: "Saturday"},
    "": {reduced: "", extended: ""},
  },
  words: {
    about: "About",
    adress: {
      adress: "Address",
      city: "City",
      complement: "Complement",
      country: "Country",
      hood: "Hood",
      number: "Number",
      state: "State",
      zipCode: "Zip Code",
    },
    amount: "Amount",
    back: "Back",
    buy: "Buy",
    cancel: "Cancel",
    closed: "Closed",
    code: "Code",
    contact: "Contact",
    creditCard: "Credit card",
    delivery: "Delivery",
    deliveryValueByKm: "Delivery value by km",
    description: "Description",
    email: "Email",
    emptyCart: "Empty cart",
    finishBuying: "Finish",
    /**
     * 
     * @param {boolean?} plural 
     * @returns {string}
     */
    flavor: (plural = null) => `Flavor${plural ? "s" : ""}`,
    holiday: "Holiday",
    information: "Information",
    login: "Login",
    money: "Money",
    nextPage: "Next",
    no: "No",
    numberOfInstallments: "Number of installments",
    observation: "Observation",
    observationShort: "Obs.",
    ok: "OK",
    openingHours: "Opening hours",
    otherInformation: "Other information", 
    payment: "Payment",
    paymentMethod: "Payment method",
    phone: "Phone",
    reason: "Reason",
    register: "Register",
    remove: "Remove",
    save: "Save",
    schedules: "Opening hours",
    search: "Search",
    soldOut: "Sold out",
    submit: "Submit",
    state: "State",
    takeAway: "Take away",
    type: "Type", 
    yes: "Yes",
  },
}
