import { updateObject } from "../../utils/ObjectUtils"

import * as actionTypes from "../actions/actionTypes"

export const STATE_CADASTRO_EMPRESA_CONTRATO = "STATE_CADASTRO_EMPRESA_CONTRATO"
export const STATE_CADASTRO_EMPRESA_EMPRESA = "STATE_CADASTRO_EMPRESA_EMPRESA"
export const STATE_CADASTRO_EMPRESA_USUARIO = "STATE_CADASTRO_EMPRESA_USUARIO"
export const STATE_CADASTRO_EMPRESA_CONCLUIDO = "STATE_CADASTRO_EMPRESA_CONCLUIDO"

export interface cadastroEmpresaReducerType {
  type: string,
  cadastroEmpresaDados: Record<string, any>,
  cargo: Record<string, any>|null,
  cnpjValido: boolean,
  contrato: Record<string, any>|null,

  /**
   * Se todos os campos obrigatórios referentes ao contrato da empresa foram preenchidos.
   */
  filledRequiredContract: boolean,

  /**
   * Se todos os campos obrigatórios referentes aos dados da empresa foram preenchidos.
   */
  filledRequiredCompany: boolean,

  /**
   * Se todos os campos obrigatórios referentes ao usuário da empresa foram preenchidos.
   */
  filledRequiredUser: boolean,

  paisObject: Record<string, any>|null,

  /**
   * Estado atual do cadastro de empresas
   */
  state: string,
  usuario: Record<string, any>|null,
  usuarioOption: string,

  step: number,

  minVersion?: boolean,

  filledRequiredCompanyStep0?: boolean,
  filledRequiredCompanyStep1?: boolean,
  filledRequiredCompanyStep2?: boolean,
  filledRequiredUserStep0?: boolean,
  filledRequiredUserStep1?: boolean,
}

export function buildInitialState() {
  const props: cadastroEmpresaReducerType = {
    type: actionTypes.CADASTRO_EMPRESA_USUARIO_NOVO,
    cadastroEmpresaDados: {
      empresa: {},
      usuario: {},
    },
    cargo: null,
    cnpjValido: false,
    contrato: null,
  
    /**
     * Se todos os campos obrigatórios referentes ao contrato da empresa foram preenchidos.
     */
    filledRequiredContract: false,
  
    /**
     * Se todos os campos obrigatórios referentes aos dados da empresa foram preenchidos.
     */
    filledRequiredCompany: false,
  
    /**
     * Se todos os campos obrigatórios referentes ao usuário da empresa foram preenchidos.
     */
    filledRequiredUser: false,

    minVersion: localStorage.getItem("expandedMode") === "true",
  
    paisObject: null,
  
    /**
     * Estado atual do cadastro de empresas
     */
    state: STATE_CADASTRO_EMPRESA_CONTRATO,
    usuario: null,
    usuarioOption: "",
  
    step: 0,
  };

  return props;
}

/**
 * Estado Inicial, obrigatório no Reducer.
 */
const initialState = buildInitialState();

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state: cadastroEmpresaReducerType = initialState, action: Partial<cadastroEmpresaReducerType>) => {

  switch (action.type) {
    case actionTypes.CADASTRO_EMPRESA_EDIT:
    case actionTypes.CADASTRO_EMPRESA_USUARIO_NOVO:
    case actionTypes.CADASTRO_EMPRESA_USUARIO_LOAD:
    case actionTypes.CADASTRO_EMPRESA_EMAIL_SPINNER:
    case actionTypes.CADASTRO_EMPRESA_NORMAL_SPINNER:
    case actionTypes.CADASTRO_EMPRESA_DEMO_SPINNER:
    case actionTypes.CADASTRO_EMPRESA_CARGO:
    case actionTypes.CADASTRO_EMPRESA_CONTRATO:
    case actionTypes.CADASTRO_EMPRESA_DADOS:
    case actionTypes.CADASTRO_EMPRESA_DEMO:
    case actionTypes.CADASTRO_EMPRESA_E_USUARIO_CHANGE_STATE:
    case actionTypes.CADASTRO_EMPRESA_E_USUARIO_SAVE_DATA:
    case actionTypes.CADASTRO_EMPRESA_LIST_EXPANDED_MODE:

      let updatedObject: cadastroEmpresaReducerType = updateObject(state, {
        ...state,
        ...action,
      }) as cadastroEmpresaReducerType;

      const filledRequiredContract = !!updatedObject?.cadastroEmpresaDados?.empresa?.contratoEmpresa?.tipoContrato;

      const user = updatedObject?.cadastroEmpresaDados?.usuario || {};
      const login = user.login || {};
      const additionalData = user.dadosAdicionais || {};

      const company = updatedObject?.cadastroEmpresaDados?.empresa || {};
      const contact = company.contatoEmpresa || {};
      const address = company.enderecoEmpresa || {};

      const filledRequiredCompanyStep0 = ((company.ramoEmpresa || null) !== null)
        && ((company.razaoSocial || "") !== "")
        && ((company.nomeFantasia || "") !== "")
        && ((company.cnpj || "") !== "")
        && ((company.inscricaoEstadual || "") !== "");

      const filledRequiredCompanyStep1 = ((address.bairro || "") !== "")
        && ((address.cep || "") !== "")
        && ((address.endereco || "") !== "")
        && ((address.municipio || "") !== "")
        && ((address.numero || "") !== "")
        && ((address.pais || "") !== null)
        && ((address.uf || "") !== "");

      const filledRequiredCompanyStep2 = ((contact.email || "") !== "")
        && ((contact.telefone || "") !== "")
        && ((contact.nomeContato || "") !== "");
        // && updatedObject.cnpjValido
      
      const filledRequiredCompany = filledRequiredCompanyStep0
        && filledRequiredCompanyStep1
        && filledRequiredCompanyStep2

      let filledRequiredUserStep0 = ((user.nome || "") !== "")
        && ((user.sobrenome || "") !== "")
        && ((login.email || "") !== "")
        && ((login.senha || "") !== "");

      let filledRequiredUserStep1 =
        //&& ((login.confirmacaoSenha || "") !== "")
        ((additionalData.telefone || "") !== "")
        && (!!additionalData.pais) && ((additionalData.cep || "") !== "")
        && ((additionalData.uf || "") !== "")
        && ((additionalData.municipio || "") !== "")
        && ((additionalData.telefone || "") !== "")
        && ((additionalData.endereco || "") !== "")
        && ((additionalData.numero || "") !== "");

      let filledRequiredUser = filledRequiredUserStep0 && filledRequiredUserStep1;

      updatedObject = {
        ...updatedObject,
        filledRequiredCompany,
        filledRequiredCompanyStep0,
        filledRequiredCompanyStep1,
        filledRequiredCompanyStep2,
        filledRequiredContract,
        filledRequiredUser,
        filledRequiredUserStep0,
        filledRequiredUserStep1,
        step: updatedObject.type === actionTypes.CADASTRO_EMPRESA_EDIT
          || updatedObject.type === actionTypes.CADASTRO_EMPRESA_E_USUARIO_SAVE_DATA
          || updatedObject.type === actionTypes.CADASTRO_EMPRESA_E_USUARIO_CHANGE_STATE
          || updatedObject.type === actionTypes.CADASTRO_EMPRESA_USUARIO_NOVO
          ? updatedObject.step
          : 0,
      };

      // Basta controlar a construção do action no outro arquivo.
      return updatedObject;
    default: return state;
  }
}

export default reducer
