import React from 'react';

type propsPadrao = React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>;

class Texto extends React.Component<propsPadrao> {
  render() {
    return <p {...this.props} className={'ui-texto ' + (this.props.className + '')} />;
  }
}

export default Texto;
