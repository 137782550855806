//@ts-check
import { roundNumber } from "../NumberUtils/NumberUtils";
import { EntidadeUtils } from "../utils";

/**
 * 
 * @param {boolean} media 
 * @param {import("../../redux/carrinho/types").carrinhoProdutoTipo[]} sabores 
 * @param {number|null} tamanhoId 
 * @param {number} quantidade 
 * @param {number} quantidadeTotal 
 * @returns 
 */
export default function calcularValorTotalProdutoComTamanho(media, sabores, 
    tamanhoId, quantidade, quantidadeTotal) {
    let precoUnitario = 0;

    if (media) {
        // Multiplica quantidade com preco e divide pelo total de quantidade
        const precoTotal = [0, ...sabores.map(sabor => {
            const produtoTamanho = sabor.produtoTamanhoList.find((produtoTamanho) => EntidadeUtils.getIdFromEntity(produtoTamanho.tamanho) === tamanhoId) || {preco: 0};
            return sabor.quantidade * produtoTamanho.preco;
        })]
        .reduce((soma, valorTotal) => soma + valorTotal);

        precoUnitario = roundNumber((precoTotal / quantidade), 2);
    }
    else {
        // Verifica o valor mais alto
        precoUnitario = [0, ...sabores.map(sabor => {
            const produtoTamanho = sabor.produtoTamanhoList.find((produtoTamanho) => EntidadeUtils.getIdFromEntity(produtoTamanho.tamanho) === tamanhoId) || {preco: 0};
            return produtoTamanho.preco;
        })]
        .reduce((valorMaisAlto, valorAtual) => valorAtual > valorMaisAlto ? valorAtual : valorMaisAlto);
    }

    return {valorTotal: roundNumber(precoUnitario * quantidadeTotal, 2), precoUnitario};
}