import React from 'react';

import { isEmpresaPainel } from "../../utils/CompanyUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../utils/reduxUtils/reduxUtils"
import { getPageSize } from "../../utils/StorageUtils/LocalStorageUtils"
import { urlDatabase } from "../../utils/SecureConnectionUtils"
import { formatI18nString } from "../../utils/StringUtils"

import { connect } from 'react-redux';
import * as cadastroActions from '../../store/actions/cadastroAction';
import * as pagamentoVendasActions from '../../store/actions/controleVenda/pagamentoVendasAction';
import { FILTER_TIMER_DELAY } from '../../store/actions/cadastroAction';
import { SPINNER_FILTRO_ORIGEM_VENDA_ID, SPINNER_FILTRO_VENDA_ID } from '../../store/actions/controleVenda/controleVendaAction';

import BarraAcoesTable from '../cadastros/barraAcao/BarraAcoesTable';
import FiltroInputDefault from '../UI/filtroInputDefault/FiltroInputDefault';
import HelpParagraph from '../UI/HelpParagraph/HelpParagraph';
import TelaCancelPagamentoVendasContent from './TelaCancelPagamentoVendasContent';
import WidthAwareDiv from '../UI/WidthAwareDiv/WidthAwareDiv';

import { SUBMIT_CANCEL_PAYMENT } from '../cadastros/barraAcao/BarraAcoesTable';

/**
 * Exibe a lista de pagamentos de venda.
 */
class GridPagamento extends React.Component {

    constructor(props) {
        log('GridPagamento constructor');
        super(props);
        this.ismounted = false;

        // Recupera e ajusta a URL para acesso aos dados
        this.urlDataBase = urlDatabase + '/pagamentoVendas/findToCancel';
    }

    /**
     * Executado quando é realizada uma consulta a partir do filtro.
     * @param {Number} pageSize
     */
    filterHandler = pageSize => {
        log('GridPagamento filterHandler');
        let filter = [];
        let filterProperties = [];
        let filtroOrigemVenda = null;
        // Se for empresa do ramo Painel, não usa filtro de origem de venda.
        if ((!isEmpresaPainel(this.props.ramoEmpresa)) && this.filtroOrigemVendaComponent) {
            filtroOrigemVenda = this.filtroOrigemVendaComponent.getValue();
        }
        let filtroVenda = this.filtroVendaComponent.getValue();
        if (filtroVenda) {
            filter.push(filtroVenda);
            filterProperties.push('vendaNome');
        }
        if (filtroOrigemVenda) {
            filter.push(filtroOrigemVenda);
            filterProperties.push('vendaContainerNome');
        }
        if (filter.length < 1) {
            filter = null;
        }
        if (this.ismounted) {
            this.props.cadastroLoadFromServer(pageSize ? 0 : this.props.page, pageSize, this.urlDataBase, this.getIsMounted, 'dataHora,DESC', filterProperties, filter, 'pagamentoVenda');
        }
    }

    /**
     * Retorna se o componente está montado no momento em que o método é chamado,
     * já que ele é passado por parâmetro para ser chamado depois.
     */
    getIsMounted = () => {
        log('CancelamentoBox getIsMounted', { mounted: this.ismounted });
        return this.ismounted;
    }

    /**
     * Método executado ao ser alterado o número de cadastros que deve ser exibido em cada página.
     * Recarrega a primeira página dos cadastros,
     * passando por parâmetro o número de cadastros a ser exibido por página.
     * @param {*} pageSize 
     */
    updatePageSize = pageSize => {
        if (pageSize !== this.props.pageSize) {
            this.filterHandler(pageSize);
        }
    }

    /**
     * Método executado APÓS a montagem do componente.
     * Realiza o carregamento dos cadastros na tela.
     */
    componentDidMount() {
        log('GridPagamento componentDidMount');
        this.ismounted = true;
    }

	/**
	 * Método executado ANTES de "DESMONTAR" o componente.
	 * Marca o componente como NÃO montado.
	 */
    componentWillUnmount() {
        this.ismounted = false;
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('GridPagamento render');

        let atLeastOneSelected = (this.props.salePaymentToCancelList || []).length > 0;

        return <>

            <WidthAwareDiv>
                <HelpParagraph
                    children={getStrings()
                        .cancelHelp(isEmpresaPainel(this.props.ramoEmpresa))
                        .map(help => formatI18nString(help))}
                />
            </WidthAwareDiv>

            <BarraAcoesTable
                attractSubmit={atLeastOneSelected}
                page={this.props.page}
                links={this.props.links}
                pageSize={this.props.pageSize}
                onNavigate={navUri => this.props.cadastroNavigate(navUri, this.props.pageSize)}
                updatePageSize={this.updatePageSize}
                handleNovo={() => this.props.showCancelDialog(this.props.history)}
                objetoCadastro='pagamentoVenda'
                filterProperties={this.props.filterProperties}
                submitRole={SUBMIT_CANCEL_PAYMENT}
                submitDisabled={!atLeastOneSelected}
            />

            <WidthAwareDiv>
                <div className='sub-form'>
                    {isEmpresaPainel(this.props.ramoEmpresa)
                        ? null
                        : <FiltroInputDefault
                            id="filtroCartao"
                            ref={input => this.filtroOrigemVendaComponent = getReduxWrappedComponent(input)}
                            onFilterTimerCompleted={() => this.filterHandler(getPageSize())}
                            spinnerId={SPINNER_FILTRO_ORIGEM_VENDA_ID}
                            timerDelay={FILTER_TIMER_DELAY}
                            label={getStrings().saleSourceFilter()}
                            placeholder={getStrings().saleSourceFilterPlaceholder()}
                        />}

                    <FiltroInputDefault
                        id="filtroComanda"
                        ref={input => this.filtroVendaComponent = getReduxWrappedComponent(input)}
                        onFilterTimerCompleted={() => this.filterHandler(getPageSize())}
                        spinnerId={SPINNER_FILTRO_VENDA_ID}
                        timerDelay={FILTER_TIMER_DELAY}
                        label={getStrings().saleFilter()}
                        placeholder={getStrings().saleFilterPlaceholder()}
                    />
                </div>
            </WidthAwareDiv>

            <TelaCancelPagamentoVendasContent />
        </>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    tabIndex: state.appReducer.getTabIndex(),
    ...state.idiomaReducer,
    links: state.cadastroReducer.links,
    page: state.cadastroReducer.page,
    pageSize: state.cadastroReducer.pageSize,
    ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa,
    salePaymentToCancelList: state.pagamentoVendasReducer.salePaymentToCancelList,
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = (dispatch) => ({

    cadastroLoadFromServer: (page, pageSize, urlDataBase, isMounted, sortProperty, filterProperties, filter, objetoCadastroLast) =>
        dispatch(cadastroActions.cadastroLoadFromServer(page, pageSize, urlDataBase, isMounted, sortProperty, filterProperties, filter, objetoCadastroLast)),

    cadastroNavigate: (navUri, pageSize) => dispatch(cadastroActions.cadastroNavigate(navUri, pageSize)),

    showCancelDialog: history => dispatch(pagamentoVendasActions.showCancelDialog(history)),
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(GridPagamento);
