import React from "react"
import { connect } from "react-redux"

import { log } from "../../../../../utils/LogUtils"
import { validaCNPJ } from "../../../../../utils/IndentificadorUtils"
import { areNumericInputValuesEqual, areSelectInputValuesEqual, areTextInputValuesEqual } from "../../../../../utils/ObjectUtils"
import * as SelectUtils from "../../../../../utils/SelectUtils"

import * as cadastroActions from "../../../../../store/actions/cadastroAction"
import { STATE_CADASTRO_EMPRESA_EMPRESA } from "../../../../../store/reducers/cadastroEmpresaReducer"
import * as cadastroEmpresaAction from "../../../../../store/actions/cadastroEmpresaAction"
import { RamoEmpresa } from "../../../../../store/actions/empresaSelectorAction"

import { SignUpCompanyAddressStep } from "./SignUpCompanyAddressStep"
import { SignUpCompanyContactStep } from "./SignUpCompanyContactStep"
import { SignUpCompanyUserFormCompanyInfoStep } from "./SignUpCompanyUserFormCompanyInfoStep"

class SignUpCompanyCompanyForm extends React.Component {

  companyFieldArray = ["razaoSocial", "nomeFantasia", "cnpj", "inscricaoEstadual"]

  addressFieldArray = ["uf", "municipio", "bairro", "endereco", "numero", "complemento", "cep"]

  contactFieldArray = ["email", "telefone", "nomeContato"]

  genericFieldArray = this.companyFieldArray
    .concat(this.addressFieldArray)
    .concat(this.contactFieldArray)
    .concat(["ramoEmpresa", "pais", "percentualComissao"])

  restauranteAKiloFieldArray = ["precoLivre"]

  /**
   * Retorna se os campos obrigatórios (required) foram todos preenchidos
   */
  checkRequired = newData => {

    let cnpjValido = validaCNPJ(newData.cnpj);
    this.props.setCnpjValido(cnpjValido);

    let result = (newData.razaoSocial !== '') && (newData.nomeFantasia !== '') && (newData.cnpj !== '') && (newData.inscricaoEstadual !== '') && (newData.ramoEmpresa !== null) && (newData.contatoEmpresa.email !== '') && (newData.contatoEmpresa.telefone !== '') && (newData.contatoEmpresa.nomeContato !== '') && newData.contratoEmpresa && (newData.contratoEmpresa.tipoContrato !== null) && (newData.enderecoEmpresa.bairro !== '') && (newData.enderecoEmpresa.cep !== '') && (newData.enderecoEmpresa.endereco !== '') && (newData.enderecoEmpresa.municipio !== '') && (newData.enderecoEmpresa.numero !== '') && (newData.enderecoEmpresa.pais !== null) && (newData.enderecoEmpresa.uf !== '') && cnpjValido;

    log('SignUpCompanyCompanyForm checkRequired', { newData, result });

    return result;
  }

  /**
   * Recupera os dados do formulário.
   */
  getFormData() {
    log('SignUpCompanyCompanyForm getFormData');
    // Verifica se há referência para os campos
    if (this.genericFieldArray.some(field => !this[field])
      // Só verifica se há referência para os seguintes campos se empresa for de determinado ramo
      || (this.props.reducerEmpresa
        && (this.props.reducerEmpresa.ramoEmpresa === RamoEmpresa.RESTAURANTE_A_KILO)
        && this.restauranteAKiloFieldArray.some(field => !this[field]))) {
      return {
        razaoSocial: '',
        nomeFantasia: '',
        cnpj: '',
        inscricaoEstadual: '',
        ramoEmpresa: null,
        ramoEmpresaOption: null,
        enderecoEmpresa: {
          pais: null,
          paisOption: null,
          uf: '',
          municipio: '',
          bairro: '',
          endereco: '',
          numero: '',
          complemento: '',
          cep: '',
        },
        contatoEmpresa: {
          email: '',
          telefone: '',
          nomeContato: ''
        },
        contratoEmpresa: (this.props.reducerEmpresa || {}).contratoEmpresa,
        parametrosEmpresa: {
          percentualComissao: 0,
          precoLivre: null
        },
      };
    }
    return {
      razaoSocial: this.props.reducerEmpresa.razaoSocial,
      nomeFantasia: this.props.reducerEmpresa.nomeFantasia,
      cnpj: this.props.reducerEmpresa.cnpj,
      inscricaoEstadual: this.props.reducerEmpresa.inscricaoEstadual,
      ramoEmpresa: this.props.reducerEmpresa.ramoEmpresa,
      ramoEmpresaOption: this.props.reducerEmpresa.ramoEmpresaOption,
      enderecoEmpresa: {
        pais: SelectUtils.cadastroBuildJSONFromOptions(false, this.pais.inputComponent.getValue()),
        paisOption: this.pais.inputComponent.getValue(),
        uf: this.uf.inputComponent.value,
        municipio: this.municipio.inputComponent.value,
        bairro: this.bairro.inputComponent.value,
        endereco: this.endereco.inputComponent.value,
        numero: this.numero.inputComponent.value,
        complemento: this.complemento.inputComponent.value,
        cep: this.cep.inputComponent.value,
      },
      contatoEmpresa: {
        email: this.props.reducerEmpresa.email,
        telefone: this.props.reducerEmpresa.telefone,
        nomeContato: this.props.reducerEmpresa.nomeContato,
      },
      contratoEmpresa: this.props.reducerEmpresa?.contratoEmpresa,
      parametrosEmpresa: {
        percentualComissao: this.props.reducerEmpresa?.parametrosEmpresa?.percentualComissao || 0,
        precoLivre: this.props.reducerEmpresa?.parametrosEmpresa?.precoLivre || null,
      },
    };
  }

  /**
   * Método que carrega os itens do campo Países.
   */
  loadPaises = () => {
    log('SignUpCompanyCompanyForm loadPaises');
    // Carrega o campo de País com a função de busca, conforme o campo for sendo preenchido
    this.props.cadastroLoadPaises(cadastros => {
      if (!this.ismounted)
        return;

      if (this.props.reducerEmpresa.enderecoEmpresa?.paisOption) {
        this.pais.inputComponent.updateValue(this.props.reducerEmpresa.enderecoEmpresa?.paisOption, "iso")
      }
      else {
        const options = SelectUtils.cadastroBuildOptionsFromPaises(true, cadastros);
        const value = options.find((value) => value.labelKey === 'BR');

        this.pais.inputComponent.updateOptions(options);
        this.pais.inputComponent.updateValue(value, 'iso');
      }
    }, () => { });
  }

  /**
   * Transfere os valores no *reducer* para os campos.
   */
  updateFromReducer = () => {
    log('SignUpCompanyCompanyForm updateFromReducer');
    // Verifica se há referência para os campos
    if (this.genericFieldArray.some(field => !this[field])
      // Só verifica se há referência para os seguintes campos se empresa for de determinado ramo
      || (this.props.reducerEmpresa
        && (this.props.reducerEmpresa.ramoEmpresa === RamoEmpresa.RESTAURANTE_A_KILO)
        && this.restauranteAKiloFieldArray.some(field => !this[field]))) {
      return;
    }
    // Busca os valores do reducer
    let reducerEmpresa = this.props.reducerEmpresa || {};
    // Transfere os valores se eles forem diferentes para não atrapalhar na digitação
    this.companyFieldArray
      .filter(field => !areTextInputValuesEqual(this[field].inputComponent.value, reducerEmpresa[field]))
      .forEach(field => this[field].inputComponent.value = reducerEmpresa[field]);

    this.addressFieldArray
      .filter(field => !areTextInputValuesEqual(this[field].inputComponent.value, (reducerEmpresa.enderecoEmpresa || {})[field]))
      .forEach(field => this[field].inputComponent.value = (reducerEmpresa.enderecoEmpresa || {})[field]);

    this.contactFieldArray
      .filter(field => !areTextInputValuesEqual(this[field].inputComponent.value, (reducerEmpresa.contatoEmpresa || {})[field]))
      .forEach(field => this[field].inputComponent.value = (reducerEmpresa.contatoEmpresa || {})[field]);

    if (!areSelectInputValuesEqual(this.ramoEmpresa.inputComponent.getValue(), reducerEmpresa.ramoEmpresaOption)) {
      this.ramoEmpresa.inputComponent.updateValue(reducerEmpresa.ramoEmpresaOption);
    }

    if (!areSelectInputValuesEqual(this.pais.inputComponent.getValue(), (reducerEmpresa.enderecoEmpresa || {}).paisOption)) {
      this.pais.inputComponent.updateValue((reducerEmpresa.enderecoEmpresa || {}).paisOption);
    }
  }

  /**
   * Armazena os dados no reducer assim que modifica algum campo.
   */
  updateReducer() {
    // Verifica se há referência para os campos
    if (this.genericFieldArray.some(field => !this[field])
      // Só verifica se há referência para os seguintes campos se empresa for de determinado ramo
      || (this.props.reducerEmpresa
        && (this.props.reducerEmpresa.ramoEmpresa === RamoEmpresa.RESTAURANTE_A_KILO)
        && this.restauranteAKiloFieldArray.some(field => !this[field]))
    ) {
      log('SignUpCompanyCompanyForm updateReducer some unmounted fields');
      return;
    }
    // Busca os valores dos campos
    let formEmpresa = this.getFormData();
    // Busca os valores do reducer
    let reducerEmpresa = this.props.reducerEmpresa;
    // Verifica se há dados para atualizar no reducer. Se não, faz mais nada.
    let valueMatches = [];

    valueMatches.push(this.companyFieldArray.every(field => areTextInputValuesEqual(formEmpresa[field], reducerEmpresa[field])));
    valueMatches.push(this.addressFieldArray.every(field => areTextInputValuesEqual(formEmpresa.enderecoEmpresa[field], (reducerEmpresa.enderecoEmpresa || {})[field])));
    valueMatches.push(this.contactFieldArray.every(field => areTextInputValuesEqual(formEmpresa.contatoEmpresa[field], (reducerEmpresa.contatoEmpresa || {})[field])));
    valueMatches.push(areSelectInputValuesEqual(formEmpresa.ramoEmpresaOption, reducerEmpresa.ramoEmpresaOption));
    valueMatches.push(areSelectInputValuesEqual(formEmpresa.enderecoEmpresa.paisOption, (reducerEmpresa.enderecoEmpresa || {}).paisOption));
    valueMatches.push(areNumericInputValuesEqual(formEmpresa.parametrosEmpresa.percentualComissao, (reducerEmpresa.parametrosEmpresa || {}).percentualComissao));
    valueMatches.push((!reducerEmpresa.empresa)
      || (reducerEmpresa.empresa.ramoEmpresa !== RamoEmpresa.RESTAURANTE_A_KILO)
      || areNumericInputValuesEqual(formEmpresa.parametrosEmpresa.precoLivre, (reducerEmpresa.parametrosEmpresa || {}).precoLivre));

    log('SignUpCompanyCompanyForm updateReducer', { valueMatches });
    if (valueMatches.every(value => value)) {
      return;
    }
    this.props.cadastroEmpresaEUsuarioSaveData(formEmpresa);
    this.checkRequired(this.getFormData());
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  componentDidMount() {
    log('SignUpCompanyCompanyForm componentDidMount');
    this.ismounted = true;

    this.loadPaises();

    this.updateFromReducer();

    this.props.cadastroSetChanged(true);

    this.checkRequired(this.getFormData());
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   * Necessário ao definir `getSnapshotBeforeUpdate`.
   */
  componentDidUpdate() {
    log('SignUpCompanyUserForm componentDidUpdate');

    this.updateReducer();
  }

  render() {
    log('SignUpCompanyCompanyForm render');
    return <>
      <SignUpCompanyUserFormCompanyInfoStep />

      <SignUpCompanyAddressStep />

      <SignUpCompanyContactStep />
    </>;
  }
}

/**
 * Mapeia as propriedades do estado global para utilizar localmente. 
 * @param {*} state 
 */
const mapStateToProps = state => ({
  ...state.idiomaReducer,
  reducerEmpresa: state.cadastroEmpresaReducer.cadastroEmpresaDados.empresa
});

/**
 * Mapeia as ações para utilizar localmente. 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  cadastroLoadPaises: (onSuccess, onComplete) =>
    dispatch(cadastroActions.cadastroLoadPaises(onSuccess, onComplete)),
  cadastroSetChanged: changed => 
    dispatch(cadastroActions.cadastroSetChanged(changed)),
  cadastroEmpresaEUsuarioSaveData: dados => 
    dispatch(cadastroEmpresaAction.cadastroEmpresaEUsuarioSaveData(STATE_CADASTRO_EMPRESA_EMPRESA, dados)),
  setCnpjValido: (cnpjValido) => 
    dispatch(cadastroEmpresaAction.setCnpjValido(cnpjValido)),
  updateFromZipCode: (zipCode, state, city, neighborhood, street) =>
    dispatch(cadastroEmpresaAction.updateFromZipCode(STATE_CADASTRO_EMPRESA_EMPRESA, zipCode, state, city, neighborhood, street))
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SignUpCompanyCompanyForm);