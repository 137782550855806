import React from 'react';

import { formatNumber } from "../../../utils/NumberUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import CadastroTable from './CadastroTable';
import OptionButtons from '../optionButtons/OptionButtons';
import BairroTableModel from './BairroTableModel';
import BotaoChecado from '../../UI/botoes/BotaoChecado/BotaoChecado';

/**
 * Classe que repassa para o CadastroTable as informações para a montagem da tabela dos Bairros.
 */
export default class BairroTable extends React.Component {

    render() {
        log('BairroTable render');
        return <CadastroTable {...this.props} objetoCadastro='bairro' sortProperty={'nome'} filterProperties={['nome', 'codigo']} tableModel={BairroTableModel}
            blankFormData={{
                enabled: true,
                codigo: '',
                nome: '',
                valorEntrega: null
            }} registerHelp={getStrings().neighborhoodTableHelp}
        />;
    }
}

/**
 * Classe que realiza a montagem das linhas da tabela dos Bairros.
 */
export class BairroTableRow extends React.Component {

    /**
     * Método que executa a montagem do componente.
     */
    render() {
        log('BairroTableRow render');

        let id = `${getURIFromEntity(this.props.cadastro)}/Checkbox`;

        return <tr>

            {/* Opções de manutenção */}
            <OptionButtons className='ButtonAlign' onUpdate={this.props.onUpdate} onDelete={this.props.onDelete} cadastro={this.props.cadastro} />

            {/* Dados do cadastro */}
            <td className='Lighter ButtonAlign' width='5%' >
                <BotaoChecado key={`__${id}__`} desativado={!this.props.cadastro.enabled} />
            </td>
            <td className='Lighter TextAlign' width='15%' >{this.props.cadastro.codigo}</td>
            <td className='Lighter TextAlign' width='40%' >{this.props.cadastro.nome}</td>
            <td className='Lighter NumberAlign' width='35%' >{formatNumber(this.props.cadastro.valorEntrega, 2)}</td>
        </tr>;
    }
}
