import React from 'react';
import { connect } from 'react-redux';
import * as appActions from '../../../store/actions/appAction';

import { ClienteHttp } from "../../../apps/promo_app_frontend/nucleo/utils/utils"
import * as AuthUtils from "../../../utils/AuthUtils"
import { getNomeProdutoFromItemVenda } from "../../../utils/MiscUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { formatDayMonthYearHourMinute } from "../../../utils/TimeUtils"
import { getIdFromEntity, getIdFromURI, getURIFromEntity } from "../../../utils/URIUtils"

// import { ICON_ASTERISK, ICON_HELMET } from '../../../utils/IconUtils';

import DialogItemVenda from '../dialog/DialogItemVenda';
import { MdCheck } from 'react-icons/md';

/**
 * Função que monta a célula a partir do item de venda.
 */
class BuildCellFromItemVenda extends React.Component {
    state = {
        corHorario: '',
    };

    updateCorHorario = setInterval(() => {

        const tempoEntrega = this.props.itemVenda.venda.entregaVenda?.tempoEntrega || 0;

        const dataHoraFimProducao = new Date(tempoEntrega > 60 ? this.props.itemVenda.venda.entregaVenda.dataHoraEntrega : this.props.itemVenda.dataHoraFimProducao);
        dataHoraFimProducao.setSeconds(0);

        let horarioAmarelo = new Date();
        horarioAmarelo.setSeconds(59);
        horarioAmarelo = new Date(horarioAmarelo.getTime() + (this.props.tempoAmarelo * 60000));

        let horarioVermelho = new Date();
        horarioVermelho.setSeconds(59);
        horarioVermelho = new Date(horarioVermelho.getTime() + (this.props.tempoVermelho * 60000));

        if (horarioVermelho >= dataHoraFimProducao) {
            if (this.state.corHorario !== 'color-red') {
                this.setState({
                    corHorario: 'color-red'
                });
            }

        }
        else if (horarioAmarelo >= dataHoraFimProducao) {
            if (this.state.corHorario !== 'color-yellow') {
                this.setState({
                    corHorario: 'color-yellow'
                });
            }
        }
        else {
            if (this.state.corHorario !== '') {
                this.setState({
                    corHorario: ''
                });
            }
        }

    }, 1000);

    componentDidMount() {

    }

    componentWillUnmount() {
        clearInterval(this.updateCorHorario);
    }

    /**
     * Se o cargo possui permissão para alterar o estado do item de venda.
     */
    editItemAllowed = () => AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_PRODUCAO_ALTERACAO]);

    /**
     * Método executado ao clicar sobre a célula.
     */
    handleClick = (evt) => {
        evt.type === 'click' && evt.preventDefault();

        if (!this.editItemAllowed()) {
            return;
        }
        this.props.appDialogShow(
            <DialogItemVenda
                itemVenda={this.props.itemVenda}
                itemProducao={this.props.itemProducao}
            />,
            null,
            {
                maxWidth: '400px',
                maxHeight: '250px',
                width: '75%',
                //height: '240px',
                top: '12%',
                left: '12%',
                marginTop: '0px',
                marginLeft: '0px',
            }
        );

        if (this.props.piscar) {
            ClienteHttp.requisicaoServidor('itemProducao/update', 'post', true, null, {
                itemNaoVisto: false,
                itemProducaoList: this.props.itemProducao
                    ? [getIdFromEntity(this.props.itemProducao)]
                    : this.props.itemVenda.itemProducaoList.map((item) => getIdFromEntity(item)),
            });
        }
    }

    mapKeys = {
        'Enter': this.handleClick,
        ' ': (evt) => { evt.preventDefault(); return this.handleClick(evt); },
    };

    keyHandler = (evt) => {
        try {
            this.mapKeys[evt.key](evt);
        } catch (err) { }
    };

    clickHandler = (event) => {
        if (!this.editItemAllowed()) {
            return;
        }

        // if (this.props.simpleTapSelection) {
        //     if (this.props.delivery.includes(this.props.itemVendaId)) {
        //         this.props.removeDelivery(this.props.itemVendaId);
                
        //         return;
        //     }

        //     this.props.updateDelivery(this.props.itemVendaId);
            
        //     return;
        // }

        // if (this.props.ctrlPressed) {
        //     if (this.props.delivery.includes(this.props.itemVendaId)) {
        //         this.props.removeDelivery(this.props.itemVendaId);
                
        //         return;
        //     }

        //     this.props.updateDelivery(this.props.itemVendaId);

        //     return;
        // }

        if (this.props.modoSelecao) {
            this.props.atualizarSelecao(getIdFromURI(this.props.itemProducaoId ? this.props.itemProducaoId : this.props.itemVendaId));

            return;
        }

        return this.handleClick(event);
    }

    // touchStartHandler = (event) => {
    //     this.startTimeStamp = event.timeStamp;
    // }

    // touchEndHandler = (event) => {
    //     this.endTimeStamp = event.timeStamp;

    //     const time = this.endTimeStamp - this.startTimeStamp;
        
    //     if (time > 500) {
    //         event.stopPropagation();

    //         if (!this.editItemAllowed()) {
    //             return;
    //         }
    
    //         this.props.updateSimpleTapSelection(true);
    //         if (this.props.delivery.includes(this.props.itemVendaId)) {
    //             this.props.removeDelivery(this.props.itemVendaId);
                
    //             return;
    //         }

    //         this.props.updateDelivery(this.props.itemVendaId);
    //     }
    // }

    getNomeCliente = (venda = {}) => {
        if (venda.clientePreCadastrado) {
            return venda.clientePreCadastrado.nome;
        }
        else if (venda.usuarioCliente) {
            return `${venda.usuarioCliente.nome} ${venda.usuarioCliente.sobrenome}`;
        }
        else if (venda.cliente) {
            return `${venda.cliente.nome} ${venda.cliente.sobrenome}`;
        }
        return '';
    }

    buildObservacao = () => {
        let observacaoItem = this.props.itemVenda.observacao;
        let observacaoVenda = this.props.itemVenda.venda.entregaVenda?.observacao;

        if (observacaoItem && observacaoVenda) {
            return <div>
                <div className='linha'>
                    <div className='observacao linha-unica' style={observacaoItem ? undefined : { background: 'transparent' }}>
                        {`Obs I: ${observacaoItem}`}
                    </div>
                </div>
                <div className='linha'>
                    <div className='observacao linha-unica' style={observacaoVenda ? undefined : { background: 'transparent' }}>
                        {`Obs P: ${observacaoVenda}`}
                    </div>
                </div>
            </div>
        }
        else if (observacaoItem) {
            return <div className='linha'>
                <div className='observacao' style={observacaoItem ? undefined : { background: 'transparent' }}>
                    {`Obs: ${observacaoItem}`}
                </div>
            </div>
        }
        else if (observacaoVenda) {
            return <div className='linha'>
                <div className='observacao' style={observacaoVenda ? undefined : { background: 'transparent' }}>
                    {`Obs P: ${observacaoVenda}`}
                </div>
            </div>
        }
        else {
            return <div className='linha'>
                <div className='observacao' style={observacaoVenda ? undefined : { background: 'transparent' }}></div>
            </div>
        }
    }

    render() {
        const estado = getStrings().saleItemStateEnumToString(this.props.itemProducao ? this.props.itemProducao.estado : this.props.itemVenda.estado);
        const itemId = getIdFromURI(this.props.itemProducaoId ? this.props.itemProducaoId : this.props.itemVendaId);
        const vendaId = getIdFromURI(getURIFromEntity(this.props.itemVenda.venda));
        const tempoEntrega = this.props.itemVenda.venda.entregaVenda?.tempoEntrega || 0;

        return <div id='production-card-cell' className={(this.props.sideDrawerShow ? 'pure-u-sideDrawerShow ' : '') + 'pure-u-1 pure-u-sm-1 pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-3 pure-u-xxl-1-4' + (this.props.piscar ? ' blink_me' : '')}>
            <div tabIndex={this.props.tabIndex} className={`${this.editItemAllowed() ? 'clickable ' : ''}card estado-producao with-footer card-container${isAncientChromeMobile ? ' ancient-chrome-mobile' : ''}`}onClick={this.clickHandler} onKeyDown={this.keyHandler} onTouchStart={this.touchStartHandler}  onTouchEnd={this.touchEndHandler}>
                {this.props.orelhinhaDePapel
                    ? <div key={`__orelhinha_de_papel_${this.props.keyIds}`} className='dog-ear'></div>
                    : null
                }
                <span className='uri-container' children={itemId} />
                <div className='card-content production'>
                    <div className='linha no-grow'>
                        <div className='numero-pedido'>
                            {`${this.props.itemVenda.venda.origemVenda?.codigo} - ${vendaId.toString().padStart(8, '0')}`}
                        </div>
                        <div className='nome-cliente'>
                            {this.getNomeCliente(this.props.itemVenda.venda)}
                        </div>
                        {this.props.modoSelecao
                            ? <div key='__selecao_check__' className='selecao'>
                                {this.props.selecionados.includes(itemId)
                                    ? <MdCheck key='__selecao_check_icon__' />
                                    : null
                                }
                            </div>
                            : null
                        }
                    </div>
                    <div className='linha'>
                        {this.props.agruparProdutosVenda
                            ? <div className='quantidade-itens'>
                                {`${this.props.itemVenda.quantidade}x`}
                            </div>
                            : null}
                        <div className='descricao'>
                            {getNomeProdutoFromItemVenda(this.props.itemVenda)}
                        </div>
                    </div>
                    {this.buildObservacao()}
                </div>

                <div className={`card-footer ${this.props.stateToColor(this.props.itemProducao ? this.props.itemProducao.estado : this.props.itemVenda.estado)}${isAncientChromeMobile ? ' row' : ''}`}>
                        <div className={`data-hora ${this.props.showProduced ? '' : this.state.corHorario}`}>
                            {`${formatDayMonthYearHourMinute(new Date(this.props.itemVenda.dataHoraInicioProducao), true)}
                            ${formatDayMonthYearHourMinute(new Date(tempoEntrega > 60 ? this.props.itemVenda.venda.entregaVenda.dataHoraEntrega : this.props.itemVenda.dataHoraFimProducao), true)}`}
                        </div>
                        <div className={`estado Uppercase ${estado}`} style={{ color: "var(--color-text-dark)" }}>
                            {estado}
                        </div>
                </div>

            </div>
        </div>;
    }
};

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    simpleTapSelection: state.simpleTapSelectionReducer,
    tabIndex: state.appReducer.getTabIndex(),
    agruparProdutosVenda: state.producaoReducer.agruparProdutosVenda,
    tempoAmarelo: state.producaoReducer.tempoAmarelo,
    tempoVermelho: state.producaoReducer.tempoVermelho,
    showProduced: state.producaoReducer.showProduced,
    sideDrawerShow: state.appReducer.sideDrawerShow,
    ...state.idiomaReducer
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    dispatch,
    appDialogShow: (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles) =>
        dispatch(appActions.appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles)),
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(BuildCellFromItemVenda);
