import React from "react"
import { connect } from "react-redux"
import Select from "react-select"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { localeSort } from "../../../utils/SortUtils"

import "react-select/dist/react-select.css"

/**
 * Componente para Multi Select
 */
class MultiSelectField extends React.Component {

    constructor(props) {
        log("MultiSelectField constructor", { props })
        super(props)

        this.isMulti = "isMulti" in this.props
        // Variáveis de State
        this.state = { value: this.isMulti ? [] : null, options: [], locale: null }

        /* 
         * Variável que armazena as opções instantaneamente.
         * Necessária pois ao executar o comando "this.setState({ options: newOptions })",
         * o sistema leva um pequeno tempo para atualizar o valor da variavel de State,
         * e se for passado novas opções ao Select e rápidamente tendado recuperar as opções dele, ele estará com os valores desatualizados.
         * Como esta variável não é de State ela muda instantaneamente e pode repassar o valor rapidamente.
         */
        this.optionInstant = [];
    }

    /**
     * Método executado para definir as opções do Select
     * @param {*} newOptions 
     */
    updateOptions = newOptions => {
        log('MultiSelectField updateOptions', { newOptions });

        // Atualiza o valor instantâneo
        this.optionInstant = newOptions;

        // Atualiza o valor de State
        this.setState({ options: newOptions, locale: this.props.locale });
    }

    /**
     * Método executado para definir os valores iniciais do Select
     * @param {*} newValue 
     * @param {*} key 
     */
    updateValue = (newValue, key) => {
        log('MultiSelectField updateValue', { newValue, key });

        // Caso o novo valor seja null, não necessita realizar as verificações
        if ((newValue === undefined) || (newValue === null)) {
            this.setState({ value: newValue, locale: this.props.locale });
            return;
        }

        // Ajusta a key para o valor default, caso não tenha sido fornecido
        if ((key === undefined) || (key === null))
            key = 'codigo';

        /* 
         * Substitui os objetos do "newValue" pelas opções disponíveis no comboBox.
         * Isso é necessário para que o componente saiba que deve remover das opções disponíveis os valores setados.
         */

        // Recupera a lista de opções do variável instantânea
        const optionList = this.optionInstant;

        const sizeOption = optionList.length;

        /*
         * Ajusta o newValue para multipla seleção
         */
        if (this.isMulti) {

            const sizeValue = newValue.length;

            if ((sizeValue > 0) && (sizeOption > 0)) {

                for (let x = 0; x < sizeValue; x++) {

                    for (let y = 0; y < sizeOption; y++) {
                        const option = optionList[y];

                        if (newValue[x].value[key] === option.value[key]) {

                            newValue[x] = option;
                            break;
                        }
                    }
                }
            }
        }

        /*
         * Ajusta o newValue para seleção única
         */
        else {

            if (sizeOption > 0) {

                for (let y = 0; y < sizeOption; y++) {
                    const option = optionList[y];

                    if (newValue.value[key] === option.value[key]) {

                        newValue = option;
                        break;
                    }
                }
            }
        }

        this.setState({ value: newValue, locale: this.props.locale });
    }

    /**
     * Método executado caso haja alguma mudança nas opções selecionadas.
     * @param {*} newValue 
     */
    handleSelectChange = newValue => {
        log('MultiSelectField handleSelectChange', { newValue });
        this.setState({ value: newValue, locale: this.props.locale });

        this.props.onChange && this.props.onChange(newValue);
    }

    /**
     * Método que retorna as opções que estão selecionadas (lê do estado).
     */
    getValue = () => {
        log('MultiSelectField getValue');
        return this.state.value;
    }

    /**
     * Método que retorna as opções (lê do estado).
     */
    getOptions = () => {
        log('MultiSelectField getOptions');
        return this.state.options;
    }

    /**
     * Retorna se há uma opção selecionada (objeto ou lista).
     */
    isValueSelected = value => {
        log('MultiSelectField isValueSelected', { value });
        return (!value) ? false : ('length' in value) ? (value.length > 0) : true;
    }

    /**
     * Retorna se há uma opção selecionada (objeto ou lista; lê das propriedades, que atualiza antes do estado).
     */
    isValueSelected_props = () => {
        log('MultiSelectField isValueSelected_props');
        return this.isValueSelected(this.selectField.props.value);
    }

    /**
     * Retorna se há uma opção selecionada (objeto ou lista; lê do estado, que atualiza depois das propriedades).
     */
    isValueSelected_state = () => {
        log('MultiSelectField isValueSelected_state');
        return this.isValueSelected(this.getValue());
    }

    /**
     * Método executado APÓS a montagem/renderização do componente.
     * Reordena as opções, caso o idioma tenha sido alterado.
     */
    componentDidUpdate = () => {
        if (this.props.sort && (this.state.locale !== this.props.locale)) {

            this.setState({ options: localeSort(this.state.options, 'label'), locale: this.props.locale });
        }

        if (this.selectField) {
            this.getInput && this.getInput(this.selectField);
        }
    }

    componentDidMount = () => {
        typeof this.props.innerFunctions === 'function' && this.props.innerFunctions({
            updateOptions: this.updateOptions,
            updateValue: this.updateValue,
            getValue: this.getValue,
            getOptions: this.getOptions,
        });
    };

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render = () => {
        log('MultiSelectField render');

        const { options, value } = {
            ...this.state,
            options: this.props.newAPI ? this.props.options : this.state.options,
            value: this.props.newAPI ? this.props.value : this.state.value,
        };

        const customProps = this.isMulti
            ? {
                searchable: this.props.searchable,
                className: `react-select-container multiSelectParentClass ${this.props.className || ''}`,
            }
            : {
                clearable: this.props.clearable,
                searchable: this.props.searchable,
                arrowRenderer: this.props.arrowRenderer,
                wrapperStyle: this.props.wrapperStyle,
                className: `react-select-container Lighter ${this.props.className || ''}`,
            };

        return <Select
            multi={this.isMulti}
            onChange={this.handleSelectChange}
            options={options}
            placeholder={this.props.placeholder}
            value={value}
            ref={(input) => { this.selectField = input; }}
            required={this.props.required}
            {...customProps}
            inputProps={this.props.inputProps}
            onInputChange={this.props.onInputChange}
            onBlur={this.props.onBlur}
            tabIndex={this.props.tabIndex}
            noResultsText={getStrings().noResultsText}
        />;
    };
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    ...state.idiomaReducer
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, null, null, { forwardRef: true })(MultiSelectField);
