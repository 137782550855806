//@ts-check
import React from 'react';

import { LocalidadeUtils, PedidoUtils } from '../../../nucleo/utils/utils';

import { connect } from 'react-redux';
import moment from 'moment';

import Icones from '../../../assets/icones/Icones';
import getEnderecoEmTexto from '../../../../../utils/EnderecoUtils/getEnderecoEmTexto';

import "./PedidoCard.css";

/**
 * 
 * @param {import('./types').pedidoInfoComponentPropsType} props 
 * @returns 
 */
const PedidoInfoComponente = (props) => {
  /**
   * 
   * @type {Array<React.ReactNode>}
   */
  const linhas = [];

  Object.keys(props.produtos).forEach((id) => {
    const produto = props.produtos[id];

    linhas.push(
      <div className='linha-item'>
        <div key={`__pedido_info_nome_${id}__`} className='item nome'>{produto.nome}</div>
        <div key={`__pedido_info_observacoes_${id}__`} className='item observacoes'>{produto.observacao}</div>
        <div key={`__pedido_info_quantidade_${id}__`} className='item quantidade'>{produto.quantidade}</div>
        <div key={`__pedido_info_preco_unitario_${id}__`} className='item preco-unitario'>{LocalidadeUtils.obterTraducao().currency.format(produto.preco)}</div>
        <div key={`__pedido_info_valor_pago_${id}__`} className='item valor-pago'>{LocalidadeUtils.obterTraducao().currency.format(produto.valorTotal)}</div>
      </div>);
  });

  return <>
    <div className='produtos-cabecalho'>
      <div className='coluna nome'>{LocalidadeUtils.obterTraducao().orders.product}</div>
      <div className='coluna observacoes'>{LocalidadeUtils.obterTraducao().words.observationShort}</div>
      <div className='coluna quantidade'>{LocalidadeUtils.obterTraducao().orders.quantity}</div>
      <div className='coluna preco-unitario'>{LocalidadeUtils.obterTraducao().orders.unityPrice}</div>
      <div className='coluna valor-pago'>{LocalidadeUtils.obterTraducao().orders.totalValue}</div>
    </div>
    <div className='produtos-itens'>
      {linhas}
    </div>
  </>;
} 

/**
 * 
 * @param {import('./types').pedidoCardPropsType} props 
 * @returns 
 */
function PedidoCard(props) {
  const [ aberto, updateAberto ] = React.useState(false);

  const {
    id,
    motivoCancelamento,
    produtos,
    dataDoPedido,
    entregaVenda,
    valorTotal,
  } = props.pedido;

  const estado = PedidoUtils.estadoDosPedidos[props.pedido.estado] || props.pedido.estado;

  const listaProdutosIds = Object.keys(produtos);

  const FlechaParaBaixoIcone = Icones.mobi.FlechaParaBaixo;
  const FlechaParaCimaIcone = Icones.mobi.FlechaParaCima;

  const {cidade, rua, pais, ...endereco} = {...(props.pedido.endereco || {}), municipio: props.pedido.endereco?.cidade}
  /**
   * 
   * @type {Partial<import('../../../../../utils/EnderecoUtils/EnderecoUsuarioType').EnderecoUsuarioType>}
   */
  const enderecoUsuario = {...endereco, municipio: cidade || null, endereco: rua || null, pais: pais ? {...pais, geo_latitude: -1, geo_longitude: -1} : null}
  const textoEndereco = getEnderecoEmTexto({enderecoUsuario})

  return <div className='app-card pedido-card' style={aberto ? undefined : {opacity: 0.7}} onClick={() => updateAberto(!aberto)}>
    <div className='linha'>
      <div className='id'>{id}</div>
      <div className='valor-total'>{LocalidadeUtils.obterTraducao().currency.format(valorTotal || 0)}</div>
    </div>
    <div className='linha'>
      <div className='data-pedido'>{moment.utc(dataDoPedido).format('DD/MM/YYYY - HH:mm')} - {listaProdutosIds.length} {LocalidadeUtils.obterTraducao().orders[listaProdutosIds.length > 1 ? 'items' : 'item']}</div>
      {aberto
        ? <div key={`__flecha__produtos_${id}__`} className='flecha'>
          <FlechaParaCimaIcone />
        </div>
        : <div key={`__flecha__produtos_${id}__`} className='flecha'>
          <FlechaParaBaixoIcone />
        </div>
      }
    </div>
    <div className='linha'>
      <div className='estado-pedido'>
        <div className='campo'>{LocalidadeUtils.obterTraducao().words.state}:</div>
        <div className='valor'>{estado}</div>
      </div>
    </div>
    {motivoCancelamento && ['CANCELADO', 'NAO_ACEITO'].includes(props.pedido.estado)
      ? <div className='linha'>
          <div className='motivo-estado-pedido'>
            <div>{(LocalidadeUtils.obterTraducao().words.reason + ':')} {motivoCancelamento}</div>
          </div>
        </div>
      : null
    }
    <div className='linha'>
      <div className='tipo-entrega'>
        <div className='campo'>{(LocalidadeUtils.obterTraducao().words.type + ':')}</div>
        <div className='valor'>{entregaVenda?.teleEntrega ? LocalidadeUtils.obterTraducao().words.delivery : LocalidadeUtils.obterTraducao().words.takeAway}</div>
      </div>
    </div>
    {entregaVenda?.valorTeleEntrega
      ? <div className='linha'>
          <div className='valor-tele-entrega'>
            <div className='campo'>{(LocalidadeUtils.obterTraducao().orders.deliveryValue + ':')}</div>
            <div className='valor'>{LocalidadeUtils.obterTraducao().currency.format(entregaVenda.valorTeleEntrega)}</div>
          </div>
        </div>
      : null
    }
    {entregaVenda?.teleEntrega
      ? <div className='linha'>
          <div className='endereco'>
            <div className='campo'>{(LocalidadeUtils.obterTraducao().words.adress.adress + ':')}</div>
            <div className='valor'>{textoEndereco}</div>
          </div>
        </div>
      : null
    }
    <div className='linha'>
      {props.pedido.observacao ?
        <div className='observacao-geral'>
          <div className='campo'>{(LocalidadeUtils.obterTraducao().words.observation + ':')}</div>
          <div className='valor'>{props.pedido.observacao}</div>
        </div>
        : null
      }
    </div>
    <div className='produtos' onClick={(event) => { event.stopPropagation(); }}>
      {aberto
        ? <PedidoInfoComponente key={`__pedido_produtos__`} produtos={produtos} />
        : null
      }
    </div>
  </div>;
  
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} _ 
 * @returns 
 */
const mapStateToProps = (_) => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PedidoCard);