import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../../../utils/LocaleUtils"
import { log } from "../../../../../utils/LogUtils"
import { areSelectInputValuesEqual, areTextInputValuesEqual } from "../../../../../utils/ObjectUtils"
import * as SelectUtils from "../../../../../utils/SelectUtils"

import { STATE_CADASTRO_EMPRESA_CONTRATO } from "../../../../../store/reducers/cadastroEmpresaReducer"
import * as cadastroActions from "../../../../../store/actions/cadastroAction"
import * as cadastroEmpresaAction from "../../../../../store/actions/cadastroEmpresaAction"

import InputCustomizado from "../../../../UI/Input/InputCustomizado"

class SignUpCompanyContractForm extends React.Component {
  constructor() {
    super();

    this.state = {
      tipoContratoOptions: [],
      tipoContratoValue: undefined,
    };
  }

  /**
   * Recupera os dados do formulário.
   */
  getFormData = () => {
    let formData;

    // Verifica se há referência para os campos
    if (this.state.tipoContratoValue) {
      formData = {
        tipoContrato: SelectUtils.cadastroRecuperaValueFromOptions(this.state.tipoContratoValue),
        tipoContratoOption: this.state.tipoContratoValue,
        situacaoEmpresa: 'Em dia',
      };
    }
    else {
      formData = {
        tipoContrato: null,
        tipoContratoOption: null,
        situacaoEmpresa: 'Em dia',
      };
    }

    return formData;
  }

  /**
   * Método que carrega os itens do campo Tipo de Contrato.
   */
  loadTiposContrato = () => {
    this.props.cadastroLoadTiposContrato((data) => {
      const options = data.map((value) => ({ value, label: getStrings().contractTypeEnumToString(value.nome) }));
      const value = options.length === 1 ? options[0] : this.props.dados.empresa?.contratoEmpresa?.tipoContratoOption;

      this.setState({ tipoContratoValue: value, tipoContratoOptions: options });
    });
  };

  /**
   * Armazena os dados no reducer assim que modifica algum campo.
   */
  updateReducer() {
    log('SignUpCompanyContractForm updateReducer');

    // Busca os valores dos campos
    let dadosContratoEmpresa = this.getFormData();

    // Busca os valores do reducer
    let contratoEmpresa = (this.props.dados.empresa || {}).contratoEmpresa || null;

    // Verifica se há dados para atualizar no reducer. Se não, faz mais nada.       
    if (areSelectInputValuesEqual(dadosContratoEmpresa.tipoContratoOption, (contratoEmpresa || {}).tipoContratoOption)
      && areTextInputValuesEqual(dadosContratoEmpresa.situacaoEmpresa, (contratoEmpresa || {}).situacaoEmpresa)) {
      return;
    }
    this.props.cadastroEmpresaEUsuarioSaveData(dadosContratoEmpresa);
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  componentDidMount() {
    log('SignUpCompanyContractForm componentDidMount');

    this.ismounted = true;
    this.loadTiposContrato();
    this.props.cadastroSetChanged(true);
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   * Necessário ao definir `getSnapshotBeforeUpdate`.
   */
  componentDidUpdate(prevProps) {
    log('SignUpCompanyContractForm componentDidUpdate');

    if (prevProps.tipoContratoValue !== this.state.tipoContratoValue) {
      this.updateReducer();
    }

    if (this.state.tipoContratoOptions.length === 1) {
      if (this.props.voltarTelaAnterior) {
        this.props.cadastroAnterior();
      }
      else if (this.state.tipoContratoValue) {
        this.props.cadastroProximo();
      }
    }
  }

  render = () => {
    return <div id='campos-contrato' className='sub-form' >
      <h2>{getStrings().contract}</h2>

      <InputCustomizado
        newAPI
        options={this.state.tipoContratoOptions}
        value={this.state.tipoContratoValue}
        id='tipoContrato'
        inputType='singleSelect'
        name='tipoContrato'
        required={true}
        placeholder={getStrings().contractTypePlaceholder}
        label={getStrings().contractTypeRequired}
        autoComplete='nah'
        onChange={(value) => {
          this.setState({ tipoContratoValue: value });
          document.querySelector('#next-button').focus();
        }}
        sort
      />
    </div>;
  };
}

/**
 * Mapeia as propriedades do estado global para utilizar localmente. 
 * @param {*} state 
 */
const mapStateToProps = state => ({

  ...state.idiomaReducer,
  dados: state.cadastroEmpresaReducer.cadastroEmpresaDados,
  estado: state.cadastroEmpresaReducer.state
});

/**
 * Mapeia as ações para utilizar localmente. 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  cadastroLoadTiposContrato: (onSuccess, onComplete) => dispatch(cadastroActions.cadastroLoadTiposContrato(onSuccess, onComplete)),
  cadastroSetChanged: changed => dispatch(cadastroActions.cadastroSetChanged(changed)),
  cadastroAnterior: () => dispatch(cadastroEmpresaAction.cadastroEmpresaEUsuarioAnterior()),
  cadastroProximo: () => dispatch(cadastroEmpresaAction.cadastroEmpresaEUsuarioProximo()),
  cadastroEmpresaEUsuarioSaveData: (dados) => dispatch(cadastroEmpresaAction.cadastroEmpresaEUsuarioSaveData(STATE_CADASTRO_EMPRESA_CONTRATO, dados))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpCompanyContractForm);
