import { Loader } from "@googlemaps/js-api-loader"
import { axios } from "../store/actions/appAction"

import { callbackToAsyncReturn } from "./functionUtils/callbackToAsyncReturn"
import { nullOrUndefined } from "./ComparatorsUtils"

const zoom = 20;

export function getGApi() {
  // @ts-ignore
  return window.google
}

function googleMaps({ idDoElemento }) {
  let directions = {
    service: {},
    renderer: {},
  }
  let position = { lat: 0, lng: 0 }
  let map = {}

  function mostrarRota(map, directions, enderecos = [], { isDestinoOrigem = false, origem = null }) {
    return new Promise((resolve, reject) => {
      if (!origem) {
        reject(origem)

        return;
      }

      resolve(origem)
    })
      .catch(() => {
        return axios().post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_G_MAPS_API_KEY}`).then(({ data }) => {
          position = data.location || position

          return position
        })
      })
      .then((origem) => {
        if (!(enderecos?.length)) {
          return
        }

        const origin = origem || position;
        const paradas = (isDestinoOrigem
          ? enderecos
          : enderecos.slice(0, -1)
        ).map((endereco) => ({ location: endereco }));
        const destination = (isDestinoOrigem
          ? origin
          : enderecos.slice(-1)[0])

        const params = {
          origin,
          destination,
          travelMode: 'DRIVING',
          provideRouteAlternatives: false,
          drivingOptions: {
            departureTime: (new Date(Date.now() + (20 * 3600))),
            trafficModel: 'optimistic',
          },
          ...(paradas.length ? { waypoints: paradas, optimizeWaypoints: true, } : {}),
        }

        directions.service.route(params, function (result, status) {
          if (status === 'OK') {
            directions.renderer.setDirections(result);
            map.setCenter(position);
            map.setZoom(zoom);
          }
        })
      })
  }

  function handleLocationError(browserHasGeolocation, infoWindow, pos, map) {
    infoWindow.setPosition(pos)
    infoWindow.setContent(
      browserHasGeolocation
        ? 'Error: The Geolocation service failed.'
        : 'Error: Your browser doesn\'t support geolocation.'
    )
    infoWindow.open(map)
  }

  async function ordernarRotasDistancia(origens = [], destinos = []) {
    const origensReduced = [[], ...origens]
      .reduce((orgns, origem) => {
        const teste = orgns.includes(origem);

        if (teste) {
          return orgns;
        }

        return [...orgns, origem];
      })

    const destinosReduced = [[], ...destinos]
      .reduce((dests, destino) => {
        const teste = dests.includes(destino)

        if (teste) {
          return dests
        }

        return [...dests, destino]
      })

    const gApi = getGApi()

    if (!(origensReduced.length && destinosReduced.length)) {
      return destinosReduced
    }

    const distance = new gApi.maps.DistanceMatrixService()

    const params = {
      origins: origensReduced,
      destinations: destinosReduced,
      travelMode: 'DRIVING',
      drivingOptions: {
        departureTime: (new Date(Date.now())),
        trafficModel: 'optimistic',
      },
    }

    let retCallback = []

    await distance.getDistanceMatrix(params, (resposta) => {

      if (nullOrUndefined(resposta)) {
        return;
      }

      let origensEDestinos = {}
      const origins = resposta.originAddresses
      const destinations = resposta.destinationAddresses
      const destinosOrdenados = []

      const rows = resposta.rows.map((row, i) => {
        const results = row.elements
        const from = origins[i]

        const r = {
          [from]: results
            .map((element, j) => {
              const multiplicador = element.distance.text.includes('km')
                ? 1000
                : 1
              const distance = parseFloat(element.distance.text.replace(/[^(0-9|,)]/g, '').replace(',', '.')) * multiplicador
              const duration = element.duration.text
              const to = destinations[j]

              origensEDestinos[from] = { ...(origensEDestinos[from] || {}), [to]: { distance, duration, from, to } }

              return origensEDestinos[from]
            })
            .filter((destinos) => {
              const to = destinos[Object.keys(destinos)[0]].to
              return to !== from
            }),
        }

        return r
      })

      let origin = Object.keys(rows[0])[0]
      let i = 0
      const length = rows[0][origin].length

      while (i < length) {
        const destinos = Object.values(origensEDestinos[origin])
          .sort((a, b) => {
            if (a.distance === b.distance) {
              return 0
            }

            return a.distance > b.distance
              ? 1
              : -1
          })

        destinosOrdenados.push(destinos[0])
        delete origensEDestinos[origin]
        const novaOrigem = destinosOrdenados[destinosOrdenados.length - 1].to
        Object.keys(origensEDestinos).forEach((origem) => {
          const destinos = origensEDestinos[origem]

          Object.keys(destinos).forEach((destino) => {
            if (destino === novaOrigem) {
              delete origensEDestinos[origem][destino]
            }
          });
        })

        origin = novaOrigem
        i += 1
      }

      retCallback = destinosOrdenados
    })

    return retCallback
  }

  async function init() {
    const loaderConfig = {
      apiKey: process.env.REACT_APP_G_MAPS_API_KEY || '',
      version: "weekly",
    };
    const loader = new Loader(loaderConfig);

    const {coords} = navigator.geolocation.getCurrentPosition ? await callbackToAsyncReturn((c) => navigator.geolocation.getCurrentPosition(c)) : {}
    position = coords ? {lat: coords.latitude, lng: coords.longitude} : await axios().post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_G_MAPS_API_KEY}`) || position
    await loader.load()
    const gApi = getGApi()
    const elemento = document.getElementById(idDoElemento)
    const mapInit = {
      center: position,
      zoom: zoom,
    }

    map = new gApi.maps.Map(elemento, mapInit)

    directions.service = new gApi.maps.DirectionsService()
    directions.renderer = new gApi.maps.DirectionsRenderer()

    directions.renderer.setMap(map)

    return { map, directions, position, mostrarRota, handleLocationError, ordernarRotasDistancia, init }
  }

  return { map, directions, position, mostrarRota, handleLocationError, ordernarRotasDistancia, init }
}

export function getMapaApi({
  api = 'googleMaps',
  idDoElemento = '',
}) {
  const mapaApi = {
    googleMaps: googleMaps({ idDoElemento }),
  };

  return mapaApi[api] || { map: {}, mostrarRota: () => { }, init: async () => { console.error('dummy...'); }, ordernarRotasDistancia: async () => { } };
}

export function distanciaEntreDoisPontos(origem, destino, callback) {
  const gApi = getGApi()

  const loader = new Loader({
    apiKey: `${process.env.REACT_APP_G_MAPS_API_KEY}`,
    version: "weekly",
  });

  if (gApi) {
    calculaDistancia(origem, destino, callback);
  }
  else {
    loader.load().then(() => {
      calculaDistancia(origem, destino, callback)
    });
  }
}

function calculaDistancia(origem, destino, callback) {
  const gApi = getGApi()

  const distance = new gApi.maps.DistanceMatrixService();

  const params = {
    origins: [origem],
    destinations: [destino],
    travelMode: 'DRIVING',
    drivingOptions: {
      departureTime: (new Date(Date.now())),
      trafficModel: 'optimistic',
    },
  };

  distance.getDistanceMatrix(params, (response, status) => {

    if (status === 'OK' && response?.rows.length) {

      console.log('ORIGEM: ' + response.originAddresses);
      console.log('DESTINO: ' + response.destinationAddresses);
      response.rows[0].elements.forEach(element => {
        element.status === 'OK' && console.log((element.distance.value / 1000) + ' Km');
      });

      const distMinima = Math.min(...response.rows[0].elements.map(element => element.status === 'OK' ? element.distance.value : 0));

      callback(distMinima);
    }
  });
}