import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from "../../../utils/LocaleUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"

import {
  ORDEM_ASCENDENTE,
  ORDEM_DESCENDENTE,
  CODIGO_ORDENACAO_PEDIDO,
  CODIGO_ORDENACAO_ESTADO,
  CODIGO_ORDENACAO_ORIGEM,
  CODIGO_ORDENACAO_PRODUTO,
  CODIGO_ORDENACAO_HORARIO_INICIO,
  CODIGO_ORDENACAO_HORARIO_TERMINO,
  PARAMETRO_BUSCA_ORDENACAO,
  TELA_EM_PRODUCAO
} from '../../../store/reducers/producaoReducer';
import * as actions from '../../../store/actions/producaoAction';

import InputCustomizado from '../../UI/Input/InputCustomizado';

class ProducaoOrdencao extends React.Component {

  ordenarPorComponent;

  componentDidMount() {
    this.loadTiposOrdenacao();
    this.loadTiposOrdem();
  }

  componentDidUpdate(prevProps) {
    const telaAtual = this.props.telaAtual;

    if ((prevProps && prevProps[telaAtual][PARAMETRO_BUSCA_ORDENACAO].ordenacao !== this.props[telaAtual][PARAMETRO_BUSCA_ORDENACAO].ordenacao)
      || (prevProps && prevProps[telaAtual][PARAMETRO_BUSCA_ORDENACAO].ordem !== this.props[telaAtual][PARAMETRO_BUSCA_ORDENACAO].ordem)) {
      this.props.loadItensVendaAbertos();
    }

    if (prevProps && prevProps.telaAtual !== telaAtual) {
      this.loadTiposOrdenacao();
      this.loadTiposOrdem();
    }
  }

  loadTiposOrdenacao = () => {
    if (this.ordenarPorComponent && this.ordenarPorComponent.inputComponent) {

      let options = [
        { label: getStrings().order(1), labelKey: CODIGO_ORDENACAO_PEDIDO, value: { codigo: CODIGO_ORDENACAO_PEDIDO } },
        { label: getStrings().state, labelKey: CODIGO_ORDENACAO_ESTADO, value: { codigo: CODIGO_ORDENACAO_ESTADO } },
        { label: getStrings().saleSource(), labelKey: CODIGO_ORDENACAO_ORIGEM, value: { codigo: CODIGO_ORDENACAO_ORIGEM } },
      ]

      if (this.props.telaAtual === TELA_EM_PRODUCAO) {
        options.push({ label: getStrings().product, labelKey: CODIGO_ORDENACAO_PRODUTO, value: { codigo: CODIGO_ORDENACAO_PRODUTO } })
      }

      if (!this.props.isEntregador) {
        options.push(
          { label: getStrings().startTime, labelKey: CODIGO_ORDENACAO_HORARIO_INICIO, value: { codigo: CODIGO_ORDENACAO_HORARIO_INICIO } },
          { label: getStrings().endTime, labelKey: CODIGO_ORDENACAO_HORARIO_TERMINO, value: { codigo: CODIGO_ORDENACAO_HORARIO_TERMINO } }
        )
      }

      this.ordenarPorComponent.inputComponent.updateOptions(options);

      this.ordenarPorComponent.inputComponent.updateValue(options.find(option => option.value.codigo === this.props[this.props.telaAtual][PARAMETRO_BUSCA_ORDENACAO].ordenacao));
    }
  }

  loadTiposOrdem = () => {
    if (this.ordemComponent && this.ordemComponent.inputComponent) {

      let options = [
        { label: getStrings().ascending, labelKey: ORDEM_ASCENDENTE, value: { codigo: ORDEM_ASCENDENTE } },
        { label: getStrings().descending, labelKey: ORDEM_DESCENDENTE, value: { codigo: ORDEM_DESCENDENTE } },
      ]

      this.ordemComponent.inputComponent.updateOptions(options);

      this.ordemComponent.inputComponent.updateValue(options.find(option => option.value.codigo === this.props[this.props.telaAtual][PARAMETRO_BUSCA_ORDENACAO].ordem));
    }
  }

  handleMudarOrdenacao = () => {
    if (this.ordenarPorComponent.inputComponent.getValue()) {
      this.props.updateParametrosBusca(
        PARAMETRO_BUSCA_ORDENACAO,
        this.ordenarPorComponent.inputComponent.getValue().value.codigo,
        'ordenacao');
    }
  }

  handleMudarOrdem = () => {
    if (this.ordemComponent.inputComponent.getValue()) {
      this.props.updateParametrosBusca(
        PARAMETRO_BUSCA_ORDENACAO,
        this.ordemComponent.inputComponent.getValue().value.codigo,
        'ordem');
    }
  }

  render() {
    return <>
      <div className='producao-ordenacao'>
        <div style={{ flexGrow: '3', margin: '0.25em 0.25em 0 0.25em', minWidth: '12.5em' }}>
          <InputCustomizado ref={input => { if (input) { this.ordenarPorComponent = getReduxWrappedComponent(input); } }} id='ordenarPor' name='ordenarPor' inputType='singleSelect' placeholder={''} label={getStrings().sortBy} searchable={false} onChange={() => setTimeout(() => this.handleMudarOrdenacao(), 0)} />
        </div>
        <div style={{ flexGrow: '1', margin: '0.25em 0.25em 0 0.25em', minWidth: '9.5em' }}>
          <InputCustomizado ref={input => { if (input) { this.ordemComponent = getReduxWrappedComponent(input); } }} id='ordem' name='ordem' inputType='singleSelect' placeholder={''} label={getStrings().sortOrder} searchable={false} onChange={() => setTimeout(() => this.handleMudarOrdem(), 0)} />
        </div>
      </div>
    </>;
  }
}

const mapStateToProps = state => ({
  ...state.producaoReducer,
  ...state.idiomaReducer,
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  loadItensVendaAbertos: link => dispatch(actions.loadItensVendaAbertos(link)),
  updateParametrosBusca: (tipoParametro, valor, posicao) => dispatch(actions.updateParametrosBusca(tipoParametro, valor, posicao)),
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(ProducaoOrdencao);