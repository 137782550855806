import linksType from "../../components/Paginacao/Tipos/linksType"
import { actionType } from "../../components/Telas/LandingPage/reducers/reducersType"
import { pageType } from "../../utils/entidadeUtils/pageType"
import { updateObject } from "../../utils/ObjectUtils"

import * as actionTypes from "../actions/actionTypes"

/**
 * Antigo `isCadastro = true`.
 */
export const STATE_CADASTRO_FORM = "STATE_CADASTRO_FORM"
/**
 * Antigo `isCadastro = false`.
 */
export const STATE_CADASTRO_LIST = "STATE_CADASTRO_LIST"
export const STATE_CADASTRO_PRINT_PREVIEW = "STATE_CADASTRO_PRINT_PREVIEW"
export const STATE_CADASTRO_PRINT_SETUP = "STATE_CADASTRO_PRINT_SETUP"
export const STATE_CADASTRO_SETUP = "STATE_CADASTRO_SETUP"

export interface cadastroReducerType {
  error: string|null,
  loading: boolean | {form: boolean},
  page: pageType|number,
  pageSize: number,
  cadastroList: Record<string,any>[],
  links: linksType,
  cadastroDados: Record<string,any>,
  cadastroDadosIniciais: Record<string,any>,
  state: string,
  filter: string,
  breadCrumbsPrefix: string,
  breadCrumbsSuffix: string[],
  objetoCadastroLast: string,
  cadastroChanged: boolean,
  cadastroFilledRequired: boolean,
  operation: string|null,
  cadastroFormRequired: boolean,
}

/**
 * Estado Inicial, obrigatório no Reducer.
 */
const initialState: cadastroReducerType = {
  error: null,
  loading: false,
  page: 0,
  pageSize: 10,
  cadastroList: [],
  links: {
    first: {href: ""},
    prev: {href: ""},
    self: {href: ""},
    next: {href: ""},
    last: {href: ""},
  },
  cadastroDados: {},
  cadastroDadosIniciais: {},
  state: STATE_CADASTRO_LIST,
  filter: "",
  breadCrumbsPrefix: "",
  breadCrumbsSuffix: [""],
  objetoCadastroLast: "",
  cadastroChanged: false,
  cadastroFilledRequired: false,
  operation: null,
  cadastroFormRequired: false,
}

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {*} state 
 * @param {*} action 
 */
const cadastroReducer = (state: cadastroReducerType = initialState, action: actionType) => {

  switch (action.type) {
    case actionTypes.CADASTRO_SWITCH_UI:
    case actionTypes.CADASTRO_EDIT:
    case actionTypes.CADASTRO_LOAD:
    case actionTypes.CADASTRO_SUBMIT:
    case actionTypes.CADASTRO_SUCCESS:
    case actionTypes.CADASTRO_FAIL:
    case actionTypes.CADASTRO_UPDATE_BARRA_ACOES_TABLE:
    case actionTypes.CADASTRO_CLEAN_FILTER:
    case actionTypes.CADASTRO_RESET_PAGE_NUMBER:
      // window.scrollTo({top: 0, left: 0, behavior: "smooth"})

      // Basta controlar a construção do action no outro arquivo.
      const { handleNovo, ...currentState } = state as cadastroReducerType & { handleNovo: () => void };
      const newState = updateObject(currentState, {
        ...currentState
        , ...action
      });
      return newState;
    default: return state;
  }
}

export default cadastroReducer;