//@ts-check
import React from "react"
import { BrowserRouter, Switch } from "react-router-dom"

/**
 * 
 * @param {import("./types").roteadorPropsType} props 
 * @returns {JSX.Element}
 */
function Roteador(props) {
  return <BrowserRouter>
		<Switch>
			{props.children}
		</Switch>
  </BrowserRouter>;
}

export default Roteador;
