//@ts-check
import React from 'react';
import { connect } from 'react-redux';
import { Botao, Entrada } from '../../../nucleo/nucleo';

import { LocalidadeUtils, ProdutoUtils } from '../../../nucleo/utils/utils';
import { atualizarQuantidadeUnidade } from '../../../nucleo/redux/carrinho/carrinhoActions';
import { fecharDialog } from '../../../nucleo/redux/dialog/dialogActions';

import "./QuantidadeDialog.css";

/**
 * 
 * @param {import('./types').quantidadeDialogPropsType} props 
 * @returns {JSX.Element}
 */
function QuantidadeDialog(props) {
  return <div className={'ui-dialog'}>
    <div id='quantidade-dialog'>
      <div className='conteudo'>
        <div className='quantidade'>
          <div>{`${LocalidadeUtils.obterTraducao().words.amount}:`}</div>
          <div>
            <Botao className='diminuir-quantidade' disabled={props.unidade.quantidade <= 1} onClick={() => { props.dispatch(atualizarQuantidadeUnidade({quantidade: props.unidade.quantidade - 1})); }}>-</Botao>
            <Entrada className='editar-quantidade' value={props.unidade.quantidade} onChange={
              /**
               * 
               * @param {{target: {value: string}}} event 
               */
              (event) => {
              if (event.target.value === '') {
                return;
              }            
              const quantidade = parseInt(event.target.value);
              if (quantidade > 0 && quantidade < ProdutoUtils.obterQuantidadeEmEstoque()) {
                props.dispatch(atualizarQuantidadeUnidade({quantidade}));
              }
            }} />
            <Botao className='aumentar-quantidade' disabled={props.unidade.quantidade >= ProdutoUtils.obterQuantidadeEmEstoque()} onClick={() => { props.dispatch(atualizarQuantidadeUnidade({quantidade: props.unidade.quantidade + 1})); }}>+</Botao>
          </div>
        </div>
        <div className='valor-total'>
          <div className='campo'>{`${LocalidadeUtils.obterTraducao().orders.totalValue}:`}</div>
          <div className='valor'>{props.shortInfo}</div>
        </div>
      </div>
      <div className='botoes'>
        <Botao children={LocalidadeUtils.obterTraducao().words.ok} onClick={() => {
            props.fecharDialog();
            props.onOk();
        }} />
        <Botao children={LocalidadeUtils.obterTraducao().words.cancel} onClick={() => {
            props.fecharDialog();
            props.onCancel();
        }} />
      </div>
    </div>
  </div>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
    shortInfo: state.webAppInfoReducer.shortInfo,
    unidade: state.webAppCarrinhoReducer.unidade,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
    dispatch,
    fecharDialog: () => dispatch(fecharDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuantidadeDialog);