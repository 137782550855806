import React from "react"

import { getStrings } from "./LocaleUtils"

import InputCustomizado from "../components/UI/Input/InputCustomizado"

const GenericContainer = props => {
  return props.children
}

const getFilter = (props = {}) => {
  return <div style={{ width: "16em" }}>
    <InputCustomizado
      newAPI
      inputType={`multiSelect`}
      placeholder={getStrings().filterBy}
      value={props.value}
      label={props.label}
      options={props.options}
      onChange={props.onChange}
    />
  </div>
}

/**
 * 
 * @param {Array<String>} head 
 * @param {Array<String> | Object} body 
 * @param {Object} options {props, namedRows, HeadContainer, BodyContainer, LineIdContainer, bodyStyle, bodyStyleIndexes}
 * 
 * @returns {React.Component} component
 */
export const generateSimpleTable = (head, body, options) => {
  let { props, namedRows, HeadContainer, BodyContainer, LineIdContainer, bodyStyle, bodyStyleIndexes, headStyle, headStyleIndexes, label, filter } = options || {}

  head = head || []
  body = body || []
  props = props || {}
  namedRows = namedRows || false
  HeadContainer = HeadContainer || GenericContainer
  BodyContainer = BodyContainer || GenericContainer
  LineIdContainer = LineIdContainer || GenericContainer

  bodyStyle = bodyStyle || null
  bodyStyleIndexes = bodyStyleIndexes || bodyStyle ? head.map((_, index) => index) : []

  headStyle = headStyle || null
  headStyleIndexes = headStyleIndexes || headStyle ? head.map((_, index) => index) : []

  label = label || null
  filter = filter || null

  const rows = namedRows
    ? Object.keys(body).map((key, index_) => {
      const values = body[key]

      return <tr key={"row_" + key}>
        <td><LineIdContainer index={index_}>{key}</LineIdContainer></td>
        {Object.keys(values).map((prop, index) => {
          const value = values[prop]
          const column = head[index]

          return <td style={bodyStyleIndexes.includes(index) ? bodyStyle : null} key={"column_" + prop}>
            <BodyContainer line={key} column={column}>
              {value}
            </BodyContainer>
          </td>
        })}
      </tr>
    })
    : body.map((_, index) => <tr key={"row_" + index}>
      {body[index].map((value, index) => <td style={bodyStyleIndexes.includes(index) ? bodyStyle : null} key={"column_" + index}><BodyContainer index={index} line={value} column={head[index]}>{value}</BodyContainer></td>)}
    </tr>)

  if (namedRows) {
    head = ["", ...head]
  }

  const basicComponent = <>
    {filter ? getFilter(filter) : null}
    <table key={"table"} {...props} className={props.className || "table-template"}>
      <thead key={"thead"}>
        <tr key={"row"}>
          {head.map((value, index) => <th style={headStyleIndexes.includes(index) ? headStyle : null} key={"head_" + index}><HeadContainer>{typeof value === "string" ? value.toUpperCase() : value}</HeadContainer></th>)}
        </tr>
      </thead>
      <tbody key={"tbody"}>
        {rows}
      </tbody>
    </table>
  </>

  const renderComponent = label
    ? <label className={`Lighter`}>
      {label}
      {basicComponent}
    </label>
    : basicComponent

  return renderComponent
}
