import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { getStrings } from "../../../../utils/LocaleUtils";
import { log } from "../../../../utils/LogUtils";
import { areSelectInputValuesEqual, areTextInputValuesEqual } from "../../../../utils/ObjectUtils";
import { reduxDispatch } from "../../../../utils/reduxUtils/reduxUtils";
import { blurAll } from "../../../../utils/ScreenUtils";
import { cadastroBuildJSONFromOptions } from "../../../../utils/SelectUtils";
// import { recaptcha_site_key } from "../../../../utils/SecureConnectionUtils";

import { STATE_SIGN_UP_BEGIN, STATE_SIGN_UP_FAILURE, STATE_SIGN_UP_SUCCESS } from "../../../../store/reducers/signUpReducer";
import * as appActions from "../../../../store/actions/appAction";
import * as authActions from "../../../../store/actions/authAction";
import * as signUpActions from "../../../../store/actions/signUpAction";
import * as usuarioActions from "../../../../store/actions/usuarioAction";

import BarraAcoesForm, { SUBMIT_ROLE_REGISTER_USER } from "../../../cadastros/barraAcao/BarraAcoesForm";
import HelpParagraph from "../../../UI/HelpParagraph/HelpParagraph";
// import LinkButton from "../../../UI/Button/LinkButton";
import SignUpHeader from "../SignUpHeader";
import SignUpUserForm from "../SignUpUserForm";
import WidthAwareDiv from "../../../UI/WidthAwareDiv/WidthAwareDiv";

import "../../../../assets/css/components.css";
import "./SignUpUser.css";

/* 
 * Necessário para o Edge, pelo menos até sair o próximo update
 * https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8993198/
 */
var URLSearchParams = require("url-search-params");

// let Recaptcha = require("react-recaptcha");

/**
 * Componente de Cadastro de Usuário.
 */
class SignUpUser extends React.Component {

  /**
   * Trata atualizações em campos de seleção.
   * @param {String[]} keyArray
   * @param {*} valueRaw
   * @param {*} valueFormatted
   */
  handleBirthdayInput = (keyArray, valueRaw, valueFormatted) => {
    log("SignUpUser handleBirthdayInput", { keyArray, valueRaw, valueFormatted });
    // Busca os dados do reducer
    let stateData = this.props.formData;
      // Busca o valor específico
      (keyArray || []).forEach(key => stateData = (stateData || {})[key]);
    // Se valor for igual ao que está no reducer, faz mais nada.
    if (areTextInputValuesEqual(valueFormatted, stateData)) {
      return;
    }
    // Armazena no reducer valor contido na seleção
    this.props.updateFormData(keyArray, valueFormatted);
    // Armazena seleção no reducer
    keyArray[keyArray.length - 1] += "Option";
    this.props.updateFormData(keyArray, valueRaw);
  }

  /**
   * Trata atualizações em campos de seleção.
   * @param {String[]} keyArray
   * @param {*} value
   */
  handleEntityInput = (keyArray, value) => {
    log("SignUpUser handleEntityInput", { keyArray, value })
    // Busca os dados do reducer
    let stateData = this.props.formData;
    // Busca o valor específico
    (keyArray || []).forEach(key => stateData = (stateData || {})[key]);
    // Se valor for igual ao que está no reducer, faz mais nada.
    if (areSelectInputValuesEqual(value, stateData || "")) {
      return
    }
    // Armazena no reducer valor contido na seleção
    this.props.updateFormData(keyArray, cadastroBuildJSONFromOptions(false, value));
    // Armazena seleção no reducer
    keyArray[keyArray.length - 1] += "Option";
    this.props.updateFormData(keyArray, value);
  }

  /**
   * Trata atualizações em campos de texto.
   * @param {String[]} keyArray
   * @param {*} value
   */
  handleTextInput = (keyArray, value) => {
    log("SignUpUser handleTextInput", { keyArray, value });
    // Busca os dados do reducer
    let stateData = this.props.formData;
    // Busca o valor específico
    (keyArray || []).forEach(key => stateData = (stateData || {})[key]);
    // Se valor for igual ao que está no reducer, faz mais nada.
    if (areTextInputValuesEqual(value, stateData || "")) {
      return;
    }
    // Armazena valor no reducer
    this.props.updateFormData(keyArray, value);
  }

  /**
   * Método executando pelo botão de envio de dados. 
   */
  submitHandler = (event, resendEmail) => {
    log("SignUpUser submitHandler")
    if (event) {
      Object.keys(event).forEach(key => log("SignUpUser submitHandler", key, event[key]));

      // Impede que o form redirecione para outra URL
      event.preventDefault();
    }

    blurAll();

    const f3 = (this.props.location.state || {}).f3;

    let usuario = this.props.formData;

    if (f3) {
      delete usuario.login.senha;
      delete usuario.login.this.confirmacaoSenha;
    }

    this.persistedEmail = usuario.login.email;

    // Se página foi aberta por link recebido em email enviado pelo cadastro de Cargo,
    // atualiza os dados do usuário.
    if (this.novoUsuarioCargo) {
      this.props.updateUsuario(usuario, this.novoUsuarioCargo, this.props.persistedEmail);
    } else {
      let f3User = undefined;

      if (f3) {
        f3User = { userId: f3.user_id, facebookToken: f3.access_token };
      }

      this.props.signUpSubmit(usuario, f3User, resendEmail);
    }
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  componentDidMount() {
    log("SignUpUser componentDidMount");

    // Inicializa a máquina de estados
    this.props.setValueSignUp(STATE_SIGN_UP_BEGIN, "state");

    // Se página foi aberta por link recebido em email enviado pelo cadastro de Cargo,
    // confirma o usuário vinculado ao token recebido.
    // A página será usada para que o novo usuário termine o seu cadastro.
    if (this.novoUsuarioCargo) {
      this.props.emailConfirmationSubmit(this.novoUsuarioCargo);
    }

    if (this.aceitarNotificacoes?.inputComponent) {
      this.aceitarNotificacoes.inputComponent.checked = true;
    }
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   * Necessário ao definir `getSnapshotBeforeUpdate`.
   */
  componentDidUpdate(prevProps) {
    log("SignUpUser componentDidUpdate");

    if (this.props.state === STATE_SIGN_UP_SUCCESS) {
      const login = { email: this.props.formData.login.email, senha: this.props.formData.login.senha };
      this.props.authenticationSubmit(login);
      return;
    }

    // Se entrou nesta tela por tentativa de login, recupera o email e senha digitados.
    if ((this.props.temp || {}).email && this.email && this.senha) {
      this.email.inputComponent.value = this.props.temp.email
      this.senha.inputComponent.value = this.props.temp.token
      this.props.setValueAuth(null, "temp")
      this.props.updateFormData(["login", "email"], this.props.temp.email)
      this.props.updateFormData(["login", "senha"], this.props.temp.token)
    }

    let f3 = (this.props.location.state || {}).f3

    // Quando for cadastro por Facebook,
    if (f3) {
      // atribui campos falsos para ler valores que serão fornecidos pelo mesmo
      this.email = (f3 || {}).email
        ? this.simulateInputComponent(f3.email)
        : this.email

      this.nome = (f3 || {}).first_name
        ? this.simulateInputComponent(f3.first_name)
        : this.nome

      this.sobrenome = (f3 || {}).last_name
        ? this.simulateInputComponent(f3.last_name)
        : this.sobrenome
    }
    // Senão, se antes era cadastro por Facebook,
    else if ((prevProps.location.state || {}).f3) {
      // limpa os campos
      this.email.inputComponent.value = ""
      this.senha.inputComponent.value = ""
      this.confirmacaoSenha.inputComponent.value = ""
      this.nome.inputComponent.value = ""
      this.sobrenome.inputComponent.value = ""
    }
  }

  /**
   * Método executado ANTES de "DESMONTAR" o componente.
   * Invalida o *reCaptchaToken* do reCAPTCHA.
   */
  componentWillUnmount() {
    log("SignUpUser componentWillUnmount");
    // Limpa o reducer
    this.props.clear();
  }

  /**
   * Método executado ANTES de "MONTAR" o componente.
   * Verifica se mudou o idioma para recarregar a tela.
   * Isto é feito porque há um [problema](https://github.com/appleboy/react-recaptcha/issues/204) na biblioteca `react-recaptcha`: mesmo chamando `grecaptcha.reset()`, o idioma do componente não altera.
   */
  getSnapshotBeforeUpdate(prevProps, prevState) {
    log("SignUpUser getSnapshotBeforeUpdate", { prevProps, prevState })
    if (prevProps.locale !== this.props.locale) {
      window.location.reload();
    }
    return null;
  }

  register = () => {
    log("SignUpUser register");

    const f3 = (this.props.location.state || {}).f3;

    // Busca o token na querystring
    const query = new URLSearchParams(this.props.location.search);
    this.novoUsuarioCargo = query.get("token");

    return <div className="tela-sign-up-user">
      {/* {f3 ? <button style={{
        fontSize: "2vw",
        fontWeight: "bold",
        backgroundColor: "whitesmoke",
        color: "black",
        border: "1px solid darkgray",
        borderRadius: "2px",
        padding: "4px 6px",
      }} onClick={() => goBack("SignUpUser")}>
        {getStrings().f3Fallback}
      </button>
        : <F3SignUpComponent
          label={getStrings().f3SignUp}
          app_id={this.props.fbAppDataReducer.appId}
          version={this.props.fbAppDataReducer.version}
          getFields={response => {
            if ((response || {}).status === "connected") {
              this.props.goTo("signUpUser", { f3: response, })
            }
          }}
        />
      } */}
      <form className="sub-form" onSubmit={() => { }}>
        <SignUpUserForm
          f3={f3}
          step={this.props.step}
          novoUsuarioCargo={this.novoUsuarioCargo}
          handleBirthdayInput={this.handleBirthdayInput}
          handleEntityInput={this.handleEntityInput}
          handleTextInput={this.handleTextInput}
          onCountriesLoaded={(options) => {
            const pais = options.find((value) => value.labelKey === "BR")
            this.pais.inputComponent.updateValue(pais, "iso")
            this.handleEntityInput(["dadosAdicionais", "pais"], pais)
          }}
        />

      </form>
    </div >;
  }

  simulateInputComponent = (value) => ({ inputComponent: { value: value }, });

  /**
   * Método que monta o componente.
   */
  render() {
    log("SignUpUser render")

    return <div className="pure-u-1-1">
      <SignUpHeader
        history={this.props.history}
      />

      <WidthAwareDiv>
        <HelpParagraph children={getStrings().signUpUserHelp(this.novoUsuarioCargo)} />
      </WidthAwareDiv>

      <>
        {(() => {
          switch (this.props.state) {
            // case STATE_SIGN_UP_SUCCESS:
            //     return <WidthAwareDiv>
            //         <SignUpConfirmation email={this.persistedEmail} senha={this.props.formData.login.senha} submitHandler={this.submitHandler} />
            //     </WidthAwareDiv>
            case STATE_SIGN_UP_BEGIN:
            case STATE_SIGN_UP_FAILURE:
              return <>
                <BarraAcoesForm
                  addButtonDisabled={!this.props.formRequiredFilled}
                  attractSubmit={this.props.formRequiredFilled}
                  submitRole={SUBMIT_ROLE_REGISTER_USER}
                  handleListar={() => this.props.goTo("/signUp")}
                  handleSubmit={() => {
                    if (this.props.step === 0) {
                      reduxDispatch(signUpActions.updateUsuarioStep(1))
                    }
                    else {
                      if (!this.novoUsuarioCargo) {
                        this.props.appCloseTour()
                      }
                      this.submitHandler()
                    }
                  }}
                />
                <WidthAwareDiv>
                  {this.register()}
                </WidthAwareDiv>
              </>
            default:
              return <BarraAcoesForm
                addButtonDisabled={!this.props.formRequiredFilled}
                attractSubmit={this.props.formRequiredFilled}
                submitRole={SUBMIT_ROLE_REGISTER_USER}
                handleListar={() => this.props.goTo("/signUp")}
                handleSubmit={() => {
                  if (!this.novoUsuarioCargo) {
                    this.props.appCloseTour()
                  }
                  this.submitHandler()
                }}
              />
          }
        })()}
      </>
    </div>;
  }
}

/**
 * Mapeia as propriedades do estado global para utilizar localmente.
* @param {*} state
        */
function mapStateToProps(state) {
  const props = {
    fbAppDataReducer: state.fbAppDataReducer,
    ...state.idiomaReducer,
    state: state.signUpReducer.state,
    tabIndex: state.appReducer.getTabIndex(),
    formData: state.signUpReducer.formData,
    formRequiredFilled: state.signUpReducer.formRequiredFilled,
    password_score: state.signUpReducer.password_score,
    password_suggestions: state.signUpReducer.password_suggestions,
    password_warning: state.signUpReducer.password_warning,
    persistedEmail: state.authReducer.persistedEmail,
    sign_up_success: state.signUpReducer.sign_up_success,
    step: state.signUpReducer.step,
    temp: state.authReducer.temp,
  };

  return props;
}

/**
 * Mapeia as ações para utilizar localmente.
* @param {*} dispatch
*/
function mapDispatchToProps(dispatch) {
  const props = {
    appCloseTour: () => dispatch(appActions.appCloseTour()),
    authenticationSubmit: login => dispatch(authActions.authenticationSubmit(login)),
    clear: () => dispatch(signUpActions.clear()),
    emailConfirmationSubmit: token => dispatch(authActions.emailConfirmationSubmit(token)),
    goTo: (path, state) => dispatch(push(path, state)),
    setToken: reCaptchaToken => dispatch(signUpActions.setToken(reCaptchaToken)),
    setValueAuth: (value, position) => dispatch(authActions.setValue(value, position)),
    setValueSignUp: (value, position) => dispatch(signUpActions.setValue(value, position)),
    signUpSubmit: (usuario, login) => dispatch(signUpActions.signUpSubmit(usuario, login)),
    setScore: score => dispatch(signUpActions.setScore(score)),
    setWarning: warning => dispatch(signUpActions.setWarning(warning)),
    updateUsuario: (usuario, token, email) => dispatch(usuarioActions.updateUsuario(usuario, { token, email })),
    updateFormData: (keyArray, value) => dispatch(signUpActions.updateFormData(keyArray, value)),
  };

  return props;
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpUser);
