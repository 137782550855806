import { produtosTipo, produtoTipo } from "./produtosReducer";
import criarAction from "../../factory/criarAction";

const tiposDeAcoes = {
  ADICIONAR_PRODUTO: 'ADICIONAR_PRODUTO:PRODUTO',
  ATUALIZAR_PRODUTO: 'ATUALIZAR_PRODUTO:PRODUTO',
  ATUALIZAR_MULTIPLOS_PRODUTOS: 'ATUALIZAR_MULTIPLOS_PRODUTOS:PRODUTO',
  POPULAR_PRODUTOS: 'POPULAR_PRODUTOS:PRODUTO',
  REMOVER_PRODUTO: 'REMOVER_PRODUTO:PRODUTO',
};

export default tiposDeAcoes;

export const atualizarProduto = (carga: {id: string, produto: produtoTipo}) => function(dispatch: any) {
  dispatch(criarAction(carga, tiposDeAcoes.ATUALIZAR_PRODUTO));
};

export const atualizarMultiplosProduto = ({produtos}: {produtos: produtosTipo}) => function(dispatch: any) {
  dispatch(criarAction({prods: produtos}, tiposDeAcoes.ATUALIZAR_MULTIPLOS_PRODUTOS));
};

export const descontarEstoqueProduto = ({id, produto, quantidadeComprada}: {id: string, produto: produtoTipo, quantidadeComprada: number}) => function(dispatch: any) {
  produto.quantidadeEmEstoque -= quantidadeComprada;
  atualizarProduto({id, produto});
};

export const popularProdutos = (carga: {produtos: produtosTipo}) => function(dispatch: any) {
  dispatch(criarAction(carga, tiposDeAcoes.POPULAR_PRODUTOS));
};
