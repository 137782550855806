import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"

import { ClienteHttp } from "../../../apps/promo_app_frontend/nucleo/utils/utils"
import { getStrings } from "../../../utils/LocaleUtils"

import ManutencaoVenda from "../../vendas/manutencao/ManutencaoVenda"
import { pedidosTelaEstadoTipo } from "./PedidosTela"
import ToolbarTemplate from "../../UI/Template/ToolbarTemplate"

import { PRODUCAO } from "../../../store/actions/actionTypes"
import {
  ORDEM_ASCENDENTE
  , PARAMETRO_BUSCA_FILTRO
  , PARAMETRO_BUSCA_ORDENACAO
  , PARAMETRO_BUSCA_PAGINACAO
  , TELA_PEDIDO_ATUAL
  , CODIGO_ORDENACAO_PEDIDO
} from "../../../store/reducers/producaoReducer"
import { appDialogHide, appDialogShow } from "../../../store/actions/appAction"
import { ESTADO_ITEM_VENDA_ESPERANDO_ACEITAR, TIPO_ORIGEM_VENDA_CARTAO, TIPO_ORIGEM_VENDA_COLETOR, TIPO_ORIGEM_VENDA_MESA, TIPO_ORIGEM_VENDA_PAINEL } from "../../../store/reducers/controleVenda/controleVendaReducer"
import { loadOrigensLivres, loadAndSetOrigemPedidos } from "../../../store/actions/controleVenda/controleVendaAction"
import { atualizarTelaAtual, loadItensVendaAbertos } from "../../../store/actions/producaoAction"

import { Texto } from "../../../apps/promo_app_frontend/nucleo/nucleo"
import ReceberPedidosTela from "./ReceberPedidosTela"

import "./PedidosToolbar.css";
import "./VendaTela.css";

import { RamoEmpresa } from "../../../store/actions/empresaSelectorAction"

export interface VendaTelaTipo {
  atualizarEstado: (novoEstado: any) => void | undefined
  atualizarHoraEntrega: () => void
  buscarEstado: () => pedidosTelaEstadoTipo
  estadoInicial: pedidosTelaEstadoTipo
  goBack: () => (void | undefined)
  naoCarregarProdutos?: boolean
};

interface VendaTelaTipoWithReducer extends VendaTelaTipo {
  appDialogHide: () => void
  dispatch: (carga: any) => any
  goTo: (path: string, state?: Record<string, any>) => void
  ramoEmpresa: string
  producaoReducer: any
  tabIndex: number
  origemVenda: any
}

interface VendaTelaStateTipo {
  produtos: any
  pedido: boolean
}

class VendaTela extends React.Component<VendaTelaTipoWithReducer> {
  state: VendaTelaStateTipo = {
    produtos: {},
    pedido: true,
  };

  selectRamoPanel = () => {
    let tipoOrigem = TIPO_ORIGEM_VENDA_MESA

    switch (this.props.ramoEmpresa) {
      case RamoEmpresa.RESTAURANTE_A_KILO:
        tipoOrigem = TIPO_ORIGEM_VENDA_CARTAO
      // eslint-disable-next-line
      case RamoEmpresa.RESTAURANTE_A_LA_CARTE:
        this.props.dispatch(loadOrigensLivres(false, () => {
          this.props.atualizarEstado({ tipoOrigem });
        }));

        if (this.state.pedido)
          this.setState({ pedido: false });

        return 
      case RamoEmpresa.PAINEL:
        this.props.dispatch(loadAndSetOrigemPedidos(TIPO_ORIGEM_VENDA_PAINEL, () => {
          this.props.atualizarEstado({ tipoOrigem: TIPO_ORIGEM_VENDA_PAINEL });
        }));
        
        if (!this.state.pedido)
          this.setState({ pedido: true });

        return
    }
  }

  componentDidMount() {

    this.props.dispatch(atualizarTelaAtual(TELA_PEDIDO_ATUAL));

    const { requisicaoServidor } = ClienteHttp;
    const cargoId = (JSON.parse(sessionStorage.getItem("c") || "{}")["c"] || "").replace(ClienteHttp.getApi("cargos/"), "");

    requisicaoServidor(`origemVendas/getTipoOrigemsContexted`, "get", true, { params: { cargoId } })
      .then((resposta: any) => {
        const tipoOrigemVendas = resposta.data;
    
        this.props.atualizarEstado({ tipoOrigemVendas });
      })

    this.props.dispatch({
      type: PRODUCAO
      , visible: true
      , [TELA_PEDIDO_ATUAL]: {
        [PARAMETRO_BUSCA_ORDENACAO]: {
          ...this.props.producaoReducer[TELA_PEDIDO_ATUAL][PARAMETRO_BUSCA_ORDENACAO]
          , ordenacao: CODIGO_ORDENACAO_PEDIDO
          , ordem: ORDEM_ASCENDENTE
        }
        , [PARAMETRO_BUSCA_FILTRO]: {
          ...this.props.producaoReducer[TELA_PEDIDO_ATUAL][PARAMETRO_BUSCA_FILTRO]
          , estadoItemVenda: ESTADO_ITEM_VENDA_ESPERANDO_ACEITAR
        }
        , [PARAMETRO_BUSCA_PAGINACAO]: {
          ...this.props.producaoReducer[TELA_PEDIDO_ATUAL].page
        }
      }
    });

    this.props.dispatch(loadItensVendaAbertos());
    //this.selectRamoPanel();
  }

  componentDidUpdate(prevProps: VendaTelaTipoWithReducer) {
    if (prevProps) {
      if (prevProps.origemVenda !== this.props.origemVenda) {
        this.props.atualizarEstado({ tipoOrigem: this.props.origemVenda.tipoOrigemVenda });
      }
    }
  }

  render() {
    const estado = this.props.buscarEstado();

    return <>
      <ToolbarTemplate awareDivStyle={{ whiteSpace: "nowrap", overflowX: "auto", width: "100%" }} toolbarClassName="pedidos-origem-toolbar">
        {estado.tipoOrigemVendas.map((tipoOrigem: string) => {
          if (tipoOrigem === TIPO_ORIGEM_VENDA_COLETOR)
            return <></>
          const producaoReducer = this.props.producaoReducer;
          const pedidosEsperandoAceitar = producaoReducer.vendaList.filter((venda: any) => venda.origemVenda.tipoOrigemVenda === tipoOrigem && venda.estadoItensVenda === ESTADO_ITEM_VENDA_ESPERANDO_ACEITAR);
          const numeroDePedidosEsperandoAceitar = pedidosEsperandoAceitar.length;

          const tipoOrigemAtual = estado.tipoOrigem;
          const setAllDisabled = estado.setAllDisabled;
          const desabilitar = setAllDisabled || (tipoOrigemAtual === tipoOrigem);

          return <button
            tabIndex={this.props.tabIndex}
            key={`tipo-origem-${tipoOrigem}`}
            id={`tipo-origem-${tipoOrigem}-button`}
            className={`blue-button font-color botao-tipo-origem-venda-pedidos-tela ${tipoOrigemAtual === tipoOrigem ? " origem-venda-selecionada" : ""} ${(numeroDePedidosEsperandoAceitar) ? " blink_me" : ""}`}
            children={<>
              {getStrings().saleSourceTypeEnumToString(tipoOrigem)}
              {numeroDePedidosEsperandoAceitar ? <button style={
                {
                  marginLeft: "0.4em",
                  display: "flex",
                  width: "1.8em",
                  height: "1.2em",
                  background: "yellow",
                  color: "black",
                  alignSelf: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }
              }>{numeroDePedidosEsperandoAceitar}</button> : null}
            </>}
            title={`${getStrings().saleSourceString}: ${getStrings().saleSourceTypeEnumToString(tipoOrigem)}`}
            disabled={desabilitar}
            onClick={() => {
              if (numeroDePedidosEsperandoAceitar) {
                const estado = this.props.buscarEstado();

                this.props.dispatch(
                  appDialogShow(
                    <ReceberPedidosTela
                      estado={estado}
                      atualizarEstado={this.props.atualizarEstado}
                      buscarEstado={this.props.buscarEstado}
                      tipoOrigem={tipoOrigem}
                    />,
                    getStrings().receiveOrdersScreenTitle(tipoOrigem),
                    undefined,
                    undefined,
                    () => { },
                    () => { },
                    {
                      width: "100%"
                      , overflow: "unset"
                    }
                  )
                );

                return;
              }

              const notAskForIntegrate = JSON.parse(localStorage.getItem("notAskForIntegrate") || `{}`)

              if (["Ifood", "Delivery Much"].includes(tipoOrigem) && !notAskForIntegrate[tipoOrigem]) {
                this.props.dispatch(appDialogShow(<div className="flex flex-column flex-nowrap flex-align-center no-padding">
                  <div className="flex flex-row flex-wrap no-padding">
                    <button className={"button-white"} onClick={() => {
                      this.props.appDialogHide();
                      this.props.goTo(`empresas?tipoOrigem=${tipoOrigem}`);

                    }}>{getStrings().yes}</button>
                    <button className={"button-white"} onClick={() => { this.props.dispatch(appDialogHide()); }}>{getStrings().no}</button>
                  </div>
                  <div className="flex flex-row flex-wrap flex-align-center no-padding">
                    <button className={"button-white"}
                      onClick={() => {
                        localStorage.setItem("notAskForIntegrate", JSON.stringify({
                          ...notAskForIntegrate,
                          [tipoOrigem]: true,
                        })); this.props.dispatch(appDialogHide());
                      }}>
                      {getStrings().dontAskMeAgain}
                    </button>
                  </div>
                </div>, getStrings().doYouWishToIntegrate(tipoOrigem)))
              }

              //this.props.atualizarEstado({ tipoOrigem });

              if (["Balcão", "Mesa", "Cartão"].includes(tipoOrigem)) {
                this.props.dispatch(loadOrigensLivres());

                if (this.state.pedido)
                  this.setState({ pedido: false });
              }
              else {
                this.props.dispatch(loadAndSetOrigemPedidos(tipoOrigem));

                if (!this.state.pedido)
                  this.setState({ pedido: true });
              }
            }}
          />
        })}
      </ToolbarTemplate>
      {estado.tipoOrigem
        ? <ManutencaoVenda {...{ pedido: this.state.pedido, estadoInicialEstadoPedidos: this.props.estadoInicial, esconderBarraAcoes: true, naoCarregarProdutos: estado.naoCarregarProdutos, esconderBotaoVoltar: true, atualizarEstado: this.props.atualizarEstado, buscarEstado: this.props.buscarEstado, customGoBack: this.props.goBack, }} />
        : <Texto className="nenhuma-origem-venda-tela-pedidos">
          {getStrings().noSaleSourceSelectedMessage}
        </Texto>
      }
    </>;
  }
}

function mapStateToProps(state: any) {
  const props = {
    ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa
    , producaoReducer: state.producaoReducer
    , tabIndex: state.appReducer.getTabIndex()
    , origemVenda: state.controleVendaReducer.origemVendaList[0],
  };

  return props;
};

function mapDispatchToProps(dispatch: (carga: any) => any) {
  const props = {
    appDialogHide: () => dispatch(appDialogHide()),
    dispatch,
    goTo: (path: string, state?: Record<string, any>) => dispatch(push(path, state)),
  };

  return props;
};

export default connect(mapStateToProps, mapDispatchToProps)(VendaTela);
