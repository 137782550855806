import React from "react"
import { withStyles } from "@material-ui/core/styles"

import { connect } from "react-redux"
import { getStrings } from "../../../utils/LocaleUtils"

import * as appActions from "../../../store/actions/appAction"

import MaterialUIButton from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Snackbar from "@material-ui/core/Snackbar"
import CloseIcon from "@material-ui/icons/Close"

const styles = theme => ({
  snackbar: {
  },
  undoButton: {
    fontWeight: "bold"
  },
  close: {
    width: theme.spacing.unit * 6,
    // height: theme.spacing.unit * 4,
  }
})

class SnackbarSystem extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <section className='snackbar-wrapper'>
        <Snackbar
          id="snackbar"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          className={classes.snackbar}
          open={this.props.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.props.appSnackbarClear}
          message={this.props.snackbarMessage}
          action={(this.props.snackbarUndoAction ? [
            <MaterialUIButton
              className={classes.undoButton}
              key='undo'
              color='inherit'
              size='small'
              onClick={() => {
                this.props.appSnackbarClear();
                this.props.snackbarUndoAction();
              }}>
              {getStrings().undo}
            </MaterialUIButton>
          ] : []).concat([
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={this.props.appSnackbarClear}>
              <CloseIcon />
            </IconButton>
          ])}
        />
      </section>
    );
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {
  return {
    snackbarMessage: state.appReducer.snackbarMessage,
    snackbarOpen: state.appReducer.snackbarOpen,
    snackbarUndoAction: state.appReducer.snackbarUndoAction
  };
};

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
  return {
    appSnackbarClear: () => dispatch(appActions.appSnackbarClear())
  };
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SnackbarSystem));