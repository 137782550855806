import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"

/**
 * Classe que monta a dialog para exibir a descrição do produto.
 */
class DialogDescricao extends React.Component {

  /**
   * Método que monta o componente.
   */
  render() {

    // Monta o componente
    return (
      <>
        <textarea id="observacao" name="observacao" maxLength="300" ref={(textarea) => { this.textArea = textarea; }}
          placeholder={getStrings().itemObservation} style={{ resize: "none", height: "60%" }} value={this.props.observacao} readOnly={true} />
      </>
    )
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {

  return {
    ...state.idiomaReducer
  }
}

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, null, null, { forwardRef: true })(DialogDescricao)
