import React from 'react';
import { connect } from 'react-redux';

import { isEmpresaPainel as util_isEmpresaPainel } from "../../utils/CompanyUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { listToFragment } from "../../utils/ScreenUtils"

// Chaves das colunas
import {
    ITEM_VENDA_ESTADO,
    ITEM_VENDA_HORA,
    ITEM_VENDA_ORIGEM,
    ITEM_VENDA_PRODUTO,
    ITEM_VENDA_VENDA
} from './RelatorioBox';

const mapCellData = {
    [ITEM_VENDA_PRODUTO]: (props) => <td className='Lighter TextAlign' width='25%' >{props.dadosMap[ITEM_VENDA_PRODUTO]}</td>,
    [ITEM_VENDA_VENDA]: ({isEmpresaPainel, ...props}) => <td className={`Lighter ${isEmpresaPainel ? 'NumberAlign' : 'TextAlign'}`} width='20%' >{props.dadosMap[ITEM_VENDA_VENDA]}</td>,
    [ITEM_VENDA_ORIGEM]: (props) => <td className='Lighter TextAlign' width='20%' >{props.dadosMap[ITEM_VENDA_ORIGEM]}</td>,
    [ITEM_VENDA_ESTADO]: (props) => <td className='Lighter TextAlign' width='25%' >{getStrings().saleItemStateEnumToString(props.dadosMap[ITEM_VENDA_ESTADO])}</td>,
    [ITEM_VENDA_HORA]: (props) => <td className='Lighter ButtonAlign' width='10%' >{props.dadosMap[ITEM_VENDA_HORA]}</td>,
}

/**
 * Classe que realiza a montagem das linhas da tabela.
 */
class MovimentoTableRow extends React.Component {

    /**
     * Monta cada célula de acordo com a chave da coluna.
     */
    cellData = column => {
        log('MovimentoTableRow cellData', column);

        const cellFunctionComponent = (mapCellData[column] || (() => null))
        const cellComponent = cellFunctionComponent({dadosMap: this.props.dadosMap, isEmpresaPainel: this.isEmpresaPainel()})
        return cellComponent
    }

    isEmpresaPainel = () => util_isEmpresaPainel(this.props.ramoEmpresa);

    /**
     * Método que executa a montagem do componente.
     */
    render() {
        log('MovimentoTableRow render');

        // Se não recebeu as colunas por parâmetro, não pode montar a linha da tabela
        if (!this.props.columnOrder) {
            return null;
        }

        return <tr>
            {/* Dados do relatório */}
            {listToFragment(this.props.columnOrder.map(column => this.cellData(column)))}
        </tr>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    ...state.relatorioReducer,
    ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(MovimentoTableRow);
