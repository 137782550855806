import React from "react";
import styled from "styled-components";
import { TitleSub, Paragraph, ConteudoSection } from ".";
import { PATH_IMAGENS_FOTOS, PATH_IMAGENS_PRINTS } from "../utils/resources";
import { ImagensContainer, PrintTablet, ImgRounded } from "./Containers";

const Relatorios = React.forwardRef((props, ref) => {
  return (
    <ConteudoSection ref={ref}>
      <TitleSub primary>Relatórios em tempo real detalhados</TitleSub>
      <Paragraph>Relatórios Gerenciais de fechamento e estatísticas impresso, pdf, e-mail...</Paragraph>
      <ImagensContainer height={"310px"}>
        <Print src={PATH_IMAGENS_PRINTS + "relatorios1.png"} alt={"relatorios"} />
        <Print src={PATH_IMAGENS_PRINTS + "relatorios2.png"} alt={"relatorios2"} />
        <ImgRoundedHighFive src={PATH_IMAGENS_FOTOS + "relatorios3.png"} alt={"Pessoa usando mobi"} />
        <ImgRoundedRelatorio src={PATH_IMAGENS_FOTOS + "relatorios4.png"} alt={"Tela instagram"} />
      </ImagensContainer>
    </ConteudoSection>
  );
});

const Print = styled(PrintTablet)`
  position: absolute;
  &:nth-child(1) {
    top: 0;
    right: 0;
    @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
      z-index: 9;
    }
  }
  &:nth-child(2) {
    left: 0;
    bottom: 0;
  }
  z-index: 10;
`;

const ImgRoundedRelatorio = styled(ImgRounded)`
  right: 0;
  bottom: 0;
  z-index: 9;
  @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    z-index: 10;
  }
`;

const ImgRoundedHighFive = styled(ImgRounded)`
  left: 0;
  top: 0;
  z-index: 11;
`;

export default Relatorios;
