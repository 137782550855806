import React from 'react';

import { getStrings } from '../../../utils/LocaleUtils';

import CadastroTable from './CadastroTable';
import ObjetoTableModel from './ObjetoTableModel';

/**
 * Classe que repassa para o CadastroTable as informações para a montagem da tabela dos Clientes.
 */
export default class ClienteTable extends React.Component {
    render() {
        return <CadastroTable {...this.props} objetoCadastro='cliente' sortProperty={'nome'} filterProperties={['nome', 'codigo']} tableModel={ObjetoTableModel}
            blankFormData={{
                enabled: true,
                codigo: '',
                nome: '',
                identificador: ''
            }} registerHelp={getStrings().clientTableHelp}
        />;
    }
}
