import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from "../../utils/LocaleUtils"
import { formatNumber } from "../../utils/NumberUtils"
import { listToFragment, updateActiveMenuItem } from "../../utils/ScreenUtils"

import FechamentoTableRow from './FechamentoTableRow';

// Chaves das colunas
import RelatorioBox, {
    PAGAMENTO_VENDA_ORIGEM,
    PAGAMENTO_VENDA_VALOR_PRODUTOS,
    PAGAMENTO_VENDA_VALOR_COMISSAO,
    PAGAMENTO_VENDA_VALOR_TOTAL,
    PAGAMENTO_VENDA_VENDA
} from './RelatorioBox';

import * as relatorioActions from '../../store/actions/relatorioAction';

/**
 * Monta a tabela de relatório de fechamento de caixa.
 */
class FechamentoBox extends React.Component {

    /**
     * Lista de chaves de coluna na ordenação inicial.
     */
    columnsInitial = [
        PAGAMENTO_VENDA_ORIGEM,
        PAGAMENTO_VENDA_VENDA,
        PAGAMENTO_VENDA_VALOR_PRODUTOS,
        PAGAMENTO_VENDA_VALOR_COMISSAO,
        PAGAMENTO_VENDA_VALOR_TOTAL
    ];

    /**
     * Retorna o texto do cabeçalho de cada coluna de acordo com a chave da coluna.
     */
    columnHeader = column => {
        switch (column) {
            case PAGAMENTO_VENDA_ORIGEM:
                return getStrings().saleSourceShort();
            case PAGAMENTO_VENDA_VENDA:
                return getStrings().sale();
            case PAGAMENTO_VENDA_VALOR_PRODUTOS:
                return getStrings().productValue;
            case PAGAMENTO_VENDA_VALOR_COMISSAO:
                return getStrings().commissionValue;
            case PAGAMENTO_VENDA_VALOR_TOTAL:
                return getStrings().totalPaid;
            default:
                return '';
        }
    }

    /**
     * Retorna o valor do rodapé (totais) de cada coluna de acordo com a chave da coluna.
     */
    columnFooter = column => {

        if (!this.props.dados.dadosMap) {
            return null;
        }

        switch (column) {
            case PAGAMENTO_VENDA_ORIGEM:
                return '';
            case PAGAMENTO_VENDA_VENDA:
                return '';
            case PAGAMENTO_VENDA_VALOR_PRODUTOS:
                return formatNumber(this.props.dados.dadosMap[PAGAMENTO_VENDA_VALOR_PRODUTOS] || 0, 2);
            case PAGAMENTO_VENDA_VALOR_COMISSAO:
                return formatNumber(this.props.dados.dadosMap[PAGAMENTO_VENDA_VALOR_COMISSAO] || 0, 2);
            case PAGAMENTO_VENDA_VALOR_TOTAL:
                return formatNumber(this.props.dados.dadosMap[PAGAMENTO_VENDA_VALOR_TOTAL] || 0, 2);
            default:
                return '';
        }
    }

    componentDidMount() {

        updateActiveMenuItem('menuRelatorioFechamento', 'menuRelatorios');

        // Método usado no início da implementação do sistema de relatórios genéricos. Investigar necessidade ao substituir sistema de relatórios.
        // this.props.getRelatorio('fechamento');

        // Inicializa as opções da geração de relatório
        this.props.sortData({ dadosList: [] }, { report: 'fechamento' }, this.columnsInitial, {});
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {

        /**
         * Lista de linhas da tabela.
         */
        let list = null;

        /**
         * Rodapé (totais) da tabela.
         */
        let footer = null;

        /**
         * Lista de chaves de coluna na ordenação personalidada, se existir, ou inicial, se não
         */
        let columnOrder = this.props.columnOrder || this.columnsInitial;

        // Formata os dados do servidor, se existirem
        if (this.props.dados && ('dadosList' in this.props.dados)) {

            // Formata cada linha da tabela
            list = this.props.dados.dadosList.map(
                dado => <FechamentoTableRow dadosMap={dado.dadosMap} />
            );

            // Formata o rodapé (totais) da coluna
            footer = list.length > 0 ? listToFragment(
                columnOrder.map(column => <th className='Bold NumberAlign'>{this.columnFooter(column)}</th>)
            ) : [];
        }

        return (
            <RelatorioBox
                header={getStrings().closingReport}
                tableHeader={columnOrder.map(column => ({
                    key: column,
                    text: this.columnHeader(column),
                }))}
                list={list}
                tableFooter={footer}
                enableFilter={{
                    empresa: true,
                    origemVenda: true,
                    tipoProduto: false,
                    grupoProduto: false,
                    produto: false,
                    usuario: false,
                    estadoVenda: true,
                    estadoItemVenda: false,
                    data: true,
                    dataEntrega:false,
                    hora: true
                }}
                helpText={[getStrings().closingReportHelp()]}
            />
        );
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {
    return {
        ...state.relatorioReducer,
        ...state.idiomaReducer
    };
};

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        getRelatorio: relatorio => dispatch(relatorioActions.getRelatorio(relatorio)),
        sortData: (dados, options, columnOrder, rowSortOptions) => dispatch(relatorioActions.sortData(dados, options, columnOrder, rowSortOptions))
    };
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(FechamentoBox);
