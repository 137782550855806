export const detectFlash = () => {
    // return true if browser supports flash, false otherwise
    // Code snippet borrowed from: https://github.com/swfobject/swfobject
    var SHOCKWAVE_FLASH = "Shockwave Flash",
        SHOCKWAVE_FLASH_AX = "ShockwaveFlash.ShockwaveFlash",
        FLASH_MIME_TYPE = "application/x-shockwave-flash",
        win = window,
        nav = navigator,
        hasFlash = false;
    
    if (typeof nav.plugins !== "undefined" && typeof nav.plugins[SHOCKWAVE_FLASH] === "object") {
        var desc = nav.plugins[SHOCKWAVE_FLASH].description;
        if (desc && (typeof nav.mimeTypes !== "undefined" && nav.mimeTypes[FLASH_MIME_TYPE] && nav.mimeTypes[FLASH_MIME_TYPE].enabledPlugin)) {
            hasFlash = true;
        }
    }
    else if (win.ActiveXObject) {
        try {
            var ax = new win.ActiveXObject(SHOCKWAVE_FLASH_AX);
            if (ax) {
                var ver = ax.GetVariable("$version");
                if (ver) hasFlash = true;
            }
        }
        catch (e) {;}
    }
        
    return hasFlash;
}