import { dispatchTipo } from "../../../../../store/actions/acaoTemplate";
import criarAction from "../factory/criarAction";
import { infoReducerTipo } from "./infoReducer";

const tiposDeAcoes = {
  'ATUALIZAR_INFO': 'ATUALIZAR_INFO:INFO',
};

export default tiposDeAcoes;

export const atualizarInfo = (carga: Partial<infoReducerTipo>) => 
  (dispatch: dispatchTipo) => {
    dispatch(criarAction(carga, tiposDeAcoes.ATUALIZAR_INFO));
  }