import * as errorUtils from "../../utils/ErrorUtils"

import { isEmpresaPainel } from "../../utils/CompanyUtils"
import { getHeaders } from "../../utils/HeadersUtils"
import { buildMatrixVariables } from "../../utils/MiscUtils"
import { ascendingSort, descendingSort } from "../../utils/SortUtils"
import { urlDatabase } from "../../utils/SecureConnectionUtils"

import * as actionTypes from "./actionTypes"
import { appSpinnerShow, appSpinnerHide, axios } from "./appAction"

import { hideSpinner } from "../../components/UI/Spinner/Spinner"

/**
 * Id do spinner que é exibido no filtro de empresas.
 */
export const SPINNER_FILTRO_EMPRESA_ID = "spinnerFiltroEmpresa"

/**
 * Id do spinner que é exibido no filtro de origens de venda.
 */
export const SPINNER_FILTRO_ORIGEM_VENDA_ID = "spinnerFiltroOrigemVenda"

/**
 * Id do spinner que é exibido no filtro de tipo de produto.
 */
export const SPINNER_FILTRO_TIPO_PRODUTO_ID = "spinnerFiltroTipoProduto"

/**
 * Id do spinner que é exibido no filtro de grupo de produto.
 */
export const SPINNER_FILTRO_GRUPO_PRODUTO_ID = "spinnerFiltroGrupoProduto"

/**
 * Id do spinner que é exibido no filtro de produto.
 */
export const SPINNER_FILTRO_PRODUTO_ID = 'spinnerFiltroProduto';

/**
 * Id do spinner que é exibido no filtro de cargo.
 */
export const SPINNER_FILTRO_CARGO_ID = 'spinnerFiltroCargo';

/**
 * Id do spinner que é exibido no filtro de estado de venda.
 */
export const SPINNER_FILTRO_ESTADO_VENDA_ID = 'spinnerFiltroEstadoVenda';

/**
 * Id do spinner que é exibido no filtro de estado do item de venda.
 */
export const SPINNER_FILTRO_ESTADO_ITEM_VENDA_ID = 'spinnerFiltroEstadoItemVenda';

/**
 * Id do spinner que é exibido no filtro de data inicial.
 */
export const SPINNER_FILTRO_DATA_INICIAL_ID = 'spinnerFiltroDataInicial';

/**
 * Id do spinner que é exibido no filtro de data final.
 */
export const SPINNER_FILTRO_DATA_FINAL_ID = 'spinnerFiltroDataFinal';

/**
 * Id do spinner que é exibido no filtro de hora inicial.
 */
export const SPINNER_FILTRO_HORA_INICIAL_ID = 'spinnerFiltroHoraInicial';

/**
 * Id do spinner que é exibido no filtro de hora final.
 */
export const SPINNER_FILTRO_HORA_FINAL_ID = 'spinnerFiltroHoraFinal';

/**
 * Para mover colunas para a esquerda.
 */
export const LEFT = -1;

/**
 * Para mover colunas para a direita.
 */
export const RIGHT = +1;

/**
 * Ordem crescente de linhas: A, B, C...
 */
export const ASCENDING = 'A';

/**
 * Ordem decrescente de linhas: Z, Y, X...
 */
export const DESCENDING = 'D';

/**
 * Linhas sem ordenação.
 */
export const UNSORTED = 'U';

/**
 * Ao haver falha em comunicação com o servidor, mostrar uma notificação.
 * @param {*} error 
 */
const errorResponseNotify = error => {
  return dispatch => {
    dispatch(errorUtils.requestErrorHandlerDefault(error));
  };
}

/**
 * Usado para o relatório genérico antes do mesmo ter sido postergado.
 */
export const getRelatorio = relatorio => {

  return dispatch => {

    dispatch(appSpinnerShow('relatorio_' + relatorio));

    axios().get(

      urlDatabase + '/relatorios/' + relatorio,
      getHeaders(),

    ).then(response =>
      dispatch({
        type: actionTypes.RELATORIO,
        dados: response.data
      })

    ).catch(error =>
      dispatch(errorResponseNotify(error))

    ).finally(() =>
      dispatch(appSpinnerHide('relatorio_' + relatorio))
    );
  }
}

/**
 * Busca do servidor os dados de cada relatório específico.
 * @param {*} options relatório e filtros
 * @param {*} columnOrder lista de chaves das colunas em ordem de exibição
 * @param {*} rowSortOptions objeto contendo, para cada chave de coluna, se a mesma deve ser ordenada e qual deve ser ordenada primeiro
 */
export const getReport = (options, columnOrder, rowSortOptions) => {

  return (dispatch, getState) => {

    dispatch(appSpinnerShow('relatorio_' + options.report));

    // rowSortOptions só será fornecido inicialmente para limpar o que ficou salvo da tela anterior.
    // Depois disso, somente os botões do cabeçalho alteram o seu valor.
    if (!rowSortOptions) {
      rowSortOptions = getState().relatorioReducer.rowSortOptions;
    }

    axios().get(

      urlDatabase + '/relatorios/specific/' + buildMatrixVariables(options),
      getHeaders(),

    ).then(response =>
      dispatch(sortData(response.data, options, columnOrder, rowSortOptions))

    ).catch(error =>
      dispatch(errorResponseNotify(error))

    ).finally(() => {
      dispatch(appSpinnerHide('relatorio_' + options.report));
      hideSpinner(SPINNER_FILTRO_EMPRESA_ID);
      hideSpinner(SPINNER_FILTRO_ORIGEM_VENDA_ID);
      hideSpinner(SPINNER_FILTRO_TIPO_PRODUTO_ID);
      hideSpinner(SPINNER_FILTRO_GRUPO_PRODUTO_ID);
      hideSpinner(SPINNER_FILTRO_PRODUTO_ID);
      hideSpinner(SPINNER_FILTRO_CARGO_ID);
      hideSpinner(SPINNER_FILTRO_ESTADO_VENDA_ID);
      hideSpinner(SPINNER_FILTRO_ESTADO_ITEM_VENDA_ID);
      hideSpinner(SPINNER_FILTRO_DATA_INICIAL_ID);
      hideSpinner(SPINNER_FILTRO_DATA_FINAL_ID);
      hideSpinner(SPINNER_FILTRO_HORA_INICIAL_ID);
      hideSpinner(SPINNER_FILTRO_HORA_FINAL_ID);
    });
  }
}

/**
 * Move uma coluna um espaço para a direita ou para a esquerda.
 * @param {*} column chave da coluna
 * @param {*} direction sentido do movimento
 */
export const moveColumn = (column, direction) => {

  return (dispatch, getState) => {

    // Faz uma cópia do objeto no reducer, ao invés de usar o mesmo objeto

    /**
     * Lista de colunas ordenada na mesma ordem de exibição das mesmas.
     */
    let columnOrder = Object.assign([], getState().relatorioReducer.columnOrder);

    // Acha na lista o índice da coluna a ser movida
    let index = columnOrder.indexOf(column);

    // Não permite que colunas nas extremidades sejam movidas para além das extremidades
    if (((direction === LEFT) && (index === 0)) || ((direction === RIGHT) && (index === (columnOrder.length - 1)))) {
      return;
    }

    /**
     * Coluna que trocará de lugar com a coluna a ser movida.
     */
    let neighbor = columnOrder[index + direction];

    // Troca as colunas de lugar
    columnOrder[index + direction] = column;
    columnOrder[index] = neighbor;

    // Guarda no reducer a nova ordem de colunas.
    dispatch({
      type: actionTypes.RELATORIO,
      columnOrder: columnOrder
    })
  };
}

/**
 * Atualiza as configurações de ordenação das linhas.
 * 
 * Cada coluna selecionada para ser ordenada possui um índice de ordenação. Quando ela é selecionada, seu índice se torna 1 e as demais são ajustadas
 * para seguir a sequência. Ao ordenar linhas, elas são ordenadas pela coluna com o menor índice. Em caso de empate, são ordenadas pela segunda coluna
 * de menor índice, e assim por diante. 
 * @param {*} column 
 * @param {*} direction 
 */
export const updateRowSortOptions = (column, direction) => {

  return (dispatch, getState) => {

    /**
     * Objeto onde as chaves são as chaves de colunas e os valores são um objeto contendo
     * qual ordenação deve ser realizada na coluna e o seu índice de ordenação.
     */
    let rowSortOptions = Object.assign({}, getState().relatorioReducer.rowSortOptions);

    // Adiciona 1 no índice de todas as colunas para que não haja coluna com índice 1.
    Object.keys(rowSortOptions).forEach(key => rowSortOptions[key].index++);

    // Atualiza a coluna selecionada com a ordenação selecionada e o índice de ordenação 1.
    rowSortOptions[column] = {
      direction: direction,
      index: 1
    };

    /**
     * Objeto com configurações de ordenação transformado em lista seguindo a ordem do índice de ordenação.
     */
    let rowSortList = Object.keys(rowSortOptions)
      .sort((a, b) => ascendingSort(rowSortOptions[a].index, rowSortOptions[b].index))
      .map(key => {
        return {
          ...rowSortOptions[key],
          column: key
        }
      });

    // Recalcula índices de ordenação para remover lacunas.

    let counter = 1;

    rowSortList.forEach(rowSortOption => rowSortOptions[rowSortOption.column].index = counter++);

    // Ordena as linhas do relatório.
    dispatch(sortData(Object.assign({}, getState().relatorioReducer.dados), undefined, undefined, rowSortOptions));
  }
}

/**
 * Ordena recursivamente as linhas do relatório de acordo com a ordenação selecionada. Linhas e quebras somente são ordenadas dentro de sua quebra.
 * @param {Object} dados dados do relatório
 * @param {Object} rowSortList objeto vinculando chaves de coluna com ordenação e índice de ordenação de cada coluna
 * @param {String} ramoEmpresa altera como algumas ordenações são realizadas
 */
const sortDadosRelatorio = (dados, rowSortList, ramoEmpresa) => {

  /**
   * Resultado da comparação entre duas linhas. Se a primeira vem antes, `-1`. Se vem depois, `1`. Se nenhuma opção, `0`.
   */
  let order;

  /**
   * Método de ordenação: ascendente ou descendente.
   */
  let sort;

  dados.dadosList.sort((a, b) => {
    order = 0;
    // Itera a lista de ordenações para decidir qual ordenação usar
    rowSortList.forEach(rowSortOption => {
      // Se já foi decidido qual linha vem antes, não faz mais nada.
      if (order !== 0) {
        return;
      }
      // Senão, compara as linhas de acordo com a ordenação selecionada.
      sort = (rowSortOption.direction === DESCENDING) ? descendingSort : ascendingSort;
      // Se for empresa do ramo Painel, trata o nome da venda como um número de sequência.
      if (rowSortOption.column === 'venda.nome' && isEmpresaPainel(ramoEmpresa)) {
        order = sort(Number(a.dadosMap[rowSortOption.column]), Number(b.dadosMap[rowSortOption.column]));
      }
      // Se não, ordena como as outras colunas.
      else {
        order = sort(a.dadosMap[rowSortOption.column], b.dadosMap[rowSortOption.column]);
      }
    });
    // Usa o resultado da comparação para ordenar as linhas
    return order;
  });

  dados.dadosList.forEach(dadosItem => sortDadosRelatorio(dadosItem, rowSortList, ramoEmpresa));
}

/**
 * Ordena as linhas do relatório de acordo com a ordenação selecionada.
 * 
 * Também guarda no *reducer* alguns dados por conveniência.
 * @param {*} dados dados do relatório
 * @param {*} options identificador do relatório e filtros
 * @param {*} columnOrder ordem de exibição das colunas
 * @param {*} rowSortOptions objeto vinculando chaves de coluna com ordenação e índice de ordenação de cada coluna
 */
export const sortData = (dados, options, columnOrder, rowSortOptions) => (dispatch, getState) => {

  /**
   * Objeto com configurações de ordenação transformado em lista seguindo a ordem do índice de ordenação.
   */
  let rowSortList = Object.keys(rowSortOptions)
    .sort((a, b) => ascendingSort(rowSortOptions[a].index, rowSortOptions[b].index))
    .map(key => {
      return {
        ...rowSortOptions[key],
        column: key
      }
    });

  // Se não há a necessidade de ordenação das linhas, simplesmente guarda os dados no reducer
  if (!rowSortList.length) {

    dispatch({
      type: actionTypes.RELATORIO,
      dados: dados,
      ...(options ? { options: options } : {}),
      ...(columnOrder ? { columnOrder: columnOrder } : null),
      ...(rowSortOptions ? { rowSortOptions: rowSortOptions } : {})
    });
    return;
  }

  // Ordena as linhas do relatório

  sortDadosRelatorio(dados, rowSortList, getState().empresaSelectorReducer.ramoEmpresa);

  // Guarda os dados no reducer
  dispatch({
    type: actionTypes.RELATORIO,
    dados: dados,
    ...(options ? { options: options } : {}),
    ...(columnOrder ? { columnOrder: columnOrder } : null),
    ...(rowSortOptions ? { rowSortOptions: rowSortOptions } : {})
  });
}
