import Sounds, { soundTipo } from "./Sounds";

let SoundManager = (texto: soundTipo) => {
    const noSound = JSON.parse(sessionStorage.getItem('c') || '{}')['s'] !== true;

    if (noSound) {
        return;
    }

    Sounds[texto].play();
};

export default SoundManager;
