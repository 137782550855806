import React from "react"
import { push } from "connected-react-router"

import { getAppUsuario } from "../../../../utils/AppUtils"
import { addAfterReturnNewArray, setIndexReturnNewArray } from "../../../../utils/ArrayUtils"
import { getAYCEAmount } from "../../../../utils/BusinessUtils"
import * as errorUtils from "../../../../utils/ErrorUtils"
import { getStrings } from "../../../../utils/LocaleUtils"
import { log } from "../../../../utils/LogUtils"
import { getHeaders } from "../../../../utils/HeadersUtils"
import { roundNumber } from "../../../../utils/NumberUtils"
import { getNewSaleItemListMapStorage, setSaleSourceNoCompany } from "../../../../utils/StorageUtils/LocalStorageUtils"
import { urlDatabase } from "../../../../utils/SecureConnectionUtils"
import { getURIFromEntity } from "../../../../utils/URIUtils"

import * as actionTypes from "../../actionTypes"
import * as appActions from "../../appAction"
import {
  ESTADO_VENDA_CANCELADO,
  STATE_CONTROLE_VENDA_MANUTENCAO_VENDA
  , TIPO_ORIGEM_VENDA_IFOOD
} from "../../../reducers/controleVenda/controleVendaReducer"
import {
  NIVEL_USUARIO_SEM_EMPRESA_VENDA
} from "../../../reducers/empresaSelectorReducer"
import {
  NEW_SALE_ITEM_AMOUNT_OPERATION_ADD
  , NEW_SALE_ITEM_AMOUNT_OPERATION_SET
  , NEW_SALE_ITEM_AMOUNT_TYPE_AYCE
  , NEW_SALE_ITEM_AMOUNT_TYPE_WEIGHTED
} from "../../../reducers/controleVenda/manutencao/manutencaoVendaReducer"
import { initialState } from "../../../reducers/controleVenda/manutencao/manutencaoVendaReducer"
import { axios } from "../../appAction"
import * as controleVendaAction from "../../controleVenda/controleVendaAction"
import { RamoEmpresa } from "../../empresaSelectorAction"
import { buildNewSaleItem, grupoProdutoLoad } from "../../controleVenda/manutencao/gridGrupoProdutoAction"
import { ADD_PRODUCT_WITH_WEIGHT } from "../../subscriberAction"

import DialogCancelSaleSaleItem from "../../../../components/vendas/dialog/DialogCancelSaleSaleItem"

// Para enviar fuso horário para o servidor
const moment = require("moment")

/**
 * Tela que exibe produtos separados por grupo ao gerir venda.
 */
export const MANUTENCAO_CONTENT_PRODUTO = "MANUTENCAO_CONTENT_PRODUTO"

/**
 * Tela que exibe itens de venda a enviar e enviados.
 */
export const MANUTENCAO_CONTENT_ITENS = "MANUTENCAO_CONTENT_ITENS"

/**
 * Tela que exibe itens de venda para pagamento.
 */
export const MANUTENCAO_CONTENT_PAGAMENTO = "MANUTENCAO_CONTENT_PAGAMENTO"

/**
 * Volta para a tela de origens ao enviar itens da venda.
 */
export const switchToSaleSourcesAfterSendingSale = getState => getState().empresaSelectorReducer.cargo;

/**
 * Método que atualiza a tela que está sendo exibida no controle de vendas.
 * @param {*} newManutencaoContent 
 */
export const setManutencaoContent = newManutencaoContent => {
  log('manutencaoVendaAction setManutencaoContent', newManutencaoContent);
  return {
    type: actionTypes.MANUTENCAO_VENDA_SET_MANUTENCAO_CONTENT,
    manutencaoContent: newManutencaoContent,
  };
}

/**
 * 
 * Método que zera os novos itens de venda
 */
export const clearNewSaleItemList = () => (dispatch, getState) => {
  log('manutencaoVendaAction clearNewSaleItemList');
  dispatch(grupoProdutoLoad(
    getState().controleVendaReducer.grupoProdutoList, getState().manutencaoVendaReducer.produtoList));
};

/**
 * Método que atualiza as variáveis de estados que possuem dados já persistidos em banco.
 * @param {Object} vendaPersisted 
 * @param {Object} options o que fazer com diversas variáveis do *reducer*
 */
export const updateVendaPersistedData = (vendaPersisted, options) => (dispatch, getState) => {
  log('manutencaoVendaAction updateVendaPersistedData', { vendaPersisted });

  const payload = {
    type: actionTypes.CONTROLE_VENDA_UPDATE_VENDA,
    // Atualiza a lista de origens de venda para que a única origem nesta lista
    // tenha somente a venda selecionada em sua lista de vendas
    origemVendaList: getState().controleVendaReducer.origemVendaList.map(origemVenda => {
      origemVenda.vendaList = [vendaPersisted];
      return origemVenda;
    }),
    ...options
  };

  dispatch(payload);
};

/**
 * Método que retorna todos os estados da tela de manutenção aos valores iniciais
 */
export const limpaManutencaoVenda = () => {
  log('manutencaoVendaAction limpaManutencaoVenda');
  let state = Object.assign({}, initialState);
  delete state.gridGrupoProdutoDisplay;
  return {
    type: actionTypes.MANUTENCAO_VENDA_LIMPA_MANUTENCAO_VENDA,
    ...state
  };
}

export const errorResponse = error => {
  log('manutencaoVendaAction errorResponse', error);
  return dispatch => {
    dispatch(errorUtils.requestErrorHandlerDefault(error));
  };
}

/**
 * Método que persiste uma nova venda.
 * @param {*} dadosEnvio 
 * @param {*} successMethod 
 */
export const saveData = (dadosEnvio, successMethod) => (dispatch, getState) => {
  log('manutencaoVendaAction saveData', dadosEnvio, successMethod);

  dispatch(appActions.appSpinnerShow('saveData'));

  const dados = Object.assign({}, dadosEnvio, {
    itemVendaList: dadosEnvio.itemVendaList.map(itemVenda => Object.assign({}, itemVenda, {
      produto: getURIFromEntity(itemVenda.produto)
    }))
  });

  // Recupera os arquivos do servidor
  axios().post(
    urlDatabase.concat('/vendas'),
    dados,
    getHeaders({
      ...(getState().empresaSelectorReducer.cargo ? {} : {
        noCompany: true
      })
    })
  )
    .then(resposta => {
      // Atualiza o link da venda para se precisar desfazer um envio
      dispatch({
        type: actionTypes.CONTROLE_VENDA_PERSIST_VENDA,
        vendaURI: getURIFromEntity(resposta.data)
      });
      if (!switchToSaleSourcesAfterSendingSale(getState) || (getState().empresaSelectorReducer.ramoEmpresa === RamoEmpresa.PAINEL)) {
        dispatch({
          type: actionTypes.CONTROLE_VENDA_PERSIST_VENDA,
          state: STATE_CONTROLE_VENDA_MANUTENCAO_VENDA,
          vendaAmount: getState().controleVendaReducer.vendaAmount + 1
        });
        dispatch(updateVendaPersistedData(resposta.data, {
          clearTotal: actionTypes.REDUCER_UPDATE,
          commission: {
            value: getState().pagamentoVendasReducer.percentualComissaoAtual,
            action: actionTypes.REDUCER_HOLD
          },
          discount: {
            type: getState().pagamentoVendasReducer.discountType,
            value: getState().pagamentoVendasReducer.discountValue,
            action: actionTypes.REDUCER_HOLD
          },
          newSalePaymentItemMap: actionTypes.REDUCER_SET,
          nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
          ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
          remainingAmountMap: actionTypes.REDUCER_SET,
          sentSaleItemList: actionTypes.REDUCER_SET,
          sentSalePaymentItemMap: actionTypes.REDUCER_DEFAULT
        }));
      }
      successMethod();
      // Se for empresa do ramo Painel, exibe a tela de pagamento com todos os itens marcados.
      if (getState().empresaSelectorReducer.ramoEmpresa === RamoEmpresa.PAINEL) {
        dispatch(exibeTelaPagamento());
      }
      // Senão, verifica se deve voltar para a tela de origens em uso.
      else if (switchToSaleSourcesAfterSendingSale(getState)) {
        dispatch(controleVendaAction.exibeGridOrigemVendaEmUso());
      }
    })
    .catch(error =>
      dispatch(errorResponse(error))
    )
    .finally(() =>
      dispatch(appActions.appSpinnerHide('saveData'))
    );
};

/**
 * Método que adiciona novos itens de venda a uma venda persistida.
 * @param {*} dadosEnvio 
 * @param {*} successMethod 
 */
export const addItensVenda = (updateUrl, itemVendaList, successMethod) => (dispatch, getState) => {
  log('manutencaoVendaAction addItensVenda', updateUrl, itemVendaList, successMethod);

  dispatch(appActions.appSpinnerShow('addItensVenda'));

  axios().post(
    updateUrl.replace('{?projection}', '') + '/addItemVenda',
    itemVendaList,
    getHeaders({
      ...(getState().empresaSelectorReducer.cargo ? {} : {
        noCompany: true
      })
    })
  )
    .then(response => {
      // Atualiza o link da venda para se precisar desfazer um envio
      dispatch({
        type: actionTypes.CONTROLE_VENDA_PERSIST_VENDA,
        vendaURI: response.data._links.self.href.replace('{?projection}', '')
      });
      if (!switchToSaleSourcesAfterSendingSale(getState) || (getState().empresaSelectorReducer.ramoEmpresa === RamoEmpresa.PAINEL)) {
        dispatch(updateVendaPersistedData(response.data, {
          clearTotal: actionTypes.REDUCER_UPDATE,
          commission: {
            value: getState().pagamentoVendasReducer.percentualComissaoAtual,
            action: actionTypes.REDUCER_HOLD
          },
          discount: {
            type: getState().pagamentoVendasReducer.discountType,
            value: getState().pagamentoVendasReducer.discountValue,
            action: actionTypes.REDUCER_HOLD
          },
          newSalePaymentItemMap: actionTypes.REDUCER_SET,
          nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
          ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
          remainingAmountMap: actionTypes.REDUCER_SET,
          sentSaleItemList: actionTypes.REDUCER_SET,
          sentSalePaymentItemMap: actionTypes.REDUCER_HOLD
        }));
      }
      successMethod(response.data);
      // Se for empresa do ramo Painel, exibe a tela de pagamento com todos os itens marcados.
      if (getState().empresaSelectorReducer.ramoEmpresa === RamoEmpresa.PAINEL) {
        dispatch(exibeTelaPagamento());
      }
      // Senão, verifica se deve voltar para a tela de origens em uso.
      else if (switchToSaleSourcesAfterSendingSale(getState)) {
        dispatch(controleVendaAction.exibeGridOrigemVendaEmUso());
      }
    })
    .catch(error =>
      dispatch(errorResponse(error))
    )
    .finally(() =>
      dispatch(appActions.appSpinnerHide('addItensVenda'))
    );
};

/**
 * Método que encerra a venda
 * @param {*} dadosEnvio 
 * @param {*} successMethod 
 */
export const closeVenda = () => (dispatch, getState) => {

  log('manutencaoVendaAction closeVenda');
  dispatch(appActions.appSpinnerShow('closeVenda'));

  axios().post(
    urlDatabase + '/vendas/closeVendas',
    [getState().controleVendaReducer.origemVendaList[0].vendaList[0]._links.self.href.replace('{?projection}', '')],
    getHeaders({
      ...(getState().empresaSelectorReducer.cargo ? {} : {
        noCompany: true
      })
    })
  )
    .then(response => {
      // Se for usuário sem vínculo com empresa,
      // inicia outra venda ou busca as vendas recentes para acompanhar o estado.
      if (!getState().empresaSelectorReducer.cargo) {
        dispatch(push('/pedidos'));
      }
      // Se é empresa do ramo Painel, inicia nova venda.
      else if (getState().empresaSelectorReducer.ramoEmpresa === RamoEmpresa.PAINEL) {
        dispatch(controleVendaAction.exibeManutencaoVendaPainel());
      }
      // Se havia mais de uma venda na origem antes de encerrar esta
      // (ou seja, se sobrou pelo menos uma venda após encerrar esta),
      // volta para a tela de vendas
      else if (getState().controleVendaReducer.vendaAmount > 1) {
        dispatch(controleVendaAction.exibeGridVendaFromOrigem(getState().controleVendaReducer.origemVendaList[0]));
      }
      // Se havia uma ou menos vendas na origem antes de encerrar esta
      // (ou seja, se esta é a última venda sendo encerrada),
      // volta para a tela de origens
      else {
        dispatch(controleVendaAction.exibeGridOrigemVendaEmUso());
      }
    })
    .catch(error =>
      dispatch(errorResponse(error))
    )
    .finally(() =>
      dispatch(appActions.appSpinnerHide('closeVenda'))
    );
}

/**
 * Método que retornar para a tela de exibição das vendas vinculadas a origem.
 */
export const exibeGridVendaFromOrigem = () => {
  log('manutencaoVendaAction exibeGridVendaFromOrigem');
  return (dispatch, getState) => {
    dispatch(controleVendaAction.exibeGridVendaFromOrigem(Object.assign({}, getState().controleVendaReducer.origemVendaList[0])));
  }
};

/**
 * Diminui a quantidade de itens de venda (se estiverem parcialmente pagos)
 * ou deleta itens de venda (se não estiverem pagos) de uma venda.
 * @param {Object} sentSaleItem um item de venda sem todas as variáveis
 */
export const handleRemoveSentSaleItem = sentSaleItem => (dispatch, getState) => {
  log('manutencaoVendaAction handleRemoveSentSaleItem', sentSaleItem);

  // Testa se item de venda possui quantidade já paga
  if (sentSaleItem.remainingPaidAmount) {
    // Se sim, ajusta a sua quantidade e valor total
    sentSaleItem.quantidade = sentSaleItem.remainingPaidAmount;
    sentSaleItem.valorTotal = sentSaleItem.quantidade * sentSaleItem.precoUnitario;
  }
  else {
    // Se não, marca o mesmo para deleção
    sentSaleItem.deleted = true;
  }

  // Se item foi marcado para deleção, ele também será cancelado.
  // Pergunta por motivo de cancelamento.
  if (sentSaleItem.deleted) {
    dispatch(appActions.appDialogShow(<DialogCancelSaleSaleItem
      handleConfirm={cancelReason => {
        sentSaleItem.motivoCancelamento = cancelReason;
        dispatch(removeSentSaleItem(sentSaleItem));
      }}
    />, getStrings().cancelProduct));
  }
  // Senão, chama diretamente o método que fará a remoção.
  else {
    dispatch(appActions.appNotificationShow(getStrings().cancelSentItem, actionTypes.APP_NOTIFICATION_TYPE_WARNING_QUESTION, getStrings().cancel,
      () => {
        dispatch(removeSentSaleItem(sentSaleItem));
      }
    ));
  }
};

/**
 * Diminui a quantidade de itens de venda (se estiverem parcialmente pagos)
 * ou deleta itens de venda (se não estiverem pagos) de uma venda.
 * @param {Object} sentSaleItem um item de venda sem todas as variáveis
 */
export const removeSentSaleItem = sentSaleItem => (dispatch, getState) => {
  log('manutencaoVendaAction removeSentSaleItem', sentSaleItem);

  dispatch(appActions.appSpinnerShow('removeSentSaleItem'));

  saleItemUpdateTreatment(axios().post(
    getURIFromEntity(sentSaleItem),
    {
      deleted: sentSaleItem.deleted,
      motivoCancelamento: sentSaleItem.motivoCancelamento,
      quantidade: sentSaleItem.quantidade,
      valorTotal: sentSaleItem.valorTotal,
    },
    getHeaders({
      // Se for um usuário sem vínculo com empresa
      ...(getState().empresaSelectorReducer.cargo ? {} : { noCompany: true })
    })
  ), dispatch, getState, 'removeSentSaleItem');
};

/**
 * Se usuário tentar sair da manutenção de venda com itens a enviar, avisa e pede confirmação antes.
 */
const confirmaCancelamentoVenda = confirmMethod => {
  log('manutencaoVendaAction confirmaCancelamentoVenda', confirmMethod);

  return dispatch => {

    dispatch(appActions.appNotificationShow(getStrings().cancelNewItems,
      actionTypes.APP_NOTIFICATION_TYPE_WARNING_QUESTION, getStrings().cancel, confirmMethod));
  };
}

/**
 * Volta para a tela anterior na manutenção de venda. Se a tela anterior for fora
 * da manutenção de venda, verifica se é necessário pedir confirmação ao usuário
 */
export const handleBack = (confirmMethod, ignoreCode) => {
  log('manutencaoVendaAction handleBack', { confirmMethod, ignoreCode });

  return (dispatch, getState) => {

    // Se estiver exibindo os itens da venda
    if ((!ignoreCode) && (getState().manutencaoVendaReducer.manutencaoContent !== MANUTENCAO_CONTENT_PRODUTO)) {
      dispatch(exibeProdutos());
      return;
    }

    // Se existem itens não enviados armazenados, verifica se a venda será cancelada
    if (getState().controleVendaReducer.hasNewSaleItemToSend) {
      dispatch(confirmaCancelamentoVenda(confirmMethod));
      return;
    }

    // Retorna para a tela de exibição das vendas vinculadas a origem
    confirmMethod();
  }
}

/**
 * Método que exibe os produtos para a venda
 */
export const exibeProdutos = () => {
  log('manutencaoVendaAction exibeProdutos');

  return (dispatch, getState) => {

    let reducer = getState().manutencaoVendaReducer;

    if (reducer.manutencaoContent === MANUTENCAO_CONTENT_PRODUTO)
      return;

    dispatch(setManutencaoContent(MANUTENCAO_CONTENT_PRODUTO));
  }
}

/**
 * Método que exibe os itens já vendidos
 */
export const exibeItensVenda = () => {
  log('manutencaoVendaAction exibeItensVenda');

  return (dispatch, getState) => {

    let reducer = getState().manutencaoVendaReducer;

    if (reducer.manutencaoContent === MANUTENCAO_CONTENT_ITENS)
      return;

    dispatch(setManutencaoContent(MANUTENCAO_CONTENT_ITENS));
  }
}

/**
 * Método que exibe a tela para pagamento das vendas
 */
export const exibeTelaPagamento = () => {
  log('manutencaoVendaAction exibeTelaPagamento');

  return (dispatch, getState) => {

    let reducer = getState().manutencaoVendaReducer;

    if (reducer.manutencaoContent === MANUTENCAO_CONTENT_PAGAMENTO)
      return;

    dispatch(setManutencaoContent(MANUTENCAO_CONTENT_PAGAMENTO));
  }
}

/**
 * Desfaz o último envio de itens de venda e atualiza os dados da venda no `reducer`,
 * desde que esteja configurado para permanecer na manutenção de vendas ao enviar a venda.
 * 
 * Se era uma nova venda, ela é excluida. Se estiver configurado para permanecer na manutenção de vendas ao enviar a venda,
 * verifica a quantidade de vendas da origem, que é retornada, e decide se volta para a tela de vendas da origem ou para a tela de origens em uso.
 */
export const undoSend = () => (dispatch, getState) => {
  log('manutencaoVendaAction undoSend');

  dispatch(appActions.appSpinnerShow('undoSend'));

  axios().post(
    getState().controleVendaReducer.vendaURI + '/undoSend',
    {},
    getHeaders({
      ...(getState().empresaSelectorReducer.cargo ? {} : {
        noCompany: true
      })
    })
  )
    .then(response => {
      // Verifica se a retaguarda retornou algo como deveria
      if ((!response) || (!(response.data))) {
        throw new Error();
      }
      // Exibe notificação
      dispatch(appActions.appNotificationShow(getStrings().undone,
        actionTypes.APP_NOTIFICATION_TYPE_SUCCESS, getStrings().success));
      // Se for empresa do ramo Painel
      if (getState().empresaSelectorReducer.ramoEmpresa === RamoEmpresa.PAINEL) {
        // Se retornou uma venda, significa que a venda ainda está aberta. Atualiza a venda.
        if (getURIFromEntity(response.data).indexOf('origemVendas') < 0) {
          dispatch(updateVendaPersistedData(response.data, {
            clearTotal: actionTypes.REDUCER_UPDATE,
            commission: {
              value: getState().pagamentoVendasReducer.percentualComissaoAtual,
              action: actionTypes.REDUCER_HOLD
            },
            discount: {
              type: getState().pagamentoVendasReducer.discountType,
              value: getState().pagamentoVendasReducer.discountValue,
              action: actionTypes.REDUCER_HOLD
            },
            newSalePaymentItemMap: actionTypes.REDUCER_SET,
            nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
            ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
            remainingAmountMap: actionTypes.REDUCER_SET,
            sentSaleItemList: actionTypes.REDUCER_SET,
            sentSalePaymentItemMap: actionTypes.REDUCER_HOLD
          }));
        }
        // Se retornou uma origem de venda, significa que a venda foi encerrada por não ter itens. Inicia nova venda.
        else {
          dispatch(controleVendaAction.exibeManutencaoVendaPainel());
        }
      }
      // Senão, se for para retornar para a tela de origens em uso após enviar venda, carrega as origens em uso, porque já estará na tela.
      else if (switchToSaleSourcesAfterSendingSale(getState)) {
        dispatch(controleVendaAction.loadOrigensEmUso());
      }
      // Senão, decide o que fazer de acordo com o retorno do método.
      // Se houver uma origem de venda com quantidade de venda não nula, exibe as vendas da origem.
      else if (response.data.quantidadeVendas) {
        dispatch(controleVendaAction.exibeGridVendaFromOrigem(response.data));
      }
      // Senão, se houver uma origem de venda com quantidade de venda nula, exibe as origens em uso.
      else if (response.data.quantidadeVendas === 0) {
        dispatch(controleVendaAction.exibeGridOrigemVendaEmUso());
      }
      // Senão, simplesmente atualiza os dados da venda no reducer.
      else {
        dispatch(updateVendaPersistedData(response.data, {
          clearTotal: actionTypes.REDUCER_UPDATE,
          commission: {
            value: getState().pagamentoVendasReducer.percentualComissaoAtual,
            action: actionTypes.REDUCER_HOLD
          },
          discount: {
            type: getState().pagamentoVendasReducer.discountType,
            value: getState().pagamentoVendasReducer.discountValue,
            action: actionTypes.REDUCER_HOLD
          },
          newSalePaymentItemMap: actionTypes.REDUCER_SET,
          nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
          ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
          remainingAmountMap: actionTypes.REDUCER_SET,
          sentSaleItemList: actionTypes.REDUCER_SET,
          sentSalePaymentItemMap: actionTypes.REDUCER_HOLD
        }));
      }
    })
    .catch(error => dispatch(errorUtils.requestErrorHandlerDefault(error)))
    .finally(() => dispatch(appActions.appSpinnerHide('undoSend')));
};

/**
 * Atualiza no `reducer` a observação de um produto a ser enviado.
 * @param {Object} newSaleItem
 * @param {String} code código do produto
 * @param {Number} sequence sequência do produto (discrimina várias instâncias de produtos pesados)
 * @param {String} oldValue valor atual da observação do produto
 * @param {String} newValue valor informado da observação do produto
 */
export const setObservation = (newSaleItem, code, sequence, oldValue, newValue) => (dispatch, getState) => {
  log('manutencaoVendaAction setObservation', { newSaleItem, code, sequence, oldValue, newValue });

  if (oldValue === newValue) {
    return;
  }
  // Busca a lista de novos itens de venda
  let newSaleItemList = getState().controleVendaReducer.newSaleItemList;
  // Atualiza a lista de novos itens de venda no reducer
  dispatch({
    type: actionTypes.MANUTENCAO_VENDA_UPDATE_NOVOS_ITENS_VENDA,
    newSaleItemList: setIndexReturnNewArray(
      newSaleItemList,
      newSaleItemList.findIndex(item => (code === item.code) && (sequence === item.sequence)),
      Object.assign({}, newSaleItem, {
        itemVenda: Object.assign({}, newSaleItem.itemVenda, {
          observacao: newValue
        })
      }))
  });
};

/**
 * Verifica se foi informada uma quantidade de produto vendido a peso que ultrapasse a quantidade equivalente do valor do livre e,
 * se sim, altera o tipo e o valor da quantidade de acordo.
 * @param {Object} itemVenda
 * @param {String} amountType se produto é vendido por peso, pelo livre ou por unidade
 * @param {Number} amount quantidade escolhida ou digitada
 * @param {Function} callBack função para retornar os valores
 */
export const checkAYCE = (itemVenda, amountType, amount, getState, callBack) => {
  log('manutencaoVendaAction checkAYCE', { itemVenda, amountType, amount, getState, callBack });
  // Busca preco do livre
  let priceAYCE = getState().empresaSelectorReducer.precoLivre || 0;
  // Busca quantidade equivalente ao livre
  let amountAYCE = getAYCEAmount(itemVenda.produto, priceAYCE) || Number.MAX_SAFE_INTEGER;
  // Se for produto vendido por peso mas o valor resultante ultrapassar o valor do livre, considera como se fosse livre.
  if (priceAYCE && (amountType === NEW_SALE_ITEM_AMOUNT_TYPE_WEIGHTED) && (amount >= amountAYCE)) {
    amountType = NEW_SALE_ITEM_AMOUNT_TYPE_AYCE;
  }
  // Se foi escolhido livre sem informar a quantidade, assume quantidade que resulta no valor do livre.
  if ((amountType === NEW_SALE_ITEM_AMOUNT_TYPE_AYCE) && (!amount)) {
    amount = amountAYCE;
  }
  // Retorna os valores
  callBack(amountType, amount, priceAYCE);
}

/**
 * Retorna nova instância de novo item de venda com dados atualizados.
 * @param {Object} newSaleItem novo item de venda
 * @param {Number} priceAYCE 
 * @param {String} amountType se produto é vendido por peso, pelo livre ou por unidade
 * @param {Number} amount quantidade escolhida ou digitada
 * @param {Boolean} newDateTime se deve gerar nova data, hora e fuso horário
 * @param {Object} date data de inserção do item, quando vier de leitura de peso da balança
 */
const updateNewSaleItem = (newSaleItem, priceAYCE, amountType, amount, newDateTime, date) => {
  log('manutencaoVendaAction updateNewSaleItem', { newSaleItem, priceAYCE, amountType, amount, newDateTime, date });
  if (!date) {
    date = new Date();
  }
  return Object.assign({}, newSaleItem, {
    itemVenda: Object.assign({}, newSaleItem.itemVenda, {
      precoUnitario: (amountType === NEW_SALE_ITEM_AMOUNT_TYPE_AYCE) ? priceAYCE : newSaleItem.itemVenda.produto.preco,
      quantidade: amount,
      valorTotal: (amountType === NEW_SALE_ITEM_AMOUNT_TYPE_AYCE) ? priceAYCE : roundNumber(amount * newSaleItem.itemVenda.produto.preco, 2),
      ...(newDateTime ? {
        dataHora: date,
        dataHoraAtualizacao: date,
        // -03:00, +00:00, +05:00, etc
        fusoHorario: moment().format('Z')
      } : {}),
      livre: amountType === NEW_SALE_ITEM_AMOUNT_TYPE_AYCE
    })
  });
}

const updateNewSaleItemCombinado = (newSaleItem, amount, newDateTime, date) => {
  log('manutencaoVendaAction updateNewSaleItemCombinado', { newSaleItem, amount, newDateTime, date });

  if (!date) {
    date = new Date();
  }

  return Object.assign({}, newSaleItem, {
    itemVenda: Object.assign({}, newSaleItem.itemVenda, {
      precoUnitario: newSaleItem.itemVenda.produto.preco,
      quantidade: amount,
      valorTotal: roundNumber(amount * newSaleItem.itemVenda.produto.preco, 2),
      ...(newDateTime ? {
        dataHora: date,
        dataHoraAtualizacao: date,
        fusoHorario: moment().format('Z')
      } : {}),
      usuarioList: [urlDatabase + '/usuarios/' + getAppUsuario()],
      livre: false
    })
  });
}

/**
 * Adiciona ou atualiza no `reducer` um novo item de venda (ainda não enviado à retaguarda).
 * 
 * Ao fazer isso, a quantidade poderá ser modificada de acordo com as seguintes situações:
 * * Se produto for vendido por peso pelo valor do livre, ajusta a quantidade de acordo com o preço do livre.
 * * Se produto for vendido por peso pelo valor da balança, verifica se não ultrapassou o valor do livre. Se ultrapassou, faz como no item anterior. Senão, faz como no item seguinte.
 * * Se produto for vendido por unidade, usa a quantidade informada.
 * @param {String} operation se é a adição de um novo item de venda ou a alteração de sua quantidade
 * @param {Object} newSaleItem objeto contendo código do produto (`code`), número de sequência (`sequence`) e novo item de venda (`itemVenda`)
 * @param {String} amountType se produto é vendido por peso, pelo livre ou por unidade
 * @param {Number} amount quantidade escolhida ou digitada
 * @param {String} observation
 * @param {Object} date data de inserção do item, quando vier de leitura de peso da balança
 */
export const addNewSaleItemOrSetAmount = (operation, newSaleItem, amountType, amount, observation, date) => (dispatch, getState) => {
  log('manutencaoVendaAction addNewSaleItemOrSetAmount', { operation, newSaleItem, amountType, amount, observation, date });
  // Busca preco do livre
  let priceAYCE;
  // Verifica quantidade e tipo da quantidade
  checkAYCE(newSaleItem.itemVenda, amountType, amount, getState, (amountType_cb, amount_cb, priceAYCE_cb) => {
    amountType = amountType_cb;
    amount = amount_cb;
    priceAYCE = priceAYCE_cb;
  });
  let newSaleItemIndex;
  let newSaleItemList = getState().controleVendaReducer.newSaleItemList;
  // Verifica se deve criar um novo item de venda ou alterar um novo item de venda existente
  switch (operation) {
    // Criar novo item de venda
    case NEW_SALE_ITEM_AMOUNT_OPERATION_ADD:

      // Busca o índice do novo item de venda
      newSaleItemIndex = newSaleItemList
        .findIndex(item => (item.itemVenda.produto.codigo === newSaleItem.code) && (item.sequence === newSaleItem.sequence));

      // Atualiza o novo item de venda
      newSaleItem = updateNewSaleItem(newSaleItem, priceAYCE, amountType, amount, true, date);

      if (newSaleItemIndex >= 0) {
        // Atualiza a lista de novos itens de venda com o novo item de venda atualizado
        newSaleItemList = setIndexReturnNewArray(newSaleItemList, newSaleItemIndex, newSaleItem);
      }
      else {
        newSaleItem = updateNewSaleItemCombinado(newSaleItem, amount, true, date);
        newSaleItemList = addAfterReturnNewArray(newSaleItemList, newSaleItemIndex, newSaleItem);
      }

      // Se for produto vendido por peso, adiciona um novo item de venda sem quantidade
      if (newSaleItem.itemVenda.produto.quantidadePesada) {

        newSaleItemList = addAfterReturnNewArray(newSaleItemList, newSaleItemIndex,
          buildNewSaleItem(newSaleItem.itemVenda.produto,
            // Busca os novos itens de venda do mesmo produto para cálculo do número de sequência
            newSaleItemList.filter(item => newSaleItem.code === item.itemVenda.produto.codigo)
              // Busca o maior número de sequência para adicionar o novo item de venda com um número de sequência maior
              .reduce((previousSequence, currentItem) => Math.max(previousSequence, currentItem.sequence), 0) + 1,
            undefined,
            getState().empresaSelectorReducer.cargo,
            ((((getState().controleVendaReducer.origemVendaList || []).find(() => true) || {})
              .empresa || {})
              .parametrosEmpresa || {})
              .obrigarPagarVendaUsuarioSemEmpresa));
      }
      break;
    // Alterar novo item de venda
    case NEW_SALE_ITEM_AMOUNT_OPERATION_SET:
      // Busca o índice do novo item de venda
      newSaleItemIndex = newSaleItemList
        .findIndex(item => (item.itemVenda.produto.codigo === newSaleItem.code) && (item.sequence === newSaleItem.sequence));
      // Atualiza o novo item de venda
      newSaleItem = updateNewSaleItem(newSaleItem, priceAYCE, amountType, amount, false);
      // Atualiza a lista de novos itens de venda com o novo item de venda atualizado
      newSaleItemList = setIndexReturnNewArray(newSaleItemList, newSaleItemIndex, newSaleItem);
      break;
    default:
      log('manutencaoVendaAction addNewSaleItemOrSetAmount', 'error', { operation });
      return;
  }
  // Atualiza a lista de novos itens de venda no reducer
  dispatch({
    type: actionTypes.MANUTENCAO_VENDA_ADICIONA_REMOVE_ITEM_VENDA,
    newSaleItemList
  });
};

/**
 * Remove do `reducer` um novo item de venda (ainda não enviado à retaguarda).
 * @param {Number} sequence
 */
export const removeNewSaleItem = newSaleItem => (dispatch, getState) => {
  log('manutencaoVendaAction removeNewSaleItem', { newSaleItem });
  let newSaleItemList = getState().controleVendaReducer.newSaleItemList;
  // Busca o índice do novo item de venda
  let newSaleItemIndex = newSaleItemList
    .findIndex(item => (item.itemVenda.produto.codigo === newSaleItem.code) && (item.sequence === newSaleItem.sequence));
  // Se o produto for vendido por peso, basta remover ele da lista de novos itens de venda.
  if (newSaleItem.itemVenda.produto.quantidadePesada) {
    newSaleItemList = newSaleItemList.filter((item, index) => newSaleItemIndex !== index);
  }
  // Se o produto for vendido por unidade, o novo item de venda deve ser limpo.
  else {
    // Atualiza a lista de novos itens de venda com o novo item de venda atualizado
    newSaleItemList = setIndexReturnNewArray(
      newSaleItemList, newSaleItemIndex, buildNewSaleItem(newSaleItem.itemVenda.produto,
        0,
        newSaleItem.itemVenda.observacao,
        getState().empresaSelectorReducer.cargo,
        ((((getState().controleVendaReducer.origemVendaList || []).find(() => true) || {})
          .empresa || {})
          .parametrosEmpresa || {})
          .obrigarPagarVendaUsuarioSemEmpresa));
  }
  // Atualiza a lista de novos itens de venda no reducer
  dispatch({
    type: actionTypes.MANUTENCAO_VENDA_ADICIONA_REMOVE_ITEM_VENDA,
    newSaleItemList
  });
};

/**
 * Marca a venda e seus itens como cancelada e atualiza os dados da venda no `reducer`.
 * 
 * Se era uma nova venda, ela é excluida. Volta para a tela de vendas da origem ou para a tela de origens em uso.
 * @param {String} vendaURI URI da venda a ser cancelada
 * @param {String} motivoCancelamento motivo do cancelamento
 */
export const cancelSale = (vendaURI, motivoCancelamento, options = { then: (response) => { } }) => (dispatch, getState) => {
  log('manutencaoVendaAction cancelSale', { vendaURI, motivoCancelamento });

  dispatch(appActions.appSpinnerShow('cancelSale'));

  axios().post(
    vendaURI + '/cancel',
    { motivoCancelamento },
    getHeaders({
      // Se for um usuário sem vínculo com empresa
      ...(getState().empresaSelectorReducer.cargo ? {} : { noCompany: true })
    })
  )
    .then(response => {
      // Verifica se a retaguarda retornou algo como deveria
      if ((!response) || (!(response.data))) {
        throw new Error();
      }

      const mensagem = response.data.tipoOrigemVenda === TIPO_ORIGEM_VENDA_IFOOD ? getStrings().cancellationRequestSent : getStrings().saleCancelled();

      // Exibe notificação
      dispatch(appActions.appNotificationShow(mensagem, actionTypes.APP_NOTIFICATION_TYPE_SUCCESS, getStrings().success));

      // Se for usuário sem vínculo com empresa, inicia outra venda.
      if (!getState().empresaSelectorReducer.cargo) {
        dispatch(controleVendaAction.setupSaleNoCompany());
      }
      // Se for empresa do ramo Painel
      else if (getState().empresaSelectorReducer.ramoEmpresa === RamoEmpresa.PAINEL) {
        // Inicia nova venda
        dispatch(controleVendaAction.exibeManutencaoVendaPainel());
      }
      // Senão, decide o que fazer de acordo com o retorno do método.
      // Se houver uma origem de venda com quantidade de venda não nula, exibe as vendas da origem.
      else if (response.data.quantidadeVendas) {
        dispatch(controleVendaAction.exibeGridVendaFromOrigem(response.data));
      }
      // Senão, se houver uma origem de venda com quantidade de venda nula, exibe as origens em uso.
      else if (response.data.quantidadeVendas === 0) {
        dispatch(controleVendaAction.exibeGridOrigemVendaEmUso());
      }

      options.then && options.then(response);
    })
    .catch(error => dispatch(errorUtils.requestErrorHandlerDefault(error)))
    .finally(() => dispatch(appActions.appSpinnerHide('cancelSale')));
};

/**
 * O que fazer com o retorno de requisições que atualizam itens de venda.
 * @param {*} promise alguma requisição chamada usando `axios`
 * @param {*} dispatch 
 * @param {*} getState 
 * @param {*} methodName nome do método para ocultar *spinner*
 */
export const saleItemUpdateTreatment = (promise, dispatch, getState, methodName) => {
  promise.then(resposta => {
    // Exibe notificação
    dispatch(appActions.appNotificationShow(getStrings().itemsUpdated,
      actionTypes.APP_NOTIFICATION_TYPE_SUCCESS, getStrings().success, null));

    if (resposta.data) {

      dispatch({
        type: actionTypes.CONTROLE_VENDA_REMOVE_ITEM_VENDA,
        // Atualiza a lista de origens de venda para que a única origem nesta lista
        // tenha somente a venda selecionada em sua lista de vendas
        origemVendaList: getState().controleVendaReducer.origemVendaList.map(origemVenda =>
          Object.assign({}, origemVenda, {
            vendaList: origemVenda.vendaList.map(venda =>
              // Atualiza os itens da venda na venda recebida por propriedade
              Object.assign({}, venda, { itemVendaList: resposta.data.itemVendaList })
            )
          })
        ),
        clearTotal: actionTypes.REDUCER_UPDATE,
        commission: {
          value: getState().pagamentoVendasReducer.percentualComissaoAtual,
          action: actionTypes.REDUCER_HOLD
        },
        discount: {
          type: getState().pagamentoVendasReducer.discountType,
          value: getState().pagamentoVendasReducer.discountValue,
          action: actionTypes.REDUCER_HOLD
        },
        newSalePaymentItemMap: actionTypes.REDUCER_SET,
        nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
        ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
        remainingAmountMap: actionTypes.REDUCER_SET,
        sentSaleItemList: actionTypes.REDUCER_SET,
        sentSalePaymentItemMap: actionTypes.REDUCER_HOLD
      });

      if (resposta.data.estadoVenda === ESTADO_VENDA_CANCELADO && !getState().empresaSelectorReducer.cargo) {
        dispatch(controleVendaAction.setupSaleNoCompany());
      }
    }
  })
    .catch(error =>
      dispatch(errorResponse(error))
    )
    .finally(() =>
      dispatch(appActions.appSpinnerHide(methodName))
    );
}

/**
 * Atualiza a observação de um item de venda enviado.
 * @param {Object} sentSaleItem um item de venda sem todas as variáveis
 * @param {String} observacao nova observação
 */
export const updateSentSaleItemObservation = (sentSaleItem, observacao) => (dispatch, getState) => {
  log('manutencaoVendaAction updateSentSaleItemObservation', sentSaleItem);

  dispatch(appActions.appSpinnerShow('updateSentSaleItemObservation'));

  saleItemUpdateTreatment(axios().post(
    getURIFromEntity(sentSaleItem),
    {
      observacao
    },
    getHeaders({
      // Se for um usuário sem vínculo com empresa
      ...(getState().empresaSelectorReducer.cargo ? {} : { noCompany: true })
    })
  ), dispatch, getState, 'updateSentSaleItemObservation');
};

/**
 * Armazena no *local storage* os produtos selecionados de uma venda feita por um usuário não logado
 * e redireciona o usuário a fazer o cadastro.
 */
export const storeSale = () => (dispatch, getState) => {
  log('manutencaoVendaAction storeSale');

  // Busca a origem de venda escaneada
  let origemVenda = (getState().controleVendaReducer.origemVendaList || []).find(() => true) || {};
  let origemVendaURI = getURIFromEntity(origemVenda);
  // Busca os novos itens de venda armazenados no local storage
  let newSaleItemListMap = getNewSaleItemListMapStorage();
  // Busca os novos itens da venda e sobrescreve os novos itens de venda para esta origem de venda
  newSaleItemListMap[origemVendaURI] = getState().controleVendaReducer.newSaleItemList;
  // Atualiza o local storage
  localStorage.setItem('newSaleItemListMap', JSON.stringify(newSaleItemListMap));
  // Armazena a origem de venda para retomar o pedido ao confirmar o cadastro.
  setSaleSourceNoCompany({ origemVendaURI, numeroPessoas: ((origemVenda.vendaList || []).find(() => true) || {}).numeroPessoas });
  // Redireciona para o usuário entrar ou realizar cadastro para seguir com o pedido
  dispatch(push('/login?noCompany=true'));
  // Avisa sobre o fato
  setTimeout(() =>
    dispatch(appActions.appNotificationShow(getStrings().noAnonymousOrder, actionTypes.APP_NOTIFICATION_TYPE_INFO, getStrings().order(1)))
    , 0);
};

/**
 * Solicita uma leitura de peso da balança para adicionar um produto em uma venda usando o peso lido.
 * 
 * @param {*} uri 
 */
export const orderUsingScales = uri => dispatch => {
  log('manutencaoVendaAction orderUsingScales', { uri });

  dispatch(appActions.appSpinnerShow('manutencaoVendaAction orderUsingScales'));

  axios().get(
    `${uri}/weight`,
    getHeaders()
  )
    .then(() =>
      dispatch(appActions.appNotificationShow(getStrings().waitForScales, actionTypes.APP_NOTIFICATION_TYPE_INFO))
    )
    .catch(error =>
      dispatch(errorResponse(error))
    )
    .finally(() =>
      dispatch(appActions.appSpinnerHide('manutencaoVendaAction orderUsingScales'))
    );
};

/**
 * Adiciona um novo item em um produto com quantidade pesada usando o peso lido em uma balança.
 * @param {String} productURI identifica o produto a ser inserido
 * @param {Number} weight peso lida da balança
 * @param {Object} date data de inserção do item, quando vier de leitura de peso da balança
 */
export const addNewSaleItemUsingWeight = (productURI, weight, date) => (dispatch, getState) => {
  log('manutencaoVendaAction addNewSaleItemUsingWeight', { productURI, weight, date });
  // Confirma que recebeu a mensagem
  dispatch(appActions.confirmMessage('role', ADD_PRODUCT_WITH_WEIGHT));
  // Se não estiver na manutenção de venda, faz mais nada.
  if (!getState().manutencaoVendaReducer.visible) {
    return;
  }
  // Prepara método para buscar item pelo seu URI
  let byUri = (item, uri) => getURIFromEntity(((item || {}).itemVenda || {}).produto || {}) === uri;
  // Busca item do mesmo produto adicionado na mesma hora que a leitura de peso
  let newSaleItem = ((getState().controleVendaReducer || {}).newSaleItemList || []).find(item =>
    // Busca aquele que tiver o mesmo URI e que foi adicionado no mesmo momento
    byUri(item, productURI) && moment(date).isSame((((item || {}).itemVenda || {}).dataHora)))
  // Se item já foi adicionado anteriormente, faz mais nada.
  if (newSaleItem) {
    return;
  }
  // Busca o newSaleItem que corresponde ao produto
  newSaleItem = ((getState().controleVendaReducer || {}).newSaleItemList || []).find(item =>
    // Busca aquele que tiver o mesmo URI e que tiver quantidade zerada
    byUri(item, productURI) && (!((item || {}).itemVenda || {}).quantidade))
  // Adiciona o produto com o peso lido da balança
  dispatch(addNewSaleItemOrSetAmount(NEW_SALE_ITEM_AMOUNT_OPERATION_ADD, newSaleItem, NEW_SALE_ITEM_AMOUNT_TYPE_WEIGHTED, weight, null, moment(date).toDate()));
  // Avisa o usuário
  dispatch(appActions.appNotificationShow(getStrings().productAddedWithWeight));
};

/**
 * Define no *reducer* o valor informado na variável informada.
 * @param value novo valor da variável
 * @param position nome da variável
 */
export const setValue = (value, position) => dispatch => {
  log('manutencaoVendaAction setValue', { value, position });

  dispatch({
    type: actionTypes.MANUTENCAO_VENDA_EXIBE,
    [position]: value
  });
};
