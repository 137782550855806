import React from "react";
import styled from "styled-components";
import { TitleSub, Paragraph, ConteudoSection } from ".";
import { PATH_IMAGENS_FOTOS, PATH_IMAGENS_PRINTS } from "../utils/resources";
import { ImagensContainer, ImgRounded, PrintTablet } from "./Containers";

const PedidosAprovados = React.forwardRef((props, ref) => {
  return (
    <ConteudoSection ref={ref}>
      <TitleSub primary>Pedidos após aprovados entram direto para a produção</TitleSub>
      <Paragraph>Tenha controle e agilidade na produção de seus pedidos</Paragraph>
      <ImagensContainer height={"310px"}>
        <Print src={PATH_IMAGENS_PRINTS + "producao1.png"} alt={"Producao"} />
        <Print src={PATH_IMAGENS_PRINTS + "producao2.png"} alt={"Producao 2"} />
        <ImgRoundLeft src={PATH_IMAGENS_FOTOS + "producao3.png"} alt={"Carregamento Embalagens"} />
        <ImgRoundRight src={PATH_IMAGENS_FOTOS + "producao4.png"} alt={"Cozinheiro Pimenta"} />
      </ImagensContainer>
    </ConteudoSection>
  );
});

const Print = styled(PrintTablet)`
  position: absolute;
  z-index: 20;
  &:nth-child(1) {
    top: 60px;
    @media (max-width: ${(props) => props.theme.sizes.breakPointSmall}) {
      top: 74px;
    }
  }
  &:nth-child(2) {
    top: 120px;
    z-index: 22;
  }
`;

const ImgRoundRight = styled(ImgRounded)`
  right: 0;
  top: 0;
  z-index: 21;
`;

const ImgRoundLeft = styled(ImgRounded)`
  left: 0;
  bottom: 0;
  z-index: 22;
`;

export default PedidosAprovados;
