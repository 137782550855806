import React from "react"
import { Button } from "./Button"
import Icones from "../../../assets/icones/Icones"

export type CopyToClipboardButtonType = {
  valueToCopy: string,

  link?: boolean,
  notPropagate?: boolean,
  notWrap?: boolean,
}

function CopyToClipboardButton_(props: CopyToClipboardButtonType) {
  const MdContentCopy = Icones.md.MdContentCopy;

  return <div className="inline-block" style={{whiteSpace: props.notWrap ? "nowrap" : undefined}}>
    {props.link
      ? <a href={props.valueToCopy} style={{marginRight: "0.4em"}} >{props.valueToCopy}</a>
      : null}
    <Button style={{
      width: "2em",
      height: "2em",
      padding: "0.2em",
      background: "white",
      color: "var(--color-secondary)",
      border: "0.1em solid var(--color-secondary)"
    }} onClick={(evt) => {
      if (props.notPropagate) {
        evt.stopPropagation();
        evt.preventDefault();
      }

      window.navigator.clipboard.writeText(props.valueToCopy);
    }} >
      <MdContentCopy />
    </Button>
  </div>;
}

export const CopyToClipboardButton = CopyToClipboardButton_;
export default CopyToClipboardButton;