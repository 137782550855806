import styled from "styled-components";
import { displayFlexCenter } from "../../css/styles";

const ConteudoSection = styled.div`
  ${displayFlexCenter}
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 1rem;
  &:nth-child(odd) {
    background: ${(props) => props.theme.lightBackgroundColor2};
  }
`;

export default ConteudoSection;
