import { paisesReducerTipo, paisReducerTipo } from "./paisesReducer";
import criarAction from "../factory/criarAction";

const actionTypes = {
  ATUALIZAR_PAIS: 'ATUALIZAR_PAIS:PAISES',
  POPULAR_PAISES: 'POPULAR_PAISES:PAISES',
};

export default actionTypes;

export const atualizarPais = (carga: {id: string, pais: paisReducerTipo}) => function(dispatch: (carga: any) => any) {
  dispatch(criarAction({...carga, id: carga.id}, actionTypes.ATUALIZAR_PAIS));
}

export const polularPaises = (carga: paisesReducerTipo) => function(dispatch: (carga: any) => any) {
  dispatch(criarAction(carga, actionTypes.POPULAR_PAISES));
}
