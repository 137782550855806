import React from 'react';
import { Async } from 'react-select';

/**
 * Componente de combobox assíncrono (dados vão carregando conforme o usuário digitar no campo). 
 */
export default class SelectFieldAsync extends React.Component {
    state = {
        value: null
    };

    /**
     * Método que retorna a opção selecionada (lê do estado). 
     */
    getValue = () => {
        return this.state.value;
    }

    /**
     * Retorna se há uma opção selecionada (só um objeto).
     */
    isValueSelected = (value) => {
        return !!value;
    }

    /**
     * Retorna se há uma opção selecionada (só um objeto; lê das propriedades, que atualiza antes do estado).
     */
    isValueSelected_props = () => {
        return this.isValueSelected(this.selectField.props.value);
    }

    /**
     * Retorna se há uma opção selecionada (só um objeto; lê do estado, que atualiza depois das propriedades).
     */
    isValueSelected_state = () => {
        return this.isValueSelected(this.getValue());
    }

    /**
     * Método executado para definir os valores iniciais do Select
     * @param {*} newValue 
     * @param {*} key 
     */
    updateValue = (newValue) => {
        this.setState({ value: newValue });
    }

    /**
     * Método executado ao selecionar uma opção na lista. 
     * @param {*} newValue 
     */
    changeHandler = (newValue) => {
        if (this.props.onChange) {
            this.props.onChange(newValue);
        }
        this.setState({ value: newValue });
    }

    render() {

        const { value } = this.state;

        return (
            <Async
                placeholder={this.props.placeholder}
                value={value}
                ref={(input) => { this.selectField = input; }}
                required={this.props.required}
                loadOptions={this.props.loadOptions}
                filterOption={() => true}
                onChange={this.changeHandler}
                inputProps={this.props.inputProps}
                onInputChange={this.props.onInputChange}
                onBlur={this.props.onBlur}
                tabIndex={this.props.tabIndex}
                className='Lighter'
            />
        )
    }
}