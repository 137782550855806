import "../../UI/Template/AbasTemplate.css"

import React from "react"
import { connect } from "react-redux"
import moment from "moment"
import { ClienteHttp } from "../../../apps/promo_app_frontend/nucleo/utils/utils"
import { nullOrUndefined } from "../../../utils/ComparatorsUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { updateActiveMenuItem } from "../../../utils/ScreenUtils"
import { teclaHandler } from "../../../utils/tecladoUtils/TecladoUtils"
import { getIdFromEntity, getURIFromEntity } from "../../../utils/URIUtils"
import { MODO_COBRANCA_TELE_ENTREGA_POR_DISTANCIA } from "../../../store/reducers/empresaSelectorReducer"
import { TELA_PEDIDO_ATUAL, TELA_PEDIDOS_ABERTOS, TELA_PEDIDOS_FATURADOS } from "../../../store/reducers/producaoReducer"
import {
  setPrinted
  , loadOrigensEmUso
  , exibeManutencaoVenda
} from "../../../store/actions/controleVenda/controleVendaAction"
import { exibeTelaPagamentoPedido } from "../../../store/actions/controleVenda/pagamentoVendasAction"
import { loadItensVendaAbertos, atualizarTelaAtual } from "../../../store/actions/producaoAction"
import AbasTemplate from "../../UI/Template/AbasTemplate"
import BoxTemplate from "../../UI/Template/BoxTemplate"
import CardPedido from "./CardPedido"
import PedidosToolbar from "./PedidosToolbar"
import VendaTela from "./VendaTela"
import paginacaoTipo from "../../Paginacao/Tipos/paginacaoTipo"
import ProducaoFiltro from "../../producao/filtro/ProducaoFiltro"
import ProducaoOrdenacao from "../../producao/filtro/ProducaoOrdenacao"
import PedidosModal from "./PedidosModal"


type pedidosTelaTipo = {
  controleVendaReducer: any
  , dispatch: (carga: any) => any
  , permissoesCargo: string[]
  , producaoReducer: any
  , tabIndex: number
};

export type modoTipo = '' | 'venda' | 'cadastrar_comanda' | 'exibe_itens_venda' | 'pagamento_venda_content_logica' | 'pagamento_venda_content'

export type pedidosTelaEstadoTipo = {
  editar: any
  , editandoPedido: boolean
  , totalVendasAnteriores: number
  , poucoTempo: string
  , pouquissimoTempo: string
  , modo: modoTipo
  , faturada: boolean
  , modoSelecao: boolean
  , selecao: Array<any>
  , selecionarTudo: boolean
  , setAllDisabled: boolean
  , ordenarIsOpen: boolean
  , filtrarIsOpen: boolean
  , cadastroComanda: {
    preCadastroUsuario: {
      id: string | null
      , telefone: string
      , nome: string
      , endereco: {
        bairro: string
        , cep: string
        , pais: string | null
        , uf: string
        , cidade: string
        , endereco: string
        , numero: string
        , complemento: string
        , pontoReferencia: string
      }
    }
    entregaVenda: {
      id: string | null
      , tempoEntrega: number | null
      , formaPagamento: any
      , horaEntrega: string | moment.Moment
      , teleEntrega: boolean | null
      , valorTeleEntrega: string | null
      , dataHoraEntrega: string | moment.Moment
      , fusoHorario: string
      , tempoProducao: number | null
      , valorCupom: string | null
      , valorPago: string
      , valorPagoDigitado: string | null
      , observacao: string | null
    }
  }
  , naoCarregarProdutos: boolean
  , tipoOrigemVendas: Array<string>
  , tipoOrigem: string
  , usuarioCliente: boolean
  , valorItensSelecionados: number
  , parametrosEmpresa: {
    modoCobrancaTeleEntrega: string
    , valorTeleEntregaPorKm: number
  }
};

type toolbarConfigTipo = {
  atualizarEstado: (novoEstado: any) => void | undefined
  , buscarEstado: () => pedidosTelaEstadoTipo
  , dispatch: (state: any) => any
  , estadoInicial: pedidosTelaEstadoTipo
  , imprimirSelecionados: (selecionados: Array<number>) => void | undefined
  , permissoesCargo: string[]
};

const estadoInicial: pedidosTelaEstadoTipo = {
  editar: null
  , editandoPedido: false
  , totalVendasAnteriores: 0
  , poucoTempo: ''
  , pouquissimoTempo: ''
  , modo: 'venda'
  , faturada: false
  , modoSelecao: false
  , selecao: []
  , selecionarTudo: false
  , setAllDisabled: true
  , ordenarIsOpen: false
  , filtrarIsOpen: false
  , cadastroComanda: {
    preCadastroUsuario: {
      id: null
      , nome: ''
      , telefone: '519'
      , endereco: {
        bairro: ''
        , cep: ''
        , cidade: ''
        , complemento: ''
        , endereco: ''
        , numero: ''
        , pais: null
        , uf: ''
        , pontoReferencia: ''
      }
    }
    , entregaVenda: {
      id: null
      , dataHoraEntrega: moment()
      , fusoHorario: moment().format('Z')
      , formaPagamento: null
      , tempoEntrega: null
      , horaEntrega: moment()
      , teleEntrega: true
      , tempoProducao: null
      , valorTeleEntrega: null
      , valorCupom: null
      , valorPago: '0,00'
      , valorPagoDigitado: null
      , observacao: null
    }
  }
  , tipoOrigemVendas: []
  , naoCarregarProdutos: false
  , tipoOrigem: ''
  , usuarioCliente: false
  , valorItensSelecionados: 0
  , parametrosEmpresa: {
    modoCobrancaTeleEntrega: MODO_COBRANCA_TELE_ENTREGA_POR_DISTANCIA
    , valorTeleEntregaPorKm: 0
  }
};

class PedidosTela extends React.Component<pedidosTelaTipo> {

  state: pedidosTelaEstadoTipo = estadoInicial;

  toolbarConfig: toolbarConfigTipo = {
    atualizarEstado: (novoEstado: any) => this.setState(novoEstado)
    , buscarEstado: () => ({ ...this.state })
    , dispatch: this.props.dispatch
    , estadoInicial: estadoInicial
    , imprimirSelecionados: (selecionados: Array<number>) => {
      this.props.dispatch(setPrinted(selecionados, false));
    }
    , permissoesCargo: this.props.permissoesCargo
  };

  atualizarParametrosEmpresa = () => {
    ClienteHttp.requisicaoServidor('empresas/getParametrosEmpresa', 'get', true)
      .then((resposta: any) => {
        const parametrosEmpresa = this.state.parametrosEmpresa;
        this.setState({
          parametrosEmpresa: {
            ...parametrosEmpresa
            , modoCobrancaTeleEntrega: resposta.data.modoCobrancaTeleEntrega
            , valorTeleEntregaPorKm: resposta.data.valorTeleEntregaPorKm
          }
        });
      });
  }

  atualizarTempos = () => {
    const tempoEntrega_ = this.state.cadastroComanda.entregaVenda.tempoEntrega;
    const tempoProducao_ = this.state.cadastroComanda.entregaVenda.tempoProducao;

    if (nullOrUndefined(tempoEntrega_) || nullOrUndefined(tempoProducao_)) {
      ClienteHttp.requisicaoServidor('getParametrosUsuarioLogado', 'get', true)
        .then((resposta: any) => {
          const parametrosUsuario = resposta.data;
          const tempoEntrega = !nullOrUndefined(tempoEntrega_)
            ? tempoEntrega_
            : parseInt(`${parametrosUsuario?.tempoEntrega || 0}`);
          const tempoProducao = !nullOrUndefined(tempoProducao_)
            ? tempoProducao_
            : parseInt(`${parametrosUsuario?.tempoProducao || 0}`);
          const horaEntrega = moment().add(tempoEntrega || '0', 'minute');

          this.setState({
            cadastroComanda: {
              ...this.state.cadastroComanda
              , entregaVenda: {
                ...this.state.cadastroComanda.entregaVenda
                , tempoEntrega
                , tempoProducao
                , horaEntrega
              }
            }
          });
        });
    }
  }

  mantemHoraEntregaAtualizada = setInterval(() => {
    this.atualizarHoraEntrega();
  }, 1000);

  atualizarHoraEntrega = () => {
    const horaEntrega = moment().add(this.state.cadastroComanda.entregaVenda.tempoEntrega || '0', 'minute');
    const horaAtual = typeof this.state.cadastroComanda.entregaVenda.horaEntrega === 'object' ? this.state.cadastroComanda.entregaVenda.horaEntrega.format('YYYY-MM-DD HH:mm') : null;
    const horaNova = horaEntrega.format('YYYY-MM-DD HH:mm');

    if (horaAtual && horaAtual !== horaNova) {

      // console.log(moment().format());
      // console.log("hora atual: " + horaAtual);
      // console.log("hora nova:  " + horaNova);
      // console.log("\n");

      this.setState({
        setAllDisabled: false
        , cadastroComanda: {
          ...this.state.cadastroComanda
          , entregaVenda: {
            ...this.state.cadastroComanda.entregaVenda
            , horaEntrega
          }
        }
      });
    }
  }

  vendaList: Array<any> = []
  keyHandler: any;

  observer = (evt: KeyboardEvent) => {
    if (evt.key === 'Escape' && ['exibe_itens_venda', 'cadastrar_comanda', 'pagamento_venda_content'].includes(this.state.modo) && evt.key === 'Escape') {
      this.setState({ modo: ['pagamento_venda_content'].includes(this.state.modo) ? '' : 'venda' });
    }
  }

  componentDidMount() {
    updateActiveMenuItem('menuItemComandas', 'menuItemCadastro');

    this.atualizarTempos();

    this.atualizarParametrosEmpresa();

    this.setState({ setAllDisabled: false, modo: this.props.permissoesCargo.includes('vendaEnvio') ? 'venda' : '' });

    this.keyHandler = window.addEventListener('keydown', (evt: any) => {
      if (['exibe_itens_venda', 'cadastrar_comanda', 'pagamento_venda_content'].includes(this.state.modo) && evt.key === 'Escape') {
        this.setState({ modo: ['pagamento_venda_content'].includes(this.state.modo) ? '' : 'venda' })
      }
    })
  }

  componentDidUpdate(prevProps: pedidosTelaTipo, prevState: pedidosTelaEstadoTipo) {

    const novaTelaAtual = this.state.faturada ? TELA_PEDIDOS_FATURADOS : TELA_PEDIDOS_ABERTOS;

    if (this.state.modo === '' && this.props.producaoReducer.telaAtual !== novaTelaAtual) {
      this.props.dispatch(atualizarTelaAtual(novaTelaAtual));
    }
    else if (this.state.modo === 'venda' && this.props.producaoReducer.telaAtual !== TELA_PEDIDO_ATUAL) {
      this.props.dispatch(atualizarTelaAtual(TELA_PEDIDO_ATUAL));
    }

    if ((this.state.modo === '' && prevState.modo !== '') || (this.state.modo === 'venda' && prevState.modo !== 'venda')
      || (prevState.faturada !== this.state.faturada)
      || ((!prevProps || !prevProps.producaoReducer.visible) && this.props.producaoReducer.visible)
      || (prevProps && prevProps.producaoReducer.agruparProdutosVenda !== this.props.producaoReducer.agruparProdutosVenda)
      || (prevProps && prevProps.producaoReducer.mostrarEncerrados !== this.props.producaoReducer.mostrarEncerrados)
      || (prevProps && prevProps.producaoReducer.ordem !== this.props.producaoReducer.ordem)) {
      this.props.dispatch(loadItensVendaAbertos());
    }

    if (prevState.parametrosEmpresa.modoCobrancaTeleEntrega !== this.state.parametrosEmpresa.modoCobrancaTeleEntrega
      || prevState.parametrosEmpresa.modoCobrancaTeleEntrega !== this.state.parametrosEmpresa.modoCobrancaTeleEntrega) {
      this.atualizarParametrosEmpresa();
    }

    if (prevState.cadastroComanda.entregaVenda.tempoEntrega !== this.state.cadastroComanda.entregaVenda.tempoEntrega
      || prevState.cadastroComanda.entregaVenda.tempoProducao !== this.state.cadastroComanda.entregaVenda.tempoProducao) {
      this.atualizarTempos();
    }

    if (['exibe_itens_venda', 'cadastrar_comanda', 'pagamento_venda_content'].includes(this.state.modo)) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'unset';
    }

    if (prevState.modo !== 'pagamento_venda_content_logica' && this.state.modo === 'pagamento_venda_content_logica') {
      const vendasSelecionadas = this.props.producaoReducer.vendaList.filter((venda: any) =>
        this.state.selecao.includes(getIdFromEntity(venda)));
      const origensVendas_ = vendasSelecionadas
        .map((venda: any) => venda.origemVenda);
      const origensVendas: any = origensVendas_.filter((origemVenda: any, index: number) => origensVendas_.indexOf(origemVenda) === index);

      const origemVendaVendaList: any = {};

      vendasSelecionadas.forEach((venda: any) => {
        if (origemVendaVendaList[getURIFromEntity(venda.origemVenda)]) {
          origemVendaVendaList[getURIFromEntity(venda.origemVenda)].push(getURIFromEntity(venda))
        }
        else {
          origemVendaVendaList[getURIFromEntity(venda.origemVenda)] = [getURIFromEntity(venda)];
        }
      })

      this.props.dispatch(
        exibeTelaPagamentoPedido(
          origensVendas,
          origemVendaVendaList,
          { cb: () => { this.setState({ modo: 'pagamento_venda_content' }) } }
        )
      );
    }

    if (this.state.editar !== null) {
      this.editar(this.state.editar);
    }
  }

  componentWillUnmount() {
    teclaHandler.unsubscribe(this.observer)
    clearInterval(this.mantemHoraEntregaAtualizada);
  }

  editar = (venda_: any) => {
    const { clientePreCadastrado, usuarioCliente, vendaId, ...venda } = venda_;
    const diaEntregaVenda = moment(venda.entregaVenda?.dataHoraEntrega);
    const dataAtual = moment();
    const tempoEntrega = diaEntregaVenda.diff(dataAtual, 'minutes');
    const entregaVenda = (usuarioCliente ? { ...venda.entregaVenda, tempoEntrega } : venda.entregaVenda) || {};
    const endereco = (clientePreCadastrado || {}).enderecoUsuario || {};
    const { enderecoUsuario, ...preCadastroUsuarioSemEndereco } = (clientePreCadastrado || {});
    const totalVendasAnteriores = [{ valorTotal: 0 }, ...venda.itemVendaList].map((itemVenda) => itemVenda.valorTotal).reduce((valorTotalAnterior, valorTotal) => valorTotalAnterior + valorTotal);
    const novoEstado = {
      totalVendasAnteriores
      , modo: 'venda'
      , editar: null
      , usuarioCliente: !!usuarioCliente
      , tipoOrigem: getStrings().saleSourceTypeEnumToString(venda.origemVenda.nome)
      , cadastroComanda: {
        entregaVenda: {
          ...entregaVenda
          , teleEntrega: entregaVenda.teleEntrega
          , horaEntrega: moment().add(usuarioCliente ? entregaVenda.tempoEntrega : this.state.cadastroComanda.entregaVenda.tempoEntrega || '0', 'minute')
          , dataHoraEntrega: moment().add(usuarioCliente ? entregaVenda.tempoEntrega : this.state.cadastroComanda.entregaVenda.tempoEntrega || '0', 'minute')
          , valorPago: parseFloat(`${entregaVenda.valorPago}`).toFixed(2).replace('.', ',')
          , valorCupom: parseFloat(`${entregaVenda.valorCupom}`).toFixed(2) === 'NaN'
            ? null
            : parseFloat(`${entregaVenda.valorCupom}`).toFixed(2).replace('.', ',')
          , valorTeleEntrega: parseFloat(`${entregaVenda.valorTeleEntrega}`).toFixed(2) === 'NaN'
            ? null
            : parseFloat(`${entregaVenda.valorTeleEntrega}`).toFixed(2).replace('.', ',')
          , tempoProducao: usuarioCliente ? entregaVenda.tempoEntrega : this.state.cadastroComanda.entregaVenda.tempoProducao
          , tempoEntrega: usuarioCliente ? entregaVenda.tempoEntrega : this.state.cadastroComanda.entregaVenda.tempoEntrega
        }
        , preCadastroUsuario: {
          ...preCadastroUsuarioSemEndereco
          , endereco: {
            ...endereco
            , pais: getURIFromEntity(endereco.pais)
            , cidade: endereco.municipio
          }
        }
      }
    };

    this.setState(novoEstado);
    this.props.dispatch(loadOrigensEmUso(venda.origemVenda.nome, undefined, {
      cb: () => {
        this.props.dispatch(exibeManutencaoVenda(venda));
      }
    }));
  }

  BotoesAbas = (props: { buscarEstado: () => pedidosTelaEstadoTipo, atualizarEstado: (novoEstado: any) => void | undefined, permissoesCargo: string[] }) => {
    const BotaoPedidoAtual = props.permissoesCargo.includes('vendaEnvio')
      ? <button
        key={`items_button`}
        id={`items-button`}
        disabled={(props.buscarEstado().modo === 'venda') || (!props.buscarEstado().selecao.length && props.buscarEstado().setAllDisabled)}
        onClick={() => props.atualizarEstado({ modo: 'venda' })}
      >
        {getStrings().currentOrder}
      </button>
      : null
    const BotaoPedidos = <button
      key={`pedidos_button`}
      id={`pedidos_button-button`}
      disabled={(props.buscarEstado().modo === '') || (!props.buscarEstado().selecao.length && props.buscarEstado().setAllDisabled)}
      onClick={() => props.atualizarEstado({
        modo: ''
        , naoCarregarProdutos: false
        , cadastroComanda: {
          preCadastroUsuario: {
            telefone: '519'
            , nome: ''
            , endereco: {
              bairro: ''
              , cep: ''
              , cidade: ''
              , complemento: ''
              , endereco: ''
              , numero: ''
              , pais: ''
              , uf: ''
            }
          }
          , entregaVenda: {
            dataHoraEntrega: moment()
            , fusoHorario: moment().format('Z')
            , formaPagamento: null
            , horaEntrega: moment()
            , teleEntrega: true
            , valorTeleEntrega: null
            , valorCupom: null
            , valorPago: null
            , valorPagoDigitado: null
          }
        }
      })} >
      {getStrings().orders}
    </button>
    return <>
      {BotaoPedidoAtual}
      {BotaoPedidos}
    </>;
  }

  _render(props: { paginacao?: paginacaoTipo, vendaList: Array<any>, buscarEstado: () => pedidosTelaEstadoTipo, atualizarEstado: (novoEstado: any) => void | undefined, toolbarConfig: toolbarConfigTipo, modoSelecao: boolean, modo: string, atualizarHoraEntrega: () => void }) {
    const modo = props.modo;

    if (!['', 'pagamento_venda_content_logica', 'pagamento_venda_content'].includes(modo)) {
      return <VendaTela {...props.toolbarConfig} atualizarEstado={props.atualizarEstado} buscarEstado={props.buscarEstado} atualizarHoraEntrega={props.atualizarHoraEntrega} goBack={() => { props.atualizarEstado({ modo: '' }); }} />;
    }

    return <>
      <BoxTemplate
        hide_header={true}
        toolbar={(customProps: any) => <PedidosToolbar {...{ ...customProps, ...props.toolbarConfig }} paginacao={props.paginacao} buscarEstado={props.buscarEstado} atualizarEstado={props.atualizarEstado} modoSelecao={props.modoSelecao} estadoInicial={estadoInicial} />}
      >

        {props.buscarEstado().ordenarIsOpen
          ? <ProducaoOrdenacao />
          : null
        }

        {props.buscarEstado().filtrarIsOpen
          ? <ProducaoFiltro />
          : null
        }

        <div className='card-template'>
          {props.vendaList.map((venda, index: number) =>

            <CardPedido
              key={`__card_pedido_${getURIFromEntity(venda)}_${index}__`}
              atualizarEstado={props.atualizarEstado}
              buscarEstado={props.buscarEstado}
              permissoesCargo={props.toolbarConfig.permissoesCargo}
              venda={{
                ...venda
                , entregaVenda: venda.entregaVenda
                  ? {
                    ...venda.entregaVenda
                    , id: props.buscarEstado().cadastroComanda.entregaVenda.id
                  }
                  : null
              }}
              vendaURI={getURIFromEntity(venda, false)}
              vendaId={getIdFromEntity(venda)}
              modoSelecao={props.buscarEstado().modoSelecao}
              selecionados={props.buscarEstado().selecao}
              atualizarSelecao={(id: any) => {
                const selecionados = props.buscarEstado().selecao;
                const selecao = props.buscarEstado().modoSelecao
                  ? selecionados.includes(id)
                    ? selecionados.filter((key) =>
                      key !== id
                    )
                    : [...selecionados, id]
                  : [id];

                props.atualizarEstado({ selecao });
              }}
            />
          )}
        </div>
      </BoxTemplate>
    </>;
  }

  render() {
    const RenderTemplate = this._render;

    const page = this.props.producaoReducer[this.props.producaoReducer.telaAtual].page;
    const links = this.props.producaoReducer.links;
    const paginacao = { links, page };

    return <>
      <PedidosModal modo={this.state.modo} setState={(state: Partial<pedidosTelaEstadoTipo>) => this.setState(state)} vendaList={this.vendaList} state={this.state} estadoInicialDoPedidosTela={estadoInicial} />
      <AbasTemplate botoesAbas={() => <this.BotoesAbas permissoesCargo={this.props.permissoesCargo} atualizarEstado={(novoEstado) => this.setState(novoEstado)} buscarEstado={() => ({ ...this.state })} />}>
        <RenderTemplate paginacao={paginacao} vendaList={this.props.producaoReducer.vendaList} modo={this.state.modo} buscarEstado={() => ({ ...this.state })} atualizarEstado={(novoEstado) => this.setState(novoEstado)} toolbarConfig={this.toolbarConfig} modoSelecao={this.state.modoSelecao} atualizarHoraEntrega={this.atualizarHoraEntrega} />
      </AbasTemplate>
    </>
  }
}

const mapStateToProps = (state: any) => {
  const props = {
    ...state.idiomaReducer
    , tabIndex: state.appReducer.getTabIndex()
    , controleVendaReducer: state.controleVendaReducer
    , producaoReducer: state.producaoReducer
    , permissoesCargo: state.empresaSelectorReducer.permissoesCargo
  }

  return props
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PedidosTela);
