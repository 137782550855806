//@ts-check
/**
 * 
 * @param {any} item 
 * @returns {Array<string>}
 */
function obterPropriedadeD(item) {
  if (item.elements[0].name === 'path') {
    return item.elements.map(
      /**
       * 
       * @param {any} path 
       * @returns {string}
       */
      (path) => path.attributes.d);
  }

  return obterPropriedadeD(item.elements[0]);
}

/**
 * 
 * @param {any} objetoXml 
 * @returns {{datasets: Array<string>, altura: number, largura: number}}
 */
export default  function(objetoXml) {
  const svg = (objetoXml.elements.filter(
    /**
     * 
     * @param {any} item 
     * @returns {boolean}
     */
    (item) => {
    return item.name === 'svg';
  }))[0];

  const datasets = obterPropriedadeD(svg);
  const [,, altura, largura] = svg.attributes.viewBox.split(' ');

  return {datasets, altura: parseFloat(altura), largura: parseFloat(largura)};
}
