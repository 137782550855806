import React from "react"
import { connect } from "react-redux"

import * as AuthUtils from "../../../utils/AuthUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"
import { formatNumber } from "../../../utils/NumberUtils"
import { getNomeProdutoFromItemVenda } from "../../../utils/MiscUtils"
import { formatHourMinuteFromDate } from "../../../utils/TimeUtils"

import * as appActions from "../../../store/actions/appAction"
import * as manutencaoVendaActions from "../../../store/actions/controleVenda/manutencao/manutencaoVendaAction"

import DialogObservacao from "../dialog/DialogObservacao"
import Icones from "../../../assets/icones/Icones"

import {
  ESTADO_ITEM_VENDA_PRODUZIDO
  , ESTADO_ITEM_VENDA_EM_PRODUCAO
  , ESTADO_ITEM_VENDA_AGUARDANDO
} from "../../../store/reducers/controleVenda/controleVendaReducer"
import { stateToColor } from "../../producao/grid/GridProducao"

/**
 * Função que monta a celula a partir do item da venda.
 */
const BuildCellFromItemVenda = (props) => {
  const mapStatesToClassName = (() => {
    let obj = {};

    obj[ESTADO_ITEM_VENDA_PRODUZIDO] = ' ready';
    obj[ESTADO_ITEM_VENDA_EM_PRODUCAO] = ' producing';
    obj[ESTADO_ITEM_VENDA_AGUARDANDO] = ' waiting';

    return obj;
  })();

  /**
   * Exibe o `dialog` para exibir/editar a observação do item de venda.
   */
  const showDialog = () => {

    log('BuildCellFromItemVenda(I) showDialog');

    props.appDialogShow(<DialogObservacao
      newSaleItem={props.newSaleItem}
      editable={props.isNovosItens}
    />, getStrings().observation);
  }

  /**
   * Remove o item de venda da venda. Se for um item persistido, pede confirmação antes. Aceitando a confirmação,
   * o item pode ter sua quantidade alterada ao invés de ser removido se já tiver sido parcialmente pago.
   */
  const removeItem = () => {

    log('BuildCellFromItemVenda(I) removeItem');

    if (props.isNovosItens) {
      props.removeNewSaleItem(props.newSaleItem);
    } else {
      props.handleRemoveSentSaleItem(props.itemVenda);
    }
  }

  const getClassName = () => {
    try {
      return mapStatesToClassName[props.itemVenda.estado];
    } catch {
      return '';
    }
  }

  const render = () => {
    log('BuildCellFromItemVenda(I) render');

    const GoTrashcan = Icones.go.GoTrashcan;
    const FaRegComment = Icones.fa.FaRegComment;

    if (!props.itemVenda) {
      return null;
    }

    let buttonItemClass = 'gray-button font-color grid-button table-button';

    // Busca o nome do produto
    let name = getNomeProdutoFromItemVenda(props.itemVenda) || props.produto.nome;
    // Se for um produto a kilo vendido como livre
    if (props.itemVenda.livre) {
      // Adiciona " (livre)" no nome
      name = getStrings().allYouCanEatName(name);
    }

    // Algumas das condições para exibir o botão ou não
    let newItemOrHasPermissionOrNeedNoPermission = props.isNovosItens
      || AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_EXCLUSAO])
      || (!props.isCargo);

    const stateColorClass = stateToColor(props.itemVenda.estado);

    if (!isAncientChromeMobile) {
      return <>
        <div className={`table-items table-row ${stateColorClass}`} >
          <div className='table-cell del ButtonAlign' >
            <button
              tabIndex={props.tabIndex}
              className={`${buttonItemClass} del-button${getClassName()}`}
              onClick={removeItem}
              disabled={!(newItemOrHasPermissionOrNeedNoPermission && props.removeEnabled)}
            >
              {newItemOrHasPermissionOrNeedNoPermission ? <GoTrashcan /> : undefined}
            </button>
          </div>
          <div className='table-cell product TextAlign' >
            {name}
          </div>
          <div className='table-cell price NumberAlign' >
            {formatNumber(props.itemVenda.precoUnitario, 2)}
          </div>
          <div className='table-cell amount NumberAlign' >
            {formatNumber(props.itemVenda.quantidade, 3)}
          </div>
          <div className='table-cell total NumberAlign' >
            {formatNumber(props.itemVenda.valorTotal, 2)}
          </div>
          <div className='table-cell time ButtonAlign' >
            {props.itemVenda.dataHora !== undefined ? formatHourMinuteFromDate(new Date(props.itemVenda.dataHora)) : '-- : --'}
          </div>
          <div className='table-cell obs ButtonAlign' >
            <button
              id='right-button'
              tabIndex={props.tabIndex}
              className={buttonItemClass}
              onClick={showDialog}
            ><FaRegComment /></button>
          </div>
        </div>
      </>;
    }

    return <>
      <div className='table-items table-row row ancient-chrome-mobile' >
        <div className='column left'>
          <div className='row'>
            <div className='table-cell del ButtonAlign' >
              <button
                tabIndex={props.tabIndex}
                className={`${buttonItemClass} del-button${getClassName()}`}
                onClick={removeItem}
                disabled={!(newItemOrHasPermissionOrNeedNoPermission && props.removeEnabled)}
              >
                {newItemOrHasPermissionOrNeedNoPermission ? <GoTrashcan /> : undefined}
              </button>
            </div>
            <div className='table-cell product TextAlign' >
              {name}
            </div>
          </div>
        </div>
        <div className='column right'>
          <div className='row'>
            <div className='table-cell price NumberAlign hide-when-small' >
              {formatNumber(props.itemVenda.precoUnitario, 2)}
            </div>
            <div className='table-cell amount NumberAlign hide-when-small' >
              {formatNumber(props.itemVenda.quantidade, 3)}
            </div>
            <div className='table-cell total NumberAlign hide-when-small' >
              {formatNumber(props.itemVenda.valorTotal, 2)}
            </div>
            <div className='table-cell time ButtonAlign' >
              {props.itemVenda.dataHora !== undefined ? formatHourMinuteFromDate(new Date(props.itemVenda.dataHora)) : '-- : --'}
            </div>
            <div className='table-cell obs ButtonAlign' >
              <button
                id='right-button'
                tabIndex={props.tabIndex}
                className={buttonItemClass}
                onClick={showDialog}
              ><FaRegComment /></button>
            </div>
          </div>
        </div>
      </div>
      <div className='display-when-small row'>
        <div className='blank-space' />
        <div className='table-cell price NumberAlign' >
          {formatNumber(props.itemVenda.precoUnitario, 2)}
        </div>
        <div className='table-cell amount NumberAlign' >
          {formatNumber(props.itemVenda.quantidade, 3)}
        </div>
        <div className='table-cell total NumberAlign' >
          {formatNumber(props.itemVenda.valorTotal, 2)}
        </div>
      </div>
    </>;
  }

  return render();
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  ...state.idiomaReducer,
  isCargo: state.empresaSelectorReducer.cargo ? true : false,
  tabIndex: state.appReducer.getTabIndex()
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  appDialogShow: (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles) =>
    dispatch(appActions.appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles)),

  appNotificationShow: (notificationMessage, notificationType, notificationTitle, notificationAction, notificationDismiss) =>
    dispatch(appActions.appNotificationShow(notificationMessage, notificationType, notificationTitle, notificationAction, notificationDismiss)),

  handleRemoveSentSaleItem: sentSaleItem => dispatch(manutencaoVendaActions.handleRemoveSentSaleItem(sentSaleItem)),

  removeNewSaleItem: newSaleItem => dispatch(manutencaoVendaActions.removeNewSaleItem(newSaleItem))
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(BuildCellFromItemVenda);
