const isHttp = window.location.href.includes('http:');
const MODO_DESENVOLVEDOR = (process.env.NODE_ENV === 'development') || process.env.REACT_APP_FORCE_DEV === 'true';
const forceHttps = !MODO_DESENVOLVEDOR;
const cert_hostname = process.env.REACT_APP_SECURITY_CERT;

// AUTH CONFIG
export const Auth = {
    MODO_DESENVOLVEDOR, // Modo Desenvolvedor dá PERMISSAO_DEV e é necessário para log remoto
}; 

// SECURE CONNECTION CONFIG
export const SecureConnection = {
    isHttp, // Confirma se site está sobre http ou https
    forceHttps, // Força o site a redirecionar para https
    cert: MODO_DESENVOLVEDOR, // Aciona o modo de cetificado para https (apenas para desenvolvimento)
    recaptcha_test_enviroment: MODO_DESENVOLVEDOR, // Aciona testes no recaptcha (desligue para produção)
    cert_hostname, // Hostname para certificado (trocar quando expirar)
    port: isHttp ? '9080' :  '9443', // Port connection to webserver
    protocol: `http${isHttp ? '' : 's'}`, // Internet protocol
};

export const generalConfig = {
    FRONT_VERSION: process.env.REACT_APP_MOBI_VERSION,
}