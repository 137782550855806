
import { push } from "connected-react-router"

import * as errorUtils from "../../utils/ErrorUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { getURIFromEntity } from "../../utils/URIUtils"

import * as actionTypes from "./actionTypes"
import { appNotificationShow, appSpinnerShow, appSpinnerHide, axios } from "./appAction"
import * as codeReaderActions from "./codeReaderAction"
import { COMPANY_INFORMATION_ENTITY } from "./codeReaderAction"
import * as gridGrupoProdutoActions from "./controleVenda/manutencao/gridGrupoProdutoAction"

import * as breadCrumbs from "../../components/UI/BreadCrumbs/BreadCrumbs"

// Para enviar fuso horário para o servidor
let moment = require("moment")

export const clearState = () => dispatch => {
    log("companyInformationAction clearState")
    dispatch({
        type: actionTypes.COMPANY_INFORMATION,
        name: "",
        origemVenda: null
    })
}

/**
 * Exibe o leitor de código de barras/QR (mais provavel que seja QR neste caso) para ler os códigos das origens de venda para exibir informações da empresa.
 * @param {*} history 
 */
export const getBarQRCodeToShowCompanyInformation = () => dispatch => {
    log("companyInformationAction getBarQRCodeToShowCompanyInformation")

    dispatch(clearState())
    dispatch(codeReaderActions.startReader(COMPANY_INFORMATION_ENTITY,
        "companyInformation",
        breadCrumbs.home_reader(),
        () => dispatch(push("/")),
        {},
        showCompanyInformation
    ))
}

/**
 * Monta uma notificação para chamar um garçom em uma origem de venda.
 */
export const notify = () => (dispatch, getState) => {
    log("companyInformationAction notify")
    
    const origemVenda = getState().companyInformationReducer.origemVenda ||
        getState().controleVendaReducer.origemVendaList.length === 1 
            ? getState().controleVendaReducer.origemVendaList[0]
            : null

    log(`origemVenda getState`, getState())

    dispatch(appSpinnerShow("notify"))

    axios().post(
        getURIFromEntity(origemVenda) + "/notify",
        {
            dataHora: new Date(),
            fusoHorario: moment().format("Z"), // -03:00, +00:00, +05:00, etc
            origemVenda: getURIFromEntity(origemVenda),
        }
    )
        .then(() => dispatch(appNotificationShow(
            getStrings().saleSourceCalled(origemVenda.nome),
            actionTypes.APP_NOTIFICATION_TYPE_SUCCESS,
            getStrings().info,
            () => {},
            8)))
        .catch(error => dispatch(errorUtils.requestErrorHandlerDefault(error)))
        .finally(() => dispatch(appSpinnerHide("notify")))
}

/**
 * Armazena os dados que serão exibidos na tela de informações da empresa.
 * @param {*} data 
 */
const showCompanyInformation = data => dispatch => {
    log("companyInformationAction showCompanyInformation", data)

    dispatch({
        type: actionTypes.COMPANY_INFORMATION,
        name: data.content.empresa.nomeFantasia,
        origemVenda: data.content.origemVenda
    })
    dispatch(gridGrupoProdutoActions.grupoProdutoLoad(data.content.menu.grupoProdutoList, data.content.menu.produtoList))
}
