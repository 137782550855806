import React from "react"

// import { Button } from "../../UI/Button/Button"

function F3ButtonComponent(props) {
  return <></>  
  // const { ...sanitized_props } = props

  // return <Button {...sanitized_props} className="f3-button">
  //   <div className="f3-button-innerHTML">
  //     <img className="img" src="https://static.xx.fbcdn.net/rsrc.php/v3/yN/r/szGrb_tkxMW.png" alt="" width="24" height="24"></img>
  //     {props.children}
  //   </div>
  // </Button>
}

export default F3ButtonComponent