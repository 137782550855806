import React from "react"
import { connect } from "react-redux"

import * as AuthUtils from "../../../utils/AuthUtils"
import { log } from "../../../utils/LogUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import * as collectorActions from "../../../store/actions/collectorAction"

import BuildCellFromCollectorProduct from "../grid/BuildCellFromCollectorProduct"
import I18n from "../../UI/I18n/I18n"
import Icones from "../../../assets/icones/Icones"

/**
 * Exibe uma tabela de produtos, possibilitando alterar sua quantidade ou realizar sua remoção.
 */
class CollectorTable extends React.Component {

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("CollectorTable render")

    const GoTrashcan = Icones.go.GoTrashcan

    return <div className="table" style={this.props.filtered ? { marginTop: "15px" } : {}}>

      {/* Header da tabela */}
      <div className={"table-header Lighter"} >
        {I18n(this.props.filtered ? "filteredProducts" : "collectedProducts")}
      </div>

      {/* Header dos itens */}
      <div className="table-collector table-columns-header">
        <div className="table-cell del ButtonAlign" >
          {(this.props.filtered || AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_EXCLUSAO])) ?
            <button
              tabIndex={this.props.tabIndex}
              className="grid-button table-button"
              onClick={() => this.props.removeProduct(undefined, this.props.filtered, this.props.collector.clienteURI,
                getURIFromEntity(this.props.collector.venda || { _links: { self: { href: null } } }), true)}
              disabled={((this.props.filtered ?
                this.props.filteredProductList :
                (this.props.collector.venda || { itemVendaList: [] }).itemVendaList) || []).length < 1}
            >
              <GoTrashcan />
            </button> : ""}
        </div>
        <div className="table-cell product TextAlign"> {I18n("product")} </div>
        <div className="table-cell NumberAlign"> {I18n("price")} </div>
        <div className="table-cell ButtonAlign"> {I18n("amount")} </div>
        <div className="table-cell NumberAlign"> {I18n("total")} </div>
      </div>

      {/* Itens */}
      {
        // Decide de que lista ler, dependendo se for a tabela de cima ou a de baixo.
        ((this.props.filtered ?
          // Se for a tabela de cima, lê da lista de produtos filtrados.
          this.props.filteredProductList :
          // Se for a tabela de baixo, lê da lista de produtos coletados.
          this.props.collectedProductList) || [])
          // Transforma a lista de produtos em linhas da tabela
          .map(collectorProduct => <BuildCellFromCollectorProduct
            clienteURI={this.props.collector.clienteURI}
            collectorProduct={collectorProduct}
            filtered={this.props.filtered}
            key={collectorProduct.produtoURI}
            vendaURI={getURIFromEntity(this.props.collector.venda || { _links: { self: { href: null } } })}
          />)}
    </div>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  collectedProductList: state.collectorReducer.collectedProductList
  , filteredProductList: state.collectorReducer.filteredProductList
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  removeProduct: (product, filtered, clienteURI, vendaURI, all) => 
    dispatch(collectorActions.removeProduct(product, filtered, clienteURI, vendaURI, all))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(CollectorTable)
