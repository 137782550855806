import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { naturalSort } from "../../../utils/SortUtils"

import {
  ESTADO_ITEM_VENDA_AGUARDANDO,
  ESTADO_ITEM_VENDA_EM_PRODUCAO,
  ESTADO_ITEM_VENDA_PRODUZIDO,
  ESTADO_ITEM_VENDA_CANCELADO,
  ESTADO_ITEM_VENDA_ENVIADO,
  ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA,
  ESTADO_ITEM_VENDA_ENTREGUE,
  ESTADO_ITEM_VENDA_ENCERRADO
} from '../../../store/reducers/controleVenda/controleVendaReducer';
import { PARAMETRO_BUSCA_FILTRO } from '../../../store/reducers/producaoReducer';
import * as actions from '../../../store/actions/producaoAction';

import FiltroInputDefault from '../../UI/filtroInputDefault/FiltroInputDefault';
import { hideSpinner } from '../../../components/UI/Spinner/Spinner';
import { cadastroBuildOptionsFromEstadoItemVenda } from '../../../utils/SelectUtils';

const SPINNER_FILTRO_ESTADO_ITEM = 'spinnerFiltroEstadoItem';
const SPINNER_FILTRO_ORIGEM_VENDA = 'spinnerFiltroOrigemVenda';
const SPINNER_FILTRO_PEDIDO = 'spinnerFiltroPedido';
const SPINNER_FILTRO_PRODUTO = 'spinnerFiltroProduto';
const SPINNER_FILTRO_CLIENTE = 'spinnerFiltroCliente';
const SPINNER_FILTRO_ENTREGADOR = 'spinnerFiltroEntregador';
const SPINNER_FILTRO_BAIRRO = 'spinnerFiltroBairro';

const FILTROS_TIMER_DELAY = 500;

class ProducaoFiltro extends React.Component {

  filtroEstadoItemVendaComponent;
  filtroOrigemVendaComponent;
  filtroPedidoComponent;
  filtroProdutoComponent;
  filtroClienteComponent;
  filtroEntregadorComponent;
  filtroBairroComponent;

  componentDidMount() {
    log('ProducaoFiltro componentDidMount');

    this.loadFiltroEstadoItemVenda();
    this.loadFiltroOrigemVenda();
    this.loadFiltroPedido();
    this.loadFiltroProduto();
    this.loadFiltroCliente();
    this.loadFiltroBairro();
    this.loadFiltroEntregador();
    this.hideSpinners();
  }

  componentDidUpdate(prevProps) {
    log('ProducaoFiltro componentDidUpdate');

    if (((!prevProps || !prevProps.visible) && this.props.visible)
      || (prevProps && prevProps[this.props.telaAtual][PARAMETRO_BUSCA_FILTRO] !== this.props[this.props.telaAtual][PARAMETRO_BUSCA_FILTRO])) {
      this.props.loadItensVendaAbertos();
    }

    if (prevProps && prevProps.telaAtual !== this.props.telaAtual) {
      this.loadFiltroEstadoItemVenda();
      this.loadFiltroOrigemVenda();
      this.loadFiltroPedido();
      this.loadFiltroProduto();
      this.loadFiltroCliente();
      this.loadFiltroBairro();
      this.loadFiltroEntregador();

      hideSpinner(SPINNER_FILTRO_ENTREGADOR);
    }
  }

  hideSpinners = () => {
    hideSpinner(SPINNER_FILTRO_ESTADO_ITEM);
    hideSpinner(SPINNER_FILTRO_ORIGEM_VENDA);
    hideSpinner(SPINNER_FILTRO_PEDIDO);
    hideSpinner(SPINNER_FILTRO_PRODUTO);
    hideSpinner(SPINNER_FILTRO_CLIENTE);
    hideSpinner(SPINNER_FILTRO_BAIRRO);
    hideSpinner(SPINNER_FILTRO_ENTREGADOR);
  }

  loadFiltroEstadoItemVenda = () => {

    if (this.filtroEstadoItemVendaComponent) {

      let estados = [
        { codigo: ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA },
        { codigo: ESTADO_ITEM_VENDA_ENTREGUE },
        { codigo: ESTADO_ITEM_VENDA_ENCERRADO }
      ];

      if (!this.props.isEntregador) {
        estados.push(
          { codigo: ESTADO_ITEM_VENDA_AGUARDANDO },
          { codigo: ESTADO_ITEM_VENDA_EM_PRODUCAO },
          { codigo: ESTADO_ITEM_VENDA_PRODUZIDO },
          { codigo: ESTADO_ITEM_VENDA_CANCELADO },
          { codigo: ESTADO_ITEM_VENDA_ENVIADO }
        );
      }

      const options = cadastroBuildOptionsFromEstadoItemVenda(true, estados);

      this.filtroEstadoItemVendaComponent.inputField.inputComponent.updateOptions(naturalSort(options, 'label'));

      if (this.props[this.props.telaAtual][PARAMETRO_BUSCA_FILTRO].estadoItemVenda) {
        const initialOptions = this.props[this.props.telaAtual][PARAMETRO_BUSCA_FILTRO].estadoItemVenda.split(',').map(estado => cadastroBuildOptionsFromEstadoItemVenda(false, { codigo: estado }));
        this.filtroEstadoItemVendaComponent.inputField.inputComponent.updateValue(initialOptions);
      }
      else {
        this.filtroEstadoItemVendaComponent.inputField.inputComponent.updateValue(null);
      }
    }
  }

  loadFiltroOrigemVenda = () => {
    if (this.filtroOrigemVendaComponent) {
      this.filtroOrigemVendaComponent.inputField.inputComponent.value = this.props[this.props.telaAtual][PARAMETRO_BUSCA_FILTRO].origemVenda || null;
    }
  }

  loadFiltroPedido = () => {
    if (this.filtroPedidoComponent) {
      this.filtroPedidoComponent.inputField.inputComponent.value = this.props[this.props.telaAtual][PARAMETRO_BUSCA_FILTRO].pedido || null;
    }
  }

  loadFiltroProduto = () => {
    if (this.filtroProdutoComponent?.inputField) {
      this.filtroProdutoComponent.inputField.inputComponent.value = this.props[this.props.telaAtual][PARAMETRO_BUSCA_FILTRO].produto || null;
    }
  }

  loadFiltroCliente = () => {
    if (this.filtroClienteComponent) {
      this.filtroClienteComponent.inputField.inputComponent.value = this.props[this.props.telaAtual][PARAMETRO_BUSCA_FILTRO].cliente || null;
    }
  }

  loadFiltroBairro = () => {
    if (this.filtroBairroComponent) {
      this.filtroBairroComponent.inputField.inputComponent.value = this.props[this.props.telaAtual][PARAMETRO_BUSCA_FILTRO].bairro || null;
    }
  }

  loadFiltroEntregador = () => {
    if (this.filtroEntregadorComponent?.inputField) {
      this.filtroEntregadorComponent.inputField.inputComponent.value = this.props[this.props.telaAtual][PARAMETRO_BUSCA_FILTRO].entregador || null;
    }
  }

  handleFiltroEstadoItemVenda = () => {
    if (this.filtroEstadoItemVendaComponent && this.filtroEstadoItemVendaComponent.inputField.inputComponent.getValue()) {
      const estadoItemVenda = this.filtroEstadoItemVendaComponent.inputField.inputComponent.getValue().map(option => option.value.codigo).toString();
      this.props.updateParametrosBusca(PARAMETRO_BUSCA_FILTRO, estadoItemVenda, 'estadoItemVenda');
    }
  }

  handlefiltroOrigemVenda = () => {
    if (this.filtroOrigemVendaComponent) {
      this.props.updateParametrosBusca(PARAMETRO_BUSCA_FILTRO, this.filtroOrigemVendaComponent.getValue(), 'origemVenda');
    }
  }

  handlefiltroPedido = () => {
    if (this.filtroPedidoComponent) {
      this.props.updateParametrosBusca(PARAMETRO_BUSCA_FILTRO, this.filtroPedidoComponent.getValue(), 'pedido');
    }
  }

  handlefiltroProduto = () => {
    if (this.filtroProdutoComponent) {
      this.props.updateParametrosBusca(PARAMETRO_BUSCA_FILTRO, this.filtroProdutoComponent.getValue(), 'produto');
    }
  }

  handlefiltroCliente = () => {
    if (this.filtroClienteComponent) {
      this.props.updateParametrosBusca(PARAMETRO_BUSCA_FILTRO, this.filtroClienteComponent.getValue(), 'cliente');
    }
  }

  handlefiltroBairro = () => {
    if (this.filtroBairroComponent) {
      this.props.updateParametrosBusca(PARAMETRO_BUSCA_FILTRO, this.filtroBairroComponent.getValue(), 'bairro');
    }
  }

  handlefiltroEntregador = () => {
    if (this.filtroEntregadorComponent) {
      this.props.updateParametrosBusca(PARAMETRO_BUSCA_FILTRO, this.filtroEntregadorComponent.getValue(), 'entregador');
    }
  }

  render() {
    return <>
      <div className='producao-filtro' >
        <FiltroInputDefault
          id="filtroEstadoItemVenda"
          ref={input => { if (input) { this.filtroEstadoItemVendaComponent = getReduxWrappedComponent(input); } }}
          inputType='multiSelect'
          label={getStrings().saleItemStateFilter()}
          placeholder={getStrings().saleItemStateProductionFilterPlaceholder}
          placeholderTransparent={true}
          sort
          onFilterTimerCompleted={() => {
            this.handleFiltroEstadoItemVenda();
            hideSpinner(SPINNER_FILTRO_ESTADO_ITEM);
          }}
          spinnerId={SPINNER_FILTRO_ESTADO_ITEM}
          timerDelay={0}
          searchable={false}
        />
        <FiltroInputDefault
          id="filtroOrigemVenda"
          ref={input => { if (input) { this.filtroOrigemVendaComponent = getReduxWrappedComponent(input); } }}
          label={getStrings().saleSourceFilter()}
          placeholder={getStrings().saleSourceFilterPlaceholderCode}
          placeholderTransparent={true}
          onFilterTimerCompleted={() => {
            this.handlefiltroOrigemVenda();
            hideSpinner(SPINNER_FILTRO_ORIGEM_VENDA);
          }}
          spinnerId={SPINNER_FILTRO_ORIGEM_VENDA}
          timerDelay={FILTROS_TIMER_DELAY}
        />
        <FiltroInputDefault
          id="filtroPedido"
          ref={input => { if (input) { this.filtroPedidoComponent = getReduxWrappedComponent(input); } }}
          label={getStrings().orderFilter}
          placeholder={getStrings().orderFilterPlaceholder}
          placeholderTransparent={true}
          onFilterTimerCompleted={() => {
            this.handlefiltroPedido();
            hideSpinner(SPINNER_FILTRO_PEDIDO);
          }}
          spinnerId={SPINNER_FILTRO_PEDIDO}
          timerDelay={FILTROS_TIMER_DELAY}
        />
        <FiltroInputDefault
          id="filtroProduto"
          ref={input => { if (input) { this.filtroProdutoComponent = getReduxWrappedComponent(input); } }}
          label={getStrings().productFilter}
          placeholder={getStrings().productFilterPlaceholder}
          placeholderTransparent={true}
          onFilterTimerCompleted={() => {
            this.handlefiltroProduto();
            hideSpinner(SPINNER_FILTRO_PRODUTO);
          }}
          spinnerId={SPINNER_FILTRO_PRODUTO}
          timerDelay={FILTROS_TIMER_DELAY}
        />
        <FiltroInputDefault
          id="filtroCliente"
          ref={input => { if (input) { this.filtroClienteComponent = getReduxWrappedComponent(input); } }}
          label={getStrings().clientFilter}
          placeholder={getStrings().clientFilterProductionPlaceholder}
          placeholderTransparent={true}
          onFilterTimerCompleted={() => {
            this.handlefiltroCliente();
            hideSpinner(SPINNER_FILTRO_CLIENTE);
          }}
          spinnerId={SPINNER_FILTRO_CLIENTE}
          timerDelay={FILTROS_TIMER_DELAY}
        />
        <FiltroInputDefault
          id="filtroBairro"
          ref={input => { if (input) { this.filtroBairroComponent = getReduxWrappedComponent(input); } }}
          label={getStrings().neighborhoodFilter}
          placeholder={getStrings().neighborhoodFilterProductionPlaceholder}
          placeholderTransparent={true}
          onFilterTimerCompleted={() => {
            this.handlefiltroBairro();
            hideSpinner(SPINNER_FILTRO_BAIRRO);
          }}
          spinnerId={SPINNER_FILTRO_BAIRRO}
          timerDelay={FILTROS_TIMER_DELAY}
        />
        {this.props.showProduced && !this.props.isEntregador
          ? <FiltroInputDefault
            id="filtroEntregador"
            ref={input => { if (input) { this.filtroEntregadorComponent = getReduxWrappedComponent(input); } }}
            label={getStrings().deliveryManFilter}
            placeholder={getStrings().deliveryManFilterPlaceholder}
            placeholderTransparent={true}
            onFilterTimerCompleted={() => {
              this.handlefiltroEntregador();
              hideSpinner(SPINNER_FILTRO_ENTREGADOR);
            }}
            spinnerId={SPINNER_FILTRO_ENTREGADOR}
            timerDelay={FILTROS_TIMER_DELAY}
          />
          : null
        }
      </div>
    </>
  }
}

const mapStateToProps = state => ({
  ...state.producaoReducer,
  ...state.idiomaReducer,
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  loadItensVendaAbertos: link => dispatch(actions.loadItensVendaAbertos(link)),
  updateParametrosBusca: (tipoParametro, valor, posicao) => dispatch(actions.updateParametrosBusca(tipoParametro, valor, posicao)),
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(ProducaoFiltro);