import React from 'react';

import { getStrings } from '../../../utils/LocaleUtils';
import { log } from '../../../utils/LogUtils';
import { getURIFromEntity } from '../../../utils/URIUtils';

import BotaoChecado from '../../UI/botoes/BotaoChecado/BotaoChecado';
import OptionButtons from '../optionButtons/OptionButtons';
import CadastroTable from './CadastroTable';
import TamanhoTableModel from './TamanhoTableModel';

export type TamanhoTableTipo = {
    urlDatabase: string,
}
export default class TamanhoTable extends  React.Component<TamanhoTableTipo> {
    render() {
        log('ProdutoTable render');

        const blankFormData = {
            enabled: true,
            codigo: '',
            nome: '',
        };

        // @ts-ignore
        return <CadastroTable {...this.props} objetoCadastro='multiplo' sortProperty={'nome'} filterProperties={['nome', 'codigo']} tableModel={TamanhoTableModel} blankFormData={blankFormData} registerHelp={getStrings().sizeTableHelp}
        />;
    }
}

export type TamanhoTableRowTipo = {
    cadastro: any,
    onUpdate: (cadastro: any) => void | undefined,
    onDelete: (cadastro: any) => void | undefined,
};

export class TamanhoTableRow extends React.Component<TamanhoTableRowTipo> {
    render() {
        log('ProdutoTableRow render');

        const id = `${getURIFromEntity(this.props.cadastro)}/Checkbox`;

        return <tr>
            {/* Opções de manutenção */}
            <OptionButtons className='ButtonAlign' onUpdate={this.props.onUpdate} onDelete={this.props.onDelete} cadastro={this.props.cadastro} />
            
            {/* Dados do cadastro */}
            <td className='Lighter ButtonAlign' style={{width: '5%'}} >
                <BotaoChecado key={`__${id}__`} desativado={!this.props.cadastro.enabled} />
            </td>
            <td className='Lighter TextAlign' style={{width: '20%'}} >{this.props.cadastro.codigo}</td>
            <td className='Lighter TextAlign' style={{width: '75%'}} >{this.props.cadastro.nome}</td>
        </tr>;
    }
}
