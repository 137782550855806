import React from "react"
import { connect } from "react-redux"
import { RouterState } from "connected-react-router"

import { getStrings } from "../../../utils/LocaleUtils"
import { reduxStateType } from "../../../utils/reduxUtils/reduxUtils"

import { cadastroEmpresaEUsuarioAnterior, cadastroEmpresaEUsuarioAnteriorType, updateEmpresaStep, updateEmpresaStepType } from "../../../store/actions/cadastroEmpresaAction"
import { updateUsuarioStep, updateUsuarioStepType } from "../../../store/actions/signUpAction"
import { updateBarraAcoesTable, updateBarraAcoesTableType } from "../../../store/actions/cadastroAction"
import { cadastroReducerType } from "../../../store/reducers/cadastroReducer"

import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"
import BuildButton from "../../UI/Toolbar/BarraAcoesButton"
import Icones from "../../../assets/icones/Icones"
import { dispatchTipo } from "../../../store/actions/acaoTemplate"

/**
 * Botão para chamar garçom.
 */
export const SUBMIT_CALL_ROLE = "SUBMIT_CALL_ROLE";

/**
 * Botão sem função.
 */
export const SUBMIT_EMPTY = "SUBMIT_EMPTY";

/**
 * Botão de confirmar envolve valores em dinheiro
 */
export const SUBMIT_ROLE_MONEY = "SUBMIT_ROLE_MONEY";

/**
 * Botão de confirmar envolve valores em dinheiro
 */
export const SUBMIT_ROLE_NEW = "SUBMIT_ROLE_NEW";

/**
 * Botão de confirmar envolve ir para a próxima tela ou avançar.
 */
export const SUBMIT_ROLE_NEXT = "SUBMIT_ROLE_NEXT";

/**
 * Botão de imprimir
 */
export const SUBMIT_ROLE_PRINT = "SUBMIT_ROLE_PRINT";

/**
 * Botão de confirmar envolve alternar entre câmera dianteira e traseira.
 */
export const SUBMIT_ROLE_SWITCH_CAMERA = "SUBMIT_ROLE_SWITCH_CAMERA";

/**
 * Botão de cadastrar novo usuário.
 */
 export const SUBMIT_ROLE_REGISTER_USER = "SUBMIT_ROLE_REGISTER_USER";


export type BarraAcoesFormType = {
  /** Função ao clicar no botão da esquerda */
  handleListar: () => void,
  /** Função ao clicar no botão da direita */
  handleSubmit: () => void,
  /**
   * Se `true`, o botão da direita usa `type=submit`.
   * Ou seja, se for pressionado dentro de um `<form/>`, * dispara o `onSubmit` do mesmo.
   * Se `false`, o botão da direita usa `type=button`.
   * Ou seja, se for pressionado dentro de um `<form/>`, só é disparado o `onClick` do botão.
   */
  isSubmit: boolean,
  isShowConfig: boolean,

  /** Se deve desabilitar o botão da direita */
  addButtonDisabled?: boolean,
  /** Ativa o brilho no botão da esquerda */
  attractBack?: boolean,
  /** Ativa o brilho no botão da direita */
  attractSubmit?: boolean,
  /** Se deve desabilitar o botão da esquerda */
  backButtonDisabled?: boolean,
  cadastroFormRequired?: boolean,
  /** Esconde o botão de enviar, caso só seja desejado o botão de voltar. */
  hideSubmit?: boolean,
  /** Papel realizado pelo botão da direita (para definir o seu ícone) */
  submitRole?: string,
}

type BarraAcoesFormReduxStateType = cadastroReducerType & RouterState & {
  cadastroEmpresaReducerType: string,
  cadastroFormRequired: boolean,
  empresaStep: number,
  usuarioStep: number,
};

type BarraAcoesFormReduxDispatchType = {
  updateEmpresaStep: updateEmpresaStepType,
  updateUsuarioStep: updateUsuarioStepType,
  cadastroAnterior: cadastroEmpresaEUsuarioAnteriorType,
  updateBarraAcoesTable: updateBarraAcoesTableType,
}

type BarraAcoesFormTypeWithReducer = BarraAcoesFormType & BarraAcoesFormReduxStateType & BarraAcoesFormReduxDispatchType;

/**
 * Classe que realiza a montagem da barra de ações para os formulários de cadastro..
 */
class BarraAcoesForm_ extends React.Component<BarraAcoesFormTypeWithReducer> {
  componentDidMount() {
    this.props.updateBarraAcoesTable({cadastroFormRequired: true});
  }

  /**
   * Método que monta o componente.
   */
  render() {
    const FaCogs = Icones.fa.FaCogs
    const MdAttachMoney = Icones.md.MdAttachMoney
    const MdCheck = Icones.md.MdCheck
    const MdNotificationsNone = Icones.md.MdNotificationsNone
    const MdPrint = Icones.md.MdPrint
    const MdSwitchCamera = Icones.md.MdSwitchCamera

    let buttonGridClassName = "bar-form-button-config-0"

    let configButton
    let configSpace

    if (this.props.isShowConfig) {

      const configButtonClassName = "bar-form-config"

      // @ts-ignore
      configButton = <BuildButton key="config" className="toolbar-button" commandMethod={this.props.handleConfig} label={<FaCogs />} buttonGridClassName={configButtonClassName} />
      configSpace = <BuildSpace key="configGridSpace" isShowConfig={this.props.isShowConfig} />

      buttonGridClassName = "bar-form-button-config-1"
    }

    let label
    let title

    switch (this.props.submitRole) {
      case SUBMIT_CALL_ROLE:
        label = <MdNotificationsNone />
        title = getStrings().callWaiter
        break
      case SUBMIT_EMPTY:
        label = "\u00a0"
        break
      case SUBMIT_ROLE_MONEY:
        label = <MdAttachMoney />
        title = getStrings().payment
        break
      case SUBMIT_ROLE_NEW:
        label = "+"
        title = getStrings().newRegister
        break
      case SUBMIT_ROLE_NEXT:
        label = "\u003e"
        title = getStrings().next
        break
      case SUBMIT_ROLE_PRINT:
        label = <MdPrint />
        title = getStrings().print
        break
      case SUBMIT_ROLE_SWITCH_CAMERA:
        label = <MdSwitchCamera />
        title = getStrings().cameraSwitch
        break
      case SUBMIT_ROLE_REGISTER_USER:
        if (this.props.usuarioStep !== undefined && this.props.usuarioStep < 1) {
          label = ">"
          title = getStrings().next
        }
        else {
          label = <MdCheck />
          title = getStrings().submit
        }
        break
      default:
        label = <MdCheck />
        title = getStrings().submit
        break
    }

    // let title

    // switch (this.props.submitRole) {
    //     case SUBMIT_ROLE_NEXT:
    //         title = getStrings().next
    //         break
    //     default:
    //         title = getStrings().submit
    //         break
    // }

    return <div id="toolbar" className="toolbar" >

      <div className="toolbar-content">

        <WidthAwareDiv>

          <div className="bar-container">

            {/* Botão Voltar */}
            <BuildButton id="previous-button" key="back" isPrimary={true} commandMethod={(e) => {
              e.stopPropagation();
              e.preventDefault();

              const { empresaStep, usuarioStep } = this.props

              switch (this.props.location.pathname) {
                case "/signUpUser":
                  if (usuarioStep > 0) {
                    this.props.updateUsuarioStep(usuarioStep - 1);
                  }
                  else {
                    this.props.cadastroAnterior();
                  }
                  return;
                case "/signUpCompany": 
                  if (empresaStep > 0) {
                    if (usuarioStep > 0) {
                      this.props.updateUsuarioStep(usuarioStep - 1);
                    }
                    else {
                      if (empresaStep === 3) {
                        this.props.updateEmpresaStep(empresaStep - 1);
                        this.props.cadastroAnterior();
                      }
                      else {
                        this.props.updateEmpresaStep(empresaStep - 1);
                      }
                    }
                  }
                  else {
                    // @ts-ignore
                    this.props.handleListar(e);
                  }
                  return;
                default: 
                  // @ts-ignore
                  this.props.handleListar(e);
                  return;
              }
            }} label={"\u003c"} title={getStrings().back} isDisabled={this.props.backButtonDisabled} buttonGridClassName={buttonGridClassName} attractSubmit={this.props.attractBack} />

            {/* Espaço entre os botões */}
            <BuildSpace key="gridSpace" isShowConfig={this.props.isShowConfig} hideSubmit={this.props.hideSubmit} />

            {/* Botão Config */}
            {configButton}
            {configSpace}

            {/* Botão Novo */}
            {this.props.hideSubmit
              ? undefined
              : <BuildButton id="next-button" key="add" isSubmit={this.props.isSubmit} isPrimary={true} isDisabled={this.props.addButtonDisabled || !this.props.cadastroFormRequired} label={label} title={title} commandMethod={this.props.handleSubmit} buttonGridClassName={buttonGridClassName} attractSubmit={this.props.attractSubmit || (this.props.cadastroChanged && this.props.cadastroFilledRequired)} />
            }

          </div>
        </WidthAwareDiv>
      </div>
    </div>
  }
}

type BuildSpaceType = {
  isShowConfig: boolean,

  hideSubmit?: boolean,
};

/**
 * Função que monta o espaço entre os dois botões.
 */
class BuildSpace extends React.Component<BuildSpaceType> {

  render() {

    let spaceGridClassName = "bar-form-space-config-0"

    if (this.props.hideSubmit) {
      spaceGridClassName = "bar-form-space-config-2"
    } else if (this.props.isShowConfig) {
      spaceGridClassName = "bar-form-space-config-1"
    }

    return (
      <div className={spaceGridClassName}>
        <div className={"bar-border"} >
          <div className={"grid-space"} >
            {/* Este label é necessário para o dimensionamento correto do Height do espaço,
                            se for definido um Heigth no CSS, o navegador FireFox exibe o campo um pixel menor nos demais navegadores,
                            fazendo com que os botões e o espaço não fiquem alinhados. */}
            <label>&nbsp;</label>
          </div>
        </div>
      </div>
    )
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
function mapStateToProps(state: reduxStateType) {
  const props: BarraAcoesFormReduxStateType = {
    ...state.cadastroReducer,
    ...state.router,
    cadastroEmpresaReducerType: state.cadastroEmpresaReducer.type,
    cadastroFormRequired: state.cadastroReducer.cadastroFormRequired,
    empresaStep: state.cadastroEmpresaReducer.step,
    usuarioStep: state.signUpReducer.step,
  };

  return props;
}

function mapDispatchToProps(dispatch: dispatchTipo) {
  const props: BarraAcoesFormReduxDispatchType = {
    /**
     * 
     * @param {number} step 
     * @returns 
     */
    updateEmpresaStep: (step) => dispatch(updateEmpresaStep(step)),
    /**
     * 
     * @param {number} step 
     * @returns 
     */
    updateUsuarioStep: (step) => dispatch(updateUsuarioStep(step)),

    cadastroAnterior: () => dispatch(cadastroEmpresaEUsuarioAnterior()),
    updateBarraAcoesTable: (payload) =>
      dispatch(updateBarraAcoesTable(payload)),
  };

  return props;
}

/**
 * Exporta o último argumento entre parênteses.
 */
const BarraAcoesForm = connect(mapStateToProps, mapDispatchToProps)(BarraAcoesForm_) as React.ElementType<BarraAcoesFormType>;

export default BarraAcoesForm;