import _F3SignUp from './F3SignUp';
import _F3Login from './F3Login';
import _F3Logout from './F3Logout';

import _F3LoginComponent from './F3LoginComponent';
import _F3SignUpComponent from './F3SignUpComponent';

import "./F3Style.css";

export const LoadF3SDK = ({app_id, version}) => {
    // Necessário para adicionar os scripts no fim da página
    window.addEventListener('load', function() {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId: '' + app_id,
                autoLogAppEvents: true,
                xfbml: true,
                version: '' + version,
            });
        };

        // Script que carrega FB SDK
        var loadf3SDKElement = document.createElement('script');
    
        loadf3SDKElement.id = 'load-f3-sdk';
        loadf3SDKElement.async = true;
        loadf3SDKElement.defer = true;
        loadf3SDKElement.src = "https://connect.facebook.net/pt_BR/sdk.js";

        // Necessário para não duplicar script
        var childNodesId = [...document.body.childNodes].map(function(value) { return value.id; });

        !childNodesId.includes('load-f3-sdk') && document.body.appendChild(loadf3SDKElement);
    });
}

export const getF3UserPicture = (userId, callback) => {
    const fb = window.FB;

    if (fb) {
        fb.api(`/${userId}/picture?redirect=false`, 'GET', {}, callback);
    }
};

export const F3Logout = _F3Logout;
export const F3SignUp = _F3SignUp;
export const F3Login = _F3Login;

export const F3SignUpComponent = _F3SignUpComponent;
export const F3LoginComponent = _F3LoginComponent;