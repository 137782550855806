import React from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"

import { getStrings } from "../../../utils/LocaleUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { blurAll } from "../../../utils/ScreenUtils"

import * as actions from "../../../store/actions/usuarioAction"

import CustomButton from "../../UI/Button/CustomButton"
import HelpParagraph from "../../UI/HelpParagraph/HelpParagraph"
import InputCustomizado from "../../UI/Input/InputCustomizado"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

import "./RedefineSenha.css";

/* 
 * Necessário para o Edge, pelo menos até sair o próximo update
 * https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8993198/
 */
const URLSearchParams = require("url-search-params")

/**
 * Componente para redefinir uma nova Senha quando o Usuário esqueceu a antiga. 
 */
class RedefineSenha extends React.Component {

  componentDidMount() {
    // Busca o token na querystring
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('token');
    const idUsuario = query.get('id');

    if (token)
      this.props.validaTokenRedefinirSenha(idUsuario, token);
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  /**
   * Método que carrega o formulário. 
   */
  loadForm = () => {
    let formFields = null;

    if (!this.props.token) {
      formFields = this.loadFormFieldEmail();
    }
    else {
      formFields = this.loadFormFieldsSenha();
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <WidthAwareDiv>
          <div className='tela-start tela-redefine-senha'>

            <HelpParagraph>
              {[getStrings().forgotPasswordHelp]}
            </HelpParagraph>

            <div className='sub-form'>
              {formFields}
              <div className='button-grid'>
                <CustomButton btnType='Success'>{getStrings().submit}</CustomButton>
              </div>
            </div>

          </div>
        </WidthAwareDiv>
      </form>);
  }

  /**
   * Método que carrega o formulário para inserção do email. 
   */
  loadFormFieldEmail = () => {
    return (
      <>
        <InputCustomizado id='email' ref={input => this.email = getReduxWrappedComponent(input)}
          placeholder={getStrings().email} maxLength='250' type='email' name='email' required={true} label={getStrings().emailRequired} />
      </>
    );
  }

  /**
   * Método que carrega o formulário para informar a nova senha. 
   */
  loadFormFieldsSenha = () => {
    return (
      <div className='sub-form'>

        <InputCustomizado id="novaSenha" ref={input => this.novaSenha = getReduxWrappedComponent(input)}
          placeholder="Nova Senha" maxLength='20' type="password" name="novaSenha" required="true" label="Nova Senha*" />

        <InputCustomizado id="confirmacaoSenha" ref={input => this.confirmacaoSenha = getReduxWrappedComponent(input)}
          placeholder="Confirmação de Senha" maxLength='20' type="password" name="confirmacaoSenha" required="true" label="Confirmação de Senha*" />

      </div>
    );
  }

  /**
   * Método executado ao clicar no botão para confirmar as alterações. 
   */
  handleSubmit = (e) => {

    // Impede que o form redirecione para outra URL
    e.preventDefault();

    blurAll();

    if (!this.props.token) {
      this.solicitaRedefinicaoSenha();
    }
    else {
      this.redefineSenha();
    }
  }

  /**
   * Método que chama a ação para envio de email para redefinir a senha. 
   */
  solicitaRedefinicaoSenha = () => {
    this.props.solicitaRedefinicaoSenha(this.email.inputComponent.value);
  }

  /**
   * Método que chama a ação para salvar a nova Senha. 
   */
  redefineSenha = () => {
    const passwordResetToken = {
      token: this.props.token,
      usuario: {
        id: this.props.idUsuario,
        login: {
          senha: this.novaSenha.inputComponent.value,
          confirmacaoSenha: this.confirmacaoSenha.inputComponent.value
        }
      }
    };

    this.props.redefineSenha(passwordResetToken);
  }

  render() {
    let content = null;

    if (!this.props.loading) {
      content = this.loadForm();
    }

    // Verifica se o usuário deve ser redirecionado e o redireciona
    let userRedirect = null;
    if (this.props.sucess) {
      userRedirect = <Redirect to='/auth' />
    }

    return (
      <>
        {userRedirect}
        {content}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.idiomaReducer,
    loading: state.usuarioReducer.loading,
    idUsuario: state.usuarioReducer.idUsuario,
    token: state.usuarioReducer.token,
    sucess: state.usuarioReducer.sucess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    solicitaRedefinicaoSenha: (email) => dispatch(actions.solicitaRedefinicaoSenha(email)),
    validaTokenRedefinirSenha: (idUsuario, token) => dispatch(actions.validaTokenRedefinirSenha(idUsuario, token)),
    redefineSenha: (usuario) => dispatch(actions.redefineSenha(usuario)),
    resetState: () => dispatch(actions.resetState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedefineSenha);