import React from "react"
import { connect } from "react-redux"

import { log } from "../../utils/LogUtils"
import { updateActiveMenuItem } from "../../utils/ScreenUtils"

import * as collectorActions from "../../store/actions/collectorAction"
import {
    STATE_COLLECTOR_SALE_CLIENT_LIST,
    STATE_COLLECTOR_SALE_CODE_READER,
    STATE_COLLECTOR_SALE_MANAGE_SALE
} from "../../store/reducers/collectorReducer"

import CodeReader from "../CodeReader/CodeReader"
import CollectorHeader from "./CollectorHeader"
import CollectorSale from "./manutencao/CollectorSale"
import GridCollector from "./grid/GridCollector"

/**
 * Componente superior das telas de gerência de vendas de empresas do ramo coletor.
 */
class CollectorBox extends React.Component {

    /**
     * Retorna a tela a ser exibida de acordo com o estado armazenado no *reducer*.
     */
    getConteudoCollectorBox() {
        log("CollectorBox getConteudoCollectorBox")
        switch (this.props.state) {
            case STATE_COLLECTOR_SALE_CLIENT_LIST:
                return <GridCollector />
            case STATE_COLLECTOR_SALE_MANAGE_SALE:
                return <CollectorSale />
            default:
                return null
        }
    }

    /**
     * Método executado APÓS montar o componente.
     */
    componentDidMount() {
        log("CollectorBox componentDidMount")

        updateActiveMenuItem("menuItemControleVenda", "menuItemVenda")
    }

	/**
	 * Método executado ANTES de "DESMONTAR" o componente.
     * Limpa variáveis do estado.
	 */
    componentWillUnmount() {

        this.props.showCollectorList(true)
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log("CollectorBox render")

        // Exibe o conteúdo da tela de acordo com o estado
        switch (this.props.state) {
            // Exibe o leitor de código de barras ou QR no lugar do cabeçalho e do conteúdo de vendas
            case STATE_COLLECTOR_SALE_CODE_READER:
                return <CodeReader />
            // Exibe uma das telas de vendas
            case STATE_COLLECTOR_SALE_CLIENT_LIST:
            case STATE_COLLECTOR_SALE_MANAGE_SALE:
            default: return <div id="cadastro-box" >

                <CollectorHeader />

                <div id="conteudo-box" >
                    {this.getConteudoCollectorBox()}
                </div>
            </div>
        }
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    state: state.collectorReducer.state
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    showCollectorList: clearClienteFilter => dispatch(collectorActions.showCollectorList(clearClienteFilter))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(CollectorBox)
