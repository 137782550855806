import React from "react"
import { connect } from "react-redux"

import { getIdFromEntity, getURIFromEntity } from "../../../utils/URIUtils"

import { ESTADO_ITEM_VENDA_ESPERANDO_ACEITAR } from "../../../store/reducers/controleVenda/controleVendaReducer"
import * as actions from "../../../store/actions/producaoAction"
import * as appActions from "../../../store/actions/appAction"

import CardPedido from "./CardPedido"

class ReceberPedidosTela extends React.Component {

  render() {

    const pedidosEsperandoAceitar = this.props.vendaList.filter((venda) => venda.origemVenda.tipoOrigemVenda === this.props.tipoOrigem && venda.estadoItensVenda === ESTADO_ITEM_VENDA_ESPERANDO_ACEITAR);

    if (!pedidosEsperandoAceitar.length) {
      this.props.appDialogHide();
      return null;
    }

    return <>
      {pedidosEsperandoAceitar.map((venda, index) =>
        <CardPedido
          key={`__card_pedido_${getURIFromEntity(venda)}_${index}__`}
          atualizarEstado={this.props.atualizarEstado}
          buscarEstado={this.props.buscarEstado}
          venda={{
            ...venda
            , entregaVenda: venda.entregaVenda
              ? {
                ...venda.entregaVenda
                , id: this.props.estado.cadastroComanda.entregaVenda.id
              }
              : null
          }}
          vendaURI={getURIFromEntity(venda, false)}
          vendaId={getIdFromEntity(venda)}
          modoSelecao={this.props.estado.modoSelecao}
          selecionados={this.props.estado.selecao}
          botoesVenda
        />)
      }
    </>
  }
}

const mapStateToProps = state => ({
  vendaList: state.producaoReducer.vendaList,
  locale: state.idiomaReducer.locale
});

const mapDispatchToProps = dispatch => ({
  loadItensVendaAbertos: () => dispatch(actions.loadItensVendaAbertos()),
  appDialogHide: () => dispatch(appActions.appDialogHide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceberPedidosTela);