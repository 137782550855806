import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from "../../../utils/LocaleUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"

import InputCustomizado from "../../UI/Input/InputCustomizado";

import "./TamanhoForm.css";

class ProdutoTamanhoForm extends React.Component {

    render() {
        const legendStyle = { fontWeight: '500', paddingInlineStart: '4px', paddingInlineEnd: '4px' };

        return this.props.produtoTamanhoList.length
            ? <fieldset className={'fieldsetStyle'}>
                <legend style={legendStyle}>{getStrings().sizes}</legend>
                <div>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr style={{ verticalAlign: 'bottom' }}>
                                <th>{getStrings().size}</th>
                                <th>{getStrings().price}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.produtoTamanhoList.map((produtoTamanho, index) => {
                                return <ProdutoTamanhoRow
                                    key={index}
                                    index={index}
                                    produtoTamanho={produtoTamanho}
                                    updateValues={this.props.updateValues}
                                />
                            })}
                        </tbody>
                    </table>
                </div>
            </fieldset>
            : null
    }
};

export class ProdutoTamanhoRow extends React.Component {

    componentDidMount() {
         this.tamanho.inputComponent.value = this.props.produtoTamanho.tamanho.nome;
         this.precoCustomizado.setMaskValue(this.props.produtoTamanho.preco);
    }

    getFormData = () => {
        const formData = {
            tamanho: this.tamanho.inputComponent.value
            , precoCustomizado: this.precoCustomizado.getMaskValue()
        };

        return formData;
    };

    render() {
        return <tr>
            <td style={{ width: '80%', padding: '5px 2.5px' }}>
                <InputCustomizado
                    labelStyle={{ height: '3em', textAlign: 'center' }}
                    required={true}
                    id={`row__${this.props.index}__tamanho`}
                    type='text'
                    name='tamanho'
                    readOnly={true}
                    placeholder={getStrings().sizePlaceholder}
                    tabIndex={-1}
                    ref={input => this.tamanho = getReduxWrappedComponent(input)}
                    onChange={() => this.props.updateValues(this.props.index, this.getFormData())} />
            </td>
            <td style={{ width: '20%', padding: '5px 2.5px' }}>
                <InputCustomizado
                    style={{ textAlign: 'right' }}
                    labelStyle={{ height: '3em', textAlign: 'center' }}
                    id={`row__${this.props.index}__precoCustomizado`}
                    max={100000000}
                    scale={2}
                    inputType='masked'
                    type='text'
                    validacaoDados='numeroDecimal'
                    name='precoCustomizado'
                    required={true}
                    ref={input => this.precoCustomizado = getReduxWrappedComponent(input)}
                    placeholder={getStrings().price}
                    onBlur={this.props.handleChange}
                    handleInputValidado={() => this.props.updateValues(this.props.index, this.getFormData())} />
            </td>
        </tr>
    }
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProdutoTamanhoForm);