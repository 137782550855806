import React from "react"

import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"

// Para compatibilidade no chrome mobile antigo
const Column = (props) => {
	const childrenComponents = props.children || null

	if (isAncientChromeMobile) {
		return <div className={`column${props.className ? ` ${props.className}` : ""}`}>
			{childrenComponents}
		</div>
	} else {
		return childrenComponents
	}
}

export default Column