//import _validaSenha from "zxcvbn";
import { reverseObject } from "./ObjectUtils";
import { isString, isNonEmptyArray, isRegex, isBoolean, isStrictObject, isArray } from "./ComparatorsUtils";

const _suggestions = {
    common_phrases: "Use a few words, avoid common phrases",
    no_need: "No need for symbols, digits, or uppercase letters",
    extra_feedback: 'Add another word or two. Uncommon words are better.',
    capitalization: "Capitalization doesn't help very much",
    all_uppercase: "All-uppercase is almost as easy to guess as all-lowercase",
    reversed_words: "Reversed words aren't much harder to guess",
    predictable_substitution: "Predictable substitutions like '@' instead of 'a' don't help very much",
    keyboard_pattern: 'Use a longer keyboard pattern with more turns',
    repeated_characters: 'Avoid repeated words and characters',
    sequences: 'Avoid sequences',
    recent_years: 'Avoid recent years',
    avoid_years: 'Avoid years that are associated with you',
    date: 'Avoid dates and years that are associated with you',
}

const _warnings = {
    default: '',
    top10: 'This is a top-10 common password',
    top100: 'This is a top-100 common password',
    common_password: 'This is a very common password',
    similar_to_common_passwords: 'This is similar to a commonly used password',
    dictionary_name: 'english_wikipedia',
    sole_match: 'A word by itself is easy to guess',
    name_or_surname: 'Names and surnames by themselves are easy to guess',
    name_and_surname: 'Common names and surnames are easy to guess',
    straight_row_of_keys: 'Straight rows of keys are easy to guess',
    short_keyboard_patterns: 'Short keyboard patterns are easy to guess',
    aaa_pattern: 'Repeats like "aaa" are easy to guess',
    abcabcabc_pattern: 'Repeats like "abcabcabc" are only slightly harder to guess than "abc"',
    sequence: "Sequences like abc or 6543 are easy to guess",
    recent_years: "Recent years are easy to guess",
    date: "Dates are often easy to guess",
}

const _reversed_suggestions = reverseObject(_suggestions);
const _reversed_warnings = reverseObject(_warnings);

export const regexes = {
    letras_minusculas: /[a-z]+/,
    letras_maisculas: /[A-Z]+/,
    letras: /[a-zA-Z]+/,
    letras_e_nums: /[a-zA-Z0-9]+/,
    digitos: /[0-9]+/,
    caracteres_especiais: /[^a-zA-Z0-9]+/g,
}

/**
 * 
 * @param {String} senha 
 * @param {RegExp} regex 
 * @param {boolean} exclusivamente 
 */
export function tem(senha, regex, exclusivamente = false) {
    let teste = false;

    if (!(isString(senha) && isRegex(regex) && isBoolean(exclusivamente))) {
        return false;
    }

    if(exclusivamente) {
        teste = senha.replace(regex, '') === '';
    } else {
        teste = isNonEmptyArray(senha.match(regex));
    }

    return teste;
}

function _extendeSuggestions(senha, obj, suggestions) {
    obj.feedback.suggestions = (obj.feedback.suggestions || []).map(suggestion => {
        let key = _reversed_suggestions[suggestion];
        return suggestions[key] === '' ? '' : suggestions[key] || _suggestions[key];
    });
    
    obj.feedback.suggestions = [
        ...(obj.feedback.suggestions || []),
        ...(suggestions.extend || []).map(({rule, message}) => {
            if (rule(senha) && senha.length > 0) {
                return message;
            }

            return '';
        }),
    ].filter((message) => message !== '');

    return obj.feedback.suggestions;
}


function _extendWarnings(senha, obj, warnings, score) {
    obj.feedback.warning = (() => {
        let key = _reversed_warnings[obj.feedback.warning];

        return warnings[key] === '' ? '' : warnings[key] || _warnings[key];
    })();
    
    obj.feedback.warning = obj.feedback.warning || (warnings.extend || []).map(({rule, message, underscore = 1}) => {
        if (rule(senha) && senha.length > 0) {
            score = score - underscore;
            return message;
        }

        return '';
    }).filter((message) => message !== '')[0] || '';

    return [obj.feedback.warning, score < 0 ? 0 : score];
}

/**
 * 
 * @param {String} senha 
 * @param {Record<string, any>} suggestions
 * @param {Record<string, any>} warnings
 * @param {Array<String>} extencao_dicionario
 */
export async function validaSenha(senha, suggestions = _suggestions, warnings = _warnings, extencao_dicionario = []) {
    if (!(isString(senha) && isStrictObject(suggestions) && isStrictObject(warnings) && isArray(extencao_dicionario))) {
        return null;
    }

    let _validaSenha = await import('zxcvbn');
    
    let obj = _validaSenha.default(senha, extencao_dicionario);

    obj.feedback.suggestions = _extendeSuggestions(senha, obj, suggestions);
    [obj.feedback.warning, obj.score] = _extendWarnings(senha, obj, warnings, obj.score);

    return obj;
}