import React from "react"
import { connect } from "react-redux"
import { idiomaReducerType } from "../../../store/reducers/idiomaReducer"
import { getStrings } from "../../../utils/LocaleUtils"

import { reduxStateType } from "../../../utils/reduxUtils/reduxUtils"

export type TableHeaderType = {
  children: string,
  align: "Button"|"Center"|"Number"|"Text",
}

export function TableHeader(props: TableHeaderType) {
  return <th className={`Bold ${props.align}Align`}>{props.children}</th>;
}

export type TableModelType = {
  children: React.ReactElement<TableHeaderType>|React.ReactElement<TableHeaderType>[],
  cadastroRowList: React.ReactNode[],

  cantDisable?: boolean,
  style?: React.CSSProperties,
}

export type TableModelModularType = {
  onUpdate: (cadastro: Record<string,any>) => void,
  onDelete: (cadastro: Record<string,any>) => void,
  
  cadastroList?: Record<string,any>[],
};

type TableModelReduxStateType = idiomaReducerType;

type TableModelReduxDispatchType = {};

type TableModelTypeWithReducer = TableModelType & TableModelReduxStateType & TableModelReduxDispatchType;

function TableModel_({cantDisable = false, ...props}: TableModelTypeWithReducer) {
  const style = props.style || {};

  return <table className="pure-table" style={{width: "100%", ...style}} >
    {/* Cabeçalho */}
    <thead>
      <tr>
        <TableHeader align="Text" >{getStrings().options}</TableHeader>
        {cantDisable
          ? null
          : <TableHeader align="Text" >{getStrings().active}</TableHeader>
        }
        {props.children}
      </tr>
    </thead>
    <tbody>
      {/* Linhas de Dados */}
      {props.cadastroRowList}
    </tbody>
  </table>;
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
function mapStateToProps(state: reduxStateType) {
  const props = {
    ...state.idiomaReducer,
  };

  return props;
};


export const TableModel = connect(mapStateToProps)(TableModel_);
export default TableModel;