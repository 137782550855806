//@ts-check
import React from 'react';

import "./Botao.css";

/**
 * 
 * @param {import('./types').botaoPropsType} props 
 * @returns {JSX.Element}
 */
function Botao(props) {
    const className = "ui-botao " + (props.className || "");
    
    return <button {...props} className={className}>
      {props.children}
    </button>;
}

export default Botao;
