import React from "react"
import { connect } from "react-redux"
import { SkyLightStateless } from "react-skylight"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"

import * as actionTypes from "../../../store/actions/actionTypes"
import * as appActions from "../../../store/actions/appAction"


const ConfirmButton = (props) => {
  return <>
    <button
      className="blue-button font-color"
      ref={props.reference}
      id={props.id}
    >
      {props.children}
    </button>
  </>;
};

class NotificationSystem extends React.Component {

  /**
   * Lista de temporizadores usados para ocultar automaticamente notificações.
   */
  timeoutList = [];

  /**
   * Método executado APÓS a atualização do componente.
   * 
   * Se `dialog` for notificação, gerencia tratamento de evento ao clicar em qualquer região da notificação.
   * Se for clicado no botão **Sim**, executa o método recebido por parâmetro. Senão, oculta a notificação.
   * Se notificação está visível, adiciona este método. Se está oculta, remove este método.
   * 
   * Se `dialog` for notificação, também configura a auto ocultação. Se notificação está visível, cria um temporizador
   * para ocultar a notificação depois de um tempo que varia conforme o tipo de notificação. Se notificação está oculta,
   * cancela os temporizadores criados até então.
   */
  componentDidUpdate() {
    log('NotificationSystem componentDidUpdate');
    // Testa se notificação está visível
    if (this.props.notificationShow) {
      // Testa se foi possível acessar o div da dialog
      if (this.notificationElement) {
        // Chama o foco para si para que não seja possível atuar nos botões ocultos embaixo da notificação que ainda poderiam estar focados
        this.notificationElement.focus();
        // Adiciona o método de clicar na notificação
        this.notificationElement.onclick = event => {
          // Se foi clicado no botão Sim
          if (event.target.id === 'yes-button') {
            // Executa o método de sim recebido por parâmetro
            this.props.notificationAction();
          }
          // Se foi clicado no botão Não
          else if (event.target.id === 'no-button') {
            // Executa o método de não recebido por parâmetro
            this.props.notificationNoAction();
          }
          // Oculta a notificação, não importa a opção selecionada
          this.props.appNotificationHide();
        }
      }
      let notificationDismiss = this.props.notificationDismiss;
      // Se tempo de auto ocultação não foi passado, define o valor padrão
      // Zero é um valor valido e corresponde a não auto ocultar
      if ((!notificationDismiss) && (notificationDismiss !== 0)) {
        notificationDismiss = appActions.NOTIFICATION_DISMISS_TIMEOUT_DEFAULT;
      }
      // Se o valor for 0, mantém a notificação para sempre
      if (this.props.notificationDismiss !== 0) {
        // Guarda o temporizador para limpar ele quando a notificação for oculta manualmente
        this.timeoutList.push(
          // Senão, oculta a notificação daqui a um tempo
          setTimeout(() => {
            // Se não for notificação de pergunta
            if ([actionTypes.APP_NOTIFICATION_TYPE_INFO_QUESTION, actionTypes.APP_NOTIFICATION_TYPE_WARNING_QUESTION].indexOf(this.props.notificationType) < 0) {
              // Oculta a notificação
              this.props.appNotificationHide();
            }
            // Define em quanto tempo a notificação será oculta. Valor recebido é em segundos e deve ser transformado para milissegundos.
          }, this.props.notificationDismiss * 1000));
      }
    }
    // Se a notificação está oculta
    else {
      // Testa se foi possível acessar o div da dialog
      if (this.notificationElement) {
        // Remove o método de clicar na notificação
        this.notificationElement.onclick = () => { };
      }
      // Limpa os temporizadores de auto ocultação
      this.timeoutList.forEach(timeout => clearTimeout(timeout));
    }

  }

  key_down = (event) => {
    if (event.key === 'Enter') {
      this.confirmButtonElement && this.confirmButtonElement.click();
    }
  };

  /**
   * Método que monta o componente.
   */
  render() {
    log('NotificationSystem render');

    // Monta o título da notificação. Se for um título genérico, pega o texto correspondente ao idioma em uso.
    let title = this.props.notificationTitleI18n ? getStrings()[this.props.notificationTitle] : this.props.notificationTitle;

    // Monta a notificação
    // Se for uma notificação com pergunta de sim/não, monta os botões correspondentes
    let content = <div ref={ref => {
      if (ref) {
        this.notificationElement = ref.parentElement;
        ref.parentElement.id = 'Notification';
        ref.parentElement.tabIndex = 0;
      }
    }}>
      {this.props.notificationContent}
      {((this.props.notificationType === actionTypes.APP_NOTIFICATION_TYPE_INFO_QUESTION)
        || (this.props.notificationType === actionTypes.APP_NOTIFICATION_TYPE_WARNING_QUESTION)) ?

        <div className={`dialog-horizontal-button-grid${isAncientChromeMobile ? ' ancient-chrome-mobile' : ''}`}>
          {/* Se clicado no SIM, executa o comando recebido por parâmetro */}
          <ConfirmButton reference={ref => {
            if (ref) {
              this.confirmButtonElement = ref;
            }
          }} id='yes-button'>{getStrings().yes}</ConfirmButton>
          {/* Senão não executa nada */}
          <ConfirmButton id='no-button'>{getStrings().no}</ConfirmButton>
        </div> : null}

      {this.props.notificationType === actionTypes.APP_NOTIFICATION_TYPE_SUCCESS_UNDOABLE ?
        <div className='dialog-horizontal-button-grid'>
          {/* Se clicado no DESFAZER, executa o comando recebido por parâmetro */}
          <button ref={ref => {
            if (ref) {
              this.confirmButtonElement = ref;
            }
          }} id='yes-button' style={{ gridColumnStart: '3' }}>{getStrings().undo}</button>
        </div> : null}
    </div>;

    // Monta o componente
    return <div
      onKeyDown={this.key_down}
      className='notification-system'
    >
      <SkyLightStateless
        ref={ref => this.dialog = ref}
        closeButtonStyle={null}
        closeOnEsc={true}
        dialogStyles={{ ...this.props.notificationDialogColors }}
        isVisible={this.props.notificationShow}
        onCloseClicked={this.props.appNotificationHide}
        onOverlayClicked={this.props.appNotificationHide}
        title={title}
        titleStyle={{ ...this.props.notificationTitleColors }}>
        {content}

      </SkyLightStateless>
    </div>;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  notificationShow: state.appReducer.notificationShow,
  notificationTitle: state.appReducer.notificationTitle,
  notificationTitleI18n: state.appReducer.notificationTitleI18n,
  notificationContent: state.appReducer.notificationContent,
  notificationDialogColors: state.appReducer.notificationDialogColors,
  notificationTitleColors: state.appReducer.notificationTitleColors,
  notificationType: state.appReducer.notificationType,
  notificationDismiss: state.appReducer.notificationDismiss,
  notificationAction: state.appReducer.notificationAction,
  notificationNoAction: state.appReducer.notificationNoAction
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  appNotificationHide: () => dispatch(appActions.appNotificationHide())
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(NotificationSystem);
