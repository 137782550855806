import React from "react"
import { connect } from "react-redux"

import { isEmpresaRestauranteAKilo } from "../../../utils/CompanyUtils"
import { isNonEmptyArray } from "../../../utils/ComparatorsUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { formatI18nString } from "../../../utils/StringUtils"

import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"
import { FILTER_TIMER_DELAY } from "../../../store/actions/cadastroAction"
import { SPINNER_FILTRO_ORIGEM_VENDA_ID, SPINNER_FILTRO_VENDA_ID } from "../../../store/actions/controleVenda/controleVendaAction"
import { RamoEmpresa } from "../../../store/actions/empresaSelectorAction"

import BarraAcoesOrigemVenda from "../barraAcao/BarraAcoesOrigemVenda.js"
import BuildCellFromOrigem from "./BuildCellFromOrigem.js"
import FiltroInputDefault from "../../UI/filtroInputDefault/FiltroInputDefault"
import HelpParagraph from "../../UI/HelpParagraph/HelpParagraph"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

class GridOrigemVenda extends React.Component {
  ismounted = false

  /**
   * Método executado APÓS a montagem do componente.
   * Realiza o carregamento dos cadastros na tela.
   */
  componentDidMount() {
    log("GridOrigemVenda componentDidMount")
    this.ismounted = true
    // Se estiver abrindo uma venda a partir de um chamado em uma origem de venda
    if (this.props.fromCallState
      // ou se for uma empresa do ramo Painel, que não usa essa tela,
      || (this.props.ramoEmpresa === RamoEmpresa.PAINEL)) {
      // não pode buscar outros cadastros.
      return
    }
    this.props.loadOrigensEmUso()
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  componentDidUpdate(previousProps) {
    log("GridOrigemVenda componentDidUpdate")
    // Se o dialog de origens livres estava visível e passou a estar oculto,
    // marca as origens e as vendas como não sendo usadas por este usuário.
    if (previousProps.dialogShow && (!this.props.dialogShow) && (this.props.dialogTitle === getStrings().newSale)) {
      this.props.clearUsuarioAtivo()
    }
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("GridOrigemVenda render")

    var origemVendaGrid = []

    /*
     * Inicializa a variável que exibirá o grid de origens, se houverem origens,
     * ou o spinner, que poderá ter sido oculto após falha no método que carrega as origens.
     */
    if (isNonEmptyArray(this.props.origemVendaList)) {
      /* Monta as celulas que irão compor a grid */
      origemVendaGrid = this.props.origemVendaList.map(origemVenda =>
        <BuildCellFromOrigem tabIndex={this.props.tabIndex} key={origemVenda._links.self.href} origemVenda={origemVenda} isOrigemLivre={false} />
      )
    }

    return <>

      <WidthAwareDiv>
        <HelpParagraph children={[].concat(
          getStrings().saleSourceHelp()[0],
          getStrings().saleSourceHelp()[1],
          formatI18nString(getStrings().saleSourceHelp()[2]),
          isEmpresaRestauranteAKilo(this.props.ramoEmpresa) ? formatI18nString(getStrings().saleSourceHelp()[3]) : [],
          formatI18nString(getStrings().saleSourceHelp()[4]),
          getStrings().saleSourceFilterHelp(),
          getStrings().saleSourceHelp()[5],
          getStrings().saleSourceHelp()[6]
        )} />
      </WidthAwareDiv>

      <BarraAcoesOrigemVenda handleNovo={this.handleNovo} payDisabled={!isNonEmptyArray(this.props.origemVendaList)}
        novoDisabled={!this.props.containsFree} />

      <WidthAwareDiv>
        <div className="sub-form">
          <FiltroInputDefault
            id="filtroCartao"
            defaultValue={this.props.filtroOrigemVendaValue}
            ref={input => this.filtroOrigemVendaComponent = getReduxWrappedComponent(input)}
            onFilterTimerCompleted={() => {
              if (this.ismounted) {
                this.props.loadOrigensEmUso(this.filtroOrigemVendaComponent.getValue(), this.filtroVendaComponent.getValue())
              }
            }}
            spinnerId={SPINNER_FILTRO_ORIGEM_VENDA_ID}
            timerDelay={FILTER_TIMER_DELAY}
            label={getStrings().saleSourceFilter()}
            placeholder={getStrings().saleSourceFilterPlaceholder()}
          />

          <FiltroInputDefault
            id="filtroComanda"
            defaultValue={this.props.filtroVendaValue}
            ref={input => this.filtroVendaComponent = getReduxWrappedComponent(input)}
            onFilterTimerCompleted={() => {
              if (this.ismounted) {
                this.props.loadOrigensEmUso(this.filtroOrigemVendaComponent.getValue(), this.filtroVendaComponent.getValue())
              }
            }}
            spinnerId={SPINNER_FILTRO_VENDA_ID}
            timerDelay={FILTER_TIMER_DELAY}
            label={getStrings().saleFilter()}
            placeholder={getStrings().saleFilterPlaceholder()}
          />
        </div>
      </WidthAwareDiv>

      <div className="pure-g" >
        {origemVendaGrid}
      </div>
    </>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  dialogShow: state.appReducer.dialogShow,
  dialogTitle: state.appReducer.dialogTitle,
  tabIndex: state.appReducer.getTabIndex(),
  ...state.idiomaReducer,
  containsFree: state.controleVendaReducer.containsFree,
  filtroOrigemVendaValue: state.controleVendaReducer.filtroOrigemVendaValue,
  filtroVendaValue: state.controleVendaReducer.filtroVendaValuem,
  fromCallState: state.controleVendaReducer.fromCallState,
  notificationShow: state.appReducer.notificationShow,
  origemVendaList: state.controleVendaReducer.origemVendaList,
  ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  clearUsuarioAtivo: () => dispatch(controleVendaActions.clearUsuarioAtivo()),
  loadOrigensEmUso: (filterOrigemVenda, filterVenda) => dispatch(controleVendaActions.loadOrigensEmUso(filterOrigemVenda, filterVenda))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(GridOrigemVenda)
