import { push } from "connected-react-router";
import React from "react";
import { connect } from "react-redux";

import { getStrings } from "../../../utils/LocaleUtils";

import { dispatchTipo } from "../../../store/actions/acaoTemplate";

// import { icones } from "../../UI/UI";

// function GenericButton(props: { type: "MOBI", src: string, onClick: (e: any) => void } | { type: "REACT_ICONS", Icon: any, onClick: (e: any) => void }) {
//   if (props.type === "REACT_ICONS") {
//     return <div onClick={props.onClick} className="pure-u-1 pure-u-sm-1-2 pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-4 cursor-pointer">
//       <div className="flex justify-content-center full-width full-height">
//         <props.Icon style={{width: "auto", height: "100%"}} />
//       </div>
//     </div>;
//   }

//   return <div onClick={props.onClick} className="pure-u-1 pure-u-sm-1-2 pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-4 cursor-pointer">
//     <img style={{ margin: "0.3em" }} alt="" src={props.src} />
//   </div>;
// }

function Dashboard_(props: { goTo: (path: string, state?: unknown) => void }) {
  return <p className='Lighter'>{getStrings().dashBoardComingSoon}</p>;
}

function mapStateToProps(state: any) {
  const props = {
    tabIndex: state.appReducer.getTabIndex()
  };

  return props;
};

function dispatchToProps(dispatch: dispatchTipo) {
  const props = {
    goTo: (path: string, state?: unknown) => dispatch(push(path, state))
  };

  return props;
}

const Dashboard = connect(mapStateToProps, dispatchToProps)(Dashboard_);

export default Dashboard;