import React from "react"
import { connect } from "react-redux"

import { getEmpresaId } from "../../../utils/CompanyUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { getProductImagePath } from "../../../utils/MiscUtils"
import { isAncientChromeMobile, isEdge, isIE } from "../../../utils/NavigatorUtils"
import { getIdFromEntity, getURIFromEntity } from "../../../utils/URIUtils"

import { NIVEL_USUARIO_SEM_EMPRESA_VENDA } from "../../../store/reducers/empresaSelectorReducer"
import * as appActions from "../../../store/actions/appAction"

import { Imagem } from "../../../apps/promo_app_frontend/nucleo/nucleo"
import DialogDescricao from "../dialog/DialogDescricao"
import Row from "../grid/Row"
import Column from "../grid/Column"
import Icones from "../../../assets/icones/Icones"
import DialogButton from "../dialog/DialogButton"
import SpinnerQuantidade from "./SpinnerQuantidade"

class CombinacoesProduto extends React.Component {

  state = {
    itemVenda: {
      quantidade: 0
    },
  }

  componentDidUpdate(prevProps) {
    if (getURIFromEntity(prevProps.produto || {}) !== getURIFromEntity(this.props.produto || {})) {
      this.imageSrc = getProductImagePath(getEmpresaId(), this.props.produto)
    }
    else {
      this.imageSrc = ""
    }

    if (prevProps.quantidadeSaboresSelecionados !== 0 && this.props.quantidadeSaboresSelecionados === 0) {
      this.setState({
        itemVenda: {
          quantidade: 0
        }
      })
    }
  }

  removeNewSaleItem = () => {
    this.addNewSaleItemOrSetAmount(null, null, null, 0, null, null)
  }

  addNewSaleItemOrSetAmount = (operation, newSaleItem, amountType, amount, observation, date) => {
    const quantidadeAdicionada = amount - this.state.itemVenda.quantidade
    const quantidadeSaboresSelecionados = this.props.quantidadeSaboresSelecionados + quantidadeAdicionada

    if (quantidadeSaboresSelecionados <= this.props.quantidadeMaximaSabores) {
      this.setState({
        itemVenda: {
          quantidade: amount
        }
      })

      this.props.updateQuantidadeSaboresSelecionados(quantidadeSaboresSelecionados)

      const key = getURIFromEntity(this.props.produto)
      const value = amount === 0 ? null : {
        codigo: this.props.produto.codigo,
        nome: this.props.produto.nome,
        quantidade: amount,
        valor: this.precoProduto(),
      }

      this.props.updateProdutosSelecionados(key, value)
    }
  }

  precoProduto = () => {
    const tamanhoProduto = this.props.produto.produtoTamanhoList.length
      ? this.props.produto.produtoTamanhoList.find(produtoTamanho => getIdFromEntity(produtoTamanho.tamanho) === getIdFromEntity(this.props.tamanhoSelecionado.tamanho))
      : null

    return tamanhoProduto ? tamanhoProduto.preco : this.props.produto.preco
  }

  render() {
    const MdInfoOutline = Icones.md.MdInfoOutline
    let menu = this.props.nivelUsuarioSemEmpresa !== NIVEL_USUARIO_SEM_EMPRESA_VENDA
    const desabilitado = this.state.itemVenda.quantidade === 0 && this.props.quantidadeSaboresSelecionados === this.props.quantidadeMaximaSabores

    return <div className={`accordion-item${isAncientChromeMobile ? " ancient-chrome-mobile" : ""}${desabilitado ? " desabilitado" : ""}`}>
      <div className={`accordion-item-cell${menu ? " menu" : ""} build-cell-from-produto`}>
        <Row className="i-1">
          <Column className="left">
            <Row>
              <h2 className="nome-produto TextAlign"> {this.props.itemVenda.livre ? getStrings().allYouCanEatName(this.props.produto.nome) : this.props.produto.nome} </h2>
            </Row>
            <Row>
              <Imagem alt={getStrings().productImageTemplate(this.props.produto.nome)} src={`${getProductImagePath(getEmpresaId(), this.props.produto)}`} />
            </Row>
          </Column>
          <Column className="right">
            <h2 className={`preco-produto NumberAlign${isAncientChromeMobile ? " right" : ""}`}> {getStrings().currencyValue(this.precoProduto())} </h2>
          </Column>
        </Row>
      </div>
      <div className="row i-2">
        <div className={`column left${isEdge || isIE ? " isMSBrowser" : ""}`}>
        </div>
        <div className={`column right${isEdge || isIE ? " isMSBrowser" : ""}`}>
          <div className="column left" style={{ float: "none" }}>
            <DialogButton className="descricao-produto" tabIndex={this.props.tabIndex} title={getStrings().descriptionPlaceholder} onClick={() => {
              this.props.appDialogShow(<DialogDescricao observacao={this.props.produto.descricao} />, getStrings().description)
            }}>
              <MdInfoOutline tabIndex={-1} />
            </DialogButton>
          </div>
          <div className="column right">
            <SpinnerQuantidade
              className={isAncientChromeMobile ? "right" : ""}
              {...this.state}
              produto={this.props.produto}
              addNewSaleItemOrSetAmount={this.addNewSaleItemOrSetAmount}
              removeNewSaleItem={this.removeNewSaleItem}
            />
          </div>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  productImagesReducer: state.productImagesReducer,
  ...state.idiomaReducer,
  nivelUsuarioSemEmpresa: state.controleVendaReducer.nivelUsuarioSemEmpresa,
})

const mapDispatchToProps = dispatch => ({
  appDialogShow: (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles) =>
    dispatch(appActions.appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CombinacoesProduto)
