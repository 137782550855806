import React from "react";
import styled from "styled-components";
import { TitleSub, Paragraph, ConteudoSection } from ".";
import { PATH_IMAGENS_FOTOS, PATH_IMAGENS_PRINTS } from "../utils/resources";
import { ImagensContainer, ImgRounded, PrintTablet } from "./Containers";

const IntegracaoDelivery = React.forwardRef((props, ref) => {
  return (
    <ConteudoSection ref={ref}>
      <TitleSub primary>Integração com aplicativos de delivery</TitleSub>
      <Paragraph>Ifood, DeliveryMuch, AiqFome... </Paragraph>
      <ImagensContainer height={"310px"}>
        <Print src={PATH_IMAGENS_PRINTS + "integracao1.png"} alt={"integracao"} />
        <ImgRoundLeft src={PATH_IMAGENS_FOTOS + "integracao2.png"} alt={"Cashier"} />
        <ImgRoundRight src={PATH_IMAGENS_FOTOS + "integracao3.png"} alt={"Notebook café"} />
      </ImagensContainer>
    </ConteudoSection>
  );
});

const Print = styled(PrintTablet)`
  position: absolute;
  z-index: 11;
`;

const ImgRoundRight = styled(ImgRounded)`
  right: -25px;
  bottom: 0;
  z-index: 10;
  @media (max-width: ${(props) => props.theme.sizes.breakPointSmall}) {
    right: 0;
  }
`;

const ImgRoundLeft = styled(ImgRounded)`
  left: -25px;
  top: 0;
  z-index: 12;
  @media (max-width: ${(props) => props.theme.sizes.breakPointSmall}) {
    left: 0;
  }
`;

export default IntegracaoDelivery;
