import React from 'react';
import LucratividadeQuebraBox from './LucratividadeQuebraBox';

/**
 * Monta a tabela de relatório de lucratividade.
 */
export default class LucratividadeDataBox extends React.Component {

    render() {
        return <LucratividadeQuebraBox hora/>;
    }
}
