import React from "react"
import { connect } from "react-redux"

import { getAppEmpresa } from "../../../utils/AppUtils"
import { isEmpresaRestauranteAKilo, getEmpresaId } from "../../../utils/CompanyUtils"
import { equalsCoerced } from "../../../utils/ComparatorsUtils"
import { getHeaders } from "../../../utils/HeadersUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import {  getProductImagePath } from "../../../utils/MiscUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { cadastroBuildJSONFromOptions, cadastroBuildOptionsFromCadastros } from "../../../utils/SelectUtils"
import { stringParseFloat } from "../../../utils/StringUtils"
import { entityURIListEqualsList } from "../../../utils/URIUtils"

import { APP_NOTIFICATION_TYPE_ERROR, APP_NOTIFICATION_TYPE_SUCCESS, APP_NOTIFICATION_TYPE_WARNING } from "../../../store/actions/actionTypes"
import * as appActions from "../../../store/actions/appAction"
import * as actions from "../../../store/actions/cadastroAction"
import * as productImagesAction from "../../../store/actions/productImagesAction"
import { axios } from "../../../store/actions/appAction"
import { getURIFromEntity } from "../../../utils/URIUtils"
import { reduxDispatch } from "../../../utils/reduxUtils/reduxUtils"

import CadastroForm from "./CadastroForm"
import InputCustomizado from "../../UI/Input/InputCustomizado"
import AtivoInputDefault from "../cadastroInputDefault/AtivoInputDefault.js"
import CodigoInputDefault from "../cadastroInputDefault/CodigoInputDefault.js"
import NomeInputDefault from "../cadastroInputDefault/NomeInputDefault.js"
import { Button } from "../../UI/Button/Button"
import FormValuesTemplate from "./FormValuesTemplate"
import ProdutoTamanhoForm from "./ProdutoTamanhoForm"

import "./ProdutoForm.css";

/**
 * Classe que repassa para o CadastroForm as informações para a montagem do formulário para o cadastro dos Produtos.
 */
class ProdutoForm extends React.Component {
  render() {
    log("ProdutoForm render");
    return <CadastroForm {...this.props} formModel={ProdutoValues}
      registerSaveHelp={getStrings().productSaveHelp} registerUpdateHelp={getStrings().productUpdateHelp} />;
  }
}

/**
 * Classe que realiza a montagem do formulário para o cadastro dos Produtos.
 */
class ProdutoValues extends FormValuesTemplate {
  tamanho = {};
  enabled = true;
  ismounted = false;

  state = {
    produtoTamanhoList: [],
  }

  // Diminui imagem quando ela for carregada
  imageLoadedHandler = (event) => {
    // tamanho da imagem
    let width = event.target.naturalWidth;
    let height = event.target.naturalHeight;

    // tamanho máximo permitido da imagem
    const maxSize = 1280;

    // caso valores sejam maiores que o tamanho máximo permitido (ps: ele arredondam pra baixo, por isso ">= (maxSize + 1)")
    if (width >= (maxSize + 1) || height >= (maxSize + 1)) {
      // pega o maior valor, divide ele pelo valor máximo permitido e transforma em decimal 
      const decimal = 1 / ((width >= height) ? (width / maxSize) : (height / maxSize));

      // aplica valor decimal para diminuir a escala até o valor máximo permitido
      width = Math.round(width * decimal);
      height = Math.round(height * decimal);

      // canvas necessário para o direcionamento
      const canvas = document.createElement("canvas");
      const quality = 1.0;

      // aplica tamanho no canvas
      canvas.width = width;
      canvas.height = height;

      // pega o contexto e desenha a imagem dentro do canvas
      const ctx = canvas.getContext("2d");
      ctx.drawImage(event.target, 0, 0, width, height);

      // gera imagem como base64 
      this.props.updateUploadedImage({ src: ctx.canvas.toDataURL(event.target, this.mimetype, quality) });
    }
  }

  uploadImageHandler = (event) => {
    // verifica se há algum arquivo
    if (event.target.files.length) {
      // Necessário para gerar base64
      const reader = new FileReader();

      reader.onload = (event) => {
        const tamanthoEmMB = (event.total / 1024000);
        if (tamanthoEmMB <= 1.3) {
          // resultado da leitura
          const result = event.target.result;
          // tipo de arquivo (mime)
          const mimetype = result.substring(result.indexOf("data:") + "data:".length, result.indexOf(";"));

          this.props.updateUploadedImage({ src: result, style: { display: "block" } });

          // mimetype passa a ter acesso global
          this.mimetype = mimetype
        }
        else {
          reduxDispatch(appActions.appDialogShow(getStrings().largerSizeError, getStrings().error));
        }
      }

      const arquivo = event.target.files[0];

      // lê arquivo
      reader.readAsDataURL(arquivo);
    }
  }

  /**
   * Retorna se os campos obrigatórios (required) foram todos preenchidos
   */
  checkRequired = newData => {
    log("ProdutoValues checkRequired", newData)
    return (newData.codigo !== "") && (newData.nome !== "") && (newData.preco !== null) && (newData.precoCusto !== null);
  }

  /**
   * Recupera os dados do formulário.
   */
  getFormData = () => {
    const formData = {
      empresaSet: [getAppEmpresa()],
      enabled: this.enabled.getValue(),
      codigo: this.codigo.getValue(),
      nome: this.nome.getValue(),
      descricao: this.descricao.inputComponent.value,
      preco: this.state.produtoTamanhoList.length ? '0.00' : this.preco.getMaskValue(),
      precoCusto: this.precoCusto.getMaskValue(),
      grupoProdutoList: cadastroBuildJSONFromOptions(true, this.grupoProdutoList.inputComponent.getValue()),
      tipoProdutoList: cadastroBuildJSONFromOptions(true, this.tipoProdutoList.inputComponent.getValue()),
      grupoProdutoListOption: this.grupoProdutoList.inputComponent.getValue(),
      tipoProdutoListOption: this.tipoProdutoList.inputComponent.getValue(),
      usoInterno: this.usoInterno.inputComponent.checked,
      quantidadePesada: isEmpresaRestauranteAKilo(this.props.ramoEmpresa) ? this.quantidadePesada.inputComponent.checked : false,
      estadoProntoCasoQuantidadePesada: isEmpresaRestauranteAKilo(this.props.ramoEmpresa) ? this.estadoProntoCasoQuantidadePesada.inputComponent.checked : false,
      codigoImpresso: this.codigoImpresso.inputComponent.value,
      //formatoCodigoImpresso: cadastroRecuperaValueFromOptions(this.formatoCodigoImpresso.inputComponent.getValue()),
      //formatoCodigoImpressoOption: this.formatoCodigoImpresso.inputComponent.getValue(),
      //finalidade: cadastroRecuperaValueFromOptions(this.finalidade.inputComponent.getValue()),
      //finalidadeOption: this.finalidade.inputComponent.getValue(),
      //quantidadeMaximaAdicional: this.usesQuantidadeAdicional ? this.quantidadeMaximaAdicional.getMaskValue() : null,
      produtoTamanhoList: this.state.produtoTamanhoList
        .map((produtoTamanho) => {
          const { id, tamanho, preco } = produtoTamanho;

          return {
            id,
            tamanho: getURIFromEntity(tamanho),
            preco: stringParseFloat(`${preco}`),
          }
        }),
      imagemB64: this.props.productImagesReducer.uploadedImage.src
        ? this.props.productImagesReducer.uploadedImage.src
        : this.props.productImagesReducer.currentImage.src || null,
      numeroMaximoProdutos: this.numeroMaximoProdutos.getMaskValue(),
      ...(this.props.cadastroDados._links ? { _links: this.props.cadastroDados._links } : {}),
    }

    log("ProdutoValues getFormData", formData);

    return formData;
  }

  /**
   * Método executado ao clicar no botão "Gravar".
   * Recupera os dados do formulário e repassa para o método que irá persistir os dados.
   */
  handleGravar = () => {
    log("ProdutoValues handleGravar");

    // Repassa para o método que irá persistir os dados
    this.props.onSave(this.getFormData());
  }

  //isFormatoCodigoImpressoSet = () => !!this.props.cadastroDados.formatoCodigoImpresso

  //isFinalidadeSet = () => !!this.props.cadastroDados.finalidade

  /**
   * Método que define o valor inicial do select.
   */
  // setFormatosCodigoImpressoVinculado = () => {
  //     log("ProdutoValues setFormatosCodigoImpressoVinculado")

  //     // Se uma opção havia sido selecionada, coloca essa opção no select. Senão, gera a opção a partir da entidade que estiver no cadastro.
  //     // A entidade só estará disponível para gerar uma opção quando um cadastro for editado, pois seus dados são lidos da retaguarda.
  //     // Quando o cadastro é enviado para a retaguarda, a entidade é substituída pelo seu URI, mas a partir daí já havia uma opção selecionada.
  //     this.formatoCodigoImpresso.inputComponent.updateValue(
  //         this.props.cadastroDados.formatoCodigoImpressoOption
  //             ? this.props.cadastroDados.formatoCodigoImpressoOption
  //             : this.props.cadastroDados.formatoCodigoImpresso ? cadastroBuildOptionsFromEnum(
  //                 false, this.props.cadastroDados.formatoCodigoImpresso, string => getStrings().printedCodeFormatEnumToString(string)) : null)
  // }

  /**
* Método que define o valor inicial do select.
*/
  // setFinalidadeVinculada = () => {
  //     log("ProdutoValues setFinalidadeVinculada")

  //     // Se uma opção havia sido selecionada, coloca essa opção no select. Senão, gera a opção a partir da entidade que estiver no cadastro.
  //     // A entidade só estará disponível para gerar uma opção quando um cadastro for editado, pois seus dados são lidos da retaguarda.
  //     // Quando o cadastro é enviado para a retaguarda, a entidade é substituída pelo seu URI, mas a partir daí já havia uma opção selecionada.
  //     this.finalidade.inputComponent.updateValue(
  //         this.props.cadastroDados.finalidadeOption 
  //         ? this.props.cadastroDados.finalidadeOption 
  //         : this.props.cadastroDados.finalidade ? cadastroBuildOptionsFromEnum(
  //             false, this.props.cadastroDados.finalidade, string => getStrings().productFunctionEnumToString(string)) : null)
  // }

  /**
   * Método que ajusta os Grupos de Produto vinculados a este produto nas opções do Multi Select.
   */
  setGruposVinculados = () => {
    log("ProdutoValues setGruposVinculados")

    this.grupoProdutoList.inputComponent.updateValue(
      this.props.cadastroDados.grupoProdutoListOption ? this.props.cadastroDados.grupoProdutoListOption :
        (this.props.cadastroDados.grupoProdutoList && (this.props.cadastroDados.grupoProdutoList.length > 0))
          ? cadastroBuildOptionsFromCadastros(true, this.props.cadastroDados.grupoProdutoList) : []);
  }

  /**
   * Método que ajusta os Tipos de Produto vinculados a este produto nas opções do Multi Select.
   */
  setTiposVinculados = () => {
    log("ProdutoValues setTiposVinculados")

    this.tipoProdutoList.inputComponent.updateValue(
      this.props.cadastroDados.tipoProdutoListOption ? this.props.cadastroDados.tipoProdutoListOption :
        (this.props.cadastroDados.tipoProdutoList && (this.props.cadastroDados.tipoProdutoList.length > 0))
          ? cadastroBuildOptionsFromCadastros(true, this.props.cadastroDados.tipoProdutoList) : []);
  }

  //usesQuantidadeAdicional = () => this.props.cadastroDados && this.props.cadastroDados.finalidade && ([Finalidade.PRODUTO_E_ADICIONAL, Finalidade.SOMENTE_ADICIONAL].indexOf(this.props.cadastroDados.finalidade) > -1)

  /**
   * Retorna se houve alteração em algum dado do cadastro
   */
  wasChanged = (newData, oldData) => {
    let result = !equalsCoerced(oldData, newData, (oldValue, newValue) =>
      (newValue.enabled === oldValue.enabled)
      && (newValue.codigo === oldValue.codigo)
      && (newValue.nome === oldValue.nome)
      && (newValue.preco === oldValue.preco)
      && (newValue.precoCusto === oldValue.precoCusto)
      && (newValue.descricao === oldValue.descricao)
      && entityURIListEqualsList(oldValue.grupoProdutoList, newValue.grupoProdutoList)
      && entityURIListEqualsList(oldValue.tipoProdutoList, newValue.tipoProdutoList)
      && (newValue.usoInterno === oldValue.usoInterno)
      && (isEmpresaRestauranteAKilo(this.props.ramoEmpresa) ? (newValue.quantidadePesada === oldValue.quantidadePesada) : true)
      //&& (newValue.formatoCodigoImpresso === oldValue.formatoCodigoImpresso)
      && (equalsCoerced(oldValue.codigoImpresso, newValue.codigoImpresso, (oldString, newString) => newString === oldString)));
    log("ProdutoValues wasChanged", { newData, oldData, result });
    return result;
  }

  /**
   * Retorna se houve alteração em algum dado do cadastro, comparando com os dados sendo editados.
   */
  wasChangedFromCurrent = newData => {
    log("ProdutoValues wasChangedFromCurrent", newData)
    return this.wasChanged(newData, this.props.cadastroDados);
  }

  /**
   * Retorna se houve alteração em algum dado do cadastro, comparando com os dados presentes ao abrir a criação/edição do cadastro.
   */
  wasChangedFromInitial = newData => {
    log("ProdutoValues wasChangedFromInitial", newData)
    return this.wasChanged(newData, this.props.cadastroDadosIniciais);
  }

  uploadFile = (event) => {
    try {
      event.stopPropagation();
      event.preventDefault();
    }
    catch (e) { }

    if (event.target.id === "removeImage") {
      return;
    }

    this.props.appSpinnerShow("save_product_image");

    const data = this.props.productImagesReducer.uploadedImage.src; // conseguir imagem em formato b64
    const version = (Math.random() * 100).toFixed(2);

    axios().post(`${this.uri}/saveProductImage`, { data }, getHeaders())
      .then(() => {
        this.props.appNotificationShow(getStrings().saveProductImageSuccess, APP_NOTIFICATION_TYPE_SUCCESS);
        this.props.updateCurrentImage({ src: data, style: { display: "block" }, version });
      })
      .catch(() => {
        this.props.appNotificationShow(getStrings().saveProductImageError, APP_NOTIFICATION_TYPE_ERROR);
        this.props.updateCurrentImage({ style: { display: "none" } });
      })
      .finally(() => { this.props.appSpinnerHide("save_product_image"); });
  }

  removeProductImage = (event) => {
    try {
      event.stopPropagation();
      event.preventDefault();
    }
    catch (e) { }

    const empresaId = getEmpresaId();

    this.props.appSpinnerShow("remove_product_image")
    axios().post(`${this.uri}/removeProductImage`, { empresaId }, getHeaders())
      .then(() => {
        this.props.appNotificationShow(getStrings().removeProductImageSuccess, APP_NOTIFICATION_TYPE_SUCCESS)
        this.props.updateCurrentImage({ src: "" })
      })
      .catch(() => { this.props.appNotificationShow(getStrings().removeProductImageError, APP_NOTIFICATION_TYPE_ERROR) })
      .finally(() => { this.props.appSpinnerHide("remove_product_image") });
  }

  handleGroupChange = () => {
    const grupos = this.grupoProdutoList.inputComponent.getValue();

    if (grupos.length > 1
      && grupos[grupos.length - 1].value.grupoProdutoTamanhoList?.length
      && grupos.slice(0, grupos.length - 1).some(grupo => grupo.value.grupoProdutoTamanhoList?.length)) {

      this.props.dispatch(appActions.appNotificationShow(getStrings().onlyOneGroupWithSizeAllowed, APP_NOTIFICATION_TYPE_WARNING))
      grupos.pop();
    }

    let grupoProdutoTamanhoList = null;

    grupos.forEach((grupo) => {
      if (grupo.value.grupoProdutoTamanhoList?.length) {
        grupoProdutoTamanhoList = grupo.value.grupoProdutoTamanhoList;
      }
    });

    this.props.handleChange();

    if (grupoProdutoTamanhoList?.length) {
      const produtoTamanhoListAtual = this.state.produtoTamanhoList.map((produtoTamanho) => {
        return { [getURIFromEntity(produtoTamanho.tamanho)]: produtoTamanho };
      })
      const produtoTamanhoListNovo = grupoProdutoTamanhoList.map(grupoProdutoTamanho => {
        const uri = getURIFromEntity(grupoProdutoTamanho.tamanho)
        if (produtoTamanhoListAtual[uri]) {
          return {
            id: produtoTamanhoListAtual[uri].id,
            preco: produtoTamanhoListAtual[uri].preco,
            tamanho: produtoTamanhoListAtual[uri].tamanho,
          };
        }
        else {
          return {
            id: null,
            preco: grupoProdutoTamanho.preco,
            tamanho: grupoProdutoTamanho.tamanho,
          };
        }
      })
      this.setState({ produtoTamanhoList: produtoTamanhoListNovo });
    }
    else {
      this.setState({ produtoTamanhoList: [] });
    }
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   * Carrega os grupos e tipos disponíveis, também define os valores iniciais das opções conforme cadastro carregado.
   */
  componentDidMount() {
    log("ProdutoValues componentDidMount");
    this.ismounted = true;

    // this.props.cadastroLoadFormatosCodigoImpresso(cadastros => {
    //     if (!this.ismounted)
    //         return

    //     this.formatoCodigoImpresso.inputComponent.updateOptions(
    //         cadastroBuildOptionsFromEnum(true, cadastros, string => getStrings().printedCodeFormatEnumToString(string)))
    // }, () => {
    //     if (this.ismounted && (this.props.loading.formatosCodigoImpresso !== false)) {

    //         this.props.cadastroUpdateLoading({ formatosCodigoImpresso: false })
    //     }
    // })

    // this.props.cadastroLoadFinalidadesProduto(cadastros => {
    //     if (!this.ismounted)
    //         return

    //     this.finalidade.inputComponent.updateOptions(
    //         cadastroBuildOptionsFromEnum(true, cadastros, string => getStrings().productFunctionEnumToString(string)))

    //     window.setTimeout(() => this.setFinalidadeVinculada(), 0)
    // }, () => {
    //     if (this.ismounted && (this.props.loading.finalidades !== false)) {

    //         this.props.cadastroUpdateLoading({ finalidades: false })
    //     }
    // })

    this.props.cadastroLoadGrupos(this.props.urlDataBase, grupoProduto => {
      if (this.ismounted) {
        this.grupoProdutoList.inputComponent.updateOptions(cadastroBuildOptionsFromCadastros(true, grupoProduto));
      }
    }, () => {
      if (this.ismounted && (this.props.loading.grupos !== false)) {

        this.props.cadastroUpdateLoading({ grupos: false });
      }
    })

    this.props.cadastroLoadTiposProduto(this.props.urlDataBase, tipoProduto => {
      if (this.ismounted) {
        this.tipoProdutoList.inputComponent.updateOptions(cadastroBuildOptionsFromCadastros(true, tipoProduto));
      }
    }, () => {
      if (this.ismounted && (this.props.loading.tipos !== false)) {

        this.props.cadastroUpdateLoading({ tipos: false });
      }
    })

    this.props.updateUploadedImage({ onLoad: this.imageLoadedHandler });
    this.uri = getURIFromEntity(this.props.cadastroDados);
  }

  /**
   * Método executado APÓS a atualização do componente.
   * Carrega os grupos e tipos disponíveis, também define os valores iniciais das opções conforme cadastro carregado.
   */
  componentDidUpdate(prevProps) {
    log("ProdutoValues componentDidUpdate");

    if (this.props.cadastroDados) {

      const cadastro = this.props.cadastroDados;

      // Se for passado false, é false; se for passado true, null, undefined ou não for passado, é true
      this.enabled.inputField.inputComponent.checked = cadastro.enabled !== false;

      this.codigo.inputField.inputComponent.value = cadastro.codigo ? cadastro.codigo : null;

      this.nome.inputField.inputComponent.value = cadastro.nome ? cadastro.nome : null;

      this.preco.setMaskValue((cadastro.preco || (cadastro.preco === 0)) ? cadastro.preco : null);

      this.precoCusto.setMaskValue((cadastro.precoCusto || (cadastro.precoCusto === 0)) ? cadastro.precoCusto : null);

      this.descricao.inputComponent.value = cadastro.descricao ? cadastro.descricao : null;

      this.usoInterno.inputComponent.checked = cadastro.usoInterno;

      this.numeroMaximoProdutos.setMaskValue((cadastro.numeroMaximoProdutos || (cadastro.numeroMaximoProdutos === 0)) ? cadastro.numeroMaximoProdutos : null);

      if (isEmpresaRestauranteAKilo(this.props.ramoEmpresa)) {
        this.quantidadePesada.inputComponent.checked = cadastro.quantidadePesada;
        this.estadoProntoCasoQuantidadePesada.inputComponent.checked = cadastro.estadoProntoCasoQuantidadePesada;
      }

      //if (this.isFormatoCodigoImpressoSet()) {
      this.codigoImpresso.inputComponent.value = cadastro.codigoImpresso ? cadastro.codigoImpresso : null;
      //}

      // if (this.usesQuantidadeAdicional) {
      //     this.quantidadeMaximaAdicional.setMaskValue((cadastro.quantidadeMaximaAdicional || (cadastro.quantidadeMaximaAdicional === 0)) ? cadastro.quantidadeMaximaAdicional : null)
      // }

      if (cadastro.produtoTamanhoList && !this.state.produtoTamanhoList.length) {
        const produtoTamanhoList = cadastro.produtoTamanhoList.filter((produtoTamanho) => produtoTamanho.tamanho.nome);
        if (produtoTamanhoList.length) {
          this.setState({ produtoTamanhoList });
        }
      }
    }

    //this.setFormatosCodigoImpressoVinculado()

    this.setGruposVinculados();

    this.setTiposVinculados();

    if (!(prevProps.cadastroDados || {}).imagemFile && (this.props.cadastroDados || {}).imagemFile) {
      const src = getProductImagePath(getEmpresaId(), this.props.cadastroDados)
      const version = this.props.cadastroDados.imagemVersion || "0";

      this.props.updateCurrentImage({ src, version });
    }
  }

  componentWillUnmount() {
    this.props.updateUploadedImage({ src: "", style: { display: "none" } });
    this.props.updateCurrentImage({ src: "" });
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("ProdutoValues render");

    const mostrarCampoPreco = !this.state.produtoTamanhoList.length;

    const imagem = this.props.productImagesReducer.uploadedImage.src
      ? this.props.productImagesReducer.uploadedImage
      : this.props.productImagesReducer.currentImage;

    return <div className="sub-form">
      <AtivoInputDefault tabIndex={this.props.tabIndex} ref={input => this.enabled = getReduxWrappedComponent(input)} handleInputValidado={this.props.handleChange} />

      <>
        <div className="uploaded-image-container">
          <img
            alt={getStrings().productFormUploadedImage}
            id="uploaded-image"
            src={imagem.src || getStrings().noImageAvailableSrc}
            onError={evt => {
              evt.currentTarget.onerror = null;
              evt.currentTarget.src = getStrings().noImageAvailableSrc;
            }}
          />
        </div>
        <div className="upload-image">
          <div className="upload-wrapper">
            <label htmlFor="file-selection">{getStrings().loadProductImage}</label>
            {this.props.productImagesReducer.currentImage.src
              ? <Button
                  id="removeImage"
                  className="bar-button"
                  onClick={this.removeProductImage}
                >
                  {getStrings().removeProductImage}
                </Button>
              : null
            }
          </div>
          <div id="image-container">
            <input id="file-selection" style={{ width: "50%" }} type="file" onChange={this.uploadImageHandler} />
          </div>
        </div>
      </>

      <CodigoInputDefault ref={input => this.codigo = getReduxWrappedComponent(input)} subPlaceholder={getStrings().productSubPlaceholder} handleInputValidado={this.props.handleChange} />

      <NomeInputDefault ref={input => this.nome = getReduxWrappedComponent(input)} subPlaceholder={getStrings().productSubPlaceholder} handleInputValidado={this.props.handleChange} isRequired={true} />

      <InputCustomizado topClassStyle={mostrarCampoPreco ? null : {display: "none"}} id="preco" max={100000000} scale={2} inputType="masked" type="text" validacaoDados="numeroDecimal" name="preco" required={mostrarCampoPreco} ref={input => this.preco = getReduxWrappedComponent(input)} label={getStrings().priceRequired} placeholder={getStrings().pricePlaceholder} onBlur={this.props.handleChange} />

      <InputCustomizado id="precoCusto" max={100000000} scale={2} inputType="masked" type="text" validacaoDados="numeroDecimal" name="precoCusto" required={true} ref={input => this.precoCusto = getReduxWrappedComponent(input)} label={getStrings().costPriceRequired} placeholder={getStrings().costPricePlaceholder} onBlur={this.props.handleChange} />

      <InputCustomizado id="descricao" inputType="textarea" name="descricao" maxLength="500" ref={input => this.descricao = getReduxWrappedComponent(input)} label={getStrings().description} placeholder={getStrings().descriptionPlaceholder} handleInputValidado={this.props.handleChange} />

      <InputCustomizado id="grupo" inputType="multiSelect" name="grupo" label={getStrings().group} placeholder={getStrings().groupPlaceholder} ref={input => this.grupoProdutoList = getReduxWrappedComponent(input)} onChange={() => setTimeout(this.handleGroupChange, 0)} />

      <ProdutoTamanhoForm
        produtoTamanhoList={this.state.produtoTamanhoList}
        updateValues={(index, values) => {
          const produtoTamanhoList = this.state.produtoTamanhoList;
          produtoTamanhoList[index] = { ...produtoTamanhoList[index], preco: values.precoCustomizado };
          this.setState({ produtoTamanhoList });
        }}
      />

      <InputCustomizado id="tipo" inputType="multiSelect" name="tipo" label={getStrings().type} placeholder={getStrings().productTypePlaceholder} ref={input => this.tipoProdutoList = getReduxWrappedComponent(input)} onChange={() => setTimeout(() => this.props.handleChange(), 0)} />

      {/* <InputCustomizado id="formatoCodigoImpresso" name="formatoCodigoImpresso" ref={input => this.formatoCodigoImpresso = getReduxWrappedComponent(input)} placeholder={getStrings().printedCodeFormatPlaceholder} label={getStrings().printedCodeFormat} onChange={() => setTimeout(() => this.props.handleChange(), 0)} inputType="singleSelect" /> */}

      <InputCustomizado id="codigoImpresso" name="codigoImpresso" ref={input => { if (input) { this.codigoImpresso = getReduxWrappedComponent(input) } }} placeholder={getStrings().printedCodePlaceholder} label={getStrings().printedCode} handleInputValidado={this.props.handleChange} type="text" maxLength="17" />

      {/* <InputCustomizado id="finalidade" name="finalidade" ref={input => this.finalidade = getReduxWrappedComponent(input)} placeholder={getStrings().productFunctionPlaceholder} label={getStrings().productFunction} onChange={() => setTimeout(() => this.props.handleChange(), 0)} inputType="singleSelect" /> */}

      <InputCustomizado tabIndex={this.props.tabIndex} ref={input => { if (input) { this.numeroMaximoProdutos = input }}} id="numeroMaximoProdutos" name="numeroMaximoProdutos" type="text" inputType="masked" validacaoDados={"numeroInteiro"} scale={0} padFractionalZeros={false} label={getStrings().maximumNumberOfProducts} placeholder={getStrings().maximumNumberOfProductsPlaceholder} handleInputValidado={this.props.handleChange} />

      {/* {this.usesQuantidadeAdicional
                ? <InputCustomizado id="quantidadeMaximaAdicional" max={100} scale={2} inputType="masked" type="text" validacaoDados="numeroDecimal" name="quantidadeManximaAdicional" ref={input => this.quantidadeMaximaAdicional = getReduxWrappedComponent(input)} label={getStrings().additionalmaximumquantity} placeholder={getStrings().additionalmaximumquantityPlaceholder} handleInputValidado={this.props.handleChange} />
                : null} */}

      <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.usoInterno = getReduxWrappedComponent(input)} id="usoInterno" type="checkbox" name="usoInterno" label={(getStrings().internalUse + " (" + getStrings().internalUseExplanation + ")")} handleInputValidado={this.props.handleChange} />

      {isEmpresaRestauranteAKilo(this.props.ramoEmpresa)
        ? <InputCustomizado key={"__a_kilo_entrada__"} tabIndex={this.props.tabIndex} ref={input => this.quantidadePesada = getReduxWrappedComponent(input)} id="quantidadePesada" type="checkbox" name="quantidadePesada" label={getStrings().weightedAmount} handleInputValidado={this.props.handleChange} />
        : null}

      {isEmpresaRestauranteAKilo(this.props.ramoEmpresa)
        ? <InputCustomizado key={"__pronto_se_pesavel_entrada__"} tabIndex={this.props.tabIndex} ref={input => this.estadoProntoCasoQuantidadePesada = getReduxWrappedComponent(input)} id="estadoProntoCasoQuantidadePesada" type="checkbox" name="estadoProntoCasoQuantidadePesada" label={getStrings().readyStateCaseWeightAmout} handleInputValidado={this.props.handleChange} />
        : null}

    </div>;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
* @param {*} state
        */
const mapStateToProps = state => ({
  tabIndex: state.appReducer.getTabIndex(),
  ...state.idiomaReducer,
  productImagesReducer: state.productImagesReducer,
  ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa,
})

/**
 * Mapeia as ações.
* @param {*} dispatch
    */
const mapDispatchToProps = dispatch => ({
  dispatch,
  updateCurrentImage: (payload) => 
    dispatch(productImagesAction.updateCurrentImageAction(payload)),
  updateProductImages: (payload) => 
    dispatch(productImagesAction.updateProductImagesAction(payload)),
  updateUploadedImage: (payload) => 
    dispatch(productImagesAction.updateUploadedImageAction(payload)),
  appSpinnerShow: (origin) => 
    dispatch(appActions.appSpinnerShow(origin)),
  appSpinnerHide: (origin) => 
    dispatch(appActions.appSpinnerHide(origin)),
  appNotificationShow: (notificationMessage, notificationType, notificationTitle, notificationAction, notificationDismiss, notificationNoAction) =>
    dispatch(appActions.appNotificationShow(
      notificationMessage, notificationType, notificationTitle, notificationAction, notificationDismiss, notificationNoAction)),
  // cadastroLoadFormatosCodigoImpresso: (onSuccess, onComplete) => 
  //   dispatch(actions.cadastroLoadFormatosCodigoImpresso(onSuccess, onComplete)),
  // cadastroLoadFinalidadesProduto: (onSuccess, onComplete) => 
  //   dispatch(actions.cadastroLoadFinalidadesProduto(onSuccess, onComplete)),
  cadastroLoadGrupos: (urlDataBase, onSuccess, onComplete) => 
    dispatch(actions.cadastroLoadGrupos(urlDataBase, onSuccess, onComplete)),
  cadastroLoadTamanhoProdutos: (urlDataBase, onSuccess, onComplete) => 
    dispatch(actions.cadastroLoadTamanhoProdutos(urlDataBase, onSuccess, onComplete)),
  cadastroLoadTiposProduto: (urlDataBase, onSuccess, onComplete) => 
    dispatch(actions.cadastroLoadTiposProduto(urlDataBase, onSuccess, onComplete)),
  cadastroUpdateLoading: steps => 
    dispatch(actions.cadastroUpdateLoading(steps)),
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ProdutoForm)