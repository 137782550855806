import styled from "styled-components";
import { displayFlexCenter } from "../css/styles";
import React from "react";
import { Title, Paragraph } from ".";
import { PATH_IMAGENS_LOGOS } from "../utils/resources";

const goToCadastro = (e) => {
  e.preventDefault();
  console.log("clicado cadastro");
  window.location.href = `${window.location.origin}/signUpCompany`;
};

const Hero = React.forwardRef((props, ref) => (
  <>
    <HeroContent ref={ref}>
      <HeroImg src={PATH_IMAGENS_LOGOS + "logo-ampix-mobi-colorido.png"} alt="Ampix Mobi" />
      <Title primary>O mobi vai movimentar seu negócio</Title>
      <Paragraph>
        A ferramenta que vai impulsionar os negócios da sua empresa mantendo sua gestão segura e oferecendo um controle completo de cada
        etapa do processo. Faça o seu estabelecimento crescer enquanto atinge a total satisfação dos seus clientes.
      </Paragraph>
      <ButtonCTA onClick={goToCadastro}>
        <span>Começar</span>
      </ButtonCTA>
    </HeroContent>
  </>
));

const HeroContent = styled.div`
  ${displayFlexCenter};
  flex-direction: column;
  margin-top: ${(props) => props.theme.sizes.navBarHeight};
  padding: 2em 3em;
  width: 100%;
`;

const HeroImg = styled.img`
  width: 350px;
  max-width: 60vw;
`;

const ButtonCTA = styled.button`
  width: unset;
  text-transform: uppercase;
  display: block;
  background: #053680;
  border-radius: 5px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  will-change: transform;
  transition: transform 250ms;

  span {
    font-weight: 600;
    display: block;
    padding: 12px 42px;
    border-radius: 5px;
    font-size: 1.25rem;
    background: ${(props) => props.theme.primaryColor};
    color: white;
    transform: translateY(-4px);
  }

  &:hover span {
    transform: translateY(-6px);
  }
  &:active span {
    transform: translateY(-2px);
  }
`;

export default Hero;
