//@ts-check
import React from "react"
import { ClienteHttp, LocalidadeUtils, NavegacaoUtils } from "../nucleo/utils/utils"
import { mostrarDialog, fecharDialog } from "../nucleo/redux/dialog/dialogActions"
import { atualizarUsuario } from "../nucleo/redux/usuario/usuarioActions"
import ConfirmacaoContaDialog from "../componentes/dialogs/ConfirmacaoContaDialog/ConfirmacaoContaDialog"
import { ativarSnackbar } from "../nucleo/redux/snackbar/snackbarActions"
import { TIPO_CADASTRO_USUARIO_COMPLETO } from "../../../store/reducers/empresaSelectorReducer"
import { validaCPFOuCNPJ } from "../nucleo/utils/IdentificadorUtils/IdentificadorUtils"
import apelidos from "../rotas/apelidos"

/**
 * 
 * @param {{email: string, senha: string}} login 
 * @param {() => void | undefined} loginFalhou 
 * @param {(carga: any) => any} dispatch 
 * @param {string?} caminho 
 * @param {string?} tipoCadastroUsuario 
 */
export default function logar(
    {email, senha},
    loginFalhou,
    dispatch,
    caminho = null,
    tipoCadastroUsuario = null,
  ) {
  ClienteHttp.obterClienteHttp().post(ClienteHttp.getApi("login"), {email, senha})
    .then((resposta) => {
      const {
        authorization,
        expiration,
        usuario,
        cargoPadrao,
        nomeusuario,
        tamanhoPadraoPaginacao,
        cpf,
      } = resposta.headers;

      const armazenamento = {
          t: authorization,
          e: expiration,
          u: usuario,
          cp: cargoPadrao,
          nomeUsuario: nomeusuario,
          tamanhoPadraoPaginacao,
      };

      ClienteHttp.setAuthStorage(armazenamento);

      dispatch(atualizarUsuario());
      dispatch(fecharDialog());

      if (tipoCadastroUsuario === TIPO_CADASTRO_USUARIO_COMPLETO && !validaCPFOuCNPJ(cpf)) {
        dispatch(NavegacaoUtils.goTo(apelidos.editar, {editar: true, completarCadastro: true}));
      }
      else {
        dispatch(ativarSnackbar({frase: LocalidadeUtils.obterTraducao().login.welcomeUser(nomeusuario)}));
        caminho && dispatch(NavegacaoUtils.goTo(caminho));
      }
    })
    .catch((erro) => {
      if ((((erro || {}).response || {}).data || "") === "invalidPassword") {
        loginFalhou();
        dispatch(ativarSnackbar({frase: LocalidadeUtils.obterTraducao().login.loginFailed}));
      }
      else {
        ClienteHttp.obterClienteHttp()
          .post(ClienteHttp.getApi(`resentEmailValidation`), null, {params: {email}})
          .then(() => {
            dispatch(mostrarDialog({conteudo: () => <ConfirmacaoContaDialog caminho={caminho} login={{email, senha}} />}));
          })
          .catch(() => {
            loginFalhou();
            dispatch(ativarSnackbar({frase: LocalidadeUtils.obterTraducao().login.loginFailed}));
          });
      }
    });
}
