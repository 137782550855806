import styled from "styled-components";

const PrintSmartphone = styled.img`
  /* width: 300px; */
  height: 300px;
  outline: 5px solid black;
  margin: 5px;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  @media (max-width: ${(props) => props.theme.sizes.breakPointSmall}) {
    height: 200px;
  }
`;

export default PrintSmartphone;
