import styled from "styled-components";
import { displayFlexCenter } from "../css/styles";

export const Button = styled.button.attrs((props) => ({
  width: props.width || "92px",
  height: props.height || "92px",
}))`
  background: ${(props) => (!props.secondary ? props.theme.primaryColor : props.theme.lightBackgroundColor)};
  ${displayFlexCenter}
  flex-direction: column;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: ${(props) => (!props.secondary ? "none" : `1px solid ${props.theme.primaryColor}`)};
  border-radius: 5px;
  transition: 0.5s;
  cursor: pointer;
  color: ${(props) => (!props.secondary ? props.theme.lightTextColor : props.theme.primaryColor)};
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.2px;
  &:hover {
    background: ${(props) => (!props.secondary ? props.theme.lightBackgroundColor : props.theme.lightBackgroundColor2)};
    border: 1px solid ${(props) => props.theme.primaryColor};
    border: ${(props) => (!props.secondary ? `1px solid ${props.theme.primaryColor}` : `1px solid ${props.theme.secondaryColor}`)};
    color: ${(props) => (!props.secondary ? props.theme.primaryColor : props.theme.secondaryColor)};
  }

  @media (min-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    width: 130px;
    height: 58px;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    padding: 0 2px;
  }
  @media (min-width: ${(props) => props.theme.sizes.breakPointBig}) {
    width: 165px;
    height: 62px;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
  }
  ${(props) => props.styleCustom};
`;

export const ButtonRound = styled(Button)`
  ${(props) => props.classeCustom}
  border-radius: 37px;
`;
