import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from '../../../utils/LocaleUtils';

import { TamanhoTableRow } from './TamanhoTable'; 

export type TamanhoTableModelTipo = {
    cadastroList: Array<any>,
    onUpdate: (cadastro: any) => void | undefined,
    onDelete: (cadastro: any) => void | undefined,
};

class TamanhoTableModel extends React.Component<TamanhoTableModelTipo> {
    render() {
        const cadastroList = this.props.cadastroList.map((cadastro) =>
            <TamanhoTableRow
                key={cadastro._links.self.href}
                cadastro={cadastro}
                onUpdate={this.props.onUpdate}
                onDelete={this.props.onDelete}
            />
        );

        return <table className='pure-table' style={{width: '100%'}} >
            <thead>
                <tr>
                    <th className='Bold CenterAlign'>{getStrings().options}</th>
                    <th className='Bold ButtonAlign'>{getStrings().active}</th>
                    <th className='Bold TextAlign'>{getStrings().code}</th>
                    <th className='Bold TextAlign'>{getStrings().name}</th>
                </tr>
            </thead>
            <tbody>
                {cadastroList}
            </tbody>
        </table>;
    }
}

const mapStateToProps = (state: any) => ({
    ...state.idiomaReducer,
});

const mapDisptachToProps = null // (dispatch: dispatchTipo) => ({});

export default connect(mapStateToProps, mapDisptachToProps)(TamanhoTableModel);
