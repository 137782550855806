//@ts-check

/** Aparece primeiro os produtos com maiores descontos. */
/**
 * 
 * @param {Array<import("../../redux/produtos/produtos/produtosReducer").produtoTipo & {id: string}>} produtos 
 * @returns {Array<any>}
 */
const ordenarPorMaioresDescontosUtil = (produtos) => {
    return produtos
        .sort((a, b) =>
        (a.promocao || {precoPromo: 0}).precoPromo < (b.promocao || {precoPromo: 0}).precoPromo
            ? 1
            : -1
        );
};

export default ordenarPorMaioresDescontosUtil;
