//@ts-check
import React from 'react';
import { connect } from 'react-redux';
import { Botao } from '../../../nucleo/nucleo';
import { LocalidadeUtils } from '../../../nucleo/utils/utils';
import { fecharDialog } from '../../../nucleo/redux/dialog/dialogActions';

import "./InstalacaoDialog.css";

/**
 * 
 * @param {import('./types').instalacaoDialogPropsType} props 
 * @returns {JSX.Element}
 */
function InstalacaoDialog(props) {
    const { fecharDialog, instalarAction, onKeyDown, ...sanitizedProps } = props;

    return <div
        className={'ui-dialog-instalacao'}
        onKeyDown={
            /**
             * 
             * @param {any} event 
             */
            (event) => {
            event.stopPropagation();

            if (event.key === 'Enter') {
                const elemento = event.target.tagName === 'INPUT'
                    ? (document.querySelectorAll('.ui-submit')[0] || {})
                    : event.target;

                elemento.click && elemento.click();
            }

            onKeyDown && onKeyDown(event);
        }}
    >
        <div {...sanitizedProps} className={'dialog-content ' || (props.className || '')}>
            <div className={'dialog-content-mensagem'}>
                {LocalidadeUtils.obterTraducao().dialog.installApp}
            </div>
            <div className={'dialog-content-botoes'}>
                <Botao onClick={() => {
                    instalarAction();
                    fecharDialog();
                }}>{LocalidadeUtils.obterTraducao().words.yes}</Botao>
                <Botao onClick={() => fecharDialog()}>{LocalidadeUtils.obterTraducao().words.no}</Botao>
            </div>
        </div>
    </div>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} _ 
 * @returns 
 */
const mapStateToProps = (_) => ({});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
    dispatch,
    fecharDialog: () => dispatch(fecharDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstalacaoDialog);
