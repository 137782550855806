import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from '../../../utils/LocaleUtils';

import EmpresaTableRow from './EmpresaTableRow';

/**
 * Classe que realiza a montagem da tabela das Empresas.
 */
class EmpresaTableModel extends React.Component {

    /**
     * Método que executa a montagem do componente.
     */
    render() {
        // Monta as linhas que irão compor a tabela
        var cadastroList = this.props.cadastroList.map(cadastro =>
            <EmpresaTableRow
                minVersion={this.props.minVersion}
                key={cadastro._links.self.href}
                cadastro={cadastro}
                onUpdate={this.props.onUpdate}
                onDelete={this.props.onDelete} />
        );

        if (this.props.minVersion) {
            return <table className='pure-table' style={{minWidth: '800px', width: '100%'}} >

                {/* Cabeçalho */}
                <thead>
                    <th className='Bold TextAlign'>{getStrings().options}</th>
                    {/* <th className='Bold TextAlign'>{getStrings().active}</th> */}
                    {/* <th className='Bold TextAlign'>{getStrings().situation}</th> */}
                    <th className='Bold TextAlign'>{getStrings().tradingName}</th>
                    <th className='Bold TextAlign'>{getStrings().companyBusiness}</th>
                    <th className='Bold TextAlign'>{getStrings().address}</th>
                    {/* <th className='Bold ButtonAlign'>{getStrings().telephone}</th>
                    <th className='Bold TextAlign'>{getStrings().email}</th> */}
                    <th className='Bold TextAlign'>{getStrings().contact}</th>
                </thead>
                <tbody>
                    {cadastroList}
                </tbody>
            </table>
        }

        // Retona a tabela montada
        return (
            <table className='pure-table' style={{minWidth: '1620px', width: '100%'}} >

                {/* Cabeçalho */}
                <thead>
                    <tr>
                        <th className='Bold TextAlign'>{getStrings().options}</th>
                        {/* <th className='Bold TextAlign'>{getStrings().active}</th> */}
                        {/* <th className='Bold TextAlign'>{getStrings().situation}</th> */}
                        <th className='Bold TextAlign'>{getStrings().companyName}</th>
                        <th className='Bold TextAlign'>{getStrings().tradingName}</th>
                        <th className='Bold TextAlign'>{getStrings().companyBusiness}</th>
                        <th className='Bold TextAlign'>{getStrings().address}</th>
                        {/* <th className='Bold ButtonAlign'>{getStrings().telephone}</th>
                        <th className='Bold TextAlign'>{getStrings().email}</th> */}
                        <th className='Bold TextAlign'>{getStrings().contact}</th>
                        <th className='Bold TextAlign'>{getStrings().federalTaxNumber}</th>
                        <th className='Bold TextAlign'>{getStrings().stateRegistry}</th>
                    </tr>
                </thead>

                {/* Linhas de Dados */}
                <tbody>
                    {cadastroList}
                </tbody>
            </table>
        )
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    minVersion: state.cadastroEmpresaReducer.minVersion,
    ...state.idiomaReducer,
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(EmpresaTableModel);
