import React from "react"
import { connect } from "react-redux"

import { getEmpresaId } from "../../../utils/CompanyUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getProductImagePath } from "../../../utils/MiscUtils"
import { isAncientChromeMobile, isEdge, isIE } from "../../../utils/NavigatorUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import * as appActions from "../../../store/actions/appAction"
import * as manutencaoVendaActions from "../../../store/actions/controleVenda/manutencao/manutencaoVendaAction"

import {
  NIVEL_USUARIO_SEM_EMPRESA_VENDA
} from "../../../store/reducers/empresaSelectorReducer"

import DialogDescricao from "../dialog/DialogDescricao"
import DialogObservacao from "../dialog/DialogObservacao"
import Row from "../grid/Row"
import Column from "../grid/Column"
import Icones from "../../../assets/icones/Icones"
import DialogButton from "../dialog/DialogButton"
import SpinnerQuantidade from "./SpinnerQuantidade"

/**
 * Função que monta a celula a partir do Produto..
 */
class BuildCellFromProduto extends React.Component {
  componentDidUpdate(prevProps) {
    if (getURIFromEntity(prevProps.produto || {}) !== getURIFromEntity(this.props.produto || {})) {
      this.imageSrc = getProductImagePath(getEmpresaId(), this.props.produto);
    }
    else {
      this.imageSrc = "";
    }
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("BuildCellFromProduto render")

    const MdInfoOutline = Icones.md.MdInfoOutline;
    const FaRegComment = Icones.fa.FaRegComment;

    let menu = this.props.nivelUsuarioSemEmpresa !== NIVEL_USUARIO_SEM_EMPRESA_VENDA;

    return <div className={`accordion-item${isAncientChromeMobile ? " ancient-chrome-mobile" : ""}`}>
      <div className={`accordion-item-cell${menu ? " menu" : ""} build-cell-from-produto`}>
        <Row className="i-1">
          <Column className="left">
            <Row>
              <h2 className="nome-produto TextAlign"> {this.props.itemVenda.livre ? getStrings().allYouCanEatName(this.props.produto.nome) : this.props.produto.nome} </h2>
            </Row>
            {this.props.produto.imagemFile
              ? <Row>
                <img
                  alt={getStrings().productImageTemplate(this.props.produto.nome)}
                  src={`${getProductImagePath(getEmpresaId(), this.props.produto)}`}
                  onError={evt => {
                    evt.currentTarget.onerror = null;
                    evt.currentTarget.src = getStrings().noImageAvailableSrc;
                  }}
                />
              </Row>
              : null
            }
          </Column>
          <Column className="right">
            <h2 className={`preco-produto NumberAlign${isAncientChromeMobile ? " right" : ""}`}> {getStrings().currencyValue(this.props.itemVenda.livre ? this.props.itemVenda.valorTotal : this.props.produto.preco)} </h2>
          </Column>
        </Row>
      </div>
      <div className="row i-2">
        <div className={`column left${isEdge || isIE ? " isMSBrowser" : ""}`}>
        </div>
        <div className={`column right${isEdge || isIE ? " isMSBrowser" : ""}`}>
          <div className="column left" style={{ float: "none" }}>
            <DialogButton className="descricao-produto" tabIndex={this.props.tabIndex} title={getStrings().descriptionPlaceholder} onClick={() => {
              this.props.appDialogShow(<DialogDescricao observacao={this.props.produto.descricao} />, getStrings().description);
            }}>
              <MdInfoOutline tabIndex={-1} />
            </DialogButton>
            {menu ? null : <>
              <DialogButton className="observacao-produto" tabIndex={this.props.tabIndex} title={getStrings().itemObservation} onClick={() => {
                this.props.appDialogShow(<DialogObservacao
                  newSaleItem={this.props.newSaleItem}
                  editable={true}
                />, getStrings().observation)
              }}>
                <FaRegComment tabIndex={-1} />
              </DialogButton>
            </>}
          </div>
          <div className="column right">
            <SpinnerQuantidade {...this.props} className={isAncientChromeMobile ? "right" : ""} />
          </div>
        </div>
      </div>
    </div>;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  productImagesReducer: state.productImagesReducer,
  ...state.idiomaReducer,
  nivelUsuarioSemEmpresa: state.controleVendaReducer.nivelUsuarioSemEmpresa,
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  addNewSaleItemOrSetAmount: (operation, newSaleItem, amountType, amount, observation) =>
    dispatch(manutencaoVendaActions.addNewSaleItemOrSetAmount(operation, newSaleItem, amountType, amount, observation)),

  appDialogShow: (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles) =>
    dispatch(appActions.appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles)),

  orderUsingScales: uri => dispatch(manutencaoVendaActions.orderUsingScales(uri)),

  removeNewSaleItem: newSaleItem => dispatch(manutencaoVendaActions.removeNewSaleItem(newSaleItem)),
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(BuildCellFromProduto)
