import React from "react"
import { connect } from "react-redux"

import { equalsCoerced } from "../../../utils/ComparatorsUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { listToFragment } from "../../../utils/ScreenUtils"
import * as SelectUtils from "../../../utils/SelectUtils"
import { localeSort, naturalSort } from "../../../utils/SortUtils"
import { formatI18nString } from "../../../utils/StringUtils"
import { entityURIEqualsURI, getURIFromEntity } from "../../../utils/URIUtils"

import * as cadastroActions from "../../../store/actions/cadastroAction"
import { barCodeFormatList } from "../../../store/actions/controleVenda/controleVendaAction"
import { RamoEmpresa } from "../../../store/actions/empresaSelectorAction"
import {
  MODELO_IMPRESSAO_PEDIDO_01
  , MODELO_IMPRESSAO_PEDIDO_02
  , MODO_COBRANCA_TELE_ENTREGA_POR_BAIRRO
  , MODO_COBRANCA_TELE_ENTREGA_POR_DISTANCIA
  , TIPO_CADASTRO_USUARIO_COMPLETO
  , TIPO_CADASTRO_USUARIO_SIMPLES
} from "../../../store/reducers/empresaSelectorReducer"

import CadastroForm from "./CadastroForm"
import EnderecoForm from "./EnderecoForm"
import InputCustomizado from "../../UI/Input/InputCustomizado"
import HelpParagraph from "../../UI/HelpParagraph/HelpParagraph"
import { Fieldset } from "../../UI/Fields/Fieldset"

import "./EmpresaForm.css";

const PLATAFORMA_INTEGRACAO_DELIVERY_MUCH = "DELIVERY_MUCH"
const PLATAFORMA_INTEGRACAO_IFOOD = "IFOOD"

/**
 * Classe que repassa para o CadastroForm as informações para a montagem do formulário para o cadastro dos Empresas.
 */
class EmpresaForm extends React.Component {
  render() {
    log("EmpresaForm render");
    return <CadastroForm {...this.props} formModel={EmpresaValues}
      registerSaveHelp={getStrings().companySaveHelp} />;
  }
}

/**
 * Classe que realiza a montagem do formulário para o cadastro das Empresas.
 */
class EmpresaValues extends React.Component {
  state = {
    logoEmpresa: "",
    versaoLogo: (Math.random() * 100).toFixed(2),
    temValorLinkParametro: false,
  }

  /**
   * Retorna se os campos obrigatórios (required) foram todos preenchidos
   */
  checkRequired = newData => {
    log("EmpresaValues checkRequired", { newData });
    return (newData.razaoSocial !== "") && (newData.nomeFantasia !== "") 
      && (newData.cnpj !== "") && (newData.inscricaoEstadual !== "")
      && (newData.ramoEmpresa !== null) && (newData.contatoEmpresa.email !== "")
      && (newData.contatoEmpresa.telefone !== "") && (newData.contatoEmpresa.nomeContato !== "")
      && (newData.contratoEmpresa.tipoContrato !== null) && (newData.enderecoEmpresa.bairro !== "")
      && (newData.enderecoEmpresa.cep !== "") && (newData.enderecoEmpresa.endereco !== "")
      && (newData.enderecoEmpresa.municipio !== "") && (newData.enderecoEmpresa.numero !== "")
      && (newData.enderecoEmpresa.pais !== null) && (newData.enderecoEmpresa.uf !== "")
      && (newData.parametrosEmpresa.nivelUsuarioSemEmpresa !== null);
  }

  /**
   * Recupera os dados do formulário.
   */
  getFormData = () => {
    const _links = this.props.cadastroDados._links;

    // Somente lê os campos relativos a codigo de barras se a empresa usa isso
    let ordemFormatoCodigoBarras = {};

    barCodeFormatList.forEach(format => {
      ordemFormatoCodigoBarras[format.persistenceName] = this.usesBarCode()
        ? this.ordemFormatoCodigoBarras[format.persistenceName].getMaskValue()
        : 0;
    });

    const formData = {
      razaoSocial: this.razaoSocial.inputComponent.value
      , nomeFantasia: this.nomeFantasia.inputComponent.value
      , cnpj: this.cnpj.inputComponent.value
      , inscricaoEstadual: this.inscricaoEstadual.inputComponent.value
      , ramoEmpresa: SelectUtils.cadastroRecuperaValueFromOptions(this.ramoEmpresa.inputComponent.getValue())
      , ramoEmpresaOption: this.ramoEmpresa.inputComponent.getValue()
      , enderecoEmpresa: {
        pais: getURIFromEntity(this.props.cadastroEmpresaDados.enderecoEmpresa.pais)
        , paisOption: SelectUtils.cadastroBuildOptionsFromPaises(false, this.props.cadastroEmpresaDados.enderecoEmpresa.pais)
        , uf: this.props.cadastroEmpresaDados.enderecoEmpresa.uf
        , municipio: this.props.cadastroEmpresaDados.enderecoEmpresa.municipio
        , bairro: this.props.cadastroEmpresaDados.enderecoEmpresa.bairro
        , endereco: this.props.cadastroEmpresaDados.enderecoEmpresa.endereco
        , numero: this.props.cadastroEmpresaDados.enderecoEmpresa.numero
        , complemento: this.props.cadastroEmpresaDados.enderecoEmpresa.complemento
        , cep: this.props.cadastroEmpresaDados.enderecoEmpresa.cep
      }
      , contatoEmpresa: {
        email: this.emailContato.inputComponent.value
        , telefone: this.telefone.inputComponent.value
        , nomeContato: this.nomeContato.inputComponent.value
      }
      , contratoEmpresa: {
        tipoContrato: SelectUtils.cadastroRecuperaValueFromOptions(this.tipoContrato.inputComponent.getValue())
        , situacaoEmpresa: "Em dia"
        , tipoContratoOption: this.tipoContrato.inputComponent.getValue()
      }
      , parametrosEmpresa: {
        percentualComissao: this.percentualComissao.getMaskValue() || 0
        , ordemFormatoCodigoBarras
        , precoLivre: this.precoLivre ? this.precoLivre.getMaskValue() : null
        , valorTeleEntregaPorKm: this.valorTeleEntregaPorKm ? this.valorTeleEntregaPorKm.getMaskValue() : null
        , habilitarImpressao: this.habilitarImpressao.inputComponent.checked
        , nivelUsuarioSemEmpresa: SelectUtils.cadastroRecuperaValueFromOptions(this.nivelUsuarioSemEmpresa.inputComponent.getValue())
        , nivelUsuarioSemEmpresaOption: this.nivelUsuarioSemEmpresa.inputComponent.getValue()
        , obrigarPagarVendaUsuarioSemEmpresa: this.obrigarPagarVendaUsuarioSemEmpresa.inputComponent.checked
        , perguntaCpf: this.perguntaCpf.inputComponent.checked
        , filtroDeProdutos: this.filtroDeProdutos.inputComponent.getValue().value.value
        , modoCobrancaTeleEntrega: SelectUtils.cadastroRecuperaValueFromOptions(this.modoCobrancaTeleEntrega.inputComponent.getValue())
        , modeloImpressaoPedido: SelectUtils.cadastroRecuperaValueFromOptions(this.modeloImpressaoPedido.inputComponent.getValue())
        , tipoCadastroUsuario: SelectUtils.cadastroRecuperaValueFromOptions(this.tipoCadastroUsuario.inputComponent.getValue())
        , produtoPesadoPadrao: SelectUtils.cadastroRecuperaValueFromOptions(this.produtoPesadoPadrao.inputComponent.getValue())
        , produtoPesadoPadraoOption: this.produtoPesadoPadrao.inputComponent.getValue()
        , nomeDoLivre : this.nomeDoLivre.inputComponent.value
      }
      , dadosIntegracaoList: [{
        enabled: this.integracaoDeliveryMuch ? this.integracaoDeliveryMuch.inputComponent.checked : false
        , plataforma: PLATAFORMA_INTEGRACAO_DELIVERY_MUCH
        , nomeUsuario: this.usuarioDeliveryMuch ? this.usuarioDeliveryMuch.inputComponent.value : ''
        , senha: this.senhaDeliveryMuch ? this.senhaDeliveryMuch.inputComponent.value : ''
      }
        , {
        enabled: this.integracaoIfood ? this.integracaoIfood.inputComponent.checked : false
        , plataforma: PLATAFORMA_INTEGRACAO_IFOOD
        , nomeUsuario: this.usuarioIfood ? this.usuarioIfood.inputComponent.value : ''
        , senha: this.senhaIfood ? this.senhaIfood.inputComponent.value : ''
      }]
      , _links
    };

    log('EmpresaValues getFormData', { formData });
    return formData;
  }

  /**
   * Método executado ao clicar no botão "Gravar".
   * Recupera os dados do formulário e repassa para o método que irá persistir os dados.
   */
  handleGravar = () => {
    const formData = this.getFormData();

    log('EmpresaValues handleGravar');
    // Repassa para o método que irá persistir os dados
    // this.props.onSave(this.getFormData());
    this.props.cadastroSaveEmpresa(this.props.onSave, formData);
  }

  /**
   * Retorna se um endereço de empresa é igual à outra.
   */
  isContatoEmpresaEqual = (oldContatoEmpresa, newContatoEmpresa) => {
    let result = equalsCoerced(oldContatoEmpresa, newContatoEmpresa, (oldValue, newValue) =>
      (oldValue.email === newValue.email)
      && (oldValue.telefone === newValue.telefone)
      && (oldValue.nomeContato === newValue.nomeContato));
    log('EmpresaValues isContatoEmpresaEqual', { oldContatoEmpresa, newContatoEmpresa, result });
    return result;
  }

  /**
   * Retorna se um endereço de empresa é igual à outra.
   */
  isContratoEmpresaEqual = (oldContratoEmpresa, newContratoEmpresa) => {
    let result = equalsCoerced(oldContratoEmpresa, newContratoEmpresa, (oldValue, newValue) =>
      entityURIEqualsURI(oldValue.tipoContrato, newValue.tipoContrato)
      && (newValue.situacaoEmpresa === oldValue.situacaoEmpresa));
    log('EmpresaValues isContratoEmpresaEqual', { oldContratoEmpresa, newContratoEmpresa, result });
    return result
  }

  /**
   * Retorna se um endereço de empresa é igual à outra.
   */
  isEnderecoEmpresaEqual = (oldEnderecoEmpresa, newEnderecoEmpresa) => {
    let result = equalsCoerced(oldEnderecoEmpresa, newEnderecoEmpresa, (oldValue, newValue) =>
      entityURIEqualsURI(oldValue.pais, newValue.pais)
      && (newValue.uf === oldValue.uf)
      && (newValue.municipio === oldValue.municipio)
      && (newValue.bairro === oldValue.bairro)
      && (newValue.endereco === oldValue.endereco)
      && (newValue.numero === oldValue.numero)
      && (newValue.complemento === oldValue.complemento)
      && (newValue.cep === oldValue.cep));
    log('EmpresaValues isEnderecoEmpresaEqual', { oldEnderecoEmpresa, newEnderecoEmpresa, result });
    return result;
  }

  /**
   * Retorna se uma lista de ordens de leitura de formatos de código de barras é igual à outra.
   */
  isOrdemFormatoCodigoBarrasEqual = (oldOrdemFormatoCodigoBarras, newOrdemFormatoCodigoBarras) => {
    let result = equalsCoerced(oldOrdemFormatoCodigoBarras, newOrdemFormatoCodigoBarras, (oldValue, newValue) => barCodeFormatList.every(format => oldValue[format.persistenceName] === newValue[format.persistenceName]));
    log('EmpresaValues isOrdemFormatoCodigoBarrasEqual', { oldOrdemFormatoCodigoBarras, newOrdemFormatoCodigoBarras, result });
    return result;
  }

  /**
   * Retorna se um parâmetro de empresa é igual ao outro.
   */
  isParametrosEmpresaEqual = (oldParametrosEmpresa, newParametrosEmpresa) => {
    let result = equalsCoerced(oldParametrosEmpresa, newParametrosEmpresa, (oldValue, newValue) =>
      (oldValue.percentualComissao === newValue.percentualComissao)
      && this.isOrdemFormatoCodigoBarrasEqual(newValue.ordemFormatoCodigoBarras, oldValue.ordemFormatoCodigoBarras)
      && (oldValue.precoLivre === newValue.precoLivre)
      && (oldValue.valorTeleEntregaPorKm === newValue.valorTeleEntregaPorKm)
      && (oldValue.habilitarImpressao === newValue.habilitarImpressao)
      && entityURIEqualsURI(oldValue.nivelUsuarioSemEmpresa, newValue.nivelUsuarioSemEmpresa)
      && (oldValue.obrigarPagarVendaUsuarioSemEmpresa === newValue.obrigarPagarVendaUsuarioSemEmpresa)
      && (oldValue.perguntaCpf === newValue.perguntaCpf)
      && entityURIEqualsURI(oldValue.produtoPesadoPadrao, newValue.produtoPesadoPadrao)
      && entityURIEqualsURI(oldValue.modoCobrancaTeleEntrega, newValue.modoCobrancaTeleEntrega)
      && entityURIEqualsURI(oldValue.modeloImpressaoPedido, newValue.modeloImpressaoPedido)
      && entityURIEqualsURI(oldValue.tipoCadastroUsuario, newValue.tipoCadastroUsuario));
    log('EmpresaValues isParametrosEmpresaEqual', { oldParametrosEmpresa, newParametrosEmpresa, result });
    return result;
  }

  isDadosIntegracaoEqual = (oldDadosIntegracaoList, newDadosIntegracaoList) => {
    if (oldDadosIntegracaoList.length !== newDadosIntegracaoList.length) {
      return false;
    }
    for (let i = 0; i < oldDadosIntegracaoList.length; i++) {
      let result = equalsCoerced(oldDadosIntegracaoList[i], newDadosIntegracaoList[i], (oldValue, newValue) =>
        (oldValue.enabled === newValue.enabled)
        && (oldValue.nomeUsuario === newValue.nomeUsuario)
        && (oldValue.senha === newValue.senha));

      if (!result) {
        return false;
      }
    }
    return true;
  }

  /**
   * Retorna o sub formulário para cadastrar a ordem de leitura dos formatos de código de barras.
   */
  loadBarCodeFormatOrderForm = () => {
    // Só é usado por empresas do ramo de restaurante a kilo.
    if (!this.usesBarCode()) {
      return null;
    }
    this.ordemFormatoCodigoBarras = {};
    return <>

      <h3>{getStrings().barCodeFormatReadingOrder}</h3>
      <HelpParagraph children={
        getStrings().companyBarCodeHelp
      } />
      {listToFragment(barCodeFormatList.map(format =>

        <InputCustomizado ref={input => this.ordemFormatoCodigoBarras[format.persistenceName] = getReduxWrappedComponent(input)}
          placeholder={getStrings().order(0)} id={`ordemFormatoCodigoBarras.${format.persistenceName}`} inputType='masked' validacaoDados='numeroDecimal'
          type='text' name={`ordemFormatoCodigoBarras.${format.persistenceName}`} required={true} label={getStrings()[format.i18n()]} onBlur={this.props.handleChange}
          scale={0} max={99} padFractionalZeros={false} />

      ))}
    </>;
  }

  /**
   * Método que carrega os itens do campo O que usuários podem fazer ao acessar a empresa.
   */
  loadNiveisUsuarioSemEmpresa = () => {
    log('EmpresaValues loadNiveisUsuarioSemEmpresa');

    // Carrega o campo de O que usuários podem fazer ao acessar a empresa com a função de busca, conforme o campo for sendo preenchido
    this.props.cadastroLoadNiveisUsuarioSemEmpresa(
      cadastros => {
        if (!this.ismounted)
          return;

        const sanitizedCadastros = (cadastros || []).filter((cad) => !['NOME', 'MENU'].includes(cad))
        this.nivelUsuarioSemEmpresa.inputComponent.updateOptions(SelectUtils.cadastroBuildOptionsFromNivelUsuarioSemEmpresa(true, sanitizedCadastros));
      },
      () => {
        if (this.ismounted && (this.props.loading.niveisUsuarioSemEmpresa !== false)) {

          this.props.cadastroUpdateLoading({ niveisUsuarioSemEmpresa: false });
        }
      });
  }

  /**
   * Método que carrega os itens do campo Produto pesado padrão.
   */
  loadProdutosPesados = () => {
    log('EmpresaValues loadProdutosPesados');

    // Carrega o campo de Produto pesado padrão com a função de busca, conforme o campo for sendo preenchido
    this.props.cadastroLoadProdutosPesados(
      cadastros => {
        if (!this.ismounted)
          return;

        this.produtoPesadoPadrao.inputComponent.updateOptions(SelectUtils.cadastroBuildOptionsFromCadastros(true, cadastros));
      },
      () => {
        if (this.ismounted && (this.props.loading.produtosPesados !== false)) {

          this.props.cadastroUpdateLoading({ produtosPesados: false });
        }
      });
  }

  /**
   * Método que carrega os itens do campo Ramo da Empresa.
   */
  loadRamosEmpresa = () => {
    log('EmpresaValues loadRamosEmpresa');

    // Carrega o campo de Ramo da Empresa com a função de busca, conforme o campo for sendo preenchido
    this.props.cadastroLoadRamosEmpresa(
      cadastros => {
        if (!this.ismounted)
          return;

        this.ramoEmpresa.inputComponent.updateOptions(localeSort(SelectUtils.cadastroBuildOptionsFromRamoEmpresa(true, cadastros), 'label'));
      },
      () => {
        if (this.ismounted && (this.props.loading.ramosEmpresa !== false)) {

          this.props.cadastroUpdateLoading({ ramosEmpresa: false });
        }
      });
  }

  /**
   * Método que carrega os itens do campo Tipo de Contrato.
   */
  loadTiposContrato = () => {
    log('EmpresaValues loadTiposContrato');

    // Carrega o campo de Tipo de Contrato com a função de busca, conforme o campo for sendo preenchido
    this.props.cadastroLoadTiposContrato(
      cadastros => {
        if (!this.ismounted)
          return;

        this.tipoContrato.inputComponent.updateOptions(SelectUtils.cadastroBuildOptionsFromTipoContrato(true, cadastros));
      },
      () => {
        if (this.ismounted && (this.props.loading.tiposContrato !== false)) {

          this.props.cadastroUpdateLoading({ tiposContrato: false });
        }
      });
  }

  setSelectFiltroProdutos = (update = false) => {
    this.filtroDeProdutos.inputComponent.updateOptions([
      { labelKey: 'nome', label: getStrings().productsFilterSelectInput.name, value: { codigo: 'nome', value: 'nome' } },
      { labelKey: 'codigo', label: getStrings().productsFilterSelectInput.code, value: { codigo: 'codigo', value: 'codigo' } },
    ]);

    if (update) {
      this.filtroDeProdutos.inputComponent.updateValue({ value: { codigo: 'nome' } });
    }
  }

  setFiltroProdutos = (cadastroDados) => {
    const filtroDeProdutos = cadastroDados.parametrosEmpresa.filtroDeProdutos;

    this.filtroDeProdutos.inputComponent.updateValue({ value: { codigo: filtroDeProdutos } });
  }

  loadModoCobrancaTeleEntrega = () => {
    if (!this.modoCobrancaTeleEntrega) {
      return;
    }

    const modos = [
      { codigo: MODO_COBRANCA_TELE_ENTREGA_POR_BAIRRO },
      { codigo: MODO_COBRANCA_TELE_ENTREGA_POR_DISTANCIA }
    ];

    const options = SelectUtils.cadastroBuildOptionsFromModoCobrancaTeleEntrega(true, modos);
    this.modoCobrancaTeleEntrega.inputComponent.updateOptions(naturalSort(options, 'label'));

    if (this.props.cadastroDados?.parametrosEmpresa?.modoCobrancaTeleEntrega) {
      const value = SelectUtils.cadastroBuildOptionsFromModoCobrancaTeleEntrega(false, { codigo: this.props.cadastroDados.parametrosEmpresa.modoCobrancaTeleEntrega });
      this.modoCobrancaTeleEntrega.inputComponent.updateValue(value);
      this.forceUpdate();
    }
  }

  loadModeloImpressaoPedido = () => {
    if (!this.modeloImpressaoPedido) {
      return;
    }

    const modos = [
      { codigo: MODELO_IMPRESSAO_PEDIDO_01 },
      { codigo: MODELO_IMPRESSAO_PEDIDO_02 }
    ];

    const options = SelectUtils.cadastroBuildOptionsFromModeloImpressaoPedido(true, modos);
    this.modeloImpressaoPedido.inputComponent.updateOptions(naturalSort(options, 'label'));

    if (this.props.cadastroDados?.parametrosEmpresa?.modeloImpressaoPedido) {
      const value = SelectUtils.cadastroBuildOptionsFromModeloImpressaoPedido(false, { codigo: this.props.cadastroDados.parametrosEmpresa.modeloImpressaoPedido });
      this.modeloImpressaoPedido.inputComponent.updateValue(value);
    }
  }

  loadTipoCadastroUsuario = () => {
    if (!this.tipoCadastroUsuario) {
      return;
    }

    const modos = [
      { codigo: TIPO_CADASTRO_USUARIO_COMPLETO },
      { codigo: TIPO_CADASTRO_USUARIO_SIMPLES }
    ];

    const options = SelectUtils.cadastroBuildOptionsFromTipoCadastroUsuario(true, modos);
    this.tipoCadastroUsuario.inputComponent.updateOptions(naturalSort(options, 'label'));

    if (this.props.cadastroDados?.parametrosEmpresa?.tipoCadastroUsuario) {
      const value = SelectUtils.cadastroBuildOptionsFromTipoCadastroUsuario(false, { codigo: this.props.cadastroDados.parametrosEmpresa.tipoCadastroUsuario });
      this.tipoCadastroUsuario.inputComponent.updateValue(value);
    }
  }

  /**
   * Método que define o valor inicial do select.
   */
  setNivelUsuarioSemEmpresaVinculado = cadastro => {
    log('EmpresaValues setNivelUsuarioSemEmpresaVinculado', { cadastro });
    if (!cadastro.parametrosEmpresa) {
      return;
    }
    // Se uma opção havia sido selecionada, coloca essa opção no select. Senão, gera a opção a partir da entidade que estiver no cadastro.
    // A entidade só estará disponível para gerar uma opção quando um cadastro for editado, pois seus dados são lidos da retaguarda.
    // Quando o cadastro é enviado para a retaguarda, a entidade é substituída pelo seu URI, mas a partir daí já havia uma opção selecionada.
    this.nivelUsuarioSemEmpresa.inputComponent.updateValue(
      cadastro.parametrosEmpresa.nivelUsuarioSemEmpresaOption
        ? cadastro.parametrosEmpresa.nivelUsuarioSemEmpresaOption
        : cadastro.parametrosEmpresa.nivelUsuarioSemEmpresa ? SelectUtils.cadastroBuildOptionsFromNivelUsuarioSemEmpresa(false, cadastro.parametrosEmpresa.nivelUsuarioSemEmpresa) : null,
      'codigo');
  }

  /**
   * Método que define o valor inicial do select.
   */
  setProdutoPesadoPadrao = cadastro => {
    log('EmpresaValues setProdutoPesadoPadrao', { cadastro });
    if (!cadastro.parametrosEmpresa)
      return;

    // Se uma opção havia sido selecionada, coloca essa opção no select. Senão, gera a opção a partir da entidade que estiver no cadastro.
    // A entidade só estará disponível para gerar uma opção quando um cadastro for editado, pois seus dados são lidos da retaguarda.
    // Quando o cadastro é enviado para a retaguarda, a entidade é substituída pelo seu URI, mas a partir daí já havia uma opção selecionada.
    this.produtoPesadoPadrao.inputComponent.updateValue(
      cadastro.parametrosEmpresa.produtoPesadoPadraoOption
        ? cadastro.parametrosEmpresa.produtoPesadoPadraoOption
        : cadastro.parametrosEmpresa.produtoPesadoPadrao ? SelectUtils.cadastroBuildOptionsFromCadastros(false, cadastro.parametrosEmpresa.produtoPesadoPadrao) : null,
      'iso');
  }

  /**
   * 
   * @param {Object | undefined} novoValor 
   */
  atualizarExplicacaoRamoEmpresa = (novoValor) => {
    const ramoEmpresaValue = novoValor || { labelKey: '' };
    const explicacaoRamoEmpresa = getStrings().businessExplanation[ramoEmpresaValue.labelKey];

    if (this.explicacaoRamoEmpresa !== explicacaoRamoEmpresa) {
      this.explicacaoRamoEmpresa = explicacaoRamoEmpresa;

      this.forceUpdate();
    }
  }

  /**
   * Método que define o valor inicial do select.
   */
  setRamoEmpresaVinculado = cadastro => {
    log('EmpresaValues setRamoEmpresaVinculado', { cadastro });
    if (!cadastro) {
      return;
    }

    const newValue = cadastro.ramoEmpresaOption
      ? cadastro.ramoEmpresaOption
      : cadastro.ramoEmpresa ? SelectUtils.cadastroBuildOptionsFromRamoEmpresa(false, cadastro.ramoEmpresa) : null;
    // Se uma opção havia sido selecionada, coloca essa opção no select. Senão, gera a opção a partir da entidade que estiver no cadastro.
    // A entidade só estará disponível para gerar uma opção quando um cadastro for editado, pois seus dados são lidos da retaguarda.
    // Quando o cadastro é enviado para a retaguarda, a entidade é substituída pelo seu URI, mas a partir daí já havia uma opção selecionada.
    this.ramoEmpresa.inputComponent.updateValue(newValue);

    this.atualizarExplicacaoRamoEmpresa(newValue);
  }

  /**
   * Método que define o valor inicial do select.
   */
  setTipoContratoVinculado = cadastro => {
    log('EmpresaValues setTipoContratoVinculado', { cadastro });
    if (!cadastro.contratoEmpresa) {
      return;
    }
    // Se uma opção havia sido selecionada, coloca essa opção no select. Senão, gera a opção a partir da entidade que estiver no cadastro.
    // A entidade só estará disponível para gerar uma opção quando um cadastro for editado, pois seus dados são lidos da retaguarda.
    // Quando o cadastro é enviado para a retaguarda, a entidade é substituída pelo seu URI, mas a partir daí já havia uma opção selecionada.
    this.tipoContrato.inputComponent.updateValue(
      cadastro.contratoEmpresa.tipoContratoOption
        ? cadastro.contratoEmpresa.tipoContratoOption
        : cadastro.contratoEmpresa.tipoContrato ? SelectUtils.cadastroBuildOptionsFromTipoContrato(false, cadastro.contratoEmpresa.tipoContrato) : null,
      'nome');
  }

  /**
   * Retorna se a empresa usa leitura de código de barras.
   */
  usesBarCode = () => {
    return false;
    //return this.props.cadastroDados && this.props.cadastroDados.ramoEmpresa && ([RamoEmpresa.COLETOR, RamoEmpresa.RESTAURANTE_A_KILO].indexOf(this.props.cadastroDados.ramoEmpresa) > -1);
  }

  /**
   * Retorna se houve alteração em algum dado do cadastro
   */
  wasChanged = (newData, oldData) => {
    let result = !equalsCoerced(oldData, newData, (oldValue, newValue) =>
      (newValue.razaoSocial === oldValue.razaoSocial)
      && (newValue.nomeFantasia === oldValue.nomeFantasia)
      && (newValue.cnpj === oldValue.cnpj)
      && (newValue.inscricaoEstadual === oldValue.inscricaoEstadual)
      && (newValue.ramoEmpresa === oldValue.ramoEmpresa)
      && this.isContatoEmpresaEqual(oldValue.contatoEmpresa, newValue.contatoEmpresa)
      && this.isContratoEmpresaEqual(oldValue.contratoEmpresa, newValue.contratoEmpresa)
      && this.isEnderecoEmpresaEqual(oldValue.enderecoEmpresa, newValue.enderecoEmpresa)
      && this.isParametrosEmpresaEqual(oldValue.parametrosEmpresa, newValue.parametrosEmpresa)
      && this.isDadosIntegracaoEqual(oldValue.dadosIntegracaoList, newValue.dadosIntegracaoList));
    log('EmpresaValues wasChanged', { newData, oldData, result });
    return result;
  }

  /**
   * Retorna se houve alteração em algum dado do cadastro, comparando com os dados sendo editados.
   */
  wasChangedFromCurrent = newData => {
    log('EmpresaValues wasChangedFromCurrent', { newData });
    return this.wasChanged(newData, this.props.cadastroDados);
  }

  /**
   * Retorna se houve alteração em algum dado do cadastro, comparando com os dados presentes ao abrir a criação/edição do cadastro.
   */
  wasChangedFromInitial = newData => {
    log('EmpresaValues wasChangedFromInitial', { newData });
    return this.wasChanged(newData, this.props.cadastroDadosIniciais);
  }

  componentDidMount() {
    log('EmpresaValues componentDidMount');
    this.ismounted = true;

    this.loadNiveisUsuarioSemEmpresa();
    this.loadProdutosPesados();
    this.loadRamosEmpresa();
    this.loadTiposContrato();
    this.loadModoCobrancaTeleEntrega();
    this.loadModeloImpressaoPedido();
    this.loadTipoCadastroUsuario();
    this.setSelectFiltroProdutos(true);

    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps) {
    log('EmpresaValues componentDidUpdate');

    const params = new URLSearchParams(window.location.search);
    const tipoOrigem = params.get("tipoOrigem");

    if (tipoOrigem) {
      const element = document.getElementById("editor-mobi-app");

      if (element) {
        element.focus();
      }
    }

    if (prevProps && prevProps.locale === this.props.locale) {
      const newValue = this.filtroDeProdutos.inputComponent.getValue();
      this.setSelectFiltroProdutos();
      this.filtroDeProdutos.inputComponent.updateValue(newValue);
    }

    if (this.props.cadastroDados) {
      const cadastro = this.props.cadastroDados;

      this.setFiltroProdutos(this.props.cadastroDados);

      // Empresa
      this.razaoSocial.inputComponent.value = cadastro.razaoSocial ? cadastro.razaoSocial : null;
      this.nomeFantasia.inputComponent.value = cadastro.nomeFantasia ? cadastro.nomeFantasia : null;
      this.cnpj.inputComponent.value = cadastro.cnpj ? cadastro.cnpj : null;
      this.inscricaoEstadual.inputComponent.value = cadastro.inscricaoEstadual ? cadastro.inscricaoEstadual : null;
      this.razaoSocial.inputComponent.value = cadastro.razaoSocial ? cadastro.razaoSocial : null;

      // Contato Empresa
      const contatoEmpresa = cadastro.contatoEmpresa;
      if (contatoEmpresa) {
        this.emailContato.inputComponent.value = contatoEmpresa.email ? contatoEmpresa.email : null;
        this.telefone.inputComponent.value = contatoEmpresa.telefone ? contatoEmpresa.telefone : null;
        this.nomeContato.inputComponent.value = contatoEmpresa.nomeContato ? contatoEmpresa.nomeContato : null;
      }

      let parametrosEmpresa = cadastro.parametrosEmpresa;
      let ordemFormatoCodigoBarras = parametrosEmpresa.ordemFormatoCodigoBarras;

      if (this.percentualComissao && this.percentualComissao.getMaskValue() === null) {
        this.percentualComissao.setMaskValue(parametrosEmpresa.percentualComissao !== 0 ? parametrosEmpresa.percentualComissao : null);
      }

      if (this.precoLivre && this.precoLivre.getMaskValue() === null) {
        this.precoLivre.setMaskValue(parametrosEmpresa.precoLivre);
      }

      if (this.valorTeleEntregaPorKm && this.valorTeleEntregaPorKm.getMaskValue() === null) {
        this.valorTeleEntregaPorKm.setMaskValue(parametrosEmpresa.valorTeleEntregaPorKm);
      }

      if (this.habilitarImpressao) {
        this.habilitarImpressao.inputComponent.checked = parametrosEmpresa.habilitarImpressao;
      }

      if (this.obrigarPagarVendaUsuarioSemEmpresa) {
        this.obrigarPagarVendaUsuarioSemEmpresa.inputComponent.checked = parametrosEmpresa.obrigarPagarVendaUsuarioSemEmpresa;
      }

      if (this.perguntaCpf) {
        this.perguntaCpf.inputComponent.checked = parametrosEmpresa.perguntaCpf;
      }

      // Somente atualiza os campos relativos a codigo de barras se a empresa usa isso
      if (this.usesBarCode()) {
        barCodeFormatList.forEach(format => {
          this.ordemFormatoCodigoBarras[format.persistenceName].setMaskValue((ordemFormatoCodigoBarras[format.persistenceName] ||
            (ordemFormatoCodigoBarras[format.persistenceName] === 0)) ? ordemFormatoCodigoBarras[format.persistenceName] : null);
        });
      }

      this.setNivelUsuarioSemEmpresaVinculado(this.props.cadastroDados);

      this.setProdutoPesadoPadrao(this.props.cadastroDados);
      
      if (this.nomeDoLivre) {
        this.nomeDoLivre.inputComponent.value = parametrosEmpresa.nomeDoLivre;
      }

      this.setRamoEmpresaVinculado(this.props.cadastroDados);

      this.setTipoContratoVinculado(this.props.cadastroDados);

      const dadosIntegracaoList = cadastro.dadosIntegracaoList;
      if (dadosIntegracaoList) {
        dadosIntegracaoList.forEach(dadosIntegracao => {
          if (dadosIntegracao.plataforma === PLATAFORMA_INTEGRACAO_DELIVERY_MUCH) {
            if (this.integracaoDeliveryMuch) {
              this.integracaoDeliveryMuch.inputComponent.checked = dadosIntegracao.enabled;
            }
            if (this.usuarioDeliveryMuch) {
              this.usuarioDeliveryMuch.inputComponent.value = dadosIntegracao.nomeUsuario;
            }
            if (this.senhaDeliveryMuch) {
              this.senhaDeliveryMuch.inputComponent.value = dadosIntegracao.senha;
            }
          }
          else if (dadosIntegracao.plataforma === PLATAFORMA_INTEGRACAO_IFOOD) {
            if (this.integracaoIfood) {
              this.integracaoIfood.inputComponent.checked = dadosIntegracao.enabled;
            }
            if (this.usuarioIfood) {
              this.usuarioIfood.inputComponent.value = dadosIntegracao.nomeUsuario;
            }
            if (this.senhaIfood) {
              this.senhaIfood.inputComponent.value = dadosIntegracao.senha;
            }
          }
        });
      }
    }
  }

  render() {
    log('EmpresaValues render');
    
    const modoCobrancaTeleEntregaValue = (this.modoCobrancaTeleEntrega?.inputComponent || ({getValue: () => null})).getValue();
    const valorTeleEntregaPorKmStyle = modoCobrancaTeleEntregaValue?.value?.codigo === MODO_COBRANCA_TELE_ENTREGA_POR_DISTANCIA
      ? {
        style: undefined,
        labelStyle: undefined,
      }
      : {
        style: {display: "none"},
        labelStyle: {display: "none"},
      };

    return <div className='empresa-form sub-form'>
      {/* Tipo de Contrato */}
      <div className='sub-form'>
        <h2>{getStrings().contract}</h2>

        <InputCustomizado id='tipoContrato' inputType='singleSelect' name='tipoContrato' ref={input => this.tipoContrato = getReduxWrappedComponent(input)} required={true} placeholder={getStrings().contractTypePlaceholder} label={getStrings().contractTypeRequired} onChange={() => setTimeout(() => this.props.handleChange(), 0)} sort />
      </div>

      {/* Empresa */}
      <div className='sub-form'>
        <h2>{getStrings().company}</h2>

        <InputCustomizado defaultValue={this.razaoSocial} ref={input => this.razaoSocial = getReduxWrappedComponent(input)} placeholder={getStrings().companyNamePlaceholder} maxLength='60' id='razaoSocial' type='text' name='razaoSocial' required={true} label={getStrings().companyNameRequired} handleInputValidado={this.props.handleChange} />

        <InputCustomizado defaultValue={this.nomeFantasia} ref={input => this.nomeFantasia = getReduxWrappedComponent(input)} placeholder={getStrings().tradingNamePlaceholder} maxLength='60' id='nomeFantasia' type='text' name='nomeFantasia' required={true} label={getStrings().tradingNameRequired} handleInputValidado={this.props.handleChange} />

        <InputCustomizado defaultValue={this.cnpj} ref={input => this.cnpj = getReduxWrappedComponent(input)} placeholder={getStrings().federalTaxNumberPlaceholder} maxLength='20' id='cnpj' type='text' name='cnpj' required={true} label={getStrings().federalTaxNumberRequired} handleInputValidado={this.props.handleChange} />

        <InputCustomizado defaultValue={this.inscricaoEstadual} ref={input => this.inscricaoEstadual = getReduxWrappedComponent(input)} placeholder={getStrings().stateRegistryPlaceholder} maxLength='20' id='inscricaoEstadual' type='text' name='inscricaoEstadual' required={true} label={getStrings().stateRegistryRequired} handleInputValidado={this.props.handleChange} />

        <InputCustomizado ref={input => this.ramoEmpresa = getReduxWrappedComponent(input)} placeholder={getStrings().companyBusinessPlaceholder} id='ramo' inputType='singleSelect' name='ramo' required={true} label={getStrings().companyBusinessRequired} onChange={() => setTimeout(() => this.props.handleChange(), 0)} sort />
        <label>{this.explicacaoRamoEmpresa}</label>
      </div>

      <EnderecoForm
        key="empresa_form"
        noAutoFocus
        companyForm={false}
        entity="companyform"
        zipCodeCallBack={(zipCode, state, city, neighborhood, street) => this.props.updateFromZipCode(zipCode, state, city, neighborhood, street)}
      />

      {/* Contato Empresa */}
      <div className='sub-form'>
        <h2>{getStrings().contact}</h2>

        <InputCustomizado defaultValue={this.emailContato} ref={input => this.emailContato = getReduxWrappedComponent(input)} placeholder={getStrings().emailPlaceholderTemplate(getStrings().companySubPlaceholder)} maxLength='60' id='emailContato' type='email' name='emailContato' required={true} label={getStrings().emailRequired} handleInputValidado={this.props.handleChange} />

        <InputCustomizado defaultValue={this.telefone} ref={input => this.telefone = getReduxWrappedComponent(input)} placeholder={getStrings().telephonePlaceholderTemplate(getStrings().companySubPlaceholder)} maxLength='30' id='telefone' type='text' name='telefone' required={true} label={getStrings().telephoneRequired} handleInputValidado={this.props.handleChange} />

        <InputCustomizado defaultValue={this.nomeContato} ref={input => this.nomeContato = getReduxWrappedComponent(input)} placeholder={getStrings().contactNamePlaceholderTemplate(getStrings().companySubPlaceholder)} maxLength='60' id='nomeContato' type='text' name='nomeContato' required={true} label={getStrings().contactNameRequired} handleInputValidado={this.props.handleChange} />
      </div>

      {/* Integração com serviços de telentrega */}
      <div className='sub-form'>
        <h2>{getStrings().deliveryIntegration}</h2>
        
        <HelpParagraph children={
          getStrings().deliveryIntegrationHelp.map(string => formatI18nString(string))
        } />

        <InputCustomizado
          tabIndex={this.props.tabIndex}
          ref={input => this.integracaoDeliveryMuch = getReduxWrappedComponent(input)}
          id='integracaoDeliveryMuch'
          type='checkbox'
          name='integracaoDeliveryMuch'
          label={'Delivery Much'}
          handleInputValidado={this.props.handleChange}
        />
        {this.integracaoDeliveryMuch?.inputComponent.checked
          ? <>
            <InputCustomizado
              defaultValue={this.usuarioDeliveryMuch}
              ref={input => this.usuarioDeliveryMuch = getReduxWrappedComponent(input)}
              placeholder={'Usuário'}
              maxLength='30'
              id='usuarioDeliveryMuch'
              type='text'
              name='usuarioDeliveryMuch'
              required={true}
              label={'Usuário'}
              handleInputValidado={this.props.handleChange}
            />
            <InputCustomizado
              defaultValue={this.senhaDeliveryMuch}
              ref={input => this.senhaDeliveryMuch = getReduxWrappedComponent(input)}
              placeholder={'Senha'}
              maxLength='30'
              id='senhaDeliveryMuch'
              type='text'
              name='senhaDeliveryMuch'
              required={true}
              label={'Senha'}
              handleInputValidado={this.props.handleChange}
            />
          </>
          : null
        }
        <InputCustomizado
          style={{ marginTop: '1.5em' }}
          tabIndex={this.props.tabIndex}
          ref={input => this.integracaoIfood = getReduxWrappedComponent(input)}
          id='integracaoIfood'
          type='checkbox'
          name='integracaoIfood'
          label={'Ifood'}
          handleInputValidado={this.props.handleChange}
        />
        {this.integracaoIfood?.inputComponent.checked
          ? <>
            <InputCustomizado
              defaultValue={this.usuarioIfood}
              ref={input => this.usuarioIfood = getReduxWrappedComponent(input)}
              placeholder={'Usuário'}
              maxLength='30'
              id='usuarioIfood'
              type='text'
              name='usuarioIfood'
              required={true}
              label={'Usuário'}
              handleInputValidado={this.props.handleChange}
            />
            <InputCustomizado
              defaultValue={this.senhaIfood}
              ref={input => this.senhaIfood = getReduxWrappedComponent(input)}
              placeholder={'Senha'}
              maxLength='30'
              id='senhaIfood'
              type='text'
              name='senhaIfood'
              required={true}
              label={'Senha'}
              handleInputValidado={this.props.handleChange}
            />
          </>
          : null
        }
      </div>

      {/* Parâmetros Empresa companyBarCodeHelp*/}
      <div className='sub-form'>
        <h2>{getStrings().parameters}</h2>

        <Fieldset legend={getStrings().weight}>
          {(this.props.cadastroDados && (this.props.cadastroDados.ramoEmpresa === RamoEmpresa.RESTAURANTE_A_KILO))
            ?
            <>
              <HelpParagraph children={
                [getStrings().companyParameterAYCE].map(string => formatI18nString(string))
              } />

              <InputCustomizado ref={input => this.precoLivre = getReduxWrappedComponent(input)} placeholder={getStrings().allYouCanEatPlaceholder} id='precoLivre' inputType='masked' validacaoDados='numeroDecimal' max={100000000} scale={2} type='text' name='precoLivre' label={getStrings().allYouCanEatPrice} handleInputValidado={this.props.handleChange} />
            </>
            : null}

          <HelpParagraph children={
            [getStrings().companyParameterDefaultWeightedProduct]
          } />

          <InputCustomizado id='produtoPesadoPadrao' inputType='singleSelect' name='produtoPesadoPadrao' topClassName='produtoPesadoPadraoSelect' ref={input => this.produtoPesadoPadrao = getReduxWrappedComponent(input)} placeholder={getStrings().weightedProductPlaceholder} label={getStrings().parameterDefaultWeightedProduct} onChange={() => setTimeout(() => this.props.handleChange(), 0)} sort />
          
          <InputCustomizado id="nomeDoLivre" type="text" name="nomeDoLivre" ref={input => this.nomeDoLivre = getReduxWrappedComponent(input)} placeholder={getStrings().productsNameWhenSetAllYouCanEatValuePlaceholder} label={getStrings().productsNameWhenSetAllYouCanEatValue} />
        </Fieldset>

        <Fieldset legend={getStrings().sale()} >
          <HelpParagraph children={
            [getStrings().companyParameterCommissionHelp].map(string => formatI18nString(string))
          } />

          <InputCustomizado ref={input => this.percentualComissao = getReduxWrappedComponent(input)} placeholder={getStrings().commissionPercentPlaceholder()} id='percentualComissao' inputType='masked' validacaoDados='numeroDecimal' scale={5} max={100} type='text' name='percentualComissao' label={getStrings().commissionPercent} handleInputValidado={this.props.handleChange} />
        </Fieldset>

        <Fieldset legend={getStrings().client} >
          <HelpParagraph children={
            getStrings().companyParameterNoCompanyUserLevelHelp.map(string => formatI18nString(string))
          } />

          <InputCustomizado id='nivelUsuarioSemEmpresa' inputType='singleSelect' name='nivelUsuarioSemEmpresa' topClassName='nivelUsuarioSemEmpresaSelect' ref={input => this.nivelUsuarioSemEmpresa = getReduxWrappedComponent(input)} required={true} placeholder={getStrings().noCompanyUserLevelPlaceholder} label={getStrings().parameterNoCompanyUserLevel} onChange={() => setTimeout(() => this.props.handleChange(), 0)} sort />
          
          <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.tipoCadastroUsuario = getReduxWrappedComponent(input)} required={true} id='tipoCadastroUsuario' inputType='singleSelect' name='tipoCadastroUsuario' label={getStrings().userRegistrationType} handleInputValidado={this.handleChange} />
          
          <HelpParagraph children={
            getStrings().companyParameterDemandPaySaleNoCompany.map(string => formatI18nString(string))
          } />

          <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.obrigarPagarVendaUsuarioSemEmpresa = getReduxWrappedComponent(input)} id='obrigarPagarVendaUsuarioSemEmpresa' type='checkbox' name='obrigarPagarVendaUsuarioSemEmpresa' label={getStrings().parameterDemandPaySaleNoCompany} handleInputValidado={this.props.handleChange} />
          
          <HelpParagraph children={
            [getStrings().companyParameterAsksIndividualTaxpayerID]
          } />

          <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.perguntaCpf = getReduxWrappedComponent(input)} id='perguntaCpf' type='checkbox' name='perguntaCpf' label={getStrings().parameterAsksIndividualTaxpayerID} handleInputValidado={this.props.handleChange} />
        </Fieldset>

        <Fieldset legend={getStrings().product} >
          <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.filtroDeProdutos = getReduxWrappedComponent(input)} id='filtroDeProdutos' inputType='singleSelect' name='filtroDeProdutos' label={getStrings().productsFilter} handleInputValidado={this.setFiltroProdutos} />
        </Fieldset>

        <Fieldset legend={getStrings().deliveryLabel} >
          <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.modoCobrancaTeleEntrega = getReduxWrappedComponent(input)} id='modoCobrancaTeleEntrega' inputType='singleSelect' name='modoCobrancaTeleEntrega' label={getStrings().deliveryChargeMode} onChange={() => { setTimeout(() => { this.forceUpdate(); this.props.handleChange && this.props.handleChange(); }, 0) }} />

          <InputCustomizado {...valorTeleEntregaPorKmStyle} clearable id='valorTeleEntregaPorKm' name='valorTeleEntregaPorKm' type='text' inputType='masked' validacaoDados='numeroDecimal' max={100000000} scale={2} label={getStrings().deliveryValueByKm} placeholder={getStrings().deliveryValueByKmPlaceholder} handleInputValidado={this.props.handleChange} ref={input => this.valorTeleEntregaPorKm = getReduxWrappedComponent(input)} />
        </Fieldset>

        <Fieldset legend={getStrings().printer} >
          <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.modeloImpressaoPedido = getReduxWrappedComponent(input)} id='modeloImpressaoPedido' inputType='singleSelect' name='modeloImpressaoPedido' label={getStrings().orderPrintTemplate} handleInputValidado={this.handleChange} />

          <HelpParagraph children={
            [getStrings().companyParameterEnablePrinting].map(string => formatI18nString(string))
          } />

          <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.habilitarImpressao = getReduxWrappedComponent(input)} id='habilitarImpressao' type='checkbox' name='habilitarImpressao' label={getStrings().parameterEnablePrinting} handleInputValidado={this.props.handleChange} />
  
        </Fieldset>

        {this.loadBarCodeFormatOrderForm()}
      </div>

    </div>;
  }
}

/**
 * Mapeia as propriedades do estado global para utilizar localmente. 
 * @param {*} state 
 */
function mapStateToProps(state) {
  const props = {
    tabIndex: state.appReducer.getTabIndex(),
    ...state.idiomaReducer,
    ...state.cadastroEmpresaReducer,
    operation: state.cadastroReducer.operation,
    cadastroDadosIniciais: state.cadastroReducer.cadastroDadosIniciais,
  }

  return props
}

/**
 * Mapeia as ações para utilizar localmente. 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  cadastroLoadNiveisUsuarioSemEmpresa: (onSuccess, onComplete) => dispatch(cadastroActions.cadastroLoadNiveisUsuarioSemEmpresa(onSuccess, onComplete)),
  cadastroLoadPaises: (onSuccess, onComplete) => dispatch(cadastroActions.cadastroLoadPaises(onSuccess, onComplete)),
  cadastroLoadProdutosPesados: (onSuccess, onComplete) => dispatch(cadastroActions.cadastroLoadProdutosPesados(onSuccess, onComplete)),
  cadastroLoadRamosEmpresa: (onSuccess, onComplete) => dispatch(cadastroActions.cadastroLoadRamosEmpresa(onSuccess, onComplete)),
  cadastroLoadTiposContrato: (onSuccess, onComplete) => dispatch(cadastroActions.cadastroLoadTiposContrato(onSuccess, onComplete)),
  cadastroUpdateLoading: steps => dispatch(cadastroActions.cadastroUpdateLoading(steps)),
  cadastroSaveEmpresa: (onSave, formData, params) => dispatch(cadastroActions.cadastroSaveEmpresa(onSave, formData, params)),
  dispatch,
  updateFromZipCode: (zipCode, state, city, neighborhood, street) => dispatch(cadastroActions.updateFromZipCode(zipCode, state, city, neighborhood, street))
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EmpresaForm);
