import actionTypes from "../actions/comboAction";
import genericReducer from "./genericReducer";

const initialState = {
    lista: {},
    produtos: {},
    produtosCandidatos: {},
}

const namedActions = {
    [actionTypes.ADD_TO_LIST_COMBO]: ({nome, codigo, precoVenda, produtos}, state) => {
        const combo = {
            nome,
            precoVenda,
            produtos,
        };

        const newState = {
            ...state,
            lista: {
                ...state.lista,
                [codigo]: combo,
            }
        }

        return newState;
    },

    [actionTypes.UPDATE_COMBO]: ({nome, codigo, preco, quantidade, grupoProduto}, state) => {
        const produtos = {
            ...state.produtos,
            [codigo]: {nome, preco, quantidade: quantidade > 0 ? quantidade : 1, grupoProduto},
        };

        const newState = {
            ...state,
            produtos,
        };

        return newState;
    },

    [actionTypes.DIMINUIR_QUANTIDADE_COMBO]: ({id}, state) => {
        const produto = state.produtos[id];
        const quantidade = (produto.quantidade - 1) > 0 ? (produto.quantidade - 1) : 1;

        if (!produto) {
            return state;
        }

        const produtos = {
            ...state.produtos,
            [id]: {
                ...produto,
                quantidade,
            }
        }

        const newState = {
            ...state,
            produtos,
        }

        return newState;
    },

    [actionTypes.AUMENTAR_QUANTIDADE_COMBO]: ({id}, state) => {
        const produto = state.produtos[id];

        if (!produto) {
            return state;
        }

        const quantidade = produto.quantidade + 1;

        const produtos = {
            ...state.produtos,
            [id]: {
                ...produto,
                quantidade,
            }
        }

        const newState = {
            ...state,
            produtos,
        }

        return newState;
    },

    [actionTypes.DIMINUIR_QUANTIDADE_CANDIDATO_COMBO]: ({id}, state) => {
        const produto = state.produtosCandidatos[id];

        if (!produto) {
            return state;
        }

        const quantidade = (produto.quantidade - 1) >= 0 ? (produto.quantidade - 1) : 0;

        const produtosCandidatos = {
            ...state.produtosCandidatos,
            [id]: {
                ...produto,
                quantidade,
            }
        }

        const newState = {
            ...state,
            produtosCandidatos,
        }

        return newState;
    },

    [actionTypes.AUMENTAR_QUANTIDADE_CANDIDATO_COMBO]: ({id}, state) => {
        const produto = state.produtosCandidatos[id];

        if (!produto) {
            return state;
        }

        const quantidade = produto.quantidade + 1;

        const produtosCandidatos = {
            ...state.produtosCandidatos,
            [id]: {
                ...produto,
                quantidade,
            }
        }

        const newState = {
            ...state,
            produtosCandidatos,
        }

        return newState;
    },

    [actionTypes.UPDATE_CANDIDATO_COMBO]: ({nome, codigo, preco, quantidade, grupoProduto}, state) => {
        const produtosCandidatos = {
            ...state.produtosCandidatos,
            [codigo]: {nome, preco, quantidade: quantidade >= 0 ? quantidade : 0, grupoProduto},
        };

        const newState = {
            ...state,
            produtosCandidatos,
        };

        return newState;
    },

    [actionTypes.ELECT_ALL_CANDIDATES_COMBO]: (_, state) => {
        const newState = {...state};

        for(const codigo of Object.keys(state.produtosCandidatos)) {
            const produto = state.produtosCandidatos[codigo];

            if (produto.quantidade > 0) {
                newState.produtos[codigo] = produto;
                delete newState.produtosCandidatos[codigo];
            }
        }

        return newState;
    },

    [actionTypes.ELECT_CANDIDATE_COMBO]: ({id}, state) => {
        const produto = state.produtosCandidatos[id];

        if (!produto) {
            return state;
        }

        const newState = {...state};
        
        if (produto.quantidade > 0) {
            newState.produtos[id] = produto;
            delete newState.produtosCandidatos[id];
        }

        return newState;
    },

    [actionTypes.REMOVE_COMBO]: ({id}, state) => {
        const produtoCandidato = state.produtos[id];

        if (!produtoCandidato) {
            return state;
        }

        const newState = {...state};
        
        newState.produtosCandidatos[id] = {...produtoCandidato, quantidade: 0};
        delete newState.produtos[id];

        return newState;
    },
}

const comboReducer = (state = initialState, action) => genericReducer(state, action, namedActions);

export default comboReducer;
