import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { updateActiveMenuItem } from "../../../utils/ScreenUtils"

import * as appActions from "../../../store/actions/appAction"
import * as authActions from "../../../store/actions/authAction"

import HelpParagraph from "../../UI/HelpParagraph/HelpParagraph"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"
import InputCustomizado from "../../UI/Input/InputCustomizado"
import Icones from "../../../assets/icones/Icones"
import { ClienteHttp } from "../../../apps/promo_app_frontend/nucleo/utils/utils"

const DownloadButton = (props) => {
  return <>
    <a tabIndex={-1} href={`${props.link || props.children || ''}`} download>
      <button
        className={`gray-button font-color${props.className ? ` ${props.className}` : ''}`}
        title={getStrings()[`hubDownload${props.windows ? `Windows` : `Another`}`]}
      >
        {getStrings()[`${props.windows ? `windows` : `anotherPlatform`}`]}
      </button>
    </a>
  </>;
};

const TokenButton = (props) => {
  return <>
    <button
      className={`gray-button font-color${props.className ? ` ${props.className}` : ''}`}
      id='generateTokenButton'
      title={props.title}
      onClick={props.onClick}
    >
      {props.icon}
    </button>
  </>;
};

export const Integration = (props) => {
  let tokenInput;
  const [firstRun, updateFirstRun] = React.useState(true);
  const [versaoHub, updateVersaoHub] = React.useState("0.0.0");

  const copyTokenInput = () => {
    log('Integration copyTokenInput');
    if (tokenInput.inputComponent.value.length > 0) {
      tokenInput.inputComponent.select();
      if (document.execCommand('copy'))
        props.appSnackbarMessage(getStrings().tokenCopied)
    } else
      props.appSnackbarMessage(getStrings().tokenGenerateToCopy)
  }

  const componentDidMount = () => {
    log('Integration componentDidMount');
    updateActiveMenuItem('menuItemIntegracao', 'menuItemVenda');
    props.getHubToken();
    verificaVersaoHub();
  }

  const componentDidUpdate = () => {
    log('Integration componentDidUpdate');
    tokenInput.inputComponent.value = props.hubToken;
  }

  // eslint-disable-next-line
  React.useEffect(() => {
    if (firstRun) {
      updateFirstRun(false);
      componentDidMount();
    } else {
      componentDidUpdate();
    }
  });

  const verificaVersaoHub = () => {
    ClienteHttp.requisicaoServidor('versaoHub', 'get', false)
      .then(response => {
        if (response.data) {
          updateVersaoHub(response.data);
        }
      });
  }

  const render = () => {
    log('Integration render');

    const MdContentCopy = Icones.md.MdContentCopy;
    const MdAutorenew = Icones.md.MdAutorenew;

    const iconStyle = { width: '1.4em', height: '1.4em' };

    return <>
      <div className='header breadCrumbs'>
        <h1>{getStrings().integration}</h1>
        <h2>{getStrings().settings}</h2>
      </div>

      <div className='tela-integration'>
        <WidthAwareDiv>
          <HelpParagraph>
            {getStrings().integrationHelp()[0]}
            {getStrings().integrationHelp()[1]}
            {getStrings().integrationHelp()[2]}
          </HelpParagraph>

          <h2 className='Bold'>{getStrings().hubDownload}</h2>
          <div className='downloadButtonsBox'>
            <DownloadButton link={`https://ampix.mobi:9443/static/ampix.hubSetup-${versaoHub}.exe`} windows />
            <DownloadButton link={`https://ampix.mobi:9443/static/ampix.hubSetup-${versaoHub}.jar`} />
          </div>

          <div className='Lighter version'>{getStrings().hubVersion(versaoHub)}</div>

          <div className='tokenInputBox'>
            <TokenButton title={getStrings().tokenGenerate} onClick={() => props.generateHubToken()} icon={<MdAutorenew style={iconStyle} />} />
            <InputCustomizado ref={input => tokenInput = getReduxWrappedComponent(input)} placeholder={getStrings().tokenUnavailable} maxLength='100' type='text' name='token' label={getStrings().tokenAuthentication} />
            <TokenButton title={getStrings().tokenCopy} onClick={() => copyTokenInput()} icon={<MdContentCopy style={iconStyle} />} />
          </div>
        </WidthAwareDiv>
      </div>
    </>;
  };

  return render();
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  state,
  hubToken: state.authReducer.hubToken
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  appSnackbarMessage: message => dispatch(appActions.appSnackbarMessage(message)),
  getHubToken: () => dispatch(authActions.getHubToken()),
  generateHubToken: () => dispatch(authActions.generateHubToken()),
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(Integration);
