import React from "react";
import styled from "styled-components";
import { TitleSub, Paragraph, ConteudoSection } from ".";
import { PATH_IMAGENS_PRINTS } from "../utils/resources";
import { ImagensContainer, PrintTablet } from "./Containers";

const PedidosPrints = () => {
  return (
    <ConteudoSection>
      <TitleSub primary>Tenha controle total dos seus pedidos</TitleSub>
      <Paragraph>Gestão de clientes e histórico de pedidos em tempo real</Paragraph>
      <ImagensContainer>
        <Print src={PATH_IMAGENS_PRINTS + "controle1.png"} alt={"controle1"} />
        <Print src={PATH_IMAGENS_PRINTS + "controle2.png"} alt={"controle2"} />
        <Print src={PATH_IMAGENS_PRINTS + "controle3.png"} alt={"controle3"} />
      </ImagensContainer>
    </ConteudoSection>
  );
};

const Print = styled(PrintTablet)`
  position: absolute;
  &:nth-child(1) {
    left: 0;
    top: 0;
  }
  &:nth-child(2) {
    right: 0;

    top: 100px;
    z-index: 10;
  }
  &:nth-child(3) {
    bottom: 0;
    left: 0;
  }
  @media (max-width: ${(props) => props.theme.sizes.breakPointSmall}) {
    &:nth-child(3) {
      bottom: 40px;
  }
`;

export default PedidosPrints;
