import { log } from '../LogUtils'
import { axios } from '../../store/actions/appAction'
import { urlDatabase } from '../SecureConnectionUtils'
import { getHeaders } from '../../apps/promo_app_frontend/nucleo/utils/ClienteHttp/ClienteHttp'

export async function confirmMessageAsync(subscriber: string, subject: string) {
  log('appAction confirmMessage', { subscriber, subject })

  return await axios().post(
      urlDatabase + '/confirmMessage',
      {},
      getHeaders({ subscriber, subject })
  )
}