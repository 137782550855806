import React from "react";
import { connect } from "react-redux";

import { isFunction } from "../../../../../utils/ComparatorsUtils";
import { log } from "../../../../../utils/LogUtils";

import { STATE_CADASTRO_EMPRESA_USUARIO } from "../../../../../store/reducers/cadastroEmpresaReducer";
import * as cadastroActions from "../../../../../store/actions/cadastroAction";
import * as cadastroEmpresaActions from "../../../../../store/actions/cadastroEmpresaAction";
import * as signUpActions from "../../../../../store/actions/signUpAction";


import SignUpUserForm from "../../SignUpUserForm";

class SignUpCompanyUserForm extends React.Component {

  usuarioFieldArray = ["nome", "sobrenome"];
  usuarioDadosAdicionaisFieldArray = ["telefone", "uf", "municipio", "bairro", "endereco", "numero", "complemento", "cep"];
  loginFieldArray = ["email", "senha"];
  usuarioFieldArrayStep0 = [...this.usuarioFieldArray];
  usuarioDadosAdicionaisFieldArrayStep0 = ["telefone"];
  loginFieldArrayStep0 = [...this.loginFieldArray];
  usuarioDadosAdicionaisFieldArrayStep1 = this.usuarioDadosAdicionaisFieldArray.filter((text) => text !== "telefone");

  getUsuarioFieldArray(step) {
    switch (step) {
      case 0:
        return this.usuarioFieldArrayStep0;
      case 1:
        return [];
      default:
        return this.usuarioFieldArray;
    }
  }

  getLoginFieldArray(step) {
    switch (step) {
      case 0:
        return this.loginFieldArrayStep0;
      case 1:
        return [];
      default:
        return this.loginFieldArray;
    }
  }

  getUsuarioDadosAdicionaisFieldArray(step) {
    switch (step) {
      case 0:
        return this.usuarioDadosAdicionaisFieldArrayStep0;
      case 1:
        return this.usuarioDadosAdicionaisFieldArrayStep1;
      default:
        return this.usuarioDadosAdicionaisFieldArray;
    }
  }

  fieldArray = [
    ...this.getUsuarioFieldArray(this.props.step).concat(this.getLoginFieldArray(this.props.step)),
  ];

  /**
   * Recupera os dados do formulário.
   */
  getFormData = () => {
    // Verifica se há referência para os campos
    let someFieldsNotReady = this.fieldArray.some(field => !this[field]);
    log("SignUpCompanyUserForm getFormData", { someFieldsNotReady });
    let result;
    if (someFieldsNotReady) {
      result = {
        nome: "",
        sobrenome: "",
        login: {
          email: "",
          senha: "",
          //confirmacaoSenha: ""
        },
        dadosAdicionais: {
          //dataNascimento: null,
          telefone: "",
          //cpf_cnpj: "",
          pais: null,
          uf: "",
          municipio: "",
          bairro: "",
          endereco: "",
          numero: "",
          complemento: "",
          cep: "",
          paisOption: null,
        }
      };
    } else {
      result = {
        nome: this.props.dadosUsuario.nome,
        sobrenome: this.props.dadosUsuario.sobrenome,
        login: {
          email: this.props.dadosUsuario.login.email,
          senha: this.props.dadosUsuario.login.senha,
        },
        dadosAdicionais: {
          telefone: this.props.dadosUsuario.dadosAdicionais.telefone,
          //cpf_cnpj: this.props.dadosUsuario.cpf_cnpj,
          pais: this.props.dadosUsuario.dadosAdicionais.pais,
          uf: this.props.dadosUsuario.dadosAdicionais.uf,
          municipio: this.props.dadosUsuario.dadosAdicionais.municipio,
          bairro: this.props.dadosUsuario.dadosAdicionais.bairro,
          endereco: this.props.dadosUsuario.dadosAdicionais.endereco,
          numero: this.props.dadosUsuario.dadosAdicionais.numero,
          complemento: this.props.dadosUsuario.dadosAdicionais.complemento,
          cep: this.props.dadosUsuario.dadosAdicionais.cep,
          paisOption: this.props.dadosUsuario.dadosAdicionais.paisOption,
        }
      }
    }
    log("SignUpCompanyUserForm getFormData", { result });
    return result;
  }

  /**
   * Armazena os dados no *reducer* assim que modifica algum campo.
   * 
   * Possibilita que um dos valores lidos dos campos seja substituído.
   * Motivo é porque, quando os campos mudam de valor, eles executam um *callback* para atualizar o *reducer*.
   * O problema é que, em alguns campos, o valor atualizado só pode ser ligo algum tempo depois do *callback* disparar.
   * Ou seja, pode acontecer do método para atualizar o *reducer* ser executado antes do valor atualizado estar disponível.
   * 
   * Com a função passada por parâmetro, é possível usar o valor recebido do *callback* ao invés do lido do campo.
   * @param {Function} updateData função para substituir um dos valores lidos dos campos
   */
  updateReducer(updateData) {
    log("SignUpCompanyUserForm updateReducer", { updateData });
    // Verifica se há referência para os campos
    if (this.fieldArray.some(field => !this[field])) {
      return;
    }
    // Busca os valores dos campos
    let dados = this.getFormData();
    // Se necessário, usa um valor mais recente.
    if (isFunction(updateData)) {
      updateData(dados);
    }
    
    this.props.cadastroEmpresaEUsuarioSaveData(dados);
  }

  /**
   * Carrega nos campos os dados do reducer.
   */
  componentDidMount() {
    log("SignUpCompanyUserForm componentDidMount");

    window.scrollTo({ top: 0 });

    this.props.cadastroSetChanged(true);
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   * Necessário ao definir `getSnapshotBeforeUpdate`.
   */
  componentDidUpdate() {
    log("SignUpCompanyUserForm componentDidUpdate");

    const { empresa, usuario } = this.props.dados;

    if (!usuario?.login?.email
      && !usuario?.nome
      && !usuario?.sobrenome
      && !usuario?.dadosAdicionais?.telefone
      && empresa?.contatoEmpresa) {
      const endereco = empresa.enderecoEmpresa;
      const contato = empresa.contatoEmpresa;
      const nomeArray = contato.nomeContato.split(" ");

      const novoUsuarioState = {
        login: { email: contato.email },
        nome: nomeArray[0],
        sobrenome: nomeArray.length > 1 ? nomeArray[nomeArray.length - 1] : "",
        dadosAdicionais: { ...endereco, telefone: contato.telefone },
      };

      this.props.cadastroEmpresaEUsuarioSaveData(novoUsuarioState);
    }
  }

  /**
   * Método executado ANTES de "MONTAR" o componente.
   * Verifica se mudou o idioma para recarregar a tela.
   * Isto é feito porque há um [problema](https://github.com/appleboy/react-recaptcha/issues/204) na biblioteca `react-recaptcha`: mesmo chamando `grecaptcha.reset()`, o idioma do componente não altera.
   */
  getSnapshotBeforeUpdate(prevProps, prevState) {
    log("SignUpCompanyUserForm getSnapshotBeforeUpdate");
    if (prevProps.locale !== this.props.locale) {
      window.location.reload();
    }
    return null;
  }

  render() {
    log("SignUpCompanyUserForm render");
    return <SignUpUserForm companyForm />;
  }
}

/**
 * Mapeia as propriedades do estado global para utilizar localmente. 
 * @param {*} state 
 */
const mapStateToProps = state => ({
  ...state.idiomaReducer,
  dados: state.cadastroEmpresaReducer.cadastroEmpresaDados,
  dadosUsuario: state.cadastroEmpresaReducer.cadastroEmpresaDados.usuario,
  password_score: state.signUpReducer.password_score,
  password_suggestions: state.signUpReducer.password_suggestions,
  password_warning: state.signUpReducer.password_warning,
  signUpFormData: state.signUpReducer.formData,
  step: state.signUpReducer.step,
});

/**
 * Mapeia as ações para utilizar localmente. 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  cadastroEmpresaEUsuarioSaveData: (dados) => dispatch(cadastroEmpresaActions.cadastroEmpresaEUsuarioSaveData(STATE_CADASTRO_EMPRESA_USUARIO, dados)),
  cadastroSetChanged: changed => dispatch(cadastroActions.cadastroSetChanged(changed)),
  setToken: reCaptchaToken => dispatch(signUpActions.setToken(reCaptchaToken)),
  setScore: (score) => dispatch(signUpActions.setScore(score)),
  setSuggestions: (suggestions) => dispatch(signUpActions.setSuggestions(suggestions)),
  setWarning: (warning) => dispatch(signUpActions.setWarning(warning)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpCompanyUserForm);