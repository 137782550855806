import { updateObject } from "../../utils/reducerUtils"

export const menuReducerActionTypes = {
  RESET_MENU: "RESET_MENU:MENU_REDUCER",
  CLOSE_MENU: "CLOSE_MENU:MENU_REDUCER",
  OPEN_MENU: "OPEN_MENU:MENU_REDUCER",
  TOGGLE_MENU: "TOGGLE_MENU:MENU_REDUCER",
};

/**
 * 
 * @typedef {Object} menuReducerType
 * @property {boolean} menuIsOpen
 * @property {string?} type
 */

/**
 * 
 * @type {menuReducerType}
 */
export const menuReducerInitialState = {
  menuIsOpen: false,
};

/**
 * 
 * @param {menuReducerType} currentState 
 * @param {Partial<menuReducerType>} action 
 * @returns {menuReducerType}
 */
export function menuReducer(currentState = menuReducerInitialState, action) {
  /**
   * 
   * @type {menuReducerType}
   */
  let newState;

  switch (action.type) {
    case menuReducerActionTypes.CLOSE_MENU:
      newState = updateObject(currentState, {
        menuIsOpen: false,
      }, action.type);
      return newState;
    case menuReducerActionTypes.OPEN_MENU:
      newState = updateObject(currentState, {
        menuIsOpen: true,
      }, action.type);
      return newState;
    case menuReducerActionTypes.RESET_MENU:
      newState = menuReducerInitialState;
      return newState;
    case menuReducerActionTypes.TOGGLE_MENU:
      newState = updateObject(currentState, {
        menuIsOpen: !currentState.menuIsOpen,
      }, action.type);
      return newState;
    default:
      newState = currentState;
      return newState;
  }
}

export default menuReducer;