import React from "react"

import { isString, nullOrUndefined } from "../../../utils/ComparatorsUtils"
import { addDateStringSeparator } from "../../../utils/DateUtils/DateUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"

import InputCustomizado from "../Input/InputCustomizado"

export const DATE_INPUT = "date"
export const DATE_TIME_INPUT = "dateTime"
export const TIME_INPUT = "time"
export const YEAR_MONTH_INPUT = "yearMonth"
export const MONTH_DAY_INPUT = "monthDay"
export const DATE_TIME_INPUT_SUPRESS_YEAR = "dateTimeSupressYear"

let moment = require("moment")

/**
 * Retorna se o InputCustomizado é um campo somente para seleção de data.
 */
export const isDateField = field => {
  if (field === YEAR_MONTH_INPUT) {
    return 'MM/YY';
  }
  if (field === MONTH_DAY_INPUT) {
    return 'DD/MM';
  }
  return [DATE_INPUT, DATE_TIME_INPUT].indexOf(field) >= 0;
}

/**
 * Retorna se o InputCustomizado é um campo para seleção de data e hora.
 */
export const isDateTimeField = field => {
  return [DATE_INPUT, DATE_TIME_INPUT, TIME_INPUT, YEAR_MONTH_INPUT, MONTH_DAY_INPUT].indexOf(field) >= 0;
}

/**
 * Retorna se o InputCustomizado é um campo somente para seleção de hora.
 */
export const isTimeField = field => {
  return [DATE_TIME_INPUT, TIME_INPUT].indexOf(field) >= 0;
}

/**
 * Classe para a montagem de um picker de data e hora, dado que o InputCustomizado sozinho não é suficiente
 * ([necessária conversão de *controlled* para *uncontrolled*](https://reactjs.org/docs/uncontrolled-components.html)).
 */
export default class DateTimeInputDefault extends React.Component {
  state = {
    value: null,
  };

  /**
   * Formata o valor informado dependendo do tipo do campo.
   * @param {moment} value
   */
  formatValue = value => {
    if (!moment.isMoment(value)) {
      return null;
    }
    switch (this.props.inputType) {
      case DATE_INPUT:
      case MONTH_DAY_INPUT:
        return value.format('YYYY-MM-DD');
      case TIME_INPUT:
        return typeof value === 'string' ? value : value.format('HH:mm:ss');
      default:
        return value.toISOString(true);
    }
  }

  /**
   * Retorna o valor selecionado formatado. Se for um campo de data, retorna no formato 2018-07-23. Se for de hora, retorna no formato 07:59:59.
   */
  getValue = () => this.formatValue(this.state.value);

  /**
   * Gera um `moment` a partir do valor informado.
   * @param {String} value
   */
  parseValue = value_ => {
    /**
     * @type {string|moment.Moment}
     */
    const value = nullOrUndefined(value_)
      ? ''
      : isString(value_) && this.props.inputType === DATE_INPUT
        ? addDateStringSeparator(value_)
        : value_

    if (!moment.isMoment(value)) {
      return value;
    }
    switch (this.props.inputType) {
      case DATE_INPUT:
      case MONTH_DAY_INPUT:
        return moment.utc(value, 'YYYY-MM-DD');
      case TIME_INPUT:
        return moment.parseZone(value, 'HH:mm:ss');
      default:
        return moment.parseZone(value, moment.ISO_8601);
    }
  }

  /**
   * Define o valor usando os mesmos formatos que são retornados. Se for um campo de data, usa o formato 2018-07-23. Se for de hora, usa o formato 07:59:59.
   */
  setValue = value => this.setState({ value: this.parseValue(value) });

  /**
   * Método executado APÓS a montagem/renderização do componente.
   * Altera o valor selecionado se foi passado um valor padrão.
   */
  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue });
    }
  }

  render() {
    return <InputCustomizado
      ref={input => this.inputCustomizado = getReduxWrappedComponent(input)}
      defaultValue={this.state.value}
      placeholder={this.props.placeholder}
      inputType={this.props.inputType}
      id={this.props.id}
      spinnerId={this.props.spinnerId}
      type={this.props.type}
      name={this.props.name}
      required={this.props.required}
      label={this.props.label}
      handleInputValidado={this.props.handleInputValidado}
      onChange={value => {
        if (this.props.onChange) {
          this.props.onChange(value);
        }
        this.setState({ value: this.parseValue(value) })
      }}
      onClear={() => {
        if (this.props.onClear) {
          this.props.onClear();
        }
        this.setState({ value: null })
      }}
      onBlur={this.props.onBlur}
      onFocus={this.props.onFocus}
    />;
  }
}
