//@ts-check
import "./ProdutoCard.css"

import React from "react"
import { Texto, Botao, Imagem, Entrada } from "../../../nucleo/nucleo"
import { connect } from "react-redux"
import { atualizarCarrinho } from "../../../nucleo/redux/carrinho/carrinhoActions"
import { LocalidadeUtils } from "../../../nucleo/utils/utils"
import { mostrarDialog } from "../../../nucleo/redux/dialog/dialogActions"
import ZoomImagemDialog from "../../dialogs/ZoomImagemDialog/ZoomImagemDialog"
import { Icones } from "../../../assets/icones/Icones"

class ProdutoCard extends React.Component {
  /**
   * 
   * @type {Partial<import('./types').produtoCardPropsType>}
   */
  static defaultProps = {
    promocao: {precoPromo: 0},
  }

  /**
   * 
   * @param {string} id 
   * @param {string} texto 
   * @param {number} quantidadeMinima 
   * @param {number} quantidadeEmEstoque 
   * @param {string | null} observacao 
   */
  atualizarQuantidade = (id, texto, quantidadeMinima, quantidadeEmEstoque, observacao) => {
    texto = texto.replace('[^0-9]*', '');
    const quantidade = texto ? parseInt(texto) : 0;

    this.props.atualizarCarrinho({id, quantidade: quantidade >= quantidadeMinima && quantidade <= quantidadeEmEstoque ? quantidade : 0, observacao});
  }

  /**
   * 
   * @param {import('./types').produtoCardPropsType} nextProps 
   * @returns 
   */
  shouldComponentUpdate(nextProps) {
    // Testa se o produto alterado é o mesmo do card, para não fazer o render de todos os cards 
    return this.props.id === this.props.produtoCarrinho.id || this.props.id === nextProps.produtoCarrinho.id;
  }

  render() {
    const {
      id,
      descricao,
      nome,
      fonteImagem,
      fonteImagemTipo,
      quantidadeEmEstoque,
      preco,
      promocao,
    } = this.props;

    const precoSemDescontoStr = promocao.precoPromo > 0
      ? LocalidadeUtils.obterTraducao().currency.format(preco)
      : '';

    const precoComDesconto = promocao.precoPromo;

    const precoStr = quantidadeEmEstoque <= 0 ? LocalidadeUtils.obterTraducao().words.soldOut : promocao.precoPromo > 0
    ? LocalidadeUtils.obterTraducao().currency.format(precoComDesconto)
    : LocalidadeUtils.obterTraducao().currency.format(preco); 

    //const produto = this.props.webAppCarrinhoReducer.produtos[id] || {quantidade: 0};
    const produto = this.props.produtoCarrinho;

    const imagemProduto = fonteImagemTipo ? fonteImagem : LocalidadeUtils.obterTraducao().noImageAvailableIcon;
    const soldOut = quantidadeEmEstoque <= 0
      ? ' sold-out'
      : '';

    const IconeLixeira = Icones.go.GoTrashcan;

    return <div tabIndex={this.props.tabIndex} className={'app-card produto-card'}>
      <div>
      <div>
        <div
          className='imagem'
          onClick={() => {
            this.props.dispatch(
              mostrarDialog({
                conteudo: () => <ZoomImagemDialog
                  fonteImagemTipo={fonteImagemTipo + 'dialog__'}
                  imagemProduto={imagemProduto}
                  titulo={nome}
                />
              })
            );
          }}
        >
          <Imagem lupa className={'app-icone'} key={fonteImagemTipo} src={imagemProduto} />
        </div>
        <div className='identificacao-produto'>
          <div className='nome'>
            <div className='info'>
              <div className='column nome'>
                <Texto className='nome'>{nome}</Texto>
              </div>
            </div>
          </div>
          <div className='descricao'>
            <Texto>{descricao}</Texto>
          </div>
          <div className='painel-produto'>
            <div className='painel-e-preco'>
              <div className='preco'>
                <div className='row sem-desconto'>{quantidadeEmEstoque <= 0 ? '' : precoSemDescontoStr}</div>
                <div className={'row com-desconto' + soldOut}>{precoStr}</div>
              </div>
              <Botao className={`remover-do-carrinho ${produto.quantidade <= 0 || produto.quantidade > quantidadeEmEstoque ? 'disabled' : ''}`}>
                <IconeLixeira
                  onClick={() => { this.props.atualizarCarrinho({ id, observacao: produto.observacao, quantidade: 0 }) }}
                />
              </Botao>
              <div className='row controle-quantidade'>
                <Botao disabled={produto.quantidade <= 0} className='diminuir-quantidade' onClick={() => this.props.atualizarCarrinho({ id, quantidade: produto.quantidade - 1, observacao: produto.observacao })}>-</Botao>
                <Entrada className='quantidade' value={produto.quantidade} onChange={
                  /**
                   * 
                   * @param {{ target: { value: string } }} event 
                   * @returns 
                   */
                  (event) => this.atualizarQuantidade(id, event.target.value, 0, quantidadeEmEstoque, produto.observacao)} />
                <Botao disabled={produto.quantidade >= quantidadeEmEstoque} className='aumentar-quantidade' onClick={() => this.props.atualizarCarrinho({ id, quantidade: produto.quantidade + 1, observacao: produto.observacao })}>+</Botao>
              </div>
            </div>
          </div>
        </div>
        </div>
        {this.props.ultimoCardDoGrupo
          ? null
          : <div className={`linha-separadora`} />
        }
      </div>
    </div>;
  }
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @param {import('./types').produtoCardPropsType} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => ({
  busca: state.webAppGrupoProdutosReducer.busca,
  /**
   * 
   * @type {import('../../../nucleo/redux/carrinho/types').carrinhoProdutoTipo}
   */
  produtoCarrinho: state.webAppCarrinhoReducer.produtos[ownProps.id] || {quantidade: 0},
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  /**
   * 
   * @param {{id: string, observacao: string | null, quantidade: number}} carga 
   * @returns 
   */
  atualizarCarrinho: (carga) => dispatch(atualizarCarrinho({...carga, add: false})),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProdutoCard);
