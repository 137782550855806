// @ts-check
import React from 'react';
import { Botao, Texto } from '../../../nucleo/nucleo';

import { LocalidadeUtils } from '../../../nucleo/utils/utils';

import { connect } from 'react-redux';

/**
 * 
 * @param {import('./types').botaoConcluirPropsType} props 
 * @returns {JSX.Element}
 */
function BotaoConcluir(props) {
  return <Botao id='botao-concluir' disabled={props.disabled} className={'row ' + (props.className || '')} onClick={(event) => {
    props.webAppBotaoPrincipalReducer.executarAcao(event);
  }}>
    <Texto>{LocalidadeUtils.obterTraducao().words.finishBuying}</Texto>
  </Botao>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  webAppBotaoPrincipalReducer: state.webAppBotaoPrincipalReducer,
  webAppCarrinhoReducer: state.webAppCarrinhoReducer,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapActionToProps = (dispatch) => ({dispatch});

export default connect(mapStateToProps, mapActionToProps)(BotaoConcluir);