import "./PreCadastroTela.css"

import React from "react"
import { connect } from "react-redux"
import moment from "moment"
import { ClienteHttp } from "../../../apps/promo_app_frontend/nucleo/utils/utils"
import { getErrorMessageObjectFromError } from "../../../apps/promo_app_frontend/nucleo/utils/ErrorUtils/ErrorUtils"
import { getEmpresaId } from "../../../utils/CompanyUtils"
import { nullOrUndefined } from "../../../utils/ComparatorsUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { distanciaEntreDoisPontos } from "../../../utils/MapUtils"
import { formatNumber, roundNumber } from "../../../utils/NumberUtils"
import { getApi } from "../../../utils/SecureConnectionUtils"
import { cadastroBuildOptionsFromPaises } from "../../../utils/SelectUtils"
import { stringParseFloat } from "../../../utils/StringUtils"
import { formataTelefone } from "../../../utils/TelefoneUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"
import { MODO_COBRANCA_TELE_ENTREGA_POR_BAIRRO, MODO_COBRANCA_TELE_ENTREGA_POR_DISTANCIA } from "../../../store/reducers/empresaSelectorReducer"
import { readZipCode, cadastroLoadPaises, buscaCepPorEndereco } from "../../../store/actions/cadastroAction"
import { appNotificationShow } from "../../../store/actions/appAction"
import InputCustomizado from "../../UI/Input/InputCustomizado"
import BoxTemplate from "../../UI/Template/BoxTemplate"
import BarraAcoesButton from "../../UI/Toolbar/BarraAcoesButton"
import { Fieldset } from "../../UI/Fields/Fieldset"
import { APP_NOTIFICATION_TYPE_ERROR, APP_NOTIFICATION_TYPE_WARNING } from "../../../store/actions/actionTypes"
import { modoTipo, pedidosTelaEstadoTipo } from "./PedidosTela"

type PreCadastroTelaTipo = {
  atualizarEstado: (novoEstado: any) => void | undefined
  , buscarEstado: () => pedidosTelaEstadoTipo
  , dispatch: (carga: any) => any
  , estadoInicial: pedidosTelaEstadoTipo
  , modo: modoTipo
  , toolbar?: React.ReactNode
  , vendaList: any
}

type formaPagamentoTipo = {
  codigo: string
  , nome: string
  , emiteDocumentoFiscal: boolean
};

type formaPagamentoOpcoesTipo = {
  labelKey: string
  , label: string
  , value: { codigo: string, value: any }
};

class PreCadastroTela extends React.Component<PreCadastroTelaTipo> {
  state: {
    innerFunctions?: { updateOptions: (updateOptions: any) => void | undefined, updateValue: (value: any, key: string) => void | undefined }
    , valorTotal?: string
    , mostraBuscaNomes?: boolean
    , mouseOverBuscaNomes?: boolean
    , listaUsuarios?: Array<any>
  } = {};

  enderecoEmpresa: any = null;

  onInput = (evt: any, nome: string) => {
    const valor = evt?.target ? evt.target.value : evt;
    const entregaVendaOuDadosPessoais = ['nome', 'telefone', 'endereco'].includes(nome)
      ? 'preCadastroUsuario'
      : 'entregaVenda';
    const cadastroComanda = this.props.buscarEstado().cadastroComanda;
    const novoEstado = {
      cadastroComanda: {
        ...cadastroComanda
        , [entregaVendaOuDadosPessoais]: { ...cadastroComanda[entregaVendaOuDadosPessoais], [nome]: valor }
      }
    };

    this.props.atualizarEstado(novoEstado);
  };

  loadBairros = () => {

    const bairroUpdateOptions = this.bairro?.updateOptions || (() => { });

    ClienteHttp.requisicaoServidor(`bairros/porEmpresa/${getEmpresaId()}`, 'get', true).then((resposta: any) => {
      const bairros: Array<formaPagamentoOpcoesTipo> = (resposta?.data?.content || []).map((bairro: formaPagamentoTipo) => ({
        labelKey: bairro.codigo,
        label: bairro.nome,
        value: { codigo: bairro.codigo, value: bairro, },
      }));

      bairroUpdateOptions(bairros);

      const bairroSelecionado = this.selecionarBairro(bairros);

      if (bairroSelecionado) {
        this.onInput({ ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco, bairro: bairroSelecionado.value.value.nome }, 'endereco');
      }
    });
  };

  selecionarBairro = (bairros: any = undefined) => {
    const bairroUpdateValue = this.bairro?.updateValue || (() => { });
    const getOptions = this.bairro?.getOptions || (() => { });
    const bairro = this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco.bairro;
    const bairroOptions = bairros || getOptions();

    if (bairroOptions) {
      const bairroSelecionado = bairroOptions.filter(
        (bairroOption: any) => (bairroOption.value?.value?.nome || '').trim().toLowerCase() === (bairro || '').trim().toLowerCase())[0];

      bairroUpdateValue(bairroSelecionado);

      return bairroSelecionado;
    }
  }

  loadPaises = () => {
    // Carrega o campo de País com a função de busca, conforme o campo for sendo preenchido.
    this.props.dispatch(cadastroLoadPaises((cadastros: any) => {
      const innerFunctions = { updateOptions: (_: any) => { }, updateValue: (_: any, key: string) => { }, ...this.state.innerFunctions };
      const endereco = this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco;
      const paisURI = getURIFromEntity(endereco.pais);

      innerFunctions.updateOptions(cadastroBuildOptionsFromPaises(true, cadastros));
      const paisSelecionado = cadastros.filter((pais: any) => {
        const paisSelecionado = pais._links?.self?.href && pais._links?.self?.href === paisURI

        return paisSelecionado;
      })[0];

      paisSelecionado && innerFunctions.updateValue({ labelKey: paisSelecionado.iso, label: getStrings().countryName(paisSelecionado.iso), value: paisSelecionado }, 'iso');
    }));
  };

  bairro?: any;
  teleEntrega?: { updateValue: (newValue: any, key?: string | null) => void | undefined, updateOptions: (newOptions: any) => void | undefined };
  formaPagamento?: { updateValue: (newValue: any, key?: string | null) => void | undefined, updateOptions: (newOptions: any) => void | undefined };

  componentDidMount() {

    this.props.atualizarEstado({ naoCarregarProdutos: true });
    this.loadPaises();

    if (this.props.buscarEstado().parametrosEmpresa.modoCobrancaTeleEntrega === MODO_COBRANCA_TELE_ENTREGA_POR_BAIRRO) {
      this.loadBairros();
    }

    const teleEntregaUpdateOptions = this.teleEntrega?.updateOptions || (() => { });
    const teleEntregaUpdateValue = this.teleEntrega?.updateValue || (() => { });
    const { requisicaoServidor } = ClienteHttp;
    const teleEntrega = [
      {
        labelKey: 'tele',
        label: getStrings().deliveryMethods['Tele Entrega'],
        value: { codigo: 'tele', value: true, },
      },
      {
        labelKey: 'local',
        label: getStrings().deliveryMethods['Retirar no local'],
        value: { codigo: 'local', value: false, },
      },
    ];

    const eTeleEntrega = this.props.buscarEstado().cadastroComanda.entregaVenda.teleEntrega;

    teleEntregaUpdateOptions(teleEntrega);
    teleEntregaUpdateValue(teleEntrega[eTeleEntrega ? 0 : 1]);

    const formaPagamentoUpdateOptions = this.formaPagamento?.updateOptions || (() => { });
    const formaPagamentoUpdateValue = this.formaPagamento?.updateValue || (() => { });

    requisicaoServidor('formaPagamentos', 'get', true).then((resposta: any) => {
      const formaPagamentos: Array<formaPagamentoOpcoesTipo> = (resposta.data._embedded?.resources || []).map((formaPagamento: formaPagamentoTipo) => ({
        labelKey: formaPagamento.codigo,
        label: formaPagamento.nome,
        value: { codigo: formaPagamento.codigo, value: formaPagamento, },
      }));

      const formaPagamento = this.props.buscarEstado().cadastroComanda.entregaVenda.formaPagamento;

      let formaPagamentoSelecionada = formaPagamentos.filter(
        formaPag => formaPag.value.value._links.self.href === (formaPagamento?._links?.self?.href || formaPagamento))[0];

      formaPagamentoSelecionada = formaPagamentoSelecionada || formaPagamentos[0];

      formaPagamentoUpdateOptions(formaPagamentos);

      formaPagamentoUpdateValue(formaPagamentoSelecionada);
      this.onInput(formaPagamentoSelecionada?.value?.value?._links?.self?.href, 'formaPagamento');
    });

    const cadastroComanda = this.props.buscarEstado().cadastroComanda;
    const { valorCupom, valorPago, valorTeleEntrega, valorPagoDigitado } = cadastroComanda.entregaVenda;

    const valorItensSelecionados = roundNumber(this.props.buscarEstado().valorItensSelecionados, 2);
    const valorTeleEntregaNumber = stringParseFloat(valorTeleEntrega);
    const valorCupomNumber = stringParseFloat(valorCupom);
    const valorPagoDigitadoNumber = stringParseFloat(valorPagoDigitado);
    let valorPagoNumber = stringParseFloat(valorPago);

    let valorTotalNumber = roundNumber(valorItensSelecionados + valorTeleEntregaNumber - valorCupomNumber, 2);
    valorTotalNumber = valorTotalNumber > 0 ? valorTotalNumber : 0;

    if (valorPagoDigitadoNumber > valorPagoNumber) {
      valorPagoNumber = valorPagoDigitadoNumber;
    }

    if (valorTotalNumber > valorPagoNumber) {
      const valorTotal = valorTotalNumber.toFixed(2).replace('.', ',');
      this.props.atualizarEstado({
        cadastroComanda: {
          ...cadastroComanda
          , preCadastroUsuario: cadastroComanda.preCadastroUsuario
          , entregaVenda: {
            ...cadastroComanda.entregaVenda
            , valorPago: valorTotal
            , valorPagoDigitado: null
          }
        }
      });
    }
  }

  componentDidUpdate(prevProps: PreCadastroTelaTipo) {
    const updateValue = this.state.innerFunctions?.updateValue;

    if (updateValue) {
      if (!this.enderecoEmpresa || !this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco?.pais) {
        const { requisicaoServidor, getApi } = ClienteHttp;
        const linkEmpresa = JSON.parse(sessionStorage.getItem('c') || '{}').e;

        requisicaoServidor(linkEmpresa.replace(getApi(''), ''), 'get', true)
          .then((resposta: any) => {
            const empresa = resposta.data;
            const pais = empresa.enderecoEmpresa.pais;
            const paisURI = getURIFromEntity(pais);

            const cidade = this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco.cidade || empresa.enderecoEmpresa.municipio;
            const uf = this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco.uf || empresa.enderecoEmpresa.uf;

            this.enderecoEmpresa = `${empresa.enderecoEmpresa.endereco}, ${empresa.enderecoEmpresa.numero} - ${empresa.enderecoEmpresa.bairro}, ${empresa.enderecoEmpresa.municipio} - ${empresa.enderecoEmpresa.uf}, ${empresa.enderecoEmpresa.cep}, ${empresa.enderecoEmpresa.pais.nome}`;

            const novoEstado = {
              cadastroComanda: {
                ...this.props.buscarEstado().cadastroComanda
                , preCadastroUsuario: {
                  ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario
                  , endereco: {
                    ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco
                    , pais: paisURI
                    , cidade: cidade
                    , uf: uf
                  }
                }
              }
            };

            this.props.atualizarEstado(novoEstado);

            this.onInput(getURIFromEntity(pais), 'pais');
            this.onInput(cidade, 'cidade');
            this.onInput(uf, 'uf');

            updateValue({
              label: `${pais.iso} - ${getStrings().countryName(pais.iso)}`
              , labelKey: pais.iso
              , value: pais
            }, 'iso');
          });

        requisicaoServidor('formaPagamentos', 'get', true).then((resposta: any) => {
          const formaPagamentos: Array<formaPagamentoOpcoesTipo> = (resposta.data._embedded?.resources || []).map((formaPagamento: formaPagamentoTipo) => ({
            labelKey: formaPagamento.codigo,
            label: formaPagamento.nome,
            value: { codigo: formaPagamento.codigo, value: formaPagamento, },
          }));

          let formaPagamentoSelecionada = formaPagamentos.filter(
            formaPag => formaPag.value.value._links.self.href === this.props.buscarEstado().cadastroComanda.entregaVenda.formaPagamento)[0] || formaPagamentos[0];

          this.formaPagamento?.updateOptions && this.formaPagamento.updateOptions(formaPagamentos);

          this.formaPagamento?.updateValue && this.formaPagamento.updateValue(formaPagamentoSelecionada);
          this.onInput(formaPagamentoSelecionada?.value?.value?._links?.self?.href, 'formaPagamento');
        });
      }
    }

    const teleEntregaUpdateOptions = this.teleEntrega?.updateOptions || (() => { });
    const teleEntregaUpdateValue = this.teleEntrega?.updateValue || (() => { });
    const teleEntrega = [
      {
        labelKey: 'tele'
        , label: getStrings().deliveryMethods['Tele Entrega']
        , value: {
          codigo: 'tele'
          , value: true
        }
      },
      {
        labelKey: 'local'
        , label: getStrings().deliveryMethods['Retirar no local']
        , value: {
          codigo: 'local'
          , value: false
        }
      },
    ];

    teleEntregaUpdateOptions(teleEntrega);
    teleEntregaUpdateValue(teleEntrega[this.props.buscarEstado().cadastroComanda.entregaVenda.teleEntrega ? 0 : 1]);


    if (typeof this.props.buscarEstado().cadastroComanda.entregaVenda.teleEntrega !== 'boolean' && teleEntregaUpdateValue) {
      teleEntregaUpdateValue({
        labelKey: 'local'
        , label: getStrings().deliveryMethods['Retirar no local']
        , value: {
          codigo: 'local'
          , value: false
        }
      });

      this.onInput(false, 'teleEntrega');
    }

  }

  getMensagemValidacaoCliente = (preCadastroUsuario: any) => {
    if (!preCadastroUsuario.telefone || !preCadastroUsuario.telefone.replace('519', '')) {
      return getStrings().blankPhone;
    }
    if (!preCadastroUsuario.nome) {
      return getStrings().blankName;
    }
    if (!preCadastroUsuario.enderecoUsuario.cep) {
      return getStrings().blankUserZipCode;
    }
    if (!preCadastroUsuario.enderecoUsuario.endereco) {
      return getStrings().blankUserAddress;
    }
    if (!preCadastroUsuario.enderecoUsuario.numero) {
      return getStrings().blankUserAddressNumber;
    }
    if (!preCadastroUsuario.enderecoUsuario.bairro) {
      return getStrings().blankUserNeighborhood;
    }
    if (!preCadastroUsuario.enderecoUsuario.municipio) {
      return getStrings().blankUserCity;
    }
    if (!preCadastroUsuario.enderecoUsuario.uf) {
      return getStrings().blankUserCountryState;
    }
    return '';
  }

  getMensagemValidacaoPedido = (entregaVenda: any) => {
    if (nullOrUndefined(entregaVenda.teleEntrega)) {
      return getStrings().blankDeliveryTakeAway;
    }
    if (!entregaVenda.formaPagamento) {
      return getStrings().blankPaymentMethod;
    }
    return '';
  }

  handleClickSalvar = () => {
    const { requisicaoServidor } = ClienteHttp;
    const buscarEstado = (this.props.buscarEstado || (() => { }))();
    const comanda = {
      ...buscarEstado.cadastroComanda
      , valorPago: typeof buscarEstado.cadastroComanda.entregaVenda.valorPago === 'string'
        ? parseFloat(buscarEstado.cadastroComanda.entregaVenda.valorPago.replace(/,/g, '.'))
        : null
      , valorCupom: typeof buscarEstado.cadastroComanda.entregaVenda.valorCupom === 'string'
        ? parseFloat(buscarEstado.cadastroComanda.entregaVenda.valorCupom.replace(/,/g, '.'))
        : null
      , valorTeleEntrega: typeof buscarEstado.cadastroComanda.entregaVenda.valorTeleEntrega === 'string'
        ? parseFloat(buscarEstado.cadastroComanda.entregaVenda.valorTeleEntrega.replace(/,/g, '.'))
        : null
    };

    const idPreCadastroUsuario = comanda.preCadastroUsuario.id;
    // const idEntregaVenda = comanda.entregaVenda.id;
    const { dataHoraEntrega, fusoHorario, horaEntrega, ...entregaVenda } = comanda.entregaVenda;
    const { endereco, ...preCadastroUsuarioSemEndereco } = comanda.preCadastroUsuario;
    const { cidade, ...enderecoUsuario } = endereco;
    const paisURI = getURIFromEntity(enderecoUsuario.pais);
    const preCadastroUsuario = {
      ...preCadastroUsuarioSemEndereco
      , enderecoUsuario: {
        ...enderecoUsuario
        , pais: paisURI
        , municipio: cidade
      }
    };

    const valorPago = comanda.entregaVenda.valorPago ? comanda.entregaVenda.valorPago.replace(',', '.') : '0.00';
    const valorCupom = (comanda.entregaVenda.valorCupom || '').replace(',', '.') || null;
    const valorTeleEntrega = (comanda.entregaVenda.valorTeleEntrega || '').replace(',', '.') || null;

    const mensagemValidacaoCliente = this.getMensagemValidacaoCliente(preCadastroUsuario);
    if (mensagemValidacaoCliente) {
      this.props.dispatch(appNotificationShow(mensagemValidacaoCliente, APP_NOTIFICATION_TYPE_WARNING));
      return;
    }

    requisicaoServidor(`preCadastroUsuarios${idPreCadastroUsuario ? `/${idPreCadastroUsuario}` : ''}`, 'post', true, {
      params: {
        idEntregaVenda: entregaVenda.id
      }
    }, preCadastroUsuario)
      .then((resposta: any) => {
        const horaEntregaMoment = moment(horaEntrega);
        const hours = horaEntregaMoment.hours();
        const minutes = horaEntregaMoment.minutes();
        const hh = (hours < 10 ? '0' : '') + hours;
        const mm = (minutes < 10 ? '0' : '') + minutes;
        const dataHoraEntregaMoment = moment(dataHoraEntrega, 'DD/MM/YYYY');
        const date = dataHoraEntregaMoment.date();
        const month = dataHoraEntregaMoment.month() + 1;
        const year = dataHoraEntregaMoment.year();
        const dd = (date < 10 ? '0' : '') + date;
        const MM = (month < 10 ? '0' : '') + month;
        const yyyy = (year < 10 ? '0' : '') + year;
        const idPreCadastroUsuario = resposta.data?.id || null;
        const entregaVendaTratada = {
          dataHoraEntrega: moment(
            `${yyyy}-${MM}-${dd}T${hh}:${mm}`
            , 'YYYY-MM-DD HH:mm').toISOString()
          , fusoHorario: moment().format('Z')
          , ...entregaVenda
          , valorCupom
          , valorPago
          , valorTeleEntrega
        };

        const mensagemValidacaoPedido = this.getMensagemValidacaoPedido(entregaVendaTratada);
        if (mensagemValidacaoPedido) {
          this.props.dispatch(appNotificationShow(mensagemValidacaoPedido, APP_NOTIFICATION_TYPE_WARNING));
          return;
        }

        requisicaoServidor(`entregaVendas`, 'post', true, null, entregaVendaTratada)
          .then((resposta: any) => {
            const {
              tipoOrigemVendas
              , tipoOrigem
              , naoCarregarProdutos
              , cadastroComanda
              , ...estadoIncial
            } = this.props.estadoInicial;
            const novoEstado = {
              ...estadoIncial
              , cadastroComanda: {
                ...buscarEstado.cadastroComanda
                , entregaVenda: {
                  ...buscarEstado.cadastroComanda.entregaVenda
                  , id: resposta.data?.id
                }
                , preCadastroUsuario: {
                  ...buscarEstado.cadastroComanda.preCadastroUsuario
                  , endereco: {
                    ...buscarEstado.cadastroComanda.preCadastroUsuario.endereco
                  }
                  , id: idPreCadastroUsuario
                }
              }
              , tipoOrigem: buscarEstado.tipoOrigem
              , setAllDisabled: false
              , modo: 'venda'
              , editandoPedido: buscarEstado.editandoPedido
              , valorItensSelecionados: buscarEstado.valorItensSelecionados
            };

            this.props.atualizarEstado(novoEstado);
          })
          .catch((error: any) => {
            const mensagem = getErrorMessageObjectFromError(error);
            this.props.dispatch(appNotificationShow(mensagem.message, APP_NOTIFICATION_TYPE_ERROR));
          });
      })
      .catch((error: any) => {
        const mensagem = getErrorMessageObjectFromError(error);
        this.props.dispatch(appNotificationShow(mensagem.message, APP_NOTIFICATION_TYPE_ERROR));
      });
  };

  calculaValorEntrega = (bairroNome: any = null) => {

    const modoCobrancaTeleEntrega = this.props.buscarEstado().parametrosEmpresa.modoCobrancaTeleEntrega;
    const valorTeleEntregaPorKm = this.props.buscarEstado().parametrosEmpresa.valorTeleEntregaPorKm;

    if (modoCobrancaTeleEntrega === MODO_COBRANCA_TELE_ENTREGA_POR_BAIRRO) {

      const bairroOptions = this.bairro?.getOptions();

      if (bairroOptions) {
        let bairroSelecionado = bairroOptions.filter(
          (bairroOption: any) => (bairroOption.value?.value?.nome || '').trim().toLowerCase() === (bairroNome || '').trim().toLowerCase())[0];

        bairroSelecionado = bairroSelecionado || this.bairro?.getValue();

        if (bairroSelecionado) {
          const valorTeleEntregaNovo = formatNumber(bairroSelecionado.value?.value?.valorEntrega, 2) || 0;
          this.atualizaValorTeleEntrega(valorTeleEntregaNovo, bairroNome);
        }
      }
    }
    else if (modoCobrancaTeleEntrega === MODO_COBRANCA_TELE_ENTREGA_POR_DISTANCIA && valorTeleEntregaPorKm) {

      const { uf, cidade, endereco, bairro, numero, cep, pais } = this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco;

      distanciaEntreDoisPontos(
        this.enderecoEmpresa,
        `${endereco}, ${numero} - ${bairro}, ${cidade} - ${uf}, ${cep}, ${pais}`,
        (distancia: any) => {
          const valorTeleEntregaNovo = formatNumber(distancia / 1000 * valorTeleEntregaPorKm, 2) || 0;
          this.atualizaValorTeleEntrega(valorTeleEntregaNovo, bairroNome);
        }
      );
    }
  }

  atualizaValorTeleEntrega = (valorTeleEntregaNovo: any, bairroNome: any) => {

    const valorTeleEntregaAtual = this.props.buscarEstado().cadastroComanda.entregaVenda.valorTeleEntrega;

    if (valorTeleEntregaNovo && valorTeleEntregaNovo !== valorTeleEntregaAtual) {
      const cadastroComanda = this.props.buscarEstado().cadastroComanda;
      const bairro = bairroNome || this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco.bairro;
      this.props.atualizarEstado({
        cadastroComanda: {
          ...cadastroComanda
          , preCadastroUsuario: {
            ...cadastroComanda.preCadastroUsuario
            , endereco: {
              ...cadastroComanda.preCadastroUsuario.endereco
              , bairro: bairro
            }
          }
          , entregaVenda: {
            ...cadastroComanda.entregaVenda
            , valorTeleEntrega: valorTeleEntregaNovo
          }
        }
      });
    }
  }

  buscaCep = () => {

    const { uf, cidade, endereco, bairro, numero } = this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco;

    if (uf && cidade && endereco && cidade.length > 3 && endereco.length > 3) {

      this.props.dispatch(buscaCepPorEndereco(uf, cidade, endereco, (retorno: any) => {

        if (Array.isArray(retorno)) {

          retorno = retorno.filter(item => item.logradouro && item.logradouro.toUpperCase() === endereco.toUpperCase());

          if (bairro) {
            retorno = retorno.filter((item: any) => !item.bairro || item.bairro.toUpperCase() === bairro.toUpperCase());
          }

          if (numero) {
            retorno = retorno.filter((item: any) => {
              if (item.complemento) {
                if (item.complemento.includes('até ')) {
                  let num = item.complemento.split('/')[1];
                  return num && parseInt(numero) <= parseInt(num) ? true : false;
                }
                else if (item.complemento.includes(' ao fim')) {
                  let num = item.complemento.split('/')[1];
                  num = num ? num.replace(' ao fim', '') : num;
                  return num && parseInt(numero) >= parseInt(num) ? true : false;
                }
                else if (item.complemento.includes('de ') && item.complemento.includes(' a ')) {
                  let numeros = item.complemento.split(' a ');
                  let numMenor = numeros[0] ? numeros[0].split('/')[0] : numeros[0];
                  numMenor = numMenor ? numMenor.replace('de ', '') : numMenor;
                  let numMaior = numeros[1] ? numeros[1].split('/')[1] : numeros[1];
                  return numMenor && numMaior && parseInt(numero) >= parseInt(numMenor) && parseInt(numero) <= parseInt(numMaior) ? true : false;
                }
              }
              return true;
            });
          }

          if (retorno.length > 0 && retorno.every((item: any) => item.logradouro === retorno[0].logradouro)) {

            const cadastroComanda = {
              ...this.props.buscarEstado().cadastroComanda
              , preCadastroUsuario: {
                ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario
                , endereco: {
                  ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco
                  , cep: retorno[0].cep.replace(/\D/g, '')
                }
              }
            };

            this.props.atualizarEstado({ cadastroComanda });
          }
        }
      }));
    }

    this.calculaValorEntrega();
  }

  limpaDadosCliente = (nomeOuTelefone: 'nome' | 'telefone') => {
    const cadastroComanda = this.props.buscarEstado().cadastroComanda;
    const novoEstado = {
      cadastroComanda: {
        ...cadastroComanda
        , preCadastroUsuario: {
          ...cadastroComanda.preCadastroUsuario
          , [nomeOuTelefone]: ''
          , endereco: {
            ...cadastroComanda.preCadastroUsuario.endereco
            , bairro: ''
            , cep: ''
            , complemento: ''
            , endereco: ''
            , numero: ''
            , pontoReferencia: ''
          }
        }
      }
    };

    this.props.atualizarEstado(novoEstado);
  }

  atualizarNomeETelefone = (valor: any, nome: 'nome' | 'telefone') => {

    this.onInput(valor, nome);

    if (nome === 'telefone' && valor) {
      ClienteHttp.requisicaoServidor('preCadastroUsuarios/porTelefone', 'get', true, { params: { [nome]: valor } })
        .then((resposta: any) => {
          if (resposta.data) {
            const { enderecoUsuario, ...dadosPessoais } = resposta.data;
            const { municipio, ...endereco } = enderecoUsuario;
            const paisURI = getURIFromEntity(endereco.pais) || getApi(`paises/${endereco.pais}`);
            const dados = { ...dadosPessoais, endereco: { ...endereco, pais: paisURI, cidade: enderecoUsuario.municipio } };
            const { id, enabled, deleted, telefone, ...dadosTratados } = dados;
            const cadastroComanda = { ...this.props.buscarEstado().cadastroComanda, preCadastroUsuario: { ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario, ...dadosTratados } };

            this.props.atualizarEstado({ cadastroComanda });

            this.calculaValorEntrega();
          }
        });
    }
  }

  buscaPorNome = (nome: any) => {
    ClienteHttp.requisicaoServidor('preCadastroUsuarios/buscaPorNome', 'get', true, { params: { nome } })
      .then((resposta: any) => {
        if (resposta?.data?.content) {
          this.setState({ mostraBuscaNomes: true, listaUsuarios: resposta.data.content });
        }
      });
  }

  BarraBotoes = (props: any) => {
    return <div className='pre-cadastro-tela-botoes' >
      <BarraAcoesButton
        isPrimary
        cellClassName={'blue-button botao-pre-cadastro'}
        texto={getStrings().save}
        commandMethod={this.handleClickSalvar}
      />
      <BarraAcoesButton
        isPrimary
        cellClassName={'blue-button botao-pre-cadastro'}
        texto={getStrings().clear}
        commandMethod={() => this.props.atualizarEstado({
          ...this.props.buscarEstado()
          , cadastroComanda: {
            ...this.props.estadoInicial.cadastroComanda
            , entregaVenda: {
              ...this.props.estadoInicial.cadastroComanda.entregaVenda
              , id: this.props.buscarEstado().cadastroComanda.entregaVenda.id
            }
          }
        })}
      />
    </div>
  }

  rowStyle: React.CSSProperties = { display: 'flex', flexFlow: 'row wrap', padding: 'auto', flex: '1 1 auto', justifyItems: 'center', margin: '0' };

  render() {
    if (this.props.modo !== 'cadastrar_comanda') {
      return null;
    }

    const cadastroComanda = this.props.buscarEstado().cadastroComanda;
    const {
      nome
      , telefone
      , endereco
    } = cadastroComanda.preCadastroUsuario;
    const {
      teleEntrega
      , valorCupom
      , valorPago
      , valorPagoDigitado
      , valorTeleEntrega
      , observacao
      , formaPagamento
    } = cadastroComanda.entregaVenda;

    if (this.props.buscarEstado().parametrosEmpresa.modoCobrancaTeleEntrega === MODO_COBRANCA_TELE_ENTREGA_POR_BAIRRO) {
      this.selecionarBairro();
    }

    const valorItensSelecionados = roundNumber(this.props.buscarEstado().valorItensSelecionados, 2);

    const valorTeleEntregaNumber = stringParseFloat(valorTeleEntrega);
    const valorCupomNumber = stringParseFloat(valorCupom);
    const valorPagoNumber = stringParseFloat(valorPago);

    let valorTotalNumber = roundNumber(valorItensSelecionados + valorTeleEntregaNumber - valorCupomNumber, 2);
    valorTotalNumber = valorTotalNumber > 0 ? valorTotalNumber : 0;

    const valorTotal = valorTotalNumber.toFixed(2).replace('.', ',');
    const valorTroco = roundNumber(valorPagoNumber - valorTotalNumber, 2).toFixed(2).replace('.', ',');

    if (!nullOrUndefined(valorPagoDigitado)) {
      if (valorPagoDigitado !== valorPago) {
        this.props.atualizarEstado({
          cadastroComanda: {
            ...cadastroComanda
            , preCadastroUsuario: cadastroComanda.preCadastroUsuario
            , entregaVenda: {
              ...cadastroComanda.entregaVenda
              , valorPago: valorPagoDigitado
            }
          }
        });
      }
    }
    else if (valorPago !== valorTotal) {
      this.props.atualizarEstado({
        cadastroComanda: {
          ...cadastroComanda
          , preCadastroUsuario: cadastroComanda.preCadastroUsuario
          , entregaVenda: {
            ...cadastroComanda.entregaVenda
            , valorPago: valorTotal
          }
        }
      });
    }

    return <BoxTemplate
      key={'__pre_cadastro_usuario_tela__'}
      style={{
        width: 'auto'
        , padding: '0 1em 1em 1em'
      }}
      contentStyle={{
        display: 'flex'
        , flexFlow: 'column nowrap'
        , flex: '1 1 auto'
      }}
      form
      hide_header={true}
    >
      <Fieldset legend={getStrings().clientInformation}>
        <div style={{ ...this.rowStyle, position: 'relative' }}>
          <InputCustomizado
            newApi
            required
            topClassName={'topClassNameStylePreCadastroTela grande'}
            value={telefone}
            onChange={(evt: any) => {
              if (!(evt?.target ? evt.target.value : evt).replace(' ', '')) {
                this.limpaDadosCliente('telefone');
              }
              else {
                this.atualizarNomeETelefone((evt?.target ? evt.target.value : evt).replace(' ', ''), 'telefone');
              }
            }}
            type={'text'}
            inputType={'maskedPhone'}
            validacaoDados={'numeroInteiro'}
            max={13}
            inputClassName={'cadastro-comanda left'}
            label={getStrings().telephone}
          />
          <InputCustomizado
            newApi
            required
            topClassName={'topClassNameStylePreCadastroTela grande'}
            value={nome}
            onChange={(evt: any) => {
              if (!(evt?.target ? evt.target.value : evt)) {
                this.limpaDadosCliente('nome');
              }
              else {
                this.atualizarNomeETelefone((evt?.target ? evt.target.value : evt), 'nome');
              }
              this.buscaPorNome(evt?.target ? evt.target.value : evt);
            }}
            onBlur={() => !this.state.mouseOverBuscaNomes && this.setState({ mostraBuscaNomes: false })}
            onClick={() => this.setState({ mostraBuscaNomes: true })}
            onKeyDown={(evt) => {
              // se pressionou a seta para cima ou a seta para baixo
              if (evt.keyCode === 38 || evt.keyCode === 40) {
                const listaUsuariosLength = this.state.listaUsuarios?.length || 0;
                let index = null;

                for (let i = 0; i < listaUsuariosLength; i++) {
                  const elemento = document.getElementById(`__item_lista_usuarios_id_${i}__`);
                  if (elemento?.dataset.active === 'true') {
                    index = i;
                  }
                }

                index = evt.keyCode === 38
                  ? index === null ? listaUsuariosLength - 1 : index - 1
                  : index === null ? 0 : index + 1;

                for (let i = 0; i < listaUsuariosLength; i++) {
                  const elemento = document.getElementById(`__item_lista_usuarios_id_${i}__`);
                  if (elemento) {
                    elemento.dataset.active = i === index ? 'true' : 'false';
                  }
                }
              }
              // se pressionou enter
              else if (evt.keyCode === 13) {
                const listaUsuariosLength = this.state.listaUsuarios?.length || 0;
                for (let i = 0; i < listaUsuariosLength; i++) {
                  const elemento = document.getElementById(`__item_lista_usuarios_id_${i}__`);
                  if (elemento && elemento.dataset.active === 'true') {
                    elemento.click();
                  }
                }
              }
            }}
            type={'text'}
            inputClassName={'cadastro-comanda right'}
            label={getStrings().name}
            maxLength='40'
          />
          {this.state.mostraBuscaNomes && this.state.listaUsuarios?.length
            ? <div
              className='resultados-busca-header'
              onMouseEnter={() => this.setState({ mouseOverBuscaNomes: true })}
              onMouseLeave={() => this.setState({ mouseOverBuscaNomes: false })}
            >
              <div className='resultados-busca-body'>
                <ul>
                  {this.state.listaUsuarios.map((usuario, index) => {
                    return <li
                      id={`__item_lista_usuarios_id_${index}__`}
                      key={`__item_lista_usuarios_key_${index}__`}
                      onClick={() => {
                        const { enderecoUsuario, ...dadosPessoais } = usuario;
                        const { municipio, ...endereco } = enderecoUsuario;
                        const paisURI = getURIFromEntity(endereco.pais) || getApi(`paises/${endereco.pais}`);
                        const dados = { ...dadosPessoais, idPreCadastroUsuario: dadosPessoais.id, endereco: { ...endereco, pais: paisURI, cidade: enderecoUsuario.municipio } };
                        const { id, enabled, deleted, ...dadosTratados } = dados;
                        const cadastroComanda = { ...this.props.buscarEstado().cadastroComanda, preCadastroUsuario: { ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario, ...dadosTratados } };

                        this.props.atualizarEstado({ cadastroComanda });

                        this.setState({ mostraBuscaNomes: false });
                      }}
                      onMouseEnter={(evt: any) => {
                        const listaUsuariosLength = this.state.listaUsuarios?.length || 0;
                        for (let i = 0; i < listaUsuariosLength; i++) {
                          const id = `__item_lista_usuarios_id_${i}__`
                          const elemento = document.getElementById(id);
                          if (elemento) {
                            elemento.dataset.active = id === evt.target.id ? 'true' : 'false';
                          }
                        }
                      }}
                    >
                      {`${usuario.nome} - ${formataTelefone(usuario.telefone)}`}
                    </li>
                  })}
                </ul>
              </div>
            </div>
            : null
          }
        </div>

        <div style={this.rowStyle}>
          <InputCustomizado
            newApi
            required
            topClassName={'topClassNameStylePreCadastroTela medio'}
            value={endereco?.cep}
            onChange={(evt: any) => {
              if (this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco.cep !== evt.target.value) {
                this.onInput({ ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco, cep: evt.target.value }, 'endereco');

                this.props.dispatch(readZipCode(evt.target.value, (_: string, uf: string, cidade: string, bairro: string | null, endereco: string | null) => {
                  const novoEstado: { uf?: string, cidade?: string, bairro?: string, endereco?: string } = {};

                  if (uf) {
                    novoEstado.uf = uf;
                  }
                  if (cidade) {
                    novoEstado.cidade = cidade;
                  }
                  if (bairro) {
                    novoEstado.bairro = bairro;
                  }
                  if (endereco) {
                    novoEstado.endereco = endereco;
                  }

                  const cadastroComanda = {
                    ...this.props.buscarEstado().cadastroComanda
                    , preCadastroUsuario: {
                      ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario
                      , endereco: {
                        ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco
                        , ...novoEstado
                      }
                    }
                  };

                  this.props.atualizarEstado({ cadastroComanda });
                }));
              }
            }}
            type={'text'}
            inputType={'number'}
            validacaoDados={'numeroInteiro'}
            inputClassName={'cadastro-comanda left'}
            label={getStrings().zipCode}
            maxLength='8'
          />
          <InputCustomizado
            newApi
            required
            topClassName={'topClassNameStylePreCadastroTela grande'}
            value={endereco?.endereco}
            onInput={({ target: { value } }: any) => this.onInput({ ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco, endereco: value }, 'endereco')}
            type={'text'}
            inputClassName={'cadastro-comanda center'}
            label={getStrings().address}
            maxLength='50'
            onBlur={(evt: any) => {
              this.buscaCep();
            }}
          />
          <InputCustomizado
            newApi
            required
            topClassName={'topClassNameStylePreCadastroTela pequeno'}
            value={endereco?.numero}
            onChange={({ target: { value } }: any) => this.onInput({ ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco, numero: value }, 'endereco')}
            type={'text'}
            inputType={'number'}
            inputClassName={'cadastro-comanda right'}
            label={getStrings().number}
            maxLength='15'
            validacaoDados={'numeroInteiro'}
            onBlur={(evt: any) => {
              this.buscaCep();
            }}
          />
        </div>

        <div style={this.rowStyle}>
          <InputCustomizado
            newApi
            topClassName={'topClassNameStylePreCadastroTela grande'}
            value={endereco?.complemento}
            onInput={({ target: { value } }: any) => this.onInput({ ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco, complemento: value }, 'endereco')}
            type={'text'}
            inputClassName={'cadastro-comanda left'}
            label={getStrings().complement}
            maxLength='20'
          />
          {this.props.buscarEstado().parametrosEmpresa.modoCobrancaTeleEntrega === MODO_COBRANCA_TELE_ENTREGA_POR_BAIRRO
            ? <InputCustomizado
              newApi
              required
              topClassName={'topClassNameStylePreCadastroTela pequeno'}
              onChange={(bairro: any) => {
                this.onInput({ ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco, bairro: bairro ? bairro.value.value.nome : '' }, 'endereco');
                this.calculaValorEntrega(bairro ? bairro.value.value.nome : null);
              }}
              type={'text'}
              placeholder={'Selecione um bairro'}
              innerFunctions={(innerFunctions) => { this.bairro = innerFunctions; }}
              inputType='singleSelect'
              inputClassName={'cadastro-comanda center'}
              label={getStrings().neighborhood}
            />
            : <InputCustomizado
              newApi
              required
              topClassName={'topClassNameStylePreCadastroTela pequeno'}
              value={endereco?.bairro}
              onInput={({ target: { value } }: any) => this.onInput({ ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco, bairro: value }, 'endereco')}
              type={'text'}
              inputClassName={'cadastro-comanda center'}
              label={getStrings().neighborhood}
              maxLength='50'
            />
          }
          <InputCustomizado
            newApi
            topClassName={'topClassNameStylePreCadastroTela medio'}
            value={endereco?.pontoReferencia}
            onInput={({ target: { value } }: any) => this.onInput({ ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco, pontoReferencia: value }, 'endereco')}
            type={'text'}
            inputClassName={'cadastro-comanda right'}
            label={getStrings().referencePoint}
            maxLength='100'
          />
        </div>

        <div style={this.rowStyle}>
          <InputCustomizado
            newApi
            required
            topClassName={'topClassNameStylePreCadastroTela grande'}
            value={endereco?.cidade}
            onInput={({ target: { value } }: any) => this.onInput({ ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco, cidade: value }, 'endereco')}
            type={'text'}
            inputClassName={'cadastro-comanda left'}
            label={getStrings().city}
            maxLength='50'
            onBlur={(evt: any) => {
              this.buscaCep();
            }}
          />
          <InputCustomizado
            newApi
            required
            topClassName={'topClassNameStylePreCadastroTela pequeno'}
            value={endereco?.uf}
            onInput={({ target: { value } }: any) => {
              this.onInput({ ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco, uf: value }, 'endereco');
            }}
            type={'text'}
            inputClassName={'cadastro-comanda center'}
            label={getStrings().countryState}
            maxLength='50'
            onBlur={(evt: any) => {
              this.buscaCep();
            }}
          />
          <InputCustomizado
            newApi
            required
            topClassName={'topClassNameStylePreCadastroTela medio'}
            onChange={(value: any) => {
              const paisURI = getURIFromEntity(value.value);

              this.onInput({
                ...this.props.buscarEstado().cadastroComanda.preCadastroUsuario.endereco
                , pais: paisURI
              }, 'endereco');
            }}
            type={'text'} placeholder={'Selecione um país'} innerFunctions={(innerFunctions) => { this.setState({ innerFunctions }); }} inputType='singleSelect'
            inputClassName={'cadastro-comanda right'}
            label={getStrings().country}
          />
        </div>
      </Fieldset>

      <Fieldset legend={getStrings().orderInformation}>
        <InputCustomizado
          newApi
          value={observacao || ''}
          onChange={({ target: { value } }: any) => {
            this.onInput(value, 'observacao');
          }}
          type={'text'}
          inputClassName={'cadastro-comanda'}
          label={getStrings().observation}
          defaultChecked
          maxLength='300'
        />
        <div style={this.rowStyle}>
          <InputCustomizado
            newApi
            required
            topClassName={'topClassNameStylePreCadastroTela grande'}
            onChange={(tipoRecebimento: any) => {
              this.onInput(tipoRecebimento?.value?.value, 'teleEntrega');
            }}
            innerFunctions={(innerFunctions) => { this.teleEntrega = innerFunctions; }}
            inputType={'singleSelect'}
            type={'text'}
            inputClassName={'cadastro-comanda left'}
            label={getStrings().deliveryTakeAway}
            defaultChecked
          />
          <InputCustomizado
            newApi
            required
            topClassName={'topClassNameStylePreCadastroTela grande'}
            value={formaPagamento}
            onChange={(formaPagamento: any) => {
              this.onInput(formaPagamento?.value?.value._links.self.href, 'formaPagamento');
            }}
            inputClassName={'cadastro-comanda right'}
            label={getStrings().paymentMethod}
            type={'text'}
            inputType={'singleSelect'}
            innerFunctions={(innerFunctions) => { this.formaPagamento = innerFunctions; }}
          />
        </div>

        <div style={this.rowStyle}>
          <InputCustomizado
            disabled={!teleEntrega}
            newApi
            topClassName={'topClassNameStylePreCadastroTela pequeno'}
            value={valorTeleEntrega}
            onChange={(evt) => {
              if (valorTotalNumber > valorPagoNumber && parseFloat(evt.target.value.replace(',', '.')) !== parseFloat((valorTeleEntrega || '0').replace(',', '.'))) {
                this.props.atualizarEstado({
                  cadastroComanda: {
                    ...cadastroComanda
                    , entregaVenda: {
                      ...cadastroComanda.entregaVenda
                      , valorPago: valorTotal
                      , valorTeleEntrega: evt.target.value
                    }
                  }
                });

                return;
              }

              this.onInput(evt, 'valorTeleEntrega');
            }}
            onBlur={(evt) => { this.onInput(evt, 'valorTeleEntrega'); }}
            type={'text'}
            inputType={'number'}
            validacaoDados={'numeroDecimal'}
            inputClassName={'cadastro-comanda left'}
            label={getStrings().deliveryPrice}
            max={999999.99}
            scale={2}
          />
          <InputCustomizado
            newApi
            topClassName={'topClassNameStylePreCadastroTela pequeno'}
            value={valorCupom}
            onChange={(evt) => {
              if (valorTotalNumber > valorPagoNumber && parseFloat(evt.target.value.replace(',', '.')) !== parseFloat((valorCupom || '0').replace(',', '.'))) {
                this.props.atualizarEstado({
                  cadastroComanda: {
                    ...cadastroComanda
                    , entregaVenda: {
                      ...cadastroComanda.entregaVenda
                      , valorPago: valorTotal
                      , valorCupom: evt.target.value
                    }
                  }
                });

                return;
              }

              this.onInput(evt, 'valorCupom');
            }}
            onBlur={(evt) => { this.onInput(evt, 'valorCupom'); }}
            inputClassName={'cadastro-comanda center'}
            label={getStrings().couponValue}
            type={'text'}
            inputType={'number'}
            validacaoDados={'numeroDecimal'}
            max={999999.99}
            scale={2}
          />
          <InputCustomizado
            newApi
            disabled
            topClassName={'topClassNameStylePreCadastroTela pequeno'}
            value={valorTotal}
            inputClassName={'cadastro-comanda center'}
            label={getStrings().totalValueLabel}
            type={'text'}
            inputType={'number'}
            validacaoDados={'numeroDecimal'}
            max={999999.99}
            scale={2}
          />
          <InputCustomizado
            newApi
            topClassName={'topClassNameStylePreCadastroTela pequeno'}
            value={valorPago}
            onChange={(evt) => { this.onInput(evt, 'valorPagoDigitado'); }}
            onBlur={(evt) => {
              const valorPagoNumber = parseFloat((evt.target.value || '0').replace(',', '.'));

              if (valorTotalNumber > valorPagoNumber) {
                evt.target.value = valorTotal;
              }

              this.onInput(evt, 'valorPagoDigitado');
            }}
            inputClassName={'cadastro-comanda center'}
            label={getStrings().totalPaid}
            type={'text'}
            inputType={'number'}
            validacaoDados={'numeroDecimal'}
            max={999999.99}
            scale={2}
          />
          <InputCustomizado
            newApi
            disabled
            topClassName={'topClassNameStylePreCadastroTela pequeno'}
            value={valorTroco}
            inputClassName={'cadastro-comanda right'}
            label={getStrings().moneyChange}
            type={'text'}
            inputType={'number'}
            validacaoDados={'numeroDecimal'}
            max={999999.99}
            scale={2}
          />
        </div>
      </Fieldset>

      <this.BarraBotoes />

    </BoxTemplate>;
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: (carga: any) => any) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PreCadastroTela);
