const detectFlash = () => {
    // return true if browser supports flash, false otherwise
    // Code snippet borrowed from: https://github.com/swfobject/swfobject
    var SHOCKWAVE_FLASH = "Shockwave Flash",
        SHOCKWAVE_FLASH_AX = "ShockwaveFlash.ShockwaveFlash",
        FLASH_MIME_TYPE = "application/x-shockwave-flash",
        win = window,
        nav = navigator,
        hasFlash = false;
    
    if (typeof nav.plugins !== "undefined" && typeof nav.plugins[SHOCKWAVE_FLASH] === "object") {
        var desc = nav.plugins[SHOCKWAVE_FLASH].description;
        if (desc && (typeof nav.mimeTypes !== "undefined" && nav.mimeTypes[FLASH_MIME_TYPE] && nav.mimeTypes[FLASH_MIME_TYPE].enabledPlugin)) {
            hasFlash = true;
        }
    }
    else if (win.ActiveXObject) {
        try {
            var ax = new win.ActiveXObject(SHOCKWAVE_FLASH_AX);
            if (ax) {
                var ver = ax.GetVariable("$version");
                if (ver) hasFlash = true;
            }
        }
        catch (e) {;}
    }
        
    return hasFlash;
};

const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
const isHttp = window.location.href.includes('http:');

if (isIE && detectFlash()) {
    const getUserMedia = (constraints) => {
        const swfURL = 'webcam.swf';
        const params = {
            width: constraints.video.width,
            height: constraints.video.height,
            dest_width: constraints.video.width,         // size of captured image
            dest_height: constraints.video.height,        // these default to width/height
            image_format: 'png',  // image format (may be jpeg or png)
            jpeg_quality: 90,      // jpeg image quality from 0 (worst) to 100 (best)
            enable_flash: true,    // enable flash fallback,
            force_flash: false,    // force flash mode,
            fps: 30,               // camera frames per second
            upload_name: 'webcam', // name of file in upload post data
            constraints: null,     // custom user media constraints,
            swfURL: '',            // URI to webcam.swf movie (defaults to the js location)
            flashNotDetectedText: 'ERROR: No Adobe Flash Player detected.  Webcam.js relies on Flash for browsers that do not support getUserMedia (like yours).',
            noInterfaceFoundText: 'No supported webcam interface found.',
            unfreeze_snap: true,    // Whether to unfreeze the camera after snap (defaults to true)
            iosPlaceholderText: 'Click here to open camera.',
            user_callback: null,    // callback function for snapshot (used if no user_callback parameter given to snap function)
            user_canvas: null       // user provided canvas for snapshot (used if no user_canvas parameter given to snap function)
        };
            
        const getFlashVars = () => {
            let flashvars = '';

            for (var key in params) {
                if (flashvars) flashvars += '&';
                flashvars += key + '=' + escape(params[key]);
            }

            return flashvars;
        };

        const flashElement = `
        <object classid="clsid:d27cdb6e-ae6d-11cf-96b8-444553540000"
            type="application/x-shockwave-flash"
            codebase="http${isHttp ? '' : 's'}://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=9,0,0,0"
            width="${constraints.video.width}"
            height="${constraints.video.height}"
            id="webcam_movie_obj"
            align="middle">
                <param name="wmode" value="opaque" />
                <param name="allowScriptAccess" value="always" />
                <param name="allowFullScreen" value="false" />
                <param name="movie" value="${swfURL}" />
                <param name="loop" value="false" />
                <param name="menu" value="false" />
                <param name="quality" value="best" />
                <param name="bgcolor" value="#ffffff" />
                <param name="flashvars" value="${getFlashVars()}"/>
                <embed 
                    id="webcam_movie_embed"
                    src="${swfURL}"
                    wmode="opaque"
                    loop="false"
                    menu="false"
                    quality="best"
                    bgcolor="#ffffff"
                    width="${constraints.video.width}"
                    height="${constraints.video.height}"
                    name="webcam_movie_embed"
                    align="middle"
                    allowScriptAccess="always"
                    allowFullScreen="false"
                    type="application/x-shockwave-flash"
                    pluginspage="http${isHttp ? '' : 's'}://www.macromedia.com/go/getflashplayer"
                    flashvars="${getFlashVars()}">
                </embed>
        </object>`;

        return new Promise((resolve) => {
            resolve(flashElement);
        });
    };


    // eslint-disable-next-line
    Object.defineProperty(navigator, 'mediaDevices', {
        value: { getUserMedia, },
        configurable: true,
        writable: true,
    });
}