
import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { formatI18nString } from "../../../utils/StringUtils"
import {
  PERMISSAO_VENDA_ENVIO,
  PERMISSAO_VENDA_PAGAMENTO,
  verificaPermissaoAcesso
} from "../../../utils/AuthUtils"

import * as collectorActions from "../../../store/actions/collectorAction"

import BuildButton from "../../UI/Toolbar/BarraAcoesButton"
import Icones from "../../../assets/icones/Icones"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

/**
 * Classe que realiza a montagem da barra de ações para a gerência de vendas com coletores.
 */
class BarraAcoesCollectorSale extends React.Component {

  /**
   * Método que monta o componente.
   */
  render() {
    log("BarraAcoesGridCollector render")

    const FaQrcode = Icones.fa.FaQrcode
    const MdSend = Icones.md.MdSend

    /**
     * Se usuário não tiver permissão, esconde o botão de realizar pagamento e enviar para o Hub.
     */
    let showPay = verificaPermissaoAcesso([PERMISSAO_VENDA_PAGAMENTO])

    /**
     * Se usuário não tiver permissão, esconde o botão de enviar itens de venda.
     */
    let showSend = verificaPermissaoAcesso([PERMISSAO_VENDA_ENVIO])

    // Monta o componente
    return <WidthAwareDiv id="toolbar" className="toolbar">

      <div className="toolbar-content">

        <div className="bar-container" >

          {/* Botão Voltar */}
          <BuildButton key="back" isPrimary={true} id="backButton"
            commandMethod={this.props.confirmToShowCollectorList} label={"\u003c"} title={getStrings().back} buttonGridClassName="bar-item" />

          {/* Botão para abrir o leitor de código de barras/QR */}
          <BuildButton key="barQRCode" isPrimary={false} id="barQRCodeButton" label={<FaQrcode />} title={getStrings().qrRead}
            buttonGridClassName="bar-item" commandMethod={this.props.getBarQRCodeToAddProduct} />

          {/* Botão para encerrar a venda e enviar ela para o Hub */}
          {showPay ? <BuildButton key="hub" isPrimary={false} id="hubButton" isDisabled={!this.props.collectedTotal()}
            label={formatI18nString([{ length: -1, icon: "ApxHub" }])} title={getStrings().sendToHub}
            buttonGridClassName="bar-item" commandMethod={this.props.confirmToSendToHub} /> : ""}

          {/* Botão para enviar itens */}
          {showSend ? <BuildButton key="send" isPrimary={true} id="sendButton" isDisabled={(!this.props.filteredTotal()) && (!this.props.collectedAmountChanged())}
            label={<MdSend />} title={getStrings().sendItems} buttonGridClassName="bar-item" commandMethod={() => this.props.saveProductList(this.props.collector)}
            attractSubmit={this.props.filteredTotal() || this.props.collectedAmountChanged()} /> : ""}
        </div>
      </div>
    </WidthAwareDiv>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  collectedAmountChanged: state.collectorReducer.collectedAmountChanged,
  collectedProductList: state.collectorReducer.collectedProductList,
  collectedTotal: state.collectorReducer.collectedTotal,
  collectorList: state.collectorReducer.collectorList,
  filteredTotal: state.collectorReducer.filteredTotal,
  // Esta variável só está aqui para que o componente renderize de novo quando seu valor mudar
  filteredProductList: state.collectorReducer.filteredProductList
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  confirmToSendToHub: () => dispatch(collectorActions.confirmToSendToHub()),
  confirmToShowCollectorList: () => dispatch(collectorActions.confirmToShowCollectorList()),
  getBarQRCodeToAddProduct: () => dispatch(collectorActions.getBarQRCodeToAddProduct()),
  saveProductList: collector => dispatch(collectorActions.saveProductList(collector))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(BarraAcoesCollectorSale)
