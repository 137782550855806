//@ts-check
import React from "react"

/**
 * 
 * @param {import("./types").seletorUnicoPropsType} props 
 * @returns {JSX.Element}
 */
function SeletorUnico(props) {
    const items = props.children.map((
      /**
       * @type {any}
       */
      {value, label, defaultChecked}, index) =>
      <option key={`__option_${label}_${value}__`} defaultChecked={defaultChecked} id={value} value={value} >{label}</option>
    );

    let value = props.value;

    if (!value) {
      const valueChecked = props.children.find(element => element.defaultChecked === true);
      value = valueChecked && (typeof valueChecked.value === 'string' || typeof valueChecked.value === 'number') 
      ? valueChecked.value 
      : undefined;
    }

    return <select className='seletor-item' name={props.name} onChange={props.onChange} value={value}> 
      {items}
    </select>;
}

export default SeletorUnico;
