export function getPageNames() {
  const names = {
    especial: {
      MOBI_APP: "/apps",
      DEV: "/dev",
    },

    naoLogado: {
      LANDING: "/",
      HOME: "/home",
      LOGIN: "/login",
      SIGNUP: "/signUp",
      SIGNUP_USER: "/signUpUser",
      SIGNUP_COMPANY: "/signUpCompany",
      REDEFINIR_SENHA: "/redefinirSenha",

      CONTROLE_VENDAS: "/controleVendas",
    },

    logado: {
      HOME: "/",
      SELECAO_EMPRESA: "/selecaoEmpresa",
      CADASTRO_EMPRESA: "/cadastroEmpresa",
      USUARIO: "/usuario",
      PEDIDOS: "/pedidos",
      LOGOUT: "/logout",

      LEITOR_CODIGO: "/leitorcodigo",

      CONTROLE_VENDAS: "/controleVendas",
    },

    cargoSelecionado: {
      HOME: "/",
      SELECAO_EMPRESA: "/selecaoEmpresa",
      USUARIO: "/usuario",
      PEDIDOS: "/pedidos",
      LOGOUT: "/logout",
      EMPRESAS: "/empresas",
      MOBI_APPS: "/mobiApps",
      CARGOS: "/cargos",
      QUADRO_HORARIOS: "/quadroHorarios",
      FERIADOS: "/feriados",
      IMPRESSORAS: "/impressoras",
      INTEGRACAO: "/integracao",
      FORMA_PAGAMENTOS: "/formaPagamentos",
      TIPO_PRODUTOS: "/tipoProdutos",
      CLIENTES: "/clientes",
      TAMANHOS: "/tamanhos",
      GRUPO_PRODUTOS: "/grupoProdutos",
      PRODUTOS: "/produtos",
      COMBOS: "/combos",
      ORIGEM_VENDAS: "/origemVendas",
      BAIRROS: "/bairros",

      CONTROLE_VENDAS: "/controleVendas",
      PRODUCAO: "/producao",
      CANCEL: "/cancel",

      RELATORIOS_MOVIMENTO: "/relatoriosMovimento",
      RELATORIOS_FECHAMENTO: "/relatoriosFechamento",
      RELATORIOS_LUCRATIVIDADE: "/relatoriosLucratividade",
      RELATORIOS_LUCRATIVIDADE_DATA: "/relatoriosLucratividadeData",
      RELATORIOS_LUCRATIVIDADE_HORA: "/relatoriosLucratividadeHora",
      RELATORIOS_ENTREGAS: "/relatoriosEntregas",
    },
  };

  return names;
}