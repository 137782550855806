import bairroReducer from './bairro/bairroReducer';
import botaoPrincipalReducer from './botoes/botaoPrincipal/botaoPrincipalReducer';
import botaoVoltarReducer from './botoes/botaoVoltar/botaoVoltarReducer';
import barraNavegacaoReducer from './barraNavegacao/barraNavegacaoReducer';
import cadastroEnderecoReducer from './cadastro/cadastroEndereco/cadastroEnderecoReducer';
import cadastroUsuarioReducer from './cadastro/cadastroUsuario/cadastroUsuarioReducer';
import carrinhoReducer from './carrinho/carrinhoReducer';
import dialogReducer from './dialog/dialogReducer';
import empresaReducer from './empresa/empresaReducer';
import formasPagamentoReducer from './pedidos/formasPagamento/formasPagamentoReducer';
import grupoProdutosReducer from './produtos/grupoProdutos/grupoProdutosReducer';
import menuModalReducer from './menus/menuModal/menuModalReducer';
import mobiAppReducer from './mobiApp/mobiAppReducer';
import navegacaoReducer from './navegacao/navegacaoReducer';
import overlayReducer from './overlay/overlayReducer';
import paisesReducer from './paisesReducer/paisesReducer';
import pedidosReducer from './pedidos/pedidosReducer';
import produtosReducer from './produtos/produtos/produtosReducer';
import snackbarReducer from './snackbar/snackbarReducer';
import usuarioReducer from './usuario/usuarioReducer';
import infoReducer from './info/infoReducer';
import parametrosEmpresaReducer from './parametrosEmpresa/parametrosEmpresaReducer';
import placeholderMessageReducer from '../../../../store/reducers/placeholderMessageReducer';
import quadroHorariosReducer from './quadroHorarios/quadroHorariosReducer';

export default {
  webAppBairroReducer: bairroReducer,
  webAppBotaoPrincipalReducer: botaoPrincipalReducer,
  webAppBotaoVoltarReducer: botaoVoltarReducer,
  webAppBarraNavegacaoReducer: barraNavegacaoReducer,
  webAppCadastroEnderecoReducer: cadastroEnderecoReducer,
  webAppCadastroUsuarioReducer: cadastroUsuarioReducer,
  webAppCarrinhoReducer: carrinhoReducer,
  webAppDialogReducer: dialogReducer,
  webAppEmpresaReducer: empresaReducer,
  webAppFormasPagamentoReducer: formasPagamentoReducer,
  webAppGrupoProdutosReducer: grupoProdutosReducer,
  webAppInfoReducer: infoReducer,
  webAppMenuModalReducer: menuModalReducer,
  webAppMobiAppReducer: mobiAppReducer,
  webAppNavegacaoReducer: navegacaoReducer,
  webAppOverlayReducer: overlayReducer,
  webAppPaisesReducer: paisesReducer,
  webAppParametrosEmpresaReducer: parametrosEmpresaReducer,
  webAppPedidosReducer: pedidosReducer,
  webAppPlaceholderMessageReducer: placeholderMessageReducer,
  webAppProdutosReducer: produtosReducer,
  webAppQuadroHorariosReducer: quadroHorariosReducer,
  webAppSnackbarReducer: snackbarReducer,
  webAppUsuarioReducer: usuarioReducer,
};
