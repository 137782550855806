
// import * as errorUtils from "../../utils/ErrorUtils"
import { getHeaders } from "../../utils/HeadersUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { urlDatabase } from "../../utils/SecureConnectionUtils"

import * as actionTypes from "./actionTypes"
import { appNotificationShow, appSpinnerHide, appSpinnerShow, axios } from "../../store/actions/appAction"

/**
 * "Entidade" que identifica os dados de identificação da empresa que são lidos por usuário sem login.
 */
export const COMPANY_INFORMATION_ENTITY = "companyInformation"

export const PRODUCT_ENTITY = "produtos"

export const SALE_SOURCE_ENTITY = "origemVendas"

/**
 * Finaliza a leitura.
 */
export const stopReader = () => {
  log("codeReaderAction stopReader")

  return dispatch => dispatch({
    type: actionTypes.CODE_READER_ACTION,
    backAction: null,
    breadCrumbs: null,
    cache: {},
    codeReadList: [],
    entity: "",
    header: "",
    onSuccess: () => { },
    readEnabled: false,
  })
}

/**
 * Define o valor inicial das variáveis do estado para iniciar a leitura.
 */
export const startReader = (entity, header, breadCrumbs, backAction, cache, onSuccess) => {
  log("codeReaderAction startReader", entity, header, breadCrumbs, backAction, cache, onSuccess)

  return dispatch => dispatch({
    type: actionTypes.CODE_READER_ACTION,
    backAction: backAction || (() => { }),
    breadCrumbs,
    cache: cache || {},
    codeReadList: [],
    entity: entity || "",
    header: header || "",
    onSuccess: onSuccess || (() => { }),
    readEnabled: true
  })
}

/**
 * Recebe um código que foi lido para verificar com a retaguarda se é um código válido.
 * Se houver falha na retaguarda, o código é verificado novamente.
 * @param {*} code código de barras ou QR lido pelo leitor
 * @param {*} tryAgain se o método foi chamado quando uma requisição falhou 
 * @param {*} external se o método foi chamado por um leitor externo
 */
export const verifyCode = (code, tryAgain, external) => (dispatch, getState) => {
  log("codeReaderAction verifyCode", { code, tryAgain, external })

  let state = () => getState().codeReaderReducer;
  // Se a leitura não está habilitada, interrompe o método.
  if (!state().readEnabled) {
    return;
  }
  // Verifica se esse código já não foi lido ou se deve ser verificado novamente
  let codeReadList = state().codeReadList;
  if (tryAgain || (codeReadList.indexOf(code) < 0)) {
    // Se o código ainda não foi lido e não é uma nova tentativa de verificar ele, adiciona ele à lista dos códigos já lidos.
    if (!tryAgain) {
      dispatch({
        type: actionTypes.CODE_READER_ACTION,
        codeReadList: codeReadList.concat(code)
      });
    }
    dispatch(appSpinnerShow("verifyCode"));
    // Decide se usará o cache, uma requisição para a retaguarda ou somente o método informado
    (((state().entity === SALE_SOURCE_ENTITY) && (code || "").startsWith(urlDatabase)) ?
      // Somente o método informado
      (new Promise(resolve => resolve({ data: code }))) :
      // O cache
      ((!tryAgain) && (Object.keys(state().cache).length > 0)) ?
        // Tenta buscar a entidade no cache
        (new Promise((resolve, reject) => {
          // Busca a entidade
          let entity = state().cache[code];
          // Se encontrou, passa para o código que vai tratar ela.
          if (entity) {
            resolve({ data: entity });
          }
          // Se não encontrou, passa para o código que vai tentar pela retaguarda.
          else {
            reject({ response: { data: "cacheMiss" } });
          }
        })) :
        // Verifica o código com a retaguarda.
        axios().get(
          (state().entity === COMPANY_INFORMATION_ENTITY)
            ? (external ? `${urlDatabase}/origemVendas/${code}/scan` : code)
            : urlDatabase + "/" + state().entity + "/findByCodigoImpresso",
          getHeaders((state().entity === COMPANY_INFORMATION_ENTITY) ? { js: true } : { code })
        )
    )
      .then(response => {
        // Se a leitura não está habilitada ou se retornou um código de código inválido, faz mais nada.
        if ((!state().readEnabled) || (["noBarQRCode", "nonUniqueBarQRCode"].indexOf(response.data) > -1)) {
          return;
        }
        // Armazena o método a ser executado após verificar positivamente um código. Isto é feito para que o estado do leitor
        // possa ser limpo antes de executar esse método, já que ele também será limpo.
        let onSuccess = state().onSuccess;
        // Limpa as variáveis do leitor
        dispatch(stopReader());
        // Dispara o método a ser executado após verificar positivamente um código
        dispatch(onSuccess(response.data));
      })
      // Se ocorrer um erro, como a leitura estará sempre ocorrendo, somente mostra o erro no console
      // e agenda uma nova tentativa de verificar o código.
      .catch(error => {
        log("codeReaderAction verifyCode", "error", error, Object.keys(error).map(key => ({ key, value: error[key] })));
        // Se a leitura não está habilitada ou se retornou um código de código inválido, faz mais nada.
        let errorResponseData = ((error || {}).response || {}).data || "";
        if ((!state().readEnabled) || (["invalidBarQRCode", "noBarQRCode", "nonUniqueBarQRCode", "notAllowedByThisCompany"].indexOf(errorResponseData) > -1)) {
          //if (errorResponseData === "notAllowedByThisCompany") {
          dispatch(appNotificationShow(getStrings()[errorResponseData], actionTypes.APP_NOTIFICATION_TYPE_WARNING, getStrings().warning));
          //}
          return;
        }
        dispatch(verifyCode(code, true));
      })
      .finally(() => dispatch(appSpinnerHide("verifyCode")));
  }
};
