import tiposDeAcoes from "./quadroHorariosActions";
import criarReducer, { acaoTipo } from "../factory/criarReducer";
import { feriadoTipo, horarioFuncionamentoTipo } from "../../../../../utils/QuadroHorariosUtils/quadroHorariosTypes";

export type quadroHorariosReducerTipo = {
  feriadoList?: feriadoTipo[],
  forcarEmpresaFechada?: boolean,
  horarioFuncionamentoList?: horarioFuncionamentoTipo[],
};

const estadoInicial: quadroHorariosReducerTipo = {};

const acoesNomeadas: { [x: string]: (acao: any, estadoAtual?: quadroHorariosReducerTipo) => quadroHorariosReducerTipo } = {
  [tiposDeAcoes.POPULAR_QUADRO_HORARIOS]: (carga: quadroHorariosReducerTipo, estadoAtual = estadoInicial) => {
    const novoEstado = {
      ...estadoAtual,
      ...carga,
    };

    return novoEstado;
  },
};

const quadroHorariosReducer = (estado: quadroHorariosReducerTipo = estadoInicial, acao: acaoTipo) =>
  criarReducer(estado, acao, acoesNomeadas);

export default quadroHorariosReducer;
