// eslint-disable-next-line
import { stateType } from "../reducers/reducersType"

/**
 * 
 * @param {stateType} currentState 
 * @param {stateType} newState 
 * @param {string} type 
 */
export function updateObject(currentState, newState, type) {
  return {
    ...currentState,
    ...newState,
    type,
  }
}