import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { nullOrUndefined } from "../../../utils/ComparatorsUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"

import {
	isDateField
	, isDateTimeField
	, isTimeField
	, DATE_INPUT
	, DATE_TIME_INPUT
	, TIME_INPUT
	, YEAR_MONTH_INPUT
	, MONTH_DAY_INPUT
} from "../dateTimeInputDefault/DateTimeInputDefault"

import MultiSelectField from "./MultiSelectField"
import Datetime from "react-datetime"
import { IMaskInput } from "react-imask"
import SelectFieldAsync from "./SelectedFieldAsync"
import { loadSpinnerInput } from "../Spinner/Spinner"

// Picker de data e hora
import "react-datetime/css/react-datetime.css" // needed for the styles to work
import "./InputCustomizado.css";

// Para internacionalização do Datetime
require("moment")
// Inglês é automático
require("moment/locale/pt")

const keyHandler = (evt) => {
	if (evt.key === " ") {
		evt.preventDefault()
		evt.target.click()
	}
}

/**
 * Componente dos formulários dos cadastros.
 */
class InputCustomizado extends React.Component {
	selected = false;

	static defaultProps = {
		onChange: () => {},
	}

	/**
	 * 
	 * @param {React.MouseEvent<HTMLInputElement, MouseEvent>} e 
	 */
	selectIfNotSelectedOnClick = (e) => {
		if (!this.selected) {
			e.target.select();
			this.selected = true;
		}
	}

	/**
	 * 
	 * @param {React.FocusEvent<HTMLInputElement>} e 
	 */
	markNotSelectOnBlur = (e) => {
		if (e.type === "blur")
			this.selected = false;
	}

	static propTypes = {
		arrowRenderer: PropTypes.any,
		autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
		checked: PropTypes.bool,
		clearable: PropTypes.bool,
		defaultChecked: PropTypes.bool,
		defaultValue: PropTypes.any,
		disabled: PropTypes.bool,
		id: PropTypes.string,
		innerFunctions: PropTypes.func,
		inputClassName: PropTypes.string,
		inputType: (props, propsName, componentName) => {
			const prop = props[propsName];

			if (['masked', 'maskedCPF', 'number', 'multiselect', 'selectAsync', 'singleSelect', 'text', 'textarea', DATE_INPUT, DATE_TIME_INPUT, TIME_INPUT, YEAR_MONTH_INPUT, MONTH_DAY_INPUT, undefined].includes(typeof prop)) {
				return new Error(`Error: invalid props "${propsName}" in Component "${componentName}"`);
			}

			return null;
		},
		handleInputValidado: PropTypes.func, // Chamado no `onClick` de *radios* e *checkboxes* ou no `onInput` de outros campos.
		label: PropTypes.string,
		labelStyle: PropTypes.any,
		locale: PropTypes.any,
		marginBottom: PropTypes.string,
		max: PropTypes.number,
		maxLength: PropTypes.any,
		min: PropTypes.number,
		name: PropTypes.string,
		newApi: PropTypes.bool,
		onBlur: PropTypes.func,
		onChange: PropTypes.func,
		onClear: PropTypes.func,
		onClick: PropTypes.func,
		onKeyDown: PropTypes.func,
		onInput: PropTypes.func,
		options: PropTypes.any,
		placeholder: PropTypes.string,
		readOnly: PropTypes.any,
		ref: PropTypes.any,
		reference: PropTypes.any,
		required: PropTypes.bool,
		searchable: PropTypes.bool,
		scale: PropTypes.number,
		sort: PropTypes.bool,
		style: PropTypes.any,
		tabIndex: PropTypes.number,
		topClassName: PropTypes.string,
		topClassStyle: PropTypes.any,
		type: PropTypes.string,
		validacaoDados: PropTypes.string,
		value: PropTypes.any,
	};

	static props = {
		autoComplete: false
	}

	handleInputValidado(value, evt) {
		if (this.props.handleInputValidado) {
			this.props.handleInputValidado(value, evt);
		}
	}

	/**
	 * Método executado quando alguma informação é digitada no campo.
	 * Realiza a validação do dado digitado.
	 * @param {*} e 
	 */
	handleInput = e => {
		if (!['radio', 'checkbox'].includes(this.props.type))
			e.preventDefault();

		// Verifica se foi definido o tipo de validação do campos
		if ('validacaoDados' in this.props) {

			// Recupera o valor do campo
			var newValue = this.inputComponent.value;

			if (newValue !== null && newValue !== undefined) {

				// Variavel para verificar se o valor foi validado
				var valorValido = false;

				// Enquanto o valor do campo não for válido, permanece no loop
				while (!valorValido) {

					// Apenas números inteiros positivos
					if (this.props.validacaoDados === 'numeroInteiro' && /^[0-9]+$/.test(newValue)) {
						valorValido = true;
					}

					// Apenas números inteiros positivos e negativos
					else if (this.props.validacaoDados === 'numeroInteiroNegativo' && /^[0-9-]+$/.test(newValue)) {
						valorValido = true;
					}

					// Números reais
					else if (this.props.validacaoDados === 'numeroDecimal') {

						if (newValue === ',' || newValue === '.')
							newValue = '0' + newValue

						newValue = newValue.replace(',', '.')

						if (parseFloat(newValue) === +newValue)
							valorValido = true;

						newValue = newValue.replace('.', ',')
					}

					// Enquanto o valor não for vazio, remove o último caracter e tenta validar novamente
					if (!valorValido) {
						if (newValue.length > 0) {
							newValue = newValue.substring(0, newValue.length - 1);
						}
						else {
							valorValido = true;
						}
					}
				}

				// Atualiza o valor do campo
				this.inputComponent.value = newValue;
			}
		}

		this.handleInputValidado(this.inputComponent.value, e);

		if (this.props.onInput) {
			this.props.onInput(e);
		}
	}

	/**
	 * Calcula escala e valor máximo para campos de entrada de valores numéricos com máscara. Escala deve estar entre 0 e 15, e o valor padrão é 2.
	 * Se não informado, o valor máximo é calculado para garantir que o valor inserido não perca precisão.
	 * 
	 * A partir de testes, foi descoberto que valores muito altos perdem precisão. Por exemplo, 99.99 pode ser arredondado para 99.98 ou para 100.
	 * Foram mapeados quais são os valores máximos para cada escala. Algumas escalas permitem 15 dígitos de precisão, enquanto que outras 16.
	 * 
	 * Para escala 0, seria possível inserir valores maiores mas, para não haver arredondamento, os dígitos menos significativos teriam de ser zero.
	 * Para escalas maiores que 15, o dígito das unidades e os dígitos decimais mais significativos teriam de ser zero.
	 * @param {*} scale número de dígitos decimais (zero para números inteiros)
	 * @param {*} max valor máximo que pode ser digitado
	 */
	calculaPropsIMaskInput = (scale, max) => {
		// valida escala
		if ((!scale) && (scale !== 0)) {
			scale = 2;
		} else if (scale < 0) {
			scale = 0;
		} else if (scale > 15) {
			scale = 15;
		}
		// calcula precisão a partir de valores descobertos com testes empíricos
		let precision = 0;
		switch (scale) {
			case 1:
			case 4:
			case 7:
			case 8:
			case 10:
			case 11:
			case 13:
			case 14:
				precision = 16;
				break;
			default:
				precision = 15;
		}
		// calcula valor máximo
		if (!max) {
			max = Number.POSITIVE_INFINITY;
		}
		max = Math.min(max, ((10 ** (precision - scale)) - 1) + (((10 ** scale) - 1) / (10 ** scale)));
		// retorna valores
		return {
			max: max,
			scale: scale
		};
	}

	/**
	 * Para campos numéricos com formatação, retorna o valor numérico inserido, ou `null` se vazio.
	 */
	getMaskValue = () => {
		// TypeError: Cannot read property 'unmaskedValue' of undefined
		// react-imask.js@2.2.1:209
		try {
			return (this.inputComponent.maskValue === '') ? null : Number(this.inputComponent.maskValue);
		} catch (error) {
			return null;
		}
	}

	/**
	 * Para campos numéricos com formatação, define o valor numérico.
	 */
	setMaskValue = value => {
		this.inputComponent.maskValue = value + '';
	}

	/**
	 * Retorna se o InputCustomizado é um campo somente para seleção de data.
	 */
	isThisDateField = () => {
		return isDateField(this.props.inputType);
	}

	/**
	 * Retorna se o InputCustomizado é um campo para seleção de data e hora.
	 */
	isThisDateTimeField = () => {
		return isDateTimeField(this.props.inputType);
	}

	/**
	 * Retorna se o InputCustomizado é um campo somente para seleção de hora.
	 */
	isThisTimeField = () => {
		return isTimeField(this.props.inputType);
	}

	// /**
	//  * Método executado APÓS a montagem/renderização do componente.
	//  * Adiciona os listeners para exibir e limpar as mensagens ao lado do campo.
	//  */
	// componentDidMount() {}

	/**
	 * Método executado ANTES da montagem/renderização do componente.
	 * Define o tipo de campo que será montado, conforme os parâmetros recebidos.
	 */
	setupField = () => {
		// Remove os parâmetros que não devem ser passados ao campo
		const {
			className
			, dispatch
			, handleInputValidado
			, inputClassName
			, inputType
			, label
			, marginBottom
			, max
			, min
			, newApi
			, onChange
			, onClear
			, onClick
			, spinnerId
			, topClassName
			, topClassStyle
			, validacaoDados
			, labelStyle
			, ...other
		} = this.props

		/*
		 * Estamos usando a funcionalidade de validação que foi adicionada com o HTML5, através da adição da propriedade `required` nos campos
		 * de entrada. Quando um `form` é submetido, o primeiro campo inválido é marcado com uma mensagem em um balão próximo do campo
		 * de entrada inválido. O balão e a mensagem variam de acordo com o navegador. Não é possível alterar a aparência do balão. É possível
		 * implementar a validação na mão para exibir um balão diferente ou qualquer outra coisa em campos inválidos, mas isso é pouca vantagem
		 * para muito trabalho. Não é possível alterar a mensagem de forma global, mas é fácil fazer para cada componente. Exceto com o
		 * React Select, que aparentemente não foi feito para suportar essa funcionalidade com a mesma facilidade. O código a seguir
		 * faz a preparação necessária para internacionalizar essa mensagens nos campos de entrada e componentes React Select.
		 * 
		 * Quando o Select estiver com as opções clearable e searchable desativadas, required deve ser false,
		 * porque neste caso não é montado um componente <input/> e por isso a validação não funciona.
		 * Isto não é um problema porque, com clearable e searchable desativados,
		 * é impossível o Select ficar sem uma opção selecionada.
		 * Ou seja, required ou não, sempre vai haver uma opção selecionada, tornando o required desnecessário.
		 */
		this.required = (this.props.required === 'true') ? true : (this.props.required === 'false' ? false : this.props.required)

		let title = undefined
		let inputProps = undefined
		let onChange_input = undefined
		let onInputChange = undefined
		let onInvalid = undefined

		let onChange_select = this.props.onChange

		if (this.props.required && (this.props.clearable || this.props.searchable)) {

			title = this.props.type === 'email' ? getStrings().emailValidationMessage : getStrings().defaultRequiredInputMessage

			inputProps = {
				onInvalid: event => {
					event.target.setCustomValidity(this.required ? getStrings().defaultRequiredInputMessage : '')
				},
				id: this.props.id
			}

			onChange_input = event => {
				event.target.setCustomValidity('')
				event.target.checkValidity()
			}

			onChange_select = value => {
				if (this.props.onChange) {
					this.props.onChange(value)
				}
				// Timeout necessário, senão o form não deixa realizar o onSubmit notificando para preencher o Select
				setTimeout(() => { this.required = ((!this.props.required) ? false : (!this.inputComponent.isValueSelected(value))) }, 0);
			}

			onInputChange = value => {
				let input = this.props.inputType === 'selectAsync'
					? this.inputComponent.selectField.select.input.input
					: this.inputComponent.selectField.input.input
				input.setCustomValidity(
					value ? '' : this.required ? getStrings().defaultRequiredInputMessage :
						this.inputComponent.isValueSelected_props() ? '' : getStrings().defaultRequiredInputMessage)
				return value
			}

			onInvalid = event => {
				event.target.setCustomValidity(this.props.type === 'email' ? getStrings().emailValidationMessage : getStrings().defaultRequiredInputMessage)
			};
		}
		// Fim do código de validação

		// Input Text
		if (!this.props.hasOwnProperty('inputType') || this.props.inputType === 'text') {
			if (['checkbox', 'radio'].includes(this.props.type)) {
				this.inputField = <input
					{...other}
					className={`Lighter${inputClassName ? ` ${inputClassName}` : ''}`}
					ref={input => this.inputComponent = input}
					title={title}
					onInvalid={onInvalid}
					onChange={onChange_input}
					onClick={this.handleInput}
				/>
			}
			else if (this.props.type === 'range') {
				this.inputField = <>
					<div className={`slidecontainer` }>
						<input
							{...other}
							max={this.props.max}
							min={this.props.min}
							className={`Lighter ${inputClassName || ''}`}
							ref={input => this.inputComponent = input}
							onInput={this.handleInput}
							title={title}
							onInvalid={onInvalid}
							onChange={onChange_input}
							onClick={this.props.onClick}
						/>
						<label style={{marginLeft: '5px'}} children={this.props.defaultValue}/>
					</div>
				</>
			}
			else
				this.inputField = <input
					{...other}
					className={`Lighter${inputClassName ? ` ${inputClassName}` : ''}`}
					ref={input => this.inputComponent = input}
					onInput={this.handleInput}
					title={title}
					onInvalid={onInvalid}
					onChange={newApi ? this.props.onChange : onChange_input}
					onClick={(e) => { this.selectIfNotSelectedOnClick(e); this.props.onClick && this.props.onClick(e); }}
					onBlur={(e) => { this.markNotSelectOnBlur(e); this.props.onBlur && this.props.onBlur(e); }}
				/>
		}

		// Text Area
		if (this.props.inputType === 'textarea') {
			this.inputField = <textarea {...other} className='Lighter' ref={input => this.inputComponent = input}
				onInput={this.handleInput} title={title} onInvalid={onInvalid} onChange={onChange_input}
				onClick={(e) => { this.selectIfNotSelectedOnClick(e); this.props.onClick && this.props.onClick(e); }}
				onBlur={(e) => { this.markNotSelectOnBlur(e); this.props.onBlur && this.props.onBlur(e); }} />
		}

		// Spinner
		else if (this.props.inputType === 'number') {
			if (newApi) {
				let valor = nullOrUndefined(other.value)
					? ''
					: typeof other.value === 'string'
						? other.value
						: typeof other.value === 'number'
							? other.value.toFixed(2).replace('.', ',')
							: other.value;
				
				const valorArray = `${nullOrUndefined(other.value) ? '' : other.value}`.split("")
					.filter(c => {
						const eNumero = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '-'].includes(c);

						return eNumero;
					})
					.map(c => c === ',' ? '.' : c);
				const [valorInteiro, valorDecimal] = valorArray.toString().replace(/,/g, '').split('.');
				const scale = this.props.scale || 2;

				const inteiroArray = valorInteiro ? valorInteiro.split("") : [];

				valor = (['numeroInteiro', 'numeroInteiroNegativo'].includes(validacaoDados)
						? (valorInteiro || '')
						: (valorInteiro || '').split('').map((c, index) => index > 0 && inteiroArray[index - 1] !== '-' && (inteiroArray.length - index) % 3 === 0 ? ` ${c}` : c))
					.toString()
					.replace(/,/g, '') + (valorDecimal !== undefined ? `,${valorDecimal}` : '');
	
				this.inputField = <input
					{...other}
					className={`Lighter ${this.props.inputClassName || ''}`}
					type={'text'}
					inputMode={'numeric'}
					step={'1'}
					min={this.props.min}
					title={title}
					onInvalid={undefined}
					scale={scale}
					onChange={(e) => {
						if (e.target.value !== '') {
							const valorArray = e.target.value.split('')
								.filter(c => {
									const eNumero = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '-'].includes(c);

									return eNumero;
								})
								.map(c => {
									const caractere = c === ','
										? '.'
										: c;

									return caractere;
								});
							valor = valorArray.toString().replace(/,/g, '').replace(/\./g, ',');
							valor = validacaoDados === 'numeroInteiroNegativo'
								? valor[0] + valor.slice(1).replace(/-/g, '')
								: valor.replace(/-/g, '');

							const valorFloat = parseFloat(valor.replace(',', '.'));
	
							if (nullOrUndefined(max) || (valorFloat <= max)) {
								e.target.value = valor;

								onChange(e);
							}
	
							return;
						}

						onChange(e);
					}}
					onBlur={(e) => {
						if (!nullOrUndefined(other.value)) {
							let val = e.target.value;

							if (val !== '') {
								const valorFloat = ['number', 'string'].includes(typeof other.value)
									? parseFloat(`${other.value}`.replace(',', '.'))
									: other.value;
								val = (['numeroInteiro', 'numeroInteiroNegativo'].includes(validacaoDados)
									? valorFloat.toString()
									: valorFloat.toFixed(2));
								val = val.replace('.', ',');
							
								if ((nullOrUndefined(max) || (valorFloat <= max)) && (nullOrUndefined(min) || (valorFloat >= min))) {
									e.target.value = val;

									this.props.onBlur && this.props.onBlur(e);
								}

								return;
							}

							e.target.value = val;

							this.props.onBlur && this.props.onBlur(e);
						}
					}}
					value={valor}
					onClick={this.props.onClick}
					onKeyDown={this.props.onKeyDown}
				/>;
			}
			else {
				// if (defaultValue === ) {

				// }
				const value = other.defaultValue ? {value: other.defaultValue} : {};

				this.inputField = <input
					{...other}
					{...value}
					className={`Lighter ${this.props.inputClassName || ''}`}
					ref={input => this.inputComponent = input}
					onInput={(e) => {
						if (this.inputComponent.value && this.inputComponent.max) {
							if (this.inputComponent.value.length > this.inputComponent.max.length) {
								this.inputComponent.value = this.inputComponent.value.slice(0, this.inputComponent.max.length);
							}
							else if (this.inputComponent.valueAsNumber > parseInt(this.inputComponent.max)) {
								this.inputComponent.value = this.inputComponent.max;
							}
						}
						this.handleInput(e);
					}}
					type={'number'}
					inputMode={'numeric'}
					step={'1'}
					min={this.props.min}
					max={this.props.max}
					title={title}
					onInvalid={onInvalid}
					onChange={onChange_input}
				/>
			}
		}

		// Multi Select
		else if (this.props.inputType === 'multiSelect') {
			const placeholder = 'placeholder' in this.props ? this.props.placeholder : getStrings().selectMultiPlaceholder;

			this.inputField = <MultiSelectField
				{...other}
				className='Lighter'
				ref={input => this.inputComponent = getReduxWrappedComponent(input)}
				options={this.props.options}
				value={this.props.value}
				placeholder={placeholder}
				isMulti
				required={this.required}
				searchable={this.props.searchable}
				onChange={onChange_select}
				inputProps={inputProps}
				onInputChange={onInputChange}
				innerFunctions={this.props.innerFunctions}
			/>
		}

		// Single Select
		else if (this.props.inputType === 'singleSelect') {
			const placeholder = 'placeholder' in this.props ? this.props.placeholder : getStrings().selectSinglePlaceholder;

			this.inputField = <MultiSelectField
				{...other}
				className={`${this.props.inputClassName || ''}`}
				_className={this.props.className}
				ref={input => this.inputComponent = getReduxWrappedComponent(input)}
				options={this.props.options}
				value={this.props.value}
				placeholder={placeholder}
				required={this.required}
				clearable={this.props.clearable}
				searchable={this.props.searchable}
				onChange={onChange_select}
				arrowRenderer={this.props.arrowRenderer}
				wrapperStyle={this.props.wrapperStyle}
				inputProps={inputProps}
				onInputChange={onInputChange}
				innerFunctions={this.props.innerFunctions}
			/>
		}

		// Select Async
		else if (this.props.inputType === 'selectAsync') {
			const placeholder = 'placeholder' in this.props ? this.props.placeholder : getStrings().selectSinglePlaceholder;

			this.inputField = <SelectFieldAsync
				{...other}
				className={`Lighter ${this.props.inputClassName || ''}`}
				ref={input => this.inputComponent = input}
				placeholder={placeholder}
				required={this.required}
				loadOptions={this.props.options}
				onChange={onChange_select}
				inputProps={inputProps}
				onInputChange={onInputChange}
			/>
		}

		// Numérico com formatação
		else if (this.props.inputType === 'masked') {
			if (newApi) {
				const scale = this.props.scale || 2;
				const max = this.props.max || 99999.99;
				const [vInteiro, vDecimal] = parseFloat(`${other.value || 0}`.replace(',', '.').replace(/ /g, '')).toFixed(scale).split(".");
				const value = vInteiro.split("").reverse().map((c, index) => ((index + 1) % 3 === 0) ? ` ${c}` : c).reverse().toString().replace(/,/g, '') + ',' + vDecimal;

				const onChange = (e) => {
					e.target.selectionStart = e.target.selectionEnd = e.target.value.length;
					const textoNum = `${e.target.value || 0}`.replace(',', '.').replace(/ /g, '');
					const valorNumerico = parseFloat(textoNum);
					
					if (e.target.value.length < value.length) {
						const valorTexto = valorNumerico.toFixed(scale + 1).replace('.', ',');
						const virgulaIndex = valorTexto.indexOf(',');
		
						const valorInteiro = valorTexto.replace(',', '').substr(0, virgulaIndex - 1);
						const valorDecimal = valorTexto.replace(',', '').substr(virgulaIndex - 1);
						const valor = parseFloat(`${valorInteiro}.${valorDecimal}`).toFixed(scale).replace('.', ',');
	
						(this.props.onChange || (() => {}))(e, valor);
						return;
					}

					if (e.target.value.length > value.length) {
						const valorTexto = valorNumerico.toFixed(scale + 1).replace('.', ',');
						const virgulaIndex = valorTexto.indexOf(',');
		
						const valorInteiro = valorTexto.replace(',', '').substr(0, virgulaIndex + 1);
						const valorDecimal = valorTexto.replace(',', '').substr(virgulaIndex + 1);
						const valor = parseFloat(`${valorInteiro}.${valorDecimal}`).toFixed(scale).replace('.', ',');

						if (!nullOrUndefined(max) && parseFloat(`${valorInteiro}.${valorDecimal}`) > max) {
							(this.props.onChange || (() => {}))(e, value);
							return;
						}

						(this.props.onChange || (() => {}))(e, valor);
						return;
					}
				};

				const onClick = (e) => {
					e.target.selectionStart = e.target.selectionEnd = e.target.value.length;
					(this.props.onClick || (() => {}))(e, e.target.value);
				};

				const onKeyUp = (e) => {
					e.target.selectionStart = e.target.selectionEnd = e.target.value.length;
					(this.props.onKeyUp || (() => {}))(e, e.target.value);
				};

				const onKeyDown = (e) => {
					e.target.selectionStart = e.target.selectionEnd = e.target.value.length;
					(this.props.onKeyDown || (() => {}))(e, e.target.value);
				};

				this.inputField = <input
					{...other}
					ref={ (ref) => this.inputComponent = ref }
					value={value}
					className={`maskedInputStyle ${className || ''} ${this.props.inputClassName || ''}`}
					onChange={onChange}
          onClick={(e) => { this.selectIfNotSelectedOnClick(e); onClick(e); }}
					onBlur={(e) => { this.markNotSelectOnBlur(e); this.props.onBlur && this.props.onBlur(e); }}
					onKeyUp={onKeyUp}
					onKeyDown={onKeyDown}
				/>
			}
			else {
				const props = this.calculaPropsIMaskInput(this.props.scale, this.props.max);
				const customProps = typeof this.custom === 'object' ? this.custom : {};
				const value = other.defaultValue ? {value: other.defaultValue + ''} : {};
	
				this.inputField = <IMaskInput
					{...other}
					ref={ref => {
						if (ref !== null) {
							this.inputComponent = ref;
						}
					}}
					className={`maskedInputStyle ${className || ''} ${this.props.inputClassName || ''}`}
					mask={Number}
					unmask={true}
					signed={true}
					thousandsSeparator={'\u2009'}
					// Se for passado false, é false; se for passado true, null, undefined ou não for passado, é true
					padFractionalZeros={this.props.padFractionalZeros !== false}
					min={this.props.min || 0}
					max={props.max}
					{...value}
					onAccept={this.props.onChange}
					title={title}
					onInvalid={onInvalid}
					onChange={onChange_input}
					onInput={this.handleInput}
					scale={props.scale}
					tabIndex={customProps.tabIndex}
					onClick={(e) => { this.selectIfNotSelectedOnClick(e); this.props.onClick && this.props.onClick(e); }}
					onBlur={(e) => { this.markNotSelectOnBlur(e); this.props.onBlur && this.props.onBlur(e); }}
				/>
			}
		}

		// Com máscara para CPF
		else if (this.props.inputType === 'maskedCPF') {

			this.inputField = <IMaskInput
				{...other}
				ref={ref => {
					if (ref !== null) {
						this.inputComponent = ref;
					}
				}}
				className={'maskedInputStyle ' + (className || '')}
				mask='000.000.000-00'
				unmask={true}
				onAccept={this.props.onChange}
				title={title}
				onInvalid={onInvalid}
				onChange={onChange_input}
				onInput={this.handleInput}
			/>
		}

		// Com máscara para telefone
		else if (this.props.inputType === 'maskedPhone') {

			this.inputField = <IMaskInput
				{...other}
				ref={ref => {
					if (ref !== null) {
						this.inputComponent = ref;
					}
				}}
				className={`maskedInputStyle ${className || ''} ${this.props.inputClassName || ''}`}
				mask='(00) 0 0000-0000'
				unmask={true}
				onAccept={this.props.onChange}
				title={title}
				onInvalid={onInvalid}
				onChange={onChange_input}
				onInput={this.handleInput}
			/>
		}

		// Data e hora
		else if (this.isThisDateTimeField()) {
			const dateField = this.isThisDateField()
			const timeField = this.isThisTimeField()

			if (this.props.newApi) {
				this.inputField = <Datetime
					{...other}
					dateFormat={dateField}
					timeFormat={timeField}
					onChange={this.props.onChange}
					locale={this.props.locale}
					closeOnSelect={true}
					onBlur={this.props.onBlur}
					onFocus={this.props.onFocus}
					inputProps={{
						id: this.props.id,
						placeholder: this.props.placeholder,
					}}
				/>;
			}
			else {
				this.inputField = <Datetime
					dateFormat={this.isThisDateField()}
					timeFormat={this.isThisTimeField()}
					value={this.props.defaultValue}
					inputProps={{
						// readOnly: true,
						id: this.props.id,
						placeholder: this.props.placeholder
					}}
					locale={this.props.locale}
					closeOnSelect={true}
					onBlur={this.props.onBlur}
					onChange={this.props.onChange}
					onFocus={this.props.onFocus}
				/>;
			}
		}
	}

	/**
	 * Método que monta/renderiza o componente.
	 */
	render() {

		this.setupField();
		const { topClassStyle, labelStyle, ...others } = this.props

		let labelComponent = null;
		const required = others.newApi
			? (typeof others.required === 'string')
				? JSON.parse(others.required)
				: others.required
			: false;

		if ('label' in others) {
			labelComponent = (
				<div style={labelStyle} className={`${others.type === 'range' ? '' : 'pure-u-1-1'}${others.type === 'checkbox' ? ' switch-label' : ''}`} >
					<label className='Lighter' htmlFor={others.id}  >{others.label + (required ? '*' : '')}</label>
				</div>
			);
		}

		// let marginBottom = others.marginBottom ? others.marginBottom : '5px';

		// Configuração do spinner dentro do input para filtros
		let spinner = null;
		if (others.spinnerId) {
			spinner = <div style={{
				position: 'absolute',
				top: '0.5em',
				right: (others.inputType === 'multiSelect') ? '3.5em' : this.isThisDateTimeField() ? '3.5em' : '2em'
			}} >{loadSpinnerInput(others.spinnerId)}</div>
		}

		// Configuração de um botão de limpar para data e hora
		let clear = null;
		if (this.isThisDateTimeField()) {
			clear = <div
				onClick={() => { if (others.onClear) others.onClear() }}
				style={{
					position: 'absolute',
					fontFamily: '\'Open Sans\', sans-serif',
					fontSize: '1.125em',
					fontWeight: '100',
					top: '0.3em',
					right: '0.6em',
					cursor: 'pointer'
				}} >×</div>
		}

		return ('radio' === others.type)
			
			? <div className={`${others.type}-form-cell ${others.topClassName || ''}`}>
				{/* Campo do checkbox */}
				{this.inputField}
				{/* Label */}
				{labelComponent}
			</div>
			: 'checkbox' === others.type

			? <div className={`${others.type}-form-cell ${others.topClassName || ''}`}>
				{/* Label para customizar a aparência do checkBox (multiplataforma) */}
				<label
					className='switch'
					tabIndex={others.tabIndex}
					onKeyDown={keyHandler}
					htmlFor={others.id}
					onFocus={others.focusHandler}
					onMouseOver={others.focusHandler}
					onBlur={others.focusHandler}
					onMouseOut={others.focusHandler}
				>
					{/* Campo do checkbox */}
					{this.inputField}
					<span className='slider round' />
				</label>
				{/* Label */}
				{labelComponent}
			</div>
			
			: <div className={`${others.topClassName || ''}`} style={topClassStyle} >
				<div className={`${others.type === 'range' ? 'flex-row' : 'pure-g'}`} >

					{/* Label do componente */}
					{labelComponent}

					{/* Campo do componente */}
					<div className='pure-u-1-1' >
						{/* Para posicionar o spinner e o clear */}
						<div style={{ position: 'relative' }} >
							{this.inputField}
							{spinner}
							{clear}
						</div>
					</div>
				</div>
			</div>;
	}
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
	...state.idiomaReducer,
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, null, null, { forwardRef: true })(InputCustomizado);
