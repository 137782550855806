import React from "react";
import { InputContainer } from "./InputContainer";
import { FormText } from "./FormText";
import { InputLabel } from "./InputLabel";
import { EmailInput } from "./Input";

const Email = React.forwardRef((props, ref) => {
  return (
    <InputContainer>
      <InputLabel> Email *</InputLabel>
        <EmailInput ref={ref} required={true} placeholder={"email@dominio.com *"} />
      <FormText>Seu email nunca será compartilhado.</FormText>
    </InputContainer>
  );
});

export default Email;
