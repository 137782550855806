import React from 'react';
import { connect } from 'react-redux';

import { formatNumber } from "../../utils/NumberUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { listToFragment } from "../../utils/ScreenUtils"

// Chaves das colunas
import {
    PAGAMENTO_VENDA_HORA,
    ITEM_PAGAMENTO_VENDA_DIFERENCA,
    ITEM_PAGAMENTO_VENDA_PORCENTAGEM_DIFERENCA,
    ITEM_PAGAMENTO_VENDA_PORCENTAGEM_MARGEM,
    ITEM_PAGAMENTO_VENDA_PRODUTO,
    ITEM_PAGAMENTO_VENDA_QUANTIDADE,
    ITEM_PAGAMENTO_VENDA_VALOR_CUSTO_TOTAL,
    ITEM_PAGAMENTO_VENDA_VALOR_TOTAL
} from './RelatorioBox';

/**
 * Classe que realiza a montagem das linhas da tabela.
 */
class LucratividadeQuebraTableRow extends React.Component {

    /**
     * Monta cada célula de acordo com a chave da coluna.
     */
    cellData = column => {

        if (!this.props.dados.dadosMap) {
            return null;
        }

        // Se não for do nível mais inferior, trata a linha como um rodapé (que poderá aparecer no meio da tabela)
        let font = (this.props.level ? 'Bold' : 'Lighter');

        switch (column) {
            case PAGAMENTO_VENDA_HORA:
                return <td className={font} width='1%' >{this.props.dadosMap[PAGAMENTO_VENDA_HORA]}</td>;
            case ITEM_PAGAMENTO_VENDA_PRODUTO:
                if (this.props.level) {
                    return <td className={font} width='1%' >{getStrings().productAmountTemplate(this.props.dadosMap[ITEM_PAGAMENTO_VENDA_PRODUTO])}</td>;
                }
                return <td className={font} width='1%' >{this.props.dadosMap[ITEM_PAGAMENTO_VENDA_PRODUTO]}</td>;
            case ITEM_PAGAMENTO_VENDA_QUANTIDADE:
                return <td className={font + ' NumberAlign'} width='1%' >{formatNumber(this.props.dadosMap[ITEM_PAGAMENTO_VENDA_QUANTIDADE], 2)}</td>;
            case ITEM_PAGAMENTO_VENDA_VALOR_TOTAL:
                return <td className={font + ' NumberAlign'} width='1%' >{formatNumber(this.props.dadosMap[ITEM_PAGAMENTO_VENDA_VALOR_TOTAL], 2)}</td>;
            case ITEM_PAGAMENTO_VENDA_VALOR_CUSTO_TOTAL:
                return <td className={font + ' NumberAlign'} width='1%' >{formatNumber(this.props.dadosMap[ITEM_PAGAMENTO_VENDA_VALOR_CUSTO_TOTAL], 2)}</td>;
            case ITEM_PAGAMENTO_VENDA_DIFERENCA:
                return <td className={font + ' NumberAlign'} width='1%' >{formatNumber(this.props.dadosMap[ITEM_PAGAMENTO_VENDA_DIFERENCA], 2)}</td>;
            case ITEM_PAGAMENTO_VENDA_PORCENTAGEM_DIFERENCA:
                return <td className={font + ' NumberAlign'} width='1%' >{formatNumber(this.props.dadosMap[ITEM_PAGAMENTO_VENDA_PORCENTAGEM_DIFERENCA], 2)}</td>;
            case ITEM_PAGAMENTO_VENDA_PORCENTAGEM_MARGEM:
                return <td className={font + ' NumberAlign'} width='1%' >{formatNumber(this.props.dadosMap[ITEM_PAGAMENTO_VENDA_PORCENTAGEM_MARGEM], 2)}</td>;
            default:
                return null;
        }
    }

    /**
     * Método que executa a montagem do componente.
     */
    render() {

        // Se não recebeu as colunas por parâmetro, não pode montar a linha da tabela
        if (!this.props.columnOrder) {
            return null;
        }

        return <tr>
            {/* Dados do relatório */}
            {listToFragment(this.props.columnOrder.map(column => this.cellData(column)))}
        </tr>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {
    return {
        ...state.relatorioReducer
    };
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(LucratividadeQuebraTableRow);
