import genericAction from "./genericAction";

const actionTypes = {
    UPDATE_COMBO: "UPDATE:COMBO",
    DIMINUIR_QUANTIDADE_COMBO: "DIMINUIR_QUANTIDADE:COMBO",
    AUMENTAR_QUANTIDADE_CANDIDATO_COMBO: "AUMENTAR_QUANTIDADE_CANDIDATO:COMBO",
    DIMINUIR_QUANTIDADE_CANDIDATO_COMBO: "DIMINUIR_QUANTIDADE_CANDIDATO:COMBO",
    AUMENTAR_QUANTIDADE_COMBO: "AUMENTAR_QUANTIDADE:COMBO",
    UPDATE_CANDIDATO_COMBO: "UPDATE_CANDIDATO:COMBO",
    ELECT_ALL_CANDIDATES_COMBO: "ELECT_ALL_CANDIDATES:COMBO",
    ELECT_CANDIDATE_COMBO: "ELECT_CANDIDATE:COMBO",
    REMOVE_COMBO: "REMOVE:COMBO",
    ADD_TO_LIST_COMBO: "ADD_TO_LIST:COMBO",
};

export default actionTypes;

export const atualizarComboAction = (payload) => dispatch => {
    dispatch(genericAction(payload, actionTypes.UPDATE_COMBO));
}

export const atualizarComboCandidatoAction = (payload) => dispatch => {
    dispatch(genericAction(payload, actionTypes.UPDATE_CANDIDATO_COMBO));
}

export const diminuirQuantidadeComboAction = (payload) => dispatch => {
    dispatch(genericAction(payload, actionTypes.DIMINUIR_QUANTIDADE_COMBO));
}

export const aumentarQuantidadeComboAction = (payload) => dispatch => {
    dispatch(genericAction(payload, actionTypes.AUMENTAR_QUANTIDADE_COMBO));
}

export const diminuirQuantidadeComboCandidatoAction = (payload) => dispatch => {
    dispatch(genericAction(payload, actionTypes.DIMINUIR_QUANTIDADE_CANDIDATO_COMBO));
}

export const aumentarQuantidadeComboCandidatoAction = (payload) => dispatch => {
    dispatch(genericAction(payload, actionTypes.AUMENTAR_QUANTIDADE_CANDIDATO_COMBO));
}

export const elegerTodosCandidatosComboAction = () => dispatch => {
    dispatch(genericAction(null, actionTypes.ELECT_ALL_CANDIDATES_COMBO));
};

export const elegerCandidatoComboAction = (payload) => dispatch => {
    dispatch(genericAction(payload, actionTypes.ELECT_CANDIDATE_COMBO));
};

export const removerComboAction = (payload) => dispatch => {
    dispatch(genericAction(payload, actionTypes.REMOVE_COMBO));
};

export const adicionarAListaDeCombosAction = (payload) => dispatch => {
    dispatch(genericAction(payload, actionTypes.ADD_TO_LIST_COMBO));
};