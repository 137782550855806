import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { formatI18nString } from "../../../utils/StringUtils"

import BarraAcoesPagamentoVendas from "./BarraAcoesPagamentoVendas"

import { STATE_CONTROLE_VENDA_MANUTENCAO_VENDA } from "../../../store/reducers/controleVenda/controleVendaReducer"

import HelpParagraph from "../../UI/HelpParagraph/HelpParagraph"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"
import TelaPagamentoVendasContent from "./TelaPagamentoVendasContent"

class TelaPagamentoVendas extends React.Component {

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("TelaPagamentoVendas render")

    if (this.props.state === STATE_CONTROLE_VENDA_MANUTENCAO_VENDA) {

      return <TelaPagamentoVendasContent />

    } else {

      return <>

        <WidthAwareDiv>
          <HelpParagraph
            children={getStrings()
              .salePaymentHelp(this.props.ramoEmpresa)
              .map(string => formatI18nString(string))}
          />
        </WidthAwareDiv>

        <BarraAcoesPagamentoVendas esconderBarraAcoes={this.props.esconderBarraAcoes} />

        <TelaPagamentoVendasContent />
      </>
    }
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  state: state.controleVendaReducer.state,
  ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa,
  ...state.idiomaReducer,
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(TelaPagamentoVendas)
