import React from "react";
import styled from "styled-components";
import { TitleSub, Paragraph, ConteudoSection } from ".";
import { PATH_IMAGENS_FOTOS, PATH_IMAGENS_PRINTS } from "../utils/resources";
import { ImagensContainer, ImgRounded, PrintSmartphone } from "./Containers";

const GerenciamentoEntregas = React.forwardRef((props, ref) => {
  return (
    <ConteudoSection ref={ref}>
      <TitleSub primary>Gerenciamento das Entregas</TitleSub>
      <Paragraph>Bairros, melhor rota, previsão de entrega, pedidos atrasados, pagamento de motoboys...</Paragraph>
      <ImagensContainer height={"310px"}>
        <Print src={PATH_IMAGENS_PRINTS + "atendimento1.png"} alt={"atendimento"} />
        <Print src={PATH_IMAGENS_PRINTS + "entrega1.png"} alt={"entrega"} />
        <ImgRoundLeft src={PATH_IMAGENS_FOTOS + "entrega2.png"} alt={"Pessoa usando mobi"} />
        <ImgRoundRight src={PATH_IMAGENS_FOTOS + "entrega3.png"} alt={"Tela instagram"} />
      </ImagensContainer>
    </ConteudoSection>
  );
});

const Print = styled(PrintSmartphone)`
  position: absolute;
  &:nth-child(1) {
    top: 0;
    right: 50px;

    @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
      z-index: 9;
    }
  }

  &:nth-child(2) {
    left: 50px;
    top: 40px;
  }

  z-index: 10;
`;

const ImgRoundRight = styled(ImgRounded)`
  right: -25px;
  bottom: 0;
  z-index: 11;
  @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    z-index: 10;
  }
`;

const ImgRoundLeft = styled(ImgRounded)`
  left: -25px;
  top: 0;
  z-index: 11;
`;

export default GerenciamentoEntregas;
