import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { formatI18nString } from "../../../utils/StringUtils"

import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"
import * as manutencaoVendaActions from "../../../store/actions/controleVenda/manutencao/manutencaoVendaAction"

import BarraAcoesVenda from "../barraAcao/BarraAcoesVenda.js"
import BuildCellFromVenda from "./BuildCellFromVenda.js"
import HelpParagraph from "../../UI/HelpParagraph/HelpParagraph"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

class GridVendaFromOrigem extends React.Component {

  /**
   * Método executado APÓS a montagem do componente.
   * Realiza o carregamento dos cadastros na tela.
   */
  componentDidMount() {

    // Limpa as variáveis de estado da tela de Manutenção Venda
    this.props.limpaManutencaoVenda()

    this.props.loadVendaListAbertaFromOrigemVenda()
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {

    // Monta as celulas que irão compor a grid
    var vendaGrid = this.props.vendaList.filter(venda => venda._links).map(venda =>
      <BuildCellFromVenda key={venda._links.self.href} venda={venda} />
    )

    return <>

      <WidthAwareDiv>
        <HelpParagraph children={getStrings().saleHelp.map(help => formatI18nString(help))} />
      </WidthAwareDiv>

      <BarraAcoesVenda />

      <div className="pure-g">
        {vendaGrid}
      </div>
    </>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  ...state.idiomaReducer,
  origemVenda: state.controleVendaReducer.origemVendaList[0],
  vendaList: state.controleVendaReducer.origemVendaList[0].vendaList || []
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  limpaManutencaoVenda: () => dispatch(manutencaoVendaActions.limpaManutencaoVenda()),
  loadVendaListAbertaFromOrigemVenda: () => dispatch(controleVendaActions.loadVendaListAbertaFromOrigemVenda()),
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(GridVendaFromOrigem)
