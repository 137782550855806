import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { getURIFromEntity } from "../../utils/URIUtils"

import * as appActions from '../../store/actions/appAction';

import BuildCellFromCalledSaleSource from './BuildCellFromCalledSaleSource';

/**
 * Classe que monta a dialog para usuário visualizar chamadas a garçom feitas a partir de leituras de códigos impressos em origens de venda.
 */
class DialogOrigemVendaChamada extends React.Component {

    componentDidUpdate() {
        if (!this.props.calledOrigemVendaList.length) {
            this.props.appDialogHide();
        }
    }

    /**
     * Método que monta o componente.
     */
    render() {
        log('DialogOrigemVendaChamada render');

        // Monta o componente
        return <>
            <div className='dialog-scroll-pane'>
                {/* origens */}
                <div className='table' >
                    {this.props.calledOrigemVendaList.map(calledOrigemVenda => <BuildCellFromCalledSaleSource
                        key={getURIFromEntity(calledOrigemVenda.origemVenda)}
                        notificacao={calledOrigemVenda}
                    />)}
                </div>
            </div>
            {/* botão */}
            <div className='dialog-horizontal-button-grid' style={{gridGap: 0}}>
                <button ref={ref => this.confirmButton = ref} type='button'
                    onClick={() => {
                        this.props.appDialogHide();
                    }} >{getStrings().closeFechar}</button>
            </div>
        </>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    ...state.idiomaReducer,
    calledOrigemVendaList: state.controleVendaReducer.calledOrigemVendaList,
    dialogShow: state.appReducer.dialogShow
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    appDialogHide: () => dispatch(appActions.appDialogHide())
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DialogOrigemVendaChamada);
