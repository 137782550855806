import React from "react"
import { connect } from "react-redux"

import { updateActiveMenuItem } from "../../../utils/ScreenUtils"
import { urlDatabase } from "../../../utils/SecureConnectionUtils"
import { getPageNames } from "../../../utils/siteUtils/siteUtils"

import { reduxStateType } from "../../../utils/reduxUtils/reduxStateType"

import CadastroBox from "./CadastroBox"
import MobiAppBoxForm from "../form/MobiAppForm"
import MobiAppBoxTable from "../table/MobiAppTable"

export type MobiAppBoxType = {
  urlDataBase: string,
}

class MobiAppBox_ extends React.Component<MobiAppBoxType> {

  componentDidMount() {
    updateActiveMenuItem("menuItemMobiApp", "menuItemCadastro");
  }
  
  render() {
    const urlDataBase = urlDatabase + getPageNames().cargoSelecionado.MOBI_APPS;

    return <CadastroBox
      urlDataBase={urlDataBase}

      cadastroTable={MobiAppBoxTable}
      cadastroForm={MobiAppBoxForm}

      needFormEntities={true}
      needFormUpdate={true}
    />;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {reduxStateType} state 
 */
function mapStateToProps(state: reduxStateType) {
  const props = {
    ...state.cadastroReducer,
  };

  return props;
}

export const MobiAppBox = connect(mapStateToProps)(MobiAppBox_);
export default MobiAppBox;