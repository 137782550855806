import React from 'react';
import { connect } from 'react-redux';
import { cadastroBuildJSONFromOptions, cadastroBuildOptionsFromCadastros, cadastroBuildOptionsFromEnum } from '../../../utils/SelectUtils';

import { getAppEmpresa } from '../../../utils/AppUtils';
import { equalsCoerced } from "../../../utils/ComparatorsUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { cadastroRecuperaValueFromOptions } from "../../../utils/SelectUtils"
import { entityURIEqualsURI, getURIFromEntity } from "../../../utils/URIUtils"

import * as actions from '../../../store/actions/cadastroAction';

import CadastroForm from './CadastroForm';
import InputCustomizado from '../../UI/Input/InputCustomizado';
import AtivoInputDefault from '../cadastroInputDefault/AtivoInputDefault.js'
import CodigoInputDefault from '../cadastroInputDefault/CodigoInputDefault.js'
import NomeInputDefault from '../cadastroInputDefault/NomeInputDefault.js'
import GrupoProdutoTamanhoForm from './GrupoProdutoTamanhoForm';

/**
 * Classe que repassa para o CadastroForm as informações para a montagem do formulário para o cadastro dos Grupos de Produto.
 */
class GrupoProdutoForm extends React.Component {
    render() {
        log('GrupoProdutoForm render');
        return <CadastroForm {...this.props} formModel={GrupoProdutoValues}
            registerSaveHelp={getStrings().productGroupHelp} registerUpdateHelp={getStrings().productGroupHelp} />;
    }
}

/**
 * Classe que realiza a montagem do formulário para o cadastro dos Grupos de Produto.
 */
class GrupoProdutoValues extends React.Component {

    constructor(props) {
        super(props);

        const possuiTamanhos = props.cadastroDados.grupoProdutoTamanhoList?.length ? true : false;

        this.possuiTamanhos = possuiTamanhos;

        const mapGrupoProdutoTamanho = {};

        (props.cadastroDados.grupoProdutoTamanhoList || []).forEach(grupoProdutoTamanho => {
            mapGrupoProdutoTamanho[getURIFromEntity(grupoProdutoTamanho.tamanho)] = {...grupoProdutoTamanho, ativo: true};
        });

        const precoProdutoCombinado = props.cadastroDados.precoProdutoCombinado
            ? cadastroBuildOptionsFromEnum(false, props.cadastroDados.precoProdutoCombinado, string => getStrings().combinedProductPriceEnumToString(string))
            : null;

        this.state = {
            multiplosTamanhosAtivo: possuiTamanhos,
            mapGrupoProdutoTamanho: mapGrupoProdutoTamanho,
            precoProdutoCombinado: precoProdutoCombinado,
        }
    }

    enabled = true;
    ismounted = false;

    /**
     * Retorna se os campos obrigatórios (required) foram todos preenchidos
     */
    checkRequired = () => {
        log('GrupoProdutoValues checkRequired');
        return this.codigo && this.nome && (this.codigo.getValue() !== '') && (this.nome.getValue() !== '');
    }

    /**
     * Recupera os dados do formulário.
     */
    getFormData = () => {
        log('GrupoProdutoValues getFormData');

        const formData = {
            empresaSet: [getAppEmpresa()],
            enabled: this.enabled.getValue(),
            codigo: this.codigo.getValue(),
            nome: this.nome.getValue(),

            //grupoPai: cadastroBuildJSONFromOptions(false, this.grupoPai.inputComponent.getValue()),

            // subgrupoList: cadastroBuildJSONFromOptions(true, this.subgrupoList.inputComponent.getValue()),

            impressora: cadastroBuildJSONFromOptions(false, this.impressora.inputComponent.getValue()),

            //grupoPaiOption: this.grupoPai.inputComponent.getValue(),

            // subgrupoListOption: this.subgrupoList.inputComponent.getValue(),

            impressoraOption: this.impressora.inputComponent.getValue(),

            grupoProdutoTamanhoList: this.state.multiplosTamanhosAtivo
                ? Object.values(this.state.mapGrupoProdutoTamanho)
                    .filter(grupoProdutoTamanho => grupoProdutoTamanho.ativo)
                    .map(grupoProdutoTamanho => {
                        const grupoProdutoTamanhoFormatado = {
                            id: grupoProdutoTamanho.id,
                            tamanho: getURIFromEntity(grupoProdutoTamanho.tamanho),
                            preco: parseFloat(`${grupoProdutoTamanho.preco}`.replace(',', '.')),
                            quantidadeMaximaSabores: parseInt(`${grupoProdutoTamanho.quantidadeMaximaSabores}`),
                            quantidadeMinimaSabores: parseInt(`${grupoProdutoTamanho.quantidadeMinimaSabores}`),
                        };

                        return grupoProdutoTamanhoFormatado;
                    })
                : [],

            precoProdutoCombinado: cadastroRecuperaValueFromOptions(this.state.precoProdutoCombinado),

            ...(this.props.cadastroDados._links ? { _links: this.props.cadastroDados._links } : {})
        };

        return formData;
    }

    /**
     * Método executado ao clicar no botão "Gravar".
     * Recupera os dados do formulário e repassa para o método que irá persistir os dados.
     */
    handleGravar = () => {
        log('GrupoProdutoValues handleGravar');

        // Repassa para o método que irá persistir os dados
        this.props.onSave(this.getFormData());
    }

    /**
     * Método que ajusta os grupos vinculados ao cadastro.
     */
    setGruposVinculados = () => {
        log('GrupoProdutoValues setGruposVinculados');

        // Grupo Pai
        // this.grupoPai.inputComponent.updateValue(
        //     this.props.cadastroDados.grupoPaiOption ? this.props.cadastroDados.grupoPaiOption :
        //         this.props.cadastroDados.grupoPai ? cadastroBuildOptionsFromCadastros(false, this.props.cadastroDados.grupoPai) : null
        // );

        // // Subgrupos
        // this.subgrupoList.inputComponent.updateValue(
        //     this.props.cadastroDados.subgrupoListOption ? this.props.cadastroDados.subgrupoListOption :
        //         (this.props.cadastroDados.subgrupoList && (this.props.cadastroDados.subgrupoList.length > 0))
        //             ? cadastroBuildOptionsFromCadastros(true, this.props.cadastroDados.subgrupoList) : []
        // );

        // Impressora
        this.impressora.inputComponent.updateValue(
            this.props.cadastroDados.impressoraOption ? this.props.cadastroDados.impressoraOption :
                this.props.cadastroDados.impressora ? cadastroBuildOptionsFromCadastros(false, this.props.cadastroDados.impressora) : null
        );
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro
     */
    wasChanged = (newData, oldData) => {
        let result = !equalsCoerced(
            oldData,
            newData,
            (oldValue, newValue) => {
                return newValue.enabled === oldValue.enabled
                    && newValue.codigo === oldValue.codigo
                    && newValue.nome === oldValue.nome
                    //&& entityURIEqualsURI(oldValue.grupoPai, newValue.grupoPai)
                    //&& entityURIListEqualsList(oldValue.subgrupoList, newValue.subgrupoList)
                    && entityURIEqualsURI(oldValue.impressora, newValue.impressora)
            });
        log('GrupoProdutoValues wasChanged', { newData, oldData, result });
        return result;
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro, comparando com os dados sendo editados.
     */
    wasChangedFromCurrent = newData => {
        log('GrupoProdutoValues wasChangedFromCurrent', newData);
        return this.wasChanged(newData, this.props.cadastroDados);
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro, comparando com os dados presentes ao abrir a criação/edição do cadastro.
     */
    wasChangedFromInitial = newData => {
        log('GrupoProdutoValues wasChangedFromInitial', newData);
        return this.wasChanged(newData, this.props.cadastroDadosIniciais);
    }

    /**
     * Método executado APÓS a montagem/renderização do componente.
     * Carrega os grupos e também define os valores iniciais das opções conforme cadastro carregado.
     */
    componentDidMount() {
        log('GrupoProdutoValues componentDidMount');
        this.ismounted = true;

        // this.props.cadastroLoadGrupos(this.props.urlDataBase, grupoProduto => {
        //     if (this.ismounted) {
        //         this.grupoPai.inputComponent.updateOptions(cadastroBuildOptionsFromCadastros(true, grupoProduto));
        //         // this.subgrupoList.inputComponent.updateOptions(cadastroBuildOptionsFromCadastros(true, grupoProduto));
        //     }
        // }, () => {
        //     if (this.ismounted && (this.props.loading.grupos !== false)) {

        //         this.props.cadastroUpdateLoading({ grupos: false });
        //     }
        // });

        this.props.cadastroLoadImpressoras(impressora => {
            if (this.ismounted) {
                this.impressora.inputComponent.updateOptions(cadastroBuildOptionsFromCadastros(true, impressora));
            }
        }, () => {
            if (this.ismounted && (this.props.loading.impressoras !== false)) {

                this.props.cadastroUpdateLoading({ impressoras: false });
            }
        });

        this.forceUpdate();
    }

    /**
     * Método executado APÓS a atualização do componente.
     * Carrega os grupos e também define os valores iniciais das opções conforme cadastro carregado.
     */
    componentDidUpdate(prevProps) {
        log('GrupoProdutoValues componentDidUpdate');

        if (this.props.cadastroDados) {

            // Se for passado false, é false; se for passado true, null, undefined ou não for passado, é true
            this.enabled.inputField.inputComponent.checked = this.props.cadastroDados.enabled !== false;

            this.codigo.inputField.inputComponent.value = this.props.cadastroDados.codigo ? this.props.cadastroDados.codigo : null;

            this.nome.inputField.inputComponent.value = this.props.cadastroDados.nome ? this.props.cadastroDados.nome : null;
        }

        this.setGruposVinculados();
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('GrupoProdutoValues render');
        return <div className='sub-form'>

            <AtivoInputDefault tabIndex={this.props.tabIndex} ref={input => this.enabled = getReduxWrappedComponent(input)} handleInputValidado={this.props.handleChange} />

            <CodigoInputDefault ref={input => this.codigo = getReduxWrappedComponent(input)} subPlaceholder={getStrings().productGroupSubPlaceholder} handleInputValidado={this.props.handleChange} />

            <NomeInputDefault ref={input => this.nome = getReduxWrappedComponent(input)} subPlaceholder={getStrings().productGroupSubPlaceholder} handleInputValidado={this.props.handleChange} isRequired={true} />

            {/* <InputCustomizado id='grupoPai' inputType='singleSelect' name='grupoPai' ref={input => this.grupoPai = getReduxWrappedComponent(input)} placeholder={getStrings().parentGroupPlaceholder} label={getStrings().parentGroup + ' (' + getStrings().parentGroupExplanation + ')'} onChange={() => setTimeout(() => this.props.handleChange(), 0)} /> */}

            <InputCustomizado id='impressora' inputType='singleSelect' name='impressora' ref={input => this.impressora = getReduxWrappedComponent(input)} placeholder={getStrings().productGroupPrinterPlaceholder} label={getStrings().printer} onChange={() => setTimeout(() => this.props.handleChange(), 0)} />

            {/* <InputCustomizado id='subgrupoList' inputType='multiSelect' name='subgrupoList' ref={input => this.subgrupoList = getReduxWrappedComponent(input)} placeholder={getStrings().subgroupsPlaceholder} label={getStrings().subgroups} onChange={() => setTimeout(() => this.props.handleChange(), 0)} /> */}
            
            <GrupoProdutoTamanhoForm
                loading={this.props.loading}
                cadastroUpdateLoading={this.props.cadastroUpdateLoading}
                possuiTamanhos={this.possuiTamanhos}
                multiplosTamanhosAtivo={this.state.multiplosTamanhosAtivo}
                mapGrupoProdutoTamanho={this.state.mapGrupoProdutoTamanho}
                precoProdutoCombinado={this.state.precoProdutoCombinado}
                updateState={(key, value) => this.setState({[key]: value})}
            />
        </div>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    tabIndex: state.appReducer.getTabIndex(),
    ...state.idiomaReducer,
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    cadastroLoadGrupos: (urlDataBase, onSuccess, onComplete) => dispatch(actions.cadastroLoadGrupos(urlDataBase, onSuccess, onComplete)),
    cadastroLoadImpressoras: (onSuccess, onComplete) => dispatch(actions.cadastroLoadImpressoras(onSuccess, onComplete)),
    cadastroUpdateLoading: steps => dispatch(actions.cadastroUpdateLoading(steps)),
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GrupoProdutoForm);
