import { estadoMenuTipo } from "./menuModalReducer";
import criarAction from "../../factory/criarAction";
import { webAppReducersTipo } from "../../types";

export const tiposDeAcoes = {
  MUDAR_ESTADO_MENU_MODAL: 'MUDAR_ESTADO_MENU_MODAL:MENU_MODAL',
}

export const mudarEstadoMenuModal = (carga: {estadoMenu: estadoMenuTipo}) => function(dispatch: any) {
  dispatch(criarAction(carga, tiposDeAcoes.MUDAR_ESTADO_MENU_MODAL));
};

export const inverterEstadoMenuModal = () => function(dispatch: any, getState: () => webAppReducersTipo) {
  dispatch(mudarEstadoMenuModal({estadoMenu: getState().webAppMenuModalReducer.estadoMenu === 'aberto' ? 'fechado' : 'aberto'}));
};
