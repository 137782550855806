import React from "react"
import { connect } from "react-redux"
import { hideSpinner } from "../../UI/Spinner/Spinner"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { formatNumber } from "../../../utils/NumberUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { formatI18nString } from "../../../utils/StringUtils"

import { FILTER_TIMER_DELAY } from "../../../store/actions/cadastroAction"
import * as collectorActions from "../../../store/actions/collectorAction"
import { SPINNER_FILTER_PRODUCT_ID } from "../../../store/actions/collectorAction"

import BarraAcoesCollectorSale from "../barraAcao/BarraAcoesCollectorSale"
import CollectorTable from "./CollectorTable"
import FiltroInputDefault from "../../UI/filtroInputDefault/FiltroInputDefault"
import HelpParagraph from "../../UI/HelpParagraph/HelpParagraph"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

/**
 * Tela que permite a gerência de uma venda feita com empresa do ramo coletor.
 */
class CollectorSale extends React.Component {

  /**
   * Método executado APÓS a montagem do componente.
   * Realiza o carregamento dos cadastros na tela.
   */
  componentDidMount() {
    log("CollectorSale componentDidMount")
    hideSpinner(SPINNER_FILTER_PRODUCT_ID)
    this.ismounted = true
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("CollectorSale render")

    return <>

      <HelpParagraph children={getStrings().collectorSaleHelp.map(help => formatI18nString(help))} />

      <BarraAcoesCollectorSale
        collector={this.props.collector}
      />

      <WidthAwareDiv>
        <div className="sub-form">
          <FiltroInputDefault
            defaultValue={this.props.filterProdutoValue}
            ref={input => { if (input) { this.filterProdutoComponent = getReduxWrappedComponent(input) } }}
            onFilterTimerCompleted={() => {
              if (this.ismounted) {
                if (this.filterProdutoComponent.getValue()) {
                  this.props.getFilteredProductList(this.filterProdutoComponent.getValue())
                } else {
                  this.props.clearFilteredProductList()
                }
              }
            }}
            spinnerId={SPINNER_FILTER_PRODUCT_ID}
            timerDelay={FILTER_TIMER_DELAY}
            label={getStrings().productFilter}
            placeholder={getStrings().productFilterPlaceholder}
          />
        </div>

        {/* Tabela de produtos filtrados */}
        <CollectorTable
          collector={this.props.collector}
          filtered
        />

        {/* Tabela de produtos coletados */}
        <CollectorTable
          collector={this.props.collector}
        />

        {/* Tabela de totais */}
        <div className="table">
          <div className="pure-g">

            <div className="pure-u-xl-1-2 pure-u-lg-1-2 pure-u-md-1-2 pure-u-sm-1-1 pure-u-1-1" >
              <div className="table-total label-total-itens-venda" >
                <label className="TextAlign" >{getStrings().filteredTotalTemplate("")}</label>
                <label className="NumberAlign" >{formatNumber(this.props.filteredTotal(), 2)}</label>
              </div>
            </div>

            <div className="pure-u-xl-1-2 pure-u-lg-1-2 pure-u-md-1-2 pure-u-sm-1-1 pure-u-1-1" >
              <div className="table-total label-total-itens-venda" >
                <label className="TextAlign" >{getStrings().collectedTotalTemplate("")}</label>
                <label className="NumberAlign" >{formatNumber(this.props.collectedTotal(), 2)}</label>
              </div>
            </div>

            <div className="pure-u-xl-1-2 pure-u-lg-1-2 pure-u-md-1-2 pure-u-sm-1-1 pure-u-1-1" >
              <div className="table-total label-total-itens-venda" >
                <label className="TextAlign" >{getStrings().grandTotalTemplate("")}</label>
                <label className="NumberAlign" >{formatNumber(this.props.grandTotal(), 2)}</label>
              </div>
            </div>
          </div>
        </div>
      </WidthAwareDiv>
    </>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  ...state.idiomaReducer,
  clienteURISelected: state.collectorReducer.clienteURISelected,
  collector: state.collectorReducer.collector(),
  collectorList: state.collectorReducer.collectorList,
  collectedTotal: state.collectorReducer.collectedTotal,
  filterProdutoValue: state.collectorReducer.filterProdutoValue,
  filteredTotal: state.collectorReducer.filteredTotal,
  grandTotal: state.collectorReducer.grandTotal,
  // Esta variável só está aqui para que o componente renderize de novo quando seu valor mudar
  filteredProductList: state.collectorReducer.filteredProductList
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  clearFilteredProductList: () => dispatch(collectorActions.clearFilteredProductList()),
  getFilteredProductList: filter => dispatch(collectorActions.getFilteredProductList(filter))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(CollectorSale)
