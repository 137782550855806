import "./AbasTemplate.css"

import React  from "react"

export type AbasTemplateTipo = {
    botoesAbas: React.ReactNode,
}

class AbasTemplate extends React.Component<AbasTemplateTipo> {
    render() {
        const BotoesAbas = this.props.botoesAbas as typeof React.Component;

        return <div className='abas-template'>
            <div className='botoes-aba-template'>
                <BotoesAbas />
            </div>
            <div className='abas-template-conteudo'>
                {this.props.children}
            </div>
        </div>;
    }
}

export default AbasTemplate;
