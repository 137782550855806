import { isString } from "./ComparatorsUtils";

export const validaCPFOuCNPJ = cpfCnpj => {
    return validaCPF(cpfCnpj) || validaCNPJ_(cpfCnpj);
}

export const validaCPF = cpf => {
    if (cpf) {

        cpf = cpf.replace(/[^\d]+/g, '');

        if (cpf.length !== 11) return false;

        // Elimina CPFs invalidos conhecidos	
        if (cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999")
            return false;

        // Valida o primeiro digito	
        var add = 0;
        var rev;
        for (let i = 0; i < 9; i++)
            add += parseInt(cpf.charAt(i), 10) * (10 - i);

        rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev !== parseInt(cpf.charAt(9), 10))
            return false;

        // Valida o segundo digito	
        add = 0;
        for (let i = 0; i < 10; i++)
            add += parseInt(cpf.charAt(i), 10) * (11 - i);
        rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev !== parseInt(cpf.charAt(10), 10))
            return false;

        return true;
    }
    else
        return false;
}

const validaCNPJ_ = cnpj => {
    if (cnpj) {

        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj.length !== 14) {
            return false;
        }

        // Elimina CNPJs invalidos conhecidos
        if (cnpj === "00000000000000" ||
            cnpj === "11111111111111" ||
            cnpj === "22222222222222" ||
            cnpj === "33333333333333" ||
            cnpj === "44444444444444" ||
            cnpj === "55555555555555" ||
            cnpj === "66666666666666" ||
            cnpj === "77777777777777" ||
            cnpj === "88888888888888" ||
            cnpj === "99999999999999") {
            return false;
        }

        // Valida DVs
        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0, tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        if (resultado !== parseInt(digitos.charAt(0), 10)) {
            return false;
        }

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        if (resultado !== parseInt(digitos.charAt(1), 10)) {
            return false;
        }

        return true;
    }
    else {
        return false;
    }
}

export const validaCNPJ = cnpj => {
    return true; // TODO manter assim por enquanto
    // if (cnpj) {

    //     cnpj = cnpj.replace(/[^\d]+/g, '');

    //     if (cnpj.length !== 14) return false;

    //     // Elimina CNPJs invalidos conhecidos
    //     if (cnpj === "00000000000000" ||
    //         cnpj === "11111111111111" ||
    //         cnpj === "22222222222222" ||
    //         cnpj === "33333333333333" ||
    //         cnpj === "44444444444444" ||
    //         cnpj === "55555555555555" ||
    //         cnpj === "66666666666666" ||
    //         cnpj === "77777777777777" ||
    //         cnpj === "88888888888888" ||
    //         cnpj === "99999999999999")
    //         return false;

    //     // Valida DVs
    //     var tamanho = cnpj.length - 2
    //     var numeros = cnpj.substring(0, tamanho);
    //     var digitos = cnpj.substring(tamanho);
    //     var soma = 0;
    //     var pos = tamanho - 7;

    //     for (let i = tamanho; i >= 1; i--) {
    //         soma += numeros.charAt(tamanho - i) * pos--;
    //         if (pos < 2)
    //             pos = 9;
    //     }
    //     var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    //     if (resultado !== parseInt(digitos.charAt(0), 10))
    //         return false;

    //     tamanho = tamanho + 1;
    //     numeros = cnpj.substring(0, tamanho);
    //     soma = 0;
    //     pos = tamanho - 7;

    //     for (let i = tamanho; i >= 1; i--) {
    //         soma += numeros.charAt(tamanho - i) * pos--;
    //         if (pos < 2)
    //             pos = 9;
    //     }
    //     resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    //     if (resultado !== parseInt(digitos.charAt(1), 10))
    //         return false;

    //     return true;
    // }
    // else
    //     return false;
}

export const formataIdentificador = (identificador) => {
    if (isString(identificador)) {
        if (identificador.length === 11) {
            return formataCPF(identificador);
        } 
        
        if (identificador.length === 14) {
            return formataCNPJ(identificador);
        }
        return identificador;
    }
    
    return '';
}

export const formataCPF = (cpf) => {
    return !isString(cpf) ? ''
    : cpf.replace(/(\d{3})(\d)/, "$1.$2").replace(/(\d{3})(\d)/, "$1.$2").replace(/(\d{3})(\d{1,2})$/, "$1-$2")
}

export const formataCNPJ = (cnpj) => {
    return !isString(cnpj) ? ''
    : cnpj.replace(/^(\d{2})(\d)/, "$1.$2").replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3").replace(/\.(\d{3})(\d)/, ".$1/$2").replace(/(\d{4})(\d)/, "$1-$2");
}