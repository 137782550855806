import { push } from "connected-react-router"

import { log } from "../../utils/LogUtils"
import { getStateType } from "../../utils/reduxUtils/getStateType"
import { getURIFromEntity } from "../../utils/URIUtils"

import {
  buildInitialState,
  cadastroEmpresaReducerType,
  STATE_CADASTRO_EMPRESA_CONTRATO,
  STATE_CADASTRO_EMPRESA_EMPRESA,
  STATE_CADASTRO_EMPRESA_USUARIO
} from "../reducers/cadastroEmpresaReducer"
import { dispatchTipo } from "./acaoTemplate"
import * as actionTypes from "./actionTypes"

// TODO verificar possibilidade de deletar, já que cadastro de empresa não usa mais várias telas

export type cadastroEmpresaUsuarioNovoType = (usuario: Record<string, any>|null) => { type: string, usuario: Record<string, any>|null, usuarioOption?: "" };

/**
 * Dispara troca de estado para exibir tela de cadastro de empresa (cadastro de usuário).
 * Se usuário for nulo, limpa a tela. Senão, a tela é preenchida com seus dados.
 * @param {Record<string, any>} usuario 
 */
export const cadastroEmpresaUsuarioNovo: cadastroEmpresaUsuarioNovoType = usuario => {
  log("cadastroEmpresaAction cadastroEmpresaUsuarioNovo", { usuario });
  return {
    type: actionTypes.CADASTRO_EMPRESA_USUARIO_NOVO,
    usuario: usuario,
    ...(((usuario === undefined) || (usuario === null)) ? { usuarioOption: "" } : {})
  };
};

export type cadastroEmpresaLimparType = () => cadastroEmpresaReducerType;

/**
 * Limpa as variáveis do estado mas mantém a tela anterior.
 */
export const cadastroEmpresaLimpar = () => {
  log("cadastroEmpresaAction cadastroEmpresaLimpar");
  return {
    ...buildInitialState(),
    // type: actionTypes.CADASTRO_EMPRESA_E_USUARIO_SAVE_DATA,
  };
};

export type cadastroEmpresaEditType = () => {type: string};

/**
 * Dispara troca de estado para exibir tela de editar empresa.
 */
export const cadastroEmpresaEdit: cadastroEmpresaEditType = () => {
  log("cadastroEmpresaAction cadastroEmpresaEdit");
  return {
    type: actionTypes.CADASTRO_EMPRESA_EDIT,
  };
};

export type cadastroEmpresaUpdateFormDataType = (type: string, cadastroEmpresaDados?: Record<string,any>, usuario?: Record<string,any>|null, 
  cargo?: Record<string,any>|null, contrato?: Record<string,any>|null) => Partial<cadastroEmpresaReducerType>;

/**
 * Guarda os dados de formulário alterados pelo usuário para popular a tela
 * quando há erro no envio ou quando o idioma é alterado.
 * @param {string} type etapa do cadastro
 * @param {Record<string,any> | undefined} cadastroEmpresaDados dados da empresa alterados
 * @param {Record<string,any> | undefined} usuario usuário da empresa alterado
 * @param {Record<string,any> | undefined} cargo cargo da empresa alterado
 * @param {Record<string,any> | undefined} contrato contrato da empresa alterado
 */
export const cadastroEmpresaUpdateFormData: cadastroEmpresaUpdateFormDataType = (type, cadastroEmpresaDados = undefined, usuario = undefined, cargo = undefined, contrato = undefined) => {
  log("cadastroEmpresaAction cadastroEmpresaUpdateFormData", { type, cadastroEmpresaDados, usuario, cargo, contrato });

  return {
    type: type,
    ...((cadastroEmpresaDados !== undefined) ? { cadastroEmpresaDados: cadastroEmpresaDados } : {}),
    ...((usuario !== undefined) ? { usuario: usuario } : {}),
    ...((cargo !== undefined) ? { cargo: cargo } : {}),
    ...((contrato !== undefined) ? { contrato: contrato } : {})
  };
};

export type cadastroEmpresaEUsuarioAnteriorType = () => (dispatch: dispatchTipo, getState: getStateType) => void; 

/**
 * Volta à tela anterior durante cadastro de Empresa e Usuário.
 * Estados: CONTRATO > EMPRESA > USUARIO
 */
export const cadastroEmpresaEUsuarioAnterior: cadastroEmpresaEUsuarioAnteriorType = () => (dispatch, getState) => {
  let state = getState().cadastroEmpresaReducer.state
  log("cadastroEmpresaAction cadastroEmpresaEUsuarioAnterior", { state })
  switch (state) {
    case STATE_CADASTRO_EMPRESA_EMPRESA:
      return dispatch(cadastroEmpresaEUsuarioChangeState(STATE_CADASTRO_EMPRESA_CONTRATO));
    case STATE_CADASTRO_EMPRESA_USUARIO:
      return dispatch(cadastroEmpresaEUsuarioChangeState(STATE_CADASTRO_EMPRESA_EMPRESA));
    case STATE_CADASTRO_EMPRESA_CONTRATO:
    default:
      dispatch(push("/signUp"));
  };
};

export type cadastroEmpresaEUsuarioProximoType = () => (dispatch: dispatchTipo, getState: getStateType) => void; 

/**
 * Vai para a próxima tela durante cadastro de Empresa e Usuário.
 * Estados: CONTRATO > EMPRESA > USUARIO
 */
export const cadastroEmpresaEUsuarioProximo: cadastroEmpresaEUsuarioProximoType = () => (dispatch, getState) => {
  log("cadastroEmpresaAction cadastroEmpresaEUsuarioProximo")
  switch (getState().cadastroEmpresaReducer.state) {
    case STATE_CADASTRO_EMPRESA_CONTRATO:
      return dispatch(cadastroEmpresaEUsuarioChangeState(STATE_CADASTRO_EMPRESA_EMPRESA));
    case STATE_CADASTRO_EMPRESA_EMPRESA:
      return dispatch(cadastroEmpresaEUsuarioChangeState(STATE_CADASTRO_EMPRESA_USUARIO));
    case STATE_CADASTRO_EMPRESA_USUARIO:
    default:
      return false;
  };
};

export type cadastroEmpresaEUsuarioChangeStateType = (state: string) => {type: string, state: string}; 

/**
 * Troca o estado atual do cadastro de Empresa e Usuário.
 * @param {*} state
 */
export const cadastroEmpresaEUsuarioChangeState: cadastroEmpresaEUsuarioChangeStateType = state => {
  log("cadastroEmpresaAction cadastroEmpresaEUsuarioChangeState", { state })
  return {
    type: actionTypes.CADASTRO_EMPRESA_E_USUARIO_CHANGE_STATE,
    state: state,
  };
};

export type cadastroEmpresaEUsuarioSaveDataType = (state: string, data: Record<string, any>) => (dispatch: dispatchTipo, getState: getStateType) => void; 

/**
 * Troca o estado atual do cadastro de Empresa e Usuário e armazena dados do cadastro.
 * @param {*} state 
 * @param {*} dados 
 */
export const cadastroEmpresaEUsuarioSaveData: cadastroEmpresaEUsuarioSaveDataType = (state, data) => (dispatch, getState) => {
  log("cadastroEmpresaAction cadastroEmpresaEUsuarioSaveData", 
    { state, data, empresa: getState().cadastroEmpresaReducer.cadastroEmpresaDados.empresa });
  switch (state) {
    case STATE_CADASTRO_EMPRESA_CONTRATO:
      return dispatch({
        type: actionTypes.CADASTRO_EMPRESA_E_USUARIO_SAVE_DATA,
        cadastroEmpresaDados: {
          ...getState().cadastroEmpresaReducer.cadastroEmpresaDados,
          empresa: {
            ...getState().cadastroEmpresaReducer.cadastroEmpresaDados.empresa,
            contratoEmpresa: data,
          }
        }
      });
    case STATE_CADASTRO_EMPRESA_USUARIO:
      return dispatch({
        type: actionTypes.CADASTRO_EMPRESA_E_USUARIO_SAVE_DATA,
        cadastroEmpresaDados: {
          ...getState().cadastroEmpresaReducer.cadastroEmpresaDados,
          usuario: data,
        }
      });
    case "STATE_CADASTRO_EMPRESA_FORM":
      return dispatch({
        type: actionTypes.CADASTRO_EMPRESA_E_USUARIO_SAVE_DATA, 
        cadastroEmpresaDados: {
          ...getState().cadastroEmpresaReducer.cadastroEmpresaDados,
          ...data,
        },
      });
    case STATE_CADASTRO_EMPRESA_EMPRESA:
    default:
      const empresa = {
        ...getState().cadastroEmpresaReducer.cadastroEmpresaDados.empresa,
        ...data,
      };
      const ramoEmpresa = empresa.ramoEmpresaOption?.value?.codigo || "";
      empresa.ramoEmpresa = ramoEmpresa;

      if (empresa.enderecoEmpresa) {
        const pais = getURIFromEntity(empresa.enderecoEmpresa.paisOption?.value);

        empresa.enderecoEmpresa.pais = pais;
      }

      return dispatch({
        type: actionTypes.CADASTRO_EMPRESA_E_USUARIO_SAVE_DATA,
        cadastroEmpresaDados: {
          ...getState().cadastroEmpresaReducer.cadastroEmpresaDados,
          empresa,
        }
      })
  }
}

export type setCnpjValidoType = (cnpjValido: string) => (dispatch: dispatchTipo) => void;

export const setCnpjValido: setCnpjValidoType = cnpjValido => dispatch => {
  dispatch({
    type: actionTypes.CADASTRO_EMPRESA_EDIT,
    cnpjValido
  });
};

export type updateFromZipCodeType = (cadastroEmpresaState: string, zipCode: string, state: string,
  city: string, neighborhood: string, street: string) => (dispatch: dispatchTipo, getState: getStateType) => void;

/**
 * Atualiza os campos de endereço a partir de consulta do CEP.
 * @param {string} cadastroEmpresaState
 * @param {string} state 
 * @param {string} city 
 * @param {string} neighborhood 
 * @param {string} street 
 */
export const updateFromZipCode: updateFromZipCodeType = (cadastroEmpresaState, zipCode, state, 
  city, neighborhood, street) => (dispatch, getState) => {
  log("cadastroEmpresaAction updateFromZipCode", { cadastroEmpresaState, zipCode, state, city, neighborhood, street });

  let entity;
  let field;
  switch (cadastroEmpresaState) {
    case STATE_CADASTRO_EMPRESA_USUARIO:
      entity = "usuario";
      field = "dadosAdicionais";
      break;
    case STATE_CADASTRO_EMPRESA_EMPRESA:
    default:
      entity = "empresa";
      field = "enderecoEmpresa";
  }
  dispatch(cadastroEmpresaEUsuarioSaveData(cadastroEmpresaState, Object.assign({},
    (getState().cadastroEmpresaReducer.cadastroEmpresaDados || {})[entity],
    {
      [field]: Object.assign({},
        ((getState().cadastroEmpresaReducer.cadastroEmpresaDados || {})[entity])[field],
        {
          cep: zipCode,
          uf: state,
          municipio: city,
          bairro: neighborhood,
          endereco: street
        }
      )
    }
  )));
}

export type updateEmpresaStepType = (step: number) => (dispatch: dispatchTipo, getState: getStateType) => void;

/**
 * 
 * @param {number} step 
 */
export const updateEmpresaStep: updateEmpresaStepType = (step) => {
  return (dispatch, getState) =>
    dispatch({ ...getState().cadastroEmpresaReducer, step });
}

export type updateMinVersionType = (minVersion: boolean) => void;

export const updateMinVersion: updateMinVersionType = (minVersion: boolean) => (dispatch: dispatchTipo) => {
  localStorage.setItem("expandedMode", minVersion as any);

  dispatch({type: actionTypes.CADASTRO_EMPRESA_LIST_EXPANDED_MODE, minVersion: minVersion});
};