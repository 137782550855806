import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import BuildCellFromItemVenda from "../grid/BuildCellFromItemVenda"
import ItensVendaTable from "./ItensVendaTable"
import ItensVendaTotal from "./ItensVendaTotal"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

/**
 * Exibe os itens da venda que foram enviados, permitindo o seu cancelamento e a visualização das suas observações,
 * e os que estão para enviar, permitindo o seu cancelamento e a edição das suas observações.
 * 
 * Quando for conveniente, refatorar esta classe para separar as tabelas em arquivos diferentes. De preferência,
 * também separar cabeçalho e linhas em arquivos diferentes. Idem TelaPagamentoVenda e TelaPagamentoVendas.
 */
const TelaExibeItensVenda = (props) => {
  if (props.modo !== undefined && props.modo !== "exibe_itens_venda") {
    return null
  }

  log("TelaExibeItensVenda render")

  return <WidthAwareDiv key={"__tela_exibe_itens_venda__"} style={props.style} pureStyle={props.pureStyle}>
    <ItensVendaTable
      title={getStrings().newItems}
      itemList={props.newSaleItemList
        .filter(newSaleItem => newSaleItem.itemVenda.quantidade)
        .map(newSaleItem => <BuildCellFromItemVenda
          key={`${getURIFromEntity(newSaleItem.itemVenda.produto)}?s=${newSaleItem.sequence || 0}`}
          itemVenda={newSaleItem.itemVenda}
          isNovosItens={true}
          removeEnabled={true}
          sequence={newSaleItem.sequence}
          produto={newSaleItem.itemVenda.produto}
          newSaleItem={newSaleItem}
        />)
      }
    />
    <ItensVendaTable
      title={getStrings().sentItems}
      itemList={props.sentSaleItemList
        .map(itemVenda => <BuildCellFromItemVenda
          key={`${getURIFromEntity(itemVenda)}`}
          itemVenda={itemVenda}
          isNovosItens={false}
          removeEnabled={itemVenda.removeEnabled}
          produto={itemVenda.produto}
          newSaleItem={{ itemVenda }}
        />)
      }
    />
    <ItensVendaTotal
      newTotal={props.newSaleItemTotal}
      sentTotal={props.sentSaleItemTotal}
    />
  </WidthAwareDiv>
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
function mapStateToProps(state) {
  const props = {
    newSaleItemList: state.controleVendaReducer.newSaleItemList
    , newSaleItemTotal: state.controleVendaReducer.newSaleItemTotal
    , sentSaleItemList: state.controleVendaReducer.sentSaleItemList
    , sentSaleItemTotal: state.controleVendaReducer.sentSaleItemTotal
    , locale: state.idiomaReducer.locale
  }
  return props
}

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(TelaExibeItensVenda)
