import { log } from './LogUtils';
import { isArray, isNonEmptyArray, isNumber } from './ComparatorsUtils';

/**
 * Altera um elemento de uma lista e retorna a lista.
 * @param {Array} array lista a ser alterada
 * @param {number} index índice do elemento a ser alterado (começa em 0)
 * @param {any} element elemento a substituir a anterior
 */
export const setIndexReturnArray = (array, index, element) => {
    log('utils setIndexReturnArray', array, index, element);

    if (!isNonEmptyArray(array) || !isNumber(index) || index >= array.length) {
        return null;
    }

    array.splice(index, 1, element);
    return array;
}

/**
 * Retorna uma nova instância de uma lista com um elemento alterado.
 * @param {Array} array lista a ser alterada
 * @param {number} index índice do elemento a ser alterado (começa em 0)
 * @param {any} element elemento a substituir a anterior
 */
export const setIndexReturnNewArray = (array, index, element) => {
    log('utils setIndexReturnNewArray', { array, index, element });

    if (!isNonEmptyArray(array) || !isNumber(index) || index >= array.length) {
        return null;
    }

    return array
        .slice(0, index)
        .concat(element)
        .concat(array.slice(index + 1));
}

/**
 * Retorna uma nova instância de uma lista com um elemento adicionado após o indice informado.
 * @param {Array} array lista a ser alterada
 * @param {number} index índice do elemento que precede o que será adicionado
 * @param {any} element elemento a ser adicionado
 */
export const addAfterReturnNewArray = (array, index, element) => {
    log('utils addAfterReturnNewArray', { array, index, element });

    if (!isArray(array) || !isNumber(index) || index > array.length) {
        return null;
    }

    return array
        .slice(0, index + 1)
        .concat(element)
        .concat(array.slice(index + 1));
}

/**
 * Retorna um vetor de objetos. Se o vetor tiver mais do que um elemento,
 * coloca um separador entre cada elemento
 * @param {Array} array vetor de objetos
 * @param {Object} separator separador
 */
export const joinObject = (array, separator) => {
    log('utils joinObject', array, separator);

    if (!(isNonEmptyArray(array) && separator)) {
        return [];
    }

    if (array.length === 1) {
        return array;
    }

    let output = [];

    array.forEach((item, index) => {
        if (index > 0) {
            output.push(separator);
        }
        output.push(item);
    });

    return output;
}

/**
 * Remove de uma lista o primeiro elemento que passar no teste. Se nenhum passar, nada é feito. Retorna o elemento removido e o seu índice (`{ element, index }`).
 * @param {Array} array lista a ter um elemento removido ou não
 * @param {function} callback função que recebe cada elemento e retorna verdadeiro para o primeiro que deve ser removido
 */
export const removeMatch = (array, callback) => {
    log('utils removeMatch', array, callback);
    let index = array.findIndex(callback);
    if (index < 0) {
        return {
            index,
            array
        };
    }
    return {
        element: array.splice(index, 1)[0],
        index,
        array
    };
}

/**
 * Cria uma lista vazia com um determinado tamanho.
 * @param {Number} length tamanho da nova lista
 */
export const newArray = length => Array.apply(null, { length });

/**
 * Executa um método um determinado número de vezes.
 * @param {Number} length número de vezes que o método será executado
 * @param {Function} callBack método a ser executado
 */
export const forEach = (length, callBack) => newArray(length).forEach(callBack);

/**
 * Retorna a lista informada por parâmetro sem os itens duplicados.
 * @param {any[]} array 
 */
export const removeDuplicates = array => [...new Set(array || [])];
