import React from "react";
import styled from "styled-components";
import { displayFlexCenter } from "../css/styles";

const SegmentoCard = (props) => (
  <Card>
    <CardImage src={props.src} alt={props.cardTitle} />
    <CardTitleContainer>
      <CardTitle>{props.cardTitle}</CardTitle>
    </CardTitleContainer>
  </Card>
);

const Card = styled.div`
  ${displayFlexCenter};
  background-color: ${(props) => props.theme.lightBackgroundColor};
  flex-direction: column;
  justify-content: space-around;
  width: 100px;
  height: 90px;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  /* outline: 1px solid black; */
  @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    font-size: 1.2em;
  }
`;

const CardImage = styled.img`
  margin-top: -30px;
  width: 75px;
  height: 75px;
  border-radius: 999px;
  outline: 7px solid white;
`;

const CardTitleContainer = styled.div`
  ${displayFlexCenter};
  width: 100%;
  margin-top: 0.6em;
`;
const CardTitle = styled.h2`
  color: ${(props) => props.theme.primaryColor};
  font-size: 0.55em;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  font-weight: 900;
`;

export default SegmentoCard;
