import React from "react"
import { axios } from "./appAction"

import * as errorUtils from "../../utils/ErrorUtils"

import * as actionTypes from "./actionTypes"
import { appDialogHide, appDialogShow, appNotificationShow, appSpinnerHide, appSpinnerShow, confirmMessage } from "./appAction"

import { SALE_ITEM_UPDATED } from "./subscriberAction"

import { getHeaders } from "../../utils/HeadersUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { formatNumber } from "../../utils/NumberUtils"
import { getApi, urlDatabase } from "../../utils/SecureConnectionUtils"
import { getPageSize } from "../../utils/StorageUtils/LocalStorageUtils"
import { getIdFromURI, getURIFromEntity, entityURIEqualsURI, getURIFromEntityAlt } from "../../utils/URIUtils"

import DialogItemProduzido from "../../components/Subscriber/DialogItemProduzido"
import DialogEntregador from "../../components/producao/dialog/DialogEntregador"
import {
  ESTADO_ITEM_VENDA_AGUARDANDO
  , ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA
} from "../reducers/controleVenda/controleVendaReducer"
import {
  PARAMETRO_BUSCA_FILTRO
  , PARAMETRO_BUSCA_ORDENACAO
  , TELA_EM_PRODUCAO
  , TELA_PEDIDOS_ABERTOS
  , TELA_PEDIDOS_FATURADOS
  , TELA_PEDIDO_ATUAL
  , TELA_PRONTOS
} from "../reducers/producaoReducer"

const moment = require("moment")

/**
 * Limpa a lista de itens de venda produzidos.
 */
export const clearUnread = () => dispatch => {
  log('producaoAction clearUnread');

  dispatch({
    type: actionTypes.PRODUCAO,
    itemVendaProduzidoList: []
  })
};

/**
 * Ao haver falha em comunicação com o servidor, mostrar uma notificação.
 * @param {*} error 
 */
const errorResponseNotify = error => dispatch => {
  log('producaoAction errorResponseNotify', 'error', { error });
  dispatch(errorUtils.requestErrorHandlerDefault(error));
};

/**
 * Busca itens de venda produzidos que o usuário ainda não visualizou.
 * 
 * Se for fazer isso por causa de uma notificação, verifica se o item que causou a notificação será retornado na lista,
 * porque às vezes demora para que ele seja incluido em uma consulta logo depois da notificação ter sido persistida.
 * 
 * Por causa disso, poderá ser retornado itens cuja notificação já foi visualizada.
 * @param {Object} itemVendaProduzido URI do item de venda que causou a notificação
 */
export const getUnreadItemVendaList = (itemVendaProduzido = undefined) => (dispatch, getState) => {
  log('producaoAction getUnreadItemVendaList', { itemVendaProduzido, 'cargo logado?': getState().empresaSelectorReducer.cargo });

  // Se não houver um cargo logado, não pode executar método.
  if (!getState().empresaSelectorReducer.cargo) {
    return;
  }

  // Não mostra spinner, pois essa consulta ocorre de tempo em tempo* no plano de fundo.
  // *Ao abrir o app e ao receber notificação.

  axios().get(
    // Decide se usará URI do item de venda que causou a notificação para saber se a mesma já está persistida
    itemVendaProduzido ? (itemVendaProduzido + '/unread') : (urlDatabase + '/itemVendas/unread'),
    getHeaders()
  )
    .then(response => {
      log('producaoAction getUnreadItemVendaList', response);
      // Faz uma validação básica na resposta recebida
      response = ((response || {}).data || {}).content || [];
      // Gera uma versão da lista somente com os itens cuja notificação ainda não foi visualizada
      let responseFiltered = response.filter(itemVenda => !(itemVenda.read));
      // Se não está buscando através de notificação ou se está e o item que a gerou está contido na resposta
      if ((!itemVendaProduzido) || response.some(itemVenda => getURIFromEntity(itemVenda) === itemVendaProduzido)) {
        // Passa os itens para o reducer, exceto os que já foram lidos
        dispatch({
          type: actionTypes.PRODUCAO,
          itemVendaProduzidoList: responseFiltered
        });
      }
      // Se está buscando através de notificação e o item que a gerou não está na resposta
      else if (itemVendaProduzido) {
        // Chama o método de novo até estar
        dispatch(getUnreadItemVendaList(itemVendaProduzido));
      }
    })
    .catch(() => { });
};

/**
 * Busca os entregadores desta empresa e exibe o *dialog* para escolher um.
 * @param {Array} listItemVenda lista dos itens a serem atualizados
 */
export const loadDeliveryUserList = (listItemVenda, listItemProducao, idEntregador) => dispatch => {
  log('producaoAction loadDeliveryUserList', { listItemVenda });

  dispatch(appSpinnerShow('loadDeliveryUserList'));

  axios().get(
    urlDatabase + '/cargos/deliveryUsers',
    getHeaders()
  )
    .then(response => {
      // Busca a lista de entregadores
      let list = ((response || {}).data || {}).content || [];
      // Se forem retornados entregadores
      if (list.length > 0) {
        if (idEntregador) {

          const index = list.findIndex(item => getIdFromURI(getURIFromEntity(item)) === parseInt(idEntregador));

          if (index > -1) {
            // Atualiza a venda
            dispatch(updateItemVenda({
              estado: ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA,
              entregador: getURIFromEntity(list[index]),
              vendaList: listItemVenda.map(item => getIdFromURI(getURIFromEntity(item)))
            }, getApi('vendas/update')));
          }
        }
        else {
          // Exibe dialog para escolher um
          dispatch(appDialogShow(<DialogEntregador
            deliveryUserList={list}
            listItemVenda={listItemVenda}
            listItemProducao={listItemProducao}
          />, getStrings().chooseDeliveryUser));
        }
      }
      // Se não, avisa.
      else {
        dispatch(appNotificationShow(getStrings().noDeliveryUser, getStrings().warning));
      }
    })
    .catch(error =>
      dispatch(errorResponseNotify(error)))
    .finally(() =>
      dispatch(appSpinnerHide('loadDeliveryUserList')));
}

/**
 * Depende do ItemVendaRepository funcionar sem quebrar o resto do projeto.
 * @param {String} link é passado quando os botões de navegação são acionados
 * @param {Number} pageSize é passado quando usuário altera número de registros por página
 * @param {Object} itemVendaUpdated último item de venda atualizado, que ocasionou um envio de mensagem
 * @param {Boolean} fromMessage se a função foi executada por causa de uma mensagem que foi recebida
 * @param {Boolean} loadItensProducao carrega is itens da produção ao invés dos itends da venda
 */
export const loadItensVendaAbertos = (link = undefined, pageSize = undefined, itemVendaUpdated = undefined, fromMessage = undefined) => (dispatch, getState) => {
  log('producaoAction loadItensVendaAbertos', { link, pageSize, itemVendaUpdated, fromMessage });
  const state = getState();

  // Se não for usuário com vínculo com empresa, faz mais nada.
  if (!state.empresaSelectorReducer.cargo) {
    return;
  }
  // Se a função foi executada por recebimento de mensagem, avisa que recebeu a mensagem.
  // A função de carregar os itens também avisa que recebeu a mensagem,
  // mas em alguns casos ela não será executada.
  if (fromMessage) {
    // Marca mensagem como recebida
    dispatch(confirmMessage('role', SALE_ITEM_UPDATED));
  }
  // Se o Controle de Produção não está visível, faz mais nada.
  if (!state.producaoReducer.visible) {
    return;
  }

  // Mostra o spinner até que os registros sejam exibidos
  dispatch(appSpinnerShow('loadItensVendaAbertos'));

  // Se estiver presente, alterar número de registros por página
  // Senão, usar o anterior
  if (!pageSize) {
    pageSize = state.producaoReducer[state.producaoReducer.telaAtual].page.size;
  }

  if (state.producaoReducer.telaAtual === TELA_PRONTOS
    || state.producaoReducer.telaAtual === TELA_PEDIDOS_ABERTOS
    || state.producaoReducer.telaAtual === TELA_PEDIDOS_FATURADOS
    || state.producaoReducer.telaAtual === TELA_PEDIDO_ATUAL) {
    loadVendasAbertas(link, pageSize, itemVendaUpdated, fromMessage, dispatch, state);
    return;
  }

  let headers = getHeaders();
  // Se um botão de navegação foi acionado, usa o link dele, que contém número e tamanho da página
  // Senão, usa o link padrão e adiciona número e tamanho da página no header
  if (!link) {
    link = urlDatabase + (state.producaoReducer.agruparProdutosVenda ? '/itemVendas' : '/itemProducao') + '/findAllProductionItems';
    headers = getHeaders({
      page: state.producaoReducer[state.producaoReducer.telaAtual].page.number,
      size: pageSize,
      showProduced: state.producaoReducer.showProduced,
      telaAtual: state.producaoReducer.telaAtual,
      ...state.producaoReducer[state.producaoReducer.telaAtual][PARAMETRO_BUSCA_ORDENACAO],
      ...state.producaoReducer[state.producaoReducer.telaAtual][PARAMETRO_BUSCA_FILTRO],
    });
  }
  // Se recebeu uma mensagem por um item de venda ter sido atualizado,
  // envia este item para confirmar que recebeu a mensagem
  if (itemVendaUpdated && (!link.includes('itemVenda='))) {
    headers.params = Object.assign({}, headers.params, {
      itemVenda: getURIFromEntityAlt(itemVendaUpdated)
    });
  }

  axios().get(
    link,
    headers
  )
    .then(response => {
      // Se o Controle de Produção não está visível, ignora a resposta da requisição.
      if (!state.producaoReducer.visible) {
        return;
      }
      // Se recebeu mensagem sobre item ter sido atualizado,
      // verifica se a requisição trouxe os dados atualizados
      if (itemVendaUpdated) {
        // Busca a data e hora da atualização na requisição
        let dataHoraAtualizacaoRequest = moment.parseZone(((response || {}).headers || {}).item_venda_last_updated || '', moment.ISO_8601);
        // Busca a data e hora da atualização recebida por mensagem
        let dataHoraAtualizacaoMessage = moment.parseZone(itemVendaUpdated.dataHoraAtualizacao || '', moment.ISO_8601);
        // Se a data e hora retornada pela requisição ainda não é a mais atual
        log('producaoAction loadItensVendaAbertos', {
          '0 request': dataHoraAtualizacaoRequest,
          '1 message': dataHoraAtualizacaoMessage,
          '2 isBefore': dataHoraAtualizacaoRequest.isBefore(dataHoraAtualizacaoMessage)
        });
        if (dataHoraAtualizacaoRequest.isBefore(dataHoraAtualizacaoMessage)) {
          // Busca de novo e faz mais nada
          //dispatch(loadItensVendaAbertos(((((response || {}).data || {})._links || {}).self || {}).href || link, null, itemVendaUpdated));
          //return;
        }
      }
      // Ao controlar o Pageable manualmente no servidor, não vem _embedded quando não tem dados
      let lista = [];
      if ('_embedded' in response.data) {
        lista = response.data._embedded.resources;
      }
      lista = lista.map(itemVenda => {
        itemVenda._links.self.href = itemVenda._links.self.href.replace('{?projection}', '');
        return itemVenda;
      });

      const mapItemVenda = new Map();
      let itemProducaoList = [];

      for (let item of lista) {
        if (item.itemVenda) {
          if (!mapItemVenda.has(item.itemVenda._links.self.href)) {
            mapItemVenda.set(item.itemVenda._links.self.href, item.itemVenda);
          }
        }
        else if (item.itemProducaoList) {
          itemProducaoList.push(...item.itemProducaoList);
        }
      }

      const itemVendaList = mapItemVenda.size > 0 ? Array.from(mapItemVenda.values()) : lista;
      itemProducaoList = itemProducaoList.size > 0 ? itemProducaoList : lista;

      dispatch(showItensVendaAbertos(itemVendaList, itemProducaoList, response.data._links, response.data.page, getState()));

      if (itemVendaUpdated
        && itemVendaUpdated.estado === ESTADO_ITEM_VENDA_AGUARDANDO
        && Array.isArray(itemVendaUpdated.itemProducaoList)
        && itemVendaUpdated.itemProducaoList.every(item => item.estado === ESTADO_ITEM_VENDA_AGUARDANDO)) {
        dispatch({
          type: actionTypes.PRODUCAO
          , somNovoItem: true
        });
      }
    })
    .catch(error => {
      log('producaoAction loadItensVendaAbertos', 'error', { error });
      // Se acontecer da requisição não retornar um item que recém foi persistido, não exibe erro.
      if ((((error || {}).response || {}).data || '').startsWith('registerNotFound')) {
        return;
      }
      dispatch(errorResponseNotify(error));
    })
    .finally(() =>
      dispatch(appSpinnerHide('loadItensVendaAbertos'))
    );
};

export const loadVendasAbertas = (link, pageSize, itemVendaUpdated, fromMessage, dispatch, state) => {

  log('producaoAction loadVendasAbertas', { link, pageSize, itemVendaUpdated, fromMessage });

  let headers = getHeaders();
  // Se um botão de navegação foi acionado, usa o link dele, que contém número e tamanho da página
  // Senão, usa o link padrão e adiciona número e tamanho da página no header
  if (!link) {
    link = urlDatabase + '/vendas/findToProduction';
    headers = getHeaders({
      page: state.producaoReducer[state.producaoReducer.telaAtual].page.number,
      size: pageSize,
      showProduced: true,
      telaAtual: state.producaoReducer.telaAtual,
      mostrarEncerrados: state.producaoReducer.mostrarEncerrados,
      ...state.producaoReducer[state.producaoReducer.telaAtual][PARAMETRO_BUSCA_ORDENACAO],
      ...state.producaoReducer[state.producaoReducer.telaAtual][PARAMETRO_BUSCA_FILTRO],
    });
  }
  // Se recebeu uma mensagem por um item de venda ter sido atualizado,
  // envia este item para confirmar que recebeu a mensagem
  if (itemVendaUpdated && (!link.includes('itemVenda='))) {
    headers.params = Object.assign({}, headers.params, {
      itemVenda: getURIFromEntityAlt(itemVendaUpdated)
    });
  }

  axios().get(
    link,
    headers
  )
    .then(response => {
      // Se o Controle de Produção não está visível, ignora a resposta da requisição.
      if (!state.producaoReducer.visible) {
        return;
      }
      // Se recebeu mensagem sobre item ter sido atualizado,
      // verifica se a requisição trouxe os dados atualizados
      if (itemVendaUpdated) {
        // Busca a data e hora da atualização na requisição
        let dataHoraAtualizacaoRequest = moment.parseZone(((response || {}).headers || {}).item_venda_last_updated || '', moment.ISO_8601);
        // Busca a data e hora da atualização recebida por mensagem
        let dataHoraAtualizacaoMessage = moment.parseZone(itemVendaUpdated.dataHoraAtualizacao || '', moment.ISO_8601);
        // Se a data e hora retornada pela requisição ainda não é a mais atual
        log('producaoAction loadItensVendaAbertos', {
          '0 request': dataHoraAtualizacaoRequest,
          '1 message': dataHoraAtualizacaoMessage,
          '2 isBefore': dataHoraAtualizacaoRequest.isBefore(dataHoraAtualizacaoMessage)
        });
        if (dataHoraAtualizacaoRequest.isBefore(dataHoraAtualizacaoMessage)) {
          // Busca de novo e faz mais nada
          //dispatch(loadItensVendaAbertos(((((response || {}).data || {})._links || {}).self || {}).href || link, null, itemVendaUpdated));
          //return;
        }
      }
      // Ao controlar o Pageable manualmente no servidor, não vem _embedded quando não tem dados
      let lista = [];
      if ('_embedded' in response.data) {
        lista = response.data._embedded.resources;
      }
      lista = lista.map(venda => {
        venda._links.self.href = venda._links.self.href.replace('{?projection}', '');
        return venda;
      });

      // const mapItemVenda = new Map();
      // let itemProducaoList = [];

      // for (let item of lista) {
      //     if (item.itemVenda) {
      //         if (!mapItemVenda.has(item.itemVenda._links.self.href)) {
      //             mapItemVenda.set(item.itemVenda._links.self.href, item.itemVenda);
      //         }
      //     }
      //     else if (item.itemProducaoList) {
      //         itemProducaoList.push(...item.itemProducaoList);
      //     }
      // }

      // const itemVendaList = mapItemVenda.size > 0 ? Array.from(mapItemVenda.values()) : lista;
      // itemProducaoList = itemProducaoList.size > 0 ? itemProducaoList : lista;

      dispatch(showVendasAbertas(lista, response.data._links, response.data.page, state));

      if (state.producaoReducer.isEntregador) {
        dispatch(resumoEntregas());
      }

      if (itemVendaUpdated
        && itemVendaUpdated.estado === ESTADO_ITEM_VENDA_AGUARDANDO
        && Array.isArray(itemVendaUpdated.itemProducaoList)
        && itemVendaUpdated.itemProducaoList.every(item => item.estado === ESTADO_ITEM_VENDA_AGUARDANDO)) {
        dispatch({
          type: actionTypes.PRODUCAO
          , somNovoItem: true
        });
      }
    })
    .catch(error => {
      log('producaoAction loadVendasAbertas', 'error', { error });
      // Se acontecer da requisição não retornar um item que recém foi persistido, não exibe erro.
      if ((((error || {}).response || {}).data || '').startsWith('registerNotFound')) {
        return;
      }
      console.log(error);
      dispatch(errorResponseNotify(error));
    })
    .finally(() =>
      dispatch(appSpinnerHide('loadItensVendaAbertos'))
    );
};

export const resumoEntregas = () => (dispatch, getState) => {

  log('producaoAction resumoEntregas');

  let link = urlDatabase + '/vendas/resumoEntregas';
  let headers = getHeaders({
    showProduced: true,
    mostrarEncerrados: getState().producaoReducer.mostrarEncerrados,
    ...getState().producaoReducer[TELA_PRONTOS][PARAMETRO_BUSCA_ORDENACAO],
    ...getState().producaoReducer[TELA_PRONTOS][PARAMETRO_BUSCA_FILTRO]
  });

  axios().get(
    link,
    headers
  )
    .then(response => {
      if (response && Array.isArray(response.data) && response.data.length > 0 && Array.isArray(response.data[0])) {
        const dados = response.data[0];
        const quantidadeEntregas = dados[0] ? formatNumber(dados[0], 0) : '0';
        const valorTeles = dados[1] ? formatNumber(dados[1], 2) : '0,00';

        dispatch(updateDadosEntregas(quantidadeEntregas, valorTeles));
      }
    })
    .catch(error => {
      log('producaoAction resumoEntregas', 'error', { error });
      dispatch(errorResponseNotify(error));
    });
};

/**
 * Define a quantidade de itens por página.
 * @param {*} pageSize 
 */
export const setPageSize = pageSize => (dispatch, getState) => {
  log('producaoAction setPageSize', pageSize);

  dispatch({
    type: actionTypes.PRODUCAO,
    [TELA_EM_PRODUCAO]: {
      ...getState().producaoReducer[TELA_EM_PRODUCAO],
      page: Object.assign({}, getState().producaoReducer[TELA_EM_PRODUCAO].page, { size: getPageSize(pageSize) })
    },
    [TELA_PRONTOS]: {
      ...getState().producaoReducer[TELA_PRONTOS],
      page: Object.assign({}, getState().producaoReducer[TELA_PRONTOS].page, { size: getPageSize(pageSize) })
    },
    [TELA_PEDIDOS_ABERTOS]: {
      ...getState().producaoReducer[TELA_PEDIDOS_ABERTOS],
      page: Object.assign({}, getState().producaoReducer[TELA_PEDIDOS_ABERTOS].page, { size: getPageSize(pageSize) })
    },
    [TELA_PEDIDOS_FATURADOS]: {
      ...getState().producaoReducer[TELA_PEDIDOS_FATURADOS],
      page: Object.assign({}, getState().producaoReducer[TELA_PEDIDOS_FATURADOS].page, { size: getPageSize(pageSize) })
    }
  });
};

/**
 * Marca o item de venda e, opcionalmente, outros itens que serão impressos na mesma impressora, para serem enviados à impressão.
 * @param {Array} itemVenda 
 * @param {Boolean} printed se deve marcar como impresso ou não
 * @param {Boolean} printOtherItems se deve marcar somente o item selecionado ou todos os itens da venda que sejam para a mesma impressora
 */
export const setPrinted = (itemVenda, printed, printOtherItems = undefined) => dispatch => {
  log('producaoAction setPrinted');

  dispatch(appSpinnerShow('setPrinted'));

  axios().post(
    urlDatabase + '/itemVendas/setPrinted',
    {
      itemVenda,
      printed,
      printOtherItems
    },
    getHeaders()
  )
    .then(() => {
      dispatch(appNotificationShow(
        getStrings().salePrintSuccess(itemVenda.length === 1),
        actionTypes.APP_NOTIFICATION_TYPE_SUCCESS,
        getStrings().printSales
      ));
    })
    .catch(error =>
      dispatch(errorResponseNotify(error))
    )
    .finally(() =>
      dispatch(appSpinnerHide('setPrinted'))
    );
}

/**
 * Marca os itens de venda produzidos como visualizados.
 * @param {Object} itemVenda caso informado, marca somente este item
 */
export const setReadItemVendaList = itemVenda => (dispatch, getState) => {
  log('producaoAction setReadItemVendaList', { itemVenda });

  dispatch(appSpinnerShow('setReadItemVendaList'));

  dispatch(appDialogHide());

  axios().post(
    urlDatabase + '/itemVendas/unread',
    (itemVenda ? [itemVenda] : getState().producaoReducer.itemVendaProduzidoList)
      .map(itemVenda => getURIFromEntity(itemVenda)),
    getHeaders()
  )
    .then(response =>
      // Se método teve sucesso, limpa a lista de itens de venda produzidos.
      dispatch({
        type: actionTypes.PRODUCAO,
        itemVendaProduzidoList: itemVenda
          ? (getState().producaoReducer.itemVendaProduzidoList || []).filter(item => !entityURIEqualsURI(itemVenda, item))
          : []
      })
    )
    .catch(error =>
      dispatch(errorResponseNotify(error))
    )
    .finally(() => {
      dispatch(appSpinnerHide('setReadItemVendaList'))
    });
};

/**
 * Define no *reducer* o valor informado na variável informada.
 * @param value novo valor da variável
 * @param position nome da variável
 */
export const setValue = (value, position) => dispatch => {
  log('producaoAction setValue', { value, position });

  const acao = {
    type: actionTypes.PRODUCAO
    , [position]: value
  };

  dispatch(acao);
};

/**
 * Inverte o valor que define se devem ser exibidos os itens produzidos.
 */
export const switchShowProduced = () => (dispatch, getState) => {
  log('producaoAction switchShowProduced');
  dispatch({
    type: actionTypes.PRODUCAO,
    showProduced: !getState().producaoReducer.showProduced,
    telaAtual: getState().producaoReducer.showProduced ? TELA_EM_PRODUCAO : TELA_PRONTOS
  });
};

/**
 * Atualiza o nome da tela.
 */
export const atualizarTelaAtual = (telaAtual) => (dispatch) => {
  log('producaoAction atualizarTelaAtual');
  dispatch({
    type: actionTypes.PRODUCAO,
    telaAtual: telaAtual
  });
};

/**
 * Mostra a dialog de exibição de itens de venda produzidos.
 */
export const showItemProduzidoDialog = () => dispatch => {
  log('producaoAction showItemProduzidoDialog');

  dispatch(appDialogShow(<DialogItemProduzido />,
    getStrings().producedItems, { maxHeight: '320px' }
  ));
};

/**
 * Dispara troca de estado para carregar objetos.
 */
const showItensVendaAbertos = (itemVendaList, itemProducaoList, links, page, state) => {
  log('producaoAction showItensVendaAbertos', itemVendaList, links, page);

  const telaAtual = state.producaoReducer.telaAtual;
  const payload = {
    type: actionTypes.PRODUCAO,
    itemVendaList: itemVendaList,
    itemProducaoList: itemProducaoList,
    links: links,
    [telaAtual]: {
      ...state.producaoReducer[telaAtual],
      ...(page ? { page } : {}),
    },
    loading: false,
  };

  return payload;
};

const showVendasAbertas = (vendaList, links, page, state) => {
  log('producaoAction showVendasAbertas', vendaList, links, page);
  const telaAtual = state.producaoReducer.telaAtual;

  const payload = {
    type: actionTypes.PRODUCAO,
    vendaList,
    links: links,
    [telaAtual]: {
      ...state.producaoReducer[telaAtual],
      ...(page ? { page: page } : {}),
    },
    loading: false,
  };

  return payload;
};

/**
 * Método que ATUALIZA o cadastro no servidor conforme os dados recebidos por parâmetro.
 * @param {*} formData objeto contendo novas propriedades (somente aquelas que serão sobrescritas)
 */
export const updateItemVenda = (formData, urlUpdate, urlParams) => dispatch => {
  log('producaoAction updateItemVenda', { formData, urlUpdate });

  dispatch(appSpinnerShow('updateItemVenda'));

  axios().post(
    urlUpdate,
    formData,
    getHeaders(urlParams)
  )
    .then(response =>
      dispatch(appNotificationShow(getStrings().saleItemStateChangedSuccessfully(), actionTypes.APP_NOTIFICATION_TYPE_SUCCESS))
    )
    .catch(error =>
      dispatch(errorResponseNotify(error))
    )
    .finally(() => {
      // Como a atualização de estado causa envio de mensagem,
      // que cause nova consulta,
      // não é mais necessário chamar a consulta aqui.
      dispatch(appSpinnerHide('updateItemVenda'))
    });
};

/**
 * Inverte o valor que define se devem ser exibidos os itens produzidos.
 */
export const switchAgruparProdutosVenda = () => (dispatch, getState) => {
  log('producaoAction switchAgruparProdutosVenda');
  setAgruparProdutosVenda(!getState().producaoReducer.agruparProdutosVenda)(dispatch);
};

/**
 * Seta o valor que define se devem ser exibidos os itens produzidos.
 */
 export const setAgruparProdutosVenda = (agruparProdutosVenda) => (dispatch) => {
  log('producaoAction setAgruparProdutosVenda');

  localStorage.setItem('agruparProdutosVenda', agruparProdutosVenda);

  dispatch({
    type: actionTypes.PRODUCAO,
    agruparProdutosVenda: agruparProdutosVenda
  });
};

/**
 * Inverte o valor que define se devem ser exibidos os itens encerrados.
 */
export const switchMostrarEncerrados = () => (dispatch, getState) => {
  log('producaoAction switchMostrarEncerrados');
  dispatch({
    type: actionTypes.PRODUCAO,
    mostrarEncerrados: !getState().producaoReducer.mostrarEncerrados
  });
};

/**
 * Define no *reducer* o valor informado na variável informada.
 * @param value novo valor da variável
 * @param position nome da variável
 */
export const updateParametrosBusca = (tipoParametro, valor, posicao) => (dispatch, getState) => {
  log('producaoAction updateParametrosBusca', { tipoParametro, valor, posicao });

  const telaAtual = getState().producaoReducer.telaAtual;

  const acao = {
    type: actionTypes.PRODUCAO,
    [telaAtual]: {
      ...getState().producaoReducer[telaAtual],
      [tipoParametro]: {
        ...getState().producaoReducer[telaAtual][tipoParametro]
        , [posicao]: valor
      }
    }
  };

  dispatch(acao);
};

// export const limpaFiltros = () => (dispatch) => {
//     log('producaoAction limpaFiltros');

//     dispatch({
//         type: actionTypes.PRODUCAO,
//         [FILTRO_EM_PRODUCAO]: {},
//         [FILTRO_PRONTOS]: {}
//     });
// };

export const updateDadosEntregas = (quantidadeEntregas, valorTeles) => (dispatch) => {
  log('producaoAction updateDadosEntregas', { quantidadeEntregas, valorTeles });

  dispatch({
    type: actionTypes.PRODUCAO,
    quantidadeEntregas: quantidadeEntregas,
    valorTeles: valorTeles,
  });
};
