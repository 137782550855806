import { minimalUsuarioReducerTipo } from "./usuarioReducer";
import criarAction from "../factory/criarAction";
import { fecharDialog } from "../dialog/dialogActions";
import { limparPedidos } from "../pedidos/pedidosActions";
import apelidos from "../../../rotas/apelidos";
import { ClienteHttp, NavegacaoUtils } from "../../utils/utils";
import { limparCadastro as limparCadastroUsuario } from "../cadastro/cadastroUsuario/cadastroUsuarioActions";
import { limparCadastro as limparCadastroEndereco } from "../cadastro/cadastroEndereco/cadastroEnderecoActions";
import { getEmpresaApp } from "../../utils/StorageUtils/StorageUtils";
import { dispatchTipo } from "../../../../../store/actions/acaoTemplate";

const tiposDeAcoes = {
  ATUALIZAR_USUARIO: 'ATUALIZAR_USUARIO:USUARIO',
  LIMPAR_USUARIO: 'LIMPAR_USUARIO:USUARIO',
}

export default tiposDeAcoes;

export const atualizarUsuario = () => function (dispatch: any) {
  if (JSON.parse(sessionStorage.getItem('a') || '{}').u) {
    ClienteHttp.obterClienteHttp().get(ClienteHttp.getApi('getUsuarioLogado'), ClienteHttp.getHeaders()).then((resposta: any) => {
      const {
        nome,
        sobrenome,
        login,
        dadosAdicionais,
        parametrosUsuario,
      } = resposta.data;

      const carga: minimalUsuarioReducerTipo = {
        usuario: {
          nome,
          sobrenome,
          email: login.email,
          cpf: dadosAdicionais.cpf_cnpj,
          celular: dadosAdicionais.telefone,
          dataDeNascimento: dadosAdicionais.dataNascimento,
          genero: "nao_informado",
          mudouEstadoVenda: parametrosUsuario.mudouEstadoVenda,
        },
        endereco: {
          bairro: dadosAdicionais.bairro,
          cep: dadosAdicionais.cep,
          cidade: dadosAdicionais.municipio,
          codigoCidade: dadosAdicionais.codigoMunicipioIbge,
          complemento: dadosAdicionais.complemento,
          estado: dadosAdicionais.uf,
          numero: dadosAdicionais.numero,
          pais: {
            codigo: dadosAdicionais.pais._links.self.href.replace(ClienteHttp.getApi('paises/'), ''),
            iso: dadosAdicionais.pais.iso,
            nome: dadosAdicionais.pais.nome,
          },
          rua: dadosAdicionais.endereco,
        },
      }

      dispatch(criarAction(carga, tiposDeAcoes.ATUALIZAR_USUARIO));
    })
      .catch((error) => {
        dispatch(criarAction({}, tiposDeAcoes.LIMPAR_USUARIO));
      });
  }
}

export const limparUsuario = () => function(dispatch: any) {
  dispatch(criarAction(undefined, tiposDeAcoes.LIMPAR_USUARIO));
}

export const redirecionarParaCadastro = (caminho?: string) => function(dispatch: dispatchTipo) {
  dispatch(NavegacaoUtils.goTo(apelidos.cadastro, {caminho}));
  dispatch(fecharDialog());
}

export const deslogarUsuario = () => function(dispatch: any) {
  dispatch(limparPedidos());
  dispatch(limparUsuario());
  dispatch(limparCadastroUsuario());
  dispatch(limparCadastroEndereco());
  dispatch(NavegacaoUtils.goTo(`${apelidos.app}?empresa=${getEmpresaApp()}`));
}
