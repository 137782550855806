//@ts-check
import criarAction from "../factory/criarAction";

const tipoDeAcoes = {
  ATUALIZAR_PRODUTO: "ATUALIZAR_PRODUTO:CARRINHO",
  ATUALIZAR_PRODUTO_COM_TAMANHO: "ATUALIZAR_PRODUTO_COM_TAMANHO:CARRINHO",
  ATUALIZAR_UNIDADE: "ATUALIZAR_UNIDADE:CARRINHO",
  ATUALIZAR_QUANTIDADE_UNIDADE: "ATUALIZAR_QUANTIDADE_UNIDADE:CARRINHO",
  LIMPAR_CARRINHO: "LIMPAR_CARRINHO:CARRINHO",
  REMOVER_PRODUTO: "REMOVER_PRODUTO:CARRINHO",
  REMOVER_ID_UNIDADE_ATIVA: "REMOVER_ID_UNIDADE_ATIVA:CARRINHO",
  REMOVER_UNIDADE: "REMOVER_UNIDADE:CARRINHO"
};

export default tipoDeAcoes;

/**
 * 
 * @param {{id?: string, observacao?: string | null, unidade?: import("./types").carrinhoProdutoTipo, quantidade: number, grupoProdutoTamanhoId?: number, valorTotal?: number, add: boolean}} produtoCarrinho 
 * @returns {(dispatch: import("../../../../../store/actions/acaoTemplate").dispatchTipo, getState: () => import("../types").webAppReducersTipo) => void}
 */
export const atualizarCarrinho = ({id, unidade, observacao = null, quantidade, grupoProdutoTamanhoId, valorTotal, add}) => function(dispatch, getState) {
  const state = getState();
  const produtos = state.webAppProdutosReducer.produtos;
  let produto;
  
  if (unidade) {
    let unidadeId = unidade?.id || NaN;
  
    if (add)
      while (state.webAppCarrinhoReducer.produtos[unidadeId] !== undefined) {
        unidadeId = unidade.id + '_' + Math.random();
      }

    produto = {...unidade, id: unidadeId}
  }
  else {
    produto = produtos[`${id}`] || state.webAppCarrinhoReducer.produtos[`${id}`];
  }

  const acao = criarAction({
    id: id || produto.id,
    quantidade,
    produto,
    observacao,
    grupoProdutoTamanhoId: grupoProdutoTamanhoId || NaN,
    valorTotal,
  },
  tipoDeAcoes.ATUALIZAR_PRODUTO);

  dispatch(acao);
}

/**
 * 
 * @param {{quantidade: number}} carga 
 * @returns {(dispatch: import("../../../../../store/actions/acaoTemplate").dispatchTipo) => void}
 */
export const atualizarQuantidadeUnidade = (carga) => (dispatch) => {
  dispatch(criarAction(carga, tipoDeAcoes.ATUALIZAR_QUANTIDADE_UNIDADE));
};

/**
 * 
 * @param {{nomeUnidade: string, idSabor: string, quantidade: number, quantidadeMaximaSabores: number, quantidadeMinimaSabores: number, grupoProdutoTamanhoId?: number}} unidadeCarrinho 
 * @returns {(dispatch: import("../../../../../store/actions/acaoTemplate").dispatchTipo, getState: () => import("../types").webAppReducersTipo) => void}
 */
export const atualizarUnidadeCarrinho = ({nomeUnidade, idSabor, quantidade, quantidadeMaximaSabores, quantidadeMinimaSabores, grupoProdutoTamanhoId}) => function(dispatch, getState) {
  const sabor = {
    ...getState().webAppProdutosReducer.produtos[idSabor],
    quantidade,
  };

  const acao = criarAction({
    grupoProdutoTamanhoId: grupoProdutoTamanhoId || NaN,
    nome: nomeUnidade,
    quantidadeMaximaSabores,
    sabor,
  },
  tipoDeAcoes.ATUALIZAR_UNIDADE);

  dispatch(acao);
}

/**
 * 
 * @returns {(dispatch: import("../../../../../store/actions/acaoTemplate").dispatchTipo) => void}
 */
export const limparCarrinhoAction = () => function(dispatch) {
  dispatch(criarAction(undefined, tipoDeAcoes.LIMPAR_CARRINHO));
}

/**
 * 
 * @param {{id: string}} carga 
 * @returns {(dispatch: import("../../../../../store/actions/acaoTemplate").dispatchTipo) => void}
 */
export const removerDoCarrinho = (carga) => function(dispatch) {
  dispatch(criarAction(carga, tipoDeAcoes.REMOVER_PRODUTO));
}

/**
 * 
 * @returns {(dispatch: import("../../../../../store/actions/acaoTemplate").dispatchTipo) => void}
 */
export const removerIdUnidadeAtiva = () => function(dispatch) {
  dispatch(criarAction(undefined, tipoDeAcoes.REMOVER_ID_UNIDADE_ATIVA));
}

/**
 * 
 * @returns {(dispatch: import("../../../../../store/actions/acaoTemplate").dispatchTipo) => void}
 */
export const removerUnidade = () => function(dispatch) {
  dispatch(criarAction(undefined, tipoDeAcoes.REMOVER_UNIDADE));
}