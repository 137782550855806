//@ts-check
import "./Icone.css"

import React from "react"

import { ArquivoUtils, XmlUtils, SvgUtils } from "../../utils/utils"
import { IconeType } from "./types.d"

class Icone extends IconeType {
  /**
   * 
   * @type {{ paths: { datasets: string[], altura: number, largura: number }}}
   */
  state = {
    paths: {
      datasets: [],
      altura: 0,
      largura: 0,
    }
  }

  componentDidMount() {
    const listaXml = JSON.parse(localStorage.getItem("xmlIcones") || "{}");
    const id = (this.props.src || "").replace(".svg", "");
    const xml = listaXml[id];

    if (!xml) {
      ArquivoUtils.carregarArquivoLocal(this.props.src || "")
        .then((resposta) => {
          const xml = resposta.data || "";
  
          if (xml) {
            localStorage.setItem("xmlIcones", JSON.stringify({
              ...listaXml,
              [id]: xml,
            }));
            
            const objetoXml = XmlUtils.xmlParaObjetoPlano(xml);
            const paths = SvgUtils.obterPathsDatasets(objetoXml);
      
            this.setState({paths});
          }
        });
    }
    else if (this.state.paths.datasets.length <= 0) {
      const objetoXml = XmlUtils.xmlParaObjetoPlano(xml);
      const paths = SvgUtils.obterPathsDatasets(objetoXml);

      this.setState({paths});
    }
  }

  render() {
    const paths = this.state.paths;
    const color = "currentColor";

    if (paths.datasets.length <= 0) {
      return null;
    }

    return <svg
      stroke={color}
      fill={color}
      strokeWidth="0"
      width={paths.altura}
      height={paths.largura}
      viewBox={`0 0 ${paths.altura} ${paths.largura}`}
      className="ui-icone"
      xmlns="http://www.w3.org/2000/svg"
      style={{width: "auto", height: "auto", ...this.props.style}}
    >
      <g id ="g-element">
        {paths.datasets.map((d, index) => <path key={index} fill="currentColor" stroke="currentColor" d={d} />)}
      </g>
    </svg>;
  }
}

export default Icone;
