import QRCode from "qrcode";

/**
 * 
 * @param {HTMLCanvasElement | null} ref
 * @param {string} link 
 * @returns {(ref: HTMLCanvasElement | null) => void}
 */
export function getLinkToQrCodeAndShowOnCanvasRef(link: string) {
  return async (ref: HTMLCanvasElement | null, ) => {
    if (ref) {
      await QRCode.toCanvas(ref, link);
    }
  }
}