import React from "react"
import { connect } from "react-redux"
import { SkyLightStateless } from "react-skylight"

import { log } from "../../../utils/LogUtils"

import { loadSpinnerModal } from "../../UI/Spinner/Spinner"

class SpinnerSystem extends React.Component {
  constructor() {
    super();

    this.state = {
      show: false,
    }
  }

  componentDidUpdate() {

    if (this.props.spinnerShow() !== this.state.show) {
      if (this.props.spinnerShow()) {
        setTimeout(() => {
          this.setState({ show: this.props.spinnerShow() });
        }, 500);
      }
      else {
        this.setState({ show: false });
      }
    }
  }

  /**
   * Método que monta o componente.
   */
  render() {
    log('SpinnerSystem render');
    let content =
      (<div ref={ref => {
        if (ref) {
          this.notificationElement = ref.parentElement;
          ref.parentElement.id = 'Spinner';
          ref.parentElement.tabIndex = 0;
        }
      }}>
        {loadSpinnerModal()}
      </div>);

    // Monta o componente
    return <div
      className='spinner-system'
    >
      <SkyLightStateless
        ref={ref => this.dialog = ref}
        closeButtonStyle={{ fontSize: 0 }}
        closeOnEsc={false}
        dialogStyles={{
          minHeight: 'unset',
          // Necessário para ajuste fino de centralização do Spinner
          left: window.innerWidth <= '768' ? '37%' : this.props.sideDrawerShow ? '50%' : '44.5%'
        }}
        isVisible={this.state.show}
        onOverlayClicked={() => { }}
        title={null}
        titleStyle={null}
        isLoading={true}>
        {content}
      </SkyLightStateless >
    </div>;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  sideDrawerShow: state.appReducer.sideDrawerShow,
  spinnerQueue: state.appReducer.spinnerQueue,
  spinnerShow: state.appReducer.spinnerShow
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(SpinnerSystem);
