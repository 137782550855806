import React from "react"

import { getStrings } from "../../../utils/LocaleUtils"

import CadastroTable from "./CadastroTable"
import EmpresaTableModel from "./EmpresaTableModel"

import { barCodeFormatList } from "../../../store/actions/controleVenda/controleVendaAction"

/**
 * Classe que repassa para o CadastroTable as informações para a montagem da tabela dos Empresas.
 */
export default class EmpresaTable extends React.Component {
  render() {
    return (
      <CadastroTable {...this.props} objetoCadastro="empresa" filterProperties={["razaoSocial", "nomeFantasia"]}
        canToggleExpand={true}
        tableModel={EmpresaTableModel} blankFormData={{
          cnpj: "",
          inscricaoEstadual: "",
          nomeFantasia: "",
          razaoSocial: "",
          ramoEmpresa: null,
          contatoEmpresa: {
            email: "",
            nomeContato: "",
            telefone: ""
          },
          contratoEmpresa: {
            situacaoEmpresa: "Em dia",
            tipoContrato: null
          },
          enderecoEmpresa: {
            bairro: "",
            cep: "",
            complemento: "",
            endereco: "",
            municipio: "",
            numero: "",
            pais: null,
            uf: ""
          },
          parametrosEmpresa: {
            percentualComissao: 0,
            ordemFormatoCodigoBarras: barCodeFormatList.reduce((acu, cur) => ({ ...acu, [cur.persistenceName]: 0 }), {}),
            precoLivre: null,
            habilitarImpressao: false,
            nivelUsuarioSemEmpresa: null,
            obrigarPagarVendaUsuarioSemEmpresa: false,
            perguntaCpf: false,
            vendaMobiAppEncerrada: null,
          }
        }} registerHelp={getStrings().companyTableHelp} deleteMessageExtra={getStrings().companyRemove}
      />
    );
  };
}
