import React from 'react';
import { connect } from 'react-redux';

import { updateActiveMenuItem } from '../../../utils/ScreenUtils';
import { urlDatabase } from '../../../utils/SecureConnectionUtils';
import { ClienteHttp } from '../../../apps/promo_app_frontend/nucleo/utils/utils';

import CadastroBox from './CadastroBox';
import FormaPagamentoTable from '../table/FormaPagamentoTable';
import FormaPagamentoForm from '../form/FormaPagamentoForm';

/**
 * JS que define os componentes, tabela e formulário, utilizados no cadastro das Formas de Pagamento.
 * Também define a URL para acessar os dados deste tipo de objeto.
 */
class FormaPagamentoBox extends React.Component {

    constructor() {
        super();

        // Recupera e ajusta a URL para acesso aos dados
        this.urlDataBase = urlDatabase + '/formaPagamentos';
    }

    /**
     * Método executado APÓS a montagem/renderização do componente.
     */
    componentDidMount() {

        updateActiveMenuItem('menuItemFormaPagamento', 'menuItemCadastro');

        const params = new URLSearchParams(window.location.search);
        const formaPagamentoId = params.get('id');
        const notificationCode = params.get('notificationCode');

        if (formaPagamentoId && notificationCode) {

            // remove os parâmetros da url
            window.history.pushState({}, '', window.location.pathname);

            ClienteHttp.requisicaoServidor('pagseguro/applicationAuthorizationNotification', 'post', true, null, { formaPagamentoId, notificationCode });
        }
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        return (
            <CadastroBox cadastroTable={FormaPagamentoTable} cadastroForm={FormaPagamentoForm} urlDataBase={this.urlDataBase}
                // Se é necessário buscar uma lista de entidades do banco de dados ao editar este cadastro
                needFormEntities={false}
                // Se é necessário buscar mais dados deste cadastro do banco de dados ao editar este cadastro
                needFormUpdate={true} />
        );
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    ...state.idiomaReducer
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(FormaPagamentoBox);
