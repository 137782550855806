
import React from "react"
import { AxiosStatic } from "axios"
import { getTour } from "../../components/UI/ModalSystem/TourSystem"

import * as actionTypes from "./actionTypes"
import { getHeaders } from "../../utils/HeadersUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { getStateType } from "../../utils/reduxUtils/reduxUtils"
import { enableBodyScroll, getShowSideDrawerCondition } from "../../utils/ScreenUtils"
import { urlDatabase } from "../../utils/SecureConnectionUtils"

import { dispatchTipo } from "./acaoTemplate"
import { UPDATE_FLAG } from "../reducers/tabIndexReducer"
import { appReducerType } from "../reducers/appReducer"
import { DEFAULT_WEIGHTED_PRODUCT_NOT_CONFIGURED } from "./subscriberAction"


/* 
 * Necessário para o Edge, pelo menos até sair o próximo update
 * https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8993198/
 */
const axiosDefault = require("axios")
require("promise.prototype.finally").shim()

let axiosCustom: AxiosStatic = axiosDefault

/**
 * Tempo padrão de auto ocultação da notificação.
 */
export const NOTIFICATION_DISMISS_TIMEOUT_DEFAULT = 5

export type appDialogShowType = (dialogContent: React.ReactElement, dialogTitle?: string,
    dialogStyles?: React.CSSProperties, dialogTitleStyles?: React.CSSProperties,
    onCloseClicked?: () => void, onOverlayClicked?: () => void,
    dialogContentStyles?: React.CSSProperties) => {
        type: string,
        dialogShow: boolean,
        dialogContent: React.ReactNode,
        dialogTitle?: string,
        dialogStyles?: React.CSSProperties,
        dialogTitleStyles?: React.CSSProperties,
        dialogOnCloseClicked?: () => void,
        dialogOnOverlayClicked?: () => void,
        dialogContentStyles?: React.CSSProperties,
    };

/**
 * Mostra uma janela modal.
 */
export const appDialogShow: appDialogShowType = (dialogContent, dialogTitle, dialogStyles,
    dialogTitleStyles, onCloseClicked, onOverlayClicked, dialogContentStyles) => {
    log('appAction appDialogShow', { dialogContent, dialogTitle, dialogStyles
        , dialogTitleStyles, onCloseClicked, onOverlayClicked
        , dialogContentStyles })

    enableBodyScroll(false)
    const props = {
        type: actionTypes.APP_MODAL,
        dialogShow: true,
        dialogContent: dialogContent,
        dialogTitle: dialogTitle,
        dialogStyles: { height: 'auto', ...dialogStyles, minHeight: 'unset', },
        dialogTitleStyles: { ...dialogTitleStyles },
        dialogOnCloseClicked: onCloseClicked,
        dialogOnOverlayClicked: onOverlayClicked,
        dialogContentStyles: { ...dialogContentStyles  },
    }
    return props
}

/**
 * Abre Snackbar com mensagem.
 */
export const appSnackbarMessage = (message: string, undoAction: () => void) => {
    log('appAction appSnackbarMessage', { message, undoAction })

    return (dispatch: dispatchTipo) => {
        dispatch(appSnackbarClear)
        // Exibe novo Snackbar após o tempo de transição de fechamento
        setTimeout(() => {
            dispatch({
                type: actionTypes.APP_SNACKBAR_MESSAGE,
                payload: message,
                undoAction
            })
        }, 200)
    }
}

/**
 * Fecha Snackbar.
 */
export const appSnackbarClear = () => {
    log('appAction appSnackbarClear')

    return {
        type: actionTypes.APP_SNACKBAR_CLEAR
    }
}

/**
 * Inicia Tour de alguma funcionalidade do site.
 */
export const appStartTour = (tourSteps: Record<string, React.ReactNode[]>) => {
    log('appAction appStartTour', { tourSteps })

    return {
        type: actionTypes.APP_START_TOUR,
        payload: tourSteps
    }
}

/**
 * Fecha o Tour atual.
 */
export const appCloseTour = () => {
    log('appAction appCloseTour')

    return (dispatch: dispatchTipo, getState: getStateType) => {
        // Caso for o tour do site, armazena flag de acessado apenas ao encerrar o tour
        if (getState().appReducer.tourSteps !== undefined &&
            getState().appReducer.tourSteps.name === 'tourWebsite')//tourWebsite().name)
            localStorage.setItem('acessed', 'true')

        dispatch({
            type: actionTypes.APP_CLOSE_TOUR
        })
    }
}

export type appDialogHideType = () => {
    type: string,
    dialogShow: false,
}; 

/**
 * Oculta a janela modal.
 */
export const appDialogHide: appDialogHideType = () => {
    log('appAction appDialogHide')

    enableBodyScroll(true)
    return {
        type: actionTypes.APP_MODAL,
        dialogShow: false
    }
}

export type appNotificationShowType = (
    notificationMessage: string,
    notificationType: string,
    notificationTitle?: string,
    notificationAction?: () => void,
    notificationDismiss?: number,
    notificationNoAction?: () => void
    ) => Partial<appReducerType>;

/**
 * Exibe uma notificação por alguns segundos.
 * @param notificationMessage 
 * @param notificationType 
 */
export const appNotificationShow: appNotificationShowType = (
    notificationMessage,
    notificationType,
    notificationTitle,
    notificationAction,
    notificationDismiss,
    notificationNoAction) => {
    
    log("appAction appNotificationShow", { notificationMessage, notificationType, notificationTitle, notificationAction, notificationDismiss, notificationNoAction });

    enableBodyScroll(false);

    let dialogColors: React.CSSProperties = {};
    let titleColors: React.CSSProperties = {};

    switch (notificationType) {
        // SUBSTITUIR AVISOS DE SUCESSO, WARNING E INFO POR NOTIFICAÇÕES PEQUENAS NÃO MODAIS (POPUP)
        case actionTypes.APP_NOTIFICATION_TYPE_SUCCESS:
        case actionTypes.APP_NOTIFICATION_TYPE_SUCCESS_UNDOABLE:
            titleColors.color = getComputedStyle(document.body).getPropertyValue('--color-dialog-text'); //'#558b2f'
            dialogColors.color = getComputedStyle(document.body).getPropertyValue('--color-dialog-text'); // '#33691e'
            dialogColors.backgroundColor = getComputedStyle(document.body).getPropertyValue('--color-dialog-background'); //'#f1f8e9'
            break;
        case actionTypes.APP_NOTIFICATION_TYPE_WARNING:
        case actionTypes.APP_NOTIFICATION_TYPE_WARNING_QUESTION:
            titleColors.color = getComputedStyle(document.body).getPropertyValue('--color-dialog-text'); //'#ffc107'
            dialogColors.color = getComputedStyle(document.body).getPropertyValue('--color-dialog-text'); //'#827717'
            dialogColors.backgroundColor = getComputedStyle(document.body).getPropertyValue('--color-dialog-background'); //'#fff8e1'
            break;
        case actionTypes.APP_NOTIFICATION_TYPE_ERROR:
            titleColors.color = getComputedStyle(document.body).getPropertyValue('--color-dialog-text'); //'#e53935'
            dialogColors.color = getComputedStyle(document.body).getPropertyValue('--color-dialog-text'); //'#b71c1c'
            dialogColors.backgroundColor = getComputedStyle(document.body).getPropertyValue('--color-dialog-background'); //'#fbe9e7'
            break;
        case actionTypes.APP_NOTIFICATION_TYPE_INFO:
        case actionTypes.APP_NOTIFICATION_TYPE_INFO_QUESTION:
        default:
            titleColors.color = getComputedStyle(document.body).getPropertyValue('--color--dialog-text'); //'#ffc107'
            dialogColors.color = getComputedStyle(document.body).getPropertyValue('--color-dialog-text'); //'#827717'
            dialogColors.backgroundColor = getComputedStyle(document.body).getPropertyValue('--color-dialog-background'); //'#fff8e1'
    }

    return {
        type: actionTypes.APP_MODAL,
        notificationShow: true,

        notificationContent: notificationMessage,

        notificationType: notificationType,

        notificationTitleI18n: notificationTitle ? false : true,

        notificationTitle:
            notificationTitle ? notificationTitle
                : (notificationType === actionTypes.APP_NOTIFICATION_TYPE_SUCCESS) ? 'success'
                    : (notificationType === actionTypes.APP_NOTIFICATION_TYPE_ERROR) ? 'error'
                        : (notificationType === actionTypes.APP_NOTIFICATION_TYPE_WARNING || notificationType === actionTypes.APP_NOTIFICATION_TYPE_WARNING_QUESTION) ? 'warning'
                            : 'info',

        notificationDismiss:
            notificationDismiss ? notificationDismiss
                : (notificationType === actionTypes.APP_NOTIFICATION_TYPE_INFO_QUESTION || notificationType === actionTypes.APP_NOTIFICATION_TYPE_WARNING_QUESTION) ? 0
                    : (notificationType === actionTypes.APP_NOTIFICATION_TYPE_SUCCESS) ? 2
                        : NOTIFICATION_DISMISS_TIMEOUT_DEFAULT,

        ...(notificationAction ? { notificationAction } : {}),

        ...(notificationNoAction ? { notificationNoAction } : {}),

        notificationDialogColors: Object.assign({}, dialogColors, { minHeight: 'unset' }),

        notificationTitleColors: titleColors,
    };
}

export type appNotificationHideType = () => {type: string, notificationShow: false };

/**
 * Oculta a notificação.
 */
export const appNotificationHide: appNotificationHideType = () => {
    log('appAction appNotificationHide')

    enableBodyScroll(true)
    return ({
        type: actionTypes.APP_MODAL,
        notificationShow: false,
    })
}

/**
 * Mostra um *spinner* modal. Registra quem solicitou a exibição do *spinner* para só ocultá-lo
 * quando a mesma origem também solicitar sua ocultação.
 * @param origin texto representando quem originou a exibição
 */
export const appSpinnerShow = (origin: string) => {
    log('appAction appSpinnerShow', { origin })

    return (dispatch: dispatchTipo, getState: getStateType) => {
        dispatch({
            type: actionTypes.APP_MODAL,
            spinnerQueue: getState().appReducer.spinnerQueue.concat(origin)
        })
    }
}

/**
 * Oculta o *spinner* modal. Remove o registro de solicitação de exibição do *spinner* feito
 * por esta origem. Se a origem não for informada, remove todos os registros. O *spinner*
 * só será oculto quando todos os registros forem removidos.
 */
export const appSpinnerHide = (origin: string) => {
    log('appAction appSpinnerHide', { origin })

    return (dispatch: dispatchTipo, getState: getStateType) => {
        dispatch({
            type: actionTypes.APP_MODAL,
            spinnerQueue: origin ? getState().appReducer.spinnerQueue.filter((element: string) => element !== origin) : []
        })
    }
}

/**
 * Gera uma nova instância do `axios` configurada para sempre enviar um `Accept-Language` no *header*
 * que corresponde ao idioma selecionado, ou o padrão se nenhum foi informado.
 * @param locale `pt-BR`, `en-US`, etc
 */
export const updateAxiosLocale = (locale: string = 'pt-BR', noLog: boolean = false) => {
    if (!noLog) {
        log('appAction updateAxiosLocale', { locale })
    }

    axiosCustom = axiosDefault.create({
        headers: { 'Accept-Language': locale }
    })
}

/**
 * Retorna a instância do `axios` com o `Accept-Language` customizado no *header*.
 */
export const axios = (noLog?: boolean) => {
    if (!axiosCustom) {
        updateAxiosLocale('pt-BR', noLog)
    }
    return axiosCustom
}

/**
 * Define se os textos de ajuda devem ser exibidos ou não.
 * @param state 
 */
export const setHelp = (state: boolean) => {
    log('appAction setHelp', { state })

    return (dispatch: dispatchTipo) => {
        localStorage.setItem('helpState', `${state}`)
        dispatch({
            type: actionTypes.APP_HELP_TOGGLE,
            help: state
        })
    }
}

/**
 * Verifica se há no local storage a visibilidade dos textos de ajuda.
 * Se não, carrega a padrão.
 */
export const checkHelpState = () => {
    log('appAction checkHelpState')

    return (dispatch: dispatchTipo, getState: getStateType) => {
        let helpState = localStorage.getItem('helpState')
        // Buscar dados do local storage retorna strings. Precisa converter string "false" para false porque, senão, o JavaScript trata como true
        // Se for null ou undefined, manter assim porque significa que não há valor no local storage.
        if (helpState === undefined)
            localStorage.setItem('helpState', getState().appReducer.help)
        else {
            dispatch(setHelp(helpState === 'true' ? true : false))
        }
        // } else {
        // localStorage.setItem('helpState', getState().appReducer.help)
        // }
    }
}

/**
 * Fecha o SideDrawer no mobile, ou mantém aberto no Desktop.
 * @param {*} state 
 */
export const closeSideDrawerHandler = () => {
    log('appAction closeSideDrawerHandler')

    return (dispatch: dispatchTipo) => {

        enableBodyScroll(true)
        dispatch(setSideDrawerState(getShowSideDrawerCondition()))
    }
}

/**
 * Alterna o estado do SideDrawer.
 * @param {*} state 
 */
export const toggleSideDrawer = () => {
    log('appAction toggleSideDrawer')

    return (dispatch: dispatchTipo) => {
        const sideDrawerState = localStorage.getItem('sideDrawerState') === 'true' ? false : true

        // Bloquear scroll do conteúdo da pagina quando o menu estive ativo e ocupar a tela inteira
        if (sideDrawerState) {
            if (!getShowSideDrawerCondition())
                enableBodyScroll(false)
        } else
            enableBodyScroll(true)

        dispatch(setSideDrawerState(sideDrawerState))
    }
}

/**
 * Define se o SideDrawer estará aberto ou não.
 * @param {*} state 
 */
export const setSideDrawerState = (state: boolean) => {
    log('appAction setSideDrawerState', { state })

    return (dispatch: dispatchTipo) => {

        localStorage.setItem('sideDrawerState', `${state}`)
        dispatch({
            type: actionTypes.APP_SIDEDRAWER_SET,
            sideDrawerShow: state
        })
    }
}

export const updateForceCloseStore = (force: boolean) => {
    return {
        type: actionTypes.UPDATE_FORCE_CLOSE_STORE_FLAG,
        payload: force,
    }
}

/**
 * Verifica se há no local storage a visibilidade do SideDrawer.
 * Se não, carrega aberto no desktop ou fechado no mobile.
 */
export const checkSideDrawerState = () => {
    log('appAction checkSideDrawerState')

    return (dispatch: dispatchTipo) => {

        let sideDrawerState = localStorage.getItem('sideDrawerState')

        // Buscar dados do local storage retorna strings. Precisa converter string "false" para false porque, senão, o JavaScript trata como true
        // Se for null ou undefined, manter assim porque significa que não há valor no local storage.
        if (sideDrawerState === undefined)
            dispatch(closeSideDrawerHandler())
        else {
            dispatch(setSideDrawerState(sideDrawerState === 'true' ? true : false))
        }
    }
}

/**
 * Checa se é a primeira vez que o site é aberto no browser para exibir pergunta do Tour.
 */
export const checkFirstUse = () => {
    log('appAction checkFirstUse')

    return (dispatch: dispatchTipo) => {

        let acessed = localStorage.getItem('acessed')
        if (acessed === undefined || acessed === null) {
            dispatch(appNotificationShow(
                getStrings().tourFirstTime, actionTypes.APP_NOTIFICATION_TYPE_INFO_QUESTION, getStrings().welcome,
                () => dispatch(appStartTour(getTour('website'))), 0, // Ocorre no "Sim" (inicia tour do site)
                () => localStorage.setItem('acessed', 'true')))   // Ocorre no "Não" (armazena a tag de acessado)
        }
    }
}

export const updateTabIndexAction = (payload: {
    superItem?: number,
    registersItem?: number,
    controlItem?: number,
    reportsItem?: number,
    settingsItem?: number,
}) => {
    return {
        type: UPDATE_FLAG,
        payload,
    }
}

/**
 * Avisa a retaguarda de que uma mensagem foi recebida.
 * @param subscriber determina o tópico da mensagem
 * @param subject assunto da mensagem
 */
export const confirmMessage = (subscriber: string, subject: string) => (_: dispatchTipo) => {
    log('appAction confirmMessage', { subscriber, subject })

    axios().post(
        urlDatabase + '/confirmMessage',
        {},
        getHeaders({ subscriber, subject })
    ).then(() => { }).catch(() => { })
}

export const updateFullScreenAction = (fullscreen: boolean) => ({
    type: actionTypes.UPDATE_FULLSCREEN,
    payload: fullscreen,
})

export const showDefaultWeightedProductNotConfigured = () => (dispatch: dispatchTipo) => {
    dispatch(appNotificationShow(getStrings()[DEFAULT_WEIGHTED_PRODUCT_NOT_CONFIGURED], actionTypes.APP_NOTIFICATION_TYPE_ERROR));
}