import EnderecoUsuarioType from './EnderecoUsuarioType'
import upperCaseInitialChars from '../StringUtils/upperCaseInitialChars'
import getEndereco from './getEndereco'

export type ClienteIntegracao = {
  email: string,

}

export function getEnderecoEmTexto(cadastro: {enderecoEmpresa?: EnderecoUsuarioType, enderecoUsuario?: Partial<EnderecoUsuarioType>, clientePreCadastrado?: {dadosAdicionais: EnderecoUsuarioType}, usuarioCliente?: {enderecoUsuario: EnderecoUsuarioType}, clienteIntegracao?: {endereco: EnderecoUsuarioType}}, {formatoExpandido = false}: {formatoExpandido?: boolean} = {}): string | null {
  const cliente = cadastro.enderecoUsuario|| cadastro.enderecoEmpresa
      ? cadastro
      : (cadastro.clientePreCadastrado
        || cadastro.clienteIntegracao
        || cadastro.usuarioCliente
        || null
      )

  if (cliente) {
    const enderecoUsuario = getEndereco(cliente)

    if (enderecoUsuario !== null) {
      let enderecoTexto = enderecoUsuario.endereco + ', ' + enderecoUsuario.numero;

      if (enderecoUsuario.complemento) {
          enderecoTexto += ', ' + enderecoUsuario.complemento;
      }

      if (enderecoUsuario.pontoReferencia) {
          enderecoTexto += ', ' + enderecoUsuario.pontoReferencia;
      }
      
      if (enderecoUsuario.bairro) {
          enderecoTexto += ' - ' + enderecoUsuario.bairro;
      }
      
      if (enderecoUsuario.municipio) {
          const municipio = formatoExpandido
            ? `, ${enderecoUsuario.municipio} - ${enderecoUsuario.uf}, ${enderecoUsuario.cep}`
            : ` (${upperCaseInitialChars(enderecoUsuario.municipio)})`;
          if (municipio) {
            enderecoTexto += municipio;
          }
      }

      return enderecoTexto
    }
  }

  return null
}

export default getEnderecoEmTexto