import React from "react";
import styled from "styled-components";
import { TitleSub, Paragraph, Button, ConteudoSection } from ".";
import { axios } from "../../../../store/actions/appAction";
import { urlDatabase } from "../../../../utils/SecureConnectionUtils";
import { displayFlexCenter } from "../css/styles";
import Email from "./Form/Email";
import Nome from "./Form/Nome";
import Social from "./Social";

const Contato = React.forwardRef((props, ref) => {

  const emailRef = React.createRef();
  const nomeRef = React.createRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (emailRef.current && nomeRef.current) {
      axios()
        .post(urlDatabase + '/contactUs', { email: emailRef.current.value, nome: nomeRef.current.value, })
        .then(() => {
          nomeRef.current.value = '';
          emailRef.current.value = '';
        })
        .catch(error => console.log(error));
    }
  };

  return (
    <ConteudoSection ref={ref}>
      <TitleSub primary>Venha fazer parte</TitleSub>
      <Paragraph>Se interressou? Então informe seus dados para que possamos entrar em contato com você</Paragraph>
      <Form onSubmit={handleSubmit}>
        <Nome ref={nomeRef}/>
        <Email ref={emailRef}/>
        <EnviarButton type={'submit'} secondary>
          <span>Enviar</span>
        </EnviarButton>
      </Form>
      <Social />
    </ConteudoSection>
  );
});

const Form = styled.form`
  ${displayFlexCenter};
  justify-content: space-between;
  flex-direction: column;
  width: 80%;
  height: 300px;
`;

const EnviarButton = styled((props) => <Button {...props} />)`
  height: 2.4em;
  line-height: 2em;
  @media (max-width: ${(props) => props.theme.sizes.breakPointSmall}) {
    line-height: 2em;
  }
`;
export default Contato;
