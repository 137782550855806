import { createObserver } from '../observerUtils/createObserver'

export function createTeclaHandler() {
  const teclaObserver = createObserver()
  window.onkeydown = (evt: KeyboardEvent) => {
    teclaObserver.notifyAll(evt)
  }

  return {...teclaObserver}
}

export const teclaHandler = createTeclaHandler()