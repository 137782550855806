import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { reduxStateType } from "../../../utils/reduxUtils/reduxStateType"

import * as actionTypes from "../../../store/actions/actionTypes"
import * as appActions from "../../../store/actions/appAction"
import * as actions from "../../../store/actions/cadastroAction"
import * as cadastroEmpresaActions from "../../../store/actions/cadastroEmpresaAction"
import {
  STATE_CADASTRO_FORM,
  STATE_CADASTRO_LIST,
  STATE_CADASTRO_PRINT_PREVIEW,
  STATE_CADASTRO_SETUP,
  STATE_CADASTRO_PRINT_SETUP,
  cadastroReducerType
} from "../../../store/reducers/cadastroReducer"
import { dispatchTipo } from "../../../store/actions/acaoTemplate"
import { idiomaReducerType } from "../../../store/reducers/idiomaReducer"

import CadastroHeader from "../header/CadastroHeader"
import CadastroSetup from "../setup/CadastroSetup"
import CadastroPrintPreview from "../print/CadastroPrintPreview"
import CadastroPrintSetup from "../print/CadastroPrintSetup"

import "./CadastroBox.css";

export interface CadastroBoxType {
  cadastroForm: React.ElementType,
  cadastroTable: React.ElementType,
  needFormEntities: boolean,
  needFormUpdate: boolean,
  urlDataBase: string,
}

interface CadastroBoxReduxStateType extends cadastroReducerType, idiomaReducerType {}
interface CadastroBoxReduxDispatchType {
  appNotificationShow: appActions.appNotificationShowType,
  cadastroEmpresaUsuarioNovo: cadastroEmpresaActions.cadastroEmpresaUsuarioNovoType,
  cadastroSwitchUI: actions.cadastroSwitchUIType,
  cadastroSwitchUIGenerico: actions.cadastroSwitchUIGenericoType,
  cadastroUpdateFormData: actions.cadastroUpdateFormDataType,
  cadastroUpdatePage: actions.cadastroUpdatePageType,
  cleanFilter: actions.cleanFilterType,
  resetPageNumber: actions.resetPageNumberType,
}

interface CadastroBoxTypeWithReducer extends CadastroBoxType, CadastroBoxReduxStateType, CadastroBoxReduxDispatchType {}

/**
 * Classe base para a exibição dinâmica entre tabela e formulário dos cadastros,
 * todas as funções que são comuns para todos os cadastros ficam neste arquivo.
 */
class CadastroBox_ extends React.Component<CadastroBoxTypeWithReducer> {

  init = true;

  /**
   * Método executado ao clicar no botão voltar da barra de ações.
   * @param {*} e 
   */
  handleListar = () => {
    log('CadastroBox handleListar', this.props.cadastroChanged);

    // Se algum atributo foi alterado, pede confirmação para voltar
    if (this.props.cadastroChanged) {

      // Exibe a notificação solicitando a confirmação da exclusão
      this.props.appNotificationShow(getStrings().discardChangesMessage,
        actionTypes.APP_NOTIFICATION_TYPE_INFO_QUESTION, getStrings().discardChangesTitle,
        // Executa o comando para voltar à tabela de cadastros
        () => {
          this.props.cadastroSwitchUI(STATE_CADASTRO_LIST, {});
        }
      );
    }
    // Senão, volta direto
    else {
      this.props.cadastroSwitchUI(STATE_CADASTRO_LIST, {});
    }
  }

  /**
   * Depois de montar o componente, zera o estado dos cadastros e do cadastro de empresas.
   */
  componentDidMount() {
    log('CadastroBox componentDidMount');
    this.props.resetPageNumber();
    this.props.cleanFilter();
    this.props.cadastroEmpresaUsuarioNovo(null);
    this.props.cadastroSwitchUI(STATE_CADASTRO_LIST, {}, undefined, undefined, null);
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log('CadastroBox render');

    // Variavel que irá receber o componente que deve ser exibido, tabela ou formulário
    var content;

    // Monta o componente de formulário que foi recebido por parâmetro
    if ((!this.init) && (this.props.state === STATE_CADASTRO_FORM)) {

      var CadastroForm = this.props.cadastroForm;
      content = <CadastroForm
        urlDataBase={this.props.urlDataBase}
        needFormEntities={this.props.needFormEntities}
        needFormUpdate={this.props.needFormUpdate}
        handleListar={() => this.handleListar()} />;
    }

    // Monta o componente de impressão de códigos de barras/QR
    else if ((!this.init) && (this.props.state === STATE_CADASTRO_PRINT_PREVIEW)) {
      content = <CadastroPrintPreview />;
    }

    // Monta o componente de impressão de códigos de barras/QR
    else if ((!this.init) && (this.props.state === STATE_CADASTRO_PRINT_SETUP)) {
      content = <CadastroPrintSetup />;
    }

    // Monta o componente de configuração
    else if ((!this.init) && (this.props.state === STATE_CADASTRO_SETUP)) {
      content = <CadastroSetup />;
    }

    // Monta o componente de tabela que foi recebido por parâmetro
    else {
      this.init = false;

      var CadastroTable = this.props.cadastroTable;
      content = <CadastroTable
        urlDataBase={this.props.urlDataBase} />
    }

    return <div id='cadastro-box' className="box">

      {/* Header do box */}
      <CadastroHeader handleListar={this.handleListar} />

      {/* Conteúdo do box */}
      <div id='conteudo-box' className="box">
        {content}
      </div>

    </div>;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
function mapStateToProps(state: reduxStateType) {
  const props = {
    ...state.cadastroReducer,
    ...state.idiomaReducer,
  };

  return props;
}

/**
 * Mapeia as ações.
 * @param {dispatchTipo} dispatch 
 */
function mapDispatchToProps(dispatch: dispatchTipo) {
  const props: CadastroBoxReduxDispatchType = {
    cadastroEmpresaUsuarioNovo: (usuario) => dispatch(cadastroEmpresaActions.cadastroEmpresaUsuarioNovo(usuario)),
    cadastroSwitchUI: (state, cadastroDados, keepLoading, formMounted, operation) =>
      dispatch(actions.cadastroSwitchUI(state, cadastroDados, keepLoading, formMounted, operation)),
    cadastroSwitchUIGenerico: (state, cadastroDados, keepLoading, formMounted, operation) =>
      dispatch(actions.cadastroSwitchUIGenerico(state, cadastroDados, keepLoading, formMounted, operation)),
    cadastroUpdateFormData: (cadastroDados, callback) => dispatch(actions.cadastroUpdateFormData(cadastroDados, callback)),
    cadastroUpdatePage: cadastroDados => dispatch(actions.cadastroUpdatePage(cadastroDados)),
    appNotificationShow: (notificationMessage, notificationType, notificationTitle, notificationAction) =>
      dispatch(appActions.appNotificationShow(notificationMessage, notificationType, notificationTitle, notificationAction)),
    cleanFilter: () => dispatch(actions.cleanFilter()),
    resetPageNumber: () => dispatch(actions.resetPageNumber()),
  };

  return props;
};

// @ts-ignore
const CadastroBox = connect(mapStateToProps, mapDispatchToProps)(CadastroBox_) as React.ElementType<CadastroBoxType>;

/**
 * Exporta o último argumento entre parênteses.
 */
export default CadastroBox;
