import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { getStrings } from "../../../../utils/LocaleUtils";
import { log } from "../../../../utils/LogUtils";
import { blurAll } from "../../../../utils/ScreenUtils";

import {
  STATE_CADASTRO_EMPRESA_CONCLUIDO,
  STATE_CADASTRO_EMPRESA_CONTRATO,
  STATE_CADASTRO_EMPRESA_EMPRESA,
  STATE_CADASTRO_EMPRESA_USUARIO,
} from "../../../../store/reducers/cadastroEmpresaReducer";
import * as actionTypes from "../../../../store/actions/actionTypes";
import * as appActions from "../../../../store/actions/appAction";
import * as authActions from "../../../../store/actions/authAction";
import * as cadastroEmpresaActions from "../../../../store/actions/cadastroEmpresaAction";
import * as signUpActions from "../../../../store/actions/signUpAction";

import HelpParagraph from "../../../UI/HelpParagraph/HelpParagraph";
import SignUpCompanyContractForm from "./Forms/SignUpCompanyContractForm";
import SignUpCompanyCompanyForm from "./Forms/SignUpCompanyCompanyForm";
import SignUpCompanyUserForm from "./Forms/SignUpCompanyUserForm";
import SignUpHeader from "../SignUpHeader";
import BarraAcoesForm, { SUBMIT_ROLE_REGISTER_USER } from "../../../cadastros/barraAcao/BarraAcoesForm";
import WidthAwareDiv from "../../../UI/WidthAwareDiv/WidthAwareDiv";

import "./SignUpCompany.css";

/**
 * Componente de Cadastro de Usuário e Empresa.
 */
class SignUpCompany extends React.Component {
  state = { voltarTelaAnterior: false };

  /**
   * Método executando pelo botão de envio de dados. 
   */
  submitHandler = event => {
    log("SignUpCompany submitHandler", event);

    // Impede que o form redirecione para outra URL
    event.preventDefault();

    this.props.appCloseTour();
    blurAll();

    // this.props.signUpSubmit(this.props.existingUser ? null : this.props.dadosEmpresaEUsuario.usuario, this.props.dadosEmpresaEUsuario.empresa, this.props.history);
    this.props.cadastroSaveEmpresa(this.props.history, this.props.existingUser);
  }

  componentDidUpdate(prevProps) {
    if (this.props.state === STATE_CADASTRO_EMPRESA_CONCLUIDO) {
      const login = { email: this.props.dadosEmpresaEUsuario.usuario.login.email, senha: this.props.dadosEmpresaEUsuario.usuario.login.senha };
      this.props.authenticationSubmit(login);
    }
    else if (prevProps.state === STATE_CADASTRO_EMPRESA_EMPRESA && this.props.state === STATE_CADASTRO_EMPRESA_CONTRATO) {
      this.setState({ voltarTelaAnterior: true });
    }

    if (this.props.empresaStep === 3 && this.props.state !== STATE_CADASTRO_EMPRESA_USUARIO) {
      this.props.cadastroProximo();
    } 
  }

  /**
   * Método executado ANTES de "DESMONTAR" o componente.
   * Reinicia a máquina de estados e invalida o *reCaptchaToken* do reCAPTCHA.
   */
  componentWillUnmount() {
    log("SignUpCompany componentWillUnmount");

    this.props.changeState(STATE_CADASTRO_EMPRESA_CONTRATO);
    this.props.setToken(null);
  }

  render() {
    log("SignUpCompany render");

    // Verifica se o botão para avançar deve ser botão de Submit do form
    let isSubmit = (this.props.existingUser && this.props.state === STATE_CADASTRO_EMPRESA_EMPRESA) 
      || this.props.state === STATE_CADASTRO_EMPRESA_USUARIO;

    return <div className="pure-u-1-1">

      <SignUpHeader
        history={this.props.history}
      />

      {this.props.state === STATE_CADASTRO_EMPRESA_CONCLUIDO
        ? null
        : <WidthAwareDiv>
          <HelpParagraph>
            {(this.props.existingUser
              ? getStrings().signUpCompanyAsUserHelp()
              : getStrings().signUpCompanyHelp()
            ).concat(((!this.props.existingUser) && (this.props.state === STATE_CADASTRO_EMPRESA_USUARIO)) ? getStrings().signUpUserHelp[1] : [])
            }
          </HelpParagraph>
        </WidthAwareDiv>
      }

      <form id="main-form" onSubmit={(event) => {
        event.preventDefault();
        if (isSubmit)
          this.submitHandler(event);
        else if ((this.props.state === STATE_CADASTRO_EMPRESA_EMPRESA) && !this.props.cnpjValido) {
          this.props.appNotificationShow(getStrings().invalidFederalTaxNumber, actionTypes.APP_NOTIFICATION_TYPE_WARNING, getStrings().warning);
        }
        else {
          this.props.cadastroProximo();
        }
      }}>
        {this.props.state === STATE_CADASTRO_EMPRESA_CONCLUIDO
          ? null
          : <BarraAcoesForm
            addButtonDisabled={(this.props.state === STATE_CADASTRO_EMPRESA_EMPRESA
              && ((this.props.empresaStep === 0 && !this.props.filledRequiredCompanyStep0)
                || (this.props.empresaStep === 1 && !this.props.filledRequiredCompanyStep1)
                || (this.props.empresaStep === 2 && !this.props.filledRequiredCompanyStep2))
            )
              || (this.props.state === STATE_CADASTRO_EMPRESA_USUARIO
                && ((this.props.usuarioStep === 0 && !this.props.filledRequiredUserStep0)
                  || (this.props.usuarioStep === 1 && !this.props.filledRequiredUserStep1))
              )
            }
            attractBack={this.props.state === STATE_CADASTRO_EMPRESA_CONCLUIDO}
            attractSubmit={(this.props.state === STATE_CADASTRO_EMPRESA_CONTRATO)
              ? this.props.filledRequiredContract
              : (this.props.state === STATE_CADASTRO_EMPRESA_EMPRESA)
                ? this.props.filledRequiredCompany
                : (this.props.state === STATE_CADASTRO_EMPRESA_USUARIO)
                  ? (this.props.filledRequiredUser && (!!this.props.reCaptchaToken)) : false}
            handleListar={() => {
              if (this.props.state === STATE_CADASTRO_EMPRESA_CONCLUIDO) { this.props.goTo("/signUp"); }
              else { this.props.cadastroAnterior(this.props.history); }
            }}
            submitRole={SUBMIT_ROLE_REGISTER_USER}
            isSubmit={false}
            handleSubmit={this.props.empresaStep < 3
              ? () => { this.props.updateEmpresaStep(this.props.empresaStep + 1); }
              : this.props.usuarioStep < 1
                ? () => { this.props.updateUsuarioStep(this.props.usuarioStep + 1); }
                : (event) => {
                  this.submitHandler(event);
                }
            }
          />
        }

        <WidthAwareDiv>
          <div className="tela-sign-up-user">
            <div className="sub-form">
              {/* Tipo de Contrato */}
              {this.props.state === STATE_CADASTRO_EMPRESA_CONTRATO
                ? <SignUpCompanyContractForm voltarTelaAnterior={this.state.voltarTelaAnterior} />
                : null
              }
              {/* Empresa */}
              {this.props.state === STATE_CADASTRO_EMPRESA_EMPRESA
                ? <SignUpCompanyCompanyForm />
                : null
              }
              {/* Se não foi passado usuário como propriedade, monta o formulário para preenchimento dos dados do novo usuário */}
              {this.props.state === STATE_CADASTRO_EMPRESA_USUARIO
                ? <SignUpCompanyUserForm />
                : null
              }
              {/* Mostra uma tela com a mensagem de confirmação do cadastro  */}
              {/* {this.props.state === STATE_CADASTRO_EMPRESA_CONCLUIDO
                ? <SignUpConfirmation
                  email={this.props.dadosEmpresaEUsuario.usuario.login.email}
                  senha={this.props.dadosEmpresaEUsuario.usuario.login.senha}
                />
                : null
              } */}
            </div>
          </div>
        </WidthAwareDiv>
      </form>
    </div>;
  }
}

/**
 * Mapeia as propriedades do estado global para utilizar localmente. 
 * @param {any} state 
 */
function mapStateToProps(state) {
  const props = {
    ...state.idiomaReducer,
    state: state.cadastroEmpresaReducer.state,
    dadosEmpresaEUsuario: state.cadastroEmpresaReducer.cadastroEmpresaDados,
    empresaStep: state.cadastroEmpresaReducer.step,
    filledRequiredCompany: state.cadastroEmpresaReducer.filledRequiredCompany,
    filledRequiredCompanyStep0: state.cadastroEmpresaReducer.filledRequiredCompanyStep0,
    filledRequiredCompanyStep1: state.cadastroEmpresaReducer.filledRequiredCompanyStep1,
    filledRequiredCompanyStep2: state.cadastroEmpresaReducer.filledRequiredCompanyStep2,
    filledRequiredContract: state.cadastroEmpresaReducer.filledRequiredContract,
    filledRequiredUser: state.cadastroEmpresaReducer.filledRequiredUser,
    filledRequiredUserStep0: state.cadastroEmpresaReducer.filledRequiredUserStep0,
    filledRequiredUserStep1: state.cadastroEmpresaReducer.filledRequiredUserStep1,
    usuarioStep: state.signUpReducer.step,
    reCaptchaToken: state.signUpReducer.reCaptchaToken,
    tabIndex: state.appReducer.getTabIndex(),
    cnpjValido: state.cadastroEmpresaReducer.cnpjValido,
  };

  return props;
}

/**
 * Mapeia as ações para utilizar localmente. 
 * @param {any} dispatch 
 */
function mapDispatchToProps(dispatch) {
  const props = {
    appCloseTour: () => 
      dispatch(appActions.appCloseTour()),
    appNotificationShow: (notificationMessage, notificationType, notificationTitle) => 
      dispatch(appActions.appNotificationShow(notificationMessage, notificationType, notificationTitle)),
    authenticationSubmit: login => 
      dispatch(authActions.authenticationSubmit(login)),
    cadastroAnterior: () => 
      dispatch(cadastroEmpresaActions.cadastroEmpresaEUsuarioAnterior()),
    cadastroProximo: () => 
      dispatch(cadastroEmpresaActions.cadastroEmpresaEUsuarioProximo()),
    cadastroSaveEmpresa: (history, existingUser) => 
      dispatch(signUpActions.cadastroSaveEmpresa(history, existingUser)),
    changeState: state => 
      dispatch(cadastroEmpresaActions.cadastroEmpresaEUsuarioChangeState(state)),
    goTo: path => 
      dispatch(push(path)),
    setToken: reCaptchaToken => 
      dispatch(signUpActions.setToken(reCaptchaToken)),
    signUpSubmit: (usuario, empresa, history) => 
      dispatch(signUpActions.signUpCompanySubmit(usuario, empresa, history)),
    updateEmpresaStep: step => 
      dispatch(cadastroEmpresaActions.updateEmpresaStep(step)),
    updateUsuarioStep: (n) => 
      dispatch(signUpActions.updateUsuarioStep(n)),
  };

  return props;
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpCompany);