//@ts-check
import "./TamanhoTela.css"

import React from "react"
import TamanhoCard from "../../cards/TamanhoCard/TamanhoCard"

/**
 * 
 * @param {import("./types").produtoComTamanhoTelaPropsType} props 
 * @returns {JSX.Element}
 */
function TamanhosTela(props) {
  const { grupoProduto } = props;
  const { grupoProdutoTamanhoList } = grupoProduto;

  return <div
    tabIndex={0}
    id={`grupo-produto-${grupoProduto.codigo}`}
    className='tamanho-tela tela'
  >
    <div className='grupo-produto-titulo'>
      {grupoProduto.nome} ({grupoProduto.grupoProdutoTamanhoList.length}):
    </div>
    {grupoProdutoTamanhoList.map((grupoProdutoTamanho, index) => {
      return <TamanhoCard
        key={`__tamanho_${grupoProdutoTamanho.id}__`}
        grupoProduto={grupoProduto}
        grupoProdutoTamanho={grupoProdutoTamanho}
        tabIndex={index === 0 ? 0 : -1}
        ultimoCardDoGrupo={(grupoProdutoTamanhoList.length - 1) === index}
      />
    })}
  </div>
}

export default TamanhosTela;
