import React from "react"
import { connect } from "react-redux"
import { appReducerType } from "../../../store/reducers/appReducer"

type onClickType = React.MouseEventHandler<HTMLButtonElement>;

export type BarraAcoesButtonType = {
  attractSubmit?: boolean,
  buttonGridClassName?: string,
  cellClassName?: string,
  commandMethod?: onClickType,
  id?: string,
  isDisabled?: boolean,
  isPrimary?: boolean,
  isSubmit?: boolean,
  grow?: string,
  label?: React.ReactNode,
  onClick?: onClickType,
  style?: React.CSSProperties,
  texto?: string,
  title?: string,
  topStyle?: React.CSSProperties,
};

type BarraAcoesButtonReduxStateType = {
  tabIndex: -1|0,
};

type BarraAcoesButtonReduxDispatchType = {};

type BarraAcoesButtonTypeWithReducer = BarraAcoesButtonType & BarraAcoesButtonReduxStateType & BarraAcoesButtonReduxDispatchType;

/**
 * Classe que monta os botões que serão exibidos na barra de ações.
 */
const BarraAcoesButton_ = (props: BarraAcoesButtonTypeWithReducer) => {
  /**
   * Método executado ao clicar sobre qualquer botão da barra de ações.
   * Será chamada a execução do método passado por parâmetro na criação do botão.
   * @param {*} e 
   */
  const handleClick: onClickType = (e) => {
    //e.preventDefault(); //Estava impedindo a validação dos campos de forms

    // Se o botão estiver desabilitado, não executa nenhuma ação
    if (props.isDisabled) {
      return false;
    }

    const clickHandler = props.commandMethod || props.onClick

    // Chamada a execução do método passado por parâmetro na criação do botão
    if (clickHandler) {
      clickHandler(e);
      return false;
    }
  }

  // Define os CSS que devem ser utilizados, conforme os parâmetros recebidos por parâmetro
  var cellClassName;

  // Se deve ser montado um botão primário (azul)
  if (props.isPrimary) {
    // Verifica se o botão estará desabilitado
    cellClassName = 'bar-button-primary ' + (props.attractSubmit ? 'primary-shine' : '') + (props.cellClassName || '') + (props.isDisabled ? '' : ' clickable');
  }

  // Senão é montado um botão normal (cinza)
  else {
    // Verifica se o botão estará desabilitado
    cellClassName = 'bar-button ' + (props.isDisabled ? '' : ' clickable');
  }

  // Monta o componente
  return <div className={props.buttonGridClassName} style={{ ...props.topStyle, flexGrow: props.grow as any }}>
    <div className='bar-border' >
      <button
        type={props.isSubmit ? 'submit' : 'button'}
        className={`${cellClassName || ''}`}
        onClick={handleClick}
        disabled={props.isDisabled}
        tabIndex={props.tabIndex}
        id={props.id}
        title={props.title}
        style={props.style}
      >
        {props.isSubmit
          ? props.label
          : <>
            <label className='bar-button-label-icon' >
              {props.label}
            </label>
            <label className='bar-button-label-text' >
              {props.texto}
            </label>
          </>
        }
      </button>
    </div>
  </div>;
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
function mapStateToProps(state: { appReducer: appReducerType }) {
  const props: BarraAcoesButtonReduxStateType = {
    tabIndex: state.appReducer.getTabIndex(),
  };

  return props;
}

/**
 * Exporta o último argumento entre parênteses.
 */
const BarraAcoesButton = connect(mapStateToProps)(BarraAcoesButton_) as React.ElementType<BarraAcoesButtonType>;
export default BarraAcoesButton;