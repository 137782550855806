import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from "../../utils/LocaleUtils"
import { formatNumber } from "../../utils/NumberUtils"
import { listToFragment, updateActiveMenuItem } from "../../utils/ScreenUtils"

import LucratividadeTableRow from './LucratividadeTableRow';

// Chaves das colunas
import RelatorioBox, {
    ITEM_PAGAMENTO_VENDA_DIFERENCA,
    ITEM_PAGAMENTO_VENDA_PORCENTAGEM_DIFERENCA,
    ITEM_PAGAMENTO_VENDA_PORCENTAGEM_MARGEM,
    ITEM_PAGAMENTO_VENDA_PRODUTO,
    ITEM_PAGAMENTO_VENDA_QUANTIDADE,
    ITEM_PAGAMENTO_VENDA_VALOR_CUSTO_TOTAL,
    ITEM_PAGAMENTO_VENDA_VALOR_TOTAL
} from './RelatorioBox';

import * as relatorioActions from '../../store/actions/relatorioAction';

/**
 * Monta a tabela de relatório de lucratividade.
 */
class LucratividadeBox extends React.Component {

    /**
     * Lista de chaves de coluna na ordenação inicial.
     */
    columnsInitial = [
        ITEM_PAGAMENTO_VENDA_PRODUTO,
        ITEM_PAGAMENTO_VENDA_QUANTIDADE,
        ITEM_PAGAMENTO_VENDA_VALOR_TOTAL,
        ITEM_PAGAMENTO_VENDA_VALOR_CUSTO_TOTAL,
        ITEM_PAGAMENTO_VENDA_DIFERENCA,
        ITEM_PAGAMENTO_VENDA_PORCENTAGEM_DIFERENCA,
        ITEM_PAGAMENTO_VENDA_PORCENTAGEM_MARGEM,
    ];

    /**
     * Retorna o texto do cabeçalho de cada coluna de acordo com a chave da coluna.
     */
    columnHeader = column => {
        switch (column) {
            case ITEM_PAGAMENTO_VENDA_PRODUTO:
                return getStrings().product;
            case ITEM_PAGAMENTO_VENDA_QUANTIDADE:
                return getStrings().amountShort;
            case ITEM_PAGAMENTO_VENDA_VALOR_TOTAL:
                return getStrings().saleValue;
            case ITEM_PAGAMENTO_VENDA_VALOR_CUSTO_TOTAL:
                return getStrings().costValue;
            case ITEM_PAGAMENTO_VENDA_DIFERENCA:
                return getStrings().difference;
            case ITEM_PAGAMENTO_VENDA_PORCENTAGEM_DIFERENCA:
                return getStrings().differencePercentageShort;
            case ITEM_PAGAMENTO_VENDA_PORCENTAGEM_MARGEM:
                return getStrings().marginPercentage;
            default:
                return '';
        }
    }

    /**
     * Retorna o valor do rodapé (totais) de cada coluna de acordo com a chave da coluna.
     */
    columnFooter = column => {

        if (!this.props.dados.dadosMap) {
            return null;
        }

        switch (column) {
            case ITEM_PAGAMENTO_VENDA_PRODUTO:
                return getStrings().productAmountTemplate(this.props.dados.dadosMap[ITEM_PAGAMENTO_VENDA_PRODUTO]);
            case ITEM_PAGAMENTO_VENDA_QUANTIDADE:
                return formatNumber(this.props.dados.dadosMap[ITEM_PAGAMENTO_VENDA_QUANTIDADE] || 0, 2);
            case ITEM_PAGAMENTO_VENDA_VALOR_TOTAL:
                return formatNumber(this.props.dados.dadosMap[ITEM_PAGAMENTO_VENDA_VALOR_TOTAL] || 0, 2);
            case ITEM_PAGAMENTO_VENDA_VALOR_CUSTO_TOTAL:
                return formatNumber(this.props.dados.dadosMap[ITEM_PAGAMENTO_VENDA_VALOR_CUSTO_TOTAL] || 0, 2);
            case ITEM_PAGAMENTO_VENDA_DIFERENCA:
                return formatNumber(this.props.dados.dadosMap[ITEM_PAGAMENTO_VENDA_DIFERENCA] || 0, 2);
            case ITEM_PAGAMENTO_VENDA_PORCENTAGEM_DIFERENCA:
                return formatNumber(this.props.dados.dadosMap[ITEM_PAGAMENTO_VENDA_PORCENTAGEM_DIFERENCA] || 0, 2);
            case ITEM_PAGAMENTO_VENDA_PORCENTAGEM_MARGEM:
                return formatNumber(100, 2);
            default:
                return '';
        }
    }

    componentDidMount() {

        updateActiveMenuItem('menuRelatorioLucratividade', 'menuRelatorios');

        // Método usado no início da implementação do sistema de relatórios genéricos. Investigar necessidade ao substituir sistema de relatórios.
        // this.props.getRelatorio('lucratividade');

        // Inicializa as opções da geração de relatório
        this.props.sortData({ dadosList: [] }, { report: 'lucratividade' }, this.columnsInitial, {});
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {

        /**
         * Lista de linhas da tabela.
         */
        let list = null;

        /**
         * Rodapé (totais) da tabela.
         */
        let footer = null;

        /**
         * Lista de chaves de coluna na ordenação personalidada, se existir, ou inicial, se não
         */
        let columnOrder = this.props.columnOrder || this.columnsInitial;

        // Formata os dados do servidor, se existirem
        if (this.props.dados && ('dadosList' in this.props.dados)) {

            // Formata cada linha da tabela
            list = this.props.dados.dadosList.map(
                dado => <LucratividadeTableRow dadosMap={dado.dadosMap} />
            );

            // Formata o rodapé (totais) da coluna
            footer = list.length > 0 ? listToFragment(
                columnOrder.map(column => <th className={'Bold ' + (column === ITEM_PAGAMENTO_VENDA_PRODUTO ? ' TextAlign' : ' NumberAlign')}>{this.columnFooter(column)}</th>)
            ) : [];
        }

        return (
            <RelatorioBox
                header={getStrings().profitabilityReport}
                tableHeader={columnOrder.map(column => ({
                    key: column,
                    text: this.columnHeader(column),
                }))}
                list={list}
                tableFooter={footer}
                enableFilter={{
                    empresa: true,
                    origemVenda: true,
                    tipoProduto: true,
                    grupoProduto: true,
                    produto: true,
                    usuario: false,
                    estadoVenda: true,
                    estadoItemVenda: false,
                    data: true,
                    dataEntrega:false,
                    hora: true
                }}
                helpText={getStrings().profitabilityReportHelp()}
            />
        );
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {
    return {
        ...state.relatorioReducer,
        ...state.idiomaReducer
    };
};

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        getRelatorio: relatorio => dispatch(relatorioActions.getRelatorio(relatorio)),
        sortData: (dados, options, columnOrder, rowSortOptions) => dispatch(relatorioActions.sortData(dados, options, columnOrder, rowSortOptions))
    };
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(LucratividadeBox);
