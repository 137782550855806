import React from "react";
import { dispatchTipo } from "../../../../../store/actions/acaoTemplate";
import { carrinhoProdutoTipo, carrinhoUnidadeTipo } from "../../../nucleo/redux/carrinho/types";
import { infoReducerTipo } from "../../../nucleo/redux/info/infoReducer";
import { grupoProdutoReducerTipo } from "../../../nucleo/redux/produtos/grupoProdutos/grupoProdutosReducer";
import { produtoTamanhoTipo } from "../../../nucleo/redux/produtos/produtos/produtosReducer";

export type produtoComTamanhoCardPropsType = {
    unidade: carrinhoUnidadeTipo,
    dispatch: dispatchTipo,
    sabor: carrinhoProdutoTipo,
    tabIndex: number,
    saboresRestantes: number,
    ultimoCardDoGrupo: boolean,
    quantidadeMaximaSabores: number,
    quantidadeMinimaSabores: number,
    quantidadeUnidade: number,
    produtoTamanho: produtoTamanhoTipo,
    grupoProduto: grupoProdutoReducerTipo,
    infoReducer: infoReducerTipo,
    style?: React.CSSProperties,
};

export class ProdutoComTamanhoCardType extends React.Component<produtoComTamanhoCardPropsType> {};