//@ts-check
import "./CarrinhoCard.css"

import React from "react"
import { Texto, Botao, Entrada } from "../../../nucleo/nucleo"
import { connect } from "react-redux"
import { atualizarCarrinho, removerDoCarrinho } from "../../../nucleo/redux/carrinho/carrinhoActions"
import { mostrarDialog } from "../../../nucleo/redux/dialog/dialogActions"
import { Icones } from "../../../assets/icones/Icones"
import ObservacaoDialog from "../../dialogs/ObservacaoDialog/ObservacaoDialog"
import DescricaoDialog from "../../dialogs/DescricaoDialog/DescricaoDialog"
import { LocalidadeUtils } from "../../../nucleo/utils/utils"

/**
 * 
 * @param {import('./types').carrinhoCardPropsType} props 
 * @returns 
 */
function CarrinhoCard(props) {
/**
 * 
 * @param {any} id 
 * @param {string} texto 
 * @param {number} quantidadeMinima 
 * @param {number} quantidadeMaxima 
 * @param {string | null} observacao 
 */
 function atualizarQuantidade(id, texto, quantidadeMinima, quantidadeMaxima, observacao) {
  texto = texto.replace('[^0-9]*', '');

  const quantidade = texto ? parseInt(texto) : 0;

  props.atualizarCarrinho({id, quantidade: quantidade >= quantidadeMinima && quantidade <= quantidadeMaxima ? quantidade : 0, unidade: props.unidades[id], observacao});
 }

  const {
    id,
    descricao,
    nome,
    preco,
    quantidade,
    quantidadeEmEstoque,
  } = props;

  const promocao = props.promocao || {precoPromo: 0};
  const precoComDesconto = promocao.precoPromo;

  const precoStr = promocao.precoPromo > 0
    ? LocalidadeUtils.obterTraducao().currency.format(precoComDesconto)
    : LocalidadeUtils.obterTraducao().currency.format(preco); 

  const precoFinalStr = LocalidadeUtils.obterTraducao().currency.format(((promocao.precoPromo || preco) * quantidade));

  const IconeBalao = Icones.fa.FaRegComment;
  const IconeInformacao = Icones.md.MdInfoOutline;
  const IconeLixeira = Icones.go.GoTrashcan;

  return <div className={'app-card carrinho-card'}>
    <div>
      <div className='identificacao-produto'>
        <div className='info'>
          <div className='column nome'>
            <Texto className='nome'>{nome}</Texto>
          </div>
        </div>
      </div>
      <div className='painel-produto'>
        <div className='preco'>
            <div className='row com-desconto'>{precoStr}</div>
        </div>
        <div className='botoes-dialog'>
          <div>
            <Botao onClick={() => {
              props.dispatch(mostrarDialog({
                conteudo: () =>
                  <ObservacaoDialog id={id} />
              }));
            }}>
              <IconeBalao />
            </Botao>
            <Botao onClick={() => {
              props.dispatch(mostrarDialog({
                conteudo: () =>
                  <DescricaoDialog>
                    {descricao}
                  </DescricaoDialog>
              }));
            }}>
              <IconeInformacao />
            </Botao>
            <Botao onClick={() => { props.removerDoCarrinho(id); }}>
              <IconeLixeira />
            </Botao>
          </div>
        </div>
        <div className='row controle-quantidade'>
          <Botao disabled={quantidade <= 1} className='diminuir-quantidade' onClick={() => { props.atualizarCarrinho({id, quantidade: quantidade - 1, unidade: props.unidades[id], observacao: props.webAppCarrinhoReducer.produtos[id].observacao}) }}>-</Botao>
          <Entrada className='quantidade' value={quantidade} onChange={
            /**
             * 
             * @param {{target: {value: string}}} event 
             * @returns 
             */
            (event) => atualizarQuantidade(id, event.target.value, 0, quantidadeEmEstoque, props.webAppCarrinhoReducer.produtos[id].observacao)} />
          <Botao disabled={quantidade >= quantidadeEmEstoque} className='aumentar-quantidade' onClick={() => { props.atualizarCarrinho({id, quantidade: props.quantidade + 1, unidade: props.unidades[id], observacao: props.webAppCarrinhoReducer.produtos[id].observacao}) }}>+</Botao>
        </div>
      </div>
      <div className={`linha-separadora`} />
      <div className='painel-carrinho'>
        <div className='total-item'>
          {LocalidadeUtils.obterTraducao().orders.itemTotal}:
        </div>
        <div className='preco-final'>
          {precoFinalStr}
        </div>
      </div>
    </div>
  </div>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  unidades: state.webAppCarrinhoReducer.produtos,
  webAppCarrinhoReducer: state.webAppCarrinhoReducer,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   * 
   * @param {{id: string, observacao: string | null, quantidade: number, unidade?: import('../../../nucleo/redux/carrinho/types').carrinhoProdutoTipo}} carga
   * @returns 
   */
  atualizarCarrinho: (carga) => dispatch(atualizarCarrinho({...carga, add: false})),
  dispatch,
  /**
   * 
   * @param {string} id 
   * @returns 
   */
  removerDoCarrinho: (id) => dispatch(removerDoCarrinho({id})),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarrinhoCard);
