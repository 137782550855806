import React from "react"
import { connect } from "react-redux"

import { isEmpresaPainel } from "../../utils/CompanyUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"

import * as appActions from "../../store/actions/appAction"
import * as pagamentoVendasActions from "../../store/actions/controleVenda/pagamentoVendasAction"
import {
  PAYMENT_CANCEL_PAYMENT
  , PAYMENT_CANCEL_SALE
  , PAYMENT_CANCEL_SALESOURCE
} from "../../store/reducers/controleVenda/pagamentoVendasReducer"

import { Button } from "../UI/Button/Button"

const StateButton = props => <Button
  {...props}
  className={`blue-button font-color${props.className ? ` ${props.className}` : ""}`}
/>

/**
 * Monta a dialog para escolha do tipo de cancelamento de pagamento desejado.
 */
class DialogCancelPayment_ extends React.Component {

  /**
   * Método que monta o componente.
   */
  render() {
    log("DialogCancelPayment render", { tabIndex: this.props.tabIndex })

    let buttons = [
      {
        id: PAYMENT_CANCEL_PAYMENT
        , text: getStrings().selectedPayment
      }, {
        id: PAYMENT_CANCEL_SALE
        , text: getStrings().saleAndPayments
      }
    ].concat(isEmpresaPainel(this.props.ramoEmpresa)
      ? []
      : {
        id: PAYMENT_CANCEL_SALESOURCE
        , text: getStrings().saleSourceOpenSales
      }
    ).map(buttonConfig => <StateButton
      key={buttonConfig.id}
      type="button"
      id={buttonConfig.id}
      onClick={() => {
        this.props.appDialogHide()
        this.props.cancelPayment(buttonConfig.id, this.props.history)
      }}>
      {buttonConfig.text}
    </StateButton>)

    // Monta o componente
    return <>
      <h2 className="ButtonAlign">{getStrings().cancelPayment}</h2>
      <div className="dialog-horizontal-button-grid flow-buttons">
        {buttons}
      </div>
    </>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa
  , tabIndex: state.appReducer.getTabIndex()
  , ...state.idiomaReducer
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  appDialogHide: () =>
    dispatch(appActions.appDialogHide())
  , cancelPayment: (scope, history) =>
    dispatch(pagamentoVendasActions.cancelPayment(scope, history))
})

const DialogCancelPayment = connect(mapStateToProps, mapDispatchToProps)(DialogCancelPayment_)

/**
 * Exporta o último argumento entre parênteses.
 */
export default DialogCancelPayment
