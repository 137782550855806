import { log } from './LogUtils';
import { isArray } from './ComparatorsUtils';
const moment = require('moment');

/**
 * Função de comparação para ordenar uma lista em ordem crescente.
 * @param {Array} a 
 * @param {Array} b 
 */
export const ascendingSort = (a, b) => (a < b) ? -1 : (b < a) ? 1 : 0;

/**
 * Função de comparação para ordenar uma lista em ordem crescente considerando um instante de tempo no fuso horário padrão.
 * @param {string} a instante de tempo em ISO 8601
 * @param {string} b instante de tempo em ISO 8601
 */
export const ascendingUTCSort = (a, b) =>
    ascendingSort(moment.parseZone(a).utc().toISOString(), moment.parseZone(b).utc().toISOString());

/**
 * Função de comparação para ordenar uma lista em ordem decrescente.
 * @param {Array} a 
 * @param {Array} b 
 */
export const descendingSort = (a, b) => (a < b) ? 1 : (b < a) ? -1 : 0;

/**
 * Ordena um vetor por ordem natural. Ou seja, menor vem primeiro.
 * @param {Array} array vetor a ser ordenado
 * @param {Property} property propriedade dos elementos do vetor a ser comparada (número, string, etc)
 */
export const naturalSort = (array, property) => {
    log('utils naturalSort', array, property);

    if (!isArray(array)) {
        return null;
    }
    
    return array.sort((a, b) => ascendingSort(a[property], b[property]));
};

/**
 * Como `naturalSort`, mas considerando letras acentuadas etc corretamente.
 * @param {Array} array 
 * @param {String} property 
 */
export const localeSort = (array, property) => {
    log('utils localeSort', array, property);

    if(!isArray(array)) {
        return null;
    }

    return array.sort((a, b) => a[property].localeCompare(b[property]));
};