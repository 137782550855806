
import { detectFlash } from './flash_utils';
import { isIE } from './NavigatorUtils';
import * as jslibHtml5CameraPhoto from 'jslib-html5-camera-photo';

export const FACING_MODES = jslibHtml5CameraPhoto.FACING_MODES;

const CameraPhoto = isIE 
  ? function (videoEl) {
      let videoElement = videoEl;
      let stream = null;
      // eslint-disable-next-line
      let flip_horizontal = true;

      this.setFlipHorizontal = (flip_h) => {
        if ((typeof flip_h) !== 'boolean') {
          console.error('Error: flip_horizontal is boolean.');
          return;
        }

        flip_horizontal = flip_h;
      }

      this.snap = (user_callback, user_canvas, flashElement) => {// use global callback and canvas if not defined as parameter
        // take snapshot and return image data uri
        // eslint-disable-next-line
        var self = this;
        var params = {
          width: videoElement.videoWidth,
          height: videoElement.videoHeight,
          dest_width: videoElement.videoWidth, // size of captured image
          dest_height: videoElement.videoHeight, // these default to width/height
          image_format: 'png',  // image format (may be jpeg or png)
          jpeg_quality: 90,      // jpeg image quality from 0 (worst) to 100 (best)
          enable_flash: true,    // enable flash fallback,
          force_flash: false,    // force flash mode,
          fps: 30,               // camera frames per second
          upload_name: 'webcam', // name of file in upload post data
          constraints: null,     // custom user media constraints,
          swfURL: '',            // URI to webcam.swf movie (defaults to the js location)
          flashNotDetectedText: 'ERROR: No Adobe Flash Player detected.  Webcam.js relies on Flash for browsers that do not support getUserMedia (like yours).',
          noInterfaceFoundText: 'No supported webcam interface found.',
          unfreeze_snap: true,    // Whether to unfreeze the camera after snap (defaults to true)
          iosPlaceholderText: 'Click here to open camera.',
          user_callback: null,    // callback function for snapshot (used if no user_callback parameter given to snap function)
          user_canvas: null       // user provided canvas for snapshot (used if no user_canvas parameter given to snap function)
        };

        if (!user_callback) {
          console.error("Please provide a callback function or canvas to snap()");
          return;
        }

        // create offscreen canvas element to hold pixels
        var canvas = document.createElement('canvas');
        canvas.width = params.dest_width;
        canvas.height = params.dest_height;
        var context = canvas.getContext('2d');

        // create inline function, called after image load (flash) or immediately (native)
        var func = function () {
          // render image if needed (flash)
          if (this.src && this.width && this.height) {
            context.drawImage(this, 0, 0, params.dest_width, params.dest_height);
          }

          // render to user canvas if desired
          if (user_canvas) {
            var user_context = user_canvas.getContext('2d');
            user_context.drawImage(canvas, 0, 0);
          }

          // fire user callback if desired
          user_callback(
            user_canvas ? null : canvas.toDataURL('image/' + params.image_format, params.jpeg_quality / 100),
            canvas,
            context
          );
        };
      
        // flash fallback
        var raw_data = flashElement._snap();

        // render to image, fire callback when complete
        var img = new Image();
        img.onload = func;
        img.src = 'data:image/' + params.image_format + ';base64,' + raw_data;

        return null;
      }

      this._setVideoSrc = (stream, { width, height }) => {
        if ('srcObject' in videoElement) {
          videoElement.srcObject = stream;
        } else {
          // using URL.createObjectURL() as fallback for old browsers 
          videoElement.innerHTML = stream;
          videoElement.src = stream;

          if (isIE) {
            videoElement.videoWidth = width;
            videoElement.videoHeight = height;
          }
        }
      }

      this.stopCamera = () => {
        return new Promise((resolve, reject) => {
          if (stream) {
            stream.getTracks().forEach(function (track) {
              track.stop();
            });
            videoElement.src = '';
            stream = null;
            resolve();
          }
          reject(Error('no stream to stop!'));
        });
      }

      this.getNavigatorMediaDevices = () => {
        let NMDevice = null;
        let isNewAPI = !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
        let isOldAPI = !!(navigator.mozGetUserMedia || navigator.webkitGetUserMedia);
        if (isNewAPI) {
          NMDevice = navigator.mediaDevices;
        } else if (isOldAPI) {
          let NMDeviceOld = navigator.mozGetUserMedia || navigator.webkitGetUserMedia;
          // Setup getUserMedia, with polyfill for older browsers 
          // Adapted from: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia 

          let polyfillGetUserMedia = {
            getUserMedia: function (constraint) {
              return new Promise(function (resolve, reject) {
                NMDeviceOld.call(navigator, constraint, resolve, reject);
              });
            }
          };

          // Overwrite getUserMedia() with the polyfill 
          NMDevice = Object.assign(NMDeviceOld,
            polyfillGetUserMedia
          );
        }

        // If is no navigator.mediaDevices || navigator.mozGetUserMedia || navigator.webkitGetUserMedia 
        // then is not supported so return null 
        return NMDevice;
      }

      this.startCamera = (facingMode, { width, height }) => {
        width = width || 800;
        height = height || 600;

        return this.stopCamera()
          .then(() => { })
          .catch(() => { })
          .then(() => {
            return new Promise((resolve, reject) => {
              const constraints = {
                video: { facingMode, width, height },
                audio: false,
              }

              this.getNavigatorMediaDevices().getUserMedia(constraints)
                .then((strm) => {
                  stream = strm;
                  this._setVideoSrc(stream, { width, height });
                  (isIE && detectFlash)
                    ? resolve({
                      getVideoTracks: () => [stream],
                    })
                    : resolve(stream);
                }).catch((error) => {
                  reject(error);
                })
            })
          })
      }
    }
  : jslibHtml5CameraPhoto.default;

export default CameraPhoto;