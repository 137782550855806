export const UPDATE_HOLIDAY = 'UPDATE:HOLIDAY';
export const REMOVE_HOLIDAY = 'REMOVE:HOLIDAY';

const initialState = {};

const mapActions = {
    [UPDATE_HOLIDAY]: ({id, data}, state) => {
        return  {
            ...state,
            [id]: {
                ...state[id] || {},
                ...data,
            },
        }
    },
    [REMOVE_HOLIDAY]: (id, state) => {
        const newState = {...state};

        delete newState[id];

        return newState;
    },
};

const holidaysReducer = (state = initialState, {type, payload}) => {
    const action = mapActions[type];

    if (action) {
        return action(payload, state);
    }
    
    return state;
};

export default holidaysReducer;
