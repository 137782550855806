import criarReducer, { acaoTipo } from "../factory/criarReducer"
import tiposDeAcoes from "./barraNavegacaoActions";

export type barraNavegacaoReducerTipo = {
  mostrar: boolean,
}

const estadoInicial: barraNavegacaoReducerTipo = {
  mostrar: true,
}

const acoesNomeadas: any = {
  [tiposDeAcoes.MOSTRAR_BOTOES_FLUTUANTES]: (_: undefined, estadoAtual: barraNavegacaoReducerTipo) => {
    const novoEstado = {
      ...estadoAtual,
      mostrar: true,
    }

    return novoEstado;
  },
  [tiposDeAcoes.ESCONDER_BOTOES_FLUTUANTES]: (_: undefined, estadoAtual: barraNavegacaoReducerTipo) => {
    const novoEstado = {
      mostrar: false,
    }

    return novoEstado;
  },
  [tiposDeAcoes.MUDAR_ESTADO_MOSTRAR_BOTOES_FLUTUANTES]: ({mostrar}: {mostrar: boolean}, estadoAtual: barraNavegacaoReducerTipo) => {
    const novoEstado = {
      ...estadoAtual,
      mostrar,
    }

    return novoEstado;
  },
}

export default function(estado: barraNavegacaoReducerTipo = estadoInicial, acao: acaoTipo) {
  return criarReducer(estado, acao, acoesNomeadas);
}
