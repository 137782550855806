//@ts-check
import "./ProdutosComTamanhoTela.css"

import React from "react"
import { ClienteHttp, EntidadeUtils, LocalidadeUtils, NavegacaoUtils, OrdenacaoUtils, ProdutoUtils } from "../../../nucleo/utils/utils"
import { connect } from "react-redux"
import { definirBotaoPrincipal } from "../../../nucleo/redux/botoes/botaoPrincipal/botaoPrincipalActions"
import { atualizarInfo } from "../../../nucleo/redux/info/infoActions"
import apelidos from "../../../rotas/apelidos"
import { atualizarCarrinho, atualizarQuantidadeUnidade, removerUnidade } from "../../../nucleo/redux/carrinho/carrinhoActions"
import { getEmpresaApp } from "../../../nucleo/utils/StorageUtils/StorageUtils"
import BarraDeBusca from "../../barras/BarraDeBusca/BarraDeBusca"
import { definirBotaoVoltar } from "../../../nucleo/redux/botoes/botaoVoltar/botaoVoltarActions"
import { mostrarDialog } from "../../../nucleo/redux/dialog/dialogActions"
import QuantidadeDialog from "../../dialogs/QuantidadeDialog/QuantidadeDialog"
import AvisoDialog from "../../dialogs/AvisoDialog/AvisoDialog"
import ProdutoComTamanhoCard from "../../cards/ProdutoComTamanhoCard/ProdutoComTamanhoCard"
import { ProdutosComTamanhoTelaType } from "./types.d"

class ProdutosComTamanhoTela extends ProdutosComTamanhoTelaType {
  componentDidMount() {
    const { grupoProduto } = this.props;
    this.props.dispatch(atualizarInfo({info: ""}));
    this.props.dispatch(definirBotaoPrincipal({
      executarAcao: () => {

        const quantidadeMinimaSabores = this.props.grupoProdutoTamanho?.quantidadeMinimaSabores || 0;
        const sabores = Object.values(this.props.sabores).filter(sabor => sabor.quantidade > 0);

        if (sabores.length >= quantidadeMinimaSabores) {
          this.props.dispatch(mostrarDialog({
            conteudo: () =>
              <QuantidadeDialog onOk={() => {
                const media = grupoProduto.precoProdutoCombinado === 'media';
                const quantidade = [0, ...sabores.map(sabor => sabor.quantidade)].reduce((soma, valorTotal) => soma + valorTotal);
                const tamanhoId = EntidadeUtils.getIdFromEntity(this.props.grupoProdutoTamanho?.tamanho || {});
                const {precoUnitario, valorTotal} = ProdutoUtils.calcularValorTotalProdutoComTamanho(media, sabores, tamanhoId, quantidade, this.props.unidade.quantidade);
                const sabor = sabores[0];

                /**
                 * 
                 * @type {import('../../../nucleo/redux/carrinho/types').carrinhoProdutoTipo}
                 */
                const unidade = {
                  id: this.props.nomeUnidade,
                  key: "",
                  codigo: "",
                  codigoImpresso: "",
                  descricao: null,
                  observacao: null,
                  fonteImagem: "",
                  fonteImagemTipo: "",
                  preco: precoUnitario,
                  quantidadeEmEstoque: ProdutoUtils.obterQuantidadeEmEstoque(),
                  grupoProdutosCodigo: this.props.grupoProduto.codigo,
                  produtoTamanhoList: sabor?.produtoTamanhoList ||  [],
                  nome: this.props.grupoProduto.nome + " " + (this.props.grupoProdutoTamanho?.tamanho?.nome || ""),
                  quantidade: this.props.unidade.quantidade,
                  valorTotal,
                  unidadeUri: ClienteHttp.getApi("produtos/" + sabor?.id),
                  mostrar: true,
                };

                sabores.forEach((sabor) => {
                  unidade.nome += " " + (sabores.length === 1
                    ? sabor.nome
                    : `(${sabor.quantidade}/${quantidade} ${sabor.nome})`);
                });

                this.props.dispatch(atualizarCarrinho({
                  unidade,
                  quantidade: unidade.quantidade,
                  valorTotal,
                  add: true,
                  observacao: null,
                }));
                
                this.props.dispatch(NavegacaoUtils.goTo(`${apelidos.app}?empresa=${getEmpresaApp()}&cg=${grupoProduto.codigo}`));
              }}
              onCancel={() => this.props.dispatch(atualizarQuantidadeUnidade({quantidade: 1}))}/>
          }));
        }
        else {
          this.props.dispatch(mostrarDialog({conteudo: () => <AvisoDialog mensagem={`${LocalidadeUtils.obterTraducao().phrases.minimumFlavorQuantity(quantidadeMinimaSabores)}`} />}));
        }
      },
    }));

    this.props.dispatch(definirBotaoVoltar({
      botaoVoltarCustomizado: () => { this.props.dispatch(NavegacaoUtils.goTo(`${apelidos.app}?empresa=${getEmpresaApp()}&cg=${grupoProduto.codigo}`)) }
    }));

  }

  componentWillUnmount() {
    this.props.dispatch(atualizarInfo({info: ""}));
    this.props.dispatch(removerUnidade());
  }

  render() {
    /**
     * 
     * @type {import('./types').produtosComTamanhoTelaTipo}
     */
    const props = this.props;
    
    if (!props.grupoProduto) {
      props.dispatch(NavegacaoUtils.goTo(`${apelidos.app}?empresa=${getEmpresaApp()}`));

      return null;
    }

    const {
      grupoProduto,
      grupoProdutoTamanho,
      busca,
    } = props;

    if (grupoProdutoTamanho === undefined) {
      return null;
    }

    const quantidadeMinimaSabores = grupoProdutoTamanho?.quantidadeMinimaSabores || 0;
    const quantidadeMaximaSabores = grupoProdutoTamanho?.quantidadeMaximaSabores || 0;
    const unidade = props.unidade;
    const sabores = props.sabores;

    return <div
        key="tela"
        tabIndex={0}
        id={`grupo-produto-${grupoProduto.codigo}`}
        className="produtos-com-tamanho-tela tela unidade"
      >
        <div className="grupo-produto-header">
          <div className="grupo-produto-titulo">
            {`Escolha os sabores`}
          </div>
          <div className="grupo-produto-quantidades">
            {`${LocalidadeUtils.obterTraducao().minimumAndMaximumAmount(quantidadeMinimaSabores, quantidadeMaximaSabores)}`}
          </div>
          <BarraDeBusca telaTamanhos={true} />
        </div>
        {sabores.filter(sabor => sabor.nome.toLowerCase().includes(busca.toLowerCase())).map((sabor, index) => {
          const produtoTamanho = sabor.produtoTamanhoList.find(produtoTamanho => EntidadeUtils.getIdFromEntity(produtoTamanho.tamanho) === EntidadeUtils.getIdFromEntity(grupoProdutoTamanho.tamanho));

          if (produtoTamanho === undefined) {
            return null;
          }
          else {
            return <ProdutoComTamanhoCard
              key={sabor.key}
              unidade={unidade}
              tabIndex={index === 0 ? 0 : -1}
              ultimoCardDoGrupo={(sabores.length - 1) === index}
              sabor={sabor}
              saboresRestantes={unidade.saboresRestantes}
              quantidadeMinimaSabores={quantidadeMinimaSabores}
              quantidadeMaximaSabores={quantidadeMaximaSabores}
              produtoTamanho={produtoTamanho}
              grupoProduto={grupoProduto}
              quantidadeUnidade={unidade.quantidade}
            />
          }
        })}
      </div>
    }
}

/**
 * 
 * @param {import("../../../nucleo/redux/types").webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  const carrinho = state.webAppCarrinhoReducer;
  const grupoProdutos = state.webAppGrupoProdutosReducer;
  const grupoProduto = grupoProdutos.grupos[grupoProdutos.grupoId];
  const grupoProdutoTamanho = grupoProduto?.grupoProdutoTamanhoList
    .find((grupoProdutoTamanho) => grupoProdutoTamanho.id === carrinho.grupoProdutoTamanhoId);
    const unidade = carrinho.unidade;
  const sabores = OrdenacaoUtils.ordenarArrayDeObjetosPorStringItem(
    Object.values(unidade.sabores),
    'nome'
  );
  const props = {
    grupoProduto,
    grupoProdutoTamanho,
    nomeUnidade: carrinho.unidadeAtual,
    sabores,
    unidade: carrinho.unidade,
    busca: grupoProdutos.busca,
  };

  return props;
};

/**
 * 
 * @param {import("../../../../../store/actions/acaoTemplate").dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProdutosComTamanhoTela);
