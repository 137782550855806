import React from 'react';
import criarReducer, { acaoTipo } from '../factory/criarReducer';
import tiposDeAcoes from './overlayActions';

export type overlayReducerTipo = {
    componente: React.ElementType|null,
};

const estadoInicial = {
    componente: null,
}

const acoesNomeadas: {[key: string]: (carga: any, estadoAtual?: overlayReducerTipo) => overlayReducerTipo} = {
    [tiposDeAcoes.ATUALIZAR_OVERLAY]: ({componente}: {componente: React.ElementType}, estadoAtual) => {
        const novoEstado: overlayReducerTipo = {
            ...estadoAtual,
            componente,
        };

        return novoEstado;
    }
    , [tiposDeAcoes.LIMPAR_OVERLAY]: () => {
        const novoEstado = estadoInicial;

        return novoEstado;
    }
}

export default (estado: overlayReducerTipo = estadoInicial, acao: acaoTipo) => criarReducer(estado, acao, acoesNomeadas);
