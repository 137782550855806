//@ts-check
import React from "react"
import { connect } from "react-redux"
import { ClienteHttp } from "../../../apps/promo_app_frontend/nucleo/utils/utils"
import { appDialogHide } from "../../../store/actions/appAction"
import { cadastroUpdateFormData, handleRoleUserListChange } from "../../../store/actions/cadastroAction"
import * as actionTypes from "../../../store/actions/actionTypes"
import { clear, updateFormData, updateUsuarioStep } from "../../../store/actions/signUpAction"
import SignUpUserForm from "../../Telas/SignUp/SignUpUserForm"
import Icones from "../../../assets/icones/Icones"

/**
 * 
 * @param {import("./types").dialogCargoNovoUsuarioPropsType} props 
 * @returns {JSX.Element}
 */
function DialogCargoNovoUsuario_(
  {
    limpaEmailUsuario,
    cadastroUpdateFormData,
    cadastroDados,
    operation,
    handleRoleUserListChange,
    appDialogHide,
    formRequiredFilled,
    signUpFormData,
    email,
    step,
    updateUsuarioStep,
    clearFormData,
    updateFormData,
  }) {

  function nextButtonOnclick() {
    if (formRequiredFilled) {
      if (step < 1) {
        updateUsuarioStep(step + 1);
      }
      else {
        ClienteHttp.requisicaoServidor("auth/signUp", "post", false, null, {
          usuario: signUpFormData
        })
          .then((_) => {
            if (operation === actionTypes.CADASTRO_NEW) {

              // Verifica se há um email digitado e se ele é válido
              if (email) {

                // Verifica se o email já não está na lista
                if (cadastroDados.usuarioList.findIndex(
                  //@ts-ignore
                  (usuario) => usuario.login.email === email) < 0) {
                  // Atualiza a lista de usuários do colaborador
                  cadastroUpdateFormData(
                    Object.assign(
                      {},
                      cadastroDados,
                      {
                        usuarioList: cadastroDados.usuarioList.concat({
                          login: {
                            email: email
                          }
                        })
                      }
                    )
                  );
                  // Limpa o campo de email
                  limpaEmailUsuario();

                  // // Agenda o método que ajusta o brilho do botão de confirmação e dialog de alterações pendentes.
                  // // Provavelmente não seria necessário se lógica de cadastro e do InputCustomizado fosse refeita.
                  // setTimeout(this.props.handleChange, 0);
                }
                // Se email já está na lista, age como se tivesse inserido.
                else {
                  limpaEmailUsuario();
                }
              }
            }
            // Se for um cadastro persistido, persiste imediatamente o novo usuário.
            else {
              handleRoleUserListChange(email, true, limpaEmailUsuario);
            }
            const pais = signUpFormData.dadosAdicionais.pais;
            const paisOption = signUpFormData.dadosAdicionais.paisOption;
            clearFormData();
            updateFormData(['dadosAdicionais', 'pais'], pais);
            updateFormData(['dadosAdicionais', 'paisOption'], paisOption);
            updateFormData(['parametrosUsuario', 'aceitarNotificacoes'], true);
            updateUsuarioStep(0);
            appDialogHide();
          });
      }
    }
  }

  const MdCheck = Icones.md.MdCheck;

  return <div>
    <SignUpUserForm
      //@ts-ignore
      ref={(signUpUserForm) => {
        if (signUpUserForm) {
          const emailInput = signUpUserForm.email.inputComponent;
          emailInput.value = email;
        }
      }}
    />
    <div className="flex" style={{margin: '20px 5px 0px 8px'}}>
      <div className="flex flex-row flex-nowrap justify-content-start full-width">
        {
          step < 1
            ? null
            : <button
              key={"dialog-back-button"}
              style={{
                background: "var(--color-secondary)",
                color: "white",
                width: "7.5em",
                height: "2.25em",
                fontSize: "1.15em"
              }}
              onClick={() => {
                updateUsuarioStep(step - 1);
              }}
            >
              {"<"}
            </button>
        }
      </div>
      <div className="flex flex-row flex-nowrap justify-content-end full-width">
        <button
          disabled={!formRequiredFilled}
          style={{
            background: "var(--color-secondary)",
            color: !formRequiredFilled ? "gray" : "white",
            width: "7.5em",
            height: "2.25em",
            fontSize: "1.15em",
            opacity: !formRequiredFilled ? "0.2" : undefined,
          }}
          onClick={nextButtonOnclick}
        >
          {step === 1 ? <MdCheck/> : ">"}
        </button>
      </div>
    </div>
  </div>;
}

//@ts-ignore
function mapStateToProps(state) {
  const props = {
    cadastroDados: state.cadastroReducer.cadastroDados || { usuarioList: [] },
    email: state.signUpReducer.formData.login.email,
    formRequiredFilled: state.signUpReducer.formRequiredFilled,
    operation: state.cadastroReducer.operation,
    signUpFormData: state.signUpReducer.formData,
    step: state.signUpReducer.step
  };

  return props;
}

/**
 * 
 * @param {import("../../../store/actions/acaoTemplate").dispatchTipo} dispatch 
 * @returns 
 */
function mapDispatchToProps(dispatch) {
  const props = {
    appDialogHide: () => dispatch(appDialogHide()),
    //@ts-ignore
    cadastroUpdateFormData: (cadastroDados) => dispatch(cadastroUpdateFormData(cadastroDados)),
    /**
     * 
     * @param {string} email 
     * @param {boolean} isValid 
     * @param {() => void} onAdded 
     * @param {boolean} remove 
     * @returns 
     */
    handleRoleUserListChange: (email, isValid, onAdded, remove = false) => dispatch(handleRoleUserListChange(email, isValid, onAdded, remove)),
    /**
     * 
     * @param {number} step 
     * @returns 
     */
    updateUsuarioStep: (step) => dispatch(updateUsuarioStep(step)),
    clearFormData: () => dispatch(clear()),
    /**
     * 
     * @param {string[]} keyArray 
     * @param {string} value 
     * @returns 
     */
    updateFormData: (keyArray, value) => dispatch(updateFormData(keyArray, value)),
  };

  return props;
}

const DialogCargoNovoUsuario = connect(mapStateToProps, mapDispatchToProps)(DialogCargoNovoUsuario_);

export default DialogCargoNovoUsuario;