import "./estilo/BotaoFlutuante.css"

import React from "react"
import Botao from "../../botoes/Botao/Botao"


type propsPadrao = {
  children: React.ReactNode,
  quantidadeItens: number,
  onClick: (event: any) => void,
}

class BotaoFlutuante extends React.Component<propsPadrao> {
  render() {
    return <Botao className="botao-flutuante" onClick={this.props.onClick}>
      {this.props.quantidadeItens > 0
        ? <div key={"__dev_quant_itens__"} className={"quantidade-itens"} children={this.props.quantidadeItens} />
        : null
      }
      {this.props.children}
    </Botao>;
  }
}

export default BotaoFlutuante;
