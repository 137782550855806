import React from 'react';

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import CadastroTable from './CadastroTable';
import OptionButtons from '../optionButtons/OptionButtons';
import FormaPagamentoTableModel from './FormaPagamentoTableModel';
import BotaoChecado from '../../UI/botoes/BotaoChecado/BotaoChecado';

/**
 * Classe que repassa para o CadastroTable as informações para a montagem da tabela das Formas de Pagamento.
 */
export default class FormaPagamentoTable extends React.Component {

    render() {
        log('FormaPagamentoTable render');
        return <CadastroTable {...this.props} objetoCadastro='formaPagamento' sortProperty={'nome'} filterProperties={['nome', 'codigo']} tableModel={FormaPagamentoTableModel}
            blankFormData={{
                enabled: true,
                codigo: '',
                nome: '',
                gatewayPagamento: null,                
                emiteDocumentoFiscal: true,
            }} registerHelp={getStrings().paymentMethodTableHelp}
        />;
    }
}

/**
 * Classe que realiza a montagem das linhas da tabela das Formas de Pagamento.
 */
export class FormaPagamentoTableRow extends React.Component {

    /**
     * Método que executa a montagem do componente.
     */
    render() {
        log('FormaPagamentoTableRow render');

        if (this.props.cadastro.codigo === 'MOB_GTW_PAG') {
            return null;
        }

        let id = `${getURIFromEntity(this.props.cadastro)}/Checkbox`;

        let idEmite = `${getURIFromEntity(this.props.cadastro)}/Checkbox`;

        return <tr>

            {/* Opções de manutenção */}
            <OptionButtons className='ButtonAlign' onUpdate={this.props.onUpdate} onDelete={this.props.onDelete} cadastro={this.props.cadastro} />

            {/* Dados do cadastro */}
            <td className='Lighter ButtonAlign' width='5%' >
                <BotaoChecado key={`__${id}__`} desativado={!this.props.cadastro.enabled} />
            </td>
            <td className='Lighter TextAlign' width='20%' >{this.props.cadastro.codigo}</td>
            <td className='Lighter TextAlign' width='60%' >{this.props.cadastro.nome}</td>
            <td className='Lighter ButtonAlign' width='15%' >
                <BotaoChecado id={idEmite} type='checkbox' name={idEmite} disabled={true} desativado={!this.props.cadastro.emiteDocumentoFiscal} />
            </td>

        </tr>;
    }
}
