import { axios } from "../store/actions/appAction"
import { PERMISSAO_DEV } from "./AuthUtils"
import { getUrl } from "./SecureConnectionUtils"
import { isBoolean } from "./ComparatorsUtils"

const storage =
  sessionStorage

/**
 * 
 * @param {string} log 
 * @returns {boolean}
 */
const isInBlackList = (log) =>
  /subscriberAction|STOMPManager/.test(log)

export const MOSTRAR_LOG_REMOTO_RECEBIDO = 
  !!JSON.parse(storage.getItem("showRemoteLogReceived") || "false")

export const remote_log_viewer_enabled = 
  !!JSON.parse(storage.getItem("remoteLogEnabled") || "false")

/** 
 * Se deve exibir chamadas para `console.log`. 
 */
export const enableLog = () =>
  JSON.parse(storage.getItem("logEnabled") || "false")

export const remote_log_viewer_url =
  (PERMISSAO_DEV && remote_log_viewer_enabled && enableLog())
    ? getUrl("/log_api/view", 9089)
    : ""

/**
 * Filtro de log salvo no storage
 * @returns {boolean}
 */
export const logFilterEnabled = () =>
  !!JSON.parse(storage.getItem("logFilterEnabled") || "false")

/** 
 * Exibe mensagens no console se variável estiver habilitada. 
 * @param {boolean|string} show // parâmetro opcional. quando logFilterEnabled estivér habilitado só irá mostrar se show for true. 
 * @param {any} args  
 */
export const log = (show, ...args) => {
  const filter = !logFilterEnabled()
    ? true
    : isBoolean(show)
      ? show
      : false

  if (!isBoolean(show)) {
    args = [show, ...args]
  }

  if (enableLog()) {
    if (filter && !isInBlackList(args[0])) {
      if (args.includes("error")) {
        console.error(...args)
      } else if (args.includes("warning")) {
        console.warn(...args)
      } else {
        console.debug(...args)
      }

      const headers = {
        Accept: "application/x-spring-data-verbose+json,application/json,text/plain,*/*"
        , "Content-Type": "application/json"
      }

      if (remote_log_viewer_enabled && !isInBlackList(args[0])) {
        axios(true).post(
          remote_log_viewer_url,
          {
            message: `${(new Date()).toISOString()} ${JSON.stringify(args[0])}`
          },
          // @ts-ignore
          headers
        ).then(() => { }).catch(() => { })
      }
    }
  }
}