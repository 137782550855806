import { getStrings } from '../LocaleUtils'

export type addDateSeparatorOptionType = {
  dateFormat?: string,
  separator?: string,
}

export function addDateStringSeparator(date?: string | null, {dateFormat = getStrings().dateFormat, separator = '/'}: addDateSeparatorOptionType = {}) {
  let newDate = ''
  
  const dateArray = (date || '').split('')

  if ((date || '').length > dateFormat.replace(separator, '').length) {
    return null
  }

  let countSeparators = 0

  dateArray.forEach((dateVal, index) => {
    const dateFormatChar = dateFormat[index + countSeparators]

    if (dateFormatChar === separator && dateVal !== separator) {
      newDate += '/'
      countSeparators += 1
    }

    newDate += dateVal
  })

  return newDate
}