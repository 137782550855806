import React from 'react';
import { connect } from 'react-redux';

import { getAppEmpresa } from "../../../utils/AppUtils"
import { equalsCoerced } from "../../../utils/ComparatorsUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"

import AtivoInputDefault from '../cadastroInputDefault/AtivoInputDefault.js'
import CadastroForm from './CadastroForm';
import CodigoInputDefault from '../cadastroInputDefault/CodigoInputDefault.js'
import InputCustomizado from '../../UI/Input/InputCustomizado';
import NomeInputDefault from '../cadastroInputDefault/NomeInputDefault.js'

let estadoProntoProducaoInput = null;

/**
 * Classe que repassa para o CadastroForm as informações para a montagem do formulário para o cadastro dos Tipos de Produto.
 */
class TipoProdutoForm extends React.Component {
    render() {
        log('TipoProdutoForm render');
        return <CadastroForm {...this.props} formModel={TipoProdutoValues}
            registerSaveHelp={getStrings().productTypeHelp} registerUpdateHelp={getStrings().productTypeHelp} />;
    }
}

/**
 * Classe que realiza a montagem do formulário para o cadastro dos Tipos de Produto.
 */
class TipoProdutoValues extends React.Component {

    constructor() {
        super();
        log('TipoProdutoValues constructor');

        this.enabled = true;
        this.ismounted = false;
    }

    /**
     * Retorna se os campos obrigatórios (required) foram todos preenchidos
     */
    checkRequired = newData => {
        log('TipoProdutoValues checkRequired', newData);
        return (newData.codigo !== '') && (newData.nome !== '');
    }

    /**
     * Recupera os dados do formulário.
     */
    getFormData = () => {
        let formData = {
            empresaSet: [getAppEmpresa()],
            enabled: this.enabled.getValue(),
            codigo: this.codigo.getValue(),
            nome: this.nome.getValue(),
            producaoUnitaria: this.producaoUnitaria.inputComponent.checked,

            estadoProntoProducao: estadoProntoProducaoInput.inputComponent.checked,
            ...(this.props.cadastroDados._links ? { _links: this.props.cadastroDados._links } : {})
        };
        log('TipoProdutoValues getFormData', { formData });
        return formData;
    }

    /**
     * Método executado ao clicar no botão "Gravar".
     * Recupera os dados do formulário e repassa para o método que irá persistir os dados.
     */
    handleGravar = () => {
        log('TipoProdutoValues handleGravar');

        // Repassa para o método que irá persistir os dados
        this.props.onSave(this.getFormData());
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro
     */
    wasChanged = (newData, oldData) => {
        let result = !equalsCoerced(oldData, newData, (oldValue, newValue) => (newValue.enabled === oldValue.enabled) && (newValue.codigo === oldValue.codigo) && (newValue.nome === oldValue.nome) && (newValue.producaoUnitaria === oldValue.producaoUnitaria));
        log('TipoProdutoValues wasChanged', { newData, oldData, result });
        return result;
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro, comparando com os dados sendo editados.
     */
    wasChangedFromCurrent = newData => {
        log('TipoProdutoValues wasChangedFromCurrent', newData);
        return this.wasChanged(newData, this.props.cadastroDados);
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro, comparando com os dados presentes ao abrir a criação/edição do cadastro.
     */
    wasChangedFromInitial = newData => {
        let wasChanged = this.wasChanged(newData, this.props.cadastroDadosIniciais);
        log('TipoProdutoValues wasChangedFromInitial', { newData, wasChanged });
        return wasChanged;
    }

    /**
     * Método executado APÓS a montagem/renderização do componente.
     * Define os valores iniciais das opções conforme cadastro carregado.
     */
    componentDidMount() {
        log('TipoProdutoValues componentDidMount');
        this.ismounted = true;
        this.componentDidUpdate();
    }

    /**
     * Método executado APÓS a atualização do componente.
     * Define os valores iniciais das opções conforme cadastro carregado.
     * 
     * Os dados são populados aqui ao invés de no `render`>`InputCustomizado`>`defaultValue`
     * somente para seguir o padrão das outras telas.
     */
    componentDidUpdate() {
        log('TipoProdutoValues componentDidUpdate');

        if (this.props.cadastroDados) {

            // Se for passado false, é false; se for passado true, null, undefined ou não for passado, é true
            this.enabled.inputField.inputComponent.checked = this.props.cadastroDados.enabled !== false;

            this.codigo.inputField.inputComponent.value = this.props.cadastroDados.codigo ? this.props.cadastroDados.codigo : null;

            this.nome.inputField.inputComponent.value = this.props.cadastroDados.nome ? this.props.cadastroDados.nome : null;

            this.producaoUnitaria.inputComponent.checked = this.props.cadastroDados.producaoUnitaria;

            estadoProntoProducaoInput.inputComponent.checked = this.props.cadastroDados.estadoProntoProducao || false;
        }
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('TipoProdutoValues render');
        return <div className='sub-form'>

            <AtivoInputDefault tabIndex={this.props.tabIndex} ref={input => this.enabled = getReduxWrappedComponent(input)} handleInputValidado={this.props.handleChange} />

            <CodigoInputDefault tabIndex={this.props.tabIndex}  ref={input => this.codigo = getReduxWrappedComponent(input)} subPlaceholder={getStrings().productTypeSubPlaceholder} handleInputValidado={this.props.handleChange} />

            <NomeInputDefault tabIndex={this.props.tabIndex}  ref={input => this.nome = getReduxWrappedComponent(input)} subPlaceholder={getStrings().productTypeSubPlaceholder} handleInputValidado={this.props.handleChange} isRequired={true} />

            <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.producaoUnitaria = getReduxWrappedComponent(input)} id='producaoUnitaria' type='checkbox' name='producaoUnitaria' label={getStrings().unitProduction} handleInputValidado={this.props.handleChange} />
            
            <InputCustomizado tabIndex={this.props.tabIndex}  id="estadoProntoProducao" type="checkbox" ref={input => { if (input) { estadoProntoProducaoInput = getReduxWrappedComponent(input); } }} label={getStrings().enterLikeReadyInProduction} />
        </div>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    tabIndex: state.appReducer.getTabIndex(),
    ...state.idiomaReducer
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(TipoProdutoForm);
