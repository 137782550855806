import * as actionTypes from '../actions/actionTypes';

export const CODIGO_ORDENACAO_PEDIDO = '0';
export const CODIGO_ORDENACAO_ESTADO = '1';
export const CODIGO_ORDENACAO_ORIGEM = '2';
export const CODIGO_ORDENACAO_PRODUTO = '3';
export const CODIGO_ORDENACAO_HORARIO_INICIO = '4';
export const CODIGO_ORDENACAO_HORARIO_TERMINO = '5';

export const ORDEM_ASCENDENTE = 'asc';
export const ORDEM_DESCENDENTE = 'desc';

export const PARAMETRO_BUSCA_FILTRO = 'filtro';
export const PARAMETRO_BUSCA_ORDENACAO = 'ordenacao';
export const PARAMETRO_BUSCA_PAGINACAO = 'page';

export const TELA_EM_PRODUCAO = 'telaEmProducao';
export const TELA_PRONTOS = 'telaProntos';
export const TELA_PEDIDOS_ABERTOS = 'telaPedidosAbertos';
export const TELA_PEDIDOS_FATURADOS = 'telaPedidosFaturados';
export const TELA_PEDIDO_ATUAL = 'telaPedidoAtual';

/**
 * Estado Inicial, obrigatório no Reducer.
 */
const initialState = {
    loading: false

    /**
     * Lista de itens de venda que tiveram seu estado alterado para `PRODUZIDO`.
     */
    , itemVendaProduzidoList: []

    , vendaList: []
    , itemVendaList: []
    , itemProducaoList: []
    , links: []

    /**
     * Se deve exibir os itens já produzidos.
     */
    , showProduced: false

    /**
     * Se o controle de produção está sendo exibido no momento.
     */
    , visible: false

    , isEntregador: false

    , somNovoItem: false

    , tempoAmarelo: 10
    , tempoVermelho: 5
    , agruparProdutosVenda: false
    , quantidadeEntregas: '0'
    , valorTeles: '0,00'
    , mostrarEncerrados: false
    , telaAtual: TELA_EM_PRODUCAO
    , telaEmProducao: {
        ordenacao: {
            ordenacao: CODIGO_ORDENACAO_PEDIDO
            , ordem: ORDEM_ASCENDENTE
        }
        , filtro: {}
        , page: {
            number: 0
            , size: 6
            , totalElements: 0
            , totalPages: 1
        }
    }
    , telaProntos: {
        ordenacao: {
            ordenacao: CODIGO_ORDENACAO_PEDIDO
            , ordem: ORDEM_ASCENDENTE
        }
        , filtro: {}
        , page: {
            number: 0
            , size: null
            , totalElements: 0
            , totalPages: 1
        }
    }
    , telaPedidosAbertos: {
        ordenacao: {
            ordenacao: CODIGO_ORDENACAO_PEDIDO
            , ordem: ORDEM_ASCENDENTE
        }
        , filtro: {}
        , page: {
            number: 0
            , size: null
            , totalElements: 0
            , totalPages: 1
        }
    }
    , telaPedidosFaturados: {
        ordenacao: {
            ordenacao: CODIGO_ORDENACAO_PEDIDO
            , ordem: ORDEM_ASCENDENTE
        }
        , filtro: {}
        , page: {
            number: 0
            , size: null
            , totalElements: 0
            , totalPages: 1
        }
    }
    , telaPedidoAtual: {
        ordenacao: {
            ordenacao: CODIGO_ORDENACAO_PEDIDO
            , ordem: ORDEM_ASCENDENTE
        }
        , filtro: {}
        , page: {
            number: 0
            , size: 100
            , totalElements: 0
            , totalPages: 1
        }
    }
};

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.PRODUCAO:  
            const novoEstado = {
                ...state,
                ...action
            };

            // Basta controlar a construção do action no outro arquivo.
            return novoEstado;
        default: return state;
    }
};

export default reducer;
