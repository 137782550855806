import React from "react"
import { connect } from "react-redux"

import { checkBrowser } from "../../../utils/NavigatorUtils"
import { listToFragment } from "../../../utils/ScreenUtils"

// import WidthAwareDiv from "../WidthAwareDiv/WidthAwareDiv"

/**
 * Classe para exibir parágrafos de ajuda se esta exibição estiver habilitada.
 */
class HelpParagraph extends React.Component {

  render() {
    return this.props.helpState ?
      <div className={'help ' + this.props.divClass}>{
        listToFragment(this.props.children
          // Gera uma nova lista somente com os itens que não forem null, undefined, etc
          .filter(child => child)
          // Mapeia os itens para parágrafos, ao não ser que sejam botões (iniciam tours)
          // Não adiciona botões em parágrafos para poder alinhar botões numa mesma linha
          .map(child => (child.type === 'button')
            // A biblioteca do Tour não roda no Internet Explorer, portanto não exibe o botão do tour
            ? (checkBrowser('ie') ? null : child)
            : ((child.type === 'ul') || (child.type === 'ol'))
              ? child
              : <p className={'Lighter'}>{child}</p>
          ))
      } </div> : null;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {
  return {
    helpState: state.appReducer.help
  };
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(HelpParagraph);
