import { log } from './LogUtils';
import { getAppToken } from './AppUtils';

/** 
 * Método que retorna o Header utilizado quando é possível passar parâmetros (ex. POST, PATCH e GET sem filtro).
 */
export const getAuthorizationHeader = () => {
    log('utils getAuthorizationHeader');
    const token = getAppToken();

    /*, Accept: ['application/x-spring-data-verbose+json', 'application/json', 'text/plain', '*' + '/*']*/
    return token ? { 'Authorization': 'Bearer ' + token } : null;
}

/**
 * Método que retorna o Header utilizado quando não é possível passar parâmetros (ex. GET com filtro).
 * @param params parâmetros
 * @param headers cabeçalhos adicionais
 * @param otherConfigs outras configurações do request
 */
export const getHeaders = (params: Record<string, unknown> = {}, headers: Record<string, unknown> = {}, otherConfigs: Record<string, unknown> = {}) => {
    log('utils getHeaders', params, headers, otherConfigs);
    return {
        headers: {
            ...getAuthorizationHeader(),
            ...headers
        },
        params,
        ...otherConfigs,
        /*, timeout: 1000*/
        // , timeout: 1000
    };
}