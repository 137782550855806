//@ts-check
import React from 'react';
import { Botao, Texto } from '../../../nucleo/nucleo';

import './BotaoCadastrar.css';

import { LocalidadeUtils } from '../../../nucleo/utils/utils';

import { connect } from 'react-redux';

/**
 * 
 * @param {import('./types').botaoCadastrarPropsType} props
 * @returns 
 */
function BotaoCadastrar(props) {
  const children = props.webAppBotaoPrincipalReducer.nomeCustomizado ||
    <Texto>{
      props.editar
        ? LocalidadeUtils.obterTraducao().words.save
        : LocalidadeUtils.obterTraducao().words.register
    }</Texto>;

  return <Botao id='botao-cadastrar' disabled={props.disabled} className={'row ' + (props.className || '')} onClick={props.webAppBotaoPrincipalReducer.executarAcao}>
    {children}
  </Botao>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  webAppBotaoPrincipalReducer: state.webAppBotaoPrincipalReducer,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapActionToProps = (dispatch) => ({
  cadastrarUsuario: () => dispatch(() => undefined),
});

export default connect(mapStateToProps, mapActionToProps)(BotaoCadastrar);