//@ts-check
import React from 'react';

import { connect } from 'react-redux';
import { LocalidadeUtils } from '../../../nucleo/utils/utils';

import DialogTemplate from '../DialogTemplate';

import "./DescricaoDialog.css";

/**
 * 
 * @param {import('./types').DescricaoDialogPropsType} props 
 * @returns {JSX.Element}
 */
function DescricaoDialog({children}) {
    return <DialogTemplate fecharHabilitado titulo={LocalidadeUtils.obterTraducao().words.description} style={{maxWidth: '25em'}}>
        <div className='descricao-dialog' >
            <div>{children}</div>
        </div>
    </DialogTemplate>
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => {
    const props = {

    }

    return props;
}

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    const actions = {
        dispatch,
    }

    return actions;
}

export default connect(mapStateToProps, mapDispatchToProps)(DescricaoDialog);