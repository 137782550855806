import React from 'react';
import { connect } from 'react-redux';

import * as subscriberActions from '../../store/actions/subscriberAction';

import { getAppEmpresa, getAppUsuario } from "../../utils/AppUtils"
import { isBoolean } from "../../utils/ComparatorsUtils"
import { getUrl } from "../../utils/SecureConnectionUtils"
import { urlApi, urlDatabase } from "../../utils/SecureConnectionUtils"
import { randomString } from "../../utils/StringUtils"
import {
    enableLog
    , logFilterEnabled
    , remote_log_viewer_enabled
    , MOSTRAR_LOG_REMOTO_RECEBIDO
} from "../../utils/LogUtils"

let SockJS = require('sockjs-client');
let Stomp = require('stompjs');

let RETRY_DELAY = 1000;

/**
 * Exibe mensagens no console se variável estiver habilitada.
 * Definido separadamente da função em Utils pois conflita com o *log* remoto.
 * @param {any} args
 */
const log = (show, ...args) => {
    const filter = !logFilterEnabled() ? true : 
        isBoolean(show) ? show : false;

    if (!isBoolean(show)) {
        args = [show, ...args];
    }

    if (enableLog() && filter) {
        if (args.includes('error')) {
            console.error(...args);
        } else if (args.includes('warning')) {
            console.warn(...args);
        } else {
            console.info(...args);
        }
    }
}

/**
 * Gerencia uma conexão STOMP sobre WebSocket com a retaguarda para possibilitar o recebimento de notificações.
 */
class STOMPManager extends React.Component {

    /**
     * Substitui o código de estado de conexão do SockJS pelo seu nome;
     */
    getSockJSReadyState = integer => {
        switch (integer) {
            case SockJS.CLOSED: return 'CLOSED';
            case SockJS.CLOSING: return 'CLOSING';
            case SockJS.CONNECTING: return 'CONNECTING';
            case SockJS.OPEN: return 'OPEN';
            default: return '';
        }
    };

    /**
     * Agenda a execução do método que verifica se a conexão está aberta e, se não, tenta abrir ela.
     */
    scheduleConnection = () => {
        log('STOMPManager scheduleConnection', { 'timeoutList.length': this.timeoutList.length });
        // Não agenda se já existe agendamento
        if (this.timeoutList.length > 0) {
            return;
        }
        this.timeoutList.push(setTimeout(() => this.componentDidUpdate(), RETRY_DELAY));
    };

    scheduleConnectionForLog = () => {
        log('STOMPManager scheduleConnectionForLog', { 'timeoutList.length': this.timeoutList.length });
        // Não agenda se já existe agendamento
        if (this.timeoutLogList.length > 0) {
            return;
        }
        this.timeoutLogList.push(setTimeout(() => this.componentDidUpdate(), RETRY_DELAY));
    }

    setSocket = (name, url, socket, scheduleConnection) => {
        // Verifica se o socket já foi instanciado
        if (!socket) {
            log(`STOMPManager setSocket socket ${name} null`);
            // Instancia um novo socket e conecta com o back via WebSocket
            socket = new SockJS(url);
            // Configura os métodos para manter a conexão
            socket.onclose = () => {
                log(`STOMPManager setSocket socket ${name} closed`);
                scheduleConnection();
            };
            socket.onerror = () => {
                log(`STOMPManager setSocket socket ${name} failed`);
                scheduleConnection();
            };
            socket.onopen = () => log(`STOMPManager setSocket socket ${name} opened`);
        }

        return socket;
    };

    // Verifica se o socket está fechando ou fechado
    socketIsClosed = (socket) => {
        return [SockJS.CLOSED, SockJS.CLOSING].includes(socket.readyState);
    };

    socketReset = (socket, stompClient, scheduleConnection) => {
        // Se não, reinstancia o socket.
        socket = null;
        stompClient = null;
        scheduleConnection();
        return [socket, stompClient];
    };

    socketResetIfClosed = (socket, stompClient, scheduleConnection) => {
        log('STOMPManager socketResetIfClosed', { readyState: this.getSockJSReadyState(socket.readyState) });
        // Verifica se o socket está fechando ou fechado
        if (this.socketIsClosed(socket)) {
            [socket, stompClient] = this.socketReset(socket, stompClient, scheduleConnection);
        }

        return [socket, stompClient];
    };

    waitForSocketConnection = (socket, scheduleConnection) => {
        // Verifica se o socket está conectando
        if (socket) {
            if (SockJS.CONNECTING === socket.readyState) {
                // Espera terminar de conectar
                scheduleConnection();
                return true;
            }
        } else {
            return true;
        }

        return false;
    };

    setStompClient = (socket, stompClient) => {
        log('STOMPManager setStompClient', { stompClient });
        // Socket está conectado. Verifica se o STOMP está iniciado.
        if (!stompClient) {
            // Instancia o STOMP.
            stompClient = Stomp.over(socket);
            stompClient.debug = () => { };
        }

        return stompClient;
    }

    /**
     * @param {Object} stompClient
     * @param {Function} scheduleConnection
     * @param {Boolean} roleChanged para conectar a tópicos diferentes quando outro cargo for selecionado
     * @param {Function} callback executado quando a conexão é aberta
     */
    executeWhenStompConnected = (stompClient, scheduleConnection, roleChanged, callback) => {
        log('STOMPManager executeWhenStompConnected', { connected: stompClient.connected });

        // Verifica se o STOMP está conectado.
        if ((!stompClient.connected) || roleChanged) {
            // Se já estava conectado, cancela a inscrição nos tópicos antigos.
            this.unsubscribe();
            // Se não está, conecta o STOMP.
            let connectionResult = stompClient.connect({}, () => {
                callback(stompClient);
            }, () => {
                log('STOMPManager executeWhenStompConnected stomp failed connection');
                // Se falhar ao conectar, tenta conectar de novo.
                scheduleConnection();
            });
            log('STOMPManager executeWhenStompConnected', { connectionResult });
            // Em alguns testes, chamar connect foi suficiente. Em outros, foi necessário chamar o retorno do connect.
            if (connectionResult) {
                connectionResult();
            }
        }
    };

    /**
     * Realiza a assinatura de um tópico.
     * @param {String} subscriber se é assinatura de assuntos do usuário (`user`) ou da empresa (`company`)
     * @param {Object} stompClient
     * @param {String} topic
     */
    subscribe = (subscriber, stompClient, topic) => {
        log('STOMPManager subscribe', { subscriber, stompClient, topic });
        // Busca a última assinatura
        let subscription = this[`${subscriber}Subscription`];
        // Verifica se já não existe uma assinatura
        if (subscription) {
            // Verifica se não é a mesma que está tentando assinar
            if ((subscription.id || '').startsWith(topic)) {
                // Se for, faz mais nada.
                log('STOMPManager subscribe duplicate');
                return;
            }
            // Se não for
            else {
                // Cancela esta assinatura para fazer uma nova
                log('STOMPManager subscribe replacing');
                subscription.unsubscribe();
            }
        }
        // Realiza a assinatura
        this[`${subscriber}Subscription`] = stompClient.subscribe(topic, message => this.props.messageReceived(topic, message), { id: `${topic}#${randomString()}` });

        log('STOMPManager subscribe', { topic, stompClient });
    };
    
    /**
     * Cancela as assinaturas, se houverem.
     */
    unsubscribe = () => {
        log('STOMPManager unsubscribe');
        if (this.companySubscription) {
            this.companySubscription.unsubscribe();
            this.companySubscription = null;
        }
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
            this.userSubscription = null;
        }};

    /**
     * Método executado APÓS montar o componente.
     */
    componentDidMount() {
        log('STOMPManager componentDidMount');
        // Instancia uma lista de timeouts para prevenir que mais de um timeout execute ao mesmo tempo
        this.timeoutList = [];
        this.timeoutLogList = [];
        this.componentDidUpdate();
    }

    /**
     * Método executado APÓS a atualização do componente.
     */
    componentDidUpdate(prevProps) {
        log('STOMPManager componentDidUpdate');

        // Valida o argumento
        prevProps = prevProps || {};

        let roleChanged = prevProps.cargo !== ((this || {}).props || {}).cargo;

        if (remote_log_viewer_enabled || MOSTRAR_LOG_REMOTO_RECEBIDO) {
            while (this.timeoutLogList.length > 0) {
                clearTimeout(this.timeoutLogList.pop());
            }

            this.logSocket = this.setSocket('log', getUrl('/log_api/websocket', 9089), this.logSocket, this.scheduleConnectionForLog);
            [this.logSocket, this.stompLogClient] = this.socketResetIfClosed(this.logSocket, this.stompLogClient, this.scheduleConnectionForLog);

            if (this.waitForSocketConnection(this.logSocket, this.scheduleConnectionForLog)) {
                return;
            }

            this.stompLogClient = this.setStompClient(this.logSocket, this.stompClient);

            this.executeWhenStompConnected(this.stompLogClient, this.scheduleConnectionForLog, roleChanged, (stompClient) => {
                log('STOMPManager componentDidUpdate stomp opened connection remote log viewer');
                // Ao conectar, inscreve nos tópicos.
                stompClient.subscribe('/topic/logs', message => this.props.messageReceived('/topic/logs', message));
            });
        }

        // Limpa os timeouts atuais
        while (this.timeoutList.length > 0) {
            clearTimeout(this.timeoutList.pop());
        }

        // Verifica se há um usuário logado. Evita conexões desnecessárias.
        if (!this.props.isAuthenticated) {
            // Se havia um usuário logado, cancela as assinaturas.
            if (prevProps.isAuthenticated) {
                this.unsubscribe();
            }
            return;
        }

        this.socket = this.setSocket('default', `${urlDatabase}/websocket/`, this.socket, this.scheduleConnection);
        [this.socket, this.stompClient] = this.socketResetIfClosed(this.socket, this.stompClient, this.scheduleConnection);

        if (this.waitForSocketConnection(this.socket, this.scheduleConnection)) {
            return;
        }

        this.stompClient = this.setStompClient(this.socket, this.stompClient);

        this.executeWhenStompConnected(this.stompClient, this.scheduleConnection, roleChanged, stompClient => {
            log('STOMPManager componentDidUpdate stomp opened connection');
            // Ao conectar, inscreve nos tópicos apropriados, dependendo se há vínculo com empresa.
            if (this.props.cargo) {
                // Gera os tópicos
                let companyTopic = '/topic/empresas/' + (getAppEmpresa() || '').split(`${urlApi}/empresas/`)[1];
                let userTopic = companyTopic + '/usuarios/' + getAppUsuario();
                // Realiza as assinaturas
                this.subscribe('company', stompClient, companyTopic);
                this.subscribe('user', stompClient, userTopic);
            }
            else if (this.props.isAuthenticated) {
                this.subscribe('user', stompClient, '/topic/usuarios/' + getAppUsuario());
            }
        });
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('STOMPManager render');
        return null;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state
 */
const mapStateToProps = state => ({

    cargo: state.empresaSelectorReducer.cargo,
    isAuthenticated: !!state.authReducer.token
});

/**
 * Mapeia as ações.
 * @param {*} dispatch
 */
const mapDispatchToProps = dispatch => ({

    messageReceived: (topic, message) => dispatch(subscriberActions.messageReceived(topic, message))
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(STOMPManager);
