import React from 'react';

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import CadastroTable from './CadastroTable';
import OptionButtons from '../optionButtons/OptionButtons';
import OrigemVendaTableModel from './OrigemVendaTableModel';
import BotaoChecado from '../../UI/botoes/BotaoChecado/BotaoChecado';

/**
 * Classe que repassa para o CadastroTable as informações para a montagem da tabela das Origens de Venda.
 */
export default class OrigemVendaTable extends React.Component {

    render() {
        log('OrigemVendaTable render');
        return <CadastroTable {...this.props} objetoCadastro='origemVenda' sortProperty={'nome'} filterProperties={['nome', 'codigo']} tableModel={OrigemVendaTableModel}
            printExternalEnabled
            printInternalEnabled
            blankFormData={{
                enabled: true,
                codigo: '',
                nome: '',
                tipoOrigemVenda: null,
                codigoImpresso: '',
                formatoCodigoImpresso: null
            }} registerHelp={getStrings().saleSourceTableHelp}
        />;
    }
}

/**
 * Classe que realiza a montagem das linhas da tabela das Origens de Venda.
 */
export class OrigemVendaTableRow extends React.Component {

    /**
     * Método que executa a montagem do componente.
     */
    render() {
        log('OrigemVendaTableRow render');

        let id = `${getURIFromEntity(this.props.cadastro)}/Checkbox`;

        if (['Loja', 'Mobi'].includes(this.props.cadastro.tipoOrigemVenda)) {
            return null;
        }

        return <tr>

            {/* Opções de manutenção */}
            <OptionButtons 
                className='ButtonAlign' 
                onUpdate={this.props.onUpdate} 
                onDelete={this.props.onDelete} 
                cadastro={this.props.cadastro} 
                disabled={this.props.cadastro.tipoOrigemVenda === 'Delivery' ? true : false}/>

            {/* Dados do cadastro */}
            <td className='Lighter ButtonAlign' width='5%' >
                <BotaoChecado key={`__${id}__`} desativado={!this.props.cadastro.enabled} />
            </td>
            <td className='Lighter TextAlign' width='20%' >{this.props.cadastro.codigo}</td>
            <td className='Lighter TextAlign' width='60%' >{this.props.cadastro.nome}</td>
            <td className='Lighter TextAlign' width='15%' >{getStrings().saleSourceTypeEnumToString(this.props.cadastro.tipoOrigemVenda)}</td>

        </tr>;
    }
}
