import React from 'react';
import criarAction from '../factory/criarAction';

const tiposDeAcoes = {
  MOSTRAR_DIALOG: 'MOSTRAR_DIALOG:DIALOG',
  FECHAR_DIALOG: 'FECHAR_DIALOG:DIALOG',
};

export default tiposDeAcoes;

export const fecharDialog = () => function(dispatch: any) {
  dispatch(criarAction(undefined, tiposDeAcoes.FECHAR_DIALOG));
};

export const mostrarDialog = (carga: {conteudo: React.ReactNode}) => function(dispatch: any) {
  dispatch(criarAction(carga, tiposDeAcoes.MOSTRAR_DIALOG));
};
