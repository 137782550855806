import { dispatchTipo } from "../../../../../store/actions/acaoTemplate";
import criarAction from "../factory/criarAction";
import { parametrosEmpresaReducerTipo } from "./parametrosEmpresaReducer";

const tiposDeAcoes = {
    ATUALIZAR_PARAMETROS_EMPRESA: 'ATUALIZAR_PARAMETROS_EMPRESA:PARAMETROS_EMPRESA',
};

export default tiposDeAcoes;

export const atualizarParametrosEmpresa = ({origemVenda, ...carga}: parametrosEmpresaReducerTipo) => function(dispatch: dispatchTipo) {
    let payload: parametrosEmpresaReducerTipo = {
        ...carga,
    }   
    dispatch(criarAction(payload, tiposDeAcoes.ATUALIZAR_PARAMETROS_EMPRESA));
}