import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { push } from "connected-react-router"

import { log } from "../../../utils/LogUtils"
import { goBack } from "../../../utils/NavigatorUtils"

import BarraAcoesForm from "../../cadastros/barraAcao/BarraAcoesForm"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

/**
 * Classe que realiza a montagem da barra de ações composta exclusivamente de um botão para voltar.
 */
class BarraAcoesBack extends React.Component {

  /**
   * Método que monta o componente.
   */
  render() {
    log("BarraAcoesBack render")

    let component = <BarraAcoesForm
      handleListar={() => {
        log("BarraAcoesBack handleListar", { route: this.props.route })
        if (this.props.route) {
          this.props.goTo(this.props.route)
        } else {
          goBack()
        }
      }}
      hideSubmit={true}
    />

    if (!this.props.noWrap) {
      component = <WidthAwareDiv>
        {component}
      </WidthAwareDiv>
    }

    // Monta o componente
    return component
  }
}

BarraAcoesBack.propTypes = {

  /**
   * Não monta a barra dentro de um `WidthAwareDiv`.
   */
  noWrap: PropTypes.bool
}

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  goTo: path => dispatch(push(path))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(null, mapDispatchToProps)(BarraAcoesBack)
