import React from 'react';
import { connect } from 'react-redux';

import { getAppEmpresa } from "../../../utils/AppUtils"
import { equalsCoerced } from "../../../utils/ComparatorsUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"

import { tamanhoCadastroTipo } from '../box/TamanhoBox';

import AtivoInputDefault from '../cadastroInputDefault/AtivoInputDefault';
import CodigoInputDefault from '../cadastroInputDefault/CodigoInputDefault';
import NomeInputDefault from '../cadastroInputDefault/NomeInputDefault';

import CadastroForm, { CadastroFormType } from './CadastroForm';
import FormValuesTemplate from './FormValuesTemplate';

export interface TamanhoFormTipo extends CadastroFormType {} 

class TamanhoForm extends React.Component<TamanhoFormTipo> {
    render() {
        log('TamanhoForm render');
        return <CadastroForm {...this.props} formModel={TamanhoValues}
            registerSaveHelp={'(não traduzido)'}
            registerUpdateHelp={'(não traduzido)'}
        />;
    }
}

export type TamanhoValuesTipo = {
    cadastroDados: tamanhoCadastroTipo,
    cadastroDadosIniciais: tamanhoCadastroTipo,
    handleChange: (value: any) => void | undefined,
    onSave: (formData: tamanhoCadastroTipo) => void | undefined,
    tabIndex: number,
}

class TamanhoValues extends FormValuesTemplate<TamanhoValuesTipo, tamanhoCadastroTipo> {
    enabled?: any;
    codigo?: any;
    nome?: any;

    /**
     * Recupera os dados do formulário.
     */
    getFormData = (): any => {
        log('TamanhoValues getFormData');

        return {
            empresaSet: [getAppEmpresa()],
            enabled: this.enabled.getValue(),
            codigo: this.codigo.getValue(),
            nome: this.nome.getValue(),
        };
    }
    
    /**
     * Retorna se os campos obrigatórios (required) foram todos preenchidos
     */
    checkRequired = (newData: tamanhoCadastroTipo): boolean => {
        log('ProdutoValues checkRequired', newData);
    
        return (newData.codigo !== '') && (newData.nome !== '');
    };

    /**
     * Retorna se houve alteração em algum dado do cadastro, comparando com os dados presentes ao abrir a criação/edição do cadastro.
     */
    wasChangedFromInitial = (newData: tamanhoCadastroTipo): boolean => {
        log('ProdutoValues wasChangedFromInitial', newData);
        return this.wasChanged(newData, this.props.cadastroDadosIniciais);
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro, comparando com os dados sendo editados.
     */
    wasChangedFromCurrent = (newData: tamanhoCadastroTipo): boolean => {
        log('ProdutoValues wasChangedFromCurrent', newData);

        return this.wasChanged(newData, this.props.cadastroDados);
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro
     */
    wasChanged = (newData: tamanhoCadastroTipo, oldData: tamanhoCadastroTipo): boolean => {
        const result = !equalsCoerced(oldData, newData, (oldValue: tamanhoCadastroTipo, newValue: tamanhoCadastroTipo) => (newValue.enabled === oldValue.enabled) && (newValue.codigo === oldValue.codigo) && (newValue.nome === oldValue.nome));
        log('ProdutoValues wasChanged', { newData, oldData, result });
        
        return result;
    }

    /**
     * Método executado ao clicar no botão "Gravar".
     * Recupera os dados do formulário e repassa para o método que irá persistir os dados.
     */
    handleGravar = () => {
        log('ProdutoValues handleGravar');

        // Repassa para o método que irá persistir os dados
        this.props.onSave(this.getFormData());
    }

    componentDidMount() {
        log('TamanhoValues componentDidUpdate');

        if (this.props.cadastroDados) {
            const cadastro = this.props.cadastroDados;

            // Se for passado false, é false; se for passado true, null, undefined ou não for passado, é true
            this.enabled.inputField.inputComponent.checked = cadastro.enabled !== false;

            this.codigo.inputField.inputComponent.value = cadastro.codigo ? cadastro.codigo : null;

            this.nome.inputField.inputComponent.value = cadastro.nome ? cadastro.nome : null;
        }
    }

    render() {
        log('TamanhoValues render');

        return <div className='sub-form'>
            <AtivoInputDefault tabIndex={this.props.tabIndex} ref={(input: any) => this.enabled = getReduxWrappedComponent(input)} handleInputValidado={this.props.handleChange} />
            
            <CodigoInputDefault ref={(input: any) => this.codigo = getReduxWrappedComponent(input)} subPlaceholder={getStrings().sizeSubPlaceholder} handleInputValidado={this.props.handleChange} />

            <NomeInputDefault ref={(input: any) => this.nome = getReduxWrappedComponent(input)} subPlaceholder={getStrings().sizeSubPlaceholder} handleInputValidado={this.props.handleChange} isRequired={true} />
        </div>;
    }
}

const mapStateToProps = (state: any) => ({
    tabIndex: state.appReducer.getTabIndex(),
    ...state.idiomaReducer,
});

const mapDispatchToProps = null; // (dispatch: dispatchTipo) => ({});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TamanhoForm);