//@ts-check
import React from 'react';

import "./Seletor.css";

import TipoSeletores from './TipoSeletores';

/**
 * 
 * @param {import('./types').seletorPropsType} props 
 * @returns {JSX.Element}
 */
function Seletor(props) {
    const SeletorComponente = TipoSeletores[props.type];
  
    return <>
      <label className='ui-seletor-label' htmlFor={'ui-seletor'}>{props.label}</label>
      <div id='ui-seletor' className='ui-seletor' onChange={
        /**
         * 
         * @param {any} event 
         */
        ({target}) => { props.onChange(target.value); }}>
        <SeletorComponente onChange={props.onChange} name={props.name} value={props.value}>
          {props.children}
        </SeletorComponente>
      </div>
    </>;
}

export default Seletor;
