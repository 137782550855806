import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"

import Icones from "../../../assets/icones/Icones"

/**
 * Classe que monta o field que exibe a página atual "2/14".
 */
const BarraAcoesField = (props) => {
  /**
   * Método executado ao clicar sobre o field.
   * Realiza a chama do método passado por parâmetro durante a montagem do field.
   * @param {*} evt
   */
  const handleClick = (evt) => {
    evt.preventDefault()
    props.commandMethod()
  }

  const MdMoreVert = Icones.md.MdMoreVert

  return (
    <div className="bar-item-big" >
      <div className="bar-border" >
        <button id={props.id} type="button" className="gray-button bar-button bar-button-more clickable" onClick={handleClick} title={`${getStrings().currentPage}\n${getStrings().pressToChangeRegistersPerPage}`} tabIndex={props.tabIndex}>
          {/* O "className" no label é necessário, pois senão o botão fica distorcido caso seja clicado soble o icone (as três bolinhas) */}
          <label id="bar-label" className="bar-label" >
            <div className="verticalCenterTextAndSVG" >
              <label><span style={{ paddingLeft: "0.5em" }}>{props.label}</span> <MdMoreVert /></label>
            </div>
          </label>
        </button>
      </div>
    </div>
  )
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {
  return {
    tabIndex: state.appReducer.getTabIndex()
  }
}

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(BarraAcoesField)
