import criarReducer, { acaoTipo } from "../factory/criarReducer";
import tiposDeAcoes from "./navegacaoActions";

export type navegacaoReducerTipo = {
  botaoVoltar: {
    disabled: boolean,
  }
};

const estadoInicial: navegacaoReducerTipo = {
  botaoVoltar: {
    disabled: false,
  },
};

const acoesNomeadas: any = {
  [tiposDeAcoes.ATUALIZAR_BOTAO_VOLTAR]: ({disabled}: {disabled: boolean}, estadoAtual: navegacaoReducerTipo) => {
    const botaoVoltar = {
      ...estadoAtual.botaoVoltar,
      disabled,
    };

    const novoEstado = {
      ...estadoAtual,
      botaoVoltar,
    }

    return novoEstado;
  },
}

export default (estado: navegacaoReducerTipo = estadoInicial, acao: acaoTipo) => 
  criarReducer(estado, acao, acoesNomeadas);
