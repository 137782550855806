import React from 'react';

import Icones from '../../../assets/icones/Icones';

const style = {width: '2.5em', height: '2.25em', textAlign: 'center'};

const QuantidadeButton = (props) => {
    return <button
        {...props}
        style={{...style, backgroundColor: 'var(--color-secondary)', color: 'var(--color-text-light)'}}
        className={`quant-button blue-button font-color${props.className ? ` ${props.className}` : ''}`}
    />;
};

const QuantidadeComponent = props => {
    const MdAdd = Icones.md.MdAdd;
    const MdRemove = Icones.md.MdRemove;

    return <div className='pure-g'>
        <QuantidadeButton
            disabled={typeof props.quantidadeMinima === 'number' ? props.quantidade <= props.quantidadeMinima : false}
            className='pure-u-1-3 pure-u-sm-6-24 button grid-button left'
            onClick={props.diminuirQuantidade}
        >
            <MdRemove />
        </QuantidadeButton>
        <div style={style} className='pure-u-1-3 pure-u-sm-12-24 quantidade' >{
            props.quantidade
        }</div>
        <QuantidadeButton
            disabled={typeof props.quantidadeMaxima === 'number' ? props.quantidade <= props.quantidadeMaxima : false}
            className='pure-u-1-3 pure-u-sm-6-24 button grid-button right'
            onClick={props.aumentarQuantidade}
        >
            <MdAdd />
        </QuantidadeButton>
    </div>;
};

export default QuantidadeComponent;
