import { acaoNomeadaTipo, acoesNomeadasTipo } from "../../../../../store/actions/acaoTemplate";

export type acaoTipo = {
  type: string,
  payload?: any,
}

const criarReducer = <reducerTipo>(currentState: reducerTipo, {type, payload}: acaoTipo, acoesNomeadas: acoesNomeadasTipo<reducerTipo>) => {
  const acao = acoesNomeadas[type] as (acaoNomeadaTipo<reducerTipo> | null)

  if (acao) {
    return acao(payload, currentState)
  }

  return currentState;
}

export default criarReducer;