import React from 'react';
import criarReducer, { acaoTipo } from "../../factory/criarReducer";
import tiposDeAcoes from "./botaoPrincipalActions";

export type botaoPrincipalReducerTipo = {
    executarAcao: (parameter: any) => void | undefined,
    nomeCustomizado?: React.ReactNode,
};

const estadoInicial: botaoPrincipalReducerTipo = {
    executarAcao: () => {},
    nomeCustomizado: null,
};

const acoesNomeadas: {[key:string]: (carga: any, estadoAtual?: botaoPrincipalReducerTipo) => botaoPrincipalReducerTipo} = {
    [tiposDeAcoes.LIMPAR_BOTAO_PRINCIPAL]: () => {
        const novoEstado = estadoInicial;

        return novoEstado;
    },
    [tiposDeAcoes.DEFINIR_BOTAO_PRINCIPAL]: (carga: botaoPrincipalReducerTipo, estadoAtual?: botaoPrincipalReducerTipo) => {
        const novoEstado: botaoPrincipalReducerTipo = {
            ...(estadoAtual || {})
            , ...carga
        };

        return novoEstado;
    },
};

export default function(estado: botaoPrincipalReducerTipo = estadoInicial, carga: acaoTipo) {
    return criarReducer<botaoPrincipalReducerTipo>(estado, carga, acoesNomeadas);
}
