import criarReducer from "../../factory/criarReducer";
import { tipoAcao } from "../../../../../../store/actions/acaoTemplate";
import actionTypes from "./formasPagamentoActions";

export type formasPagamentoReducerTipo = {
  [key: string]: {
    nome: string
    usaPagSeguro: boolean
  }
}

const estadoInicial: formasPagamentoReducerTipo = {};

const acoesNomeadas: {[key: string]: (carga: any, estadoAtual?: formasPagamentoReducerTipo) => formasPagamentoReducerTipo} = {
  [actionTypes.POPULAR_FORMAS_PAGAMENTO]: (formasPagamento: formasPagamentoReducerTipo) => {
    const newState = {
      ...formasPagamento
    }
    
    return newState;
  }
};

export default function(estado: formasPagamentoReducerTipo = estadoInicial, acao: tipoAcao) {
  return criarReducer(estado, acao, acoesNomeadas);
}
