import { log } from './LogUtils';
import { isString } from './ComparatorsUtils';


/**
 * Método que retorna o ID, a partir de um Objeto recebida por parâmetro. 
 * @param {Object} entity 
 */
export const getIdFromEntity = entity => {
    log('utils getIdFromEntity', entity);
    return getIdFromURI(getURIFromEntity(entity));
}

/**
 * Método que extrai e retorna o ID, a partir da URI de um Objeto recebida por parâmetro. 
 * @param {String} uri 
 */
export const getIdFromURI = uri => {
    log('utils getIdFromURI', uri);
    if (!isString(uri)) {
        return null;
    }

    const index = uri.lastIndexOf('/');

    if (index < 0) {
        return null;
    }

    // Extrai a ID da URI
    let id = uri.substring(index + 1);

    // Algumas URIs são baseadas em Projection, portanto remove o '{?projection}' que fica ao final, depois do ID, caso necessário
    id = id.replace('{?projection}', '');

    const test = parseInt(id).toString();

    if (id !== test) {
        return null;
    }

    return parseInt(id);
}

/**
 * Aceita uma entidade ou um URI como parâmetro. Se foi passado um URI, só retorna ele.
 * Se foi passado uma entidade, retorna a URI dela. Se a entidade ou qualquer propriedade do caminho até o URI não existirem,
 * retorna uma *string* vazia.
 * @param {*} entityOrURI
 * @param {boolean} stricted
 * @returns {string}
 */
export const getURIFromEntity = (entityOrURI, stricted = false) => {
    // log('utils getURIFromEntity', entityOrURI);

    if (isString(entityOrURI)) {
        return entityOrURI;
    }

    if (entityOrURI?._links?.self?.href) {
        return entityOrURI._links.self.href.replace('{?projection}', '');
    }

    if (!stricted) {
        return '';
    } else {
        return null;
    }
};

/**
 * Aceita uma entidade ou um URI como parâmetro. Se foi passado um URI, só retorna ele.
 * Se foi passado uma entidade, retorna a URI dela. Se a entidade ou qualquer propriedade do caminho até o URI não existirem,
 * retorna uma *string* vazia.
 * 
 * Considera URI em um formato diferente, geralmente retornado pelo Spring em mensagens via STOMP.
 * @param {*} entityOrURI
 * @param {boolean} stricted
 * @returns {string}
 */
export const getURIFromEntityAlt = entityOrURI => {
    // log('utils getURIFromEntityAlt', { entityOrURI });

    if (isString(entityOrURI)) {
        return entityOrURI;
    }
    return ((((entityOrURI || {}).links || [])[0] || {}).href || '').replace('{?projection}', '');
};

/**
 * Compara dois objetos, que podem ser uma entidade com URI ou um URI. Retorna se os URIs são iguais.
 * @param {String} entityOrURI_1
 * @param {String} entityOrURI_2
 */
export const entityURIEqualsURI = (entityOrURI_1, entityOrURI_2) => {
    log('utils entityURIEqualsURI', entityOrURI_1, entityOrURI_2);

    return getURIFromEntity(entityOrURI_1, true) === getURIFromEntity(entityOrURI_2, true);
};

/**
 * Compara duas listas, uma contendo entidades com URI, e outra somente URIs de entidade.
 * Retorna se as duas listas são iguais. Ou seja, se para cada URI de uma lista,
 * há uma entidade com aquele URI na outra lista.
 * @param {Array} entityListURI lista de entidades que possuem URI
 * @param {string[]} entityURIList lista de URIs de entidade
 */
export const entityURIListEqualsList = (entityListURI, entityURIList) => {
    log('utils entityURIListEqualsList', entityListURI, entityURIList);

    // Se ambas as listas não existem, elas são iguais
    if ((!entityListURI) && (!entityURIList)) {
        return true;
    }

    // A partir daqui, pelo menos tem uma lista

    // Já que tem uma lista, se não tiver outra, elas são diferentes
    if ((!entityListURI) || (!entityURIList)) {
        return false;
    }

    // A partir daqui, tem as duas listas

    // Se a quantidade de elementos for diferente, elas são diferentes
    if (entityListURI.length !== entityURIList.length) {
        return false;
    }

    // Se ambas forem vazias, são iguais
    if ((!entityListURI.length) && (!entityURIList.length)) {
        return true;
    }

    // A partir daqui, as duas listas tem itens e na mesma quantidade

    let found;

    // Itera as entidades
    for (let entity of entityListURI) {

        // Inicializa a variável para esta entidade
        found = false;

        // Itera os URIs para tentar achar o URI desta entidade
        for (let URI of entityURIList) {

            // Como não tem como pular no forEach
            if (!found) {

                // Se o URI bate com o da entidade, achou
                if (entityURIEqualsURI(entity, URI)) {
                    found = true;
                }
            }
        }

        // Se nenhum dos URIs era o desta entidade, uma lista é diferente da outra
        if (!found) {
            return false;
        }
    }

    // Se todos os itens corresponderam, as listas são iguais
    return true;
}
