export const theme = {
	//estilos cores
	darkBackgroundColor: '#252B42',
	lightBackgroundColor: '#FEFEFE',
	lightBackgroundColor2: '#e7eaf7',
	primaryColor: '#034B85',
	secondaryColor: '#00A884',
	textColor: '#252F48',
	secondTextColor: '#737373',
	lightTextColor: '#FFFFFF',
	disabledElementColor: '#2DC071',
	alertColor: '#E77C40',
	dangerColor: '#E74040',
	successColor: '#8EC2F2',
	mutatedColor: '#BDBDBD',
	boxShadow: '0 4px 26px rgba(172, 197, 207, 0.26)',
	greenGradient: '#01aa83',

	gradient: `linear-gradient(90deg,#01aa83,#034985,#034985)`,
	sizes: {
		breakPointSmall: '420px',
		breakPointMedium: '645px',
		breakPointBig: '800px',
		navBarHeight: '75px'
	}

}

export default theme;
