import criarAction from "../factory/criarAction";

const tiposDeAcoes = {
  MOSTRAR_BOTOES_FLUTUANTES: 'MOSTRAR_BOTOES_FLUTUANTES:BOTOES_FLUTUANTES',
  ESCONDER_BOTOES_FLUTUANTES: 'ESCONDER_BOTOES_FLUTUANTES:BOTOES_FLUTUANTES',
  MUDAR_ESTADO_MOSTRAR_BOTOES_FLUTUANTES: 'MUDAR_ESTADO_MOSTRAR_BOTOES_FLUTUANTES:BOTOES_FLUTUANTES',
};

export default tiposDeAcoes;

export const mostrarBotoesFlutuantes = () => function(dispatch: any) {
  dispatch(criarAction(undefined, tiposDeAcoes.MOSTRAR_BOTOES_FLUTUANTES));
}

export const esconderBotoesFlutuantes = () => function(dispatch: any) {
  dispatch(criarAction(undefined, tiposDeAcoes.ESCONDER_BOTOES_FLUTUANTES));
}


export const mudarEstadoMostrarBotoesFlutuantes = (carga: {mostrar:boolean}) => function(dispatch: any) {
  dispatch(criarAction(carga, tiposDeAcoes.MUDAR_ESTADO_MOSTRAR_BOTOES_FLUTUANTES));
}
