import React from 'react';
import { connect } from 'react-redux';
import * as appActions from '../../../store/actions/appAction';
import { ESTADO_ITEM_VENDA_AGUARDANDO, ESTADO_ITEM_VENDA_CANCELADO, ESTADO_ITEM_VENDA_EM_PRODUCAO, ESTADO_ITEM_VENDA_ENCERRADO, ESTADO_ITEM_VENDA_ENTREGUE, ESTADO_ITEM_VENDA_ENVIADO, ESTADO_ITEM_VENDA_PRODUZIDO, ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA } from '../../../store/reducers/controleVenda/controleVendaReducer';
import Icones from '../../../assets/icones/Icones';
import { appDialogShow } from '../../../store/actions/appAction';

import { getNomeProdutoFromItemVenda } from "../../../utils/MiscUtils"
import { formatNumber } from "../../../utils/NumberUtils"

export const verificaCorEstado = (estado) => {
    const obj = {
        [ESTADO_ITEM_VENDA_ENVIADO]: 'enviado',
        [ESTADO_ITEM_VENDA_AGUARDANDO]: 'aguardando',
        [ESTADO_ITEM_VENDA_EM_PRODUCAO]: 'produzindo',
		[ESTADO_ITEM_VENDA_PRODUZIDO]: 'pronto',
		[ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA]: 'entrega',
        [ESTADO_ITEM_VENDA_ENTREGUE]: 'entregue',
        [ESTADO_ITEM_VENDA_ENCERRADO]: 'encerrado',
        [ESTADO_ITEM_VENDA_CANCELADO]: 'cancelado',
    }
    return obj[estado] || '';
}

class CardPedidoItemProducao extends React.Component {

    render() {
        const FaRegComment = Icones.fa.FaRegComment;
        const estadoItensVenda = this.props.estadoItensVenda;

        return <>
            {this.props.itemList.map((item, index) =>
                <div className={`linha ${estadoItensVenda !== item.estado ? verificaCorEstado(item.estado) : ''}`} key={index}>
                    <div className='coluna-quantidade'>
                        {item.quantidade}
                    </div>
                    <div className='coluna-descricao'>
                        {getNomeProdutoFromItemVenda(item)}
                    </div>
                    <div className='coluna-valor'>
                        {formatNumber(item.valorTotal, 2)}
                    </div>
                    <div
                        className='coluna-observacao'
                        onClick={item.observacao
                            ? () => { this.props.dispatch(appDialogShow(item.observacao, getNomeProdutoFromItemVenda(item))); }
                            : undefined
                        }
                    >
                        {item.observacao
                            ? <FaRegComment id='botao-observacao-item'/>
                            : null
                        }
                    </div>
                </div>)
            }
        </>;
    }
};

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    ...state.idiomaReducer
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    appDialogShow: (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles) =>
        dispatch(appActions.appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles)),
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(CardPedidoItemProducao);
