import { acaoNomeadaTipo, acoesNomeadasTipo, tipoAcao } from "../actions/acaoTemplate";

function redutorTemplate<reducerType>(currentState: reducerType, {type, carga}: tipoAcao, acoesNomeadas: acoesNomeadasTipo<reducerType>) {
  const acao = acoesNomeadas[type] as (acaoNomeadaTipo<reducerType> | null)

  if (acao) {
    return acao({type, ...carga}, currentState);
  }

  return currentState
};

export default redutorTemplate;
