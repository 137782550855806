import * as actionTypes from "../actions/actionTypes"

import { updateObject } from "../../utils/ObjectUtils"

/**
 * Estado Inicial, obrigatório no Reducer.
 */
export const initialState = {

  /**
   * O que fazer ao pressionar o botão de voltar da barra de tarefas.
   */
  backAction: null

  /**
   * Bread crumbs, que pode variar dependendo da utilidade do leitor.
   */
  , breadCrumbs: null

  /**
   * Objeto que armazena entidades indexadas pelo seus códigos. Se possível, tenta retornar a entidade daqui ao invés de fazer uma requisição.
   */
  , cache: {}

  /**
   * Lista de códigos de barras ou QR que já foram lidos.
   */
  , codeReadList: []

  /**
   * Qual entidade está sendo buscada pelo seu código de barras ou QR.
   */
  , entity: ""

  /**
   * Título da tela, exibido abaixo da barra superior e acima do resto.
   */
  , header: ""

  /**
   * O que fazer quando verificar um código válido.
   */
  , onSuccess: () => { }

  /**
   * Se o leitor está habilitado para realizar leituras.
   */
  , readEnabled: false
}

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.CODE_READER_ACTION:

      // Basta controlar a construção do action no outro arquivo.
      return updateObject(state, {
        ...state
        , ...action
      })

    default: return state
  }
}

export default reducer
