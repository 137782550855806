import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent, reduxStateType } from "../../../utils/reduxUtils/reduxUtils"
import { blurAll } from "../../../utils/ScreenUtils"
import { formatI18nString } from "../../../utils/StringUtils"

import { cadastroReducerType } from "../../../store/reducers/cadastroReducer"
import * as actions from "../../../store/actions/cadastroAction"
import * as appActions from "../../../store/actions/appAction"
import * as actionTypes from "../../../store/actions/actionTypes"
import { overlayReducerTipo } from "../../../apps/promo_app_frontend/nucleo/redux/overlay/overlayReducer"

import BarraAcoesForm from "../barraAcao/BarraAcoesForm"
import CargoUsuarioForm from "./CargoUsuarioForm"
import HelpParagraph from "../../UI/HelpParagraph/HelpParagraph"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"
import { dispatchTipo } from "../../../store/actions/acaoTemplate"

export type CadastroFormModularType = {
  handleListar: () => void,
  needFormEntities: boolean,
  needFormUpdate: boolean,
  urlDataBase: string,
}

export interface CadastroFormType {
  handleListar: () => void,
  needFormEntities: boolean,
  needFormUpdate: boolean,
  urlDataBase: string,

  formModel?: React.ElementType,
  registerSaveHelp?: string|any[],
  registerUpdateHelp?: string|any[],
};

interface CadastroFormReduxStateType extends cadastroReducerType { webAppOverlayReducer: overlayReducerTipo };

interface CadastroFormReduxDispatchType {
  cadastroSave: actions.cadastroSaveType,
  cadastroUpdate: actions.cadastroUpdateType,
  cadastroSaveUpdate: actions.cadastroSaveUpdateType,
  cadastroUpdateFormData: actions.cadastroUpdateFormDataType,
  cadastroLoadFormData: actions.cadastroLoadFormDataType,
  cadastroShowSpinner: actions.cadastroShowSpinnerType,
  cadastroSetChanged: actions.cadastroSetChangedType,
  cadastroSetFilledRequired: actions.cadastroSetFilledRequiredType,
  appNotificationShow: appActions.appNotificationShowType,
  appNotificationHide: appActions.appNotificationHideType,
};

interface CadastroFormTypeWithReducer extends CadastroFormType, CadastroFormReduxStateType, CadastroFormReduxDispatchType {};

/**
 * Classe base para a exibição dos formulário dos cadastros,
 * todas as funções que são comuns para todos os cadastros ficam neste arquivo.
 */
class CadastroForm_ extends React.Component<CadastroFormTypeWithReducer> {

  emailUsuario: any;
  formInstance: any;
  updateUrl = null;

  /**
   * Método executado ao clicar no botão "Gravar" do formulário.
   * @param {*} formData 
   */
  onSave = (formData: Record<string,any>, params: Record<string,any>) => {
    const updateUrl = this.updateUrl
    const urlDataBase = this.props.urlDataBase
    const filter = this.props.filter
    log("CadastroForm onSave", { formData, params })
    this.props.cadastroSaveUpdate(formData, updateUrl, urlDataBase, filter, params)
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   * 
   * `componentWillMount` foi substituído por `componentDidMount` por atualizações no React. Para agilizar o sistema,
   * uma versão reduzida do registro é carregada para a tabela e, ao editar o registro, ele é carregado completamente.
   * No `*Form` de cada registro, os campos devem ser populados ao atualizar a tela para exibir os dados completos.
   * 
   * Os campos não podem ser populados no `render` porque são feitas chamadas de métodos assíncronos para popular os `Select`s.
   * Existe a possibilidade desses métodos completarem antes do `render` terminar, o que causaria acesso aos campos
   * que ainda não estão inicializados. Além disso, poderia ocasioar em uma atualização nos `props` antes do `render` terminar,
   * o que não é permitido.
   */
  componentDidMount() {
    log("CadastroForm componentDidMount");

    // Se foi recebido por parâmetro um cadastro persistido, armazena a URL para realizar a atualização do mesmo
    if (this.props.operation === actionTypes.CADASTRO_EDIT) {

      this.updateUrl = this.props.cadastroDados._links.self.href;

      // Testa se precisa mostrar o spinner, dependendo se o registro deste cadastro precisa buscar mais dados do servidor
      if (this.props.needFormUpdate || this.props.needFormEntities) {
        // Mostra o spinner enquanto os dados adicionais são buscados
        this.props.cadastroShowSpinner(false);
      }
      // Carrega os dados completos do registro
      if (this.props.needFormUpdate) {
        this.props.cadastroLoadFormData(this.updateUrl);
      }
    }
    // Senão, só exibe o spinner, caso o formulário precise carregar outros registros
    else if (this.props.needFormEntities) {
      this.props.cadastroShowSpinner(true);
    }
    // Zera a variável sobre algum atributo ter sido alterado
    this.props.cadastroSetChanged(false);
  }

  componentWillUnmount() {
    // Quando trocar de tela, seta variável de cadastro a ser alterado para falso
    this.props.cadastroSetChanged(false);
  }

  /**
  * Método executado ao clicar no botão "Gravar".
  * Recupera os dados do formulário e repassa para o método que irá persistir os dados.
  * @param {React.FormEvent<HTMLFormElement>} e 
  */
  handleGravar: React.FormEventHandler<HTMLFormElement> = e => {
    log("CadastroForm handleGravar", e);

    // Impede que o form redirecione para outra URL
    e.preventDefault();

    blurAll();

    getReduxWrappedComponent(this.formInstance).handleGravar();
  }

  /**
  * Método executado ao clicar no botão "Config".
  * Executa a função definida no formulário para o botão config.
  */
  handleConfig = () => {
    log("CadastroForm handleConfig");
    this.formInstance.handleConfig();
  }

  /**
   * Guarda os dados de formulário alterados pelo usuário para popular a tela
   * quando há erro no envio ou quando o idioma é alterado.
   * @param {*} formData objeto do cadastro contendo dados alterados
   */
  handleChange = () => {
    let newData = Object.assign({}, this.props.cadastroDados, this.formInstance.getFormData());
    log("CadastroForm handleChange", { newData });
    // Checa os campos obrigatórios do form, impedindo prosseguir caso nem todos os campos required forem preenchidos ainda
    if (!this.formInstance.checkRequired(newData)) {
      log("CadastroForm handleChange checkRequired failed");
      this.props.cadastroSetFilledRequired(false)
    } else {
      log("CadastroForm handleChange checkRequired success");
      this.props.cadastroSetFilledRequired(true)
    }
    // Se o usuário editar manualmente o cadastro e ele voltar a estar como estava antes,
    // permite voltar para a tela de lista de cadastros sem perguntar.
    if (!this.formInstance.wasChangedFromInitial(newData)) {
      log("CadastroForm handleChange 0 if");
      this.props.cadastroSetChanged(false);
    } else {
      log("CadastroForm handleChange 0 else");
      // Atualiza a variável sobre algum atributo ter sido alterado
      this.props.cadastroSetChanged(true);
    }
    // Se o usuário não alterou o valor de qualquer campo do cadastro
    if (!this.formInstance.wasChangedFromCurrent(newData)) {
      log("CadastroForm handleChange 1 if");
      return;
    } else {
      log("CadastroForm handleChange 1 else");
    }
    log("CadastroForm handleChange 2", newData);
    // Senão, guarda os dados
    this.props.cadastroUpdateFormData(newData);
  }

  loadForm = () => {
    const Component = this.props.formModel || (() => null);

    log("CadastroForm loadForm", { props: this.props, Component });

    return <Component ref={(ref: any) => this.formInstance = getReduxWrappedComponent(ref)} {...this.props} onSave={this.onSave} handleChange={this.handleChange} />;
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("CadastroForm render");

    // Verifica se deve ser exibido o botão Config
    let isShowConfig = this.props.objetoCadastroLast === "origemVenda";

    const Overlay = this.props.webAppOverlayReducer.componente || ((props) => null);

    return <div style={{ height: "100%" }}>
      <Overlay />

      <WidthAwareDiv>
        <HelpParagraph children={
          [getStrings().registerSaveFormHelp(this.props.operation === actionTypes.CADASTRO_EDIT)]
            // @ts-ignore
            .concat(((this.props.operation === actionTypes.CADASTRO_NEW) ? this.props.registerSaveHelp : this.props.registerUpdateHelp) || [])
            .map(help => formatI18nString(help))
        } />
      </WidthAwareDiv>
      <form onSubmit={this.handleGravar} method="post">

        <BarraAcoesForm
          handleListar={this.props.handleListar}
          // @ts-ignore
          handleConfig={this.handleConfig}
          isShowConfig={isShowConfig}
          isSubmit={true} />

        <WidthAwareDiv>
          {/* Exibe o formulário recebido por parâmetro */}
          {this.loadForm()}
        </WidthAwareDiv>
      </form>
      {/* Componente que precisa estar do lado de fora do form porque o HTML não permite um form dentro de outro. */}
      {this.props.objetoCadastroLast === "cargo" ? <WidthAwareDiv>
        <div className="sub-form">
          {/* @ts-ignore */}
          <CargoUsuarioForm ref={(input: any) => this.emailUsuario = getReduxWrappedComponent(input)} handleChange={this.handleChange} />
        </div>
      </WidthAwareDiv> : null}
    </div>;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
function mapStateToProps(state: reduxStateType) {
  const props: CadastroFormReduxStateType = {
    webAppOverlayReducer: state.webAppOverlayReducer,
    ...state.cadastroReducer
  };

  return props;
};

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch: dispatchTipo) {
  const props: CadastroFormReduxDispatchType = {
    cadastroSave: (formData, urlSave) => dispatch(actions.cadastroSave(formData, urlSave)),
    cadastroUpdate: (formData, urlUpdate) => dispatch(actions.cadastroUpdate(formData, urlUpdate)),
    cadastroSaveUpdate: (formData, updateUrl, urlDataBase, filter, params) => 
      dispatch(actions.cadastroSaveUpdate(formData, updateUrl, urlDataBase, filter, params)),
    cadastroUpdateFormData: cadastroDados => dispatch(actions.cadastroUpdateFormData(cadastroDados)),
    cadastroLoadFormData: (urlForm, callback) => dispatch(actions.cadastroLoadFormData(urlForm, callback)),
    cadastroShowSpinner: newRegister => dispatch(actions.cadastroShowSpinner(newRegister)),
    cadastroSetChanged: changed => dispatch(actions.cadastroSetChanged(changed)),
    cadastroSetFilledRequired: filledRequired => dispatch(actions.cadastroSetFilledRequired(filledRequired)),
    appNotificationShow: (notificationMessage, notificationType, notificationTitle, notificationAction, 
      notificationDismiss, notificationNoAction) =>
      dispatch(appActions.appNotificationShow(notificationMessage, notificationType, notificationTitle, 
        notificationAction, notificationDismiss, notificationNoAction)),
    appNotificationHide: () => dispatch(appActions.appNotificationHide()),
  }

  return props;
};

const CadastroForm = connect(mapStateToProps, mapDispatchToProps)(CadastroForm_) as React.ElementType<CadastroFormType>;

/**
 * Exporta o último argumento entre parênteses.
 */
export default CadastroForm;