import React from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";

export interface CustomButtonType extends Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  "onFocus"|"onMouseOver"|"onBlur"|"onMouseOut"
> {
  eventHandler?: (evt: React.FocusEvent<HTMLButtonElement>|React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  buttonType?: "normal"|"primary"|"secondary"
  link?: boolean
}

interface CustomButtonTypeWitReducer extends CustomButtonType {
  tabIndex?: number
}

function switchButtonType(buttonType: "normal"|"primary"|"secondary") {
  switch(buttonType) {
    case "primary":
      return "mobi-button primary-style";
    case "secondary":
      return "mobi-button secondary-style";
    default:
      return "mobi-button normal-style";
  }
}

// import "./Button.scss";
function CustomButton_({link =false, tabIndex = 0, buttonType = "normal", ...props}: CustomButtonTypeWitReducer) {
  return <button
    tabIndex={tabIndex}
    style={props.style}
    disabled={props.disabled}
    onClick={props.onClick}
    className={`${switchButtonType(buttonType)} ${props.className ? ` ${props.className}` : ""}`}
    id={props.id}
    onFocus={props.eventHandler}
    onMouseOver={props.eventHandler}
    onBlur={props.eventHandler}
    onMouseOut={props.eventHandler}>
    {props.children}
  </button>;
};

function mapStateToProps(state: any) {
  const props = {
    tabIndex: state.appReducer.getTabIndex(),
  };

  return props;
}

function mapDispatchToProps(dispatch: any) {
  const props = {

  };

  return props;
}

const CustomButton = connect(mapStateToProps,mapDispatchToProps)(CustomButton_);

export default CustomButton;