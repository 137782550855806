import React from 'react';
import { PERMISSAO_DEV } from '../../../utils/AuthUtils';
import { getUrl } from '../../../utils/SecureConnectionUtils';

import * as appActions from '../../../store/actions/appAction';

const DeleteTestUserButton = (props) => {
    const sanitized_props = {...props, id:undefined, onClick:undefined, children:undefined};

    return PERMISSAO_DEV
        ? <button {...sanitized_props} id="botaoRemoverEmailTeste" onClick={() => {
            appActions.axios(true).delete(getUrl('/api/usuarios'), { params: { email: 'teste@teste' } });
        }}>Deletar teste@teste</button>
        : null
};

export default DeleteTestUserButton;