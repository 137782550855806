import styled from "styled-components";
import { displayFlexCenter } from "../../css/styles";

const ImagensContainer = styled.div.attrs((props) => ({
  width: props.width || "80%",
  height: props.height || "350px",
}))`
  ${displayFlexCenter}
  position: relative;
  margin-bottom: 4rem;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-width: 400px;
`;

export default ImagensContainer;
