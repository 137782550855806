import React from "react"
import { connect } from "react-redux"
import { AccordionClick } from "../../UI/Accordion/Accordion"

import { getEmpresaId, loadEmpresaId } from "../../../utils/CompanyUtils"
import { log } from "../../../utils/LogUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import {
  GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_CLOSED,
  GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_OPEN
} from "../../../store/reducers/controleVenda/manutencao/manutencaoVendaReducer"

import BuildCellFromProduto from "./BuildCellFromProduto"
import BuildCellFromTamanho from "./BuildCellFromTamanho"

// let MutationObserver = require("mutation-observer")

/**
 * Função que monta a célula a partir do GrupoProduto.
 */
class BuildCellFromGrupoProduto extends React.Component {
  constructor() {
    super()
    this.state = {
      active: false,
      itemTabIndex: -1,
    }
  }

  mapKeysToActions = {
    "Enter": this.clickHandler,
    " ": (evt) => {
      evt.preventDefault()
      return this.clickHandler(evt)
    },
  }

  clickHandler = (evt) => {
    const active = AccordionClick(evt)
    this.setState({ active })
  }

  keyHandler = (evt) => {
    const element = evt.target

    if (typeof element.className === "string") {
      const classNameArray = element.className.split(" ")

      if (classNameArray.includes("card")) {
        try {
          this.mapKeysToActions[evt.key](evt)
        } catch (err) { }
      }
    }
  }

  /**
   * Altera a exibição do grid de grupos de acordo com a propriedade.
   */
  updateDisplay = () => {
    log("BuildCellFromGrupoProduto updateDisplay")

    if (this.accordionContent)
      switch (this.props.gridGrupoProdutoDisplay) {
        case GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_OPEN:
          if (!this.accordionContent.previousElementSibling.classList.contains("active")) {
            this.accordionContent.previousElementSibling.classList.toggle("active")
          }
          // this.accordionContent.style.maxHeight = this.accordionContent.scrollHeight + "px"
          this.state.active || this.setState({ active: true })
          break
        case GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_CLOSED:
        default:
          if (this.accordionContent.previousElementSibling.classList.contains("active")) {
            this.accordionContent.previousElementSibling.classList.toggle("active")
          }
          // this.accordionContent.style.maxHeight = "0px"
          !this.state.active || this.setState({ active: false })
          break
      }
  }

  /**
   * Método executado após o componente ser montado a primeira vez.
   */
  componentDidMount = () => {
    log("BuildCellFromGrupoProduto componentDidMount")
    const active = this.props.displaySelector === GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_OPEN
    this.setState({ active })

    // Define um método que será executado sempre que a lista de produtos do grupo for alterada.
    const mutationCallback = mutationList => {
      mutationList.forEach(mutation => {
        // Vide Accordion.js
        // Por causa do accordion, é necessário ajustar a altura manualmente quando são adicionados elementos depois do componente já estar sendo exibido.
        if (this.accordionContent.style.maxHeight !== "0px") {
          this.accordionContent.style.maxHeight = this.accordionContent.scrollHeight + "px"
        }
      })
    }

    // Cria um objeto para chamar o método acima
    this.mutationObserver = new MutationObserver(mutationCallback)

    // Vincula o objeto acima com a lista de produtos do grupo
    this.mutationObserver.observe(this.accordionContent, { childList: true })

    window.setTimeout(() => {
      this.updateDisplay()
    }, 10)

    if (!getEmpresaId()) {
      const uri = window.location.href.replace(`${window.location.protocol}//${window.location.host}`, "")
      const uriArray = uri.split("controleVendas?code=")
      const id = uriArray.length > 1 ? parseInt(uriArray[1]) : undefined

      loadEmpresaId(id)
    }
  }

  /**
   * Método executado APÓS a atualização do componente.
   */
  componentDidUpdate = (prevProps) => {
    log("BuildCellFromGrupoProduto componentDidUpdate", { prevProps })

    const itemTabIndex = this.state.active
      ? this.props.tabIndex
      : -1

    itemTabIndex === this.state.itemTabIndex
      || this.setState({ itemTabIndex })

    // Verifica se deve alterar a exibição dos grupos
    if (prevProps.gridGrupoProdutoDisplay !== this.props.gridGrupoProdutoDisplay) {
      this.updateDisplay()
    }
  }

  /**
   * Método executado ANTES de "DESMONTAR" o componente.
   * Marca o componente como NÃO montado.
   */
  componentWillUnmount = () => {
    log("BuildCellFromGrupoProduto componentWillUnmount")
    // Desvincula o objeto acima com a lista de produtos do grupo
    this.mutationObserver.disconnect()
  }

  render = () => {
    log("BuildCellFromGrupoProduto render")

    return <div key={"__build_cell_from_grupo_produto__"} className="pure-u-1 pure-u-sm-1-2 pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-4"
      ref={ref => {
        if (ref) {
          this.targetNode = ref
        }
      }}
    >
      <div
        tabIndex={this.props.tabIndex}
        className={`card grupo-produto card-container${isAncientChromeMobile ? " ancient-chrome-mobile" : ""}`}
        onClick={this.clickHandler}
        onKeyDown={this.keyHandler}
        style={{ height: "auto", maxHeight: undefined }}
      >
        <div id="accordion" name="accordion" className="card-content adjust build-cell-from-grupo-produto">
          <h1 className="ButtonAlign"> {this.props.grupoProduto.nome} </h1>
        </div>
        <div className="accordion-content" ref={x => this.accordionContent = x} style={{ height: "auto", maxHeight: undefined }}>
          {this.props.grupoProduto.grupoProdutoTamanhoList.length
            ? this.props.grupoProduto.grupoProdutoTamanhoList.map(grupoProdutoTamanho => {
              // Monta a célula para o tamanho
              return <BuildCellFromTamanho
                key={`__build_cell_from_tamanho_${this.props.grupoProduto.codigo}_${grupoProdutoTamanho.id}__`}
                tamanhoSelecionado={grupoProdutoTamanho}
                grupo={this.props.grupoProduto}
                newSaleItemList={this.props.newSaleItemList}
                tabIndex={this.state.itemTabIndex}
                abreTelaModal={this.props.abreTelaModal}
                fechaTelaModal={this.props.fechaTelaModal}
              />
            })
            : (this.props.newSaleItemList || []).map(newSaleItem => {
              // Monta a célula para o produto
              return <BuildCellFromProduto
                itemVenda={newSaleItem.itemVenda}
                key={getURIFromEntity(newSaleItem.itemVenda.produto) + "?s=" + (newSaleItem.sequence || 0)}
                newSaleItem={newSaleItem}
                produto={newSaleItem.itemVenda.produto}
                tabIndex={this.state.itemTabIndex}
              />
            })}
        </div>
      </div>
    </div>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  gridGrupoProdutoDisplay: state.manutencaoVendaReducer.gridGrupoProdutoDisplay,
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(BuildCellFromGrupoProduto)
