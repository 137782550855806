import { updateObject } from "../../utils/ObjectUtils"

import * as actionTypes from "../actions/actionTypes"


/**
 * Estado Inicial. Necessário para o controle de estados. 
 */
const initialState = {
  error: null
  , idUsuario: null
  , loading: false

  /**
   * Para ser exibido no menu, do lado do número de versão.
   */
  , nomeUsuario: { nome: "", sobrenome: "" }
  , sucess: false
  , tamanhoPadraoPaginacao: 10
  , token: null
  , usuario: null
}

/**
 * Reducer para controle geral da aplicação. 
 * Controla o Estado conforme a Ação executada. 
 * @param {*Estado inicial} state 
 * @param {*Ação executada} action 
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USUARIO_RESET_STATE: return resetState(state, action)

    case actionTypes.USUARIO_LOADING_START: return usuarioLoadingStart(state, action)
    case actionTypes.USUARIO_LOADING_SUCCESS: return usuarioLoadingSuccess(state, action)
    case actionTypes.USUARIO_LOADING_FAIL: return usuarioLoadingFail(state, action)

    case actionTypes.USUARIO_UPDATE_SUBMIT: return usuarioUpdateSubmit(state, action)
    case actionTypes.USUARIO_UPDATE_SUCCESS: return usuarioUpdateSuccess(state, action)
    case actionTypes.USUARIO_UPDATE_FAIL: return usuarioUpdateFail(state, action)

    case actionTypes.ALTERA_SENHA_SUBMIT: return alteraSenhaSubmit(state, action)
    case actionTypes.ALTERA_SENHA_SUCCESS: return alteraSenhaSuccess(state, action)
    case actionTypes.ALTERA_SENHA_FAIL: return alteraSenhaFail(state, action)

    case actionTypes.SOLICITA_REDEFINICAO_SENHA_SUBMIT: return solicitaRedefinicaoSenhaSubmit(state, action)
    case actionTypes.SOLICITA_REDEFINICAO_SENHA_SUCCESS: return solicitaRedefinicaoSenhaSuccess(state, action)
    case actionTypes.SOLICITA_REDEFINICAO_SENHA_FAIL: return solicitaRedefinicaoSenhaFail(state, action)

    case actionTypes.VALIDA_TOKEN_REDEFINIR_SENHA_SUBMIT: return validaTokenRedefinirSenhaSubmit(state, action)
    case actionTypes.VALIDA_TOKEN_REDEFINIR_SENHA_SUCCESS: return validaTokenRedefinirSenhaSuccess(state, action)
    case actionTypes.VALIDA_TOKEN_REDEFINIR_SENHA_FAIL: return validaTokenRedefinirSenhaFail(state, action)

    case actionTypes.REDEFINE_SENHA_SUBMIT: return redefineSenhaSubmit(state, action)
    case actionTypes.REDEFINE_SENHA_SUCCESS: return redefineSenhaSuccess(state, action)
    case actionTypes.REDEFINE_SENHA_FAIL: return redefineSenhaFail(state, action)

    case actionTypes.USUARIO_SET_STATE: return updateObject(state, {
      ...state,
      ...action,
    })

    default: return state
  }
}
export default reducer

const resetState = (state, action) => {
  return initialState
}


/*
 * USUARIO_LOADING
 */
const usuarioLoadingStart = (state, action) => {
  return updateObject(state, {
    error: null
    , loading: true
  })
}

const usuarioLoadingSuccess = (state, action) => {
  return updateObject(state, {
    usuario: action.usuario
    , loading: false
  })
}

const usuarioLoadingFail = (state, action) => {
  return updateObject(state, {
    usuario: null
    , error: action.error
    , loading: false
  })
}


/*
 * USUARIO_UPDATE
 */
const usuarioUpdateSubmit = (state, action) => {
  return updateObject(state, {
    loading: true
    , error: null
  })
}

const usuarioUpdateSuccess = (state, action) => {
  return updateObject(state, {
    error: null
    , usuario: action.usuario
    , loading: false
  })
}

const usuarioUpdateFail = (state, action) => {
  return updateObject(state, {
    error: action.error
    , loading: false
  })
}


/*
 * ALTERA_SENHA
 */
const alteraSenhaSubmit = (state, action) => {
  return updateObject(state, {
    loading: true
    , error: null
  })
}

const alteraSenhaSuccess = (state, action) => {
  return updateObject(state, {
    error: null
    , loading: false
  })
}

const alteraSenhaFail = (state, action) => {
  return updateObject(state, {
    error: action.error
    , loading: false
  })
}


/*
 * SOLICITA_REDEFINICAO_SENHA
 */
const solicitaRedefinicaoSenhaSubmit = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  })
}

const solicitaRedefinicaoSenhaSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false
  })
}

const solicitaRedefinicaoSenhaFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}


/*
 * VALIDA_TOKEN_REDEFINIR_SENHA
 */
const validaTokenRedefinirSenhaSubmit = (state, action) => {
  return updateObject(state, {
    loading: true,
    idUsuario: null,
    token: null,
    error: null
  })
}

const validaTokenRedefinirSenhaSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    idUsuario: action.idUsuario,
    token: action.token,
    loading: false
  })
}

const validaTokenRedefinirSenhaFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    idUsuario: null,
    token: null,
    loading: false
  })
}


/*
 * REDEFINE_SENHA
 */
const redefineSenhaSubmit = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  })
}

const redefineSenhaSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    sucess: true,
    token: null,
    loading: false
  })
}

const redefineSenhaFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}