import { Auth } from './Config';

export function loadPagseguroLightbox(code, pagSeguroCallback, esconderOverlay, errorCallback) {

    const scriptId = 'pagseguro-lightbox';
    const scriptExists = document.getElementById(scriptId);

    if (scriptExists) {
        window.PagSeguroLightbox(code, pagSeguroCallback);
        //esconderOverlay();
    }
    else {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = Auth.MODO_DESENVOLVEDOR
            ? 'https://stc.sandbox.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js'
            : 'https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js';

        document.body.appendChild(script);

        script.onload = () => {
            window.PagSeguroLightbox(code, pagSeguroCallback);
            //esconderOverlay();
        }

        script.onerror = () => {
            errorCallback();
        }
    }
}