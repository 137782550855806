import React from 'react';
import criarAction from "../factory/criarAction";

const tiposDeAcoes = {
    ATUALIZAR_OVERLAY: 'ATUALIZAR_OVERLAY:OVERLAY',
    LIMPAR_OVERLAY: 'LIMPAR_OVERLAY:OVERLAY',
}

export default tiposDeAcoes;

export const mostrarOverlay = (carga: {componente: React.ReactNode}) => function(dispatch: (carga: any) => void) {
    dispatch(criarAction(carga, tiposDeAcoes.ATUALIZAR_OVERLAY));
};

export const esconderOverlay = () => function(dispatch: (carga: any) => void) {
    dispatch(criarAction(undefined, tiposDeAcoes.LIMPAR_OVERLAY));
};
