import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getNomeProdutoFromItemVenda } from "../../../utils/MiscUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"
import { formatNumber } from "../../../utils/NumberUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"

import * as pagamentoVendasActions from "../../../store/actions/controleVenda/pagamentoVendasAction"

import InputCustomizado from "../../UI/Input/InputCustomizado"

const QuantidadeButton = (props) => {
  return <button
    {...props}
    className={`quant-button blue-button font-color${props.className ? ` ${props.className}` : ''}`}
  />;
};

const SpinnerQuantidade = (props) => {
  return <div className={`right table-cell pay ButtonAlign${props.new ? ' input-group pagamento' : ''}`} >{
    props.new
      // Se for não persistido, exibe spinner de quantidade.
      ? (<>
        <QuantidadeButton
          tabIndex={props.tabIndex}
          className='pure-u-1-3 pure-u-sm-6-24 button grid-button left'
          onClick={() => props.setNewSalePaymentItemAmount([props.salePaymentItem.key], -1, false)}
        >
          −
        </QuantidadeButton>
        <div className='pure-u-1-3 pure-u-sm-12-24 quantidade' >{
          props.salePaymentItem.quantidade
        }</div>
        <QuantidadeButton
          tabIndex={props.tabIndex}
          className='pure-u-1-3 pure-u-sm-6-24 button grid-button right'
          onClick={() => props.setNewSalePaymentItemAmount([props.salePaymentItem.key], +1, false)}
        >
          +
        </QuantidadeButton>
      </>)
      // Senão, só exibe a quantidade.
      : props.salePaymentItem.quantidade
  }</div>;
}

/**
 * Função que monta a celula a partir do item de pagamento da venda.
 */
class BuildCellFromItemPagamentoVenda extends React.Component {

  /**
   * Metodo executado após a atualização do componente.
   */
  componentDidUpdate() {
    log('BuildCellFromItemPagamentoVenda componentDidUpdate');
    if (this.props.new) {
      this.checkboxInput.inputComponent.checked = this.props.salePaymentItem.checked;
    }
  }

  render() {
    log('BuildCellFromItemPagamentoVenda render');

    // Para garantir que id e nome serão únicos
    let id = `${this.props.salePaymentItem.key}&n=${this.props.new}&component=checkBox`;
    // Busca o nome do produto
    let name = getNomeProdutoFromItemVenda(this.props.salePaymentItem);
    // Se for um produto a kilo
    if (this.props.salePaymentItem.produto.quantidadePesada) {
      // Adiciona o peso no nome
      name = getStrings().weightedAmountName(name, this.props.salePaymentItem.weightedAmount);
    }
    // Se for um produto a kilo vendido como livre
    if (this.props.salePaymentItem.livre) {
      // Adiciona " (livre)" no nome
      name = getStrings().allYouCanEatName(name);
    }

    if (!isAncientChromeMobile) {
      return <div className='table-pagamento table-row build-cell-from-item-pagamento-vendas'>
        {/* Check box */}
        <div className='table-cell check ButtonAlign' >
          {this.props.new
            ? <InputCustomizado
              tabIndex={this.props.tabIndex}
              ref={checkbox => this.checkboxInput = getReduxWrappedComponent(checkbox)}
              id={id}
              type='checkbox'
              name={id}
              handleInputValidado={() => this.props.setNewSalePaymentItemAmount(
                [this.props.salePaymentItem.key],
                this.props.salePaymentItem.checked ? -1 : +1,
                true)}
              disabled={!this.props.new}
            />
            : null
          }
        </div>
        {/* Nome */}
        <div className='table-cell product TextAlign' >
          {name}
        </div>
        {/* Quantidade restante */}
        <div className='table-cell amount NumberAlign' >{
          this.props.remainingAmount
        }</div>
        {/* Preço */}
        <div className='right table-cell price NumberAlign' >{
          formatNumber(this.props.salePaymentItem.precoUnitario, 2)
        }</div>
        {/* Spinner de quantidade */}
        <SpinnerQuantidade {...this.props} />
        {/* Valor total */}
        <div className='right table-cell total NumberAlign' >{
          formatNumber(this.props.salePaymentItem.valorTotal, 2)
        }</div>
      </div>;
    }


    return <div className='table-pagamento table-row build-cell-from-item-pagamento-vendas'>
      <div className='row'>
        {/* Check box */}
        <div className='table-cell check ButtonAlign' >
          {this.props.new
            ? <InputCustomizado
              tabIndex={this.props.tabIndex}
              ref={checkbox => this.checkboxInput = getReduxWrappedComponent(checkbox)}
              id={id}
              type='checkbox'
              name={id}
              handleInputValidado={() => this.props.setNewSalePaymentItemAmount(
                [this.props.salePaymentItem.key],
                this.props.salePaymentItem.checked ? -1 : +1,
                true)}
              disabled={!this.props.new}
            />
            : null
          }
        </div>
        {/* Nome */}
        <div className='table-cell product TextAlign' >
          {name}
        </div>
        {/* Quantidade restante */}
        <div className='table-cell amount NumberAlign' >{
          this.props.remainingAmount
        }</div>
        {/* Preço */}
        <div className='table-cell price NumberAlign' >{
          formatNumber(this.props.salePaymentItem.precoUnitario, 2)
        }</div>
        {/* Spinner de quantidade */}
        <div className='table-cell spinner-quantidade NumberAlign'>
          <SpinnerQuantidade {...this.props} />
        </div>
        {/* Valor total */}
        <div className='table-cell total NumberAlign' >{
          formatNumber(this.props.salePaymentItem.valorTotal, 2)
        }</div>
      </div>
    </div>;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  ...state.idiomaReducer,
  tabIndex: state.appReducer.getTabIndex()
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  setNewSalePaymentItemAmount: (salePaymentItemKeyList, sign, all) =>
    dispatch(pagamentoVendasActions.setNewSalePaymentItemAmount(salePaymentItemKeyList, sign, all))
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BuildCellFromItemPagamentoVenda);
