/*
 * Reducer
 */

/**
 * Define o valor de uma variável no *reducer* para o valor padrão.
 */
export const REDUCER_DEFAULT = 'REDUCER_DEFAULT';

/**
 * Define o valor de uma variável no *reducer* para o valor anterior.
 */
export const REDUCER_HOLD = 'REDUCER_HOLD';

/**
 * Define o valor de uma variável no *reducer* para um novo valor.
 */
export const REDUCER_SET = 'REDUCER_SET';

/**
 * Define o valor de uma variável no *reducer* para um novo valor baseado no valor anterior.
 */
export const REDUCER_UPDATE = 'REDUCER_UPDATE';

/*
 * App 
 */

/**
 * Ação para exibir um componente modal, seja um *dialog*, uma notificação ou um *spinner*.
 */
export const APP_MODAL = 'APP_MODAL';

/**
 * Notificação de informação.
 */
export const APP_NOTIFICATION_TYPE_INFO = 'APP_NOTIFICATION_TYPE_INFO';

/**
 * Notificação de informação com pergunta Sim/Não.
 */
export const APP_NOTIFICATION_TYPE_INFO_QUESTION = 'APP_NOTIFICATION_TYPE_INFO_QUESTION';

/**
 * Notificação de sucesso.
 */
export const APP_NOTIFICATION_TYPE_SUCCESS = 'APP_NOTIFICATION_TYPE_SUCCESS';

/**
 * Notificação de sucesso que pode ser desfeito.
 */
export const APP_NOTIFICATION_TYPE_SUCCESS_UNDOABLE = 'APP_NOTIFICATION_TYPE_SUCCESS_UNDOABLE';

/**
 * Notificação de aviso.
 */
export const APP_NOTIFICATION_TYPE_WARNING = 'APP_NOTIFICATION_TYPE_WARNING';

/**
 * Notificação de aviso com pergunta Sim/Não.
 */
export const APP_NOTIFICATION_TYPE_WARNING_QUESTION = 'APP_NOTIFICATION_TYPE_WARNING_QUESTION';

/**
 * Notificação de falha.
 */
export const APP_NOTIFICATION_TYPE_ERROR = 'APP_NOTIFICATION_TYPE_ERROR';

/**
 * Ação para exibir ou ocultar os textos de ajuda.
 */
export const APP_HELP_TOGGLE = 'APP_MODAL';

/*
 * Snackbars.
 */
export const APP_SNACKBAR_MESSAGE = 'APP_SNACKBAR_MESSAGE';
export const APP_SNACKBAR_CLEAR = 'APP_SNACKBAR_CLEAR';

/*
 * Tour de alguma funcionalidade do site.
 */
export const APP_START_TOUR = 'APP_START_TOUR';
export const APP_CLOSE_TOUR = 'APP_CLOSE_TOUR';

/*
 * Sidedrawer.
 */
export const APP_SIDEDRAWER_TOGGLE = 'APP_SIDEDRAWER_TOGGLE';
export const APP_SIDEDRAWER_CLOSE_HANDLER = 'APP_SIDEDRAWER_SET_CLOSE_HANDLER';
export const APP_SIDEDRAWER_SET = 'APP_SIDEDRAWER_SET';

/*
 * Autenticação de Usuário (Authentication) 
 */
export const AUTH_CHECK_STATE_SUCCESS = 'AUTH_CHECK_STATE_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SET_HUB_TOKEN = 'AUTH_SET_HUB_TOKEN';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_UPDATE = 'AUTH_UPDATE';

/*
 * Cadastro de novo Usuário (Sign Up) 
 */
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';
export const SIGN_UP_START = 'SIGN_UP_START';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';

export const SIGN_UP_STEP = 'SIGN_UP_STEP';

/**
 * Atualização do estado.
 */
export const SIGN_UP_STATE = 'SIGN_UP_STATE';

/**
 * Atualização do token gerado pelo reCAPTCHA.
 */
export const SIGN_UP_TOKEN = 'SIGN_UP_TOKEN';

/**
 * Atualização de score, suggestions e warning do cadastroUtils
 */
export const SIGN_UP_SCORE = 'SIGN_UP_SCORE';
export const SIGN_UP_SUGGESTIONS = 'SIGN_UP_SUGGESTIONS';
export const SIGN_UP_WARNING = 'SIGN_UP_WARNING';

/**
 * Ações relativas ao cadastro de usuário/empresa;
 */
export const SIGN_UP = 'SIGN_UP';

/*
 * Confirmação de email de novo Usuário
 */
export const EMAIL_CONFIRMATION_START = 'EMAIL_CONFIRMATION_START';
export const EMAIL_CONFIRMATION_SUCCESS = 'EMAIL_CONFIRMATION_SUCCESS';
export const EMAIL_CONFIRMATION_FAIL = 'EMAIL_CONFIRMATION_FAIL';

/*
 * Seletor de Empresa
 */
export const EMPRESA_SELECTOR_LOADING_START = 'EMPRESA_SELECTOR_LOADING_START';
export const EMPRESA_SELECTOR_LOADING_SUCCESS = 'EMPRESA_SELECTOR_LOADING_SUCCESS';
export const EMPRESA_SELECTOR_LOADING_FAIL = 'EMPRESA_SELECTOR_LOADING_FAIL';
export const EMPRESA_SELECTOR_CARGO_SELECIONADO_SUBMIT = 'EMPRESA_SELECTOR_CARGO_SELECIONADO_SUBMIT';
export const EMPRESA_SELECTOR_CARGO_SELECIONADO_SUCCESS = 'EMPRESA_SELECTOR_CARGO_SELECIONADO_SUCCESS';
export const EMPRESA_SELECTOR_CARGO_SELECIONADO_FAIL = 'EMPRESA_SELECTOR_CARGO_SELECIONADO_FAIL';
export const EMPRESA_SELECTOR_UPDATE_NOME_EMPRESA = 'EMPRESA_SELECTOR_UPDATE_NOME_EMPRESA';
export const EMPRESA_SELECTOR = 'EMPRESA_SELECTOR';

/*
 * Alteração de Dados/Senha do Usuário
 */
export const USUARIO_SET_STATE = 'USUARIO_SET_STATE';
export const USUARIO_RESET_STATE = 'USUARIO_RESET_STATE';
export const USUARIO_LOADING_START = 'USUARIO_LOADING_START';
export const USUARIO_LOADING_SUCCESS = 'USUARIO_LOADING_SUCCESS';
export const USUARIO_LOADING_FAIL = 'USUARIO_LOADING_FAIL';
export const USUARIO_UPDATE_SUBMIT = 'USUARIO_UPDATE_SUBMIT';
export const USUARIO_UPDATE_SUCCESS = 'USUARIO_UPDATE_SUCCESS';
export const USUARIO_UPDATE_FAIL = 'USUARIO_UPDATE_FAIL';
export const ALTERA_SENHA_SUBMIT = 'ALTERA_SENHA_SUBMIT';
export const ALTERA_SENHA_SUCCESS = 'ALTERA_SENHA_SUCCESS';
export const ALTERA_SENHA_FAIL = 'ALTERA_SENHA_FAIL';
export const SOLICITA_REDEFINICAO_SENHA_SUBMIT = 'SOLICITA_REDEFINICAO_SENHA_SUBMIT';
export const SOLICITA_REDEFINICAO_SENHA_SUCCESS = 'SOLICITA_REDEFINICAO_SENHA_SUCCESS';
export const SOLICITA_REDEFINICAO_SENHA_FAIL = 'SOLICITA_REDEFINICAO_SENHA_FAIL';
export const VALIDA_TOKEN_REDEFINIR_SENHA_SUBMIT = 'VALIDA_TOKEN_REDEFINIR_SENHA_SUBMIT';
export const VALIDA_TOKEN_REDEFINIR_SENHA_SUCCESS = 'VALIDA_TOKEN_REDEFINIR_SENHA_SUCCESS';
export const VALIDA_TOKEN_REDEFINIR_SENHA_FAIL = 'VALIDA_TOKEN_REDEFINIR_SENHA_FAIL';
export const REDEFINE_SENHA_SUBMIT = 'REDEFINE_SENHA_SUBMIT';
export const REDEFINE_SENHA_SUCCESS = 'REDEFINE_SENHA_SUCCESS';
export const REDEFINE_SENHA_FAIL = 'REDEFINE_SENHA_FAIL';

/*
 * Cadastro genérico
 */

/** 
 * Ao trocar entre tela de edição e tela de exibição de cadastros.
 */
export const CADASTRO_SWITCH_UI = 'CADASTRO_SWITCH_UI';

/** 
 * Ao criar cadastros.
 */
export const CADASTRO_NEW = 'CADASTRO_NEW';

/** 
 * Ao editar cadastros.
 */
export const CADASTRO_EDIT = 'CADASTRO_EDIT';

/** 
 * Ao carregar cadastros.
 */
export const CADASTRO_LOAD = 'CADASTRO_LOAD';

/** 
 * Ao iniciar criação, edição ou deleção de cadastro.
 */
export const CADASTRO_SUBMIT = 'CADASTRO_SUBMIT';

/** 
 * Ao concluir criação, edição ou deleção de cadastro com sucesso.
 */
export const CADASTRO_SUCCESS = 'CADASTRO_SUCCESS';

/** 
 * Ao concluir criação, edição ou deleção de cadastro com falha.
 */
export const CADASTRO_FAIL = 'CADASTRO_FAIL';

/**
 * Ações relativas à tela de configurações.
 */
export const CADASTRO_SETUP = 'CADASTRO_SETUP';

/**
 * Ações relativas à impressão de códigos de barras/QR de cadastros
 */
export const CADASTRO_PRINT = 'CADASTRO_PRINT';

/**
 * Ações relativas ao carregamento da biblioteca para impressão de códigos de barras/QR de cadastros
 */
export const CADASTRO_PRINT_TEXT_TO_SVG = 'CADASTRO_PRINT_TEXT_TO_SVG';

export const CADASTRO_UPDATE_BARRA_ACOES_TABLE = "CADASTRO_UPDATE_BARRA_ACOES_TABLE";

/** 
 * Limpeza do filtro de busca ao trocar de tela.
 */
 export const CADASTRO_CLEAN_FILTER = 'CADASTRO_CLEAN_FILTER';

 /** 
 * Reseta o número da página ao trocar de tela.
 */
  export const CADASTRO_RESET_PAGE_NUMBER = 'CADASTRO_RESET_PAGE_NUMBER';

//------------------------------------------------------------------//
/*
 * CADASTRO DE EMPRESA
 */

/**
 * Editar dados de uma empresa.
 */
export const CADASTRO_EMPRESA_EDIT = 'CADASTRO_EMPRESA_EDIT';

/**
 * Solicitar confirmação de usuário.
 */
export const CADASTRO_EMPRESA_USUARIO_NOVO = 'CADASTRO_EMPRESA_USUARIO_NOVO';

/**
 * Carregar usuário confirmado.
 */
export const CADASTRO_EMPRESA_USUARIO_LOAD = 'CADASTRO_EMPRESA_USUARIO_LOAD';

/**
 * Mostrar spinner após enviar email de confirmação.
 */
export const CADASTRO_EMPRESA_EMAIL_SPINNER = 'CADASTRO_EMPRESA_EMAIL_SPINNER';

/**
 * Mostrar spinner após enviar email de confirmação.
 */
export const CADASTRO_EMPRESA_NORMAL_SPINNER = 'CADASTRO_EMPRESA_NORMAL_SPINNER';

/**
 * Mostrar spinner após enviar email de confirmação.
 */
export const CADASTRO_EMPRESA_DEMO_SPINNER = 'CADASTRO_EMPRESA_DEMO_SPINNER';

/**
 * Cadastrar cargo.
 */
export const CADASTRO_EMPRESA_CARGO = 'CADASTRO_EMPRESA_CARGO';

/**
 * Cadastrar contrato.
 */
export const CADASTRO_EMPRESA_CONTRATO = 'CADASTRO_EMPRESA_CONTRATO';

/**
 * Outros dados da empresa.
 */
export const CADASTRO_EMPRESA_DADOS = 'CADASTRO_EMPRESA_DADOS';

/**
 * Cadastrar empresa de demonstração.
 */
export const CADASTRO_EMPRESA_DEMO = 'CADASTRO_EMPRESA_DEMO';

/**
 * Troca o estado durante o cadastro de Empresa e Usuário.
 */
export const CADASTRO_EMPRESA_E_USUARIO_CHANGE_STATE = 'CADASTRO_EMPRESA_E_USUARIO_CHANGE_STATE';

/**
 * Armazena os dados durante o cadastro de Empresa e Usuário.
 */
export const CADASTRO_EMPRESA_E_USUARIO_SAVE_DATA = 'CADASTRO_EMPRESA_E_USUARIO_SAVE_DATA';

export const CADASTRO_EMPRESA_LIST_EXPANDED_MODE = "CADASTRO_EMPRESA_LIST_EXPANDED_MODE";

//------------------------------------------------------------------//
/*
 * CONTROLE DE VENDA
 */

/**
 * Ação quando o leitor de código de barras ou QR é aberto.
 */
export const CONTROLE_VENDA_CODE_READER_OPENED = 'CONTROLE_VENDA_CODE_READER_OPENED';

/** 
 * Ação para adicionar no `reducer` uma venda nova através da atualização da lista
 * de origens por uma lista contendo apenas a origem que contém essa venda.
 */
export const CONTROLE_VENDA_LOAD_NOVA_VENDA = 'CONTROLE_VENDA_LOAD_NOVA_VENDA';

/** 
 * Ação para atualizar a lista de origens de venda.
 */
export const CONTROLE_VENDA_LOAD_ORIGEM_VENDA_LIST = 'CONTROLE_VENDA_LOAD_ORIGEM_VENDA_LIST';

/** 
 * Ação para substituir a lista de origens de venda por uma lista contendo somente as origens para pagamento.
 */
export const CONTROLE_VENDA_LOAD_ORIGEM_VENDA_LIST_PAGAR = 'CONTROLE_VENDA_LOAD_ORIGEM_VENDA_LIST_PAGAR';

/**
 * Ação para atualizar a lista de origens de venda sem vendas abertas.
 */
export const CONTROLE_VENDA_LOAD_FREE = 'CONTROLE_VENDA_LOAD_FREE';

/**
 * Ação para atualizar a lista de origens de venda que receberam chamado a garçom.
 */
export const CONTROLE_VENDA_LOAD_UNREAD = 'CONTROLE_VENDA_LOAD_UNREAD';

/** 
 * Ação para atualizar a lista de vendas através da atualização da lista de origens
 * por uma lista contendo apenas a origem que contém as vendas.
 */
export const CONTROLE_VENDA_LOAD_VENDA_LIST = 'CONTROLE_VENDA_LOAD_VENDA_LIST';

/** 
 * Ação para alterar o estado da máquina de estados para refletir que a venda nova virou uma venda persistida.
 */
export const CONTROLE_VENDA_PERSIST_VENDA = 'CONTROLE_VENDA_PERSIST_VENDA';

/** 
 * Ação para atualizar a lista de origens de venda livres no `reducer`.
 */
export const CONTROLE_VENDA_REMOVE_FREE_ORIGEM_VENDA = 'CONTROLE_VENDA_REMOVE_FREE_ORIGEM_VENDA';

/** 
 * Ação para atualizar a venda no `reducer` através da atualização da lista
 * de origens por uma lista contendo apenas a origem que contém a venda.
 */
export const CONTROLE_VENDA_REMOVE_ITEM_VENDA = 'CONTROLE_VENDA_REMOVE_ITEM_VENDA';

/** 
 * Ação para atualizar a lista de origens de venda em uso no `reducer`.
 */
export const CONTROLE_VENDA_REMOVE_ORIGEM_VENDA_IN_USE = 'CONTROLE_VENDA_REMOVE_ORIGEM_VENDA_IN_USE';

/** 
 * Ação para alterar o estado da máquina de estados para o estado inicial. Isso é feito porque, sem isso,
 * ao sair do controle de vendas o estado fica armazenado. Ao entrar novamente no controle de vendas,
 * a última tela que estava aberta é exibida e somente depois é alterada para a tela inicial.
 */
export const CONTROLE_VENDA_RESET_STATE = 'CONTROLE_VENDA_RESET_STATE';

/**
 * Ação para atualizar dados quaisquer no *reducer*.
 */
export const CONTROLE_VENDA_SET_VALUE = 'CONTROLE_VENDA_SET_VALUE';

/**
 * Ação para alterar o estado da máquina de estados para exibir a tela das origens em uso.
 */
export const CONTROLE_VENDA_SHOW_GRID_ORIGENS = 'CONTROLE_VENDA_SHOW_GRID_ORIGENS';

/**
 * Ação para alterar o estado da máquina de estados para exibir a tela das vendas abertas de uma origem.
 */
export const CONTROLE_VENDA_SHOW_GRID_VENDAS = 'CONTROLE_VENDA_SHOW_GRID_VENDAS';

/**
 * Ação para alterar o estado da máquina de estados para exibir a tela das origens em uso.
 */
export const CONTROLE_VENDA_SHOW_MANUTENCAO_VENDA = 'CONTROLE_VENDA_SHOW_MANUTENCAO_VENDA';

/**
 * Ação para alterar o estado da máquina de estados para exibir a tela de pagamento,
 * tanto de várias vendas de uma origem como de várias vendas de várias origens.
 */
export const CONTROLE_VENDA_SHOW_PAGAMENTO_VENDAS = 'CONTROLE_VENDA_SHOW_PAGAMENTO_VENDAS';

/**
 * Ação para atualizar, no `reducer`, os dados da venda que dependem do percentual ou valor de desconto.
 */
export const CONTROLE_VENDA_UPDATE_DISCOUNT = 'CONTROLE_VENDA_UPDATE_DISCOUNT';

/**
 * Ação para atualizar, no `reducer`, os dados da venda que dependem do percentual de comissão.
 */
export const CONTROLE_VENDA_UPDATE_PERCENTUAL_COMISSAO = 'CONTROLE_VENDA_UPDATE_PERCENTUAL_COMISSAO';

/**
 * Ação para atualizar a venda no `reducer` através da atualização da lista
 * de origens por uma lista contendo apenas a origem que contém a venda.
 */
export const CONTROLE_VENDA_UPDATE_VENDA = 'CONTROLE_VENDA_UPDATE_VENDA';

//------------------------------------------------------------------//
/*
 * MANUTENCAO VENDA
 */

/** 
 * Genérico.
 */
export const MANUTENCAO_VENDA_EXIBE = 'MANUTENCAO_VENDA_EXIBE';

/** 
 * Chama a alteração da tela que está sendo exibida na manutenção de vendas.
 */
export const MANUTENCAO_VENDA_SET_MANUTENCAO_CONTENT = 'MANUTENCAO_VENDA_SET_MANUTENCAO_CONTENT';

/** 
 * Chama a alteração da tela que está sendo exibida na manutenção de vendas.
 */
export const MANUTENCAO_VENDA_SET_HAS_ITENS_TO_SEND = 'MANUTENCAO_VENDA_SET_HAS_ITENS_TO_SEND';

/** 
 * Chama a alteração da tela que está sendo exibida na manutenção de vendas.
 */
export const MANUTENCAO_VENDA_SET_IS_EXIBE_ENCERRAMENTO = 'MANUTENCAO_VENDA_SET_IS_EXIBE_ENCERRAMENTO';

/**
 * Chama a adição/remoção do item de pagamento.
 */
export const MANUTENCAO_VENDA_ADICIONA_REMOVE_ITEM_VENDA = 'MANUTENCAO_VENDA_ADICIONA_REMOVE_ITEM_VENDA';

/** 
 * Chama a atualização dos itens da venda.
 */
export const MANUTENCAO_VENDA_UPDATE_NOVOS_ITENS_VENDA = 'MANUTENCAO_VENDA_UPDATE_NOVOS_ITENS_VENDA';

/** 
 * Chama a atualização da lista de referências dos produtos.
 */
export const MANUTENCAO_VENDA_UPDATE_PRODUTO_REF_LIST = 'MANUTENCAO_VENDA_UPDATE_PRODUTO_REF_LIST';

/** 
 * Chama a limpeza/reinicialização dos variáveis de estado da manutenção de venda.
 */
export const MANUTENCAO_VENDA_LIMPA_MANUTENCAO_VENDA = 'MANUTENCAO_VENDA_LIMPA_MANUTENCAO_VENDA';

//------------------------------------------------------------------//
/*
 * GRID GRUPO PRODUTO
 */

/** 
 * Chama o carregamento dos produtos de grupo que possuem produtos vinculados.
 */
export const GRID_GRUPO_PRODUTO_GRUPO_PRODUTO_LOAD = 'GRID_GRUPO_PRODUTO_GRUPO_PRODUTO_LOAD';

/** 
 * Reordena os produtos carregados.
 */
export const GRID_GRUPO_PRODUTO_GRUPO_PRODUTO_SORT = 'GRID_GRUPO_PRODUTO_GRUPO_PRODUTO_SORT';

/*
 * INTERNACIONALIZAÇÃO
 */
export const I18N = 'I18N';

/*
 * PRODUÇÃO
 */
export const PRODUCAO = 'PRODUCAO';

/*
 * PAGAMENTO VENDAS
 */

/**
 * Adiciona um pagamento de venda para ser cancelado.
 */
export const PAGAMENTO_VENDAS_ADD_CANCEL = 'PAGAMENTO_VENDAS_ADD_CANCEL';

/**
 * Remove todos os pagamentos de venda para serem cancelados.
 */
export const PAGAMENTO_VENDAS_CLEAR_CANCEL = 'PAGAMENTO_VENDAS_CLEAR_CANCEL';

/**
 * Remove um pagamento de venda para ser cancelado.
 */
export const PAGAMENTO_VENDAS_REMOVE_CANCEL = 'PAGAMENTO_VENDAS_REMOVE_CANCEL';

/**
 * Atualiza os dados na tela.
 */
export const PAGAMENTO_VENDAS_UPDATE = 'PAGAMENTO_VENDAS_UPDATE';

/**
 * Atualiza quantidade de novos itens de pagamento de venda, necessitando atualização dos totais.
 */
export const PAGAMENTO_VENDAS_UPDATE_NEW_SALE_PAYMENT_ITEM = 'PAGAMENTO_VENDAS_UPDATE_NEW_SALE_PAYMENT_ITEM';

/*
 * RELATÓRIOS
 */

/**
 * Ações nas telas de relatório.
 */
export const RELATORIO = 'RELATORIO';

//------------------------------------------------------------------//
/*
 * LEITOR DE CÓDIGO DE BARRAS OU QR
 */

/**
 * Ações relacionadas ao leitor de código de barras/QR.
 */
export const CODE_READER_ACTION = 'CODE_READER_ACTION';

//------------------------------------------------------------------//
/*
 * CONTROLE DE VENDA PARA EMPRESAS DO RAMO COLETOR
 */

/**
 * Ação para exibir o leitor de código de barras ou QR.
 */
export const COLLECTOR_CODE_READER = 'COLLECTOR_CODE_READER';

/**
 * Ação para incrementar a quantidade de um produto.
 */
export const COLLECTOR_INCREMENT = 'COLLECTOR_INCREMENT';

/**
 * Ação para carregar na tela coletores da retaguarda.
 */
export const COLLECTOR_LOAD = 'COLLECTOR_LOAD';

/**
 * Ação para gerenciar a venda de um cliente.
 */
export const COLLECTOR_MANAGE = 'COLLECTOR_MANAGE';

/**
 * Ação para carregar na tela produtos da retaguarda.
 */
export const COLLECTOR_PRODUCT_LOAD = 'COLLECTOR_PRODUCT_LOAD';

/**
 * Ação para remover produtos filtrados da tela.
 */
export const COLLECTOR_REMOVE = 'COLLECTOR_REMOVE';

//------------------------------------------------------------------//
/*
 * HOME EXTERNA
 */

/**
 * Ação para exibir dados da empresa ao escanear código.
 */
export const COMPANY_INFORMATION = 'COMPANY_INFORMATION';


/**
 * cadastroReducer
 * UPDATE_VIEW_ID
 */
export const UPDATE_VIEW_ID = 'UPDATE_VIEW_ID';


/**
 * appReducer
 * UPDATE_FORCE_CLOSE_STORE_FLAG
 */
export const UPDATE_FORCE_CLOSE_STORE_FLAG = 'UPDATE_FORCE_CLOSE_STORE_FLAG';


/**
 * 
 */
export const UPDATE_FULLSCREEN = 'UPDATE_FULLSCREEN:APP_REDUCER';
// CODIGO DE CONFIRMACAO
export const ATIVAR_INPUT_DO_CODIGO_DE_CONFIRMACAO = 'ATIVAR_INPUT_DO_CODIGO_DE_CONFIRMACAO:CODIGO_DE_CONFIRMACAO';
export const DESATIVAR_INPUT_DO_CODIGO_DE_CONFIRMACAO = 'DESATIVAR_INPUT_DO_CODIGO_DE_CONFIRMACAO:CODIGO_DE_CONFIRMACAO';