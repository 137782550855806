import React from "react"
import { push } from "connected-react-router"

import { connect } from "react-redux"

import { ClienteHttp } from "../../../apps/promo_app_frontend/nucleo/utils/utils"
import { getAppUsuarioURI } from "../../../utils/AppUtils"
import { forEach, newArray } from "../../../utils/ArrayUtils"
import { isEmpresaPainel, isEmpresaRestauranteAKilo } from "../../../utils/CompanyUtils"
import { nullOrUndefined } from "../../../utils/ComparatorsUtils"
import { populateFromDB } from "../../../utils/DatabaseUtils"
import { isProdutoProducaoUnitaria  } from "../../../utils/EntityUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { convertArrayToObject } from "../../../utils/ObjectUtils"
import { formatI18nString } from "../../../utils/StringUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import * as actionTypes from "../../../store/actions/actionTypes"
import {
  STATE_CONTROLE_VENDA_NOVA_VENDA
  , STATE_CONTROLE_VENDA_GET_SALE_FROM_CANCEL,
  ESTADO_ITEM_VENDA_PRODUZIDO,
  ESTADO_VENDA_AGUARDANDO_ENCERRAMENTO
} from "../../../store/reducers/controleVenda/controleVendaReducer"
import {
  NIVEL_USUARIO_SEM_EMPRESA_MENU
  , NIVEL_USUARIO_SEM_EMPRESA_VENDA
} from "../../../store/reducers/empresaSelectorReducer"
import { messageLayoutIds } from "../../../store/reducers/placeholderMessageReducer"
import * as appActions from "../../../store/actions/appAction"
import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"
import * as empresaSelectorActions from "../../../store/actions/empresaSelectorAction"
import { limparEntradaBusca } from "../../../store/reducers/entradas/entradaBusca/entradaBuscaActions"
import * as gridGrupoProdutoActions from "../../../store/actions/controleVenda/manutencao/gridGrupoProdutoAction"
import * as manutencaoVendaActions from "../../../store/actions/controleVenda/manutencao/manutencaoVendaAction"
import {
  MANUTENCAO_CONTENT_ITENS
  , MANUTENCAO_CONTENT_PAGAMENTO
  , MANUTENCAO_CONTENT_PRODUTO
} from "../../../store/actions/controleVenda/manutencao/manutencaoVendaAction"
import * as pagamentoVendasActions from "../../../store/actions/controleVenda/pagamentoVendasAction"
import { updatePlaceholderMessageAction, setLayoutIdAction } from "../../../store/actions/placeholderMessageAction"

import GridGrupoProduto from "./GridGrupoProduto"
import TelaExibeItensVenda from "./TelaExibeItensVenda.js"
import TelaPagamentoVendas from "../pagamento/TelaPagamentoVendas"
import BarraAcoesManutencaoVenda from "../barraAcao/BarraAcoesManutencaoVenda.js"
import HelpParagraph from "../../UI/HelpParagraph/HelpParagraph"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"
import ShowClosedStoreMessage from "../../Telas/Store/ShowClosedStoreMessage"
import ShowWelcomeMessage from "../../Telas/Store/ShowWelcomeMessage.js"

/**
 * Classe da tela que abriga as telas de manutenção da venda:
 * * Tela de produtos separados por grupo
 * * Tela de itens de venda
 * * Tela de pagamento
 */
class ManutencaoVenda extends React.Component {
  state = {
    showWelcomeMessage: true,
  }

  /**
   * Retorna a tela a ser exibida de acordo com a variável armazenada no `reducer`.
   */
  getManutencaoContent = () => {
    const estado = this.props.buscarEstado
      ? this.props.buscarEstado()
      : {};

    log('ManutencaoVenda getManutencaoContent');
    switch (this.props.manutencaoContent) {
      case MANUTENCAO_CONTENT_ITENS:
        return <TelaExibeItensVenda />;
      case MANUTENCAO_CONTENT_PAGAMENTO:
        return <TelaPagamentoVendas esconderBarraAcoes={this.props.esconderBarraAcoes} />;
      case MANUTENCAO_CONTENT_PRODUTO:
        if ([NIVEL_USUARIO_SEM_EMPRESA_MENU, NIVEL_USUARIO_SEM_EMPRESA_VENDA].includes(this.props.nivelUsuarioSemEmpresa)) {
          return <GridGrupoProduto tipoOrigem={estado.tipoOrigem} buscarEstado={this.props.buscarEstado} atualizarEstado={this.props.atualizarEstado} />;
        } else {
          return <h2>{getStrings().companyMenuDisabled}</h2>;
        }
      default:
        return null;
    }
  }

  /**
   * Método executado ao clicar no botão de envio da venda.
   */
  confirmaEnviaVendaServidor = () => {

    log('ManutencaoVenda confirmaEnviaVendaServidor');

    if (!this.props.hasNewSaleItemToSend) {
      this.props.appNotificationShow(getStrings().noNewItemsSelected,
        actionTypes.APP_NOTIFICATION_TYPE_INFO, getStrings().noItems, null);
      return;
    }

    // Se a empresa for do ramo Painel ou restaurante a kilo, não deve exibir confirmação para enviar itens.
    if (isEmpresaPainel(this.props.ramoEmpresa) || isEmpresaRestauranteAKilo(this.props.ramoEmpresa)) {
      this.preparaDadosEnvioVendaServidor();
    }
    // Senão, pede confirmação antes de enviar itens.
    else {
      this.props.appNotificationShow(getStrings().sendNewItems,
        actionTypes.APP_NOTIFICATION_TYPE_INFO_QUESTION, getStrings().sendItems, () => this.preparaDadosEnvioVendaServidor());
    }
  }

  /**
   * Método que prepara os dados para o envio ao servidor
   */
  preparaDadosEnvioVendaServidor = () => {
    const { venda, origemVenda, newSaleItemList } = this.props;

    log('ManutencaoVenda preparaDadosEnvioVendaServidor', { venda, newSaleItemList });

    // Se for usuário não logado, armazena a venda no local storage e solicita criação de cadastro.
    if (!this.props.isAuthenticated) {
      this.props.storeSale();
      return;
    }

    let vendaDados = {
      empresa: origemVenda.empresa._links.self.href,
      nome: venda.nome,
      origemVenda: origemVenda._links.self.href,
      numeroPessoas: venda.numeroPessoas,
      ...(this.props.isCargo ? {} : { usuarioCliente: getAppUsuarioURI() })
    };

    let todosItens = [];

    if (this.props.state !== STATE_CONTROLE_VENDA_NOVA_VENDA) {
      for (let x = 0; x < (venda.itemVendaList || []).length; x++) {
        todosItens[todosItens.length] = venda.itemVendaList[x]._links ? getURIFromEntity(venda.itemVendaList[x]) : { id: venda.itemVendaList[x].registroId };
      }
    }

    newSaleItemList
      .filter(newSaleItem => newSaleItem.itemVenda.quantidade)
      .forEach(newSaleItem => {
        // Se for um produto com produção unitária, que não é com quantidade pesada e que tenha quantidade maior que um
        // (este último somente para otimização)
        if (isProdutoProducaoUnitaria(newSaleItem.itemVenda.produto) && (!newSaleItem.itemVenda.produto.quantidadePesada) && (newSaleItem.itemVenda.quantidade > 1)) {
          // Espalha a quantidade no mesmo número de itens de venda
          forEach(newSaleItem.itemVenda.quantidade, () => {
            return todosItens.push({
              ...newSaleItem.itemVenda,
              quantidade: 1,
              valorTotal: newSaleItem.itemVenda.precoUnitario,
              estado: newSaleItem.itemVenda.produto.quantidadePesada && newSaleItem.itemVenda.produto.estadoProntoCasoQuantidadePesada ? 'PRONTO' : newSaleItem.itemVenda.estado,
            })
          });
        }
        // Senão
        else {
          // Adiciona o item normalmente
          todosItens.push({ ...newSaleItem.itemVenda, estado: newSaleItem.itemVenda.produto.quantidadePesada && newSaleItem.itemVenda.produto.estadoProntoCasoQuantidadePesada ? 'PRONTO' : newSaleItem.itemVenda.estado });
        }
      });

    vendaDados.itemVendaList = todosItens;

    const buscarEstado = this.props.buscarEstado || (() => ({}));
    const estado = buscarEstado();
    const cadastroComanda = estado.cadastroComanda;
    const idPreCadastroUsuario = cadastroComanda?.preCadastroUsuario?.id;
    const idEntregaVenda = cadastroComanda?.entregaVenda?.id;
    const atualizarEstado = this.props.atualizarEstado || (() => { });

    const novoEstadoPedido = {
      cadastroComanda: {
        ...cadastroComanda,
        preCadastroUsuario: {
          telefone: this.props.estadoInicialEstadoPedidos?.cadastroComanda?.telefone || '519',
          nome: this.props.estadoInicialEstadoPedidos?.cadastroComanda?.nome || '',
          endereco: this.props.estadoInicialEstadoPedidos?.cadastroComanda?.endereco || {
            bairro: '',
            cep: '',
            cidade: '',
            complemento: '',
            endereco: '',
            numero: '',
            pais: null,
            uf: '',
            pontoReferencia: '',
          }
        },
        entregaVenda: {
          idEntregaVenda: null,
          observacao: '',
          teleEntrega: true,
        }
      },
      tipoOrigem: '',
      valorItensSelecionados: 0,
      editandoPedido: false
    }

    // Se venda é persistida, adiciona os novos itens de venda
    if (this.props.state !== STATE_CONTROLE_VENDA_NOVA_VENDA) {

      this.props.addItensVenda(
        venda?._links?.self?.href,
        newSaleItemList
          .filter(newSaleItem => newSaleItem.itemVenda.quantidade)
          .reduce((list, newSaleItem) => {
            // Se for um produto com produção unitária, que não é com quantidade pesada e que tenha quantidade maior que um
            // (este último somente para otimização)
            if (isProdutoProducaoUnitaria(newSaleItem.itemVenda.produto) && (!newSaleItem.itemVenda.produto.quantidadePesada) && (newSaleItem.itemVenda.quantidade > 1)) {
              // Espalha a quantidade no mesmo número de itens de venda
              return list.concat(newArray(newSaleItem.itemVenda.quantidade).map(() => Object.assign({}, newSaleItem.itemVenda, {
                quantidade: 1,
                valorTotal: newSaleItem.itemVenda.precoUnitario,
                produto: getURIFromEntity(newSaleItem.itemVenda.produto)
              })));
            }
            // Senão
            else {
              // Adiciona o item normalmente
              return list.concat(Object.assign({}, newSaleItem.itemVenda, { produto: getURIFromEntity(newSaleItem.itemVenda.produto) }));
            }
          }, [])
          .map(itemVenda => {
            let itemProducaoList = [];
            for (let i = 0; i < itemVenda.quantidade; i++) {
              itemProducaoList.push({ estado: itemVenda.estado })
            }

            itemVenda.itemProducaoList = itemProducaoList;
            itemVenda.venda = itemVenda.venda
              ? itemVenda.venda
              : venda?._links?.self?.href;

            return itemVenda;
          }),
        this.vendaEnviadaSuccess
      );

      atualizarEstado(novoEstadoPedido);
    }
    // Se venda é nova, persiste a venda junto com os itens de venda
    else {
      const todosEstadoProntos = !vendaDados.itemVendaList.some((itemVenda) => !(itemVenda.produto.tipoProdutoList || [])[0]?.estadoProntoProducao);
      vendaDados.itemVendaList.forEach(itemVenda => {
        let itemProducaoList = [];
        for (let i = 0; i < itemVenda.quantidade; i++) {
          itemVenda.estado = todosEstadoProntos
            ? ESTADO_ITEM_VENDA_PRODUZIDO
            : itemVenda.estado;
          itemProducaoList.push({ estado:  (itemVenda.produto.tipoProdutoList || [])[0]?.estadoProntoProducao ? ESTADO_ITEM_VENDA_PRODUZIDO : itemVenda.estado });
        }
        itemVenda.itemProducaoList = itemProducaoList;
      });

      vendaDados.estadoVenda = todosEstadoProntos
        ? ESTADO_VENDA_AGUARDANDO_ENCERRAMENTO
        : vendaDados.estadoVenda;

      vendaDados.estadoItensVenda = todosEstadoProntos
        ? ESTADO_ITEM_VENDA_PRODUZIDO
        : vendaDados.estadoItensVenda;

      atualizarEstado(novoEstadoPedido);

      const { getApi } = ClienteHttp;
      const venda = { ...vendaDados, entregaVenda: !nullOrUndefined(idEntregaVenda) ? getApi(`entregaVendas/${idEntregaVenda}`) : null, clientePreCadastrado: !nullOrUndefined(idPreCadastroUsuario) ? getApi(`preCadastroUsuarios/${idPreCadastroUsuario}`) : null };

      this.props.saveData(venda, this.vendaEnviadaSuccess);
    }
  }

  /**
   * Método executado quando a venda é enviada corretamente ao servidor.
   */
  vendaEnviadaSuccess = vendaPersisted => {

    log('ManutencaoVenda vendaEnviadaSuccess', vendaPersisted);

    // Exibe notificação
    this.props.appSnackbarMessage(getStrings().itemsSent, () => this.props.undoSend());

    // Limpa os itens novos
    this.limpaNovosItensVenda(false);

    if (vendaPersisted) {
      // Atualiza os itens da venda na venda recebida por propriedade
      this.props.venda.itemVendaList = vendaPersisted.itemVendaList;
    }

    // if (this.props.pedido) {
    //     // this.props.dispatch(appActions.appSpinnerShow('vendaLista'));

    //     const pageSize = getPageSize(6);

    //     ClienteHttp.requisicaoServidor('vendas/findToOrders', 'get', true, {params:{page: 0, size: pageSize}})
    //         .then((resposta) => {
    //             const vendaList = resposta.data?._embedded?.resources || [];

    //             this.props.atualizarEstado({vendaList, paginacao: {links: resposta.data._links, page: resposta.data.page}});
    //         })
    //         .finally(() => {
    //             this.props.atualizarEstado({setAllDisabled: false});
    //             // this.props.dispatch(appActions.appSpinnerHide('vendaLista'));
    //         });
    // }
  }

  // Método que limpa as variáveis que armazenam os itens novos da venda
  limpaNovosItensVenda = isEncerramentoVenda => {

    log('ManutencaoVenda limpaNovosItensVenda', isEncerramentoVenda);

    // Limpa a lista de itens
    this.props.clearNewSaleItemList();

    // Se está sendo realizado o encerramento da venda, chama novamente o método de validação do encerramento
    if (isEncerramentoVenda !== undefined && isEncerramentoVenda !== null && isEncerramentoVenda) {
      this.confirmaEncerramentoVenda(true, true);
    }
  }

  /**
   * Método executado ao clicar no botão para realizar o encerramento da venda.
   */
  confirmaEncerramentoVenda = (isIgnoraConfirmacaoEncerramento, isIgnoraItensPendente, isIgnorarItensNaoPagos) => {

    log('ManutencaoVenda confirmaEncerramentoVenda', isIgnoraConfirmacaoEncerramento, isIgnoraItensPendente, isIgnorarItensNaoPagos);

    // Mensagem caso seja uma nova venda
    if (this.props.state === STATE_CONTROLE_VENDA_NOVA_VENDA) {
      this.props.appNotificationShow(getStrings().noClosingPendingSale(),
        actionTypes.APP_NOTIFICATION_TYPE_INFO, getStrings().pendingSale(), null);
      return;
    }

    // Mensagem caso existam itens novos pendentes.
    if (this.props.hasNewSaleItemToSend && (isIgnoraItensPendente === undefined || isIgnoraItensPendente === null || !isIgnoraItensPendente)) {
      this.props.appNotificationShow(getStrings().closeSaleCancelPending(),
        actionTypes.APP_NOTIFICATION_TYPE_WARNING_QUESTION, getStrings().pendingItems, () => { this.limpaNovosItensVenda(true) });
      return;
    }

    // Mensagem caso exista valor de pagamento pendente.
    if (this.props.hasRemainingAmount && (isIgnorarItensNaoPagos === undefined || isIgnorarItensNaoPagos === null || !isIgnorarItensNaoPagos)) {
      this.props.appNotificationShow(getStrings().closeSaleIgnorePending(),
        actionTypes.APP_NOTIFICATION_TYPE_WARNING_QUESTION, getStrings().pendingPayment, () => { this.confirmaEncerramentoVenda(true, true, true) });
      return;
    }

    // Mensagem para confirmação de encerramento
    if (isIgnoraConfirmacaoEncerramento === undefined || isIgnoraConfirmacaoEncerramento === null || !isIgnoraConfirmacaoEncerramento) {
      this.props.appNotificationShow(getStrings().closeSale(),
        actionTypes.APP_NOTIFICATION_TYPE_INFO_QUESTION, getStrings().saleClosure, () => { this.props.closeVenda() });
      return;
    }

    this.props.closeVenda();
  }

  /**
   * Retorna se deve chamar a atenção para o botão de encerrar venda.
   * Isso deve ser feito quando a venda foi totalmente paga.
   */
  shouldAttractToClose = () => {

    log('ManutencaoVenda shouldAttractToClose');

    // Deve chamar a atenção para o botão de encerrar venda se houver venda, se ela conter itens de venda, e se todos os itens tiverem sido pagos.
    if (((((this.props.venda || {}).itemVendaList || []).length || 0) > 0) && (!this.props.hasRemainingAmount)) {
      return true;
    }
    return false;
  }

  /**
   * Método executado APÓS de "MONTAR" o componente.
   */
  componentDidMount() {
    log('ManutencaoVenda componentDidMount');

    const origemVenda = this.props.origemVenda;

    // quadro de horários do banco
    const parametrosEmpresa = origemVenda?.empresa?.parametrosEmpresa || {};
    const mensagemDeTelaList = (parametrosEmpresa.mensagemDeTelaList || []);

    populateFromDB((mensagemDeTelaList || []).map(obj =>
    ({
      id: obj.nome,
      data: convertArrayToObject((obj.valor || []).map(obj => ({
        id: obj.nome,
        data: JSON.parse(obj.valor || '{}'),
      }))),
    })
    ), this.props.updatePlaceholderMessage);

    this.props.setLayoutId(parametrosEmpresa.mensagemCustomizadaId);

    this.props.setValue(true, 'visible');

    // Se estiver abrindo uma venda a partir do cancelamento de um ou mais de seus pagamentos
    if (this.props.fromCallState === STATE_CONTROLE_VENDA_GET_SALE_FROM_CANCEL) {
      // Volta para a tela de pagamentos
      this.props.exibeTelaPagamento();
    }
    // Em todos os outros casos
    else {
      // Inicia na tela de produtos
      this.props.exibeProdutos();
    }

    if (!this.props.naoCarregarProdutos) {
      // Carrega os produtos com os seus grupos do servidor
      this.props.loadGrupoProdutoListWithProdutosVinculados();
    }

    // Exibe aviso caso seja uma venda nova e caso não seja um usuário sem vínculo com empresa somente visualizando informações da empresa.
    if ((this.props.state === STATE_CONTROLE_VENDA_NOVA_VENDA) && (this.props.nivelUsuarioSemEmpresa === NIVEL_USUARIO_SEM_EMPRESA_VENDA) && (this.props.store || {}).storeStatus === 'open') {

      // Exibe a notificação indicando o início de uma nova venda
      this.props.appSnackbarMessage(getStrings().beginningSale());
      // actionTypes.APP_NOTIFICATION_TYPE_INFO, getStrings().newSale, null);
    }

    // Se venda foi aberta por um usuário sem vínculo com empresa,
    // busca o nome da empresa a partir da origem para exibir na tela.
    if (!this.props.isCargo) {
      this.props.updateNomeEmpresa(origemVenda?.empresa?.nomeFantasia || '');
    }

    if (this.state.showWelcomeMessage) {
      setTimeout(() => {
        this.setState({ showWelcomeMessage: false })
      }, 3000)
    }
  }

  componentDidUpdate(prevProps) {
    log('ManutencaoVenda componentDidUpdate', { prevProps });

    if ((!prevProps.runUpdateCommissionDiscount) && this.props.runUpdateCommissionDiscount) {
      this.props.updateCommissionDiscount();
    }
  }

  /**
   * Método executado ANTES de "DESMONTAR" o componente.
   * Marca o componente como NÃO montado.
   */
  componentWillUnmount() {
    log('ManutencaoVenda componentWillUnmount');

    this.props.setValue(false, 'visible');

    // Se venda foi aberta por um usuário sem vínculo com empresa,
    // limpa parâmetros da empresa.
    if (!this.props.isCargo) {
      this.props.updateNomeEmpresa('');
      this.props.updatePerguntaCpf(false);
      this.props.updatePrecoLivre(0);
    }

    this.props.dispatch(limparEntradaBusca());
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    if (this.props.noCompany && (this.props.store || {}).storeStatus !== 'open') {
      if (![messageLayoutIds.closedStore, messageLayoutIds.forceClosedStore].includes(this.props.telaMensagens.layoutId)) {
        this.props.setLayoutId(messageLayoutIds.closedStore);
      }

      return <ShowClosedStoreMessage />;
    }

    if (this.props.noCompany && this.state.showWelcomeMessage) {
      return <ShowWelcomeMessage />
    }

    log('ManutencaoVenda render');

    const { estadoInicialEstadoPedidos } = this.props;

    // Demora mais tempo que os outros porque está muito dependente desta tela
    return <>

      <WidthAwareDiv>
        <HelpParagraph divClass='saleHelp' children={
          (this.props.manutencaoContent === MANUTENCAO_CONTENT_PRODUTO
            ? getStrings().saleProductHelp(this.props.state === STATE_CONTROLE_VENDA_NOVA_VENDA)
            : this.props.manutencaoContent === MANUTENCAO_CONTENT_ITENS
              ? getStrings().saleItemHelp()
              : getStrings().salePaymentHelp(this.props.ramoEmpresa, true))
            .map(string => formatI18nString(string))} />
      </WidthAwareDiv>

      <BarraAcoesManutencaoVenda
        pedido={this.props.pedido}
        atualizarEstado={this.props.atualizarEstado}
        buscarEstado={this.props.buscarEstado}
        customGoBack={this.props.customGoBack}
        esconderBotaoVoltar={this.props.esconderBotaoVoltar}
        estadoInicialEstadoPedidos={estadoInicialEstadoPedidos}
        handleEncerramento={event => this.confirmaEncerramentoVenda()}
        handleSend={this.confirmaEnviaVendaServidor}
        handlePay={this.props.paySales}
        disableItems={this.props.manutencaoContent === MANUTENCAO_CONTENT_ITENS}
        disablePay={(this.props.manutencaoContent === MANUTENCAO_CONTENT_PAGAMENTO) || (this.props.state === STATE_CONTROLE_VENDA_NOVA_VENDA)}
        attractToClose={this.shouldAttractToClose()}
        showPrimarySend={(this.props.manutencaoContent === MANUTENCAO_CONTENT_PRODUTO) || (this.props.manutencaoContent === MANUTENCAO_CONTENT_ITENS)}
        showPrimaryPay={(this.props.manutencaoContent === MANUTENCAO_CONTENT_PAGAMENTO) && this.props.hasCheckedProduct}
        showPrimaryClose={(this.props.manutencaoContent === MANUTENCAO_CONTENT_PAGAMENTO) && (!this.props.hasCheckedProduct)}
        vendaURI={getURIFromEntity(this.props.venda)}
      />
      {this.getManutencaoContent()}
    </>;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  entradaBuscaReducer: state.entradaBuscaReducer,
  telaMensagens: state.placeholderMessageReducer,
  isAuthenticated: !!state.authReducer.token,
  isCargo: !!state.empresaSelectorReducer.cargo,
  state: state.controleVendaReducer.state,
  fromCallState: state.controleVendaReducer.fromCallState,
  nivelUsuarioSemEmpresa: state.controleVendaReducer.nivelUsuarioSemEmpresa,
  origemVenda: state.controleVendaReducer.origemVendaList[0],
  runUpdateCommissionDiscount: state.controleVendaReducer.runUpdateCommissionDiscount,
  vendaAmount: state.controleVendaReducer.vendaAmount,
  venda: ((state.controleVendaReducer.origemVendaList || [])[0]?.vendaList || [])[0] || { nome: 'pedido', numeroPessoas: 1, },

  manutencaoContent: state.manutencaoVendaReducer.manutencaoContent,
  newSaleItemList: state.controleVendaReducer.newSaleItemList,

  hasNewSaleItemToSend: state.controleVendaReducer.hasNewSaleItemToSend,

  ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa,
  hasCheckedProduct: state.controleVendaReducer.hasCheckedProduct,
  hasRemainingAmount: state.controleVendaReducer.hasRemainingAmount,

  ...state.idiomaReducer
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  dispatch,
  goTo: (history, pathName) => dispatch(push(history, pathName)),

  storeSale: () => dispatch(manutencaoVendaActions.storeSale()),
  saveData: (dadosEnvio, successMethod) => dispatch(manutencaoVendaActions.saveData(dadosEnvio, successMethod)),
  addItensVenda: (updateUrl, itemVendaList, successMethod) => dispatch(manutencaoVendaActions.addItensVenda(updateUrl, itemVendaList, successMethod)),
  undoSend: () => dispatch(manutencaoVendaActions.undoSend()),
  closeVenda: () => dispatch(manutencaoVendaActions.closeVenda()),

  exibeProdutos: () => dispatch(manutencaoVendaActions.exibeProdutos()),
  exibeTelaPagamento: () => dispatch(manutencaoVendaActions.exibeTelaPagamento()),
  setValue: (value, position) => dispatch(manutencaoVendaActions.setValue(value, position)),

  clearNewSaleItemList: () => dispatch(manutencaoVendaActions.clearNewSaleItemList()),
  updateCommissionDiscount: () => dispatch(controleVendaActions.updateCommissionDiscount()),

  appNotificationShow: (notificationMessage, notificationType, notificationTitle, notificationAction) =>
    dispatch(appActions.appNotificationShow(notificationMessage, notificationType, notificationTitle, notificationAction)),
  appSnackbarMessage: (message, undoAction) => dispatch(appActions.appSnackbarMessage(message, undoAction)),
  updateNomeEmpresa: nomeEmpresa => dispatch(empresaSelectorActions.updateNomeEmpresa(nomeEmpresa)),
  updatePerguntaCpf: perguntaCpf => dispatch(empresaSelectorActions.updatePerguntaCpf(perguntaCpf)),
  updatePrecoLivre: precoLivre => dispatch(empresaSelectorActions.updatePrecoLivre(precoLivre)),

  loadGrupoProdutoListWithProdutosVinculados: () => dispatch(gridGrupoProdutoActions.loadGrupoProdutoListWithProdutosVinculados()),

  paySales: () => dispatch(pagamentoVendasActions.paySales()),

  updatePlaceholderMessage: ({ id, data }) => dispatch(updatePlaceholderMessageAction({ id, data })),

  setLayoutId: (id) => dispatch(setLayoutIdAction(id)),
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(ManutencaoVenda);
