import React from 'react';
import criarReducer, { acaoTipo } from "../factory/criarReducer";
import tiposDeAcoes from "./dialogActions";

export type dialogReducerTipo = {
  dialog: React.ReactNode
  , mostrar: boolean
}

const estadoInicial: dialogReducerTipo = {
  dialog: (props: any) => null
  , mostrar: false
};

const acoesNomeadas: {[key:string]: (carga: any, estadoAtual?: dialogReducerTipo) => dialogReducerTipo} = {
  [tiposDeAcoes.MOSTRAR_DIALOG]: ({conteudo}: {conteudo: React.ReactNode}, estadoAtual?: dialogReducerTipo) => {
    const dialog = conteudo;

    const novoEstado = {
      ...estadoAtual
      , dialog
      , mostrar: true
    }

    return novoEstado;
  }
  , [tiposDeAcoes.FECHAR_DIALOG]: () => {
    const novoEstado = {
      ...estadoInicial
    }

    return novoEstado;
  }
};

export default function carrinhoReducer(estado: dialogReducerTipo = estadoInicial, acao: acaoTipo): any {
  return criarReducer(estado, acao, acoesNomeadas);
}
