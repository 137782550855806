import { LocalidadeUtils } from "../../utils/utils";
import criarReducer, { acaoTipo } from "../factory/criarReducer";
import tiposDeAcoes from "./infoActions";

export type infoReducerTipo = {
  info: string,
  shortInfo: string,
  soldOut: boolean,
};

const estadoInicial: infoReducerTipo = {
  info: '',
  shortInfo: '',
  soldOut: false,
};

const acoesNomeadas: {[x: string]: (carga: any, estado?: infoReducerTipo) => infoReducerTipo} = {
  [tiposDeAcoes.ATUALIZAR_INFO]: ({info, shortInfo, soldOut}: {info: string, shortInfo: string, soldOut: boolean}, estadoAtual = estadoInicial) => {
    const soldOut_ = soldOut !== undefined
      ? soldOut
      : estadoAtual.soldOut;
    const info_ = info 
      + (soldOut
        ? ` - ${LocalidadeUtils.obterTraducao().phrases.maximumAmountReached}`
        : ''
      );
    const novoEstado: infoReducerTipo = {
      ...estadoAtual,
      info: info_,
      shortInfo: shortInfo || '',
      soldOut: soldOut_,
    };

    return novoEstado;
  },
};

const infoReducer = (estado = estadoInicial, acao: acaoTipo) => criarReducer<infoReducerTipo>(estado, acao, acoesNomeadas);

export default infoReducer;
