import React from "react"
import { connect } from "react-redux"

import * as AuthUtils from "../../../utils/AuthUtils"
import { getStrings } from "../../../utils/LocaleUtils"

import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"
import * as pagamentoVendasActions from "../../../store/actions/controleVenda/pagamentoVendasAction"

import BuildButton from "../../UI/Toolbar/BarraAcoesButton"
import Icones from "../../../assets/icones/Icones"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

/**
 * Classe que realiza a montagem da barra de ações da tela de vendas de uma origem.
 */
class BarraAcoesVenda extends React.Component {

  render() {
    const MdAttachMoney = Icones.md.MdAttachMoney
    const MdPlaylistAddCheck = Icones.md.MdPlaylistAddCheck
    const MdPrint = Icones.md.MdPrint
    const MdQueue = Icones.md.MdQueue

    return <WidthAwareDiv id="toolbar" className="toolbar">
      <div className="toolbar-content">
        <div className="bar-container" >
          {/* Botão Voltar */}
          <BuildButton id="back-button" key="back" isPrimary={true} commandMethod={this.props.exibeGridOrigemVendaEmUso} isDisabled={false} label={"\u003c"} title={getStrings().back}
            buttonGridClassName="bar-item" />

          {/* Botão Imprimir */}
          <BuildButton id="print-button" key="print" isPrimary={false} commandMethod={this.props.showPrintVendaDialog}
            label={<div style={{ whiteSpace: "nowrap" }} ><MdPrint /></div>} title={getStrings().printSales} buttonGridClassName="bar-item" />

          {/* Botão Encerrar */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_PAGAMENTO]) ?
            <BuildButton id="end-button" key="stop" isPrimary={false} commandMethod={() => this.props.confirmaEncerramentoVenda(this.props.vendaList)}
              label={<MdPlaylistAddCheck />} title={getStrings().closeAllSales} buttonGridClassName="bar-item" /> : ""}

          {/* Botão Pagamento */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_PAGAMENTO]) ?
            <BuildButton id="pay-button" key="pay" isPrimary={false} commandMethod={() => this.props.exibeTelaPagamentoVendas([this.props.origemVenda], false)}
              label={<MdAttachMoney />} title={getStrings().paySales} buttonGridClassName="bar-item" /> : ""}

          {/* Botão Novo */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_ENVIO]) ?
            <BuildButton id="add-button" key="add" isPrimary={true} commandMethod={() => { this.props.setupNewVenda(this.props.origemVenda) }}
              label={<MdQueue />} title={getStrings().newSale} buttonGridClassName="bar-item" /> : ""}
        </div>
      </div>
    </WidthAwareDiv>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  ...state.idiomaReducer,
  origemVenda: state.controleVendaReducer.origemVendaList[0],
  vendaList: state.controleVendaReducer.origemVendaList[0].vendaList || []
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  confirmaEncerramentoVenda: (vendaList, isIgnoraConfirmacaoEncerramento, isIgnorarItensNaoPagos) =>
    dispatch(pagamentoVendasActions.confirmaEncerramentoVenda(vendaList, isIgnoraConfirmacaoEncerramento, isIgnorarItensNaoPagos)),
  exibeGridOrigemVendaEmUso: () => dispatch(controleVendaActions.exibeGridOrigemVendaEmUso()),
  exibeTelaPagamentoVendas: origensVendaPagar =>
    dispatch(pagamentoVendasActions.exibeTelaPagamentoVendas(origensVendaPagar)),
  setupNewVenda: origemVenda => dispatch(controleVendaActions.setupNewVenda(origemVenda)),
  showPrintVendaDialog: () => dispatch(controleVendaActions.showPrintVendaDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(BarraAcoesVenda)
