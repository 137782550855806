import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"

import * as appActions from "../../../store/actions/appAction"

import CheckBoxLabelHorizontal from "../Input/CheckBoxLabelHorizontal"
import Icones from "../../../apps/promo_app_frontend/assets/icones/Icones"


type propsPadrao = {
  id?: string,
  tabIndex: number,
  helpState: boolean,
  setHelp: (event: any) => void | undefined,
}

const HelpIconEnabled = Icones.md.MdSpeakerNotes;
const HelpIconDisabled = Icones.md.MdSpeakerNotesOff;

/**
 * Componente para exibir um botão para alternar a visibilidade dos textos de ajuda.
 */
const HelpToggle = (props: propsPadrao) => {
  let helpToggle: any;

  return <button tabIndex={props.tabIndex} className={'clickable helpToggle'} onClick={() => { props.setHelp(!helpToggle.checkBox.checked); }}>
    <CheckBoxLabelHorizontal
      id='helpButton'
      type='checkbox'
      label={props.helpState ? <HelpIconEnabled /> : <HelpIconDisabled />}
      ref={ref => {
        if (ref) {
          helpToggle = getReduxWrappedComponent(ref);
        }
      }}
      inputProps={{
        checked: props.helpState,
        id: 'helpToggle',
        name: 'helpToggle',
        onChange: () => { },
        title: getStrings().help
      }}

    />
  </button>;
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = (state: any) => ({
  tabIndex: state.appReducer.getTabIndex(),
  helpState: state.appReducer.help,
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    setHelp: (state: boolean) => dispatch(appActions.setHelp(state))
  };
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(HelpToggle);
