import appReducer from './reducers/appReducer';
import authReducer from './reducers/authReducer';
import cadastroReducer from './reducers/cadastroReducer';
import cadastroEmpresaReducer from './reducers/cadastroEmpresaReducer';
import cadastroPrintReducer from './reducers/cadastroPrintReducer';
import cadastroPrintTextToSVGReducer from './reducers/cadastroPrintTextToSVGReducer';
import cadastroSetupReducer from './reducers/cadastroSetupReducer';
import codeReaderReducer from './reducers/codeReaderReducer';
import comboReducer from './reducers/comboReducer';
import companyInformationReducer from './reducers/companyInformationReducer';
import controleVendaReducer from './reducers/controleVenda/controleVendaReducer';
import collectorReducer from './reducers/collectorReducer';
import deliveryReducer from './reducers/deliveryReducer';
import entradaBuscaReducer from './reducers/entradas/entradaBusca/entradaBuscaReducer';
import empresaSelectorReducer from './reducers/empresaSelectorReducer';
import fbAppDataReducer from './reducers/f3/fbAppDataReducer';
import fbUsersDataReducer from './reducers/f3/fbUsersDataReducer';
import holidaysReducer from './reducers/holidaysReducer';
import idiomaReducer from './reducers/idiomaReducer';
import inputsReducer from './reducers/inputsReducer';
import manutencaoVendaReducer from './reducers/controleVenda/manutencao/manutencaoVendaReducer';
import pagamentoVendasReducer from './reducers/controleVenda/pagamentoVendasReducer';
import placeholderMessageReducer from './reducers/placeholderMessageReducer';
import producaoReducer from './reducers/producaoReducer';
import productImagesReducer from './reducers/productImagesReducer';
import relatorioReducer from './reducers/relatorioReducer';
import selectFieldsReducer from './reducers/selectFieldsReducer';
import signUpReducer from './reducers/signUpReducer';
import simpleTapSelectionReducer from './reducers/simpleTapSelectionReducer';
import tabIndexReducer from './reducers/tabIndexReducer';
import usuarioReducer from './reducers/usuarioReducer';
import menuReducer from '../components/Telas/LandingPage/reducers/menuReducer/menuReducer';
import modalReducer from '../components/Telas/LandingPage/reducers/modalReducer/modalReducer';
import webAppReducers from '../apps/promo_app_frontend/nucleo/redux/webAppReducers';

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { } from 'react-redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

/**
 * [Redux] Necessário para utilizar a extensão do Redux DevTools no Chrome. 
 * Maiores informações no link: https://github.com/zalmoxisus/redux-devtools-extension
 */
/* eslint-disable no-undef */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

/**
 * [Redux] Constante que armazena os Reducers da aplicação, necessários no Store. 
 */
const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  // 'PROPRIEDADE: PROPRIEDADE' é a mesma coisa que só 'PROPRIEDADE' 
  appReducer,
  authReducer,
  cadastroReducer,
  cadastroEmpresaReducer,
  cadastroPrintReducer,
  cadastroPrintTextToSVGReducer,
  cadastroSetupReducer,
  codeReaderReducer,
  collectorReducer,
  comboReducer,
  companyInformationReducer,
  controleVendaReducer,
  deliveryReducer,
  empresaSelectorReducer,
  entradaBuscaReducer,
  fbAppDataReducer,
  fbUsersDataReducer,
  holidaysReducer,
  idiomaReducer,
  inputsReducer,
  manutencaoVendaReducer,
  pagamentoVendasReducer,
  placeholderMessageReducer,
  producaoReducer,
  productImagesReducer,
  relatorioReducer,
  selectFieldsReducer,
  signUpReducer,
  simpleTapSelectionReducer,
  tabIndexReducer,
  usuarioReducer,
  menuReducer,
  modalReducer,
  ...webAppReducers,
  /**
   * Caso necessário, mais Reducers podem ser adicionados aqui, utilizando vírgula. 
   */
});

export const history = createBrowserHistory()

/**
 * [Redux] Central Store, responsável por armazenar o estado todo da aplicação.
 * Criado com o rootReducer (que contém os Reducers da aplicação) e com Enhancers (necessário para utilização de Middleware). 
 */
const store = createStore(
  createRootReducer(history), // root reducer with router state
  composeEnhancers(
    /*
     * [INFO] Middleware é código ou função que é acoplado em um processo e executado como parte dele, sem pará-lo. 
     * Com isso, é possível fazer algo com a Action antes que ela retorne ao reducer (como por exemplo alguma ação de log ou executar um código assíncronamente).
     */

    /*
     * Adiciona o Middleware "Redux Thunk" aos Enhancers. 
     * O Middleware "Redux Thunk" permite que você escreva Action Creators que retornam uma Função ao invés de uma Action. 
     * O thunk pode ser usado para atrasar o envio de uma Ação, ou para despachar somente se uma determinada condição for atendida. 
     */
    applyMiddleware(
      routerMiddleware(history), // for dispatching history actions
      thunk
    )
  )
);

export default store