import styled from "styled-components";

const ImgRounded = styled.img.attrs((props) => ({
  width: props.width || "170px",
  height: props.height || "170px",
}))`
  position: absolute;
  outline: 4px solid black;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 999px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  @media (max-width: ${(props) => props.theme.sizes.breakPointSmall}) {
    width: 130px;
    height: 130px;
  }
`;

export default ImgRounded;
