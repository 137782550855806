import React from 'react';
import { connect } from 'react-redux';
import { FormaPagamentoTableRow } from './FormaPagamentoTable';

import { getStrings } from '../../../utils/LocaleUtils';

/**
 * Classe que realiza a montagem da tabela das Formas de Pagamento.
 */
class FormaPagamentoTableModel extends React.Component {

    /**
     * Método que executa a montagem do componente.
     */
    render() {

        // Monta as linhas que irão compor a tabela
        var cadastroList = this.props.cadastroList.map(cadastro =>
            <FormaPagamentoTableRow
                key={cadastro._links.self.href}
                cadastro={cadastro}
                onUpdate={this.props.onUpdate}
                onDelete={this.props.onDelete} />
        );

        // Retona a tabela montada 
        return <table className='pure-table' width='100%' >

            {/* Cabeçalho */}
            <thead>
                <tr>
                    <th className='Bold CenterAlign'>{getStrings().options}</th>
                    <th className='Bold ButtonAlign'>{getStrings().active}</th>
                    <th className='Bold TextAlign'>{getStrings().code}</th>
                    <th className='Bold TextAlign'>{getStrings().name}</th>
                    <th className='Bold ButtonAlign'>{getStrings().fiscal}</th>
                </tr>
            </thead>

            {/* Linhas de Dados */}
            <tbody>
                {cadastroList}
            </tbody>
        </table>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    ...state.idiomaReducer
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(FormaPagamentoTableModel);
