import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { getStrings } from "../../utils/LocaleUtils"

import * as appActions from '../../store/actions/appAction';
import * as controleVendaActions from '../../store/actions/controleVenda/controleVendaAction';

let moment = require('moment');

class BuildCellFromCalledSaleSource extends React.Component {

    render() {

        let momentInstance = moment(this.props.notificacao.dataHora);

        return <div className='card called-sale-source' onClick={() => {
            this.props.appDialogHide();
            this.props.treatCall(this.props.notificacao.origemVenda, this.props.history);
        }} >

            <div className='card-content'>
                <h2 className='TextAlign codigo-origem'>
                    {getStrings().saleSourceTemplate(this.props.notificacao.origemVenda.nome)}
                </h2>
                <h2 className='NumberAlign data'>
                    {momentInstance.format('DD/MM/Y')}
                </h2>
                <h2 className='NumberAlign hora'>
                    {momentInstance.format('HH:mm')}
                </h2>
                <button className='botao-ok' onClick={(e) => {
                    e.stopPropagation();
                    this.props.updateUnread(this.props.notificacao.origemVenda);
                }}>OK</button>
            </div>
        </div>;
    }
}

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    appDialogHide: () => dispatch(appActions.appDialogHide()),
    treatCall: (origemVenda, history) => dispatch(controleVendaActions.treatCall(origemVenda, history)),
    updateUnread: origemVenda => dispatch(controleVendaActions.updateUnread(origemVenda))
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default withRouter(connect(null, mapDispatchToProps, null, { forwardRef: true })(BuildCellFromCalledSaleSource));
