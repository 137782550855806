import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { formatI18nString } from "../../../utils/StringUtils"

import { FILTER_TIMER_DELAY } from "../../../store/actions/cadastroAction"
import { SPINNER_FILTER_CLIENT_ID } from "../../../store/actions/collectorAction"
import * as collectorActions from "../../../store/actions/collectorAction"

import BarraAcoesBack from "../../../components/UI/Toolbar/BarraAcoesBack"
import BuildCellFromCollector from "../grid/BuildCellFromCollector"
import FiltroInputDefault from "../../UI/filtroInputDefault/FiltroInputDefault"
import HelpParagraph from "../../UI/HelpParagraph/HelpParagraph"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

/**
 * Exibe uma lista de clientes, que podem já ter uma venda em aberto ou não.
 */
class GridCollector extends React.Component {

  /**
   * Método executado APÓS a montagem do componente.
   * Realiza o carregamento dos cadastros na tela.
   */
  componentDidMount() {
    log("GridCollector componentDidMount")
    this.props.getCollectorList(this.props.filterClienteValue)
    this.ismounted = true
  }

  componentWillUnmount = () => {
    log("GridCollector componentWillUnmount")
    this.ismounted = false
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("GridCollector render")

    return <>
      <HelpParagraph children={getStrings().collectorGridHelp.map(help => formatI18nString(help))} />
      <BarraAcoesBack />
      <WidthAwareDiv>
        <div className="sub-form">
          <FiltroInputDefault
            defaultValue={this.props.filterClienteValue}
            ref={input => { if (input) { this.filterClienteComponent = getReduxWrappedComponent(input) } }}
            onFilterTimerCompleted={() => {
              if (this.ismounted) {
                this.props.getCollectorList(this.filterClienteComponent.getValue())
              }
            }}
            spinnerId={SPINNER_FILTER_CLIENT_ID}
            timerDelay={FILTER_TIMER_DELAY}
            label={getStrings().client}
            placeholder={getStrings().clientFilterPlaceholder}
          />
        </div>
      </WidthAwareDiv>
      <div className="pure-g">
        {this.props.collectorList.map(collector =>
          <BuildCellFromCollector
            collector={collector}
            key={collector.clienteURI} />
        )}
      </div>
    </>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  ...state.idiomaReducer,
  collectorList: state.collectorReducer.collectorList,
  filterClienteValue: state.collectorReducer.filterClienteValue
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  getCollectorList: search => dispatch(collectorActions.getCollectorList(search))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(GridCollector)
