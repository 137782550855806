import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { cadastroBuildOptionsFromEnum, cadastroRecuperaValueFromOptions } from "../../../utils/SelectUtils"

import {
  GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_CLOSED,
  GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_OPEN,
  GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_CODE_NUMERICAL,
  GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_NAME_ALPHABETICAL,
} from "../../../store/reducers/controleVenda/manutencao/manutencaoVendaReducer"
import * as gridGrupoProdutoActions from "../../../store/actions/controleVenda/manutencao/gridGrupoProdutoAction"

import InputCustomizado from "../../UI/Input/InputCustomizado"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

/**
 * Classe que monta o combo box usado para escolher a maneira como os grupos e produtos são exibidos na tela.
 * 
 * O código fazia parte de `GridGrupoProduto.js` e foi separado para evitar reconciliações desnecessárias ao alterar quantidade de produto.
 */
class GridDisplaySelect extends React.Component {

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  componentDidMount() {
    log("GridDisplaySelect componentDidMount")

    // Define as opções disponíveis no campo "Exibição" e seleciona o valor atual do cadastro.
    this.display.inputComponent.updateOptions(cadastroBuildOptionsFromEnum(true, [
      GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_CLOSED,
      GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_OPEN,
      GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_CODE_NUMERICAL,
      GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_NAME_ALPHABETICAL
    ], string => getStrings().displayEnumToString(string)))

    this.display.inputComponent.updateValue(cadastroBuildOptionsFromEnum(false,
      this.props.gridGrupoProdutoDisplay, string => getStrings().displayEnumToString(string)))

    typeof this.props.getDisplaySelector === "function"
      && this.props.getDisplaySelector(this.display.inputComponent)
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("GridDisplaySelect render")

    if (this.props.ignorarWidthAwareDiv) {
      return <InputCustomizado
        topClassName={`displaySelect ${this.props.topClassName || ""}`}
        id="display"
        inputType="singleSelect"
        name="display"
        label={getStrings().display}
        ref={input => this.display = getReduxWrappedComponent(input)}
        onChange={() => setTimeout(() => this.props.updateGridGrupoProdutoDisplay(cadastroRecuperaValueFromOptions(this.display.inputComponent.getValue())), 0)}
        clearable={false}
        searchable={false}
        sort
      />
    }

    return <WidthAwareDiv>
      <InputCustomizado
        topClassName={`displaySelect ${this.props.topClassName || ""}`}
        id="display"
        inputType="singleSelect"
        name="display"
        label={getStrings().display}
        ref={input => this.display = getReduxWrappedComponent(input)}
        onChange={() => setTimeout(() => this.props.updateGridGrupoProdutoDisplay(cadastroRecuperaValueFromOptions(this.display.inputComponent.getValue())), 0)}
        clearable={false}
        searchable={false}
        sort
      />
    </WidthAwareDiv>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  ...state.idiomaReducer,
  gridGrupoProdutoDisplay: state.manutencaoVendaReducer.gridGrupoProdutoDisplay
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  updateGridGrupoProdutoDisplay: gridGrupoProdutoDisplay => dispatch(gridGrupoProdutoActions.updateGridGrupoProdutoDisplay(gridGrupoProdutoDisplay))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(GridDisplaySelect)
