const F3ValidResponse = (callback) => {
    const fb = window.FB;

    fb.getLoginStatus((response) => {
        if (response && !response.error) {
            callback && callback(response);
        }
    });
};

export default F3ValidResponse;