import { log } from './LogUtils';
import { roundNumber } from './NumberUtils';
import { isNonEmptyObject, isNumber } from './ComparatorsUtils';

/**
 * @param {object} value valor
 * @param {number} percentage percentual
 * Retorna a quantidade de venda de um produto com quantidade pesada tal que o valor de venda resultante seja igual ao valor do livre.
 * 
 * Produtos com quantidade pesada cuja quantidade ultrapassar essa quantidade são vendidos como se o usuário tivesse escolhido "livre".
 * 
 * Explicação do cálculo: Digamos que o valor do a kilo seja 35 e o livre 20. A quantidade que equivale os dois é 0,5714…. Um produto pesado a 0,571 ainda é a kilo, enquanto que um pesado a 0,572 já é livre. Ou seja, qualquer quantidade acima de 0,5714… é livre. O problema é que a quantidade é arredondada em 3 casas decimais. Ou seja, se um cliente pedir um livre, ele terá a quantidade ajustada para esse valor, só que arredondada, pagando 20 por um produto com peso 0,571. Só que um a kilo com peso 0,571 custa 19,99... Assim, para que o cliente pague a mesma coisa por um livre e por um a kilo com o mesmo peso de corte, 0,5714… deve ser arredondado para cima, e todo peso igual ou maior é considerado livre.
 */
export const getAYCEAmount = (produto, precoLivre) => {
    log('utils getAYCEAmount', produto);

    if (!(isNonEmptyObject(produto) && isNumber(produto.preco))) {
        return NaN;
    }

    return Math.ceil((precoLivre / produto.preco) * 1000) / 1000;
}

/**
 * Calcula o percentual de algum valor e arredonda para 2 casas decimais.
 * @param {number} value valor
 * @param {number} percentage percentual
 */
export const applyPercentage = (value, percentage) => {
    if (!(isNumber(value) && isNumber(percentage))) {
        return NaN;
    }

    return roundNumber((percentage / 100) * value, 2);
}

/**
 * Calcula o valor de algo com comissão.
 * @param {number} value 
 * @param {number} commission percentual de comissão
 */
export const applyCommission = (value, commission) => {
    if (!(isNumber(value))) {
        return NaN;
    }

    return applyPercentage(value, commission) + value;
}

/**
 * Calcula o valor de algo com desconto em percentual.
 * @param {number} value 
 * @param {number} discount percentual de desconto
 */
export const applyDiscount = (value, discount) => {
    return value - applyPercentage(value, discount);
}