import React from "react";
import styled from "styled-components";
import { TitleSub, Paragraph, ConteudoSection } from ".";
import { PATH_IMAGENS_FOTOS } from "../utils/resources";
import { ImagensContainer, ImgRounded } from "./Containers";

const AppPropio = () => {
  return (
    <ConteudoSection>
      <TitleSub primary>App online próprio, sem comissão</TitleSub>
      <Paragraph>Aplicativo online e personalizado por link Facebook, Instagram, WhatsApp, site...</Paragraph>
      <ImagensContainer>
        <ImgSmartphone src={PATH_IMAGENS_FOTOS + "app_online_proprio1.svg"} alt={"Tela mobi.app"} />
        <ImgCliente src={PATH_IMAGENS_FOTOS + "app_online_proprio2.png"} alt={"Tela instagram"} />
        <ImgInsta src={PATH_IMAGENS_FOTOS + "app_online_proprio3.png"} alt={"Pessoa usando mobi"} />
      </ImagensContainer>
    </ConteudoSection>
  );
};

const ImgSmartphone = styled.img`
  position: relative;
  width: 350px;
  height: 300px;
  z-index: 10;
  @media (max-width: ${(props) => props.theme.sizes.breakPointSmall}) {
    width: 280px;
    height: 230px;
  }
`;

const ImgInsta = styled(ImgRounded)`
  right: 30px;
  top: -15px;
  z-index: 9;
  @media (max-width: ${(props) => props.theme.sizes.breakPointSmall}) {
    top: 0;
    right: 15px;
  }
`;

const ImgCliente = styled(ImgRounded)`
  bottom: -15px;
  left: 20px;
  z-index: 11;
  @media (max-width: ${(props) => props.theme.sizes.breakPointSmall}) {
    bottom: 0;
    left: 15px;
  }
`;

export default AppPropio;
