import { log } from '../LogUtils';
import { isNonEmptyObject } from '../ComparatorsUtils';


export const getGridGrupoProdutoDisplayStorage = () => localStorage.getItem("gridGrupoProdutoDisplay") ? JSON.parse(localStorage.getItem("gridGrupoProdutoDisplay")) : null;


/**
 * Retorna se há dados de venda armazenados no *storage*
 * e se esses dados podem ser recuperados através de uma origem armazenada no *storage*.
 */
export const canOpenSaleFromStorage = () => {

    let saleSourceNoCompany = getSaleSourceNoCompany();

    if (!saleSourceNoCompany) {
        return false;
    }

    let newSaleItemListMap = getNewSaleItemListMapStorage();

    if (!isNonEmptyObject(newSaleItemListMap)) {
        return false;
    }

    return !!newSaleItemListMap[saleSourceNoCompany.origemVendaURI];
}


/**
 * Caso o usuário tente fazer um pedido sem estar logado, ele será redirecionado para fazer um cadastro.
 * Esta origem de venda será enviada junto para ser retornada pelo email de confirmação de cadastro,
 * para quando o cadastro for confirmado o pedido possa ser retomado.
 */
export const getSaleSourceNoCompany = () => JSON.parse(localStorage.getItem('saleSourceNoCompany') || null) || null;

export const setSaleSourceNoCompany = saleSourceNoCompany => localStorage.setItem('saleSourceNoCompany', JSON.stringify(saleSourceNoCompany || null));


export const getNewSaleItemListMapStorage = () => JSON.parse(localStorage.getItem('newSaleItemListMap') || null) || {};

export const setNewSaleItemListMapStorage = newSaleItemListMap => localStorage.setItem('newSaleItemListMap', JSON.stringify(newSaleItemListMap || null));


/**
 * URI da última origem de venda lida pelo leitor de código impresso para acompanhar estado dos pedidos.
 */
export const getLastScannedSaleSource = () => JSON.parse(localStorage.getItem('lastScannedSaleSource') || null) || null;

export const setLastScannedSaleSource = lastScannedSaleSource => localStorage.setItem('lastScannedSaleSource', JSON.stringify(lastScannedSaleSource || null));

export const removeLastScannedSaleSource = () => localStorage.removeItem('lastScannedSaleSource');

/**
 * @param {number|null} defaultSize
 * 
 * Busca no Storage o tamanho das páginas
 */
export const getPageSize = (defaultSize = null) => {
    log('utils getPageSize', { defaultSize });
    let pageSize = JSON.parse(localStorage.getItem('tempPageSize'))
    return (pageSize === null) ? defaultSize : pageSize;
}

/**
 * Seta no Storage o tamanho das páginas
 * @param {Number} value 
 * @param {Boolean} condition 
 */
export const setPageSize = (value, condition = true) => {
    condition && localStorage.setItem(
        'tempPageSize',
        value,
    );
}

/**
 * Remove do Storage o tamanho das páginas
 */
export const removePageSize = () => {
    localStorage.removeItem('tempPageSize');
}