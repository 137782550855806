import moment, { Moment } from "moment";
import { quadroHorariosReducerTipo } from "../../apps/promo_app_frontend/nucleo/redux/quadroHorarios/quadroHorariosReducer";
import { diaDaSemanaHorariosTipo, horarioTipo, horarioFuncionamentoTipo, diaDaSemanaTipo } from "../QuadroHorariosUtils/quadroHorariosTypes";

export const diasDaSemana: diaDaSemanaTipo[] = ['SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB', 'DOM'];

export const horarioFuncionamentoListToDiasDaSemanaHorarios = (horarioFuncionamentoList?: horarioFuncionamentoTipo[]): diaDaSemanaHorariosTipo => {

  const diasDaSemanaHorarios: diaDaSemanaHorariosTipo = { SEG: [], TER: [], QUA: [], QUI: [], SEX: [], SAB: [], DOM: [] };

  if (horarioFuncionamentoList?.length) {
    horarioFuncionamentoList.forEach((horario: horarioFuncionamentoTipo) => {
      diasDaSemanaHorarios[horario.diaDaSemana] = [...diasDaSemanaHorarios[horario.diaDaSemana], { horaInicial: horario.horaInicial, horaFinal: horario.horaFinal }];
    });
  }

  return diasDaSemanaHorarios;
}

export const calculaMinutos = (horario: string): number => {
  const [horas, minutos] = horario.split(':');
  return (parseInt(horas) * 60) + parseInt(minutos);
}

export const mesclaHorarios = (listaHorarios: horarioTipo[]): horarioTipo[] => {

  let mesclado;
  let novaListaHorarios: horarioTipo[] = [];

  do {
    mesclado = false;

    for (let i = 0; i < listaHorarios.length; i++) {

      const horario = listaHorarios[i];

      novaListaHorarios = [];

      for (let j = 0; j < listaHorarios.length; j++) {

        const comparacao = listaHorarios[j];

        if (i !== j) {
          let horaInicial = comparacao.horaInicial;
          let horaFinal = comparacao.horaFinal;

          if (horario.horaInicial === comparacao.horaInicial && horario.horaFinal === comparacao.horaFinal) {
            horaInicial = horario.horaInicial;
            horaFinal = horario.horaFinal;
            mesclado = true;
          }
          else if (horario.horaInicial < comparacao.horaInicial && horario.horaFinal >= comparacao.horaInicial) {
            horaInicial = horario.horaInicial;
            horaFinal = horario.horaFinal > comparacao.horaFinal ? horario.horaFinal : comparacao.horaFinal;
            mesclado = true;
          }
          else if (horario.horaInicial <= comparacao.horaFinal && horario.horaFinal > comparacao.horaFinal) {
            horaInicial = horario.horaInicial < comparacao.horaInicial ? horario.horaInicial : comparacao.horaInicial;
            horaFinal = horario.horaFinal;
            mesclado = true;
          }
          else if (horario.horaInicial >= comparacao.horaInicial && horario.horaFinal <= comparacao.horaFinal) {
            horaInicial = comparacao.horaInicial;
            horaFinal = comparacao.horaFinal;
            mesclado = true;
          }

          novaListaHorarios.push({ horaInicial, horaFinal });
        }
      }

      if (mesclado) {
        break;
      }
      else {
        novaListaHorarios.push({ horaInicial: horario.horaInicial, horaFinal: horario.horaFinal });
      }
    }

    listaHorarios = novaListaHorarios;
  }
  while (mesclado && novaListaHorarios.length > 1)

  return novaListaHorarios;
}

export const timeInputOnChange = (value: any, atualizaHora: React.Dispatch<React.SetStateAction<moment.Moment | undefined>>) => {
  if (typeof value === 'string') {
      
      let valueTemp = value.replace(/\D/g, '');
      
      valueTemp = valueTemp.split('').reduce((acc, cur, idx) => {
          return idx === 2 ? (acc + ':' + cur) : (acc + cur);
      }, '');

      if (valueTemp.length > 4) {
          const horaTemp = moment(valueTemp, 'HH:mm');
          atualizaHora(horaTemp);
      }
  }
  else {
      const horaTemp = moment(`${value.hour()}:${value.minutes()}`, 'HH:mm');
      atualizaHora(horaTemp);
  }
}

export const timeInputOnBlur = (value: any, hora: Moment | undefined, atualizaHora: React.Dispatch<React.SetStateAction<moment.Moment | undefined>>) => {
  if (typeof value === 'string') {
      
      let horaTemp;

      if (value.length) {
          horaTemp = moment(hora).seconds(0).milliseconds(0);
      }
      else {
          horaTemp = moment('00:00', 'HH:mm');
      }
      atualizaHora(horaTemp);
  }
}

function isHorarioBetween(hora: Date, horaInicial: string, horaFinal: string): boolean {
  const horario = (hora.getHours() * 60) + hora.getMinutes();
  const horarioInicial = horaInicial.split(':');
  const horarioFinal = horaFinal.split(':');
  const inicio = (parseInt(horarioInicial[0]) * 60) + parseInt(horarioInicial[1]);
  const fim = (parseInt(horarioFinal[0]) * 60) + parseInt(horarioFinal[1]);

  return horario >= inicio && horario <= fim;
}

export const estaEmHorarioDeFuncionamento = (quadroHorarios: quadroHorariosReducerTipo): boolean => {

  if (quadroHorarios.forcarEmpresaFechada) {
    return false;
  }

  const hoje = new Date();

  if (quadroHorarios.feriadoList?.length) {
    for (const feriado of quadroHorarios.feriadoList) {
      if (feriado.mes === hoje.getMonth() && feriado.dia === hoje.getDate()) {
        if (feriado.horarioFeriadoList?.length) {
          if (feriado.horarioFeriadoList.some(horarioFeriado => isHorarioBetween(hoje, horarioFeriado.horaInicial, horarioFeriado.horaFinal))) {
            return true;
          }
          return false;
        }
        else {
          return false;
        }
      }
    }
  }

  if (quadroHorarios.horarioFuncionamentoList?.length) {
    const diaDaSemana = diasDaSemana[hoje.getDay() - 1];
    for (const horarioFuncionamento of quadroHorarios.horarioFuncionamentoList) {
      if (horarioFuncionamento.diaDaSemana === diaDaSemana
        && isHorarioBetween(hoje, horarioFuncionamento.horaInicial, horarioFuncionamento.horaFinal)) {
        return true;
      }
    }
  }

  return false;
}