import { log } from './LogUtils';
import { isDate, isMoment, isString } from './ComparatorsUtils';
import { DATE_FORMAT_SECOND, DATE_FORMAT_MINUTE, DATE_FORMAT_HOUR, DATE_FORMAT_DAY, DATE_FORMAT_MONTH, DATE_FORMAT_YEAR, formatDateValue, DATE_FORMAT_YEAR_SHORT } from './DateUtils';

let moment = require('moment');

/**
 * 
 * @param {Date} dateTime 
 */
export const formatHourMinuteFromDate = dateTime => {
    // log('utils formatHourMinuteFromDate', dateTime);

    if (!(isDate(dateTime) || isMoment(dateTime))) {
        return null;
    }

    let time = (formatDateValue(dateTime, DATE_FORMAT_HOUR) + ':' + formatDateValue(dateTime, DATE_FORMAT_MINUTE));

    return time;
}

/**
 * 
 * @param {Date} dateTime 
 */
export const formatDayMonthYear = (dateTime, yearShort) => {
    // log('utils formatDayMonthYear', { dateTime });

    if (!(isDate(dateTime) || isMoment(dateTime))) {
        return null;
    }

    return formatDateValue(dateTime, DATE_FORMAT_DAY) + '/' + formatDateValue(dateTime, DATE_FORMAT_MONTH) + '/' + formatDateValue(dateTime, yearShort ? DATE_FORMAT_YEAR_SHORT : DATE_FORMAT_YEAR);
}

/**
 * 
 * @param {Date} dateTime 
 */
export const formatDayMonthYearHourMinute = (dateTime, yearShort) => {
    return formatDayMonthYear(dateTime, yearShort) + ' - ' + formatHourMinuteFromDate(dateTime);
}

/**
 * 
 * @param {Date} dateTime 
 */
export const formatMonthDayYear = dateTime => {
    // log('utils formatDayMonthYear', { dateTime });

    if (!(isDate(dateTime) || isMoment(dateTime))) {
        return null;
    }

    return formatDateValue(dateTime, DATE_FORMAT_MONTH) + '/' + formatDateValue(dateTime, DATE_FORMAT_DAY) + '/' + formatDateValue(dateTime, DATE_FORMAT_YEAR);
}

/**
 * Formata um instante de tempo no formato ISO 8601 para o formato 23:59 considerando hora local do fuso horário original.
 * @param {String} dateTime instante de tempo no formato ISO 8601
 */
export const formatHourMinuteFromOriginalZone = dateTime => {
    log('utils formatHourMinuteFromOriginalZone', dateTime);

    if (!isString(dateTime)) {
        return null;
    }

    return moment(dateTime).parseZone().format('HH:mm');
}

/**
 * Retorna a hora atual no formato `12:34:56`.
 */
export const getCurrentHourMinuteSecondFormatted = () => {
    log('utils getCurrentHourMinuteSecondFormatted');
    let data = new Date();
    return formatDateValue(data, DATE_FORMAT_HOUR) + ':' + formatDateValue(data, DATE_FORMAT_MINUTE) + ':' + formatDateValue(data, DATE_FORMAT_SECOND);
}

export const getFormattedTime = (time, timeFormat) => {
    timeFormat = timeFormat || 'HH:mm';

    return moment(time, timeFormat).format();
}

/**
 * 
 * @param {moment.Moment} date 
 * @param {String} time 
 */
export const setDateTimeAndGetDate = (date, time) => {
    return moment(moment(date).format('MM-DD-YYYY HH:mm').slice(0, -5) + time).format('MM-DD-YYYY HH:mm')
}
