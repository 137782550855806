import { getAppCargo, getAppPermissoesCargo, updateAppPermissoesCargo } from "../../utils/AppUtils"
import { comparaListas } from "../../utils/ComparatorsUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { enableLog, MOSTRAR_LOG_REMOTO_RECEBIDO } from "../../utils/LogUtils"
import { reduxDispatch } from "../../utils/reduxUtils/reduxDispatch"
import { confirmMessageAsync } from "../../utils/ServerUtils/ServerUtils"
import { formatI18nString } from "../../utils/StringUtils"


import * as actionTypes from "./actionTypes"
import * as appActions from "./appAction"
import * as producaoActions from "./producaoAction"
import * as controleVendaActions from "./controleVenda/controleVendaAction"
import * as manutencaoVendaActions from "./controleVenda/manutencao/manutencaoVendaAction"
import { justUpdatePermissoesCargo } from "./empresaSelectorAction"

export const ADD_PRODUCT_WITH_WEIGHT = "addProductWithWeight"

export const BEGIN_NEW_SALE_FROM_SCALES = "beginNewSaleFromScales"

export const CARGO = "cargo"

export const CARGO_UPDATED = "cargoUpdated"

export const PRINT_SCALES_FAIL = "printScalesFail"

export const FREE_SALE_SOURCE_RESERVED = "freeSaleSourceReserved"

export const READ_SCALES_FAIL = "readScalesFail"

export const SALE_ITEM_STATE_CHANGED = "saleItemStateChanged"

export const SALE_ITEM_UPDATED = "saleItemUpdated"

export const SALE_SOURCE_CALLED = "saleSourceCalled"

export const SALE_SOURCE_IN_USE_RESERVED = "saleSourceInUseReserved"

export const DEFAULT_WEIGHTED_PRODUCT_NOT_CONFIGURED = "defaultWeightedProductNotConfigured"

/**
 * Exibe mensagens no console se variável estiver habilitada.
 * Definido separadamente da função em Utils pois conflita com o *log* remoto.
 * @param {any} args
 */
const log = (...args) => {
  if (enableLog()) {
    if (args.includes("error")) {
      console.error(...args)
    } else if (args.includes("warning")) {
      console.warn(...args)
    } else {
      console.info(...args)
    }
  }
}

export function messageReceived(topic, message_) { return function(dispatch, getState) {
  log("subscriberAction messageReceived", {
    topic,
    subject: JSON.parse(message_?.body || "null")?.subject,
    message: message_,
    body: JSON.parse(message_?.body || "null"),
  })

  // Verifica se recebeu dados
  if ((!message_) || (!message_.body)) {
    return
  }
  // Verifica se recebeu dados no formato correto
  const messageBody = JSON.parse(message_.body)
  if (!messageBody) {
    return
  }

  const message = {
    ...messageBody,
    log: messageBody.log
      ? messageBody.log.substring(messageBody.log.indexOf(" ") + 2, messageBody.log.length - 1)
      : undefined
  }

  if (message.log) {
    if (MOSTRAR_LOG_REMOTO_RECEBIDO) {
      if (message.log.includes("error")) {
        console.error(`remote_log: ${message.log}`)
      } else if (message.log.includes("warning")) {
        console.warn(`remote_log: ${message.log}`)
      } else {
        console.info(`remote_log: ${message.log}`)
      }
    }
  }
  else {
    // Trata cada mensagem
    switch (message.subject) {
      case ADD_PRODUCT_WITH_WEIGHT:
        dispatch(manutencaoVendaActions.addNewSaleItemUsingWeight(message.produto, message.weight, message.dateTime))
        break
      case BEGIN_NEW_SALE_FROM_SCALES:
        dispatch(controleVendaActions.setupPersistedSaleFromURI(message.subject, message.venda, message.produto, message.weight))
        break
      case CARGO_UPDATED:
        confirmMessageAsync("role", message.subject)
          .then(() => {
            const cargo = JSON.parse(message.cargo)
            if (getAppCargo() === cargo.links[0].href) {
              const permissoesCargo = Object.keys(cargo.cargoPermissao)
                .filter((key) => cargo.cargoPermissao[key] === true)
              const permissoesCargoNaSessao = getAppPermissoesCargo()
              const listasDiferentes = !comparaListas(permissoesCargo, permissoesCargoNaSessao)
              const cadastroReducer = getState().cadastroReducer
              if (listasDiferentes && cadastroReducer.type !== "CADASTRO_SUBMIT") {
                updateAppPermissoesCargo(permissoesCargo)
                reduxDispatch(justUpdatePermissoesCargo(permissoesCargo))
              }
            }
          })
        break
      case PRINT_SCALES_FAIL:
      case READ_SCALES_FAIL:
        dispatch(notifyUser(message.subject))
        break
      case SALE_ITEM_STATE_CHANGED:
        dispatch(producaoActions.getUnreadItemVendaList(message.itemVenda))
        break
      case SALE_ITEM_UPDATED:
        let itemVenda = JSON.parse(message.itemVenda || "null")
        dispatch(producaoActions.loadItensVendaAbertos(null, null, itemVenda, true))
        dispatch(controleVendaActions.handleNoCompanySaleItemStateUpdate(itemVenda))
        break
      case SALE_SOURCE_CALLED:
        dispatch(controleVendaActions.getUnreadOrigemVendaList())
        break
      case FREE_SALE_SOURCE_RESERVED:
        dispatch(controleVendaActions.removeFreeSaleSource(message.origemVenda, message.u))
        break
      case SALE_SOURCE_IN_USE_RESERVED:
        dispatch(controleVendaActions.removeSaleSourceInUse(message.origemVenda, message.u))
        break
      case DEFAULT_WEIGHTED_PRODUCT_NOT_CONFIGURED:
        dispatch(appActions.showDefaultWeightedProductNotConfigured());
        break;
      default:
    }
  }
}
}

/**
 * Avisa o usuário de que a leitura de peso pela balança falhou
 */
export const notifyUser = subject => dispatch => {
  log("manutencaoVendaAction notifyUser", { subject })
  // Busca a mensagem a ser exibida
  let message
  switch (subject) {
    case PRINT_SCALES_FAIL:
      message = getStrings().printScalesFailed
      break
    case READ_SCALES_FAIL:
      message = formatI18nString(getStrings().scalesFailed)
      break
    default:
      message = ""
  }
  // Exibe aviso
  dispatch(appActions.appNotificationShow(message, actionTypes.APP_NOTIFICATION_TYPE_WARNING))
  // Notifica retaguarda de que recebeu mensagem
  dispatch(appActions.confirmMessage("role", subject))
}
