import React from 'react';
import { connect } from 'react-redux';

import { isEmpresaPainel as util_isEmpresaPainel } from "../../utils/CompanyUtils"
import { formatNumber } from "../../utils/NumberUtils"
import { log } from "../../utils/LogUtils"
import { listToFragment } from "../../utils/ScreenUtils"

// Chaves das colunas
import {
    NOME_CLIENTE,
    ITEM_VENDA_PRODUTO,
    OBSERVACAO,
    ITEM_VENDA_HORA,
    ITEM_PAGAMENTO_VENDA_VALOR_TOTAL,
    TELEFONE,
    ENDERECO,
} from './RelatorioBox';

/**
 * Classe que realiza a montagem das linhas da tabela.
 */
class EntregasTableRow extends React.Component {
    /**
     * Monta cada célula de acordo com a chave da coluna.
     */

    cellData = column => {
        log('EntregasTableRow cellData', column);
        console.log(this.id);
        if (this.props.tracejado) {
        return <td  className='Lighter TextAlign'><div id ='tracejado'></div></td>;
        }
        switch (column) {
            case NOME_CLIENTE:
                return <td className={`Lighter TextAlign ${this.props.cor}`} width='25%' >{this.props.dadosMap[NOME_CLIENTE]}</td>;
            case ITEM_VENDA_PRODUTO:
                return <td className={`Lighter TextAlign ${this.props.cor}`} width='25%' >{this.props.dadosMap[ITEM_VENDA_PRODUTO]}</td>;
            case OBSERVACAO:
                return <td className={`Lighter TextAlign ${this.props.cor}`} width='25%' >{this.props.dadosMap[OBSERVACAO]}</td>;
            case ITEM_VENDA_HORA:
                return <td className={`Lighter ButtonAlign ${this.props.cor}`} width='10%' >{this.props.dadosMap[ITEM_VENDA_HORA]}</td>;
            case ITEM_PAGAMENTO_VENDA_VALOR_TOTAL:
                return <td className={`Lighter NumberAlign ${this.props.cor}`} width='1%' >{formatNumber(this.props.dadosMap[ITEM_PAGAMENTO_VENDA_VALOR_TOTAL], 2)}</td>;
            case TELEFONE:
                return <td className={`Lighter TextAlign ${this.props.cor}`} width='25%' >{this.props.dadosMap[TELEFONE]}</td>;
            case ENDERECO:
                return <td className={`Lighter TextAlign ${this.props.cor}`} width='25%' >{this.props.dadosMap[ENDERECO]}</td>;
            default:
                return null;
        }
    }

    isEmpresaPainel = () => util_isEmpresaPainel(this.props.ramoEmpresa);

    /**
     * Método que executa a montagem do componente.
     */
    render() {
        log('EntregasTableRow render');

        // Se não recebeu as colunas por parâmetro, não pode montar a linha da tabela
        if (!this.props.columnOrder) {
            return null;
        }

        return <tr>
            {/* Dados do relatório */}
            {listToFragment(this.props.columnOrder.map(column => this.cellData(column)))}
        </tr>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    ...state.relatorioReducer,
    ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(EntregasTableRow);