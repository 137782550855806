import { log } from './LogUtils';
import { isNonEmptyObject } from './ComparatorsUtils';
import pt_br from '../assets/strings/pt-BR';
import en_us from '../assets/strings/en-US';

export const locale = {
    en_us: 'en-US',
    pt_br: 'pt-BR'
}

export const global_strings = {
    [locale.en_us]: en_us,
    [locale.pt_br]: pt_br
}

export const defaultLocaleString = locale.pt_br;

export const defaultLocale = global_strings[defaultLocaleString];
let strings = defaultLocale;

export const getStrings = () => {
    if (!strings) {
        strings = defaultLocale;
    }
    return strings;
}

/**
 * 
 * @param {Object} newStrings 
 */
export const setStrings = newStrings => {
    log('utils setStrings', newStrings);

    if (!isNonEmptyObject(newStrings)) {
        return null;
    }

    strings = newStrings;
    return true;
}