import React from "react";
import styled from "styled-components";
import { Paragraph } from ".";
import { PATH_IMAGENS_SOCIAL } from "../utils/resources";

const Social = () => {
  return (
    <SocialContainer>
      <Paragraph style={{marginBottom: '5px'}}>Você também pode nos encontrar em: </Paragraph>
      <IconesGrid>
        <a href="https://api.whatsapp.com/send?phone=555137151516" target="_blank" rel="noopener noreferrer">
          <Icone src={PATH_IMAGENS_SOCIAL + "whatsapp.svg"} />
        </a>
        <a href="https://www.facebook.com/ampixsoftware/" target="_blank" rel="noopener noreferrer">
          <Icone src={PATH_IMAGENS_SOCIAL + "facebook.svg"} />
        </a>
        <a href="https://www.instagram.com/ampixsoftware/" target="_blank" rel="noopener noreferrer">
          <Icone src={PATH_IMAGENS_SOCIAL + "instagram.svg"} />
        </a>
        <a href="https://www.linkedin.com/company/ampix-software/" target="_blank" rel="noopener noreferrer">
          <Icone src={PATH_IMAGENS_SOCIAL + "linkedin.svg"} />
        </a>
      </IconesGrid>
    </SocialContainer>
  );
};

const SocialContainer = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const Icone = styled.img`
  width: 50px;
  height: 50px;
  margin: 0.5em;
  border-radius: 999px;
  &:hover {
    filter: drop-shadow(8px 8px 10px gray);
  }
`;

export default Social;
