import React from "react";
import styled from "styled-components";
import { TitleSub, Paragraph, ConteudoSection } from ".";
import { PATH_IMAGENS_FOTOS, PATH_IMAGENS_PRINTS } from "../utils/resources";
import { ImagensContainer, ImgRounded, PrintTablet } from "./Containers";

const LeitorQRCode = React.forwardRef((props, ref) => {
  return (
    <ConteudoSection ref={ref}>
      <TitleSub primary>QR Code</TitleSub>
      <Paragraph>QR Code em cardápios, panfletos e mesas</Paragraph>
      <ImagensContainer>
        <Print src={PATH_IMAGENS_PRINTS + "qr_code1.png"} alt={"Producao"} />
        <ImgRoundLeft src={PATH_IMAGENS_FOTOS + "qr_code2.png"} alt={"Carregamento Embalagens"} />
        <ImgRoundRight src={PATH_IMAGENS_FOTOS + "qr_code3.png"} alt={"Cozinheiro Pimenta"} />
      </ImagensContainer>
    </ConteudoSection>
  );
});

const Print = styled(PrintTablet)`
  height: 350px;
  width: 200px;
  position: absolute;
  z-index: 11;
`;

const ImgRoundRight = styled(ImgRounded)`
  right: 0;
  bottom: -45px;
  z-index: 11;
`;

const ImgRoundLeft = styled(ImgRounded)`
  left: 0;
  bottom: -10px;
  z-index: 12;
`;

export default LeitorQRCode;
