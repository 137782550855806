import React from 'react';

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import CadastroTable from './CadastroTable';
import CargoTableModel from './CargoTableModel';
import OptionButtons from '../optionButtons/OptionButtons';
import BotaoChecado from '../../UI/botoes/BotaoChecado/BotaoChecado';

/**
 * Classe que repassa para o CadastroTable as informações para a montagem da tabela dos Cargos.
 */
export default class CargoTable extends React.Component {

    render() {
        log('CargoTable render');
        return <CadastroTable {...this.props} objetoCadastro='cargo' sortProperty='nome' filterProperties={['nome']} tableModel={CargoTableModel}
            blankFormData={{
                enabled: true,
                nome: '',
                usuarioList: [],

                cargoPermissao: {
                    cadastroGeral: false,
                    cadastroEmpresa: false,
                    cadastroCargo: false,
                    producaoAlteracao: false,
                    producaoControle: false,
                    vendaControle: false,
                    vendaEntrega: false,
                    vendaEnvio: false,
                    vendaExclusao: false,
                    vendaPagamento: false,
                    controleFaturamento: false,
                    controleFinanceiro: false,
                    relatorios: false,
                    integracaoConfiguracao: false
                },

                parametrosCargo: {
                    limiteExibicaoItensProduzidos: 86400000000000,
                    escopoExibicaoItemVenda: null,
                    receberNotificacaoOrigem: true,
                    tipoProdutoList: []
                }
            }} registerHelp={getStrings().roleTableHelp}
        />;
    }
}

/**
 * Classe que realiza a montagem das linhas da tabela de cadastro.
 */
export class CargoTableRow extends React.Component {

    render() {
        log('CargoTableRow render');

        let id = `${getURIFromEntity(this.props.cadastro)}/Checkbox`;

        return <tr>

            {/* Opções de manutenção */}
            <OptionButtons className='ButtonAlign' onUpdate={this.props.onUpdate} onDelete={this.props.onDelete} cadastro={this.props.cadastro} />
            
            {/* Dados do cadastro */}
            <td className='Lighter ButtonAlign' width='5%' >
                <BotaoChecado key={`__${id}__`} desativado={!this.props.cadastro.enabled} />
            </td>
            <td className='Lighter LeftAlign' width='95%' >{this.props.cadastro.nome}</td>
        </tr>;
    }
}
