
import { hideSpinner } from "../../components/UI/Spinner/Spinner"

import * as errorUtils from "../../utils/ErrorUtils"
import { getAppCargo, getAppEmpresa } from "../../utils/AppUtils"
import { forEach } from "../../utils/ArrayUtils"
import { getEmpresaId, isEmpresaPainel } from "../../utils/CompanyUtils"
import { isString } from "../../utils/ComparatorsUtils"
import { getHeaders } from "../../utils/HeadersUtils"
import { validaCNPJ } from "../../utils/IndentificadorUtils"
import { getStrings, locale } from "../../utils/LocaleUtils"
import { getPageSize } from "../../utils/StorageUtils/LocalStorageUtils"
import { log } from "../../utils/LogUtils"
import { barCodeFormatOrder } from "../../utils/MiscUtils"
import { isEdge } from "../../utils/NavigatorUtils"
import { getApi, urlDatabase } from "../../utils/SecureConnectionUtils"
import { cadastroBuildOptionsFromUsuarios } from "../../utils/SelectUtils"
import { getIdFromEntity, getURIFromEntity } from "../../utils/URIUtils"

import * as actionTypes from "./actionTypes"
import { dispatchTipo } from "./acaoTemplate"
import {
  appNotificationShow,
  appSpinnerShow,
  appSpinnerHide,
  axios
} from "./appAction"

import {
  STATE_CADASTRO_FORM,
  STATE_CADASTRO_LIST,
  STATE_CADASTRO_PRINT_SETUP,
  STATE_CADASTRO_SETUP
} from "../reducers/cadastroReducer"
import * as cadastroEmpresaActions from "./cadastroEmpresaAction"
import * as cadastroPrintActions from "./cadastroPrintAction"
import {
  SPINNER_FILTRO_ORIGEM_VENDA_ID
  , SPINNER_FILTRO_VENDA_ID
} from "./controleVenda/controleVendaAction"
import * as empresaSelectorActions from "./empresaSelectorAction"
import * as pagamentoVendasActions from "./controleVenda/pagamentoVendasAction"

import { pageType } from "../../utils/entidadeUtils/pageType"
import { responseType } from "../../utils/htmlUtils/responseType"
import { getStateType } from "../../utils/reduxUtils/getStateType"

//import { embeddedFont } from "../../components/cadastros/print/EmbeddedFont"
//import TextToSVG from "../../assets/libs/text-to-svg"

/**
 * A busca filtrada de cadastros só se inicia FILTER_TIMER_DELAY milissegundos depois do usuário terminar de digitar o filtro. 
 */
export const FILTER_TIMER_DELAY = 1000

/**
 * Id do spinner que é exibido ao lado do input de filtro de cadastros. 
 */
export const SPINNER_INPUT_ID = 'spinnerInput'

export const SPEC_QUERY_EXACT = 'specQueryExact'

export const SPEC_QUERY_STARTS_WITH = 'specQueryStartsWith'

export const SPEC_QUERY_ENDS_WITH = 'specQueryEndsWith'

export const SPEC_QUERY_CONTAINS = 'specQueryContains'

/**
 * Constroi uma *query* de busca para os repositórios do tipo `JpaSpecificationExecutor`.
 * Ex.: `nome:*a*` para 1 propriedade `nome:*1* OR codigo:*1*` para mais propriedades.
 * 
 * Se `filter` for um valor, usa ele em todas as propriedades.
 * Se `filter` for uma lista, distribui os itens da lista entre as propriedades. O comprimento de ambas as listas deve ser o mesmo.
 * 
 * `wildCardConfig` tem a seguinte função, dependendo do seu valor:
 * * `SPEC_QUERY_EXACT`: filtra as entidades cuja propriedade for exatamente igual ao valor (desconsiderando capitalização)
 * * `SPEC_QUERY_STARTS_WITH`: filtra as entidades onde o valor for igual ao início da propriedade
 * * `SPEC_QUERY_ENDS_WITH`: filtra as entidades onde o valor for igual ao final da propriedade
 * * `SPEC_QUERY_CONTAINS`: filtra as entidades onde o valor estiver presente na propriedade.
 * @param filterProperties variáveis da entidade 
 * @param filter valor(es) a ser(em) buscado(s) nas propriedades
 * @param wildCardConfig configura o uso de coringas
 */
export const buildSpecQuery = (filterProperties: string[], filter: string | (string | boolean)[], wildCardConfig?: string) => {
  log('cadastroAction buildSpecQuery', { filterProperties, filter, wildCardConfig })
  let query = ''

  let prefix = ''
  let suffix = ''

  switch (wildCardConfig) {
    case SPEC_QUERY_ENDS_WITH:
    case SPEC_QUERY_CONTAINS:
    default:
      prefix = '*'
  }

  switch (wildCardConfig) {
    case SPEC_QUERY_STARTS_WITH:
    case SPEC_QUERY_CONTAINS:
    default:
      suffix = '*'
  }

  filterProperties.forEach((property, i) => {
    if (i > 0) {
      query += ' OR '
    }

    query += property + ':' + prefix + (Array.isArray(filter)
      // Se filtro for uma lista, usa cada valor para uma propriedade.
      ? encodeURIComponent(filter[i])
      // Se não, usa o mesmo valor em todas as propriedades.
      : encodeURIComponent(filter)) + suffix
  })

  return query
}

/**
 * Dispara troca de estado para carregar objetos.
 * @param page número da página de resultados
 * @param pageSize tamanho da página de resultados (quantidade de registros)
 * @param cadastroList lista de registros retornados pelo servidor
 * @param links endereços das outras páginas de resultado
 * @param cadastroDados 
 * @param filter valor do filtro
 * @param breadCrumbsSuffix 
 * @param objetoCadastroLast tipo de entidade dos dados requisitados
 */
const cadastroLoad = (page: pageType, pageSize: number, cadastroList: any[], links: Record<string, { href: string }>, cadastroDados: any, filter?: string | string[] | null, breadCrumbsSuffix?: string, objetoCadastroLast?: string) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoad', { page, pageSize, cadastroList, links, cadastroDados, filter, breadCrumbsSuffix, objetoCadastroLast })
  // Se for pagamentos de venda, limpa as seleções para cancelamento.
  if (objetoCadastroLast === 'pagamentoVenda') {
    dispatch(pagamentoVendasActions.clearSalePaymentToCancel())
  }
  dispatch({
    type: actionTypes.CADASTRO_LOAD,
    error: null,
    loading: false,
    page: page,
    pageSize: pageSize,
    cadastroList: cadastroList,
    links: links,
    state: STATE_CADASTRO_LIST,
    ...((cadastroDados !== undefined) ? { cadastroDados: cadastroDados } : {}),
    ...((filter !== undefined) ? { filter: filter } : {}),
    ...(breadCrumbsSuffix ? { breadCrumbsSuffix: breadCrumbsSuffix } : {}),
    ...((objetoCadastroLast !== undefined) ? { objetoCadastroLast: objetoCadastroLast } : {}),
  })
}

export type cadastroSubmitType = () => {
  type: string,
  error: null,
  loading: boolean,
}

/**
 * Dispara troca de estado para criação, edição ou deleção de objeto.
 */
export const cadastroSubmit: cadastroSubmitType = () => {
  log("cadastroAction cadastroSubmit");
  return {
    type: actionTypes.CADASTRO_SUBMIT,
    error: null,
    loading: true,
  };
}

/**
 * Dispara troca de estado para retorno de criação, edição ou deleção de objeto com sucesso.
 * @param {String} state
 * @param {*} cadastroDados
 * @param {*} filter
 */
const cadastroSuccess = (state: string, cadastroDados?: any, filter?: string | string[] | null) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroSuccess', { state, cadastroDados, filter })
  dispatch(cadastroSuccessGenerico(state, cadastroDados, filter))
}

/**
 * Dispara troca de estado para retorno de criação, edição ou deleção de objeto com sucesso.
 * @param {String} state
 * @param {*} cadastroDados
 * @param {*} filter
 */
const cadastroSuccessGenerico = (state: string, cadastroDados?: any, filter?: string | string[] | null) => {
  log('cadastroAction cadastroSuccessGenerico', { state, cadastroDados, filter })
  return {
    type: actionTypes.CADASTRO_SUCCESS,
    ...(state ? { state } : {}),
    ...((cadastroDados !== undefined) ? { cadastroDados } : {}),
    ...((filter !== undefined) ? { filter } : {}) // permitir null para limpar o filtro
  }
}

/**
 * Dispara troca de estado para retorno de criação, edição ou deleção de objeto com falha.
 */
const cadastroFail = (error: string, state: string, cadastroDados?: any) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroFail', { error, state, cadastroDados })
  dispatch(cadastroFailGenerico(error, state, cadastroDados))
}

/**
 * Dispara troca de estado para retorno de criação, edição ou deleção de objeto com falha.
 */
const cadastroFailGenerico = (error: string, state: string, cadastroDados?: any) => {
  log('cadastroAction cadastroFailGenerico', { error, state, cadastroDados })
  return {
    type: actionTypes.CADASTRO_FAIL,
    error: error,
    loading: false,
    ...(state ? { state } : {}),
    ...((cadastroDados !== undefined) ? { cadastroDados } : {}),
  }
}

export type cadastroSwitchUIType = (state: string, cadastroDados?: Record<string,any>|null, keepLoading?: boolean,
  formMounted?: boolean, operation?: string|null) => (dispatch: dispatchTipo) => void;

/**
 * Dispara troca de estado para alternar entre tela de criação ou edição de cadastros e tela de exibição de cadastros.
 * @param {string} state se é criação/edição de cadastro
 * @param {Record<string,any>|null|undefined} cadastroDados dados do cadastro a ser editado
 * @param {boolean|undefined} keepLoading se `true`, não altera o estado da variável `loading` se não, `loading` fica `false`
 * @param {boolean|undefined} formMounted se foi aberto o formulário do cadastro para criação ou edição
 * @param {string|null|undefined} operation string de identificação da operação
 */
export const cadastroSwitchUI: cadastroSwitchUIType = (state, cadastroDados, keepLoading = false,
  formMounted = false, operation = '') => (dispatch) => {
  log("cadastroAction cadastroSwitchUI", { state, cadastroDados, keepLoading, formMounted, operation });

  dispatch(cadastroSwitchUIGenerico(state, cadastroDados, keepLoading, formMounted, operation));
};


export type cadastroSwitchUIGenericoType = (state: string, cadastroDados?: Record<string,any>|null, keepLoading?: boolean,
  formMounted?: boolean, operation?: string|null) => { type: string, state: string, cadastroDados?: Record<string,any>|null,
    cadastroDadosIniciais?: Record<string,any>|null, loading?: boolean, operation?: string };

/**
 * Dispara troca de estado para alternar entre tela de criação ou edição de cadastros e tela de exibição de cadastros.
 * @param {string} state se é criação/edição de cadastro
 * @param {Record<string,any>|null|undefined} cadastroDados dados do cadastro a ser editado
 * @param {boolean|undefined} keepLoading se `true`, não altera o estado da variável `loading` se não, `loading` fica `false`
 * @param {boolean|undefined} formMounted se foi aberto o formulário do cadastro para criação ou edição
 * @param {string|null|undefined} operation string de identificação da operação
 */
export const cadastroSwitchUIGenerico: cadastroSwitchUIGenericoType = (state, cadastroDados, keepLoading, formMounted, operation) => {
  log('cadastroAction cadastroSwitchUIGenerico', { state, cadastroDados, keepLoading, formMounted, operation });

  const cadastroDadosIniciaisObject = formMounted ? { cadastroDadosIniciais: cadastroDados, } : {};
  const keepLoadingObject = keepLoading ? {} : { loading: false };
  const operationObject = operation ? { operation: operation } : {};

  return {
    type: actionTypes.CADASTRO_SWITCH_UI,
    state,
    cadastroDados,
    ...cadastroDadosIniciaisObject,
    ...keepLoadingObject,
    ...operationObject,
  };
}

/**
 * Ao concluir criação, edição ou deleção com sucesso, altera o estado para ocultar o spinner e mostrar uma notificação e 
 * volta para a tela de listagem dos cadastros. 
 * @param {*} response 
 * @param {*} filter 
 * @param {Boolean} remainInForm se deve manter a tela do formulário aberta
 */
const successResponse = (response?: Partial<responseType>, filter?: string | string[] | null, remainInForm: boolean = false) => (dispatch: dispatchTipo) => {
  log('cadastroAction successResponse', { response, filter, remainInForm })

  if (!remainInForm) {
    dispatch(cadastroSuccess(STATE_CADASTRO_LIST, null, filter))
  }

  let message = getStrings().registerSaved
  let dismiss = undefined

  if (response?.data && isString(response.data)) {
    message = response.data
    dismiss = response.dismiss
  }

  dispatch(appNotificationShow(message, actionTypes.APP_NOTIFICATION_TYPE_SUCCESS, undefined, undefined, dismiss))
}

/**
 * Ao concluir criação, edição ou deleção com falha, mostrar uma notificação. 
 * @param error 
 */
const errorResponseNotify = (error: string) => (dispatch: dispatchTipo) => {
  log('cadastroAction errorResponseNotify', { error })
  dispatch(errorUtils.requestErrorHandlerDefault(error))
}

/**
 * Ao concluir criação, edição ou deleção com falha, altera o estado para ocultar o spinner e mostrar uma notificação. 
 * @param error 
 */
const errorResponseSpinner = (error: string, state: string, cadastroDados: any, urlSaveUpdate: string) => (dispatch: dispatchTipo) => {
  log('cadastroAction errorResponseSpinner', {
    error, state, cadastroDados, urlSaveUpdate, errorKeys: Object.keys(error)
      // @ts-ignore
      .map(key => ({ key, value: error[key] }))
  })
  dispatch(cadastroFail(error, state, ((urlSaveUpdate.indexOf('/cargos') > -1)
    // @ts-ignore
    && error.response && error.response.data && (!error.response.data.length)) ? error.response.data : cadastroDados))
  dispatch(errorResponseNotify(error))
}

export type cadastroSaveType = (formData: Record<string,any>, urlSave: string, filter?: string|string[]|null
  , params?: Record<string,any>, successCallback?: () => void) => (dispatch: dispatchTipo, getState: getStateType) => void;

/**
 * Método que CRIA o cadastro no servidor conforme os dados recebidos por parâmetro. 
 * @param formData
 * @param urlSave URI da entidade
 * @param filter 
 */
export const cadastroSave: cadastroSaveType = (formData, urlSave, filter
  , params = undefined, successCallback = () => { }) => (dispatch: dispatchTipo, getState: getStateType) => {
    log("cadastroAction cadastroSave", { formData, urlSave, filter, params });

    dispatch(cadastroSubmit());

    dispatch(appSpinnerShow("cadastroSave"));

    axios().post(
      urlSave,
      formData,
      getHeaders(params)
    )
      .then((response: responseType) => {
        // Caso seja o salvamento de um cargo e um usuário foi criado, ajusta para que a mensagem fique mais tempo na tela.
        if (urlSave.endsWith('/cargos') && response.data.usuarioList.some((usuario: any) => usuario.estadoUsuarioCargo === 'CREATED')) {
          response.data = getStrings().roleNeedsToBeVerified;
          response.dismiss = 21;
        }
        // Caso seja o salvamento de uma empresa, atualiza lista de cargos para definir se exibe botão de seleção de empresa ou não no sidedrawer
        else if (urlSave.endsWith('/empresas')) {

          dispatch(empresaSelectorActions.updateQuantidadeCargos(getState().empresaSelectorReducer.quantidadeCargos + 1));
        }
        else if (urlSave === getApi(`empresas/${getEmpresaId()}`)) {
          dispatch(cadastroLoadFormData(urlSave));
        }
        dispatch(successResponse(response, filter));
        successCallback();
      })
      .catch(error =>
        dispatch(errorResponseSpinner(error, STATE_CADASTRO_FORM, formData, urlSave))
      )
      .finally(() =>
        dispatch(appSpinnerHide('cadastroSave'))
      );
}

export type cadastroUpdateType = (formData: Record<string,any>, urlUpdate: string, filter?: string | string[] | null, params?: Record<string,any>) => (dispatch: dispatchTipo, getState: getStateType) => void;

/**
 * Método que ATUALIZA o cadastro no servidor conforme os dados recebidos por parâmetro.
 * @param  formData
 * @param urlUpdate URI da entidade
 * @param  filter 
 */
export const cadastroUpdate: cadastroUpdateType = (formData, urlUpdate, filter, params) => (dispatch, getState) => {
    log("cadastroAction cadastroUpdate", { formData, urlUpdate, filter });

    dispatch(cadastroSubmit());

    dispatch(appSpinnerShow("cadastroUpdate"));

    // Ajusta vínculo com empresa
    if (formData.empresa) {
      formData.empresa = getURIFromEntity(formData.empresa);
    }

    axios().post(
      urlUpdate.replace("{?projection}", ""),
      formData,
      getHeaders(params)
    )
      .then((response: responseType) => {
        let responseNew: { data?: any, dismiss?: any, status?: number } | undefined = undefined
        // Se for cargo
        if (urlUpdate.indexOf("cargos") > -1) {
          // E for o cargo atual
          if (getURIFromEntity(response?.data) === getAppCargo()) {
            // Atualiza os dados do cargo no storage
            dispatch(empresaSelectorActions.cargoSelecionadoSuccess(response, true));
          }
          // Caso um usuário foi criado, ajusta para que a mensagem fique mais tempo na tela.
          if (response.data.usuarioList.some((usuario: any) => usuario.estadoUsuarioCargo === 'CREATED')) {
            responseNew = {
              data: getStrings().roleNeedsToBeVerified,
              dismiss: 21,
              status: response.status,
            };
          }
        }
        // Se for a empresa atual
        else if ((urlUpdate.indexOf('empresas') > -1) && (getURIFromEntity(response.data) === getAppEmpresa())) {
          // @ts-ignore
          dispatch(empresaSelectorActions.updateCargoStorage(undefined, undefined, undefined, undefined, undefined, barCodeFormatOrder(formData.parametrosEmpresa.ordemFormatoCodigoBarras)));
          // Atualiza o ramo da empresa e o preço do livre no storage
          dispatch(empresaSelectorActions.updateCargoStorage(undefined, response.data.ramoEmpresa, undefined, undefined, response.data.nomeFantasia, undefined, response.data.parametrosEmpresa.precoLivre, response.data.parametrosEmpresa.perguntaCpf));
        }
        else if (urlUpdate.indexOf('formaPagamentos') > -1) {

          if (response.data.usaPagSeguro && !response.data.gatewayPagamento) {

            const formaPagamentoId = getIdFromEntity(response.data);
            const redirectUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?id=${formaPagamentoId}`;

            dispatch(appSpinnerShow('solicitacaoAutorizacao'));

            axios().post(urlDatabase + '/pagseguro/applicationAuthorizationRequest', { redirectUrl, formaPagamentoId }, getHeaders())
              .then(response => {
                if (response.data) {
                  window.location.href = response.data
                }
              })
              .finally(() => dispatch(appSpinnerHide('solicitacaoAutorizacao')));
          }
        }

        if (urlUpdate.indexOf('quadroHorarios') > -1) {
          const quadroHorarios = response.data || null;

          dispatch({
            type: 'CADASTRO_SWITCH_UI',
            state: 'STATE_CADASTRO_FORM',
            page: 0,
            pageSize: 0,
            cadastroDados: quadroHorarios,
            cadastroDadosIniciais: quadroHorarios,
            loading: false,
            operation: 'CADASTRO_EDIT'
          });
        }
        else {
          dispatch(successResponse(responseNew, filter));
        }
      })
      .catch(error => {
        if (urlUpdate.indexOf('quadroHorarios') > -1) {
          formData = getState().cadastroReducer.cadastroDados;
        }
        dispatch(errorResponseSpinner(error, STATE_CADASTRO_FORM, formData, urlUpdate));
        dispatch(treatNotVerified(error));
      })
      .finally(() =>
        dispatch(appSpinnerHide('cadastroUpdate'))
      );
};

export type cadastroDeleteType = (cadastroADeletar: any, page: pageType, pageSize: number, urlDataBase: string, isMounted: () => boolean,
clearCadastroADeletar: () => boolean, sortProperty: string|undefined, filterProperties: string[], filter?: string | string[] | null) => 
  (dispatch: dispatchTipo, getState: getStateType) => void;

/**
 * Método executado ao confirmar a exclusão do cadastro.
 * Envia ao servidor o comando de exclusão.
 * @param cadastroADeletar 
 * @param page número da página de resultados
 * @param pageSize tamanho da página de resultados (quantidade de registros)
 * @param urlDataBase endereço da entidade
 * @param isMounted se a tela que originou a requisição ainda está visível
 * @param clearCadastroADeletar limpa a variável da memória
 * @param sortProperty propriedade da entidade a ser considerada na ordenação
 * @param filterProperties propriedade da entidade a serem filtradas
 * @param filter valor do filtro
 */
export const cadastroDelete: cadastroDeleteType = (cadastroADeletar, page, pageSize, urlDataBase, isMounted, clearCadastroADeletar, sortProperty,
  filterProperties, filter) => (dispatch, getState) => {
  log('cadastroAction cadastroDelete', { cadastroADeletar, page, pageSize, urlDataBase, isMounted, clearCadastroADeletar, sortProperty, filterProperties, filter })

  if (cadastroADeletar == null) {
    return
  }

  dispatch(appSpinnerShow('cadastroDelete'))

  axios().delete(
    getURIFromEntity(cadastroADeletar),
    getHeaders()
  )
    .then(response => {
      // Caso seja o delete de uma empresa, atualiza lista de cargos para definir se exibe botão de seleção de empresa ou não no sidedrawer
      if (getURIFromEntity(cadastroADeletar).includes('/empresas')) {

        dispatch(empresaSelectorActions.updateQuantidadeCargos(getState().empresaSelectorReducer.quantidadeCargos - 1))
      }
      dispatch(appNotificationShow(getStrings().registerRemoved, actionTypes.APP_NOTIFICATION_TYPE_SUCCESS))
      if ((page.number * page.size) === (page.totalElements - 1)) {
        page.number -= 1
      }
      dispatch(cadastroLoadFromServer(page, pageSize, urlDataBase, isMounted, sortProperty, filterProperties, filter, undefined))
    })
    .catch(error =>
      dispatch(errorResponseNotify(error))
    )
    .finally(() => {
      clearCadastroADeletar()
      dispatch(appSpinnerHide('cadastroDelete'))
    })
}

export type cadastroLoadFromServerType = (page: pageType|number, pageSize: number, urlDataBase: string, isMounted: () => boolean,
  sortProperty: string|undefined, filterProperties: string[], filter?: string | string[] | null, objetoCadastroLast?: string) => 
    (dispatch: dispatchTipo, getState: getStateType) => void;

/**
 * Método que carrega os dados do servidor,
 * conforme a página e o número de registros por página que devem ser exibidos.
 * @param page número da página de resultados
 * @param pageSize tamanho da página de resultados (quantidade de registros)
 * @param urlDataBase endereço da entidade
 * @param isMounted se a tela que originou a requisição ainda está visível
 * @param sortProperty propriedade da entidade a ser considerada na ordenação
 * @param filterProperties propriedades da entidade a serem filtradas
 * @param filter valor do filtro
 * @param objetoCadastroLast tipo de entidade dos dados requisitados
 */
export const cadastroLoadFromServer: cadastroLoadFromServerType = (page, pageSize, urlDataBase, isMounted, sortProperty,
  filterProperties, filter, objetoCadastroLast) => (dispatch, getState) => {
  log("cadastroAction cadastroLoadFromServer", { page, pageSize, urlDataBase, isMounted, sortProperty, filterProperties, filter, objetoCadastroLast });
  // Recupera o número da página que deve ser exibido
  var pageNumber = page.hasOwnProperty("number") ? (page as pageType).number : page;

  pageSize = getPageSize(pageSize);

  var params: { page: number | pageType, size: number, sort?: string, search?: string } = { page: pageNumber, size: pageSize, sort: sortProperty };

  // A busca por pagamentos de venda é feita de maneira mais específica
  if (objetoCadastroLast === "pagamentoVenda") {

    // Se for empresa do ramo Painel, busca pelo nome exato da venda.
    let wildCard = isEmpresaPainel(getState().empresaSelectorReducer.ramoEmpresa) ? "" : "*";
    // @ts-ignore
    (filterProperties || []).forEach((filterProperty, i) => params[filterProperty] = wildCard + encodeURIComponent(filter[i]) + wildCard);
  }
  // A busca por cadastros é feita de maneira mais genérica
  else if ((filter !== undefined) && (filter !== null) && (filter !== "")) {
    // Se for lista de empresas, elas são lidas a partir dos cargos do usuário.
    if (objetoCadastroLast === "empresa") {
      // Acessa a empresa através do cargo
      filterProperties = filterProperties.map(filterProperty => `empresaº${filterProperty}`);
    }
    params.search = buildSpecQuery(filterProperties, filter, SPEC_QUERY_CONTAINS);
  }

  dispatch(appSpinnerShow("cadastroLoadFromServer"))

  axios().get(
    urlDataBase,
    getHeaders(params)
  )
    .then(cadastros => {

      if (isMounted()) {

        // Ao controlar o Pageable manualmente no servidor, não vem _embedded quando não tem dados
        let lista = []
        if ("_embedded" in cadastros.data) {
          lista = cadastros.data._embedded.resources;
        }
        dispatch(cadastroUpdatePage(cadastros.data.page, pageSize));
        dispatch(cadastroLoad(cadastros.data.page, pageSize, lista, cadastros.data._links, {}, filter, undefined, objetoCadastroLast));
      }
    })
    .catch(error =>
      dispatch(errorResponseNotify(error))
    )
    .finally(() => {
      hideSpinner(SPINNER_FILTRO_ORIGEM_VENDA_ID);
      hideSpinner(SPINNER_FILTRO_VENDA_ID);
      if (isEdge) {
        setTimeout(() => {
          hideSpinner(SPINNER_INPUT_ID);
          dispatch(appSpinnerHide("cadastroLoadFromServer"));
        }, 200)
      } else {
        hideSpinner(SPINNER_INPUT_ID);
        dispatch(appSpinnerHide("cadastroLoadFromServer"));
      }
    });
}

export type cadastroNavigateType = (navUri: string, pageSize: number) => (dispatch: dispatchTipo, getState: getStateType) => void;

/**
 * Método executado ao clicar nos botões para trocar a página de cadastros sendo exibida, conforme o parâmetro recebido.
 * @param navUri endereço da página para consultar no servidor
 * @param pageSize tamanho da página de resultados (quantidade de registros)
 */
export const cadastroNavigate: cadastroNavigateType = (navUri, pageSize) => (dispatch, getState) => {
  log("cadastroAction cadastroNavigate", { navUri, pageSize });

  dispatch(appSpinnerShow("cadastroNavigate"));

  axios().get(
    navUri,
    getHeaders()
  )
    .then(cadastros => {
      log("cadastroAction cadastroNavigate", { cadastros });
      dispatch(cadastroUpdatePage(cadastros.data.page, pageSize));
      dispatch(cadastroLoad(cadastros.data.page, pageSize, cadastros.data._embedded?.resources || [], cadastros.data._links, undefined, undefined, undefined, getState().cadastroReducer.objetoCadastroLast));
    })
    .catch(error =>
      dispatch(errorUtils.verifyForbiddenAccess(error))
    )
    .finally(() => {
      hideSpinner(SPINNER_INPUT_ID);
      dispatch(appSpinnerHide("cadastroNavigate"));
    });
}

export type cadastroSaveUpdateType = (formData: Record<string,any>, updateUrl: string|null, urlDataBase: string,
  filter: string|string[]|null, params: Record<string,any>) => (dispatch: dispatchTipo) => void;

/**
 * Método executado ao clicar no botão "Gravar" do formulário. 
 */
export const cadastroSaveUpdate: cadastroSaveUpdateType = (formData, updateUrl, urlDataBase, filter, params) =>
  (dispatch) => {
  log("cadastroAction cadastroSaveUpdate", { formData, updateUrl, urlDataBase, filter, params });

  dispatch(cadastroUpdate(formData, updateUrl != null
    ? updateUrl // Se está definida a URL para updade, realiza uma atualização
    : urlDataBase // Senão é realizado um novo cadastro
    , filter, params)
  );
};

/**
 * Método que carrega os Tipos de Contrato.
 */
export const cadastroLoadEscoposExibicaoItemVenda = (onSuccess: (data?: any) => void, onComplete: () => void) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadEscoposExibicaoItemVenda', { onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadEscoposExibicaoItemVenda'))

  axios().get(
    urlDatabase + '/cargos/escoposExibicaoItemVenda'
  )
    .then(response =>
      onSuccess(response.data)
    )
    .catch(error =>
      dispatch(errorUtils.verifyForbiddenAccess(error))
    )
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadEscoposExibicaoItemVenda'))
    })
}

/**
 * Método que carrega as Funções do Cargo.
 */
export const cadastroLoadCargoFuncoes = (onSuccess: (data?: any) => void, onComplete: () => void) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadCargoFuncoes', { onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadCargoFuncoes'))

  axios().get(
    urlDatabase + '/cargos/cargoFuncoes'
  )
    .then(response =>
      onSuccess(response.data)
    )
    .catch(error =>
      dispatch(errorUtils.verifyForbiddenAccess(error))
    )
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadCargoFuncoes'))
    })
}

/**
 * Método que carrega as Formas de Pagamento.
 */
export const cadastroLoadFormasPagamento = (onSuccess: (data?: any) => void, onComplete: () => void) => (dispatch: dispatchTipo, getState: getStateType) => {

  log('cadastroAction cadastroLoadFormasPagamento', { onSuccess, onComplete })

  let origemVendaURI = null
  // Se for um usuário sem vínculo com empresa
  if (!getState().empresaSelectorReducer.cargo) {
    // Busca o URI da origem de venda para fazer o papel de empresa
    origemVendaURI = getURIFromEntity((getState().controleVendaReducer.origemVendaList || []).find(() => true) || {})
  }

  dispatch(appSpinnerShow('cadastroLoadFormasPagamento'))

  axios().get(
    urlDatabase + '/formaPagamentos',
    getHeaders({
      all: true,
      ...(origemVendaURI ? { origemVenda: origemVendaURI } : {})
    })
  )
    .then(response => onSuccess(response.data.content))
    .catch(error => dispatch(errorUtils.verifyForbiddenAccess(error)))
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadFormasPagamento'))
    })
}

/**
 * Método que carrega os países.
 */
export const cadastroLoadFormatosCodigoImpresso = (onSuccess: (data?: any) => void, onComplete: () => void) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadFormatosCodigoImpresso', { onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadFormatosCodigoImpresso'))

  axios().get(
    urlDatabase + '/formatosCodigoImpresso',
    getHeaders()
  )
    .then(response =>
      onSuccess(response.data)
    )
    .catch(error =>
      dispatch(errorUtils.verifyForbiddenAccess(error))
    )
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadFormatosCodigoImpresso'))
    })
}

/**
 * Método que carrega as finalidades do produto.
 */
export const cadastroLoadFinalidadesProduto = (onSuccess: (data?: any) => void, onComplete: () => void) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadFinalidadesProduto', { onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadFinalidadesProduto'))

  axios().get(
    urlDatabase + '/finalidadesProduto',
    getHeaders()
  )
    .then(response =>
      onSuccess(response.data)
    )
    .catch(error =>
      dispatch(errorUtils.verifyForbiddenAccess(error))
    )
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadFinalidadesProduto'))
    })
}

/**
 * Método que carrega os grupos cadastrados do banco e os seta nas opções do Multi Select do Grupo.
 */
export const cadastroLoadGrupos = (urlDataBase: string, onSuccess: (data?: any) => void, onComplete: () => void) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadGrupos', { urlDataBase, onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadGrupos'))

  axios().get(
    urlDataBase.replace('/produtos', '/grupoProdutos'),
    getHeaders({ all: true })
  )
    .then(response =>
      onSuccess(response.data.content)
    )
    .catch(error =>
      dispatch(errorUtils.verifyForbiddenAccess(error))
    )
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadGrupos'))
    })
}

/**
 * Método que carrega as impressoras cadastradas do banco e as seta nas opções do Multi Select da Impressora.
 */
export const cadastroLoadImpressoras = (onSuccess: (data?: any) => void, onComplete: () => void) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadImpressoras', { onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadImpressoras'))

  axios().get(
    urlDatabase + '/impressoras',
    getHeaders({ all: true })
  )
    .then(response =>
      onSuccess(response.data.content)
    )
    .catch(error =>
      dispatch(errorUtils.verifyForbiddenAccess(error))
    )
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadImpressoras'))
    })
}

/**
 * Método que carrega os Ramos da Empresa.
 */
export const cadastroLoadNiveisUsuarioSemEmpresa = (onSuccess: (data?: any) => void, onComplete: () => void) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadNiveisUsuarioSemEmpresa', { onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadNiveisUsuarioSemEmpresa'))

  axios().get(
    urlDatabase + '/empresas/niveisUsuarioSemEmpresa',
    getHeaders()
  )
    .then(response =>
      onSuccess(response.data)
    )
    .catch(error =>
      dispatch(errorUtils.verifyForbiddenAccess(error))
    )
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadNiveisUsuarioSemEmpresa'))
    })
}

/**
 * Método que carrega os países.
 */
export const cadastroLoadPaises = (onSuccess: (content?: any) => void, onComplete: () => void = () => { }) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadPaises', { onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadPaises'))

  axios().get(
    urlDatabase + '/paises',
    getHeaders()
  )
    .then(response =>
      onSuccess(response.data.content)
    )
    .catch(error =>
      dispatch(errorUtils.verifyForbiddenAccess(error))
    )
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadPaises'))
    })
}

/**
 * Método que carrega os bairros.
 */
export const cadastroLoadBairros = (onSuccess: (content?: any) => void, onComplete: () => void = () => { }) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadBairros', { onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadBairros'))

  axios().get(
    urlDatabase + '/bairros/findAll',
    getHeaders()
  )
    .then(response =>
      onSuccess(response.data.content)
    )
    .catch(error =>
      dispatch(errorUtils.verifyForbiddenAccess(error))
    )
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadBairros'))
    })
}

/**
 * Método que carrega os países.
 */
export const cadastroLoadProdutosPesados = (onSuccess: (content?: any) => void, onComplete: () => void = () => { }) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadProdutosPesados', { onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadProdutosPesados'))

  axios().get(
    urlDatabase + '/produtos',
    getHeaders({ all: true, search: buildSpecQuery(['quantidadePesada'], [true]) })
  )
    .then(response =>
      onSuccess(response.data.content)
    )
    .catch(error =>
      dispatch(errorUtils.verifyForbiddenAccess(error))
    )
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadProdutosPesados'))
    })
}

/**
 * Método que carrega os Ramos da Empresa.
 */
export const cadastroLoadRamosEmpresa = (onSuccess: (content?: any) => void, onComplete: () => void) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadRamosEmpresa', { onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadRamosEmpresa'))

  axios().get(
    urlDatabase + '/empresas/ramos'
  )
    .then(response =>
      onSuccess(response.data)
    )
    .catch(error =>
      dispatch(errorUtils.verifyForbiddenAccess(error))
    )
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadRamosEmpresa'))
    })
}

/**
 * Método que carrega os Tipos de Contrato.
 */
export const cadastroLoadTiposContrato = (onSuccess: (content?: any) => void, onComplete: () => void = () => { }) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadTiposContrato', { onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadTiposContrato'))

  axios().get(
    urlDatabase + '/tiposContrato'
  )
    .then(response =>
      onSuccess(response.data.content)
    )
    .catch(error =>
      dispatch(errorUtils.verifyForbiddenAccess(error))
    )
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadTiposContrato'))
    })
}

/**
 * Método que carrega os Ramos da Empresa.
 */
export const cadastroLoadTiposImpressora = (onSuccess: (content?: any) => void, onComplete: () => void) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadTiposImpressora', { onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadTiposImpressora'))

  axios().get(
    urlDatabase + '/impressoras/tipos'
  )
    .then(response =>
      onSuccess(response.data)
    )
    .catch(error =>
      dispatch(errorUtils.verifyForbiddenAccess(error))
    )
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadTiposImpressora'))
    })
}

/**
 * Método que carrega os tipos cadastrados do banco e os seta nas opções do Multi Select do Tipo.
 */
export const cadastroLoadTiposProduto = (urlDataBase: string, onSuccess: (content?: any) => void, onComplete: () => void) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadTiposProduto', { urlDataBase, onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadTiposProduto'))

  axios().get(
    urlDataBase ? urlDataBase.replace('/produtos', '/tipoProdutos') : (urlDatabase + '/tipoProdutos'),
    getHeaders({ all: true })
  )
    .then(response =>
      onSuccess(response.data.content)
    )
    .catch(error =>
      dispatch(errorUtils.verifyForbiddenAccess(error))
    )
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadTiposProduto'))
    })
}

/**
 * Método que busca a lista de Usuários para ser apresentada no campo. 
 * @param input Texto digitado no campo para fazer a busca
 */
export const cadastroLoadUsuarioList = (input?: string) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadUsuarioList', { input })
  if (!input) {
    return Promise.resolve({ options: [] })
  }

  return axios().get(
    urlDatabase + '/usuarios/searchEnabledByEmailWithMaxResults',
    getHeaders({ email: input, maxResults: 5 })
  )
    .then(response => {
      const options = cadastroBuildOptionsFromUsuarios(true, response.data.content)
      return { options }
    })
    .catch(error => {
      dispatch(errorUtils.verifyForbiddenAccess(error))
    })
}

/**
 * 
 * @param urlDataBase 
 * @param onSuccess 
 * @param onComplete 
 */
export const cadastroLoadTamanhoProdutos = (urlDataBase: string, onSuccess: (content?: any) => void, onComplete: () => void) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadTamanhoProdutos', { urlDataBase, onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadTamanhoProdutos'))

  return axios().get(
    urlDatabase + '/tamanhos',
    getHeaders({ all: true }),
  )
    .then(response => {
      onSuccess(response.data.content)
    })
    .catch(error => {
      dispatch(errorUtils.verifyForbiddenAccess(error))
    })
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadTamanhoProdutos'))
    })
}

/**
 * 
 * @param urlDataBase 
 * @param onSuccess 
 * @param onComplete 
 */
export const cadastroLoadPrecoProdutoCombinado = (urlDataBase: string, onSuccess: (content?: any) => void, onComplete: () => void) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroLoadPrecoProdutoCombinado', { urlDataBase, onSuccess, onComplete })

  dispatch(appSpinnerShow('cadastroLoadPrecoProdutoCombinado'))

  return axios().get(
    urlDatabase + '/precoProdutoCombinado',
    getHeaders({ all: true }),
  )
    .then(response => {
      onSuccess(response.data)
    })
    .catch(error => {
      dispatch(errorUtils.verifyForbiddenAccess(error))
    })
    .finally(() => {
      onComplete()
      dispatch(appSpinnerHide('cadastroLoadPrecoProdutoCombinado'))
    })
}

export type cadastroUpdateFormDataType = (cadastroDados: Record<string, unknown>, cadastroDadosIniciais?: Record<string, unknown>) =>
  {type: string, cadastroDados: Record<string, unknown>, cadastroDadosIniciais?: Record<string, unknown>};

/**
 * Guarda os dados de formulário alterados pelo usuário para popular a tela
 * quando há erro no envio ou quando o idioma é alterado.
 * @param cadastroDados objeto do cadastro contendo dados alterados
 */
export const cadastroUpdateFormData: cadastroUpdateFormDataType = (cadastroDados, cadastroDadosIniciais) => {
  log("cadastroAction cadastroUpdateFormData", { cadastroDados, cadastroDadosIniciais });

  return {
    type: actionTypes.CADASTRO_SWITCH_UI,
    cadastroDados: cadastroDados,
    ...(cadastroDadosIniciais ? { cadastroDadosIniciais: cadastroDadosIniciais } : {}),
  };
}

export type cadastroUpdatePageType = (page: pageType, pageSize?: number) => { type: string, page: pageType, pageSize?: number };

/**
 * Atualiza os dados atuais sobre o número e o tamanho da página.
 * @param page número da página
 * @param pageSize tamanho da página (quantidade de registros)
 */
export const cadastroUpdatePage: cadastroUpdatePageType = (page, pageSize) => {
  log("cadastroAction cadastroUpdatePage", { page, pageSize });

  return {
    type: actionTypes.CADASTRO_LOAD,
    page: page,
    pageSize: pageSize,
  };
}

export type cadastroLoadFormDataType = (urlCadastro: string|null, callback?: (data?: any) => void) => (dispatch: dispatchTipo) => void;

/**
 * Carrega a tela de formulário de cadastro com os dados completos do mesmo.
 */
export const cadastroLoadFormData: cadastroLoadFormDataType = (urlCadastro, callback) => (dispatch) => {
  log("cadastroAction cadastroLoadFormData", { urlCadastro });

  dispatch(appSpinnerShow("cadastroLoadFormData"));

  axios().get(
    urlCadastro as string,
    getHeaders()
  )
    .then(response => {
      dispatch(cadastroSwitchUI(STATE_CADASTRO_FORM, response.data, true, true, actionTypes.CADASTRO_EDIT));
      dispatch(cadastroEmpresaActions.cadastroEmpresaUpdateFormData(actionTypes.CADASTRO_EMPRESA_EDIT, response.data));
      callback && callback(response.data);
    })
    .catch(error => {
      dispatch(errorResponseNotify(error));
      dispatch(cadastroSwitchUI(STATE_CADASTRO_LIST));
    })
    .finally(() => {
      dispatch(appSpinnerHide("cadastroLoadFormData"));
      dispatch(cadastroUpdateLoading({ form: false }));
    });
};

export type cadastroShowSpinnerType = (newRegister: boolean) => (dispatch: dispatchTipo) => void;

/**
 * Altera o estado de carregamento.
 * @param newRegister se é um novo cadastro ou edição de cadastro 
 */
export const cadastroShowSpinner: cadastroShowSpinnerType = (newRegister) => (dispatch) => {
  log("cadastroAction cadastroShowSpinner", { newRegister });

  // Inicializa o estado de carregamento
  dispatch(cadastroInitLoading(newRegister));
};

/**
 * Define o estado de carregamento.
 * @param loading dados de carregamento
 */
const cadastroSetLoading = (loading: { form: boolean }) => {
  log('cadastroAction cadastroSetLoading', { loading })
  return {
    type: actionTypes.CADASTRO_EDIT,
    loading: loading
  }
}

/**
 * Inicializa o estado de carregamento.
 * @param newRegister se é um novo cadastro ou edição de cadastro 
 */
const cadastroInitLoading = (newRegister: boolean) => (dispatch: dispatchTipo) => {
  log('cadastroAction cadastroInitLoading', { newRegister })
  dispatch(cadastroSetLoading({ form: !newRegister }))
}

/**
 * Atualiza o estado de carregamento.
 * @param steps variáveis referentes ao estado de carregamento de cada etapa
 */
export const cadastroUpdateLoading = (steps: Record<string, unknown>) => {
  log('cadastroAction cadastroUpdateLoading', { steps })

  return (dispatch: dispatchTipo, getState: getStateType) => {

    dispatch(cadastroSetLoading({
      ...getState().cadastroReducer.loading,
      ...steps
    }))
  }
}

export type cadastroSetChangedType = (changed: boolean) => {type: string, cadastroChanged: boolean };

/**
 * Define o estado de atributos alterados. Se `false`, a tela de formulário de cadastro não teve nenhuma atributo alterado ainda.
 * Se `true`, algum atributo já foi alterado, e o usuário deve confirmar se deseja sair da tela sem salvar os dados alterados.
 * @param {boolean} changed se algum atributo foi alterado
 */
export const cadastroSetChanged: cadastroSetChangedType = (changed) => {
  log("cadastroAction cadastroSetChanged", { changed })
  return {
    type: actionTypes.CADASTRO_EDIT,
    cadastroChanged: changed,
  };
};

export type cadastroSetFilledRequiredType = (filledRequired: boolean) => {};

/**
 * Define se os campos required do cadastro atual foram todos preenchidos ou não.
 * @param {boolean} filledRequired 
 */
export const cadastroSetFilledRequired: cadastroSetFilledRequiredType = (filledRequired) => {
  log("cadastroAction cadastroSetFilledRequired", { filledRequired });
  return {
    type: actionTypes.CADASTRO_EDIT,
    cadastroFilledRequired: filledRequired,
  };
};

/**
 * Se, ao tentar editar um cargo, receber erro referente a cargo não estar verificado, volta para a tela de cadastros.
 * @param error 
 */
export const treatNotVerified = (error: { response?: { data: string } }) => (dispatch: dispatchTipo) => {
  log('cadastroAction treatNotVerified', { error })

  if (error.response && (error.response.data === 'roleNotVerified')) {
    dispatch(cadastroSwitchUI(STATE_CADASTRO_LIST))
  }
}

/**
 * Retorna a lista de empresas vinculadas ao usuário logado.
 */
export const getEmpresasFromUsuario = (onSuccess: (content?: any) => void) => (dispatch: dispatchTipo) => {
  log('cadastroAction getEmpresasFromUsuario', { onSuccess })

  dispatch(appSpinnerShow('cadastroLoadEmpresasFromUsuario'))

  axios().get(
    urlDatabase + '/usuarios/getEmpresasFromUsuarioLogado',
    getHeaders()
  )
    .then(response => {
      onSuccess(response.data.content)
    })
    .catch(error =>
      dispatch(errorResponseNotify(error))
    )
    .finally(() =>
      dispatch(appSpinnerHide('cadastroLoadEmpresasFromUsuario'))
    )
}

/**
 * Mostra a tela de configurações.
 */
export const showSetup = () => (dispatch: dispatchTipo) => {
  log('cadastroAction showSetup')

  dispatch({
    type: actionTypes.CADASTRO_SWITCH_UI,
    state: STATE_CADASTRO_SETUP
  })
}

export const cadastroSaveEmpresa = (onSave: (formData: Record<string, unknown>, params: Record<string, unknown>) => void, formData: Record<string, unknown>, params: Record<string, unknown>) => (dispatch: dispatchTipo, getState: getStateType) => {
  log('cadastroAction cadastroSaveEmpresa', { onSave, formData })

  if ((getState().idiomaReducer.locale !== 'pt-BR') || validaCNPJ(formData.cnpj)) {
    onSave(formData, params)
  }
  else {
    dispatch(appNotificationShow(getStrings().invalidFederalTaxNumber, actionTypes.APP_NOTIFICATION_TYPE_WARNING, getStrings().warning))
  }
}

export type showPrintType = (clearConfig?: boolean, registerList?: string[], printExternalEnabled?: boolean, printInternalEnabled?: boolean) => (dispatch: dispatchTipo) => void;

/**
 * Mostra a tela de configurações.
 * @param clearConfig se deve limpar o reducer ao exibir a tela
 * @param registerList registros para geração de seus códigos impressos
 * @param printExternalEnabled se deve permitir a impressão de códigos para uso externo
 * @param printInternalEnabled se deve permitir a impressão de códigos para uso interno
 */
export const showPrint: showPrintType = (clearConfig = false, registerList, printExternalEnabled = false, printInternalEnabled = false) => (dispatch: dispatchTipo) => {
  log("cadastroAction showPrint", { clearConfig, registerList, printExternalEnabled, printInternalEnabled })

  dispatch(appSpinnerShow("cadastroAction showPrint"))

  import("../../components/cadastros/print/EmbeddedFont").then(async modulo => {

    const embeddedFont = modulo.embeddedFont;

    const { default: TextToSVG } = await import("../../assets/libs/text-to-svg");

    // Carrega a fonte usada pelo app.
    // @ts-ignore
    TextToSVG.load(embeddedFont, (err, textToSVG) => {
      dispatch(appSpinnerHide("cadastroAction showPrint"));
      // Se houve erro ao carregar a fonte, notifica o usuário e não permite gerar os códigos.
      if (err) {
        dispatch(errorResponseNotify(err));
        return;
      }
      // Armazena a biblioteca carregada com a fonte para não ser necessário carregar ela de novo
      dispatch({
        type: actionTypes.CADASTRO_PRINT_TEXT_TO_SVG,
        textToSVG
      })
      if (clearConfig) {
        dispatch(cadastroPrintActions.clearConfig(printExternalEnabled, printInternalEnabled))
      }
      if (registerList) {
        dispatch(cadastroPrintActions.setValue(registerList, "registerList"))
      }
      dispatch({
        type: actionTypes.CADASTRO_SWITCH_UI,
        state: STATE_CADASTRO_PRINT_SETUP
      })
    });
  })
};

/**
 * Marca a origem como não sendo usada.
 */
export const clearUsuarioAtivo = () => (dispatch: dispatchTipo, getState: getStateType) => {
  log('cadastroAction clearUsuarioAtivo')

  dispatch(appSpinnerShow('cadastroAction clearUsuarioAtivo'))

  axios().post(
    getURIFromEntity(getState().cadastroReducer.cadastroDados) + '/clearUsuarioAtivo',
    null,
    getHeaders()
  )
    .then(() => dispatch(appNotificationShow(getStrings().saleSourceClearedForUse, actionTypes.APP_NOTIFICATION_TYPE_SUCCESS)))
    // Se der erro, ignora.
    .catch(error => errorResponseNotify(error))
    .finally(() => dispatch(appSpinnerHide('cadastroAction clearUsuarioAtivo')))
}

/**
 * Busca informações do endereço a partir do CEP e atualiza os campos da tela.
 * @param zipCodeRaw CEP sem processamento
 * @param {Function} callBack o que fazer com os dados recebidos
 */
export const readZipCode = (zipCodeRaw: string, callBack: (zipCodeRaw: string, uf: string, name: string, bairro: string, endereco: string) => void = () => { }) => (dispatch: dispatchTipo, getState: getStateType) => {
  log('cadastroAction readZipCode', { zipCodeRaw, callBack })
  // Define a quantidade de dígitos esperada de acordo com a internacionalização
  let currentLocale = getState().idiomaReducer.locale
  let length
  let callBackI18n: (response: any) => void
  let api
  switch (currentLocale) {
    case locale.en_us:
      api = (zipCode: string) => `https://api.zippopotam.us/us/${zipCode}`
      callBackI18n = (response: any) => {
        let place: Record<string, unknown> = (response.places || [])[0] || {}
        // @ts-ignore
        callBack(zipCodeRaw, place['state abbreviation'], place['place name'])
      }
      length = 5
      break
    case locale.pt_br:
    default:
      api = (zipCode: string) => `https://viacep.com.br/ws/${zipCode}/json/`
      callBackI18n = (response: any) => callBack(zipCodeRaw, response.uf, response.localidade, response.bairro, response.logradouro)
      length = 8
  }
  // Faz a pré validação
  if ((!zipCodeRaw) || (zipCodeRaw.length < length)) {
    return
  }
  // Itera a string para extrair os dígitos
  let zipCode = ''
  let char
  forEach(zipCodeRaw.length, (_: any, index: number) => {

    char = zipCodeRaw.charAt(index)
    // Se for um dígito
    if (char.match('[0-9]')) {
      // Acumula
      zipCode += char
    }
  })
  // Verifica se acumulou a quantidade necessária de dígitos
  if (zipCode.length < length) {
    return
  }
  // Busca os dados do CEP
  zipCode = zipCode.slice(0, length)
  dispatch(appSpinnerShow('cadastroLoadPaises'))
  axios().get(api(zipCode), { timeout: 5000 })
    .then(response => {
      // Se ocorreu um erro, por exemplo por enviar um CEP inválido, faz mais nada.
      if (((response || {}).data || {}).erro) {
        return
      }
      callBackI18n(response.data || {})
    })
    .catch(() => { })
    .finally(() => dispatch(appSpinnerHide('cadastroLoadPaises')))
}

export const buscaCepPorEndereco = (uf: string, municipio: string, endereco: string, callBack: (data: any) => void) => (dispatch: dispatchTipo) => {

  const api = `https://viacep.com.br/ws/${uf}/${municipio}/${endereco}/json/`

  dispatch(appSpinnerShow('cadastroLoadPaises'))
  axios().get(api, { timeout: 5000 })
    .then((response?: { data: any }) => {

      // Se ocorreu um erro, por exemplo por enviar um CEP inválido, faz mais nada.
      if (response?.data?.erro) {
        return
      }
      callBack(response?.data || {})
    })
    .catch(() => { })
    .finally(() => dispatch(appSpinnerHide('cadastroLoadPaises')))
}

/**
 * Atualiza os campos de endereço a partir de consulta do CEP.
 * @param state 
 * @param city 
 * @param neighborhood 
 * @param street 
 */
export const updateFromZipCode = (zipCode: string, state: string, city: string, neighborhood: string, street: string) => (dispatch: dispatchTipo, getState: getStateType) => {
  log('cadastroAction updateFromZipCode', { zipCode, state, city, neighborhood, street })

  dispatch(cadastroUpdateFormData(Object.assign({}, getState().cadastroReducer.cadastroDados || {}, {
    enderecoEmpresa: Object.assign({}, (getState().cadastroReducer.cadastroDados || {}).enderecoEmpresa, {
      cep: zipCode,
      uf: state,
      municipio: city,
      bairro: neighborhood,
      endereco: street
    })
  })))
}

/**
 * Valida as alterações na lista de usuários vinculados a um cargo para,
 * em seguida, persistir essas alterações.
 * @param email novo email digitado pelo usuário
 * @param isValid se o email é válido
 * @param onAdded a ser executado quando email for inserido no Cargo ou já tiver sido inserido
 * @param remove se é para remover o usuário ao invés de adicionar
 */
export const handleRoleUserListChange = (email: string, isValid: boolean, onAdded: () => void, remove: boolean = false) => (dispatch: dispatchTipo, getState: getStateType) => {
  log('cadastroAction handleRoleUserListChange', { email, isValid, onAdded, remove })

  // Método só pode ser executado em um cadastro persistido
  if (getState().cadastroReducer.operation !== actionTypes.CADASTRO_EDIT) {
    return
  }
  let cargo = getState().cadastroReducer.cadastroDadosIniciais
  // Se for para remover um usuário
  if (remove) {
    // Monta entidade com os dados iniciais menos o usuário informado
    cargo = Object.assign({}, cargo, {
      usuarioList: (cargo.usuarioList || []).filter((usuario: any) => usuario.login.email !== email)
    })
    // Atualiza a lista de usuários do colaborador
    dispatch(updateRoleUserList(cargo))
  }
  // Verifica se há um email digitado e se ele é válido
  else if (email && isValid) {
    // Verifica se o email já não está na lista
    if (cargo.usuarioList.findIndex((usuario: any) => usuario.login.email === email) < 0) {
      // Monta entidade com os dados iniciais e o email recém informado
      cargo = Object.assign({}, cargo, {
        usuarioList: (cargo.usuarioList || []).concat({
          login: {
            email
          }
        })
      })
      // Atualiza a lista de usuários do colaborador
      dispatch(updateRoleUserList(cargo))
      // Avisa que foi adicionado
      if (onAdded) {
        onAdded()
      }
    }
    // Avisa que já estava adicionado
    if (onAdded) {
      onAdded()
    }
  }
}

/**
 * Persiste um Cargo com os dados inicialmente carregados para o *reducer*,
 * exceto pela lista de usuários, que é atualizada com o email inserido ou removido.
 * Tenta criar um vínculo entre os Cargo e os usuários ainda não vinculados e atualiza a tela,
 * sem alterar qualquer outro campo do Cargo que já tenha sido alterado.
 * @param cargo entidade
 */
export const updateRoleUserList = (cargo: Record<string, unknown>) => (dispatch: dispatchTipo, getState: getStateType) => {
  log('cadastroAction updateRoleUserList', { cargo })

  dispatch(cadastroSubmit())

  dispatch(appSpinnerShow('updateRoleUserList'))

  // Ajusta vínculo com empresa
  if (cargo.empresa) {
    cargo.empresa = getURIFromEntity(cargo.empresa)
  }

  let state = () => getState().cadastroReducer
  let uri = getURIFromEntity(cargo)

  axios().post(
    uri,
    cargo,
    getHeaders()
  )
    .then(response => {
      let responseNew: { data?: any, dismiss: number, status: number } | undefined
      // Caso um usuário foi criado, ajusta para que a mensagem fique mais tempo na tela.
      if (response.data.usuarioList.some((usuario: any) => usuario.estadoUsuarioCargo === 'CREATED')) {
        responseNew = {
          data: getStrings().roleNeedsToBeVerified,
          dismiss: 21,
          status: response.status
        }
      }
      // Exibe mensagem de sucesso e, se for o caso, mensagem mais específica.
      dispatch(successResponse(responseNew, null, true))
      // Atualiza no reducer somente os dados relativos aos vínculos com usuários
      let cadastroDados = state().cadastroDados
      let cadastroDadosIniciais = state().cadastroDadosIniciais
      dispatch({
        type: actionTypes.CADASTRO_SUCCESS,
        cadastroDados: Object.assign({}, cadastroDados, {
          usuarioList: response.data.usuarioList
        }),
        cadastroDadosIniciais: Object.assign({}, cadastroDadosIniciais, {
          usuarioList: response.data.usuarioList
        })
      })
    })
    .catch(error => {
      dispatch(errorResponseSpinner(error, STATE_CADASTRO_FORM, state().cadastroDados, uri))
      dispatch(treatNotVerified(error))
    })
    .finally(() =>
      dispatch(appSpinnerHide('updateRoleUserList'))
    )
}

export type updateBarraAcoesTableType = (payload: {cadastroFormRequired: boolean}) => (dispatch: dispatchTipo) => {type: string, cadastroFormRequired: boolean};

export const updateBarraAcoesTable: updateBarraAcoesTableType = (payload) => {
  return function (dispatch) {
    return dispatch({
      type: actionTypes.CADASTRO_UPDATE_BARRA_ACOES_TABLE,
      ...payload,
    });
  };
}

export type cleanFilterType = () => (dispatch: dispatchTipo) => void;

/**
 * Limpa o filtro da busca.
 */
export const cleanFilter: cleanFilterType = () => (dispatch: dispatchTipo) => {
  log('cadastroAction cleanFilter')

  dispatch({
    type: actionTypes.CADASTRO_CLEAN_FILTER,
    filter: '',
  })
}

export type resetPageNumberType = () => (dispatch: dispatchTipo, getState: getStateType) => void;

/**
 * Limpa o filtro da busca.
 */
 export const resetPageNumber: resetPageNumberType = () => (dispatch: dispatchTipo, getState: getStateType) => {
  log('cadastroAction resetPageNumber')

  dispatch({
    type: actionTypes.CADASTRO_RESET_PAGE_NUMBER,
    page: {...getState().cadastroReducer.page, number: 0},
  })
}