import criarReducer, { acaoTipo } from "../factory/criarReducer";
import tiposDeAcoes from "./usuarioActions";
import { cadastroEnderecoReducerTipo } from "../cadastro/cadastroEndereco/cadastroEnderecoReducer";

export type minimalUsuarioReducerTipo = cadastroEnderecoReducerTipo & {
  usuario: {
    nome: string,
    sobrenome: string,
    email: string,
    cpf: string,
    celular: string,
    dataDeNascimento: string,
    genero: 'nao_informado' | 'masculino' | 'feminino',
    mudouEstadoVenda: boolean,
  },
};

export type usuarioReducerTipo = minimalUsuarioReducerTipo & {verificaUsuarioLogado: () => boolean};

export type extendedUsuarioReducerTipo = usuarioReducerTipo;

const usuarioReducerVazio: usuarioReducerTipo = {
  usuario: {
    nome: '',
    sobrenome: '',
    email: '',
    celular: '',
    cpf: '',
    dataDeNascimento: '',
    genero: 'nao_informado',
    mudouEstadoVenda: false,
  },
  endereco: {
    bairro: '',
    cep: '',
    cidade: '',
    codigoCidade: '',
    complemento: '',
    estado: '',
    numero: '',
    pais: {
      codigo: 0,
      iso: '',
      nome: '',
    },
    rua: '',
  },
  verificaUsuarioLogado: () => {
    if (sessionStorage.getItem('a')) {
      return true;
    }
    return false;
  },
};

const estadoInicial = usuarioReducerVazio;

const acoesNomeadas: any = {
  [tiposDeAcoes.ATUALIZAR_USUARIO]: (usuario: {usuario: extendedUsuarioReducerTipo}, estadoAtual: usuarioReducerTipo) => {
    const novoEstado = {
      ...estadoAtual,
      ...usuario,
    };

    return novoEstado;
  },
  [tiposDeAcoes.LIMPAR_USUARIO]: () => {
    const novoEstado = {
      ...usuarioReducerVazio,
    };

    sessionStorage.removeItem('a');

    return novoEstado;
  },
};

// @ts-ignore
export default function(estado: usuarioReducerTipo = {verificaUsuarioLogado: () => false, ...estadoInicial}, acao: acaoTipo) {
  return criarReducer(estado, acao, acoesNomeadas);
}
