import criarReducer from "../factory/criarReducer";
import { tipoAcao } from "../../../../../store/actions/acaoTemplate";
import { MODO_COBRANCA_TELE_ENTREGA_POR_DISTANCIA, TIPO_CADASTRO_USUARIO_COMPLETO } from "../../../../../store/reducers/empresaSelectorReducer";
import actionTypes from "./empresaActions";
import { cadastroEnderecoReducerTipo } from "../cadastro/cadastroEndereco/cadastroEnderecoReducer";

export type empresaReducerTipo = cadastroEnderecoReducerTipo & {
    empresa: {
        id: number,
        uri: string,
        cnpj: string,
        razaoSocial: string,
        nomeFantasia: string,
    },
    parametrosEmpresa: {
      filtroDeProdutos: 'nome' | 'codigo',
      modoCobrancaTeleEntrega: string,
      valorTeleEntregaPorKm: number,
      tipoCadastroUsuario: string,
    },
    contato: {
      email: string,
      telefone: string,
    }
};

const estadoInicial: empresaReducerTipo = {
    empresa: {
        id: 0
        , uri: ''
        , cnpj: ''
        , razaoSocial: ''
        , nomeFantasia: ''
    },
    parametrosEmpresa: {
      filtroDeProdutos: 'nome',
      modoCobrancaTeleEntrega: MODO_COBRANCA_TELE_ENTREGA_POR_DISTANCIA,
      valorTeleEntregaPorKm: 0,
      tipoCadastroUsuario: TIPO_CADASTRO_USUARIO_COMPLETO,
    },
    endereco: {
        bairro: ''
        , cep: ''
        , cidade: ''
        , codigoCidade: ''
        , complemento: ''
        , estado: ''
        , numero: ''
        , pais: {
            codigo: 0
            , iso: ''
            , nome: ''
          }
        , rua: ''
    },
    contato: {
      email: ''
      , telefone: ''
    }
};

const acoesNomeadas: {[key:string]: (carga: any, estadoAtual?: empresaReducerTipo) => empresaReducerTipo} = {
  [actionTypes.POPULAR_EMPRESA]: (empresa: empresaReducerTipo, estadoAtual?: empresaReducerTipo) => {
    const newState = {
      ...estadoAtual
      , ...empresa
    }
    
    return newState;
  }
};

export default function(estado: empresaReducerTipo = estadoInicial, acao: tipoAcao) {
  return criarReducer(estado, acao, acoesNomeadas);
}
