import React from 'react';

import { getStrings } from '../../../utils/LocaleUtils';

import CadastroTable from './CadastroTable';
import ObjetoTableModel from './ObjetoTableModel';

/**
 * Classe que repassa para o CadastroTable as informações para a montagem da tabela das Impressoras.
 */
export default class ImpressoraTable extends React.Component {
    render() {
        return <CadastroTable {...this.props} objetoCadastro='impressora' sortProperty={'nome'} filterProperties={['nome', 'codigo']} tableModel={ObjetoTableModel}
            blankFormData={{
                enabled: true,
                codigo: '',
                nome: '',
                comprimentoLinha: 48,
                tamanhoCodigoQR: 250,
                dispositivo: '',
                tipoImpressora: null,
                avancoLinha: 5,
            }} registerHelp={getStrings().printerTableHelp}
        />;
    }
}
