import React from "react";
import { connect } from "react-redux";

import { getStrings } from "../../../../../utils/LocaleUtils";
import { log } from "../../../../../utils/LogUtils";
import { getReduxWrappedComponent } from "../../../../../utils/reduxUtils/reduxUtils";
import { cadastroBuildOptionsFromRamoEmpresa } from "../../../../../utils/SelectUtils";
import { localeSort } from "../../../../../utils/SortUtils";

import { dispatchTipo } from "../../../../../store/actions/acaoTemplate";
import { cadastroLoadRamosEmpresa } from "../../../../../store/actions/cadastroAction";
import { cadastroEmpresaEUsuarioSaveData } from "../../../../../store/actions/cadastroEmpresaAction";
import { STATE_CADASTRO_EMPRESA_EMPRESA } from "../../../../../store/reducers/cadastroEmpresaReducer";

import InputCustomizado from "../../../../UI/Input/InputCustomizado";


export interface SignUpCompanyUserFormCompanyInfoStepType {}

interface SignUpCompanyUserFormCompanyInfoStepTypeWithReducer extends SignUpCompanyUserFormCompanyInfoStepType {
  cadastroLoadRamosEmpresa: (onSuccess: (content?: any) => void, onComplete: () => void) => void
  empresa: any
  empresaStep: number
  updateCadastroEmpresaFormData: (dados: any) => void 
}

class SignUpCompanyUserFormCompanyInfoStep_ extends React.Component<SignUpCompanyUserFormCompanyInfoStepTypeWithReducer> {
  ramoEmpresa: any = undefined
  razaoSocial: any = undefined
  nomeFantasia: any = undefined
  cnpj: any = undefined
  inscricaoEstadual: any = undefined

  ismounted: boolean = false

  /**
   * Método que carrega os itens do campo Ramo da Empresa.
   */
  loadRamosEmpresa = () => {
      log("SignUpCompanyCompanyForm loadRamosEmpresa");
      // Carrega o campo de Ramo da Empresa com a função de busca, conforme o campo for sendo preenchido
      this.props.cadastroLoadRamosEmpresa(
          cadastros => {
              if (!this.ismounted)
                  return;

              this.ramoEmpresa.inputComponent.updateOptions(localeSort(cadastroBuildOptionsFromRamoEmpresa(true, cadastros), "label"));
          }, () => { });
  }

  updateReducer(key: string, dados: any) {
    this.props.updateCadastroEmpresaFormData({
      [key]: dados,
    });
  }

  componentDidMount() {
    this.ismounted = true
    this.loadRamosEmpresa()
  }

  componentDidUpdate() {
    if (this.props.empresa.ramoEmpresaOption?.value?.codigo !== this.ramoEmpresa.inputComponent.getValue()?.value?.codigo) {
      this.ramoEmpresa.inputComponent.updateValue(this.props.empresa.ramoEmpresaOption);
    }

    if ((this.props.empresa.razaoSocial || "") !== this.razaoSocial.inputComponent.value) {
      this.razaoSocial.inputComponent.value = this.props.empresa.razaoSocial || "";
    }

    if ((this.props.empresa.nomeFantasia || "") !== this.nomeFantasia.inputComponent.value) {
      this.nomeFantasia.inputComponent.value = this.props.empresa.nomeFantasia || "";
    }

    if ((this.props.empresa.cnpj || "") !== this.cnpj.inputComponent.value) {
      this.cnpj.inputComponent.value = this.props.empresa.cnpj || "";
    }

    if ((this.props.empresa.inscricaoEstadual || "") !== this.inscricaoEstadual.inputComponent.value) {
      this.inscricaoEstadual.inputComponent.value = this.props.empresa.inscricaoEstadual || "";
    }
  }

  render() {
    return <>
      <div id="campos-empresa" className={`${this.props.empresaStep !== 0 ? "hidden" : ""}`} >
        
        <h2>{`${getStrings().company} - ${getStrings().generalData}`}</h2>

        <InputCustomizado
          //topClassStyle={{ padding: "0 0.3em" }}
          ref={(input: any) => { if (input !== null) { this.ramoEmpresa = getReduxWrappedComponent(input) } }}
          placeholder={getStrings().companyBusinessPlaceholder}
          id="ramo"
          inputType="singleSelect"
          name="ramo"
          required={true}
          label={getStrings().companyBusinessRequired}
          autoComplete="nah"
          onChange={(dados) => this.updateReducer("ramoEmpresaOption", dados)}
          sort
        />

        <InputCustomizado
          //topClassStyle={{ padding: "0 0.3em" }}
          ref={(input: any) => { if (input !== null) this.razaoSocial = getReduxWrappedComponent(input) }}
          placeholder={getStrings().companyNamePlaceholder}
          maxLength="60"
          id="razaoSocial"
          type="text"
          name="razaoSocial"
          required={true}
          label={getStrings().companyNameRequired}
          handleInputValidado={(dados) => this.updateReducer("razaoSocial", dados)}
          autoComplete="nah"
        />

        <InputCustomizado
          //topClassStyle={{ padding: "0 0.3em" }}
          ref={(input: any) => { if (input !== null) this.nomeFantasia = getReduxWrappedComponent(input) }}
          placeholder={getStrings().tradingNamePlaceholder}
          maxLength="60"
          id="nomeFantasia"
          type="text"
          name="nomeFantasia"
          required={true}
          label={getStrings().tradingNameRequired}
          handleInputValidado={(dados) => this.updateReducer("nomeFantasia", dados)}
          autoComplete="nah"
        />

        <div className="flex flex-row flex-nowrap">
          <div className="flex flex-row flex-nowrap" style={{ flex: "1 1 auto" }}>
            <InputCustomizado
              topClassName="full-width"
              //topClassStyle={{ padding: "0 0.3em" }}
              ref={(input: any) => { if (input !== null) this.cnpj = getReduxWrappedComponent(input) }}
              placeholder={getStrings().federalTaxNumberPlaceholder}
              maxLength="20"
              id="cnpj"
              type="text"
              name="cnpj"
              required={true}
              label={getStrings().federalTaxNumberRequired}
              handleInputValidado={(dados) => this.updateReducer("cnpj", dados)}
              autoComplete="nah"
            />
          </div>
          <div className="flex flex-row flex-nowrap" style={{ flex: "1 1 auto" }}>
            <InputCustomizado
              topClassName="full-width"
              //topClassStyle={{ padding: "0 0.3em" }}
              topClassStyle={{ paddingLeft: "0.6em" }}
              ref={(input: any) => { if (input !== null) this.inscricaoEstadual = getReduxWrappedComponent(input) }}
              placeholder={getStrings().stateRegistryPlaceholder}
              maxLength="20"
              id="inscricaoEstadual"
              type="text"
              name="inscricaoEstadual"
              required={true}
              label={getStrings().stateRegistryRequired}
              handleInputValidado={(dados) => this.updateReducer("inscricaoEstadual", dados)}
              autoComplete="nah"
            />
          </div>
        </div>
      </div>
    </>
  }
}

function mapStateProps(state: any) {
  const props = {
    empresa: state.cadastroEmpresaReducer.cadastroEmpresaDados.empresa,
    empresaStep: state.cadastroEmpresaReducer.step,
  }

  return props
}

function mapDispatchToProps(dispatch: dispatchTipo) {
  const props = {
    updateCadastroEmpresaFormData: (dados: any) => 
      dispatch(cadastroEmpresaEUsuarioSaveData(STATE_CADASTRO_EMPRESA_EMPRESA, dados)),
    cadastroLoadRamosEmpresa: (onSuccess: (content?: any) => void, onComplete: () => void) => dispatch(cadastroLoadRamosEmpresa(onSuccess, onComplete)),
  }

  return props
}

export const SignUpCompanyUserFormCompanyInfoStep = connect(mapStateProps, mapDispatchToProps, null, {forwardRef: true})(SignUpCompanyUserFormCompanyInfoStep_)