import { UPDATE_CURRENT_IMAGE, UPDATE_PRODUCT_IMAGES, UPDATE_UPLOADED_IMAGE } from "../reducers/productImagesReducer";

export const updateCurrentImageAction = (payload) => {
    return {
        type: UPDATE_CURRENT_IMAGE,
        payload,
    }
};

export const updateProductImagesAction = (payload) => {
    return {
        type: UPDATE_PRODUCT_IMAGES,
        payload,
    }
};

export const updateUploadedImageAction = (payload) => {
    return {
        type: UPDATE_UPLOADED_IMAGE,
        payload,
    }
};