const genericReducer = (state = null, {type, payload}, namedActions = {}) => {
    const action = namedActions[type];

    if (action) {
        const newState = action(payload, state);

        return newState;
    }
    
    return state;
}

export default genericReducer;
