import { isString } from "./ComparatorsUtils";
import { containsOnlyNumbers } from "./StringUtils";

export const formataTelefone = (telefone) => {
  if (isString(telefone) && containsOnlyNumbers(telefone)) {
    if (telefone.length > 10) {
      return telefone.replace(/(\d{2})(\d{1})(\d{4})(\d+)/, '($1) $2 $3-$4');
    }
    if (telefone.length > 9) {
      return telefone.replace(/(\d{2})(\d{4})(\d+)/, '($1) $2-$3');
    }
    if (telefone.length > 8) {
      return telefone.replace(/(\d{1})(\d{4})(\d+)/, '$1 $2-$3');
    }
    return telefone.replace(/(\d{4})(\d+)/, '$1-$2');
  }
  return telefone;
}