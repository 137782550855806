//@ts-check
import React from 'react';
import { Botao, Texto } from '../../../nucleo/nucleo';
import Icones from '../../../assets/icones/Icones';
import { LocalidadeUtils, NavegacaoUtils } from '../../../nucleo/utils/utils';
import { connect } from 'react-redux';

import './BotaoVoltar.css';

/**
 * 
 * @param {import('./types').botaoVoltarType} props 
 * @returns 
 */
function BotaoVoltar(props) {
    const IconeVoltar = Icones.mobi.Voltar;

    return <Botao {...props.botaoVoltar} id='botao-voltar' onClick={props.acaoVoltarCustomizada || (() => props.dispatch(NavegacaoUtils.goBack()))}>
        <IconeVoltar style={{width: '2em'}} />
        <Texto>{LocalidadeUtils.obterTraducao().words.back}</Texto>
    </Botao>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
    acaoVoltarCustomizada: state.webAppBotaoVoltarReducer.botaoVoltarCustomizado,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(BotaoVoltar);