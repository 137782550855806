import React from 'react';

export const UPDATE_A_SELECT_FIELD = 'UPDATE:SELECT_FIELDS';
export const REMOVE_A_SELECT_FIELD = 'REMOVE:SELECT_FIELDS';
export const CLEAR_SELECT_FIELDS = 'CLEAR:SELECT_FIELDS';

const initialState = {};

const buildOption = (value = '', label, className = '') => {
    return {
        value,
        label: label ? label : <div>{value}</div>,
        className
    };
}

const mapOptions = ({values, labels, classNames}) => {
    values = values ? values : [];
    labels = labels ? labels : [];
    classNames = classNames ? classNames : [];

    return values
        .map((value, index) => 
            buildOption(
                value,
                index < labels.length
                    ? labels[index]
                    : value,
                index < classNames.length
                    ? classNames[index]
                    : '',
            )
        );
}

const mapActions = {
    [UPDATE_A_SELECT_FIELD]: ({selectFieldId, value, label, className, options}, state) => {
        return {
            ...state,
            [selectFieldId]: {
                value: buildOption(
                    typeof value === 'string'
                        ? value
                        : ((state[selectFieldId] || {}).value || {}).value,
                    label,
                    className || ((state[selectFieldId] || {}).value || {}).className,
                ),
                options: options
                    ? mapOptions(options)
                    : (state[selectFieldId] || {}).options || [], 
            }
        };
    },
    [REMOVE_A_SELECT_FIELD]: (selectFieldId, state) => {
        const newState = {...state};

        if (newState[selectFieldId]) {
            delete newState[selectFieldId];
        }

        return newState;
    },
    [CLEAR_SELECT_FIELDS]: () => ({}),
};

const selectFieldsReducer = (state = initialState, {type, payload}) => {
    const action = mapActions[type];

    if (action) {
        return action(payload, state);
    }

    return state;
};

export default selectFieldsReducer;