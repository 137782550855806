import React from "react";
import "./css/font.css";
import styled, { ThemeProvider } from "styled-components";
import theme from "./css/theme";
import "./css/index.css";
import {
  Navbar,
  Hero,
  Footer,
  Contato,
  IntegracaoDelivery,
  PedidosAprovados,
  Segmentos,
  AppPropio,
  Relatorios,
  AtendimentoSalao,
  PedidosPrints,
  GerenciamentoEntregas,
  LeitorQRCode,
  MuitoAlem,
} from "./components";

const NewLandingPage = () => {
  //usado pra fazer o scroll até o elemento quando clicar em algum menu
  const heroRef = React.createRef();
  const segmentoRef = React.createRef();
  const recursosRef = React.createRef();
  const contatoRef = React.createRef();

  const executeScroll = (ref) => {
    let refs = [heroRef, segmentoRef, recursosRef, contatoRef];
    console.log(ref);
    if (refs[ref].current) {
      refs[ref].current.scrollIntoView(true);
    }
  };

  return (
    <PageContainer>
      <ThemeProvider theme={theme}>
        <Navbar sizes={theme.sizes} executeScroll={executeScroll} />
        <Hero ref={heroRef} />
        <Conteudo>
          <AppPropio />
          <Relatorios ref={recursosRef} />
          <PedidosPrints />
          <AtendimentoSalao />
          <GerenciamentoEntregas />
          <IntegracaoDelivery />
          <PedidosAprovados />
          <LeitorQRCode />
          <Segmentos ref={segmentoRef} />
          <MuitoAlem />
          {/* <Ebooks /> */}
          <Contato ref={contatoRef} />
        </Conteudo>
        <Footer />
      </ThemeProvider>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-width: 320px;
`;

const Conteudo = styled.div`
  width: 100%;
`;
export default NewLandingPage;
