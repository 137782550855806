//@ts-check
import { obterTraducao } from '../LocalidadeUtils/LocalidadeUtils';

const traducao = obterTraducao();

const estadoDosPedidos = {
    AGUARDANDO: traducao.orders.approved,
    AGUARDANDO_PAGAMENTO: traducao.orders.waitingPayment,
    CANCELADO: traducao.orders.cancelled,
    EM_PRODUCAO: traducao.orders.inProduction,
    ENCERRADO: traducao.orders.finished,
    ENVIADO: traducao.orders.sent,
    ENTREGA: traducao.orders.deliverying,
    ENTREGADOR: traducao.orders.inTransit,
    ENTREGUE: traducao.orders.delivered,
    ESPERANDO_ACEITAR: traducao.orders.analisys,
    NAO_ACEITO: traducao.orders.notApproved,
    PRODUZIDO: traducao.orders.produced,
    SAIU_PARA_ENTREGA: traducao.orders.deliverying,
};

export default estadoDosPedidos;
