import React from 'react';
import { log } from './LogUtils';
import { isNonEmptyArray } from './ComparatorsUtils';

// http://react-icons.github.io/react-icons/md.html

const AColorPicker = require('a-color-picker');

/*
 * Retorna se pode exibir o `side drawer` ao trocar de tela.
 * Por enquanto, não é possível usar variáveis em media queries, mas essa funcionalidade está em desenvolvimento (2018/04/27).
 * Se precisar alterar esse valor, alterar também em SideMenu.scss
 */
export const getShowSideDrawerCondition = () => {
    log('utils getShowSideDrawerCondition');
    return window.innerWidth > 768;
}

/**
 * Ativa ou desativa o scroll da página. Útil para a exibição de modais.
 * @param {boolean} scroll true habilita o scroll, false desabilita
 */
export const enableBodyScroll = scroll => {
    log('utils enableBodyScroll', scroll);

    scroll ? document.body.classList.remove("no-scroll") : document.body.classList.add("no-scroll");
};

/**
 * Retorna uma cor aleatória em tom pastel.
 */
export const randomPastelColor = () => {
    log('utils randomPastelColor');

    var randomHSL = "hsl(" + 360 * Math.random() + ',' +
        (25 + 70 * Math.random()) + '%,' +
        (85 + 10 * Math.random()) + '%)';
    return randomHSL;
};

/**
 * Remove o foco do elemento que estiver focado.
 */
export const blurAll = () => {
    log('utils blurAll');

    let element = document.querySelector(':focus');
    if (element) {
        element.blur();
    }
}

/**
 * Transforma uma lista de componentes em uma composição de componentes usando ``.
 * @param {Array} list 
 */
export const listToFragment = list => {
    if (!isNonEmptyArray(list)) {
        return null;
    }

    if (list.length === 1) {
        return list[0];
    }

    return <>
        {list[0]}
        {listToFragment(list.slice(1))}
    </>;
}

/**
 * Define qual item do menu está ativo para trocar a cor dele.
 * Também define qual item do menu contém o item que está ativo, se for o caso de ser um sub menu.
 * @param {string|undefined} itemId identificador do item selecionado por último
 * @param {string|undefined} superItemId identificador do item que contém o item acima
 */
export const updateActiveMenuItem = (itemId = undefined, superItemId = undefined) => {
    log('utils updateActiveMenuItem', itemId, superItemId);

    let menuItems = document.getElementsByClassName('menuItem');

    for (let item of menuItems) {
        item.classList.remove('active');
    }

    menuItems = document.getElementsByClassName('superMenuItem');

    for (let item of menuItems) {
        item.classList.remove('subActive');
    }

    if (itemId && document.getElementById(itemId)) {
        document.getElementById(itemId).classList.add('active');
    }

    if (superItemId && document.getElementById(superItemId)) {
        document.getElementById(superItemId).previousSibling.classList.add('subActive');
    }
}

export const ColorPicker = ({selector}) => {
    const picker = AColorPicker;

    return picker.from(selector);
};

export const reloadImage = (url, timeout) => {
    setTimeout(() => {
        fetch(url, { cache: 'reload', mode: 'no-cors'})
        .then(() => document.body.querySelectorAll(`img[src='${url}']`).forEach(img => img.src = url));
    }, timeout || 0);
}
