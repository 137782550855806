//@ts-check
import React from 'react';

import './BotaoPrincipal.css';

import BotaoCarrinho from '../BotaoCarrinho/BotaoCarrinho';
import BotaoComprar from '../BotaoComprar/BotaoComprar';
import BotaoCadastrar from '../BotaoCadastrar/BotaoCadastrar';
import BotaoConCluir from '../BotaoConcluir/BotaoConcluir';
import apelidos from '../../../rotas/apelidos';
import BotaoCarrinhoUnidade from '../BotaoCarrinhoUnidade/BotaoCarrinhoUnidade';

/**
 * 
 * @param {any} props 
 * @returns 
 */
const BotaoTemplate = (props) => {
  const Componente = props.componente;

  return <Componente {...props} className={'ui-botao-principal ' + (props.className || '')} />
}

/**
 * 
 * @param {any} props 
 * @returns 
 */
function BotaoPrincipal(props) {
  const sanitizedProps = {...props};

  delete sanitizedProps.caminhoAtual;

  if (props.caminhoAtual.replace(new RegExp(`\\${apelidos.app}\\/*`), '') === '') {
    return <BotaoTemplate componente={BotaoCarrinho} {...sanitizedProps} />;
  }

  if (props.caminhoAtual === apelidos.carrinho) {
    return <BotaoTemplate componente={BotaoComprar} {...sanitizedProps} />;
  }

  if (props.caminhoAtual === apelidos.cadastro) {
    return <BotaoTemplate componente={BotaoCadastrar} {...sanitizedProps} />;
  }

  if (props.caminhoAtual === apelidos.editar) {
    return <BotaoTemplate componente={BotaoCadastrar} {...sanitizedProps} editar />;
  }

  if (props.caminhoAtual === apelidos.compra) {
    return <BotaoTemplate componente={BotaoConCluir} {...sanitizedProps} />;
  }

  if (props.caminhoAtual === apelidos.produtosComTamanho) {
    return <BotaoTemplate componente={BotaoCarrinhoUnidade} {...sanitizedProps} />;
  }

  return <></>;
}

export default BotaoPrincipal;
