
import { push } from "connected-react-router"

import { getAppUsuario, updateAppQuantidadeCargos, updateAppPermissoesCargo, updateAppToken } from "../../utils/AppUtils"
import * as AuthUtils from "../../utils/AuthUtils"
import * as errorUtils from "../../utils/ErrorUtils"
import { getHeaders } from "../../utils/HeadersUtils"
import { isEdge } from "../../utils/NavigatorUtils"
import { barCodeFormatOrder } from "../../utils/MiscUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { getCargoStorage, setCargoStorage } from "../../utils/StorageUtils/SessionStorageUtils"
import { urlDatabase } from "../../utils/SecureConnectionUtils"

import * as actionTypes from "./actionTypes"
import { axios, appSpinnerHide, appSpinnerShow, appSnackbarMessage } from "./appAction"
import { setSideDrawerState } from "./appAction"
import * as controleVendaActions from "./controleVenda/controleVendaAction"
import * as producaoActions from "./producaoAction"
import { empresaSelectorActionTypes } from "../reducers/empresaSelectorReducer"

export const RamoEmpresa = {
  COLETOR: "COLETOR"
  , PAINEL: "PAINEL"
  , RESTAURANTE_A_LA_CARTE: "RESTAURANTE_A_LA_CARTE"
  , RESTAURANTE_A_KILO: "RESTAURANTE_A_KILO"
}

/**
 * Método que busca a lista de Cargos atribuídos ao Usuário logado. 
 * Executado quando a tela for carregada. 
 */
export const getCargoList = () => {
  log('empresaSelectorAction getCargoList')

  return dispatch => {

    dispatch(appSpinnerShow('getCargoList'));

    dispatch(empresaSelectorLoadingStart());

    // Resgata o usuário da sessionStorage
    const usuario = getAppUsuario();

    axios().get(
      urlDatabase + '/cargos/findListFromUsuario',
      getHeaders({ usuario: usuario })
    )
      .then(response =>
        dispatch(empresaSelectorLoadingSuccess(response))
      )
      .catch(error =>
        dispatch(empresaSelectorLoadingFail(error))
      )
      .finally(() => { if (!isEdge) { return dispatch(appSpinnerHide('getCargoList')) } }
      );
  };
}

/**
 * Método que mostra o Spinner enquanto os Cargos são buscados no BackEnd. 
 * Executado ao iniciar o carregamento do componente EmpresaSelector. 
 */
const empresaSelectorLoadingStart = () => {
  log('empresaSelectorAction empresaSelectorLoadingStart');

  return dispatch => {

    dispatch({
      type: actionTypes.EMPRESA_SELECTOR_LOADING_START
    });
  };
};

/**
 * Método que atribui a lista de Cargos ao estado, para que seja apresentada na tela de seleção. 
 * Executado quando o carregamento do componente EmpresaSelector for finalizado com sucesso. 
 * @param {*} response 
 */
const empresaSelectorLoadingSuccess = response => {
  log('empresaSelectorAction empresaSelectorLoadingSuccess', response);

  return dispatch => {
    dispatch({
      type: actionTypes.EMPRESA_SELECTOR_LOADING_SUCCESS,
      list: response.data.content
    });
  };
};

/**
 * Método que apresenta uma Notificação com o Erro. 
 * Executado quando houver falha no carregamento do componente EmpresaSelector. 
 * @param {*} error 
 */
const empresaSelectorLoadingFail = error => {
  log('empresaSelectorAction empresaSelectorLoadingFail', error);

  return dispatch => {

    dispatch(errorUtils.requestErrorHandlerDefault(error));

    dispatch({
      type: actionTypes.EMPRESA_SELECTOR_LOADING_FAIL,
      error: error
    });
  };
};


/**
 * Método que atribui o Cargo ao Estado e ao Storage do Navegador. 
 * Executado ao selecionar uma Empresa/Cargo no componente EmpresaSelector. 
 * @param {*} idCargo 
 */
export const empresaSelectorCargoSelecionado = (idCargo, history) => {
  log('empresaSelectorAction empresaSelectorCargoSelecionado', idCargo, history);

  return dispatch => {
    dispatch(appSpinnerShow('empresaSelectorCargoSelecionado'));

    dispatch(cargoSelecionadoSubmit());

    axios().get(
      urlDatabase + '/cargos/cargoSelecionado',
      getHeaders({ c: idCargo })
    )
      .then(response => {
        dispatch(cargoSelecionadoSuccess(response));

        const somenteEntregador = AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_ENTREGA]) && !AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_PRODUCAO_ALTERACAO, AuthUtils.PERMISSAO_PRODUCAO_CONTROLE]);

        if (somenteEntregador) {
          // Redireciona para a tela de produção
          dispatch(push('/producao'));
        }
        else {
          // Redireciona para a página inicial
          dispatch(push('/'));
        }
      })
      .catch(error =>
        dispatch(cargoSelecionadoFail(error))
      )
      .finally(() => {
        if (!isEdge) { return dispatch(appSpinnerHide('empresaSelectorCargoSelecionado')); }
      });

    if (isEdge) {
      setTimeout(() => {
        dispatch(appSpinnerHide('empresaSelectorCargoSelecionado'));
      }, 250);
    }
  };
};

/**
 * Método que mostra o Spinner enquanto o Cargo é validado no BackEnd. 
 * Executado ao selecionar um Cargo no componente EmpresaSelector. 
 */
const cargoSelecionadoSubmit = () => {
  log('empresaSelectorAction cargoSelecionadoSubmit');

  return dispatch => {

    dispatch({
      type: actionTypes.EMPRESA_SELECTOR_CARGO_SELECIONADO_SUBMIT
    });
  };
};

/**
 * Método que atualiza o Token com o Token contendo os dados do Cargo criptografados. Também atribui os dados do Cargo ao Storage. 
 * Executado quando evento de seleção do Cargo retornar do BackEnd com sucesso. 
 * @param {*} response 
 * @param {*} cadastroUpdate se método foi chamado por uma atualização no cadastro do Cargo
 */
export const cargoSelecionadoSuccess = (response, cadastroUpdate) => {
  log('empresaSelectorAction cargoSelecionadoSuccess', response);
  return dispatch => {
    const tokenAtualizado = response.headers.authorization;

    updateAppToken(tokenAtualizado);

    const cargo = response.data;
    const colab = cargo._links.self.href;
    const empresa = cargo.empresa._links.self.href;

    dispatch(updateCargoStorage(
      cargo.cargoPermissao,
      cargo.empresa.ramoEmpresa,
      colab,
      empresa,
      cargo.empresa.nomeFantasia,
      // Busca as configurações de formatos de código de barras para leitura
      barCodeFormatOrder(cargo.empresa.parametrosEmpresa.ordemFormatoCodigoBarras),
      // Busca o parâmetro de preço da refeição livre
      cargo.empresa.parametrosEmpresa.precoLivre,
      // Busca o parâmetro que decide se deve perguntar pelo CPF ao pagar
      cargo.empresa.parametrosEmpresa.perguntaCpf,
      cargo.parametrosCargo.ativarSomAoNotificar));

    if (!cadastroUpdate) {
      dispatch(appSnackbarMessage(getStrings().helloRoleTemplate(cargo.nome)));

      // Ativa o SideDrawer ao selecionar empresa 
      // TODO Remover quando o Dashboard estiver implementado
      dispatch(setSideDrawerState(true));
    }

    dispatch({
      type: actionTypes.EMPRESA_SELECTOR_CARGO_SELECIONADO_SUCCESS,
      cargo: cargo._links.self.href
    });

    if (!cadastroUpdate) {
      dispatch(appSnackbarMessage(getStrings().helloRoleTemplate(cargo.nome)));
      // Estando o cargo no reducer, verifica se há itens de venda produzidos e origens de venda chamadas não visualizadas.
      dispatch(producaoActions.getUnreadItemVendaList());
      dispatch(controleVendaActions.getUnreadOrigemVendaList());
    }
  };
};

/**
 * Método que apresenta uma Notificação com o Erro. 
 * Executado quando houver falha no evento de seleção do Cargo. 
 * @param {*} error 
 */
const cargoSelecionadoFail = error => {
  log('empresaSelectorAction cargoSelecionadoFail', error);

  return dispatch => {

    dispatch(errorUtils.requestErrorHandlerDefault(error));

    dispatch({
      type: actionTypes.EMPRESA_SELECTOR_CARGO_SELECIONADO_FAIL,
      error: error
    });
  };
};

export function justUpdatePermissoesCargo(permissoesCargo) {
  return ({ type: empresaSelectorActionTypes.JUST_UPDATE_PERMISSOES_CARGO, permissoesCargo })
}

/**
 * Método executado ao montar o `SideDrawer`. Define ou limpa o nome da empresa selecionada.
 * @param {*} cargo 
 */
export const updateNomeEmpresa = nomeFantasia => {
  log('empresaSelectorAction updateNomeEmpresa', nomeFantasia);
  return {
    type: actionTypes.EMPRESA_SELECTOR_UPDATE_NOME_EMPRESA,
    nomeEmpresa: nomeFantasia
  };
};

/**
 * Método executado ao montar o `App`. Guarda no reducer a variável que está no `storage`
 * e indica que permissões o cargo possui.
 */
export const updatePermissoesCargo = permissoesCargo => {
  log('empresaSelectorAction updatePermissoesCargo', permissoesCargo);
  updateAppPermissoesCargo(permissoesCargo);
  return {
    type: actionTypes.EMPRESA_SELECTOR,
    permissoesCargo
  };
};

/**
 * Método executado ao montar o `SideDrawer`. Define o ramo da empresa selecionada.
 * @param {*} cargo 
 */
export const updatePrecoLivre = precoLivre => {
  log('empresaSelectorAction updatePrecoLivre', { precoLivre });
  return {
    type: actionTypes.EMPRESA_SELECTOR,
    precoLivre
  };
};

/**
 * Método executado ao montar o `SideDrawer`. Define o ramo da empresa selecionada.
 * @param {*} cargo 
 */
export const updatePerguntaCpf = perguntaCpf => {
  log('empresaSelectorAction updatePerguntaCpf', { perguntaCpf });
  return {
    type: actionTypes.EMPRESA_SELECTOR,
    perguntaCpf
  };
};

/**
 * Método executado ao montar o `App`. Guarda no reducer a variável que está no `storage`
 * e indica se o usuário logado possui vínculo com empresa(s).
 */
export const updateQuantidadeCargos = quantidadeCargos => {
  log('empresaSelectorAction updateQuantidadeCargos', quantidadeCargos);
  updateAppQuantidadeCargos(quantidadeCargos);
  return {
    type: actionTypes.EMPRESA_SELECTOR,
    quantidadeCargos: quantidadeCargos
  };
};

/**
 * Método executado ao montar o `SideDrawer`. Define o ramo da empresa selecionada.
 * @param {*} cargo 
 */
export const updateRamoEmpresa = ramoEmpresa => {
  log('empresaSelectorAction updateRamoEmpresa', ramoEmpresa);
  return {
    type: actionTypes.EMPRESA_SELECTOR,
    ramoEmpresa: ramoEmpresa
  };
};

/**
 * Atualiza as informações do cargo que ficam guardadas no *session storage*.
 * @param {Object | undefined} entidadePermissaoList
 * @param {String | undefined} ramoEmpresa
 * @param {String | undefined} uriCargo
 * @param {String | undefined} uriEmpresa
 * @param {String | undefined} nomeFantasia
 * @param {String[] | null | undefined} ordemFormatoCodigoBarras
 * @param {Number | undefined} precoLivre
 * @param {Boolean | undefined} perguntaCpf
 * @param {Boolean | undefined} ativarSomAoNotificar
 */
export const updateCargoStorage = (entidadePermissaoList = undefined, ramoEmpresa = undefined, uriCargo = undefined,
  uriEmpresa = undefined, nomeFantasia = undefined, ordemFormatoCodigoBarras = undefined, precoLivre = undefined, perguntaCpf = undefined, ativarSomAoNotificar = undefined) => dispatch => {

    log('empresaSelectorAction updateCargoStorage', { entidadePermissaoList, ramoEmpresa, uriCargo, uriEmpresa, nomeFantasia, ordemFormatoCodigoBarras, precoLivre, perguntaCpf });

    // Armazena as permissões do Cargo em um array
    let permissoes = undefined;
    if (entidadePermissaoList) {
      permissoes = [];
      Object.keys(entidadePermissaoList).forEach(key => {
        if (entidadePermissaoList[key] === true) {
          permissoes.push(key);
        }
      });
    }

    const cargoPersistido = getCargoStorage();

    // Verifica se já havia cargo no session storage
    if (cargoPersistido) {
      // Se sim, substitui o valor dos primeiros parâmetros da função (que provavelmente são nulos mesmo)
      uriCargo = uriCargo ? uriCargo : cargoPersistido.c;
      uriEmpresa = uriEmpresa ? uriEmpresa : cargoPersistido.e;
      nomeFantasia = nomeFantasia ? nomeFantasia : cargoPersistido.n;
      permissoes = entidadePermissaoList ? permissoes : cargoPersistido.p;
      ramoEmpresa = ramoEmpresa ? ramoEmpresa : cargoPersistido.r;
      ordemFormatoCodigoBarras = ordemFormatoCodigoBarras ? ordemFormatoCodigoBarras : cargoPersistido.ean;
      precoLivre = (precoLivre === undefined) ? cargoPersistido.l : precoLivre;
      perguntaCpf = (perguntaCpf === undefined) ? cargoPersistido.i : perguntaCpf;
      ativarSomAoNotificar = (ativarSomAoNotificar === undefined) ? cargoPersistido.s : ativarSomAoNotificar;
    }

    // Monta os dados do Cargo para o Storage (c: cargo, e: empresa, p: permissões)
    const storage = {
      c: uriCargo,
      e: uriEmpresa,
      n: nomeFantasia,
      p: permissoes,
      r: ramoEmpresa,
      ean: ordemFormatoCodigoBarras,
      l: precoLivre,
      i: perguntaCpf,
      s: ativarSomAoNotificar,
    };

    // Armazena o cargo no Session Storage
    setCargoStorage(storage);
    // Também armazena alguns dados no reducer para que componentes possam ser notificados das alterações
    dispatch({
      type: actionTypes.EMPRESA_SELECTOR,
      nomeEmpresa: nomeFantasia,
      permissoesCargo: permissoes,
      ramoEmpresa,
      precoLivre,
      perguntaCpf
    });
  }
