/**
 * 
 * @param {Object} payload 
 * @param {String} type 
 */
const genericAction = (payload, type) => ({
    type,
    payload,
});

export default genericAction;
