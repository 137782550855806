import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"

import { SPINNER_INPUT_ID } from "../../../store/actions/cadastroAction"

import InputCustomizado from "../../UI/Input/InputCustomizado"
import DateTimeInputDefault, { DATE_INPUT, DATE_TIME_INPUT, isDateTimeField, TIME_INPUT } from "../../UI/dateTimeInputDefault/DateTimeInputDefault"
import { showSpinner, hideSpinner } from "../../../components/UI/Spinner/Spinner"

/**
 * Classe responsável por montar o campo padrão "Filtro".
 */
class FiltroInputDefault extends React.Component {
  constructor(props) {
    super();

    this.delayTimer = null
    this.filtroValue = ''
    this.stopTimer = props.stopTimer || (() => { })
    this.onFilterTimerRestart = props.onFilterTimerRestart || (() => { })
  }

  getInputType = () => this.props.inputType || 'text';

  /**
   * Método que retorna o valor do campo. Esse método é alterado para filtros de data e hora.
   */
  getValue = () => {
    if (isDateTimeField(this.getInputType())) {
      return this.inputField.getValue();
    }
    return this.inputField.inputComponent.value;
  }

  setValue = value => {
    if (isDateTimeField(this.getInputType())) {
      this.inputField.setValue(value);
    }
  }

  /**
   * Ao alterar o conteúdo do filtro:
   * * exibe o spinner;
   * * remove o temporizador anterior;
   * * executa o método recebido por parâmetro para ser executado quando o temporizador reiniciar a contagem;
   * * cria um novo temporizador para esperar o usuário terminar de digitar;
   * * cadastra o método recebido por parâmetro para ser executado quando o temporizador terminar a contagem.
   */
  handleFiltro = value => {
    this.filtroValue = value;
    showSpinner(this.spinnerId);
    clearTimeout(this.delayTimer);
    this.onFilterTimerRestart();
    this.delayTimer = setTimeout(() => {
      this.props.onFilterTimerCompleted();
    }, this.props.timerDelay);
  }

  /**
   * Diferente de outros filtros, só reinicia a contagem quando o usuário fechar o filtro de data e hora.
   */
  onBlurDateTime = () => {
    this.onFilterTimerRestart();
    this.delayTimer = setTimeout(() => {
      hideSpinner(this.spinnerId);
    }, this.props.timerDelay);
  }

  /**
   * Método que monta o componente.
   */
  render() {

    /**
     * Ajustar outras variáveis que dependem se o filtro é de texto, opções ou data e hora. Se não informado, considera texto por padrão.
     */
    let inputType = this.getInputType();

    // Prepara o placeholder do campo
    var placeholder = isDateTimeField(inputType) ? '' : (getStrings().filterIcon + ' ');

    if ('placeholder' in this.props) {
      placeholder += this.props.placeholder;
    }
    else if ('subPlaceholder' in this.props) {
      // placeholder = 'Filtro ' + this.props.subPlaceholder + ' (Ex: Nome do cadastro)';
      placeholder += getStrings().filterPlaceholderTemplate(this.props.subPlaceholder);
    }
    else {
      placeholder += getStrings().filterPlaceholder;
    }

    // Se houver mais de um filtro na mesma tela, configura o identificador do spinner correspondente a este filtro
    this.spinnerId = this.props.spinnerId ? this.props.spinnerId : SPINNER_INPUT_ID;

    let maxLength;
    let type;

    switch (inputType) {
      case 'multiSelect':
      case DATE_INPUT:
      case DATE_TIME_INPUT:
      case TIME_INPUT:
        maxLength = undefined;
        type = undefined;
        break;
      case 'text':
      default:
        maxLength = '40';
        type = 'text';
    }

    // Monta filtro de data e hora
    if (isDateTimeField(inputType)) {

      return <DateTimeInputDefault
        ref={input => {
          if (input) {
            this.inputField = getReduxWrappedComponent(input);
          }
        }}
        placeholder={placeholder}
        defaultValue={this.props.defaultValue}
        inputType={inputType}
        id={this.props.id || 'filtroDataHora'}
        spinnerId={this.props.spinnerId}
        name='filtroDataHora'
        required={false}
        label={this.props.label || getStrings().filter}
        onBlur={this.props.disableTimeout ? (() => { }) : this.onBlurDateTime}
        onChange={value => {
          if (this.props.onChange) {
            this.props.onChange(value);
          }
        }}
        onClear={() => {
          if (this.props.onClear) {
            this.props.onClear();
          }
          if (!this.props.disableTimeout) {
            showSpinner(this.spinnerId);
            this.onBlurDateTime();
          }
        }}
        onFocus={this.props.disableTimeout ? (() => { }) : (() => {
          showSpinner(this.spinnerId);
          this.stopTimer();
        })}
      />;
    }

    // Monta filtro de texto ou de opções
    return <InputCustomizado
      ref={input => this.inputField = getReduxWrappedComponent(input)}
      defaultValue={this.props.defaultValue}
      placeholder={placeholder}
      maxLength={maxLength}
      inputType={inputType}
      id={this.props.id || 'filtro'}
      spinnerId={this.props.spinnerId}
      type={type}
      name='filtro'
      required={false}
      // label={this.props.label || getStrings().filter}
      handleInputValidado={this.props.disableTimeout ? this.props.handleInputValidado || (() => { }) : this.handleFiltro}
      onChange={this.props.disableTimeout ? this.props.onChange || (() => { }) : this.handleFiltro}
      sort={this.props.sort}
      inputClassName={this.props.placeholderTransparent ? '' : 'filter'}
      searchable={this.props.searchable}
    />;
  }
}

FiltroInputDefault.propTypes = {
  ref: PropTypes.func,

  /**
   * Desativa a temporização do filtro.
   */
  defaultValue: PropTypes.any,
  
  disableTimeout: PropTypes.bool,

  handleInputValidado: PropTypes.func,

  inputType: PropTypes.string,

  label: PropTypes.string,

  name: PropTypes.string,

  onChange: PropTypes.func,

  /**
   * Chamado quando um filtro do tipo data/hora é limpo.
   */
  onClear: PropTypes.func,

  /**
   * Chamado quando o temporizador termina de contar o tempo estabelecido.
   */
  onFilterTimerCompleted: PropTypes.func,

  /**
   * Chamado quando o conteúdo do filtro é alterado, o que causa o reinício do temporizador.
   */
  onFilterTimerRestart: PropTypes.func,

  placeholder: PropTypes.string,

  searchable: PropTypes.bool,

  spinnerId: PropTypes.string,

  timerDelay: PropTypes.number,
};

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {
  return {
    ...state.idiomaReducer
  };
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, null, null, { forwardRef: true })(FiltroInputDefault);
