import React from 'react';

import { getStrings } from '../../../utils/LocaleUtils';

import CadastroTable from './CadastroTable';
import ObjetoTableModel from './ObjetoTableModel';

/**
 * Classe que repassa para o CadastroTable as informações para a montagem da tabela dos Grupos de Produto.
 */
export default class GrupoProdutoTable extends React.Component {
    render() {
        return (
            <CadastroTable {...this.props} objetoCadastro='grupoProduto' sortProperty={'nome'} filterProperties={['nome', 'codigo']} tableModel={ObjetoTableModel}
                blankFormData={{
                    enabled: true,
                    codigo: '',
                    nome: '',
                    // grupoPai: null,
                    // subgrupoList: [],
                    impressora: null
                }} registerHelp={getStrings().productGroupTableHelp}
            />
        );
    }
}
