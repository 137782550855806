import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { getApi } from "../../../utils/SecureConnectionUtils"
import { getIdFromURI, getURIFromEntity } from "../../../utils/URIUtils"

import * as appActions from '../../../store/actions/appAction';
import * as producaoActions from '../../../store/actions/producaoAction';

import BuildButton from '../../UI/Toolbar/BarraAcoesButton';
import { AiOutlinePrinter } from "react-icons/ai";

import {
    ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA,
    nowWaitingOrProducingList,
    nowProducedOrDeliveredList,
    toWaitOrProduceList,
    toDeliverOrCancelList
} from '../../../store/reducers/controleVenda/controleVendaReducer';

import InputCustomizado from '../../UI/Input/InputCustomizado';
import Row from '../../vendas/grid/Row';

import "./DialogItemVenda.css";

const id_mapping = {
    TO_PRINT: 'print-item-button',
    EM_PRODUCAO: 'production-item-button',
    PRODUZIDO: 'ready-item-button',
    AGUARDANDO: 'waiting-item-button',
};

const StateButton = (props) => {
    return <>
        <button
            {...props}
            className={`state-button blue-button font-color${props.className ? ` ${props.className}` : ''}`}
        />
    </>;
};

/**
 * Monta a dialog para exibição de observações do item venda e para alteração do estado do mesmo.
 */
class DialogItemVenda extends React.Component {

    buildButtonState = (buttonState, itemList) => itemList.forEach((item) => {
        // Itera os estados possíveis do item de venda
        for (let state of toWaitOrProduceList.concat(toDeliverOrCancelList)) {
            // Pula o estado do próprio item de venda
            let notTheSameState = state !== item.estado;
            // Se o produto aparece na página principal
            let nowWaitingOrProducing = nowWaitingOrProducingList.some(
                stateNow => item.estado === stateNow);
            // Estes são os estados que ele pode assumir
            let toWaitOrProduce = toWaitOrProduceList.some(toState => state === toState);
            // Se o produto aparece na outra página
            let nowProducedOrDelivered = nowProducedOrDeliveredList.some(
                stateNow => item.estado === stateNow);
            // Estes são os estados que ele pode assumir
            let toDeliverOrCancel = toDeliverOrCancelList.some(toState => state === toState);

            if ((state === 'TO_PRINT') || (notTheSameState
                && ((nowWaitingOrProducing && toWaitOrProduce)
                    || (nowProducedOrDelivered && toDeliverOrCancel)))) {

                if (!buttonState[state]) {
                    buttonState[state] = { itemList: [] };
                }
                buttonState[state].itemList.push(item);
            }
        }
    });

    getObservacaoVenda = (venda) => {
        if (venda?.observacao) {
            if (venda?.entregaVenda?.observacao) {
                return venda.observacao + '\n' + venda.entregaVenda.observacao;
            }
            return venda.observacao;
        }
        else {
            return venda?.entregaVenda?.observacao;
        }
    };

    /**
     * Método que monta o componente.
     */
    render() {
        log('DialogItemVenda render', { tabIndex: this.props.tabIndex });

        let observacao = null;
        // Exibe a observação do item se ela existe ou se ela foi atualizada
        if (this.props.itemVenda && (this.props.itemVenda.observacao || this.props.itemVenda.observacaoAtualizada)) {
            // Exibe a observação do item se ela existe antes ou depois de ter sido alterada
            if (this.props.itemVenda.observacao) {
                observacao =
                    <>
                        <label>{getStrings().itemObservation}</label>
                        {/* <textarea id='observacao' name='observacao' ref={(textarea) => { this.textArea = textarea; }}
                            style={{ resize: 'none', height: '30%' }} value={this.props.itemVenda.observacao} readOnly={true} /> */}

                        <span className="observacao-item-textarea" role="textbox" >{this.props.itemVenda.observacao}</span>
                    </>
            }
            // Informa que a observação do item existia mas foi removida
            else {
                observacao = <label>{getStrings().itemObservationRemoved}</label>;
            }
        }

        let observacaoVenda = null;
        if (this.props.itemVenda && this.getObservacaoVenda(this.props.itemVenda.venda)) {
            observacaoVenda =
            <>
                <label>{getStrings().saleObservation}</label>
                <span className="observacao-item-textarea" role="textbox" >{this.getObservacaoVenda(this.props.itemVenda.venda)}</span>
            </>
        }

        let itemList = [];

        if (this.props.agruparProdutosVenda) {
            if (this.props.itemVendasList) {
                itemList.push(...this.props.itemVendasList);
            }
            else if (this.props.itemVenda) {
                itemList.push(this.props.itemVenda);
            }
        }
        else {
            if (this.props.itemProducaoList) {
                itemList.push(...this.props.itemProducaoList);
            }
            else if (this.props.itemProducao) {
                itemList.push(this.props.itemProducao);
            }
        }

        let buttonState = {};
        let buttons = [];

        this.buildButtonState(buttonState, itemList);

        Object.keys(buttonState).forEach((state) => {
            buttons.push(
                <StateButton
                    key={state}
                    type='button'
                    id={id_mapping[state]}
                    onClick={() => {
                        this.props.appDialogHide();
                        this.props.onDialogHide && this.props.onDialogHide();
                        if (state === 'TO_PRINT') {
                            buttonState[state].itemList.forEach(item => this.props.setPrinted(getURIFromEntity(item), false, this.checkBox.inputComponent.checked));
                        } else if (state === ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA) {
                            if (this.props.agruparProdutosVenda) {
                                this.props.loadDeliveryUserList(itemList);
                            } else {
                                this.props.loadDeliveryUserList(undefined, itemList);
                            }
                        } else {
                            if (this.props.agruparProdutosVenda) {
                                this.props.updateItemVenda({ estado: state, itemVendaList: buttonState[state].itemList.map(item => getIdFromURI(getURIFromEntity(item))) }, getApi('itemVendas/update'));

                            } else {
                                this.props.updateItemVenda({ estado: state, itemProducaoList: buttonState[state].itemList.map(item => getIdFromURI(getURIFromEntity(item)))}, getApi('itemProducao/update'));
                            }
                        }
                    }}>
                    {getStrings().saleItemStateEnumToString(state)}
                </StateButton>
            );
        });

        // Ajuste para compatibilidade: gera um componente para cada 2.
        buttons = buttons.reduce((previous, current, index, array) => {
            // Pula os pares
            if ((index % 2) === 1) {
                return previous;
            }
            // Se houver um componente para fazer dupla com este,
            // gera um componente com este e o próximo.
            if (array[index + 1]) {
                return previous.concat(<Row key={index}>
                    {current}
                    {array[index + 1]}
                </Row>);
            }
            // Senão, gera um componente apenas com o atual
            // (deve ocorrer sempre no último se forem botões ímpares ou não ocorrer se forem botões pares).
            return previous.concat(<Row key={index}>
                {current}
            </Row>);
        }, []);

        const iconStyle = {width: 'auto', height: 'auto', maxWidth: '1em', maxHeight: '1em', verticalAlign: 'middle', paddingRight: '0.3em', paddingBottom: '0.1em'};

        // Monta o componente
        return <div className={isAncientChromeMobile ? 'ancient-chrome-mobile' : undefined} style={{marginBottom: '7px'}}>
            {observacao}
            {observacaoVenda}
            <h2 className='ButtonAlign'>{getStrings().setSaleItemStateTo}</h2>
            <div className='dialog-horizontal-button-grid flow-buttons'>
                {buttons}
            </div>
            {!this.props.itemVendasList
            ? <div style={{marginTop: '1em'}}>
                <Row>
                    <InputCustomizado
                        tabIndex={(this.props.tabIndex + 1) * -1}
                        key='printOtherItemsCheckBox'
                        id='printOtherItemsCheckBox'
                        type='checkbox'
                        name='printOtherItemsCheckbox'
                        label={getStrings().printOtherItems}
                        ref={ref => {
                            if (ref) {
                                // guarda a referência do item
                                this.checkBox = getReduxWrappedComponent(ref);
                            }
                        }}
                    />
                </Row>
                <BuildButton
                    style={{marginTop: '0.5em', fontSize: '1em'}}
                    id="botao-imprimir"
                    key='select'
                    isPrimary={true}
                    commandMethod={() => {
                        this.props.appDialogHide();
                        this.props.onDialogHide && this.props.onDialogHide();
                        this.props.setPrinted(getURIFromEntity(this.props.itemVenda), false, this.checkBox.inputComponent.checked);
                    }}
                    label={<AiOutlinePrinter style={iconStyle} />}
                    texto={getStrings().print}
                    title={getStrings().print} />
            </div>
            : null
            }
        </div>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    tabIndex: state.appReducer.getTabIndex(),
    agruparProdutosVenda: state.producaoReducer.agruparProdutosVenda,
    ...state.idiomaReducer,
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    appDialogShow: (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles) =>
        dispatch(appActions.appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles)),
    appDialogHide: () => dispatch(appActions.appDialogHide()),
    loadDeliveryUserList: (listItemVenda, listItemProducao) => dispatch(producaoActions.loadDeliveryUserList(listItemVenda, listItemProducao)),
    setPrinted: (itemVenda, printed, printOtherItems) => dispatch(producaoActions.setPrinted(itemVenda, printed, printOtherItems)),
    updateItemVenda: (formData, urlUpdate, urlParams) => dispatch(producaoActions.updateItemVenda(formData, urlUpdate, urlParams)),
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(DialogItemVenda);
