import * as actionTypes from "../actions/actionTypes"

import { updateObject } from "../../utils/ObjectUtils"

/**
 * Usuário faz nada. Nem vê o nome da empresa.
 */
export const NIVEL_USUARIO_SEM_EMPRESA_NADA = "NADA"

/**
 * Usuário só pode ver o nome da empresa.
 */
export const NIVEL_USUARIO_SEM_EMPRESA_NOME = "NOME"

/**
 * Usuário pode ver a lista de produtos.
 */
export const NIVEL_USUARIO_SEM_EMPRESA_MENU = "MENU"

/**
 *  Usuário pode fazer pedidos.
 */
export const NIVEL_USUARIO_SEM_EMPRESA_VENDA = "VENDA"

export const MODO_COBRANCA_TELE_ENTREGA_POR_BAIRRO = "POR_BAIRRO"
export const MODO_COBRANCA_TELE_ENTREGA_POR_DISTANCIA = "POR_DISTANCIA"

export const MODELO_IMPRESSAO_PEDIDO_01 = "MODELO_01"
export const MODELO_IMPRESSAO_PEDIDO_02 = "MODELO_02"

export const TIPO_CADASTRO_USUARIO_COMPLETO = "COMPLETO"
export const TIPO_CADASTRO_USUARIO_SIMPLES = "SIMPLES"

export const empresaSelectorActionTypes = {
  JUST_UPDATE_PERMISSOES_CARGO: "JUST_UPDATE_PERMISSOES_CARGO"
}

/**
 * Estado Inicial. Necessário para o controle de estados. 
 */
const initialState = {
  list: [],
  cargo: null,
  error: null,
  nomeEmpresa: "",
  perguntaCpf: false,
  permissoesCargo: [],
  precoLivre: null,
  quantidadeCargos: null,
  ramoEmpresa: "",
}

/**
 * Reducer para controle geral da aplicação. 
 * Controla o Estado conforme a Ação executada. 
 * @param {*} state Estado inicial
 * @param {*} action Ação executada
 */
export default function empresaSelectorReducer(currentState = initialState, action) {
  switch (action.type) {
    case actionTypes.AUTH_LOGOUT: return authLogout(currentState, action)
    case actionTypes.AUTH_SUCCESS: return authSuccess(currentState, action)
    case actionTypes.AUTH_CHECK_STATE_SUCCESS: return authCheckStateSuccess(currentState, action)
    case actionTypes.EMPRESA_SELECTOR_LOADING_START: return empresaSelectorLoadingStart(currentState, action)
    case actionTypes.EMPRESA_SELECTOR_LOADING_SUCCESS: return empresaSelectorLoadingSuccess(currentState, action)
    case actionTypes.EMPRESA_SELECTOR_LOADING_FAIL: return empresaSelectorLoadingFail(currentState, action)
    case actionTypes.EMPRESA_SELECTOR_CARGO_SELECIONADO_SUBMIT: return empresaSelectorCargoSelecionadoSubmit(currentState, action)
    case actionTypes.EMPRESA_SELECTOR_CARGO_SELECIONADO_SUCCESS: return empresaSelectorCargoSelecionadoSuccess(currentState, action)
    case actionTypes.EMPRESA_SELECTOR_CARGO_SELECIONADO_FAIL: return empresaSelectorCargoSelecionadoFail(currentState, action)
    case empresaSelectorActionTypes.JUST_UPDATE_PERMISSOES_CARGO: return ({ ...currentState, ...action })
    case actionTypes.EMPRESA_SELECTOR_UPDATE_NOME_EMPRESA: return updateNomeEmpresa(currentState, action)
    case actionTypes.EMPRESA_SELECTOR: return updateObject(currentState, { ...currentState, ...action })
    default: return currentState
  }
}

/**
 * Método de controle de Logout. Necessário para limpar o estado do Cargo quando for efetuado o Logout. 
 * @param {*} state 
 * @param {*} action 
 */
const authLogout = (state, action) => {
  return updateObject(state, {
    list: []
    , cargo: null
  })
}

/**
 * Método de controle de Autorização. Necessário para armazenar o Cargo no estado quando a aplicação executar as verificações. 
 * @param {*} state 
 * @param {*} action 
 */
const authSuccess = (state, action) => {
  return updateObject(state, {
    cargo: action.cargo
  })
}

/**
 * Método de controle de Autorização. Necessário para armazenar o Cargo no estado quando a aplicação executar as verificações. 
 * @param {*} state 
 * @param {*} action 
 */
const authCheckStateSuccess = (state, action) => {
  return updateObject(state, {
    cargo: action.cargo
  })
}

const empresaSelectorLoadingStart = (state, action) => {
  return updateObject(state, {
    error: null
  })
}

const empresaSelectorLoadingSuccess = (state, action) => {
  return updateObject(state, {
    list: action.list
  })
}

const empresaSelectorLoadingFail = (state, action) => {
  return updateObject(state, {
    list: []
    , error: action.error
  })
}

const empresaSelectorCargoSelecionadoSubmit = (state, action) => {
  return updateObject(state, {
    cargo: null
    , error: null
  })
}

const empresaSelectorCargoSelecionadoSuccess = (state, action) => {
  return updateObject(state, {
    cargo: action.cargo
    , list: []
    , error: null
  })
}

const empresaSelectorCargoSelecionadoFail = (state, action) => {
  return updateObject(state, {
    error: action.error
    , cargo: null
    , list: []
  })
}

const updateNomeEmpresa = (state, action) => {
  return updateObject(state, {
    nomeEmpresa: action.nomeEmpresa
  })
}
