import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"

import * as appActions from "../../../store/actions/appAction"
import * as manutencaoVendaActions from "../../../store/actions/controleVenda/manutencao/manutencaoVendaAction"

const ConfirmButton = (props) => {
  return <button
    {...props}
    className={`blue-button font-color${props.className ? ` ${props.className}` : ""}`}
  />
}

/**
 * Classe que monta a dialog para Ajustar a observação do Item.
 */
class DialogObservacao extends React.Component {

  /**
   * Método executado ao clicar no botão "Confirma" da dialog.
   * Realiza a chamada do método que irá atualizar a observação.
   */
  updateObservacao = () => {
    log("DialogObservacao updateObservacao")

    let newObservacao = this.textArea.value

    if ((newObservacao === undefined) || (newObservacao === "")) {
      newObservacao = null
    }

    this.props.appDialogHide()

    // Se for um novo item de venda
    if (this.props.editable) {
      // Atualiza a observação exibida em tela
      this.props.setObservation(this.props.newSaleItem, this.props.newSaleItem.code, this.props.newSaleItem.sequence, this.props.newSaleItem.itemVenda.observacao, newObservacao)
    }
    // Se for um item de venda enviado
    else {
      // Também persiste a nova observação na retaguarda
      this.props.updateSentSaleItemObservation(this.props.newSaleItem.itemVenda, newObservacao)
    }
  }

  /**
   * Método executado após o componente ser montado a primeira vez.
   */
  componentDidMount() {
    log("DialogObservacao componentDidMount")
    // Se o dialog está visível, atualiza o campo de texto com a observação recebida
    if (this.props.dialogShow) {
      this.textArea.value = this.props.newSaleItem.itemVenda.observacao || ""
    }
  }

  /**
   * Método executado APÓS a atualização do componente.
   */
  componentDidUpdate(prevProps) {
    log("DialogObservacao componentDidUpdate", { prevProps })
    // Se o dialog estava oculto e passou para visível, atualiza o campo de texto com a observação recebida
    if ((!prevProps.dialogShow) && this.props.dialogShow) {
      this.textArea.value = this.props.newSaleItem.itemVenda.observacao || ""
    }
  }

  /**
   * Método que monta o componente.
   */
  render() {
    log("DialogObservacao render")

    const confirmaComponent = <>
      <ConfirmButton onClick={this.updateObservacao} >
        <label style={{ border: "none" }}>{getStrings()[this.props.editable ? `confirm` : `update`]}</label>
      </ConfirmButton>
    </>

    // Monta o componente
    return <>
      <textarea id="observacao" /*  disabled={!this.props.editable} */ name="observacao" maxLength="300" ref={textarea => this.textArea = textarea}
        placeholder={getStrings().itemObservation} style={{ resize: "none", height: "60%" }} />
      <div className="dialog-horizontal-button-grid unique">
        {confirmaComponent}
      </div>
    </>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  ...state.idiomaReducer,
  dialogShow: state.appReducer.dialogShow
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  appDialogHide: () => dispatch(appActions.appDialogHide()),

  setObservation: (newSaleItem, code, sequence, oldValue, newValue) =>
    dispatch(manutencaoVendaActions.setObservation(newSaleItem, code, sequence, oldValue, newValue)),

  updateSentSaleItemObservation: (sentSaleItem, observation) =>
    dispatch(manutencaoVendaActions.updateSentSaleItemObservation(sentSaleItem, observation))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DialogObservacao)
