import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import * as appActions from "../../../store/actions/appAction"
import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"

import InputCustomizado from "../../UI/Input/InputCustomizado"

const PrintButton = (props) => {
  const validProps = { ...props, reference: undefined }

  return <button
    {...validProps}
    ref={props.reference}
    className={`blue-button${props.className ? ` ${props.className}` : ""}`}
  />
}

/**
 * Classe que monta a dialog para usuário escolher vendas para imprimir.
 */
class DialogPrintVendas extends React.Component {

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  componentDidMount() {
    log("DialogPrintVendas componentDidMount")
    this.componentDidUpdate()
  }

  /**
   * Método executado APÓS a atualização do componente.
   */
  componentDidUpdate() {
    log("DialogPrintVendas componentDidUpdate")
    if (this.confirmButton) {
      // Desabilita o botão depois da tela montar. Se colocar disabled no render, nunca mais fica enabled.
      this.confirmButton.disabled = true
    }
    this.checkBoxList
      .filter(checkBoxComponent => checkBoxComponent && checkBoxComponent.inputComponent)
      .forEach(checkBoxComponent => checkBoxComponent.inputComponent.checked = false)
  }

  /**
   * Método que monta o componente.
   */
  render() {
    log("DialogPrintVendas render")

    // Inicializa a variável para guardar a referência de cada item da lista
    this.checkBoxList = []

    // Monta a lista de origens de venda com check box
    let vendaGrid = this.props.vendaList.map(venda =>
      <InputCustomizado
        tabIndex={(this.props.tabIndex + 1) * -1}
        key={getURIFromEntity(venda)}
        id={getURIFromEntity(venda)}
        type="checkbox"
        name={getURIFromEntity(venda)}
        label={venda.nome}
        ref={ref => {
          if (ref) {
            // adiciona no item uma referência para a venda
            getReduxWrappedComponent(ref).venda = venda
            // guarda a referência do item
            this.checkBoxList.push(getReduxWrappedComponent(ref))
          }
        }}
        handleInputValidado={() => {
          this.confirmButton.disabled = this.checkBoxList.filter(checkBoxComponent =>
            checkBoxComponent.inputComponent.checked).length === 0
        }} />

    )

    // Monta o componente
    return <>
      <div className="dialog-scroll-pane">
        {/* origens */}
        <div className="table" >
          {vendaGrid}
        </div>
      </div>
      {/* botão */}
      <div className="dialog-horizontal-button-grid">
        <PrintButton tabIndex={(this.props.tabIndex + 1) * -1} reference={ref => this.confirmButton = ref} type="button"
          onClick={() => {
            this.props.appDialogHide()
            // Marca as vendas como não impressas e volta para a tela de origens
            this.props.setPrinted(
              // Gera uma lista de origens de venda a partir da lista de itens, filtrando por aqueles que estão selecionados
              this.checkBoxList.filter(checkBoxComponent => checkBoxComponent.inputComponent.checked).map(checkBoxComponent => getURIFromEntity(checkBoxComponent.venda)),
              false
            )
          }} >{getStrings().confirm}</PrintButton>
      </div>
    </>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  tabIndex: state.appReducer.getTabIndex(),
  ...state.idiomaReducer,
  dialogShow: state.appReducer.dialogShow,
  vendaList: ((state.controleVendaReducer.origemVendaList || [])[0] || {}).vendaList || []
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  appDialogHide: () => dispatch(appActions.appDialogHide()),
  setPrinted: (vendaList, printed) => dispatch(controleVendaActions.setPrinted(vendaList, printed))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DialogPrintVendas)
