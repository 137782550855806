import React from 'react'
import { Redirect } from 'react-router-dom'
// import { connect } from 'react-redux'
import { reduxDispatch } from '../../../utils/reduxUtils/reduxDispatch'

import * as actions from '../../../store/actions/authAction'
import { updateActiveMenuItem } from '../../../utils/ScreenUtils'

function onLogout() {
  reduxDispatch(actions.logoutStart())
}

function Logout() {
  // Para somente desativar o item do menu
  updateActiveMenuItem('', '')

  onLogout()

  return <Redirect to="/home" />
}

export default Logout
