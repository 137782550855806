import React from 'react';


/**
 * Componente para exibir um check box com um label do lado direito.
 */
export default class CheckBoxLabelHorizontal extends React.Component {

    render() {

        // Para exibir um ícone SVG ao lado do checkbox
        if (this.props.iconLabel) {
            return <div id={this.props.id} className={'verticalCenterTextAndSVG checkBoxLabelHorizontal ' + (this.props.className || '')}>
                <label className='verticalCenterInputAndSVG'>
                    <input ref={ref => this.checkBox = ref} type='checkbox' {...this.props.inputProps} />{this.props.label}
                </label>
            </div>;
        }

        // Para exibir texto ao lado do checkbox
        return <div id={this.props.id} className={'verticalCenterTextAndSVG checkBoxLabelHorizontal ' + (this.props.className || '')}>
            <label><input ref={ref => this.checkBox = ref} type='checkbox' {...this.props.inputProps} /> <span className='Lighter'>{this.props.label}</span></label>
        </div>;
    }
}
