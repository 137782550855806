import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { FeriadosFormTipo, feriadoTipo, mesTipo, quadroHorariosTipo } from "../../../utils/QuadroHorariosUtils/quadroHorariosTypes"
import { reduxDispatch } from "../../../utils/reduxUtils/reduxDispatch"
import { getApi } from "../../../utils/SecureConnectionUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import { dispatchTipo } from "../../../store/actions/acaoTemplate"
import { appDialogHide, appDialogShow } from "../../../store/actions/appAction"
import { cadastroSaveUpdate } from "../../../store/actions/cadastroAction"

import Icones from "../../../assets/icones/Icones"
import { genBreadCrumbs } from "../../UI/BreadCrumbs/genBreadCrumbs"
import BarraAcoesButton from "../../UI/Toolbar/BarraAcoesButton"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"
import { DialogFeriadosEdicao } from "../dialog/DialogFeriadosEdicao"

import "./FeriadosForm.css";

const editarDialog = (props: FeriadosFormTipo, feriadoSelecionado?: feriadoTipo) => {
  reduxDispatch((dispatch: dispatchTipo) => {
    dispatch(
      appDialogShow(
        <DialogFeriadosEdicao
          cadastroDados={props.cadastroDados}
          feriadoSelecionado={feriadoSelecionado ? {...feriadoSelecionado} : undefined}
          salvarDados={salvarDados}
          esconderDialog={() => dispatch(appDialogHide())}
        />,
        feriadoSelecionado ? getStrings().editHoliday : getStrings().newHoliday,
        { overflow: 'unset' },
        undefined,
        () => { },
        () => { },
        { overflow: 'unset' }
      ));
  });
}

const excluirFeriado = (cadastroDados: quadroHorariosTipo | null, dataFeriado: string) => {
  if (!cadastroDados) {
    return;
  }

  const novoFeriadoList: feriadoTipo[] = [];

  cadastroDados.feriadoList.forEach(feriado => {
    if (`${feriado.ano}-${feriado.mes}-${feriado.dia}` !== dataFeriado) {
      novoFeriadoList.push(feriado);
    }
  });

  salvarDados({...cadastroDados, feriadoList: novoFeriadoList});
}

const salvarDados = (formData: quadroHorariosTipo) => {
  reduxDispatch(cadastroSaveUpdate(formData, getURIFromEntity(formData, true), getApi('quadroHorarios'), null, {}));
}

const BarraQuadroHorario = (props: FeriadosFormTipo) => {
  const AiOutlineCalendar = Icones.ai.AiOutlineCalendar;
  const IoMdLock = Icones.io.IoMdLock;
  const IoMdUnlock = Icones.io.IoMdUnlock;

  return <div id="toolbar" className="toolbar">
    <div className="toolbar-content">
      <WidthAwareDiv>
        <div className="bar-container" >
          <BarraAcoesButton
            id="back-button"
            buttonGridClassName="bar-item"
            key="back"
            isPrimary={true}
            commandMethod={() => props.updateExibirFeriados(false)}
            label={"\u003c"}
            title={getStrings().back}
          />
          <BarraAcoesButton
            id="lock-button"
            buttonGridClassName="bar-item"
            style={props.cadastroDados?.forcarEmpresaFechada ? {background: 'rgb(225,20,20)', color: 'rgb(255,255,255)'} : {}}
            key="lock"
            isPrimary={false}
            commandMethod={() => props.perguntaForcarEmpresaFechada(props.cadastroDados?.forcarEmpresaFechada)}
            label={props.cadastroDados?.forcarEmpresaFechada ? <IoMdLock /> : <IoMdUnlock />}
            title={props.cadastroDados?.forcarEmpresaFechada ? getStrings().cancelForceCloseStore : getStrings().forceCloseStore}
          />
          <BarraAcoesButton
            id="holidays-button"
            buttonGridClassName="bar-item"
            key="holidays"
            isPrimary={false}
            commandMethod={() => props.updateExibirFeriados(false)}
            label={<AiOutlineCalendar />}
            title={getStrings().holiday("s")}
          />
          <BarraAcoesButton
            id="new-item-button"
            buttonGridClassName="bar-item"
            key="add"
            isPrimary={true}
            commandMethod={() => editarDialog(props)}
            isDisabled={false} label={"\u002B"}
            title={getStrings().newRegister}
            attractSubmit={false}
          />
        </div>
      </WidthAwareDiv>
    </div>
  </div>
}

class FeriadosForm extends React.Component<FeriadosFormTipo> {

  render() {
    const GoTrashcan = Icones.go.GoTrashcan;

    const feriados: feriadoTipo[] = this.props.cadastroDados?.feriadoList || [];
    const meses: mesTipo[] = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const mapMeses: Record<string, feriadoTipo[]> = {};

    const anoAtual = new Date().getFullYear();

    feriados
      .sort((a, b) => {
        const dataA = new Date(anoAtual, a.mes, a.dia);
        const dataB = new Date(anoAtual, b.mes, b.dia);
        return dataA > dataB ? 1 : dataA < dataB ? -1 : 0;
      })
      .forEach(feriado => {
        const mes = meses[feriado.mes];
        const lista = mapMeses[mes];
        mapMeses[mes] = lista ? [...lista, { ...feriado }] : [{ ...feriado }];
      });

    return <div>
      {genBreadCrumbs(getStrings().holiday('s'), getStrings().registers)}
      {<BarraQuadroHorario {...this.props} />}
      <WidthAwareDiv>
        <div id="feriados-form">
          <div className="meses">
            {meses.map(mes => {
              return !mapMeses[mes]
                ? null
                : <div key={`__mes_${mes}__`} className="meses__mes">
                  <div className="meses__mes__nome">
                    {getStrings().months[mes]}
                  </div>
                  {mapMeses[mes].map(feriado => {
                    return <div
                      key={`__feriado_${feriado.ano}-${feriado.mes}-${feriado.dia}__`}
                      className="meses__mes__feriado"
                      onClick={() => editarDialog(this.props, feriado)}
                      >
                      <div className="feriado-container">
                        <button onClick={(event) => {
                            event.stopPropagation();
                            excluirFeriado(this.props.cadastroDados, `${feriado.ano}-${feriado.mes}-${feriado.dia}`);
                          }}>
                            <GoTrashcan />
                        </button>
                        <div className="feriado-info">
                        <div className="feriado-data">
                          <div className="data-nome">
                            {`${feriado.dia.toString().padStart(2 ,'0')} - ${feriado.nome}`}
                          </div>
                        </div>
                        <div className="feriado-horarios">
                          {feriado.horarioFeriadoList?.length
                            ? feriado.horarioFeriadoList.map(horario => {
                              return <div
                                key={`__horario_${horario.horaInicial}-${horario.horaFinal}__`}
                                className="feriado-horarios__horario">
                                {`${horario.horaInicial} - ${horario.horaFinal}`}
                              </div>
                            })
                            : getStrings().closed.toUpperCase()
                          }
                        </div>
                        </div>
                      </div>
                    </div>
                  })}
                </div>
            })}
          </div>
        </div>
      </WidthAwareDiv>
    </div>
  }
}

const mapStateToProps = (state: any) => ({
  locale: state.idiomaReducer.locale,
  cadastroDados: state.cadastroReducer.cadastroDados
});

export default connect(mapStateToProps)(FeriadosForm);