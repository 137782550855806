import { botaoVoltarReducerTipo } from "./botaoVoltarReducer";
import criarAction from "../../factory/criarAction";

const tiposDeAcoes = {
    DEFINIR_BOTAO_VOLTAR: 'DEFINIR_BOTAO_VOLTAR:BOTAO_VOLTAR',
    LIMPAR_BOTAO_VOLTAR: 'LIMPAR_BOTAO_VOLTAR:BOTAO_VOLTAR',
}

export default tiposDeAcoes;

export const limparBotaoVoltar = () => function(dispatch: any) {
    dispatch(criarAction(undefined, tiposDeAcoes.LIMPAR_BOTAO_VOLTAR));
}

export const definirBotaoVoltar = (carga: botaoVoltarReducerTipo) => function(dispatch: any) {
    dispatch(criarAction(carga, tiposDeAcoes.DEFINIR_BOTAO_VOLTAR));
}
