import { cadastroEnderecoReducerTipo } from "./cadastroEnderecoReducer";
import criarAction from "../../factory/criarAction";

const tiposDeAcoes = {
  ATUALIZAR_CADASTRO: 'ATUALIZAR_CADASTRO:CADASTRO_ENDERECO',
  LIMPAR_CADASTRO: 'LIMPAR_CADASTRO:CADASTRO_ENDERECO',
};

export default tiposDeAcoes;

export function limparCadastro() {
  return function(dispatch: any) {
    dispatch(criarAction(undefined, tiposDeAcoes.LIMPAR_CADASTRO));
  }
}

export const atualizarCadastro = (carga: cadastroEnderecoReducerTipo) => function(dispatch: any) {
  dispatch(criarAction(carga, tiposDeAcoes.ATUALIZAR_CADASTRO));
}
