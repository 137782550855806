import React from "react"

import FeriadosForm from "../form/FeriadosForm"
import QuadroHorariosForm from "../form/QuadroHorariosForm"

import { reduxDispatch } from "../../../utils/reduxUtils/reduxDispatch"
import { updateActiveMenuItem } from "../../../utils/ScreenUtils"
import { requestErrorHandlerDefault } from "../../../utils/ErrorUtils"
import { getStrings } from "../../../utils/LocaleUtils"

import { APP_NOTIFICATION_TYPE_INFO_QUESTION } from "../../../store/actions/actionTypes"
import { appNotificationShow, appSpinnerHide, appSpinnerShow } from "../../../store/actions/appAction"

import { ClienteHttp } from "../../../apps/promo_app_frontend/nucleo/utils/utils"

export interface QuadroHorariosBoxType {}

function perguntaForcarEmpresaFechada(forcarEmpresaFechada: boolean | undefined) {
  reduxDispatch(appNotificationShow(
    forcarEmpresaFechada ? `${getStrings().cancelForceCloseStore}?` : `${getStrings().forceCloseStore}?`,
    APP_NOTIFICATION_TYPE_INFO_QUESTION,
    undefined,
    () => executaForcarEmpresaFechada(!forcarEmpresaFechada),
  ))
}

function executaForcarEmpresaFechada(forcarEmpresaFechada: boolean) {
  reduxDispatch(appSpinnerShow('forcarFecharLoja'))
  ClienteHttp.requisicaoServidor('quadroHorarios/forceCloseCompany', 'post', true, {params: {forceClose: forcarEmpresaFechada}})
    .then((response) => {
      handleResponse(response)
    })
    .catch(error =>
      reduxDispatch(requestErrorHandlerDefault(error))
    )
    .finally(() => {reduxDispatch(appSpinnerHide('forcarFecharLoja'))})
}

function carregaQuadroHorarios() {
  reduxDispatch(appSpinnerShow('carregaQuadroHorarios'))
  ClienteHttp.requisicaoServidor('quadroHorarios', 'get', true)
    .then((response) => {
      handleResponse(response)
    })
    .catch(error =>
      reduxDispatch(requestErrorHandlerDefault(error))
    )
    .finally(() => {reduxDispatch(appSpinnerHide('carregaQuadroHorarios'))})
}

function handleResponse(response: any) {
  const quadroHorarios = response.data || null
  reduxDispatch({
    type: 'CADASTRO_SWITCH_UI',
    state: 'STATE_CADASTRO_FORM',
    page: 0,
    pageSize: 0,
    cadastroDados: quadroHorarios,
    cadastroDadosIniciais: quadroHorarios,
    loading: false,
    operation: 'CADASTRO_EDIT'
  })
}

export function QuadroHorariosBox(_: QuadroHorariosBoxType) {
  const [firstTime, updateFirstTime] = React.useState(true)
  const [exibirferiados, updateExibirFeriados] = React.useState(false)

  if (firstTime) {
    updateFirstTime(false)
    updateActiveMenuItem('menuItemQuadroHorarios', 'menuItemCadastro')
    carregaQuadroHorarios()
  }
  return exibirferiados
    ? <FeriadosForm
      updateExibirFeriados={updateExibirFeriados}
      perguntaForcarEmpresaFechada={perguntaForcarEmpresaFechada}
    />
    : <QuadroHorariosForm
      updateExibirFeriados={updateExibirFeriados}
      perguntaForcarEmpresaFechada={perguntaForcarEmpresaFechada}
    />
}