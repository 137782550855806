import React from 'react';

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { formatNumber } from "../../../utils/NumberUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import CadastroTable from './CadastroTable';
import OptionButtons from '../optionButtons/OptionButtons';
import ProdutoTableModel from './ProdutoTableModel';
import BotaoChecado from '../../UI/botoes/BotaoChecado/BotaoChecado';

/**
 * Classe que repassa para o CadastroTable as informações para a montagem da tabela dos Produtos.
 */
export default class ProdutoTable extends React.Component {

    render() {
        log('ProdutoTable render');
        return <CadastroTable {...this.props} objetoCadastro='produto' sortProperty={'nome'} filterProperties={['nome', 'codigo']} tableModel={ProdutoTableModel}
            printInternalEnabled
            blankFormData={{
                enabled: true,
                codigo: '',
                multiplo: false,
                nome: '',
                preco: null,
                precoCusto: null,
                produtoMulitplosProduto: [],
                descricao: '',
                grupoProdutoList: [],
                tipoProdutoList: [],
                usoInterno: false,
                quantidadePesada: false,
                codigoImpresso: '',
                formatoCodigoImpresso: null
            }} registerHelp={getStrings().productTableHelp}
        />;
    }
}

/**
 * Classe que realiza a montagem das linhas da tabela dos Produtos.
 */
export class ProdutoTableRow extends React.Component {

    /**
     * Método que executa a montagem do componente.
     */
    render() {
        log('ProdutoTableRow render');

        let id = `${getURIFromEntity(this.props.cadastro)}/Checkbox`;

        return <tr>

            {/* Opções de manutenção */}
            <OptionButtons className='ButtonAlign' onUpdate={this.props.onUpdate} onDelete={this.props.onDelete} cadastro={this.props.cadastro} />

            {/* Dados do cadastro */}
            <td className='Lighter ButtonAlign' width='5%' >
                <BotaoChecado key={`__${id}__`} desativado={!this.props.cadastro.enabled} />
            </td>
            <td className='Lighter TextAlign' width='20%' >{this.props.cadastro.codigo}</td>
            <td className='Lighter TextAlign' width='60%' >{this.props.cadastro.nome}</td>
            <td className='Lighter NumberAlign' width='10%' >{formatNumber(this.props.cadastro.preco, 2)}</td>

            {/* <td className='Lighter ButtonAlign' width='5%' >
                <InputCustomizado id={'multiplo'} type='checkbox' name={'multiplo'} disabled={true} checked={this.props.cadastro.multiplo} />
            </td> */}
        </tr>;
    }
}
