const initialState = {
    appId: '580612729395742',
    version: 'v5.0',
};

const mapActions = {};

const fbAppDataReducer = (state = initialState, {type, payload}) => {
    if (Object.keys(mapActions).includes(type)) {
        return mapActions[type](payload, state);
    }

    return state;
}

export default fbAppDataReducer;