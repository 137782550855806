import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import moment from "moment"

import * as AuthUtils from "../../../utils/AuthUtils"
import { isEmpresaPainel } from "../../../utils/CompanyUtils"
import { nullOrUndefined } from "../../../utils/ComparatorsUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { goBack } from "../../../utils/NavigatorUtils"

import { STATE_CONTROLE_VENDA_NOVA_VENDA } from "../../../store/reducers/controleVenda/controleVendaReducer"
import { NIVEL_USUARIO_SEM_EMPRESA_VENDA } from "../../../store/reducers/empresaSelectorReducer"
import * as appActions from "../../../store/actions/appAction"
import { notify } from "../../../store/actions/companyInformationAction"
import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"
import { atualizarEntradaBusca } from "../../../store/reducers/entradas/entradaBusca/entradaBuscaActions"
import * as manutencaoVendaActions from "../../../store/actions/controleVenda/manutencao/manutencaoVendaAction"

import BuildButton from "../../UI/Toolbar/BarraAcoesButton"
import DialogCancelSaleSaleItem from "../dialog/DialogCancelSaleSaleItem"
import Icones from "../../../assets/icones/Icones"
import InputCustomizado from "../../UI/Input/InputCustomizado"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

import "../../../assets/css/gradient-animation.css";

/**
 * Classe que realiza a montagem da barra de ações para as visualizações de cadastros.
 */
class BarraAcoesManutencaoVenda extends React.Component {

  /**
   * Decide se deve voltar para a tela de origens ou a tela de vendas, dependendo se a tela de vendas terá vendas não encerradas para exibir.
   */
  exibeGridAnterior = () => {
    log('BarraAcoesManutencaoVenda exibeGridAnterior');

    // Se for usuário sem vínculo com empresa
    if (!this.props.isCargo) {
      if (this.props.clientSaleAmount) {
        this.props.goTo('/pedidos');
        return;
      }
      this.props.setupSaleNoCompany();
    }
    // Se for empresa do ramo Painel, não há grid anterior, então volta para a tela anterior.
    else if (isEmpresaPainel(this.props.ramoEmpresa)) {
      goBack();
    }
    // Se ainda for nova venda e a origem da venda não tiver outras vendas, volta para a tela de origens.
    else if (this.props.vendaAmount === 0) {

      this.props.exibeGridOrigemVendaEmUso();
    }
    // Se for nova venda mas a origem tiver outras vendas, ou se for venda persistida, volta para as vendas da origem.
    else {
      this.props.exibeGridVendaFromOrigem();
    }
  }

  render = () => {
    log('BarraAcoesManutencaoVenda render');

    let disableItems = this.props.disableItems || (this.props.nivelUsuarioSemEmpresa !== NIVEL_USUARIO_SEM_EMPRESA_VENDA);

    let disableSend = (!this.props.hasNewSaleItemToSend) || (this.props.isCargo && (!AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_ENVIO])));

    let disableClose = this.props.state === STATE_CONTROLE_VENDA_NOVA_VENDA;

    /**
     * Se usuário não tiver permissão, esconde o botão de cancelar venda.
     */
    let showCancel = (!this.props.isCargo) || AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_EXCLUSAO]);

    /**
     * Se usuário não tiver permissão, esconde o botão de abrir a tela de itens.
     */
    let showItems = (!this.props.isCargo) || AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_ENVIO, AuthUtils.PERMISSAO_VENDA_EXCLUSAO]);

    /**
     * Se usuário não tiver permissão, esconde o botão de abrir a tela de pagamento.
     */
    let showPay = (!this.props.isCargo) || AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_PAGAMENTO]);

    const MdFormatListBulleted = Icones.md.MdFormatListBulleted;
    const MdAttachMoney = Icones.md.MdAttachMoney;
    const MdSend = Icones.md.MdSend;
    const MdCheck = Icones.md.MdCheck;
    const MdClose = Icones.md.MdClose;
    const MdNotificationsNone = Icones.md.MdNotificationsNone;
    const FaRegAddressCard = Icones.fa.FaAddressCard;
    const AiOutlineFileDone = Icones.ai.AiOutlineFileDone;

    const {
      pedido,
      buscarEstado,
    } = this.props;

    const estado = (buscarEstado || (() => ({})))();
    const idEntregaVenda = estado.cadastroComanda?.entregaVenda?.id;
    const editandoPedido = estado.editandoPedido;

    const iconStyle = { width: 'auto', height: 'auto', maxWidth: '1em', maxHeight: '1em', verticalAlign: 'middle', paddingLeft: '0.3em', paddingRight: '0.3em', paddingBottom: '0.1em' };

    return <WidthAwareDiv id='toolbar' className={'toolbar' + (this.props.buscarEstado ? ' pedidos-toolbar' : '')}>
      <div className='toolbar-content'>
        <div className='bar-container' >
          {pedido || this.props.esconderBotaoVoltar
            ? null
            : <BuildButton id='back-button' key='back' isPrimary={true} commandMethod={() => { // Botão Voltar
              if (this.props.customGoBack) {
                this.props.customGoBack();
                return;
              }

              this.props.handleBack(() => this.exibeGridAnterior());
            }} label={'\u003c'} title={getStrings().back}
              buttonGridClassName='bar-item' />
          }

          {/* Botão Cancelar */}
          {((estado.modo !== 'venda') && ((!disableClose) && (showCancel || (!this.props.isCargo))))
            ? <BuildButton
              id='cancel-button'
              key='cancel'
              isPrimary={false}
              commandMethod={() => this.props.appDialogShow(<DialogCancelSaleSaleItem
                handleConfirm={cancelReason => this.props.cancelSale(this.props.vendaURI, cancelReason)}
              />, getStrings().cancelSale())}
              label={<MdClose />}
              title={getStrings().cancelSale()}
              buttonGridClassName='bar-item'
            />
            : null}

          {/* Botão Lista Itens */}
          {(!nullOrUndefined(estado.modo))
            ? (showItems) ? <BuildButton id="list-button" cellClassName='bar-button-primary-white' key='stop' isPrimary={true} commandMethod={(pedido) ? () => {
              this.props.atualizarEstado({ modo: 'exibe_itens_venda' });
            } : this.props.exibeItensVenda}
              label={<MdFormatListBulleted style={iconStyle} />} texto={getStrings().addedItems} title={getStrings().itemsList} isDisabled={disableItems} buttonGridClassName='bar-item' /> : ''

            : (showItems) ? <BuildButton id="list-button" key='stop' isPrimary={false} commandMethod={this.props.exibeItensVenda}
              label={<MdFormatListBulleted />} title={getStrings().itemsList} isDisabled={disableItems} buttonGridClassName='bar-item' /> : ''}

          {/* Botão Pagamento */}
          {(this.props.isCargo && nullOrUndefined(estado.modo) && showPay) ? <BuildButton id="pay-button" key='pay' isPrimary={false} commandMethod={this.props.exibeTelaPagamento}
            label={<MdAttachMoney />} title={getStrings().payment} buttonGridClassName='bar-item' isDisabled={this.props.disablePay} /> : ''}

          {(pedido && !nullOrUndefined(estado.modo) && !["Balcão", "Mesa", "Cartão", "Painel"].includes(estado.tipoOrigem) && !estado.usuarioCliente)
            ? <>
              {/* Botão cadastro comanda */}
              <BuildButton
                id='cadastro-button'
                cellClassName='bar-button-primary-white'
                key='cadastro'
                isPrimary={true}
                commandMethod={() => {
                  this.props.atualizarEstado({ modo: 'cadastrar_comanda' });
                }}
                label={<FaRegAddressCard style={iconStyle} />}
                texto={getStrings().deliveryInfo}
                title={getStrings().edit}
                isDisabled={false}
                buttonGridClassName='bar-item'
              />
            </>
            : null
          }
          {<>
            {/* Dos três botões a seguir, somente um é exibido por vez */}

            {this.props.showPrimarySend && (!estado.modo || !estado.usuarioCliente) ? (((!this.props.hasNewSaleItemToSend) && (!this.props.isCargo))
              // Botão Chamar Garçom
              ? <BuildButton
                id='next-button'
                key='add'
                isSubmit={true}
                isPrimary={true}
                isDisabled={false}
                label={<MdNotificationsNone />}
                title={getStrings().callWaiter}
                commandMethod={() => this.props.notify(this.props.origemVenda)}
                buttonGridClassName={'bar-item'}
              />
              // Botão Enviar
              : (['venda', 'exibe_itens_venda', 'cadastrar_comanda'].includes(estado.modo))
                ? <BuildButton
                  id='next-button'
                  key='next'
                  isPrimary={true}
                  commandMethod={(pedido && nullOrUndefined(idEntregaVenda) && !["Balcão", "Mesa", "Cartão", "Painel"].includes(estado.tipoOrigem))
                    ? () => this.props.atualizarEstado({ modo: 'cadastrar_comanda' })
                    : (evt) => {
                      this.props.handleSend(evt);
                    }}
                  label={<MdSend style={iconStyle} />}
                  texto={getStrings().sendItems}
                  title={getStrings().sendItems}
                  isDisabled={disableSend}
                  buttonGridClassName='bar-item'
                  // Só habilita o brilho se o botão estiver habilitado e se houver itens a enviar
                  cellClassName={' bar-button-primary-white ' + (((!disableSend) && this.props.hasNewSaleItemToSend) ? ' primary-shine' : '')}
                />
                : <BuildButton
                  id='next-button'
                  key='next'
                  isPrimary={true}
                  commandMethod={this.props.handleSend}
                  label={<MdSend />}
                  title={getStrings().sendItems}
                  isDisabled={disableSend}
                  buttonGridClassName='bar-item'
                  // Só habilita o brilho se o botão estiver habilitado e se houver itens a enviar
                  cellClassName={((!disableSend) && this.props.hasNewSaleItemToSend) ? ' primary-shine' : ''}
                />
            )
              : ''}

            {(editandoPedido && pedido && !nullOrUndefined(estado.modo))
              ? <>
                {/* Botão para finalizar a edição da comanda */}
                <BuildButton id='finalizar-edicao-button' cellClassName='bar-button-primary-white' key='finalizar-edicao' isPrimary={true} commandMethod={() => {
                  const cadastroComanda = this.props.buscarEstado().cadastroComanda;

                  const novoEstado = {
                    editandoPedido: false,
                    cadastroComanda: {
                      ...cadastroComanda,
                      preCadastroUsuario: {
                        telefone: this.props.estadoInicialEstadoPedidos?.cadastroComanda?.telefone || '519',
                        nome: this.props.estadoInicialEstadoPedidos?.cadastroComanda?.nome || '',
                        endereco: this.props.estadoInicialEstadoPedidos?.cadastroComanda?.endereco || {
                          bairro: '',
                          cep: '',
                          cidade: '',
                          complemento: '',
                          endereco: '',
                          numero: '',
                          pais: null,
                          uf: '',
                          pontoReferencia: '',
                        }
                      },
                      entregaVenda: {
                        idEntregaVenda: null,
                        observacao: '',
                        teleEntrega: true,
                        dataHoraEntrega: moment()
                      }
                    },
                    tipoOrigem: '',
                    valorItensSelecionados: 0
                  };

                  this.props.atualizarEstado(novoEstado);
                }}
                  label={<AiOutlineFileDone style={iconStyle} />} texto={getStrings().finishEditing} title={getStrings().finishEditing} isDisabled={false} buttonGridClassName='bar-item' />
              </>
              : null
            }

            {/* Botão Pagar */}
            {this.props.isCargo && this.props.showPrimaryPay ? <BuildButton id='next-button' key='handle_pay' isPrimary={true} commandMethod={this.props.handlePay}
              label={<MdAttachMoney />} title={getStrings().payment} isDisabled={false} buttonGridClassName='bar-item'
              cellClassName=' primary-shine' /> : ''}

            {/* Botão Encerrar */}
            {this.props.showPrimaryClose ? <BuildButton id='next-button' key='close_vendas' isPrimary={true} commandMethod={this.props.handleEncerramento}
              label={<MdCheck />} title={getStrings().close} isDisabled={disableClose} buttonGridClassName='bar-item'
              cellClassName={this.props.attractToClose ? ' primary-shine' : ''} /> : ''}
          </>
          }
        </div>
        {<div className='search' style={{ marginTop: '1em' }}>
          <InputCustomizado newApi type='text' placeholder={getStrings().search} value={this.props.entradaBuscaReducer.texto} onChange={({ target: { value } }) => {
            this.props.dispatch(atualizarEntradaBusca({ texto: value }));
          }} />
        </div>}
      </div>
    </WidthAwareDiv>;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  entradaBuscaReducer: state.entradaBuscaReducer,
  clientSaleAmount: state.controleVendaReducer.clientSaleAmount,
  hasNewSaleItemToSend: state.controleVendaReducer.hasNewSaleItemToSend,
  isCargo: state.empresaSelectorReducer.cargo ? true : false,
  nivelUsuarioSemEmpresa: state.controleVendaReducer.nivelUsuarioSemEmpresa,
  state: state.controleVendaReducer.state,
  ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa,
  vendaAmount: state.controleVendaReducer.vendaAmount
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  notify: () => dispatch(notify()),

  appDialogShow: (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles, onCloseClicked, onOverlayClicked) =>
    dispatch(appActions.appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles, onCloseClicked, onOverlayClicked)),
  appDialogHide: () => dispatch(appActions.appDialogHide()),
  goTo: path => dispatch(push(path)),

  cancelSale: (vendaURI, cancelReason) => dispatch(manutencaoVendaActions.cancelSale(vendaURI, cancelReason)),
  dispatch,
  exibeGridOrigemVendaEmUso: () => dispatch(controleVendaActions.exibeGridOrigemVendaEmUso()),
  exibeGridVendaFromOrigem: () => dispatch(manutencaoVendaActions.exibeGridVendaFromOrigem()),
  exibeItensVenda: () => dispatch(manutencaoVendaActions.exibeItensVenda()),
  exibeTelaPagamento: () => dispatch(manutencaoVendaActions.exibeTelaPagamento()),
  handleBack: (confirmMethod, ignoreCode) => dispatch(manutencaoVendaActions.handleBack(confirmMethod, ignoreCode)),
  setupSaleNoCompany: () => dispatch(controleVendaActions.setupSaleNoCompany()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BarraAcoesManutencaoVenda);
