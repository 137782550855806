import { SecureConnection } from './Config';
import { isMobile } from './NavigatorUtils';

export const forceHttps = SecureConnection.forceHttps;
const cert = SecureConnection.cert;
const recaptcha_test_enviroment = SecureConnection.recaptcha_test_enviroment;

/**
 * Constante que armazena pedaço da URL que pode ser do projeto de teste em produção
 * 
 * * Desenvolvimento e Produção: `api`
 * * Teste em Produção: `api_test`
 */
export const urlApi = (localStorage.getItem('apiTestEnabled') === 'true') ? 'api_test' : 'api';
export const isHttp = SecureConnection.isHttp;
export const hostname = !isHttp && cert && !isMobile && SecureConnection.cert_hostname ? SecureConnection.cert_hostname : window.location.hostname; // hostname (pode ser do certificado ou url local)
export const recaptcha_site_key = recaptcha_test_enviroment ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' : '6LfR1IYUAAAAAA8xNbwKQ7p29EXHElm21dApAAdy'; // chave teste | chave produção do recaptcha

/**
 * 
 * @param {String} api 
 * @param {Number} custom_port 
 */
export const getUrl = (api, custom_port) => `${SecureConnection.protocol}://${hostname}:${custom_port ? custom_port : SecureConnection.port}${api}`; // Função que retorna url para acesso as APIs

export const getFrontUrl = (params = '') => `${window.location.protocol}//${window.location.host}/${params}`;

/**
 * Constante que armazena o local do Banco de Dados da Aplicação.
 * 
 * * Desenvolvimento: `https://localhost:9443/api`
 * * Produção sem domínio: `https://93.188.163.126:9443/api`
 * * Produção com domínio: `https://ampix.mobi:9443/api`
 */
export const urlDatabase = getUrl(`/${urlApi}`);

export const getApi = (api) => getUrl(`/${urlApi}/${api}`);
