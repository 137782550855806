//@ts-check
import "./CompraTela.css"

import React from "react"
import { Texto, Entrada } from "../../../nucleo/nucleo"
import { LocalidadeUtils, ClienteHttp } from "../../../nucleo/utils/utils"
import { connect } from "react-redux"
import { mostrarDialog } from "../../../nucleo/redux/dialog/dialogActions"
import { definirBotaoPrincipal } from "../../../nucleo/redux/botoes/botaoPrincipal/botaoPrincipalActions"
import { adicionarPedido } from "../../../nucleo/redux/pedidos/pedidosActions"
import { polularFormasPagamento } from "../../../nucleo/redux/pedidos/formasPagamento/formasPagamentoActions"
import { polularEmpresa } from "../../../nucleo/redux/empresa/empresaActions"
import { getURIFromEntity } from "../../../nucleo/utils/URIUtils/URIUtils"
import { roundNumber } from "../../../nucleo/utils/NumberUtils/NumberUtils"
import AvisoDialog from "../../dialogs/AvisoDialog/AvisoDialog"
import { distanciaEntreDoisPontos } from "../../../../../utils/MapUtils"
import { MODO_COBRANCA_TELE_ENTREGA_POR_BAIRRO, MODO_COBRANCA_TELE_ENTREGA_POR_DISTANCIA } from "../../../../../store/reducers/empresaSelectorReducer"
import ErroDialog from "../../dialogs/ErroDialog/ErroDialog"
import { definirBotaoVoltar } from "../../../nucleo/redux/botoes/botaoVoltar/botaoVoltarActions"

class CompraTela extends React.Component {
  /**
   * 
   * @type {{local: 'endereco' | '', formaPagamento: string, numeroDeParcelas: number, dataEntrega: string, horaEntrega: string, observacao: string | null, valorTeleEntrega: number}}
   */
  state = {
    local: 'endereco',
    formaPagamento: '',
    numeroDeParcelas: 1,
    dataEntrega: '',
    horaEntrega: '',
    observacao: null,
    valorTeleEntrega: 0,
  }

  validaOnClick = true;

  validaDataHoraEntrega = () => {
    let mensagem = '';

    if (!this.state.dataEntrega) {
      mensagem = this.state.local === 'endereco' ? LocalidadeUtils.obterTraducao().register.uninformed.deliveryDate : LocalidadeUtils.obterTraducao().register.uninformed.takeAwayDate;
    }
    else if (!this.state.horaEntrega) {
      mensagem = this.state.local === 'endereco' ? LocalidadeUtils.obterTraducao().register.uninformed.deliveryHour : LocalidadeUtils.obterTraducao().register.uninformed.takeAwayHour;
    }

    if (mensagem) {
      this.props.dispatch(mostrarDialog({conteudo: () => <AvisoDialog mensagem={mensagem} />}));
      return false;
    }
    return true;
  }

  componentDidMount() {
    const { idDaEmpresa, idOrigemVendaDaEmpresa } = this.props.parametrosEmpresa;

    idOrigemVendaDaEmpresa && ClienteHttp.obterClienteHttp().get(ClienteHttp.getApi('formaPagamentos/findListToApp'), ClienteHttp.getHeaders({empresaId: idDaEmpresa}))
    .then((resposta) => {
      /**
       * 
       * @type {Array<{codigo: string, nome: string, usaPagSeguro: boolean}>}
       */
      const dados = ((resposta.data || {}).content || []);

      /**
       * 
       * @type {import('../../../nucleo/redux/pedidos/formasPagamento/formasPagamentoReducer').formasPagamentoReducerTipo}
       */
      let formasPagamento = {};
      
      if (dados.length > 0) {
        for (const formaPagamento of dados) {
          formasPagamento[getURIFromEntity(formaPagamento)] = {nome: formaPagamento.nome, usaPagSeguro: formaPagamento.usaPagSeguro};
        }
        
        this.props.dispatch(polularFormasPagamento(formasPagamento));
        this.setState({formaPagamento: getURIFromEntity(dados[0])});
      }
    });

    idDaEmpresa && ClienteHttp.obterClienteHttp().get(ClienteHttp.getApi(`empresas/${idDaEmpresa}`), ClienteHttp.getHeaders())
    .then((resposta) => {

      if (resposta.data) {
        /**
         * 
         * @type {import('../../../nucleo/redux/empresa/empresaReducer').empresaReducerTipo}
         */
        const empresa = {
          empresa: {
            id: idDaEmpresa,
            uri: getURIFromEntity(resposta),
            cnpj: resposta.data.cnpj,
            razaoSocial: resposta.data.razaoSocial,
            nomeFantasia: resposta.data.nomeFantasia,
          },
          parametrosEmpresa: {
            filtroDeProdutos: resposta.data.parametrosEmpresa.filtroDeProdutos,
            modoCobrancaTeleEntrega: resposta.data.parametrosEmpresa.modoCobrancaTeleEntrega,
            valorTeleEntregaPorKm: resposta.data.parametrosEmpresa.valorTeleEntregaPorKm,
            tipoCadastroUsuario: resposta.data.parametrosEmpresa.tipoCadastroUsuario,
          },
          endereco: {
            bairro: resposta.data.enderecoEmpresa.bairro,
            cep: resposta.data.enderecoEmpresa.cep,
            cidade: resposta.data.enderecoEmpresa.municipio,
            codigoCidade: resposta.data.enderecoEmpresa.codigoMunicipioIbge,
            complemento: resposta.data.enderecoEmpresa.complemento,
            estado: resposta.data.enderecoEmpresa.uf,
            numero: resposta.data.enderecoEmpresa.numero,
            pais: {
              codigo: resposta.data.enderecoEmpresa.pais._links.self.href.replace(ClienteHttp.getApi('paises/'), ''),
              iso: resposta.data.enderecoEmpresa.pais.iso,
              nome: resposta.data.enderecoEmpresa.pais.nome,
            },
            rua: resposta.data.enderecoEmpresa.endereco,
          },
          contato: {
            email: resposta.data.contatoEmpresa.email,
            telefone: resposta.data.contatoEmpresa.telefone,
          }
        };
      
        this.props.definirBotaoPrincipal({
          executarAcao: (
            /**
             * 
             * @param {any} _ 
             */
            (_) => {
            if (this.validaOnClick) {
              if (!this.props.webAppMobiAppReducer.obrigaDataEntrega || this.validaDataHoraEntrega()) {
                this.validaOnClick = false;
                this.props.adicionarPedido(
                  this.state.valorTeleEntrega,
                  this.state.formaPagamento,
                  this.state.local === 'endereco',
                  this.state.numeroDeParcelas,
                  this.state.dataEntrega,
                  this.state.horaEntrega,
                  this.props.parametrosEmpresa,
                  this.props.webAppMobiAppReducer.vendaMobiAppEncerrada,
                  () => {
                    this.validaOnClick = true;
                  },
                  this.state.observacao);
              }
            }
          }),
        });

        this.props.dispatch(definirBotaoVoltar({
          botaoVoltarCustomizado: undefined
        }));

        this.props.dispatch(polularEmpresa(empresa));
      }
    })
    .catch(() => {
      this.props.dispatch(mostrarDialog({conteudo: () => <ErroDialog mensagem={LocalidadeUtils.obterTraducao().phrases.shoppingFailed}/>}));
    });

    this.calculaValorTeleEntrega();

    this.forceUpdate();
  }

  /**
   * 
   * @param {any} evt 
   */
  observacaoValueHandler = ({target: {value}}) => {
    this.setState({observacao: value || null});
  }

  /**
   * 
   * @param {import('./types').compraTelaPropsType} _prevProps 
   * @param {{local: 'endereco' | '', formaPagamento: string, numeroDeParcelas: number, dataEntrega: string, horaEntrega: string, observacao: string | null, valorTeleEntrega: number}} prevState 
   */
  componentDidUpdate(_prevProps, prevState) {
    if (prevState.local !== this.state.local) {
      this.calculaValorTeleEntrega();
    }
  }

  calculaValorTeleEntrega = () => {

    if (this.state.local === 'endereco') {
      
      const modoCobrancaTeleEntrega = this.props.webAppEmpresaReducer.parametrosEmpresa.modoCobrancaTeleEntrega;

      if (modoCobrancaTeleEntrega === MODO_COBRANCA_TELE_ENTREGA_POR_BAIRRO) {
        this.calculaValorTeleEntregaPorBairro();
      }
      else if (modoCobrancaTeleEntrega === MODO_COBRANCA_TELE_ENTREGA_POR_DISTANCIA) {
        this.calculaValorTeleEntregaPorDistancia();
      }
      else {
        this.setState({ valorTeleEntrega: 0 });
      }
    }
    else {
      this.setState({ valorTeleEntrega: 0 });
    }
  }

  calculaValorTeleEntregaPorBairro = () => {
    
    const idDaEmpresa = this.props.parametrosEmpresa.idDaEmpresa;

    ClienteHttp.obterClienteHttp().get(ClienteHttp.getApi(`bairros/porEmpresa/${idDaEmpresa}`), ClienteHttp.getHeaders())
      .then((resposta) => {
        const bairros = resposta?.data?.content || [];
        const bairroNome = this.props.webAppUsuarioReducer.endereco.bairro;
        const bairroSelecionado = bairros.filter(
          /**
           * 
           * @param {any} bairro 
           * @returns 
           */
          (bairro) => bairro.nome.trim().toLowerCase() === bairroNome.trim().toLowerCase())[0];

        if (bairroSelecionado) {
          const valorTeleEntrega = roundNumber(bairroSelecionado.valorEntrega, 2) || 0;
          this.setState({ valorTeleEntrega: valorTeleEntrega });
        }
        else {
          this.calculaValorTeleEntregaPorDistancia();
        }
      });
  }

  calculaValorTeleEntregaPorDistancia = () => {

    const valorTeleEntregaPorKm = this.props.webAppEmpresaReducer.parametrosEmpresa.valorTeleEntregaPorKm;

    if (valorTeleEntregaPorKm) {
      const enderecoUsuario = this.props.webAppUsuarioReducer.endereco;
      const enderecoEmpresa = this.props.webAppEmpresaReducer.endereco;
      const origem = `${enderecoUsuario.rua}, ${enderecoUsuario.numero} - ${enderecoUsuario.bairro}, ${enderecoUsuario.cidade} - ${enderecoUsuario.estado}, ${enderecoUsuario.cep}, ${enderecoUsuario.pais}`;
      const destino = `${enderecoEmpresa.rua}, ${enderecoEmpresa.numero} - ${enderecoEmpresa.bairro}, ${enderecoEmpresa.cidade} - ${enderecoEmpresa.estado}, ${enderecoEmpresa.cep}, ${enderecoEmpresa.pais}`;

      distanciaEntreDoisPontos(origem, destino,
        /**
         * 
         * @param {any} distancia 
         */
        (distancia) => {
          const valorTeleEntrega = roundNumber(distancia / 1000 * valorTeleEntregaPorKm, 2) || 0;
          this.setState({ valorTeleEntrega: valorTeleEntrega });
        });
    }
    else {
      this.setState({ valorTeleEntrega: 0 });
    }
  }

  /**
   * 
   * @param {any} endereco 
   * @returns 
   */
  Endereco = (endereco) => {
    return <div key='__endereco__' id='endereco-usuario'>
      <Entrada value={this.state.local} onChange={
        /**
         * 
         * @param {{ target: { value: string, name: string } }} evt 
         */
        ({ target }) => { this.setState({ local: target.value }) }} name='local' type='seletor-unico' seletores={[
        { value: 'endereco', label: LocalidadeUtils.obterTraducao().phrases.receiveInThisAddress, defaultChecked: true },
        { value: 'estabelecimento', label: LocalidadeUtils.obterTraducao().phrases.receiveInEstablishiment },
      ]} />
      <div className='linha'>
        <div className='linha-item'>
          <Texto className='campo'>{`${LocalidadeUtils.obterTraducao().words.adress.zipCode}:`}</Texto>
          <Texto className='valor'>{`${endereco.cep}`}</Texto>
        </div>
      </div>
      <div className='linha'>
        <div className='linha-item'>
          <Texto className='campo'>{`${LocalidadeUtils.obterTraducao().words.adress.adress}:`}</Texto>
          <Texto className='valor'>{`${endereco.rua}`}</Texto>
        </div>
        <div className='linha-item'>
          <Texto className='campo'>{`${LocalidadeUtils.obterTraducao().words.adress.number}:`}</Texto>
          <Texto className='valor'>{`${endereco.numero}`}</Texto>
        </div>
      </div>
      {endereco.complemento &&
        <div className='linha'>
          <div className='linha-item'>
            <Texto className='campo'>{`${LocalidadeUtils.obterTraducao().words.adress.complement}:`}</Texto>
            <Texto className='valor'>{`${endereco.complemento}`}</Texto>
          </div>
        </div>
      }
      <div className='linha'>
        <div className='linha-item'>
          <Texto className='campo'>{`${LocalidadeUtils.obterTraducao().words.adress.hood}:`}</Texto>
          <Texto className='valor'>{`${endereco.bairro}`}</Texto>
        </div>
        <div className='linha-item'>
          <Texto className='campo'>{`${LocalidadeUtils.obterTraducao().words.adress.city}:`}</Texto>
          <Texto className='valor'>{`${endereco.cidade}`}</Texto>
        </div>
      </div>
      <div className='linha'>
        <div className='linha-item'>
          <Texto className='campo'>{`${LocalidadeUtils.obterTraducao().words.adress.state}:`}</Texto>
          <Texto className='valor'>{`${endereco.estado}`}</Texto>
        </div>
        <div className='linha-item'>
          <Texto className='campo'>{`${LocalidadeUtils.obterTraducao().words.adress.country}:`}</Texto>
          <Texto className='valor'>{`${LocalidadeUtils.obterTraducao().country.countryName(endereco.pais.iso)}`}</Texto>
        </div>
      </div>
    </div>
  }

  /**
   * 
   * @param {any} _ 
   * @returns 
   */
  Compra = (_) => {
    const enderecoUsuario = this.props.webAppUsuarioReducer.endereco;
    const enderecoEmpresa = this.props.webAppEmpresaReducer.endereco;
    const mobiApp = this.props.webAppMobiAppReducer;

    return <>
      {mobiApp.obrigaDataEntrega
        ? <div key='__entrega__' id='data-hora-entrega'>
          <Entrada
            obrigatorio
            type="date"
            label={this.state.local === 'endereco' ? LocalidadeUtils.obterTraducao().register.label.deliveryDate : LocalidadeUtils.obterTraducao().register.label.takeAwayDate}
            onChange={
              /**
               * 
               * @param {{ target: { value: string, name: string } }} evt 
               */
              ({ target }) => { this.setState({ dataEntrega: target.value }) }}
          />
          <Entrada
            obrigatorio
            type="time"
            label={this.state.local === 'endereco' ? LocalidadeUtils.obterTraducao().register.label.deliveryHour : LocalidadeUtils.obterTraducao().register.label.takeAwayHour}
            onChange={
              /**
               * 
               * @param {{ target: { value: string, name: string } }} evt 
               */
              ({ target }) => { this.setState({ horaEntrega: target.value }) }}
          />
        </div>
        : null
      }
      {this.state.local === 'endereco'
        ? this.Endereco(enderecoUsuario)
        : this.Endereco(enderecoEmpresa)
      }
      <div id='forma-pagamento'>
        <Entrada value={this.state.formaPagamento}
          placeholder={LocalidadeUtils.obterTraducao().words.paymentMethod}
          name='forma-pagamento'
          type='seletor-unico'
          onChange={
            /**
             * 
             * @param {any} event 
             * @returns 
             */
            (event) => this.setState({ formaPagamento: event.target.value })}
          seletores={Object.keys(this.props.webAppFormasPagamentoReducer).map(
            /**
             * 
             * @param {string} id 
             * @param {number} index 
             * @returns 
             */
            (id, index) => ({
            value: id,
            label: this.props.webAppFormasPagamentoReducer[id].nome,
            defaultChecked: index === 0,
          }))}
        />
        {this.state.local === 'endereco'
          ? <div className='forma-pagamento-totais'>
              <Texto className='campo'>{`${LocalidadeUtils.obterTraducao().orders.deliveryValue}:`}</Texto>
              <Texto className='valor'>{LocalidadeUtils.obterTraducao().currency.format(this.state.valorTeleEntrega)}</Texto>
            </div>
          : null
        }
        <div className='forma-pagamento-totais'>
          <Texto className='campo'>
            {`${LocalidadeUtils.obterTraducao().orders.totalValue}:`}
          </Texto>
          <Texto className='valor'>
            {LocalidadeUtils.obterTraducao().currency.format((([...Object.keys(this.props.webAppCarrinhoReducer.produtos).map((id) => {
              const produto = this.props.webappProdutosReducer.produtos[id] || this.props.webAppCarrinhoReducer.produtos[id];
              const quantidade = this.props.webAppCarrinhoReducer.produtos[id].quantidade;
              const { preco } = produto;
              const promocao = produto.promocao || { precoPromo: 0 };
              const { precoPromo } = promocao;
              const precoComDesconto = precoPromo || preco;

              const precoFinal = precoComDesconto * quantidade;

              return precoFinal;
            }), 0]).reduce(
              /**
               * 
               * @param {number} precoProdutoAnterior 
               * @param {number} precoProdutoAtual 
               * @returns 
               */
              (precoProdutoAnterior, precoProdutoAtual) =>
              precoProdutoAnterior + precoProdutoAtual) + this.state.valorTeleEntrega))}
          </Texto>
        </div>
      </div>
      <div id='observacao-geral'>
          <Texto>{LocalidadeUtils.obterTraducao().words.observation}</Texto>
          <Entrada value={this.state.observacao || ''} onChange={this.observacaoValueHandler} />
      </div>
    </>
  }

  render() {
    return <div key='tela' className='tela' id='compra-tela'>
      {this.props.webAppUsuarioReducer.verificaUsuarioLogado()
        ? <this.Compra />
        : null
      }
    </div>
  }
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  parametrosEmpresa: state.webAppParametrosEmpresaReducer,
  webappProdutosReducer: state.webAppProdutosReducer,
  webAppUsuarioReducer: state.webAppUsuarioReducer,
  webAppCarrinhoReducer: state.webAppCarrinhoReducer,
  webAppFormasPagamentoReducer: state.webAppFormasPagamentoReducer,
  webAppEmpresaReducer: state.webAppEmpresaReducer,
  webAppMobiAppReducer: state.webAppMobiAppReducer,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   * 
   * @param {number} valorTeleEntrega 
   * @param {string} formaPagamento 
   * @param {boolean} endereco 
   * @param {number} numeroDeParcelas 
   * @param {string} dataEntrega 
   * @param {string} horaEntrega 
   * @param {number} parametrosEmpresa 
   * @param {boolean} vendaMobiAppEncerrada 
   * @param {() => void|undefined} callback 
   * @param {string|null|undefined} observacao 
   * @returns 
   */
  adicionarPedido: (valorTeleEntrega, formaPagamento, endereco, numeroDeParcelas, dataEntrega, horaEntrega, parametrosEmpresa, vendaMobiAppEncerrada, callback, observacao = undefined) => dispatch(adicionarPedido(valorTeleEntrega, formaPagamento, endereco, numeroDeParcelas, dataEntrega, horaEntrega, parametrosEmpresa, vendaMobiAppEncerrada, callback, observacao)),
  dispatch,
  /**
   * 
   * @param {import('../../../nucleo/redux/botoes/botaoPrincipal/botaoPrincipalReducer').botaoPrincipalReducerTipo} carga 
   * @returns 
   */
  definirBotaoPrincipal: (carga) => dispatch(definirBotaoPrincipal(carga)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompraTela);
