import { updateObject } from "../../utils/ObjectUtils"

import * as actionTypes from "../actions/actionTypes"

/**
 * Estado Inicial, obrigatório no Reducer.
 */
export const initialState = {

  /**
   * Biblioteca para geração de caminhos SVG a partir de texto que precisa ser carregada com uma fonte específica.
   */
  textToSVG: null
}

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.CADASTRO_PRINT_TEXT_TO_SVG:
      return updateObject(state, {
        ...state,
        ...action
      })
    default: return state
  }
}

export default reducer
