//@ts-check
import React from 'react';
import { Botao, Texto } from '../../../nucleo/nucleo';

import { LocalidadeUtils } from '../../../nucleo/utils/utils';

import { connect } from 'react-redux';
import Icones from '../../../assets/icones/Icones';

/**
 * 
 * @param {import('./types').botaoComprarPropsType} props
 * @returns 
 */
function BotaoComprar(props) {
  const AvancarIcone = Icones.mobi.Avancar;

  return <Botao id='botao-comprar' className={'row ' + (props.className || '')} disabled={props.quantidadeDeProdutos <= 0} onClick={props.webAppBotaoPrincipalReducer.executarAcao}>
    <Texto>{LocalidadeUtils.obterTraducao().words.buy}</Texto>
    <AvancarIcone />
  </Botao>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  webAppBotaoPrincipalReducer: state.webAppBotaoPrincipalReducer,
  quantidadeDeProdutos: Object.keys(state.webAppCarrinhoReducer.produtos).length,
});

const mapActionToProps = () => ({});

export default connect(mapStateToProps, mapActionToProps)(BotaoComprar);
