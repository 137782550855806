import { updateObject } from "../../utils/ObjectUtils"

import * as actionTypes from "../actions/actionTypes"

export const STATE_SIGN_UP_BEGIN = "STATE_SIGN_UP_BEGIN"
export const STATE_SIGN_UP_FAILURE = "STATE_SIGN_UP_FAILURE"
export const STATE_SIGN_UP_SUCCESS = "STATE_SIGN_UP_SUCCESS"

const formRequiredText = ["nome", "sobrenome",]
const formRequiredAdditionalText = ["pais", "uf", "municipio", "endereco", "numero", "cep", "telefone"]
const formRequiredLoginText = ["email", "senha"]
const formRequiredTextStep0 = [...formRequiredText]
const formRequiredAdditionalTextStep0 = ["telefone"]
const formRequiredLoginTextStep0 = [...formRequiredLoginText]
const formRequiredAdditionalTextStep1 = formRequiredAdditionalText.filter((text) => text !== "telefone")

/**
 * 
 * @param {Record<string,any>} data
 * @returns {boolean}
 */
function formRequiredPerStep(state: Record<string,any>) {
  const { step } = state;

  switch (step) {
    case 0:
      return formRequiredLoginTextStep0.every(text => state.formData.login[text])
        && formRequiredTextStep0.every(text => state.formData[text])
        && formRequiredAdditionalTextStep0.every(text => state.formData.dadosAdicionais[text]);
    case 1:
      return formRequiredAdditionalTextStep1.every(text => state.formData.dadosAdicionais[text]);
    default:
      return formRequiredLoginText.every(text => state.formData.login[text])
        && formRequiredText.every(text => state.formData[text])
        && formRequiredAdditionalText.every(text => state.formData.dadosAdicionais[text]);
  }
}

export type signUpReducerType = {
  type: string|null,
  step: number,
  /**
   * Dados do formulário para cadastrar novo usuário
   */
  formData: {
    nome: string,
    sobrenome: string,
    login: {
      email: string,
      senha: string,
      //confirmacaoSenha: string,
    }
    dadosAdicionais: {
      dataNascimento: string,
      dataNascimentoOption: Record<string,any>|null,
      telefone: string,
      pais: string,
      paisOption: null,
      uf: string,
      municipio: string,
      bairro: string,
      endereco: string,
      numero: string,
      complemento: string,
      cep: string,
    }
    parametrosUsuario: {
      aceitarNotificacoes: boolean,
    }
  }

  /**
   * Se todos os campos obrigatórios já foram preenchidos.
   */
  formRequiredFilled: boolean,

  password_score: number,
  password_suggestions: string[],
  password_warning: string,

  /**
   * Token gerado pelo widget do reCaptcha que precisa ser validado na retaguarda.
   */
  reCaptchaToken: string|null,

  /**
   * Momento atual do cadastro.
   */
  state: string,
}

export const buildInitialState: () => signUpReducerType = () => ({
  type: null,
  step: 0,
  /**
   * Dados do formulário para cadastrar novo usuário
   */
  formData: {
    nome: "",
    sobrenome: "",
    login: {
      email: "",
      senha: "",
      //confirmacaoSenha: "",
    },
    dadosAdicionais: {
      dataNascimento: "",
      dataNascimentoOption: null,
      telefone: "",
      pais: "",
      paisOption: null,
      uf: "",
      municipio: "",
      bairro: "",
      endereco: "",
      numero: "",
      complemento: "",
      cep: "",
    },
    parametrosUsuario: {
      aceitarNotificacoes: true,
    },
  },

  /**
   * Se todos os campos obrigatórios já foram preenchidos.
   */
  formRequiredFilled: false,

  password_score: 0,
  password_suggestions: [],
  password_warning: "",

  /**
   * Token gerado pelo widget do reCaptcha que precisa ser validado na retaguarda.
   */
  reCaptchaToken: null,

  /**
   * Momento atual do cadastro.
   */
  state: STATE_SIGN_UP_BEGIN,
});

/**
 * Estado Inicial, obrigatório no Reducer.
 */
const initialState = buildInitialState();

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state: signUpReducerType = initialState, action: Partial<signUpReducerType>) => {

  switch (action.type) {
    case actionTypes.SIGN_UP:
    case actionTypes.SIGN_UP_STATE:
    case actionTypes.SIGN_UP_STEP:
    case actionTypes.SIGN_UP_TOKEN:
    case actionTypes.SIGN_UP_SCORE:
    case actionTypes.SIGN_UP_SUGGESTIONS:
    case actionTypes.SIGN_UP_WARNING:

      let updatedState = updateObject(state, {
        ...state
        , ...action
      }) as signUpReducerType;

      action.formRequiredFilled = formRequiredPerStep(updatedState);

      updatedState.formRequiredFilled = action.formRequiredFilled;

      // Basta controlar a construção do action no outro arquivo.
      return updatedState;
    default: return state;
  }
}

export default reducer;