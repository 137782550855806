import React from "react"
import { connect } from "react-redux"

import { getPageSize, setPageSize } from "../../../utils/StorageUtils/LocalStorageUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"

import * as appActions from "../../../store/actions/appAction"
import * as cadastroPrintActions from "../../../store/actions/cadastroPrintAction"

import InputCustomizado from "../../UI/Input/InputCustomizado"
import { Button } from "../../UI/Button/Button"

/**
 * Classe que monta a dialog para definição do número de registros que devem ser exibidos por página, na tabela de cadastros.
 */
class DialogPageable_ extends React.Component {
  pageSizeField

  /**
   * Método executado ao clicar no botão "Confirma" da dialog.
   * Realiza a chamada do método que irá atualizar o número de registros por página.
   */
  updatePageSize = () => {
    this.props.appDialogHide()
    setPageSize(this.pageSizeField.getMaskValue())
    this.props.updatePageSize(this.pageSizeField.getMaskValue())
  }

  /**
   * Método executado quando o campo do número de registros ganha o foco.
   * Seleciona todo o conteúdo do campo.
   */
  handleFocus = () => {
    this.pageSizeField.inputComponent.element.select()
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  componentDidMount = () => {
    this.pageSizeField.setMaskValue(this.props.forceNumeroRegistros
      ? (this.props.numeroRegistros || getPageSize(this.props.numeroRegistros))
      : getPageSize(this.props.numeroRegistros))
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.""
   * Necessário ao definir `getSnapshotBeforeUpdate`.
   */
  componentDidUpdate = () => {
    if (this.props.dialogShow) {
      this.componentDidMount()
    }
  }

  render() {
    return <>
      <InputCustomizado
        id="itemsPerPage"
        ref={input => this.pageSizeField = getReduxWrappedComponent(input)}
        inputType="masked"
        validacaoDados="numeroInteiro"
        type="text"
        label={getStrings().registersPerPage}
        max={1000}
        scale={0}
        padFractionalZeros={false}
        onFocus={this.handleFocus}
        custom={{ tabIndex: (this.props.tabIndex + 1) * -1 }} />

      <div className="dialog-horizontal-button-grid flow-buttons">
        <Button
          isDialog
          id="confirm-button"
          className="blue-button font-color"
          onClick={this.updatePageSize}
          style={{ margin: 0 }}
        >
          {getStrings().confirm}
        </Button>
      </div>
    </>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  tabIndex: state.appReducer.getTabIndex()
  , dialogShow: state.appReducer.dialogShow
  , locale: state.idiomaReducer
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  appDialogHide: () => dispatch(appActions.appDialogHide())
  , getRegisterList: (filterProperties, printExternalEnabled, printInternalEnabled) =>
    dispatch(cadastroPrintActions.getRegisterList(filterProperties, printExternalEnabled, printInternalEnabled))
})

const DialogPageable = connect(mapStateToProps, mapDispatchToProps)(DialogPageable_)

/**
 * Exporta o último argumento entre parênteses.
 */
export default DialogPageable
