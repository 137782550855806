import React from "react"

import { getStrings } from "../../../utils/LocaleUtils"

import CadastroTable from "./CadastroTable"
import MobiAppTableModel from "./MobiAppTableModel"

export type MobiAppTableType = {
  urlDataBase: string,
};

class MobiAppTable_ extends React.Component<MobiAppTableType> {
  render() {
    return <CadastroTable
      unique
      urlDataBase={this.props.urlDataBase}

      objetoCadastro="mobiApp"
      filterProperties={[]}
      registerHelp={getStrings().mobiAppTableHelp}

      tableModel={MobiAppTableModel}
      blankFormData={{
        valorLinkParametro: "",
        nomeExibicao: "",
      }}
    />;
  }
}

export const MobiAppTable = MobiAppTable_;
export default MobiAppTable;