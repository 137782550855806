import { log } from './LogUtils';
import { getURIFromEntity } from './URIUtils';
import { isStrictObject, isArray } from './ComparatorsUtils';

/**
 * Retorna se o produto tem um tipo com produção unitária marcada.
 * @param {Object} produto 
 */
export const isProdutoProducaoUnitaria = produto => {
    log('EntityUtils isProdutoProducaoUnitaria', { produto });
    return ((produto || {}).tipoProdutoList || []).some(tipoProduto => (tipoProduto || {}).producaoUnitaria);
}

/**
 * 
 * Replace inner entitys for uris
 */
export const referencedEntity = (entity) => {
    const newEntity = {};

    for(const key in entity) {
        const test = isArray(entity[key]) || isStrictObject(entity[key]);
        const value = test
            ? referencedEntity(entity[key])
            : entity[key];
        
        const uri = getURIFromEntity(value);
        newEntity[key] = uri ? uri : value;
    };

    return newEntity;
};


export default referencedEntity;