import { push } from "connected-react-router"

import { getIdFromURI, getURIFromEntity } from './URIUtils'
import referencedEntity from './EntityUtils'
import { getApi } from './SecureConnectionUtils'
import { removeLastScannedSaleSource } from './StorageUtils/LocalStorageUtils'
import { getPageNames } from './siteUtils/siteUtils'
import { reduxDispatch } from './reduxUtils/reduxUtils'

import { RamoEmpresa } from '../store/actions/empresaSelectorAction'
import { cadastroSave } from '../store/actions/cadastroAction'
import { axios } from '../store/actions/appAction'
import { removeSaleSourceCode } from './StorageUtils/SessionStorageUtils'
import { dispatchTipo } from "../store/actions/acaoTemplate"

/** 
 * retorna empresaId através do sessionStorage
 * 
 * @returns {number || null} empresaId
 * */
export const getEmpresaId = () => getIdFromURI(JSON.parse(sessionStorage.getItem('c') || '{}')['e']);

/**
 * 
 * @param {number} id 
 * @returns {void}
 */
export function loadEmpresaId(id: number) {
  if (typeof id === 'number') {
    axios().get(getApi(`origemVendas/${id}/scan?js=true`))
      .then((resposta) => {
        const id = getIdFromURI(getURIFromEntity((resposta.data || {}).empresa));
        sessionStorage.setItem("c", `{"e": "${getApi(`empresas/${id}`)}"}`);
      })
      .catch(
        /**
         * 
         * @param {ErrorEvent} e 
         */
        (e) => {
          if (e.error === undefined) {
            removeLastScannedSaleSource();
            removeSaleSourceCode();
            reduxDispatch(push(getPageNames().logado.CONTROLE_VENDAS));
          }
        }
      );
  }
}

/**
 * Retorna se a empresa do cargo selecionado é do ramo Coletor,
 * pois isto influencia o comportamento de algumas telas, como o uso de integração.
 *
 * @param {string} ramo
 * @returns {boolean}
 */
export function isEmpresaColetor(ramo: string) { return ramo === RamoEmpresa.COLETOR };

/**
 * Retorna se a empresa do cargo selecionado é um do ramo Painel,
 * pois isto influencia o comportamento de algumas telas, como o uso do leitor de código de barras/QR.
 *
 * @param {string} ramo
 * @returns {boolean}
 */
export function isEmpresaPainel(ramo: string) { return ramo === RamoEmpresa.PAINEL };

/**
 * Retorna se a empresa do cargo selecionado é um restaurante a kilo,
 * pois isto influencia o comportamento de algumas telas, como o uso do leitor de código de barras/QR.
 *
 * @param {string} ramo
 * @returns {boolean}
 */
export function isEmpresaRestauranteAKilo(ramo: string) { return ramo === RamoEmpresa.RESTAURANTE_A_KILO };

export function salvarParametros(dispatch: dispatchTipo, empresa: any, payloadId: string, payload: any) {
  const newEmpresa = {
    ...referencedEntity(empresa),
    dadosIntegracaoList: empresa.dadosIntegracaoList,
    parametrosEmpresa: {
      [payloadId]: payload,
    },
  }

  dispatch(cadastroSave(newEmpresa, getApi(`empresas/${getEmpresaId()}`)));
}

export function getEndereco(venda: any) {
  if (venda.clientePreCadastrado) {
    return venda.clientePreCadastrado.enderecoUsuario;
  }

  return (venda.usuarioCliente || venda.cliente).dadosAdicionais;
}