//@ts-check
import { getEmpresaApp, setEmpresaApp } from '../../nucleo/utils/StorageUtils/StorageUtils';
import { ClienteHttp } from '../../nucleo/utils/utils';

/**
 * 
 * @param {(resposta: import('../../nucleo/redux/parametrosEmpresa/parametrosEmpresaReducer').parametrosEmpresaReducerTipo) => any} callback 
 */
export const getParametrosEmpresa = (callback) => {
    const pastaPublica = process.env.PUBLIC_URL;
    
    ClienteHttp.obterClienteHttp().get(`${pastaPublica}/mobi_app_layout/parametrosEmpresa.json`)
        .then((resposta) => {
            const parametrosEmpresa = resposta.data[getNomeEmpresa()] || {};

            callback(parametrosEmpresa);
        });
}

/**
 * 
 * @returns {string}
 */
export const getNomeEmpresa = () => {
    return getEmpresaApp() || "";
}

/**
 * 
 * @param {string?} nomeEmpresa 
 */
export const setNomeEmpresa = (nomeEmpresa = null) => {
    setEmpresaApp(nomeEmpresa);
}
