import React from 'react';
import { connect } from 'react-redux';

import getEnderecoEmTexto from '../../../utils/EnderecoUtils/getEnderecoEmTexto';
import { getContatoEmTexto } from '../../../utils/ContatoUtils/getContatoEmTexto';
import { getStrings } from '../../../utils/LocaleUtils';

import * as cadastroEmpresaActions from '../../../store/actions/cadastroEmpresaAction';

import OptionButtons from '../optionButtons/OptionButtons';
import { getAppEmpresa } from '../../../utils/AppUtils';

// import BotaoChecado from '../../UI/botoes/BotaoChecado/BotaoChecado';

/**
 * Classe que realiza a montagem das linhas da tabela dos Empresas.
 */
class EmpresaTableRow extends React.Component {

  componentDidUpdate() {
    const params = new URLSearchParams(window.location.search);
    const tipoOrigem = params.get("tipoOrigem");
    const currentEmpresaLink = getAppEmpresa();
    const cadastro = this.props.cadastro;

    if (tipoOrigem && cadastro._links.self.href === currentEmpresaLink) {
      this.props.onUpdate(cadastro);
      this.props.cadastroEmpresaEdit();
    }
  }

  /**
   * Método que executa a montagem do componente.
   */
  render() {
    const cadastro = this.props.cadastro;
    // const id = `${getURIFromEntity(cadastro)}/Checkbox`;

    if (this.props.minVersion) {
      return (<tr>
        {/* Opções de manutenção */}
        <OptionButtons className='ButtonAlign' onUpdate={(cadastro) => {
          this.props.onUpdate(cadastro);
          this.props.cadastroEmpresaEdit();
        }} onDelete={this.props.onDelete} cadastro={cadastro} />
        <td className='Lighter TextAlign' width='150px' >{cadastro.nomeFantasia}</td>
        <td className='Lighter TextAlign' width='200px' >{getStrings().companyBusinessEnumToString(cadastro.ramoEmpresa)}</td>
        <td className='Lighter TextAlign' width='200px' >{getEnderecoEmTexto(cadastro, { formatoExpandido: true })}</td>
        <td className='Lighter ButtonAlign text-no-wrap' width='200px' >{getContatoEmTexto(cadastro)}</td>
      </tr>)
    }

    return (
      <tr>

        {/* Opções de manutenção */}
        <OptionButtons className='ButtonAlign' onUpdate={(cadastro) => {
          this.props.onUpdate(cadastro);
          this.props.cadastroEmpresaEdit();
        }} onDelete={this.props.onDelete} cadastro={cadastro} />

        {/* Dados do cadastro */}
        {/* <td className='Lighter TextAlign' width='7px' >
                    <BotaoChecado key={`__${id}__`} desativado={!this.props.cadastro.enabled} />
                </td> */}
        {/* <td className='Lighter TextAlign' width='100px' >{getStrings().situationEnumToString(cadastro.contratoEmpresa.situacaoEmpresa)}</td> */}
        <td className='Lighter TextAlign' width='200px' >{cadastro.razaoSocial}</td>
        <td className='Lighter TextAlign' width='200px' >{cadastro.nomeFantasia}</td>
        <td className='Lighter TextAlign' width='200px' >{getStrings().companyBusinessEnumToString(cadastro.ramoEmpresa)}</td>
        <td className='Lighter TextAlign' width='250px' >{getEnderecoEmTexto(cadastro, { formatoExpandido: true })}</td>
        <td className='Lighter ButtonAlign text-no-wrap' width='150px' >{getContatoEmTexto(cadastro)}</td>
        <td className='Lighter TextAlign' width='150px' >{cadastro.cnpj}</td>
        <td className='Lighter TextAlign' width='100px' >{cadastro.inscricaoEstadual}</td>
      </tr>
    )
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  ...state.idiomaReducer
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  cadastroEmpresaEdit: () => dispatch(cadastroEmpresaActions.cadastroEmpresaEdit())
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(EmpresaTableRow);
