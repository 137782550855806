//@ts-check
import React from "react"
import icones from "../../../../assets/icones/Icones"
import Icone from "../../nucleo/componentes/Icone/Icone"
import { Imagem } from "../../nucleo/nucleo"
import { getNomeEmpresa } from "../empresas/configEmpresa"

/**
 * 
 * @param {{[key: string]: import("react-icons/lib").IconType}} icones 
 * @param {{className?: string}} customProps 
 */
const criarIconesEncapsulados = (icones, customProps = {}) => {
  const iconesEncapuslados = {...icones};
  const className = customProps.className = '';

  for(const key in icones) {
    const Icone = icones[key];

    iconesEncapuslados[key] = (props) => <Icone {...props} {...customProps} className={'ui-icone ' + className} />;
  }

  return iconesEncapuslados;
}

export const Icones = {
  ai: criarIconesEncapsulados(icones.ai),
  fa: criarIconesEncapsulados(icones.fa),
  go: criarIconesEncapsulados(icones.go),
  md: criarIconesEncapsulados(icones.md),
  mobi: {
    /**
     * 
     * @param {import("../../nucleo/componentes/Icone/types").iconePropsType} props 
     * @returns {JSX.Element}
     */
    Ajuda: (props) => <Icone {...props} src={process.env.PUBLIC_URL + "/mobi_icons/ajuda.svg"} />,
    /**
     * 
     * @param {import("../../nucleo/componentes/Icone/types").iconePropsType} props 
     * @returns {JSX.Element}
     */
    Avancar: icones.md.MdNavigateNext,
    /**
     * 
     * @param {import("../../nucleo/componentes/Icone/types").iconePropsType} props 
     * @returns {JSX.Element}
     */
    Carrinho: (props) => <Icone {...props} src={process.env.PUBLIC_URL + "/mobi_icons/cart.svg"} />,
    /**
     * 
     * @param {import("../../nucleo/componentes/Icone/types").iconePropsType} props 
     * @returns {JSX.Element}
     */
    CarrinhoAdd: (props) => <Icone {...props} src={process.env.PUBLIC_URL + "/mobi_icons/cart-add.svg"} />,
    /**
     * 
     * @param {import("../../nucleo/componentes/Icone/types").iconePropsType} props 
     * @returns {JSX.Element}
     */
    CarrinhoMais: (props) => <Icone {...props} src={process.env.PUBLIC_URL + "/mobi_icons/cart-add.svg"} />,
    /**
     * 
     * @param {import("../../nucleo/componentes/Icone/types").iconePropsType} props 
     * @returns {JSX.Element}
     */
    FlechaParaBaixo: (props) => <Icone {...props} style={{transform: "rotate(180deg)", ...(props.style || {})}} src={process.env.PUBLIC_URL + "/mobi_icons/cima.svg"} />,
    /**
     * 
     * @param {import("../../nucleo/componentes/Icone/types").iconePropsType} props 
     * @returns {JSX.Element}
     */
    FlechaParaCima: (props) => <Icone {...props} src={process.env.PUBLIC_URL + "/mobi_icons/cima.svg"} />,
    /**
     * 
     * @param {import("../../nucleo/componentes/Icone/types").iconePropsType} props 
     * @returns {JSX.Element}
     */
    Logo: (props) => <Icone {...props} src={process.env.PUBLIC_URL + "/mobi_icons/logo-mobi.svg"} />,
    /**
     * 
     * @param {import("../../nucleo/componentes/Imagem/types").imagemPropsType} props 
     * @returns {JSX.Element}
     */
    LogoEmpresa: (props) => {
      const { nomeEmpresa, versaoLogo, ...sanitizedProps } = props;
      return <Imagem key={"__logo_empresa_icon__"} id={"logo-empresa"} {...sanitizedProps} src={sanitizedProps.src || `${process.env.PUBLIC_URL}/mobi_app_layout/${nomeEmpresa || getNomeEmpresa()}/logo.png?v=${versaoLogo || "0"}`} />
    },
    /**
     * 
     * @param {import("../../nucleo/componentes/Icone/types").iconePropsType} props 
     * @returns {JSX.Element}
     */
    Menu: (props) => <Icone {...props} src={process.env.PUBLIC_URL + "/mobi_icons/menu-open.svg"} />,
    /**
     * 
     * @param {import("../../nucleo/componentes/Icone/types").iconePropsType} props 
     * @returns {JSX.Element}
     */
    Perfil: (props) => <Icone {...props} src={process.env.PUBLIC_URL + "/mobi_icons/usuario.svg"} />,
    Voltar: icones.md.MdNavigateBefore
  }
}

export default Icones;