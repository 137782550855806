export const UPDATE_INPUT = 'UPDATE:INPUTS';
export const REMOVE_INPUT = 'REMOVE:INPUTS';
export const CLEAR_INPUTS =  'CLEAR:INPUTS';

const initialState = {};

const mapActions = {
    [UPDATE_INPUT]: ({id, data}, state) => {
        const newState = {
            ...state,
            [id]: {
                ...state[id] || {},
                ...data,
            }
        }

        return newState;
    },
    [REMOVE_INPUT]: (id, state) => {
        const newState = {...state};

        if (newState[id]) {
            delete newState[id];
        }

        return newState;
    },
    [CLEAR_INPUTS]: () => {
        return {};
    },
};

const inputsReducer = (state = initialState, {type, payload}) => {
    const action = mapActions[type];

    if (action) {
        return action(payload, state);
    }

    return state;
};

export default inputsReducer;
