import React from "react";
import styled from "styled-components";
import { displayFlexCenter } from "../css/styles";
import { PATH_IMAGENS_LOGOS } from "../utils/resources";

const LogoLink = styled.a`
  ${displayFlexCenter}
  margin-left: auto;
`;
const Logo = styled.img`
  height: 50px;
  transition: all 0.3s ease;
`;

const Apxlogo = () => {
  return (
    <LogoLink>
      <Logo src={PATH_IMAGENS_LOGOS + "logo-ampix-mobi-colorido.png"} alt="ampixLogo" />
    </LogoLink>
  );
};

export default Apxlogo;
