import { isIE } from "../../utils/NavigatorUtils"
import { updateObject } from "../../utils/ObjectUtils"

import * as actionTypes from "../actions/actionTypes"

import cssVars from "css-vars-ponyfill"

export const TWO_OF_FIVE = "TWO_OF_FIVE"
export const INTERLEAVED_TWO_OF_FIVE = "INTERLEAVED_TWO_OF_FIVE"
export const CODABAR = "CODABAR"
export const CODE_39 = "CODE_39"
export const CODE_39_VIN = "CODE_39_VIN"
export const CODE_93 = "CODE_93"
export const CODE_128 = "CODE_128"
export const EAN = "EAN"
export const EAN_8 = "EAN_8"
export const UPC = "UPC"
export const UPC_E = "UPC_E"

/**
 * Se a geração de código de barras (ou QR, mas não deve acontecer) falhar. Adicionado no mapa de SVGs ao invés do SVG.
 */
export const FAIL = "fail"
export const FORMAT_PNG = "png"
export const FORMAT_SVG = "svg"

/**
 * Códigos gerados diretamente em arquivos, para serem tratados antes da impressão.
 */
export const MEDIA_FILES = "files"
/**
 * Códigos gerados em uma página para serem impressos vários de uma vez.
 */
export const MEDIA_PAGES = "pages"

export const PAPER_SIZE_LIST = [
  { key: "A0", width: 84.1, height: 118.9 },
  { key: "A1", width: 59.4, height: 84.1 },
  { key: "A2", width: 42.0, height: 59.4 },
  { key: "A3", width: 29.7, height: 42.0 },
  { key: "A4", width: 21.0, height: 29.7 },
  { key: "A5", width: 14.8, height: 21.0 },
  { key: "A6", width: 10.5, height: 14.8 },
  { key: "A7", width: 7.4, height: 10.5 },
  { key: "A8", width: 5.2, height: 7.4 },
  { key: "A9", width: 3.7, height: 5.2 },
  { key: "A10", width: 2.6, height: 3.7 },
  { key: "half_letter", width: 13.97, height: 21.59 },
  { key: "letter", width: 21.59, height: 27.94 },
  { key: "legal", width: 21.59, height: 35.56 },
  { key: "ledger_tabloid", width: 27.94, height: 43.18 },
  { key: "custom" }
].reduce((previousList, currentPaperSize) =>
  (currentPaperSize.key === "custom")
    ? previousList.concat(currentPaperSize)
    : previousList
      .concat(Object.assign({}, currentPaperSize, { key: currentPaperSize.key + "_p" }))
      .concat(Object.assign({}, currentPaperSize, { key: currentPaperSize.key + "_l", width: currentPaperSize.height, height: currentPaperSize.width })),
  []
)

/**
 * Códigos a serem lidos por usuários sem cargo.
 */
export const EXTERNAL_USE = "external"
/**
 * Códigos a serem lidos para seleção de produtos e início de nova venda por colaborador.
 */
export const INTERNAL_USE = "internal"

/**
 * Estado Inicial, obrigatório no Reducer.
 */
export const initialState = {

  /**
   * Altura dos códigos em cm. Pode não corresponder ao tamanho real depois de impresso. [Não há o que fazer a respeito](https://github.com/w3c/csswg-drafts/issues/614#issuecomment-260620057).
   */
  codeHeight: 6

  /** 
   * Formato das imagens geradas.
   */
  , format: FORMAT_PNG

  /**
   * Tamanho da fonte dos textos que acompanham os códigos.
   */
  , fontSize: 0.5

  /**
   * Incluir texto em impressões de uso interno para o usuário poder saber a qual cadastro o código pertence sem escanear o código.
   */
  , includeRegisterCode: true

  /**
   * Para qual mídia os códigos serão gerados.
   */
  , media: MEDIA_PAGES

  /**
   * Altura da página caso seja selecionado o tamanho de página personalizado.
   */
  , paperHeight: null

  /**
   * Margem de impressão quando a mídia for "páginas".
   */
  , paperMargin: 1

  /**
   * Tamanho do papel quando a mídia for "páginas".
   */
  , paperSize: PAPER_SIZE_LIST.find(paperSize => paperSize.key === "A4_p")

  /**
   * Largura da página, caso seja selecionado o tamanho de página personalizado.
   */
  , paperWidth: null

  /**
   * Origens de venda podem ter seus códigos impressos tanto para uso interno como para uso externo.
   * Produtos, porém, somente para uso interno. Esta variável controla se é possível imprimir códigos para uso externo.
   */
  , printExternalEnabled: false

  /**
   * Origens de venda podem ter seus códigos impressos tanto para uso interno como para uso externo.
   * Produtos, porém, somente para uso interno. Esta variável controla se é possível imprimir códigos para uso interno.
   */
  , printInternalEnabled: false

  /**
   * Lista de cadastros a serem impressos.
   */
  , registerList: []

  /**
   * Classe do cadastro, para buscar o nome internacionalizado correto.
   */
  , registerType: ""

  /**
   * Mapa de códigos gerados em SVG, indexados pela URI do cadastro, para serem opcionalmente convertidos em PNG e salvos como arquivo ou agregados em um PDF.
   * 
   * Se não foi possível gerar o código, contém uma mensagem de erro para o cadastro.
   */
  , svgMap: {}

  /**
   * Como os códigos serão usados.
   */
  , use: EXTERNAL_USE
}

const setRootStyleProperty = (name, value) => {
  const rootElement = document.getElementById("root")
  isIE ? cssVars("--cadastro-print-code-height", value)
    : rootElement.style.setProperty(name, value)
}

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.CADASTRO_PRINT:
      // Atualiza altura do código no CSS
      action.codeHeight && setRootStyleProperty("--cadastro-print-code-height", `${action.codeHeight}cm`)
      // Atualiza altura do texto no CSS
      action.fontSize && setRootStyleProperty("--cadastro-print-code-font-size", `${action.fontSize}cm`)

      // Basta controlar a construção do action no outro arquivo.
      return updateObject(state, {
        ...state
        , ...action
      })
    default: return state
  }
}

export default reducer
