import { isNonEmptyObject } from "../ComparatorsUtils"

/**
 * Quando é usado React Redux em um componente, ele fica escondido dentro de um wrapper.
 * Este método retorna o componente, mesmo que ele não use Redux.
 * @param {Object} input componente com ou sem Redux
 */
export function getReduxWrappedComponent(input: any) {
  return isNonEmptyObject(input)
    ? 'getWrappedInstance' in input
      ? input.getWrappedInstance()
      : input
    : input
}