import React from "react"

import { getStrings } from "../../utils/LocaleUtils"
import { ColorPicker } from "../../utils/ScreenUtils";

import { dispatchTipo } from "./acaoTemplate"
import { appDialogHide, appDialogShow } from "./appAction"

export type onHandleColorType = (color: string|undefined, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

export type colorHandlerType = (props: {handleChange: (() => void)|undefined, onHandleColor: onHandleColorType, color: string|undefined}, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
  (dispatch: dispatchTipo) => void;

export const colorHandler: colorHandlerType = ({handleChange, onHandleColor, color}, event) => (dispatch) => {
  event.stopPropagation();
  event.preventDefault();

  let colorPicker: any;
  let rgb: string = color || "rgb(255,255,255)";
  let elementRef: HTMLDivElement;

  dispatch(appDialogShow(<div key="__color_picker_dialog__" >
      <div key="__color_picker__" id={"color-picker"} ref={(_) => {
        if (!_) {
          return;
        }

        elementRef = _;

        colorPicker = ColorPicker({selector: "#color-picker"});
        // @ts-ignore
        colorPicker.on("change", (event) => {
          rgb = `rgb(${event.rgb})`;
        });
        // @ts-ignore
        colorPicker[0].setColor(color);
      }} />
      <button
        onClick={() => {
          onHandleColor(rgb, event);
          dispatch(appDialogHide());
          elementRef.removeChild(elementRef.childNodes.item(0));
          handleChange && handleChange();
        }}
      >
        Escolher cor
      </button>
    </div>,
    getStrings().editColor,
    undefined,
    undefined,
    () => { elementRef.removeChild(elementRef.childNodes.item(0)); }
  ));
}

export const multiColorHandler: colorHandlerType = ({handleChange, onHandleColor, color}, event) => (dispatch) => {
  event.stopPropagation();
  event.preventDefault();

  let colorPicker: any;

  const color_ = (color || "linear-gradient(to right,rgb(1,170,131),rgb(3,73,133),rgb(3,73,133))").replace("linear-gradient(", "").replace("to right,", "").split("),");

  let rgb: string[] = [
    color_[0] + ")",
    color_[1] + ")",
    color_[2].slice(0, -1),
  ];
  let elementRef: HTMLDivElement;

  const ColorPickerElement = (props: {n: number}) => {
    return <div className="flex flex-column flex-nowrap">
      <div key={`__color_picker_${props.n}__`} id={`color-picker-${props.n}`} ref={(_) => {
        if (!_) {
          return;
        }

        elementRef = _;

        colorPicker = ColorPicker({selector: `#color-picker-${props.n}`});
        // @ts-ignore
        colorPicker.on("change", (event) => {
          rgb[props.n] = `rgb(${event.rgb})`;
        });
        // @ts-ignore
        colorPicker[0].setColor(rgb[props.n]);
      }} />
    </div>;
  }

  dispatch(appDialogShow(<div key="__color_picker_dialog__" className="flex flex-column flex-nowrap" >
      <div className="flex flex-row flex-nowrap" >
        <ColorPickerElement n={0} />
        <ColorPickerElement n={1} />
        <ColorPickerElement n={2} />
      </div>
      <button
        onClick={() => {
          const gradient = `linear-gradient(to right, ${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
          onHandleColor(gradient, event);
          dispatch(appDialogHide());
          elementRef.removeChild(elementRef.childNodes.item(0));
          handleChange && handleChange();
        }}
      >
        Escolher cor
      </button>
    </div>,
    getStrings().editColor,
    undefined,
    undefined,
    () => { elementRef.removeChild(elementRef.childNodes.item(0)); }
  ));
}