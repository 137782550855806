import criarReducer, { acaoTipo } from "../../factory/criarReducer";
import { tiposDeAcoes } from "./menuModalActions";

export type estadoMenuTipo = 'aberto' | 'fechado'; 

export type menuModalReducerTipo = {
  estadoMenu: estadoMenuTipo
};

const estadoInicial: menuModalReducerTipo = {
  estadoMenu: 'fechado'
};

const acoesNomeadas: {[key: string]: (carga: any, estadoAtual?: menuModalReducerTipo) => menuModalReducerTipo} = {
  [tiposDeAcoes.MUDAR_ESTADO_MENU_MODAL]: ({estadoMenu}: {estadoMenu: estadoMenuTipo}, estadoAtual?: menuModalReducerTipo) => {
    const novoEstado = {
      ...(estadoAtual || {})
      , estadoMenu
    }

    return novoEstado;
  }
}

export default (estado: menuModalReducerTipo = estadoInicial, acao: acaoTipo) => 
  criarReducer(estado, acao, acoesNomeadas);
