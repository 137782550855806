export const UPDATE_SIMPLE_TAP_SELECTION = 'UPDATE:SIMPLE_TAP_SELECTION';

const initialState = false;

const mapActions = {
    /**
     * 
     * @param {Boolean} payload 
     */
    [UPDATE_SIMPLE_TAP_SELECTION]: (payload) => {
        return !!payload;
    },
};

const simpleTapSelectionReducer = (state = initialState, {type, payload}) => {
    try {
        return mapActions[type](payload, state);
    } catch(e) {}
    
    return state;
};

export default simpleTapSelectionReducer;