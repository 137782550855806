import React from "react"

import { log } from "../../utils/LogUtils"
import { formatNumber } from "../../utils/NumberUtils"

/**
 * Item inserido entre os pagamentos de venda para identificar e separar os mesmos
 * e exibir totais de cada pagamento.
 */
export default class BuildCellFromPagamentoVendaTotal extends React.Component {

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("BuildCellFromPagamentoVendaTotal render")

    let pagamentoVenda = this.props.pagamentoVenda || {}

    return <div className="table-cancel table-footer" >
      <div className="table-cancel table-row" >
        {pagamentoVenda.valorDesconto
          ? <div className="table-cell amount NumberAlign">{
            formatNumber(pagamentoVenda.valorDesconto, 2)
          }</div>
          : " "}
        <div className="table-cell total NumberAlign">{
          formatNumber(pagamentoVenda.valorTotal, 2)
        }</div>
      </div>
    </div>
  }
}
