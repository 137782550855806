import criarReducer, { acaoTipo } from '../../factory/criarReducer';
import tiposDeAcoes from './cadastroUsuarioActions';
import { minimalUsuarioReducerTipo } from '../../usuario/usuarioReducer';

export type cadastroUsuarioReducerTipo = minimalUsuarioReducerTipo & {
  senha: string,
  senhaRepetida?: string,
};

const estadoInicial: cadastroUsuarioReducerTipo = {
  usuario: {
    nome: '',
    sobrenome: '',
    email: '',
    cpf: '',
    celular: '',
    dataDeNascimento: (new Date()).toISOString().slice(0, 10),
    genero: 'nao_informado',
    mudouEstadoVenda: false,
  },
  endereco: {
    bairro: '',
    cep: '',
    cidade: '',
    codigoCidade: '',
    complemento: '',
    estado: '',
    numero: '',
    pais: {
      codigo: 0,
      iso: '',
      nome: '',
    },
    rua: '',
  },
  senha: '',
  senhaRepetida: '',
};

const acoesNomeadas: any = {
  [tiposDeAcoes.ATUALIZAR_CADASTRO]: (carga: cadastroUsuarioReducerTipo, estadoAtual: cadastroUsuarioReducerTipo) => {
    const novoEstado = {
      ...estadoAtual,
      ...carga,
    };

    return novoEstado;
  },
  [tiposDeAcoes.LIMPAR_CADASTRO]: () => {
    const novoEstado = {
      ...estadoInicial,
    };

    return novoEstado;
  },
};

export default function(estado: cadastroUsuarioReducerTipo = estadoInicial, acao: acaoTipo) {
  return criarReducer(estado, acao, acoesNomeadas);
}
