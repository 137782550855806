import { APP_NOTIFICATION_TYPE_INFO, CADASTRO_SETUP } from "./actionTypes"
import { STATE_CADASTRO_LIST } from "../reducers/cadastroReducer"
import { initialState } from "../reducers/cadastroSetupReducer"
import { appNotificationShow, appSpinnerHide, appSpinnerShow, axios } from "./appAction"
import { cadastroSwitchUI } from "./cadastroAction"

import { requestErrorHandlerDefault } from "../../utils/ErrorUtils"
import { getHeaders } from "../../utils/HeadersUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { urlDatabase } from "../../utils/SecureConnectionUtils"

export const clearState = () => dispatch => {
  log("cadastroSetupAction clearState")

  dispatch({
    type: CADASTRO_SETUP,
    ...initialState
  })
}

export const saveOrigemVendaBatch = config => dispatch => {
  log("cadastroSetupAction saveOrigemVendaBatch", config)

  dispatch(appSpinnerShow("saveOrigemVendaBatch"))

  axios().post(
    urlDatabase + "/origemVendas/batch",
    config,
    getHeaders()
  )
    .then(() => {
      dispatch(appNotificationShow(getStrings().saleSourceBatchSuccess, APP_NOTIFICATION_TYPE_INFO, getStrings().success))
      dispatch(cadastroSwitchUI(STATE_CADASTRO_LIST))
    })
    .catch(error => dispatch(requestErrorHandlerDefault(error)))
    .finally(() => dispatch(appSpinnerHide("saveOrigemVendaBatch")))
}

export const setValue = (value, position) => dispatch => {
  log("cadastroSetupAction setValue", value, position)

  dispatch({
    type: CADASTRO_SETUP,
    [position]: value
  })
}
