import { dispatchTipo } from "../../../../../store/actions/acaoTemplate";
import criarAction from "../factory/criarAction";
import { quadroHorariosReducerTipo } from "./quadroHorariosReducer";

const tiposDeAcoes = {
  POPULAR_QUADRO_HORARIOS: 'POPULAR_QUADRO_HORARIOS:QUADRO_HORARIOS',
};

export default tiposDeAcoes;

export const popularQuadroHorarios = (carga: quadroHorariosReducerTipo) => function (dispatch: dispatchTipo) {
  dispatch(criarAction(carga, tiposDeAcoes.POPULAR_QUADRO_HORARIOS));
}