import React from 'react'

import "./ZoomImagemDialog.css";

import Imagem from '../../../nucleo/componentes/Imagem/Imagem'
import DialogTemplate from '../DialogTemplate'

/**
 * 
 * @param {ZoomImagemDialogPropsType} props 
 * @returns {JSX.Element}
 */
export default function ZoomImagemDialog({fonteImagemTipo, imagemProduto, titulo}) {
  return <DialogTemplate fecharHabilitado titulo={titulo}>
      <Imagem key={fonteImagemTipo} className={'zoom-imagem-dialog'} src={imagemProduto} />
  </DialogTemplate>;
}