//@ts-check
import React from "react"
import { icones } from "../../../../../components/UI/UI"
import { LocalidadeUtils } from "../../utils/utils"

/**
 * 
 * @param {import("./types").imagemPropsType} props 
 * @returns {JSX.Element}
 */
function Imagem({ draggable = false, customSrcOnError, src, lupa = false, ...props }) {
  /**
   * 
   * @type {[string|undefined, (value?: string|undefined) => void]}
   */
  const [ sourceFailed, updateSourceFailed ] = React.useState();
  const noImageAvailableIcon = customSrcOnError || LocalidadeUtils.obterTraducao().noImageAvailableIcon
  src = src || noImageAvailableIcon

  // Carrega noImageAvailableIcon caso sem src
  // source é recebido de React.useState e alterado por updateSource
  const [ source, updateSource ] = React.useState(src)

  // Altera source
  React.useEffect(() => {
    if (src && src !== noImageAvailableIcon && sourceFailed !== src && src !== source) {
      updateSourceFailed()
      updateSource(src)
    }
  }, [source, src, noImageAvailableIcon, sourceFailed])

  function carregaNoAvailableImageIconCasoErro() {
    updateSourceFailed(src)
    updateSource(noImageAvailableIcon)
  }

  function desativarDragPorPadrao() {
    return draggable
      ? props.onDragStart
      : (
        /**
         * 
         * @param {any} evt 
         */
        (evt) => { evt.preventDefault(); })
  }
  
  const imgContent = <img
    alt="img"
    {...props}
    onDragStart={desativarDragPorPadrao()}
    src={source}
    className={"ui-imagem " + (props.className || "")}
    onError={(evt) => { carregaNoAvailableImageIconCasoErro(); props.onError && props.onError(evt); }}
  />

  if (lupa) {
    const LupaIcone = icones.ai.AiOutlineSearch;

    return <>
      {imgContent}
      {(lupa && source !== noImageAvailableIcon)
        ? <div className="glass" >
          <LupaIcone />
        </div>
        : null
      }
    </>
  }

  return imgContent;
}

export default Imagem;
