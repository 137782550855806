import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { blurAll } from "../../../utils/ScreenUtils"

import * as appActions from "../../../store/actions/appAction"
import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"
import * as pagamentoVendasActions from "../../../store/actions/controleVenda/pagamentoVendasAction"

import InputCustomizado from "../../UI/Input/InputCustomizado"

/**
 * Classe que monta o conteúdo da *dialog* para inserir CPF ao pagar venda.
 */
class DialogFederalTaxNumber extends React.Component {

  handleSubmit = event => {
    log("DialogFederalTaxNumber handleSubmit", { event })

    // Impede que o form redirecione para outra URL
    if (event) {
      event.preventDefault()
    }

    blurAll()

    this.props.appDialogHide()

    this.props.paySales(true, this.individualTaxpayerIDField.inputComponent.value)
  }

  componentDidMount() {
    log("DialogFederalTaxNumber componentDidMount")

    this.componentDidUpdate({ dialogShow: false })
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  componentDidUpdate(prevProps) {
    log("DialogFederalTaxNumber componentDidUpdate")
    // Se passou a ser exibido
    if ((!((prevProps || {}).dialogShow)) && (this.props || {}).dialogShow) {
      // Possibilita que o usuário digite no campo sem precisar selecionar ele primeiro
      this.individualTaxpayerIDField.inputComponent.value = ""
      this.individualTaxpayerIDField.inputComponent.select()
    }
  }

  render() {
    log("DialogFederalTaxNumber render")
    // Retorna o conteúdo da dialog
    return <form onSubmit={this.handleSubmit} >
      <div className="sub-form">
        <InputCustomizado
          ref={input => { if (input) { this.individualTaxpayerIDField = getReduxWrappedComponent(input) } }}
          placeholder={getStrings().clientIdentifierReceiptPlaceholder}
          maxLength="20"
          id="cpf"
          type="text"
          name="cpf"
          label={getStrings().clientIdentifier}
          onFocus={() => {
            this.individualTaxpayerIDField.inputComponent.select()
          }} />
      </div>

      {/* type="submit" indica que quando o botão for clicado será chamado o método "onSubmit" do formulário */}
      <div className="dialog-horizontal-button-grid">
        <button
          className="blue-button font-color"
          type="submit"
          tabIndex={(this.props.tabIndex + 1) * -1}
        >
          {getStrings().create}
        </button>
      </div>
    </form>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  tabIndex: state.appReducer.getTabIndex(),
  dialogShow: state.appReducer.dialogShow,
  fromCallState: state.controleVendaReducer.fromCallState,
  isAuthenticated: !!state.authReducer.token,
  isCargo: !!state.empresaSelectorReducer.cargo
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  appDialogHide: () => dispatch(appActions.appDialogHide()),
  continueNewVenda: newVendaData => dispatch(controleVendaActions.continueNewVenda(newVendaData)),
  exibeManutencaoVendaNoCompany: (origemVenda, newVendaData, isAuthenticated) =>
    dispatch(controleVendaActions.exibeManutencaoVendaNoCompany(origemVenda, newVendaData, isAuthenticated)),
  setupNewVendaFromCall: newVendaData => dispatch(controleVendaActions.setupNewVendaFromCall(newVendaData)),
  resetFromCall: () => dispatch(controleVendaActions.resetFromCall()),
  paySales: (askedForIndividualTaxpayerID, individualTaxpayerID) =>
    dispatch(pagamentoVendasActions.paySales(askedForIndividualTaxpayerID, individualTaxpayerID))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(DialogFederalTaxNumber)
