import React from "react"
import { connect } from "react-redux"

import { log } from "../../../utils/LogUtils"
import { blurAll } from "../../../utils/ScreenUtils"

import { STATE_CONTROLE_VENDA_PAGAR_ORIGENS } from "../../../store/reducers/controleVenda/controleVendaReducer"
import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"
import * as pagamentoVendasActions from "../../../store/actions/controleVenda/pagamentoVendasAction"

import BarraAcoesForm, { SUBMIT_ROLE_MONEY } from "../../cadastros/barraAcao/BarraAcoesForm"

/**
 * Barra de ações da tela de pagamento de vendas, que é chamada pela tela de origens ou de vendas.
 */
class BarraAcoesPagamentoVendas extends React.Component {

  /**
   * Retorna se deve chamar a atenção para o botão de encerrar venda.
   * Isso deve ser feito quando a venda foi totalmente paga.
   */
  shouldAttractToClose = () => {
    log("BarraAcoesPagamentoVendas shouldAttractToClose")

    // Deve chamar a atenção para o botão de encerrar venda se houver venda, se ela conter itens de venda, e se todos os itens tiverem sido pagos.
    if (!this.props.origemVendaList) {
      return false
    }

    let hasItems = false

    this.props.origemVendaList.filter(origemVenda => origemVenda.vendaList).forEach(origemVenda =>
      origemVenda.vendaList.forEach(venda => {
        if (venda.itemVendaList && ("length" in venda.itemVendaList) && (venda.itemVendaList.length > 0)) {
          hasItems = true
        }
      })
    )
    if (!hasItems) {
      return false
    }
    return !this.props.hasRemainingAmount
  }

  render() {
    if (this.props.esconderBarraAcoes) {
      return null
    }

    log("BarraAcoesPagamentoVendas ", { shouldAttractToClose: this.props.hasCheckedProduct || this.shouldAttractToClose() })
    return <BarraAcoesForm
      handleListar={() => {
        log("BarraAcoesPagamentoVendas handleListar")
        // Ao clicar no botão voltar, volta para a tela que abriu esta
        if (this.props.state === STATE_CONTROLE_VENDA_PAGAR_ORIGENS) {
          this.props.exibeGridOrigemVendaEmUso()
        } else {
          this.props.exibeGridVendaFromOrigem(this.props.origemVendaList[0])
        }
      }}
      isShowConfig={false}
      attractSubmit={this.props.hasCheckedProduct || this.shouldAttractToClose()}
      submitRole={this.props.hasCheckedProduct ? SUBMIT_ROLE_MONEY : undefined}
      handleSubmit={() => {
        log("BarraAcoesPagamentoVendas handleSubmit")

        blurAll()

        // Se está mostrando botão de pagar, executa método de pagar
        if (this.props.hasCheckedProduct) {
          this.props.paySales()
        }
        // Senão, executa método de encerrar
        else {
          let vendaList = []
          this.props.origemVendaList.filter(origemVenda => origemVenda.vendaList).forEach(
            origemVenda => vendaList = vendaList.concat(origemVenda.vendaList)
          )
          this.props.confirmaEncerramentoVenda(vendaList)
        }
      }} />
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  state: state.controleVendaReducer.state,
  origemVendaList: state.controleVendaReducer.origemVendaList,
  hasCheckedProduct: state.controleVendaReducer.hasCheckedProduct,
  hasRemainingAmount: state.controleVendaReducer.hasRemainingAmount
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  confirmaEncerramentoVenda: (vendaList, isIgnoraConfirmacaoEncerramento, isIgnorarItensNaoPagos) =>
    dispatch(pagamentoVendasActions.confirmaEncerramentoVenda(vendaList, isIgnoraConfirmacaoEncerramento, isIgnorarItensNaoPagos)),

  exibeGridVendaFromOrigem: origemVenda => dispatch(controleVendaActions.exibeGridVendaFromOrigem(origemVenda)),

  exibeGridOrigemVendaEmUso: () => dispatch(controleVendaActions.exibeGridOrigemVendaEmUso()),

  paySales: () => dispatch(pagamentoVendasActions.paySales())
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(BarraAcoesPagamentoVendas)
