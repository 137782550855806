import React from "react"
import { connect } from "react-redux"

import { isIE } from "../../../utils/NavigatorUtils"
import { defaultLocaleString, getStrings, global_strings as strings } from "../../../utils/LocaleUtils"

import * as appActions from "../../../store/actions/appAction"
import * as actions from "../../../store/actions/idiomaAction"
import { push } from "connected-react-router"

// import Logo from "../../UI/Logo/Logo"
import InputCustomizado from "../../UI/Input/InputCustomizado"
import DrawerToggle from "../SideDrawer/DrawerToggle/DrawerToggle"
import HelpToggle from "../../UI/HelpToggle/HelpToggle"
import ItemStateAlert from "../../Subscriber/ItemStateAlert"
import SaleSourceAlert from "../../Subscriber/SaleSourceAlert"
import Icones from "../../../apps/promo_app_frontend/assets/icones/Icones"
import { Button } from "../../UI/Button/Button"

import "./Header.css";
import { getPageNames } from "../../../utils/siteUtils/siteUtils"
// import { Link } from "react-router-dom"

// const MdMenu = Icones.md.MdMenu;

// const CustomLink = (props) => <div className="pure-u-1 pure-u-sm-1 pure-u-md-1-1 pure-u-lg-1-2 pure-u-xl-1-2 pure-u-xxl-1-2">
//   {props.fake
//     ? props.children
//     : <Link {...props} />
//   }
// </div>

const ConteudoPadraoBarraTopo = (props) => {
  const pageDomain = props.isAuthenticated
    ? props.isCargo
      ? "cargoSelecionado"
      : "logado"
    : "naoLogado";

  return <>
    <DrawerToggle clicked={props.toggleSideDrawer} />

    {/* <div className="pure-g" style={{ opacity: 0, marginLeft: "2em" }}>
      <CustomLink fake style={{ whiteSpace: "nowrap", color: "white", fontWeight: "bold", textDecoration: "none", fontStyle: "normal" }} href="/login" ><MdMenu style={{ fontSize: "32px" }} /></CustomLink>
      <CustomLink fake style={{ whiteSpace: "nowrap", color: "white", fontWeight: "bold", textDecoration: "none", fontStyle: "normal" }} href="/login" >{getStrings().signIn}</CustomLink>
      <CustomLink fake style={{ whiteSpace: "nowrap", color: "white", fontWeight: "bold", textDecoration: "none", fontStyle: "normal" }} href="/signUp">|{getStrings().signUp}</CustomLink>
    </div> */}

    <ItemStateAlert />

    <SaleSourceAlert />

    <div className="Logo header LogoCenter">
      <Button className="Logo" tabIndex={props.tabIndex} onClick={() => props.goTo(getPageNames()[pageDomain].HOME)}>
        <Icones.mobi.Logo />
      </Button>
    </div>

    {/* <div className="pure-g" style={{ marginRight: "2em" }}>
      <CustomLink style={{ fontSize: "calc(2em - 0.75vw)", whiteSpace: "nowrap", color: "white", fontWeight: "bold", textDecoration: "none", fontStyle: "normal" }} to="/login" >{getStrings().signIn}</CustomLink>
      <CustomLink style={{ fontSize: "calc(2em - 0.75vw)", whiteSpace: "nowrap", color: "white", fontWeight: "bold", textDecoration: "none", fontStyle: "normal" }} to="/signUp">|{getStrings().signUp}</CustomLink>
    </div> */}

    <HelpToggle tabIndex={props.tabIndex} id={"helpToggle"} />

    <InputCustomizado
      tabIndex={props.tabIndex}
      newAPI
      options={props.mapOptions()}
      value={props.locale}
      id="language"
      inputType="singleSelect"
      name="language"
      topClassName="clickable languageSelect"
      placeholder=""
      clearable={false}
      searchable={false}
      onChange={props.updateLocaleHandler}
      arrowRenderer={null}
      marginBottom="0"
    />

    <div className="User">
      {/* Usuario */}
    </div>
  </>
}

const Header = (props) => {
  const buildLanguageOption = (idioma) => {
    idioma = idioma || defaultLocaleString
    return { value: { codigo: idioma }, label: (strings[idioma] || {}).languageSelectOption, className: "languageOptionClass" }
  }

  const mapOptions = () => Object.keys(strings).map(
    (idioma) => buildLanguageOption(idioma)
  )

  const updateLocaleHandler = (x) => {
    props.updateLocale(x.value.codigo)
    updateLocale(buildLanguageOption(x.value.codigo))
  }

  const [locale, updateLocale] = React.useState(buildLanguageOption(localStorage.getItem("locale")))

  const render = () => {
    /**
     * Quantidade de alertas sendo exibidos. Usado para definir tamanho do título no *header*.
     */
    let alertAmount = 0

    if (props.itemVendaProduzidoList.length > 0) {
      alertAmount++
    }
    if (props.calledOrigemVendaList.length > 0) {
      alertAmount++
    }

    switch (alertAmount) {
      case 2:
        alertAmount = "twoAlert"
        break
      case 1:
        alertAmount = "oneAlert"
        break
      default:
        alertAmount = "zeroAlert"
    }

    return <header className={`Header${alertAmount ? ` ${alertAmount}` : ""}${isIE ? " ie" : ""}`} style={props.style}>
      <ConteudoPadraoBarraTopo isCargo={props.isCargo} isAuthenticated={props.isAuthenticated} tabIndex={props.tabIndex} toggleSideDrawer={props.toggleSideDrawer} goTo={props.goTo} getStrings={getStrings} mapOptions={mapOptions} locale={locale} updateLocaleHandler={updateLocaleHandler} />
    </header>
  }

  return render()
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  tabIndex: state.appReducer.getTabIndex(),
  ...state.idiomaReducer,
  helpState: state.appReducer.help,
  isAuthenticated: state.authReducer.token !== null,
  isCargo: !!state.empresaSelectorReducer.cargo,
  itemVendaProduzidoList: state.producaoReducer.itemVendaProduzidoList,
  calledOrigemVendaList: state.controleVendaReducer.calledOrigemVendaList,
  webAppRedutor: state.webAppRedutor,
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  goTo: path =>
    dispatch(push(path)),
  updateLocale: (locale) =>
    dispatch(actions.updateLocale(locale)),
  setHelp: state =>
    dispatch(appActions.setHelp(state)),
  appSnackbarMessage: message =>
    dispatch(appActions.appSnackbarMessage(message)),
  appSpinnerShow: message =>
    dispatch(appActions.appSpinnerShow(message)),
  appSpinnerHide: message =>
    dispatch(appActions.appSpinnerHide(message)),
  toggleSideDrawer: () =>
    dispatch(appActions.toggleSideDrawer()),
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(Header)
