import React from "react"
import { connect } from "react-redux"

import * as collectorActions from "../../store/actions/collectorAction"
import {
  STATE_COLLECTOR_SALE_CLIENT_LIST,
  STATE_COLLECTOR_SALE_MANAGE_SALE
} from "../../store/reducers/collectorReducer"

import { log } from "../../utils/LogUtils"

import * as breadCrumbs from "../UI/BreadCrumbs/BreadCrumbs"
import I18n from "../UI/I18n/I18n"

/**
 * Exibe o *header* ou o *bread crumbs* baseado nas variáveis armazenadas no *reducer*.
 */
class CollectorHeader extends React.Component {

  /**
   * Retorna o conteúdo do header baseado nas variáveis armazenadas no *reducer*.
   */
  getHeader = () => {

    switch (this.props.state) {

      case STATE_COLLECTOR_SALE_CLIENT_LIST:
        return I18n('sourcesInUse');
      case STATE_COLLECTOR_SALE_MANAGE_SALE:
        return breadCrumbs.sourcesInUse_client(
          this.props.showCollectorList,
          this.props.collector.cliente.nome);
      default:
        return null;
    }
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log('CollectorHeader render');

    return <div className='header breadCrumbs'>
      <h1>{I18n('movement')}</h1>
      <h2>{this.getHeader()}</h2>
    </div>;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  clienteURISelected: state.collectorReducer.clienteURISelected,
  collector: state.collectorReducer.collector() || { cliente: { nome: '' } },
  collectorList: state.collectorReducer.collectorList,
  state: state.collectorReducer.state
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  showCollectorList: () => dispatch(collectorActions.showCollectorList())
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(CollectorHeader);
