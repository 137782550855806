import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { formatI18nString } from "../../../utils/StringUtils"

/**
 * Componente que exibe um texto internacionalizado e busca a tradução automaticamente quando o idioma é alterado.
 */
class I18n extends React.Component {

	/**
	 * Método que executa a montagem/rederização do componente.
	 */
	render() {
		// Busca as strings
		let strings = getStrings()[this.props.stringName];
		// Se for função, chama como função.
		if (this.props.args) {
			strings = strings(...this.props.args);
		}
		// Se for uma lista de strings, busca a string que esse componente renderiza.
		if (this.props.isMulti) {
			strings = strings[this.props.index];
		}
		// Renderiza o componente
		return formatI18nString(strings);
	}
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

	...state.idiomaReducer
});

/**
 * Exporta o último argumento entre parênteses.
 */
let I18nC = connect(mapStateToProps, null, null, { forwardRef: true })(I18n);

/**
 * Retorna um componente ou uma lista de componentes de texto internacionalizado.
 * @param {string} stringName nome da *string* no arquivo de *strings* de cada idioma
 * @param {boolean|undefined} isMulti se a *string* no arquivo de *strings* é somente uma frase ou várias frases
 * @param {any[]|undefined} args se a *string* for uma função, ela será chamada como tal recebendo esses argumentos. Para uma função sem argumentos, passar uma lista vazia.
 */
let i18n = (stringName, isMulti = undefined, args = undefined) => {
	// Busca as strings
	let strings = getStrings()[stringName];
	if (args) {
		strings = strings(...args);
	}
	// Se não for uma lista de strings, converte para uma lista temporariamente.
	if (!isMulti) {
		strings = [strings];
	}
	// Monta os componentes
	let i18nList = strings.map((string, index) => <I18nC key={`${stringName}_i18n_${index}`} stringName={stringName} isMulti={isMulti} args={args} index={index} />);
	// Se for uma lista de strings, retorna a lista.
	if (isMulti) {
		return i18nList;
	}
	// Se não for uma lista de strings, retorna só um componente.
	else {
		return i18nList.pop();
	}
};

export default i18n;
