import { getAppUsuario } from "../../utils/AppUtils"
import * as errorUtils from "../../utils/ErrorUtils"
import { validaCNPJ } from "../../utils/IndentificadorUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { updateDeepObject } from "../../utils/ObjectUtils"
import { urlDatabase } from "../../utils/SecureConnectionUtils"
import { getSaleSourceNoCompany } from "../../utils/StorageUtils/LocalStorageUtils"
import { getIdFromURI, getURIFromEntity } from "../../utils/URIUtils"

import * as actionTypes from "./actionTypes"
import { appNotificationShow, appSpinnerShow, appSpinnerHide, axios } from "./appAction"
import * as authActions from "./authAction"
import * as cadastroActions from "./cadastroAction"
import * as cadastroEmpresaActions from "./cadastroEmpresaAction"
import * as empresaSelectorActions from "./empresaSelectorAction"
import { dispatchTipo } from "./acaoTemplate"
import { buildInitialState, STATE_SIGN_UP_FAILURE, STATE_SIGN_UP_SUCCESS } from "../reducers/signUpReducer"
import { STATE_CADASTRO_EMPRESA_CONCLUIDO } from "../reducers/cadastroEmpresaReducer"
import { updateFbUsersDataAction } from "../reducers/f3/fbUsersDataReducer"
import { getStateType } from "../../utils/reduxUtils/getStateType"

export type setTokenType = (reCaptchaToken: string) => (dispatch: dispatchTipo) => void;

/**
 * Armazena o valor do reCaptchaToken gerado pelo reCAPTCHA.
 * @param {*} reCaptchaToken 
 */
export const setToken: setTokenType = reCaptchaToken => dispatch => {
  log("signUpAction setToken", reCaptchaToken);

  dispatch({
    type: actionTypes.SIGN_UP_TOKEN,
    reCaptchaToken
  });
}

export type updateUsuarioStepType = (step: number) => (dispatch: dispatchTipo) => void;

/**
 * 
 * @param {number} step 
 * @returns 
 */
export const updateUsuarioStep: updateUsuarioStepType = (step) => {
  return dispatch => {
    dispatch({ type: actionTypes.SIGN_UP_STEP, step })
  }
}

export type setScoreType = (password_score: number) => (dispatch: dispatchTipo) => void;

/**
 * 
 * @param {number} password_score 
 * @returns 
 */
export const setScore: setScoreType = password_score => dispatch => {
  log("signUpAction setScore", { password_score });
  dispatch({
    type: actionTypes.SIGN_UP_SCORE,
    password_score
  })
}

export type setSuggestionsType = (password_suggestions: string[]) => (dispatch: dispatchTipo) => void;

export const setSuggestions: setSuggestionsType = password_suggestions => dispatch => {
  log("signUpAction setSuggestions", { password_suggestions });
  dispatch({
    type: actionTypes.SIGN_UP_SUGGESTIONS,
    password_suggestions
  })
}

export type setWarningType = (password_warning: string) => (dispatch: dispatchTipo) => void;

export const setWarning: setWarningType = password_warning => dispatch => {
  log("signUpAction setWarning", { password_warning });
  dispatch({
    type: actionTypes.SIGN_UP_WARNING,
    password_warning
  })
}

export type signUpSubmitType = (usuario: Record<string, any>, f3_login?: Record<string,any>, resendEmail?: string|null) => 
  (dispatch: dispatchTipo, getState: getStateType) => void;

/**
 * Ação de Cadastro de novo Usuario. 
 * @param {Record<string, any>} usuario JSON com os dados do Usuario
 * @param {Record<string,any>|undefined} f3_login Login do Facebook (desativado)
 * @param {boolean|null|undefined} resendEmail (provalvemente não está sendo mais usada)
 */
export const signUpSubmit: signUpSubmitType = (usuario, f3_login = {}, resendEmail = null) => (dispatch, getState) => {
  log("signUpAction signUpSubmit", { usuario, f3_login });

  // caso esteja se logando com facebook
  const userId = f3_login.userId;
  // caso esteja se logando com facebook
  if (Object.keys(f3_login).length > 0) {
    f3_login = { facebookToken: f3_login.facebookToken };
  }

  // if (!getState().signUpReducer.reCaptchaToken) {
  //     dispatch(appNotificationShow(getStrings().captchaTokenNull, actionTypes.APP_NOTIFICATION_TYPE_WARNING, getStrings().warning));
  //     dispatch(setValue(STATE_SIGN_UP_FAILURE, 'state'));
  // }

  dispatch(appSpinnerShow("signUpSubmit"));

  // Busca a origem de venda onde foi feito um pedido sem estar logado, se houver.
  let saleSourceNoCompany = getSaleSourceNoCompany();

  axios().post(
    `${urlDatabase}/auth/signUp${Object.keys(f3_login).length > 0 ? "F3" : ""}`,
    {
      usuario,
      token: getState().signUpReducer.reCaptchaToken,
      // Caso o cadastro seja consequência de uma venda por um usuário não logado,
      // envia a origem de venda para que a venda seja finalizada quando retornar do email de confirmação.
      ...(saleSourceNoCompany || {}),
      ...(f3_login || {}),
      resendEmail,
    })
    .then(response => {
      // Usando Facebook
      if (userId) {
        const f3Token = f3_login.facebookToken;
        const usuario = ((response || {}).config || {}).data
          ? JSON.parse(response.config.data).usuario || null
          : null;
        // @ts-ignore
        const FB = window.FB;

        FB.api(`/me/picture?redirect=false`, 'GET', {}, (response: any) => {
          const f3USerData = {
            token: f3Token,
            nome: usuario ? `${usuario.nome} ${usuario.sobrenome}` : null,
            imagem_do_perfil: ((response || {}).data || {}).url || null,
          };

          dispatch(updateFbUsersDataAction({ [userId]: f3USerData }));
          dispatch(authActions.authenticationWithF3(f3Token));
        });
      }
      // Se usuário inseriu um email já cadastrado e a senha associada a esse email,
      // ao invés de avisar que o cadastro já existe loga o usuário
      if (response.data === "loggedInInstead") {
        dispatch(authActions.authSuccess(response));

        return;
      }
      // Limpa a origem de venda onde foi feito um pedido sem estar logado, se houver,
      // pois a finalização do pedido nesta origem será feito pelo email de confirmação.
      //setSaleSourceNoCompany(null);
      dispatch(signUpSuccess(response));

      if (!userId) {
        dispatch(setValue(STATE_SIGN_UP_SUCCESS, "state"));
      }
    })
    .catch(error => {
      dispatch(signUpFail(error));
      dispatch(setValue(STATE_SIGN_UP_FAILURE, "state"));
    })
    .finally(() => {
      dispatch(cadastroEmpresaActions.cadastroEmpresaUsuarioNovo(getState().cadastroEmpresaReducer.usuario));
      dispatch(appSpinnerHide("signUpSubmit"));
    });
};

export type signUpCompanySubmitType = (usuario: Record<string,any>, empresa: Record<string,any>, history: any) =>
  (dispatch: dispatchTipo, getState: getStateType) => void;

/**
 * Ação de Cadastro de novo Usuario, com sua Empresa. 
 * @param {*} usuario JSON com os dados do Usuario; pode ser null/undefined se for usar usuário logado
 * @param {*} empresa JSON com os dados da Empresa
 */
export const signUpCompanySubmit: signUpCompanySubmitType = (usuario, empresa, history) => (dispatch, getState) => {
  log("signUpAction signUpCompanySubmit", usuario, empresa, history);

  // if (usuario && (!getState().signUpReducer.reCaptchaToken)) {
  //     dispatch(appNotificationShow(getStrings().captchaTokenNull, actionTypes.APP_NOTIFICATION_TYPE_WARNING, getStrings().warning));
  //     return;
  // }

  dispatch(appSpinnerShow("signUpCompanySubmit"));

  // Empacota os objetos a serem enviados em um único JSON
  const wrapper = {
    ...(usuario ? { usuario: usuario } : {}),
    empresa: empresa,
    reCaptchaToken: getState().signUpReducer.reCaptchaToken
  };

  axios().post(
    usuario ? urlDatabase + "/auth/signUpCompany" : urlDatabase + "/usuarios/" + getAppUsuario() + "/signUpCompany",
    wrapper
  )
    .then(response => {

      dispatch(signUpSuccess(response, !usuario));

      dispatch(empresaSelectorActions.updateQuantidadeCargos(1));

      // Se cadastrou a empresa junto com o usuário
      if (usuario) {
        // Mostra a tela que informa para verificar o email de confirmação
        dispatch(cadastroEmpresaActions.cadastroEmpresaEUsuarioChangeState(STATE_CADASTRO_EMPRESA_CONCLUIDO));
        // Para desligar o brilho do botão de enviar
        dispatch(cadastroActions.cadastroSetChanged(false));
      }
      // Se cadastrou a primeira empresa de um usuário
      else {
        // Executa a ação de selecionar um cargo
        dispatch(empresaSelectorActions.empresaSelectorCargoSelecionado(getIdFromURI(response.data._links.self.href), history));
        // Limpa as varíaveis do estado
        dispatch(cadastroEmpresaActions.cadastroEmpresaLimpar());
      }
    })
    .catch(error =>
      dispatch(signUpFail(error))
    )
    .finally(() => {
      dispatch(cadastroEmpresaActions.cadastroEmpresaUsuarioNovo(getState().cadastroEmpresaReducer.usuario));
      dispatch(appSpinnerHide("signUpCompanySubmit"));
    });
};

type signUpSuccessType = (response: Record<string,any>, existingUser?: boolean) => (dispatch: dispatchTipo) => void;

/**
 * 
 * @param {*} response 
 * @param {*} existingUser se é um usuário logado
 */
const signUpSuccess: signUpSuccessType = (response, existingUser) => {
  log("signUpAction signUpSuccess", response, existingUser);

  // return dispatch => {
  //     dispatch(appNotificationShow(existingUser ? getStrings().registerSaved : getStrings().confirmationEmailSent,
  //         actionTypes.APP_NOTIFICATION_TYPE_SUCCESS));
  // };

  return dispatch => {
    dispatch(appNotificationShow(getStrings().registrationSuccessfullyCompleted, actionTypes.APP_NOTIFICATION_TYPE_SUCCESS));
  };
};

type signUpFailType = (error: string) => (dispatch: dispatchTipo) => void;

const signUpFail: signUpFailType = error => {
  log("signUpAction signUpFail", error);
  return dispatch => {
    dispatch(errorUtils.requestErrorHandlerDefault(error));
  };
};

export type validaConfirmacaoSenhaHandlerType = (senha: any, confirmacaoSenha: any) => (dispatch: dispatchTipo) => void;

/**
 * Método que valida o campo de Confirmação de Senha. 
 */
export const validaConfirmacaoSenhaHandler: validaConfirmacaoSenhaHandlerType = (senha, confirmacaoSenha) => {
  log("signUpAction validaConfirmacaoSenhaHandler", senha, confirmacaoSenha);
  return dispatch => {
    if (senha.inputComponent.value !== confirmacaoSenha.inputComponent.value) {
      confirmacaoSenha.inputComponent.setCustomValidity(getStrings().passwordConfirmationDiffers);
    }
    else {
      confirmacaoSenha.inputComponent.setCustomValidity('');
    }
  }
}

export type cadastroSaveEmpresaType = (history: any, existingUser?: boolean) => (dispatch: dispatchTipo, getState: getStateType) => void;

export const cadastroSaveEmpresa: cadastroSaveEmpresaType = (history, existingUser) => (dispatch, getState) => {
  log("signUpAction cadastroSaveEmpresa", { history, existingUser });

  if ((getState().idiomaReducer.locale !== "pt-BR") || validaCNPJ(getState().cadastroEmpresaReducer.cadastroEmpresaDados.empresa.cnpj)) {
    dispatch(signUpCompanySubmit(existingUser ? null : getState().cadastroEmpresaReducer.cadastroEmpresaDados.usuario, getState().cadastroEmpresaReducer.cadastroEmpresaDados.empresa, history));
  }
  else {
    dispatch(appNotificationShow(getStrings().invalidFederalTaxNumber, actionTypes.APP_NOTIFICATION_TYPE_WARNING, getStrings().warning));
  }
}

export type setValueType = (value: any, position: string) => (dispatch: dispatchTipo) => void;

export const setValue: setValueType = (value, position) => dispatch => {
  log("signUpAction setValue", { value, position });

  dispatch({
    type: actionTypes.SIGN_UP,
    [position]: value
  });
};

export type updateFormDataType = (keyArray: string[], value: string) => (dispatch: dispatchTipo,
  getState: getStateType) => void;

/**
 * Atualiza o valor de um dos campos do formulário do cadastro de usuário.
 * @param {String[]} keyArray caminho para se chegar ao valor
 * @param {*} value novo valor
 */
export const updateFormData: updateFormDataType = (keyArray, value) => (dispatch, getState) => {
  log("signUpAction updateFormData", { keyArray, value });

  const state = getState();
  const formData = updateDeepObject(state.signUpReducer.formData, keyArray, value);

  const pais = getURIFromEntity(formData.dadosAdicionais.paisOption?.value);
  formData.dadosAdicionais.pais = pais;

  log("signUpAction updateFormData", { formData, });

  dispatch({
    type: actionTypes.SIGN_UP,
    formData,
  });
};

export type updateAllFormDataType = (dados: Record<string,any>) => (dispatch: dispatchTipo, getState: getStateType) => void;

/**
 * 
 * @param {any} dados 
 * @returns
 */
export const updateAllFormData: updateAllFormDataType = (dados) => {
  return function(dispatch, getState) {
    const state = getState();
    const formData = {
      ...state.signUpReducer.formData,
      ...dados,
    }
    const pais = getURIFromEntity(formData.dadosAdicionais.paisOption?.value);
    formData.dadosAdicionais.pais = pais;

    dispatch({
      type: actionTypes.SIGN_UP,
      formData,
    });
  }
}

export type clearType = () => (dispatch: dispatchTipo) => void;

/**
 * Traz o *reducer* para o estado inicial
 */
export const clear: clearType = () => dispatch => {
  log("signUpAction clearFormData");

  dispatch({
    ...buildInitialState(),
    type: actionTypes.SIGN_UP,
  });
};