import { updateObject } from "../../utils/reducerUtils"

export const modalReducerActionTypes = {
  RESET_MODAL: "RESET_MODAL:MODAL_REDUCER",
  CLOSE_MODAL: "CLOSE_MODAL:MODAL_REDUCER",
  OPEN_MODAL: "OPEN_MODAL:MODAL_REDUCER",
  TOGGLE_MODAL: "TOGGLE_MODAL:MODAL_REDUCER",
};

/**
 * 
 * @typedef {Object} modalReducerType
 * @property {boolean} modalIsOpen
 * @property {string?} type
 */

/**
 * 
 * @type {modalReducerType}
 */
export const modalReducerInitialState = {
  tabIndex: 0,
  modalIsOpen: false,
};

/**
 * 
 * @param {modalReducerType} currentState 
 * @param {Partial<modalReducerType>} action 
 * @returns {modalReducerType}
 */
export function modalReducer(currentState = modalReducerInitialState, action) {
  /**
   * 
   * @type {modalReducerType}
   */
  let newState;

  switch (action.type) {
    case modalReducerActionTypes.CLOSE_MODAL:
      newState = updateObject(currentState, {
        tabIndex: 0,
        modalIsOpen: false,
      }, action.type);
      return newState;
    case modalReducerActionTypes.OPEN_MODAL:
      newState = updateObject(currentState, {
        tabIndex: -1,
        modalIsOpen: true,
      }, action.type);
      return newState;
    case modalReducerActionTypes.TOGGLE_MODAL:
      const modalIsOpen = !currentState.modalIsOpen;
      newState = updateObject(currentState, {
        tabIndex: modalIsOpen ? -1 : 0,
        modalIsOpen: modalIsOpen,
      }, action.type);
      return newState;
    case modalReducerActionTypes.RESET_MODAL:
      newState = modalReducerInitialState;
      return newState;
    default:
      newState = currentState;
      return newState;
  }
}

export default modalReducer;