import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { goBack } from "../../utils/NavigatorUtils"
import { getReduxWrappedComponent } from "../../utils/reduxUtils/reduxUtils"
import { blurAll, updateActiveMenuItem } from "../../utils/ScreenUtils"
import {
  cadastroBuildJSONFromOptions
  , cadastroBuildOptionsFromCargos_LabelEmpresa,
  cadastroBuildOptionsFromPaises
} from "../../utils/SelectUtils"

import * as actions from "../../store/actions/usuarioAction"
import * as actionTypes from "../../store/actions/actionTypes"
import * as appActions from "../../store/actions/appAction"

import BarraAcoesForm from "../cadastros/barraAcao/BarraAcoesForm"
import EnderecoForm from "../cadastros/form/EnderecoForm"
import FiltroInputDefault from "../UI/filtroInputDefault/FiltroInputDefault"
import HelpParagraph from "../UI/HelpParagraph/HelpParagraph"
import InputCustomizado from "../UI/Input/InputCustomizado"
import NomeInputDefault from "../cadastros/cadastroInputDefault/NomeInputDefault"
import WidthAwareDiv from "../UI/WidthAwareDiv/WidthAwareDiv"
import F3Login from "../Auth/F3SDK/F3Login"
import F3Button from "../Auth/F3SDK/F3ButtonComponent"
import { getF3UserPicture } from "../Auth/F3SDK/F3SDKReact"
import F3Logout from "../Auth/F3SDK/F3Logout"

import { DATE_INPUT } from "../UI/dateTimeInputDefault/DateTimeInputDefault"
import { getURIFromEntity } from "../../utils/URIUtils"

/**
 * Componente para alteração de dados do Usuário. 
 */
class UsuarioForm extends React.Component {

  componentDidMount() {
    log("UsuarioForm componentDidMount");

    updateActiveMenuItem("menuItemUsuarioForm", "menuItemUsuario");

    // Busca os dados do Usuário
    this.props.getUsuario();
  }

  componentDidUpdate() {
    log("UsuarioForm componentDidUpdate");
    if (this.props.loading)
      return;

    // Validação necessária para não resetar os dados dos campos ao clicar no Submit e aparecer a notificação
    if ((this.usuarioLocal === undefined) || (this.usuarioLocal === null)) {
      this.usuarioLocal = this.props.usuario;
    }
    else {
      return;
    }

    // Carrega os dados do usuário nos campos
    let cadastro = this.props.usuario;

    if ((cadastro === undefined) || (cadastro === null))
      return;

    let dadosAdicionais = cadastro.dadosAdicionais;

    if (!dadosAdicionais) {
      return;
    }

    this.nome.inputField.inputComponent.value = cadastro.nome;
    this.sobrenome.inputComponent.value = cadastro.sobrenome;

    let parametrosUsuario = cadastro.parametrosUsuario || {};
    this.tamanhoPadraoPaginacao.setMaskValue(parametrosUsuario.tamanhoPadraoPaginacao);
    this.aceitarNotificacoes.inputComponent.checked = parametrosUsuario.aceitarNotificacoes;

    this.cpf.setMaskValue(dadosAdicionais.cpf_cnpj);
    this.dataNascimento.setValue(dadosAdicionais.dataNascimento);
    this.telefone.inputComponent.value = dadosAdicionais.telefone;
    
    // this.uf.inputComponent.value = dadosAdicionais.uf;
    // this.municipio.inputComponent.value = dadosAdicionais.municipio;
    // this.bairro.inputComponent.value = dadosAdicionais.bairro;
    // this.endereco.inputComponent.value = dadosAdicionais.endereco;
    // this.numero.inputComponent.value = dadosAdicionais.numero;
    // this.complemento.inputComponent.value = dadosAdicionais.complemento;
    // this.cep.inputComponent.value = dadosAdicionais.cep;

    this.loadCargoList(cadastro);
  }

  /**
   * Método que popula a combobox de seleção do Empresa Padrão com os Cargos vinculados ao Usuário. 
   */
  loadCargoList = cadastro => {
    log("UsuarioForm loadCargoList", cadastro);
    this.cargoPadrao.inputComponent.updateOptions(cadastroBuildOptionsFromCargos_LabelEmpresa(true, cadastro.cargoList));

    // Se não for uma alteração de cadastro ou o cadastro não possuir um tipo definido, retorna
    if ((cadastro == null) || (cadastro.cargoPadrao == null)) {
      return;
    }

    // Seleciona o valor atual do cadastro
    this.cargoPadrao.inputComponent.updateValue(cadastroBuildOptionsFromCargos_LabelEmpresa(false, cadastro.cargoPadrao));
  }

  /**
   * Método que o formulário com os dados. 
   */
  loadUsuarioForm = () => {
    log("UsuarioForm loadUsuarioForm");
    return <div id="conteudo-box">

      <form onSubmit={() => { }} method="post">

        <BarraAcoesForm handleListar={() => goBack()} handleSubmit={this.handleGravar} />

        <WidthAwareDiv>

          <HelpParagraph>
            {[getStrings().userFormHelp]}
          </HelpParagraph>

          {this.loadUsuarioFormFields()}

        </WidthAwareDiv>
      </form>
    </div>;
  }

  BotaoAnexarFacebook = (props) => {
    return <div style={{ display: "flex", justifyContent: "center", marginTop: "2em" }}>
      <F3Button className="f3-button" type="button" onClick={(event) => {
        event.stopPropagation();
        const FB = window.FB;

        if (FB) {
          this.props.appSpinnerShow("loadF3");

          F3Login((response) => {
            props.externalUpdateConnected && props.externalUpdateConnected(response);
            this.props.appSpinnerHide("loadF3");
          });
        }
      }} children={getStrings().f3AccountRegisterLink} />
    </div>;
  }

  FacebookConnected = (props) => {
    const [imageSource, updateImageSource] = React.useState(null);

    this.props.appSpinnerShow("loadF3");

    getF3UserPicture("me", (response) => {
      if ((response || {}).data) {
        updateImageSource(response.data.url);
        this.props.appSpinnerHide("loadF3");
      }
    });

    return <div className="f3-button">
      {imageSource
        ? <img alt="imagem do perfil" src={imageSource} />
        : null
      }
      <p>Connected with Facebook</p>
      <button type="button" onClick={() => {
        F3Logout((response) => {
          props.externalUpdateConnected && props.externalUpdateConnected(response);
        });
      }} children={getStrings().f3Logout} />
    </div>;
  }

  FacebookStatus = (props) => {
    const [connected, updateConnected] = React.useState(false);
    const [token, updateToken] = React.useState(null);

    const FacebookConnected = this.FacebookConnected;
    const BotaoAnexarFacebook = this.BotaoAnexarFacebook;

    return connected
      ? <FacebookConnected externalUpdateConnected={(response) => {
        const external_connected = (response || {}).status === "connected";

        if (!external_connected) {
          external_connected !== connected && updateConnected(external_connected);
          token && updateToken(null);
        }
      }} />
      : <BotaoAnexarFacebook externalUpdateConnected={(response) => {
        const external_connected = (response || {}).status === "connected";

        if (external_connected) {
          external_connected !== connected && updateConnected(external_connected);

          const external_token = response.authResponse.accessToken;

          external_token !== token && updateToken(external_token);
          external_token && props.getToken && props.getToken(external_token);
        }
      }} />;
  };

  /**
   * Método que carrega os campos do formulário. 
   */
  loadUsuarioFormFields = () => {
    log("UsuarioForm loadUsuarioFormFields");
    //const FacebookStatus = this.FacebookStatus;

    return <div className="sub-form usuario-form">
      {/* {((this.props.usuario || {}).login || {}).facebookUserId
        ? null
        : <FacebookStatus getToken={(f3Token) => { this.f3Token = f3Token; }} />
      } */}

      <div className="sub-form">
        <h2>{getStrings().personalData}</h2>
        <NomeInputDefault
          ref={input => this.nome = getReduxWrappedComponent(input)}
          subPlaceholder={getStrings().userSubPlaceholder}
          isRequired={true}
          handleInputValidado={(value) => { }}
        />
        <InputCustomizado
          id="sobrenome"
          ref={input => this.sobrenome = getReduxWrappedComponent(input)}
          placeholder={getStrings().lastName}
          maxLength="40"
          type="text"
          name="sobrenome"
          required={true}
          label={getStrings().lastNameRequired}
        />
        <InputCustomizado
          id="cpf"
          name="cpf"
          type="text"
          inputType="maskedCPF"
          maxLength="20"
          required={true}
          ref={input => this.cpf = getReduxWrappedComponent(input)}
          label={getStrings().individualTaxpayerIDRequired}
          placeholder={getStrings().individualTaxpayerIDPlaceholder}
        />
        <FiltroInputDefault
          disableTimeout
          ref={input => { if (input) { this.dataNascimento = getReduxWrappedComponent(input); } }}
          inputType={DATE_INPUT}
          label={getStrings().birthday}
          placeholder={getStrings().birthdayPlaceholder}
        />
        <InputCustomizado
          id="telefone"
          ref={input => { if (input) { this.telefone = getReduxWrappedComponent(input); } }}
          placeholder={getStrings().phoneNumberPlaceholder}
          maxLength="15"
          type="text"
          name="telefone"
          validacaoDados="numeroInteiro"
          label={getStrings().phoneNumberRequired}
          required={true}
        />
      </div>

      <EnderecoForm
        key="usuario_form"
        companyForm={false}
        entity="userform"
        noAutoFocus
      />

      <div className="sub-form">
        <h2>{getStrings().passwordChange}</h2>
        <InputCustomizado
          id="senhaAtual"
          ref={input => this.senhaAtual = getReduxWrappedComponent(input)}
          autoComplete="off"
          placeholder={getStrings().currentPassword}
          maxLength="20"
          type="password"
          name="senhaAtual"
          required={false}
          label={getStrings().currentPassword}
        />
        <InputCustomizado
          id="novaSenha"
          ref={input => this.novaSenha = getReduxWrappedComponent(input)}
          autoComplete="off"
          placeholder={getStrings().newPassword}
          maxLength="20"
          type="password"
          name="novaSenha"
          required={false}
          label={getStrings().newPassword}
        />
        <InputCustomizado
          id="confirmacaoSenha"
          ref={input => this.confirmacaoSenha = getReduxWrappedComponent(input)}
          autoComplete="off"
          placeholder={getStrings().passwordConfirmation}
          maxLength="20"
          type="password"
          name="confirmacaoSenha"
          required={false}
          label={getStrings().passwordConfirmation}
        />
      </div>

      <div className="sub-form">
        <h2>{getStrings().parameters}</h2>
        <InputCustomizado
          id="cargo"
          inputType="singleSelect"
          name="cargo"
          ref={input => this.cargoPadrao = getReduxWrappedComponent(input)}
          placeholder={getStrings().companyDefaultPlaceholder}
          label={getStrings().companyDefault}
        />
        <InputCustomizado
          id="tamanhoPadraoPaginacao"
          ref={input => this.tamanhoPadraoPaginacao = getReduxWrappedComponent(input)}
          placeholder={getStrings().parameterDefaultPaginationSizePlaceholder}
          inputType="masked"
          validacaoDados="numeroInteiro"
          type="text" name="tamanhoPadraoPaginacao"
          required={false}
          label={getStrings().parameterDefaultPaginationSize}
          max={1000}
          scale={0}
          padFractionalZeros={false}
        />
        <InputCustomizado
          id="aceitarNotificacoes"
          name="notificacoes"
          type="checkbox"
          placeholder={""}
          label={getStrings().acceptNotifications}
          tabIndex={this.props.tabIndex}
          ref={input => this.aceitarNotificacoes = getReduxWrappedComponent(input)}
          handleInputValidado={this.props.handleChange}
        />
      </div>
    </div>;
  }

  /**
   * Método executado ao clicar no botão para confirmar as alterações. 
   */
  handleGravar = e => {
    log("UsuarioForm handleGravar", e);

    // Impede que o form redirecione para outra URL
    e.preventDefault();

    if (!(this.senhaAtual.inputComponent.value === "" && this.novaSenha.inputComponent.value === "" && this.confirmacaoSenha.inputComponent.value === "")
      && (this.senhaAtual.inputComponent.value === "" || this.novaSenha.inputComponent.value === "" || this.confirmacaoSenha.inputComponent.value === "")) {
      this.props.appSnackbarMessage(getStrings().invalidPassword);
      return;
    }

    blurAll();

    // Mensagem para confirmação de envio
    this.props.appNotificationShow(getStrings().saveChanges,
      actionTypes.APP_NOTIFICATION_TYPE_INFO_QUESTION, getStrings().save, () => this.submit());
  }

  submit = () => {
    log("UsuarioForm submit");
    this.usuarioLocal = null;

    let usuario = {
      nome: this.nome.getValue(),
      sobrenome: this.sobrenome.inputComponent.value,
      cargoPadrao: cadastroBuildJSONFromOptions(false, this.cargoPadrao.inputComponent.getValue()),
      parametrosUsuario: {
        tamanhoPadraoPaginacao: this.tamanhoPadraoPaginacao.getMaskValue(),
        aceitarNotificacoes: this.aceitarNotificacoes.inputComponent.checked,
      }
    };

    usuario.dadosAdicionais = {
      cpf_cnpj: this.cpf.getMaskValue(),
      dataNascimento: this.dataNascimento.getValue(),
      telefone: this.telefone.inputComponent.value,

      pais: getURIFromEntity(this.props.usuario.dadosAdicionais?.pais),
      paisOption: cadastroBuildOptionsFromPaises(false, this.props.usuario.dadosAdicionais?.pais),
      uf: this.props.usuario.dadosAdicionais?.uf || "",
      municipio: this.props.usuario.dadosAdicionais?.municipio || "",
      bairro: this.props.usuario.dadosAdicionais?.bairro || "",
      endereco: this.props.usuario.dadosAdicionais?.endereco || "",
      numero: this.props.usuario.dadosAdicionais?.numero || "",
      complemento: this.props.usuario.dadosAdicionais?.complemento || "",
      cep: this.props.usuario.dadosAdicionais?.cep || "",
    };

    if (this.senhaAtual.inputComponent.value !== "") {
      usuario.login = {
        senhaAtual: this.senhaAtual.inputComponent.value,
        senha: this.novaSenha.inputComponent.value,
        confirmacaoSenha: this.confirmacaoSenha.inputComponent.value
      };
    }

    this.props.updateUsuario(usuario, this.f3Token);
  }

  key_down = (event) => {
    if (event.key === "Enter") {
      this.handleGravar(event);
    }
  }

  render() {
    log("UsuarioForm render");
    let content = null;

    if (!this.props.loading) {
      content = this.loadUsuarioForm();
    }

    return <div onKeyDown={this.key_down} id="cadastro-box" style={{ height: "100%" }}>
      {/* Header do box */}
      <div className="header breadCrumbs">
        <h1>{getStrings().user}</h1>
        <h2>{getStrings().settings}</h2>
      </div>

      {content}
    </div>;
  }
}

function mapStateToProps(state) {
  const props = {
    ...state.idiomaReducer,
    loading: state.usuarioReducer.loading,
    usuario: state.usuarioReducer.usuario || {},
  };

  return props;
};

function mapDispatchToProps(dispatch) {
  const props = {
    getUsuario: () => dispatch(actions.getUsuario()),
    updateUsuario: (usuario, f3Token) => dispatch(actions.updateUsuario(usuario, { f3Token })),
  
    appNotificationShow: (notificationMessage, notificationType, notificationTitle, notificationAction) =>
      dispatch(appActions.appNotificationShow(notificationMessage, notificationType, notificationTitle, notificationAction)),
  
    appSnackbarMessage: (message, undoAction) => dispatch(appActions.appSnackbarMessage(message, undoAction)),
  
    appSpinnerShow: (origin) => dispatch(appActions.appSpinnerShow(origin)),
    appSpinnerHide: (origin) => dispatch(appActions.appSpinnerHide(origin)),
  };

  return props;
};

export default connect(mapStateToProps, mapDispatchToProps)(UsuarioForm);

// Monta o JSON a partir das opções selecionadas