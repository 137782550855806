//@ts-check
import React from 'react';

import PrincipalTela from './componentes/telas/PrincipalTela/PrincipalTela';
import Rotas from './rotas/Rotas';

/**
 * 
 * @param {{history: any}} props
 * @returns {JSX.Element}
 */
function App({history}) {
  return <PrincipalTela key='__web_app__' history={history}>
    <Rotas />
  </PrincipalTela>;
}

export default App;
