import { isNonEmptyArray } from "../../../utils/ComparatorsUtils"
import { updateObject } from "../../../utils/ObjectUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import * as actionTypes from "../../actions/actionTypes"

export const DISCOUNT_TYPE_PERCENTAGE = "DISCOUNT_TYPE_PERCENTAGE"
export const DISCOUNT_TYPE_VALUE = "DISCOUNT_TYPE_VALUE"

/**
 * Cancelamento dos pagamentos de venda selecionados.
 */
export const PAYMENT_CANCEL_PAYMENT = "payment"
/**
 * Cancelamento das vendas dos pagamentos de venda selecionados
 * e de todos os seus pagamentos de venda.
 */
export const PAYMENT_CANCEL_SALE = "sale"
/**
 * Cancelamento de todas as vendas abertas das origens de venda selecionadas
 * e de todos os seus pagamentos de venda.
 */
export const PAYMENT_CANCEL_SALESOURCE = "saleSource"
export const PAYMENT_PAYED_VALUE = "payedValue"

/**
 * Estado Inicial, obrigatório no Reducer.
 */
export const initialState = {

  /**
   * Se o desconto aplicado será em porcentagem ou em valor final.
   */
  discountType: DISCOUNT_TYPE_VALUE

  /**
   * Valor do desconto, que pode ser uma porcentagem ou um valor final.
   */
  , discountValue: 0

  /**
   * Forma de pagamento.
   */
  , paymentMethod: null

  , percentualComissaoAtual: 0
  , percentualComissaoEmpresa: 0

  /**
   * Lista de *links* de pagamentos de venda para cancelar.
   */
  , salePaymentToCancelList: []

  , payedValue: 0
}

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {

  switch (action.type) {
    // Adiciona um pagamento na lista de marcados para cancelamento
    case actionTypes.PAGAMENTO_VENDAS_ADD_CANCEL:

      if (!isNonEmptyArray(action.salePaymentToCancelList)) {
        return state
      }

      return updateObject(state, {
        ...state
        , salePaymentToCancelList: (state.salePaymentToCancelList || []).concat(action.salePaymentToCancelList || [])
      })
    // Desmarca todos os pagamentos para cancelamento
    case actionTypes.PAGAMENTO_VENDAS_CLEAR_CANCEL:

      return updateObject(state, {
        ...state
        , salePaymentToCancelList: []
      })
    // Desmarca um pagamento para cancelamento
    case actionTypes.PAGAMENTO_VENDAS_REMOVE_CANCEL:

      if (!(action.salePaymentToCancelList)) {
        return state
      }

      return updateObject(state, {
        ...state
        // Nova lista é a lista anterior sem os pagamentos a serem removidos
        , salePaymentToCancelList: (state.salePaymentToCancelList || [])
          .filter(salePaymentToCancel =>
            // Só permite na nova lista os itens que não estiverem na lista passada na ação
            !((action.salePaymentToCancelList || [])
              .some(actionSalePaymentToCancel => getURIFromEntity(salePaymentToCancel) === getURIFromEntity(actionSalePaymentToCancel))
            )
          )
      })
    case actionTypes.PAGAMENTO_VENDAS_UPDATE:

      // Basta controlar a construção do action no outro arquivo.
      return updateObject(state, {
        ...state
        , ...action
      })

    case PAYMENT_PAYED_VALUE:
      return {
        ...state
        , payedValue: (typeof action.payload === "number" && action.payload > 0) ? action.payload : 0
      }
    default: return state
  }
}

export default reducer