export const UPDATE_FLAG = 'TAB_INDEX:UPDATE';

const initialState = {
    superItem: 0,
    registersItem: -1,
    controlItem: -1,
    reportsItem: -1,
    settingsItem: -1,
};

const actions = (() => {
    let object = {};

    object[UPDATE_FLAG] = (payload, state) => {
        let obj = {...state};

        for (let items in payload) {
            obj[items] = payload[items];
        }

        return obj;
    };

    return object;
})();

const tabIndexReducer = (state = initialState, {type, payload}) => {
    try {
        return actions[type](payload, state);
    } catch (e) {}

    return state;
};

export default tabIndexReducer;