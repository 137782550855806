import { acoesNomeadasTipo, tipoAcao } from '../../../actions/acaoTemplate'
import redutorTemplate from '../../redutorTemplate'

export const tiposDeAcoes = {
    ATUALIZAR_ENTRADA_BUSCA: 'ATUALIZAR_ENTRADA_BUSCA:ENTRADA_BUSCA',
    LIMPAR_ENTRADA_BUSCA: 'LIMPAR_ENTRADA_BUSCA:ENTRADA_BUSCA',
}

export type entradaBuscaReducerTipo = {
    texto: string,
}

const estadoInicial: entradaBuscaReducerTipo = {
    texto: '',
}

const acoesNomeadas: acoesNomeadasTipo<entradaBuscaReducerTipo> = {
    [tiposDeAcoes.ATUALIZAR_ENTRADA_BUSCA]: ({texto}: {texto: string}, estadoAtual) => {
        const novoEstado: entradaBuscaReducerTipo = {
            ...estadoAtual,
            texto,
        }

        return novoEstado;
    },
    [tiposDeAcoes.LIMPAR_ENTRADA_BUSCA]: (_: any, estadoAtual) => {
        const novoEstado: entradaBuscaReducerTipo = {
            ...estadoAtual,
            texto: '',
        }

        return novoEstado;
    },
}

export default function entradaBuscaReducer(estado = estadoInicial, acao: tipoAcao) {
    return redutorTemplate(estado, acao, acoesNomeadas);
}
