import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { formatI18nString } from "../../../utils/StringUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import * as cadastroActions from '../../../store/actions/cadastroAction';
import * as cadastroPrintActions from '../../../store/actions/cadastroPrintAction';

import { FAIL } from '../../../store/reducers/cadastroPrintReducer';

import BarraAcoesForm, { SUBMIT_ROLE_PRINT } from '../barraAcao/BarraAcoesForm';
import BuildCellFromCadastroPrintCode from './BuildCellFromCadastroPrintCode';
import HelpParagraph from '../../UI/HelpParagraph/HelpParagraph';
import WidthAwareDiv from '../../UI/WidthAwareDiv/WidthAwareDiv';

/**
 * Classe que monta a tela que exibe como ficarão os códigos depois de impressos.
 */
class CadastroPrintPreview extends React.Component {

	/**
	 * Método executado ANTES de "DESMONTAR" o componente.
	 * Marca o componente como NÃO montado.
	 */
    componentWillUnmount() {
        this.props.clearBuild();
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('CadastroPrintPreview render');

        // Ainda está trabalhando se não houver lista de registros
        let working = (!this.props.registerList)
            // ou lista de SVGs
            || (!this.props.svgMap)
            // ou a lista de registros não for do mesmo tamanho que a lista de SVGs
            || (this.props.registerList.length !== Object.keys(this.props.svgMap).length)
            // ou todos os SVGs falharam em serem gerados
            || Object.keys(this.props.svgMap).every(key => this.props.svgMap[key] === FAIL);

        // Verifica se nenhum registro teve seu código gerado
        let fail = this.props.registerList && this.props.svgMap
            && (this.props.registerList.length === Object.keys(this.props.svgMap).length)
            && Object.keys(this.props.svgMap).every(key => this.props.svgMap[key] === FAIL);

        return <div style={{ height: '100%' }}>

            <WidthAwareDiv>
                <HelpParagraph children={[formatI18nString(getStrings().printerCodePreviewHelp)]} />
            </WidthAwareDiv>
            <form onSubmit={event => {
                event.preventDefault();
                this.props.saveResult();
            }} method='post' style={{ height: '100%' }}>

                <BarraAcoesForm
                    attractSubmit={!working}
                    handleListar={() => this.props.showPrint()}
                    handleSubmit={() => { }}
                    isShowConfig={false}
                    isSubmit={true}
                    submitRole={SUBMIT_ROLE_PRINT}
                    addButtonDisabled={working}
                />
                {fail
                    ? <WidthAwareDiv className='sub-form'>
                        {getStrings().printedCodeInvalid}
                    </WidthAwareDiv>
                    : <div className='cadastroCodeList'>
                        {(this.props.registerList || []).map((register, index) => <BuildCellFromCadastroPrintCode
                            register={register}
                            index={index}
                            key={getURIFromEntity(register)}
                            uri={getURIFromEntity(register)}
                            nome={register.nome}
                        />)}
                    </div>}
            </form>
        </div>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    ...state.cadastroPrintReducer,
    ...state.idiomaReducer,
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    buildCodes: () => dispatch(cadastroPrintActions.buildCodes()),

    cadastroSwitchUI: (state, cadastroDados, keepLoading, formMounted, operation) =>
        dispatch(cadastroActions.cadastroSwitchUI(state, cadastroDados, keepLoading, formMounted, operation)),

    clearBuild: () => dispatch(cadastroPrintActions.clearBuild()),

    saveResult: () => dispatch(cadastroPrintActions.saveResult()),

    setValue: (value, position) => dispatch(cadastroPrintActions.setValue(value, position)),

    showPrint: () => dispatch(cadastroActions.showPrint()),
});

/**
* Exporta o último argumento entre parênteses.
*/
export default connect(mapStateToProps, mapDispatchToProps)(CadastroPrintPreview);
