import React from "react"

import { joinObject } from "../../../utils/ArrayUtils"
import { log } from "../../../utils/LogUtils"
import { listToFragment } from "../../../utils/ScreenUtils"

import I18n from "../I18n/I18n"
import LinkRedux from "../LinkRedux/LinkRedux"

/**
 * Coloca separadores em uma lista de objetos e passa a lista resultante para uma lista de componentes.
 * @param {*} list 
 */
export const addSeparator = list => {
  log('BreadCrumbs addSeparator', { list });
  return listToFragment(joinObject(list, I18n('breadCrumbsSeparator')));
};

export const home_reader = () => {
  log('BreadCrumbs home_reader');
  return addSeparator([
    <LinkRedux to='/' >{I18n('homePage')}</LinkRedux>,
    I18n('codeReader')
  ]);
};

export const home_reader_company = readerAction => {
  log('BreadCrumbs home_reader_company', { readerAction });
  return addSeparator([
    <LinkRedux to='/' >{I18n('homePage')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); readerAction() }}>{I18n('codeReader')}</LinkRedux>,
    I18n('companyInformation')
  ]);
};

export const home_reader_orders = readerAction => {
  log('BreadCrumbs home_reader_orders', { readerAction });
  return addSeparator([
    <LinkRedux to='/' >{I18n('homePage')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); readerAction(); }}>{I18n('codeReader')}</LinkRedux>,
    I18n('orders')
  ]);
};

export const home_reader_orders_saleProducts = (homeAction, readerAction, ordersAction, venda) => {
  log('BreadCrumbs home_reader_orders_saleProducts', { homeAction, readerAction, ordersAction, venda });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); homeAction(); }}>{I18n('homePage')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); readerAction(); }}>{I18n('codeReader')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); ordersAction(); }}>{I18n('orders')}</LinkRedux>,
    I18n('breadCrumbsSaleProducts', false, [(venda || {}).nome || I18n('newSale')])
  ]);
};

export const home_reader_orders_saleProducts_items = (homeAction, readerAction, ordersAction, venda, saleProductsAction) => {
  log('BreadCrumbs home_reader_orders_saleProducts_items', { homeAction, readerAction, ordersAction, venda, saleProductsAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); homeAction(); }}>{I18n('homePage')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); readerAction(); }}>{I18n('codeReader')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); ordersAction(); }}>{I18n('orders')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); saleProductsAction(); }}>{I18n('breadCrumbsSaleProducts', false, [(venda || {}).nome || I18n('newSale')])}</LinkRedux>,
    I18n('items')
  ]);
};

export const home_reader_orders_saleProducts_payment = (homeAction, readerAction, ordersAction, venda, saleProductsAction) => {
  log('BreadCrumbs home_reader_orders_saleProducts_payment', { homeAction, readerAction, ordersAction, venda, saleProductsAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); homeAction(); }}>{I18n('homePage')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); readerAction(); }}>{I18n('codeReader')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); ordersAction(); }}>{I18n('orders')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); saleProductsAction(); }}>{I18n('breadCrumbsSaleProducts', false, [(venda || {}).nome || I18n('newSale')])}</LinkRedux>,
    I18n('payment')
  ]);
};

export const home_reader_saleProducts = (homeAction, readerAction, venda) => {
  log('BreadCrumbs home_reader_saleProducts', { homeAction, readerAction, venda });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); homeAction(); }}>{I18n('homePage')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); readerAction(); }}>{I18n('codeReader')}</LinkRedux>,
    I18n('breadCrumbsSaleProducts', false, [(venda || {}).nome || I18n('newSale')])
  ]);
};

export const home_reader_saleProducts_items = (homeAction, readerAction, venda, saleProductsAction) => {
  log('BreadCrumbs home_reader_saleProducts_items', { homeAction, readerAction, venda, saleProductsAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); homeAction(); }}>{I18n('homePage')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); readerAction(); }}>{I18n('codeReader')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); saleProductsAction(); }}>{I18n('breadCrumbsSaleProducts', false, [(venda || {}).nome || I18n('newSale')])}</LinkRedux>,
    I18n('items')
  ]);
};

export const home_reader_saleProducts_payment = (homeAction, readerAction, venda, saleProductsAction) => {
  log('BreadCrumbs home_reader_saleProducts_payment', { homeAction, readerAction, venda, saleProductsAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); homeAction(); }}>{I18n('homePage')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); readerAction(); }}>{I18n('codeReader')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); saleProductsAction(); }}>{I18n('breadCrumbsSaleProducts', false, [(venda || {}).nome || I18n('newSale')])}</LinkRedux>,
    I18n('payment')
  ]);
};

export const registers_create = listarAction => {
  log('BreadCrumbs registers_create', { listarAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); listarAction() }} >{I18n('registers')}</LinkRedux>,
    I18n('create')
  ]);
};

export const registers_create_setup = (listarAction, createAction) => {
  log('BreadCrumbs registers_create_setup', { listarAction, createAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); listarAction() }} >{I18n('registers')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); createAction() }} >{I18n('create')}</LinkRedux>,
    I18n('settings')
  ]);
};

export const registers_edit = (listarAction, registerName) => {
  log('BreadCrumbs registers_edit', { listarAction, registerName });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); listarAction() }} >{I18n('registers')}</LinkRedux>,
    I18n('editTemplate', false, [registerName])
  ]);
};

export const registers_print = listarAction => {
  log('BreadCrumbs registers_print', { listarAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); listarAction() }} >{I18n('registers')}</LinkRedux>,
    I18n('printCodes')
  ]);
};

export const registers_print_preview = (listarAction, setupAction) => {
  log('BreadCrumbs registers_print_preview', { listarAction, setupAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); listarAction() }} >{I18n('registers')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); setupAction() }} >{I18n('printCodes')}</LinkRedux>,
    I18n('preview')
  ]);
};

export const saleProducts = venda => {
  log('BreadCrumbs saleProducts', { venda });
  return I18n('breadCrumbsSaleProducts', false, [(venda || {}).nome || I18n('newSale')]);
};

export const saleProducts_items = (venda, saleProductsAction) => {
  log('BreadCrumbs saleProducts_items', { venda, saleProductsAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); saleProductsAction() }}>{I18n('breadCrumbsSaleProducts', false, [(venda || {}).nome || I18n('newSale')])}</LinkRedux>,
    I18n('items')
  ]);
};

export const saleProducts_payment = (venda, saleProductsAction) => {
  log('BreadCrumbs saleProducts_payment', { venda, saleProductsAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); saleProductsAction() }}>{I18n('breadCrumbsSaleProducts', false, [(venda || {}).nome || I18n('newSale')])}</LinkRedux>,
    I18n('payment')
  ]);
};

export const signUp_user = () => {
  log('BreadCrumbs signUp_user');
  return addSeparator([
    <LinkRedux to='/signUp'>{I18n('signUp')}</LinkRedux>,
    I18n('user')
  ]);
};

export const signUpCompany_contract = () => {
  log('BreadCrumbs signUpCompany_contract');
  return addSeparator([
    <LinkRedux to='/signUp'>{I18n('signUp')}</LinkRedux>,
    I18n('contract')
  ]);
};

export const signUpCompany_contract_company = (signUpBackAction) => {
  log('BreadCrumbs signUpCompany_contract_company', { signUpBackAction });
  return addSeparator([
    <LinkRedux to='/signUp'>{I18n('signUp')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); signUpBackAction() }}>{I18n('contract')}</LinkRedux>,
    I18n('company')
  ]);
};

export const signUpCompany_contract_company_user = (signUpContractAction, signUpBackAction) => {
  log('BreadCrumbs signUpCompany_contract_company_user', { signUpContractAction, signUpBackAction });
  return addSeparator([
    <LinkRedux to='/signUp'>{I18n('signUp')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); signUpContractAction() }}>{I18n('contract')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); signUpBackAction() }}>{I18n('company')}</LinkRedux>,
    I18n('user')
  ]);
};

export const signUpCompany_contract_company_user_confirmation = () => {
  log('BreadCrumbs signUpCompany_contract_company_user_confirmation');
  return addSeparator([
    <LinkRedux to='/signUp'>{I18n('signUp')}</LinkRedux>,
    <LinkRedux to='/signUp'>{I18n('contract')}</LinkRedux>,
    <LinkRedux to='/signUp'>{I18n('company')}</LinkRedux>,
    <LinkRedux to='/signUp'>{I18n('user')}</LinkRedux>,
    I18n('confirmation')
  ]);
};

export const sourcesInUse_client = (sourcesInUseAction, client) => {
  log('BreadCrumbs sourcesInUse_client', { sourcesInUseAction, client });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); sourcesInUseAction() }}>{I18n('sourcesInUse')}</LinkRedux>,
    I18n('breadCrumbsClient', false, [client])
  ]);
};

export const sourcesInUse_client_codeReader = (sourcesInUseAction, client, clientAction) => {
  log('BreadCrumbs sourcesInUse_client_codeReader', { sourcesInUseAction, client, clientAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); sourcesInUseAction() }}>{I18n('sourcesInUse')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); clientAction() }}>{I18n('breadCrumbsClient', false, [client])}</LinkRedux>,
    I18n('codeReader')
  ]);
};

export const sourcesInUse_codeReader = sourcesInUseAction => {
  log('BreadCrumbs sourcesInUse_codeReader', { sourcesInUseAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); sourcesInUseAction() }}>{I18n('sourcesInUse')}</LinkRedux>,
    I18n('codeReader')
  ]);
};

export const sourcesInUse_salesPayment = sourcesInUseAction => {
  log('BreadCrumbs sourcesInUse_salesPayment', { sourcesInUseAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); sourcesInUseAction() }}>{I18n('sourcesInUse')}</LinkRedux>,
    I18n('payment')
  ]);
};

export const sourcesInUse_sourceSales = (sourcesInUseAction, nomeOrigemVenda) => {
  log('BreadCrumbs sourcesInUse_sourceSales', { sourcesInUseAction, nomeOrigemVenda });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); sourcesInUseAction() }}>{I18n('sourcesInUse')}</LinkRedux>,
    I18n('breadCrumbsSourceSales', false, [nomeOrigemVenda])
  ]);
};

export const sourcesInUse_sourceSales_saleProducts = (sourcesInUseAction, nomeOrigemVenda, sourceSalesAction, blockSales, venda) => {
  log('BreadCrumbs sourcesInUse_sourceSales_saleProducts', { sourcesInUseAction, nomeOrigemVenda, sourceSalesAction, blockSales, venda });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); sourcesInUseAction() }}>{I18n('sourcesInUse')}</LinkRedux>,
    blockSales ? I18n('breadCrumbsSourceSales', false, [nomeOrigemVenda]) :
      <LinkRedux to='navigation' onClick={e => { e.preventDefault(); sourceSalesAction() }}>{I18n('breadCrumbsSourceSales', false, [nomeOrigemVenda])}</LinkRedux>,
    I18n('breadCrumbsSaleProducts', false, [venda ? venda.nome : I18n('newSale')])
  ]);
};

export const sourcesInUse_sourceSales_saleProducts_items = (sourcesInUseAction, nomeOrigemVenda, sourceSalesAction, blockSales, venda, saleProductsAction) => {
  log('BreadCrumbs sourcesInUse_sourceSales_saleProducts_items', { sourcesInUseAction, nomeOrigemVenda, sourceSalesAction, blockSales, venda, saleProductsAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); sourcesInUseAction() }}>{I18n('sourcesInUse')}</LinkRedux>,
    blockSales ? I18n('breadCrumbsSourceSales', false, [nomeOrigemVenda]) :
      <LinkRedux to='navigation' onClick={e => { e.preventDefault(); sourceSalesAction() }}>{I18n('breadCrumbsSourceSales', false, [nomeOrigemVenda])}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); saleProductsAction() }}>{I18n('breadCrumbsSaleProducts', false, [venda ? venda.nome : I18n('newSale')])}</LinkRedux>,
    I18n('items')
  ]);
};

export const sourcesInUse_sourceSales_saleProducts_payment = (sourcesInUseAction, nomeOrigemVenda, sourceSalesAction, blockSales, venda, saleProductsAction) => {
  log('BreadCrumbs sourcesInUse_sourceSales_saleProducts_payment', { sourcesInUseAction, nomeOrigemVenda, sourceSalesAction, blockSales, venda, saleProductsAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); sourcesInUseAction() }}>{I18n('sourcesInUse')}</LinkRedux>,
    blockSales ? I18n('breadCrumbsSourceSales', false, [nomeOrigemVenda]) :
      <LinkRedux to='navigation' onClick={e => { e.preventDefault(); sourceSalesAction() }}>{I18n('breadCrumbsSourceSales', false, [nomeOrigemVenda])}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); saleProductsAction() }}>{I18n('breadCrumbsSaleProducts', false, [venda ? venda.nome : I18n('newSale')])}</LinkRedux>,
    I18n('payment')
  ]);
};

export const sourcesInUse_sourceSales_salesPayment = (sourcesInUseAction, nomeOrigemVenda, sourceSalesAction) => {
  log('BreadCrumbs sourcesInUse_sourceSales_salesPayment', { sourcesInUseAction, nomeOrigemVenda, sourceSalesAction });
  return addSeparator([
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); sourcesInUseAction() }}>{I18n('sourcesInUse')}</LinkRedux>,
    <LinkRedux to='navigation' onClick={e => { e.preventDefault(); sourceSalesAction() }}>{I18n('breadCrumbsSourceSales', false, [nomeOrigemVenda])}</LinkRedux>,
    I18n('payment')
  ]);
};
