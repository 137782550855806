import { regexes, tem } from "./CadastroUtils";
import stringSimilarity from 'string-similarity';

const extendRules = {
    passwordSuggestions: (language) => [
        {
            rule: (senha) => tem(senha, regexes.letras, true) || (tem(senha, regexes.letras_e_nums, true) && senha.replace(regexes.digitos, "") === senha.replace(senha.replace(regexes.letras, "").toString(), "")),
            message:
                language === 'en_US' ? "Avoid using just a word and some digits." :
                language === 'pt_BR' ? "Evite usar somente uma palavra e alguns dígitos."
                : '',
        }, {
            rule: (senha) => tem(senha, regexes.letras_e_nums, true),
            message:
                language === 'en_US' ? "Use a special character." :
                language === 'pt_BR' ? "Use um caractere especial."
                : '',
        },
    ],
    passwordWarnings: (language) => [
        {
            rule: (senha) => tem(senha, regexes.letras, true),
            message:
                language === 'en_US' ? "Possibly a word. Susceptible to dictionary attacks." :
                language === 'pt_BR' ? "Possivelmente uma palavra. Suscetível a ataques de dicionário."
                : '',
        }, {
            rule: (senha) => tem(senha, regexes.letras) && tem(senha, regexes.letras_e_nums, true) && senha.replace(regexes.digitos, "") === senha.replace(senha.replace(regexes.letras, "").toString(), ""),
            message:
                language === 'en_US' ? "Possibly a word with some digits. Security still low." :
                language === 'pt_BR' ? "Possivelmente uma palavra com alguns dígitos. Segurança ainda é fraca."
                : '',
        }, {
            rule: (senha) => tem(senha, regexes.letras_e_nums, true),
            message:
                language === 'en_US' ? "Usage of some symbol or special characters can help increase the security of your password." :
                language === 'pt_BR' ? "O uso de algum símbolo ou caractere especial pode ajudar a aumentar a segurança da sua senha."
                : '',
        }, {
            rule: (senha) => senha.length < 12,
            message:
                language === 'en_US' ? "Short password." :
                language === 'pt_BR' ? "Senha muito pequena."
                : '',
        }, {
            rule: (senha) =>
                stringSimilarity.compareTwoStrings(senha, 'just@nexample-4') > 0.66
                || stringSimilarity.compareTwoStrings(senha, 'somente1exemplo-4') > 0.66
                || stringSimilarity.compareTwoStrings(senha, 'march.example') > 0.66
                || stringSimilarity.compareTwoStrings(senha, 'março.exemplo') > 0.66,
            message:
                language === 'en_US' ? "Don't use examples for your passwords." :
                language === 'pt_BR' ? "Não use os exemplos nas suas senhas."
                : '',
            underscore: 4,
        }
    ],
}

export default extendRules;