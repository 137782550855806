import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from '../../../utils/LocaleUtils';
import { log } from '../../../utils/LogUtils';
import { cadastroBuildOptionsFromPaperSize } from '../../../utils/SelectUtils';
import { formatI18nString } from '../../../utils/StringUtils';
import { getReduxWrappedComponent } from '../../../utils/reduxUtils/reduxUtils';

import * as cadastroActions from '../../../store/actions/cadastroAction';
import * as cadastroPrintActions from '../../../store/actions/cadastroPrintAction';

import { STATE_CADASTRO_LIST } from '../../../store/reducers/cadastroReducer';

import { FORMAT_PNG, FORMAT_SVG, MEDIA_FILES, MEDIA_PAGES, PAPER_SIZE_LIST, INTERNAL_USE, EXTERNAL_USE } from '../../../store/reducers/cadastroPrintReducer';

import BarraAcoesForm, { SUBMIT_ROLE_NEXT } from '../barraAcao/BarraAcoesForm';
import HelpParagraph from '../../UI/HelpParagraph/HelpParagraph';
import InputCustomizado from '../../UI/Input/InputCustomizado';
import WidthAwareDiv from '../../UI/WidthAwareDiv/WidthAwareDiv';

import "./CadastroPrint.css";

/**
 * Classe que monta a tela que define as opções para geração de código impresso.
 */
class CadastroPrintSetup extends React.Component {

    /**
     * Método executado APÓS a montagem/renderização do componente.
     */
    componentDidMount() {
        log('CadastroPrintSetup componentDidMount');

        this.paperSize.inputComponent.updateOptions(cadastroBuildOptionsFromPaperSize(true, PAPER_SIZE_LIST), 'label');

        ['codeHeight', 'fontSize', 'paperHeight', 'paperMargin', 'paperWidth'].filter(input => this[input]).forEach(input => this[input].setMaskValue(this.props[input]));

        this.componentDidUpdate();
    }

    /**
     * Método executado APÓS a atualização do componente.
     * Atualiza os campos.
     */
    componentDidUpdate() {
        log('CadastroPrintSetup componentDidUpdate');

        ['format', 'media', 'use',].forEach(prop => this[this.props[prop]] ? (this[this.props[prop]].inputComponent.checked = true) : null);

        if (this.includeRegisterCode) {
            this.includeRegisterCode.inputComponent.checked = this.props.includeRegisterCode
        }

        if (this.props.media === MEDIA_PAGES) {
            this.paperSize.inputComponent.updateValue(this.props.paperSize ? cadastroBuildOptionsFromPaperSize(false, this.props.paperSize) : null, 'key');
            this.paperMargin.setMaskValue(this.props.paperMargin);
        }
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('CadastroPrintSetup render');

        // Se todas as condições foram satisfeitas para permitir que os códigos sejam gerados.
        let ready = this.props.codeHeight
            && (this.props.fontSize || ((this.props.use === INTERNAL_USE) && (!this.props.includeRegisterCode)))
            && ((this.props.media === MEDIA_FILES) || (this.props.paperSize && this.props.paperMargin && ((this.props.paperSize.key !== 'custom') || (this.props.paperWidth && this.props.paperHeight))));

        let showCustomPaperInput = (this.props.media === MEDIA_PAGES) && (this.props.paperSize && (this.props.paperSize.key === 'custom'));

        return <div style={{ height: '100%' }}>

            <WidthAwareDiv>
                <HelpParagraph children={getStrings().printedCodeSetupHelp(this.props.printExternalEnabled).map(string => formatI18nString(string))} />
            </WidthAwareDiv>

            <form onSubmit={event => {
                event.preventDefault();
                this.props.buildCodes();
            }} method='post' style={{ height: '100%' }}>

                <BarraAcoesForm
                    addButtonDisabled={!ready}
                    attractSubmit={ready}
                    handleListar={() => this.props.cadastroSwitchUI(STATE_CADASTRO_LIST)}
                    handleSubmit={() => { }}
                    isShowConfig={false}
                    isSubmit={true}
                    submitRole={SUBMIT_ROLE_NEXT}
                />

                <WidthAwareDiv>

                    {/* Uso externo (colocado em origens de venda para usuário sem cadastro ver o menu) ou interno (para selecionar origens de venda ou produtos) */}
                    {this.props.printExternalEnabled ? <div className='sub-form'>

                        <InputCustomizado ref={input => this.external = getReduxWrappedComponent(input)} id='externalRadio' type='radio' name='internalExternalRadio' label={getStrings().externalCode} handleInputValidado={() => this.props.setValue(EXTERNAL_USE, 'use')} />

                        <InputCustomizado ref={input => this.internal = getReduxWrappedComponent(input)} id='internalRadio' type='radio' name='internalExternalRadio' label={getStrings().internalCode} handleInputValidado={() => this.props.setValue(INTERNAL_USE, 'use')} />

                    </div> : null}
                    {/* Geração de arquivos separados ou de PDF contendo todos */}
                    <div className='sub-form'>

                        <InputCustomizado ref={input => this.pages = getReduxWrappedComponent(input)} id='pagesRadio' type='radio' name='filesPagesRadio' label={getStrings().pages} handleInputValidado={() => this.props.setValue(MEDIA_PAGES, 'media')} />

                        <InputCustomizado ref={input => this.files = getReduxWrappedComponent(input)} id='filesRadio' type='radio' name='filesPagesRadio' label={getStrings().files} handleInputValidado={() => this.props.setValue(MEDIA_FILES, 'media')} />

                    </div>
                    <div className='sub-form'>{(this.props.media === MEDIA_FILES)
                        // Geração de arquivo em PNG ou SVG
                        ? <>
                            <InputCustomizado ref={input => this.png = getReduxWrappedComponent(input)} id='pngRadio' type='radio' name='formatRadio' label={getStrings().png} handleInputValidado={() => this.props.setValue(FORMAT_PNG, 'format')} />

                            <InputCustomizado ref={input => this.svg = getReduxWrappedComponent(input)} id='svgRadio' type='radio' name='formatRadio' label={getStrings().svg} handleInputValidado={() => this.props.setValue(FORMAT_SVG, 'format')} />
                        </>
                        : null}

                        {/* Tamanho do papel */}
                        <InputCustomizado id='paperSize' inputType='singleSelect' name='paperSize' ref={input => this.paperSize = getReduxWrappedComponent(input)} placeholder={getStrings().paperSizePlaceholder} label={getStrings().paperSizeRequired} onChange={() => setTimeout(() => this.props.setValue((this.paperSize.inputComponent.getValue() || { value: null }).value, 'paperSize'), 0)} required={this.props.media === MEDIA_PAGES} topClassName={(this.props.media === MEDIA_PAGES) ? '' : 'hidden'} />

                        {showCustomPaperInput ? <>
                            {/* Largura customizada do papel */}
                            <InputCustomizado
                                id='paperWidth'
                                max={100000000}
                                scale={2}
                                inputType='masked'
                                type='text'
                                validacaoDados='numeroDecimal'
                                name='paperWidth'
                                required={showCustomPaperInput}
                                ref={input => this.paperWidth = getReduxWrappedComponent(input)}
                                label={getStrings().paperWidthRequired}
                                placeholder={getStrings().paperWidthPlaceholder}
                                // Atualiza a quantidade do produto no estado a cada dígito digitado, a menos que o campo contenha um número inválido.
                                onInput={() => {
                                    if (this.paperWidth.getMaskValue() || (this.paperWidth.getMaskValue() === 0)) {
                                        this.props.setValue(this.paperWidth.getMaskValue(), 'paperWidth');
                                    }
                                }}
                                // Ao sair do campo, se ele possui um valor inválido ou estiver vazio, altera a quantidade do produto para zero.
                                // Isso não pode ser feito antes, senão atrapalha a digitação do campo.
                                onBlur={() => {
                                    if ((!this.paperWidth.getMaskValue()) && (this.paperWidth.getMaskValue() !== 0)) {
                                        this.props.setValue(0, 'paperWidth');
                                    }
                                }}
                            />

                            {/* Altura customizada do papel */}
                            <InputCustomizado
                                id='paperHeight'
                                max={100000000}
                                scale={2}
                                inputType='masked'
                                type='text'
                                validacaoDados='numeroDecimal'
                                name='paperHeight'
                                required={showCustomPaperInput}
                                ref={input => this.paperHeight = getReduxWrappedComponent(input)}
                                label={getStrings().paperHeightRequired}
                                placeholder={getStrings().paperHeightPlaceholder}
                                // Atualiza a quantidade do produto no estado a cada dígito digitado, a menos que o campo contenha um número inválido.
                                onInput={() => {
                                    if (this.paperHeight.getMaskValue() || (this.paperHeight.getMaskValue() === 0)) {
                                        this.props.setValue(this.paperHeight.getMaskValue(), 'paperHeight');
                                    }
                                }}
                                // Ao sair do campo, se ele possui um valor inválido ou estiver vazio, altera a quantidade do produto para zero.
                                // Isso não pode ser feito antes, senão atrapalha a digitação do campo.
                                onBlur={() => {
                                    if ((!this.paperHeight.getMaskValue()) && (this.paperHeight.getMaskValue() !== 0)) {
                                        this.props.setValue(0, 'paperHeight');
                                    }
                                }}
                            />
                        </> : null}

                        {/* Margem do papel */}
                        {(this.props.media === MEDIA_PAGES)
                            ? <InputCustomizado
                                id='paperMargin'
                                max={100000000}
                                scale={2}
                                inputType='masked'
                                type='text'
                                validacaoDados='numeroDecimal'
                                name='paperMargin'
                                required={this.props.media === MEDIA_PAGES}
                                ref={input => this.paperMargin = getReduxWrappedComponent(input)}
                                label={getStrings().paperMarginRequired}
                                placeholder={getStrings().paperMarginPlaceholder}
                                // Atualiza a quantidade do produto no estado a cada dígito digitado, a menos que o campo contenha um número inválido.
                                onInput={() => {
                                    if (this.paperMargin.getMaskValue() || (this.paperMargin.getMaskValue() === 0)) {
                                        this.props.setValue(this.paperMargin.getMaskValue(), 'paperMargin');
                                    }
                                }}
                                // Ao sair do campo, se ele possui um valor inválido ou estiver vazio, altera a quantidade do produto para zero.
                                // Isso não pode ser feito antes, senão atrapalha a digitação do campo.
                                onBlur={() => {
                                    if ((!this.paperMargin.getMaskValue()) && (this.paperMargin.getMaskValue() !== 0)) {
                                        this.props.setValue(0, 'paperMargin');
                                    }
                                }}
                            />
                            : null}
                    </div>
                    <div className='sub-form'>

                        {/* Altura do código impresso (somente do código de barras ou QR) */}
                        <InputCustomizado
                            id='codeHeight'
                            max={100000000}
                            scale={2}
                            inputType='masked'
                            type='text'
                            validacaoDados='numeroDecimal'
                            name='codeHeight'
                            required={true}
                            ref={input => this.codeHeight = getReduxWrappedComponent(input)}
                            label={getStrings().codeHeightRequired}
                            placeholder={getStrings().codeHeightPlaceholder}
                            // Atualiza a quantidade do produto no estado a cada dígito digitado, a menos que o campo contenha um número inválido.
                            onInput={() => {
                                if (this.codeHeight.getMaskValue() || (this.codeHeight.getMaskValue() === 0)) {
                                    this.props.setValue(this.codeHeight.getMaskValue(), 'codeHeight');
                                }
                            }}
                            // Ao sair do campo, se ele possui um valor inválido ou estiver vazio, altera a quantidade do produto para zero.
                            // Isso não pode ser feito antes, senão atrapalha a digitação do campo.
                            onBlur={() => {
                                if ((!this.codeHeight.getMaskValue()) && (this.codeHeight.getMaskValue() !== 0)) {
                                    this.props.setValue(0, 'codeHeight');
                                }
                            }}
                        />

                        {/* Altura do texto */}
                        <InputCustomizado
                            id='fontSize'
                            max={100000000}
                            scale={2}
                            inputType='masked'
                            type='text'
                            validacaoDados='numeroDecimal'
                            name='fontSize'
                            required={(this.props.use === EXTERNAL_USE) || this.props.includeRegisterCode}
                            ref={input => this.fontSize = getReduxWrappedComponent(input)}
                            label={getStrings().fontSizeRequired}
                            placeholder={getStrings().fontSizePlaceholder}
                            // Atualiza a quantidade do produto no estado a cada dígito digitado, a menos que o campo contenha um número inválido.
                            onInput={() => {
                                if (this.fontSize.getMaskValue() || (this.fontSize.getMaskValue() === 0)) {
                                    this.props.setValue(this.fontSize.getMaskValue(), 'fontSize');
                                }
                            }}
                            // Ao sair do campo, se ele possui um valor inválido ou estiver vazio, altera a quantidade do produto para zero.
                            // Isso não pode ser feito antes, senão atrapalha a digitação do campo.
                            onBlur={() => {
                                if ((!this.fontSize.getMaskValue()) && (this.fontSize.getMaskValue() !== 0)) {
                                    this.props.setValue(0, 'fontSize');
                                }
                            }}
                        />
                    </div>

                    {/* Incluir código do registro quando for uso interno */}
                    {(this.props.use === INTERNAL_USE)
                        ? <div className='sub-form'>
                            <InputCustomizado
                                ref={input => this.includeRegisterCode = getReduxWrappedComponent(input)}
                                id='includeRegisterCode'
                                type='checkbox'
                                name='includeRegisterCode'
                                label={getStrings().includeRegisterCode}
                                handleInputValidado={() => this.props.setValue(this.includeRegisterCode.inputComponent.checked, 'includeRegisterCode')}
                            />
                        </div>
                        : null
                    }

                </WidthAwareDiv>
            </form>
        </div>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    ...state.cadastroPrintReducer,
    ...state.idiomaReducer,
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    buildCodes: () => dispatch(cadastroPrintActions.buildCodes()),

    cadastroSwitchUI: (state, cadastroDados, keepLoading, formMounted, operation) =>
        dispatch(cadastroActions.cadastroSwitchUI(state, cadastroDados, keepLoading, formMounted, operation)),

    setValue: (value, position) => dispatch(cadastroPrintActions.setValue(value, position))
});

/**
* Exporta o último argumento entre parênteses.
*/
export default connect(mapStateToProps, mapDispatchToProps)(CadastroPrintSetup);
