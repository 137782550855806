import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/getReduxWrappedComponent"

import * as appActions from "../../../store/actions/appAction"

import Icones from "../../../assets/icones/Icones"
import InputCustomizado from "../../UI/Input/InputCustomizado"

/**
 * Classe que monta a dialog para entrada da quantidade de produto pesada em balança.
 */
class DialogPeso extends React.Component {

  /**
   * Método executado quando o campo do número de registros ganha o foco.
   * Seleciona todo o conteúdo do campo.
   */
  handleFocus = () => {
    log("DialogPeso handleFocus")
    this.peso.inputComponent.element.select()
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  componentDidMount() {
    log("DialogPeso componentDidMount")
    this.componentDidUpdate()
  }

  /**
   * Método executado APÓS a atualização do componente.
   */
  componentDidUpdate() {
    log("DialogPeso componentDidUpdate")
    this.peso.setMaskValue(this.props.defaultValue)
    this.peso.inputComponent.element.focus()
    this.handleFocus()
  }

  /**
   * Método que monta o componente.
   */
  render() {
    log("DialogPeso render")

    const GoLaw = Icones.go.GoLaw

    return <>
      <form onSubmit={event =>
        // Impede que o form redirecione para outra URL
        event.preventDefault()}>

        <InputCustomizado
          id="peso"
          inputType="masked"
          label={getStrings().weight}
          max={9.999}
          name="peso"
          onFocus={this.handleFocus}
          placeholder={getStrings().weightPlaceholder}
          ref={input => {
            if (input) {
              this.peso = getReduxWrappedComponent(input)
            }
          }}
          scale={3}
          type="text"
          validacaoDados="numeroDecimal" />

        <div className="dialog-horizontal-button-grid" >
          <button
            type="button"
            className="livre"
            onClick={() => {
              this.props.scalesAction()
              this.props.appDialogHide()
            }}
            style={{ gridColumnStart: "2" }}>
            {<GoLaw />}
          </button>
          {this.props.precoLivre ?
            <button
              type="button"
              className="livre"
              onClick={() => {
                this.props.AYCEAction()
                this.props.appDialogHide()
              }}
              style={{ gridColumnStart: "3" }}>
              {getStrings().allYouCanEatShort}
            </button> : null}
          <button
            type="submit"
            onClick={() => {
              let amount = this.peso.getMaskValue()
              if (amount) {
                this.props.submitAction(amount)
              } else {
                this.props.removeAction(amount)
              }
              this.props.appDialogHide()
            }}
            style={{ gridColumnStart: "4" }}>
            {getStrings().confirm}
          </button>
        </div>
      </form>
    </>
  }
}

DialogPeso.propTypes = {

  /**
   * Valor inicial do campo de peso.
   */
  defaultValue: PropTypes.number,

  /**
   * Ação a ser realizada ao pressionar o botão da balança.
   */
  scalesAction: PropTypes.func,

  /**
   * Ação a ser realizada ao pressionar o botão de confirmar se o peso for válido e diferente de zero.
   */
  submitAction: PropTypes.func,

  /**
   * Ação a ser realizada ao pressionar o botão de livre.
   */
  AYCEAction: PropTypes.func,

  /**
   * Ação a ser realizada ao pressionar o botão de confirmar se o peso for zero ou inválido.
   */
  removeAction: PropTypes.func
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  ...state.idiomaReducer,
  dialogShow: state.appReducer.dialogShow,
  precoLivre: state.empresaSelectorReducer.precoLivre
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  appDialogHide: () => dispatch(appActions.appDialogHide()),
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(DialogPeso)
