import React from "react"
import { connect } from "react-redux"
import PropsType from "prop-types"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"

import WidthAwareDiv from "../WidthAwareDiv/WidthAwareDiv"
import BuildButton from "../../UI/Toolbar/BarraAcoesButton"
import ToolbarPlaceholderTemplate from "./ToolbarPlaceholderTemplate"
import Icones from "../../../assets/icones/Icones"

const mapearBotoesId = {
  F11: "#previous-button"
  , F12: "#advance-button"
}

let botoesFn = (() => {
  let array = []

  for (let x = 1; x < 13; x++) {
    array.push(`F${x}`)
  }

  return array
})()



class ToolbarTemplate extends React.Component {
  static propsType = {
    addItemAction: PropsType.func,
    attractToAdvance: PropsType.bool,
    advanceButtonDisabled: PropsType.bool,
    barContainerStyle: PropsType.any,
    children: PropsType.node,
    goBack: PropsType.func,
    saveHandler: PropsType.func,
    setAllDisabled: PropsType.bool,
    spinnerShow: PropsType.bool,
    style: PropsType.any,
    toolbarClassName: PropsType.string,
  };

  keyDownHandler = (event) => {

    if (botoesFn.includes(event.key)) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (!this.props.spinnerShow) {
      const botao = document.querySelector(mapearBotoesId[event.key]) || { click: () => { } };

      botao.click();
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.keyDownHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyDownHandler);
  }

  render() {
    const MdAdd = Icones.md.MdAdd;
    const MdCheck = Icones.md.MdCheck;

    if (this.props.attractToAdvance) {
      log('attractToAdvance');
    }

    return <div id='toolbar' className={'toolbar ' + (this.props.toolbarClassName ? this.props.toolbarClassName : '')} style={this.props.style}>
      <div className='toolbar-content'>
        <WidthAwareDiv pureStyle={this.props.awareDivStyle}>
          <div className='bar-container' style={this.props.barContainerStyle}>
            {/* Botão Voltar */}
            {this.props.goBack
              ? <BuildButton id='previous-button' buttonGridClassName='bar-item' key='back' isPrimary commandMethod={this.props.goBack} isDisabled={this.props.setAllDisabled} label={'\u003c'} title={getStrings().back} />
              : null
            }
            <div className='bar-item' style={{ flexGrow: 3 }}>
              <ToolbarPlaceholderTemplate children={this.props.children} />
            </div>
            {this.props.addItemAction
              ? <BuildButton id="advance-button" buttonGridClassName='bar-item' key='advance-button' isPrimary commandMethod={this.props.addItemAction} isDisabled={this.props.setAllDisabled || this.props.advanceButtonDisabled} label={<MdAdd />} title={getStrings().save} attractSubmit={this.props.attractToAdvance && !this.props.advanceButtonDisabled && !this.props.setAllDisabled} />
              : this.props.saveHandler
                ? <BuildButton id="advance-button" buttonGridClassName='bar-item' key='advance-button' isPrimary commandMethod={this.props.saveHandler} isDisabled={this.props.setAllDisabled || this.props.advanceButtonDisabled} label={<MdCheck />} title={getStrings().save} attractSubmit={this.props.attractToAdvance && !this.props.advanceButtonDisabled && !this.props.setAllDisabled} />
                : null
            }
          </div>
        </WidthAwareDiv>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  spinnerShow: state.appReducer.spinnerShow(),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarTemplate);
