import styled from "styled-components";

export const Paragraph = styled.p`
  text-align: center;
  font-size: 0.8em;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 40px;
  max-width: 80%;
  font-weight: 500;
  @media (min-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    font-size: 1em;
    line-height: 26px;
  }
`;
