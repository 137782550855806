import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/producaoAction';
import * as appActions from '../../../store/actions/appAction';

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"

import BuildButton from '../../UI/Toolbar/BarraAcoesButton';
import BuildField from '../../UI/Toolbar/BarraAcoesField';
import DialogPageable from '../../cadastros/dialog/DialogPageable';

import { updateSimpleTapSelectionAction } from '../../../store/actions/simpleTapSelectionAction';
import Icones from '../../../assets/icones/Icones';

/**
 * Classe que realiza a montagem da barra de ações para as visualizações de cadastros.
 */
class BarraAcoesProducao extends React.Component {

    /**
     * Método executado ao clicar no botão "<<" (primeira página).
     * Carrega os cadastros correspondentes.
     */
    handleNavFirst = () => {
        log('BarraAcoesProducao handleNavFirst');
        this.props.onNavigate(this.props.links.first.href);
    }

    /**
     * Método executado ao clicar no botão "<" (página anterior).
     * Carrega os cadastros correspondentes.
     */
    handleNavPrev = () => {
        log('BarraAcoesProducao handleNavPrev');
        this.props.onNavigate(this.props.links.prev.href);
    }

    /**
     * Método executado ao clicar no botão ">" (próxima página).
     * Carrega os cadastros correspondentes.
     */
    handleNavNext = () => {
        log('BarraAcoesProducao handleNavNext');
        this.props.onNavigate(this.props.links.next.href);
    }

    /**
     * Método executado ao clicar no botão ">>" (última página).
     * Carrega os cadastros correspondentes.
     */
    handleNavLast = () => {
        log('BarraAcoesProducao handleNavLast');
        this.props.onNavigate(this.props.links.last.href);
    }

    /**
     * Método executado ao clicar sobre o field que exibe a página atual "2/14 (página 2 de 14)".
     * Exibe uma dialog para definir o número de registros que será exibido por página.
     */
    showDialog = () => {
        log('BarraAcoesProducao showDialog');
        this.props.appDialogShow(
            <DialogPageable
                ref={ref => this.dialog = ref}
                updatePageSize={(pageSize) => this.props.loadItensVendaAbertos(null, pageSize)}
                appDialogHide={this.props.appDialogHide}
                numeroRegistros={this.props[this.props.telaAtual].page.size}
                forceNumeroRegistros={true}
            />,
            getStrings().registers
        );
    }

    componentDidUpdate() {}

	/**
	 * Método executado ANTES de "MONTAR" o componente.
	 * Atualiza a lista de produtos se foi trocado entre produzidos e não produzidos.
	 */
    getSnapshotBeforeUpdate(prevProps, prevState) {
        log('BarraAcoesProducao getSnapshotBeforeUpdate', prevProps, prevState);

        if (prevProps.showProduced !== this.props.showProduced) {
            this.props.loadItensVendaAbertos();
        }
        return null;
    }

    /**
     * Método que monta o componente.
     */
    render() {
        log('BarraAcoesProducao render');

        /* Label que exibe a página sendo exibida, ou uma mensagem caso não existam cadastros */
        var pageInfo;
        if (this.props[this.props.telaAtual].page.hasOwnProperty('number')) {
            pageInfo = (this.props[this.props.telaAtual].page.number + 1) + '/' + (this.props[this.props.telaAtual].page.totalPages);

        } else {
            pageInfo = '0/0';
        }


        const AiOutlinePrinter = Icones.ai.AiOutlinePrinter;
        const AiOutlineSearch = Icones.ai.AiOutlineSearch;
        const AiOutlineSelect = Icones.ai.AiOutlineSelect;
        const AiOutlineSync = Icones.ai.AiOutlineSync;
        const FiChevronLeft = Icones.fi.FiChevronLeft;
        const FiChevronRight = Icones.fi.FiChevronRight;
        const FiChevronsLeft = Icones.fi.FiChevronsLeft;
        const FiChevronsRight = Icones.fi.FiChevronsRight;
        const MdEventAvailable = Icones.md.MdEventAvailable;
        const MdEventNote = Icones.md.MdEventNote;
        const MdCheck = Icones.md.MdCheck;
        const MdSort = Icones.md.MdSort;
        const DisplayRouteIcon = Icones.fa.FaMapMarked;

        const iconStyle = {width: 'auto', height: 'auto', maxWidth: '1em', maxHeight: '1em', verticalAlign: 'middle', paddingLeft: '0.3em', paddingRight: '0.3em', paddingBottom: '0.1em'};

        return <div id='toolbar' className='toolbar' >
            <div className='toolbar-content'>
                <div>
                    <div className='bar-container justify-content-center' >
                        {this.props.simpleTapSelection
                            ? <>
                                <BuildButton buttonGridClassName='bar-item' key='check' isPrimary={true} commandMethod={() => this.props.updateSimpleTapSelection(false)} label={<MdCheck />} title={getStrings.check} />
                            </>
                            : <>
                             <div className='bar-navegacao' >
                                {/* Botões de navegação */}
                                <BuildButton id="first-page-button" buttonGridClassName='bar-item' key='first' isPrimary={false} commandMethod={this.handleNavFirst} isDisabled={!(('first' in this.props.links) && ('prev' in this.props.links))} label={<FiChevronsLeft style={iconStyle} />} title={getStrings().firstPage} />
                                <BuildButton id="previous-page-button" buttonGridClassName='bar-item' key='prev' isPrimary={false} commandMethod={this.handleNavPrev} isDisabled={!('prev' in this.props.links)} label={<FiChevronLeft style={iconStyle} />} title={getStrings().previous} />
        
                                {/* Página atual */}
                                <BuildField id='pageInfo' key='pageInfo' commandMethod={this.showDialog} label={pageInfo} />
        
                                {/* Botões de navegação */}
                                <BuildButton id="next-page-button" buttonGridClassName='bar-item' key='next' isPrimary={false} commandMethod={this.handleNavNext} isDisabled={!('next' in this.props.links)} label={<FiChevronRight style={iconStyle} />} title={getStrings().next} />
                                <BuildButton id="last-page-button" buttonGridClassName='bar-item' key='last' isPrimary={false} commandMethod={this.handleNavLast} isDisabled={!(('last' in this.props.links) && ('next' in this.props.links))} label={<FiChevronsRight style={iconStyle} />} title={getStrings().lastPage} />
                            </div>

                            <div className='bar-botoes' >
                                {this.props.modoSelecao
                                    ? <div key='__selecao_check__' className='bar-button-primary-white-selecao' onClick={this.props.selecionarTodos}>
                                        {this.props.estadoSelecionarTodos
                                            ? <MdCheck key='__selecao_check_icon__' style={{ height: '1.8em', width: '1.8em' }} />
                                            : null
                                        }
                                    </div>
                                    : null
                                }
                                <BuildButton
                                    id="botao-selecionar"
                                    buttonGridClassName='bar-item'
                                    cellClassName={`bar-button-primary-white ${this.props.modoSelecao ? 'pressionado' : ''}`}
                                    key='select' isPrimary={true}
                                    isDisabled={!this.props.selecionarMetodo}
                                    commandMethod={this.props.selecionarMetodo}
                                    label={<AiOutlineSelect style={iconStyle} />}
                                    texto={this.props.modoSelecao ? getStrings().cancel : getStrings().select}
                                    title={getStrings().selectCards}
                                />
                                <BuildButton
                                    id="botao-imprimir"
                                    buttonGridClassName='bar-item'
                                    cellClassName='bar-button-primary-white'
                                    key='print'
                                    isPrimary={true}
                                    isDisabled={!this.props.modoSelecao}
                                    commandMethod={this.props.imprimir}
                                    label={<AiOutlinePrinter style={iconStyle} />}
                                    texto={getStrings().print}
                                    title={getStrings().print}
                                />
                                <BuildButton
                                    id="botao-mudar-estado"
                                    buttonGridClassName='bar-item'
                                    cellClassName='bar-button-primary-white'
                                    key='change-state'
                                    isPrimary={true}
                                    isDisabled={!this.props.modoSelecao}
                                    commandMethod={this.props.abrirDialogDaSelecao}
                                    label={<AiOutlineSync style={iconStyle} />}
                                    texto={getStrings().changeState}
                                    title={getStrings().changeState}
                                />
                                <BuildButton
                                    id="botao-ordenar"
                                    buttonGridClassName='bar-item'
                                    cellClassName={`bar-button-primary-white ${this.props.ordenarIsOpen ? 'pressionado' : ''}`}
                                    key='order'
                                    isPrimary={true}
                                    commandMethod={this.props.ordenarMetodo}
                                    label={<MdSort style={iconStyle} />}
                                    texto={this.props.ordenarIsOpen ? getStrings().closeFechar : getStrings().sort}
                                    title={getStrings().sort}
                                />
                                <BuildButton
                                    id="botao-filtrar"
                                    buttonGridClassName='bar-item'
                                    cellClassName={`bar-button-primary-white ${this.props.filtrarIsOpen ? 'pressionado' : ''}`}
                                    key='filter' isPrimary={true}
                                    commandMethod={this.props.filtrarMetodo}
                                    label={<AiOutlineSearch style={iconStyle} />}
                                    texto={this.props.filtrarIsOpen ? getStrings().closeFechar : getStrings().filtrate}
                                    title={getStrings().filtrate}
                                />
                                {this.props.isVendasParaEntregar
                                    ? <BuildButton
                                        id="display-route"
                                        buttonGridClassName='bar-item'
                                        cellClassName='bar-button-primary-white'
                                        key='displayroute' isPrimary={true}
                                        commandMethod={this.props.mostrarRotaCallback}
                                        label={<DisplayRouteIcon style={iconStyle} />}
                                        texto={getStrings().displayRoute}
                                        title={getStrings().displayRoute}
                                    />
                                    : null
                                }
                                {!this.props.somenteEntregador
                                    ? <BuildButton
                                        id="switch-button"
                                        buttonGridClassName='bar-item'
                                        cellClassName='bar-button-primary-white'
                                        key='switch' isPrimary={true}
                                        commandMethod={this.props.switchShowProduced}
                                        label={this.props.showProduced ? <MdEventNote style={iconStyle} /> : <MdEventAvailable style={iconStyle} />}
                                        texto={this.props.showProduced ? getStrings().producing : getStrings().produced}
                                        title={this.props.showProduced ? getStrings().showWaitingProducingItems : getStrings().showProducedItems}
                                    />
                                    : null
                                }
                            </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    ...state.producaoReducer
    , ...state.idiomaReducer
    , simpleTapSelection: state.simpleTapSelectionReducer
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    appDialogHide: () => dispatch(appActions.appDialogHide())
    , appDialogShow: (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles) =>
        dispatch(appActions.appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles))

    , updateSimpleTapSelection: (value) => dispatch(updateSimpleTapSelectionAction(value))
    
    , loadItensVendaAbertos: (link, pageSize) => dispatch(actions.loadItensVendaAbertos(link, pageSize))
    , switchShowProduced: () => dispatch(actions.switchShowProduced())
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(BarraAcoesProducao);
