import React from 'react';

import BuildCellFromOrigem from '../grid/BuildCellFromOrigem.js';
import { connect } from 'react-redux';

/**
 * Classe que monta o conteúdo da dialog para selecionar Origens de Venda livre.
 */
const DialogOrigensLivres = (props) => {
    /* Monta as células a partir da origem */
    const origemVendaGrid = (props.freeOrigemVendaList || []).map(origemVenda =>
        <BuildCellFromOrigem
            key={origemVenda._links.self.href}
            tabIndex={(props.tabIndex + 1) * -1}
            origemVenda={origemVenda}
            isOrigemLivre={true}
        />
    );

    /* Retorna o conteúdo da dialog */
    return (
        <div className='dialog-scroll-pane'>
            <div className="pure-g" >
                {origemVendaGrid}
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    freeOrigemVendaList: state.controleVendaReducer.freeOrigemVendaList,
    tabIndex: state.appReducer.getTabIndex(),
});

export default connect(mapStateToProps, null)(DialogOrigensLivres);