import { global_strings as strings } from "../../utils/LocaleUtils"

import * as actionTypes from "./actionTypes"
import { updateAxiosLocale } from "./appAction"

/**
 * Verifica se há uma língua selecionada no local storage.
 * Se não, carrega a padrão.
 */
export const checkSelectedLanguage = () => {

  return (dispatch, getState) => {

    let locale = localStorage.getItem("locale")
    if (locale in strings) {
      dispatch(updateLocale(locale))
    } else {
      localStorage.setItem("locale", getState().idiomaReducer.locale)
    }
  }
}

/**
 * Dispara troca de estado para atualizar língua selecionada.
 * @param {*} locale
 */
export const updateLocale = (locale) => {
  localStorage.setItem("locale", locale)
  updateAxiosLocale(locale)
  return {
    type: actionTypes.I18N,
    locale: locale
  }
}