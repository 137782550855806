//@ts-check
import "./DialogTemplate.css"

import React from "react"
import { connect } from "react-redux"
import { fecharDialog } from "../../nucleo/redux/dialog/dialogActions"
import { teclaHandler } from "../../../../utils/tecladoUtils/teclaHandler"
import { Icones } from "../../assets/icones/Icones"
import Botao from "../../nucleo/componentes/Botao/Botao"

class DialogTemplate extends React.Component {
  componentDidMount() {
    const {fecharDialog = () => {}} = this.props

    /**
     * 
     * @param {KeyboardEvent} evt 
     */
    function observer(evt) {
      if (evt.key === 'Escape') {
        teclaHandler.unsubscribe(observer)
        fecharDialog()
      }
    }

    teclaHandler.subscribe(observer)
  }

  render() {
    const {fecharDialog = () => {}, onKeyDown = () => {}, titulo, ...sanitizedProps} = this.props

    const IconeLixeira = Icones.md.MdClose;

    const styleHeaderTitle = this.props.fecharHabilitado ? {marginLeft: '40px'} : {marginLeft: '0'};

    return <div
      className={'ui-dialog'}
      onKeyDown={
        /**
         * 
         * @param {any} event 
         */
        (event) => {
        event.stopPropagation();

        if (event.key === 'Enter') {
          const elemento = event.target.tagName === 'INPUT'
            ? (document.querySelectorAll('.ui-submit')[0] || {})
            : event.target;

          elemento.click && elemento.click();
        }

        onKeyDown(event);
      }}
      onClick={() => fecharDialog()}
    >
      <div {...sanitizedProps} className={'dialog-frame ' || (this.props.className || '')} onClick={(event) => event.stopPropagation()}>
        <div>
          <div className='dialog-header'>
            {
              this.props.titulo
              ? <div key='__dialog_header_title__' className='dialog-header-title' style={styleHeaderTitle}>
                {this.props.titulo}
              </div>
              : null
            }
            {this.props.fecharHabilitado
              ? <div key='__dialog_header_close__' className='dialog-header-close'>
                <Botao onClick={() => fecharDialog()}>
                  <IconeLixeira />
                </Botao>
              </div>  
              : null
            }
          </div>
        </div>
        <div>
          <div className='dialog-content'>
            {this.props.children}
          </div>
        </div>
      </div>
    </div>
  }
}

/**
 * 
 * @param {import('../../nucleo/redux/types').webAppReducersTipo} _ 
 * @returns 
 */
const mapStateToProps = (_) => ({});

/**
 * 
 * @param {import('../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  fecharDialog: () => dispatch(fecharDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogTemplate);
