import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from "../../utils/LocaleUtils"
import { updateActiveMenuItem } from "../../utils/ScreenUtils"

import EntregasTableRow from './EntregasTableRow';

// Chaves das colunas
import RelatorioBox, {
    NOME_CLIENTE,
    ITEM_VENDA_PRODUTO,
    ITEM_VENDA_HORA,
    ITEM_PAGAMENTO_VENDA_VALOR_TOTAL,
    TELEFONE,
    ENDERECO,
    VENDA_ID,
    OBSERVACAO
} from './RelatorioBox';

import * as relatorioActions from '../../store/actions/relatorioAction';

/**
 * Monta a tabela de relatório de entregas.
 */
class EntregasBox extends React.Component {

    /**
     * Lista de chaves de coluna na ordenação inicial.
     */
    columnsInitial = [
        NOME_CLIENTE,
        ITEM_VENDA_PRODUTO,
        OBSERVACAO,
        ITEM_VENDA_HORA,
        ITEM_PAGAMENTO_VENDA_VALOR_TOTAL,
        TELEFONE,
        ENDERECO
    ];

    /**
     * Retorna o texto do cabeçalho de cada coluna de acordo com a chave da coluna.
     */
    columnHeader = column => {
        switch (column) {
            case NOME_CLIENTE:
                return getStrings().name;
            case ITEM_VENDA_PRODUTO:
                return getStrings().order(1);
            case OBSERVACAO:
                return getStrings().observation;
            case ITEM_VENDA_HORA:
                return getStrings().timeHourMinute;
            case ITEM_PAGAMENTO_VENDA_VALOR_TOTAL:
                return getStrings().saleValue;
            case TELEFONE:
                return getStrings().telephone;
            case ENDERECO:
                return getStrings().address;
            default:
                return '';
        }
    }

    componentDidMount() {

        updateActiveMenuItem('menuRelatorioEntregas', 'menuRelatorios');

        // Método usado no início da implementação do sistema de relatórios genéricos. Investigar necessidade ao substituir sistema de relatórios.
        // this.props.getRelatorio('entregas');

        // Inicializa as opções da geração de relatório
        this.props.sortData({ dadosList: [] }, { report: 'entregas' }, this.columnsInitial, {});
    }

    cor = 'branco';
    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {

        /**
         * Lista de linhas da tabela.
         */
        let list = null;

        /**
         * Lista de chaves de coluna na ordenação personalidada, se existir, ou inicial, se não
         */
        let columnOrder = this.props.columnOrder || this.columnsInitial;

        // Formata os dados do servidor, se existirem
        if (this.props.dados && ('dadosList' in this.props.dados)) {
            
            this.idAtual = null;
            // Formata cada linha da tabela
            list = [];
            for (let dado of this.props.dados.dadosList) {
                this.id = dado.dadosMap[VENDA_ID];
                    if (this.idAtual === null || this.idAtual === this.id) {
                        this.idAtual = this.id;
                        list.push(<EntregasTableRow dadosMap={dado.dadosMap} cor = {this.cor} />);
                    }
                    else {
                        this.idAtual = this.id;
                        this.cor = this.cor === 'branco' ? 'cinza' : 'branco';
                        list.push(<EntregasTableRow tracejado />);
                        list.push(<EntregasTableRow dadosMap={dado.dadosMap} cor = {this.cor} />);
                    }
            }
        }

        return (
            <RelatorioBox
                header={getStrings().deliveryReport}
                tableHeader={columnOrder.map(column => ({
                    key: column,
                    text: this.columnHeader(column),
                }))}
                list={list}
                enableFilter={{
                    empresa: true,
                    //origemVenda: true,
                    //tipoProduto: true,
                    grupoProduto: true,
                    produto: true,
                    //usuario: true,
                    estadoVenda: true,
                    estadoItemVenda: true,
                    data: true,
                    dataEntrega : true,
                    hora: true
                }}
                helpText={[getStrings().movementReportHelp()]}
            />
        );
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {
    return {
        ...state.relatorioReducer,
        ...state.idiomaReducer
    };
};

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        getRelatorio: relatorio => dispatch(relatorioActions.getRelatorio(relatorio)),
        sortData: (dados, options, columnOrder, rowSortOptions) => dispatch(relatorioActions.sortData(dados, options, columnOrder, rowSortOptions))
    };
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(EntregasBox);