//@ts-check
import React from "react";

import "./OverlayTemplate.css";

class OverlayTemplate extends React.Component {
    render() {
        return <div className="ui-overlay">
            {this.props.children}
        </div>
    }
}

export default OverlayTemplate;
