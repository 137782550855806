import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { formatNumber } from "../../utils/NumberUtils"
import { listToFragment } from "../../utils/ScreenUtils"

// Chaves das colunas
import {
    ITEM_PAGAMENTO_VENDA_DIFERENCA,
    ITEM_PAGAMENTO_VENDA_LIVRE,
    ITEM_PAGAMENTO_VENDA_PORCENTAGEM_DIFERENCA,
    ITEM_PAGAMENTO_VENDA_PORCENTAGEM_MARGEM,
    ITEM_PAGAMENTO_VENDA_PRODUTO,
    ITEM_PAGAMENTO_VENDA_QUANTIDADE,
    ITEM_PAGAMENTO_VENDA_VALOR_CUSTO_TOTAL,
    ITEM_PAGAMENTO_VENDA_VALOR_TOTAL
} from './RelatorioBox';

/**
 * Classe que realiza a montagem das linhas da tabela.
 */
class LucratividadeTableRow extends React.Component {

    /**
     * Monta cada célula de acordo com a chave da coluna.
     */
    cellData = column => {
        log('LucratividadeTableRow cellData', column);
        switch (column) {
            case ITEM_PAGAMENTO_VENDA_PRODUTO:
                return <td className='Lighter' width='1%' >{this.props.dadosMap[ITEM_PAGAMENTO_VENDA_LIVRE] ? getStrings().allYouCanEatName(this.props.dadosMap[ITEM_PAGAMENTO_VENDA_PRODUTO]) : this.props.dadosMap[ITEM_PAGAMENTO_VENDA_PRODUTO]}</td>;
            case ITEM_PAGAMENTO_VENDA_QUANTIDADE:
                return <td className='Lighter NumberAlign' width='1%' >{this.props.dadosMap[ITEM_PAGAMENTO_VENDA_LIVRE] ? getStrings().allYouCanEatShort : formatNumber(this.props.dadosMap[ITEM_PAGAMENTO_VENDA_QUANTIDADE], 2)}</td>;
            case ITEM_PAGAMENTO_VENDA_VALOR_TOTAL:
                return <td className='Lighter NumberAlign' width='1%' >{formatNumber(this.props.dadosMap[ITEM_PAGAMENTO_VENDA_VALOR_TOTAL], 2)}</td>;
            case ITEM_PAGAMENTO_VENDA_VALOR_CUSTO_TOTAL:
                return <td className='Lighter NumberAlign' width='1%' >{formatNumber(this.props.dadosMap[ITEM_PAGAMENTO_VENDA_VALOR_CUSTO_TOTAL], 2)}</td>;
            case ITEM_PAGAMENTO_VENDA_DIFERENCA:
                return <td className='Lighter NumberAlign' width='1%' >{formatNumber(this.props.dadosMap[ITEM_PAGAMENTO_VENDA_DIFERENCA], 2)}</td>;
            case ITEM_PAGAMENTO_VENDA_PORCENTAGEM_DIFERENCA:
                return <td className='Lighter NumberAlign' width='1%' >{formatNumber(this.props.dadosMap[ITEM_PAGAMENTO_VENDA_PORCENTAGEM_DIFERENCA], 2)}</td>;
            case ITEM_PAGAMENTO_VENDA_PORCENTAGEM_MARGEM:
                return <td className='Lighter NumberAlign' width='1%' >{formatNumber(this.props.dadosMap[ITEM_PAGAMENTO_VENDA_PORCENTAGEM_MARGEM], 2)}</td>;
            default:
                return null;
        }
    }

    /**
     * Método que executa a montagem do componente.
     */
    render() {
        log('LucratividadeTableRow render');

        // Se não recebeu as colunas por parâmetro, não pode montar a linha da tabela
        if (!this.props.columnOrder) {
            return null;
        }

        return <tr>
            {/* Dados do relatório */}
            {listToFragment(this.props.columnOrder.map(column => this.cellData(column)))}
        </tr>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    ...state.relatorioReducer
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(LucratividadeTableRow);
