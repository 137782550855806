import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"

import Icones from "../../../assets/icones/Icones"
import { Button } from "../../UI/Button/Button"

/**
 * Classe que realiza a montagem dos botões de opção para todas as tabelas de cadastro.
 */
class OptionButtons_ extends React.Component {
  static propTypes = {
    cadastro: PropTypes.any
    , className: PropTypes.string
    , disabled: PropTypes.bool
    , onDelete: PropTypes.func
    , onUpdate: PropTypes.func
    , tabIndex: PropTypes.number
    , width: PropTypes.number
  }

  /**
   * Método executado ao clicar no botão "Alterar" da linha.
   * Chama a tela de formulário para a alteração deste cadastro.
   * @param {*} e 
   */
  handleUpdate = (e) => {
    e.preventDefault()
    this.props.onUpdate(this.props.cadastro)
  }

  /**
   * Método executado ao clicar no botão "Deletar" da linha.
   * Exibição de notificação de confirmação de exclusão.
   * @param {*} e 
   */
  handleDelete = (e) => {
    e.preventDefault()
    this.props.onDelete(this.props.cadastro)
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    const GoPencil = Icones.go.GoPencil
    const GoTrashcan = Icones.go.GoTrashcan

    const width = (("width" in this.props) ? this.props.width : "1%")

    return (
      <td width={width} >
        <div className="pure-g option-buttons" >
          <BuildButton id="edit-button" disabled={this.props.disabled} onClick={this.handleUpdate} tabIndex={this.props.tabIndex} label={<GoPencil />} title={getStrings().edit} />
          <BuildButton id="remove-button" disabled={this.props.disabled} onClick={this.handleDelete} tabIndex={this.props.tabIndex} label={<GoTrashcan />} title={getStrings().remove} />
        </div>
      </td>
    )
  }
}

/**
 * Classe que monta os botões de opção conforme os parâmetros recebidos.
 */
const BuildButton = (props) => {
  return <>
    <div className="bar-border" >
      <Button
        id={props.id}
        disabled={props.disabled}
        type="button"
        className="gray-button opt-button clickable"
        onClick={props.onClick}
        tabIndex={props.tabIndex}
        title={props.title}
      >
        <label className="font-color">
          {props.label}
        </label>
      </Button>
    </div>
  </>
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {
  return {
    tabIndex: state.appReducer.getTabIndex()
  }
}

/**
 * Exporta o último argumento entre parênteses.
 */
export const OptionButtons = connect(mapStateToProps)(OptionButtons_);
export default OptionButtons;