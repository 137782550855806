import tiposDeAcoes from "./parametrosEmpresaActions";
import criarReducer, { acaoTipo } from "../factory/criarReducer";

export type parametrosEmpresaReducerTipo = {
  idDaEmpresa?: number,
  idOrigemVendaDaEmpresa?: number,
  nomeExibicao?: string,
  valorLinkParametro?: string,
  versaoLogoEmpresa?: string,
  origemVenda?: {
    id: string,
    empresa: {
      id: string,
    },
  },
};

const estadoInicial: parametrosEmpresaReducerTipo = {};

const acoesNomeadas: {[x: string]: (acao: any, estadoAtual?: parametrosEmpresaReducerTipo) => parametrosEmpresaReducerTipo} = {
    [tiposDeAcoes.ATUALIZAR_PARAMETROS_EMPRESA]: (carga: parametrosEmpresaReducerTipo, estadoAtual = estadoInicial) => {
        const novoEstado = {
            ...estadoAtual,
            ...carga,
        };

        return novoEstado;
    },
};

const parametrosEmpresaReducer = (estado: parametrosEmpresaReducerTipo = estadoInicial, acao: acaoTipo) =>
  criarReducer(estado, acao, acoesNomeadas);

export default parametrosEmpresaReducer;
