import React from "react"
import { connect } from "react-redux"

import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"

import { NIVEL_USUARIO_SEM_EMPRESA_VENDA } from "../../../store/reducers/empresaSelectorReducer"

import CombinacoesTela from "./CombinacoesTela"

class BuildCellFromTamanho extends React.Component {

  clickHandler = () => {
    this.props.abreTelaModal(
      <CombinacoesTela
        tabIndex={this.props.tabIndex}
        grupo={this.props.grupo}
        tamanhoSelecionado={this.props.tamanhoSelecionado}
        newSaleItemList={this.props.newSaleItemList}
        fecharTela={this.props.fechaTelaModal}
      />
    )
  }

  render() {
    const menu = this.props.nivelUsuarioSemEmpresa !== NIVEL_USUARIO_SEM_EMPRESA_VENDA

    return <>
      <div className={`accordion-item${isAncientChromeMobile ? " ancient-chrome-mobile" : ""} build-cell-from-tamanho clickable`} onClick={this.clickHandler}>
        <div className={`accordion-item-cell${menu ? " menu" : ""}`}>
          {this.props.tamanhoSelecionado.tamanho.nome}
          <button
            className={"quant-button pure-u-6-24 button dark-button font-color incrementa"}
            style={{ width: "1.5em" }}
            tabIndex={this.props.tabIndex}
            onClick={this.clickHandler}
          >
            +
          </button>
        </div>
      </div>
    </>
  }
}

const mapStateToProps = state => ({
  ...state.idiomaReducer,
  tabIndex: state.appReducer.getTabIndex(),
  nivelUsuarioSemEmpresa: state.controleVendaReducer.nivelUsuarioSemEmpresa,
})

const mapDispatchToProps = dispatch => ({
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(BuildCellFromTamanho)
