import React from 'react';
import { connect } from 'react-redux';

import * as cadastroEmpresaActions from '../../../store/actions/cadastroEmpresaAction';

import {
    STATE_CADASTRO_EMPRESA_CONTRATO,
    STATE_CADASTRO_EMPRESA_EMPRESA,
    STATE_CADASTRO_EMPRESA_USUARIO,
    STATE_CADASTRO_EMPRESA_CONCLUIDO
} from '../../../store/reducers/cadastroEmpresaReducer';
import { getPageNames } from '../../../utils/siteUtils/siteUtils';

import * as breadCrumbs from '../../UI/BreadCrumbs/BreadCrumbs';
import I18n from '../../UI/I18n/I18n';

/**
 * Exibe o header ou o bread crumbs baseado nas variáveis armazenadas no *reducer*.
 */
class SignUpHeader extends React.Component {

    /**
     * Retorna o conteúdo do header baseado nas variáveis armazenadas no *reducer*.
     */
    getHeader = () => {
        switch (window.location.pathname) {
            case getPageNames().naoLogado.SIGNUP:
                return I18n('signUp');
            case getPageNames().naoLogado.SIGNUP_USER:
                return breadCrumbs.signUp_user();
            case getPageNames().logado.CADASTRO_EMPRESA:
            case getPageNames().naoLogado.SIGNUP_COMPANY:
                switch (this.props.state) {
                    case STATE_CADASTRO_EMPRESA_EMPRESA:
                        return breadCrumbs.signUpCompany_contract_company(
                            () => this.props.cadastroAnterior(this.props.history)
                        );
                    case STATE_CADASTRO_EMPRESA_USUARIO:
                        return breadCrumbs.signUpCompany_contract_company_user(
                            () => this.props.changeState(STATE_CADASTRO_EMPRESA_CONTRATO),
                            () => this.props.cadastroAnterior(this.props.history)
                        );
                    case STATE_CADASTRO_EMPRESA_CONCLUIDO:
                        return breadCrumbs.signUpCompany_contract_company_user_confirmation();
                    case STATE_CADASTRO_EMPRESA_CONTRATO:
                    default:
                        return breadCrumbs.signUpCompany_contract();
                }
            default:
                return I18n('signUp');
        }
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {

        return <div className='header breadCrumbs'>
            <h1>{I18n('signUp')}</h1>
            <h2>{this.getHeader()}</h2>
        </div>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    state: state.cadastroEmpresaReducer.state
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    cadastroAnterior: () => dispatch(cadastroEmpresaActions.cadastroEmpresaEUsuarioAnterior()),
    changeState: state => dispatch(cadastroEmpresaActions.cadastroEmpresaEUsuarioChangeState(state))
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(SignUpHeader);
