import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { getStrings } from "../../../utils/LocaleUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"
import { formatI18nString } from "../../../utils/StringUtils"

import BarraAcoesBack from "../../UI/Toolbar/BarraAcoesBack"
import HelpParagraph from "../../UI/HelpParagraph/HelpParagraph"
import SignUpHeader from "./SignUpHeader"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

import "./SignUp.css";
import { getPageNames } from "../../../utils/siteUtils/siteUtils"

/**
 * Home Page externa da aplicação. Apresentada quando o Usuário não estiver autenticado, apresentando as opções.
 */
class SignUp extends React.Component {

  render() {

    return <div className='pure-u-1-1'>
      <SignUpHeader />

      <WidthAwareDiv>

        <div className='tela-start tela-sign-up'>

          <HelpParagraph>
            {getStrings().signUpHelp()[0]}
            {getStrings().signUpHelp()[1]}
            {getStrings().signUpHelp()[2]}
            {formatI18nString(getStrings().signUpHelp()[3])}
          </HelpParagraph>

        </div>
      </WidthAwareDiv>

      <BarraAcoesBack
        history={this.props.history}
        noWrap={true}
        route={getPageNames().naoLogado.HOME}
      />

      <WidthAwareDiv>

        <div className={`tela-start tela-sign-up${isAncientChromeMobile ? ' ancient-chrome-mobile' : ''}`}>

          <p className='Lighter'>{getStrings().signUpQuestion}</p>

          <div className='button-grid'>
            <div className='cell'><Link className='blue-button signup-button' id="user-button" tabIndex={this.props.tabIndex} to='/signUpUser' >{getStrings().user}</Link></div>
            <div className='cell'><Link className='blue-button signup-button' id="company-button" tabIndex={this.props.tabIndex} to='/signUpCompany' >{getStrings().userAndCompany}</Link></div>
          </div>

        </div>
      </WidthAwareDiv>
    </div>;
  }
};

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state
 */
const mapStateToProps = state => {
  return {
    tabIndex: state.appReducer.getTabIndex()
  };
};


/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(SignUp);
