import { log } from "./LogUtils"

/*
 * Checa o browser em que o site está rodando.
 * Pode ou não ser passado o browser alvo como parâmetro. Se passar, retorna true ou false para o browser informado. Senão, retorna a string contendo o nome do browser.
 * Não foi testado nos browsers Safari, e em mobile. Testar antes caso for necessário utilizar especificamente para algum destes browser.
 * @param {*} browser 
 */
export const checkBrowser = browser => {
  log("utils checkBrowser", browser)
  // Opera 8.0+
  if (!browser || browser === "opera") {
    var oprAddons = (typeof window.opr === "object") ? window.opr.addons : false
    if ((!!window.opr && oprAddons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0)
      return browser === "opera" ? true : "opera"
  }

  // Firefox 1.0+
  else if (!browser || browser === "firefox") {
    if (typeof InstallTrigger !== "undefined")
      return browser === "firefox" ? true : "firefox"
  }

  // Safari 3.0+ "[object HTMLElementConstructor]" 
  else if (!browser || browser === "safari") {
    if (/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]" })(!window["safari"]))
      return browser === "safari" ? true : "safari"
  }

  // Internet Explorer 6-11
  else if (!browser || browser === "ie") {
    if (/*@cc_on!@*/false || !!document.documentMode)
      return browser === "ie" ? true : "ie"
  }

  // Edge 20+
  else if (!browser || browser === "edge") {
    if (!!window.StyleMedia)
      return browser === "edge" ? true : "edge"
  }

  // Chrome 1+
  else if (!browser || browser === "chrome") {
    var chromeWebstore = (typeof window.chrome === "object") ? window.chrome.webstore : false
    if (!!window.chrome && !!chromeWebstore)
      return browser === "chrome" ? true : "chrome"
  }
}

/**
 * Retorna valor booleano conforme
 * RegExp passada por parâmetro
 * @param {RegExp} regex
 * 
 * @returns {Boolean} match
 */
export const verifyUserAgent = (regex) => {
  return regex.test(navigator.userAgent)
}

export const isIOS = verifyUserAgent(/iPad|iPhone|iPod/) && !window.MSStream
export const isIE = verifyUserAgent(/MSIE|Trident/)
export const isEdge = verifyUserAgent(/Edge/)
export const isMobile = verifyUserAgent(/Android|webOS|iPhone|iPod/) && window.innerWidth < 768
export const isAncientChromeMobile = isMobile && verifyUserAgent(/chrome\/([0-5][0-9]|6[0-5])/i)

/**
 * Navega até um URL sem recarregar a página.
 * @param {*} history 
 * @param {String} pathName 
 */
export const goTo = (history, pathName) => {
  log("utils goTo", { pathName })
  if (!history) {
    return
  }
  history.push(pathName)
}

/**
 * Navega até o URL anterior sem recarregar a página.
 */
export const goBack = () => {
  log("utils goBack")
  window.history.back()
}
