const UPDATE = 'UPDATE:FB_USER_DATA';
const initialState = JSON.parse(localStorage.getItem('F3')) || {};

const mapActions = {
    [UPDATE]: (payload) => {
        localStorage.setItem('F3', JSON.stringify(payload));

        return payload;
    },
};

const fbUsersDataReducer = (state = initialState, {type, payload}) => {
    if (Object.keys(mapActions).includes(type)) {
        return mapActions[type](payload, state);
    }

    return state;
}

export default fbUsersDataReducer;

export const updateFbUsersDataAction = (payload) => {
    return {
        type: UPDATE,
        payload,
    };
}