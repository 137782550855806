import React from 'react';
import { connect } from 'react-redux';

import SVG from 'svg.js';

import { isIE } from '../../../utils/NavigatorUtils';
import { getStrings } from '../../../utils/LocaleUtils';
import { log } from '../../../utils/LogUtils';

import * as cadastroPrintActions from '../../../store/actions/cadastroPrintAction';

import { FAIL } from '../../../store/reducers/cadastroPrintReducer';

import {
    CODABAR,
    CODE_39,
    CODE_39_VIN,
    CODE_93,
    CODE_128,
    EAN,
    EAN_8,
    EXTERNAL_USE,
    INTERLEAVED_TWO_OF_FIVE,
    TWO_OF_FIVE,
    UPC,
    UPC_E
} from '../../../store/reducers/cadastroPrintReducer';

//let JsBarcode = require('jsbarcode');

/**
 * Classe que monta o código de barras ou QR, opcionalmente com código do cadastro e mensagem para acessar `ampix.mobi`.
 */
class BuildCellFromCadastroPrintCode extends React.Component {

    constructor(props) {
        super(props);
        log('BuildCellFromCadastroPrintCode constructor', props);

        // Monta o id que é usado para gerar o código
        let id = `BuildCellFromCadastroPrintCode${this.props.index}`;

        this.divId = `${id}Div`;
        this.registerCodeId = `${id}RegisterCode`;
        this.printedCodeId = `${id}PrintedCode`;
        this.attract0Id = `${id}Attract0`;
        this.attract1Id = `${id}Attract1`;
        this.drawingId = `${id}Drawing`;

        // Inicializa o estado que controla para esconder o componente caso não seja possível gerar o código
        this.state = { hidden: false };
    }

    /**
     * Converte o nome do formato do código de barras do nosso padrão para o padrão da biblioteca de geração de código de barras.
     */
    convert(format) {
        log('BuildCellFromCadastroPrintCode convert', format);
        switch (format) {
            case TWO_OF_FIVE: return 'std25';
            case INTERLEAVED_TWO_OF_FIVE: return 'int25';
            case CODABAR: return 'codabar';
            case CODE_39:
            case CODE_39_VIN: return 'code39';
            case CODE_93: return 'code93';
            case CODE_128: return 'code128';
            case EAN: return 'ean13';
            case EAN_8: return 'ean8';
            case UPC: return 'upc';
            default: return '';
        }
    }

    /**
     * Converte o nome do formato do código de barras do nosso padrão para o padrão da biblioteca de geração de código de barras.
     */
    convertJsBarcode(format) {
        log('BuildCellFromCadastroPrintCode convertJsBarcode', format);
        switch (format) {
            case INTERLEAVED_TWO_OF_FIVE: return 'ITF';
            case CODABAR: return 'codabar';
            case CODE_39:
            case CODE_39_VIN: return 'CODE39';
            case CODE_128: return 'CODE128';
            case EAN: return 'EAN13';
            case EAN_8: return 'EAN8';
            case UPC: return 'UPC';
            case UPC_E: return 'UPCE';
            default: return '';
        }
    }

    /**
     * Se deve usar uma das bibliotecas para gerar o código.
     */
    useJsBarcode = () => [INTERLEAVED_TWO_OF_FIVE, CODABAR, CODE_39, CODE_39_VIN, CODE_128, EAN, EAN_8, UPC, UPC_E]
        .indexOf(this.props.register.formatoCodigoImpresso) > -1


    svgChildren = (src) => {
        let svg = SVG(src);
        
        if(svg) {
            svg.children = () => {
                if((svg.node || {}).childNodes) {
                    return SVG.utils.map(SVG.utils.filterSVGElements(svg.node.childNodes), function(node) {
                        return SVG.adopt(node);
                    })
                }
                else {
                    return [];
                }
            }
        } else {
            svg = {children:() => []};
        }

        return svg;
    }

    /**
     * Método executado APÓS a montagem/renderização do componente.
     * Termina de montar o código para exibição.
     */
    async componentDidMount() {
        // Como o componente precisa ser montado para que o código seja gerado, é mais fácil esconder ele se algo der errado.
        // O certo seria evitar de montar ele, mas isso resulta em reescrita de código para guardar o SVG no *reducer*.
        // Muito trabalho para pouco ganho. Mas, se for preciso, deve ser feito.
        log('BuildCellFromCadastroPrintCode componentDidMount');
        // Busca o elemento que conterá o código gerado
        let placeholder = document.getElementById(this.printedCodeId);
        // Se for código QR, não precisa de processamento adicional.
        if ((this.props.use === EXTERNAL_USE) || (this.props.register.formatoCodigoImpresso === 'QR')) {
        }
        // Se for código de barras, usa uma biblioteca dependendo do formato.
        else if (this.useJsBarcode()) {
            try {
                const {default: JsBarcode} = await import('jsbarcode');
                // Gera o código
                // TODO texto removido até ser encontrada uma maneira do texto funcionar com svg2pdf.js
                // JsBarcode('#' + this.printedCodeId, this.props.register.codigoImpresso, {
                //     format: this.convertJsBarcode(this.props.register.formatoCodigoImpresso),
                //     height: placeholder.clientHeight - 20 // fontSize
                // });
                JsBarcode('#' + this.printedCodeId, this.props.register.codigoImpresso, {
                    format: this.convertJsBarcode(this.props.register.formatoCodigoImpresso),
                    height: placeholder.clientHeight, fontSize: 0
                });
            } catch (error) {
                log('BuildCellFromCadastroPrintCode componentDidMount', error);
                // Adiciona mensagem de erro para este cadastro.
                this.props.addGeneratedCode(this.props.uri, FAIL);
                // Esconde o código se não for possível exibir ele.
                this.setState({ hidden: true });
                return;
            }
        }
        // Se for código de barras, usa a outra biblioteca dependendo do formato.
        else {
            // Gera o código // TODO achar uma biblioteca que gere Code 25 (Standard/Industrial 2 of 5) e Code 93 para não precisar usar jQuery
            try {
                // TODO texto removido até ser encontrada uma maneira do texto funcionar com svg2pdf.js
                // window.jQuery(`div[id='${this.printedCodeId}']`).barcode(this.props.register.codigoImpresso,
                //     this.convert(this.props.register.formatoCodigoImpresso),
                //     {
                //         barHeight: placeholder.clientHeight - 25, // marginHRI + fontSize
                //         fontSize: 15,
                //         marginHRI: 10,
                //         output: 'svg'
                //     });
                window.jQuery(`div[id='${this.printedCodeId}']`).barcode(this.props.register.codigoImpresso,
                    this.convert(this.props.register.formatoCodigoImpresso),
                    {
                        barHeight: placeholder.clientHeight - 10, // marginHRI
                        fontSize: 0,
                        marginHRI: 10,
                        output: 'svg'
                    });
            } catch (error) {
                log('BuildCellFromCadastroPrintCode componentDidMount', error);
                // Adiciona mensagem de erro para este cadastro.
                this.props.addGeneratedCode(this.props.uri, FAIL);
                // Esconde o código se não for possível exibir ele.
                this.setState({ hidden: true });
                return;
            }
            // Verifica se o código foi gerado (depende da validação do formato do código de barras)
            const innerHTMLForCodeGenVerify = ((((placeholder || {}).innerHTML || '').split('data="')[1] || '').split('"></object>')[0] || '')
            if (innerHTMLForCodeGenVerify.length < 1) {
                // Adiciona mensagem de erro para este cadastro.
                this.props.addGeneratedCode(this.props.uri, FAIL);
                // Esconde o código se não for possível exibir ele.
                this.setState({ hidden: true });
                return;
            }
            // Cria um elemento para parsar o objeto nativo gerado pelo jQuery
            let svg = document.createElement('span');
            // Atribui o conteúdo do objeto nativo a um objeto normal
            const innerHTML = innerHTMLForCodeGenVerify.replace(/&quot;/g, '"');
            svg.innerHTML = decodeURI(innerHTML);
            // Atribui o conteúdo parsado ao elemento que continha o objeto nativo
            placeholder.innerHTML = svg.textContent.split('data:image/svg+xml,')[1];
            // Corrige o estilo do elemento que está sendo alterado por algum motivo
            placeholder.style.overflow = 'hidden';
        }
        // Monta o SVG para ser salvo em arquivo
        const draw = SVG(this.drawingId).size(1, 1); // Para fazer manutenção no layout, alterar aqui para 2000x2000 ou algo assim.
        // Converte dimensões de centímetros para pixels
        // Bordas
        const rectPrintCodePadding = draw.rect(window.getComputedStyle(document.body).getPropertyValue('--cadastro-print-code-padding'), 1);
        const padding = rectPrintCodePadding.rbox().width;
        rectPrintCodePadding.remove();
        // Posição inicial
        const rectStartPosition = draw.rect(1, 1);
        const startPosition = rectStartPosition.rbox();
        rectStartPosition.remove();
        // Tamanho da fonte
        const rectFontSize = draw.rect(this.props.fontSize + 'cm', 1);
        const options = { fontSize: rectFontSize.rbox().width };
        rectFontSize.remove();
        // Tamanho do código impresso
        const codeHeight_ = isIE ? this.props.codeHeight + 1 : this.props.codeHeight;
        const rectCodeHeight = draw.rect(codeHeight_ + 'cm', 1);
        const codeHeight = rectCodeHeight.rbox().width;
        rectCodeHeight.remove();
        // Armazena a posição percorrida
        let y = startPosition.y + padding;

        let metrics;
        // Adiciona o código do cadastro
        let registerCode;
        if (((this.props.use === EXTERNAL_USE) || this.props.includeRegisterCode)) {
            // Fazendo desta maneira, o path é adicionado com o seu baseline no y = 0 do svg.js.
            // Assim, metrics.ascender é o deslocamento em px do baseline até o topo dos caracteres, incluindo o espaçamento configurado na fonte.
            // Apesar de ser para cima, é positivo.
            // E metrics.descender é o deslocamento em px do baseline até o fundo dos caracteres. Apesar de ser para baixo, é negativo.
            // metrics.height é a diferença entre ascender e descender.
            metrics = this.props.textToSVG.getMetrics(this.registerCode.textContent, options);
            registerCode = draw.path(this.props.textToSVG.getD(this.registerCode.textContent, options));
            registerCode.dy(metrics.ascender + padding);
            y += metrics.height;
        }

        // Adiciona o elemento para transcrever o código impresso e o texto que acompanha em SVG puro
        let group = draw.group();

        // ((((this.props.use === EXTERNAL_USE) || (this.props.register.formatoCodigoImpresso === 'QR')) || (!this.useJsBarcode()))
        //     // Busca o código impresso gerado por QRCode ou jQuery
        //     ? this.svgChildren(document.querySelector(`#${this.printedCodeId} > svg`))
        //     // Busca o código impresso gerado por JsBarcode
        //     : this.svgChildren(this.printedCode))
        //     // Busca os elementos contidos
        //     .children()
        //     // Filtra só os desejados
        //     .filter(child => ['g', 'path', 'rect'].indexOf(child.type) > -1)
        //     // Adiciona na lista
        //     .forEach(child => group.add(child));

        // Diferencia o tratamento dependendo de como o código impresso foi gerado
        const isQrCodeOrjQuery = this.props.use === EXTERNAL_USE
            || this.props.register.formatoCodigoImpresso === 'QR'
            || !this.useJsBarcode();

        const svgChildren = isQrCodeOrjQuery
            // Busca o código impresso gerado por QRCode ou jQuery
            ? this.svgChildren(document.querySelector(`#${this.printedCodeId} > svg`))
            // Busca o código impresso gerado por JsBarcode
            : this.svgChildren(this.printedCode);

        svgChildren
            // Busca os elementos contidos
            .children()
            // Filtra só os desejados
            .filter(child => ['g', 'path', 'rect'].indexOf(child.type) > -1)
            // Adiciona na lista
            .forEach(child => group.add(child));

        // Calcula escala para determinar tamanho correto.
        const codeScale_ = (codeHeight / group.rbox().height).toFixed(8);
        const codeScale = codeScale_ === 'Infinity'
            ? 1
            : codeScale_;

        if (codeScale !== 0) {
            // Altera escala dos elementos
            group.scale(codeScale);
            // Ajusta posição y dos elementos
            group.dy((y - group.rbox().y) / codeScale);
        } else {
            draw.remove();
        }

        y = group.rbox().y2;

        // Adiciona o attract
        let attract0;
        let attract1;
        if (this.props.use === EXTERNAL_USE) {
            // Armazena as linhas separadas do attract
            let attract = getStrings().printedCodeAttract.split('\n');
            // Adiciona a primeira linha
            metrics = this.props.textToSVG.getMetrics(attract[0], options);
            attract0 = draw.path(this.props.textToSVG.getD(attract[0], options));
            attract0.dy(y - startPosition.y + metrics.ascender);
            y = attract0.rbox().y2;
            // Adiciona a segunda linha
            metrics = this.props.textToSVG.getMetrics(attract[1], options);
            attract1 = draw.path(this.props.textToSVG.getD(attract[1], options));
            attract1.dy(y - startPosition.y + metrics.ascender);
            y += metrics.height;
        }

        // Ajusta a altura de tudo
        y += padding;
        let height = y - startPosition.y;
        // TODO deu um erro aqui uma vez, mas eu não consegui reproduzir.
        // Reclamou de valor negativo. O segundo devia estar maior que o primeiro, o que não deve ocorrer.
        log('BuildCellFromCadastroPrintCode componentDidMount', { y, 'startPosition.y': startPosition.y })
        draw.height(height < 0 ? y : height);

        // Calcula a largura de tudo
        let width = Math.max(...[
            registerCode ? registerCode.rbox().width : 0,
            group.rbox().width,
            attract0 ? attract0.rbox().width : 0,
            attract1 ? attract1.rbox().width : 0,
        ]) + (2 * padding);
        
        // Ajusta a largura de tudo
        draw.width(width);

        // Centraliza tudo
        [{ element: registerCode, scale: 1 },
        { element: group, scale: codeScale },
        { element: attract0, scale: 1 },
        { element: attract1, scale: 1 }]
            .filter(wrapper => wrapper.element)
            .forEach(wrapper => wrapper.element.dx((draw.rbox().cx - wrapper.element.rbox().cx) / wrapper.scale));

        // Adiciona fundo
        draw.rect(width, draw.height()).fill('#fff').back(); // Para fazer manutenção no layout, comentar esta linha.
        // Adiciona o código gerado no reducer para fácil acesso ao imprimir
        this.props.addGeneratedCode(this.props.uri, draw.svg());
        // Esconde código impresso original
        this.setState({ hidden: true }); // Para fazer manutenção no layout, comentar esta linha.
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('BuildCellFromCadastroPrintCode render');

        return <>
            {/* Construção da etiqueta a partir de divs que serve de base para o SVG */}
            {this.state.hidden ? null : <div className='cadastroCodeDiv' id={this.divId} ref={ref => { if (ref) { this.div = ref } }}>
                {/* Código do cadastro */}
                {((this.props.use === EXTERNAL_USE) || this.props.includeRegisterCode)
                    ? <div className='cadastroCodeFont' id={this.registerCodeId} ref={ref => { if (ref) {
                        if (!this.registerCode && ref) {
                            this.registerCode = ref; this.registerCode.textContent = `${this.props.nome}`;
                        }
                    } }} >{getStrings().printedCodeRegisterNameTemplate(this.props.registerType)(this.props.register.codigo)}</div>
                    : null}
                {/* Código impresso do cadastro */}
                {((this.props.use === EXTERNAL_USE) || (this.props.register.formatoCodigoImpresso === 'QR'))
                    ? <div className='cadastroCodeImage' dangerouslySetInnerHTML={{ __html: this.props.register.svg }} id={this.printedCodeId} />
                    : (this.useJsBarcode())
                        ? <svg className='cadastroCodeImage' id={this.printedCodeId} ref={ref => { if (ref) { this.printedCode = ref } }} />
                        : <div className='cadastroCodeImage' id={this.printedCodeId} >{this.props.register.codigo}</div>}
                {/* Mensagem para visitar ampix.mobi */}
                {(this.props.use === EXTERNAL_USE) ? <div className='cadastroCodeFont' id={this.attract0Id} ref={ref => { if (ref) { this.attract0 = ref } }}>{getStrings().printedCodeAttract.split('\n')[0]}</div> : null}
                {(this.props.use === EXTERNAL_USE) ? <div className='cadastroCodeFont' id={this.attract1Id} ref={ref => { if (ref) { this.attract1 = ref } }}>{getStrings().printedCodeAttract.split('\n')[1]}</div> : null}
            </div>}
            {/* Elemento onde será construido o SVG */}
            <div
                id={this.drawingId}
                style={{ position: this.state.hidden ? 'relative' : 'absolute' }} // Para fazer manutenção no layout, comentar esta linha.
            />
        </>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state
 */
const mapStateToProps = state => ({

    ...state.cadastroPrintReducer,
    ...state.cadastroPrintTextToSVGReducer,
    ...state.idiomaReducer,
});

/**
 * Mapeia as ações.
 * @param {*} dispatch
 */
const mapDispatchToProps = dispatch => ({

    addGeneratedCode: (uri, svg) => dispatch(cadastroPrintActions.addGeneratedCode(uri, svg)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuildCellFromCadastroPrintCode);

/*
                      barcode  bardcode  io-barcode  jQuery  lindell/JsBarcode  jsbarcode2  red-agate-barcode  jshor/symbology (doesn't install or build)
twoOfFive                                            x                                                         x
interleavedTwoOfFive  x        x         x           x       x                  x           x                  x
codabar               x        x                     x       x                  x                              x
code39                x        x         x           x       x                  x           x                  x
code39Vin             x        x         x           x       x                  x           x                  x
code93                                               x                                                         x
code128               x        x         x           x       x                  x           x                  x
ean                   x        x         x           x       x                  x           x                  x
ean8                           x                     x       x                  x           x                  x
upc(a)                x        x         x           x       x                  x           x                  x
upcE                  x                                      x                  x           x                  x

if not SVG, bwipjs or barcode-bakery-1d
*/
