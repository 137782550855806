import React from 'react';

import { log } from '../../../utils/LogUtils';
import { getURIFromEntity } from '../../../utils/URIUtils';

import OptionButtons from '../optionButtons/OptionButtons';
import BotaoChecado from '../../UI/botoes/BotaoChecado/BotaoChecado';

/**
 * Classe que realiza a montagem das linhas da tabela.
 */
export default class ObjetoTableRow extends React.Component {

    /**
     * Método que executa a montagem do componente.
     */
    render() {
        log('ObjetoTableRow render');

        let id = `${getURIFromEntity(this.props.cadastro)}/Checkbox`;

        return <tr>

            {/* Opções de manutenção */}
            <OptionButtons onUpdate={this.props.onUpdate} onDelete={this.props.onDelete} cadastro={this.props.cadastro} />

            {/* Dados do cadastro */}
            <td className='Lighter ButtonAlign' width='5%' >
                <BotaoChecado key={`__${id}__`} desativado={!this.props.cadastro.enabled} />
            </td>
            <td className='Lighter TextAlign' width='20%' >{this.props.cadastro.codigo}</td>
            <td className='Lighter TextAlign' width='75%' >{this.props.cadastro.nome}</td>
        </tr>;
    }
}
