import store from '../../store/store'

import { dispatchTipo } from '../../store/actions/acaoTemplate'

export type reduxDispatchType = Record<string,any> | ((dispatch: dispatchTipo, getState?: () => any) => (void | undefined | Record<string,any>))

export function reduxDispatch(action: reduxDispatchType) {
  if (typeof action === 'function') {
    const plainAction = action(store.dispatch, store.getState)

    if (plainAction) {
      store.dispatch(plainAction)
    }
  }
  else
    store.dispatch(action as any)
}