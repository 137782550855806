import { push } from "connected-react-router"

import {
  getAppCargo
  , getAppPermissoesCargo
  , getAppToken
  , getAppTokenExpiration
  , getAppUsuario
} from "../../utils/AppUtils"
import { authErrorHandler } from "../../utils/ErrorUtils"
import { getHeaders } from "../../utils/HeadersUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { getApi, urlDatabase } from "../../utils/SecureConnectionUtils"
import { getSaleSourceNoCompany } from "../../utils/StorageUtils/LocalStorageUtils"
import { setAuthStorage, removeAuthStorage, removeCargoStorage } from "../../utils/StorageUtils/SessionStorageUtils"

import * as actionTypes from "./actionTypes"
import { axios, appSnackbarMessage, appDialogHide, appNotificationShow, appSpinnerShow, appSpinnerHide } from "./appAction"
import * as cadastroEmpresaActions from "./cadastroEmpresaAction"
import * as controleVendaActions from "./controleVenda/controleVendaAction"
import * as empresaSelectorActions from "./empresaSelectorAction"
import * as usuarioActions from "./usuarioAction"
import * as producaoActions from "./producaoAction"
import { getPageNames } from "../../utils/siteUtils/siteUtils"

export const authenticationWithF3 = inputToken => dispatch => {
  log("authAction authenticationWithF3", { inputToken })

  dispatch(appSpinnerShow("authenticationF3Submit"))

  axios().post(getApi("loginF3"), { usuario: { login: {} }, facebookToken: inputToken, })
    .then(response =>
      dispatch(authSuccess(response))
    )
    .catch(error =>
      dispatch(authFail(error))
    )
    .finally(() => {
      dispatch(appDialogHide())
      dispatch(appSpinnerHide("authenticationF3Submit"))
    })
}

/**
 * Verifica se o usuário está criado mas ainda não verificado
 * para reenviar o email de confirmação.
 * @param {Object} login 
 * @param {Object} error 
 */
export const getUsuarioEstaAtivo = (login, error) => dispatch => {
  log("authAction getUsuarioEstaAtivo", { email: login.email, error })

  dispatch(appSpinnerShow("resentEmailValidation"))

  axios().post(getApi("resentEmailValidation"), null, { params: { email: login.email } })
    .then(response => {
      if ((response || {}).data) {
        dispatch(signUpSuccess(response, getStrings().resentEmailValidation))
        dispatch(ativarInputDoCodigoDeConfirmacao())
      } else {
        dispatch(authFail(error))
      }
    })
    .catch(error => {
      // Se erro foi por não existir usuário com este email, redireciona para a tela de cadastro.
      if (((error || {}).response || {}).data === "userNotFound") {
        dispatch(push("/signUpUser"))
        // Salva o email e a senha para já vir preenchido na tela de cadastro.
        // Por segurança, estas variavéis devem ser limpas assim que usadas,
        // o que deve acontecer em menos de um segundo a partir da próxima chamada.
        dispatch({
          type: actionTypes.AUTH_UPDATE,
          temp: {
            email: login.email,
            token: login.senha
          }
        })
        // Para exibir a mensagem correta
        error.response.data = "notRegisteredYet"
      }
      dispatch(authFail(error))
    })
    .finally(() =>
      dispatch(appSpinnerHide("resentEmailValidation"))
    )
}

/**
 * Ação de Login. 
 * @param {*} login JSON com os dados de Login do Usuario
 */
export const authenticationSubmit = login => dispatch => {
  log("authAction authenticationSubmit", { login })

  dispatch(appSpinnerShow("authenticationSubmit"))

  dispatch(authStart())

  axios().post(
    `${urlDatabase}/login`,
    login
  )
    .then(response =>
      dispatch(authSuccess(response))
    )
    .catch(error => {
      // Se for erro de senha inválida, só informa o usuário.
      if ((((error || {}).response || {}).data || "") === "invalidPassword") {
        dispatch(authFail(error))
      }
      // Se não, uma causa possível é haver um usuário não verificado.
      else {
        // Reenvia o email de verificação.
        dispatch(getUsuarioEstaAtivo(login, error))
      }
    })
    .finally(() =>
      dispatch(appSpinnerHide("authenticationSubmit"))
    )
}

export const authStart = () => {
  log("authAction authStart")

  return {
    type: actionTypes.AUTH_START
  }
}

export const authSuccess = response => dispatch => {
  log("authAction authSuccess", { response })

  // Busca os dados retornados no Header da autenticação
  const token = response.headers.authorization
  const expiration = response.headers.expiration
  const usuario = response.headers.usuario
  const nomeUsuario = response.headers.nomeusuario
  const sobrenomeUsuario = response.headers.sobrenomeusuario
  const cargoPadrao = response.headers.cp
  const quantCargos = ((!response.headers.qc) && (response.headers.qc !== 0)) ? null : (response.headers.qc * 1)
  const tamanhoPadraoPaginacao = response.headers.tamanho_padrao_paginacao

  let nome = { nome: nomeUsuario, sobrenome: sobrenomeUsuario }

  const storage = {
    t: token,
    e: expiration,
    u: usuario,
    cp: cargoPadrao,
    nomeUsuario: nome,
    tamanhoPadraoPaginacao
  }

  // Armazena os dados no storage "a" (auth)
  setAuthStorage(storage)

  // Verifica se o Usuário possui cargos vinculados para redirecionar para o Seletor de Empresa
  // Se usuário logou com venda feita antes de ter selecionado empresa,
  // trata como se não tivesse vínculo com empresa.
  let authRedirectPath = getPageNames().cargoSelecionado.HOME
  if (quantCargos && (quantCargos > 0) && (!getSaleSourceNoCompany())) {
    authRedirectPath = getPageNames().cargoSelecionado.SELECAO_EMPRESA
    // Verifica se o Usuário possui Cargo Padrão e adiciona à querystring
    if (cargoPadrao) {
      authRedirectPath += "?cp=" + cargoPadrao
    }
  }

  sessionStorage.setItem("showWelcomeMessage", true)

  dispatch({
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    usuario: usuario,
    cargoPadrao: cargoPadrao,
    authRedirectPath: authRedirectPath,
  })

  dispatch(empresaSelectorActions.updateQuantidadeCargos(quantCargos))
  dispatch(usuarioActions.updateNomeUsuario(nome))
  dispatch(usuarioActions.updateTamanhoPadraoPaginacao(tamanhoPadraoPaginacao))
}

const authFail = error => dispatch => {
  log("authAction authFail", { error })

  dispatch(authErrorHandler(error))

  dispatch({
    type: actionTypes.AUTH_FAIL
  })
}

/**
 * Ação de Logout. Remove os dados da aplicação do Local Storage. 
 * @param {boolean} hideNotification Enviar TRUE para ocultar a notificação de logout
 */
export const logoutStart = (hideNotification = false) => dispatch => {
  log("authAction logoutStart", { hideNotification })

  dispatch(logout())
  dispatch(controleVendaActions.clearUnread())
  dispatch(producaoActions.clearUnread())
  dispatch(empresaSelectorActions.updatePermissoesCargo([]))
  dispatch(empresaSelectorActions.updateQuantidadeCargos(null))
  dispatch(empresaSelectorActions.updateNomeEmpresa(""))
  dispatch(empresaSelectorActions.updatePerguntaCpf(false))
  dispatch(empresaSelectorActions.updatePrecoLivre(0))
  dispatch(usuarioActions.updateNomeUsuario({ nome: "", sobrenome: "" }))

  if (!hideNotification) {
    dispatch(appSnackbarMessage(getStrings().byeUser))
  }
}

/**
 * Método que efetua o Logout propriamente, removendo os dados do Storage. 
 */
const logout = () => {
  log("authAction logout")

  // Limpa o SessionStorage
  removeAuthStorage()
  removeCargoStorage()

  return {
    type: actionTypes.AUTH_LOGOUT
  }
}


/**
 * Ação de verificação da Autenticação do Usuário. Verifica se ele ainda está apto a permanecer logado (sessão). 
 */
export const authCheckState = () => dispatch => {
  log("authAction authCheckState")

  const token = getAppToken()

  if (!token) {
    dispatch(logoutStart(true))
  }
  else {
    const expiration = new Date(getAppTokenExpiration())

    if (expiration <= new Date()) {
      dispatch(logoutStart(true))
    }
    else {
      const usuario = getAppUsuario()

      dispatch(authCheckStateSuccess(token, usuario))
    }
  }
}

const authCheckStateSuccess = (token, usuario) => dispatch => {
  log("authAction authCheckStateSuccess", { token, usuario })

  const cargo = getAppCargo()
  const permissoesCargo = getAppPermissoesCargo()

  dispatch({
    type: actionTypes.AUTH_CHECK_STATE_SUCCESS,
    idToken: token,
    usuario: usuario,
    cargo: cargo,
    permissoesCargo: permissoesCargo
  })
  // Estando o cargo no reducer, verifica se há itens de venda produzidos e origens de venda chamadas não visualizadas.
  dispatch(producaoActions.getUnreadItemVendaList())
  dispatch(controleVendaActions.getUnreadOrigemVendaList())
}

/**
 * Ação de Cadastro/Inscrição de novo Usuario. 
 * @param {*} usuario JSON com os dados do Usuario
 */
export const signUpSubmit = usuario => (dispatch, getState) => {
  log("authAction signUpSubmit", { usuario })

  dispatch(signUpStart())

  axios().post(
    urlDatabase + "/auth/signUp",
    usuario,
  )
    .then(response => {
      dispatch(signUpSuccess(response))
    })
    .catch(error => {
      dispatch(signUpFail(error))
    })
    .finally(() => {
      dispatch(cadastroEmpresaActions.cadastroEmpresaUsuarioNovo(getState().cadastroEmpresaReducer.usuario))
    })
}

const signUpStart = () => {
  log("authAction signUpStart")

  return {
    type: actionTypes.SIGN_UP_START
  }
}

const signUpSuccess = (response, customTitle) => dispatch => {
  log("authAction signUpSuccess", { response, customTitle })

  dispatch(appNotificationShow(getStrings().confirmationEmailSent, actionTypes.APP_NOTIFICATION_TYPE_SUCCESS, customTitle))

  dispatch({
    type: actionTypes.SIGN_UP_SUCCESS
  })
}

const signUpFail = error => dispatch => {
  log("authAction signUpFail", { error })

  dispatch(authErrorHandler(error))

  dispatch({
    type: actionTypes.SIGN_UP_FAIL,
    error: error
  })
}


/**
 * Ação de Confirmação de email de novo Usuario. 
 * @param {*} token Token de confirmação
 */
export const emailConfirmationSubmit = token => dispatch => {
  log("authAction emailConfirmationSubmit", { token })

  dispatch(appSpinnerShow("emailConfirmationSubmit"))

  dispatch(emailConfirmationStart())

  axios().post(
    urlDatabase + "/auth/registrationConfirm",
    { token: token }
  )
    .then(response =>
      dispatch(emailConfirmationSuccess(response))
    )
    .catch(error =>
      dispatch(emailConfirmationFail(error))
    )
    .finally(() =>
      dispatch(appSpinnerHide("emailConfirmationSubmit"))
    )
}

export const emailConfirmationSubmitByCode = (login, success) => dispatch => {
  log("authAction emailConfirmationSubmitByCode", { login })

  dispatch(appSpinnerShow("emailConfirmationSubmit"))

  dispatch(emailConfirmationStart())

  axios().post(
    urlDatabase + "/auth/registrationConfirmByCode",
    { token: login.token, usuario: { login: { email: login.email } } }
  )
    .then(response => {
      dispatch(authenticationSubmit({
        email: response.data.login.email,
        senha: login.senha
      }))

      success && success()
    })
    .catch(error => {
      dispatch(emailConfirmationFail(error))
    })
    .finally(() =>
      dispatch(appSpinnerHide("emailConfirmationSubmit"))
    )
}

const emailConfirmationStart = () => {
  log("authAction emailConfirmationStart")

  return {
    type: actionTypes.EMAIL_CONFIRMATION_START
  }
}

const emailConfirmationSuccess = response => dispatch => {
  log("authAction emailConfirmationSuccess", { response })

  dispatch(appNotificationShow(getStrings().emailConfirmed, actionTypes.APP_NOTIFICATION_TYPE_SUCCESS))

  dispatch({
    type: actionTypes.EMAIL_CONFIRMATION_SUCCESS,
    email: (((response || {}).data || {}).login || {}).email
  })
}

const emailConfirmationFail = error => dispatch => {
  log("authAction emailConfirmationFail", { error })

  dispatch(authErrorHandler(error))

  dispatch({
    type: actionTypes.EMAIL_CONFIRMATION_FAIL,
    error: error
  })
}


/**
 * Método que valida o campo de Confirmação de Senha. 
 */
export const validaConfirmacaoSenhaHandler = (senha, confirmacaoSenha) => dispatch => {
  log("authAction validaConfirmacaoSenhaHandler", { senha, confirmacaoSenha })

  if (senha.inputComponent.value !== confirmacaoSenha.inputComponent.value) {
    confirmacaoSenha.inputComponent.setCustomValidity(getStrings().passwordConfirmationDiffers)
  }
  else {
    confirmacaoSenha.inputComponent.setCustomValidity("")
  }
}

/**
 * Atualiza o valor da variável que controla o redirecionamento ao usuário fazer login. Ele pode ser redirecionado para a tela
 * de seleção de empresa, que pode redirecioná-lo para a tela inicial com um cargo selecionado se usuário possuir cargo padrão.
 * @param {*} redirectPath 
 */
export const setRedirectPath = redirectPath => dispatch => {
  log("authAction setRedirectPath", { redirectPath })

  dispatch({
    type: actionTypes.AUTH_SUCCESS,
    authRedirectPath: redirectPath
  })
}

/**
 * Busca o token do Hub no VPS e armazena no reducer.
 */
export const getHubToken = () => dispatch => {
  log("authAction getHubToken")

  dispatch(appSpinnerShow("getHubToken"))

  dispatch(authStart())

  axios().get(
    urlDatabase + "/empresas/getHubToken",
    getHeaders())
    .then(response =>
      dispatch(setHubToken(response.data))
    )
    .catch(error =>
      log("authAction getHubToken", "error", { error })
    )
    .finally(() =>
      dispatch(appSpinnerHide("getHubToken"))
    )
}

/**
 * Gera um novo token do Hub no VPS e armazena no reducer.
 */
export const generateHubToken = () => dispatch => {
  log("authAction generateHubToken")

  dispatch(appSpinnerShow("getHubToken"))

  dispatch(authStart())

  axios().post(
    urlDatabase + "/empresas/newHubToken",
    null,
    getHeaders())
    .then(response =>
      dispatch(setHubToken(response.data))
    )
    .catch(error =>
      log("authAction generateHubToken", "error", { error })
    )
    .finally(() =>
      dispatch(appSpinnerHide("getHubToken"))
    )
}

/**
 * Modifica o atual token do Hub do reducer.
 * @param {*} token 
 */
export const setHubToken = token => dispatch => {
  log("authAction setHubToken", { token })

  dispatch({
    type: actionTypes.AUTH_SET_HUB_TOKEN,
    hubToken: token
  })
}

/**
 * Define no *reducer* o valor informado na variável informada.
 * @param value novo valor da variável
 * @param position nome da variável
 */
export const setValue = (value, position) => dispatch => {
  log("authAction setValue", { value, position })

  dispatch({
    type: actionTypes.AUTH_UPDATE,
    [position]: value
  })
}

export const ativarInputDoCodigoDeConfirmacao = () => function (dispatch) {
  dispatch({ type: actionTypes.ATIVAR_INPUT_DO_CODIGO_DE_CONFIRMACAO })
}

export const desativarInputDoCodigoDeConfirmacao = () => function (dispatch) {
  dispatch({ type: actionTypes.DESATIVAR_INPUT_DO_CODIGO_DE_CONFIRMACAO })
}
