import { dispatchTipo } from "../../../../../../store/actions/acaoTemplate";
import { carregarProdutos } from "../../carregamentos/carregamentoActions";
import criarAction from "../../factory/criarAction";
import { parametrosEmpresaReducerTipo } from "../../parametrosEmpresa/parametrosEmpresaReducer";
import { produtoTipo } from "../produtos/produtosReducer";
import { grupoProdutosReducerTipoSemGrupoCarregado, grupoProdutoTamanhoListTipo } from './grupoProdutosReducer';

const tiposDeAcoes = {
  ATUALIZAR_CAMPO_BUSCA_EM_GRUPO: 'ATUALIZAR_CAMPO_BUSCA_EM_GRUPO:GRUPO_PRODUTOS',
  ADICIONAR_GRUPOS: 'ADICIONAR_GRUPOS:GRUPO_PRODUTOS',
  ADICIONAR_PRODUTO_A_GRUPO: 'ADICIONAR_PRODUTO_A_GRUPO:GRUPO_PRODUTOS',
  DEFINIR_GRUPO_PRODUTO_ID: 'DEFINIR_GRUPO_PRODUTO_ID:GRUPO_PRODUTOS',
  LIMPAR_GRUPO_PRODUTOS: 'LIMPAR_GRUPO_PRODUTOS:GRUPO_PRODUTOS',
  MODIFICAR_GRUPO_CARREGADO: 'MODIFICAR_GRUPO_CARREGADO:GRUPO_PRODUTOS',
  REMOVER_GRUPO_PRODUTO_ID: 'REMOVER_GRUPO_PRODUTO_ID:GRUPO_PRODUTOS',
  REMOVER_GRUPO_PRODUTO_ID_DEFINIDO: 'REMOVER_GRUPO_PRODUTO_ID_DEFINIDO:GRUPO_PRODUTOS',
};

export default tiposDeAcoes;

export const adicionarProdutoAGrupoAction = (codigo: number, nome: string, produto: produtoTipo, grupoProdutoTamanhoList: grupoProdutoTamanhoListTipo, precoProdutoCombinado?: string) => function(dispatch: (carga: any) => any) {
  dispatch(criarAction({codigo, nome, produto, grupoProdutoTamanhoList, precoProdutoCombinado: precoProdutoCombinado === undefined
    ? 'media'
    : precoProdutoCombinado}, tiposDeAcoes.ADICIONAR_PRODUTO_A_GRUPO));
};

export const atualizarCampoBuscaEmGrupoAction = (carga: {busca: string}) => (dispatch: dispatchTipo) => {
  dispatch(criarAction(carga, tiposDeAcoes.ATUALIZAR_CAMPO_BUSCA_EM_GRUPO));
}

export const adicionarGruposAction = ({parametrosEmpresa, ...carga}: {grupos: grupoProdutosReducerTipoSemGrupoCarregado, parametrosEmpresa: parametrosEmpresaReducerTipo}) => function(dispatch: dispatchTipo) {
  dispatch(criarAction(carga, tiposDeAcoes.ADICIONAR_GRUPOS));

  window.setTimeout(() => {
    dispatch(modificarGrupoCarregado({grupoCarregado: false}));
    carregarProdutos(parametrosEmpresa, dispatch);
  }, 300000);
}

export const definirGrupoProdutoId = (carga: {codigo: number}) => (dispatch: dispatchTipo) => {
  dispatch(criarAction(carga, tiposDeAcoes.DEFINIR_GRUPO_PRODUTO_ID));
}

export const limparGrupoProdutosAction = () => function(dispatch: (carga: any) => any) {
  dispatch(criarAction(undefined, tiposDeAcoes.LIMPAR_GRUPO_PRODUTOS));
};

export function modificarGrupoCarregado(carga: {grupoCarregado: boolean}) { 
  return function(dispatch: dispatchTipo) {
    dispatch(criarAction(carga, tiposDeAcoes.MODIFICAR_GRUPO_CARREGADO));
  }
};

export const removerGrupoProdutoId = (carga: {codigo: number}) => function(dispatch: (carga: any) => any) {
  dispatch(criarAction(carga, tiposDeAcoes.REMOVER_GRUPO_PRODUTO_ID));
};

export const removerGrupoProdutoIdDefinido = () => function(dispatch: (carga: any) => any) {
  dispatch(criarAction(undefined, tiposDeAcoes.REMOVER_GRUPO_PRODUTO_ID_DEFINIDO));
};