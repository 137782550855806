import { formasPagamentoReducerTipo } from "./formasPagamentoReducer";
import criarAction from "../../factory/criarAction";

const actionTypes = {
  POPULAR_FORMAS_PAGAMENTO: 'POPULAR_FORMAS_PAGAMENTO',
};

export default actionTypes;

export const polularFormasPagamento = (carga: formasPagamentoReducerTipo) => function(dispatch: (carga: any) => any) {
  dispatch(criarAction(carga, actionTypes.POPULAR_FORMAS_PAGAMENTO));
}
