import React from 'react';

import criarAction from '../../factory/criarAction';
import { webAppReducersTipo } from '../../types';
import { mostrarDialog } from '../../dialog/dialogActions';
import { ClienteHttp, LocalidadeUtils, NavegacaoUtils } from '../../../utils/utils';
import { getErrorMessageObjectFromError } from '../../../utils/ErrorUtils/ErrorUtils';
import apelidos from '../../../../rotas/apelidos';

import ConfirmacaoContaDialog from '../../../../componentes/dialogs/ConfirmacaoContaDialog/ConfirmacaoContaDialog';
import logar from '../../../../logica/logar';
import AvisoDialog from '../../../../componentes/dialogs/AvisoDialog/AvisoDialog';
import { mostrarOverlay, esconderOverlay } from '../../overlay/overlayActions';
import CarregamentoOverlay from '../../../../componentes/overlay/CarregamentoOverlay/CarregamentoOverlay';
import { getEmpresaApp } from '../../../../nucleo/utils/StorageUtils/StorageUtils';
import { atualizarUsuario } from '../../usuario/usuarioActions';

const tiposDeAcoes = {
  ATUALIZAR_CADASTRO: 'ATUALIZAR_CADASTRO:CADASTRO_USUARIO',
  EDITAR_CADASTRO: 'EDITAR_CADASTRO:CADASTRO_USUARIO',
  LIMPAR_CADASTRO: 'LIMPAR_CADASTRO:CADASTRO_USUARIO',
};

export default tiposDeAcoes;

export function limparCadastro() { 
  return function(dispatch: any) {
    dispatch(criarAction(undefined, tiposDeAcoes.LIMPAR_CADASTRO));
  }
}

export const atualizarCadastro = (carga: any) => function(dispatch: any) {
  dispatch(criarAction(carga, tiposDeAcoes.ATUALIZAR_CADASTRO));
}

export const editarCadastroAction = (carga: {caminho?: string}) => function(dispatch: any, getState: () => webAppReducersTipo) {
  const cadastroUsuario = getState().webAppCadastroUsuarioReducer;
  const cadastroEndereco = getState().webAppCadastroEnderecoReducer;
  const codigoPais: number = cadastroEndereco.endereco.pais.codigo;
  const paisOptions = getState().webAppPaisesReducer[codigoPais];

  const {
    bairro,
    cep,
    cidade,
    codigoCidade,
    complemento,
    estado,
    numero,
    rua,
  } = cadastroEndereco.endereco;

  const dadosAdiconaisEndereco = {
    bairro,
    cep,
    complemento,
    endereco: rua,
    municipio: cidade,
    codigoMunicipio: codigoCidade,
    numero,
    pais: ClienteHttp.getApi(`paises/${codigoPais}`),
    paisOptions,
    rua,
    uf: estado,
  };

  const {
    cpf,
    celular,
    dataDeNascimento,
    nome,
    sobrenome,
  } = cadastroUsuario.usuario;

  const dadosAdicionaisUsuario = {
    cpf_cnpj: cpf,
    dataNascimento: dataDeNascimento,
    rg_ie: null,
    telefone: celular,
  }

  const dadosAdicionais = {
    ...dadosAdiconaisEndereco,
    ...dadosAdicionaisUsuario,
  }

  ClienteHttp.obterClienteHttp().patch(ClienteHttp.getApi('updateUsuarioLogado'), {
    cargoPadrao: null,
    dadosAdicionais,
    nome,
    parametrosUsuario: {tamanhoPadraoPaginacao: 10},
    sobrenome,
  }, ClienteHttp.getHeaders())
    .then(() => {
      dispatch(atualizarUsuario())

      if (carga?.caminho) {
        dispatch(NavegacaoUtils.goTo(carga.caminho));
      }
      else {
        dispatch(NavegacaoUtils.goTo(`${apelidos.app}?empresa=${getEmpresaApp()}`));
      }
    });

  limparCadastro();
}

export const cadastrarUsuario = (carga: {caminho?: string}) => function(dispatch: any, getState: () => webAppReducersTipo) {
  const cadastroUsuario = getState().webAppCadastroUsuarioReducer;
  const cadastroEndereco = getState().webAppCadastroEnderecoReducer;
  // const empresaURI = getState().webAppEmpresaReducer.empresa.uri;

  const {
    bairro,
    cep,
    cidade,
    codigoCidade,
    complemento,
    estado,
    numero,
    pais,
    rua,
  } = cadastroEndereco.endereco;

  const dadosAdiconaisEndereco = {
    bairro,
    cep,
    complemento,
    endereco: rua,
    municipio: cidade,
    codigoMunicipioIbge: codigoCidade,
    numero,
    pais: ClienteHttp.getApi(`paises/${pais.codigo}`),
    rua,
    uf: estado,
  };

  const {
    cpf,
    celular,
    dataDeNascimento,
    email,
    nome,
    sobrenome,
  } = cadastroUsuario.usuario;

  const dadosAdicionaisUsuario = {
    cpf_cnpj: cpf || "0",
    dataNascimento: dataDeNascimento,
    rg_ie: null,
    telefone: celular,
  }

  const dadosAdicionais = {
    ...dadosAdiconaisEndereco,
    ...dadosAdicionaisUsuario,
  }

  dispatch(mostrarOverlay({componente: (props: any) => <CarregamentoOverlay {...props} />}));

  ClienteHttp.obterClienteHttp().post(ClienteHttp.getApi('auth/signUp'), {
    usuario: {
      nome,
      sobrenome,
      login: {
        email,
        senha: cadastroUsuario.senha,
        confirmacaoSenha: cadastroUsuario.senha,
      },
      dadosAdicionais,
    },
    // empresa: empresaURI,
  })
    .then((resposta: any) => {
        if (resposta.data === 'loggedInInstead') {
          logar({senha: '', email}, () => {}, dispatch, carga.caminho);
        }
        else if (resposta.data === 'confirmationEmailSent') {
          dispatch(
            mostrarDialog({
              conteudo: () => <ConfirmacaoContaDialog caminho={carga.caminho} login={{email, senha: cadastroUsuario.senha}} />,
            })
          );
        }
        else if (resposta.data === 'userRegistered') {
          logar({email, senha: cadastroUsuario.senha}, () => {}, dispatch, carga.caminho);
          dispatch(NavegacaoUtils.goTo(`${apelidos.app}?empresa=${getEmpresaApp()}`));
        }
      }
    )
    .then(() => {
      dispatch(limparCadastro());
    })
    .catch((error) => {
      const mensagem = getErrorMessageObjectFromError(error);
      if (mensagem && mensagem.message) {
        dispatch(mostrarDialog({ conteudo: () => <AvisoDialog mensagem={mensagem.message} /> }));
      }
      else {
        dispatch(mostrarDialog({ conteudo: () => <AvisoDialog mensagem={LocalidadeUtils.obterTraducao().register.error.defaultMessage} /> }));
      }
    })
    .finally(() => {
      dispatch(esconderOverlay());
    });
}

export const editarCadastro = () => function(dispatch: any, getState: () => webAppReducersTipo) {
  dispatch(
    atualizarCadastro({
        ...getState().webAppUsuarioReducer,
        senha: '',
        senhaRepetida: '',
    })
  );
}
