import criarReducer, { acaoTipo } from "../factory/criarReducer";
import tiposDeAcoes from "./snackbarActions";

export type snackbarReducerTipo = {
  ativo: boolean,
  frase: string,
}

const estadoInicial: snackbarReducerTipo = {
  ativo: false,
  frase: '',
}

const acoesNomeadas: {[key: string]: (carga: any, estadoAtual?: snackbarReducerTipo) => snackbarReducerTipo} = {
  [tiposDeAcoes.ATIVAR_SNACKBAR]: (carga: {frase: string}, estadoAtual?: snackbarReducerTipo) => {
    const novoEstado: snackbarReducerTipo = {
      ...(estadoAtual || estadoInicial)
      , ...carga
      , ativo: true
    }

    return novoEstado;
  },
  [tiposDeAcoes.DESATIVAR_SNACKBAR]: (_, estadoAtual?: snackbarReducerTipo) => {
    const novoEstado: snackbarReducerTipo = {
      ...(estadoAtual || estadoInicial)
      , ativo: false
    }

    return novoEstado;
  },
}

export default (estado: snackbarReducerTipo = estadoInicial, acoes: acaoTipo) => 
  criarReducer(estado, acoes, acoesNomeadas);
