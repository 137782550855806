//@ts-check
import React from 'react';
import { Entrada, Botao, Link, Texto } from '../../../nucleo/nucleo';
import { LocalidadeUtils } from '../../../nucleo/utils/utils';
import DialogTemplate from '../DialogTemplate';
import { connect } from 'react-redux';
import { mostrarDialog, fecharDialog } from '../../../nucleo/redux/dialog/dialogActions';
import NovaSenhaDialog from '../NovaSenhaDialog/NovaSenhaDialog';
import { redirecionarParaCadastro, atualizarUsuario } from '../../../nucleo/redux/usuario/usuarioActions';
import logar from '../../../logica/logar';

import './LoginDialog.css';

/**
 * 
 * @param {import('./types').loginDialogPropsType} props 
 * @returns 
 */
function LoginDialog(props) {
  const [email, updateEmail] = React.useState('');
  const [senha, updateSenha] = React.useState('');
  const [loginFalhou, updateLoginFalhou] = React.useState(false);

  return <DialogTemplate fecharHabilitado={props.fecharHabilitado} titulo={LocalidadeUtils.obterTraducao().dialog.login} style={{maxWidth: '25em'}}>
    <div id='login-dialog'>
      <Entrada onChange={
        /**
         * 
         * @param {{target:{value: string}}} evt 
         */
        ({target}) => { updateEmail(target.value); }} placeholder={LocalidadeUtils.obterTraducao().login.userId} type='email' />
      <Entrada onChange={
        /**
         * 
         * @param {{target:{value: string}}} evt 
         */
        ({target}) => { updateSenha(target.value); }} placeholder={LocalidadeUtils.obterTraducao().login.password} type="password" />
      <Botao className={'ui-submit'} onClick={() => {
      logar({email, senha}, () => updateLoginFalhou(true), props.dispatch, props.caminho, props.parametrosEmpresa.tipoCadastroUsuario);

      }} children={LocalidadeUtils.obterTraducao().login.login} />
      <div className='column-center'>
        {loginFalhou
          ? <Texto key='__texto_login_erro__' className='texto-erro'>{LocalidadeUtils.obterTraducao().phrases.incorrectLogin}</Texto>
          : null
        }
        <Link tabIndex={0} className='nova-senha' onClick={() => props.mostrarDialog(() => <NovaSenhaDialog fecharDialog={props.fecharDialog} fecharHabilitado={true} />)}>{LocalidadeUtils.obterTraducao().login.forgotMyPassword}</Link>
        <Link tabIndex={0} className='cadastrar' onClick={() => { props.redirecionarParaCadastro(props.caminho); }}>{LocalidadeUtils.obterTraducao().login.notRegistered}</Link>
      </div>
    </div>
  </DialogTemplate>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  state,
  webAppUsuarioReducer: state.webAppUsuarioReducer,
  parametrosEmpresa: state.webAppEmpresaReducer.parametrosEmpresa,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  atualizarUsuario: () => dispatch(atualizarUsuario()),
  dispatch,
  fecharDialog: () => dispatch(fecharDialog()),
  /**
   * 
   * @param {React.ElementType} Dialog 
   * @returns 
   */
  mostrarDialog: (Dialog) => dispatch(mostrarDialog({conteudo: () => <Dialog />})),
  /**
   * 
   * @param {string|undefined} caminho 
   * @returns 
   */
  redirecionarParaCadastro: (caminho) => dispatch(redirecionarParaCadastro(caminho)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginDialog);
