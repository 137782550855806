import criarAction from "../factory/criarAction";

const tiposDeAcoes = {
  ATIVAR_SNACKBAR: 'ATIVAR_SNACKBAR:SNACKBAR',
  DESATIVAR_SNACKBAR: 'DESATIVAR_SNACKBAR:SNACKBAR',
}

export default tiposDeAcoes;

export const desativarSnackbar = () => function(dispatch: any) {
  dispatch(criarAction(undefined, tiposDeAcoes.DESATIVAR_SNACKBAR));
};

export const ativarSnackbar = (carga: {frase: string}) => function(dispatch: any) {
  dispatch(criarAction(carga, tiposDeAcoes.ATIVAR_SNACKBAR));

  setTimeout((dispatch) => {
    dispatch(desativarSnackbar());
  }, 3000, dispatch);
};
