import "./PedidosModal.css"

import React from "react"
import { connect } from "react-redux"
import { dispatchTipo } from "../../../store/actions/acaoTemplate"
import { reduxDispatch } from "../../../utils/reduxUtils/reduxDispatch"
import { showProductsPaidCloseSalesDialog } from "../../../store/actions/controleVenda/pagamentoVendasAction"
import { modoTipo, pedidosTelaEstadoTipo } from "./PedidosTela"
import PreCadastroTela from "./PreCadastroTela"
import TelaExibeItensVenda from "../../vendas/manutencao/TelaExibeItensVenda"
import PedidosTelaConteudoDoModal from "./PedidosTelaConteudoDoModal"

export type PedidosModalTipo = {
    dispatch: dispatchTipo,
    modo: modoTipo,
    setState: (state: any) => void | undefined,
    vendaList: Array<any>,
    state: pedidosTelaEstadoTipo,
    estadoInicialDoPedidosTela: pedidosTelaEstadoTipo,
}

function PedidosModal({modo, setState, state, estadoInicialDoPedidosTela, vendaList}: PedidosModalTipo) {

    reduxDispatch(showProductsPaidCloseSalesDialog());

    if (!['exibe_itens_venda', 'cadastrar_comanda', 'pagamento_venda_content'].includes(modo)) {
        return null;
    }

    return <div key={'__modal-pedidos-tela__'} className='modal-pedidos-tela'>
            <div className={`modal-couteudo-pedidos-tela ${modo === 'exibe_itens_venda' ? 'itens-venda' : 'pre-cadastro-tela'}`}>
            <div className={'modal-pedidos-tela-close'} onClick={() => { setState({modo: ['pagamento_venda_content'].includes(modo) ? '' : 'venda'}); }}>
                <div>
                    ×
                </div>
            </div>
            <PreCadastroTela modo={modo} vendaList={[...vendaList]} atualizarEstado={(novoEstado) => setState(novoEstado)} buscarEstado={() => ({...state})} estadoInicial={estadoInicialDoPedidosTela} />
            <TelaExibeItensVenda modo={modo} pureStyle={{width: '100%', margin: '0.5em'}} />
            <PedidosTelaConteudoDoModal modo={modo} setState={setState} />
        </div>
    </div>
}

const mapStateToProps = (state: any) => {
    const props = {

    };

    return props;
}

const mapDispatchToProps = (dispatch: dispatchTipo) => {
    const props = {
        dispatch
    };

    return props;
}

export default connect(mapStateToProps, mapDispatchToProps)(PedidosModal);