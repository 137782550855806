import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from "../../utils/LocaleUtils"
import { updateActiveMenuItem } from "../../utils/ScreenUtils"

import LucratividadeQuebraTableRow from './LucratividadeQuebraTableRow';

// Chaves das colunas
import RelatorioBox, {
    PAGAMENTO_VENDA_HORA,
    ITEM_PAGAMENTO_VENDA_DIFERENCA,
    ITEM_PAGAMENTO_VENDA_PORCENTAGEM_DIFERENCA,
    ITEM_PAGAMENTO_VENDA_PORCENTAGEM_MARGEM,
    ITEM_PAGAMENTO_VENDA_PRODUTO,
    ITEM_PAGAMENTO_VENDA_QUANTIDADE,
    ITEM_PAGAMENTO_VENDA_VALOR_CUSTO_TOTAL,
    ITEM_PAGAMENTO_VENDA_VALOR_TOTAL,

    flattenDadosRelatorio
} from './RelatorioBox';

import * as relatorioActions from '../../store/actions/relatorioAction';

/**
 * Monta a tabela de relatório de lucratividade.
 */
class LucratividadeQuebraBox extends React.Component {

    /**
     * Lista de chaves de coluna na ordenação inicial.
     */
    columnsInitial = [
        PAGAMENTO_VENDA_HORA,
        ITEM_PAGAMENTO_VENDA_PRODUTO,
        ITEM_PAGAMENTO_VENDA_QUANTIDADE,
        ITEM_PAGAMENTO_VENDA_VALOR_TOTAL,
        ITEM_PAGAMENTO_VENDA_VALOR_CUSTO_TOTAL,
        ITEM_PAGAMENTO_VENDA_DIFERENCA,
        ITEM_PAGAMENTO_VENDA_PORCENTAGEM_DIFERENCA,
        ITEM_PAGAMENTO_VENDA_PORCENTAGEM_MARGEM,
    ];

    /**
     * Retorna o texto do cabeçalho de cada coluna de acordo com a chave da coluna.
     */
    columnHeader = column => {
        switch (column) {
            case PAGAMENTO_VENDA_HORA:
                return this.props.hora ? getStrings().timeHourMinute : getStrings().date;
            case ITEM_PAGAMENTO_VENDA_PRODUTO:
                return getStrings().product;
            case ITEM_PAGAMENTO_VENDA_QUANTIDADE:
                return getStrings().amountShort;
            case ITEM_PAGAMENTO_VENDA_VALOR_TOTAL:
                return getStrings().saleValue;
            case ITEM_PAGAMENTO_VENDA_VALOR_CUSTO_TOTAL:
                return getStrings().costValue;
            case ITEM_PAGAMENTO_VENDA_DIFERENCA:
                return getStrings().difference;
            case ITEM_PAGAMENTO_VENDA_PORCENTAGEM_DIFERENCA:
                return getStrings().differencePercentageShort;
            case ITEM_PAGAMENTO_VENDA_PORCENTAGEM_MARGEM:
                return getStrings().marginPercentage;
            default:
                return '';
        }
    }

    componentDidMount() {

        updateActiveMenuItem('menuRelatorioLucratividade' + (this.props.hora ? 'Hora' : 'Data'), 'menuRelatorios');

        // Método usado no início da implementação do sistema de relatórios genéricos. Investigar necessidade ao substituir sistema de relatórios.
        // this.props.getRelatorio('lucratividadeQuebra');

        // Inicializa as opções da geração de relatório
        this.props.sortData({ dadosList: [] }, { report: 'lucratividade' + (this.props.hora ? 'Hora' : 'Data') }, this.columnsInitial, {});
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {

        /**
         * Lista de linhas da tabela.
         */
        let list = null;

        /**
         * Lista de chaves de coluna na ordenação personalidada, se existir, ou inicial, se não
         */
        let columnOrder = this.props.columnOrder || this.columnsInitial;

        // Formata os dados do servidor, se existirem
        if (this.props.dados && ('dadosList' in this.props.dados)) {

            list = [];
            // Desaninha os dados para formatar cada linha da tabela
            flattenDadosRelatorio(this.props.dados, list, (dadosMap, level) => <LucratividadeQuebraTableRow dadosMap={dadosMap} level={level} />);
        }

        return (
            <RelatorioBox
                header={this.props.hora ? getStrings().profitabilityHourReport : getStrings().profitabilityDayReport}
                tableHeader={columnOrder.map(column => ({
                    key: column,
                    text: this.columnHeader(column),
                }))}
                list={list}
                enableFilter={{
                    empresa: true,
                    origemVenda: true,
                    tipoProduto: true,
                    grupoProduto: true,
                    produto: true,
                    usuario: false,
                    estadoVenda: true,
                    estadoItemVenda: false,
                    data: true,
                    dataEntrega:false,
                    hora: true
                }}
                helpText={
                    getStrings().profitabilityReportHelp().concat(this.props.hora
                        ? getStrings().profitabilityHourReportHelp()
                        : getStrings().profitabilityDayReportHelp())
                }
            />
        );
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {
    return {
        ...state.relatorioReducer,
        ...state.idiomaReducer
    };
};

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        getRelatorio: relatorio => dispatch(relatorioActions.getRelatorio(relatorio)),
        sortData: (dados, options, columnOrder, rowSortOptions) => dispatch(relatorioActions.sortData(dados, options, columnOrder, rowSortOptions))
    };
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(LucratividadeQuebraBox);
