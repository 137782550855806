import React from 'react';
import PropsType from 'prop-types';

class ToolbarPlaceholderTemplate extends React.Component {
    static propTypes = {
        disabled: PropsType.bool,
        setAllDisabled: PropsType.bool,
    };

    render() {
        if (this.props.children) {
            if (this.props.children.map) {
                return this.props.children.map((item) => ({...item, props: {...item.props, disabled: (this.props.disabled || this.props.setAllDisabled)}}));
            }

            return {...this.props.children, props: {...this.props.children.props, disabled: (this.props.disabled || this.props.setAllDisabled), topStyle: {...(this.props.children.props.topStyle || {}), width: '100%', display: 'flex', flexFlow: 'row wrap', flex: '1 1 auto'}}};
        }
        
        return <div className={'bar-item'}>
            <div className={'bar-border'} >
                <div className={'grid-space'} >
                    {<label>&nbsp;</label>}
                </div>
            </div>
        </div>;
    }
}

export default ToolbarPlaceholderTemplate;