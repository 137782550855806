import React from 'react'

export type FieldsetType = React.DetailedHTMLProps<React.FieldsetHTMLAttributes<HTMLFieldSetElement>, HTMLFieldSetElement> & {legend: string}

export function Fieldset({children, style = {},...props}: FieldsetType) {
  return <>      
    <fieldset {...props} style={{padding: '0 .5em 0.5em', margin: '0', borderRadius: '0.3em', marginBottom: '0.5em',...style, }}>
      <legend style={{fontWeight: '500', paddingInlineStart: '4px', paddingInlineEnd: '4px'} as any}>{props.legend}</legend>
      {children}
    </fieldset>
  </>
}