import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"

import { getLastScannedSaleSource } from "../../utils/StorageUtils/LocalStorageUtils"
import { log } from "../../utils/LogUtils"
import { updateActiveMenuItem } from "../../utils/ScreenUtils"

import "./vendas.css";
import { getPageNames } from "../../utils/siteUtils/siteUtils"
import { dispatchTipo } from "../../store/actions/acaoTemplate"

export interface PedidosBoxType {}
export interface PedidosBoxTypeWithReducer extends PedidosBoxType {
  goTo: (path: string) => void
}

/**
 * Tela para permitir que o usuário sem vínculo com empresa possa acompanhar os seus pedidos
 * sem realizar nova leitura de código impresso de origem de venda.
 */
class PedidosBox extends React.Component<PedidosBoxTypeWithReducer> {

  /**
   * Método executado APÓS montar o componente.
   */
  componentDidMount() {
    log("PedidosBox componentDidMount");

    updateActiveMenuItem("menuItemPedidos");

    const origemVendaCode = (getLastScannedSaleSource() || "").split("/api/origemVendas/")[1];
    const url = origemVendaCode
      // Se achou origem de venda previamente lida
      ? `${getPageNames().logado.CONTROLE_VENDAS}?code=${origemVendaCode}&order=true`
      : getPageNames().logado.CONTROLE_VENDAS;

    // Exibe os últimos pedidos ou permite um novo
    this.props.goTo(url);
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("PedidosBox render");

    return <></>;
  }
}

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch: dispatchTipo) {
  const props = {
    goTo: (path: string) => 
      dispatch(push(path)),
  };

  return props;
}

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(null, mapDispatchToProps)(PedidosBox);