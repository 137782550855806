import { log } from './LogUtils';
import download from './download2';
import { isString } from './ComparatorsUtils';

/**
 * Abre uma janela para o usuário escolher o local onde será salvo um arquivo.
 * @param {*} data conteúdo do arquivo. Até agora, testado e funcionando com o resultado de método do *Spring* que retorne `StreamingResponseBody`
 * (`axios` deve ser configurado com `responseType: 'blob'`).
 * @param {String} fileName 
 */
export const saveData = (data, fileName) => {
    log('utils saveData', data, fileName);
    return download(data, fileName, 'application/octet-stream')
};

/**
 * https://stackoverflow.com/a/43209768/1781376
 * @param {String} base64Data 
 * @param {String} contentType 
 */
export const base64toBlob = (base64Data, contentType) => {
    if (!(isString(base64Data) && isString(contentType))) {
        return null;
    }

    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}
