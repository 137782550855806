//@ts-check
import BalaoTexto_ from './componentes/BalaoTexto/BalaoTexto';
import Botao_ from './componentes/Botao/Botao';
import Entrada_ from './componentes/Entrada/Entrada';
import Form_ from './componentes/Form/Form';
import Imagem_ from './componentes/Imagem/Imagem';
import Link_ from './componentes/Link/Link';
import Rota_ from './componentes/roteador/Rota';
import Roteador_ from './componentes/roteador/Roteador';
import Texto_ from './componentes/Texto/Texto';

export const BalaoTexto = BalaoTexto_;
export const Botao = Botao_;
export const Entrada = Entrada_;
export const Form = Form_;
export const Imagem = Imagem_;
export const Link = Link_;
export const Rota = Rota_;
export const Roteador = Roteador_;
export const Texto = Texto_;
