import React from 'react';

import { getStrings } from '../../../utils/LocaleUtils';

import CadastroTable from './CadastroTable';
import ObjetoTableModel from './ObjetoTableModel';

/**
 * Classe que repassa para o CadastroTable as informações para a montagem da tabela dos Tipos de Produto.
 */
export default class TipoProdutoTable extends React.Component {
    render() {
        return (
            <CadastroTable {...this.props} objetoCadastro='tipoProduto' sortProperty={'nome'} filterProperties={['nome', 'codigo']} tableModel={ObjetoTableModel}
                blankFormData={{
                    enabled: true,
                    codigo: '',
                    nome: ''
                }} registerHelp={getStrings().productTypeTableHelp}
            />
        );
    }
}
