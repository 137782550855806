import React from "react";
import { connect } from "react-redux";

import { getStrings } from "../../../utils/LocaleUtils";

import * as actionTypes from "../../../store/actions/actionTypes";
import * as cadastroActions from "../../../store/actions/cadastroAction";

import { Button } from "../../UI/Button/Button";
import Icones from "../../../assets/icones/Icones";

/**
 * Monta uma linha da tabela de usuários de um cargo.
 */
const CargoUsuarioRow = (props) => {
  const GoTrashcan = Icones.go.GoTrashcan;

  return <tr>
    {/* Botão de remover */}
    <td className="Lighter ButtonAlign fix" width="50px" >
      <Button
        className="gray-button font-color"
        type="button"
        style={{width: '40px'}}
        onClick={() => {
          // Se for um cadastro novo, remove o email de usuário para ser persistido junto com o resto do cadastro.
          if (props.operation === actionTypes.CADASTRO_NEW) {
            props.cadastroUpdateFormData(
              Object.assign(
                {}
                , props.cadastroDados
                , {
                  usuarioList: props.cadastroDados.usuarioList.filter(usuario => usuario.login.email !== props.email)
                }
              )
            );
            // Agenda o método que ajusta o brilho do botão de confirmação e dialog de alterações pendentes.
            // Provavelmente não seria necessário se lógica de cadastro e do InputCustomizado fosse refeita.
            setTimeout(props.handleChange, 0);
          }
          // Se for um cadastro persistido, persiste imediatamente a remoção do usuário.
          else {
            props.handleRoleUserListChange(props.email, true, null, true);
          }
        }}>
        <GoTrashcan />
      </Button>
    </td>
    {/* Email do usuário */}
    <td className="Lighter TextAlign fix">{props.email}</td>
    {/* Estado do vínculo */}
    <td className="Lighter TextAlign fix">{getStrings().roleUserStateEnumToString(props.status)}</td>
  </tr>;
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
function mapStateToProps (state) {
  const props = {
    cadastroDados: state.cadastroReducer.cadastroDados || { usuarioList: [] }
    , operation: state.cadastroReducer.operation
  };

  return props;
}

/**
 * Mapeia as ações para utilizar localmente. 
 * @param {*} dispatch 
 */
function mapDispatchToProps (dispatch) {
  const props = {
    cadastroUpdateFormData: cadastroDados => 
      dispatch(cadastroActions.cadastroUpdateFormData(cadastroDados))
    , handleRoleUserListChange: (email, isValid, onAdded, remove) => 
      dispatch(cadastroActions.handleRoleUserListChange(email, isValid, onAdded, remove))
  };

  return props;
}

export default connect(mapStateToProps, mapDispatchToProps)(CargoUsuarioRow);
