import { updateObject } from "../../utils/ObjectUtils"

import {
  COLLECTOR_CODE_READER
  , COLLECTOR_LOAD
  , COLLECTOR_MANAGE
  , COLLECTOR_PRODUCT_LOAD
  , COLLECTOR_INCREMENT
  , COLLECTOR_REMOVE
} from "../actions/actionTypes"

/**
 * Exibição de clientes para iniciar ou continuar pedido.
 */
export const STATE_COLLECTOR_SALE_CLIENT_LIST = "STATE_COLLECTOR_SALE_CLIENT_LIST"

/**
 * Exibição do leitor de código de barras ou QR para adicionar produtos.
 */
export const STATE_COLLECTOR_SALE_CODE_READER = "STATE_COLLECTOR_SALE_CODE_READER"

/**
 * Manutenção da venda e seus produtos.
 */
export const STATE_COLLECTOR_SALE_MANAGE_SALE = "STATE_COLLECTOR_SALE_MANAGE_SALE"

/**
 * Estado Inicial, obrigatório no Reducer.
 */
export const initialState = {

  /**
   * O que está sendo feito no momento.
   */
  state: STATE_COLLECTOR_SALE_CLIENT_LIST

  /**
   * URI do cliente cuja venda está sendo gerenciada.
   */
  , clienteURISelected: null

  /**
   * Lista de produtos persistidos.
   */
  , collectedProductList: []

  /**
   * Se a quantidade de algum produto coletado foi alterada.
   */
  , collectedAmountChanged: function () {

    return (this.collectedProductList || []).some(collectedProduct => collectedProduct.amountChanged)
  }

  /**
   * Valor total da lista de produtos coletados.
   */
  , collectedTotal: function () {

    return this.collectorList.reduce((previousOuterSum, currentCollector) =>
      previousOuterSum + ((currentCollector.venda || { itemVendaList: [] }).itemVendaList || []).reduce((previousInnerSum, currentItemVendaList) =>
        previousInnerSum + currentItemVendaList.valorTotal, 0), 0)
  }

  /**
   * Coletor da venda sendo gerenciada.
   */
  , collector: function () {

    return this.collectorList.find(collector => collector.clienteURI === this.clienteURISelected)
  }

  /**
   * Lista de objetos contendo cliente e venda.
   */
  , collectorList: []

  /**
   * Valor do campo de filtro de clientes.
   */
  , filterClienteValue: null

  /**
   * Valor do campo de filtro de produtos.
   */
  , filterProdutoValue: null

  /**
   * Lista contendo objetos contendo produtos para serem coletados. Populada pelo filtro e pelo leitor de código de barras ou QR.
   */
  , filteredProductList: []

  /**
   * Valor total da lista de produtos filtrados.
   */
  , ilteredTotal: function () {
    return this.filteredProductList.filter(product => product.amount)
      .reduce((previousSum, currentProduct) => previousSum + currentProduct.total, 0)
  }

  /**
   * Valor total das duas listas.
   */
  , grandTotal: function () {
    return this.filteredTotal() + this.collectedTotal()
  }

  /**
   * Lista contendo os URIs dos últimos produtos recebidos da retaguarda.
   * Usado para controlar se, ao remover o item, ele será removido da tabela ou não.
   */
  , lastProductList: []

  /**
   * Armazena produtos recebidos da retaguarda.
   * 
   * Há a necessidade de se obter dados do produto quando ele é selecionado pela leitura de código de barras ou QR.
   * Se a retaguarda for ser acessada para cada leitura, o desempenho não será bom, pelo menos não do jeito que está agora.
   * 
   * Uma alternativa é carregar dados de todos os produtos ao iniciar a manutenção de vendas
   * com coletor. Porém, algumas empresas podem ter produtos suficientes para fazer com que isso
   * demore muito tempo. Assim, o meio termo é guardar dados de produtos na medida em que eles vão chegando.
   * 
   * Quando o desempenho da retaguarda for melhorado, pode ser que seja interessante carregar todos os produtos.
   */
  , produtoCache: {}
}

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {

  switch (action.type) {

    case COLLECTOR_CODE_READER:
    case COLLECTOR_INCREMENT:
    case COLLECTOR_LOAD:
    case COLLECTOR_MANAGE:
    case COLLECTOR_PRODUCT_LOAD:
    case COLLECTOR_REMOVE:

      // Basta controlar a construção do action no outro arquivo.
      return updateObject(state, {
        ...state
        , ...action
      })

    default: return state
  }
}

export default reducer
