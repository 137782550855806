import { getStrings } from './LocaleUtils';
import { getURIFromEntity } from './URIUtils';
import { log } from './LogUtils';
import { naturalSort } from './SortUtils';
import { isNonEmptyArray, isString, isNonEmptyObject, isArray } from './ComparatorsUtils';

/**
 * Método que recebe os cadastros vindos do servidor e os transforma em itens de opção para o Select.
 * 
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 * @param {Function} cadastroBuilder Método que transforma o Cadastro em Item para o Select
 * @param {boolean} dontSort disabilita a ordenação
 */
export const cadastroBuildOptions = (isList, cadastroValue, cadastroBuilder, dontSort) => {
    log('SelectUtils cadastroBuildOptions', { isList, cadastroValue, cadastroBuilder });

    isList = isNonEmptyArray(cadastroValue);

    // Se não houverem cadastros, retorna as opções em branco
    if (!(isList || isNonEmptyObject(cadastroValue) || (isString(cadastroValue) && cadastroValue !== ''))) {
        return (isArray(cadastroValue) ? [] : null);
    }

    // Se for lista
    if (isList) {
        // Monta as opções a partir de cada cadastro
        cadastroValue = cadastroValue.map(cadastro => cadastroBuilder(cadastro));
        // Se não for para não ordenar
        if (!dontSort) {
            // Ordena a lista
            cadastroValue = naturalSort(cadastroValue, 'label');
        }
    }
    // Monta as opções a partir do cadastro
    return (isList ? cadastroValue : cadastroBuilder ? cadastroBuilder(cadastroValue) : []);
};

/**
 * Método que recebe os cadastros vindos do servidor e os transforma em itens de opção para o Select.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromCadastros = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromCadastros', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        cadastro => ({ label: cadastro.nome, value: cadastro })
    );
};

/**
 * Método que recebe uma lista de Cargos e os transforma em itens de opção para os Selects, utilizando o nome da Empresa como label.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromCargos_LabelEmpresa = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromCargos_LabelEmpresa', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        cadastro => ({ label: cadastro.empresa.nomeFantasia, value: { ...cadastro, codigo: getURIFromEntity(cadastro) } })
    );
};

/**
 * Método que recebe o estado do item de venda e o transforma em item de opção para os Selects.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromEmpresas = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromEmpresas', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        cadastro => ({ label: cadastro.nomeFantasia, value: { codigo: cadastro._links.self.href } })
    );
};

/**
 * Método que recebe os enums vindos do servidor e os transforma em itens de opção para o Select.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 * @param {Function} i18n função que internacionaliza o enum
 */
export const cadastroBuildOptionsFromEnum = (isList, cadastroValue, i18n) => {
    log('SelectUtils cadastroBuildOptionsFromEnum', { isList, cadastroValue, i18n });
    return cadastroBuildOptions(isList, cadastroValue,
        cadastro => ({ labelKey: cadastro, get label() {
            return i18n(this.labelKey);
        }, value: { codigo: cadastro } })
    );
};

/**
 * Método que recebe os Escopos de Exibição de Item de venda vindos do servidor e os transforma em itens de opção para o Select.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromEscopoExibicaoItemVenda = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromEscopoExibicaoItemVenda', { isList, cadastroValue });
    return cadastroBuildOptionsFromEnum(isList, cadastroValue, string => getStrings().saleItemDisplayScopeEnumToString(string));
};

/**
 * Método que recebe as Funções do Cargo vindas do servidor e as transforma em itens de opção para o Select.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromCargoFuncao = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromCargoFuncao', { isList, cadastroValue });
    return cadastroBuildOptionsFromEnum(isList, cadastroValue, string => getStrings().roleFunctionsEnumToString(string));
};

/**
 * Método que recebe o estado do item de venda e o transforma em item de opção para os Selects.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromEstadoItemVenda = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromEstadoItemVenda', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        cadastro => ({ labelKey: cadastro.codigo, get label() { return getStrings().saleItemStateEnumToString(this.labelKey); }, value: { codigo: cadastro.codigo } })
    );
};

/**
 * Método que recebe o estado de venda e o transforma em item de opção para os Selects.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromEstadoVenda = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromEstadoVenda', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        cadastro => ({ labelKey: cadastro.codigo, get label() { return getStrings().saleStateEnumToString(cadastro.codigo); }, value: { codigo: cadastro.codigo } })
    );
};

/**
 * Método que recebe os Níveis de Usuário Sem Cargo vindos do servidor e os transforma em itens de opção para o Select.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromNivelUsuarioSemEmpresa = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromNivelUsuarioSemEmpresa', { isList, cadastroValue });
    return cadastroBuildOptionsFromEnum(isList, cadastroValue, string => getStrings().noCompanyUserLevelEnumToString(string));
};

/**
 * Método que recebe os países vindos do servidor e os transforma em itens de opção para o Select.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromPaises = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromPaises', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        cadastro => ({ labelKey: cadastro.iso, get label() { return this.labelKey + ' - ' + getStrings().countryName(this.labelKey); }, value: cadastro })
    );
};

/**
 * Método que recebe os bairros vindos do servidor e os transforma em itens de opção para o Select.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromBairros = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromBairros', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        cadastro => ({ labelKey: cadastro.iso, get label() { return this.labelKey + ' - ' + getStrings().countryName(this.labelKey); }, value: cadastro })
    );
};

/**
 * Método que recebe os tamanhos de papéis pré definidos e os transforma em itens de opção para o Select.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromPaperSize = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromPaperSize', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        cadastro => ({ labelKey: cadastro.key, get label() { return getStrings().paperSizeName(cadastro.key); }, value: cadastro }),
        true
    );
};

/**
 * Método que recebe os Ramos da Empresa vindos do servidor e os transforma em itens de opção para o Select.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromRamoEmpresa = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromRamoEmpresa', { isList, cadastroValue });
    return cadastroBuildOptionsFromEnum(isList, cadastroValue, string => getStrings().companyBusinessEnumToString(string));
};

/**
 * Método que recebe uma `string` e a transforma em item de opção para os Selects.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromString = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromString', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        cadastro => ({ label: cadastro, value: { codigo: cadastro } })
    );
};

/**
 * Método que recebe os cadastros vindos do servidor e os transforma em itens de opção para o Select.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromTipoContrato = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromTipoContrato', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        // Internacionaliza o nome do tipo de contrato
        cadastro => ({ labelKey: cadastro.nome, get label() { return getStrings().contractTypeEnumToString(this.labelKey) }, value: cadastro })
    );
};

/**
 * Método que recebe os cadastros vindos do servidor e os transforma em itens de opção para o Select.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromTipoContratoCodigo = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromTipoContratoCodigo', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        // Internacionaliza o nome do tipo de contrato
        cadastro => ({ label: getStrings().contractTypeEnumToString(cadastro.codigo), value: { codigo: cadastro.codigo } })
    );
};

/**
 * Método que recebe os Tipos de Impressora vindos do servidor e os transforma em itens de opção para o Select.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromTipoImpressora = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromTipoImpressora', { isList, cadastroValue });
    return cadastroBuildOptionsFromEnum(isList, cadastroValue, string => getStrings().printerTypeEnumToString(string));
};

/**
 * Método que recebe o tipo de origem de venda vindo do servidor e o transforma em item de opção para os Selects.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array|String} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromTipoOrigemVenda = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromTipoOrigemVenda', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        cadastro => ({ labelKey: cadastro.codigo, get label() { return getStrings().saleSourceTypeEnumToString(this.labelKey); }, value: { codigo: cadastro.codigo } })
    );
};

/**
 * Método que recebe os usuários vindos do servidor e os transforma em itens de opção para os Selects.
 * 
 * @param {Boolean} isList Boolean que identifica se o 'cadastroValue' é uma lista
 * @param {Array} cadastroValue Pode ser utilizado o Cadastro ou CadastroList, dependendo do 'isList'
 */
export const cadastroBuildOptionsFromUsuarios = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromUsuarios', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        cadastro => ({ label: getStrings().userOptionLabel(cadastro.login.email, cadastro.nome, cadastro.sobrenome), value: cadastro })
    );
};

export const cadastroBuildOptionsFromModoCobrancaTeleEntrega = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromModoCobrancaTeleEntrega', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        cadastro => ({ labelKey: cadastro.codigo, get label() { return getStrings().deliveryChargeModeEnumToString(this.labelKey); }, value: { codigo: cadastro.codigo } })
    );
};

export const cadastroBuildOptionsFromModeloImpressaoPedido = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromModeloImpressaoPedido', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        cadastro => ({ labelKey: cadastro.codigo, get label() { return getStrings().orderPrintTemplateEnumToString(this.labelKey); }, value: { codigo: cadastro.codigo } })
    );
};

export const cadastroBuildOptionsFromTipoCadastroUsuario = (isList, cadastroValue) => {
    log('SelectUtils cadastroBuildOptionsFromTipoCadastroUsuario', { isList, cadastroValue });
    return cadastroBuildOptions(isList, cadastroValue,
        cadastro => ({ labelKey: cadastro.codigo, get label() { return getStrings().userRegistrationTypeEnumToString(this.labelKey); }, value: { codigo: cadastro.codigo } })
    );
};

/**
 * Método que recebe a(s) opção(s) selecionada(s) no Select e o transforma em JSON para serem gravados ao servidor.
 *  
 * @param {Array|String} option 
 */
export const cadastroBuildJSONFromOptions = (isMulti, option) => {
    log('SelectUtils cadastroBuildJSONFromOptions', { isMulti, option });
    // Se não houverem opções selecionadas, retorna o JSON em branco

    let is_non_empty_array = isNonEmptyArray(option);

    if (!(is_non_empty_array || isNonEmptyObject(option))) {
        return (isArray(option) ? [] : null);
    }

    // Monta o JSON a partir das opções selecionadas
    return (is_non_empty_array ? option.map(optionValue => getURIFromEntity(optionValue.value)) : getURIFromEntity(option.value));
};

/**
 * Método que retorna o valor que deve ser utiizado para o atributo "Tipo Contrato" e "Situação Empresa". 
 * 
 * @param {Object} option
 */
export const cadastroRecuperaValueFromOptions = option => {
    log('SelectUtils cadastroRecuperaValueFromOptions', { option });
    // Caso nenuma opção foi selecionada, retorna null
    if (!(isNonEmptyObject(option) && option.value)) {
        return null;
    }

    // Se houver, recupera o link da opção selecionada. Se não, recupera o código da opção selecionada.
    return getURIFromEntity(option.value) || option.value.codigo;
};

/**
 * Método que retorna um array com os códigos ou URIs da lista de opções passada por parâmetro.
 * 
 * @param {Object} optionList
 */
export const cadastroRecuperaValueFromOptionsList = optionList => {
    log('SelectUtils cadastroRecuperaValueFromOptionsList', { optionList });

    // Caso nenuma opção foi selecionada, retorna null
    if (!Array.isArray(optionList) || !optionList.length) {
        return null;
    }

    // Se houver, recupera o link da opção selecionada. Se não, recupera o código da opção selecionada.
    return optionList.map(option => getURIFromEntity(option.value) || option.value.codigo);
};

/**
 * 
 * @param {Any} args {value: Any, label: String, options: Array}
 */
export const createSelect = (args = {}) => {
    const value = args.value || args.multi ? [] : null;
    const label = args.label || args.multi ? [] : '';
    const options = args.options || [];
    const onChange = args.onChange || (() => {});
    const onClear = args.onClear || (() => {});

    const select = {
        value,
        label,
        options,
        onChange,
        onClear,
    };

    return select;
}
