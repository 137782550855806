import { log } from './LogUtils';
import { isDate, isMoment, isNumber } from './ComparatorsUtils';

import "../apps/promo_app_frontend/nucleo/redux/parametrosEmpresa/parametrosEmpresaReducer";

let moment = require('moment');

export const DATE_FORMAT_YEAR_SHORT = 7;
export const DATE_FORMAT_YEAR = 6;
export const DATE_FORMAT_MONTH = 5;
export const DATE_FORMAT_DAY = 4;
export const DATE_FORMAT_HOUR = 3;
export const DATE_FORMAT_MINUTE = 2;
export const DATE_FORMAT_SECOND = 1;
export const DATE_FORMAT_MILLISECOND = 0;

/**
 * Método que retorna o valor do date, conforme o parâmetro passado.
 * 0 - Milisegundos
 * 1 - Segundos
 * 2 - Minutos
 * 3 - Horas
 * 4 - Dia
 * 5 - Mês
 * 6 - Ano
 * @param {Date} dateTime 
 * @param {number} valueConstant 
 * @param {boolean} moment se `dateTime` é uma instância de moment
 */
export const formatDateValue = (dateTime, valueConstant) => {
    log('utils formatDateValue', dateTime, valueConstant);

    const moment = isMoment(dateTime);
    
    if (!(isDate(dateTime) || moment) || !isNumber(valueConstant) || !(valueConstant in [
        DATE_FORMAT_MILLISECOND,
        DATE_FORMAT_SECOND,
        DATE_FORMAT_MINUTE,
        DATE_FORMAT_HOUR,
        DATE_FORMAT_DAY,
        DATE_FORMAT_MONTH,
        DATE_FORMAT_YEAR,
        DATE_FORMAT_YEAR_SHORT,
    ]))
        return null;

    let value;

    if (valueConstant === DATE_FORMAT_MILLISECOND) {
        value = moment ? dateTime.milliseconds() : dateTime.getMilliseconds();
    }
    else if (valueConstant === DATE_FORMAT_SECOND) {
        value = moment ? dateTime.seconds() : dateTime.getSeconds();
    }
    else if (valueConstant === DATE_FORMAT_MINUTE) {
        value = moment ? dateTime.minutes() : dateTime.getMinutes();
    }
    else if (valueConstant === DATE_FORMAT_HOUR) {
        value = moment ? dateTime.hours() : dateTime.getHours();
    }
    else if (valueConstant === DATE_FORMAT_DAY) {
        value = moment ? dateTime.date() : dateTime.getDate();
    }
    else if (valueConstant === DATE_FORMAT_MONTH) {
        value = moment ? dateTime.month() + 1 : dateTime.getMonth() + 1;
    }
    else if (valueConstant === DATE_FORMAT_YEAR) {
        value = moment ? dateTime.year() : dateTime.getFullYear();
    }
    else if (valueConstant === DATE_FORMAT_YEAR_SHORT) {
        value = moment ? dateTime.year().toString().substr(-2) : dateTime.getFullYear().toString().substr(-2);
    }

    return (value > 9 ? value : '0' + value).toString();
}

/**
 * Retorna a data e hora atual, com o fuso horário separado da data e hora.
 */
export const getCurrentDateTimeAndZone = () => {
    log('utils getCurrentDateTimeAndZone');
    let currentMoment = moment();
    return {
        dateTime: currentMoment.format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
        timeZone: currentMoment.format('Z')
    };
};

/**
 * 
 * @param {number} day 
 * @returns {diasDaSemanaTipo}
 */
export function getDiaDaSemana(day) {   
    const arrayDias = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];
    return arrayDias[day];
}

/**
 * 
 * @param {String} date 
 * 
 * @returns {String} formattedDate
 */
export const getFormattedDate = (date, dateFormat) => {
    dateFormat = dateFormat || 'DD-MM-YYYY';

    return moment(date, dateFormat).format();
}

/**
 * 
 * @param {String} date 
 * @param {String} time 
 * 
 * @returns {String} formattedDate
 */
export const getFormattedDateAndTime = (date, time, dateFormat, timeFormat) => {
    dateFormat = dateFormat || 'DD-MM-YYYY';
    timeFormat = timeFormat || 'HH:mm';

    return moment(`${date} ${time}`, `${dateFormat} ${timeFormat}`).format();
}

/**
 * 
 * @param {String} format 
 * 
 * @returns {String} formattedDate
 */
export const getCurrentDateTimeFormatted = (format) => {
    format = format || 'DD/MM/YYYY - HH:mm';
    return moment().format(format);
}

/**
 * 
 * @param {String|moment.Moment} date 
 * @param {String|moment.Moment} startDate 
 * @param {String|moment.Moment} endDate 
 * 
 * @returns {Boolean} isInRange
 */
export const dateIsInRange = (date, startDate, endDate) => {
    date = isMoment(date) ? date : moment(date);
    startDate = isMoment(startDate) ? startDate : moment(startDate);
    endDate = isMoment(endDate) ? endDate : moment(endDate);

    return date.isBetween(startDate, endDate, null, '[]');
}
