import EnderecoUsuarioType from './EnderecoUsuarioType';

export function getEndereco(cliente: {enderecoEmpresa?: EnderecoUsuarioType, dadosAdicionais?: EnderecoUsuarioType, endereco?: EnderecoUsuarioType, enderecoUsuario?: Partial<EnderecoUsuarioType>}): Partial<EnderecoUsuarioType> | null {
  const endereco = cliente.dadosAdicionais
    || cliente.endereco
    || cliente.enderecoUsuario
    || cliente.enderecoEmpresa
    || null;

  return endereco;
}

export default getEndereco