//@ts-check
import "./Entrada.css"

import React from "react"
import Seletor from "../seletores/Seletor"
import { EntradaType } from "./types.d"

/**
 * 
 * @param {{type?: string, value?: string | number | readonly string[], label?: string}} props
 * @returns {JSX.Element}
 */
const Label = (props) => {
  return  <label className="ui-entrada-label">{props.label}</label>
}

class Entrada extends EntradaType {
  /**
   * 
   * @param {React.ChangeEvent<HTMLInputElement>} event 
   */
  changeHandler = (event) => {
    let value;

    switch (this.props.type) {
      case "digits":
        event.target.value = event.target.value.replace(/[^\d]/g, "");
        break;
      case "number":
        value = event.target.value &&
          (parseFloat(
            event.target.value
              .replace(/[^\d|',']/g, "")
              .replace(",", ".")
          ).toFixed(2))
          .replace(".", ","); 
        
        event.target.value = value;
        break;
      default:
        break;
    }

    this.props.onChange && this.props.onChange(event);
  }

  render() {
    const {ref, children, label: label_, obrigatorio, onChange, seletores, ...sanitizedProps} = this.props;
    const label = (obrigatorio ? "*" : "") + (label_ || "");

    if (["seletor-unico", "seletor-multiplo"].includes(this.props.type || "")) {
      /**
       * 
       * @type {import("../seletores/types").seletoresType}
       */
      const type = this.props.type === "seletor-multiplo" ? "seletor-multiplo" : "seletor-unico";
    
      return <Seletor value={this.props.value} onChange={(value) => onChange && onChange({target: {value, name: this.props.name || "", obrigatorio}})} name={this.props.name || "seletor-unico"} label={label} type={type} children={seletores || []} />
    }

    return <>
      {label ? <Label value={this.props.value} label={label} /> : null}
      <input {...sanitizedProps} placeholder={this.props.placeholder} type={this.props.type === "password" ? "password" : this.props.type === "date" ? "date" : this.props.type === "time" ? "time" : this.props.type === "email" ? "email" : "text"} onChange={this.changeHandler} className={"ui-entrada " + (this.props.className || "")} />
    </>;
  }
}

export default Entrada;
