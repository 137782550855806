//@ts-check

import "./ProdutoTela.css";

import React from 'react';
import apelidos from '../../../rotas/apelidos';
import { connect } from 'react-redux';
import { NavegacaoUtils, OrdenacaoUtils } from '../../../nucleo/utils/utils';
import { definirBotaoPrincipal } from '../../../nucleo/redux/botoes/botaoPrincipal/botaoPrincipalActions';
import ProdutoCard from '../../cards/ProdutoCard/ProdutoCard';

class ProdutoTela extends React.Component {
  /**
   * 
   * @type {{produtos: []}}
   */
  static defaultProps
  
  componentDidMount() {
    this.props.dispatch(definirBotaoPrincipal({
      executarAcao: () => {
        this.props.dispatch(NavegacaoUtils.goTo(apelidos.carrinho));
      },
    }));
  }

  render() {
    /**
     * 
     * @type {Array<import("../../../nucleo/redux/produtos/produtos/produtosReducer").produtoTipo>}
     */
    const listaDeProdutos = OrdenacaoUtils
      .ordenarPorMaioresDescontosUtil(Object.values(this.props.produtos).filter((produto) => produto.mostrar));

    const produtoOrdenado = OrdenacaoUtils
      .ordenarArrayDeObjetosPorStringItem(listaDeProdutos.filter((produto) => produto.grupoProdutosCodigo === this.props.grupoProduto.codigo), this.props.empresaReducer.parametrosEmpresa.filtroDeProdutos || 'nome');

    return <div tabIndex={0} id={`grupo-produto-${this.props.grupoProduto.codigo}`} className='produto-tela'>
      <div style={!produtoOrdenado.length ? {display: 'none'} : undefined} tabIndex={0} className={`grupo-produto-titulo`}>{`${this.props.grupoProduto.nome} (${produtoOrdenado.length}):`}</div>
      {produtoOrdenado
        .map(
          /**
           * 
           * @param {import("../../../nucleo/redux/produtos/produtos/produtosReducer").produtoTipo} produto 
           * @param {number} index 
           * @returns 
           */
          // @ts-ignore
          (produto, index) => <ProdutoCard ultimoCardDoGrupo={(produtoOrdenado.length - 1) === index} tabIndex={index === 0 ? 0 : -1} {...produto} />)}
    </div>;
  }
}

/**
 * 
 * @param {import("../../../nucleo/redux/types").webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  empresaReducer: state.webAppEmpresaReducer,
  produtos: state.webAppProdutosReducer.produtos,
});

/**
 * 
 * @param {import("../../../../../store/actions/acaoTemplate").dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProdutoTela);
