import React from "react"

import { eventParamType } from "../../../utils/InputCustomizado/eventParamType"

import InputCustomizado from "../../UI/Input/InputCustomizado"
import { Button } from "../../UI/Button/Button"
import DeleteTestUserButton from "../../Telas/SignUp/DeleteTestUserButton"

import { isIOS, isIE, isEdge, isMobile, isAncientChromeMobile } from "../../../utils/NavigatorUtils"

import "./DevPage.css"
import { global_strings, locale } from "../../../utils/LocaleUtils"

function execute(storage: Storage, key: string, value: string) {
  storage.setItem(key, value)
}

function InputTestScreen() {
  const [valueText, updateValueText] = React.useState<string|undefined>()
  const [valueNumeric, updateValueNumeric] = React.useState<string|undefined>()

  return <>
    <InputCustomizado inputType="text" placeholder="text" value={valueText} onChange={({target: {value}}: eventParamType) => { updateValueText(value) }} />
    <InputCustomizado inputType="numeric" placeholder="numeric" value={valueNumeric} onChange={({target: {value}}: eventParamType) => { updateValueNumeric(value) }} />
  </>
}

function DevPage() {
  const [sessionStorageChecked, setSessionStorageChecked] = React.useState(false)
  const storageName = sessionStorageChecked
    ? "sessionStorage"
    : "localStorage"
  const storage = window[storageName]
  const [fieldValue, setFieldValue] = React.useState("")
  const [storageIsUpdated, setLocalStorageIsUpdated] = React.useState(false)

  function showLocalStorage() {
    if(storageIsUpdated) {
      setLocalStorageIsUpdated(false);
      return ""
    }

    const storageArray = Object.keys(storage)

    return <>
      <label>{"sessionStorage"}</label>
      <input type="checkbox" defaultChecked={sessionStorageChecked} onChange={(e) => {
        setSessionStorageChecked(!sessionStorageChecked);
      }} />
      {storageArray.map((property, index) => {
        const valueText = storage.getItem(property)
        const value: boolean | null = typeof valueText === "boolean"
          ? valueText === "true"
          : null

          return <li key={index}>
            {property}: {valueText}
            {(typeof value === "boolean")
              ? <Button onClick={() => { storage.setItem(property, JSON.stringify(!value)); setLocalStorageIsUpdated(true) }}>{"Invert"}</Button>
              : null
            }
            <Button onClick={() => { setFieldValue(`${property} ${value}`) }}>Edit</Button>
            <Button onClick={() => { storage.removeItem(property); setLocalStorageIsUpdated(true)} }>{"Delete"}</Button>
          </li>
      })}
    </>
  }


  const [key, value] = fieldValue.split(" ");
  const {[locale.pt_br]: ptBR, [locale.en_us]: enUS} = global_strings;

  const stringsNotTransledEnUs = Object.keys(ptBR).filter((stringKey) => {
    // @ts-ignore
    return enUS[stringKey] === undefined;
  });

  const stringsNotTransledPtBr = Object.keys(enUS).filter((stringKey) => {
    // @ts-ignore
    return ptBR[stringKey] === undefined;
  });

  return <div id="dev-page">
    {stringsNotTransledPtBr.length
      ? <p>{"Não traduzido - pt-BR"}</p>
      : null}
    {stringsNotTransledPtBr}
    {stringsNotTransledEnUs.length
      ? <p>{"Não traduzido - en-US"}</p>
      : null}
    {stringsNotTransledEnUs}
    <p></p>
    {<InputTestScreen />}
    {/* User Agent */}
    <p>{"User Agent"}</p>
    <textarea children={navigator.userAgent} style={{minHeight:'50px', resize:'none'}} readOnly  />
    <br />
    <p>{`isIOS: ${isIOS}`}</p>
    <p>{`isIE: ${isIE}`}</p>
    <p>{`isEdge: ${isEdge}`}</p>
    <p>{`isMobile: ${isMobile}`}</p>
    <p>{`isAncientChromeMobile: ${isAncientChromeMobile}`}</p>
    <br />

    {/* Botão Deletar Teste - 'teste@teste' */}
    <DeleteTestUserButton />

    {/* storage - editor */}
    <input type="text" value={fieldValue} onChange={evt => setFieldValue(evt.target.value)} onKeyDown={evt => {
      if (evt.key === "Enter") {
        execute(storage, key, value)
        setFieldValue("")
      }
    }} />
    <Button onClick={() =>{
      execute(storage, key, value)
      setFieldValue("")
    }}>
      {"executar"}
    </Button>
    <ul>{ showLocalStorage() }</ul>
    <br />
  </div>
}

export default DevPage