export const UPDATE_DELIVERY = 'UPDATE:DELIVERY';
export const REMOVE_DELIVERY = 'REMOVE:DELIVERY';
export const CLEAR_DELIVERY = 'CLEAR:DELIVERY';

const initialState = [];

const mapAction = {
    /**
     * 
     * @param {String} payload
     * @param {String[]} state
     */
    [UPDATE_DELIVERY]: (payload, state) => {
        if (state.includes(payload)) {
            return state;
        }

        return [...state, payload];
    },
    /**
     * 
     * @param {String} item
     * @param {String[]} state
     */
    [REMOVE_DELIVERY]: (item, state) => {
        if (!state.includes(item)) {
            return state;
        }
            
        const index = state.indexOf(item);

        return [...state.slice(0, index), ...state.slice(index + 1)];
    },

    /**
     * 
     */
    [CLEAR_DELIVERY]: () => {
        return [];
    },
};

const deliveryReducer = (state = initialState, {type, payload}) => {
    if (Object.keys(mapAction).includes(type)) {
        return mapAction[type](payload, state);
    }
    
    return state;
}

export default deliveryReducer;