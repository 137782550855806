import React from "react";
import { connect } from "react-redux";

import log from "../../../apps/promo_app_frontend/nucleo/utils/LogUtils/log";
import { validaSenha } from "../../../utils/CadastroUtils";
import { comparaListas } from "../../../utils/ComparatorsUtils";
import { getStrings } from "../../../utils/LocaleUtils";
import { reduxDispatch } from "../../../utils/reduxUtils/reduxDispatch";
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils";

import { dispatchTipo } from "../../../store/actions/acaoTemplate";
import { setScore, setSuggestions, setWarning, updateAllFormData } from "../../../store/actions/signUpAction";

import InputCustomizado from "../../UI/Input/InputCustomizado";
import { cadastroEmpresaEUsuarioSaveData } from "../../../store/actions/cadastroEmpresaAction";
import { STATE_CADASTRO_EMPRESA_USUARIO } from "../../../store/reducers/cadastroEmpresaReducer";

export interface SignUpUserFormStepPersonalInfoType {
  companyForm: boolean
  f3: {
    user_id: string
    first_name: string
    last_name: string
    email: string
  }
  novoUsuarioCargo: boolean
}

interface SignUpUserFormStepPersonalInfoTypeWithReducer extends SignUpUserFormStepPersonalInfoType {
  password_score: number
  password_suggestions: string[]
  password_warning: string
  signUpFormData: any
  step: number
  updateCadastroEmpresaUsuario: (dados: any) => void
  updateSignUpAllFormData: (dados: any) => void
  usuarioEmpresa: any
}

function mapForceToRGB(force: number) {
  const rgbs = [
    "rgb(200, 20, 20)"
    , "rgb(250, 150, 25)"
    , "rgb(250, 250, 0)"
    , "rgb(180, 255, 150)"
    , "rgb(0, 200, 0)"
  ];

  return rgbs[force];
};

function mapForceToWidth(force: number) {
  const widths = [
    "3%"
    , "6%"
    , "12%"
    , "18%"
    , "36%"
  ];

  return widths[force];
};

class SignUpUserFormStepPersonalInfo_ extends React.Component<SignUpUserFormStepPersonalInfoTypeWithReducer> {
  email: any = undefined;
  senha: any = undefined;
  valor_da_senha: any = undefined;
  nome: any = undefined;
  sobrenome: any = undefined;
  telefone: any = undefined;

  /**
   * Método que avalia a qualidade da senha, executa no componentDidMount e no onInput da senha
   */
  avaliarSenha = () => {
    log("SignUpUser avaliarSenha");
    const suggestions = getStrings().passwordSuggestions;
    const warnings = getStrings().passwordWarnings;
    const valida = validaSenha(this.valor_da_senha, suggestions, warnings);

    valida.then((resposta: any) => {
      if (resposta && this.props.password_score !== resposta.score) {
        reduxDispatch(setScore(resposta.score));
      }

      if (resposta && !comparaListas(this.props.password_suggestions, resposta.feedback.suggestions)) {
        reduxDispatch(setSuggestions(resposta.feedback.suggestions));
      }

      if (resposta && this.props.password_warning !== resposta.feedback.warning) {
        reduxDispatch(setWarning(resposta.feedback.warning));
      }

      this.valor_da_senha = undefined;
    })
  }

  usuarioReducerSwitch = () => {
    const companyForm = `${this.props.companyForm || false}`;

    switch (companyForm) {
      case "true":
        return this.props.usuarioEmpresa;

      case "false":
        return this.props.signUpFormData;
    }
  }

  updateFunctionSwitch = () => {
    const companyForm = `${this.props.companyForm || false}`;

    switch(companyForm) {
      case "true":
        return this.props.updateCadastroEmpresaUsuario;

      case "false":
        return this.props.updateSignUpAllFormData;

      default:
        return () => {};
    }
  }

  handleTextInput = (keyArray: string[], value: any) => {
    const usuario = this.usuarioReducerSwitch();
    const updateFunction = this.updateFunctionSwitch();
    const [ key, key2 ] = keyArray;
    
    updateFunction({
      ...usuario,
      [key]: key2
        ? {
          ...(usuario[key] || {}),
          [key2]: value,
        }
        : value
    });
  }

  componentDidMount() {
    this.avaliarSenha();
  }

  componentDidUpdate() {
    log("SignUpUser componentDidUpdate");

    const usuarioReducer = this.usuarioReducerSwitch();
 
    if ((usuarioReducer.login?.email || "") !== this.email.inputComponent.value) {
      this.email.inputComponent.value = usuarioReducer.login?.email || "";
    }

    if ((usuarioReducer.login?.senha || "") !== this.senha.inputComponent.value) {
      this.senha.inputComponent.value = usuarioReducer.login?.senha || "";
    }

    if ((usuarioReducer.nome || "") !== this.nome.inputComponent.value) {
      this.nome.inputComponent.value = usuarioReducer?.nome || "";
    }

    if ((usuarioReducer.sobrenome || "") !== this.sobrenome.inputComponent.value) {
      this.sobrenome.inputComponent.value = usuarioReducer?.sobrenome || "";
    }

    if ((usuarioReducer.dadosAdicionais?.telefone || "") !== this.telefone.inputComponent.value) {
      this.telefone.inputComponent.value = usuarioReducer.dadosAdicionais?.telefone || "";
    }
  }

  render() {
    const style = this.props.novoUsuarioCargo
      ? { display: "none" }
      : undefined;

    const f3_id = this.props.f3
      ? this.props.f3.user_id
      : null;
    const f3_first_name = this.props.f3
      ? this.props.f3.first_name
      : null;
    const f3_last_name = this.props.f3
      ? this.props.f3.last_name
      : null;
    const f3_email = this.props.f3
      ? this.props.f3.email
      : null;

    return <div className={this.props.step !== 0 ? "hidden" : undefined}>

      {this.props.companyForm
        ? <h2>{`${getStrings().user} - ${getStrings().personalData}`}</h2>
        : <h2>{getStrings().personalData}</h2>
      }

      {(f3_email) ? null : <InputCustomizado
        id="email"
        ref={(input: any) => {
          if (input) {
            this.email = getReduxWrappedComponent(input);

            const params = new URLSearchParams(window.location.search);
            const email_ = params.get("email");

            if (email_ !== null) {
              this.handleTextInput(["login", "email"], email_);
            }
          }
        }}
        topClassStyle={style}
        style={style}
        placeholder={getStrings().email}
        maxLength="250"
        type="email"
        name="email"
        required={true}
        label={getStrings().emailRequired}
        handleInputValidado={value => this.handleTextInput(["login", "email"], value)}
      />}

      {f3_id ? null :
        <InputCustomizado id="senha"
          onInput={(evt) => {
            this.valor_da_senha = evt.target.value
            this.avaliarSenha()
          }}
          ref={(input: any) => { if (input) { this.senha = getReduxWrappedComponent(input) } }}
          placeholder={getStrings().password}
          maxLength="20"
          type="password"
          name="senha"
          required={true}
          label={getStrings().passwordRequired}
          handleInputValidado={value => this.handleTextInput(["login", "senha"], value)}
          autoComplete="nah"
          style={{ display: f3_id ? "none" : null, }}
        />}

      <div
        title={this.props.password_suggestions.join("\n") + "\n" + this.props.password_warning}
        className={"flex flex-flow flex-no-wrap"}
        style={{ marginTop: "2px", marginBottom: "10px" }}
      >
        <div style={{ paddingRight: "0.3em" }}>
          {`${getStrings().force}:`}
        </div>
        <div
          style={{
            paddingLeft: "0.3em"
            , marginTop: "8px"
            , width: mapForceToWidth(this.props.password_score)
            , height: "0.5em"
            , borderRadius: "0.5em"
            , background: mapForceToRGB(this.props.password_score)
          }}
        />
      </div>

      <div className="flex flex-row flex-nowrap">
        <div className="flex flex-row flex-nowrap" style={{ flex: "1 1 0" }}>
          {f3_first_name ? null : <InputCustomizado
            topClassName="full-width"
            topClassStyle={{ paddingRigth: "0.3em" }}
            id="nome"
            ref={(input: any) => { if (input) { this.nome = getReduxWrappedComponent(input); } }}
            placeholder={getStrings().firstName}
            maxLength="40"
            type="text"
            name="nome"
            required={true}
            label={getStrings().firstNameRequired}
            handleInputValidado={value => this.handleTextInput(["nome"], value)}
            autoComplete="nah"
          />}
        </div>
        <div className="flex flex-row flex-nowrap" style={{ flex: "1 1 0" }}>
          {f3_last_name ? null : <InputCustomizado
            topClassName="full-width"
            topClassStyle={{ paddingLeft: "0.6em" }}
            id="sobrenome"
            ref={(input: any) => { if (input) { this.sobrenome = getReduxWrappedComponent(input); } }}
            placeholder={getStrings().lastName}
            maxLength="40"
            type="text"
            name="sobrenome"
            required={true}
            label={getStrings().lastNameRequired}
            handleInputValidado={value => this.handleTextInput(["sobrenome"], value)}
            autoComplete="nah"
          />}
        </div>
      </div>

      {<InputCustomizado
        id="telefone"
        ref={(input: any) => { if (input) { this.telefone = getReduxWrappedComponent(input); } }}
        placeholder={getStrings().phoneNumberPlaceholder}
        maxLength="15"
        type="text"
        name="telefone"
        required={true}
        label={getStrings().phoneNumberRequired}
        handleInputValidado={value => this.handleTextInput(["dadosAdicionais", "telefone"], value)}
        autoComplete="nah"
      />}
    </div>
  }
}

function mapStateToProps(state: any) {
  const props = {
    usuarioEmpresa: state.cadastroEmpresaReducer.cadastroEmpresaDados.usuario,
    password_score: state.signUpReducer.password_score,
    password_suggestions: state.signUpReducer.password_suggestions,
    password_warning: state.signUpReducer.password_warning,
    signUpFormData: state.signUpReducer.formData,
    step: state.signUpReducer.step,
  };

  return props;
}

function mapDispatchToProps(dispatch: dispatchTipo) {
  const props = {
    updateCadastroEmpresaUsuario: (dados: any) => 
      dispatch(cadastroEmpresaEUsuarioSaveData(STATE_CADASTRO_EMPRESA_USUARIO, dados)),
    updateSignUpAllFormData: (dados: any) =>
      dispatch(updateAllFormData(dados)),
  };

  return props;
}

const SignUpUserFormStepPersonalInfo = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SignUpUserFormStepPersonalInfo_)

export default SignUpUserFormStepPersonalInfo