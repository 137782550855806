import React from "react"

import { cadastroReducerType } from "../../../store/reducers/cadastroReducer"
import { idiomaReducerType } from "../../../store/reducers/idiomaReducer"

import { CadastroFormType } from "./CadastroForm"

export type CadastroValuesType = {
  children: React.ReactNode,
}

export type CadastroValuesParentType = CadastroFormType & cadastroReducerType & {
  handleChange: () => void,
  onSave: (formData: Record<string,any>, params?: Record<string,any>) => void,
}

export type CadastroValuesParentReduxStateType = idiomaReducerType & {
  tabIndex: -1|0,
}

type CadastroValuesReduxStateType = {
  className?: string;
  style?: React.CSSProperties;
};

type CadastroValuesTypeWithReducer = CadastroValuesType & CadastroValuesReduxStateType;

class CadastroValues_ extends React.Component<CadastroValuesTypeWithReducer> {
  render() {
    return <div className={"sub-form " + (this.props.className || "")} style={this.props.style} >
      {this.props.children}
    </div>;
  }
}

/**
 * Exporta o último argumento entre parênteses.
 */
export const CadastroValues = CadastroValues_;
export default CadastroValues;