import React from 'react';
import { connect } from 'react-redux';

import { log } from "../../utils/LogUtils"
import { getURIFromEntity } from "../../utils/URIUtils"

import BuildCellFromItemPagamentoVenda from './BuildCellFromItemPagamentoVenda';
import BuildCellFromPagamentoVendaOrigem from './BuildCellFromPagamentoVendaOrigem';
import BuildCellFromPagamentoVendaTotal from './BuildCellFromPagamentoVendaTotal';

/**
 * Exibe um pagamento de venda com seus itens.
 */
class PagamentoVendaTable extends React.Component {

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('PagamentoVendaTable render');

        let pagamentoVenda = this.props.pagamentoVenda || {};

        return <>
            {/* Cabeçalho */}
            <BuildCellFromPagamentoVendaOrigem
                pagamentoVenda={pagamentoVenda}
            />
            {/* Produtos */}
            {Object.values((pagamentoVenda.itemPagamentoVendaList || [])).map(itemPagamentoVenda => <BuildCellFromItemPagamentoVenda
                key={getURIFromEntity(itemPagamentoVenda)}
                itemPagamentoVenda={itemPagamentoVenda}
            />)}
            {/* Rodapé */}
            <BuildCellFromPagamentoVendaTotal
                pagamentoVenda={pagamentoVenda}
            />
        </>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    tabIndex: state.appReducer.getTabIndex(),
    ...state.idiomaReducer,
});

export default connect(mapStateToProps)(PagamentoVendaTable);
