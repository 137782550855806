import criarReducer, { acaoTipo } from "../factory/criarReducer";
import { tiposDeAcoes } from "./mobiAppActions";
import { nullOrUndefined } from "../../../nucleo/utils/ComparatorUtils/ComparatorUtils";

export type mobiAppCoresType = {
    corBotaoDeAcao: string
    , corBotaoDeAcaoTexto: string
    , corBotaoVoltar: string
    , corBotaoVoltarTexto: string
    , corPrincipal: string
    , corPrincipalTexto: string
    , corDeFundo: string
    , corDeFundoTexto: string
    , corDoDialog: string
    , corDoDialogTexto: string
    , corDoCard: string
    , corDoCardTexto: string
    , corDaEntrada: string
    , corDaEntradaTexto: string
    , corDaBarraNavegacao: string
    , corDaBarraNavegacaoTexto: string
    , corBarraTopo: string
    , corBarraTopoTexto: string
    , corLinksDoCard: string
}

export type mobiAppReducerTipo = mobiAppCoresType & {
    logoEmpresa: string
    , valorLinkParametro: string
    , nomeExibicao: string
    , habilitarMensagemLojaFechada: boolean
    , mostrarMensagemContatoEmpresa: boolean
    , obrigaDataEntrega: boolean
    , vendaMobiAppEncerrada: boolean
    , versaoLogoEmpresa: string
};

export const mobiAppCoresEstadoInicial = {
    corPrincipal:'rgb(7,54,89)'
    , corPrincipalTexto:'rgb(255,255,255)'
    , corBotaoDeAcao:'rgb(7,54,89)'
    , corBotaoDeAcaoTexto:'rgb(255,255,255)'
    , corBotaoVoltar:'rgb(255,255,255)'
    , corBotaoVoltarTexto:'rgb(0,0,0)'
    , corDeFundo:'rgb(255,255,255)'
    , corDeFundoTexto:'rgb(50,50,50)'
    , corDoDialog:'rgb(255,255,255)'
    , corDoDialogTexto:'rgb(50,50,50)'
    , corDoCard: 'rgb(245,245,245)'
    , corDoCardTexto: 'rgb(50,50,50)'
    , corDaEntrada:'rgb(255,255,255)'
    , corDaEntradaTexto:'rgb(50,50,50)'
    , corDaBarraNavegacao:'rgb(255,255,255)'
    , corDaBarraNavegacaoTexto: 'rgb(0, 146, 69)'
    , corBarraTopo:'linear-gradient(to right,rgb(1,170,131),rgb(3,73,133),rgb(3,73,133))'
    , corBarraTopoTexto: 'rgb(255,255,255)'
    , corLinksDoCard: 'rgb(119, 119, 119)'
}

const estadoInicial: mobiAppReducerTipo  = {
    logoEmpresa: ''
    , valorLinkParametro: 'exemplo_empresa'
    , nomeExibicao: 'Padrao'
    , ...mobiAppCoresEstadoInicial
    , habilitarMensagemLojaFechada: false
    , mostrarMensagemContatoEmpresa: false
    , obrigaDataEntrega: false
    , vendaMobiAppEncerrada: false
    , versaoLogoEmpresa: ''
};

// @ts-ignore
const acoesNomeadas: {[x: string]: (carga: any, estadoAtual?: mobiAppReducerTipo) => mobiAppReducerTipo} = {
    [tiposDeAcoes.ATUALIZAR_MOBI_APP]: (carga: any, estadoAtual?) => {
        if (!carga) {
            return estadoInicial;
        }

        for(const id in carga) {
            if (nullOrUndefined(carga[id])) {
                delete carga[id];
            }
        }

        const novoEstado: mobiAppReducerTipo = {
            ...(estadoAtual || {})
            , ...carga
        };

        return novoEstado;
    }
    , [tiposDeAcoes.LIMPAR_MOBI_APP]: () => {
        return estadoInicial;
    }
    , [tiposDeAcoes.RESETAR_MOBI_APP]: (_: any, estadoAtual?: mobiAppReducerTipo) => {
        return {
            ...estadoAtual,
            ...mobiAppCoresEstadoInicial,
        };
    }
};

export default (estado: mobiAppReducerTipo = estadoInicial, acao: acaoTipo) => 
    criarReducer(estado, acao, acoesNomeadas);
