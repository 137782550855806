
import React from "react"
import { connect } from "react-redux"

import * as AuthUtils from "../../../utils/AuthUtils"
import { isEmpresaRestauranteAKilo } from "../../../utils/CompanyUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { goBack } from "../../../utils/NavigatorUtils"

import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"

import Icones from "../../../assets/icones/Icones"
// import MdKeyboard from "react-icons/lib/md/keyboard"
import BuildButton from "../../UI/Toolbar/BarraAcoesButton"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

/**
 * Classe que realiza a montagem da barra de ações para as visualizações de cadastros.
 */
class BarraAcoesOrigemVenda extends React.Component {

  /**
   * Método que monta o componente.
   */
  render() {
    const FaQrcode = Icones.fa.FaQrcode
    const GoLaw = Icones.go.GoLaw
    const MdQueue = Icones.md.MdQueue
    const MdAttachMoney = Icones.md.MdAttachMoney

    // Monta o componente
    return <WidthAwareDiv id="toolbar" className="toolbar">

      <div className="toolbar-content">

        <div className="bar-container" >

          {/* Botão Voltar */}
          <BuildButton key="back" isPrimary={true} id="backButton" commandMethod={() => goBack()} label={"\u003c"} title={getStrings().back} buttonGridClassName="bar-item" />

          {/* Botão Calculadora react-icons/lib/md/grid-on react-icons/lib/md/keyboard */}
          {/* <BuildButton key="calc" isPrimary={false} isDisabled={false} label={<MdKeyboard />} buttonGridClassName="bar-item"
                                commandMethod={() => {
                                    var shell = new ActiveXObject("WScript.shell")
                                    shell.run("NOTEPAD.EXE", 3)
                                }} /> */}

          {/* Botão leitor código de barras ou QR */}
          {(!isEmpresaRestauranteAKilo(this.props.ramoEmpresa)) ? "" : <BuildButton key="prev" className={`toolbar-button`} isPrimary={false} id="prevButton" isDisabled={(!isEmpresaRestauranteAKilo(this.props.ramoEmpresa))}
            label={<FaQrcode />} title={getStrings().qrRead} buttonGridClassName="bar-item" commandMethod={() => this.props.getBarQRCodeToBeginNewSale()} />}

          {/* Botão Novo a partir de balança */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_ENVIO])
            ? <BuildButton
              key="addFromScales"
              id="add-from-scales-button"
              isPrimary={false}
              label={<GoLaw />}
              title={getStrings().newSaleFromScales}
              buttonGridClassName="bar-item"
              commandMethod={this.props.orderUsingScales}
              ref={ref => this.botaoBalanca = ref} />
            : ""}

          {/* Botão Pagamento */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_PAGAMENTO]) ?
            <BuildButton key="pay" className={`toolbar-button`} id="payButton" isPrimary={false} isDisabled={this.props.payDisabled} commandMethod={this.props.showPayOrigemVendaDialog}
              label={<MdAttachMoney />} title={getStrings().paySales} buttonGridClassName="bar-item" /> : ""}

          {/* Botão Novo */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_ENVIO]) ?
            <BuildButton key="add" id="add-button" isPrimary={true} isDisabled={this.props.novoDisabled} label={<MdQueue />} title={getStrings().newSale} buttonGridClassName="bar-item"
              commandMethod={this.props.loadOrigensLivres} ref={ref => this.botaoNovo = ref} /> : ""}

        </div>
      </div>
    </WidthAwareDiv>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  getBarQRCodeToBeginNewSale: () => dispatch(controleVendaActions.getBarQRCodeToBeginNewSale()),
  loadOrigensEmUso: () => dispatch(controleVendaActions.loadOrigensEmUso()),
  loadOrigensLivres: () => dispatch(controleVendaActions.loadOrigensLivres()),
  orderUsingScales: () => dispatch(controleVendaActions.orderUsingScales()),
  showPayOrigemVendaDialog: () => dispatch(controleVendaActions.showPayOrigemVendaDialog()),
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(BarraAcoesOrigemVenda)
