import { GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_CLOSED } from '../store/reducers/controleVenda/manutencao/manutencaoVendaReducer';
import { log } from './LogUtils';
import { getAuthStorage, getCargoStorage } from './StorageUtils/SessionStorageUtils';
import { getGridGrupoProdutoDisplayStorage } from './StorageUtils/LocalStorageUtils';
import { isNumber, isNonEmptyArray, isStrictObject, isString, nullOrUndefined } from './ComparatorsUtils';
import { urlDatabase } from './SecureConnectionUtils';

/** Método que busca o Token da Aplicação no Storage. */
export const getAppToken = () => {
    log('utils getAppToken');
    const auth = getAuthStorage();

    return auth && isString(auth.t) ? auth.t : null;
}

/** Método que busca a Expiração do Token da Aplicação no Storage. */
export const getAppTokenExpiration = () => {
    log('utils getAppTokenExpiration');
    const auth = getAuthStorage();

    return auth && isString(auth.e) ? auth.e : null;
}

/** Método que busca o Usuário da Aplicação no Storage. */
export const getAppUsuario = () => {
    log('utils getAppUsuario');
    const auth = getAuthStorage();

    return auth && isString(auth.u) ? auth.u : null;
}

/**
 * Retorna o URI do Usuário da Aplicação no Storage.
 */
export const getAppUsuarioURI = () => {
    log('utils getAppUsuarioURI');
    return urlDatabase + '/usuarios/' + getAppUsuario();
}

/** Método que busca o Cargo da Aplicação no Storage. */
export const getAppCargo = () => {
    log('utils getAppCargo');
    const cargo = getCargoStorage();

    return cargo && isString(cargo.c) ? cargo.c : null;
}

/** Método que busca a Empresa do Cargo da Aplicação no Storage. */
export const getAppEmpresa = () => {
    log('utils getAppEmpresa');
    const cargo = getCargoStorage();

    return cargo && isString(cargo.e) ? cargo.e : null;
}

/** Método que busca as Permissões do Cargo da Aplicação no Storage. */
export const getAppPermissoesCargo = () => {
    const cargo = getCargoStorage();

    return cargo && isNonEmptyArray(cargo.p) ? cargo.p : null;
}

/** Método que busca o Nome Fantasia da Empresa do Cargo da Aplicação no Storage. */
export const getAppNomeEmpresa = () => {
    log('utils getAppNomeEmpresa');
    const cargo = getCargoStorage();

    return cargo && isString(cargo.n) ? cargo.n : '';
}

/** Método que busca o ramo da Empresa do Cargo da Aplicação no Storage. */
export const getAppRamoEmpresa = () => {
    log('utils getAppRamoEmpresa');
    const cargo = getCargoStorage();

    return cargo && isString(cargo.r) ? cargo.r : '';
}

/** Método que busca a lista de códigos de barras ou QR das Origens de Venda da Empresa do Cargo da Aplicação no Storage. */
export const getAppOrdemFormatoCodigoBarras = () => {
    log('utils getAppOrdemFormatoCodigoBarras');
    const cargo = getCargoStorage();

    return cargo && isNonEmptyArray(cargo.ean) ? cargo.ean : [];
}

/** Método que busca o parâmetro de preço da refeição livre da Empresa do Cargo da Aplicação no Storage. */
export const getAppPerguntaCpf = () => {
    log('utils getAppPerguntaCpf');
    const cargo = getCargoStorage();

    log('utils getAppPerguntaCpf ' + cargo)
    return (cargo || {}).i || false;
}

/** Método que busca o parâmetro de preço da refeição livre da Empresa do Cargo da Aplicação no Storage. */
export const getAppPrecoLivre = () => {
    log('utils getAppPrecoLivre');
    const cargo = getCargoStorage();

    log('utils getAppPrecoLivre ' + cargo)
    return cargo && isNumber(cargo.l) ? cargo.l : 0;
}

/** Método que busca o Tamanho Padrão da Paginação do Usuário da Aplicação no Storage. */
export const getAppNomeUsuario = () => {
    log('utils getAppNomeUsuario');
    const cargo = getAuthStorage();

    return cargo && isStrictObject(cargo.nomeUsuario) ? cargo.nomeUsuario : { nome: '', sobrenome: '' };
}

/**
 * Retorna do storage se o usuário logado possui possui vínculo com empresa(s)
 */
export const getAppQuantidadeCargos = () => {
    log('utils getAppQuantidadeCargos');
    const auth = getAuthStorage();

    return auth && isNumber(auth.c) ? auth.c : null;
}

/** Método que busca o Tamanho Padrão da Paginação do Usuário da Aplicação no Storage. */
export const getAppTamanhoPadraoPaginacao = () => {
    log('utils getAppTamanhoPadraoPaginacao');
    const auth = getAuthStorage();

    return auth && auth.tamanhoPadraoPaginacao ? auth.tamanhoPadraoPaginacao : 10;
}

/** Método que busca a exibição de produtos por grupo da Aplicação no Storage. */
export const getAppGridGrupoProdutoDisplay = () => {
    log('utils getAppGridGrupoProdutoDisplay');
    const gridGrupoProdutoDisplay = getGridGrupoProdutoDisplayStorage();

    return gridGrupoProdutoDisplay ? gridGrupoProdutoDisplay : GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_CLOSED;
}

/**
 * 
 * @param {'a'|'c'} key 
 */
function mapStorageItem(key) {
    const props = {
        'a': getAuthStorage(),
        'c': getCargoStorage(),
    }

    return props[key]
}

/**
 * Método que atualiza uma propriedade do usuário ou cargo logado
 * @param {String} key nome da propriedade
 * @param {*} value valor da propriedade
 * @param {'a'|'c'} storageSource se será atualizada uma propriedade do usuário ou do cargo
 */
export const updateAppProperty = (key, value, storageSource) => {
    log('utils updateAppProperty', { key, value, storageSource });
    // Busca no Storage os dados referentes a Autorização (a) ou Cargo (c)
    const storage = () => mapStorageItem(storageSource)

    // Converte a String para JSON
    const object = storage();

    if (!(object) || nullOrUndefined(key)) {
        return false;
    }

    // Atualiza o valor do atributo
    object[key] = value;

    // Armazena novamente os dados no Storage
    sessionStorage.setItem(storageSource, JSON.stringify(object));
    return true;
}

/**
 * Método que atualiza uma propriedade do usuário logado
 * @param {String} key nome da propriedade
 * @param {*} value valor da propriedade
 */
export const updateAppAuthProperty = (key, value) => {
    log('utils updateAppProperty', { key, value });
    return updateAppProperty(key, value, 'a');
}

/**
 * Método que atualiza uma propriedade do cargo logado
 * @param {String} key nome da propriedade
 * @param {*} value valor da propriedade
 */
export const updateAppCargoProperty = (key, value) => {
    log('utils updateAppProperty', { key, value });
    return updateAppProperty(key, value, 'c');
}

/**
 * Método que atualiza a variável do Storage que indica tamanho padrão de paginação do usuário.
 * @param {Object} nomeUsuario
 */
export const updateAppNomeUsuario = nomeUsuario => updateAppAuthProperty('nomeUsuario', nomeUsuario);

/**
 * Método que atualiza a variável do Storage que indica tamanho padrão de paginação do usuário.
 * @param {Array} permissoesCargo
 */
export const updateAppPermissoesCargo = permissoesCargo => updateAppCargoProperty('p', permissoesCargo);

/**
 * Método que atualiza a variável do Storage que indica se usuário logado possui vínculo com empresa(s).
 * @param {number} quantidadeCargos
 */
export const updateAppQuantidadeCargos = quantidadeCargos => updateAppAuthProperty('c', quantidadeCargos);

/**
 * Método que atualiza a variável do Storage que indica tamanho padrão de paginação do usuário.
 * @param {number} tamanhoPadraoPaginacao
 */
export const updateAppTamanhoPadraoPaginacao = tamanhoPadraoPaginacao => updateAppAuthProperty('tamanhoPadraoPaginacao', tamanhoPadraoPaginacao);

/**
 * Método que atualiza o Token do Storage com o novo Token recebido como parâmetro.
 * @param {String} token
 */
export const updateAppToken = token => updateAppAuthProperty('t', token);

/**
 * Método que atualiza a exibição de produtos por grupo da aplicação no storage.
 * @param {String} gridGrupoProdutoDisplay
 */
export const updateAppGridGrupoProdutoDisplay = gridGrupoProdutoDisplay => {
    log('utils updateAppGridGrupoProdutoDisplay', { gridGrupoProdutoDisplay });

    // Se foi passado um truthy
    if (gridGrupoProdutoDisplay) {
        // Se não for uma String, não sei se é para setar ou para limpar.
        if (!isString(gridGrupoProdutoDisplay)) {
            // Faz mais nada
            log('utils updateAppGridGrupoProdutoDisplay', 'error', { gridGrupoProdutoDisplay });
            return null;
        }

        // Armazena novamente os dados no Storage
        localStorage.setItem('gridGrupoProdutoDisplay', JSON.stringify(gridGrupoProdutoDisplay));
        return true;
    }

    // Remove storage se valor for nulo ou undefined
    localStorage.removeItem('gridGrupoProdutoDisplay');
    return false;
}