import React from 'react';
import { connect } from 'react-redux';

import { updateActiveMenuItem } from '../../../utils/ScreenUtils';
import { urlDatabase } from '../../../utils/SecureConnectionUtils';

import CadastroBox from './CadastroBox';
import OrigemVendaTable from '../table/OrigemVendaTable';
import OrigemVendaForm from '../form/OrigemVendaForm';

/**
 * JS que define os componentes, tabela e formulário, utilizados no cadastro das Origens de Venda.
 * Também define a URL para acessar os dados deste tipo de objeto.
 */
class OrigemVendaBox extends React.Component {

    constructor() {
        super();

        // Recupera e ajusta a URL para acesso aos dados
        this.urlDataBase = urlDatabase + '/origemVendas';
    }

    /**
     * Método executado APÓS a montagem/renderização do componente.
     */
    componentDidMount() {

        updateActiveMenuItem('menuItemOrigemVenda', 'menuItemCadastro');
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        return (
            <CadastroBox cadastroTable={OrigemVendaTable} cadastroForm={OrigemVendaForm} urlDataBase={this.urlDataBase}
                // Se é necessário buscar uma lista de entidades do banco de dados ao editar este cadastro
                needFormEntities={true}
                // Se é necessário buscar mais dados deste cadastro do banco de dados ao editar este cadastro
                needFormUpdate={true} />
        );
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    ...state.idiomaReducer
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(OrigemVendaBox);
