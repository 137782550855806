import { push } from "connected-react-router"

import { isString } from "./ComparatorsUtils"
import { getStrings } from "./LocaleUtils"
import { log } from "./LogUtils"
import { nullOrUndefined } from "./ComparatorsUtils"
import { removeAuthStorage, removeCargoStorage } from "./StorageUtils/SessionStorageUtils"

import * as actionTypes from "../store/actions/actionTypes"
import { appNotificationShow } from "../store/actions/appAction"
import * as authActions from "../store/actions/authAction"

/**
 * Método padrão utilizado para tratamento de retorno com erro do BackEnd. 
 * Apresenta uma notificação de erro e força o Logout, caso o Status retornado seja 403 (Forbidden).
 * @param {*} error 
 */
export const requestErrorHandlerDefault = error => dispatch => {
    log("ErrorUtils requestErrorHandlerDefault", { error })

    // Chama o método que monta e apresenta a notificação
    dispatch(requestErrorNotificationShow(error))

    // Chama o método que verifica se o Status do erro é 403 (Forbidden) e força o logout
    dispatch(verifyForbiddenAccess(error))
}

/**
 * Método utilizado para tratamento de erro nas ações de Autenticação. 
 * @param {*} error 
 */
export const authErrorHandler = error => dispatch => {
    log('ErrorUtils authErrorHandler', { error });

    // Chama o método que monta e apresenta a notificação
    dispatch(requestErrorNotificationShow(error));

    // Limpa o SessionStorage
    removeAuthStorage();
    removeCargoStorage();
}

/**
 * Método que verifica, a partir do erro, se o Status é 403 (Forbidden) e força o logout. 
 * @param {*} error 
 */
export const verifyForbiddenAccess = error => dispatch => {
    log('ErrorUtils verifyForbiddenAccess', { error });

    if (error && error.response && (error.response.status === 403)) {
        dispatch(push('/home'));
        dispatch(authActions.logoutStart(true));
    }
};

/**
 * Método que monta e apresenta a notificação, a partir do erro ao tentar realizar um request no BackEnd. 
 * @param {*} error 
 */
export const requestErrorNotificationShow = error => dispatch => {
    log('ErrorUtils requestErrorNotificationShow', { error });

    // Chama o método que constrói o objeto com a mensagem a partir do erro
    const errorMessageObject = getErrorMessageObjectFromError(error);

    // Chama o método que apresenta a notificação do erro
    dispatch(appNotificationShow(errorMessageObject.message, errorMessageObject.level));
}

/**
 * Método que constrói o objeto com a mensagem a partir do erro. 
 * @param {*} error 
 */
export const getErrorMessageObjectFromError = error => {
    log('ErrorUtils getErrorMessageObjectFromError', { error });

    let message = '';
    let status = null;
    let level = actionTypes.APP_NOTIFICATION_TYPE_INFO;

    // Armazena o Status do Erro, caso houver
    if (error && error.response) {
        status = error.response.status;
    }

    // Armazena a Mensagem propriamente e o Nível (tipo) da mensagem
    if ((!error) || (('response' in error) && nullOrUndefined(error.response))) {
        // Trata erro ao tentar conectar ao servidor (a propriedade 'response' existe, mas possui valor 'undefined')
        // Em caso de timeout, 'error' pode ser 'undefined' ou não. No segundo caso, também existe 'response' 'undefined'.
        message = getStrings().communicationFailure;
        level = actionTypes.APP_NOTIFICATION_TYPE_ERROR;
    }
    else if (error && (!('response' in error))) {
        // Se o tratamento de erros do servidor causar um erro, deve ser usada a mensagem seguinte:
        message = getStrings().errorToPerformAction;
        level = actionTypes.APP_NOTIFICATION_TYPE_ERROR;
    }
    else if ((error.response.status === 400) && error.response.data && (typeof error.response.data === 'string')) {
        log(error.response.data)
        // Bad Request (Provavelmente algum dado inválido)
        message = error.response.data;
        // Verifica se a mensagem retornada é um código conhecido
        if (message in getStrings()) {
            // Busca a string internacionalizada correspondente ao código
            message = getStrings()[message];
            // String pode na verdade ser uma função
            if (!isString(message)) {
                // Se não for string, trata como função, a executa e armazena o seu resultado
                message = message();
            }
            level = actionTypes.APP_NOTIFICATION_TYPE_WARNING;
        }
        else {
            level = actionTypes.APP_NOTIFICATION_TYPE_ERROR;
        }
    }
    else if ((error.response.status === 400) && error.response.data && error.response.data._links && error.response.data._links.self && error.response.data._links.self.href && (error.response.data._links.self.href.indexOf('/cargos') > -1)) {
        // Quando um cargo é inserido ou alterado, se ocorre algum erro com os seus usuários, o cargo retorna com o motivo do erro em cada usuário.
        // Exibir mensagem para verificar os usuários.
        message = getStrings().roleUserWarning;
        level = actionTypes.APP_NOTIFICATION_TYPE_WARNING;
    }
    else if (error.response.status === 404) {
        // objeto não existe no servidor/url inválido
        message = getStrings().registerNotFound;
        level = actionTypes.APP_NOTIFICATION_TYPE_ERROR;
    }
    else if (error.response.status === 403) {
        // Forbidden (Acesso negado)
        switch (error.response.data) {
            case 'tokenOverride':
                message = getStrings().tokenOverride;
                break;
            default:
                message = getStrings().accessDenied;
        }
        level = actionTypes.APP_NOTIFICATION_TYPE_WARNING;
    }
    else if (error.response.status === 409 && error.response.data) {
        // Conflito. Usuário deveria ser capaz de resolver por conta própria.
        var data = error.response.data;
        var cause = null;

        while (data.cause) {
            cause = data = data.cause;
        }

        if ((cause && cause.message && cause.message.includes('still reference')) || (data === 'still reference')) {
            message = getStrings().registerInUse;
            level = actionTypes.APP_NOTIFICATION_TYPE_WARNING;
        }
    }
    else if (error.response.status === 500) {
        // Internal Server Error

        // Também disparado pelo Hibernate por erros de restrições
        if (error.response.data && error.response.data.message && error.response.data.message.includes('constraint')) {

            message = getStrings().registerInUse;
            level = actionTypes.APP_NOTIFICATION_TYPE_WARNING;

        } else {
            message = getStrings().serverError;
            level = actionTypes.APP_NOTIFICATION_TYPE_ERROR;
        }
    }
    else {
        message = getStrings().errorToPerformAction;
        level = actionTypes.APP_NOTIFICATION_TYPE_ERROR;
    }

    return {
        message,
        status,
        level
    };
}