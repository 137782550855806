import criarReducer, { acaoTipo } from "../../factory/criarReducer";
import tiposDeAcoes from "./botaoVoltarActions";

export type botaoVoltarReducerTipo = {
    botaoVoltarCustomizado?: (parameter: any) => void | undefined
};

const estadoInicial: botaoVoltarReducerTipo = {
    botaoVoltarCustomizado: undefined
};

const acoesNomeadas: {[key:string]: (carga: any, estadoAtual?: botaoVoltarReducerTipo) => botaoVoltarReducerTipo} = {
    [tiposDeAcoes.LIMPAR_BOTAO_VOLTAR]: () => {
        const novoEstado = estadoInicial;

        return novoEstado;
    },
    [tiposDeAcoes.DEFINIR_BOTAO_VOLTAR]: (carga: botaoVoltarReducerTipo) => {
        const novoEstado: botaoVoltarReducerTipo = {
            ...carga
        };

        return novoEstado;
    },
};

export default function(estado: botaoVoltarReducerTipo = estadoInicial, carga: acaoTipo) {
    return criarReducer(estado, carga, acoesNomeadas);
}
