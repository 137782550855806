import React from "react"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"
import { formatNumber } from "../../../utils/NumberUtils"

/**
 * Item inserido entre os itens de venda para identificar e separar as vendas
 * e exibir totais de cada venda.
 */
export default class BuildCellFromQuebraVendaTotal extends React.Component {

  render() {
    log("BuildCellFromQuebraVendaTotal render")

    if (!isAncientChromeMobile) {
      return <div className="table-pagamento table-footer build-cell-from-quebra-venda-total" >
        <div className="table-pagamento table-row without-discount" >
          <div className="price NumberAlign">
            {getStrings().payTotal}
          </div>
          <div className="pay NumberAlign">{
            formatNumber((this.props.new && (!this.props.hasNewSalePaymentItemToSend))
              ? this.props.productTotalRemaining
              : this.props.productTotal, 2)
          }</div>
          <div className="total NumberAlign">{
            formatNumber((this.props.new && (!this.props.hasNewSalePaymentItemToSend))
              ? this.props.productWithCommissionTotalRemaining
              : this.props.productWithCommissionTotal, 2)
          }</div>
        </div>
        <div className="table-pagamento table-row with-discount" >
          <div className="product NumberAlign">
            {getStrings().discountedTotalPerPerson}
          </div>
          <div className="amount NumberAlign">{
            formatNumber((this.props.new && (!this.props.hasNewSalePaymentItemToSend))
              ? this.props.productWithCommissionWithDiscountByPersonTotalRemaining
              : this.props.productWithCommissionWithDiscountByPersonTotal, 2)
          }</div>
          <div className="pricePlusPay NumberAlign">
            {getStrings().discountedTotal}
          </div>
          <div className="total NumberAlign"> {
            formatNumber((this.props.new && (!this.props.hasNewSalePaymentItemToSend))
              ? this.props.productWithCommissionWithDiscountTotalRemaining
              : this.props.productWithCommissionWithDiscountTotal, 2)
          }</div>
        </div>
        {/* <div className="table-pagamento table-row with-discount" >
                    <div className="product NumberAlign">
                        {getStrings().coupon}
                    </div>
                    <div className="amount NumberAlign">{
                        formatNumber(this.props.valorCupom, 2)
                    }</div>
                    <div className="pricePlusPay NumberAlign">
                        {getStrings().deliveryLabel}
                    </div>
                    <div className="total NumberAlign"> {
                        formatNumber(this.props.valorTeleEntrega, 2)
                    }</div>
                </div> */}
      </div>
    }

    return <div className="table-pagamento table-footer build-cell-from-quebra-venda-total ancient-chrome-mobile" >
      <div className="table-pagamento table-row without-discount" >
        <div className="row">
          <div className="column left" />
          <div className="column right">
            <div className="row">
              <div className="price NumberAlign">
                {getStrings().payTotal}
              </div>
              <div className="pay NumberAlign">{
                formatNumber((this.props.new && (!this.props.hasNewSalePaymentItemToSend))
                  ? this.props.productTotalRemaining
                  : this.props.productTotal, 2)
              }</div>
              <div className="total NumberAlign">{
                formatNumber((this.props.new && (!this.props.hasNewSalePaymentItemToSend))
                  ? this.props.productWithCommissionTotalRemaining
                  : this.props.productWithCommissionTotal, 2)
              }</div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-pagamento table-row with-discount" >
        <div className="row">
          <div className="column left" />
          <div className="column right">
            <div className="row">
              <div className="product NumberAlign">
                {getStrings().discountedTotalPerPerson}
              </div>
              <div className="amount NumberAlign">{
                formatNumber((this.props.new && (!this.props.hasNewSalePaymentItemToSend))
                  ? this.props.productWithCommissionWithDiscountByPersonTotalRemaining
                  : this.props.productWithCommissionWithDiscountByPersonTotal, 2)
              }</div>
            </div>
          </div>
          <div className="column right">
            <div className="row">
              <div className="pricePlusPay NumberAlign">
                {getStrings().discountedTotal}
              </div>
              <div className="total NumberAlign"> {
                formatNumber((this.props.new && (!this.props.hasNewSalePaymentItemToSend))
                  ? this.props.productWithCommissionWithDiscountTotalRemaining
                  : this.props.productWithCommissionWithDiscountTotal, 2)
              }</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}
