import React from 'react'
import { getStrings } from '../LocaleUtils'

export function getContatoEmTexto({contatoEmpresa}: {contatoEmpresa?: {telefone: string, email: string, nomeContato: string}} = {}) {
  if (!contatoEmpresa) {
    return null
  }

  const { telefone, email, nomeContato } = contatoEmpresa
  
  return <>
    {`${getStrings().name}: ${nomeContato}`}
    <br/>{`${getStrings().email}: ${email}`}
    <br/>{`${getStrings().phoneNumber}: ${telefone}`}
  </>
}