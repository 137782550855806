import React from "react"
import { connect } from "react-redux"

import { log } from "../../../utils/LogUtils"
import { filterObject } from "../../../utils/ObjectUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import { isSaleProductKeyFromSale } from "../../../store/reducers/controleVenda/controleVendaReducer"

import VendaTable from "./VendaTable"

class AbstractOrigemVendaTable extends React.Component {

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("AbstractOrigemVendaTable render")

    return ((this.props.origemVenda || {}).vendaList || [])
      // Para os itens novos, pula os que já foram totalmente pagos.
      .filter(venda => (!this.props.new) || (this.props.productTotalRemainingMap || {})[getURIFromEntity(venda)])
      .map(venda => {

        let vendaURI = getURIFromEntity(venda)

        return <VendaTable
          hasNewSalePaymentItemToSend={this.props.hasNewSalePaymentItemToSend}
          key={vendaURI}
          new={this.props.new}
          origemVenda={this.props.origemVenda}
          productTotal={(this.props.productTotalMap || {})[vendaURI]}
          productWithCommissionTotal={(this.props.productWithCommissionTotalMap || {})[vendaURI]}
          productWithCommissionWithDiscountByPersonTotal={(this.props.productWithCommissionWithDiscountByPersonTotalMap || {})[vendaURI]}
          productWithCommissionWithDiscountTotal={(this.props.productWithCommissionWithDiscountTotalMap || {})[vendaURI]}
          productTotalRemaining={(this.props.productTotalRemainingMap || {})[vendaURI]}
          productWithCommissionTotalRemaining={(this.props.productWithCommissionTotalRemainingMap || {})[vendaURI]}
          productWithCommissionWithDiscountByPersonTotalRemaining={(this.props.productWithCommissionWithDiscountByPersonTotalRemainingMap || {})[vendaURI]}
          productWithCommissionWithDiscountTotalRemaining={(this.props.productWithCommissionWithDiscountTotalRemainingMap || {})[vendaURI]}
          remainingAmountMap={filterObject(this.props.remainingAmountMap || {}, key => isSaleProductKeyFromSale(key, vendaURI))}
          salePaymentItemMap={filterObject(this.props.salePaymentItemMap || {}, key => isSaleProductKeyFromSale(key, vendaURI))}
          venda={venda}
        />
      })
  }
}

/**
 * Evita de ficar vinculado às duas listas do *reducer* para evitar execuções desnecessárias quando uma das listas atualizar.
 * Ao invés disso, ou retorna um componente vinculado a uma das listas ou retorna outro componente vinculado à outra lista.
 */
export default class OrigemVendaTable extends React.Component {

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("OrigemVendaTable render")

    return this.props.new
      ? <OrigemVendaTableNewItemList
        new={true}
        origemVenda={this.props.origemVenda}
      />
      : <OrigemVendaTableSentItemList
        new={false}
        origemVenda={this.props.origemVenda}
      />
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToPropsNewItemList = state => ({

  hasNewSalePaymentItemToSend: state.controleVendaReducer.hasNewSalePaymentItemToSend,
  productTotalMap: state.controleVendaReducer.newProductTotalMap,
  productWithCommissionTotalMap: state.controleVendaReducer.newProductWithCommissionTotalMap,
  productWithCommissionWithDiscountByPersonTotalMap: state.controleVendaReducer.newProductWithCommissionWithDiscountByPersonTotalMap,
  productWithCommissionWithDiscountTotalMap: state.controleVendaReducer.newProductWithCommissionWithDiscountTotalMap,
  productTotalRemainingMap: state.controleVendaReducer.newProductTotalRemainingMap,
  productWithCommissionTotalRemainingMap: state.controleVendaReducer.newProductWithCommissionTotalRemainingMap,
  productWithCommissionWithDiscountByPersonTotalRemainingMap: state.controleVendaReducer.newProductWithCommissionWithDiscountByPersonTotalRemainingMap,
  productWithCommissionWithDiscountTotalRemainingMap: state.controleVendaReducer.newProductWithCommissionWithDiscountTotalRemainingMap,
  remainingAmountMap: state.controleVendaReducer.remainingAmountMap,
  salePaymentItemMap: state.controleVendaReducer.newSalePaymentItemMap,
  tabIndex: state.appReducer.getTabIndex(),
  ...state.idiomaReducer,
})

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToPropsSentItemList = state => ({

  // hasNewSalePaymentItemToSend: state.controleVendaReducer.hasNewSalePaymentItemToSend,
  productTotalMap: state.controleVendaReducer.sentProductTotalMap,
  productWithCommissionTotalMap: state.controleVendaReducer.sentProductWithCommissionTotalMap,
  productWithCommissionWithDiscountByPersonTotalMap: state.controleVendaReducer.sentProductWithCommissionWithDiscountByPersonTotalMap,
  productWithCommissionWithDiscountTotalMap: state.controleVendaReducer.sentProductWithCommissionWithDiscountTotalMap,
  remainingAmountMap: state.controleVendaReducer.remainingAmountMap,
  salePaymentItemMap: state.controleVendaReducer.sentSalePaymentItemMap,
  tabIndex: state.appReducer.getTabIndex(),
  ...state.idiomaReducer,
})

let OrigemVendaTableNewItemList = connect(mapStateToPropsNewItemList)(AbstractOrigemVendaTable)
let OrigemVendaTableSentItemList = connect(mapStateToPropsSentItemList)(AbstractOrigemVendaTable)
