import React from 'react'

import { connect } from 'react-redux';
import { setLayoutIdAction } from '../../../store/actions/placeholderMessageAction';
import { updateFullScreenAction } from '../../../store/actions/appAction';

class ShowClosedStoreMessage extends React.Component {
    componentDidMount() {
        this.props.setLayoutId(((this.props.location || {}).state || {}).layoutId);
        this.props.updateFullScreen(true);
    }

    componentWillUnmount() {
        this.props.updateFullScreen(false);
    }

    render() {
        return this.props[this.props.layoutId] ?
            <div className={`no-user-select`} unselectable={`on`} style={{...this.props[this.props.layoutId].style, ...this.props.fullScreenStyle}}>
                <h1>{this.props[this.props.layoutId].text.value}</h1>
            </div>
            : null;
    }
}

const mapStateToProps = state => ({
    layoutId:        state.placeholderMessageReducer.layoutId,
    fullScreenStyle: state.appReducer.fullScreenStyle.content,
                  ...state.placeholderMessageReducer.data    ,
});

const mapDispatchToProps = dispatch => ({
    setLayoutId:        (layoutId) => dispatch(setLayoutIdAction(layoutId)),
    updateFullScreen: (fullScreen) => dispatch(updateFullScreenAction(fullScreen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowClosedStoreMessage);
