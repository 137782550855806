import React, { useState, useEffect } from "react"
import moment, { Moment } from "moment"

import { TIME_INPUT } from "../../UI/dateTimeInputDefault/DateTimeInputDefault"
import InputCustomizado from "../../UI/Input/InputCustomizado"
import { getStrings } from "../../../utils/LocaleUtils"
import { diaDaSemanaHorarioTipo, diaDaSemanaSelecaoTipo, diaDaSemanaTipo, diaDaSemanaOuTodosTipo, DialogQuadroHorariosEdicaoTipo } from "../../../utils/QuadroHorariosUtils/quadroHorariosTypes"
import { timeInputOnBlur, timeInputOnChange } from "../../../utils/QuadroHorariosUtils/quadroHorariosUtils"

import "./DialogQuadroHorariosEdicao.css";

const diaDaSemanaOuTodos: diaDaSemanaOuTodosTipo[] = ['SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB', 'DOM', 'TODOS'];

const diasDaSemanaSelecionadosEstadoInicial = {
    SEG: false,
    TER: false,
    QUA: false,
    QUI: false,
    SEX: false,
    SAB: false,
    DOM: false,
    TODOS: false,
};

function DiaDaSemanaCheckbox({day, check, defaultChecked, update}: {day: diaDaSemanaOuTodosTipo, check: boolean, defaultChecked?: boolean, update: () => void }) {
    return <InputCustomizado
        key={`__day_of_week_${day}__`}
        newApi
        defaultChecked={defaultChecked}
        checked={check}
        name="dayOfWeek"
        type="checkbox"
        handleInputValidado={update}
        {...{
            label: `${getStrings().daysOfWeekLabel[day].reduced}`,
            placeholder: `${getStrings().daysOfWeekLabel[day].reduced}`
        }}
    />
}

function DialogQuadroHorariosEdicao({esconderDialog, salvarAlteracoes, diaDaSemanaSelecionado, horarioSelecionado}: DialogQuadroHorariosEdicaoTipo) {

    const [horaInicial, setHoraInicial] = useState<Moment | undefined>();
    const [horaFinal, setHoraFinal] = useState<Moment | undefined>();
    const [aviso, setAviso] = useState('');
    const [diaDaSemanaSelecao, setDiaDaSemanaSelecao] = useState<diaDaSemanaSelecaoTipo>(diasDaSemanaSelecionadosEstadoInicial);

    // useEffect(() => {
    //     const elemento = document.querySelector('.form-control') as HTMLInputElement;
    //     if (elemento) {
    //         if (horarioSelecionado) {
    //             elemento.select();
    //         }
    //         else {
    //             elemento.focus();
    //         }
    //     }
    // }, [horaInicial]);

    useEffect(() => {
        if (diaDaSemanaSelecionado) {
            setDiaDaSemanaSelecao({...diasDaSemanaSelecionadosEstadoInicial, [diaDaSemanaSelecionado]: true});
        }
        else {
            setDiaDaSemanaSelecao({...diasDaSemanaSelecionadosEstadoInicial});
        }
        if (horarioSelecionado && diaDaSemanaSelecionado) {
            setHoraInicial(moment(horarioSelecionado.horaInicial, 'HH:mm'));
            setHoraFinal(moment(horarioSelecionado.horaFinal, 'HH:mm'));
        }
        else {
            setHoraInicial(undefined);
            setHoraFinal(undefined);
        }
        setAviso('');
    }, [diaDaSemanaSelecionado, horarioSelecionado]);

    function updateDiaDaSemanaSelecao(diaDaSemanaSelecionado: Partial<diaDaSemanaSelecaoTipo>) {

        if (diaDaSemanaSelecionado.TODOS !== undefined) {
            const novoEstado = {...diaDaSemanaSelecao};

            (Object.keys(diaDaSemanaSelecao) as diaDaSemanaOuTodosTipo[]).forEach(key => {
                novoEstado[key] = (diaDaSemanaSelecionado.TODOS as boolean);
            });

            setDiaDaSemanaSelecao(novoEstado);
        }
        else {
            const novoEstado = {
                ...diaDaSemanaSelecao,
                ...diaDaSemanaSelecionado,
            };

            const todos = Object.entries(novoEstado).every(([key, value]) =>  key === 'TODOS' || value);

            setDiaDaSemanaSelecao({...novoEstado, TODOS: todos});
        }
    }

    function fecharTela() {
        if (!diaDaSemanaSelecionado) {
            setDiaDaSemanaSelecao({...diasDaSemanaSelecionadosEstadoInicial});
        }
        if (!horarioSelecionado) {
            setHoraInicial(undefined);
            setHoraFinal(undefined);
        }
        setAviso('');
        esconderDialog();
    }

    return <div key={`__timesheet-editor-dialog__`} id="dialog-quadro-horarios-edicao">
        {diaDaSemanaSelecionado && horarioSelecionado
            ? <div className="dia-da-semana-selecionado">
                {getStrings().daysOfWeekLabel[diaDaSemanaSelecionado].extended}
            </div>
            : <div className="dias-da-semana">
                {diaDaSemanaOuTodos.map((diaDaSemana, index) =>
                    <DiaDaSemanaCheckbox
                        key={`__dia_semana_checkbox_${index}__`}
                        day={diaDaSemana}
                        check={diaDaSemanaSelecao[diaDaSemana]}
                        update={() => updateDiaDaSemanaSelecao({ [diaDaSemana]: !diaDaSemanaSelecao[diaDaSemana] })}
                    />
                )}
            </div>
        }
        <div className="horarios">
            <div className="horarios-item">
                <span>{getStrings().from}</span>
                <InputCustomizado
                    newApi
                    type="date"
                    inputType={TIME_INPUT}
                    value={horaInicial}
                    placeholder={getStrings().timeStart} 
                    onChange={value => timeInputOnChange(value, setHoraInicial)}
                    onBlur={value => timeInputOnBlur(value, horaInicial, setHoraInicial)}
                    onClear={() => setHoraInicial(undefined)}
                />
            </div>
            <div className="horarios-item">
                <span>{getStrings().to}</span>
                <InputCustomizado
                    newApi
                    type="date"
                    inputType={TIME_INPUT}
                    value={horaFinal}
                    placeholder={getStrings().timeEnd} 
                    onChange={value => timeInputOnChange(value, setHoraFinal)}
                    onBlur={value => timeInputOnBlur(value, horaFinal, setHoraFinal)}
                    onClear={() => setHoraFinal(undefined)}
                />
            </div>
        </div>
        {aviso
            ? <div className="aviso">{aviso}</div>
            : null
        }
        <div className="botoes">
            <button
                className="button-primary"
                style={{marginLeft: '2em'}}
                onClick={() => fecharTela()}>
                    {getStrings().cancel}
            </button>
            <button
                className="button-primary"
                onClick={() => {
                    const {TODOS, ...diasDaSemana} = diaDaSemanaSelecao;
                    const diaDaSemanaSelecionados = (Object.keys(diasDaSemana) as diaDaSemanaTipo[]).filter(key => diasDaSemana[key]);

                    if (!diaDaSemanaSelecionados.length) {
                        setAviso(getStrings().dayOfWeekNotSelected);
                        return;
                    }    
                    if (!horaInicial) {
                        setAviso(getStrings().startTimeNotInformed);
                        return;
                    }
                    if (!horaFinal) {
                        setAviso(getStrings().endTimeNotInformed);
                        return;
                    }
                    if (horaInicial.isSameOrAfter(horaFinal)) {
                        setAviso(getStrings().endTimeSameOrBeforeStartTime);
                        return;
                    }

                    const horariosSalvar: diaDaSemanaHorarioTipo = {};

                    for (const diaDaSemana of diaDaSemanaSelecionados) {
                        const now = Date.now()
                        const horaInicialString = (horaInicial || moment.utc(now)).format('HH:mm');
                        const horaFinalString = (horaFinal || moment.utc(now)).format('HH:mm');

                        horariosSalvar[diaDaSemana] = {horaInicial: horaInicialString, horaFinal: horaFinalString};
                    }

                    let horarioApagar;

                    if (diaDaSemanaSelecionado && horarioSelecionado) {
                        horarioApagar = {[diaDaSemanaSelecionado]: {...horarioSelecionado}};
                    }

                    salvarAlteracoes(horariosSalvar, horarioApagar);
                    fecharTela();
                }}
            >
                {horarioSelecionado ? getStrings().change : getStrings().add}
            </button>
            {diaDaSemanaSelecionado && horarioSelecionado
                ? <button
                    className="button-primary"
                    style={{backgroundColor: 'rgb(225, 20, 20)'}}
                    onClick={() => {
                        const horarioApagar = {[diaDaSemanaSelecionado]: {...horarioSelecionado}};
                        salvarAlteracoes(undefined, horarioApagar);
                        fecharTela();
                    }}>
                    {getStrings().delete}
                </button>
                : null
            }
        </div>
    </div>
}

export default DialogQuadroHorariosEdicao;