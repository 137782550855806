import F3ValidResponse from "./F3ValidResponse";

const F3Login = (callback) => {
    const FB = window.FB;

    if (FB) {
        F3ValidResponse((response) => {
            if (response.status !== 'connected') {
                FB.login((response) => {
                    callback && callback(response);
                });
            }
            else {
                callback && callback(response);
            }
        });
    }
    else {
        callback && callback(null);
    }
};

export default F3Login;