import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { formatNumber, roundNumber } from "../../../utils/NumberUtils"

/**
 * Exibe os valores totais de novos itens de venda, itens de venda persistidos e a soma dos dois.
 */
class ItensVendaTotal extends React.Component {

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {

    log("ItensVendaTotal render")
    return <div className="table">
      {/* Tabela de valores */}
      <div className="pure-g">

        <div className="pure-u-xl-1-2 pure-u-lg-1-2 pure-u-md-1-2 pure-u-sm-1-1 pure-u-1-1" >
          <div className="table-total label-total-itens-venda" >
            <label className="TextAlign" >{getStrings().newTotalTemplate("")}</label>
            <label className="NumberAlign" >{formatNumber(this.props.newTotal, 2)}</label>
          </div>
        </div>

        <div className="pure-u-xl-1-2 pure-u-lg-1-2 pure-u-md-1-2 pure-u-sm-1-1 pure-u-1-1" >
          <div className="table-total label-total-itens-venda" >
            <label className="TextAlign" >{getStrings().sentTotalTemplate("")}</label>
            <label className="NumberAlign" >{formatNumber(this.props.sentTotal, 2)}</label>
          </div>
        </div>

        <div className="pure-u-xl-1-2 pure-u-lg-1-2 pure-u-md-1-2 pure-u-sm-1-1 pure-u-1-1" >
          <div className="table-total label-total-itens-venda" >
            <label className="TextAlign" >{getStrings().grandTotalTemplate("")}</label>
            <label className="NumberAlign" >{formatNumber((roundNumber(this.props.newTotal, 2) + roundNumber(this.props.sentTotal, 2)), 2)}</label>
          </div>
        </div>
      </div>
    </div>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  ...state.idiomaReducer
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(ItensVendaTotal)
