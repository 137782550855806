import actionTypes from "../actions/placeholderMessageAction";
import { getStrings as getStrings_ } from "../../utils/LocaleUtils";
import { CSSProperties } from "react";

function getStrings() {
    return getStrings_ || (() => ({}))
}

export type coresRGBTipo = {[rgb: string]: number};

const colorChannels: coresRGBTipo = {r: 3  , g: 73 , b:133,};
const textColor    : coresRGBTipo = {r: 255, g: 255, b:255,};

export const messageLayoutIds = {
    closedStore: '__closedStore__',
    forceClosedStore: '__forceClosedStore__',
    welcomeMessage: '__welcomeMessage__',
};

export type placeholderMessageReducerTipo = {
    layoutId: string
    , isText: boolean
    , data: Record<string, {
        style: CSSProperties,
        colorChannels: coresRGBTipo,
        text: {
            value: string,
            color: coresRGBTipo,
        },
    }>
};

export const initialState: placeholderMessageReducerTipo = {
    layoutId: messageLayoutIds.closedStore,
    isText: false,
    data: {
        [messageLayoutIds.closedStore]: {
            style: {
                width:  '100%',
                height: '100%',
                overflowX: 'hidden',
                textAlign: 'center',
                padding: '25vh 0',
                backgroundColor: `rgb(${JSON.stringify(Object.keys(colorChannels).map((color) => colorChannels[color])).slice(1,-1)})`,
                color:           `rgb(${JSON.stringify(Object.keys(    textColor).map((color) =>     textColor[color])).slice(1,-1)})`,
            },
            colorChannels,
            // @ts-ignore
            text: {value: getStrings().closedStore, color:textColor},
        },
        [messageLayoutIds.forceClosedStore]: {
            style: {
                width:  '100%',
                height: '100%',
                overflowX: 'hidden',
                textAlign: 'center',
                padding: '25vh 0',
                backgroundColor: `rgb(${JSON.stringify(Object.keys(colorChannels).map((color) => colorChannels[color])).slice(1,-1)})`,
                color:           `rgb(${JSON.stringify(Object.keys(    textColor).map((color) =>     textColor[color])).slice(1,-1)})`,
            },
            colorChannels,
            // @ts-ignore
            text: {value: getStrings().closedStore, color:textColor},
        },
        [messageLayoutIds.welcomeMessage]: {
            style: {
                width:  '100%',
                height: '100%',
                overflowX: 'hidden',
                textAlign: 'center',
                padding: '25vh 0',
                backgroundColor: `rgb(${JSON.stringify(Object.keys(colorChannels).map(color => colorChannels[color])).slice(1,-1)})`,
                color:           `rgb(${JSON.stringify(Object.keys(    textColor).map(color =>     textColor[color])).slice(1,-1)})`,
            },
            colorChannels,
            // @ts-ignore
            text: {value: getStrings().welcome, color:textColor},
        },
    },
};

const sanitizeData = ({style, colorChannels, text}: {style: CSSProperties, colorChannels: coresRGBTipo, text?: {value: string, color: coresRGBTipo}}, isText: boolean) => {
    const value = typeof text?.value === 'string' ?
        text?.value
        : null;

    const sanitized = {
        style,
        colorChannels: isText ? null : colorChannels,
        text: isText ? (text || {value: null, color: null}) : {value},
    };

    return sanitized;
}

const mapActions: {[x: string]: (acao: any, estadoAtual: placeholderMessageReducerTipo) => placeholderMessageReducerTipo} = {
    [actionTypes.UPDATE_PLACEHOLDER_MESSAGE]: ({id, data}: {id: string, data: {style: CSSProperties, text?: {value: string, color: coresRGBTipo}, colorChannels: coresRGBTipo}}, state: placeholderMessageReducerTipo) => {
        const {style, text, colorChannels} = sanitizeData(data, state.isText);
        const layout =  (state.data[id] || {});
        const textLayout = (layout.text || {value: '', color: textColor});

        const newState = {
            ...state,
            data: {
                ...state.data,
                [id]: {
                    ...layout,
                    style: {
                        ...layout.style,
                        ...style,
                    },
                    colorChannels: {
                        ...layout.colorChannels,
                        ...colorChannels,
                    },
                    text: {
                        ...layout.text,
                        ...text,
                        value: typeof text?.value === 'string'
                            ? text?.value
                            : textLayout.value,
                        color: {
                            ...textLayout.color,
                            ...(text?.color || {}),
                        },
                    },
                },
            },
        };

        return newState;
    },
    [actionTypes.UPDATE_TOGGLE_IS_TEXT]: (payload: boolean, state: placeholderMessageReducerTipo) => {
        const newState = {
            ...state,
            isText: payload,
        };

        return newState;
    },
    [actionTypes.SET_LAYOUT_ID]: (layoutId: string, state: placeholderMessageReducerTipo) => {
        const newState = {
            ...state,
            layoutId: layoutId || state.layoutId,
        }

        return newState;
    },
}

const placeholderMessageReducer = (state: placeholderMessageReducerTipo = localStorage.getItem('msg_placeholder') ? {...initialState, data: {...initialState.data, ...JSON.parse(localStorage.getItem('msg_placeholder') || 'null')}} : initialState, {type, payload}: {type: string, payload: any}) => {
    const action = mapActions[type];

    if (action) {
        const newState = action(payload, state);
        return newState;
    }

    return state;
}

export default placeholderMessageReducer;