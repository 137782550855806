import { isNonEmptyObject, isEmpty, nullOrUndefined } from '../ComparatorsUtils';


/** Método que busca no Storage o código da origem de venda. */
export const getSaleSourceCode = () => JSON.parse(sessionStorage.getItem('saleSourceCode') || null) || null;

/** Método que seta no Storage o código da origem de venda. */
export const setSaleSourceCode = saleSourceCode => sessionStorage.setItem('saleSourceCode', JSON.stringify(saleSourceCode || null));

/** Método que remove do Storage o código da origem de venda. */
export const removeSaleSourceCode = () => {
    sessionStorage.removeItem('saleSourceCode');
}


/** Método que busca no Storage o valor referente aos dados de Autenticação (a). */
export function getAuthStorage() {
    return JSON.parse(sessionStorage.getItem('a') || 'null');
}

/**
 * Método que seta no Storage o valor referente aos dados de Autenticação (a).
 * @param {Object} object 
 */
export const setAuthStorage = (object) => {
    if (isNonEmptyObject(object)) {
        sessionStorage.setItem('a', JSON.stringify(object));
        return true;
    }

    if (nullOrUndefined(object) || isEmpty(object)) {
        sessionStorage.removeItem('a');
    }

    return false;
}

/** Método que remove do Storage o valor referente aos dados de Autenticação (a). */
export const removeAuthStorage = () => {
    sessionStorage.removeItem('a');
}


/** Método que busca no Storage o valor referente aos dados do Cargo (c). */
export function getCargoStorage() {
    const stringifiedJSON = sessionStorage.getItem('c') || 'null'
    return JSON.parse(stringifiedJSON)
}

/**
 * Método que seta no Storage o valor referente aos dados do Cargo (c).
 * @param {Object} object 
 */
export const setCargoStorage = object => {

    if (isNonEmptyObject(object)) {
        sessionStorage.setItem('c', JSON.stringify(object));
        return true;
    }

    sessionStorage.removeItem('c');
    return false;
}

/** Método que remove do Storage o valor referente aos dados do Cargo (c). */
export const removeCargoStorage = () => {
    sessionStorage.removeItem('c');
}


/** Método que busca no Storage a empresa utilizada pelo app. */
export const getEmpresaApp = () => JSON.parse(sessionStorage.getItem('appCompany') || 'null');

/** Método que seta no Storage a empresa utilizada pelo app. */
export const setEmpresaApp = empresaApp => empresaApp && sessionStorage.setItem('appCompany', JSON.stringify(empresaApp));

/** Método que remove do Storage a empresa utilizada pelo app. */
export const removeEmpresaApp = () => {
    sessionStorage.removeItem('appCompany');
}