//@ts-check
import React from 'react';

import DialogTemplate from '../DialogTemplate';
import { Entrada, Texto } from '../../../nucleo/nucleo';
import { ClienteHttp, LocalidadeUtils, NavegacaoUtils } from '../../../nucleo/utils/utils';
import apelidos from '../../../rotas/apelidos';
import { connect } from 'react-redux';
import logar from '../../../logica/logar';
import { getEmpresaApp } from '../../../nucleo/utils/StorageUtils/StorageUtils';

/**
 * 
 * @param {import('./types').confirmacaoContaDialogPropsType} props 
 * @returns 
 */
function ConfirmacaoContaDialog(props) {
  const [erro, updateErro] = React.useState(false);

  return <DialogTemplate {...props} voltarHabilitado={false} fecharHabilitado={true}>
    <label className='alinhar-ao-centro'>{LocalidadeUtils.obterTraducao().login.accountConfirmation(props.login.email)}</label>
    <Entrada placeholder={LocalidadeUtils.obterTraducao().words.code} onChange={
      /**
       * 
       * @param {{target: {value: string}}} event 
       */
      (event) => {
      event.target.value = event.target.value.replace(/[^0-9]*/g, '');
      const token = event.target.value;

      if (token.length > 4) {
        ClienteHttp.obterClienteHttp()
          .post(ClienteHttp.getApi(`auth/registrationConfirmByCode`), { token, usuario: { login: { email: props.login.email } } })
          .then(() => {

            logar(props.login, () => {}, props.dispatch, props.caminho);
            props.dispatch(NavegacaoUtils.goTo(`${apelidos.app}?empresa=${getEmpresaApp()}`));
          })
          .catch(() => {
            updateErro(true);
          });
      }
    }} />
    {erro
      ? <div className='warning-template'>{<Texto key='__texto_login_erro__' className='texto-erro'>{LocalidadeUtils.obterTraducao().phrases.incorrectToken}</Texto>}</div>
      : null}
  </DialogTemplate>;
  
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  state,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmacaoContaDialog);
