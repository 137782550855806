import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { getStrings } from "../../../utils/LocaleUtils"

import * as appActions from "../../../store/actions/appAction"

import Tour from "reactour"
import { getPageNames } from "../../../utils/siteUtils/siteUtils"
import { getState } from "../../../utils/reduxUtils/reduxUtils"

let sideDrawerMustBeClosed = false;

/**
 * 
 * @param {String} id 
 */
const openMenu = id => {
  const accordionMenu = document.querySelector(`#accordion${id[0].toUpperCase() + id.substring(1)}`);
  const menu = document.querySelector(`#${id}`);

  if (accordionMenu
    && !accordionMenu.classList.contains("active")) {
    accordionMenu.classList.toggle("active");
  }

  if (menu) {
    menu.style.maxHeight = document.querySelector(`#${id}`).scrollHeight + "px";
  }
}

/**
 * 
 * @param {String} id 
 */
const closeMenu = id => {
  const accordionMenu = document.querySelector(`#accordion${id[0].toUpperCase() + id.substring(1)}`);
  const menu = document.querySelector(`#${id}`);

  if (accordionMenu
    && accordionMenu.classList.contains("active")) {
    accordionMenu.classList.toggle("active");
  }

  if (menu) {
    menu.style.maxHeight = "0";
  }
}

const openSideDrawer = () => {
  const sideDrawer = document.querySelector('#menu');
  if (!sideDrawer.classList.contains('Open'))
    document.querySelector('.DrawerToggle').click();
}

const closeSideDrawer = () => {
  const sideDrawer = document.querySelector('#menu');
  if (!sideDrawer.classList.contains('Close')) {
    document.querySelector('.DrawerToggle').click();
  }
}

export const getTour = (name, callback) => {
  const state = getState();
  const isAuthenticated = state.authReducer.token !== null;
  const isCargo = !!state.empresaSelectorReducer.cargo;
  const pageDomain = isAuthenticated
    ? isCargo
      ? "cargoSelecionado"
      : "logado"
    : "naoLogado";

  let tourObject = {
    website: {
      [getPageNames()[pageDomain].HOME]: [{
        selector: '.Header',
        content: getStrings().tourWebsiteHeader,
        hidePrevButton: true,
      }, {
        selector: '.languageSelect',
        content: getStrings().tourWebsiteLanguageSelect
      }, {
        selector: '.helpToggle',
        content: getStrings().tourWebsiteHelpToggle
      },
      {
        action: closeSideDrawer,
        selector: '.DrawerToggle',
        content: getStrings().tourWebsiteDrawerToggle
      }, {
        action: openSideDrawer,
        selector: '#SideDrawer-placeholder',
        content: getStrings().tourWebsiteSideDrawer
      }, {
        action: () => {
          if (window.innerWidth <= 768) {
            closeSideDrawer();
          }
          // Se o help não for encontrado (está fechado), clica no toggle e abre
          let help = document.querySelector('.help');
          if (help === undefined || help === null)
            document.querySelectorAll('#helpToggle')[0].click();
        },
        selector: '.help',
        content: getStrings().tourWebsiteHelp,
        // observe: '.tela-start'
      }
      ],
      [getPageNames().logado.SELECAO_EMPRESA]: [{
        selector: '.Header',
        content: getStrings().tourWebsiteHeader,
        hidePrevButton: true,
      }, {
        selector: '.languageSelect',
        content: getStrings().tourWebsiteLanguageSelect
      }, {
        selector: '.helpToggle',
        content: getStrings().tourWebsiteHelpToggle
      },
      {
        action: closeSideDrawer,
        selector: '.DrawerToggle',
        content: getStrings().tourWebsiteDrawerToggle
      }, {
        action: openSideDrawer,
        selector: '#SideDrawer-placeholder',
        content: getStrings().tourWebsiteSideDrawer
      }, {
        action: () => {
          if (window.innerWidth <= 768) {
            closeSideDrawer();
          }
          // Se o help não for encontrado (está fechado), clica no toggle e abre
          let help = document.querySelector('.help');
          if (help === undefined || help === null)
            document.querySelectorAll('#helpToggle')[0].click();
        },
        selector: '.help',
        content: getStrings().tourWebsiteHelp,
        // observe: '.tela-start'
      }
      ]
    },
    cadastro: {
      [getPageNames()[pageDomain].HOME]: // Tela de login ou cadastro
        [
          {
            selector: '.sign-up',
            content: getStrings().tourCadastroStart,
          }
        ],
      [getPageNames().naoLogado.SIGNUP]: // Tela de escolha do cadastro de Usuário ou Empresa
        [
          {
            selector: '.button-grid',
            content: getStrings().tourCadastroSignUpButtonGrid
          }
        ],
        [getPageNames().naoLogado.SIGNUP_USER]: // Tela de cadastro de Usuário
        [
          {
            selector: '#campos-usuario',
            content: getStrings().tourCadastroSignUpUserUsuario,
            position: 'top',
            hidePrevButton: true
          }, {
            selector: '.button-grid button',
            content: getStrings().tourCadastroSignUpUserButtonGrid
          }
        ],
        [getPageNames().naoLogado.SIGNUP_COMPANY]: // Tela de cadastro de Empresa
        [
          {
            selector: '#campos-contrato',
            content: getStrings().tourCadastroSignUpCompanyContrato,
            position: 'top',
            hidePrevButton: true
          }, {
            selector: '#next-button',
            content: getStrings().tourCadastroSignUpCompanyNextToEmpresa,
            position: 'bottom',
            hideNextButton: true
          }, {
            selector: '#campos-empresa',
            content: getStrings().tourCadastroSignUpCompanyEmpresa,
            // Observer para focar componente assim que carregarem os dados do select
            observe: '#main-form-willchangestep',
            position: 'top',
            hidePrevButton: true
          }, {
            selector: '#campos-endereco',
            content: getStrings().tourCadastroSignUpCompanyEndereco,
            position: 'top'
          }, {
            selector: '#campos-contato',
            content: getStrings().tourCadastroSignUpCompanyContato,
            position: 'top'
          }, {
            selector: '#campos-parametros',
            content: getStrings().tourCadastroSignUpCompanyParametros,
            position: 'top'
          }, {
            selector: '#next-button',
            content: getStrings().tourCadastroSignUpCompanyNextToUsuario,
            position: 'bottom',
            hideNextButton: true
          }, {
            selector: '#campos-usuario',
            content: getStrings().tourCadastroSignUpCompanyUsuario,
            position: 'top',
            hidePrevButton: true
          }, {
            selector: '#next-button',
            content: getStrings().tourCadastroSignUpCompanyNextToSubmit
          }
        ]
    },
    usuarioLogado: {
      [getPageNames().logado.HOME]: [
        {
          action: () => { openSideDrawer(); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuItemHome',
          content: getStrings().tourStartMenuItem,
        },
        {
          action: () => { openSideDrawer(); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuItemEmpresaSelector',
          content: getStrings().tourCompanySelector,
        },
        {
          action: () => { openSideDrawer(); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#accordionMenuItemCadastro',
          content: getStrings().tourRegisterSitemMenu,
        },
        {
          action: () => { openSideDrawer(); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#accordionMenuItemVenda',
          content: getStrings().tourControlMenuItem,
        },
        {
          action: () => { openSideDrawer(); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#accordionMenuRelatorios',
          content: getStrings().tourReportsMenuItem,
        },
        {
          action: () => { openSideDrawer(); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#accordionMenuItemUsuario',
          content: getStrings().tourSettingsMenuItem,
        },
        {
          action: () => { openSideDrawer(); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuItemLogout',
          content: getStrings().tourLogoutMenuItem,
        },
      ]
    },
    usuario: {
      [getPageNames()[pageDomain].HOME]: [
        {
          action: () => {
            openSideDrawer();
            sideDrawerMustBeClosed = true;
          },
          selector: '#menuItemHome',
          content: getStrings().tourHomeMenuItem,
        },
        {
          action: () => {
            openSideDrawer();
            sideDrawerMustBeClosed = true;
          },
          selector: '#menuItemLogin',
          content: getStrings().tourLoginMenuItem,
        },
      ]
    },
    menuCadastro: {
      [getPageNames().cargoSelecionado.HOME]: [
        {
          action: () => { openSideDrawer(); openMenu('menuItemCadastro'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuItemEmpresa',
          content: getStrings().tourCompanyMenuItem,
        },
        {
          action: () => { openSideDrawer(); openMenu('menuItemCadastro'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuItemCargo',
          content: getStrings().tourRoleMenuItem,
        },
        {
          action: () => { openSideDrawer(); openMenu('menuItemCadastro'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuItemImpressora',
          content: getStrings().tourPrintMenuItem,
        },
        {
          action: () => { openSideDrawer(); openMenu('menuItemCadastro'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuItemFormaPagamento',
          content: getStrings().tourPaymentMethodMenuItem,
        },
        {
          action: () => { openSideDrawer(); openMenu('menuItemCadastro'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuItemTipoProduto',
          content: getStrings().tourProductTypeMenuItem,
        },
        {
          action: () => { openSideDrawer(); openMenu('menuItemCadastro'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuItemGrupoProduto',
          content: getStrings().tourProductGroupMenuItem,
        },
        {
          action: () => { openSideDrawer(); openMenu('menuItemCadastro'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuItemProduto',
          content: getStrings().tourProductMenuItem,
        },
        {
          action: () => { openSideDrawer(); openMenu('menuItemCadastro'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuItemOrigemVenda',
          content: getStrings().tourCardMenuItem,
        },
      ]
    },
    menuControle: {
      [getPageNames().cargoSelecionado.HOME]: [
        {
          action: () => { openSideDrawer(); openMenu('menuItemVenda'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuItemControleVenda',
          content: getStrings().tourMovementMenuItem,
        },
        {
          action: () => { openSideDrawer(); openMenu('menuItemVenda'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuItemProducao',
          content: getStrings().tourProductionMenuItem,
        },
      ]
    },
    menuRelatorios: {
      [getPageNames().cargoSelecionado.HOME]: [
        {
          action: () => { openSideDrawer(); openMenu('menuRelatorios'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuRelatorioMovimento',
          content: getStrings().tourMovementReportMenuItem,
        },
        {
          action: () => { openSideDrawer(); openMenu('menuRelatorios'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuRelatorioFechamento',
          content: getStrings().tourClosingReportMenuItem,
        },
        {
          action: () => { openSideDrawer(); openMenu('menuRelatorios'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuRelatorioLucratividade',
          content: getStrings().tourProfitabilityReportMenuItem,
        },
        {
          action: () => { openSideDrawer(); openMenu('menuRelatorios'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuRelatorioLucratividadeData',
          content: getStrings().tourProfitabilityDateReportMenuItem,
        },
        {
          action: () => { openSideDrawer(); openMenu('menuRelatorios'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuRelatorioLucratividadeHora',
          content: getStrings().tourProfitabilityHourReportMenuItem,
        },
        {
          action: () => { openSideDrawer(); openMenu('menuRelatorios'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuRelatorioEntregas',
          content: getStrings().tourMovementReportMenuItem,
        },
      ]
    },
    menuConfiguracoes: {
      [getPageNames().logado.HOME]: [
        {
          action: () => { openSideDrawer(); openMenu('menuItemUsuario'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuItemUsuarioForm',
          content: getStrings().tourUserMenuItem,
        },
        {
          action: () => { openSideDrawer(); openMenu('menuItemUsuario'); if (window.innerWidth <= 768) sideDrawerMustBeClosed = true },
          selector: '#menuItemIntegracao',
          content: getStrings().tourIntegrationMenuItem,
        }
      ]
    },
  };

  if (callback)
    tourObject[name] = callback(tourObject[name]);

  return {
    steps: Object.keys(tourObject[name]).map((pathname) => {
      let obj = {};
      obj[pathname] = tourObject[name][pathname];
      return obj;
    })
  };
}

class TourSystem extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentStep: {},
      currentStepNumber: 0,
    }
  }

  /**
   * Atualiza o step atual
   */
  updateCurrentStep = (step) => {
    // Se o tour estiver fechado ou o tour atual não tiver este passo, reseta em 0
    if (!step || !this.props.tourIsOpen || !this.getSteps(this.props.tourSteps)[step]) {
      step = 0;
    }
    // Busca step
    let currentStepToUpdate = this.getSteps(this.props.tourSteps)[step];
    // Atualiza no state apenas se o novo step é diferente do atual
    if (this.state.currentStep !== step) {
      this.setState({
        currentStep: currentStepToUpdate,
        currentStepNumber: step,
      })
      // Se está para esconder botão de próximo e existe um main-form na tela, ele irá para o próximo step ao clicar no botão de próximo do form
      // Possivelmente mover para o reducer (quando criado para o tour) e colocar função direto no onsubmit dos forms que possuem tour            
      if (currentStepToUpdate.hideNextButton && document.getElementById('main-form')) {
        // Armazena onSubmit anterior para não substituir
        this.oldSubmit = document.getElementById('main-form').onsubmit
        document.getElementById('main-form').onsubmit = () => {
          // Executa primeiro o onSubmit anterior
          if (this.oldSubmit)
            this.oldSubmit();
          // Controlador para avançar apenas 1 step (entra em loop caso não tiver)
          this.updatedStep = false;
          // Vai para o próximo step
          this.goToNextStep();
        }
        // Modifica o id do form para identificar que o submit irá mudar também o step e para não adicionar a função novamente
        document.getElementById('main-form').id = 'main-form-willchangestep';
      }
    }
  }

  /**
   * Aumenta o step em 1
   */
  goToNextStep = () => {
    let step = this.state.currentStepNumber + 1
    // Se o tour estiver fechado ou o tour atual não tiver este passo, reseta em 0
    if (!step || !this.props.tourIsOpen || !this.getSteps(this.props.tourSteps)[step]) {
      step = 0;
    }
    // Busca step
    let currentStepToUpdate = this.getSteps(this.props.tourSteps)[step]
    if (currentStepToUpdate && !this.updatedStep) {
      this.setState({
        currentStep: currentStepToUpdate,
        currentStepNumber: step,
      })
      // Desativa controlador para não repetir ação
      this.updatedStep = true;
    }
  }

  /**
   * Busca os steps de acordo com o path atual
   */
  getSteps = (tour) => {
    if (tour === undefined || tour.steps === undefined) {
      closeMenu('menuItemCadastro');
      closeMenu('menuItemVenda');
      closeMenu('menuRelatorios');
      closeMenu('menuItemUsuario');

      if (sideDrawerMustBeClosed) {
        closeSideDrawer();
        sideDrawerMustBeClosed = false;
      }

      this.props.closeTour();
      return [];
    }

    // Senão, existe um step para cada path, e ele retorna o correto quando o path atualiza
    let steps = tour.steps.reduce((acum, curr) => ({ ...acum, ...curr }), {})[this.props.location.pathname]
    if (steps === undefined) { // Quando ir para um path não previsto, fecha tour
      closeMenu('menuItemCadastro');
      closeMenu('menuItemVenda');
      closeMenu('menuRelatorios');
      closeMenu('menuItemUsuario');

      if (sideDrawerMustBeClosed) {
        closeSideDrawer();
        sideDrawerMustBeClosed = false;
      }

      this.props.closeTour();
      return [];
    }
    return steps;
  }

  /** Botão customizado de passar para próximo step. */
  nextButton = () =>
    <div id="next-button" style={{ 'color': '#485dff', 'fontWeight': 'bold', 'fontSize': '14px', 'textTransform': 'uppercase', 'float': 'left' }}>
      {getStrings().next}
    </div>
  /** Botão customizado de retornar ao step anterior. */
  prevButton = () =>
    <div id="prev-button" style={{ 'color': '#485dff', 'fontWeight': 'bold', 'fontSize': '14px', 'textTransform': 'uppercase', 'float': 'right' }}>
      {getStrings().previous}
    </div>
  /** Botão customizado de fechar quando temrinarem os steps. */
  closeButton = () =>
    <div id="close-button" style={{ 'fontWeight': 'bold', 'fontSize': '14px', 'textTransform': 'uppercase', 'float': 'left' }}>
      {getStrings().close}
    </div>

  render() {
    return (
      <section className='sitetour-wrapper'>
        <Tour
          steps={!this.props.tourIsOpen ? null : this.getSteps(this.props.tourSteps)} // Steps do tour
          isOpen={this.props.tourIsOpen} // Define se o tour está aberto
          onRequestClose={evt => {
            closeMenu('menuItemCadastro');
            closeMenu('menuItemVenda');
            closeMenu('menuRelatorios');
            closeMenu('menuItemUsuario');

            if (sideDrawerMustBeClosed) {
              closeSideDrawer();
              sideDrawerMustBeClosed = false;
            }

            return this.props.closeTour(evt);
          }} // Action ao clicar no botão de fechar ou na mask
          className="tour" // Nome da classe do tour
          maskClassName="tour-mask" // Nome da classe da mask
          rounded={2} // Bordas do popup do tour e da mask
          maskSpace={6} // Espaço entre o popup do tour e da mask
          accentColor="#485dff" // Cor accent
          update={this.props.location.pathname} // Atualiza os steps sempre que esta o path altera (quando muda de tela)

          showNumber={false} // Se deve mostrar o número do passo atual
          showNavigationNumber={false} // Se deve mostrar o número ao dar hover nas bolinhas
          closeWithMask={true} // Se deve fechar tour ao clicar na mask
          disableKeyboardNavigation={false} // Desativa navegação pelo tour pelas setas do teclado
          disableDotsNavigation={true} // Desativa navegação pelo tour pelas bolinhas, são apenas para display
          showNavigation={!this.props.tourIsOpen ? null : this.getSteps(this.props.tourSteps).length > 1} // Apenas exibe navegação se houver mais de 1 step
          showButtons={!this.props.tourIsOpen ? null : this.getSteps(this.props.tourSteps).length > 1} // Apenas exibe botões se houver mais de 1 step

          getCurrentStep={(step) => this.updateCurrentStep(step)} // Atualiza o state do step atual sempre que muda
          goToStep={this.state.currentStepNumber} // Vai para o step de acordo com o state para manter sincronizado (assim que altera o state, vai pare o step)

          nextButton={this.state.currentStep.hideNextButton ? <div id="next-button"></div> : this.nextButton()} // Botão customizado de passar para próximo step (esconde o botão quando o step requer)
          prevButton={this.state.currentStep.hidePrevButton ? <div id="prev-button"></div> : this.prevButton()} // Botão customizado de retornar ao step anterior (esconde o botão quando o step requer)
          lastStepNextButton={this.closeButton()} // Botão customizado de encerrar tour

          startAt={0} // Começa do 0 sempre que o tour é aberto
          scrollOffset={  // Posiciona máscara e helper em posição específica, e não no centro
            -55 // Tamanho do header
            - 18  // Tamanho do maskSpace * 3
          }
        />
      </section>
    )
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = (state) => {
  return {
    tourIsOpen: state.appReducer.tourIsOpen,
    tourSteps: state.appReducer.tourSteps
  };
};

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
  return {
    openTour: (steps) => dispatch(appActions.appStartTour(steps)),
    closeTour: () => dispatch(appActions.appCloseTour()),
  };
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TourSystem));
