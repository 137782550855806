import React from "react"
import { connect } from "react-redux"

import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"
import { formatNumber } from "../../../utils/NumberUtils"

import { AccordionClick } from "../../UI/Accordion/Accordion"
import Column from "../../../components/vendas/grid/Column.js"

import "../vendas.css";
import Icones from "../../../assets/icones/Icones"

const ColumnComponents = (props) => {
  const isDialogOrigemLivres = props.className.split(" ").includes("dialogOrigemLivres")

  return !isDialogOrigemLivres
    ? <Column className={props.className}>
      {props.children}
    </Column>
    : <>{props.children}</>
}

/**
 * Componente que monta a celula da grid a partir da OrigemVenda ou Venda.
 */
const BuildCellFromOrigemRaw = (props) => {
  log("BuildCellFromOrigemRaw render")
  const ArrowDownIcon = Icones.ArrowDownIcon

  const mapKeysToActions = {
    "Enter": props.handleClick,
    " ": (evt) => {
      evt.preventDefault()
      return props.handleClick(evt)
    },
  }

  const handleKey = (evt) => {

    if (evt.target.className.includes("card")) {
      try {
        mapKeysToActions[evt.key](evt)
      } catch (err) { }
    }
  }

  return <div className={"pure-u-1 pure-u-sm-1-2 " + (props.isOrigemLivre ? "" : "pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-4")} >
    <div tabIndex={props.tabIndex} className={`clickable card origem-venda${props.isOrigemLivre ? "" : " with-footer"} card-container${isAncientChromeMobile ? " ancient-chrome-mobile" : ""}`} onClick={props.handleClick} onKeyDown={handleKey}>

      <div className={`card-content ${!props.isOrigemLivre ? " sell-control" : ""}`}>
        <ColumnComponents className={`left${props.isOrigemLivre ? " dialogOrigemLivres" : ""}`}>
          <h1 className="nome-origem TextAlign">
            {props.nome}
          </h1>

          <h2 className="codigo-origem">{props.codigo}</h2>
        </ColumnComponents>
        <ColumnComponents className={`right ${props.isOrigemLivre ? " dialogOrigemLivres" : ""}`}>
          {(props.isOrigemLivre || props.totalOnly) ? null :
            <div className={`pago-origem card-cell${!props.isOrigemLivre ? " row" : ""}`}>
              <h3 className="TextAlign" >{getStrings().totalPaidShortTemplate("")}</h3>
              <h3 className="NumberAlign" >{formatNumber(props.valorPagamento, 2)}</h3>
            </div>}
          {(props.isOrigemLivre || props.totalOnly) ? null :
            <div className={`enviado-origem card-cell${!props.isOrigemLivre ? " row" : ""}`}>
              <h3 className="TextAlign" >{getStrings().sentTotalShorter}</h3>
              <h3 className="NumberAlign" >{formatNumber(props.valorEnviado, 2)}</h3>
            </div>}
          {props.isOrigemLivre ? null :
            <div className={`geral-origem card-cell${!props.isOrigemLivre ? " row" : ""}`}>
              <h3 className="TextAlign" >{props.totalOnly
                ? getStrings().totalColonSpace : getStrings().grandTotalShortTemplate("")}</h3>
              <h3 className="NumberAlign" >{formatNumber(props.valorGeral, 2)}</h3>
            </div>}
        </ColumnComponents>
      </div>

      {props.isOrigemLivre ? null :
        <div className={`card-footer${!props.isOrigemLivre ? " sell-control" : ""} ${props.footerColor ? "" : "light-button"} font-color ${props.footerColor || ""}`}>
          <button tabIndex={props.tabIndex} name="accordion" onClick={AccordionClick}>
            <h2 className="flex flex-row flex-nowrap justify-content-center align-items-center" style={{ color: "var(--color-text-dark)" }}>
              <div style={{flexGrow: 1}} >{props.accordionTitle}</div>
              <ArrowDownIcon className="no-margin no-padding" />
            </h2>
          </button>
          <div className="accordion-content">
            <h3 style={{ color: "var(--color-text-dark)" }}> {props.nomeVendas} </h3>
          </div>
        </div>}
    </div>
  </div>
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  ...state.idiomaReducer,
  ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa,
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  exibeGridVendaFromOrigem: origemVenda => dispatch(controleVendaActions.exibeGridVendaFromOrigem(origemVenda)),
  reserveSaleSource: origemVenda => dispatch(controleVendaActions.reserveSaleSource(origemVenda)),
  setupNewVenda: origemVenda => dispatch(controleVendaActions.setupNewVenda(origemVenda))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(BuildCellFromOrigemRaw)
