import { Auth } from './Config';
import { getAppPermissoesCargo } from './AppUtils';
import { isNonEmptyArray } from './ComparatorsUtils';

/* 
 * Constantes das permissões 
 */
// Cadastro

/**
 * Consultar, criar, editar e excluir cadastros, exceto de empresa e cargo.
 */
export const PERMISSAO_CADASTRO_GERAL = 'cadastroGeral';

/**
 * Consultar, criar, editar e excluir cadastros de empresa.
 */
export const PERMISSAO_CADASTRO_EMPRESA = 'cadastroEmpresa';

/**
 * Consultar, criar, editar e excluir cadastros de cargo.
 */
export const PERMISSAO_CADASTRO_CARGO = 'cadastroCargo';

// Produção

/**
 * Alterar o estado de itens de venda.
 */
export const PERMISSAO_PRODUCAO_ALTERACAO = 'producaoAlteracao';

/**
 * Consultar itens de venda aguardando produção ou em produção.
 */
export const PERMISSAO_PRODUCAO_CONTROLE = 'producaoControle';

// Relatórios

/**
 * Exibição dos relatórios.
 */
export const PERMISSAO_RELATORIOS = 'relatorios';

// Venda

/**
 * Acessar o controle de vendas.
 */
export const PERMISSAO_VENDA_CONTROLE = 'vendaControle';

/**
 * Entregar itens.
 */
export const PERMISSAO_VENDA_ENTREGA = 'vendaEntrega';

/**
 * Enviar itens. Implica em poder iniciar venda.
 */
export const PERMISSAO_VENDA_ENVIO = 'vendaEnvio';

/**
 * Excluir itens.
 */
export const PERMISSAO_VENDA_EXCLUSAO = 'vendaExclusao';

/**
 * Pagar vendas e itens.
 */
export const PERMISSAO_VENDA_PAGAMENTO = 'vendaPagamento';

// Integração

/**
 * Acesso à tela de gerência de token para integração.
 */
export const PERMISSAO_INTEGRACAO_CONFIGURACAO = 'integracaoConfiguracao';

// Faturamento
export const PERMISSAO_CONTROLE_FATURAMENTO = 'controleFaturamento';
// Financeiro
export const PERMISSAO_CONTROLE_FINANCEIRO = 'controleFinanceiro';

/**
 * Permissão Desenvolvedor
 */
export const PERMISSAO_DEV = Auth.MODO_DESENVOLVEDOR;

/**
 * Método que verifica se o cargo possui uma das permissões enviadas por parâmetro. 
 * @param {string[]} filtroDePermissoes Array de permissões que podem acessar o conteúdo
 */
export const verificaPermissaoAcesso = (filtroDePermissoes: string[]) => {
    const permissoesCargo = getAppPermissoesCargo();

    // Verifica se a lista de Permissões não foi localizada no storage
    if (!(isNonEmptyArray(permissoesCargo) && isNonEmptyArray(filtroDePermissoes))) {
        return false;
    }

    for (let permissaoCargo of permissoesCargo) {
        if (filtroDePermissoes.includes(permissaoCargo)) {
            // Permissão aceita!
            return true;
        }
    }

    // Permissão negada!
    return false;
}
