import { empresaReducerTipo } from "./empresaReducer";
import criarAction from "../factory/criarAction";

const actionTypes = {
  POPULAR_EMPRESA: 'POPULAR_EMPRESA',
};

export default actionTypes;

export const polularEmpresa = (carga: empresaReducerTipo) => function(dispatch: (carga: any) => any) {
  dispatch(criarAction(carga, actionTypes.POPULAR_EMPRESA));
}