import { base64toBlob, saveData } from "../../utils/BlobUtils"
import * as errorUtils from "../../utils/ErrorUtils"
import { getHeaders } from "../../utils/HeadersUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { extractWidthAndHeightFromSVGString, pxToCm } from "../../utils/MiscUtils"
import { urlDatabase } from "../../utils/SecureConnectionUtils"
import { getURIFromEntity } from "../../utils/URIUtils"

import {
  APP_NOTIFICATION_TYPE_WARNING
  , CADASTRO_PRINT
  , CADASTRO_SWITCH_UI
} from "./actionTypes"
import {
  STATE_CADASTRO_PRINT_PREVIEW
} from "../reducers/cadastroReducer"
import {
  EXTERNAL_USE
  , FAIL
  , FORMAT_PNG
  , initialState
  , MEDIA_FILES
  , INTERNAL_USE
} from "../reducers/cadastroPrintReducer"
import { appNotificationShow, appSpinnerHide, appSpinnerShow, axios } from "./appAction"
import { buildSpecQuery, showPrint } from "./cadastroAction"
import { dispatchTipo } from "./acaoTemplate"
import { getStateType } from "../../utils/reduxUtils/getStateType"
import { requestQrCodeToString } from "../../utils/qrCodeUtils/requestQrCodeToString"

export type addGeneratedCodeType = (uri: string, svg: string) => (dispatch: dispatchTipo, getState: getStateType) => void;

/**
 * Adiciona no *reducer* um código gerado, indexado pelo URI do cadastro.
 * @param {string} uri
 * @param {string} svg 
 */
export const addGeneratedCode: addGeneratedCodeType = (uri, svg) => (dispatch, getState) => {
  log("cadastroPrintAction addGeneratedCode", uri, svg);

  dispatch({
    type: CADASTRO_PRINT,
    svgMap: Object.assign({}, getState().cadastroPrintReducer.svgMap, { [uri]: svg })
  });
}

export type buildCodesType = () => (dispatch: dispatchTipo, getState: getStateType) => void;

/**
 * Busca a lista de cadastros para gerar seus códigos.
 */
export const buildCodes: buildCodesType = () => (dispatch, getState) => {
  log("cadastroPrintAction buildCodes");
  // Exibe spinner
  dispatch(appSpinnerShow("cadastroPrintAction buildCodes"));
  
  // Agenda a execução do resto do método. Necessário para que o spinner apareça antes.
  setTimeout(() => {
    const state = getState();
    const cadastroReducer = state.cadastroReducer;
    let cadastroPrintReducer = state.cadastroPrintReducer;
    /**
     * Lista usada para saber quando todas as gerações de código tiverem concluído.
     */
    let promiseList: Record<string,any>[] = [];
    // Filtra os cadastros de acordo com os requisitos de cada caso
    let registerList = cadastroPrintReducer.registerList.filter((register: any) =>
      // Se for para uso externo, só trata os cadastros que possuírem código, pois sempre será exibido.
      ((cadastroPrintReducer.use === EXTERNAL_USE) && register.codigo)
      // Se for para uso interno, só trata os cadastros que possuírem código ou se foi configurado que não precisa de código e as que possuírem código impresso
      || ((cadastroPrintReducer.use === INTERNAL_USE) && ((!cadastroPrintReducer.includeRegisterCode) || register.codigo) && (register.codigoImpresso) && (register.formatoCodigoImpresso))
    );

    // Verifica se nenhum registro estava elegível para ter seu código gerado
    if ((registerList || []).length < 1) {
      // Se nenhum estava, avisa e não permite seguir em frente.
      dispatch(appNotificationShow(getStrings().printedCodeInvalid, APP_NOTIFICATION_TYPE_WARNING, getStrings().printCodes));
      dispatch(appSpinnerHide("cadastroPrintAction buildCodes"));

      return;
    }

    // Gera o código para cada cadastro
    registerList.forEach((register: Record<string, string>) => {
      // Se for para uso interno (onde lê do código impresso) e cadastro não usar QR, o código será de barras e gerado em outro lugar.
      if ((cadastroPrintReducer.use === INTERNAL_USE) && (register.formatoCodigoImpresso !== "QR")) {
        dispatch(appSpinnerHide("cadastroPrintAction buildCodes"));

        return;
      }

      promiseList.push(requestQrCodeToString
        ( (cadastroPrintReducer.use === EXTERNAL_USE)
          ? `${getURIFromEntity(register)}/scan`
          : register.codigoImpresso, { errorCorrectionLevel: "H" }
        )
        .then((svg: string) => register.svg = svg));
    });

    // Quando todos os códigos tiverem sido gerados
    Promise.all(promiseList).then(() => {
      dispatch(appSpinnerHide("cadastroPrintAction buildCodes"));
      // Armazena os códigos no reducer para que a tela de visualização os exiba
      dispatch(setConfig
        ( cadastroPrintReducer.format
        , cadastroPrintReducer.height
        , cadastroPrintReducer.margin
        , cadastroPrintReducer.media
        , registerList
        , cadastroReducer.objetoCadastroLast
        , cadastroPrintReducer.use
        )
      );
    });
  }, 1000);
}

export type clearBuildType = () => (dispatch: dispatchTipo) => void;

/**
 * Limpa os códigos gerados para impressão.
 */
export const clearBuild: clearBuildType = () => dispatch => {
  log("cadastroPrintAction clearBuild");

  dispatch({
    type: CADASTRO_PRINT,
    svgMap: []
  });
};

export type clearConfigType = (printExternalEnabled: boolean, printInternalEnabled: boolean) => (dispatch: dispatchTipo) => void;

/**
 * Limpa todos os dados do reducer.
 * @param printExternalEnabled se deve permitir a impressão de códigos para uso externo
 * @param printInternalEnabled se deve permitir a impressão de códigos para uso interno
 */
export const clearConfig: clearConfigType = (printExternalEnabled, printInternalEnabled) => dispatch => {
  log("cadastroPrintAction clearConfig");

  dispatch({
    type: CADASTRO_PRINT,
    ...initialState, // TODO investigar porque fica null ao invés do valor padrão
    printExternalEnabled,
    printInternalEnabled
  });
};

type errorResponseNotifyType = (error: string) => (dispatch: dispatchTipo) => void;

/**
 * Ao haver falha em comunicação com o servidor, mostrar uma notificação.
 * @param {string} error 
 */
const errorResponseNotify: errorResponseNotifyType = error => dispatch => {
  log("cadastroPrintAction errorResponseNotify", error);
  dispatch(errorUtils.requestErrorHandlerDefault(error));
};

export type getRegisterListType = (filterProperties: string[], printExternalEnabled?: boolean, printInternalEnabled?: boolean) => (dispatch: dispatchTipo,
  getState: getStateType) => void;

/**
 * Busca a lista de cadastros para gerar seus códigos.
 * @param {*} filterProperties propriedades da entidade a serem filtradas
 */
export const getRegisterList: getRegisterListType = (filterProperties, printExternalEnabled, printInternalEnabled) => (dispatch, getState) => {
  log("cadastroPrintAction getRegisterList", { filterProperties, printExternalEnabled, printInternalEnabled });

  // Mostra o spinner até que os registros sejam exibidos
  dispatch(appSpinnerShow("getRegisterList"));

  let objetoCadastro = getState().cadastroReducer.objetoCadastroLast;
  let filter = getState().cadastroReducer.filter;

  let search = null;
  if (filter) {
    search = buildSpecQuery(filterProperties, filter);
  }

  axios().get(
    `${urlDatabase}/${objetoCadastro}s/`,
    getHeaders({ all: true, projection: `${objetoCadastro}PrintCodeProjection`, ...(search ? { search } : {}) })
  )
    .then(response => {
      // Verifica se foram retornadas entidades dado o filtro informado
      if (((((response || {}).data || {}).content || []).length || 0) < 1) {
        // Se não foram retornadas entidades, avisa e faz mais nada.
        dispatch(appNotificationShow(getStrings().noResultsText, APP_NOTIFICATION_TYPE_WARNING, getStrings().printCodes));
        return;
      }

      const registerList = response.data.content.map((register: any) => {
        return {
          ...register,
          codigoImpresso: register.codigoImpresso ? register.codigoImpresso : register.codigo,
          formatoCodigoImpresso: "QR",
        };
      });

      // Verifica se, no caso de somente a impressão interna estar habilitada, pelo menos um cadastro tem código impresso cadastrado.
      if ((!printExternalEnabled) && (registerList.length < 1)) {
        // Se nenhum cadastro tem código impresso cadastrado, avisa e faz mais nada.
        dispatch(appNotificationShow(getStrings().printedCodeInvalid, APP_NOTIFICATION_TYPE_WARNING, getStrings().printCodes, undefined, 22));
        return;
      }
      dispatch(showPrint(true, registerList, printExternalEnabled, printInternalEnabled));
    })
    .catch(error =>
      dispatch(errorResponseNotify(error))
    )
    .finally(() =>
      dispatch(appSpinnerHide("getRegisterList"))
    );
}

export type saveResultType = () => (dispatch: dispatchTipo, getState: getStateType) => void;

/**
 * Junta os códigos gerados em um arquivo compactado ou em um PDF e exibe a janela para salvar o arquivo.
 */
export const saveResult: saveResultType = () => (dispatch, getState) => {
  log("cadastroPrintAction saveResult");

  let state = () => getState().cadastroPrintReducer;
  // Ignora os registros que falharam em terem seu código gerado
  // @ts-ignore
  let registerList = state().registerList.filter((register) => state().svgMap[getURIFromEntity(register)] !== FAIL);
  // Exibe spinner
  dispatch(appSpinnerShow("cadastroPrintAction saveResult"));
  // Agenda a execução do resto do método. Necessário para que o spinner apareça antes.
  setTimeout(async () => {
    // Verifica se deve salvar os códigos em arquivos
    if (state().media === MEDIA_FILES) {

      // Importa a biblioteca que gera o arquivo compactado
      const { default: JSZip } = await import("jszip");
      const zip = new JSZip();

      // Importa a biblioteca que processa o SVG
      const { default: canvg } = await import("canvg");

      // Armazena o formato de arquivo
      let format = `.${(state().format === FORMAT_PNG) ? "png" : "svg"}`;
      // Adiciona os códigos gerados ao arquivo compactado
      registerList.forEach((register: any) => {
        // Caso SVG esteja selecionado, armazena o código gerado em SVG.
        let blob = state().svgMap[getURIFromEntity(register)];
        // Caso PNG esteja selecionado, converte o código gerado em PNG.
        if (state().format === FORMAT_PNG) {
          // Cria um canvas para passar para a biblioteca
          let canvas = document.createElement("canvas");
          // Processa o SVG.
          // @ts-ignore
          canvg(canvas, blob, { ImageClass: null });
          // Converte para PNG.
          blob = base64toBlob(canvas.toDataURL("image/png").substring(22), "image/png");
        }
        // Adiciona a imagem ao arquivo compactado
        zip.file(register.codigo + format, blob);
      });
      // Gera arquivo compactado
      zip.generateAsync({ type: "blob" })
        .then(blob => saveData(blob, "*.zip"))
        .catch(error => dispatch(errorResponseNotify(error)))
        .finally(() => dispatch(appSpinnerHide("cadastroPrintAction saveResult")));
    }
    // Senão, salva os códigos em páginas.
    else {
      // Calcula a área usável do papel
      let paperWidthPx: any;
      let paperHeightPx: any;
      let paperWidthCm: any;
      let paperHeightCm: any;
      if (state().paperSize.key === "custom") {
        paperWidthCm = state().paperWidth;
        paperHeightCm = state().paperHeight;
      } else {
        paperWidthCm = state().paperSize.width;
        paperHeightCm = state().paperSize.height;
      }
      paperWidthPx = Math.max(paperWidthCm - (2 * state().paperMargin), 0);
      paperHeightPx = Math.max(paperHeightCm - (2 * state().paperMargin), 0);
      let line: any;
      let svgSize: any;
      /** Lista de códigos impressos que cabem dentro da largura da página */
      // @ts-ignore
      let lineList = registerList.reduce((previousList, currentRegister) => {
        // Busca o tamanho do SVG
        svgSize = extractWidthAndHeightFromSVGString(state().svgMap[getURIFromEntity(currentRegister)]);
        ["width", "height"].forEach(prop => svgSize[prop] = pxToCm(svgSize[prop]));
        // Se a lista de linhas estiver vazia (somente ocorrerá na primeira iteração) ou se não houver espaço na linha atual, adiciona nova linha com o SVG.
        if ((previousList.length < 1) || ((line.width + svgSize.width) > paperWidthPx)) {
          line = {
            svgList: [{
              svg: state().svgMap[getURIFromEntity(currentRegister)],
              width: svgSize.width,
              height: svgSize.height
            }],
            width: svgSize.width,
            height: svgSize.height
          };
          return previousList.concat(line);
        }
        // Se a lista de linhas tiver linhas e espaço, adiciona o SVG e atualiza os tamanhos.
        else {
          line.svgList.push({
            svg: state().svgMap[getURIFromEntity(currentRegister)],
            width: svgSize.width,
            height: svgSize.height
          });
          // Aumenta a largura ocupada pela linha com a largura do SVG
          line.width += svgSize.width;
          // Atualiza a altura da linha com a maior altura dos SVGs contidos
          line.height = Math.max(line.height, svgSize.height);
          return previousList;
        }
      }, []);
      let page: {
        lineList: {width: number, height: number}[],
        addPage: boolean,
        width: number,
        height: number,
      };
      /** Lista de linhas de código impresso que cabem dentro da altura da página */
      // @ts-ignore
      let pageList = lineList.reduce((previousList, currentLine) => {
        // Se a lista de páginas estiver vazia (somente ocorrerá na primeira iteração) ou se não houver espaço na página atual, adiciona nova página com a linha.
        if ((previousList.length < 1) || ((page.height + currentLine.height) > paperHeightPx)) {
          page = {
            lineList: [currentLine],
            addPage: previousList.length > 0,
            width: currentLine.width,
            height: currentLine.height
          };
          return previousList.concat(page);
        }
        // Se a lista de páginas tiver páginas e espaço, adiciona a linha e atualiza os tamanhos.
        else {
          page.lineList.push(currentLine);
          // Atualiza a largura da página com a maior largura das linhas contidas
          page.width = Math.max(page.width, currentLine.width);
          // Aumenta a altura ocupada pela página com a altura da linha
          page.height += currentLine.height;
          return previousList;
        }
      }, []);

      // Inicializa o PDF.
      let orientation = (paperWidthCm > paperHeightCm) ? "l" : "p";

      // @ts-ignore
      const { default: jsPDF } = await import("jspdf-yworks");

      // @ts-ignore
      const { default: svg2pdf } = await import("svg2pdf.js");

      let pdf = new jsPDF(orientation, "cm", [paperWidthCm, paperHeightCm]);
      // Inicializa div necessária para passar SVG para o PDF
      let svgDiv = document.createElement("div");
      // Inicializa variáveis de layout
      let x: number;
      let y: number;
      // Itera as páginas
      // @ts-ignore
      pageList.forEach(page => {
        // Adiciona páginas quando necessário
        if (page.addPage) {
          pdf.addPage([paperWidthCm, paperHeightCm], orientation);
        }
        // Reinicia a posição Y
        y = state().paperMargin;
        // Itera as linhas
        // @ts-ignore
        page.lineList.forEach(line => {
          // Reinicia a posição X
          x = state().paperMargin;
          // Itera os SVGs
          // @ts-ignore
          line.svgList.forEach(svgWrapper => {
            // Adiciona o SVG na página
            svgDiv.innerHTML = svgWrapper.svg;
            svg2pdf(svgDiv.firstChild, pdf, {
              xOffset: x,
              yOffset: y,
              scale: pxToCm(1)
            });
            // Atualiza a posição X
            x += svgWrapper.width;
          });
          // Atualiza a posição Y
          y += line.height;
        });
      });
      // Abre janela para salvar arquivo
      saveData(base64toBlob(pdf.output("datauristring").substring(51), "application/pdf"), "QR Codes.pdf");
      dispatch(appSpinnerHide("cadastroPrintAction saveResult"));
    }
  }, 0);
}

export type setConfigType = (format: string, height: string, margin: string, media: string, registerList: any[], registerType: any[],
  use: string) => (dispatch: dispatchTipo) => void;

/**
 * Guarda no reducer os dados necessários para gerar os códigos.
 * @param format
 * @param height
 * @param margin
 * @param media
 * @param registerList
 * @param registerType
 * @param use
 */
export const setConfig: setConfigType = (format, height, margin, media, registerList, registerType, use) => dispatch => {
  log("cadastroPrintAction setConfig", format, height, margin, media, registerList, registerType, use);

  dispatch({
    type: CADASTRO_PRINT,
    format,
    height,
    margin,
    media,
    registerList,
    registerType,
    use
  });

  dispatch({
    type: CADASTRO_SWITCH_UI,
    state: STATE_CADASTRO_PRINT_PREVIEW
  });
};

export type setValueType = (value: any, position: string) => (dispatch: dispatchTipo) => void;

/**
 * Define no *reducer* o valor informado na variável informada.
 * @param value novo valor da variável
 * @param position nome da variável
 */
export const setValue: setValueType = (value, position) => dispatch => {
  log("cadastroPrintAction setValue", value, position);

  dispatch({
    type: CADASTRO_PRINT,
    [position]: value
  });
};
