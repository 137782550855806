import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"

import { normalizeCombosFromDB } from "../../../utils/ComboUtils"
import { getDataFromDB } from "../../../utils/DatabaseUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { createSelect } from "../../../utils/SelectUtils"
import { generateSimpleTable } from "../../../utils/TableUtils"

import { cadastroLoadGrupos } from "../../../store/actions/cadastroAction"
import {
  atualizarComboAction
  , atualizarComboCandidatoAction
  , diminuirQuantidadeComboAction
  , aumentarQuantidadeComboAction
  , diminuirQuantidadeComboCandidatoAction
  , aumentarQuantidadeComboCandidatoAction
  , elegerCandidatoComboAction
  , elegerTodosCandidatosComboAction
  , removerComboAction
  , adicionarAListaDeCombosAction
} from "../../../store/actions/comboAction"
import { loadGrupoProdutoListWithProdutosVinculados } from "../../../store/actions/controleVenda/manutencao/gridGrupoProdutoAction"

import InputCustomizado from "../../UI/Input/InputCustomizado"
import { Button } from "../../UI/Button/Button"
import ToolbarTemplate from "../../UI/Template/ToolbarTemplate"
// import BuildButton from "../UI/Toolbar/BarraAcoesButton"
import BoxTemplate from "../../UI/Template/BoxTemplate"
import QuantidadeComponent from "../../UI/Input/QuantidadeComponent"
import DelButton from "../../UI/Button/DelButton"
import Icones from "../../../assets/icones/Icones"

class ComboProdutos_ extends React.Component {
  state = {
    grupoProdutosSelect: {}
    , grupoProdutosCandidatosSelect: {}
  }

  toolbar = () => {
    return <ToolbarTemplate attractToAdvance />
  }

  componentDidMount() {
    getDataFromDB("combos", {
      onSuccess: (response) => {
        const listaCombos = normalizeCombosFromDB(response)

        listaCombos.forEach(combo => {
          this.props.adicionarAListaDeCombos(combo)
        })
      }
    })
    const selectHandler = (selectedValue, update) => {
      const values = []

      for (const { value } of selectedValue) {
        values.push(value)
      }

      const obj = {
        value: values
      }

      update(obj)
    }

    const grupoProdutosSelect = createSelect({
      multi: true
      , onChange: (selectedValue) =>
        selectHandler(selectedValue, (grupoProdutosSelect) => this.setState({
          grupoProdutosSelect: {
            ...this.state.grupoProdutosSelect
            , ...grupoProdutosSelect
          }
        }))
    })

    const grupoProdutosCandidatosSelect = createSelect({
      multi: true,
      onChange: (selectedValue) =>
        selectHandler(selectedValue, (grupoProdutosCandidatosSelect) =>
          this.setState({
            grupoProdutosCandidatosSelect: {
              ...this.state.grupoProdutosCandidatosSelect
              , ...grupoProdutosCandidatosSelect
            }
          }))
    })

    this.props.loadGrupoProdutoListWithProdutosVinculados(list => {
      for (const { itemVenda } of list.newSaleItemList) {
        const produto = itemVenda.produto

        this.props.atualizarComboCandidato(
          produto.nome
          , produto.codigo
          , produto.preco
          , null
          , produto.grupoProdutoList[0].nome
        )
      }

      for (const grupoProduto of list.grupoProdutoList) {
        grupoProdutosSelect.options.push({
          value: grupoProduto.nome
          , label: grupoProduto.nome
        })

        grupoProdutosCandidatosSelect.options.push({
          value: grupoProduto.nome
          , label: grupoProduto.nome
        })
      }
    })

    this.setState({
      grupoProdutosSelect
      , grupoProdutosCandidatosSelect
    })
  }

  getNomeHeader = () => {
    return "Nome"
  }

  getAddButton = (clickHandler) => {
    const MdAdd = Icones.md.MdAdd

    return <div className={"centralize-content"} style={{ width: "100%", backgroundColor: "var(--color-text-light)" }}>
      <Button onClick={clickHandler} className="bar-button-primary blue-button fit-content width" style={{ height: "2.25em", fontSize: "1em" }}>
        <MdAdd />
      </Button>
    </div>
  }

  getProdutosCells = () => {
    const MdCheck = Icones.md.MdCheck

    const produtos = Object.keys(this.props.combo.produtosCandidatos).filter(codigo => {
      const value = this.props.combo.produtosCandidatos[codigo]

      if (!(this.state.grupoProdutosCandidatosSelect.value || []).length) {
        return true
      }

      return this.state.grupoProdutosCandidatosSelect.value.includes(value.grupoProduto)
    }).map(codigo => {
      const value = this.props.combo.produtosCandidatos[codigo]

      return Object.keys(value).filter(key => !["grupoProduto"].includes(key)).map(key => {
        if (key === "quantidade") {
          return <div className={`row`} style={{ display: "inline-flex", padding: ".25em" }}>
            <QuantidadeComponent
              quantidade={value.quantidade}
              quantidadeMinima={0}
              diminuirQuantidade={(event) => {
                event.stopPropagation()
                this.props.diminuirQuantidadeComboCandidato(codigo)
              }}
              aumentarQuantidade={(event) => {
                event.stopPropagation()
                this.props.aumentarQuantidadeComboCandidato(codigo)
              }}
            />
            <Button disabled={value.quantidade <= 0} className={"fit-content width gray-button"} style={{ paddingLeft: ".8em", paddingRight: ".8em", marginLeft: "0.5em" }} onClick={() => this.props.elegerCandidatoCombo(codigo)}>
              <MdCheck />
            </Button>
          </div>
        }

        return value[key]
      })
    })

    return produtos
  }

  getComboProdutosCells = () => {
    const comboProdutos = Object.keys(this.props.combo.produtos)
      .map(codigo => ({ codigo, value: this.props.combo.produtos[codigo] })).filter(v => {
        const value = v.value

        if (!(this.state.grupoProdutosSelect.value || []).length) {
          return true
        }

        return this.state.grupoProdutosSelect.value.includes(value.grupoProduto)
      }).map(v => {
        const value = v.value
        const codigo = v.codigo

        return [
          value.nome,
          value.preco,
          <div className={"row"} style={{ display: "inline-flex" }}>
            <div className={"column left"} style={{ padding: ".25em" }}>
              <QuantidadeComponent
                quantidade={value.quantidade}
                quantidadeMinima={1}
                diminuirQuantidade={(event) => {
                  event.stopPropagation()
                  this.props.diminuirQuantidadeCombo(codigo)
                }}
                aumentarQuantidade={(event) => {
                  event.stopPropagation()
                  this.props.aumentarQuantidadeCombo(codigo)
                }}
              />
            </div>
            <div className={"column right"} style={{ padding: ".25em" }}>
              <DelButton onClick={() => this.props.removerCombo(codigo)} />
            </div>
          </div>
        ]
      })

    return comboProdutos
  }

  render() {
    const MdCheck = Icones.md.MdCheck

    return <BoxTemplate toolbar={this.toolbar()} type={"edit"}>
      <InputCustomizado type={"text"} label={getStrings().name} placeholder={getStrings().comboNamePlaceholder} />

      <InputCustomizado inputType={"masked"} label={getStrings().price} placeholder={getStrings().comboPricePlaceholder} />

      {generateSimpleTable(
        [getStrings().name, getStrings().price, getStrings().amount],
        this.getComboProdutosCells(),
        {
          label: "Itens do Combo"
          //, filter: this.state.grupoProdutosSelect
        }
      )}

      {/* Gera espaço entre as tables */}
      <div />

      {generateSimpleTable(
        [getStrings().name, getStrings().price, <div className="row" style={{ display: "inline-flex", width: "100%" }}>
          <div className={`column  left`} style={{ flexDirection: "row", width: "50%", padding: 0, paddingLeft: "35px" }}>
            {getStrings().amount.toUpperCase()}
          </div>
          <div className={`column right`} style={{ flexDirection: "row", width: "50%", padding: 0, justifyContent: "flex-end" }}>
            {<Button
              className="bar-button gray-button font-color fit-content width"
              style={{ float: "right", height: "1.75em" }}
              onClick={this.props.elegerTodosCandidatosCombo}
            >
              <MdCheck />
            </Button>}
          </div>
        </div>],
        this.getProdutosCells(),
        {
          label: "Itens para adicionar ao combo"
          , filter: this.state.grupoProdutosCandidatosSelect
        }
      )}

    </BoxTemplate>
  }
}

const mapStateToProps = state => ({
  ...state.idiomaReducer
  , combo: state.comboReducer
  , controleVenda: state.controleVendaReducer
})

const mapDispatchToProps = dispatch => ({
  adicionarAListaDeCombos: (combo) => 
    dispatch(adicionarAListaDeCombosAction(combo))
  , removerCombo: (id) => 
    dispatch(removerComboAction({ id }))
  , elegerCandidatoCombo: (id) => 
    dispatch(elegerCandidatoComboAction({ id }))
  , elegerTodosCandidatosCombo: () => 
    dispatch(elegerTodosCandidatosComboAction())
  , diminuirQuantidadeCombo: (id) => 
    dispatch(diminuirQuantidadeComboAction({ id }))
  , aumentarQuantidadeCombo: (id) => 
    dispatch(aumentarQuantidadeComboAction({ id }))
  , diminuirQuantidadeComboCandidato: (id) => 
    dispatch(diminuirQuantidadeComboCandidatoAction({ id }))
  , aumentarQuantidadeComboCandidato: (id) => 
    dispatch(aumentarQuantidadeComboCandidatoAction({ id }))
  , atualizarCombo: (nome, codigo, preco, quantidade, grupoProduto) => 
    dispatch(atualizarComboAction({ nome, codigo, preco, quantidade: quantidade || 0, grupoProduto }))
  , atualizarComboCandidato: (nome, codigo, preco, quantidade, grupoProduto) => 
    dispatch(atualizarComboCandidatoAction({ nome, codigo, preco, quantidade: quantidade || 0, grupoProduto }))
  , cadastroLoadGrupos: (urlCadastro, onSuccess, onComplete) => 
    dispatch(cadastroLoadGrupos(urlCadastro, onSuccess, onComplete))
  , loadGrupoProdutoListWithProdutosVinculados: (callback) => 
    dispatch(loadGrupoProdutoListWithProdutosVinculados(callback))
  , goTo: (path, state) => 
    dispatch(push(path, state))
})


const ComboProdutos = connect(mapStateToProps, mapDispatchToProps)(ComboProdutos_)

export default ComboProdutos