import React from "react"
import { connect } from "react-redux"

import {
  PERMISSAO_VENDA_EXCLUSAO,
  PERMISSAO_VENDA_ENVIO,
  PERMISSAO_VENDA_PAGAMENTO,
  verificaPermissaoAcesso
} from "../../../utils/AuthUtils"
import { formataIdentificador } from "../../../utils/IndentificadorUtils"
import { log } from "../../../utils/LogUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import * as collectorActions from "../../../store/actions/collectorAction"

import "../vendas.css";

/**
 * Componente que monta a célula da *grid* a partir do coletor.
 */
class BuildCellFromCollector extends React.Component {

  /**
   * Método executado ao clicar sobre a célula. Exibe a venda vinculada ao cliente deste cartão.
   */
  handleClick = e => {
    log("BuildCellFromCollector handleClick")
    e.preventDefault()
    this.props.manageCollector(this.props.collector.clienteURI, getURIFromEntity(this.props.collector.venda), true)
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("BuildCellFromCollector render")

    /**
     * Se o cargo possui permissão para entrar na venda.
     */
    let enterCollectorAllowed = verificaPermissaoAcesso([PERMISSAO_VENDA_EXCLUSAO, PERMISSAO_VENDA_ENVIO, PERMISSAO_VENDA_PAGAMENTO])

    return <div className={"pure-u-1 pure-u-sm-1-2 pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-4"} >
      <div className={`${enterCollectorAllowed ? "clickable" : ""} card cliente`} onClick={enterCollectorAllowed ? this.handleClick : () => { }} >

        <div className="card-content  adjust">
          <h1 className="ButtonAlign">
            {this.props.collector.cliente.nome}
          </h1>
        </div>

        {/* H2 fora do content pois o overflow no texto do h1 acaba expandindo o tamanho do content e o h2 não centraliza no card corretamente */}
        <h2 className="ButtonAlign">
          {formataIdentificador(this.props.collector.cliente.identificador)}
        </h2>

      </div>
    </div>
  }
}

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
  return {
    manageCollector: (clienteURISelected, vendaURI, fromGrid) => dispatch(collectorActions.manageCollector(clienteURISelected, vendaURI, fromGrid))
  }
}

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(null, mapDispatchToProps)(BuildCellFromCollector)
