import React from "react"
import { connect } from "react-redux"
import { ClienteHttp } from "../../../apps/promo_app_frontend/nucleo/utils/utils"
import { getStrings } from "../../../utils/LocaleUtils"
import { getIdFromEntity } from "../../../utils/URIUtils"
import { ESTADO_ITEM_VENDA_CANCELADO, TIPO_ORIGEM_VENDA_IFOOD } from "../../../store/reducers/controleVenda/controleVendaReducer"
import { appDialogShow, appDialogHide } from "../../../store/actions/appAction"
import { cancelSale } from "../../../store/actions/controleVenda/manutencao/manutencaoVendaAction"
import { loadItensVendaAbertos } from "../../../store/actions/producaoAction"
import BarraAcoesButton from "../../UI/Toolbar/BarraAcoesButton"
import DialogPageable from "../../cadastros/dialog/DialogPageable"
import BuildField from "../../UI/Toolbar/BarraAcoesField"
import DialogCancelSaleSaleItem from "../../vendas/dialog/DialogCancelSaleSaleItem"
import Icones from "../../../assets/icones/Icones"
import { pedidosTelaEstadoTipo } from "./PedidosTela"
import paginacaoTipo from "../../Paginacao/Tipos/paginacaoTipo"

type pedidosToolbarTipo = {
  atualizarEstado: (novoEstado: any) => void | undefined
  , buscarEstado: () => pedidosTelaEstadoTipo
  , dispatch: (carga: any) => any
  , permissoesCargo: string[]
  , producaoReducer: any
  , imprimirSelecionados: (selecionados: Array<string>) => void | undefined
  , modoSelecao: boolean
  , paginacao?: paginacaoTipo
};

const BuildButton = (props: any) => <BarraAcoesButton {...props} topStyle={{ whiteSpace: 'nowrap', ...props.topStyle }} />;

class ComandasToolbar extends React.Component<pedidosToolbarTipo> {

  isPrimary: boolean = true;

  iconStyle = { width: 'auto', height: 'auto', maxWidth: '1em', maxHeight: '1em', verticalAlign: 'middle', paddingLeft: '0.3em', paddingRight: '0.3em', paddingBottom: '0.1em' };

  BotaoImprimir = () => {
    const AiOutlinePrinter = Icones.ai.AiOutlinePrinter;

    return <BuildButton
      cellClassName='bar-button-primary-white'
      isPrimary={this.isPrimary}
      key='imprimir'
      id='imprimir-button'
      buttonGridClassName='bar-item'
      label={<AiOutlinePrinter style={this.iconStyle} />}
      texto={getStrings().print}
      title={getStrings().print}
      isDisabled={!this.props.buscarEstado().selecao.length || this.props.buscarEstado().setAllDisabled}
      commandMethod={() => {
        this.props.imprimirSelecionados(this.props.buscarEstado().selecao.map((id) => ClienteHttp.getApi(`vendas/${id}`)));
      }}
    />;
  };

  BotaoEncerrar = () => {
    const CgPlayListRemove = Icones.cg.CgPlayListRemove;

    const estado = this.props.buscarEstado();
    const vendasSelecionadas = this.props.producaoReducer.vendaList.filter((venda: any) => estado.selecao.includes(getIdFromEntity(venda)) && venda.estadoItensVenda !== ESTADO_ITEM_VENDA_CANCELADO);
    const origensIfood = vendasSelecionadas.map((venda: any) => venda.origemVenda.tipoOrigemVenda).filter((tipoOrigem: any) => tipoOrigem === TIPO_ORIGEM_VENDA_IFOOD);

    return <BuildButton
      cellClassName='bar-button-primary-white'
      isPrimary={this.isPrimary}
      key='encerrar'
      id='encerrar-button'
      buttonGridClassName='bar-item'
      label={<CgPlayListRemove style={this.iconStyle} />}
      texto={getStrings().cancelSale()}
      title={getStrings().cancelSale()}
      isDisabled={!vendasSelecionadas.length || estado.setAllDisabled || !this.props.permissoesCargo.includes('vendaExclusao')}
      commandMethod={() => {
        this.props.dispatch(appDialogShow(<DialogCancelSaleSaleItem
          tipoOrigemVenda={origensIfood.length ? TIPO_ORIGEM_VENDA_IFOOD : undefined}
          handleConfirm={cancelReason => {
            for (const venda of vendasSelecionadas) {
              this.props.dispatch(cancelSale(ClienteHttp.getApi(`vendas/${getIdFromEntity(venda)}`), cancelReason, {
                then: () => {
                  this.props.atualizarEstado({ modo: '', selecao: [] });
                }
              }));
            }
          }} />,
          '',
          {
            overflow: 'unset'
          },
          undefined,
          () => { },
          () => { },
          {
            overflow: 'unset'
          }
        ));
      }}
    />;
  };

  BotaoPagamento = () => {
    const MdAttachMoney = Icones.md.MdAttachMoney;

    const estado = this.props.buscarEstado();
    const vendasSelecionadas = this.props.producaoReducer.vendaList.filter((venda: any) => estado.selecao.includes(getIdFromEntity(venda)) && venda.estadoItensVenda !== ESTADO_ITEM_VENDA_CANCELADO);

    return <BuildButton
      cellClassName='bar-button-primary-white'
      isPrimary={this.isPrimary}
      key='pagamento'
      id='pagamento-button'
      buttonGridClassName='bar-item'
      label={<MdAttachMoney style={this.iconStyle} />}
      texto={getStrings().payment}
      title={getStrings().payment}
      isDisabled={!vendasSelecionadas.length || estado.setAllDisabled || !this.props.permissoesCargo.includes('vendaPagamento')}
      commandMethod={() => {
        this.props.atualizarEstado({ modo: 'pagamento_venda_content_logica' });
      }}
    />;
  };

  BotaoOrdenar = () => {
    const MdSort = Icones.md.MdSort;

    return <BuildButton
      cellClassName={`bar-button-primary-white ${this.props.buscarEstado().ordenarIsOpen ? 'pressionado' : ''}`}
      isDisabled={this.props.buscarEstado().setAllDisabled}
      isPrimary={this.isPrimary}
      key='ordenar'
      id='ordenar-button'
      buttonGridClassName='bar-item'
      label={<MdSort style={this.iconStyle} />}
      texto={this.props.buscarEstado().ordenarIsOpen ? getStrings().closeFechar : getStrings().sort}
      title={getStrings().sort}
      commandMethod={() => this.props.atualizarEstado({ ordenarIsOpen: !this.props.buscarEstado().ordenarIsOpen })}
    />;
  }

  BotaoFiltrar = () => {
    const AiOutlineSearch = Icones.ai.AiOutlineSearch;

    return <BuildButton
      cellClassName={`bar-button-primary-white ${this.props.buscarEstado().filtrarIsOpen ? 'pressionado' : ''}`}
      isDisabled={this.props.buscarEstado().setAllDisabled}
      isPrimary={this.isPrimary}
      key='filtrar'
      id='filtrar-button'
      buttonGridClassName='bar-item'
      label={<AiOutlineSearch style={this.iconStyle} />}
      texto={this.props.buscarEstado().filtrarIsOpen ? getStrings().closeFechar : getStrings().filtrate}
      title={getStrings().filtrate}
      commandMethod={() => this.props.atualizarEstado({ filtrarIsOpen: !this.props.buscarEstado().filtrarIsOpen })}
    />;
  }

  BotaoAlternar = () => {
    const MdEventNote = Icones.md.MdEventNote;
    const MdEventAvailable = Icones.md.MdEventAvailable;

    return <BuildButton
      cellClassName='bar-button-primary-white'
      isDisabled={this.props.buscarEstado().setAllDisabled}
      isPrimary={this.isPrimary}
      key='alternar-abertos-faturados'
      id='alternar-abertos-faturados-button'
      buttonGridClassName='bar-item'
      label={this.props.buscarEstado().faturada ? <MdEventNote style={this.iconStyle} /> : <MdEventAvailable style={this.iconStyle} />}
      texto={getStrings()[this.props.buscarEstado().faturada ? 'openedSale' : 'paidSale'](true)}
      title='Alternar entre pedidos Abertos e Faturados'
      commandMethod={() => {
        const faturada = !this.props.buscarEstado().faturada;
        this.props.atualizarEstado({ faturada });
      }}
    // commandMethod={() => {
    //     this.props.dispatch(appSpinnerShow('vendaLista'));

    //     this.buscaVendas(undefined, undefined, { faturada: !this.props.buscarEstado().faturada })
    //         .then((resposta) => {
    //             const vendaList = resposta.data?._embedded?.resources || [];
    //             const faturada = !this.props.buscarEstado().faturada;
    //             this.props.atualizarEstado({ vendaList, faturada, paginacao: { links: resposta.data._links, page: resposta.data.page } });
    //         })
    //         .finally(() => {
    //             this.props.dispatch(appSpinnerHide('vendaLista'));
    //         });
    // }}
    />;
  }

  BotaoSelecionar = () => {
    const AiOutlineSelect = Icones.ai.AiOutlineSelect;

    return <BuildButton
      cellClassName={`bar-button-primary-white ${this.props.buscarEstado().modoSelecao ? 'pressionado' : ''}`}
      isDisabled={this.props.buscarEstado().setAllDisabled}
      isPrimary={this.isPrimary}
      key='selecionar'
      id='selecionar-button'
      buttonGridClassName='bar-item'
      label={<AiOutlineSelect style={this.iconStyle} />}
      texto={this.props.buscarEstado().modoSelecao ? getStrings().cancel : getStrings().select}
      title='Abre seleção de cards de tipos variados'
      commandMethod={() => { this.props.atualizarEstado({ modoSelecao: !this.props.buscarEstado().modoSelecao, selecao: [], selecionarTudo: false }); }}
    />;
  }

  BotaoSelecionarTudo = () => {
    if (!this.props.buscarEstado().modoSelecao) {
      return null;
    }

    const MdCheck = Icones.md.MdCheck;

    return <div
      id='selecionar-tudo-button'
      key='selecionar-tudo'
      title='Selecionar tudo'
      className='bar-button-primary-white-selecao'
      onClick={() => {
        const estado = this.props.buscarEstado();
        const vendaListIds = this.props.producaoReducer.vendaList.map((venda: any) => getIdFromEntity(venda));

        this.props.atualizarEstado({
          selecao: !estado.selecionarTudo ? vendaListIds : []
          , selecionarTudo: !estado.selecionarTudo
        });
      }}
    >
      {this.props.buscarEstado().selecionarTudo
        ? <MdCheck key='__selecao_check_icon__' style={{ width: '1.8em', height: '1.8em' }} />
        : null
      }
    </div>;
  }

  BotaoAdicionar = () => {
    const MdAdd = Icones.md.MdAdd;

    return <BuildButton
      isDisabled={this.props.buscarEstado().setAllDisabled}
      isPrimary={this.isPrimary}
      key='salvar'
      id='salvar-button'
      buttonGridClassName='bar-item'
      label={<>
        <MdAdd />
        {getStrings().add}
      </>}
      title={getStrings().add}
      commandMethod={() => {
        this.props.atualizarEstado({ modo: 'editar' });
      }}
    />
  }

  BotaoSalvar = () => {
    const MdCheck = Icones.md.MdCheck;

    return <BuildButton
      isDisabled={this.props.buscarEstado().setAllDisabled}
      isPrimary={this.isPrimary}
      key='salvar'
      id='salvar-button'
      buttonGridClassName='bar-item'
      label={<>
        <MdCheck />
        {getStrings().finish}
      </>}
      title={getStrings().finish}
      commandMethod={() => {
        this.props.atualizarEstado({ modoSelecao: false, selecao: [] });
      }}
    />
  }

  BotoesPedidosAbertos = (props: { buscarEstado: () => pedidosTelaEstadoTipo, botaoEncerrar: () => React.ReactElement, botaoPagamento: () => React.ReactElement }) => {
    if (props.buscarEstado().faturada) {
      return null;
    }

    const BotaoEncerrar = props.botaoEncerrar;
    const BotaoPagamento = props.botaoPagamento;

    return <div style={{ display: 'flex', flexFlow: 'nowrap', flex: '1 1 auto' }} key={'BotoesPedidosAbertos'}>
      <BotaoEncerrar />
      <BotaoPagamento />
    </div>;
  }

  /**
* Método executado ao clicar no botão "<<" (primeira página).
* Carrega os cadastros correspondentes.
*/
  handleNavFirst = () => {
    this.onNavigate(this.props.paginacao?.links?.first?.href);
  }

  /**
   * Método executado ao clicar no botão "<" (página anterior).
   * Carrega os cadastros correspondentes.
   */
  handleNavPrev = () => {
    this.onNavigate(this.props.paginacao?.links?.prev?.href);
  }

  /**
   * Método executado ao clicar no botão ">" (próxima página).
   * Carrega os cadastros correspondentes.
   */
  handleNavNext = () => {
    this.onNavigate(this.props.paginacao?.links?.next?.href);
  }

  /**
   * Método executado ao clicar no botão ">>" (última página).
   * Carrega os cadastros correspondentes.
   */
  handleNavLast = () => {
    this.onNavigate(this.props.paginacao?.links?.last?.href);
  }

  /**
   * Método executado ao clicar sobre o field que exibe a página atual "2/14 (página 2 de 14)".
   * Exibe uma dialog para definir o número de registros que será exibido por página.
   */
  showDialog = () => {
    this.props.dispatch(appDialogShow(
      <DialogPageable
        //ref={(ref: any) => this.dialog = ref}
        updatePageSize={(pageSize: number) => this.onNavigate(undefined, pageSize)}
        appDialogHide={this.props.dispatch(appDialogHide)}
        numeroRegistros={this.props.paginacao?.page?.size}
        forceNumeroRegistros={true}
      />,
      getStrings().registers
    ));
  }

  onNavigate = (link?: string, pageSize?: number) => {
    this.props.dispatch(loadItensVendaAbertos(link, pageSize));
  }

  // buscaVendas = (link?: string, pageSize?: number, params?: any) => {
  //     const parameters = link ? null : {
  //         params: {
  //             page: this.props.paginacao?.page?.number,
  //             size: pageSize ? pageSize : this.props.paginacao?.page?.size,
  //             ...params,
  //         }
  //     };
  //     const configs = getHeaders(undefined, undefined, parameters);

  //     return ClienteHttp.obterClienteHttp().get(link ? link : getApi('vendas/findToOrders'), configs);
  // }

  Navegacao = () => {
    const paginaAtual = (typeof this.props.paginacao?.page?.number !== 'number' ? -1 : (this.props.paginacao?.page?.number || 0)) + 1;
    const numeroDePaginas = this.props.paginacao?.page?.totalPages || 0
    const pageInfo = paginaAtual + '/' + numeroDePaginas;

    const FiChevronLeft = Icones.fi.FiChevronLeft;
    const FiChevronRight = Icones.fi.FiChevronRight;
    const FiChevronsLeft = Icones.fi.FiChevronsLeft;
    const FiChevronsRight = Icones.fi.FiChevronsRight;

    const iconStyle = { width: 'auto', height: 'auto', maxWidth: '1em', maxHeight: '1em', verticalAlign: 'middle', paddingLeft: '0.3em', paddingRight: '0.3em', paddingBottom: '0.1em' };

    return <div className='bar-navegacao' >
      {/* Botões de navegação */}
      <BuildButton id="first-page-button" buttonGridClassName='bar-item' key='first' isPrimary={false} commandMethod={this.handleNavFirst} isDisabled={!((this.props.paginacao?.links?.first) && (this.props.paginacao?.links?.prev))} label={<FiChevronsLeft style={iconStyle} />} title={getStrings().firstPage} />
      <BuildButton id="previous-page-button" buttonGridClassName='bar-item' key='prev' isPrimary={false} commandMethod={this.handleNavPrev} isDisabled={!(this.props.paginacao?.links?.prev)} label={<FiChevronLeft style={iconStyle} />} title={getStrings().previous} />

      {/* Página atual */}
      <BuildField id='pageInfo' key='pageInfo' commandMethod={this.showDialog} label={pageInfo} />

      {/* Botões de navegação */}
      <BuildButton id="next-page-button" buttonGridClassName='bar-item' key='next' isPrimary={false} commandMethod={this.handleNavNext} isDisabled={!(this.props.paginacao?.links?.next)} label={<FiChevronRight style={iconStyle} />} title={getStrings().next} />
      <BuildButton id="last-page-button" buttonGridClassName='bar-item' key='last' isPrimary={false} commandMethod={this.handleNavLast} isDisabled={!((this.props.paginacao?.links?.last) && (this.props.paginacao?.links?.next))} label={<FiChevronsRight style={iconStyle} />} title={getStrings().lastPage} />
    </div>
  }

  render() {
    if (!this.props.paginacao) {
      return null;
    }

    const BotoesPedidosAbertos = () => <this.BotoesPedidosAbertos botaoEncerrar={this.BotaoEncerrar} botaoPagamento={this.BotaoPagamento} buscarEstado={this.props.buscarEstado} />;

    return <div id='toolbar' className='toolbar' >
      <div className='toolbar-content'>
        <div>
          <div className='bar-container justify-content-center' >
            <this.Navegacao />
            <div className='bar-botoes' >
              <this.BotaoSelecionarTudo />
              <this.BotaoSelecionar />
              <this.BotaoImprimir />
              <BotoesPedidosAbertos />
              <this.BotaoOrdenar />
              <this.BotaoFiltrar />
              <this.BotaoAlternar />
            </div>
          </div>
        </div>
      </div>
    </div >
  }
}

const mapStateToProps = (state: any) => ({
  producaoReducer: state.producaoReducer
});
const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ComandasToolbar);
