import React from 'react';

class FormValuesTemplate<P = {}, cadastroTipo = {}, S = {}, SS = any> extends React.Component<P, S, SS> {
    getFormData: () => any = () => { return {}; };
    checkRequired: (newData: cadastroTipo) => boolean = (newData) => false;
    wasChangedFromCurrent: (newData: cadastroTipo) => boolean = (newData) => false;
    wasChangedFromInitial: (newData: cadastroTipo, oldData: cadastroTipo) => boolean = (newData, oldData) => false;
    handleGravar: () => void | undefined = () => {};
}

export default FormValuesTemplate;
