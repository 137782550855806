import { updateObject } from "../../utils/ObjectUtils"

import * as actionTypes from "../actions/actionTypes"

/**
 * Estado Inicial, obrigatório no Reducer.
 */
export const initialState = {

  /**
   * Se o código resultante está dentro do limite de caracteres.
   */
  codeLengthValid: true

  /**
   * Prefixo do código.
   */
  , codePrefix: ""

  /**
   * Prévia do código.
   */
  , codePreview: "0–0"

  /**
   * Sufixo do código.
   */
  , codeSuffix: ""

  /**
   * Se o nome resultante está dentro do limite de caracteres.
   */
  , nameLengthValid: true

  /**
   * Prefixo do nome.
   */
  , namePrefix: ""

  /**
   * Prévia do nome.
   */
  , namePreview: "0–0"

  /**
   * Sufixo do nome.
   */
  , nameSuffix: ""

  /**
   * Último número da sequência que será gerada.
   */
  , numberFinal: 0

  /**
   * Primeiro número da sequência que será gerada.
   */
  , numberInitial: 0

  /**
   * Caractere inserido para garantir que todos os números terão o mesmo comprimento. Por exemplo, 0.
   */
  , numberPadding: ""

  , tipoOrigemVenda: undefined
}

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.CADASTRO_SETUP:

      let newState = updateObject(state, {
        ...state,
        ...action
      })

      getPreview(newState)

      // Basta controlar a construção do action no outro arquivo.
      return newState
    default: return state
  }
}

const getPreview = state => {
  // Se alguma variável for undefined, null etc, não executa o método.
  if (["codePrefix", "codeSuffix", "namePrefix", "nameSuffix", "numberInitial", "numberFinal", "numberPadding"].some(variable =>
    (!state[variable]) && (state[variable] !== "") && (state[variable] !== 0))) {
    return
  }
  // Trata o usuário informar o número final menor que o inicial.
  let numberInitial = Math.min(state.numberInitial, state.numberFinal)
  let numberFinal = Math.max(state.numberInitial, state.numberFinal)
  // Verifica quantos caracteres de preenchimento são necessários
  let numberLength = `${numberFinal}`.length
  // Gera as prévias
  let codeFinalPreview = state.codePrefix + numberFinal + state.codeSuffix
  let nameFinalPreview = state.namePrefix + numberFinal + state.nameSuffix
  state.codePreview = state.codePrefix + `${numberInitial}`.padStart(numberLength, state.numberPadding) + state.codeSuffix + "–" + codeFinalPreview
  state.namePreview = state.namePrefix + `${numberInitial}`.padStart(numberLength, state.numberPadding) + state.nameSuffix + "–" + nameFinalPreview
  // Valida o código e nome gerado
  state.codeLengthValid = codeFinalPreview.length <= 20
  state.nameLengthValid = nameFinalPreview.length <= 40
}

export default reducer
