import React from "react"
import { connect } from "react-redux"
import { reduxStateType } from "../../../utils/reduxUtils/reduxStateType"
import { getURIFromEntity } from "../../../utils/URIUtils"
import BotaoChecado from "../../UI/botoes/BotaoChecado/BotaoChecado"

import OptionButtons from "../optionButtons/OptionButtons"

export type TableDataType = {
  align: "Button"|"Center"|"Number"|"Text",
  children: React.ReactNode,
  widthInPx: number,

  className?: string,
  style?: React.CSSProperties,
}

export function TableData(props: TableDataType) {
  const width: string = `${props.widthInPx}px`;
  const style = props.style || {};
  const className = props.className ? (" " + props.className) : "";

  return <td className={`Lighter ${props.align}Align${className}`} style={{width, ...style}} >
    {props.children}
  </td>;
}

export type TableRowType = {
  cadastro: Record<string,any>,
  onDelete: (cadastro: Record<string,any>) => void,
  onUpdate: (cadastro: Record<string,any>) => void,
  children: React.ReactNode[],

  cantDisable?: boolean,
};

function TableRow_({cantDisable = false, ...props}: TableRowType) {
  let id = `${getURIFromEntity(props.cadastro)}/Checkbox`;

  return <tr>
    {/* Opções de manutenção */}
    <OptionButtons className="ButtonAlign" onUpdate={(cadastro: Record<string,any>) => {
      props.onUpdate(cadastro);
    }} onDelete={props.onDelete} cadastro={props.cadastro} />
    {/* Dados do cadastro */}
    {cantDisable
      ? null
      : <td className="Lighter ButtonAlign" width="5%" >
        <BotaoChecado key={`__${id}__`} desativado={!props.cadastro.enabled} />
      </td>
    }
    {props.children}
  </tr>;
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
function mapStateToProps(state: reduxStateType) {
  const props = {
    ...state.idiomaReducer,
  };

  return props;
}

export const TableRow = connect(mapStateToProps)(TableRow_);
export default TableRow;