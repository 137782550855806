import React from "react"
import { connect } from "react-redux"
import { getStrings } from "../../../utils/LocaleUtils"
import { paySales } from "../../../store/actions/controleVenda/pagamentoVendasAction"
import BarraAcoesButton from "../../UI/Toolbar/BarraAcoesButton"
import Icones from "../../../assets/icones/Icones"
import TelaPagamentoVendasContent from "../../vendas/pagamento/TelaPagamentoVendasContent"
import { dispatchTipo } from "../../../store/actions/acaoTemplate"
import { modoTipo } from "./PedidosTela"

export type PedidosTelaConteudoDoModalTipo = {
  dispatch: dispatchTipo,
  modo: modoTipo,
  setState: (state: any) => void | undefined,
}

const iconStyle = { width: 'auto', height: 'auto', maxWidth: '1em', maxHeight: '1em', verticalAlign: 'middle', paddingLeft: '0.3em', paddingRight: '0.3em', paddingBottom: '0.1em' };

function PedidosTelaConteudoDoModal({ dispatch, modo, setState }: PedidosTelaConteudoDoModalTipo) {
  if (modo !== 'pagamento_venda_content') {
    return null;
  }
  const MdAttachMoney = Icones.md.MdAttachMoney;

  return <div key='conteudo-do-modal'>
    <BarraAcoesButton topStyle={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }}
      key={'pagar-key'}
      isPrimary
      commandMethod={() => {
        dispatch(paySales(undefined, undefined, {
          cb: () => {
            setState({ modo: '', selecao: [] });
          }
        }));
      }}
      label={<MdAttachMoney style={iconStyle} />}
      texto={getStrings().payment}
    />
    <TelaPagamentoVendasContent pureStyle={{ width: '100%', margin: '1em' }} />
  </div>
}

const mapStateToProps = (state: any) => {
  const props = {

  };

  return props;
}

const mapDispatchToProps = (dispatch: dispatchTipo) => {
  const props = {
    dispatch
  }

  return props;
}


export default connect(mapStateToProps, mapDispatchToProps)(PedidosTelaConteudoDoModal)