import React from "react"

import { getAppEmpresa } from "../../../utils/AppUtils"
import { forEach, removeDuplicates } from "../../../utils/ArrayUtils"
import { isEmpresaColetor, isEmpresaPainel } from "../../../utils/CompanyUtils"
import { getHeaders } from "../../../utils/HeadersUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { roundNumber } from "../../../utils/NumberUtils"
import { project } from "../../../utils/ObjectUtils"
import { getStateType } from "../../../utils/reduxUtils/getStateType"
import { urlDatabase } from "../../../utils/SecureConnectionUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import * as actionTypes from "../actionTypes"
import { dispatchTipo } from "../acaoTemplate"
import {
  isSaleProductKeyFromSale
  , STATE_CONTROLE_VENDA_GRID_ORIGENS
  , STATE_CONTROLE_VENDA_MANUTENCAO_VENDA
  , STATE_CONTROLE_VENDA_GET_SALE_FROM_CANCEL
  , STATE_CONTROLE_VENDA_PAGAR_VENDAS
  , STATE_CONTROLE_VENDA_PAGAR_ORIGENS
} from "../../reducers/controleVenda/controleVendaReducer"
import {
  PAYMENT_CANCEL_SALE
  , PAYMENT_CANCEL_SALESOURCE
  , PAYMENT_PAYED_VALUE
} from "../../reducers/controleVenda/pagamentoVendasReducer"
import {
  appDialogShow
  , appNotificationShow
  , appSpinnerHide
  , appSpinnerShow
  , axios
} from "../appAction"
import * as cadastroActions from "../cadastroAction"
import * as controleVendaActions from "./controleVendaAction"
import {
  NIVEL_USUARIO_SEM_EMPRESA_VENDA
} from "../../reducers/empresaSelectorReducer"
import * as manutencaoVendaActions from "./manutencao/manutencaoVendaAction"
import {
  MANUTENCAO_CONTENT_PAGAMENTO
} from "./manutencao/manutencaoVendaAction"

import DialogCancelPayment from "../../../components/cancel/DialogCancelPayment"
import DialogFederalTaxNumber from "../../../components/vendas/dialog/DialogFederalTaxNumber"


// Para enviar fuso horário para o servidor
const moment = require("moment")

/**
 * Para guardar no *reducer* os pagamentos de venda que foram selecionados para serem cancelados.
 */
let pagamentoVendaCancelProjection = {
  venda: {
    cliente: {
      _links: { self: { href: false } }
    },
    origemVenda: {
      _links: { self: { href: false } }
    },
    _links: { self: { href: false } }
  },
  _links: { self: { href: false } }
}

/**
 * Atualiza a lista de Origens de Venda armazenada no estado com dados recebidos do servidor.
 * @param origensVendaPagar lista de Origens de Venda armazenada no estado
 * @param vendasServidor dados recebidos do servidor
 */
const cloneOrigensVendaServidor = (origensVendaPagar: any[], vendasServidor?: any[]) => {
  log('pagamentoVendasAction cloneOrigensVendaServidor', origensVendaPagar, vendasServidor)

  // Se não há Vendas para atualizar os dados, retorna as Origens de Venda como estão
  if (!vendasServidor) {
    return origensVendaPagar
  }
  // Se não há Origens de Venda no estado, retorna uma nova com as Vendas recebidas (não deve ocorrer)
  if (!origensVendaPagar) {
    return [{
      vendaList: vendasServidor
    }]
  }

  let venda

  // Prepara a nova lista de Origens de Venda
  return origensVendaPagar.map(origemVenda => {

    // Faz uma cópia desvinculada da Origem de Venda
    return Object.assign({}, origemVenda, {

      // Se Origem de Venda tinha lista de Vendas, verifica se há Vendas para serem substituídas pela versão mais nova
      ...(origemVenda.vendaList ? {
        // Itera a lista de Vendas antigas
        vendaList: origemVenda.vendaList.map((vendaOld: any) => {

          venda = vendaOld
          // Itera a lista de Vendas novas
          vendasServidor.some(vendaNew => {
            // Se for a mesma Venda substitui pela nova
            if (getURIFromEntity(vendaOld) === getURIFromEntity(vendaNew)) {
              venda = vendaNew
              return true
            }
            // Senão continua com a antiga
            return false
          })
          return venda
        })
      } : {})
    })
  })
}

/**
 * Carrega a lista de Origens de Venda com Vendas a pagar para o estado.
 * @param origensVendaPagar
 */
const loadVendasPagar = (origensVendaPagar: any[], options: Record<string, unknown>) => (dispatch: dispatchTipo) => {
  log('pagamentoVendasAction loadVendasPagar', origensVendaPagar)

  dispatch({
    type: actionTypes.CONTROLE_VENDA_LOAD_ORIGEM_VENDA_LIST_PAGAR,
    origemVendaList: origensVendaPagar,
    ...options
  })
}

/**
 * Exibe a tela para o pagamento de Vendas, independente de suas Origens de Venda.
 * Busca no servidor as vendas abertas de cada origem
 * @param vendas Lista de Origens de Venda com Vendas a pagar.
 */
export const exibeTelaPagamentoOrigensVenda = (origensVendaPagar: any[]) => (dispatch: dispatchTipo) => {
  log('pagamentoVendasAction exibeTelaPagamentoOrigensVenda', origensVendaPagar)

  let map = {}
  let list = origensVendaPagar.map(origemVenda => {
    // @ts-ignore: sempre é string
    map[origemVenda._links.self.href] = origemVenda
    return origemVenda._links.self.href
  })

  dispatch(appSpinnerShow('exibeTelaPagamentoOrigensVenda'))
  axios().post(
    urlDatabase + '/origemVendas/findVendasAbertasFromOrigensVenda',
    list,
    getHeaders()

  ).then(response => {

    dispatch(
      exibeTelaPagamentoVendas(Object.keys(map).map((key: string) => ({
        // @ts-ignore
        ...map[key],
        vendaList: response.data.content[key]
      }))
        // , true
      ))

  }).catch(error =>
    dispatch(controleVendaActions.errorResponse(error))

  ).finally(() =>
    dispatch(appSpinnerHide('exibeTelaPagamentoOrigensVenda'))
  )
}

/**
 * Exibe a tela para o pagamento de Vendas, independente de suas Origens de Venda.
 * @paramx origensVendaPagar Lista de Origens de Venda com Vendas a pagar.
 */
export const exibeTelaPagamentoVendas = (origensVendaPagar: any[], options = { cb: () => { } }) => (dispatch: dispatchTipo, getState: getStateType) => {
  log('pagamentoVendasAction exibeTelaPagamentoVendas', origensVendaPagar)
  const { pagamentoVendasReducer, empresaSelectorReducer, controleVendaReducer } = getState()

  dispatch(loadVendasPagar(origensVendaPagar, {
    clearTotal: actionTypes.REDUCER_DEFAULT,
    commission: {
      value: pagamentoVendasReducer.percentualComissaoAtual,
      action: actionTypes.REDUCER_HOLD
    },
    discount: {
      type: pagamentoVendasReducer.discountType,
      value: pagamentoVendasReducer.discountValue,
      action: actionTypes.REDUCER_HOLD
    },
    newSalePaymentItemMap: actionTypes.REDUCER_SET,
    nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
    ramoEmpresa: empresaSelectorReducer.ramoEmpresa,
    remainingAmountMap: actionTypes.REDUCER_SET,
    sentSaleItemList: actionTypes.REDUCER_SET,
    sentSalePaymentItemMap: actionTypes.REDUCER_SET
  }))
  dispatch({
    type: actionTypes.CONTROLE_VENDA_SHOW_PAGAMENTO_VENDAS,
    state: (controleVendaReducer.state === STATE_CONTROLE_VENDA_GRID_ORIGENS)
      ? STATE_CONTROLE_VENDA_PAGAR_ORIGENS : STATE_CONTROLE_VENDA_PAGAR_VENDAS
  })

  window.setTimeout(() => {
    options.cb && options.cb()
  }, 500)
}

/**
 * Método que atualiza o percentual de comissão sobre o valor do pagamento.
 * @param newPercentualComissao
 */
export const updatePercentualComissao = (newPercentualComissao: number) => (dispatch: dispatchTipo, getState: getStateType) => {
  log('pagamentoVendasAction updatePercentualComissao', newPercentualComissao)

  dispatch({
    type: actionTypes.PAGAMENTO_VENDAS_UPDATE,
    percentualComissaoAtual: newPercentualComissao
  })
  // Atualiza o valor total dos itens de pagamento, que depende da comissão.
  dispatch({
    // Controla se limpa ou não a lista de itens de venda enviados, o que ocorre se estiver no pagamento de várias vendas.
    type: (getState().controleVendaReducer.state === STATE_CONTROLE_VENDA_MANUTENCAO_VENDA)
      ? actionTypes.CONTROLE_VENDA_UPDATE_VENDA
      : actionTypes.CONTROLE_VENDA_UPDATE_PERCENTUAL_COMISSAO,
    clearTotal: actionTypes.REDUCER_UPDATE,
    commission: {
      value: getState().pagamentoVendasReducer.percentualComissaoAtual,
      action: actionTypes.REDUCER_SET
    },
    discount: {
      type: getState().pagamentoVendasReducer.discountType,
      value: getState().pagamentoVendasReducer.discountValue,
      action: actionTypes.REDUCER_HOLD
    },
    newSalePaymentItemMap: actionTypes.REDUCER_UPDATE,
    nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
    ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
    remainingAmountMap: actionTypes.REDUCER_HOLD,
    salePaymentItemKeyList: Object.keys(getState().controleVendaReducer.newSalePaymentItemMap || {}),
    sentSaleItemList: actionTypes.REDUCER_HOLD,
    sentSalePaymentItemMap: actionTypes.REDUCER_HOLD
  })
}

/**
 * Atualiza o percentual/valor de desconto.
 * @param newDiscount 
 */
export const updateDiscount = (newDiscount: number) => (dispatch: dispatchTipo, getState: getStateType) => {
  log('pagamentoVendasAction updateDiscount', newDiscount)

  dispatch({
    type: actionTypes.PAGAMENTO_VENDAS_UPDATE,
    discountValue: newDiscount
  })
  dispatch({
    type: actionTypes.CONTROLE_VENDA_UPDATE_DISCOUNT,
    clearTotal: actionTypes.REDUCER_UPDATE,
    commission: {
      value: getState().pagamentoVendasReducer.percentualComissaoAtual,
      action: actionTypes.REDUCER_SET
    },
    discount: {
      type: getState().pagamentoVendasReducer.discountType,
      value: newDiscount,
      action: actionTypes.REDUCER_HOLD
    },
    newSalePaymentItemMap: actionTypes.REDUCER_UPDATE,
    nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
    ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
    remainingAmountMap: actionTypes.REDUCER_HOLD,
    salePaymentItemKeyList: Object.keys(getState().controleVendaReducer.newSalePaymentItemMap || {}),
    sentSaleItemList: actionTypes.REDUCER_HOLD,
    sentSalePaymentItemMap: actionTypes.REDUCER_HOLD
  })
}

/**
 * Método executado ao clicar no botão para realizar o encerramento da venda.
 */
export const confirmaEncerramentoVenda = (vendaList: any[], isIgnoraConfirmacaoEncerramento: boolean = false, isIgnorarItensNaoPagos: boolean = false) => (dispatch: dispatchTipo, getState: getStateType) => {
  log('pagamentoVendasAction confirmaEncerramentoVenda', vendaList, isIgnoraConfirmacaoEncerramento, isIgnorarItensNaoPagos)

  // Mensagem caso exista valor de pagamento pendente.
  if (getState().controleVendaReducer.hasRemainingAmount && (!isIgnorarItensNaoPagos)) {

    dispatch(appNotificationShow(getStrings().closeSaleIgnorePending(), actionTypes.APP_NOTIFICATION_TYPE_WARNING_QUESTION,
      getStrings().pendingPayment, () => dispatch(confirmaEncerramentoVenda(vendaList, true, true))))
    return
  }

  // Mensagem para confirmação de encerramento
  if (!isIgnoraConfirmacaoEncerramento) {

    dispatch(appNotificationShow(getStrings().closeSales(), actionTypes.APP_NOTIFICATION_TYPE_INFO_QUESTION,
      getStrings().salesClosure(), () => dispatch(controleVendaActions.closeVendas(vendaList))))
    return
  }
  dispatch(controleVendaActions.closeVendas(vendaList))
}

/**
 * Se todos os produtos de todas as vendas estiverem pagos, exibe uma *dialog* para encerrar as vendas.
 * 
 * Só deve ser chamado se já foi verificado que todos os produtos foram pagos.
 * 
 * A *dialog* só é exibida uma vez. Para ser exibida de novo, é necessário adicionar mais produtos na venda e pagar esses produtos, voltando ao estado em que todos os produtos foram pagos.
 */
export const showProductsPaidCloseSalesDialog = (
  // /*vendaList, */closeSaleAction?: any[]
) => (dispatch: dispatchTipo, getState: getStateType) => {
  log('pagamentoVendasAction showProductsPaidCloseSalesDialog'
    // , closeSaleAction
  )
  // Se houver valor pendente para pagar, não deve executar esse método.
  if (getState().controleVendaReducer.hasRemainingAmount && getState().controleVendaReducer.state !== STATE_CONTROLE_VENDA_PAGAR_VENDAS) {
    return
  }
  // Inicializa a variável que verifica se foram adicionados produtos
  let productsAdded: boolean = false;
  // Itera as origens de venda
  (getState().controleVendaReducer.origemVendaList || []).forEach((origemVenda: any) =>
    // Itera as vendas
    (origemVenda.vendaList || []).forEach((venda: any) => {
      // Se este método já foi chamado, foi armazenado na venda a quantidade de itens de venda.
      // Se esta variável for diferente, foram adicionados itens desde que este método foi chamado.
      if (venda.itemVendaListLength !== (venda.itemVendaList || []).length) {
        // Marca que itens foram adicionados
        productsAdded = true
        // Atualiza a variável na venda
        venda.itemVendaListLength = (venda.itemVendaList || []).length
      }
    })
  )
  // Se não há valor pendente e foram adicionados produtos, encerra a(s) venda(s).
  if (productsAdded) {
    // Antigamente, isso só ocorria para empresas do ramo Painel.
    // As outras recebiam uma mensagem perguntando se queriam ter as vendas encerradas.
    const origemVenda = (getState().controleVendaReducer.origemVendaList || [])[0]
    let uri = getURIFromEntity((origemVenda.vendaList || [])[0])
    const origemVendaList = (getState().controleVendaReducer.origemVendaList || [])
    // Também testa se a venda está persistida, porque este método pode ser chamado durante transições de telas.
    if (uri && (uri !== 'nova_venda')) {
      switch (getState().controleVendaReducer.state) {
        // Encerra as vendas em que todos os itens foram pagos
        case STATE_CONTROLE_VENDA_PAGAR_VENDAS:
          let listVendas: any[] = []
          origemVendaList.forEach((origemVenda: any) => (origemVenda?.vendaList || [])
            .forEach(
              (venda: any) => {
                let listItens: any[] = [];
                (venda.itemVendaList || [])
                  .forEach((itemVenda: any) => listItens.push(itemVenda.produto.codigo))

                let listItensPagos: any[] = [];
                (venda.pagamentoVendaList || [])
                  .forEach((pagamentoVenda: any) => (pagamentoVenda.itemPagamentoVendaList || [])
                    .forEach((itemPagamentoVenda: any) => listItensPagos.push(itemPagamentoVenda.produto.codigo)))

                if (listItens.every(item => listItensPagos.includes(item))) {
                  listVendas.push(venda)
                }
              }
            )
          )
          // ((((getState().controleVendaReducer.origemVendaList || [])[0] || {}).vendaList) || [])
          //     .forEach(
          //         venda => {
          //             let listItens = []
          //             (venda.itemVendaList || [])
          //                 .forEach(itemVenda => listItens.push(itemVenda.produto.codigo))

          //             let listItensPagos = []
          //             (venda.pagamentoVendaList || [])
          //                 .forEach(pagamentoVenda => (pagamentoVenda.itemPagamentoVendaList || [])
          //                     .forEach(itemPagamentoVenda => listItensPagos.push(itemPagamentoVenda.produto.codigo)))

          //             if (listItens.every(item => listItensPagos.includes(item))) {
          //                 listVendas.push(venda)
          //             }
          //         }
          //     )

          if (listVendas.length > 0)
            dispatch(controleVendaActions.closeVendas(listVendas))
          break
        case STATE_CONTROLE_VENDA_MANUTENCAO_VENDA:
          // Encerra uma venda
          dispatch(manutencaoVendaActions.closeVenda())
          break
        default:
          // Junta todas as vendas de todas as origens em uma única lista
          dispatch(controleVendaActions.closeVendas(
            // Junta todas as vendas de todas as origens em uma única lista
            getState().controleVendaReducer.origemVendaList.reduce((previousVendaList: any[], currentOrigemVenda: any) =>
              previousVendaList.concat(currentOrigemVenda.vendaList), [])
          ))
          break
      }
    }
  }
}

/**
 * Atualiza o tipo de desconto no `reducer`.
 */
export const setDiscountType = (discountType: string) => (dispatch: dispatchTipo, getState: getStateType) => {
  log('pagamentoVendasAction setDiscountType', { discountType })

  dispatch({
    type: actionTypes.PAGAMENTO_VENDAS_UPDATE,
    discountType
  })
  dispatch({
    type: actionTypes.CONTROLE_VENDA_UPDATE_DISCOUNT,
    clearTotal: actionTypes.REDUCER_UPDATE,
    commission: {
      value: getState().pagamentoVendasReducer.percentualComissaoAtual,
      action: actionTypes.REDUCER_SET
    },
    discount: {
      type: discountType,
      value: getState().pagamentoVendasReducer.discountValue,
      action: actionTypes.REDUCER_HOLD
    },
    newSalePaymentItemMap: actionTypes.REDUCER_UPDATE,
    nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
    ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
    remainingAmountMap: actionTypes.REDUCER_HOLD,
    salePaymentItemKeyList: Object.keys(getState().controleVendaReducer.newSalePaymentItemMap || {}),
    sentSaleItemList: actionTypes.REDUCER_HOLD,
    sentSalePaymentItemMap: actionTypes.REDUCER_HOLD
  })
}

export const setFormaPagamento = (formaPagamento: any) => (dispatch: dispatchTipo) => {
  log('pagamentoVendasAction setFormaPagamento', { formaPagamento })

  dispatch({
    type: actionTypes.PAGAMENTO_VENDAS_UPDATE,
    paymentMethod: formaPagamento
  })
}

/**
 * Atualiza no `reducer` uma lista de novos itens de pagamento de venda (ainda não enviados à retaguarda).
 * 
 * @param salePaymentItemKeyList identifica os itens de pagamento de venda no `reducer`
 * @param sign se deve incrementar (`+1`) ou decrementar (`-1`)
 * @param all se deve selecionar toda ou nenhuma da quantidade do item
 */
export const setNewSalePaymentItemAmount = (salePaymentItemKeyList: string, sign: number, all: boolean) => (dispatch: dispatchTipo, getState: getStateType) => {
  log('pagamentoVendasAction setNewSalePaymentItemAmount', { salePaymentItemKeyList, sign, all })

  dispatch({
    type: actionTypes.PAGAMENTO_VENDAS_UPDATE_NEW_SALE_PAYMENT_ITEM,
    all,
    clearTotal: actionTypes.REDUCER_UPDATE,
    commission: {
      value: getState().pagamentoVendasReducer.percentualComissaoAtual,
      action: actionTypes.REDUCER_HOLD
    },
    discount: {
      type: getState().pagamentoVendasReducer.discountType,
      value: getState().pagamentoVendasReducer.discountValue,
      action: actionTypes.REDUCER_HOLD
    },
    newSalePaymentItemMap: actionTypes.REDUCER_UPDATE,
    nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
    ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
    remainingAmountMap: actionTypes.REDUCER_HOLD,
    salePaymentItemKeyList,
    sentSaleItemList: actionTypes.REDUCER_HOLD,
    sentSalePaymentItemMap: actionTypes.REDUCER_HOLD,
    sign
  })
}

/**
 * Método que realiza o pagamento dos itens selecionados na tela de pagamento.
 * Não muda de tela.
 * @param askedForIndividualTaxpayerID se já foi exibido o *dialog* perguntado pelo CPF
 * @param individualTaxpayerID CPF informado pelo cliente, caso informado
 */
export const paySales = (askedForIndividualTaxpayerID?: boolean, individualTaxpayerID?: string, options = ({ cb: () => { } })) => (dispatch: dispatchTipo, getState: getStateType) => {
  log('pagamentoVendasAction paySales', { askedForIndividualTaxpayerID, individualTaxpayerID })

  // Se ainda não exibiu o dialog para perguntar pelo CPF
  // e a empresa está configurada para perguntar.
  if ((!askedForIndividualTaxpayerID) && getState().empresaSelectorReducer.perguntaCpf) {
    // Exibe pergunta
    dispatch(appDialogShow(<DialogFederalTaxNumber />, getStrings().payment))
    // Faz mais nada. Método será chamado novamente quando o CPF for inserido.
    return
  }
  // Se não deve perguntar, segue normalmente.

  dispatch(appSpinnerShow('paySales'))

  // Gera os dados a serem enviados
  let payload: any = {};
  let vendaURI: string | undefined
  let itemPagamentoVendaList: any[] | undefined
  let item: any | undefined

  // Itera as Origens de Venda com Vendas a pagar
  getState().controleVendaReducer.origemVendaList.filter((origemVenda: any) => origemVenda.vendaList).forEach((origemVenda: any) =>

    // Filtra as vendas por somente aquelas com itens de pagamento com quantidade selecionada
    origemVenda.vendaList.filter(
      (venda: any) => {
        // Busca a chave que indexa a venda
        vendaURI = getURIFromEntity(venda)
        // Retorna se existem itens de pagamento desta venda
        return Object.keys(getState().controleVendaReducer.newSalePaymentItemMap).some(
          newSalePaymentItemKey => isSaleProductKeyFromSale(newSalePaymentItemKey, vendaURI)
            && (getState().controleVendaReducer.newSalePaymentItemMap[newSalePaymentItemKey] || {}).quantidade
        )
      }
      // Itera as vendas a pagar
    ).forEach((venda: any) => {

      // Busca a chave que indexa a venda
      vendaURI = getURIFromEntity(venda)

      // Adiciona os pagamentos persistidos
      payload[vendaURI] = (venda.pagamentoVendaList || []).map((pagamentoVenda: any) => getURIFromEntity(pagamentoVenda))

      // Inicializa a lista de itens de pagamento de venda
      itemPagamentoVendaList = [];
      // Itera os novos itens de pagamento de venda
      Object.values(getState().controleVendaReducer.newSalePaymentItemMap).filter(
        (newSalePaymentItem: any) => isSaleProductKeyFromSale(newSalePaymentItem.key, vendaURI) && newSalePaymentItem.quantidade
      ).forEach((newSalePaymentItem: any) => {
        // Gera um item de pagamento de venda somente usando os dados que são necessários
        item = {
          produto: newSalePaymentItem.produto.produtoURI,
          nomeProduto: newSalePaymentItem.nomeProduto,
          quantidade: newSalePaymentItem.produto.quantidadePesada
            ? newSalePaymentItem.weightedAmount
            : newSalePaymentItem.quantidade,
          precoUnitario: newSalePaymentItem.precoUnitario,
          precoCustoUnitario: newSalePaymentItem.precoCustoUnitario,
          valorTotal: newSalePaymentItem.valorTotal,
          valorCustoTotal: roundNumber(newSalePaymentItem.precoCustoUnitario * (newSalePaymentItem.produto.quantidadePesada
            ? newSalePaymentItem.weightedAmount
            : newSalePaymentItem.quantidade), 2),
          dataHora: newSalePaymentItem.dataHora,
          fusoHorario: newSalePaymentItem.fusoHorario,
          livre: newSalePaymentItem.livre,
        }
        // Se for um item de mais de um produto com quantidade pesada e mesmo peso, deve adicionar um item para cada produto.
        if (newSalePaymentItem.produto.quantidadePesada && (newSalePaymentItem.quantidade > 1)) {
          // Ajusta o valor total do item.
          // Dado que é gerado apenas um item para vários produtos, ele vem com o valor somado.
          // Ele deve ser dividido pelo número de produtos,
          // o que não é um problema dado que o desconto foi calculado para cada produto.
          item.valorTotal = roundNumber(newSalePaymentItem.valorTotal / newSalePaymentItem.quantidade, 2)

          // @ts-ignore
          forEach(newSalePaymentItem.quantidade, () => itemPagamentoVendaList.push(item))
        }
        // Se não, adiciona só um item.
        else {
          // @ts-ignore
          itemPagamentoVendaList.push(item)
        }
      })

      // Adiciona o novo pagamento de venda
      payload[vendaURI].push({
        empresa: getAppEmpresa(),
        venda: vendaURI,
        dataHora: new Date(),
        fusoHorario: moment().format('Z'), // -03:00, +00:00, +05:00, etc

        ...(getState().pagamentoVendasReducer.paymentMethod
          ? { formaPagamento: getURIFromEntity(getState().pagamentoVendasReducer.paymentMethod) }
          : {}),

        valorProdutos: getState().controleVendaReducer.newProductTotalMap[vendaURI],

        valorComissao: roundNumber(getState().controleVendaReducer.newProductWithCommissionTotalMap[vendaURI]
          - getState().controleVendaReducer.newProductTotalMap[vendaURI], 2),

        valorDesconto: roundNumber(getState().controleVendaReducer.newProductWithCommissionTotalMap[vendaURI]
          - getState().controleVendaReducer.newProductWithCommissionWithDiscountTotalMap[vendaURI], 2),

        valorTotal: getState().controleVendaReducer.newProductWithCommissionWithDiscountTotalMap[vendaURI],

        itemPagamentoVendaList,

        cpf: individualTaxpayerID
      })
    })
  )

  let origemVendaURI = null
  // Se for um usuário sem vínculo com empresa
  if (!getState().empresaSelectorReducer.cargo) {
    // Busca o URI da origem de venda para fazer o papel de empresa
    origemVendaURI = getURIFromEntity((getState().controleVendaReducer.origemVendaList || []).find(() => true) || {})
  }

  let payloadTratado = {}

  Object.entries(payload).forEach(([key, value]) => {
    // @ts-ignore
    payloadTratado[key] = value.filter(element => element)
  })

  // Envia a requisição para o servidor
  axios().post(
    urlDatabase + '/vendas/payVendas',
    payloadTratado,
    getHeaders({
      ...(origemVendaURI ? {
        noCompany: true,
        origemVenda: origemVendaURI
      } : {})
    })
  )
    .then(response => {
      // Exibe notificação
      dispatch(appNotificationShow(getStrings().paymentRegistered,
        actionTypes.APP_NOTIFICATION_TYPE_SUCCESS, getStrings().success))

      // Limpa os novos itens de pagamento
      dispatch(loadVendasPagar(
        cloneOrigensVendaServidor(getState().controleVendaReducer.origemVendaList, response.data.content),
        {
          clearTotal: actionTypes.REDUCER_UPDATE,
          commission: {
            value: getState().pagamentoVendasReducer.percentualComissaoAtual,
            action: actionTypes.REDUCER_HOLD
          },
          discount: {
            type: getState().pagamentoVendasReducer.discountType,
            value: getState().pagamentoVendasReducer.discountValue,
            action: actionTypes.REDUCER_HOLD
          },
          newSalePaymentItemMap: actionTypes.REDUCER_SET,
          nivelUsuarioSemEmpresa: NIVEL_USUARIO_SEM_EMPRESA_VENDA,
          ramoEmpresa: getState().empresaSelectorReducer.ramoEmpresa,
          remainingAmountMap: actionTypes.REDUCER_SET,
          sentSaleItemList: (getState().controleVendaReducer.state === STATE_CONTROLE_VENDA_MANUTENCAO_VENDA)
            ? actionTypes.REDUCER_SET
            : actionTypes.REDUCER_DEFAULT,
          sentSalePaymentItemMap: actionTypes.REDUCER_SET
        }))

      // Limpa o valor de desconto
      dispatch(updateDiscount(0))
    })
    .catch(error =>
      dispatch(controleVendaActions.errorResponse(error))
    )
    .finally(() => {
      dispatch(appSpinnerHide('paySales'))
      options.cb && window.setTimeout(() => {
        options.cb()
      }, 1000)
    })
}

/**
 * Adiciona no *reducer* o URI de um pagamento de venda para ser cancelado.
 * @param salePaymentToCancel `href` de um pagamento de venda
 */
export const addSalePaymentToCancel = (salePaymentToCancelList: string[]) => (dispatch: dispatchTipo) => {
  log('pagamentoVendasAction addSalePaymentToCancel', { salePaymentToCancelList })
  dispatch({
    type: actionTypes.PAGAMENTO_VENDAS_ADD_CANCEL,
    salePaymentToCancelList: (salePaymentToCancelList || []).map(salePaymentToCancel => project(salePaymentToCancel, pagamentoVendaCancelProjection))
  })
}

/**
 * Adiciona no *reducer* o URI de um pagamento de venda para ser cancelado.
 * // salePaymentToCancelList `href` de um pagamento de venda
 */
export const addAllSalePaymentToCancel = () => (dispatch: dispatchTipo, getState: getStateType) => {
  log('pagamentoVendasAction addAllSalePaymentToCancel')
  dispatch({
    type: actionTypes.PAGAMENTO_VENDAS_ADD_CANCEL,
    salePaymentToCancelList: (getState().cadastroReducer.cadastroList || []).map((pagamentoVenda: any) => getURIFromEntity(pagamentoVenda))
  })
}

/**
 * Remove do *reducer* o URI de um pagamento de venda para ser cancelado.
 * @param salePaymentToCancelList `href` de um pagamento de venda
 */
export const clearSalePaymentToCancel = (salePaymentToCancelList?: any[]) => (dispatch: dispatchTipo) => {
  log('pagamentoVendasAction clearSalePaymentToCancel', { salePaymentToCancelList })
  dispatch({
    type: actionTypes.PAGAMENTO_VENDAS_CLEAR_CANCEL
  })
}

/**
 * Remove do *reducer* o URI de um pagamento de venda para ser cancelado.
 * @param salePaymentToCancelList `href` de um pagamento de venda
 */
export const removeSalePaymentToCancel = (salePaymentToCancelList: any[]) => (dispatch: dispatchTipo) => {
  log('pagamentoVendasAction removeSalePaymentToCancel', { salePaymentToCancelList })
  dispatch({
    type: actionTypes.PAGAMENTO_VENDAS_REMOVE_CANCEL,
    salePaymentToCancelList
  })
}

/**
 * Exibe *dialog* para escolher entre cancelar:
 * * o pagamento selecionado
 * * todos os pagamentos da venda do pagamento selecionado
 * * todas as vendas abertas da origem de venda da venda do pagamento selecionado.
 * @param history para abrir a manutenção de venda quando for empresa do ramo Painel e foi(ram) cancelado(s) pagamento(s) de uma mesma venda
 */
export const showCancelDialog = (history: any) => (dispatch: dispatchTipo) => {
  log('pagamentoVendasAction showCancelDialog')

  // @ts-ignore: DialogCancelPayment js
  dispatch(appDialogShow(<DialogCancelPayment history={history} />))
}

/**
 * Cancela um pagamento.
 * @param scope se serão cancelados somente os pagamentos, as vendas também ou todas as vendas abertas de uma origem
 * @param history para abrir a manutenção de venda quando for empresa do ramo Painel e foi(ram) cancelado(s) pagamento(s) de uma mesma venda
 * @param {Boolean} confirmation indica que o usuário já confirmou que deseja realizar a ação
 */
export const cancelPayment = (scope: string, history: any, confirmation: boolean) => (dispatch: dispatchTipo, getState: getStateType) => {
  log('pagamentoVendasAction cancelPayment', { scope, history, confirmation })

  let salePaymentToCancelList = getState().pagamentoVendasReducer.salePaymentToCancelList || []

  let singularPayment = salePaymentToCancelList.length < 2

  // Caso seja apenas uma venda, seu URI será necessário para abrir sua manutenção de venda
  let vendaURI: string | undefined

  // Usa um Set para remover duplicados automaticamente
  let singularSale = removeDuplicates(salePaymentToCancelList
    .map((salePaymentToCancel: any) => vendaURI = getURIFromEntity(salePaymentToCancel.venda))).length < 2

  let saleContainer = isEmpresaColetor(getState().empresaSelectorReducer.ramoEmpresa) ? 'cliente' : 'origemVenda'

  // Usa um Set para remover duplicados automaticamente
  let singularSaleSource = removeDuplicates(salePaymentToCancelList
    .map((salePaymentToCancel: any) => getURIFromEntity((salePaymentToCancel?.venda || {})[saleContainer]))).length < 2

  // Verifica se é a 1ª ou a 2ª vez em que o método é executado
  if (!confirmation) {
    // Exibe a notificação solicitando a confirmação da exclusão
    dispatch(appNotificationShow(getStrings().cancelPaymentConfirmation(scope, singularPayment, singularSale, singularSaleSource),
      actionTypes.APP_NOTIFICATION_TYPE_INFO_QUESTION, getStrings().cancelPayment,
      () => dispatch(cancelPayment(scope, history, true))
    ))
    return
  }

  dispatch(appSpinnerShow('cancelPayment'))

  axios().post(
    urlDatabase + `/pagamentoVendas/cancel${(scope === PAYMENT_CANCEL_SALE) ? 'Sale' : (scope === PAYMENT_CANCEL_SALESOURCE) ? 'SaleSource' : ''}`,
    salePaymentToCancelList.map((salePaymentToCancel: any) => getURIFromEntity(salePaymentToCancel)),
    getHeaders()
  )
    .then(() => {
      dispatch(appNotificationShow(getStrings().paymentCancelled(singularPayment), actionTypes.APP_NOTIFICATION_TYPE_SUCCESS, getStrings().success))
      // Se for empresa do ramo Painel e somente uma venda foi selecionada, abre a manutenção de venda com essa venda.
      if (isEmpresaPainel(getState().empresaSelectorReducer.ramoEmpresa) && singularPayment && scope !== PAYMENT_CANCEL_SALE) {
        dispatch(manutencaoVendaActions.setManutencaoContent(MANUTENCAO_CONTENT_PAGAMENTO))
        dispatch({
          type: actionTypes.CONTROLE_VENDA_SHOW_MANUTENCAO_VENDA,
          fromCallState: STATE_CONTROLE_VENDA_GET_SALE_FROM_CANCEL,
          state: STATE_CONTROLE_VENDA_MANUTENCAO_VENDA,
          vendaURI
        })
      }
      dispatch(cadastroActions.cadastroNavigate(getState().cadastroReducer.links.self.href, getState().cadastroReducer.pageSize))
    }).catch(error =>
      dispatch(controleVendaActions.errorResponse(error)))
    .finally(() =>
      dispatch(appSpinnerHide('cancelPayment')))
}

export const updatePayedValueAction = (payload: number) => {
  return {
    type: PAYMENT_PAYED_VALUE,
    payload,
  }
}

export const exibeTelaPagamentoPedido = (origensVenda: any[], origemVendaVendaList: any[], options = { cb: () => { } }) => (dispatch: dispatchTipo) => {
  log('pagamentoVendasAction exibeTelaPagamentoPedido', origensVenda, origemVendaVendaList)

  let map = {}
  origensVenda.forEach((origemVenda) => {
    // @ts-ignore
    map[origemVenda._links.self.href] = origemVenda
  })

  dispatch(appSpinnerShow('exibeTelaPagamentoPedido'))
  axios().post(
    urlDatabase + '/origemVendas/findVendasPagamentoPedido',
    origemVendaVendaList,
    getHeaders()

  ).then(response => {

    dispatch(exibeTelaPagamentoVendas(Object.keys(map).map(key => ({
      // @ts-ignore
      ...map[key],
      vendaList: response.data.content[key]
    }))
      // , true
    ))

  }).catch(error =>
    dispatch(controleVendaActions.errorResponse(error))

  ).finally(() => {
    dispatch(appSpinnerHide('exibeTelaPagamentoPedido'))

    window.setTimeout(() => {
      options.cb && options.cb()
    }, 50)
  }
  )
}