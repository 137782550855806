import * as actionTypes from "../actions/actionTypes"
import { updateObject } from "../../utils/ObjectUtils"
import { setStrings, global_strings as strings, defaultLocale } from "../../utils/LocaleUtils"

export interface idiomaReducerType {
  type?: string
  locale: string
}

/**
 * Estado Inicial, obrigatório no Reducer.
 */
const initialState: idiomaReducerType = {
  locale: defaultLocale?.ietfTag || "pt-BR",
}

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {idiomaReducerType} state 
 * @param {idiomaReducerType} action 
 */
const reducer = (currentState: idiomaReducerType = initialState, action: idiomaReducerType) => {

  switch (action.type) {
    case actionTypes.I18N:
      setStrings(strings[action.locale])
      // Basta controlar a construção do action no outro arquivo.
      const newState = updateObject(currentState, { locale: action.locale })
      return newState
    default: return currentState
  }
}

export default reducer
