import criarReducer, { acaoTipo } from "../../factory/criarReducer";
import tiposDeAcoes from "./produtosActions";

type promocaoPorQuantidadeTipo = {
  tipo: 'por_quantidade',
  precoPromo: number,
  quantidadeASerVendida: number,
};

type promocaoPorTempoTipo = {
  tipo: 'por_tempo',
  precoPromo: number,
  dataInicio: string,
  dataFim: string,
};

export type promocaoTipo = promocaoPorQuantidadeTipo | promocaoPorTempoTipo;

export type produtoTamanhoTipo = {id: number, preco: number, quantidadeMaximaSabores: number, tamanho: {_links: string, nome: string, codigo: string}};

export type produtoTipo = {
  id: string,
  key: string,
  codigo: string,
  codigoImpresso: string,
  nome: string,
  descricao: string | null,
  // codigo: string,
  fonteImagem: string,
  fonteImagemTipo: string,
  mostrar: boolean,
  preco: number,
  promocao?: promocaoTipo,
  quantidadeEmEstoque: number,
  grupoProdutosCodigo: number,
  produtoTamanhoList: Array<produtoTamanhoTipo>,
  unidadeUri?: string,
}

export type produtosTipo = {
  [key: string]: produtoTipo,
}

export type produtosReducerTipo = {
  produtos: produtosTipo,
};

const estadoInicial: produtosReducerTipo = {
  produtos: {},
};

const acoesNomeadas: any = {
  [tiposDeAcoes.POPULAR_PRODUTOS]: function({produtos}: {produtos: produtosTipo}, estadoAtual: produtosReducerTipo) {
    const novoEstado: produtosReducerTipo = {
      ...estadoAtual,
      produtos: {
        ...estadoAtual.produtos,
        ...produtos,
      },
    }

    return novoEstado;
  },
  [tiposDeAcoes.ADICIONAR_PRODUTO]: function(produto: produtoTipo, estadoAtual: produtosReducerTipo) {
    
    const produtos: any = {
      ...estadoAtual.produtos,
      [produto.id]: {...produto, key: `__key_item_produto_${produto.key}__`,},
    };

    const novoEstado: produtosReducerTipo = {
      ...estadoAtual,
      produtos,
    }

    return novoEstado;
  },
  [tiposDeAcoes.REMOVER_PRODUTO]: function({id}: {id: string}, estadoAtual: produtosReducerTipo) {
    const produtos: any = {...estadoAtual.produtos};

    delete produtos[id];

    const novoEstado: produtosReducerTipo = {
      ...estadoAtual,
      produtos,
    }

    return novoEstado;
  },
  [tiposDeAcoes.ATUALIZAR_PRODUTO]: function({id, produto}: {id: string, produto: produtoTipo}, estadoAtual: produtosReducerTipo) {
    const produtos: any = {
      ...estadoAtual.produtos,
      [id]: produto,
    };

    const novoEstado: produtosReducerTipo = {
      ...estadoAtual,
      produtos,
    }

    return novoEstado;
  },
  [tiposDeAcoes.ATUALIZAR_MULTIPLOS_PRODUTOS]: function({prods}: { prods: produtosTipo}, estadoAtual: produtosReducerTipo) {
    const produtos: produtosTipo = {
      ...estadoAtual.produtos,
      ...prods,
    };

    const novoEstado: produtosReducerTipo = {
      ...estadoAtual,
      produtos,
    }

    return novoEstado;
  },
};

export default function(estado: produtosReducerTipo = estadoInicial, acao: acaoTipo) {
  return criarReducer(estado, acao, acoesNomeadas);
};
