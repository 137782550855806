//@ts-check
/**
 * 
 * @param {Array<any>} array
 * @param {string} nomeItem
 * @returns {Array<any>}
 */
export default (array, nomeItem) => array.sort((a, b) => {
    if(a[nomeItem] < b[nomeItem]) {
      return -1;
    }

    if (a[nomeItem] > b[nomeItem]) {
      return 1;
    }

    return 0;
});
