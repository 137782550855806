import { updateAppNomeUsuario, updateAppTamanhoPadraoPaginacao } from "../../utils/AppUtils"
import * as errorUtils from "../../utils/ErrorUtils"
import { getHeaders } from "../../utils/HeadersUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { getApi, urlDatabase } from "../../utils/SecureConnectionUtils"

import * as actionTypes from "./actionTypes"
import { appNotificationShow, appSpinnerHide, appSpinnerShow, axios } from "./appAction"
import * as authActions from "./authAction"
import * as cadastroActions from "./cadastroAction"
import * as producaoActions from "./producaoAction"


/**
 * Ação que reseta o estado do usuarioReducer, fazendo com que fique com o estado inicial. 
 */
export const resetState = () => {
  log('usuarioAction resetState');
  return {
    type: actionTypes.USUARIO_RESET_STATE
  };
};


/**
 * Método que busca os dados do Usuário logado. 
 * Executado quando a tela for carregada. 
 */
export const getUsuario = () => dispatch => {
  log('usuarioAction getUsuario');

  dispatch(appSpinnerShow('getUsuario'));

  dispatch(usuarioLoadingStart());

  axios().get(
    urlDatabase + '/getUsuarioLogado',
    getHeaders()
  )
    .then(response =>
      dispatch(usuarioLoadingSuccess(response))
    )
    .catch(error =>
      dispatch(usuarioLoadingFail(error))
    )
    .finally(() =>
      dispatch(appSpinnerHide('getUsuario'))
    );
};

/**
 * Método que mostra o Spinner enquanto o Usuário é buscado no BackEnd. 
 * Executado ao iniciar o carregamento do componente. 
 */
const usuarioLoadingStart = () => {
  log('usuarioAction usuarioLoadingStart');
  return {
    type: actionTypes.USUARIO_LOADING_START
  };
};

/**
 * Método que atribui o Usuário ao estado, para que seja apresentada na tela. 
 * Executado quando o carregamento do componente for finalizado com sucesso. 
 * @param {*} response 
 */
const usuarioLoadingSuccess = response => dispatch => {
  log('usuarioAction usuarioLoadingSuccess', response);
  dispatch({
    type: actionTypes.USUARIO_LOADING_SUCCESS,
    usuario: response.data
  });
};

/**
 * Método que apresenta uma Notificação com o Erro. 
 * Executado quando houver falha no carregamento do componente. 
 * @param {*} error 
 */
const usuarioLoadingFail = error => dispatch => {
  log('usuarioAction usuarioLoadingFail', error);
  dispatch(errorUtils.requestErrorHandlerDefault(error));

  dispatch({
    type: actionTypes.USUARIO_LOADING_FAIL,
    error: error
  });
};

const updateF3Usuario = (response, f3Token) => {
  if (f3Token) {
    const body = {
      usuario: {
        login: { email: response.data.login.email },
      },
      facebookToken: f3Token,
    };

    axios().post(getApi('setF3Account'), body, null, (response) => log(response))
      .then(response =>
        log('usuarioAction usuarioLoadingFail', { response })
      )
      .catch(error => log('usuarioAction usuarioLoadingFail', 'error', { error }));
  }
};

/**
 * Método que atualiza o cadastro no servidor conforme os dados recebidos por parâmetro. 
 * @param {*} usuario
 * @param {*} token usado ao cadastrar usuário através do cadastro de cargo,
 * depois que o usuário acessa o *link* recebido por email,
 * para identificar o usuário na retaguarda
 * @param {*} email usado para, no mesmo caso acima, logar o usuário após ele finalizar o seu cadastro
 */
export const updateUsuario = (usuario, { token, email, f3Token }) => dispatch => {
  log('usuarioAction updateUsuario', { usuario, token, email, f3Token });

  dispatch(appSpinnerShow('updateUsuario'));

  dispatch(usuarioUpdateSubmit());

  axios().patch(
    `${urlDatabase}/updateUsuarioLogado${token ? `/${token}` : ''}`,
    usuario,
    getHeaders()
  )
    .then(response => {
      updateF3Usuario(response, f3Token);

      // Se método foi chamado para concluir cadastro de usuário criado através do cadastro de cargo
      token
        // Executa a ação de selecionar um cargo
        ? dispatch(authActions.authenticationSubmit({ ...usuario.login, email }))
        // Senão, só atualiza os dados do usuário.
        : dispatch(usuarioUpdateSuccess(response))
    })
    .catch(error =>
      dispatch(usuarioUpdateFail(error))
    )
    .finally(() =>
      dispatch(appSpinnerHide('updateUsuario'))
    );
};

/**
 * Método que mostra o Spinner enquanto o cadastro é salvo no BackEnd. 
 */
const usuarioUpdateSubmit = () => {
  log('usuarioAction usuarioUpdateSubmit');
  return {
    type: actionTypes.USUARIO_UPDATE_SUBMIT
  };
};

/**
 * Método executado ao finalizar a ação com sucesso. 
 * @param {*} response 
 */
const usuarioUpdateSuccess = response => dispatch => {
  log('usuarioAction usuarioUpdateSuccess', response);

  dispatch(appNotificationShow(getStrings().registerSaved, actionTypes.APP_NOTIFICATION_TYPE_SUCCESS));

  dispatch({
    type: actionTypes.USUARIO_UPDATE_SUCCESS,
    usuario: response.data
  });
  let nomeUsuario = { nome: response.data.nome, sobrenome: response.data.sobrenome };
  updateAppTamanhoPadraoPaginacao(response.data.parametrosUsuario.tamanhoPadraoPaginacao);
  updateAppNomeUsuario(nomeUsuario);
  dispatch(updateTamanhoPadraoPaginacao(response.data.parametrosUsuario.tamanhoPadraoPaginacao));
  dispatch(updateNomeUsuario(nomeUsuario));
  dispatch(cadastroActions.cadastroUpdatePage(0, response.data.parametrosUsuario.tamanhoPadraoPaginacao));
  dispatch(producaoActions.setPageSize(response.data.parametrosUsuario.tamanhoPadraoPaginacao));
};

/**
 * Método executado quando ocorrer erro ao executar a ação. 
 * @param {*} error 
 */
const usuarioUpdateFail = error => dispatch => {
  log('usuarioAction usuarioUpdateFail', error);
  dispatch(errorUtils.requestErrorHandlerDefault(error));

  dispatch({
    type: actionTypes.USUARIO_UPDATE_FAIL,
    error: error
  });
};

/**
 * Método que atualiza o cadastro no servidor conforme os dados recebidos por parâmetro. 
 * @param {*} login
 */
export const updateSenha = usuario => dispatch => {
  log('usuarioAction updateSenha', usuario);

  dispatch(appSpinnerShow('updateSenha'))

  dispatch(alteraSenhaSubmit());

  axios().patch(
    urlDatabase + '/updateSenha',
    usuario,
    getHeaders()
  )
    .then(response =>
      dispatch(alteraSenhaSuccess(response))
    )
    .catch(error =>
      dispatch(alteraSenhaFail(error))
    )
    .finally(() =>
      dispatch(appSpinnerHide('updateSenha'))
    );
};

/**
 * Método que mostra o Spinner enquanto o registro é salvo no BackEnd. 
 */
const alteraSenhaSubmit = () => {
  log('usuarioAction alteraSenhaSubmit');
  return {
    type: actionTypes.ALTERA_SENHA_SUBMIT
  };
};

/**
 * Método executado ao finalizar a ação com sucesso. 
 * @param {*} response 
 */
const alteraSenhaSuccess = response => dispatch => {
  log('usuarioAction alteraSenhaSuccess', response);
  dispatch(appNotificationShow(getStrings().registerSaved, actionTypes.APP_NOTIFICATION_TYPE_SUCCESS));

  dispatch({
    type: actionTypes.ALTERA_SENHA_SUCCESS
  });
};

/**
 * Método executado quando ocorrer erro ao executar a ação. 
 * @param {*} error 
 */
const alteraSenhaFail = error => dispatch => {
  log('usuarioAction alteraSenhaFail', error);
  dispatch(errorUtils.requestErrorHandlerDefault(error));

  dispatch({
    type: actionTypes.ALTERA_SENHA_FAIL,
    error: error
  });
};


/**
 * Método que faz a solicitação para o servidor gerar um token para resetar a senha. 
 * @param {*} email
 */
export const solicitaRedefinicaoSenha = email => dispatch => {
  log('usuarioAction solicitaRedefinicaoSenha', email);

  dispatch(appSpinnerShow('solicitaRedefinicaoSenha'));

  dispatch(solicitaRedefinicaoSenhaSubmit());

  axios().post(
    urlDatabase + '/user/resetPassword',
    {
      login: {
        email: email
      }
    }
  )
    .then(response =>
      dispatch(solicitaRedefinicaoSenhaSuccess(response))
    )
    .catch(error =>
      dispatch(solicitaRedefinicaoSenhaFail(error))
    )
    .finally(() =>
      dispatch(appSpinnerHide('solicitaRedefinicaoSenha'))
    );
};

/**
 * Método que mostra o Spinner enquanto o registro é salvo no BackEnd. 
 */
const solicitaRedefinicaoSenhaSubmit = () => {
  log('usuarioAction solicitaRedefinicaoSenhaSubmit');
  return {
    type: actionTypes.SOLICITA_REDEFINICAO_SENHA_SUBMIT
  };
};

/**
 * Método executado ao finalizar a ação com sucesso. 
 * @param {*} response 
 */
const solicitaRedefinicaoSenhaSuccess = response => dispatch => {
  log('usuarioAction solicitaRedefinicaoSenhaSuccess', response);
  dispatch(appNotificationShow(getStrings().confirmationEmailSent, actionTypes.APP_NOTIFICATION_TYPE_SUCCESS));

  dispatch({
    type: actionTypes.SOLICITA_REDEFINICAO_SENHA_SUCCESS
  });
};

/**
 * Método executado quando ocorrer erro ao executar a ação. 
 * @param {*} error 
 */
const solicitaRedefinicaoSenhaFail = error => dispatch => {
  log('usuarioAction solicitaRedefinicaoSenhaFail', error);
  dispatch(errorUtils.requestErrorHandlerDefault(error));

  dispatch({
    type: actionTypes.SOLICITA_REDEFINICAO_SENHA_FAIL,
    error: error
  });
};


/**
 * Método que valida o Token para redefinição de senha. 
 * @param {*} idUsuario
 * @param {*} token
 */
export const validaTokenRedefinirSenha = (idUsuario, token) => dispatch => {
  log('usuarioAction validaTokenRedefinirSenha', idUsuario, token);

  dispatch(appSpinnerShow('validaTokenRedefinirSenha'))

  dispatch(validaTokenRedefinirSenhaSubmit());

  axios().get(
    urlDatabase + '/user/validaTokenRedefinirSenha',
    { params: { id: idUsuario, token: token } }
  )
    .then(response =>
      dispatch(validaTokenRedefinirSenhaSuccess(idUsuario, token))
    )
    .catch(error =>
      dispatch(validaTokenRedefinirSenhaFail(error))
    )
    .finally(() =>
      dispatch(appSpinnerHide('validaTokenRedefinirSenha'))
    );
};

/**
 * Método que mostra o Spinner enquanto o registro é salvo no BackEnd. 
 */
const validaTokenRedefinirSenhaSubmit = () => {
  log('usuarioAction validaTokenRedefinirSenhaSubmit');
  return {
    type: actionTypes.VALIDA_TOKEN_REDEFINIR_SENHA_SUBMIT
  };
};

/**
 * Método executado ao finalizar a ação com sucesso. 
 * @param {*} idUsuario 
 * @param {*} token 
 */
const validaTokenRedefinirSenhaSuccess = (idUsuario, token) => dispatch => {
  log('usuarioAction validaTokenRedefinirSenhaSuccess', idUsuario, token);
  dispatch({
    type: actionTypes.VALIDA_TOKEN_REDEFINIR_SENHA_SUCCESS,
    idUsuario: idUsuario,
    token: token
  });
};

/**
 * Método executado quando ocorrer erro ao executar a ação. 
 * @param {*} error 
 */
const validaTokenRedefinirSenhaFail = error => dispatch => {
  log('usuarioAction validaTokenRedefinirSenhaFail', error);
  dispatch(errorUtils.requestErrorHandlerDefault(error));

  dispatch({
    type: actionTypes.VALIDA_TOKEN_REDEFINIR_SENHA_FAIL,
    error: error
  });
};


/**
 * Método que atualiza o cadastro no servidor conforme os dados recebidos por parâmetro. 
 * @param {*} passwordResetToken Objeto com os dados para redefinição de senha.
 */
export const redefineSenha = passwordResetToken => dispatch => {
  log('usuarioAction redefineSenha', passwordResetToken);

  dispatch(appSpinnerShow('redefineSenha'))

  dispatch(redefineSenhaSubmit());

  axios().post(
    urlDatabase + '/user/changePassword',
    passwordResetToken
  )
    .then(response =>
      dispatch(redefineSenhaSuccess(response))
    )
    .catch(error =>
      dispatch(redefineSenhaFail(error))
    )
    .finally(() =>
      dispatch(appSpinnerHide('redefineSenha'))
    );
};

/**
 * Método que mostra o Spinner enquanto o registro é salvo no BackEnd. 
 */
const redefineSenhaSubmit = () => {
  log('usuarioAction redefineSenhaSubmit');
  return {
    type: actionTypes.REDEFINE_SENHA_SUBMIT
  };
};

/**
 * Método executado ao finalizar a ação com sucesso. 
 * @param {*} dispatch 
 * @param {*} response 
 */
const redefineSenhaSuccess = response => dispatch => {
  log('usuarioAction redefineSenhaSuccess', response);
  dispatch(appNotificationShow(getStrings().registerSaved, actionTypes.APP_NOTIFICATION_TYPE_SUCCESS));

  dispatch({
    type: actionTypes.REDEFINE_SENHA_SUCCESS
  });
};

/**
 * Método executado quando ocorrer erro ao executar a ação. 
 * @param {*} error 
 */
const redefineSenhaFail = error => dispatch => {
  log('usuarioAction redefineSenhaFail', error);
  dispatch(errorUtils.requestErrorHandlerDefault(error));

  dispatch({
    type: actionTypes.REDEFINE_SENHA_FAIL,
    error: error
  });
};

export const updateNomeUsuario = nomeUsuario => dispatch => {
  log('usuarioAction updateNomeUsuario', nomeUsuario);
  dispatch({
    type: actionTypes.USUARIO_SET_STATE,
    nomeUsuario
  });
};

export const updateTamanhoPadraoPaginacao = tamanhoPadraoPaginacao => dispatch => {
  log('usuarioAction updateTamanhoPadraoPaginacao', tamanhoPadraoPaginacao);
  dispatch({
    type: actionTypes.USUARIO_SET_STATE,
    tamanhoPadraoPaginacao
  });
};
