import React from "react";
import { FormText } from "./FormText";
import { InputLabel } from "./InputLabel";
import { InputContainer } from "./InputContainer";
import { TextInput } from "./Input";

const Nome = React.forwardRef((props, ref) => {
  return (
    <InputContainer>
      <InputLabel>Nome *</InputLabel>
        <TextInput ref={ref} required={true} placeholder={"Nome para contato *"} />
      <FormText>Entraremos em contato assim que possível.</FormText>
    </InputContainer>
  );
});

export default Nome;
