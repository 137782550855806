import React from 'react';
import { connect } from 'react-redux';

import * as producaoActions from '../../store/actions/producaoAction';

import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { getURIFromEntity } from "../../utils/URIUtils"

import BuildCellFromProducedItem from './BuildCellFromProducedItem';

/**
 * Classe que monta a dialog para usuário visualizar itens de venda que tiveram estado alterado para produzido.
 */
class DialogItemProduzido extends React.Component {

    /**
     * Método que monta o componente.
     */
    render() {
        log('DialogItemProduzido render');

        // Monta o componente
        return <>
            {this.props.dialogShow
                ? <>
                    <div className='dialog-scroll-pane'>
                        {/* itens */}
                        <div className='table' >
                            {this.props.itemVendaProduzidoList.map(itemVendaProduzido => <BuildCellFromProducedItem
                                key={getURIFromEntity(itemVendaProduzido)}
                                itemVenda={itemVendaProduzido}
                            />)}
                        </div>
                    </div>
                    {/* botão */}
                    <div className='dialog-horizontal-button-grid'>
                        <button ref={ref => this.confirmButton = ref} type='button'
                            onClick={() => this.props.setReadItemVendaList()} >{getStrings().ok}</button>
                    </div>
                </>
                : null
            }
        </>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    ...state.idiomaReducer,
    dialogShow: state.appReducer.dialogShow,
    itemVendaProduzidoList: state.producaoReducer.itemVendaProduzidoList
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    setReadItemVendaList: () => dispatch(producaoActions.setReadItemVendaList())
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DialogItemProduzido);
