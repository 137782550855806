import React from 'react';
import F3Login from './F3Login';
import F3Button from './F3ButtonComponent';
import F3Logout from './F3Logout';
import { log } from '../../../utils/LogUtils';

const login = (getFields) => {
    F3Login(response => {
        try {
            getFields(response);
        } catch(e) {
            log(e);
        }
    });
}

const F3LoginComponent = props => {

    const onLoginClick = () => {
        if (props.alwaysLogout) {
            F3Logout(response => {
                if ((response || {}).status === 'unknown') {
                    login(props.getFields);
                }
            });
        }
        else {
            login(props.getFields);
        }
    };

    return <div id='f3-login' style={{display: 'flex', justifyContent: 'center'}}>
        <F3Button
            id='f3-login-button'
            onClick={onLoginClick}
            style={props.style}
        >
            {props.label || 'Login with Facebook'}
        </F3Button>
    </div>;
}

export default F3LoginComponent;