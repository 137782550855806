//@ts-check
/**
 * 
 * @param {string} codigo
 */
export default function scrollToGrupo(codigo) {
    /**
     * 
     * @type {HTMLElement|null}
     */
    const elemento = document.querySelector(`#grupo-produto-${codigo}`);

    if (elemento === null) {
      return;
    }

    const elementPosition = elemento.offsetTop;
    const conteudoElemento = document.getElementsByClassName('conteudo')[0];
    const barraLogoEmpresa = document.getElementById('barra-logo-empresa');

    if (conteudoElemento) {
      conteudoElemento.scrollTo({
        top: elementPosition + (barraLogoEmpresa?.offsetHeight || 0) + 5,
      });
    }
}
