import { updateObject } from "../../utils/ObjectUtils"

import * as actionTypes from "../actions/actionTypes"

/**
 * Estado Inicial, obrigatório no Reducer.
 */
const initialState = {
    /**
     * Dados do relatório.
     */
    dados: {}
    /**
     * Identificador do relatório e filtros.
     */
    , options: {}
    /**
     * Ordem de exibição das colunas.
     */
    , columnOrder: null
    /**
     * Objeto vinculando chaves de coluna com ordenação e índice de ordenação de cada coluna.
     */
    , rowSortOptions: {}
}

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.RELATORIO:

            // Basta controlar a construção do action no outro arquivo.
            return updateObject(state, {
                ...state
                , ...action
            })
        default: return state
    }
}

export default reducer
