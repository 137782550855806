import { isNumber } from './ComparatorsUtils';

/**
 * 
 * @param {number} valor 
 * @param {number} casasDecimais 
 */
export const roundNumber = (valor, casasDecimais) => {
    if (!(isNumber(valor) && isNumber(casasDecimais))) {
        return NaN;
    }

    let ajusteFormat = 1;
    for (let x = 0; x < casasDecimais; x++) {
        ajusteFormat += '0';
    }

    return Math.round((valor + 0.00000001) * ajusteFormat) / ajusteFormat;
}

/**
 * Mesma coisa que arredondar para baixo.
 * @param {number} valor valor a ser truncado
 * @param {number} casasDecimais casas decimais a manter
 */
export const truncateNumber = (valor, casasDecimais) => {
    if (!(isNumber(valor) && isNumber(casasDecimais))) {
        return NaN;
    }

    casasDecimais = 10 ** casasDecimais;

    return Math.trunc(valor * casasDecimais) / casasDecimais;
}

export const formatNumber = (valor, casasDecimais) => {
    if (!(isNumber(valor) && isNumber(casasDecimais))) {
        return '';
    }

    return (roundNumber(valor, casasDecimais)).toFixed(casasDecimais).replace('.', ',');
}

/**
 * Verifica se o primeiro parâmetro é um número finito. Se sim, ele é retornado. Se não, é retornado o segundo parâmetro.
 * @param {number} number 
 */
export const numberOrElse = (number, backup) => {
    return Number.isFinite(number) ? number : backup;
}

/**
 * Verifica se o valor passado é um número finito. Se sim, ele é retornado. Se não, é retornado zero.
 * @param {number} number 
 */
export const numberOrZero = number => {
    return numberOrElse(number, 0);
}

/**
 * Retorna um número aleatório dentro de um intervalo.
 * @param {number} start inclusivo
 * @param {number} end exclusivo
 */
export const randomNumberBetween = (start, end) => {
    return Math.floor(Math.random() * (end - start)) + start;
}