import React from 'react';
import { connect } from 'react-redux';

import { getAppEmpresa } from '../../../utils/AppUtils';
import { equalsCoerced } from '../../../utils/ComparatorsUtils';
import { getStrings } from '../../../utils/LocaleUtils';
import { log } from '../../../utils/LogUtils';
import { getReduxWrappedComponent } from '../../../utils/reduxUtils/reduxUtils';

import CadastroForm from './CadastroForm';
import AtivoInputDefault from '../cadastroInputDefault/AtivoInputDefault.js'
import CodigoInputDefault from '../cadastroInputDefault/CodigoInputDefault.js'
import InputCustomizado from '../../UI/Input/InputCustomizado';
import NomeInputDefault from '../cadastroInputDefault/NomeInputDefault.js'

/**
 * Classe que repassa para o CadastroForm as informações para a montagem do formulário para o cadastro dos Clientes.
 */
class ClienteForm extends React.Component {
    render() {
        log('ClienteForm render');
        return <CadastroForm {...this.props} formModel={ClienteValues} />;
    }
}

/**
 * Classe que realiza a montagem do formulário para o cadastro dos Clientes.
 */
class ClienteValues extends React.Component {

    constructor() {
        super();
        log('ClienteValues constructor');

        this.enabled = true;
        this.ismounted = false;
    }

    /**
     * Retorna se os campos obrigatórios (required) foram todos preenchidos
     */
    checkRequired = newData => {
        log('ClienteValues checkRequired', newData);
        return (newData.codigo !== '') && (newData.nome !== '');
    }

    /**
     * Recupera os dados do formulário.
     */
    getFormData = () => {
        log('ClienteValues getFormData');

        return {
            empresaSet: [getAppEmpresa()],
            enabled: this.enabled.getValue(),
            codigo: this.codigo.getValue(),
            nome: this.nome.getValue(),
            identificador: this.identificador.inputComponent.value,
            ...(this.props.cadastroDados._links ? { _links: this.props.cadastroDados._links } : {})
        };
    }

    /**
     * Método executado ao clicar no botão "Gravar".
     * Recupera os dados do formulário e repassa para o método que irá persistir os dados.
     */
    handleGravar = () => {
        log('ClienteValues handleGravar');

        // Repassa para o método que irá persistir os dados
        this.props.onSave(this.getFormData());
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro
     */
    wasChanged = (newData, oldData) => {
        let result = !equalsCoerced(oldData, newData, (oldValue, newValue) => (newValue.enabled === oldValue.enabled) && (newValue.codigo === oldValue.codigo) &&
            (newValue.nome === oldValue.nome) && (newValue.identificador === oldValue.identificador));
        log('ClienteValues wasChanged', { newData, oldData, result });
        return result;
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro, comparando com os dados sendo editados.
     */
    wasChangedFromCurrent = newData => {
        log('ClienteValues wasChangedFromCurrent', newData);
        return this.wasChanged(newData, this.props.cadastroDados);
    }

    /**
     * Retorna se houve alteração em algum dado do cadastro, comparando com os dados presentes ao abrir a criação/edição do cadastro.
     */
    wasChangedFromInitial = newData => {
        log('ClienteValues wasChangedFromInitial', newData);
        return this.wasChanged(newData, this.props.cadastroDadosIniciais);
    }

    /**
     * Método executado APÓS a montagem/renderização do componente.
     * Define os valores iniciais das opções conforme cadastro carregado.
     */
    componentDidMount() {
        log('ClienteValues componentDidMount');
        this.ismounted = true;
        this.componentDidUpdate();
    }

    /**
     * Método executado APÓS a atualização do componente.
     * Define os valores iniciais das opções conforme cadastro carregado.
     * 
     * Os dados são populados aqui ao invés de no `render`>`InputCustomizado`>`defaultValue`
     * somente para seguir o padrão das outras telas.
     */
    componentDidUpdate() {
        log('ClienteValues componentDidUpdate');

        if (this.props.cadastroDados) {

            // Se for passado false, é false; se for passado true, null, undefined ou não for passado, é true
            this.enabled.inputField.inputComponent.checked = this.props.cadastroDados.enabled !== false;

            this.codigo.inputField.inputComponent.value = this.props.cadastroDados.codigo || null;

            this.nome.inputField.inputComponent.value = this.props.cadastroDados.nome || null;

            this.identificador.inputComponent.value = this.props.cadastroDados.identificador || null;
        }
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('ClienteValues render');
        return <div className='sub-form'>

            <AtivoInputDefault ref={input => this.enabled = getReduxWrappedComponent(input)} handleInputValidado={this.props.handleChange} />

            <CodigoInputDefault ref={input => this.codigo = getReduxWrappedComponent(input)} subPlaceholder={getStrings().clientSubPlaceholder} handleInputValidado={this.props.handleChange} />

            <NomeInputDefault ref={input => this.nome = getReduxWrappedComponent(input)} subPlaceholder={getStrings().clientSubPlaceholder} handleInputValidado={this.props.handleChange} isRequired={true} />

            <InputCustomizado ref={input => this.identificador = getReduxWrappedComponent(input)} placeholder={getStrings().clientIdentifierPlaceholder} maxLength='18' id='identificador' type='text' name='identificador' label={getStrings().clientIdentifier} handleInputValidado={this.props.handleChange} />
        </div>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    ...state.idiomaReducer
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(ClienteForm);
