import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { log } from "../../utils/LogUtils"
import { isIE } from "../../utils/NavigatorUtils"

import * as controleVendaActions from '../../store/actions/controleVenda/controleVendaAction';
import { push } from 'connected-react-router'

import {
    STATE_CONTROLE_VENDA_LIST_SALES_FROM_CALL,
    STATE_CONTROLE_VENDA_NEW_SALE_FROM_CALL
} from '../../store/reducers/controleVenda/controleVendaReducer';

import "./Alert.css";
import "./SaleSourceAlert.css";
import Icones from '../../assets/icones/Icones';

/**
 * Mostra uma mesa (cadeira) animada no *header* para sinalizar que há chamadas a partir de códigos impressos em origens de venda.
 */
class SaleSourceAlert extends React.Component {

    /**
     * Método executado APÓS a montagem/renderização do componente.
     * Realiza a troca de tela para o controle de vendas após ter certeza que os dados necessários estão disponíveis no *reducer*.
     */
    componentDidUpdate() {
        // Se é para iniciar uma venda a partir de uma chamada e os dados já estão no reducer
        if ([STATE_CONTROLE_VENDA_NEW_SALE_FROM_CALL, STATE_CONTROLE_VENDA_LIST_SALES_FROM_CALL].indexOf(this.props.fromCallState) >= 0) {
            // Troca para a tela de controle de vendas
            if (this.props.history.location.pathname !== '/controleVendas') {
                this.props.goTo('/controleVendas');
            }
        }
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('SaleSourceAlert render');

        // opções:
        // MdEventSeat
        // MdLocalRestaurant
        // MdRoomService
        // MdWeekend
        const MdEventSeat = Icones.md.MdEventSeat;

        // Somente exibe o aviso se há itens de venda produzidos não visualizados
        if (this.props.calledOrigemVendaList.length > 0 || localStorage.getItem('forceAlert') === 'true') {
            return <div className={`clickable SaleSourceAlert${isIE ? ' ie' : ''}`} onClick={() => {
                this.props.showOrigemVendaChamadaDialog();
            }}>
                <MdEventSeat />
            </div>;
        }
        // Se não, esconde o aviso.
        else {
            return null;
        }
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    calledOrigemVendaList: state.controleVendaReducer.calledOrigemVendaList,
    fromCallState: state.controleVendaReducer.fromCallState
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    goTo: path => dispatch(push(path)),
    showOrigemVendaChamadaDialog: () => dispatch(controleVendaActions.showOrigemVendaChamadaDialog())
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SaleSourceAlert));
