import React from "react"
import Icones from "../../../apps/promo_app_frontend/assets/icones/Icones"
import Imagem from "../../../apps/promo_app_frontend/nucleo/componentes/Imagem/Imagem"
import { mobiAppReducerTipo } from "../../../apps/promo_app_frontend/nucleo/redux/mobiApp/mobiAppReducer"
import { getStrings } from "../../../utils/LocaleUtils"

export type PreviewMobiAppType = {
  mobiApp: mobiAppReducerTipo,
  nomeEmpresa: string,
  versaoLogo?: string,
}

export function PreviewMobiApp({mobiApp, versaoLogo, nomeEmpresa}: PreviewMobiAppType) {
  const MdClose = Icones.md.MdClose;
  const barraTopoAltura = 2.75
  const barraNavegacaoAltura = 2.5
  const barraNavegacaoPaddingVertical = 0.5
  const MobiAppIcone = mobiApp.logoEmpresa
      ? (props: any) => <img style={props.style} alt="img" src={mobiApp.logoEmpresa} />
      : (props: any) => <Icones.mobi.LogoEmpresa nomeEmpresa={nomeEmpresa} {...props} versaoLogo={versaoLogo} />
  const [ showDialog, updateShowDialog ] = React.useState(false);


  return <div className="relative coluna" style={{ alignSelf: "start" }} onClick={(ev) => { ev.stopPropagation(); ev.preventDefault(); updateShowDialog(false); }} >
    <div className="absolute flex flex-column flex-nowrap justify-content-center align-items-center" style={{ width: "100%", height: "100%", background: "rgba(0, 0, 0, 0.8)", display: showDialog ? undefined : "none", fontWeight: "bold" }} >
      <div className="flex flex-column flex-nowrap justify-content-start align-items-center" style={{ padding: "0.4em", border: "1px solid lightgray", borderRadius: "var(--borda-raio)", width: "85%", height: "12em", background: mobiApp.corDoDialog, color: mobiApp.corDoDialogTexto }} > 
        <div className="flex flex-row flex-nowrap justify-content-end align-items-start cursor-pointer" style={{ width: "100%", color: "#323232", }} ><MdClose /></div>
        <div className="flex flex-column flex-nowrap justify-content-center align-items-center" style={{width: "100%", height: "100%"}} >
          {getStrings().dialog}
        </div>
      </div>
    </div>
    <div
      className="flex flex-column flex-nowrap justify-content-start align-items-center"
      style={{ flex: "1 1 auto", margin: "0.2em", minWidth: "22em", minHeight: "40em" }}
    >
      <div
        className="flex flex-row flex-nowrap justify-content-start align-items-center"
        style={{
          flex: "0 1 auto"
          , background: mobiApp.corBarraTopo
          , color: mobiApp.corBarraTopoTexto
          , fontWeight: "bolder"
          , width: "100%"
          , height: `${barraTopoAltura}em`
          , padding: "0 0.2em"
        }}>
        <div className="block cursor-pointer" style={{ width: "auto" }} onClick={(ev) => { ev.stopPropagation(); ev.preventDefault(); updateShowDialog(true); }} >
          <Icones.mobi.Menu style={{ height: "2.25em" }} />
        </div>
        <div className="block" style={{ width: "100%", textAlign: "center" }}>
          {mobiApp.nomeExibicao}
        </div>
        <div className="block" style={{ width: "auto" }}>
          <Icones.mobi.Logo style={{ height: "2.25em" }} />
        </div>
      </div>
      <div className="disable-scroll full-width" style={{ flex: "1 1 auto", background: mobiApp.corDeFundo, color: mobiApp.corDeFundoTexto }}>
        <div
          className="disable-horizontal-scroll enable-vertical-scroll full-width"
          style={{
            minHeight: "100%"
            , fontWeight: "bolder"
            , flex: "1 1 auto"
            , background: mobiApp.corDeFundo
            , color: mobiApp.corDeFundoTexto
          }}>
          <div className="flex flex-row flex-nowrap justify-content-center align-items-center full-width" style={{padding: "0.2em"}}>
            <MobiAppIcone nomeEmpresa={mobiApp.valorLinkParametro} style={{ height: "4.5em" }} />
          </div>
          <div className="flex flex-row flex-nowrap justify-content-center align-items-center full-width disable-scroll">
            <div className="flex flex-row flex-nowrap justify-content-center align-items-center full-width disable-horizontal-scroll enable-vertical-scroll">
              <div style={{borderRadius: "0.2em", fontSize: "0.5em", padding: "0.1em 0.2em", width: "4.5em", height: "2em", textAlign: "center", background: mobiApp.corPrincipal, color: mobiApp.corPrincipalTexto}} onClick={(evt) => { evt.stopPropagation(); evt.preventDefault(); }}>{getStrings().group}</div>
            </div>
          </div>
          <div className="flex flex-row flex-nowrap full-width justify-content-center align-items-center" style={{marginTop: "1em"}}>
            <input type={"texto"} placeholder={getStrings().search} style={{width: "8em", boxShadow: "none", border: "1px solid lightgray", borderRadius: "0.3em", background: mobiApp.corDaEntrada, color: mobiApp.corDaEntradaTexto,}} />
          </div>
          <div className="flex" style={{ width: "calc(100% - 0.2em)", flex: "1 1 auto", paddingTop: "0.5em", }}>
            <div className="flex flex-column flex-nowrap full-width" style={{ background: mobiApp.corDoCard, color: mobiApp.corDoCardTexto, height: "6em", }}>
              <div className="flex flex-column flex-nowrap full-width" style={{height: "33.33%"}}>
                <div className="flex flex-row flex-nowrap full-width" >
                  <div className="block" style={{width: "auto"}}>
                    <Imagem style={{ margin: "0.3em", width: "3em", height: "3em", }} src="" />
                  </div>
                  <div className="block" style={{width: "100%", textAlign: "center"}}>
                    {"Produto X"}
                  </div>
                </div>
              </div>
              <div className="flex flex-column flex-nowrap full-width" style={{height: "33.33%"}}>
                <div className="flex flex-row flex-nowrap full-width" >
                  <div className="block" style={{width: "auto"}}></div>
                  <div className="block" style={{width: "100%"}}></div>
                  <div className="block" style={{width: "auto", textAlign: "center", color: "rgb(0, 146, 49)"}}>17,49</div>
                </div>
              </div>
              <div className="flex flex-column flex-nowrap full-width" style={{height: "33.33%"}}>
                <div className="flex flex-row flex-nowrap full-width" >
                  <div className="block" style={{width: "auto"}}></div>
                  <div className="block" style={{width: "100%"}}></div>
                  <div className="block" style={{width: "auto"}}>
                    <div className="flex flex-row flex-nowrap">
                      <div style={{
                        textAlign: "center"
                        , width: "0.5em"
                        , borderRadius: "0.3em 0 0 0.3em"
                        , padding: "0.1em 0.4em"
                        , background: mobiApp.corPrincipal
                        , color: mobiApp.corPrincipalTexto}}>
                          {"-"}
                      </div>
                      <div style={{
                        background: mobiApp.corDaEntrada
                        , color: mobiApp.corDaEntradaTexto
                        , textAlign: "center"
                        , padding: "0.1em 0.05em"
                      }}>
                        {"0"}
                      </div>
                      <div style={{
                        textAlign: "center"
                        , width: "0.5em"
                        , borderRadius: "0 0.3em 0.3em 0"
                        , padding: "0.1em 0.4em"
                        , background: mobiApp.corPrincipal
                        , color: mobiApp.corPrincipalTexto
                      }}>
                        {"+"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex flex-row flex-nowrap full-width justify-content-center align-items-center"
        style={{
          background: mobiApp.corDaBarraNavegacao
          , color: mobiApp.corDaBarraNavegacaoTexto
          , padding: `${barraNavegacaoPaddingVertical}em 0`
          , height: `${barraNavegacaoAltura}em`
          , borderTop: "1px solid #777"
        }}>
        <div>
          <div style={{
            marginLeft: "4px",
            boxShadow: "0.05em 0.05em 0.1em 0.1em"
            , color: "#777"
            , borderRadius: "0.25em"
          }}>
            <div
              className="flex flex-row justify-content-start align-items-center"
              style={{
                borderRadius: "0"
                , width: "3.5em"
                , background: mobiApp.corBotaoVoltar
                , color: "black"
                , padding: "0.2em"
                , textAlign: "center"
              }}
            >
            <Icones.mobi.Voltar style={{ height: "1.25em", color: mobiApp.corBotaoVoltarTexto }} />
          </div>
        </div>
      </div>
        <div className="flex flex-row flex-nowrap justify-content-center align-items-center" style={{ flex: "1 1 auto", fontSize: "0.9em", fontWeight: "bold" }}>R$ 27,00</div>
        <div>
          <div style={{ marginRight: "4px", boxShadow: "0.05em 0.05em 0.1em 0.1em", color: "#777", borderRadius: "0.25em", }}>
            <div
              className="flex flex-row justify-content-end align-items-center"
              style={{
                borderRadius: "0"
                , width: "3.5em"
                , background: mobiApp.corBotaoDeAcao
                , padding: "0.2em"
                , textAlign: "center"
              }}>
              <Icones.mobi.Avancar style={{ height: "1.25em", color: mobiApp.corBotaoDeAcaoTexto }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}