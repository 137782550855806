import { getApi } from "./SecureConnectionUtils";
import { axios } from "../store/actions/appAction";
import { getHeaders } from "./HeadersUtils";

export const populateFromDB = (array, update, keys = {id: 'id', data: 'data'}) => {
    const id   = (keys || {}).id || 'id';
    const data = (keys || {}).data || 'data';

    for (const item of array) {
        const object = {id: item[id], data: item[data]};

        update(object);
    }
}

/**
 * 
 * @param {String} path 
 * @param {Object} optionalArgs {onSucces, onError, onEnd}  
 */
export const getDataFromDB = (path, { onSuccess, onError, onEnd, method }) => {
    const uri = getApi(path)

    const method_ = method || 'get'

    axios()[method_](uri, getHeaders())
        .then(onSuccess)
        .catch(onError)
        .finally(onEnd)
}
