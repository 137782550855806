import criarReducer, { acaoTipo } from '../../factory/criarReducer';
import tiposDeAcoes from './cadastroEnderecoActions';

export type cadastroEnderecoReducerTipo = {
  endereco: {
    pais: {
      codigo: number,
      iso: string,
      nome: string,
    },
    estado: string,
    cep: string,
    cidade: string,
    codigoCidade: string,
    bairro: string,
    rua: string,
    numero: string,
    complemento: string,
  },
};

const estadoInicial: cadastroEnderecoReducerTipo = {
  endereco: {
    pais: {
      codigo: 0,
      iso: '',
      nome: '',
    },
    estado: '',
    cep: '',
    cidade: '',
    codigoCidade: '',
    bairro: '',
    rua: '',
    numero: '',
    complemento: '',
  },
};

const acoesNomeadas: any = {
  [tiposDeAcoes.ATUALIZAR_CADASTRO]: ({endereco}: cadastroEnderecoReducerTipo, estadoAtual: cadastroEnderecoReducerTipo) => {
    const novoEstado = {
      ...estadoAtual,
      endereco,
    };

    return novoEstado;
  },
  [tiposDeAcoes.LIMPAR_CADASTRO]: () => {
    const novoEstado = {
      ...estadoInicial,
    };

    return novoEstado;
  },
};

export default function(estado: cadastroEnderecoReducerTipo = estadoInicial, acao: acaoTipo) {
  return criarReducer(estado, acao, acoesNomeadas);
}
