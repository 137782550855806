//@ts-check
import React from 'react';
import OverlayTemplate from '../OverlayTemplate/OverlayTemplate';

import "./CarregamentoOverlay.css";


class CarregamentoOverlay extends React.Component {
    render() {
        return <OverlayTemplate>
            <div id='animacao-carregamento' />
        </OverlayTemplate>;
    }
}

export default CarregamentoOverlay;
