import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { cadastroRecuperaValueFromOptions } from "../../../utils/SelectUtils"

import { TIPO_ORIGEM_VENDA_IFOOD } from "../../../store/reducers/controleVenda/controleVendaReducer"
import * as appActions from "../../../store/actions/appAction"

import InputCustomizado from "../../UI/Input/InputCustomizado"

class DialogCancelSaleSaleItem extends React.Component {

  componentDidMount() {
    if (this.motivoCancelamentoSelect && this.motivoCancelamentoSelect.inputComponent) {
      let options = [
        { label: "Problemas de Sistema", labelKey: "501", value: { codigo: "501" } }
        , { label: "Pedido em Duplicidade", labelKey: "502", value: { codigo: "502" } }
        , { label: "Item Indisponível", labelKey: "503", value: { codigo: "503" } }
        , { label: "Restaurante sem Motoboy", labelKey: "504", value: { codigo: "504" } }
        , { label: "Cardápio Desatualizado", labelKey: "505", value: { codigo: "505" } }
        , { label: "Pedido Fora da Área de Entrega", labelKey: "506", value: { codigo: "506" } }
        , { label: "Cliente Golpista / Trote", labelKey: "507", value: { codigo: "507" } }
        , { label: "Fora do Horário do Delivery", labelKey: "508", value: { codigo: "508" } }
        , { label: "Dificuldades Internas do Restaurante", labelKey: "509", value: { codigo: "509" } }
        , { label: "Área de Risco", labelKey: "511", value: { codigo: "511" } }
        , { label: "Restaurante Abrirá Mais Tarde", labelKey: "512", value: { codigo: "512" } }
        , { label: "Restaurante Fechou Mais Cedo", labelKey: "513", value: { codigo: "513" } }
      ]
      this.motivoCancelamentoSelect.inputComponent.updateOptions(options)
      this.motivoCancelamentoSelect.inputComponent.updateValue(options[0])
    }
  }

  componentDidUpdate(prevProps) {
    log("DialogCancelSaleSaleItem componentDidUpdate")
    // Se passou a ser exibido, limpa o campo com o motivo.
    if ((!((prevProps || {}).dialogShow)) && ((this.props || {}).dialogShow) && this.cancelReasonTextArea) {
      this.cancelReasonTextArea.value = null
    }
  }

  render() {
    log("DialogCancelSaleSaleItem render")

    return <>
      {this.props.tipoOrigemVenda === TIPO_ORIGEM_VENDA_IFOOD
        ? <InputCustomizado
          id="motivoCancelamentoSelect"
          ref={input => { if (input) { this.motivoCancelamentoSelect = getReduxWrappedComponent(input) } }}
          name="motivoCancelamentoSelect"
          inputType="singleSelect"
          label={getStrings().rejectReasonTitle}
        />
        : <textarea
          id="cancel-reason-text"
          placeholder={this.props.placeholder || getStrings().cancelReason}
          ref={textArea => {
            if (textArea) {
              this.cancelReasonTextArea = textArea
            }
          }}
          style={{ resize: "none", height: "60%" }}
        ></textarea>
      }
      <div className="dialog-horizontal-button-grid unique">
        <button
          className="blue-button font-color"
          id="confirm-cancel-button"
          onClick={() => {
            this.props.appDialogHide()
            this.props.handleConfirm(
              this.cancelReasonTextArea
                ? this.cancelReasonTextArea.value
                : cadastroRecuperaValueFromOptions(this.motivoCancelamentoSelect.inputComponent.getValue()))
          }}
        >
          {getStrings().confirm}
        </button>
      </div>
    </>
  }
}

DialogCancelSaleSaleItem.propTypes = {

  /**
   * Chamado no botão de confirmar.
   */
  handleConfirm: PropTypes.func,
  placeholder: PropTypes.any,
  tipoOrigemVenda: PropTypes.any,
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  ...state.idiomaReducer,
  dialogShow: state.appReducer.dialogShow
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  appDialogHide: () => dispatch(appActions.appDialogHide()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DialogCancelSaleSaleItem)
