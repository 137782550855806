//@ts-check
import "../ProdutoCard/ProdutoCard.css"

import React from "react"
import { ProdutoUtils, EntidadeUtils, LocalidadeUtils } from "../../../nucleo/utils/utils"
import { Botao, Entrada, Imagem, Texto } from "../../../nucleo/nucleo"
import { connect } from "react-redux"
import { atualizarUnidadeCarrinho } from "../../../nucleo/redux/carrinho/carrinhoActions"
import { atualizarInfo } from "../../../nucleo/redux/info/infoActions"
import { Icones } from "../../../assets/icones/Icones"
import { mostrarDialog } from "../../../nucleo/redux/dialog/dialogActions"
import ZoomImagemDialog from "../../dialogs/ZoomImagemDialog/ZoomImagemDialog"
import { ProdutoComTamanhoCardType } from "./types.d"

class ProdutoComTamanhoCard extends ProdutoComTamanhoCardType {
  /**
   * 
   * @param {string} nomeUnidade 
   * @param {string} idSabor 
   * @param {string} texto 
   * @param {number} quantidadeMinima 
   * @param {number} quantidadeMaximaSabores 
   * @param {number} quantidadeMinimaSabores 
   */
  atualizarQuantidade = (nomeUnidade, idSabor, texto, quantidadeMinima, quantidadeMaximaSabores, quantidadeMinimaSabores) => {
    texto = texto.replace('[^0-9]*', '');
    const quantidade = texto ? parseInt(texto) : 0;
  
    this.props.dispatch(atualizarUnidadeCarrinho({nomeUnidade, idSabor, quantidade: quantidade >= quantidadeMinima && quantidade <= quantidadeMaximaSabores ? quantidade : 0, quantidadeMaximaSabores, quantidadeMinimaSabores}));
  }

  /**
   * 
   * @param {import('../../../nucleo/redux/produtos/grupoProdutos/grupoProdutosReducer').grupoProdutoReducerTipo} grupoProduto 
   * @param {import('../../../nucleo/redux/carrinho/types').carrinhoUnidadeTipo} unidade 
   * @param {import('../../../nucleo/redux/carrinho/types').carrinhoProdutoTipo} sabor 
   * @param {number} quantidadeMaximaSabores 
   */
  atualizarNomeInfo = (grupoProduto, unidade, sabor, quantidadeMaximaSabores) => {
    const media = grupoProduto.precoProdutoCombinado === 'media';
    const sabores = Object.values({...unidade.sabores, [sabor.id]: sabor})
      .filter(sabor => sabor.quantidade > 0);
    const quantidade = [0, ...sabores.map(sabor => sabor.quantidade)].reduce((soma, valorAtual) => soma + valorAtual);
    const saboresRestantes = quantidadeMaximaSabores - quantidade;
    const soldOut = saboresRestantes <= 0;

    const tamanhoId = EntidadeUtils.getIdFromEntity(this.props.produtoTamanho.tamanho)
    const {valorTotal} = ProdutoUtils.calcularValorTotalProdutoComTamanho(media, sabores, tamanhoId, quantidade, this.props.quantidadeUnidade);

    if (valorTotal > 0) {
      const info = `${LocalidadeUtils.obterTraducao().currency.format(valorTotal)}`;
      
      if (this.props.infoReducer.info !== info || this.props.infoReducer.soldOut !== soldOut) {
        this.props.dispatch(atualizarInfo({info, shortInfo: info, soldOut}));
      }
    }
    else {
      const info = '';
      
      if (this.props.infoReducer.info !== info || this.props.infoReducer.soldOut !== soldOut) {
        this.props.dispatch(atualizarInfo({info, soldOut}));
      }
    }
  }

  /**
   * 
   * @param {import('./types').produtoComTamanhoCardPropsType} prevPros 
   */
  componentDidUpdate(prevPros) {
    if (prevPros.sabor.quantidade !== this.props.sabor.quantidade || prevPros.quantidadeUnidade !== this.props.quantidadeUnidade) {
      this.atualizarNomeInfo(this.props.grupoProduto, this.props.unidade, this.props.sabor, this.props.quantidadeMaximaSabores);
    }
  }

  render() {
    const {
      unidade,
      sabor,
      quantidadeMaximaSabores,
      quantidadeMinimaSabores,
    } = this.props;

    const {
      nome,
      descricao,
      fonteImagem,
      fonteImagemTipo,
    } = sabor;
  
    const idSabor = sabor.id;
  
    const precoSabor = sabor.produtoTamanhoList.find(produtoTamanho => EntidadeUtils.getIdFromEntity(produtoTamanho.tamanho) === EntidadeUtils.getIdFromEntity(this.props.produtoTamanho.tamanho))?.preco || 0;
  
    const soldOut = this.props.saboresRestantes <= 0;
    const precoStr = LocalidadeUtils.obterTraducao().currency.format(precoSabor);

    const imagemProduto = fonteImagemTipo ? fonteImagem : LocalidadeUtils.obterTraducao().noImageAvailableIcon;

    const IconeLixeira = Icones.go.GoTrashcan;

    return <div tabIndex={this.props.tabIndex} className={'app-card produto-card'}>
      <div>
      <div>
        <div
          className='imagem'
          onClick={() => {
            this.props.dispatch(
              mostrarDialog({
                conteudo: () => <ZoomImagemDialog
                  fonteImagemTipo={fonteImagemTipo + 'dialog__'}
                  imagemProduto={imagemProduto}
                  titulo={nome}
                />
              })
            );
          }}
        >
          <Imagem lupa className={'app-icone'} key={fonteImagemTipo} style={this.props.style} src={imagemProduto} />
        </div>
        <div className='identificacao-produto'>
          <div className='nome'>
            <div className='info'>
              <div className='column nome'>
                <Texto className='nome'>{nome}</Texto>
              </div>
            </div>
          </div>
          <div className='descricao'>
            <Texto>{descricao}</Texto>
          </div>
          <div className='painel-produto'>
            <div className='painel-e-preco'>
              <div className='preco'>
                <div className={`row sem-desconto`}></div>
                <div className={`row com-desconto${soldOut ? ' sold-out' : ''}`}>{precoStr}</div>
              </div>
              <Botao className={`remover-do-carrinho ${sabor.quantidade <= 0 || sabor.quantidade > sabor.quantidadeEmEstoque ? 'disabled' : ''}`}>
                <IconeLixeira
                  onClick={() => { this.props.dispatch(atualizarUnidadeCarrinho({ nomeUnidade: unidade.nome, idSabor, quantidade: 0, quantidadeMaximaSabores, quantidadeMinimaSabores })); }}
                />
              </Botao>
              <div className='row controle-quantidade'>
                <Botao disabled={sabor.quantidade <= 0} className='diminuir-quantidade' onClick={() => {
                  this.props.dispatch(atualizarUnidadeCarrinho({ nomeUnidade: unidade.nome, idSabor, quantidade: sabor.quantidade - 1, quantidadeMaximaSabores, quantidadeMinimaSabores }));
                }}>-</Botao>
                <Entrada className='quantidade' value={sabor.quantidade} onChange={
                  /**
                   * 
                   * @param {{ target: { value: string } }} event 
                   */
                  (event) => { this.atualizarQuantidade(unidade.nome, idSabor, event.target.value, quantidadeMinimaSabores, quantidadeMaximaSabores, quantidadeMinimaSabores); }} />
                <Botao disabled={this.props.saboresRestantes <= 0} className='aumentar-quantidade' onClick={() => {
                  this.props.dispatch(atualizarUnidadeCarrinho({ nomeUnidade: unidade.nome, idSabor, quantidade: sabor.quantidade + 1, quantidadeMaximaSabores, quantidadeMinimaSabores }));
                }}>+</Botao>
              </div>
            </div>
          </div>
        </div>
        </div>
        {this.props.ultimoCardDoGrupo
          ? null
          : <div className={`linha-separadora`} />
        }
      </div>
    </div>;
  }
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  infoReducer: state.webAppInfoReducer,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProdutoComTamanhoCard);
