import React from 'react';

import criarAction from '../factory/criarAction';
import { webAppReducersTipo } from '../types';
import { ClienteHttp, LocalidadeUtils, NavegacaoUtils } from '../../utils/utils';
import * as TimeUtils from '../../utils/DateTimeUtils/DateTimeUtils';
import { descontarEstoqueProduto } from '../produtos/produtos/produtosActions';
import { limparCarrinhoAction } from '../carrinho/carrinhoActions';
import apelidos from '../../../rotas/apelidos';
import { ativarSnackbar } from '../snackbar/snackbarActions';
import moment from 'moment';
import { atualizarUsuario } from '../usuario/usuarioActions';
import { getIdFromEntity, getIdFromURI } from '../../utils/URIUtils/URIUtils';
import { loadPagseguroLightbox } from '../../../../../utils/PagSeguroUtils';
import CarregamentoOverlay from '../../../componentes/overlay/CarregamentoOverlay/CarregamentoOverlay';
import { esconderOverlay, mostrarOverlay } from '../overlay/overlayActions';
import { getNomeEmpresa } from '../../../assets/empresas/configEmpresa';
import { mostrarDialog } from '../dialog/dialogActions';
import ErroDialog from '../../../componentes/dialogs/ErroDialog/ErroDialog';

const tiposDeAcoes = {
  ATUALIZAR_PEDIDO: 'ATUALIZAR_PEDIDO:PEDIDOS',
  ADICIONAR_PEDIDO: 'ADICIONAR_PEDIDO:PEDIDOS',
  LIMPAR_PEDIDOS: 'LIMPAR_PEDIDOS:PEDIDOS',
}

export default tiposDeAcoes;

const limparEIrParaTelaDePedidos = () => (dispatch: (carga: any) => any, getState: () => webAppReducersTipo) => {
  const state = getState();

  dispatch(atualizarUsuario());
  dispatch(limparCarrinhoAction());
  dispatch(NavegacaoUtils.goTo(`${apelidos.pedidos}`, {mostrarMensagemContatoEmpresa: state.webAppMobiAppReducer.mostrarMensagemContatoEmpresa}));
}

export const adicionarPedido = (
  valorTeleEntrega: number,
  formaPagamento: string,
  endereco: boolean,
  numeroDeParcelas: number,
  dataEntrega: string,
  horaEntrega: string,
  parametrosEmpresa: any,
  vendaMobiAppEncerrada: boolean,
  callback?: () => void | undefined,
  observacao: string | null = null,
) => 
function(dispatch: any, getState: () => webAppReducersTipo) {
  const state = getState();
  const webAppCarrinhoReducer = state.webAppCarrinhoReducer;
  const webAppFormasPagamentoReducer = state.webAppFormasPagamentoReducer;

  const usaPagSeguro = webAppFormasPagamentoReducer[formaPagamento].usaPagSeguro;
  const estadoPedido = usaPagSeguro ? 'INDEFINIDO' : vendaMobiAppEncerrada ? 'ENCERRADO' : 'ESPERANDO_ACEITAR';

  const {dateTime, timeZone} = TimeUtils.getCurrentDateTimeAndZone();
  type itemVendaListTipo = Array<{
    dataHora: string,
    dataHoraAtualizacao: string,
    estado: 'ENCERRADO' | 'ESPERANDO_ACEITAR' | 'INDEFINIDO',
    fusoHorario: string,
    livre: false,
    observacao: string | null,
    precoUnitario: number,
    produto: string,
    quantidade: number,
    usuarioList: Array<string>,
    valorTotal: number,
    itemProducaoList: Array<any>,
    combinado: boolean,
    nomeProduto?: string,
  }>;
  let itemVendaList: itemVendaListTipo = [];

  for(const id in webAppCarrinhoReducer.produtos) {
    const produto = webAppCarrinhoReducer.produtos[id];
    const usuarioId = JSON.parse(sessionStorage.getItem('a') || '{}').u;

    let itemProducaoList = [];
    for (let i = 0; i < produto.quantidade; i++) {
        itemProducaoList.push({estado: estadoPedido})
    }

    itemVendaList.push({
      dataHora: `${dateTime}Z`,
      dataHoraAtualizacao: `${dateTime}Z`,
      estado: estadoPedido,
      fusoHorario: timeZone,
      livre: false,
      observacao: produto.observacao,
      precoUnitario: (produto.promocao && produto.promocao.precoPromo) ? produto.promocao.precoPromo : produto.preco,
      produto: produto.unidadeUri || ClienteHttp.getApi(`produtos/${id}`),
      quantidade: produto.quantidade,
      usuarioList: [ClienteHttp.getApi(`usuarios/${usuarioId}`)],
      valorTotal: produto.preco * produto.quantidade,
      itemProducaoList: itemProducaoList,
      combinado: !!produto.unidadeUri,
      nomeProduto: produto.unidadeUri
        ? produto.nome
        : undefined,
    });
  }

  type entregaVendaTipo = {
    teleEntrega: boolean,
    dataHoraEntrega: string,
    fusoHorario: string,
    formaPagamento: string,
    valorTeleEntrega: number,
  };

  const entregaVenda: entregaVendaTipo = {
    teleEntrega: endereco,
    dataHoraEntrega: moment(`${dataEntrega} ${horaEntrega}`, 'YYYY-MM-DD HH:mm').toISOString(),
    fusoHorario: timeZone,
    formaPagamento: formaPagamento,
    valorTeleEntrega: valorTeleEntrega,
  }

  const dadosProdutos: {
    empresa: string,
    itemVendaList: itemVendaListTipo,
    nome: string,
    numeroPessoas: number,
    origemVenda: string,
    usuarioCliente: string,
    mantemPedidoAberto: boolean,
    tipoEntrega: number,
    estadoItensVenda: 'ENCERRADO' | 'ESPERANDO_ACEITAR' | 'INDEFINIDO',
    entregaVenda: entregaVendaTipo,
    observacao: string | null,
  } = {
    empresa: ClienteHttp.getApi(`empresas/${parametrosEmpresa.idDaEmpresa}`),
    itemVendaList,
    nome: `app_${TimeUtils.getCurrentDateTimeFormatted('HH:mm:ss')}`,
    numeroPessoas: 1,
    origemVenda: ClienteHttp.getApi(`origemVendas/${parametrosEmpresa.idOrigemVendaDaEmpresa}`),
    usuarioCliente: ClienteHttp.getApi(`usuarios/${JSON.parse(sessionStorage.getItem('a') || '{}').u}`),
    mantemPedidoAberto: true,
    tipoEntrega: endereco ? 0 : 1,
    estadoItensVenda: estadoPedido,
    entregaVenda,
    observacao,
  }

  ClienteHttp.obterClienteHttp()
    .post(ClienteHttp.getApi(`vendas`), {...dadosProdutos}, ClienteHttp.getHeaders({ noCompany: true }))
    .then((resposta: any) => {

      const dados = resposta.data || {};
      const vendaId = getIdFromEntity(dados);

      if (usaPagSeguro) {
        checkout(dispatch, vendaId, formaPagamento, callback);
      }
      else if (vendaMobiAppEncerrada) {
        enviaPedidoHub(state, dispatch, vendaId, formaPagamento, numeroDeParcelas, callback);
      }
      else {
        dispatch(ativarSnackbar({frase: LocalidadeUtils.obterTraducao().orders.orderPlaced}));
        dispatch(limparEIrParaTelaDePedidos());
      }

      dispatch(limparCarrinhoAction());
    })
    .catch(() => {
      dispatch(mostrarDialog({conteudo: () => <ErroDialog mensagem={LocalidadeUtils.obterTraducao().phrases.shoppingFailed}/>}));
      callback && callback();
    });
}

const checkout = (dispatch: any, vendaId: any, formaPagamento: string, callback?: () => void | undefined) => {

  dispatch(mostrarOverlay({componente: (props: any) => <CarregamentoOverlay {...props} />}));
  
  const formaPagamentoId = getIdFromURI(formaPagamento);
  const redirectUrl = `${window.location.origin}/apps?empresa=${getNomeEmpresa()}&vendaId=${vendaId}&formaPagamentoId=${formaPagamentoId}`;

  ClienteHttp.obterClienteHttp()
    .post(ClienteHttp.getApi('pagseguro/checkout'), { vendaId, formaPagamentoId, redirectUrl }, ClienteHttp.getHeaders())
    .then((resposta: any) => {

      const code = resposta.data;

      const pagSeguroCallback = {
        success: function (transactionCode: any) {
          //Insira os comandos para quando o usuário finalizar o pagamento. 
          //O código da transação estará na variável "transactionCode"
          console.log("Compra feita com sucesso, código de transação: " + transactionCode);

          dispatch(esconderOverlay());

          ClienteHttp.requisicaoServidor('pagseguro/saveTransaction', 'post', true, {params: {noCompany: true}}, {formaPagamentoId, transactionCode})
            .then(response => {
              console.log(response.data);

              dispatch(ativarSnackbar({ frase: LocalidadeUtils.obterTraducao().orders.orderPlaced }));
              dispatch(limparEIrParaTelaDePedidos());
            })
            .catch(() => {
              dispatch(mostrarDialog({conteudo: () => <ErroDialog mensagem={LocalidadeUtils.obterTraducao().phrases.shoppingFailed}/>}));
            });
        },
        abort: function () {
          //Insira os comandos para quando o usuário abandonar a tela de pagamento.
          console.log("abortado");
          dispatch(esconderOverlay());
          callback && callback();
        }
      };

      //window.PagSeguroLightbox(code, pagSeguroCallback);
      loadPagseguroLightbox(code, pagSeguroCallback,
        () => {
          dispatch(esconderOverlay());
        },
        () => {
          dispatch(esconderOverlay());
          dispatch(mostrarDialog({conteudo: () => <ErroDialog mensagem={LocalidadeUtils.obterTraducao().phrases.shoppingFailed}/>}));
        });
    })
    .catch(() => {
      dispatch(esconderOverlay());
      dispatch(mostrarDialog({conteudo: () => <ErroDialog mensagem={LocalidadeUtils.obterTraducao().phrases.shoppingFailed}/>}));
      callback && callback();
    });
}

const enviaPedidoHub = (state: any, dispatch: any, id: any, formaPagamento: string, numeroDeParcelas: number, callback?: () => void | undefined) => {

  const webAppCarrinhoReducer = state.webAppCarrinhoReducer;
  const webAppUsuarioReducer = state.webAppUsuarioReducer;
  const webAppProdutosReducer = state.webAppProdutosReducer;

  let currentMoment = TimeUtils.getCurrentDateTimeAndZone();
  let dateTime = currentMoment.dateTime;
  let timeZone = currentMoment.timeZone;

  ClienteHttp.obterClienteHttp()
    .post(ClienteHttp.getApi(`vendas/${id}/sendToHub`), { dateTime: dateTime + timeZone, timeZone }, ClienteHttp.getHeaders({ noCompany: true }))
    .then((resposta: any) => {

      dispatch(criarAction({
        id,
        produtos: webAppCarrinhoReducer.produtos,
        endereco: webAppUsuarioReducer.endereco,
        formaPagamento,
        numeroDeParcelas,
      }, tiposDeAcoes.ADICIONAR_PEDIDO));

      for (const id in webAppCarrinhoReducer.produtos) {
        const produto = webAppProdutosReducer.produtos[id];

        if (produto) {
          dispatch(descontarEstoqueProduto({ id, produto, quantidadeComprada: webAppCarrinhoReducer.produtos[id].quantidade }));
        }
      }

      dispatch(ativarSnackbar({ frase: LocalidadeUtils.obterTraducao().orders.orderPlaced }));
      dispatch(limparEIrParaTelaDePedidos());
    })
    .catch(() => {
      dispatch(esconderOverlay());
      dispatch(mostrarDialog({conteudo: () => <ErroDialog mensagem={LocalidadeUtils.obterTraducao().phrases.shoppingFailed}/>}));
      callback && callback();
    });
}

export const atualizarPedido = (carga: any) => function(dispatch: any) {
  dispatch(criarAction(carga, tiposDeAcoes.ATUALIZAR_PEDIDO));
}

export const limparPedidos = () => function(dispatch: any) {
  dispatch(criarAction(undefined, tiposDeAcoes.LIMPAR_PEDIDOS));
}
