import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { formatNumber } from "../../utils/NumberUtils"

/**
 * Função que monta a celula a partir do item de pagamento da venda.
 */
class BuildCellFromItemPagamentoVenda extends React.Component {

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log('BuildCellFromItemPagamentoVenda render');

    let itemPagamentoVenda = this.props.itemPagamentoVenda || {};

    let produto = itemPagamentoVenda.produto;
    // Busca o nome do produto
    let name = produto.nome;
    // Se for um produto a kilo vendido como livre
    if (itemPagamentoVenda.livre) {
      // Adiciona " (livre)" no nome
      name = getStrings().allYouCanEatName(name);
    }

    return <div className='table-cancel table-row'>
      <div className='table-cell check ButtonAlign' >{// Check box
        null
      }</div>
      <div className='table-cell product TextAlign' >{// Nome
        name
      }</div>
      <div className='table-cell amount NumberAlign' >{// Quantidade
        itemPagamentoVenda.produto.quantidadePesada
          ? formatNumber(itemPagamentoVenda.quantidade, 3)
          : itemPagamentoVenda.quantidade
      }</div>
      <div className='table-cell total NumberAlign' >{// Valor total
        formatNumber(itemPagamentoVenda.valorTotal, 2)
      }</div>
    </div>;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  tabIndex: state.appReducer.getTabIndex()
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, null, null, { forwardRef: true })(BuildCellFromItemPagamentoVenda);
