import { updateObject } from "../../utils/ObjectUtils"

import * as actionTypes from "../actions/actionTypes"

export interface appReducerType {
  type: string,

  //Sidedrawer
  sideDrawerShow: boolean,

  // Dialog
  dialogShow: boolean,
  dialogTitle: string|null,
  dialogContent: any,
  dialogStyles: React.CSSProperties|null,
  dialogContentStyles: React.CSSProperties|null,
  dialogTitleStyles: React.CSSProperties|null,
  dialogOnCloseClicked: (() => void)|null,
  dialogOnOverlayClicked: (() => void)|null,

  // Notification
  notificationShow: boolean,
  notificationTitle: string|null,
  notificationTitleI18n: boolean,
  notificationContent: string|null,
  notificationDialogColors: Record<string, any>,
  notificationTitleColors: Record<string, any>,
  notificationType: string|null,
  notificationDismiss: number|null,
  notificationAction: () => void,
  notificationNoAction: () => void,

  // Screen
  fullScreen: boolean,
  fullScreenStyle: Record<string, any>,

  // Spinner
  spinnerShow: () => boolean,

  // Snackbar
  snackbarMessage: string,
  snackbarOpen: boolean,
  snackbarUndoAction: (() => void)|null,

  // Tour
  tourIsOpen: boolean,
  tourSteps: Record<string, any>,

  /**
   * Lista de nomes dos originadores do `appSpinnerShow`. Usado para somente esconder o spinner quando todos os originadores chamarem `appSpinnerHide`.
   * Esses dois funções acessam essa variável diretamente. Em outras linguagens, isso seria um problema de concorrência, podendo resultar em um função
   * lendo valores passados da variável. Mas, após ler sobre o *event loop* do JavaScript e fazer alguns testes, parece não ser necessário tratar
   * concorrência. Se fosse, `setTimeout` parece uma solução interessante, porque adiciona funções no *event loop* que são executadas uma a uma.
   */
  spinnerQueue: string[],

  /**
   * Retorna de *dialog* deve ser exibido.
   */
  isShowing: () => boolean,

  /**
   * Retorna de *dialog* deve ser exibido.
   */
  isDialogOrNotificationShowing: () => boolean,

  /**
   * Retorna `tabindex` para que componentes não possam ser selecionados com a tecla Tab quando estiverem ocultos por um *dialog*.
   */
  getTabIndex: () => -1|0,

  /**
   * Retorna `tabindex` para os componentes do menu, que não podem ser selecionados com a tecla Tab quando estiverem ocultos por um *dialog*
   * ou uma notificação. Quando houver um *spinner* na tela, o menu pode ser acessado, então a função da tecla Tab deve ser mantida.
   */
  getMenuTabIndex: () => -1|0,

  /**
   * Exibição de texto de ajuda
   */
  help: boolean,
}

/**
 * Estado Inicial, obrigatório no Reducer.
 */
const initialState: appReducerType = {
  type: actionTypes.APP_MODAL,

  //Sidedrawer
  sideDrawerShow: false,

  // Dialog
  dialogShow: false,
  dialogTitle: null,
  dialogContent: null,
  dialogStyles: null,
  dialogContentStyles: null,
  dialogTitleStyles: null,
  dialogOnCloseClicked: null,
  dialogOnOverlayClicked: null,

  // Notification
  notificationShow: false,
  notificationTitle: null,
  notificationTitleI18n: false,
  notificationContent: null,
  notificationDialogColors: {},
  notificationTitleColors: {},
  notificationType: null,
  notificationDismiss: null,
  notificationAction: () => { },
  notificationNoAction: () => { },

  // Screen
  fullScreen: false,
  fullScreenStyle: {
    hide: null,
    margin: null,
    padding: null,
    content: null,
  },

  // Spinner
  spinnerShow: function () {
    return this.spinnerQueue.length > 0;
  },

  // Snackbar
  snackbarMessage: "",
  snackbarOpen: false,
  snackbarUndoAction: null,

  // Tour
  tourIsOpen: false,
  tourSteps: {},

  /**
   * Lista de nomes dos originadores do `appSpinnerShow`. Usado para somente esconder o spinner quando todos os originadores chamarem `appSpinnerHide`.
   * Esses dois funções acessam essa variável diretamente. Em outras linguagens, isso seria um problema de concorrência, podendo resultar em um função
   * lendo valores passados da variável. Mas, após ler sobre o *event loop* do JavaScript e fazer alguns testes, parece não ser necessário tratar
   * concorrência. Se fosse, `setTimeout` parece uma solução interessante, porque adiciona funções no *event loop* que são executadas uma a uma.
   */
  spinnerQueue: [],

  /**
   * Retorna de *dialog* deve ser exibido.
   */
  isShowing: function () {
    return this.isDialogOrNotificationShowing() || this.spinnerShow();
  },

  /**
   * Retorna de *dialog* deve ser exibido.
   */
  isDialogOrNotificationShowing: function () {
    return this.dialogShow || this.notificationShow || this.tourIsOpen;
  },

  /**
   * Retorna `tabindex` para que componentes não possam ser selecionados com a tecla Tab quando estiverem ocultos por um *dialog*.
   */
  getTabIndex: function () {
    return this.isShowing() ? -1 : 0;
  },

  /**
   * Retorna `tabindex` para os componentes do menu, que não podem ser selecionados com a tecla Tab quando estiverem ocultos por um *dialog*
   * ou uma notificação. Quando houver um *spinner* na tela, o menu pode ser acessado, então a função da tecla Tab deve ser mantida.
   */
  getMenuTabIndex: function () {
    return this.isDialogOrNotificationShowing() ? -1 : 0;
  },

  /**
   * Exibição de texto de ajuda
   */
  help: false,
}

/**
 * Executado com o uso de dispatch().
 * Causa a troca de estado.
 * @param {*} state 
 * @param {*} action 
 */
const reducer = (state = initialState, action: Partial<appReducerType & {payload: any, undoAction: () => void}>) => {

  switch (action.type) {
    case actionTypes.APP_HELP_TOGGLE:
    case actionTypes.APP_MODAL:
      // window.scrollTo({top: 0, left: 0, behaviour: "smooth"})

      // Basta, basta controlar a construção do action no outro arquivo.
      return updateObject(state, {
        ...state
        , ...action
      });
    case actionTypes.APP_SNACKBAR_MESSAGE:
      return {
        ...state
        , snackbarMessage: action.payload
        , snackbarOpen: true
        , snackbarUndoAction: action.undoAction
      };
    case actionTypes.APP_SNACKBAR_CLEAR:
      return {
        ...state
        // , snackbarMessage: "" // Remove mensagem da Snackbar antes de terminar animação de saída
        , snackbarOpen: false
      }
    case actionTypes.APP_START_TOUR:
      return {
        ...state
        , tourIsOpen: true
        , tourSteps: action.payload
      }
    case actionTypes.APP_CLOSE_TOUR:
      return {
        ...state
        , tourIsOpen: false
        , tourSteps: {}
      }
    case actionTypes.APP_SIDEDRAWER_SET:
      return {
        ...state
        , sideDrawerShow: action.sideDrawerShow
      }
    case actionTypes.UPDATE_FORCE_CLOSE_STORE_FLAG:
      return {
        ...state
        , forceCloseStore: action.payload
      }
    case actionTypes.UPDATE_FULLSCREEN:
      return {
        ...state
        ,fullScreen: action.payload
        ,fullScreenStyle: action.payload ? {
          hide: { display: "none" }
          , margin: { margin: 0 }
          , padding: { padding: 0 }
          , content: {
            width: `${window.innerWidth}px`
            , height: `${window.innerHeight}px`
            , overflow: "hidden"
            , margin: 0
            , top: 0
            , left: 0
            , position: "absolute"
            , zIndex: 999
          }
        } : {
          hide: null
          , margin: null
          , padding: null
          , content: null
        }
      }
    default: return state
  }
}

export default reducer
