import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Button } from "./ButtonsDefault";
import ApxLogo from "./ApxLogo";
import { displayFlexCenter } from "../css/styles";

const goToCadastro = (e) => {
  e.preventDefault();
  console.log("clicado cadastro");
  window.location.href = `${window.location.origin}/signUpCompany`;
};

const Navbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const listnerRef = useRef(null);
  const [scrollState, setScrollState] = useState(false);
  const { executeScroll } = props;

  //useEffect para fazer o logo "aparecer" quando passar de um certo ponto
  //pois já tem o logo em tela, e não é necessário que apareça no navbar tb
  useEffect(() => {
    listnerRef.current = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      var width = document.body.clientWidth;
      //valor arbritrário fui no olho até sumir a imagem no scroll
      if (width > 360) {
        if (scrolled >= 290) {
          if (scrollState !== true) {
            setScrollState(true);
          }
        } else {
          if (scrollState !== false) {
            setScrollState(false);
          }
        }
      }
    });
    return () => {
      document.removeEventListener("scroll", listnerRef.current);
    };
  }, [scrollState]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  //só faz isso pra fechar o menu quando clicar no link
  const onClickLink = (referenciaLink) => {
    executeScroll(referenciaLink);
    setIsOpen(false);
  };

  return (
    <Nav>
      <Hamburger onClick={toggleMenu} isOpen={isOpen}>
        <span key={"1"} />
        <span key={"2"} />
        <span key={"3"} />
      </Hamburger>

      <Menu isOpen={isOpen} tabIndex={0}>
        <MenuLink onClick={() => onClickLink(0)}>Início</MenuLink>
        <MenuLink onClick={() => onClickLink(2)}>Recursos</MenuLink>
        <MenuLink onClick={() => onClickLink(1)}>Segmentos</MenuLink>
        <MenuLink onClick={() => onClickLink(3)}>Contato</MenuLink>
      </Menu>
      <BtnsLogCadContainer>
        <BtnCadastro styleCustom={BtnCadastro} onClick={goToCadastro}>
          <span>Cadastrar</span>
        </BtnCadastro>
        <LogIn href={`${window.location.origin}/login`}>Entrar</LogIn>
      </BtnsLogCadContainer>
      {scrollState ? <ApxLogo /> : ""}
    </Nav>
  );
};

const Nav = styled.div`
  ${displayFlexCenter};
  height: ${(props) => props.theme.sizes.navBarHeight};
  justify-content: flex-start;
  align-items: center;
  background: ${(props) => props.theme.lightBackgroundColor};
  padding: 10px 1rem;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: ${(props) => props.theme.boxShadow};
  -webkit-tap-highlight-color: transparent;
  @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    font-size: 1.2em;
  }
`;

const width = "120px;";
const height = "40px;";

// Workaround para erro de extends pois dava undefined
// https://lifesaver.codes/answer/uncaught-error-cannot-create-styled-component-for-component-undefined

const BtnsLogCadContainer = styled.div`
  ${displayFlexCenter};
  position: absolute;
  left: 350px;
  @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    left: 60px;
  }
`;
const BtnCadastro = styled((props) => <Button {...props} />)`
  height: ${height};
  width: ${width};
  font-size: 1em;
  @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    height: 30px;
    width: 100px;
    font-size: 0.6em;
  }
`;

const LogIn = styled.a`
  ${displayFlexCenter};
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-weight: 900;
  font-size: 1em;
  color: ${(props) => props.theme.primaryColor};
  margin-left: 10px;
  /* z-index: 99999; */
  @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    padding: 0;
    height: 25px;
    width: 60px;
    font-size: 0.8em;
  }
`;

const MenuLink = styled.a`
  padding: 0.7rem 1rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 1em;
  color: ${(props) => props.theme.textColor};

  &:visited {
    color: ${(props) => props.theme.textColor};
  }
  &:after {
    content: "";
    display: block;
    width: 0;
    border-bottom: 2px solid black;
    transition: all 0.3s linear 0s;
  }
  &:hover:after {
    width: 100%;
  }
  @media (min-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    padding: 0.7rem 0.3rem;
    &:last-child {
      border: none;
    }
  }
`;

const Menu = styled.div`
  /* margin-left: auto; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  z-index: 1000;
  background-color: ${(props) => props.theme.lightBackgroundColor};

  @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    overflow: hidden;
    flex-direction: column;
    max-height: ${({ isOpen }) => (isOpen ? "300px" : "0")};
    transition: 0.3s ease-in;
    width: 60%;

    box-shadow: 0 4px 26px rgba(172, 197, 207, 0.699);
    border-radius: 10px;
    background-color: ${(props) => props.theme.grayBackgroundColor};

    margin-top: calc(${(props) => props.theme.sizes.navBarHeight}* 3.3);
    left: 0px;
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  span {
    height: 4px;
    width: 35px;
    background: ${(props) => props.theme.textColor};
    margin-bottom: 4px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      span:nth-child(1) {
        transform: rotateZ(-45deg) translateX(-7px) translateY(7px);
      }
      span:nth-child(2) {
        transform: rotateZ(-90deg);
        opacity: 0;
      }
      span:nth-child(3) {
        transform: rotateZ(45deg) translateX(-3px) translateY(-5px);
      }
    `};
  /* Acima é animação para transformar o Hamburguer em X */

  @media (max-width: ${(props) => props.theme.sizes.breakPointMedium}) {
    height: 100%;
    ${displayFlexCenter};
  }
`;

export default Navbar;
