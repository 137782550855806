export const UPDATE_CURRENT_IMAGE = "UPDATE:CURRENT_IMAGE";
export const UPDATE_PRODUCT_IMAGES = "UPDATE:PRODUCT_IMAGES";
export const UPDATE_UPLOADED_IMAGE = "UPDATE:UPLOADED_IMAGE";

const initialState = {
    currentImage: {
        version: 0,
        src: "",
        style: { display: "hidden", },
    },
    productImages: {},
    uploadedImage: {
        src: "",
        style: { display: "hidden", },
    }
};

const mapActions = {
    [UPDATE_CURRENT_IMAGE]: (payload, state) => {
        return {...state, currentImage:{...state.currentImage, ...payload}};
    },
    [UPDATE_PRODUCT_IMAGES]: (payload, state) => {
        return {...state, productImages:{...state.productImages, ...payload}};
    },
    [UPDATE_UPLOADED_IMAGE]: (payload, state) => {
        return {...state, uploadedImage:{...state.uploadedImage, ...payload}};
    },
};

const productImagesReducer = (state = initialState, {type, payload}) => {
    const update = mapActions[type];

    if (update) {
        const newState = update(payload, state);

        return newState;
    }

    return state;
}

export default productImagesReducer;
