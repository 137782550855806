import { log } from '../LogUtils'

export type observerType =  (props: any) => any
export type observerStateType = { observers: (observerType)[] }

export function createObserver() {
  const state: observerStateType = { observers: [] }
  
  function subscribe(newObserver: observerType) {
    state.observers.push(newObserver)
  }

  function unsubscribe(observerToDelete: observerType) {
    state.observers = state.observers.filter((observer) => observer !== observerToDelete)
  }

  function notifyAll(props: Record<string, any>) {
    log('observers:', `Notifying ${state.observers.length} observer(s)`)

    state.observers.forEach((observer) => {
      observer(props)
    })
  }

  return {subscribe, unsubscribe, notifyAll, observers: [...state.observers]}
}