import criarReducer from "../factory/criarReducer";
import { tipoAcao } from "../../../../../store/actions/acaoTemplate";
import actionTypes from "./paisesActions";

export type paisReducerTipo = {
  label: string
  , labelKey: string
  , value: {
    geo_latitude: number
    , geo_longitude: number
    , iso: string
    , nome: string
    , _links: {self: {href: string}}
  },
};

export type paisesReducerTipo = {
  [key: string]: paisReducerTipo;
}

const estadoInicial: paisesReducerTipo = {};

const acoesNomeadas: {[key: string]: (carga: any, estadoAtual?: paisesReducerTipo) => paisesReducerTipo} = {
  [actionTypes.ATUALIZAR_PAIS]: ({id, pais}: {id: string, pais: paisReducerTipo}, estadoAtual?: paisesReducerTipo) => {
    const newState = {
      ...(estadoAtual || {})
      , [id]: pais
    }
    
    return newState;
  }
  , [actionTypes.POPULAR_PAISES]: (paises: paisesReducerTipo, estadoAtual?: paisesReducerTipo) => {
    const newState = {
      ...(estadoAtual || {})
      , ...paises
    }
    
    return newState;
  }
};

export default function(estado: paisesReducerTipo = estadoInicial, acao: tipoAcao) {
  return criarReducer(estado, acao, acoesNomeadas);
}
