import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { formatNumber } from "../../../utils/NumberUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import {
  NEW_SALE_ITEM_AMOUNT_OPERATION_ADD,
  NEW_SALE_ITEM_AMOUNT_OPERATION_SET,
  NEW_SALE_ITEM_AMOUNT_TYPE_UNIT,
  NEW_SALE_ITEM_AMOUNT_TYPE_WEIGHTED,
  NEW_SALE_ITEM_AMOUNT_TYPE_AYCE
} from "../../../store/reducers/controleVenda/manutencao/manutencaoVendaReducer"

import DialogPeso from "../dialog/DialogPeso"

function QuantButton(props) {
  return <button
    {...props}
    className={`quant-button pure-u-6-24 button dark-button font-color${props.className ? ` ${props.className}` : ""}`}
  />;
}

class SpinnerQuantidade extends React.Component {
  render() {
    const itemVendaQuantidade = this.props.itemVenda.quantidade || 0;
    const numeroMaximoProdutos = this.props.produto.numeroMaximoProdutos ? this.props.produto.numeroMaximoProdutos : 999;

    /* Spinner de quantidade */
    return <div className={`input-group${this.props.className ? ` ${this.props.className}` : ""} right`}>
      {/* Decrementa quantidade ou remove produto pesado */}
      <QuantButton
        tabIndex={this.props.tabIndex}
        className="decrementa"
        onClick={() => (itemVendaQuantidade === 0)
          ? null
          : (this.props.produto.quantidadePesada || itemVendaQuantidade === 1)
            ? this.props.removeNewSaleItem(this.props.newSaleItem)
            : this.props.addNewSaleItemOrSetAmount(
              NEW_SALE_ITEM_AMOUNT_OPERATION_SET, this.props.newSaleItem, NEW_SALE_ITEM_AMOUNT_TYPE_UNIT, itemVendaQuantidade - 1, this.props.itemVenda.observacao)}
      >
        {this.props.produto.quantidadePesada ? "×" : "−"}
      </QuantButton>
      {/* Exibe quantidade, texto para abrir dialog de peso ou texto informando livre */}
      {this.props.produto.quantidadePesada ?
        <button
          tabIndex={this.props.tabIndex}
          className={"pure-u-18-24 quantidade" + ((itemVendaQuantidade === 0) ? " zerada" : "")}
          onClick={() => this.props.appDialogShow(
            <DialogPeso
              defaultValue={itemVendaQuantidade}
              scalesAction={() => this.props.orderUsingScales(getURIFromEntity(this.props.produto))}
              submitAction={amount => this.props.addNewSaleItemOrSetAmount(
                (itemVendaQuantidade === 0) ? NEW_SALE_ITEM_AMOUNT_OPERATION_ADD : NEW_SALE_ITEM_AMOUNT_OPERATION_SET, this.props.newSaleItem, NEW_SALE_ITEM_AMOUNT_TYPE_WEIGHTED, amount, this.props.itemVenda.observacao)}
              AYCEAction={() => this.props.addNewSaleItemOrSetAmount(
                (itemVendaQuantidade === 0) ? NEW_SALE_ITEM_AMOUNT_OPERATION_ADD : NEW_SALE_ITEM_AMOUNT_OPERATION_SET, this.props.newSaleItem, NEW_SALE_ITEM_AMOUNT_TYPE_AYCE, null, this.props.itemVenda.observacao)}
              removeAction={() => (itemVendaQuantidade > 0)
                ? this.props.removeNewSaleItem(this.props.newSaleItem)
                : null}
            />,
            getStrings().amount
          )}
          disabled={this.props.itemVenda.livre}>
          {(itemVendaQuantidade === 0) ? getStrings().weightAbbreviated : this.props.itemVenda.livre ? getStrings().allYouCanEatShort : formatNumber(itemVendaQuantidade, 3)}
        </button> :
        <div className="pure-u-12-24 quantidade" >
          <input className="pure-u-12-24 input-quantidade" value={itemVendaQuantidade} onChange={
            (event) => {
              let quantidade = parseInt(event?.target?.value || "0")
              quantidade = Number.isNaN(quantidade) ? 0 : quantidade

              this.props.addNewSaleItemOrSetAmount(
                (itemVendaQuantidade === 0)
                  ? NEW_SALE_ITEM_AMOUNT_OPERATION_ADD
                  : NEW_SALE_ITEM_AMOUNT_OPERATION_SET,
                this.props.newSaleItem,
                NEW_SALE_ITEM_AMOUNT_TYPE_UNIT,
                quantidade < numeroMaximoProdutos
                  ? quantidade
                  : numeroMaximoProdutos,
                this.props.itemVenda.observacao
              )
            }
          }>
          </input>
        </div>
      }
      {/* Incrementa quantidade */}
      {this.props.produto.quantidadePesada ?
        null :
        <QuantButton
          tabIndex={this.props.tabIndex}
          className="incrementa"
          onClick={() => this.props.addNewSaleItemOrSetAmount(
            (itemVendaQuantidade === 0)
              ? NEW_SALE_ITEM_AMOUNT_OPERATION_ADD
              : NEW_SALE_ITEM_AMOUNT_OPERATION_SET,
            this.props.newSaleItem,
            NEW_SALE_ITEM_AMOUNT_TYPE_UNIT,
            (numeroMaximoProdutos === null) || (itemVendaQuantidade < numeroMaximoProdutos)
              ? (itemVendaQuantidade === 0)
                ? 1
                : itemVendaQuantidade + 1
              : numeroMaximoProdutos,
            this.props.itemVenda.observacao
            )}
        >
          +
        </QuantButton>
      }
    </div>;
  }
}

function mapStateToProps(state) {
  const props = {
    tabIndex: state.appReducer.getTabIndex(),
  };

  return props;
}

function mapDispatchToProps(dispatch) {
  const props = {
    dispatch,
  };

  return props;
}

export default connect(mapStateToProps, mapDispatchToProps)(SpinnerQuantidade)
