import criarReducer, { acaoTipo } from "../../factory/criarReducer";
import { grupoProdutoTamanhoTipo } from "../grupoProdutoTamanhos/grupoProdutoTamanhoReducer";
import { produtoTipo } from "../produtos/produtosReducer";
import tiposDeAcoes from "./grupoProdutosActions";

export type grupoProdutoTamanhoListTipo = Array<grupoProdutoTamanhoTipo>;

export type grupoProdutoReducerTipo = {
  codigo: number
  nome: string,
  grupoProdutoTamanhoList: grupoProdutoTamanhoListTipo,
  produtos: {[id: string]: produtoTipo},
  precoProdutoCombinado: string,
};

export type grupoProdutosReducerTipoSemGrupoCarregado = {
  grupoId: string,
  grupos: {
    [key: string]: grupoProdutoReducerTipo,
  },
}

export type grupoProdutosReducerTipo = grupoProdutosReducerTipoSemGrupoCarregado & {
  busca: string,
  grupoCarregado: boolean,
};

const estadoInicial: grupoProdutosReducerTipo = {
  busca: '',
  grupoId: '',
  grupoCarregado: false,
  grupos: {},
};

const acoesNomeadas: { [key: string]: (carga: any, estadoAtual?: grupoProdutosReducerTipo) => grupoProdutosReducerTipo } = {
  [tiposDeAcoes.ATUALIZAR_CAMPO_BUSCA_EM_GRUPO]: ({busca}: {busca: string}, estadoAtual = estadoInicial) => {
    const novoEstado: grupoProdutosReducerTipo = {
      ...estadoAtual,
      busca,
    };

    return novoEstado;
  },
  [tiposDeAcoes.DEFINIR_GRUPO_PRODUTO_ID]: ({ codigo }: { codigo: number }, estadoAtual = estadoInicial) => {
    const novoEstado: grupoProdutosReducerTipo = {
      ...estadoAtual,
      grupoId: `${codigo}`,
    };

    return novoEstado;
  },
  [tiposDeAcoes.REMOVER_GRUPO_PRODUTO_ID]: ({ codigo }: { codigo: number }, estadoAtual = estadoInicial) => {
    let grupos = estadoAtual.grupos;

    delete grupos[`${codigo}`];

    const novoEstado: grupoProdutosReducerTipo = {
      ...estadoAtual,
      grupoId: `${codigo}` === estadoAtual.grupoId
        ? ''
        : `${codigo}`,
      grupos,
    };

    return novoEstado;
  },
  [tiposDeAcoes.ADICIONAR_PRODUTO_A_GRUPO]: ({ codigo, nome, produto, grupoProdutoTamanhoList, precoProdutoCombinado }: { codigo: number, nome: string, produto: produtoTipo & {id: string}, grupoProdutoTamanhoList: grupoProdutoTamanhoListTipo, precoProdutoCombinado: string }, estadoAtual: grupoProdutosReducerTipo = estadoInicial) => {
    const grupo = {
      codigo,
      nome,
      grupoProdutoTamanhoList,
      precoProdutoCombinado,
    };

    const produtos = {
      ...(estadoAtual.grupos[`${codigo}`]?.produtos || {}),
      [produto.id]: {...produto, mostrar: true},
    };

    const novoEstado: grupoProdutosReducerTipo = {
      ...(estadoAtual || estadoInicial),
      grupos: {
        ...estadoAtual.grupos,
        [`${codigo}`]: {
          ...grupo,
          produtos,
        },
      }
    };

    return novoEstado;
  },
  [tiposDeAcoes.ADICIONAR_GRUPOS]: ({grupos}: {grupos: grupoProdutosReducerTipoSemGrupoCarregado}, estadoAtual = estadoInicial) => {
    const novoEstado: grupoProdutosReducerTipo = {
      ...grupos,
      grupoCarregado: true,
      busca: estadoAtual.busca,
    };

    return novoEstado;
  },
  [tiposDeAcoes.LIMPAR_GRUPO_PRODUTOS]: (_, __) => {
    const novoEstado: grupoProdutosReducerTipo = estadoInicial;

    return novoEstado;
  },
  [tiposDeAcoes.REMOVER_GRUPO_PRODUTO_ID_DEFINIDO]: (_, estadoAtual = estadoInicial) => {
    const novoEstado: grupoProdutosReducerTipo = {
      ...estadoAtual,
      grupoId: '',
    };

    return novoEstado;
  },
  [tiposDeAcoes.MODIFICAR_GRUPO_CARREGADO]: ({grupoCarregado}: {grupoCarregado: false}, estadoAtual = estadoInicial) => {
    const novoEstado = {
      ...estadoAtual,
      grupoCarregado,
    }

    return novoEstado;
  },
}

export default function (estado: grupoProdutosReducerTipo = estadoInicial, acoes: acaoTipo) {
  return criarReducer(estado, acoes, acoesNomeadas);
}
