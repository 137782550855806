import React from 'react';

class DialogButton extends React.Component {
    render() {
        return <>
            <button
                {...this.props}
                className={`dialog-button gray-button font-color${this.props.className ? ` ${this.props.className}` : ''} left`}
                style={{background:  'var(--color-card-accordion-background)'}}
            />
        </>;
    }
};

export default DialogButton;
