import React from "react"
import { connect } from "react-redux"

import { log } from "../../../utils/LogUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import { STATE_CONTROLE_VENDA_MANUTENCAO_VENDA } from "../../../store/reducers/controleVenda/controleVendaReducer"

import BuildCellFromItemPagamentoVenda from "./BuildCellFromItemPagamentoVenda"
import BuildCellFromQuebraVendaOrigem from "./BuildCellFromQuebraVendaOrigem"
import BuildCellFromQuebraVendaTotal from "./BuildCellFromQuebraVendaTotal"

/**
 * Exibe a tabela de produtos, um cabeçalho com nome da origem, da venda e opcionalmente um *check box* e um rodapé com totais.
 * 
 * Pode exibir tanto novos itens de pagamento (não persistidos) como itens de pagamento enviados (persistidos).
 * 
 * Evita de ficar vinculado às duas listas do *reducer* para evitar execuções desnecessárias quando uma das listas atualizar.
 * Ao invés disso, ou retorna um componente vinculado a uma das listas ou retorna outro componente vinculado à outra lista.
 */
class VendaTable extends React.Component {

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log("VendaTable render")

        let vendaURI = getURIFromEntity(this.props.venda)
        let valorCupom = (this.props.venda.entregaVenda?.valorCupom || 0) * -1
        let valorTeleEntrega = this.props.venda.entregaVenda?.valorTeleEntrega || 0

        return <>
            {/* Cabeçalho */}
            {this.props.state !== STATE_CONTROLE_VENDA_MANUTENCAO_VENDA
                ? <BuildCellFromQuebraVendaOrigem
                    checked={(this.props.hasCheckedProductMap || {})[vendaURI]}
                    new={this.props.new}
                    origemVenda={this.props.origemVenda}
                    salePaymentItemKeyList={Object.keys(this.props.salePaymentItemMap || {})}
                    venda={this.props.venda}
                />
                : null}
            {/* Produtos */}
            {Object.values((this.props.salePaymentItemMap || {})).map(salePaymentItem => <BuildCellFromItemPagamentoVenda
                key={`${salePaymentItem.key}&n=${this.props.new}`}
                new={this.props.new}
                remainingAmount={(this.props.remainingAmountMap || {})[salePaymentItem.key]}
                salePaymentItem={salePaymentItem}
            />)}
            {/* Rodapé */}
            {this.props.state !== STATE_CONTROLE_VENDA_MANUTENCAO_VENDA
                ? <BuildCellFromQuebraVendaTotal
                    hasNewSalePaymentItemToSend={this.props.hasNewSalePaymentItemToSend}
                    new={this.props.new}
                    productTotal={this.props.productTotal}
                    productWithCommissionTotal={this.props.productWithCommissionTotal}
                    productWithCommissionWithDiscountByPersonTotal={this.props.productWithCommissionWithDiscountByPersonTotal}
                    productWithCommissionWithDiscountTotal={this.props.productWithCommissionWithDiscountTotal}
                    productTotalRemaining={this.props.productTotalRemaining}
                    productWithCommissionTotalRemaining={this.props.productWithCommissionTotalRemaining}
                    productWithCommissionWithDiscountByPersonTotalRemaining={this.props.productWithCommissionWithDiscountByPersonTotalRemaining}
                    productWithCommissionWithDiscountTotalRemaining={this.props.productWithCommissionWithDiscountTotalRemaining}
                    valorCupom={valorCupom}
                    valorTeleEntrega={valorTeleEntrega}
                />
                : null}
        </>
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    hasCheckedProductMap: state.controleVendaReducer.hasCheckedProductMap,
    state: state.controleVendaReducer.state,
    tabIndex: state.appReducer.getTabIndex(),
    ...state.idiomaReducer,
})

export default connect(mapStateToProps)(VendaTable)
