import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"

import * as appActions from "../../../store/actions/appAction"
import * as pagamentoVendasActions from "../../../store/actions/controleVenda/pagamentoVendasAction"

import InputCustomizado from "../../UI/Input/InputCustomizado"

const ConfirmButton = (customProps) => {
  const props = { ...customProps, reference: undefined }

  return <>
    <button
      {...props}
      ref={customProps.reference}
      className={`blue-button font-color${props.className ? ` ${props.className}` : ""}`}
    />
  </>
}

/**
 * Classe que monta a dialog para usuário escolher origens de venda para pagar.
 */
class DialogPayOrigensVenda extends React.Component {

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  componentDidMount() {
    log("DialogPayOrigensVenda componentDidMount")
    this.componentDidUpdate()
  }

  /**
   * Método executado APÓS a atualização do componente.
   */
  componentDidUpdate() {
    log("DialogPayOrigensVenda componentDidUpdate")
    if (this.confirmButton) {
      // Desabilita o botão depois da tela montar. Se colocar disabled no render, nunca mais fica enabled.
      this.confirmButton.disabled = true
    }
    this.checkBoxList
      .filter(checkBoxComponent => checkBoxComponent && checkBoxComponent.inputComponent)
      .forEach(checkBoxComponent => checkBoxComponent.inputComponent.checked = false)
  }

  /**
   * Método que monta o componente.
   */
  render() {
    log("DialogPayOrigensVenda render")

    // Inicializa a variável para guardar a referência de cada item da lista
    this.checkBoxList = []

    // Monta a lista de origens de venda com check box
    let origemVendaGrid = this.props.origemVendaList.map(origemVenda =>
      <InputCustomizado key={origemVenda.nome} id={origemVenda.nome} type="checkbox" name={origemVenda.nome} label={origemVenda.nome}
        ref={ref => {
          if (ref) {
            // adiciona no item uma referência para a origem de venda
            getReduxWrappedComponent(ref).origemVenda = origemVenda
            // guarda a referência do item
            this.checkBoxList.push(getReduxWrappedComponent(ref))
          }
        }}
        handleInputValidado={() => {
          this.confirmButton.disabled = this.checkBoxList.filter(checkBoxComponent =>
            checkBoxComponent.inputComponent.checked).length === 0
        }}
        tabIndex={(this.props.tabIndex + 1) * -1}
      />
    )

    // Monta o componente
    return <>
      <div className="dialog-scroll-pane">
        {/* origens */}
        <div className="table" >
          {origemVendaGrid}
        </div>
      </div>
      {/* botão */}
      <div className="dialog-horizontal-button-grid">
        <ConfirmButton reference={ref => this.confirmButton = ref} type="button"
          onClick={() => {
            this.props.appDialogHide()
            // Exibe a tela para pagar as vendas das origens selecionadas
            this.props.exibeTelaPagamentoOrigensVenda(
              // Gera uma lista de origens de venda a partir da lista de itens, filtrando por aqueles que estão selecionados
              this.checkBoxList.filter(checkBoxComponent => checkBoxComponent.inputComponent.checked).map(checkBoxComponent => checkBoxComponent.origemVenda)
            )
          }} >{getStrings().confirm}</ConfirmButton>
      </div>
    </>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  tabIndex: state.appReducer.getTabIndex(),
  ...state.idiomaReducer,
  dialogShow: state.appReducer.dialogShow,
  origemVendaList: state.controleVendaReducer.origemVendaList
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  exibeTelaPagamentoOrigensVenda: origensVendaPagar => dispatch(pagamentoVendasActions.exibeTelaPagamentoOrigensVenda(origensVendaPagar)),

  appDialogHide: () => dispatch(appActions.appDialogHide())
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DialogPayOrigensVenda)
