import { dispatchTipo } from "../../../../../store/actions/acaoTemplate";
import criarAction from "../factory/criarAction";
import { mobiAppReducerTipo } from "./mobiAppReducer";

export const tiposDeAcoes = {
    ATUALIZAR_MOBI_APP: 'ATUALIZAR_MOBI_APP:MOBI_APP',
    LIMPAR_MOBI_APP: 'LIMPAR_MOBI_APP:MOBI_APP',
    RESETAR_MOBI_APP: 'RESETAR_MOBI_APP:MOBI_APP',
};

export const atualizarMobiApp = (carga: Partial<mobiAppReducerTipo>) => function (dispatch: dispatchTipo) {
    dispatch(criarAction(carga, tiposDeAcoes.ATUALIZAR_MOBI_APP));
}

export const limparMoBiApp = () => function(dispatch: dispatchTipo) {
    dispatch(criarAction(undefined, tiposDeAcoes.LIMPAR_MOBI_APP));
}


export const resetarCoresMoBiApp = () => function(dispatch: dispatchTipo) {
    dispatch(criarAction(undefined, tiposDeAcoes.RESETAR_MOBI_APP));
}
