import React from "react"
import { connect } from "react-redux"

import { ClienteHttp } from "../../../apps/promo_app_frontend/nucleo/utils/utils"
import { getAppEmpresa } from "../../../utils/AppUtils"
import { equalsCoerced } from "../../../utils/ComparatorsUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getIdFromEntity } from "../../../utils/URIUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"

import * as actions from "../../../store/actions/cadastroAction"
import * as appActions from "../../../store/actions/appAction"
import { APP_NOTIFICATION_TYPE_WARNING } from "../../../store/actions/actionTypes"

import CadastroForm from "./CadastroForm"
import InputCustomizado from "../../UI/Input/InputCustomizado"
import AtivoInputDefault from "../cadastroInputDefault/AtivoInputDefault.js"
import CodigoInputDefault from "../cadastroInputDefault/CodigoInputDefault.js"
import NomeInputDefault from "../cadastroInputDefault/NomeInputDefault.js"
import { Button } from "../../UI/Button/Button"

/**
 * Classe que repassa para o CadastroForm as informações para a montagem do formulário para o cadastro das Formas de Pagamento.
 */
class FormaPagamentoForm extends React.Component {
  render() {
    log("FormaPagamentoForm render")
    return <CadastroForm {...this.props} formModel={FormaPagamentoValues} />
  }
}

/**
 * Classe que realiza a montagem do formulário para o cadastro das Formas de Pagamento.
 */
class FormaPagamentoValues extends React.Component {

  state = {
    usaPagSeguro: false,
  }

  enabled = true
  ismounted = false

  /**
   * Retorna se os campos obrigatórios (required) foram todos preenchidos
   */
  checkRequired = newData => {
    log("FormaPagamentoValues checkRequired", newData)
    return (newData.codigo !== "") && (newData.nome !== "")
  }

  /**
   * Recupera os dados do formulário.
   */
  getFormData = () => {
    log("FormaPagamentoValues getFormData")

    const formData = {
      empresaSet: [getAppEmpresa()],
      enabled: this.enabled.getValue(),
      codigo: this.codigo.getValue(),
      nome: this.nome.getValue(),
      emiteDocumentoFiscal: this.emiteDocumentoFiscal.inputComponent.checked,
      usaPagSeguro: this.usaPagSeguro.inputComponent.checked,

      ...(this.props.cadastroDados._links ? { _links: this.props.cadastroDados._links } : {})
    }

    return formData
  }

  /**
   * Método executado ao clicar no botão "Configurações".
   * Exibe a tela de gerar origens de venda automaticamente.
   */
  handleConfig = () => {
    log("FormaPagamentoValues handleConfig")
    this.props.showSetup()
  }

  /**
   * Método executado ao clicar no botão "Gravar".
   * Recupera os dados do formulário e repassa para o método que irá persistir os dados.
   */
  handleGravar = () => {
    log("FormaPagamentoValues handleGravar")

    // Repassa para o método que irá persistir os dados
    this.props.onSave(this.getFormData())
  }

  /**
   * Retorna se houve alteração em algum dado do cadastro
   */
  wasChanged = (newData, oldData) => {
    let result = !equalsCoerced(oldData, newData,
      (oldValue, newValue) =>
        (newValue.enabled === oldValue.enabled)
        && (newValue.codigo === oldValue.codigo)
        && (newValue.nome === oldValue.nome)
        && (newValue.emiteDocumentoFiscal === oldValue.emiteDocumentoFiscal)
        && (newValue.usaPagSeguro === oldValue.usaPagSeguro))

    log("FormaPagamentoValues wasChanged", { newData, oldData, result })

    return result
  }

  /**
   * Retorna se houve alteração em algum dado do cadastro, comparando com os dados sendo editados.
   */
  wasChangedFromCurrent = newData => {
    log("FormaPagamentoValues wasChangedFromCurrent", newData)
    return this.wasChanged(newData, this.props.cadastroDados)
  }

  /**
   * Retorna se houve alteração em algum dado do cadastro, comparando com os dados presentes ao abrir a criação/edição do cadastro.
   */
  wasChangedFromInitial = newData => {
    log("FormaPagamentoValues wasChangedFromInitial", newData)
    return this.wasChanged(newData, this.props.cadastroDadosIniciais)
  }

  solicitarAutorizacaoPagSeguro = () => {
    const formaPagamentoId = getIdFromEntity(this.props.cadastroDados)
    const redirectUrl = `${window.location.href}?id=${formaPagamentoId}`

    this.props.appSpinnerShow("solicitacaoAutorizacao")

    ClienteHttp.requisicaoServidor("pagseguro/applicationAuthorizationRequest", "post", true, null, { redirectUrl, formaPagamentoId })
      .then(response => {
        if (response.data) {
          window.location.href = response.data
        }
      })
      .finally(() => this.props.appSpinnerHide("solicitacaoAutorizacao"))
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   * Carrega os tipos disponíveis e também define os valores iniciais das opções conforme cadastro carregado.
   */
  componentDidMount() {
    log("FormaPagamentoValues componentDidMount")
    this.ismounted = true

    this.setState({usaPagSeguro: this.props.cadastroDados.usaPagSeguro})

    this.componentDidUpdate()
  }

  /**
   * Método executado APÓS a atualização do componente.
   * Carrega os tipos disponíveis e também define os valores iniciais das opções conforme cadastro carregado.
   * 
   * Não há dados adicionais que são carregados para `FormaPagamento`, mas os campos devem ser populados novamente para,
   * no caso de troca de idioma, os tipos de `FormaPagamento` serem gerados no novo idioma.
   */
  componentDidUpdate(prevProps) {
    log("FormaPagamentoValues componentDidUpdate")

    if (this.props.cadastroDados) {

      // Se for passado false, é false; se for passado true, null, undefined ou não for passado, é true
      this.enabled.inputField.inputComponent.checked = this.props.cadastroDados.enabled !== false

      if (this.codigo) {
        this.codigo.inputField.inputComponent.value = this.props.cadastroDados.codigo ? this.props.cadastroDados.codigo : null
      }
      if (this.nome) {
        this.nome.inputField.inputComponent.value = this.props.cadastroDados.nome ? this.props.cadastroDados.nome : null
      }

      this.emiteDocumentoFiscal.inputComponent.checked = this.props.cadastroDados.emiteDocumentoFiscal

      this.usaPagSeguro.inputComponent.checked = this.props.cadastroDados.usaPagSeguro

      if (prevProps
        && !prevProps.cadastroDados.usaPagSeguro
        && this.props.cadastroDados.usaPagSeguro
        && !this.props.cadastroDados.gatewayPagamento) {

        this.props.appNotificationShow(
          <div>Para utilizar o pagamento pelo PagSeguro é necessário ter uma conta no PagSeguro e conceder autorização para o Ampix Mobi realizar as operações.<br /><br />
            Após salvar o cadastro da Forma de Pagamento você será redirecionado para o site do PagSeguro para entrar na sua conta ou criar uma nova conta.
          </div>,
          APP_NOTIFICATION_TYPE_WARNING, "", null, 300)
      }
    }
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("FormaPagamentoValues render")

    return <div className="sub-form">
      <AtivoInputDefault
        tabIndex={this.props.tabIndex}
        ref={input => this.enabled = getReduxWrappedComponent(input)}
        handleInputValidado={this.props.handleChange}
      />

      <CodigoInputDefault
        key={"__codigo_input__"}
        ref={input => this.codigo = getReduxWrappedComponent(input)}
        subPlaceholder={getStrings().paymentMethodSubPlaceholder}
        handleInputValidado={this.props.handleChange}
      />

      <NomeInputDefault
        key={"__nome_input__"}
        ref={input => this.nome = getReduxWrappedComponent(input)}
        subPlaceholder={getStrings().paymentMethodSubPlaceholder}
        handleInputValidado={this.props.handleChange}
        isRequired={true}
      />

      <InputCustomizado
        tabIndex={this.props.tabIndex}
        ref={input => this.emiteDocumentoFiscal = getReduxWrappedComponent(input)}
        id="emiteDocumentoFiscal"
        type="checkbox"
        name="emiteDocumentoFiscal"
        label={getStrings().issueReceipt}
        handleInputValidado={this.props.handleChange}
      />

      <InputCustomizado
        tabIndex={this.props.tabIndex}
        ref={input => this.usaPagSeguro = getReduxWrappedComponent(input)}
        id="usaPagSeguro"
        type="checkbox"
        name="usaPagSeguro"
        label={getStrings().gateway.usePaymentGateway("PagSeguro")}
        handleInputValidado={this.props.handleChange}
      />

      {getIdFromEntity(this.props.cadastroDados)
        && this.state.usaPagSeguro
        && !this.props.cadastroDados.gatewayPagamento?.autorizado
        ? <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
          <div style={{ color: "red", fontSize: "1.2em", fontWeight: "600" }}>
            <div style={{ textAlign: "center", marginBottom: "0.4em" }}>{"Aplicação não autorizada pelo PagSeguro"}</div>
            <div style={{ textAlign: "center", marginBottom: "0.4em" }}>{"Clique no botão abaixo para solicitar a autorização"}</div>
          </div>
          <Button
            key="__request_authorization_btn__"
            style={{ background: "var(--color-secondary)", color: "white", padding: "0.5em", borderRadius: "0.3em", width: "12em" }}
            type="button"
            onClick={this.solicitarAutorizacaoPagSeguro}
          >
            {"Solicitar autorização"}
          </Button>
        </div>
        : null
      }
    </div>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  tabIndex: state.appReducer.getTabIndex()
  , ...state.idiomaReducer
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  cadastroLoadFormatosCodigoImpresso: (onSuccess, onComplete) => 
    dispatch(actions.cadastroLoadFormatosCodigoImpresso(onSuccess, onComplete))
  , cadastroUpdateLoading: steps => 
    dispatch(actions.cadastroUpdateLoading(steps))
  , showSetup: () => 
    dispatch(actions.showSetup())
  , appSpinnerShow: (origin) => 
    dispatch(appActions.appSpinnerShow(origin))
  , appSpinnerHide: (origin) => 
    dispatch(appActions.appSpinnerHide(origin))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FormaPagamentoForm)
